import "./RemindersTableName.scss";

const RemindersTableName = ({ name, isDefault }) => {
    return (
        <div className='reminders-table-name'>
            <span className='reminders-table-name__text'>{name}</span>
            {isDefault && (
                <span className='reminders-table-name__default'>Default</span>
            )}
        </div>
    );
};

export default RemindersTableName;
