import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, RichTextInput } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage } from "utils/helper";

const AddContentRichTextForm = ({
    selectedFormat,
    capsuleData,
    handleSuccess,
    editItem,
}) => {
    const [loading, setLoading] = useState(false);
    const [request, updateRequest] = useState({
        capsuleClassId: capsuleData?.capsule?.capsuleClassId,
        classCapsuleEntryId: capsuleData?.contentId,
        content: editItem?.content || "",
        contentType: selectedFormat,
    });
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();
    const [formattedText, setRich] = useState("");
    const toastMessage = useContext(ToastContext);

    const handleChange = (value, delta, source, editor) => {
        updateRequest({ ...request, content: value });
        setRich(editor?.getText());
        setErrors({});
    };

    const createCapsuleContentRichTextBlock = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            const url = apiRoute.createCapsuleContentBlock;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editCapsuleContentRichTextBlock = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            const { content } = request;
            const url = apiRoute.capsuleContentBlock(editItem?.contentId);
            const res = await API.put(url, { content });

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(
            e,
            { content: formattedText },
            undefined,
            {
                content: "Rich text is required",
            }
        );
        if (formisValid) {
            if (editItem) {
                return editCapsuleContentRichTextBlock();
            }
            return createCapsuleContentRichTextBlock();
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <RichTextInput
                grpClass='mb-3'
                type='text'
                name='content'
                labelClass='d-none'
                label='Rich Text'
                id='content'
                placeholder='Add description'
                value={request?.content}
                onChange={handleChange}
                isErr={errors?.content}
                errMssg={errors?.content}
            />
            <Button
                type='submit'
                text={editItem ? "Edit text" : "Save text"}
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

AddContentRichTextForm.prototypes = {
    selectedFormat: PropTypes.string,
    capsuleData: PropTypes.object,
    handleSuccess: PropTypes.func,
    editItem: PropTypes.object,
};

export default AddContentRichTextForm;
