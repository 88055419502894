import BCMapViewHeader from "components/dashboard/TrainSAM/SAM/BranchCollectionView/BranchCollectionMapView/BCMapViewHeader/BCMapViewHeader";
import AgentAViewGuideHeader from "./AgentAViewGuideHeader/AgentAViewGuideHeader";
import ConvoMapView from "components/dashboard/TrainSAM/SAM/Conversations/ConversationView/ConvoMapView/ConvoMapView";
import AgentAViewGuideBranches from "./AgentAViewGuideBranches/AgentAViewGuideBranches";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { IDLE, LOADING, ERROR, SUCCESS } = dataQueryStatus;

const AgentAViewGuide = ({ match }) => {
    const { params } = match || {};
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const guideId = params?.[0];
    const [guideDetails, setGuideDetails] = useState({});
    const [selectedStepId, selectStepId] = useState();
    const [lastToggledBranchId, selectLastToggledBranchId] = useState("");

    const getGuideFlow = async (selectedGuideFlowId) => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute.getGuide(guideId);
            const res = await API.get(url, {
                params: {
                    // ...filterParams
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setGuideDetails(data);
                const { steps } = data;

                if (selectedGuideFlowId) {
                    selectStepId(selectedGuideFlowId);
                } else {
                    const firstStep = steps?.[0];
                    if (firstStep) {
                        selectStepId(firstStep?.guideFlow?.guideFlowId);
                    }
                }

                setStatus(SUCCESS);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getGuideFlow();
        // eslint-disable-next-line
    }, []);

    const { agentGuide } = guideDetails;
    const { premiliaryFlow } = agentGuide || {};

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return <ErrorView errorMssg={errorMssg} />;
            case SUCCESS:
                return (
                    <AgentAViewGuideBranches
                        steps={guideDetails?.steps}
                        {...{ selectStepId, selectedStepId, guideId, lastToggledBranchId }}
                        handleRefresh={getGuideFlow}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <>
            <AgentAViewGuideHeader />
            <div className='meta__container pt-3'>
                <BCMapViewHeader title={agentGuide?.agentGuideName || "-"} />
                <div className='conversations-container'>
                    <div className='container-xxl p-0'>
                        <ConvoMapView
                            mainBranchId={premiliaryFlow?.guideFlowId}
                            isAgentGuideView={true}
                            // refreshConvoMap={refreshConvoMap}
                            // updateConvoMapBranches={(branches) =>
                            //     
                            // }
                            selectLastToggledBranchId={
                                selectLastToggledBranchId
                            }
                        />
                    </div>
                    {renderBasedOnStatus()}
                </div>
            </div>
        </>
    );
};

export default AgentAViewGuide;
