import { ReactSVG } from "react-svg";
import "./AnalyticsQAVATitle.scss";
import asset from "assets/images";
import { Link } from "react-router-dom";

const AnalyticsQAVATitle = ({ title, hideIcon, isActive, url }) => {
    return (
        <div className='analytics-qava-title'>
            {!hideIcon && (
                <Link to={'/analytics/qa/vet-tickets'}>
                    <ReactSVG src={asset.icons.arrowLeft} />
                </Link>
            )}
            <span className={`${isActive ? "active" : ""}`}>{title}</span>
        </div>
    );
};

export default AnalyticsQAVATitle;
