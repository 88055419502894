import { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import EmptyVariantData from "./EmptyVariantData";
import VariantMetadata from "./VariantMetadata/VariantMetadata";
import VariantActionModal from "./VariantActionModal/VariantActionModal";
import { VARIANT_ACTIONS } from "./enum";
import VariantDropdown from "./VariantDropdown/VariantDropdown";
import "./Variant.scss";

const { EDIT, CREATE } = VARIANT_ACTIONS;

const Variant = ({ selectedIssue, handleSuccess }) => {
    const [currentAction, setCurrentAction] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState({});

    const closeModal = () => {
        setCurrentAction("");
        setSelectedVariant("");
    };

    const handleActionModal = (action, variant) => {
        setCurrentAction(action);
        setSelectedVariant(variant);
    };

    const headers = [
        "Variant",
        "Metadata",
        <div>
            <AddButton
                btnText='Add New Variant'
                onClick={() => setCurrentAction(CREATE)}
            />
        </div>,
    ];

    const dataSource = selectedIssue?.variants?.map((variant) => ({
        Variant: (
            <div>
                <span>{variant?.name}</span>
            </div>
        ),
        metadata: <VariantMetadata metadata={variant?.metadata} />,
        Actions: (
            <div className='discovery--actions'>
                <div>
                    <AddButton
                        btnText='Add Metadata'
                        onClick={() => {
                            setCurrentAction(EDIT);
                            setSelectedVariant(variant);
                        }}
                    />
                </div>
                <VariantDropdown
                    variant={variant}
                    handleActionModal={handleActionModal}
                />
            </div>
        ),
    }));

    return (
        <>
            <Table
                dataSource={
                    selectedIssue?.variants?.length > 0
                        ? dataSource
                        : EmptyVariantData
                }
                headers={headers}
                otherClassNames='discovery-variant'
            />

            {currentAction && (
                <VariantActionModal
                    currentAction={currentAction}
                    closeModal={closeModal}
                    variant={selectedVariant}
                    selectedIssue={selectedIssue}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

Variant.prototypes = {
    selectedIssue: PropTypes.string.isRequired,
    handleSuccess: PropTypes.func.isRequired,
};

export default Variant;
