import * as types from "./types";

const initialState = {
    user: {
        workspace: {},
        role: {
            permissions: [],
        },
    },
    workspaces: [{}],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_AUTH_INFO:
            return { ...state, user: { ...state.user, ...payload } };

        case types.SET_USER_WORKSPACES:
            return { ...state, workspaces: payload };

        case types.UPDATE_PARTICULAR_WORKSPACE:
            return {
                ...state,
                workspaces: state?.workspaces?.map((workspace) => {
                    if (
                        workspace?.workspaceId ===
                        payload?.user?.workspace?.workspaceId
                    ) {
                        return {
                            ...workspace,
                            ...payload,
                        };
                    } else return workspace;
                }),
            };

        default:
            return state;
    }
};

export default AuthReducer;
