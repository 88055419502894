import { Tag, Empty } from "components/ui";
import "./TeamMemberSelect.scss";

const TeamMemberSelect = ({
    selectedUsers,
    setSelectedUsers,
    request,
    setRequest,
    hasErr,
    errMssg,
    updateErrors,
}) => {
    const handleSelectedUser = (userId) => {
        updateErrors?.("userIds", "");
        const filteredIds = request?.userIds.filter((id) => id !== userId);

        const filteredUsers = selectedUsers?.filter(
            (selectedUser) => selectedUser?.userId !== userId
        );

        setSelectedUsers([...filteredUsers]);

        return setRequest({
            ...request,
            userIds: [...filteredIds],
        });
    };

    return (
        <div>
            <div
                className={`member__select__container ${
                    hasErr ? "member__select__invalid" : ""
                }`}>
                {selectedUsers?.length ? (
                    selectedUsers?.map((selectedUser, i) => (
                        <Tag
                            key={i}
                            name={selectedUser?.name}
                            cancel
                            onCancel={(e) => handleSelectedUser(e)}
                            value={selectedUser.userId}
                        />
                    ))
                ) : (
                    <Empty className='member__select__empty' />
                )}
            </div>
            <div className='member__select__error'>{errMssg}</div>
        </div>
    );
};

export default TeamMemberSelect;
