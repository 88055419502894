import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import { RichTextInput } from "components/ui";
import "./TicketAAInfoCard.scss";

const TicketAAInfoCard = ({
    borderColor,
    otherClass,
    title,
    text,
    handleSend,
    loading,
    handleChange,
    disabledText,
    disabled,
}) => {
    return (
        <div
            className={`ticketaa-info-card ${otherClass || ""}`}
            style={{ borderColor }}>
            {/* {title && <h6>{title}</h6>} */}
            <RichTextInput
                value={text}
                onChange={(value) =>
                    !disabled && handleChange?.(value)
                }
                hideLabel
            />
            <span
                className={`ticketaa-info-card-send-btn ${
                    disabled ? "disabled" : ""
                }`}
                style={{ background: borderColor }}
                onClick={() => !disabled && handleSend()}>
                {loading ? (
                    <SmallLoader />
                ) : (
                    <ReactSVG
                        src={imageLinks.icons.sendIconv2}
                        title={disabled ? disabledText : ""}
                        className='ticketaa-info-card-send-icon'
                    />
                )}
            </span>
        </div>
    );
};

export default TicketAAInfoCard;
