import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import UnassignedCapsulesHeader from "./UnassignedCapsulesHeader/UnassignedCapsulesHeaeder";
import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const UnassignedCapsules = ({
    pushSelectedClass,
    selectedCapsuleClass,
    prevAssigned,
}) => {
    const [errorMssg, setErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [status, setStatus] = useState(
        capsuleClasses?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const [totalCount, setTotalCount] = useState();
    const prevAssignedElements = prevAssigned?.filter(
        (assigned) =>
            assigned?.capsule.capsuleCode === selectedCapsuleClass?.capsuleCode
    );
    const assignedIds = prevAssignedElements?.map((el) => el.capsuleEntryId);

    const realCount =
        totalCount < prevAssignedElements?.length
            ? 0
            : totalCount - prevAssignedElements?.length;

    const getCapsuleClasses = async () => {
        try {
            if (capsuleClasses?.length < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        //eslint-disable-next-line
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={capsuleClasses?.map(
                            (capsuleClass) => capsuleClass?.capsuleCode
                        )}
                        pushSelectedClass={pushSelectedClass}
                        filterIds={assignedIds}
                        saveCount={(count) => setTotalCount(count)}
                        capsuleBottomMenu={true}
                        hideEditAction={true}
                        hideDeleteAction={true}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };
    return (
        <div className='col-lg-12'>
            <UnassignedCapsulesHeader count={realCount ? realCount : "-"} />
            {renderBasedOnStatus()}
        </div>
    );
};

export default UnassignedCapsules;
