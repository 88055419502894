import React, { useEffect } from "react";
import { Empty, Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import { useState } from "react";
import API from "services/lib/api";
import BranchViewElement from "components/dashboard/Branches/BranchView/BranchViewElement/BranchViewElement";

const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const InitialBranch = () => {
    const [loadingText, setLoadingText] = useState();

    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const [initialBranch, setInitialBranch] = useState();

    const handleError = (err) => {
        setErrorMssg(getErrorMessage(err));
        setStatus(ERROR);
    };

    const getInitialWorkspaceChatBranch = async () => {
        try {
            setStatus(LOADING);
            setLoadingText({
                title: "Fetching Initial Workspace Branch",
            });

            const url = apiRoute?.getInitialBranch;
            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;
                if (data.branchId) {
                    setInitialBranch(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        getInitialWorkspaceChatBranch();
        // eslint-disable-next-line
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading {...loadingText} />;

            case NULLMODE:
                return <Empty text={"No Initial Branch Created"} />;

            case DATAMODE:
                return (
                    <BranchViewElement
                        branch={initialBranch}
                        handleRefresh={getInitialWorkspaceChatBranch}
                        hideOptionsView={true}
                        hideBranchActions={true}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getInitialWorkspaceChatBranch}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='meta__container padded__layout'>
            {renderCompBasedOnStage()}
        </div>
    );
};

export default InitialBranch;
