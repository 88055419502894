import * as types from "./types";

const initialState = {
    messagingTags: [],
};

const MessageTagsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_MESSAGING_TAGS:
            return { ...state, messagingTags: payload };
      
        default:
            return state;
    }
};

export default MessageTagsReducer;
