import StatusMapChart from "./StatusMapChart/StatusMapChart";
import StatusMapHeader from "./StatusMapHeader/StatusMapHeader";

import "./StatusMap.scss";

const StatusMap = ({
    handleSelect,
    statusMapMetrics,
    statusMapData,
    selectedOption,
}) => {
    return (
        <>
            <div className='statusMap'>
                <StatusMapHeader
                    selectedOption={selectedOption}
                    handleSelect={handleSelect}
                    statusMapMetrics={statusMapMetrics}
                />
                <StatusMapChart
                    statusMapData={statusMapData}
                    selectedOption={selectedOption}
                />
            </div>
        </>
    );
};

export default StatusMap;
