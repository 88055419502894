import React from "react";
import PropTypes from "prop-types";
import AddCapsuleClassButton from "./AddCapsuleClassButton/AddCapsuleClassButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleClassesHeader.scss";

const { KB_CAPSULE_SETTINGS } = baseResources;
const { CREATE } = baseResourceActions;

const CapsuleClassesHeader = ({
    numberOfClasses,
    handleCapsulationSuccess,
    hideAddClassBtn,
}) => {
    return (
        <>
            <div className='capsule__class--header'>
                <h3 className='capsule__class--header--text'>
                    Classes{" "}
                    <span className='capsule__class--header--badge'>
                        {numberOfClasses}
                    </span>
                </h3>
                {!hideAddClassBtn && (
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_CAPSULE_SETTINGS}`]}>
                        <AddCapsuleClassButton
                            handleCapsulationSuccess={handleCapsulationSuccess}
                        />
                    </ComponentAccessControl>
                )}
            </div>
        </>
    );
};

CapsuleClassesHeader.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
    numberOfClasses: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.bool,
    ]).isRequired,
};

export default CapsuleClassesHeader;
