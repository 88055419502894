import React, { useEffect } from "react";
import BadgeLabel from "../../../../common/BadgeLabel/BadgeLabel";
import imageLinks from "assets/images";
import CustomRuleElement from "./CustomRuleElement/CustomRuleElement";
import "./FormBuilderInputRulesListing.scss";

const {
    icons: { crossIconGrey, arrowRight },
} = imageLinks;

const FormBuilderInputRulesListing = ({
    elementId,
    elementType,
    customRules,
    activeRule,
    setActiveRule,
    showRules,
    toggleRulesVisibility,
    removeInputRule,
    removeRuleConstraint,
    errors,
    handleValidateCustomRule,
}) => {
    useEffect(() => {
        handleValidateCustomRule(elementId, customRules, setActiveRule);
        // eslint-disable-next-line
    }, [errors]);

    return (
        <div className='builder__rules row'>
            <div className='col-md-3'>
                <BadgeLabel
                    text={showRules ? "Close Rules" : "View Rules"}
                    icon={showRules ? crossIconGrey : arrowRight}
                    onClick={() => {
                        removeRuleConstraint();
                        toggleRulesVisibility(!showRules);
                    }}
                />
            </div>
            <div className='col-md-9 ps-0'>
                {showRules && (
                    <div className='builder__rules--listing'>
                        <ul className='builder__rules--listing__list'>
                            {customRules.map(
                                (
                                    {
                                        ruleCode,
                                        ruleConstraint,
                                        constraintData,
                                    },
                                    index
                                ) => {
                                    return (
                                        <CustomRuleElement
                                            key={index}
                                            ruleIndex={index}
                                            elementType={elementType}
                                            ruleCode={ruleCode}
                                            isActive={
                                                ruleCode ===
                                                activeRule?.ruleCode
                                            }
                                            ruleConstraint={ruleConstraint}
                                            constraintData={constraintData}
                                            setActiveRule={setActiveRule}
                                            handleDelete={() => {
                                                removeRuleConstraint();
                                                removeInputRule(
                                                    elementId,
                                                    ruleCode
                                                );
                                                toggleRulesVisibility(false);
                                            }}
                                            hasError={errors?.[
                                                elementId
                                            ]?.customRules?.includes(index)}
                                        />
                                    );
                                }
                            )}
                        </ul>
                        <div className='col-1'>
                            <BadgeLabel icon={arrowRight} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormBuilderInputRulesListing;
