import React from "react";
import { MESSAGE_TAG_RESPONSE_TYPE } from "components/dashboard/Analyzer/enum";
import { Empty, Tag } from "components/ui";

const TDCannedResponsesBody = ({ cannedResponses, handleSelectTagResp }) => {
    return (
        <>
            <div className='analyzer-td__elements'>
                {cannedResponses?.length ? (
                    <div className='analyzer-td__items'>
                        {cannedResponses?.map((item, i) => (
                            <Tag
                                key={i}
                                name={item?.title}
                                onClick={(e) =>
                                    handleSelectTagResp(
                                        item,
                                        MESSAGE_TAG_RESPONSE_TYPE.CANNED_RESPONSE
                                    )
                                }
                            />
                        ))}
                    </div>
                ) : (
                    <Empty text='No response created' />
                )}
            </div>
        </>
    );
};

export default TDCannedResponsesBody;
