import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import DependencyModal from "components/dashboard/TrainSAM/Dependency/common/DependencyModal/DependencyModal";
import { DEPENDENCY_ACTIONS } from "components/dashboard/TrainSAM/Dependency/enum";
import { apiRoute } from "services";

const { EDIT_DEPENDENCY, DELETE_DEPENDENCY } = DEPENDENCY_ACTIONS;

const SwitchesTableModal = ({
    currentAction,
    getDependencies,
    currentDependency,
    closeModal,
    showModal,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT_DEPENDENCY:
                return (
                    <DependencyModal
                        closeModal={closeModal}
                        {...currentDependency}
                        getDependencies={getDependencies}
                        showModal={showModal}
                        isEdit={currentAction === EDIT_DEPENDENCY}
                    />
                );
            case DELETE_DEPENDENCY:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${currentDependency?.dependencyName}`}
                        show={showModal}
                        closeModal={closeModal}
                        handleSuccess={getDependencies}
                        url={apiRoute?.dependency(
                            currentDependency?.dependencyId
                        )}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnAction()}</>;
};

export default SwitchesTableModal;
