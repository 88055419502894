import { useLocation } from "react-router";
import PreHeading from "components/ui/PreHeading/PreHeading";
import AnalyticsTSamHeading from "../../common/AnalyticsTSamHeading/AnalyticsTSamHeading";
import AnalyticsTSamConvoViewTable from "./AnalyticsTSamConvoViewTable/AnalyticsTSamConvoViewTable";

const AnalyticsTSamConvoView = ({ match }) => {
    let data = useLocation();
    const { params: { conversationId } = {} } = match || {};

    return (
        <>
            <PreHeading
                title='Conversations'
                text={"Conversations"}
                subText={data?.state?.issueName}
                url={`/analytics/t-sam/conversations`}
            />
            {/* /t-sam/conversations/${conversationId}/view-conversation */}
            <div className='padded__layout pt-3'>
                <AnalyticsTSamHeading
                    title={data?.state?.issueName}
                    hasFilter={false}
                    noSearch
                />
                <AnalyticsTSamConvoViewTable conversationId={conversationId} />
            </div>
        </>
    );
};

export default AnalyticsTSamConvoView;
