import React, { useEffect } from "react";
import { imageCropScales, STANDARD, aspectRatios } from "./enums";
import "./ImageCropScale.scss";

const { standardRatio, wideRatio } = aspectRatios;

const ImageCropScale = ({ setAspect, selectedScale, setSelectedScale }) => {
    useEffect(() => {
        setAspect(selectedScale === STANDARD ? standardRatio : wideRatio);
        // eslint-disable-next-line
    }, [selectedScale]);

    return (
        <div className='image__crop__scale__container'>
            <ul className='image__crop__scales'>
                {Object.entries(imageCropScales)?.map(([key, value], i) => (
                    <li
                        key={i}
                        className={`image__crop__scale ${
                            selectedScale === key
                                ? "active__image__crop__scale"
                                : ""
                        }`}
                        onClick={() => setSelectedScale(key)}>
                        <span className={`image__crop__scale--${value}`}></span>
                        <span className={`image__crop__scale--text`}>
                            {value}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ImageCropScale;
