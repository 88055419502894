import React from 'react';
import BranchesGroupList from './BranchesGroupList/BranchesGroupList';

const BranchGroupViewList = ({ branches, branchGroupType, selectedBranch, parentCapsule, parentSource, handleBranchSelect, handleFullPageRefresh }) => {
    return (
        <BranchesGroupList
            branches={branches}
            selectedBranch={selectedBranch}
            handleBranchSelect={handleBranchSelect}
            branchGroupType={branchGroupType}
            parentCapsule={parentCapsule}
            parentSource={parentSource}
            handleFullPageRefresh={handleFullPageRefresh}
        />
    );
};

export default BranchGroupViewList;
