import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { TICKET_GUIDE_STEP_ACTIONS } from "./enum";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";

const TicketAGuideStep = ({
    steps,
    stepData,
    handleNextStep,
    currentStep,
    disabledOption,
    selectedFlowOptionId,
    selectGuideOptionId,
    loading,
}) => {

    const {
        guideFlowOptionActionType,
        guideFlowOptionLabel,
        guideFlowOptionId,
    } = stepData || {};


    const handleClickStep = () => {
        const cCurrentStep = currentStep.currentStep;
        const availableNextStep = steps?.find?.(
            ({ currentStep }) => currentStep === cCurrentStep + 1
        )?.ticketAgentGuideOptionId
        const offCourseOption = disabledOption?.guideFlowOptionId !== guideFlowOptionId;

        if (availableNextStep && !offCourseOption) {
            selectGuideOptionId(availableNextStep)
        } else {
            handleNextStep(guideFlowOptionId, guideFlowOptionActionType);
        }

    };

    const isLoading = selectedFlowOptionId === guideFlowOptionId && loading;

    return (
        <div
            className={`ticketaa-next-steps__item`}
            onClick={handleClickStep}>
            <span className='ticketaa-next-steps__item__text'>
                {guideFlowOptionLabel}
            </span>
            <div className='d-flex'>
                {guideFlowOptionActionType && (
                    <span className='ticketaa-next-steps__item__action'>
                        {TICKET_GUIDE_STEP_ACTIONS[guideFlowOptionActionType]}
                    </span>
                )}
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <ReactSVG src={asset.icons.rightBlack} />
                )}
            </div>
        </div>
    );
};

export default TicketAGuideStep;
