import { Table } from "components/ui";
import SwitchesStatusSwitch from "./SwitchesStatusSwitch/SwitchesTableStatus";
import SwitchesTableActions from "./SwitchesTableActions/SwitchesTableActions";
import SwitchesStatusMap from "./SwitchesStatusMap/SwitchesStatusMap";
import { SWITCHES_HEADERS } from "components/dashboard/TrainSAM/Dependency/enum";

const SwitchesListingTableView = ({
    dependencies,
    handleActionModal,
    getDependencies,
    tableLoading,
}) => {
    const tableData = () =>
        dependencies.map(
            ({ dependencyId, status, dependencyName, dependencyElements }) => ({
                name: <div>{dependencyName}</div>,
                status: (
                    <SwitchesStatusSwitch
                        dependencyId={dependencyId}
                        status={status}
                        getDependencies={getDependencies}
                    />
                ),
                actions: (
                    <SwitchesTableActions
                        dependencyName={dependencyName}
                        dependencyId={dependencyId}
                        handleActionModal={handleActionModal}
                        dependencyElements={dependencyElements}
                    />
                ),
                dropdownElement: (
                    <SwitchesStatusMap dependencyId={dependencyId} />
                ),
            })
        );

    return (
        <>
            <Table
                headers={SWITCHES_HEADERS}
                dataSource={tableData()}
                isAccordion={true}
                isLoading={tableLoading}
            />
        </>
    );
};

export default SwitchesListingTableView;
