import React from "react";
import { constraintInputTypes } from "components/dashboard/KnowledgeBase/FormSetup/BuilderElementsList/data";
import BadgeLabel from "components/dashboard/KnowledgeBase/FormSetup/FormBuilderForm/common/BadgeLabel/BadgeLabel";
import { CustomDatePicker, Input } from "components/ui";
import imageLinks from "assets/images";

const { TEXT_INPUT, DATE_INPUT, LINK, NUMBER_INPUT } = constraintInputTypes;

const RuleConstrainInput = ({
    constraintKeyType,
    constraintKey,
    displayAs,
    handleInputChange,
    removeRuleConstraint,
    defaultValue,
    hasError,
}) => {
    const onInputChange = (e) => {
        const { value } = e.target;
        handleInputChange(value);
    };

    const renderBasedOnType = () => {
        switch (constraintKeyType) {
            case TEXT_INPUT:
                return (
                    <Input
                        type='text'
                        name={constraintKey}
                        onChange={onInputChange}
                        value={defaultValue}
                        placeholder={displayAs}
                        hideLabel
                        isErr={hasError}
                        min
                    />
                );
            case NUMBER_INPUT:
                return (
                    <Input
                        type='number'
                        name={constraintKey}
                        onChange={onInputChange}
                        value={defaultValue}
                        placeholder={displayAs}
                        hideLabel
                        isErr={hasError}
                        min={0}
                    />
                );
            case LINK:
                return (
                    <Input
                        type='url'
                        name={constraintKey}
                        onChange={onInputChange}
                        value={defaultValue}
                        placeholder={displayAs}
                        hideLabel
                        isErr={hasError}
                    />
                );
            case DATE_INPUT:
                return (
                    <CustomDatePicker
                        name={constraintKey}
                        value={defaultValue}
                        onChange={handleInputChange}
                        hasError={hasError}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='deletable__input row align-items-center'>
            <div className='col-2'>
                <BadgeLabel
                    icon={imageLinks?.icons?.crossIconGrey}
                    onClick={() => removeRuleConstraint()}
                />
            </div>
            <div className='col-10'>{renderBasedOnType()}</div>
        </div>
    );
};

export default RuleConstrainInput;
