import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";

const TagsNonSelected = () => {
    return (
        <div className='analyzer-tags-container__non-selected'>
            <EmptyInfo message={"Please select a tag from above."} />
        </div>
    );
};

export default TagsNonSelected;
