import asset from "assets/images";
import "./AddedScenarios.scss";

const AddedScenarios = ({ handleRemove, addedScenarios, addedCollections }) => {
    return (
        <div className='added-scenarios'>
            <h3>Added Scenarios</h3>
            <section>
                {addedCollections?.map?.(
                    ({ branchCollectionId, branchCollectionName }) => (
                        <div>
                            {branchCollectionName}
                            <img
                                src={asset.icons.cancelX}
                                onClick={() =>
                                    handleRemove({
                                        branchCollectionId,
                                        branchCollectionName,
                                        isCollection: true
                                    })
                                }
                                alt='Icon'
                                className='d-inline-flex'
                            />
                        </div>
                    )
                )}
                {addedScenarios?.map?.(
                    ({ guideScenairoId, guideScenairoName }) => (
                        <div>
                            {guideScenairoName}
                            <img
                                src={asset.icons.cancelX}
                                onClick={() =>
                                    handleRemove({
                                        guideScenairoId,
                                        guideScenairoName,
                                    })
                                }
                                alt='Icon'
                                className='d-inline-flex'
                            />
                        </div>
                    )
                )}
            </section>
        </div>
    );
};

export default AddedScenarios;
