import ReactQuill from "react-quill";

const modules = {
    toolbar: [
        [{ header: [] }],
        ["bold", "italic", "underline"],
        [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
        ],
        ["link", "image", "code-block", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "align",
    "underline",
    "strike",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "code-block",
];

export const RichTextInput = ({
    name,
    id,
    label,
    labelClass,
    grpClass,
    placeholder,
    isErr,
    errMssg,
    extraMssg,
    disabled,
    onChange,
    value = "",
    hideLabel
}) => {
    return (
        <div className={`form-group ${grpClass || ""}`}>
           {!hideLabel && <label
                htmlFor={id}
                className={`form-label ${labelClass || ""} ${
                    disabled ? "text-muted" : ""
                }`}>
                {label}
            </label>}
            <ReactQuill
                theme='snow'
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                modules={modules}
                formats={formats}
                id={name}
                className={`react-quill ${isErr ? "is-invalid" : ""}`}
            />
            {extraMssg ? (
                <div className='text-muted extra-input-mssg'>{extraMssg}</div>
            ) : null}
            <div className='input-err-message text-danger mt-1'>{errMssg}</div>
        </div>
    );
};
