const accountService = "accounts-service/api/v1/";
const agentsInbox = "agents-inbox/api/v1/";
const kSam = "k-sam/api/v1/";
const gmailService = "gmail-client/api/v1/";
const twitterService = "twitter/api/v1/";
const facebookService = "facebook/api/v1/";

export const apiRoute = {
    refreshTokenEndpoint: `oauth/refresh-token`,
    // Gmail
    authorizeGoogle: `${gmailService}google/oauth/accessToken`,
    // Twitter
    authorizeTwitter: `${twitterService}connect`,
    disconnectTwitter: `${twitterService}unsubscribe`,
    //FB
    authorizeFacebook: `${facebookService}connect`,
    /**
     *
     * ACCOUNTS SERVICE
     */

    //AUTH ENDPOINTS
    signup: `${accountService}auth/signup`,
    signin: `${accountService}auth/signin`,
    signout: `${accountService}auth/signout`,
    verifyEmail: `${accountService}auth/user/verify-email`,
    resendOTP: (userId) => `${accountService}auth/user/${userId}/resend-otp`,
    forgotPassword: `${accountService}auth/user/forgot-password`,
    verifyPwdOTP: (sessionId) =>
        `${accountService}auth/user/forgot-password/${sessionId}/verify-otp`,
    resendPwdOTP: (sessionId) =>
        `${accountService}auth/user/forgot-password/${sessionId}/resend-otp`,
    resetPassword: (sessionId) =>
        `${accountService}auth/user/forgot-password/${sessionId}/update-password`,
    getWorkspaces: (email) =>
        `${accountService}auth/user/workspaces?email=${email}`,
    generateWorkspaceSlug: `${accountService}common/generate/workspace-slug`,

    // NOTIFICATION ENDPOINTS
    deviceNotificationToken: `${accountService}push-notification/device-token`,

    //USER ENDPOINTS
    userProfile: `${accountService}user/profile`, //update and get
    updatePassword: `${accountService}user/update-password`,
    //WORKSPACE ENDPOINTS
    getUsers: `${accountService}workspace/users`,
    workspaceProfile: `${accountService}workspace/profile`, //update and get
    inviteUser: `${accountService}workspace/user/invite`,
    resendInvite: (userId) =>
        `${accountService}workspace/user/${userId}/resend-invite`,
    changeUserAccess: (userId, block) =>
        `${accountService}workspace/user/${userId}/access?block=${block}`,
    removeUser: (userId) => `${accountService}workspace/user/${userId}/remove`,
    blockUser: (userId) => `${accountService}workspace/user/${userId}/access`,

    getPermissions: `${accountService}workspace/permissions`,
    getRoles: `${accountService}workspace/roles/permissions`,
    addRole: `${accountService}workspace/role`,
    editRole: (roleCode) => `${accountService}workspace/role/${roleCode}`,
    updateUserRole: (userId, roleCode) =>
        `${accountService}workspace/role/${userId}/${roleCode}`,

    removeRole: (roleCode) => `${accountService}workspace/role/${roleCode}`,
    getWorkspaceRoles: `${accountService}workspace/roles`,
    workspaceAppearance: `${accountService}workspace/appearance-setting`,

    /**
     *
     * AGENTS INBOX
     */
    //TAGS ENDPOINTS
    getMessageTags: `${agentsInbox}messaging-tags`,
    createMessageTag: `${agentsInbox}messaging-tags`,
    closeTicket: (ticketId) => `${agentsInbox}ticket/${ticketId}/close`,
    getTicketFiles: `${agentsInbox}ticket/files`,
    investigateMessage: `${agentsInbox}messaging-tags/investigate`,
    getMessageTag: (tagId) => `${agentsInbox}messaging-tags/${tagId}`,
    editMessageTag: (tagId) => `${agentsInbox}messaging-tags/${tagId}`,
    searchCannedResponse: `${agentsInbox}messaging-tags/search/canned-response`,
    createCannedResponse: `${agentsInbox}messaging-tags/canned-response`,
    createTaglessCannedResponse: `${agentsInbox}messaging-tags/canned-response`,
    editCannedResponse: (CannedResponseId) =>
        `${agentsInbox}messaging-tags/canned-response/${CannedResponseId}`,
    addKeyPhrase: (tagId) => `${agentsInbox}messaging-tags/${tagId}/key-phrase`,
    editKeyPhrase: (keyPhraseId) =>
        `${agentsInbox}messaging-tags/key-phrase/${keyPhraseId}`,
    getInboxApps: `${agentsInbox}app-integrations/statuses`,
    selectExternalEmail: (appUserId) =>
        `${agentsInbox}app-integrations/${appUserId}/for-external-emails`,
    getExternalEmail: `${agentsInbox}app-integrations/statuses/gmail`,
    disconnectInboxApp: (appUserId) =>
        `${agentsInbox}app-integrations/${appUserId}/disconnect`,
    connectWebhook: `${agentsInbox}app-integrations/connect/web-hook`,
    getMessageTemplates: `${agentsInbox}message-templates`,
    editMssgTemplateVariableComp: (templateId) =>
        `${agentsInbox}message-templates/${templateId}/use-variables`,
    editMssgTemplate: (templateId) =>
        `${agentsInbox}message-templates/${templateId}`,
    getTemplateVariables: `${agentsInbox}message-templates/template-variables`,
    getTicketsCount: `${agentsInbox}ticket/count`,
    getAgentTicketsInfoCount: `${agentsInbox}tickets/agent/info-count`,
    getTicketsInfoCounts: `${agentsInbox}tickets/capsule-form-count`,
    getMessageTagsTicketsCount: `${agentsInbox}tickets/message-tags`,
    getTickets: `${agentsInbox}tickets/`,
    getTicketDetails: (ticketId) => `${agentsInbox}ticket/${ticketId}`,
    getTicketInfo: (ticketId) => `${agentsInbox}ticket/${ticketId}/info`,
    getTicketPostFormCollections: (ticketId) =>
        `${agentsInbox}ticket/${ticketId}/post-form-collection`,
    getTicketCannedReponses: (ticketId) =>
        `${agentsInbox}ticket/${ticketId}/canned-responses`,
    getMessageTagsByClassification: `${agentsInbox}tickets/message-tags/by-classification/`,
    getTicketMessages: (ticketId) => ticketId ? `${agentsInbox}messages/${ticketId}` : ``,
    createTicketComment: `${agentsInbox}ticket/comment`,
    getTicketComments: (ticketId) =>
        `${agentsInbox}ticket/${ticketId}/comments`,
    ticketComment: (commentId) => `${agentsInbox}ticket/comment/${commentId}`,
    resolveTicketComment: (commentId) =>
        `${agentsInbox}ticket/comment/${commentId}/resolved`,
    sendExternalEmail: `${agentsInbox}ticket/external-email`,
    externalEmail: (ticketId) =>
        `${agentsInbox}ticket/${ticketId}/external-email`,
    addTicketInfo: `${agentsInbox}ticket/save-info`,
    removeTicketInfo: (ticketId, ticketInfoId) =>
        `${agentsInbox}ticket/${ticketId}/info/${ticketInfoId}`,
    resolveTicket: (ticketId) => `${agentsInbox}ticket/${ticketId}/resolve`,

    // ANALYTICS
    getMostFrequentConvos: `${agentsInbox}ticket/analytics/conversations`,
    getTicketVolumeAnalytics: `${agentsInbox}ticket/analytics/volume`,
    getTicketsBacklogAnalytics: `${agentsInbox}ticket/analytics/backlogs`,
    getTicketsAnalyticsRatings: `${agentsInbox}ticket/analytics/ratings`,
    getTicketsResponseTimeAnalytics: `${agentsInbox}ticket/analytics/responses`,
    getFrequentTicketCapsules: `${agentsInbox}ticket/analytics/capsules`,

    getConversationTicketActivity: (conversationId) =>
        `${agentsInbox}ticket/recent-activity/${conversationId}/conversation`,
    getCapsuleConversationTicketActivity: (capsuleEntryId) =>
        `${agentsInbox}ticket/capsule/${capsuleEntryId}/conversation-analytics`,

    /**
     *
     * K-SAM
     */
    //PRODUCTS AND CLASSES ENDPOINTS
    allWorkspaceProducts: `${kSam}products`,
    addWorkspaceProduct: `${kSam}product`,
    getCapsuleClasses: `${kSam}class-capsules`,
    getAllCapsuleBranches: (capsuleClassId, capsuleEntryId) =>
        `${kSam}capsule-status/branch/${capsuleClassId}/${capsuleEntryId}`,
    getExactCapsuleClass: (capsuleClassId) =>
        `${kSam}class-capsule/${capsuleClassId}`,
    capsuleClassesContentType: `${kSam}class-capsule/content-type`,
    createCapsuleClass: `${kSam}class-capsule`,
    createCapsuleContentBlock: `${kSam}class-capsule/block-content`,
    getCapsuleContentBlocks: (capsuleClassId, capsuleEntryId) =>
        `${kSam}class-capsule/block-contents/${capsuleClassId}/${capsuleEntryId}`,
    capsuleContentBlock: (contentId) =>
        `${kSam}class-capsule/block-content/${contentId}`,
    getCapsuleClass: (capsuleClassId) =>
        `${kSam}class-capsule/${capsuleClassId}`,
    relateCapsules: `${kSam}class-capsules/relate`,
    deleteAttachedCapsules: (parentId, childId) =>
        `${kSam}class-capsules/relate/${parentId}/${childId}`,
    getCapsuleClassEntries: (capsuleClassId) =>
        `${kSam}class-capsule/entries/${capsuleClassId}`,
    getCapsuleStatusMap: (collectionName, capsuleEntryId, type) =>
        `${kSam}capsules/${collectionName}/${capsuleEntryId}/status-map?type=${type}`,
    getSolutionCollections: `${kSam}capsules/solution-capsules/collections`,
    updateCapsuleStatus: (capsuleClassId, capsuleEntryId) =>
        `${kSam}capsules/${capsuleClassId}/${capsuleEntryId}/status`,
    deleteCapsule: (capsuleClassId, capsuleEntryId) =>
        `${kSam}capsules/${capsuleClassId}/${capsuleEntryId}`,

    // FOLLOW UP
    getFollowUps: (capsuleEntryIds) => `${kSam}followup/${capsuleEntryIds}`,
    addFollowUpCollection: `${kSam}followup`,
    updateFollowUpCollection: (followUpId) => `${kSam}followup/${followUpId}`,
    deleteFollowUpCollection: (followUpId) => `${kSam}followup/${followUpId}`,
    getFollowUpTasks: (followUpId) => `${kSam}followup/${followUpId}/task`,
    createFollowUpTask: `${kSam}followup/task`,
    updateFollowUpTask: (followUpTaskId) =>
        `${kSam}followup/task/${followUpTaskId}`,
    deleteFollowUpTask: (followUpTaskId) =>
        `${kSam}followup/task/${followUpTaskId}`,
    updateTicketTask: (ticketId, followupId, ticketFollowupTaskId, status) =>
        `${kSam}followup/${followupId}/ticket/${ticketId}/task/${ticketFollowupTaskId}/?status=${status}`,
    scheduleTaskReminder: (
        ticketId,
        ticketFollowupTaskId,
        reminderScheduleInSeconds
    ) =>
        `${kSam}followup/reminder/ticket/${ticketId}/task/${ticketFollowupTaskId}/schedule/${reminderScheduleInSeconds}`,
    getTicketTasks: (ticketId) => `${kSam}followup/ticket/${ticketId}/tasks`,

    // BRANCH COLLLECTIONS
    relateFormPostFormCollection: `${kSam}form/relate/post-form-collections`,
    getBranchCollections: `${kSam}branch-collections`,
    getBranchCollection: (branchCollectionId) =>
        `${kSam}branch-collections/${branchCollectionId}`,
    getGeneralDowntimeBranchCollections: `${kSam}branch-collections/general-downtime-collection/branches`,
    generalDowntime: `${kSam}branch-collections/general-downtime-collection/branch/downtime`,
    generalSubdowntime: `${kSam}branch-collections/general-downtime-collection/branch/subdowntime`,
    generalUptime: `${kSam}branch-collections/general-downtime-collection/branch/uptime`,
    postFormBranchCollections: `${kSam}branch-collections/post-form-collections`,
    updatePostFormBranchCollection: (collectionId) =>
        `${kSam}branch-collections/post-form-collection/${collectionId}`,
    solutionBranchCollections: `${kSam}branch-collections/solution-collections`,
    getCollectionBranches: (branchCollectionId) =>
        `${kSam}branch-collections/${branchCollectionId}/branches`,
    createCollectionBranch: `${kSam}branch-collections/branch`,
    attachCollectionBranch: `${kSam}branch-collections/branch/attach`,
    deleteBranchCollection: (branchCollectionId) =>
        `${kSam}branch-collections/${branchCollectionId}`,
    removeBranchFromCollection: (branchCollectionId, branchId) =>
        `${kSam}branch-collections/${branchCollectionId}/branch/${branchId}/unattached`,
    destroyBranch: (branchId) => `${kSam}branch/${branchId}`,
    getBranchConvoCollections: (branchId) =>
        `${kSam}branch/${branchId}/conversations-collections`,
    getCapsulesDowntimebranch: `${kSam}downtime-branches`,
    getCapsuleDowntimeGuideSteps: `${kSam}/downtime-guide-steps`,

    // CONVERSATION BREAKERS
    getConversationBreakers: `${kSam}branches/action-branch`,
    conversationBreaker: (actionBranchId) =>
        `${kSam}branches/action-branch/${actionBranchId}`,
    createConversationBreakerOptions: (actionBranchId) =>
        `${kSam}branches/action-branch/${actionBranchId}/option`,
    conversationBreakerOption: (actionBranchId, actionBranchOptionId) =>
        `${kSam}branches/action-branch/${actionBranchId}/option/${actionBranchOptionId}`,

    // LLM Documentation
    fetchQaDocumentation: `${kSam}qa-documentations`,
    createQaDocumentation: `${kSam}qa-documentation`,
    contextAwareMessage: `${kSam}qa-documentation/context-aware-chat`,
    deleteQaDocument: (qaDocumentId) => `${kSam}qa-documentation/remove/${qaDocumentId}`,
    updateQaDocument: (qaDocumentId) => `${kSam}qa-documentation/edit/${qaDocumentId}`,
    //ISSUES ENDPOINTS
    issues: `${kSam}issues`, //get
    issue: `${kSam}issue`,
    investigateIssueMessage: `${kSam}issue/investigate-message`,
    issuesConversation: `${kSam}issue/conversations`,
    getDiscoveries: `${kSam}issues/discovery`,
    deleteIssue: (issueId) => `${kSam}issue/remove/${issueId}`,
    updateIssue: (issueId) => `${kSam}issue/edit/${issueId}`,
    deleteIssuesConversation: (conversationId) =>
        `${kSam}conversation/${conversationId}`,
    getIssuesConversation: (conversationId) =>
        `${kSam}conversation/${conversationId}`,
    updateConversationStatus: (conversationId) =>
        `${kSam}conversations/${conversationId}/status`,
    issueDiscovery: (issueId) => `${kSam}issue/${issueId}/discovery`,
    createIssueVariant: (issueId) => `${kSam}issue/${issueId}/variant`,
    variant: (variantId) => `${kSam}issue/${variantId}`, //get, delete and update
    //CLASSIFICATION
    classifications: `${kSam}classification`,
    classification: (classificationId) =>
        `${kSam}classification/${classificationId}`,
    deleteClassificationElement: (
        classificationId,
        classificationIdCapsuleEntryId
    ) =>
        `${kSam}classification/${classificationId}/${classificationIdCapsuleEntryId}`,
    classificationElement: `${kSam}classification/element`,
    getUnassignedClassifications: `${kSam}classification/team/unassigned`,
    //CONVERSATIONS
    getConversations: `${kSam}conversations`,
    createConversation: `${kSam}conversation`,
    pinConversation: (conversationId) =>
        `${kSam}conversations/${conversationId}/bookmark`,
    getConversationBranches: (conversationId) =>
        `${kSam}conversations/${conversationId}/branches`,
    getConversationTree: (conversationId) =>
        `${kSam}conversations/${conversationId}/branch-tree`,
    addBranchToConversation: (conversationId, branchId) =>
        `${kSam}conversations/${conversationId}/branch/${branchId}`,
    removeBranchFromConversation: (conversationId, branchId) =>
        `${kSam}conversations/${conversationId}/branch/${branchId}`,
    //BRANCHES
    getBranches: `${kSam}branches`,
    createBranch: `${kSam}branch`,
    checkBranchName: `${kSam}branch/check`,
    editBranch: (branchId) => `${kSam}branch/${branchId}`,
    getBranch: (branchId) => `${kSam}branch/${branchId}`,
    getInitialBranch: `${kSam}branch/initial-branch`,
    deleteBranchImage: (branchId) => `${kSam}branch/image/${branchId}`,
    //OPTIONS
    createBranchOption: `${kSam}branch/option`,
    editBranchOption: (branchId, branchOptionId) =>
        `${kSam}branch/option/${branchId}/${branchOptionId}`,
    deleteBranchOption: (branchId, branchOptionId) =>
        `${kSam}branch/${branchId}/${branchOptionId}`,
    updateOptionsOrder: `${kSam}branchOptions/update-order`,

    // TEAM
    createTeam: `${accountService}team`,
    teams: `${accountService}teams`,
    team: (teamId) => `${accountService}team/${teamId}`,
    customRule: (teamId) => `${accountService}team/${teamId}/custom-rules`,
    ticketRouting: (teamId, userId) =>
        `${accountService}team/${teamId}/ticket-routing/${userId}`,
    removeMember: (teamId, userId) =>
        `${accountService}team/${teamId}/remove/${userId}`,
    // DEPENDENCY
    downtimeBranch: `${kSam}downtime-branch`,
    createSubDowntimeBranch: `${kSam}sub-downtime-branches`,
    createUptimeBranch: `${kSam}uptime-branches`,

    getDowntimeBranches: (capsuleId, capsuleEntryId) =>
        `${kSam}flows/downtime-branches/${capsuleId}/${capsuleEntryId}`,
    createDependency: `${kSam}dependency`,
    dependency: (dependencyId) => `${kSam}dependency/${dependencyId}`,
    getDependency: `${kSam}dependency`,
    getFeatureCount: `${kSam}feature-status/count`,
    updateDependencyStatus: (dependencyId) =>
        `${kSam}dependency/status/${dependencyId}`,
    getDependencyStatusMap: (dependencyId, type) =>
        `${kSam}dependency/${dependencyId}/status-map?type=${type}`,
    //FORMS
    getForms: `${kSam}forms`,
    deleteForm: (formId) => `${kSam}form/${formId}`,
    addCannedResponseToForm: (formId) =>
        `${agentsInbox}messaging-tags/match-form/${formId}/canned-response`,
    getFormRule: (elementType) => `${kSam}form-rule?elementType=${elementType}`,
    createForm: `${kSam}form`,
    createFormBranch: `${kSam}form-branch`,
    getForm: (formId) => `${kSam}form/${formId}`,
    getTicketFilledForm: (ticketId, formId) =>
        `${kSam}forms/filled-record/${ticketId}/${formId}`,
    fileUpload: `${kSam}file-upload`,
    updateForm: (formId) => `${kSam}form/${formId}`,

    // AGENT ASSISTANCE
    getGuides: `${kSam}agent-guides`,
    getFollowupGuides: (capsules) => `${kSam}agent-guides/capsules/${capsules}`,
    attachFollowupGuide: `${kSam}agent-guide/capsule-entry`,
    createGuide: `${kSam}agent-guide`,
    updateGuide: (guideId) => `${kSam}agent-guide/edit/${guideId}`,
    deleteGuide: (guideId) => `${kSam}agent-guide/remove/${guideId}`,
    getGuide: (guideId) => `${kSam}agent-guide/${guideId}/steps`,
    getGuideFlows: `${kSam}guide-flows`,
    getGuideFlow: (guideFlowId) => `${kSam}guide-flow/${guideFlowId}`,
    getGuideConnectedStepInfo: (ticketOptionId) =>
        `${kSam}agent-guide/ticket-option/${ticketOptionId}`,
    createGuideBranch: (guideId) => `${kSam}agent-guide/${guideId}/step`,
    editGuideBranch: (guideFlowId) => `${kSam}guide-flow/${guideFlowId}/edit`,
    createGuideFlow: (guideId) => `${kSam}guide-flow/${guideId}`,
    deleteGuideFlow: (guideFlowId) => `${kSam}guide-flow/${guideFlowId}/delete`,
    updateGuideScenario: (guideFlowId) =>
        `${kSam}guide-flow/${guideFlowId}/scenairos`,
    attachResourceToGuideFlow: (guideFlowId) =>
        `${kSam}guide-flow/${guideFlowId}/attach/canned-resource`,
    attachUpdateToGuideFlow: (guideFlowId, cannedResourceId, agentUpdateId) =>
        `${kSam}guide-flow/${guideFlowId}/attach-canned-resource/${cannedResourceId}/agent-update/${agentUpdateId}`,
    detachCannedGuideFlow: (guideFlowId, cannedResourceId) =>
        `${kSam}guide-flow/${guideFlowId}/detach/${cannedResourceId}/canned-resource`,
    updateGuideFlowOption: (guideFlowId, guideFlowOptionId) =>
        `${kSam}guide-flow/${guideFlowId}/edit-option/${guideFlowOptionId}`,
    createGuideFlowOption: (guideFlowId) =>
        `${kSam}guide-flow/${guideFlowId}/create-option`,
    deleteGuideFlowOption: (guideFlowOptionId) =>
        `${kSam}guide-flow/${guideFlowOptionId}/delete-option`,
    getScenarios: `${kSam}guide-scenairos`,
    createScenario: `${kSam}guide-scenairo`,
    updateScenario: (guideScenarioId) =>
        `${kSam}guide-scenairo/${guideScenarioId}/edit`,
    deleteScenario: (guideScenarioId) =>
        `${kSam}guide-scenairo/remove/${guideScenarioId}`,
    detachGuideScenairoCollection: (guideFlowId, branchCollectionId) => `${kSam}guide-flow/${guideFlowId}/detach/${branchCollectionId}/branch-collection/`,
    createCannedResource: `${kSam}canned-resource`,
    getCannedResources: `${kSam}canned-resources`,
    editCannedResource: (cannedResourceId) =>
        `${kSam}canned-resource/${cannedResourceId}`,
    deleteCannedResource: (cannedResourceId) =>
        `${kSam}canned-resource/${cannedResourceId}/delete`,
    getCannedUpdates: `${kSam}agent-update`,
    createCannedUpdate: `${kSam}agent-update`,
    cannedUpdate: (agentUpdateId) => `${kSam}agent-update/${agentUpdateId}`,
    getUpdateTasks: (agentUpdateId) =>
        `${kSam}agent-update/${agentUpdateId}/tasks`,
    createUpdateTask: `${kSam}agent-update/task`,
    updateTask: (agentUpdateTaskId) =>
        `${kSam}agent-update/task/${agentUpdateTaskId}`,

    getGuideDowntimes: (capsuleId, classCapsuleEntryId) =>
        `${kSam}flows/downtime-guide-steps/${capsuleId}/${classCapsuleEntryId}`,

    getCapsuleGuideSteps: (capsuleId, classCapsuleEntryId) =>
        `${kSam}guide-flows/guide-steps/${capsuleId}/${classCapsuleEntryId}`,

    createDowntimeStep: `${kSam}downtime-step`,
    createUptimeStep: `${kSam}uptime-step`,
    createGuideFlowUpdate: (guideFlowId) =>
        `${kSam}guide-flow/${guideFlowId}/create-update`,
    getTicketGuide: (ticketId) => `${kSam}agent-guide/ticket/${ticketId}`,
    investigateTicketGuide: `${kSam}agent-guide/investigate-message`,
    investigateGuideScenario: `${kSam}guide-scenairo/investigate-message`,
    startTicketGuide: (agentGuideId, ticketId) =>
        `${kSam}agent-guide/start/${agentGuideId}/ticket/${ticketId}`,
    reverseTicketGuideSelection: (ticketId, ticketAgentGuideOptionId) =>
        `${kSam}/agent-guide/reverse-ticket-selection/${ticketId}/${ticketAgentGuideOptionId}`,
    proposeScenario: (guideFlowId) =>
        `${kSam}guide-flow/${guideFlowId}/propose-scenairo`,
    selectGuideNextStep: (
        ticketAgentGuideOptionId,
        ticketId,
        guideFlowOptionId
    ) =>
        `${kSam}agent-guide/select/${ticketAgentGuideOptionId}/ticket/${ticketId}/option/${guideFlowOptionId}`,
    updateTicketAgentTask: (ticketUpdateTaskId, taskStatus) =>
        `${kSam}ticket-agent-update/${ticketUpdateTaskId}/status?action=${taskStatus}`,
    updateTicketAgentUpdateTask: (ticketUpdateTaskId, taskStatus) =>
        `${kSam}ticket-agent-update-update/${ticketUpdateTaskId}/status?action=${taskStatus}`,
    //APP MESSAGE USER
    personas: `${agentsInbox}app-message-user/public-agents`,
    updatePersonaStatus: (agentId) =>
        `${agentsInbox}app-message-user/public-agents/${agentId}/active`,
    persona: (agentId) =>
        `${agentsInbox}app-message-user/public-agents/${agentId}`,
    mediaUpload: `agents-inbox/file-upload/file-upload`,
    generateUploadLink: `agents-inbox/file-upload/upload-url`,
    getFileLink: `agents-inbox/file-upload/file-url`,
    formCannedResponses: `${agentsInbox}messaging-tags/canned-responses/forms`,

    // --- VET AGENT TICKETS
    getVetableTickets: `${agentsInbox}tickets/vetable-tickets`,
    pinVetableTicket: (ticketId, pinned) =>
        `${agentsInbox}ticket/${ticketId}/pin?pinned=${pinned}`,
    getAgentSuggestions: (agentId, duration) =>
        `${agentsInbox}tickets/agents-vetable-conversation?analyticsRequestDuration=${duration}&agentId=${agentId}&pageSize=10&currentPage=1`,
    setTicketClipboard: (ticketId) =>
        `${agentsInbox}ticket/${ticketId}/set-note`,
};
