import asset from "assets/images";
import "./CustomDropdown.scss";

const CustomDropdown = ({
    show,
    setShow,
    selected,
    placeholder,
    ddOptionsClass,
    options,
    noItemMsg,
    handleChange,
    className,
    size,
    ddValueClass,
    anchor,
    optClass,
    labelClass,
}) => {
    return (
        <>
            <div
                className={`position-relative custom-dropdown ${
                    className || ""
                }`}
                onClick={(e) => e.stopPropagation()}>
                <div
                    className={`selected cursor-pointer d-flex justify-content-between align-items-center selected--${size} selected--${
                        ddValueClass || ""
                    }`}
                    onClick={() => setShow(!show)}>
                    <span
                        className={`selected__label ${
                            labelClass ? labelClass : ""
                        }`}>
                        {selected?.label || placeholder}{" "}
                    </span>
                    {anchor ? (
                        <img
                            className='anchor'
                            src={asset?.images?.anchorDown}
                            alt='dropdown'
                        />
                    ) : null}
                </div>
                {show ? (
                    <div
                        className={`dropdown-box position-absolute options w-100 options--${
                            ddOptionsClass || ""
                        }`}>
                        {options?.length ? (
                            options?.map((item, i) => (
                                <p
                                    className={`mb-0 text-dark cursor-pointer opt opt--${
                                        optClass || ""
                                    } ${
                                        item?.value === selected?.value
                                            ? "opt--selected"
                                            : ""
                                    }`}
                                    key={i}
                                    onClick={() => handleChange(item)}>
                                    {item?.label}
                                </p>
                            ))
                        ) : (
                            <p className='mb-0 text-dark opt'>{noItemMsg}</p>
                        )}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default CustomDropdown;
