import { useEffect, useState } from "react";
import { Tag } from "components/ui";

export const InputMultiple = ({
    name,
    id,
    label,
    labelClass,
    grpClass,
    inputClass,
    type,
    placeholder,
    setInputValue,
    getSuggestions,
    suggestions,
    isErr,
    isValid,
    inputValue = [],
    errMssg,
    extraMssg,
    isLoading,
    disabled,
    autoFocus,
}) => {
    const [selected, setSelected] = useState(inputValue);
    const [value, setValue] = useState("");

    const handleKeyPress = (e) => {
        if (e.key?.toLowerCase() === "enter") {
            e.preventDefault();
            addData(e);
        }
    };

    const addData = (e) => {
        if (e?.target?.value) {
            if (suggestions) {
                getSuggestions?.();
            } else {
                setSelected([...selected, e.target.value]);
                setInputValue?.(name, [...selected, e.target.value]);
                setValue("");
            }
        }
    };

    const handleSuggestionClick = (item) => {
        setSelected([...selected, item]);
    };

    const removeItem = (index) => {
        const newData = selected?.filter((item, i) => i !== index);

        setSelected(newData);
        setInputValue?.(name, newData);
    };

    useEffect(() => {
        if (inputValue.length) {
            setSelected(inputValue);
        }
    }, [inputValue]);

    return (
        <div className={`form-group input-multiple ${grpClass || ""}`}>
            <label
                htmlFor={id}
                className={`form-label ${labelClass || ""} ${
                    disabled ? "text-muted" : ""
                }`}>
                {label}
            </label>
            <input
                type={type}
                name={name}
                id={id}
                className={`form-control ${
                    isLoading
                        ? "is-loading"
                        : isErr
                        ? "is-invalid"
                        : isValid
                        ? "is-valid"
                        : ""
                } ${inputClass || ""}`}
                data-field='multiple'
                placeholder={placeholder}
                value={value}
                onKeyDown={handleKeyPress}
                onBlur={addData}
                onChange={({ target: { value } }) => setValue(value)}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            {extraMssg ? (
                <div className='text-muted extra-input-mssg'>{extraMssg}</div>
            ) : null}
            <div className='invalid-feedback'>{errMssg}</div>
            {suggestions?.length ? (
                <div className='d-flex flex-column flex-gap--4 p-4 mt-1 input-multiple__bordered-box'>
                    {suggestions?.map((item, i) => (
                        <p
                            key={i}
                            className='m-0 text-dark cursor-pointer'
                            onClick={() => handleSuggestionClick(item)}>
                            {item}
                        </p>
                    ))}
                </div>
            ) : null}
            {selected?.length ? (
                <div className='d-flex align-items-start flex-wrap flex-gap p-3 mt-1 input-multiple__bordered-box'>
                    {selected?.map((item, i) => (
                        <Tag
                            key={i}
                            classType='default-cursor'
                            className='text-dark'
                            name={typeof item === "object" ? item?.name : item}
                            onCancel={() => removeItem(i)}
                            cancel
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
