import React from "react";
import { Link } from "react-router-dom";
import "./FormBuilderBranchInstruction.scss";

const FormBuilderBranchInstruction = ({
    branch,
    errors,
    elementId,
    branchId,
}) => {
    return (
        <div className='formbuilder__branch'>
            {!branchId ? (
                <div className='empty__branch'>
                    <span>Branch will appear here</span>
                </div>
            ) : (
                <Link to={{
                    pathname: '/knowledge-base/form/concluding-branch',
                    state: {
                        branchId
                    }
                }}>
                    <div className='added__branch'>
                        <span>{branch?.label || branch?.branchName}</span>
                    </div>
                </Link>
            )}
            <div className='invalid-feedback d-block'>
                <span>{errors?.branch}</span>
            </div>
        </div>
    );
};

export default FormBuilderBranchInstruction;
