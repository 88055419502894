import React from "react";
import { SideModal } from "components/ui";
import CapsuleClassName from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleClassName/CapsuleClassName";
import AddCapsuleEntryForm from "../AddCapsuleEntryModal/AddCapsuleEntryForm/AddCapsuleEntryForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const EditCapsuleEntryModal = ({
    showModal,
    closeModal,
    mainCapsuleClass,
    selectedProduct,
    handleCapsulationSuccess,
    capsuleAction,
    additionRequestObj,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            <>
                                Edit{" "}
                                <CapsuleClassName capsule={mainCapsuleClass} />
                            </>
                        }
                        subTitle={
                            <>
                                Edit{" "}
                                <CapsuleClassName capsule={mainCapsuleClass} />{" "}
                                capsule
                            </>
                        }
                    />
                    <AddCapsuleEntryForm
                        additionRequestObj={additionRequestObj}
                        mainCapsuleClass={mainCapsuleClass}
                        handleSuccess={handleCapsulationSuccess}
                        closeModal={closeModal}
                        capsuleAction={capsuleAction}
                        selectedProduct={selectedProduct}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default EditCapsuleEntryModal;
