import "./ClassesContainer.scss";

const ClassesContainer = ({ extendHeight, children }) => {
    return (
        <div
            className={`classes__container ${
                extendHeight ? "extend__container__height" : ""
            }`}>
            {children}
        </div>
    );
};

export default ClassesContainer;
