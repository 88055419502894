import { useState, useEffect, useContext } from "react";
import ProductClassesContainer from "../../ProductSetupContainer/ProductDashboard/ProductClassesContainer/ProductClassesContainer";
import { primaryCapsuleCodes } from "components/dashboard/KnowledgeBase/common/Capsule/dictionary/capsuleCodes";
import {
    getCapsulesRelatedEntries,
    getCapsuleEntriesAggregate,
} from "../../common/Capsule/lib";
import SolutionSetupPlayGround from "./SolutionSetupPlayGround/SolutionSetupPlayGround";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import "./SolutionSetupView.scss";

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;
const { SOLUTION, ROADBLOCK, FAQ, PROCESS_FAIL } = primaryCapsuleCodes;

const SolutionSetupView = ({ selectedProduct }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [capsuleRenderCount] = useState(1);
    const [capsulesEntries, setCapsuleEntries] = useState([]);
    const [showPlayground, togglePlayGround] = useState(false);
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState({});
    const [selectedSecCapsuleClass, setSelectedSecCapsuleClass] = useState();
    const [solutionCollections, setSolutionCollections] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, updatePageFilters] = useState({
        currentPage: 1,
        pageSize: 10,
        offset: 0,
        alphabeticalOrder: null,
        dateCreatedOrder: null,
        search: "",
    });

    const [searchIsLoading, setSearchIsLoading] = useState(false);

    const filterWithMainCapsuleCodes = [ROADBLOCK, FAQ, PROCESS_FAIL];
    const filterWithSecCapsuleCodes = [SOLUTION];
    const toastMessage = useContext(ToastContext);

    const handleMainCapsuleSelect = async (capsule) => {
        try {
            setStatus(LOADING);
            const secondaryCapsule = [
                ...capsule.parents,
                ...capsule.children,
            ]?.find((item) => item.capsuleCode === SOLUTION);

            setSelectedCapsuleClass(capsule);
            setSelectedSecCapsuleClass(secondaryCapsule);

            const isParental = capsule?.parents?.some(
                (item) =>
                    item.capsuleClassId === secondaryCapsule?.capsuleClassId
            );

            togglePlayGround(true);

            const capsuleEntries = await getCapsulesRelatedEntries(
                capsule,
                secondaryCapsule,
                selectedProduct?.value,
                isParental,
                filters?.pageSize,
                filters?.offset,
                filters?.alphabeticalOrder,
                filters?.dateCreatedOrder,
                filters?.search
            );

            const total = await getCapsuleEntriesAggregate(
                capsule,
                selectedProduct?.value
            );

            setTotalRecords(total);
            setCapsuleEntries(capsuleEntries);
            setSearchIsLoading(false);
            capsuleEntries.length > 0
                ? setStatus(DATAMODE)
                : setStatus(NULLMODE);
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(selectedCapsuleClass).length > 0) {
            handleMainCapsuleSelect(selectedCapsuleClass);
        }
        // eslint-disable-next-line
    }, [filters]);

    const getSolutionCollections = async () => {
        try {
            const url = apiRoute?.getSolutionCollections;
            const res = await API.get(url, {
                params: {
                    productId: selectedProduct?.productId,
                },
            });
            if (res.status === 200) {
                const { capsuleCollections } = res.data.data || {};
                setSolutionCollections(capsuleCollections);
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleCollectionDelete = (classCapsuleEntryId) => {
        const newCollectionList = solutionCollections?.filter(
            (x) => x.classCapsuleEntryId !== classCapsuleEntryId
        );
        setSolutionCollections(newCollectionList);
    };

    useEffect(() => {
        getSolutionCollections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct]);

    const handleSearchEntries = (searchKeyword) => {
        setSearchIsLoading(true);
        updatePageFilters({ ...filters, search: searchKeyword });
    };

    return (
        <>
            <div className='solution__setup__view meta__container'>
                <div className='container-xxl'>
                    <ProductClassesContainer
                        selectedProduct={selectedProduct}
                        selectedCapsuleClass={selectedCapsuleClass}
                        selectedSecCapsuleClass={selectedSecCapsuleClass}
                        setSelectedCapsuleClass={handleMainCapsuleSelect}
                        setSelectedSecCapsuleClass={() => ""}
                        capsuleRenderCount={capsuleRenderCount}
                        useClassesFilter={true}
                        filterWithMainCapsuleCodes={filterWithMainCapsuleCodes}
                        filterWithSecCapsuleCodes={filterWithSecCapsuleCodes}
                    />

                    {showPlayground && (
                        <SolutionSetupPlayGround
                            capsulesEntries={capsulesEntries}
                            selectedCapsuleClass={selectedCapsuleClass}
                            selectedSecCapsuleClass={selectedSecCapsuleClass}
                            selectedProduct={selectedProduct}
                            solutionCollections={solutionCollections}
                            handleCollectionDelete={handleCollectionDelete}
                            status={status}
                            error={errorMssg}
                            totalRecords={totalRecords}
                            filters={filters}
                            updatePageFilters={updatePageFilters}
                            handleSearchEntries={handleSearchEntries}
                            searchIsLoading={searchIsLoading}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default SolutionSetupView;
