import { useState } from "react";
import { useSelector } from "react-redux";
import TicketInfoCapsuleDetail from "../../TicketInfoDetails/TicketInfoDetailsElement/TicketInfoCapsuleDetail/TicketInfoCapsuleDetail";
import TicketInfoCapsule from "components/dashboard/KnowledgeBase/common/Capsule/TicketInfoCapsule/TicketInfoCapsule";
import TicketAACardHeading from "../common/TicketAACardHeading/TicketAACardHeading";
import "./TicketAAGuideCapsule.scss";

const TicketAAGuideCapsule = ({ capsuleClassId, capsuleEntryId }) => {
    const [open, toggleOpen] = useState(false);
    const { capsuleClasses } = useSelector((state) => state.capsule);

    const capsule = capsuleClasses?.find(
        (x) => x.capsuleClassId === capsuleClassId
    );

    const isCapsuleAvailable =
        Boolean(capsuleClassId) && Boolean(capsuleEntryId);

    return (
        <>
            {isCapsuleAvailable && (
                <section className='ticketaa-guide-capsule'>
                    <TicketAACardHeading
                        title={
                            <TicketInfoCapsule
                                capsule={capsule}
                                capsuleEntryId={capsuleEntryId}
                                // isActive={true}
                                useAsTag={true}
                                setBorderColor={true}
                            />
                        }
                        onClick={() => toggleOpen(!open)}
                        isOpen={open}
                    />

                    {open && (
                        <TicketInfoCapsuleDetail
                            capsule={capsule}
                            capsuleEntryId={capsuleEntryId}
                            hideRelateCapsuleInfo={true}
                        />
                    )}
                </section>
            )}
        </>
    );
};

export default TicketAAGuideCapsule;
