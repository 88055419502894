import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { chartLayout } from "./enum";
import "./PlottedBarChart.scss";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import { filterDataWithEmptyBars } from "utils/helper";
import { getReformattedClassificationStrokes } from "../../helpers";

const { HORIZONTAL } = chartLayout;

const PlottedBarChart = ({
    chartMode,
    chartData,
    strokeData,
    yAxisLabel,
    xAxisLabel,
    chartTitle,
    timeMetric,
    xDataKey1,
    yDataKey1,
    xDataKey2,
    yDataKey2,
    xDomain1,
    xDomain2,
    yDomain1,
    yDomain2,
    hasFreqKey,
    roundUpTooltipVal,
    allStrokeData,
    customUnit1,
    customUnit2,
    useAIAssistance,
    useDefaultOptions,
    tooltipExtraMetrics,
    hasTicketInfo,
}) => {
    // based on the chart layout
    const isHorizontalChart = chartMode === HORIZONTAL;

    const chartStrokeData = hasTicketInfo
        ? getReformattedClassificationStrokes(strokeData)
        : strokeData;
    const chartAllStrokeData = hasTicketInfo
        ? getReformattedClassificationStrokes(allStrokeData)
        : allStrokeData;

    let newChartData = filterDataWithEmptyBars(
        chartData,
        !isHorizontalChart,
        useAIAssistance
    );

    return (
        <div className='plotted__bar__chart__container'>
            <div className='y-axis graph__label'>{yAxisLabel}</div>
            <div className='plotted__bar__chart'>
                <ResponsiveContainer
                    width='100%'
                    height='100%'
                    isAnimationActive>
                    <BarChart
                        width={500}
                        height={300}
                        data={newChartData}
                        layout={isHorizontalChart ? "horizontal" : "vertical"}>
                        <CartesianGrid
                            strokeDasharray='0.1 0.1'
                            vertical={!isHorizontalChart}
                            horizontal={isHorizontalChart}
                        />
                        <XAxis
                            dataKey={isHorizontalChart ? xDataKey1 : xDataKey2}
                            type={isHorizontalChart ? "category" : "number"}
                            axisLine={false}
                            domain={isHorizontalChart ? xDomain1 : xDomain2}
                        />
                        <YAxis
                            dataKey={isHorizontalChart ? yDataKey1 : yDataKey2}
                            type={isHorizontalChart ? "number" : "category"}
                            axisLine={false}
                            // reversed={!isHorizontalChart}
                            domain={isHorizontalChart ? yDomain1 : yDomain2}
                        />
                        <Tooltip
                            cursor={{ fill: "transparent" }}
                            wrapperStyle={{ visibility: "visible" }}
                            content={
                                <ChartTooltip
                                    {...{
                                        customUnit1,
                                        customUnit2,
                                        timeMetric,
                                        isHorizontalChart,
                                        chartTitle,
                                        roundUpTooltipVal,
                                        strokeData: chartAllStrokeData,
                                        showAll: chartStrokeData?.length === 0,
                                        useAIAssistance,
                                        useDefaultOptions,
                                        tooltipExtraMetrics,
                                        hideZeroMetrics: hasTicketInfo,
                                    }}
                                />
                            }
                            shared={false}
                        />

                        {(chartStrokeData?.length > 0
                            ? [...chartStrokeData]
                            : [...chartAllStrokeData]
                        )?.map?.(({ label, value, color }, index) => (
                            <Bar
                                key={index}
                                dataKey={
                                    !hasFreqKey
                                        ? useAIAssistance
                                            ? `${value}_AI`
                                            : value
                                        : isHorizontalChart
                                        ? useAIAssistance
                                            ? `${value}_AI`
                                            : value
                                        : useAIAssistance
                                        ? `${value}_AI_FREQ`
                                        : `${value}_FREQ`
                                }
                                fill={
                                    chartStrokeData?.length > 0
                                        ? color
                                        : chartAllStrokeData[
                                              chartAllStrokeData?.length - 1
                                          ]?.color
                                }
                                name={label}
                                barSize={60}
                                // barSize={205} maxBarSize={500}
                                stackId={
                                    chartStrokeData?.length === 0 ? "a" : null
                                }
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className='x-axis graph__label'>{xAxisLabel}</div>
        </div>
    );
};

export default PlottedBarChart;
