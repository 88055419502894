import React, { useState } from "react";
import { Table } from "components/ui";
import { ROLES_PERMS_HEADERS } from "../enums";
import CreateRoleButton from "../buttons/CreateRoleButton/CreateRoleButton";
import RolesAndPermissionsModals from "./RolesAndPermissionsModals/RolesAndPermissionsModals";
import RolesAndPermissionsDropdown from "./RolesAndPermissionsDropdown/RolesAndPermissionsDropdown";
import RoleModulesTags from "./RoleModulesTags/RoleModulesTags";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./RolesAndPermissionsContent.scss";

const { USER_MANAGEMENT_ROLES } = baseResources;
const { CREATE } = baseResourceActions;

const RolesAndPermissionsContent = ({
    roles,
    handleSuccess,
    tableIsLoading,
    errorMssg,
    handleSearch,
    searchIsLoading,
    clearSearch,defaultValue
}) => {
    const [currentAction, setCurrentAction] = useState("");
    const [currentRole, setCurrentRole] = useState({});

    const handleActionModal = (action, role) => {
        setCurrentAction(action);
        setCurrentRole(role);
    };

    const closeActionModal = () => {
        setCurrentAction("");
        setCurrentRole("");
    };

    const tableData = roles?.map((role) => ({
        roleName: `${role.displayName}`,
        modules: <RoleModulesTags permissions={role.permissions} />,
        action: (
            <RolesAndPermissionsDropdown
                role={role}
                handleActionModal={handleActionModal}
            />
        ),
    }));

    return (
        <>
            <div className='role__permission--action'>
                <PaginationSearch
                    handleSearch={handleSearch}
                    placeholder='Search for role name'
                    isLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={defaultValue}
                />
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${USER_MANAGEMENT_ROLES}`]}>
                    <CreateRoleButton handleActionModal={handleActionModal} />
                </ComponentAccessControl>
            </div>

            {tableData?.length > 0 && (
                <Table
                    headers={ROLES_PERMS_HEADERS}
                    dataSource={tableData}
                    isLoading={tableIsLoading}
                    hasErrors={Boolean(errorMssg)}
                    tableError={errorMssg}
                />
            )}

            {currentAction && (
                <RolesAndPermissionsModals
                    role={currentRole}
                    currentAction={currentAction}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

export default RolesAndPermissionsContent;
