import { ReactSVG } from "react-svg";
import "./AnalyticsQADropHeader.scss";
import asset from "assets/images";

const AnalyticsQADropHeader = ({ title, isOpen, toggleOpen }) => {
    return (
        <div className='analytics-qa-dropHeader'>
            <h5>{title}</h5>
            <ReactSVG
                src={isOpen ? asset.icons.upside : asset.icons.downside}
                onClick={toggleOpen}
            />
        </div>
    );
};
export default AnalyticsQADropHeader;
