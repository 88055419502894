import React from "react";
import "./HeaderInfo.scss";

const HeaderInfo = ({ info, children, otherClassName }) => {
    return (
        <div className={`header__info ${otherClassName ? otherClassName : ""}`}>
            {info ? info : children}
        </div>
    );
};

export default HeaderInfo;
