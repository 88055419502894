import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { POST_FORM_MODAL_ACTIONS } from "../../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_COLLECTION, KB_FORM_SETTING } = baseResources;
const { READ, UPDATE, DELETE } = baseResourceActions;
const {
    VIEW_POST_FORM_COLLECTION,
    EDIT_POST_FORM_COLLECTION,
    DELETE_POST_FORM_COLLECTION,
} = POST_FORM_MODAL_ACTIONS;

const PostFormCollectionActionDropdown = ({
    postFormCollection,
    handleActionModal,
}) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={imageLinks?.icons?.carbonMore}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) =>
                handleActionModal(value, postFormCollection)
            }
            options={[
                {
                    value: VIEW_POST_FORM_COLLECTION,
                    label: "View",
                    baseResources: [
                        `${READ}_${KB_FORM_SETTING}`,
                        `${READ}_${KB_SAM_COLLECTION}`,
                    ],
                },
                {
                    value: EDIT_POST_FORM_COLLECTION,
                    label: "Edit",
                    baseResources: [
                        `${UPDATE}_${KB_FORM_SETTING}`,
                        `${UPDATE}_${KB_SAM_COLLECTION}`,
                    ],
                },
                {
                    value: DELETE_POST_FORM_COLLECTION,
                    label: "Delete",
                    baseResources: [
                        `${DELETE}_${KB_FORM_SETTING}`,
                        `${DELETE}_${KB_SAM_COLLECTION}`,
                    ],
                },
            ]}
        />
    );
};

export default PostFormCollectionActionDropdown;
