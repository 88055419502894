import PropTypes from "prop-types";

const UnassignedCapsulesHeader = ({ count }) => {
	return (
		<h1 className="fw-bold d-flex align-items-center mb-4 unassigned-capsule-header">
			Unassigned capsules
			<span className="custom-badge custom-badge--light text-dark fw-bold">
				{count}
			</span>
		</h1>
	);
};

UnassignedCapsulesHeader.prototypes = {
	count: PropTypes.number.isRequired,
};

export default UnassignedCapsulesHeader;
