import React, { useState, useEffect } from "react";
import { Button, FileInput } from "components/ui";
import { createImgFromText, dataQueryStatus, dataUrlToFile } from "utils";
import asset from "assets/images";
import "./PersonalProfileAvatar.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const { ERROR } = dataQueryStatus;

const PersonalProfileAvatar = ({ user, setImage, toastMessage }) => {
    const [avatar, setAvatar] = useState(asset?.images?.placeholder);

    useEffect(() => {
        const { imageUrl } = user || {};
        imageUrl && setAvatar(imageUrl);
        //eslint-disable-next-line
    }, [user.imageUrl]);

    const setDefaultAvatar = async () => {
        const { firstName, lastName } = user || {};
        const initials = `${firstName?.[0]}${lastName?.[0]}`;
        const defaultPhoto = await createImgFromText(initials?.toUpperCase());
        const file = await dataUrlToFile(defaultPhoto, firstName);
        setImage(file);
        setAvatar(defaultPhoto);
    };

    const handleUpload = ({ target: { files } }) => {
        const file = files[0];
        if (file && ["image/png", "image/jpeg"].includes(file.type)) {
            if (file.size > 1048576)
                toastMessage("File too large. Max of 1MB", ERROR);
            else {
                setImage(file);
                setAvatar(URL.createObjectURL(file));
            }
        } else
            toastMessage("Please upload a valid image (png/jpg only)", ERROR);
    };

    return (
        <div className='personal-profile__header'>
            <ImageView src={avatar} className='user__avatar' alt={user?.firstName} />

            <FileInput
                fileType='image/*'
                id='avatar'
                name='img'
                label='Upload New'
                labelClass='profile-file-input'
                fileValue={user?.image}
                labelName='Display picture'
                onChange={handleUpload}
            />

            <Button
                text='Use default'
                type='button'
                classType='outline-primary'
                otherClass='profile-file-input-button'
                onClick={setDefaultAvatar}
            />
        </div>
    );
};

export default PersonalProfileAvatar;
