import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiRoute } from "services";
import { Empty, Loading } from "components/ui";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import SwitchesListing from "./SwitchesListing/SwitchesListing";
import SwitchesStatusCardGroup from "./SwitchesStatusCardGroup/SwitchesStatusCardGroup";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import SwitchesListingHeader from "./SwitchesListing/SwitchesListingHeader/SwitchesListingHeader";
import {
    setSwitchesBaseFilter,
    setSwitchesFilterOption,
    setSwitchesFilterParams,
} from "store/filters/actions";

const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const SwitchesContainer = () => {
    const [dependencies, setDependencies] = useState([]);

    const [dependencyStatusCounts, setDependencyStatusCounts] = useState({});

    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();

    const [tableLoading, setTableLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.switchesFilters
    );

    const dispatch = useDispatch();

    const closeModal = () => setShowModal(false);

    const toggleModal = () => {
        setShowModal(true);
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setSwitchesFilterParams({ ...filterParams, currentPage }));
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setSwitchesFilterParams({ currentPage: 1, search }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setSwitchesBaseFilter(baseFilter));

        dispatch(setSwitchesFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);

        dispatch(
            setSwitchesFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setSwitchesFilterOption({ ...option, value }));
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);

        dispatch(
            setSwitchesFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setSwitchesFilterOption({ ...option, value }));
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);

        dispatch(
            setSwitchesFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setSwitchesFilterOption({ ...option, value }));
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <>
                        <Empty
                            text={
                                useTableLoader
                                    ? "No switch matches the search"
                                    : "No switch has been created yet."
                            }
                        />
                    </>
                );

            case DATAMODE:
                return (
                    <>
                        <SwitchesListing
                            dependencies={dependencies}
                            tableLoading={tableLoading}
                            showModal={showModal}
                            closeModal={closeModal}
                            getDependencies={() => {
                                setClearSearch(true);
                                dispatch(
                                    setSwitchesFilterParams({
                                        search: "",
                                        currentPage: 1,
                                        pageSize: 5,
                                    })
                                );
                            }}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getDependencies}
                    />
                );

            default:
                return "";
        }
    };

    const getDependencies = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader ? setTableLoading(true) : setStatus(LOADING);

            const url = apiRoute?.getDependency;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { metaData, data } = res.data;

                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setTableLoading(false);
                setStatus(newStatus);
                setDependencies(data);

                setTotalRecords(metaData?.totalRecords);
                const { offStatusCount, onStatusCount } = metaData;
                setDependencyStatusCounts({
                    onStatusCount: onStatusCount === null ? 0 : onStatusCount,
                    offStatusCount:
                        offStatusCount === null ? 0 : offStatusCount,
                });
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
            useTableLoader ? setTableLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getDependencies(useTableLoader, httpRequest);
        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams, useTableLoader]);

    return (
        <>
            <div className='padded__layout'>
                <div className='dependency'>
                    <SwitchesStatusCardGroup {...dependencyStatusCounts} />

                    <SwitchesListingHeader
                        toggleModal={toggleModal}
                        handleSearch={handleSearch}
                        searchIsLoading={searchIsLoading}
                        clearSearch={clearSearch}
                        handleSortByAlpha={handleSortByAlpha}
                        handleSortByDate={handleSortByDate}
                        handleSortByLastModified={handleSortByLastModified}
                        baseFilter={baseFilter}
                        filterOption={filterOption}
                        searchDefaultValue={filterParams?.search}
                        handleBasefilter={handleBasefilter}
                        getDependencies={getDependencies}
                        showModal={showModal}
                        closeModal={closeModal}
                    />
                    {renderBasedOnStage()}
                </div>
            </div>
        </>
    );
};

export default SwitchesContainer;
