import React, { useEffect, useState, useCallback } from "react";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import {
    appMessageUserTypes,
    messageTypes,
} from "../../ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import TMTagDetailElement from "./TMTSuggestionElement/TMTagDetail/TMTagDetailElement/TMTagDetailElement";
import TMTSuggestionElement from "./TMTSuggestionElement/TMTSuggestionElement";
import { RECEIVE_MESSAGE, SUBSCRIBE_TO_TICKET } from "services/socket/events";
import { useSocket } from "services/socket/hook";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;
const { THIRD_USER } = appMessageUserTypes;
const { DEFAULT } = messageTypes;

const TMessageTagSuggestion = ({
    selectedTicket,
    recentCustomerMessage,
    setCannedMessage,
}) => {
    const { ticketId } = selectedTicket;
    const socket = useSocket() // Access the socket from context
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [availableMTs, setAvailableMts] = useState();
    const { messageContent } = recentCustomerMessage || {};

    const getMessagingTags = async () => {
        try {
            setStatus(LOADING);
            const res = await API.get(apiRoute.getMessageTags);

            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    setAvailableMts(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const investigateMessageTags = async (messageContent) => {
        try {
            if (messageContent) {
                setStatus(LOADING);
                setErrorMssg();

                const url = apiRoute?.investigateMessage;
                const res = await API.get(url, {
                    params: {
                        search: messageContent.trim(),
                    },
                });
                if (res.status === 200) {
                    const { data } = res.data;
                    if (data.length > 0) {
                        setAvailableMts(data);
                        setStatus(DATAMODE);
                    } else {
                        setStatus(NULLMODE);
                    }
                }
            } else {
                getMessagingTags();
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <>
                        <TMTagDetailElement
                            lastMessageContent={messageContent}
                            selectedTicket={selectedTicket}
                        />
                    </>
                );

            case DATAMODE:
                return (
                    <TMTSuggestionElement
                        selectedTicket={selectedTicket}
                        availableMTs={availableMTs}
                        lastMessageContent={messageContent}
                        setCannedMessage={setCannedMessage}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getMessagingTags}
                    />
                );

            default:
                return "";
        }
    };

    const acceptNewMessageForInvestigation = useCallback((message) => {
        const { messageType, senderType, messageContent } = message;
        if (messageType === DEFAULT && senderType === THIRD_USER) {
            investigateMessageTags(messageContent);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let httpRequest = new AbortController();

        investigateMessageTags(messageContent, httpRequest);
        socket?.emit(SUBSCRIBE_TO_TICKET, { ticketId });
        socket?.on(RECEIVE_MESSAGE, acceptNewMessageForInvestigation);

        return () => {
            httpRequest.abort();
            socket.off(RECEIVE_MESSAGE);
        };
        //eslint-disable-next-line
    }, []);

    return <div>{renderBasedOnStatus()}</div>;
};

export default TMessageTagSuggestion;
