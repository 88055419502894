import AnalyticsTSamHeading from "../common/AnalyticsTSamHeading/AnalyticsTSamHeading";
import AnalyticsTSamTabs from "../common/AnalyticsTSamTabs/AnalyticsTSamTabs";
import AnalyticsTSamConvoTable from "./AnalyticsTSamConvoTable/AnalyticsTSamConvoTable";

const AnalyticsTSamConversations = () => {
    return (
        <>
            <AnalyticsTSamTabs />
            <div className='padded__layout'>
                <AnalyticsTSamHeading
                    title='Most frequent conversations'
                    placeholder={"Search comversations here"}
                    noSearch
                />
                <AnalyticsTSamConvoTable />
            </div>
        </>
    );
};

export default AnalyticsTSamConversations;
