import { useState } from "react";
import PropTypes from "prop-types";
import { Empty } from "components/ui";
import CannedResponseActionDropdown from "../CannedResponseActionDropdown/CannedResponseActionDropdown";
import CannedResponseActionModals from "../CannedResponseActionModal/CannedResponseActonModal";
import "./CannedResponseList.scss";

const CannedResponseList = ({ cannedResponses, handleSuccess }) => {
    const [currentAction, setCurrentAction] = useState("");
    const [selectedCannedResponse, setSelectedCannedResponse] = useState({});

    const handleActionModal = (action, response) => {
        setCurrentAction(action);
        setSelectedCannedResponse(response);
    };

    const closeActionModal = () => {
        setCurrentAction("");
        setSelectedCannedResponse("");
    };
    return (
        <>
            <div className='canned__response__list'>
                {cannedResponses?.length ? (
                    <div className='canned_responses'>
                        {cannedResponses?.map((cannedResponse, i) => (
                            <CannedResponseActionDropdown
                                handleActionModal={handleActionModal}
                                cannedResponse={cannedResponse}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty text='No response created' />
                )}
            </div>
            {currentAction && (
                <CannedResponseActionModals
                    cannedResponse={selectedCannedResponse}
                    currentAction={currentAction}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

CannedResponseList.prototypes = {
    cannedResponses: PropTypes.array.isRequired,
};

export default CannedResponseList;
