import { Button } from "components/ui";

const AnalyzerButton = ({
    loading,
    isACopyAction,
    isResponseCopied,
    isCannedResponse,
}) => {
    return (
        <>
            <Button
                type='submit'
                text={
                    isACopyAction
                        ? isResponseCopied
                            ? "Copied!"
                            : isCannedResponse
                            ? "Copy Response"
                            : "Copy conversation Link"
                        : "Analyze"
                }
                classType='primary'
                otherClass='w-100'
                loading={loading}
            />
        </>
    );
};
export default AnalyzerButton;
