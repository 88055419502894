import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import ClassificationForm from "./ClassificationForm/ClassificationForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const ClassificationModal = ({
    showModal,
    closeModal,
    capsuleClasses,
    selectedClassification,
    isEdit,
    selectedUnassignedCapsule,
    handleSuccess,
    elements,
    prevAssigned,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0 agent-form'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isEdit
                                ? "Edit Classification"
                                : "Create Classification"
                        }
                        subTitle={
                            isEdit
                                ? `Edit ${selectedClassification?.name}`
                                : "Create a new classification"
                        }
                    />
                    <ClassificationForm
                        closeModal={closeModal}
                        capsuleClasses={capsuleClasses}
                        selectedClassification={selectedClassification}
                        isEdit={isEdit}
                        selectedUnassignedCapsule={selectedUnassignedCapsule}
                        handleSuccess={handleSuccess}
                        elements={elements}
                        prevAssigned={prevAssigned}
                    />
                </div>
            </div>
        </SideModal>
    );
};

ClassificationModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectedClassification: PropTypes.object,
    edit: PropTypes.bool,
    selectedUnassignedCapsule: PropTypes.object,
    handleSuccess: PropTypes.func,
};

export default ClassificationModal;
