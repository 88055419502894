import React, { useState } from "react";
import EditBranchButton from "./EditBranchButton/EditBranchButton";
import CreateBranchModal from "components/dashboard/TrainSAM/SAM/Conversations/branches/modal/CreateBranchModal/CreateBranchModal";
import { branchFormActions } from "components/dashboard/TrainSAM/SAM/Conversations/enums";
import BranchViewElementDropdown from "./BranchViewElementDropdown/BranchViewElementDropdown";
import DeleteBranchModal from "components/dashboard/TrainSAM/SAM/Conversations/branches/modal/DeleteBranchModal/DeleteBranchModal";
import BRANCH_VIEW_ELEMENT_ACTIONS from "./enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import "./BranchViewElementHeader.scss";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { UPDATE } = baseResourceActions;
const { DETACH_BRANCH } = BRANCH_VIEW_ELEMENT_ACTIONS;
const { EDIT_BRANCH, EDIT_CONVERSATION_BREAKER } = branchFormActions;

const BranchViewElementHeader = ({
    branch,
    step,
    handleRefresh,
    hideBranchActions,
    conversationId,
    collectionId,
    isConversationBreakerView,
    showRemoveBranchOption,
    isAgentGuideView,
}) => {
    const [showEditModal, toggleEditModal] = useState(false);
    const [showRemoveModal, toggleRemoveModal] = useState(false);
    const [modalActionType, updateModalActionType] = useState();
    const [showDeleteModal, toggleDeleteModal] = useState(false);

    const handleAction = (actionType) => {
        actionType === DETACH_BRANCH
            ? toggleRemoveModal(true)
            : toggleDeleteModal(true);
    };

    return (
        <>
            <div className='branch__view--heading'>
                <div className='row align-items-center'>
                    <div className='col-md-9'>
                        <h1>
                            {isAgentGuideView
                                ? step?.guideFlowName
                                : branch?.branchName}
                        </h1>
                    </div>
                    <div className='col-md-3 d-flex justify-content-end branch__view--heading__actions'>
                        <div>
                            <ComponentAccessControl
                                baseResources={[
                                    `${UPDATE}_${KB_SAM_BRANCH}`,
                                    `${UPDATE}_${KB_SAM_COLLECTION}`,
                                    `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                    `${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                                ]}>
                                <EditBranchButton
                                    onClick={() => {
                                        updateModalActionType(
                                            isConversationBreakerView
                                                ? EDIT_CONVERSATION_BREAKER
                                                : EDIT_BRANCH
                                        );
                                        toggleEditModal(true);
                                    }}
                                    otherClassName={
                                        "branch__view--heading__button"
                                    }
                                />
                            </ComponentAccessControl>
                        </div>

                        {!hideBranchActions && (
                            <div>
                                <BranchViewElementDropdown
                                    handleAction={handleAction}
                                    showRemoveBranchOption={
                                        showRemoveBranchOption
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showEditModal && (
                <CreateBranchModal
                    show={showEditModal}
                    isEdit={true}
                    closeModal={() => toggleEditModal(false)}
                    referenceData={branch}
                    source={ branch?.branchType}
                    modalFormAction={modalActionType}
                    handleSuccess={(branchId) => {
                        toggleEditModal(false);
                        handleRefresh(branchId);
                    }}
                    handleRefresh={(branchId) => {
                        toggleEditModal(false);
                        handleRefresh(branchId);
                    }}
                    isAgentGuideView={isAgentGuideView}
                />
            )}

            {showRemoveModal && (
                <ConfirmationModal
                    title={`Are you sure you want to remove ${branch?.branchName}`}
                    show={showRemoveModal}
                    closeModal={() => toggleRemoveModal(false)}
                    handleSuccess={handleRefresh}
                    url={
                        conversationId
                            ? apiRoute?.removeBranchFromConversation(
                                  conversationId,
                                  branch?.branchId
                              )
                            : apiRoute?.removeBranchFromCollection(
                                  collectionId,
                                  branch?.branchId
                              )
                    }
                    method='delete'
                />
            )}

            {showDeleteModal && (
                <DeleteBranchModal
                    referenceData={branch}
                    closeModal={() => toggleDeleteModal(false)}
                    show={showDeleteModal}
                    handleRefresh={handleRefresh}
                    conversationId={conversationId}
                    collectionId={collectionId}
                    guideFlowId={step?.guideFlowId}
                    isAgentGuideView={isAgentGuideView}
                />
            )}
        </>
    );
};

export default BranchViewElementHeader;
