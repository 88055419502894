import { VIEW_GD_UPDATES_ACTIONS } from "../../enum";
import DeleteGDUpdateModal from "../DeleteGDUpdateModal/DeleteGDUpdateModal";
import CreateGDUpdateModal from "../CreateGDUpdateModal/CreateGDUpdateModal";

const { EDIT_UPDATE, DELETE_UPDATE, CREATE_UPDATE } = VIEW_GD_UPDATES_ACTIONS;

const GuideDowntimeModal = ({
    showModal,
    selectedStepType,
    selectedStep,
    handleSuccess,
    selectedUpdate,
    closeModal,
    isEdit,
    modalAction,
    handleRefresh,
}) => {
    const renderBasedOnAction = () => {
        switch (modalAction) {
            case CREATE_UPDATE:
            case EDIT_UPDATE:
                return (
                    <CreateGDUpdateModal
                        {...{
                            showModal,
                            selectedStepType,
                            selectedStep,
                            handleSuccess,
                            selectedUpdate,
                            closeModal,
                            isEdit,
                            handleRefresh,
                        }}
                    />
                );

            case DELETE_UPDATE:
                return (
                    <DeleteGDUpdateModal
                        {...{
                            selectedUpdate,
                            handleSuccess,
                            closeModal,
                            showModal,
                            handleRefresh,
                        }}
                    />
                );
            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default GuideDowntimeModal;
