import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
import CustomRules from "./CustomRules/CustomRules";
import TeamsModal from "../TeamsModal/TeamsModal";
import ViewTeamHeader from "./ViewTeamHeader/ViewTeamHeader";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus, handleError } from "utils";
import TeamMemberTable from "./TeamMemberTable/TeamMemberTable";
import ViewTeamHeaderActions from "./ViewTeamHeaderActions/ViewTeamHeaderActions";
import "./ViewTeam.scss";

const { IDLE, ERROR, DATAMODE, LOADING } = dataQueryStatus;

const ViewTeam = () => {
    const [teamData, setTeamData] = useState({});
    const [showSideModal, setShowSideModal] = useState(false);
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const { teamId } = useParams();
    const closeModal = () => setShowSideModal(false);
    const openModal = () => setShowSideModal(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, updatePageFilters] = useState({
        currentPage: 1,
        pageSize: 5,
    });

    const handlePageChange = (currentPage) => {
        const skip = (currentPage - 1) * filters.pageSize;
        const page = currentPage * filters.pageSize;

        setTeamMembers([...teamData?.teamMembers?.slice(skip, page)]);
        updatePageFilters({ ...filters, currentPage });
    };

    const getTeam = async (httpRequest) => {
        try {
            setStatus(LOADING);

            const url = apiRoute?.team(teamId);
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setTeamData(data);
                setTotalRecords(data?.teamMembers?.length);
                setTeamMembers(data?.teamMembers?.slice(0, filters?.pageSize));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(handleError(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getTeam(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, [teamId]);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <div className='col-12'>
                        <ViewTeamHeaderActions
                            teamData={teamData}
                            openModal={openModal}
                        />
                        <TeamMemberTable
                            members={teamMembers}
                            teamId={teamId}
                            openModal={openModal}
                            handleSuccess={getTeam}
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            filters={filters}
                        />

                        <CustomRules
                            customRules={teamData?.customRules}
                            teamId={teamId}
                            handleSuccess={getTeam}
                        />
                    </div>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        size='50'
                        handleRetry={getTeam}
                    />
                );
            default:
                return <Loading />;
        }
    };

    return (
        <>
            <ViewTeamHeader />
            <div className='view__team padded__layout'>
                {renderCompBasedOnStage()}
                {showSideModal && (
                    <TeamsModal
                        showSideModal={showSideModal}
                        closeModal={closeModal}
                        editItem={teamData}
                        handleSuccess={getTeam}
                    />
                )}
            </div>
        </>
    );
};

export default ViewTeam;
