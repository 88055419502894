import ShinyLoader from 'components/ui/ShinyLoader/ShinyLoader';
import React from 'react';

const BranchesColumnSkeleton = () => {
    return (
        <>
            <div
                className={`convo__branch`}>
                <ShinyLoader />
                <div className="mt-2">
                    <ShinyLoader width={'50px'} />
                </div>
            </div>
            <div
                className={`convo__branch`}>
                <ShinyLoader />
                <div className="mt-2">
                    <ShinyLoader width={'50px'} />
                </div>
            </div>
        </>
    );
};

export default BranchesColumnSkeleton;