import React from "react";
import { intervalToLevels } from "utils/helper";
import "./BranchScheduleContent.scss";

const BranchScheduleContent = ({
    title = "Schedule branch",
    content = "This branch would be sent based on the time condition",
    deliveryInterval,
}) => {
    return (
        <div className='branch__content'>
            <h6>{title}</h6>
            <p>{content}</p>
            <div className='row mt-3'>
                <div className='col-md-2'>
                    <div className='branch__scheduled__time'>
                        {intervalToLevels(deliveryInterval)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BranchScheduleContent;
