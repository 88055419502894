import { useEffect, useState } from "react";

import { HorizontalScroll } from "components/ui";

import { getFormattedStatusMapData } from "../../../helpers";

import StatusMapChartBar from "./StatusMapChartBar/StatusMapChartBar";

import "./StatusMapCharBar.scss";

const StatusMapChartHeading = () => {
    return <p className='title'>Status Map</p>;
};

const StatusMapChartBody = ({
    chartData,
    toggleShow,
    clickedBar,
    selectedOption,
}) => {
    return (
        <HorizontalScroll>
            <div className='bar-container'>
                <div className='bar-container-inner'>
                    {chartData.data.map((data, key) => (
                        <StatusMapChartBar
                            key={key}
                            {...{
                                ...data,
                                toggleShow,
                                barId: key,
                                clickedBar,
                                ...chartData,
                                selectedOption,
                            }}
                        />
                    ))}
                </div>
            </div>
        </HorizontalScroll>
    );
};

const StatusMapChartFooter = () => {
    return <p className='legend-name'>Time Map</p>;
};

const StatusMapChart = ({ statusMapData, selectedOption }) => {
    const [chartData, setChartData] = useState({
        totalHrsOfDowntimes: 0,
        totalHrsOfUptimes: 0,
        data: [],
    });

    useEffect(() => {
        let statusData = getFormattedStatusMapData(statusMapData);
        setChartData(statusData);
    }, [statusMapData]);

    const [clickedBar, setClickedBar] = useState();

    const toggleShow = (pos) => {
        setClickedBar(pos);
    };

    return (
        <>
            <div className='status-map-chart'>
                <StatusMapChartHeading />
                <StatusMapChartBody
                    {...{
                        chartData,
                        toggleShow,
                        clickedBar,
                        selectedOption,
                    }}
                />
                <StatusMapChartFooter />
            </div>
        </>
    );
};

export default StatusMapChart;
