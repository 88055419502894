import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { useContext } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { POST_FORM_COLLECTION, SOLUTIONS_COLLECTION } from "../enums";
import BranchCollectionNameInput from "./BranchCollectionNameInput/BranchCollectionNameInput";
import {
    CREATE_NEW_BRANCH_COLLECTION,
    ADD_EXISTING_BRANCH_COLLECTION,
} from "./enums";
import { clearPostFormCollections } from "store/modules/knowledgeBase/actions";
import { useDispatch } from "react-redux";

const AddBranchCollectionForm = ({
    source,
    referenceData,
    defaultActionType,
    existingBranchCollections,
    handleSuccess,
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [branchCollectionValue, setBranchCollectionValue] = useState();
    const [actionType, setActionType] = useState(defaultActionType);
    const [loadingCollectionsNames, setLoadingCollectionsNames] =
        useState(true);

    const toastMessage = useContext(ToastContext);
    const history = useHistory();

    const dispatch = useDispatch();

    const handleError = (err) => {
        setErrorMsg(getErrorMessage(err));
        setLoading(false);
    };

    const handleBranchCollectionNameChange = (actionType, valueObj) => {
        setLoadingCollectionsNames(false);
        setActionType(actionType);
        setBranchCollectionValue(valueObj);
    };

    const createPostFormCollection = async (useExisting) => {
        try {
            setLoading(true);
            setErrorMsg();

            const { relateFormPostFormCollection, postFormBranchCollections } =
                apiRoute;
            const url = useExisting
                ? relateFormPostFormCollection
                : postFormBranchCollections;

            const res = await API.post(url, {
                ...(useExisting
                    ? { formId: referenceData?.formId }
                    : { formIds: [referenceData?.formId] }),
                branchCollectionName: branchCollectionValue,
                postFormCollectionId: useExisting
                    ? branchCollectionValue?.value
                    : null,
            });

            if (res.status === 201) {

                if (useExisting) {
                    /* if this post form collection was created to relate with a form, 
                    clear the cached post form collections so updated list will always be fetched when the user leaves here to view them
                    */
                    dispatch(clearPostFormCollections());
                }

                toastMessage(res.data.message);
                setLoading(false);
                handleSuccess?.();

                const { branchCollectionId } = res.data.data;
                history.push({
                    pathname: `/t-sam/branch-collection/${branchCollectionId}/view`,
                    state: {
                        branchCollection: res.data.data,
                    },
                });
            }
        } catch (err) {
            handleError(err);
        }
    };

    const createSolutionCollection = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            const url = apiRoute?.solutionBranchCollections;
            const res = await API.post(url, {
                ...referenceData,
                branchCollectionName: branchCollectionValue,
            });
            if (res.status === 201) {
                toastMessage(res.data.message);
                setLoading(false);
                const { branchCollectionId } = res.data.data;
                history.push({
                    pathname: `/t-sam/branch-collection/${branchCollectionId}/view`,
                    state: {
                        branchCollection: res.data.data,
                    },
                });
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleRelatingExistingCollection = () => {
        switch (source) {
            case POST_FORM_COLLECTION:
                createPostFormCollection(true);
                break;
            default:
                break;
        }
    };

    const handleCreatingCollection = () => {
        switch (source) {
            case POST_FORM_COLLECTION:
                createPostFormCollection();
                break;
            case SOLUTIONS_COLLECTION:
                createSolutionCollection();
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        actionType === ADD_EXISTING_BRANCH_COLLECTION
            ? handleRelatingExistingCollection()
            : handleCreatingCollection();
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />

            {source === POST_FORM_COLLECTION ? (
                <BranchCollectionNameInput
                    source={source}
                    handleBranchCollectionNameChange={
                        handleBranchCollectionNameChange
                    }
                    actionType={actionType}
                    isLoading={loadingCollectionsNames}
                    setLoadingCollectionsNames={setLoadingCollectionsNames}
                    autoFocus={true}
                    createAble={true}
                    existingBranchCollections={existingBranchCollections}
                />
            ) : (
                <Input
                    name='branchCollectionName'
                    label={"Collection"}
                    grpClass='mb-3'
                    onChange={({ target: { value } }) => {
                        setBranchCollectionValue(value);
                        setLoadingCollectionsNames(false);
                    }}
                    autoFocus={true}
                />
            )}
            <Button
                classType={`primary`}
                otherClass='w-100'
                type={"submit"}
                text={
                    actionType === CREATE_NEW_BRANCH_COLLECTION
                        ? "Create Collection"
                        : "Add Collection"
                }
                disabled={loadingCollectionsNames || !branchCollectionValue}
                loading={loading}
            />
        </form>
    );
};

export default AddBranchCollectionForm;
