import TicketCannedResponse from "./TicketCannedResponse/TicketCannedResponse";
import "./TicketCRListing.scss";

const TicketCRListing = ({ searchValue, data, setCannedMessage }) => {
    return (
        <>
            <div className='canned-response-listing'>
                {data
                    ?.filter((cannedResponse) =>
                        cannedResponse?.title
                            ?.toLocaleLowerCase()
                            ?.includes(searchValue.toLocaleLowerCase())
                    )
                    ?.map(({ title, response }, index) => {
                        return (
                            <TicketCannedResponse
                                key={index}
                                title={title}
                                response={response}
                                setCannedMessage={setCannedMessage}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default TicketCRListing;
