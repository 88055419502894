import React, { useState, useEffect } from "react";
import API from "services/lib/api";
import { apiRoute } from "services/apiRoutes";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ProductLoading from "./ProductLoading/ProductLoading";
import { dataQueryStatus } from "utils";
import FadeIn from "components/ui/effects/FadeIn";
import ProductSetupView from "./ProductSetupView/ProductSetupView";
import ProductDashboard from "./ProductDashboard/ProductDashboard";
import ProductDashboardHeader from "./ProductDashboard/ProductDashboardHeader/ProductDashboardHeader";
import { getErrorMessage } from "utils/helper";
import SolutionSetupView from "../SolutionSetupContainer/SolutionSetupView/SolutionSetupView";
import { saveProducts, setSelectedProduct } from "store/product/actions";
import { useDispatch, useSelector } from "react-redux";

const { LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const ProductSetupContainer = ({ defaultProductId, isSolutionView }) => {
    const { products, selectedProduct } = useSelector((state) => state.product);
    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const dispatch = useDispatch();

    const retrieveProducts = async (httpRequest) => {
        try {
            setStatus(LOADING);
            const res = await API.get(apiRoute.allWorkspaceProducts, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    const allProducts = data;
                    dispatch(saveProducts(data));

                    if (defaultProductId) {
                        const defaultProduct =
                            defaultProductId &&
                            allProducts.find((product) => {
                                return product.productId === defaultProductId;
                            });
                        if (defaultProduct) {
                            handleProductSelect({
                                ...defaultProduct,
                                value: defaultProduct.productId,
                            });
                            setStatus(DATAMODE);
                        }
                        setErrorMssg("Product Not Found");
                        setStatus(ERROR);
                    } else if (selectedProduct?.productId) {
                        setStatus(DATAMODE);
                    } else {
                        handleProductSelect({
                            ...data[0],
                            value: data[0].productId,
                        });
                        setStatus(DATAMODE);
                    }
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const handleProductSelect = (product) => {
        dispatch(setSelectedProduct(product));
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        retrieveProducts(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return (
                    <ProductLoading
                        loadingText={
                            isSolutionView
                                ? "We are setting up your solution environment"
                                : "We are setting up your product environment"
                        }
                    />
                );
            case NULLMODE:
                return <ProductSetupView retrieveProducts={retrieveProducts} />;
            case DATAMODE:
                return isSolutionView ? (
                    <SolutionSetupView selectedProduct={selectedProduct} />
                ) : (
                    <ProductDashboard
                        key={selectedProduct?.value}
                        selectedProduct={selectedProduct}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={retrieveProducts}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <div key={status} className='h-100 setup-container'>
            {status === DATAMODE && (
                <ProductDashboardHeader
                    products={products}
                    selectedProduct={selectedProduct}
                    handleProductSelect={handleProductSelect}
                    retrieveProducts={retrieveProducts}
                />
            )}
            <FadeIn pkey={status}>{renderCompBasedOnStage()}</FadeIn>
        </div>
    );
};

export default ProductSetupContainer;
