import React from "react";
import ProductSetupForm from "./ProductSetupForm/ProductSetupForm";
import "./ProductSetupView.scss";

const ProductSetupView = ({ retrieveProducts }) => {
    return (
        <div className='product-setup'>
            <div className='product-setup--container'>
                <div>
                    <h1 className='product-setup--text'>
                        Product Information Setup
                    </h1>
                    <p className='product-setup--sub__text'>
                        Add a new product information below to get started
                    </p>
                    <ProductSetupForm handleSuccess={retrieveProducts} />
                </div>
            </div>
        </div>
    );
};

export default ProductSetupView;
