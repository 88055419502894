import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { apiRoute } from "services";
import API from "services/lib/api";
import { Button, Input, InputMultiple, SearchSelectInput } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { MESSAGE_TAG_ACTIONS } from "../../../enum";

const CreateMessageTagForm = ({
    tag,
    closeModal,
    handleSuccess,
    currentAction,
}) => {
    const { conversations: tagConvos } = tag || {};

    const [request, setRequest] = useState({
        name: tag?.messagingTagName || "",
        keyword: "",
        metadata: [],
        conversationId:
            tagConvos?.length > 0
                ? tagConvos?.map(
                      (conversation) => conversation.conversationId
                  ) || []
                : undefined,
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [conversations, setConversations] = useState([]);
    const [existingConversationLabels, setExistingConversationLabels] =
        useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationsIsLoading, setConversationsIsLoading] = useState(false);
    const [conversationsErrorMssg, setConversationsErrorMssg] = useState("");
    const toastMessage = useContext(ToastContext);

    useEffect(() => {
        const { conversations } = tag || {};

        if (currentAction === MESSAGE_TAG_ACTIONS.EDIT) {
            const formattedData =
                conversations &&
                conversations?.map((conversation) => ({
                    value: conversation?.conversationId,
                    label: conversation?.issue?.issueName,
                }));
            setExistingConversationLabels(formattedData);
        }
        // eslint-disable-next-line
    }, [tag]);

    const getConversations = async (httpRequest) => {
        try {
            setConversationsIsLoading(true);
            setConversationsErrorMssg();

            const res = await API.get(apiRoute.getConversations, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res?.data;
                const formattedData = data?.map((conversation) => ({
                    value: conversation?.conversationId,
                    label: conversation?.issue?.issueName,
                }));
                setConversations(formattedData);
                setConversationsIsLoading(false);
            }
        } catch (err) {
            setConversationsIsLoading(false);
            setConversationsErrorMssg(getErrorMessage(err));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMultipleInputChange = (name, value) => {
        setRequest({
            ...request,
            [name]: value?.length > 0 ? value : undefined,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const createTag = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute?.createMessageTag;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;

                handleSuccess();
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editTag = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const { keyword, metadata, ...req } = request;

            const url = apiRoute.editMessageTag(tag?.messagingTagId);

            const res = await API.put(url, req);

            if (res.status === 200) {
                const { message } = res.data;

                handleSuccess(tag?.messagingTagId);
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "conversationId",
        ]);

        if (formisValid) {
            if (currentAction === MESSAGE_TAG_ACTIONS.EDIT) {
                return editTag();
            }
            return createTag();
        } else {
            setErrors((prev) => ({ ...prev, ...formErrors }));
        }
    };

    const { name, keyword } = request;

    useEffect(() => {
        let httpRequest = new AbortController();

        getConversations(httpRequest);

        return () => {
            httpRequest?.abort();
        };
    }, []);

    const { conversationId } = request;

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='name'
                label='Name of tag'
                labelClass='text-dark fw-medium'
                id='name'
                placeholder='Enter here'
                value={name}
                onChange={handleChange}
                isErr={errors?.name}
                errMssg={errors?.name}
                autoFocus={true}
            />
            {currentAction !== MESSAGE_TAG_ACTIONS.EDIT && (
                <>
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='keyword'
                        label='Enter key word or phrase'
                        labelClass='text-dark fw-medium'
                        id='keyword'
                        placeholder='Enter text here'
                        value={keyword}
                        onChange={handleChange}
                        isErr={errors?.keyword}
                        errMssg={errors?.keyword}
                    />
                    <InputMultiple
                        grpClass='mb-3'
                        type='text'
                        name='metadata'
                        label='Metadata'
                        labelClass='text-dark fw-medium'
                        id='metadata'
                        placeholder='Add metadata'
                        setInputValue={handleMultipleInputChange}
                        isErr={errors?.metadata}
                        errMssg={errors?.metadata}
                        inputValue={tag?.metadata}
                    />
                </>
            )}
            <SearchSelectInput
                grpClass='mb-3'
                name='conversationId'
                label='Conversation Suggestions'
                labelClass='text-dark fw-medium'
                darkLabel={true}
                id='conversationId'
                placeholder='Add Conversation Suggestions'
                setInputValue={handleMultipleInputChange}
                errMssg={errors?.conversationId}
                isLoading={conversationsIsLoading}
                serverError={conversationsErrorMssg}
                handleRetry={() => getConversations()}
                data={conversations?.filter(
                    ({ value }) => !conversationId?.includes(value)
                )}
                inputValue={existingConversationLabels}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                loading={loading}
                disabled={conversationsIsLoading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

CreateMessageTagForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
};

export default CreateMessageTagForm;
