import React from "react";
import { Modal } from "components/ui";
import { branchImageActions } from "../enums";
import ImageCropper from "./ImageCropper/ImageCropper";
import ImageCropViewer from "./ImageCropViewer/ImageCropViewer";
import { useState } from "react";

const { VIEW_IMAGE, ATTACH_IMAGE } = branchImageActions;

const BranchImageCropModal = ({
    show,
    close,
    branchImageAction,
    referenceData,
    handleRefresh,
    handleOpenDeleteModal,
}) => {
    const [cropImageAction, setCropImageAction] = useState();
    const [editImageUrl, setEditImageUrl] = useState("");

    const renderBasedOnAction = () => {
        switch (cropImageAction || branchImageAction) {
            case VIEW_IMAGE:
                return (
                    <ImageCropViewer
                        referenceData={referenceData}
                        closeModal={close}
                        setEditImageUrl={setEditImageUrl}
                        setCropImageAction={setCropImageAction}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                    />
                );

            case ATTACH_IMAGE:
                return (
                    <ImageCropper
                        close={close}
                        branchImageAction={branchImageAction}
                        referenceData={referenceData}
                        editImageUrl={editImageUrl}
                        handleRefresh={handleRefresh}
                    />
                );
            default:
                return "";
        }
    };
    return (
        <Modal show={show} close={close} centered={true} contentClassName=''>
            {renderBasedOnAction()}
        </Modal>
    );
};

export default BranchImageCropModal;
