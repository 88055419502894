import { useState } from "react";
import CreateDependencyModal from "../../../../common/DependencyModal/DependencyModal";

import SwitchesListingTable from "../../../SwitchesContainer/SwitchesListing/SwitchesListingTable/SwitchesListingTable";
import ViewSwitchesListingHeader from "./ViewSwitchesListingHeader/ViewSwitchesListingHeader";

const ViewSwitchesListingTable = ({
    dependencies,
    getDependencies,
    tableLoading,
}) => {
    return (
        <SwitchesListingTable
            dependencies={dependencies}
            getDependencies={getDependencies}
            tableLoading={tableLoading}
        />
    );
};

const ViewSwitchesListing = ({
    dependencies,
    getDependencies,
    tableLoading,
    handleSearch,
    searchIsLoading,
    clearSearch,
}) => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);

    const toggleModal = () => {
        setShowModal(true);
    };

    return (
        <>
            <ViewSwitchesListingHeader
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
            />

            {dependencies?.length > 0 && (
                <ViewSwitchesListingTable
                    dependencies={dependencies}
                    getDependencies={getDependencies}
                    tableLoading={tableLoading}
                />
            )}

            {showModal && (
                <CreateDependencyModal
                    {...{ showModal, closeModal, getDependencies }}
                />
            )}
        </>
    );
};

export default ViewSwitchesListing;
