import { updateDocument } from "../queries/queries";

const moment = require("moment");

export const getTodayAndYesterdayDates = () => {
    return {
        today: moment().format("MMM Do, YYYY"),
        yesterday: moment().subtract(1, "days").format("MMM Do, YYYY"),
    };
};

export const groupNotificationMessages = (notifications, userId) => {
    let today = [];
    let yesterday = [];
    let last4Days = [];
    let last7Days = [];
    let last1Month = [];
    let over1Month = [];

    let todayAndYesterday = getTodayAndYesterdayDates();
    notifications.map((eachMessage) => {
        let message = eachMessage._document.data.value.mapValue.fields;
        let messageId = eachMessage?.id;
        let workspaceId = message?.workspaceId?.stringValue;
        let workspaceUsers = message.workspaceUsers.arrayValue.values;

        let readStatus = true;

        let updatedWorkspaceUsers = workspaceUsers?.map((eachWorkspaceUser) => {
            let { read, userId: workspaceUserId } =
                eachWorkspaceUser.mapValue.fields;
            if (workspaceUserId.stringValue === userId && !read?.booleanValue) {
                // read.booleanValue = true;
                readStatus = false;
                return {
                    read: true,
                    userId: workspaceUserId.stringValue,
                };
            } else {
                return {
                    read: false,
                    userId: workspaceUserId.stringValue,
                };
            }
        });

        message.readStatus = readStatus;

        if (!readStatus) {
            updateDocument(workspaceId, messageId, {
                workspaceUsers: updatedWorkspaceUsers,
            });
        }

        let noOfPastDaysBehind = moment().diff(
            message.createdAt.stringValue,
            "days",
            false
        );

        if (
            moment(message.createdAt.stringValue).format("MMM Do, YYYY") ===
            todayAndYesterday.today
        ) {
            return today.push(message);
        } else if (noOfPastDaysBehind <= 1) {
            return yesterday.push(message);
        } else if (noOfPastDaysBehind >= 2 && noOfPastDaysBehind <= 4) {
            return last4Days.push(message);
        } else if (noOfPastDaysBehind >= 4 && noOfPastDaysBehind <= 7) {
            return last7Days.push(message);
        } else if (noOfPastDaysBehind > 7 && noOfPastDaysBehind < 30) {
            return last1Month.push(message);
        } else {
            return over1Month.push(message);
        }
    });

    let groupedNotifications = [
        { groupName: "TODAY", data: today },
        { groupName: "YESTERDAY", data: yesterday },
        { groupName: "LAST 4 DAYS", data: last4Days },
        {
            groupName: "LAST 1 WEEK",
            data: last7Days,
        },
        { groupName: "LESS THAN A MONTH", data: last1Month },
        {
            groupName: "OVER 1 MONTH AGO",
            data: over1Month,
        },
    ];

    return groupedNotifications;
};

export const filteredUnReadMessages = (allMessages, workspaceUserId) => {
    let unreadMessages = allMessages?.filter((eachMessage) => {
        let workspaceUsers =
            eachMessage._document.data.value.mapValue.fields.workspaceUsers
                .arrayValue.values;

        let unreadUserMessage = workspaceUsers?.filter(
            ({
                mapValue: {
                    fields: { read, userId },
                },
            }) => {
                return (
                    read.booleanValue === false &&
                    userId?.stringValue === workspaceUserId
                );
            }
        );

        return unreadUserMessage?.length === 1;
    });

    return unreadMessages;
};
