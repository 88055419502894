import AddButton from "components/ui/Button/AddButton/AddButton";
import "./CustomURLFields.scss";
import { Input } from "components/ui";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const CustomURLFields = ({ handleChange, data }) => {
    const [inAppUrls, updateInAppUrls] = useState([{ title: "", value: "" }]);

    const handleAddNewField = () => {
        updateInAppUrls((prev) => [...prev, { title: "", value: "" }]);
    };

    const handleUpdateField = (e, index) => {
        const { value, name } = e?.target || {};

        let inAppUrl = inAppUrls[index];

        inAppUrl = { ...inAppUrl, [name]: value };

        let copiedInAppUrls = [...inAppUrls];

        copiedInAppUrls[index] = inAppUrl;

        updateInAppUrls((prev) => [...copiedInAppUrls]);

        handleChange?.([...copiedInAppUrls]);
    };

    const handleRemoveField = (index) => {
        let copiedInAppUrls = [...inAppUrls];
        copiedInAppUrls.splice(index, 1);
        updateInAppUrls((prev) => [...copiedInAppUrls]);
        handleChange?.([...copiedInAppUrls]);
    };

    useEffect(() => {
        if (data?.length > 0) {
            updateInAppUrls(data);
        }
    }, [data]);

    return (
        <>
            <div className='custom-url-field'>
                <div className='custom-url-field__list'>
                    {inAppUrls.map(({ title, value }, key) => (
                        <div key={key}>
                            <Input
                                grpClass='mb-3'
                                name='title'
                                label='Custom URL'
                                labelClass='text-dark '
                                id='title'
                                placeholder='Enter the link title'
                                value={title}
                                required
                                onChange={(e) => handleUpdateField(e, key)}
                            />
                            <Input
                                grpClass='mb-3'
                                name='value'
                                labelClass='text-dark '
                                id='value'
                                placeholder='Enter the URL'
                                value={value}
                                type="url"
                                required
                                onChange={(e) => handleUpdateField(e, key)}
                            />
                            <div className='cursor-pointer custom-url-field__remove'>
                                <ReactSVG
                                    src={asset?.icons?.bin}
                                    className='d-inline-flex'
                                    onClick={() => handleRemoveField(key)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <AddButton
                    btnText={"Add New URL"}
                    otherClassName='custom-url-field__button'
                    onClick={handleAddNewField}
                />
            </div>
        </>
    );
};

export default CustomURLFields;
