import { Table } from "components/ui";

import { FOLLOW_UP_TASKS_TABLE_HEADER } from "../enum";
import FollowUpTasksTableDropdown from "./FollowUpTasksTableDropdown/FollowUpTasksTableDropdown";
import FollowUpTasksViewActions from "./FollowUpTasksViewActions/FollowUpTasksViewActions";
import FollowUpTaskViewDuration from "./FollowUpTaskViewDuration/FollowUpTaskViewDuration";

const FollowUpTasksViewListing = ({ handleActionModal, followUpTasks }) => {
    const dataSource = followUpTasks?.map((followUpTask) => ({
        Task: `${followUpTask?.taskTitle}`,
        DueDate: (
            <FollowUpTaskViewDuration
                duration={followUpTask?.initiationSchedule}
            />
        ),
        actions: (
            <FollowUpTasksViewActions
                handleActionModal={handleActionModal}
                followUpTask={followUpTask}
            />
        ),
        dropdownElement: (
            <FollowUpTasksTableDropdown followUpTask={followUpTask} />
        ),
    }));

    return (
        <>
            <Table
                headers={FOLLOW_UP_TASKS_TABLE_HEADER}
                dataSource={dataSource}
                otherClassNames='follow-up-task-view__table'
                isAccordion={true}
            />
        </>
    );
};

export default FollowUpTasksViewListing;
