import Heading from "components/ui/Heading/Heading";

const AnalyzerHeading = ({ selectedTagRespTitle }) => {
    return (
        <>
            <Heading
                headingText={
                    selectedTagRespTitle
                        ? selectedTagRespTitle
                        : "Find the right conversation."
                }
                headingSubText={
                    "Search for a keyword or paste an entire conversation."
                }
                textOtherClass='analyzer-heading'
            />
        </>
    );
};

export default AnalyzerHeading;
