import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import PropTypes from "prop-types";
import InviteMembersForm from "../../../InviteMembersForm/InviteMembersForm";

const AddTeamMember = ({ handleSuccess }) => {
    return (
        <div className='row me-0 ms-0'>
            <div className='col-10 offset-1'>
                <ModalHeading
                    title={"Add Team member"}
                    subTitle='Add a new team member in your team'
                />
                <InviteMembersForm handleSuccess={handleSuccess} />
            </div>
        </div>
    );
};

AddTeamMember.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
};

export default AddTeamMember;
