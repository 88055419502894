import React from "react";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";
import BranchViewElement from "./BranchViewElement/BranchViewElement";

const { LOADING, ERROR, DATAMODE } = dataQueryStatus;

const BranchView = ({
    branch,
    status,
    errorMssg,
    handleRefresh,
    hideBranchName,
    conversationId,
    collectionId,
    hideCapsuleContentGroup,
    hideOptionsView,
    hideBranchActions,
    isConversationBreakerView,
    existingOptionConnections,
    showRemoveBranchOption,
    branchEmbeds,
    isAgentGuideView,
    step,
    handleSuccess,
    guideId,
    showCannedMessages
}) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <BranchViewElement
                        branch={branch}
                        hideBranchName={hideBranchName}
                        handleRefresh={handleRefresh}
                        conversationId={conversationId}
                        collectionId={collectionId}
                        hideCapsuleContentGroup={hideCapsuleContentGroup}
                        hideOptionsView={hideOptionsView}
                        hideBranchActions={hideBranchActions}
                        isConversationBreakerView={isConversationBreakerView}
                        existingOptionConnections={existingOptionConnections}
                        showRemoveBranchOption={showRemoveBranchOption}
                        branchEmbeds={branchEmbeds}
                        isAgentGuideView={isAgentGuideView}
                        step={step}
                        guideId={guideId}
                        handleSuccess={handleSuccess}
                        showCannedMessages={showCannedMessages}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    return <div>{renderBasedOnStatus()}</div>;
};

export default BranchView;
