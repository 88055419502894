import PreHeading from "components/ui/PreHeading/PreHeading";

const ViewTeamHeader = ({ teamData }) => {
    return (
        <PreHeading
            title={teamData?.content}
            text='Team members'
            url='/agents-inbox/settings/tickets-routing'
            subText='View team'
        />
    );
};

export default ViewTeamHeader;
