import React from "react";
import imageLinks from "assets/images";
import { ReactSVG } from "react-svg";
import CommentDropdown from "./CommentDropdown/CommentDropdown";
import { getTimestampDate, getDateAndMonth } from "utils/helper";
import "./Comment.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const Comment = ({
    comment,
    handleShowDeleteModal,
    handleSetCurrentComment,
    handleResolveComment,
}) => {
    const {
        authProfile: { imageUrl, firstName },
        comment: content,
        commentId,
        updatedDate,
        mentioned,
        authorId,
    } = comment || {};

    return (
        <div className='comment__group'>
            <div className='comment__metadata__container'>
                <div className='comment__created__by'>
                    <ImageView src={imageUrl} alt='user' />
                    <p>
                        <span>{firstName}</span> commented
                    </p>
                </div>
                <div className='comment__timestamp'>
                    <span className='comment__timestamp--date'>
                        {getDateAndMonth(updatedDate)}
                    </span>
                    <ReactSVG src={imageLinks.icons.mutedDot} />
                    <span className='comment__timestamp--time'>
                        {getTimestampDate(updatedDate, "time")}
                    </span>
                </div>
            </div>
            <div className='comment__container'>
                <p
                    className='comment'
                    dangerouslySetInnerHTML={{
                        __html: content.replace(/\n\r?/g, "<br />"),
                    }}
                />
            </div>
            <div className='comment__reactions__container'>
                <div className='comment__reactions'>
                    <CommentDropdown
                        handleShowDeleteModal={() =>
                            handleShowDeleteModal(commentId)
                        }
                        handleSetCurrentComment={() =>
                            handleSetCurrentComment(
                                commentId,
                                content,
                                mentioned
                            )
                        }
                        handleResolveComment={() =>
                            handleResolveComment(commentId)
                        }
                        authorId={authorId}
                    />
                </div>
            </div>
        </div>
    );
};

export default Comment;
