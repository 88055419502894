import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { baseResources, baseResourceActions } from "routes/enum";

const { DELETE } = baseResourceActions;
const { AGENTS_INBOX_BASE_SETTING } = baseResources;

const ScenariosDropdown = ({ onClick }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={() => onClick?.()}
            options={[
                {
                    value: "DELETE",
                    label: "Delete",
                    baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
                },
            ]}
        />
    );
};

export default ScenariosDropdown;
