import { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import AnalyticsQAVetTicketTable from "../AnalyticsQAVetTicketTable/AnalyticsQAVetTicketTable";
import { useDispatch } from "react-redux";
import {
    savePinnedVetTickets,
    saveUnpinnedVetTickets,
} from "store/modules/qa/actions";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatus;

const AnalyticsQAVetTicketsList = ({
    isPinned,
    title,
    titleClass,
    toggleRefresh,
    refresh,
    filterParams,
    useTableLoader,
    setUseTableLoader,
    setSearchIsLoading,
    pages,
}) => {
    const [vetableTickets, setVetableTickets] = useState([]);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );
    const [errorMssg, setErrorMssg] = useState("");

    const dispatch = useDispatch();

    const getVetableTickets = async () => {
        try {
            if (!useTableLoader) {
                setStatus(LOADING);
            }
            const url = apiRoute?.getVetableTickets;
            const res = await API.get(url, {
                params: {
                    pinned: isPinned,
                    sortBy: filterParams?.dateCreated || "asc",
                    // ...filterParams,
                },
            });

            if (res.status === 200) {
                const { data, metaData } = res.data;
                setVetableTickets(data);
                if (isPinned) {
                    dispatch(
                        savePinnedVetTickets(
                            filterParams?.currentPage,
                            metaData?.totalRecords,
                            data
                        )
                    );
                } else {
                    dispatch(
                        saveUnpinnedVetTickets(
                            filterParams?.currentPage,
                            metaData?.totalRecords,
                            data
                        )
                    );
                }
                setStatus(DATAMODE);
                setUseTableLoader(false);
                setSearchIsLoading(false);
            }
        } catch (err) {
            setStatus(ERROR);
            setUseTableLoader(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        handleRetry={getVetableTickets}
                        errorMssg={errorMssg}
                    />
                );
            case DATAMODE:
                return (
                    <AnalyticsQAVetTicketTable
                        tickets={vetableTickets}
                        isPinned={isPinned}
                        useTableLoader={useTableLoader}
                        toggleRefresh={toggleRefresh}
                        filterParams={filterParams}
                    />
                );
            case NULLMODE:
                return <Empty />;
            default:
                return "";
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0) {
            setVetableTickets(cachedPage);
        } else {
            getVetableTickets(httpRequest);
        }

        // eslint-disable-next-line
    }, [filterParams, refresh]);

    return (
        <>
            <h3 className={`${titleClass}`}>{title}</h3>
            {renderBasedOnStatus()}
        </>
    );
};

export default AnalyticsQAVetTicketsList;
