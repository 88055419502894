import PropTypes from "prop-types";
import { Empty, Tag } from "components/ui";
import "./SuggestionsList.scss";

const SuggestionsList = ({ conversations }) => {
    return (
        <>
            <div className='tagging__suggestions'>
                {conversations?.length ? (
                    <div className='tagging__suggestions--tags'>
                        {conversations?.map((conversation, i) => (
                            <Tag
                                key={i}
                                name={conversation?.issue?.issueName}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty text='No suggestion created' />
                )}
            </div>
        </>
    );
};

SuggestionsList.prototypes = {
    conversations: PropTypes.array.isRequired,
};

export default SuggestionsList;
