import { Empty } from "components/ui";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";

import { taskStatusNames } from "../../enum";

const EmptyTasks = () => {
    return (
        <div>
            <div className='pending__tasks--header'>
                <h4>{taskStatusNames.PENDING}</h4>
                <ReactSVG src={imageLinks?.icons?.editNote} />
            </div>
            <div className='empty__follow__up__container'>
                <Empty
                    icon={imageLinks?.icons?.task}
                    text='No follow up task has been added to this ticket.'
                    className='empty__follow__up__tasks'
                />
            </div>
        </div>
    );
};

export default EmptyTasks;
