import ViewSwitchesHeading from "./ViewSwitchesHeading/ViewSwitchesHeading";
import ViewSwitchesHeadingActions from "./ViewSwitchesHeadingActions/ViewSwitchesHeadingActions";

const ViewSwitchesListingHeader = ({
    toggleModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
}) => {
    return (
        <>
            <ViewSwitchesHeading />
            <ViewSwitchesHeadingActions
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
            />
        </>
    );
};

export default ViewSwitchesListingHeader;
