import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Empty, Loading } from "components/ui";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import UsersHeader from "./common/UsersHeader/UsersHeader";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import InviteMembersForm from "./InviteMembersForm/InviteMembersForm";
import TeamMembers from "./TeamMembers/TeamMembers";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { saveTeamMembers, clearTeamMembers } from "store/modules/admin/actions";
import { setUsersFilterParams } from "store/filters/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const UserManagement = () => {
    const [errorMssg, setErrorMssg] = useState();
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [members, setMembers] = useState([]);
    const {
        membersCache: { pages, totalRecords },
    } = useSelector((state) => state.admin);

    const { filterParams } = useSelector(
        (state) => state?.filters?.usersFilters
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);

        dispatch(setUsersFilterParams({ currentPage: 1, search }));
        dispatch(clearTeamMembers());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setUsersFilterParams({ ...filterParams, currentPage }));
    };

    const getMembers = async (httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (members?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;

                setMembers(data);
                dispatch(
                    saveTeamMembers(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setMembers(cachedPage);
        } else {
            getMembers(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const handleSuccess = () => {
        setClearSearch(true);
        dispatch(clearTeamMembers());
        getMembers()
        // dispatch(
        //     setUsersFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getMembers} />
                );

            case NULLMODE:
                return useTableLoader ? (
                    <>
                        <UsersHeader status={DATAMODE} />
                        <TeamMembers
                            members={[]}
                            handleSuccess={handleSuccess}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleSearch={handleSearch}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={filterParams?.search}
                        />
                        <Empty text={"No User matches the search."} />
                    </>
                ) : (
                    <>
                        <UsersHeader status={status} />
                        <InviteMembersForm handleSuccess={getMembers} />
                    </>
                );

            case DATAMODE:
                return (
                    <>
                        <UsersHeader status={status} />
                        <TeamMembers
                            members={members}
                            handleSuccess={handleSuccess}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleSearch={handleSearch}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={filterParams?.search}
                        />

                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    return <div className='padded__layout'>{renderBasedOnStatus()}</div>;
};

export default UserManagement;
