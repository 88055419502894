import TabbedWrapperHeader from "routes/TabbedWrapperRouter/TabbedWrapperHeader/TabbedWrapperHeader";
import EmailUpdates from "../../EmailUpdates/EmailUpdates";
import ResponseUpdates from "../../ResponseUpdates/ResponseUpdates";
import MentionUpdates from "../../MentionUpdates/MentionUpdates";

const UpdatesTab = ({ agentUpdateId }) => {
    const addedPath = agentUpdateId ? `` : "";
    // view/${agentUpdateId}
    const tabbledRoutes = [
        {
            title: "Email updates",
            path: `/t-sam/updates/email/${addedPath}`,
            component: EmailUpdates,
        },
        {
            title: "Response updates",
            path: `/t-sam/updates/response/${addedPath}`,
            component: ResponseUpdates,
        },
        {
            title: "Mention updates",
            path: `/t-sam/updates/mention/${addedPath}`,
            component: MentionUpdates,
        },
    ];

    return <TabbedWrapperHeader tabbedRoutes={tabbledRoutes} />;
};

export default UpdatesTab;
