import { Empty } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import "./ViewGDUpdates.scss";
import ViewGDUpdateItem from "./ViewGDUpdateItem/ViewGDUpdateItem";
import ViewGDUpdateDetail from "./ViewGDUpdateDetail/ViewGDUpdateDetail";
import { useState } from "react";
import { UPDATE_TASK_ACTIONS, VIEW_GD_UPDATES_ACTIONS } from "./enum";
import GuideDowntimeModal from "./modal/GuideDowntimeModal/GuideDowntimeModal";
import CannedTasksModal from "components/dashboard/TrainSAM/Updates/common/modal/CannedTasksModal/CannedTasksModal";
import { GUIDE_FLOW_TYPES } from "../../enum";
import ViewGDUpdateEditActions from "./ViewGDUpdateEditActions/ViewGDUpdateEditActions";

const { EDIT_TASK, CREATE_TASK } = UPDATE_TASK_ACTIONS;
const ViewGDUpdates = ({
    selectedStep,
    selectedStepType,
    handleSuccess,
    handleRefresh,
    selectUpdate,
    selectedUpdate,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [showTaskModal, toggleTaskModal] = useState(false);
    const [selectedTask, selectTask] = useState();

    const [modalAction, setModalAction] = useState("");

    const handleModalAction = (actionType, isTask, task) => {
        setModalAction(actionType);
        selectTask(task);
        if (isTask) {
            toggleTaskModal(true);
        } else {
            toggleModal(true);
        }
    };

    const { guideFlow } = selectedStep || {};
    const { agentUpdates } = guideFlow || {};

    const [agentUpdateTasks, setAgentUpdateTasks] = useState([]);

    const isTaskEdit = modalAction === EDIT_TASK;

    const isDowntimeGuide =
        selectedStepType === GUIDE_FLOW_TYPES.DOWNTIME_GUIDE_FLOW;
    return (
        <>
            <section className='view-gd-updates'>
                <div className='view-gd-updates__heading'>
                    <h5>Updates</h5>
                    {guideFlow && (
                        <AddButton
                            btnText={"Add update"}
                            onClick={() =>
                                handleModalAction(
                                    VIEW_GD_UPDATES_ACTIONS.CREATE_UPDATE
                                )
                            }
                        />
                    )}
                </div>

                {agentUpdates?.length > 0 ? (
                    <>
                        <div>
                            <ul className='view-gd-updates__list'>
                                {agentUpdates?.map((update, key) => (
                                    <ViewGDUpdateItem
                                        key={key}
                                        {...update}
                                        isActive={
                                            selectedUpdate?.agentUpdateId ===
                                            update?.agentUpdateId
                                        }
                                        onClick={() => selectUpdate(update)}
                                    />
                                ))}
                                {!isDowntimeGuide ? (
                                    <li>
                                        <AddButton
                                            btnText={"Add Task"}
                                            onClick={() =>
                                                handleModalAction(
                                                    CREATE_TASK,
                                                    true
                                                )
                                            }
                                        />
                                    </li>
                                ) : (
                                    <ViewGDUpdateEditActions
                                        className={
                                            "view-gd-updates__list__actions"
                                        }
                                        handleModalAction={handleModalAction}
                                    />
                                )}
                            </ul>
                        </div>
                        {selectedUpdate && (
                            <div className='view-gd-updates__details'>
                                {!isDowntimeGuide ? (
                                    <>
                                        <ViewGDUpdateDetail
                                            {...{
                                                agentUpdateTasks,
                                                setAgentUpdateTasks,
                                                selectedUpdate,
                                                handleModalAction,
                                            }}
                                        />
                                        <ViewGDUpdateEditActions
                                            className={"update__actions"}
                                            handleModalAction={
                                                handleModalAction
                                            }
                                        />
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className='view-gd-updates__empty'>
                            <Empty text={"No data provided yet"} />
                        </div>
                    </>
                )}
            </section>
            {showModal && (
                <GuideDowntimeModal
                    {...{
                        showModal,
                        selectedStepType,
                        selectedStep,
                        handleSuccess,
                        selectedUpdate,
                        handleRefresh,
                    }}
                    isEdit={modalAction === VIEW_GD_UPDATES_ACTIONS.EDIT_UPDATE}
                    closeModal={() => toggleModal(false)}
                    modalAction={modalAction}
                />
            )}

            {showTaskModal && (
                <CannedTasksModal
                    {...{
                        modalAction,
                        handleSuccess,
                    }}
                    toggleModal={toggleTaskModal}
                    showModal={showTaskModal}
                    closeModal={() => toggleTaskModal(false)}
                    selectedCannedTask={selectedTask}
                    cannedResourceType={"AUTOMATION"}
                    agentUpdateId={selectedUpdate?.agentUpdateId}
                    isEdit={isTaskEdit}
                    hideChooseAgentTaskType={true}
                    hasAutomationFields
                    title={isTaskEdit ? "Update Task" : "Add Task"}
                />
            )}
        </>
    );
};

export default ViewGDUpdates;
