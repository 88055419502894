export const networkStrengths = {
    OFFLINE: "OFFLINE",
    ONLINE: "ONLINE",
    RECONNECTING: "RECONNECTING",
};

export const MESSAGE_STATUSES = {
    SENDING: "SENDING",
    SENT: "SENT",
    FAILED: "FAILED",
    RESENDING: "RESENDING",
};
