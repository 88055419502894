import { availableInboxApps } from "../../../../enum";

const { GMAIL, TWITTER } = availableInboxApps;

const AppUsersTableHeader = ({ appSource }) => {
    return (
        <div className='app_users_header'>
            <div className='row'>
                <div className='col-3'>
                    <h6>{appSource === TWITTER ? "Username" : "Email"}</h6>
                </div>
                <div className='col-3'>
                    <h6>Date</h6>
                </div>
                {appSource === GMAIL && (
                    <div className='col-3'>
                        <h6>For Emails</h6>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppUsersTableHeader;
