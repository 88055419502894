import React from "react";
import { SideModal } from "components/ui";
import CapsuleClassName from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleClassName/CapsuleClassName";
import AddCapsuleEntryForm from "./AddCapsuleEntryForm/AddCapsuleEntryForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const AddCapsuleEntryModal = ({
    showModal,
    closeModal,
    mainCapsuleClass,
    selectedProduct,
    handleCapsulationSuccess,
    capsuleAction,
    additionRequestObj,
    setCurrentCapsuleAction,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    existingSubCapsules,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            <>
                                Add New{" "}
                                <CapsuleClassName capsule={mainCapsuleClass} />
                            </>
                        }
                        subTitle={
                            <>
                                Create new{" "}
                                <CapsuleClassName capsule={mainCapsuleClass} />{" "}
                                capsule
                            </>
                        }
                    />
                    <AddCapsuleEntryForm
                        additionRequestObj={additionRequestObj}
                        mainCapsuleClass={mainCapsuleClass}
                        handleSuccess={handleCapsulationSuccess}
                        selectedProduct={selectedProduct}
                        capsuleAction={capsuleAction}
                        setCurrentCapsuleAction={setCurrentCapsuleAction}
                        selectedCapsuleClass={selectedCapsuleClass}
                        selectedSecCapsuleClass={selectedSecCapsuleClass}
                        existingSubCapsules={existingSubCapsules}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default AddCapsuleEntryModal;
