import AnalyticsQATicketCapsule from "./AnalyticsQATicketCapsule/AnalyticsQATicketCapsule";
import AnalyticsQATicketSummary from "./AnalyticsQATicketSummary/AnalyticsQATicketSummary";

const AnalyticsQATicketInfo = () => {
    return (
        <div>
            <AnalyticsQATicketSummary />
            <AnalyticsQATicketCapsule />
        </div>
    );
};

export default AnalyticsQATicketInfo;
