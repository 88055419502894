import * as types from "./types";

const initialState = { products: [], selectedProduct: {} };

const ProductReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_PRODUCTS:
            return { ...state, products: payload };

        case types.SET_SELECTED_PRODUCT:
            return { ...state, selectedProduct: payload };

        default:
            return state;
    }
};

export default ProductReducer;
