import imageLinks from "assets/images";
import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import {
    getErrorMessage,
    merge2ObjectArraysUniquely,
    sortFirstAndOthers,
} from "utils/helper";
import BranchesList from "../../Conversations/branches/BranchesList/BranchesList";
import BranchesTimeLineHeader from "./BranchesTimeLineHeader/BranchesTimeLineHeader";
import { setExistingBranchOptionConnections } from "components/dashboard/Branches/BranchView/utils/helper";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const BranchesTimeLine = ({
    branchCollection,
    branchCollectionId,
    handleRefreshConvoMap,
    updateCollectionBranches,
    convoMapBranches,
    collectionBranches,
    mainBranchId,
}) => {
    const [selectedBranch, setSelectedBranch] = useState({});
    const [status, setStatus] = useState("");
    const [branchViewStatus, setBranchViewStatus] = useState(IDLE);

    const [errorMssg, setErrorMssg] = useState("");
    const [bvErrorMssg, setBVErrorMssg] = useState("");
    const [existingOptionConnections, setExistingOptionConnections] = useState(
        {}
    );

    const handleError = (err) => {
        setErrorMssg(getErrorMessage(err));
        setStatus(ERROR);
    };

    // const pushBranchToCollection = (branch) => {
    //     setCollectionBranches((prevData) => ([...prevData, branch]));
    //     setSelectedBranch(branch)
    //     setStatus(DATAMODE)
    //     setBranchViewStatus(DATAMODE)
    // }

    const getCollectionBranches = async (branchId) => {
        try {
            setStatus(LOADING);
            const res = await API.get(
                apiRoute?.getCollectionBranches(branchCollectionId)
            );
            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    updateCollectionBranches(data);
                    handleBranchSelect(branchId ? branchId : mainBranchId);
                    mergeConvoBranches(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleBranchSelect = async (branchId) => {
        try {
            setSelectedBranch({ branchId });
            setBranchViewStatus(LOADING);
            const url = apiRoute?.getBranch(branchId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;

                setSelectedBranch(data);
                // --- update the modified particular branch in the list
                // updateCollectionBranches((prevBranches) =>
                //     prevBranches?.map((branch) => {
                //         return data ? branch?.branchId === branchId : branch;
                //     })
                // );

                setBranchViewStatus(DATAMODE);
                setExistingBranchOptionConnections(
                    data,
                    setExistingOptionConnections
                );
            }
        } catch (err) {
            setBVErrorMssg(getErrorMessage(err));
            setBranchViewStatus(ERROR);
        }
    };

    const handleRefresh = (branchId) => {
        getCollectionBranches(branchId);
    };

    const mergeConvoBranches = (data) => {
        const mergedBranches = merge2ObjectArraysUniquely(
            convoMapBranches,
            data ? data : collectionBranches,
            "branchId"
        );

        const sortedMergedBranches = sortFirstAndOthers(
            "branchId",
            mainBranchId,
            mergedBranches
        );
        updateCollectionBranches(sortedMergedBranches);
    };

    const handleSuccess = (branchId) => {
        if (branchId) {
            handleBranchSelect(branchId);
        } else {
            getCollectionBranches();
        }
        handleRefreshConvoMap(branchId);
    };

    useEffect(() => {
        getCollectionBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainBranchId]);

    useEffect(() => {
        mergeConvoBranches();
        // eslint-disable-next-line
    }, [convoMapBranches]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        className={"empty__container"}
                        text='Add a branch to build a conversation'
                        icon={imageLinks?.images?.branch}
                    />
                );

            case DATAMODE:
                return (
                    <div>
                        <BranchesList
                            branches={collectionBranches}
                            handleBranchSelect={handleBranchSelect}
                            selectedBranch={selectedBranch}
                            showEmptyOptionLabel
                        />
                        <BranchView
                            errorMssg={bvErrorMssg}
                            status={branchViewStatus}
                            branch={selectedBranch}
                            handleRefresh={(branchId) =>
                                handleSuccess(branchId)
                            }
                            hideCapsuleContentGroup={true}
                            collectionId={branchCollectionId}
                            existingOptionConnections={
                                existingOptionConnections
                            }
                        />
                    </div>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCollectionBranches}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='mt-5'>
            <BranchesTimeLineHeader
                source={branchCollection?.branchCollectionType}
                referenceData={branchCollection}
                collectionBranches={collectionBranches}
                handleRefresh={handleRefresh}
            />
            {renderBasedOnStatus()}
        </div>
    );
};

export default BranchesTimeLine;
