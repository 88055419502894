export const SWITCHES_TYPES = {
    ON_SWITCH: "ON",
    OFF_SWITCH: "OFF",
};

export const STATUS_CARD_TYPES = {
    ON_TYPE: "ON",
    OFF_TYPE: "OFF",
};

export const SWITCHES_HEADERS = ["Name", "Status", ""];

export const CAPSULE_STATUS_LISTING_TABLE_HEADERS = [
    "Name",
    "Status",
    "",
];

export const DEPENDENCY_ACTIONS = {
    DELETE_DEPENDENCY: "DELETE_DEPENDENCY",
    EDIT_DEPENDENCY: "EDIT_DEPENDENCY",
};


export const STATUS_MAP_OPTIONS = { DAILY: "DAILY", WEEKLY: "WEEKLY" };

export const STATUS_MAP_OPTION_STATES = {
    DAILY: false,
    WEEKLY: true,
};


export const STATUS_METRICS = {
    uptimeAveragePercentage: "uptime",
    downtimeAveragePercentage: "downtime",
};

export const STATUS_MAP_COLORS = {
    true: "#44DAA5",
    false: "#FF686B",
};
