import AddButton from "components/ui/Button/AddButton/AddButton";
import { Button } from "components/ui";
import "./ViewGDSteps.scss";
import { GUIDE_FLOW_TYPES } from "../../enum";

const ViewGDSteps = ({
    isDowntime = true,
    handleSelectStep,
    toggleModal,
    selectedStep,
}) => {
    return (
        <section className='view-gd-steps'>
            <div className='view-gd-steps__buttons'>
                <Button
                    text='Downtime'
                    classType={isDowntime ? "primary" : "plain"}
                    otherClass={!isDowntime ? "plain-button" : ""}
                    onClick={() =>
                        handleSelectStep(GUIDE_FLOW_TYPES.DOWNTIME_GUIDE_FLOW)
                    }
                />
                <Button
                    text='Uptime'
                    classType={isDowntime ? "plain" : "primary"}
                    otherClass={isDowntime ? "plain-button" : ""}
                    onClick={() =>
                        handleSelectStep(GUIDE_FLOW_TYPES.UPTIME_GUIDE_FLOW)
                    }
                />
            </div>
            <div>
                {!selectedStep && (
                    <AddButton
                        btnText={"Add Step"}
                        onClick={() => {
                            handleSelectStep();
                            toggleModal(true);
                        }}
                    />
                )}
            </div>
        </section>
    );
};

export default ViewGDSteps;
