import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { emailAttachmentTypes } from "../enum";

const { FILE, TICKET } = emailAttachmentTypes;

const EmailAttachmentDropdown = ({ handleOpenEmailAttachmentModal }) => {
    const [dropdownOpen, toggleDropdown] = useState(false);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <ReactSVG
                src={imageLinks?.icons?.attachmentIcon}
                className='send__email__actions--attachment'
                onClick={() => toggleDropdown((prevState) => !prevState)}
            />
            <DropdownMenu>
                <DropdownItem
                    onClick={() => handleOpenEmailAttachmentModal(FILE)}>
                    File
                </DropdownItem>
                <DropdownItem
                    onClick={() => handleOpenEmailAttachmentModal(TICKET)}>
                    Ticket
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default EmailAttachmentDropdown;
