import AnalyticsQATVariationItem from "./AnalyticsQATVariationItem/AnalyticsQATVariationItem";
import "./AnalyticsQATVariations.scss";

const AnalyticsQATVariations = () => {
    const variations = [
        {
            title: "New replies",
            variations: ["Reply 1.0", "", ""],
        },
        {
            title: "Response time",
            variations: ["Reply 1.0", "", ""],
        },
        {
            title: "Process Change",
            variations: ["Variant 1.0", "", ""],
        },
    ];
    return (
        <div className='analytics-qat-variations'>
            {variations?.map(({ title, variations }, key) => (
                <AnalyticsQATVariationItem
                    title={title}
                    variations={variations}
                    key={key}
                />
            ))}
        </div>
    );
};

export default AnalyticsQATVariations;
