import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import ConversationTemplateForm from "./ConversationTemplateForm/ConversationTemplateForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const ConversationTemplateModal = ({
    showSideModal,
    closeModal,
    isEdit,
    createTemplateType,
    editTemplateData,
    handleRefresh,
}) => {
    return (
        <SideModal show={showSideModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={`${isEdit ? "Edit" : "Create"} Template`}
                        subTitle={
                            isEdit
                                ? `Edit ${editTemplateData?.templateType}`
                                : "Create a new template"
                        }
                    />
                    <ConversationTemplateForm
                        closeModal={closeModal}
                        createTemplateType={createTemplateType}
                        editTemplateData={editTemplateData}
                        isEdit={isEdit}
                        handleRefresh={handleRefresh}
                    />
                </div>
            </div>
        </SideModal>
    );
};

ConversationTemplateModal.prototypes = {
    showSideModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    editTemplateData: PropTypes.object,
};

export default ConversationTemplateModal;
