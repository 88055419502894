import { DEPENDENCY_ACTIONS } from "components/dashboard/TrainSAM/Dependency/enum";
import { Dropdown } from "components/ui";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_DEPENDENCY } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const SwitchesTableActions = ({
    dependencyName,
    dependencyId,
    dependencyElements,
    handleActionModal,
}) => {
    return (
        <>
            <Dropdown
                size='sm'
                ddValueClass='borderless'
                className='table__dropdown'
                ddOptionsClass='table__dropdown'
                onChange={({ value }) =>
                    handleActionModal(value, {
                        dependencyName,
                        dependencyId,
                        dependencyElements,
                    })
                }
                options={[
                    {
                        value: DEPENDENCY_ACTIONS.EDIT_DEPENDENCY,
                        label: "Edit",
                        baseResources: [`${UPDATE}_${KB_DEPENDENCY}`],
                    },
                    {
                        value: DEPENDENCY_ACTIONS.DELETE_DEPENDENCY,
                        label: "Delete",
                        baseResources: [`${DELETE}_${KB_DEPENDENCY}`],
                    },
                ]}
            />
        </>
    );
};

export default SwitchesTableActions;
