import { useState } from "react";
import PropTypes from "prop-types";
import SearchMessageTag from "./SearchMessageTag/SearchMessageTag";
import CreateMessageTagButton from "./CreateMessageTagButton/CreateMessageTagButton";
import CreateMessageTagModal from "./CreateMessageTagModal/CreateMessageTagModal";
import Heading from "components/ui/Heading/Heading";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CreateMessageTagHeader.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const CreateMessageTagHeader = ({ fetchMessageTags, handleSearch,searchDefaultValue }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Heading
                headingText={"Create tags"}
                headingSubText='List of tags'
            />
            <div className='message__tag__header'>
                <div>
                    <SearchMessageTag handleSearch={handleSearch} searchDefaultValue={searchDefaultValue}/>
                </div>
                <div>
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <CreateMessageTagButton
                            openModal={() => setShowModal(true)}
                        />
                    </ComponentAccessControl>
                </div>
            </div>

            {showModal && (
                <CreateMessageTagModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    handleSuccess={fetchMessageTags}
                />
            )}
        </>
    );
};

CreateMessageTagHeader.prototypes = {
    fetchMessageTags: PropTypes.func.isRequired,
    toastResponseStatus: PropTypes.func.isRequired,
};

export default CreateMessageTagHeader;
