import asset from "assets/images";
import { ReactSVG } from "react-svg";
import "./TicketAAMainInstruction.scss";
import { useState } from "react";

const TicketAAMainInstruction = ({ instruction }) => {
    const [open, toggleOpen] = useState(true);
    return (
        <section className='ticketaa-instruction'>
            <div>
                <h5>Main Instruction</h5>
                <ReactSVG
                    src={open ? asset.icons.upside : asset.icons.downside}
                    onClick={() => toggleOpen(!open)}
                />
            </div>
            {open && <p>{instruction}</p>}
        </section>
    );
};

export default TicketAAMainInstruction;
