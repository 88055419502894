import { Button, Empty } from "components/ui";
import "./EmptyMessageTagList.scss";

export const EmptyMessageTagList = () => {
    return (
        <>
            <div className='tag__list'>
                <Button
                    text='Tags will appear here'
                    type='button'
                    classType='outline-secondary text-dark'
                    otherClass='btn-xs fw-medium me-2'
                />
            </div>
            <div className='key__Phrase__header'>
                <h1 className='key__Phrase__header--text'>
                    Key words & phrases
                </h1>
            </div>
            <div className='tagging__body'>
                <Empty />
            </div>
            <div className='suggestion__header'>
                <h1 className='suggestion__header--text'>
                    Conversation Suggestions
                </h1>
            </div>
            <div className='tagging__suggestions'>
                <Empty />
            </div>
            <div className='canned__response__header'>
                <h1 className='canned__response__header--text'>
                    Canned responses
                </h1>
            </div>
            <div className='tagging__body'>
                <Empty />
            </div>
        </>
    );
};
