import React, { useEffect } from "react";
import SelectUI from "components/ui/SelectUI/SelectUI";

const FilterDateSort = ({ handleSortByDate, filterOption }) => {
    const options = [
        { label: "Newest First", value: "desc", icon: "radio" },
        {
            label: "Oldest First",
            value: "asc",
            icon: "radio",
        },
    ];

    useEffect(() => {
        if (!filterOption?.value) {
            const { value, ...option } = options[1];
            handleSortByDate?.(value, option);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='filter__select'>
                <SelectUI
                    name='filterByDate'
                    id='filterByDate'
                    placeholder='Date Created'
                    hasOptionIcon={true}
                    options={options}
                    defaultValue={
                        filterOption?.value?.length > 0
                            ? filterOption
                            : options[1]
                    }
                    onChange={({ value, ...option }) =>
                        handleSortByDate?.(value, option)
                    }
                />
            </div>
        </>
    );
};

export default FilterDateSort;
