export const ADD_BRANCH_TO_CONVERSATION = "ADD_BRANCH_TO_CONVERSATION";

export const branchFormActions = {
    CREATE_DOWNTIME_BRANCH: "CREATE_DOWNTIME_BRANCH",
    CREATE_PRIMARY_BRANCH: "CREATE_PRIMARY_BRANCH",
    CREATE_GENERAL_BRANCH: "CREATE_GENERAL_BRANCH",
    EDIT_BRANCH: "EDIT_BRANCH",
    EDIT_CONVERSATION_BREAKER: "EDIT_CONVERSATION_BREAKER",
    CREATE_GUIDE_BRANCH:"CREATE_GUIDE_BRANCH"
};

export const branchOptionActionTypes = Object.freeze({
    FORWARD_AGENT: "Send Ticket to Agent",
    CLOSE_CONVERSATION: "Close Conversation",
    RESTART_CONVERSATION: "Restart Conversation",
});

export const CONVERSATIONS_PERMS_ACTIONS = {
    CREATE_CONVERSATION: "CREATE_CONVERSATION",
    PIN_CONVERSATION: "PIN_CONVERSATION",
    DELETE_CONVERSATION: "DELETE_CONVERSATION",
    COPY_CONVERSATION: "COPY_CONVERSATION",
};
