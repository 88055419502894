import React, { useState } from "react";
import { useSelector } from "react-redux";
import imageLinks from "assets/images";
import { ReactSVG } from "react-svg";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CommentDropdown.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const CommentDropdown = ({
    handleShowDeleteModal,
    handleSetCurrentComment,
    handleResolveComment,
    authorId,
}) => {
    const [dropdownOpen, toggleDropdown] = useState(false);
    const {
        user: { userId },
    } = useSelector((state) => state.auth);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            className={`added__collection`}
            direction='up'
            id='comment__dropdown'
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <ReactSVG
                src={imageLinks?.icons?.carbonMore}
                onClick={() => toggleDropdown((prevState) => !prevState)}
                className='comment__dropdown__icon'
            />
            <DropdownMenu className='comment__dropdown__menu '>
                {authorId === userId && (
                    <>
                        <ComponentAccessControl
                            baseResources={[
                                `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                            ]}>
                            <DropdownItem onClick={handleSetCurrentComment}>
                                Edit
                            </DropdownItem>
                        </ComponentAccessControl>
                        <ComponentAccessControl
                            baseResources={[
                                `${DELETE}_${AGENTS_INBOX_BASE_SETTING}`,
                            ]}>
                            <DropdownItem onClick={handleShowDeleteModal}>
                                Delete
                            </DropdownItem>
                        </ComponentAccessControl>
                    </>
                )}
                <ComponentAccessControl
                    baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <DropdownItem onClick={handleResolveComment}>
                        Resolve
                    </DropdownItem>
                </ComponentAccessControl>
            </DropdownMenu>
        </Dropdown>
    );
};

export default CommentDropdown;
