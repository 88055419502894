export const uniqueColumnElements = (columnElements) => {
    const uniqueIds = new Set();

    const uniqueElements = columnElements
        ?.filter((element) => element !== undefined)
        ?.filter(({ element }) => {
            const isDuplicate = uniqueIds.has(element?.branchId);

            uniqueIds.add(element?.branchId);

            if (!isDuplicate) {
                return true;
            }

            return false;
        });

    return uniqueElements;
};
