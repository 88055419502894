import asset from "assets/images";
import "../Modal.scss";
import { ReactSVG } from "react-svg";

export const SideModal = ({ show, close, children, showCloseIcon = true, otherclassNames }) => {
    return (
        <div
            className={`side-modal ${
                show
                    ? "active"
                    : ""
            } ${otherclassNames ? otherclassNames : ''}`}>
            <div className='row side-overlay'>
                <div className='col-4 col-lg-7 d-flex justify-content-end px-0'>
                    {showCloseIcon && (
                        <div className='close-modal-btn' onClick={close}>
                            <ReactSVG src={asset?.icons?.crossIcon} alt='close' />
                        </div>
                    )}
                </div>
                <div className='col-8 col-lg-5 side-modal--container'>
                    {show ? children : null}
                </div>
            </div>
        </div>
    );
};
