import React, { useContext } from "react";
import AttachmentFileInput from "components/ui/AttachmentFileInput/AttachmentFileInput";
import { mediaType } from "../../MessageBody/Messages/Message/enums";
import ModalPreview from "../../MessageBody/ModalPreview/ModalPreview";
import { getFileFormat } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./UploadIcons.scss";

const { ERROR } = dataQueryStatus;
const { IMAGE } = mediaType;

const UploadIcons = ({
    uploads,
    updateUploads,
    isDisabled,
    showModal,
    toggleModal,
    handleUpload,
    selectedMedia,
    acceptedFiles = '.pdf,.doc,.docx,video/*,image/png,image/jpeg,image/jpg'
}) => {
    const toastMessage = useContext(ToastContext);
    const uploadFile = (files) => {
        if (uploads?.length > 5 || uploads?.length + files?.length > 5) {
            return toastMessage("Maximum of 5 files", ERROR);
        }
        const uploaded = [];
        let error;

        files?.map((file) => {
            const uploadType = getFileFormat(file?.type);

            if (
                uploadType === IMAGE
                    ? file.size > 20971520
                    : file.size > 20971520
            ) {
                error =
                    "Large files have been excluded. (Image, 20MB) (File, 20MB)";

                return error;
            }

            const url = URL.createObjectURL(file);

            const uploadObj = {
                file,
                fileAttachmentUrl: url,
                fileAttachmentType: uploadType,
                fileAttachmentName: file?.name,
                fileLocalUrl: url,
            };

            updateUploads((prev) => [...prev, uploadObj]);
            return uploaded.push(uploadObj);
        });

        if (error) {
            toastMessage(error, ERROR);
        }
        handleUpload(uploaded);
    };
    const handleFileEvent = ({ target: { files } }) => {
        if (files) {
            const selectedFiles = Array.prototype.slice.call(files);
            // maximum of five files
            const firstFiveFiles = selectedFiles?.slice(0, 5);
            uploadFile(firstFiveFiles);
        }
    };

    return (
        <div className='upload'>
            <div className='upload--icons'>
                <AttachmentFileInput
                    id='file'
                    accept={acceptedFiles}
                    onChange={handleFileEvent}
                    disabled={isDisabled}
                    file={uploads}
                    multiple={true}>
                    <ReactSVG
                        src={imageLinks?.icons?.attachmentIcon}
                        className={`cursor-pointer ${
                            isDisabled ? "upload__disabled" : ""
                        }`}
                    />
                </AttachmentFileInput>
            </div>
            {showModal && (
                <ModalPreview
                    showModal={showModal}
                    toggleModal={() => toggleModal(false)}
                    preview={selectedMedia?.fileAttachmentUrl}
                    previewType={selectedMedia?.fileAttachmentType}
                    fileName={selectedMedia?.fileAttachmentName}
                />
            )}
        </div>
    );
};

export default UploadIcons;
