import { STATUS_CARD_TYPES } from "../../../enum";
import CapsuleStatusCard from "./CapsuleStatusCard/CapsuleStatusCard";
import "./CapsuleStatusCardGroup.scss";

const { ON_TYPE, OFF_TYPE } = STATUS_CARD_TYPES;

const CapsuleStatusCardGroup = ({
    selectedCapsuleClass,
    handleCapsulesTotalCount,
    refresh,
}) => {
    return (
        <div className='caspsule__status__header'>
            <div className='left-card'>
                <CapsuleStatusCard
                    capsuleClass={selectedCapsuleClass}
                    capsuleCardType={ON_TYPE}
                    handleCapsulesTotalCount={handleCapsulesTotalCount}
                    refresh={refresh}
                />
            </div>
            <div>
                <CapsuleStatusCard
                    capsuleClass={selectedCapsuleClass}
                    capsuleCardType={OFF_TYPE}
                    handleCapsulesTotalCount={handleCapsulesTotalCount}
                    refresh={refresh}
                />
            </div>
        </div>
    );
};

export default CapsuleStatusCardGroup;
