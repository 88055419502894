import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import AddFormCRModalForm from "./AddFormCRModalForm/AddFormCRModalForm";
import { POST_CANNED_RESPONSE_ACTIONS } from "../../enums";

const { EDIT_CANNED_RESPONSE, VIEW_CANNED_RESPONSE } =
    POST_CANNED_RESPONSE_ACTIONS;

const AddFormCannedRespModal = ({
    show,
    closeModal,
    referenceData,
    handleSuccess,
    actionModalType,
    cannedResponse,
    existingCannedReponses,
}) => {
    const isViewOnly = actionModalType === VIEW_CANNED_RESPONSE;
    const isEdit = actionModalType === EDIT_CANNED_RESPONSE;
    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isEdit
                                ? "Update Canned Response"
                                : isViewOnly
                                ? "View Canned Response"
                                : "Add Canned Response"
                        }
                    />
                    <AddFormCRModalForm
                        referenceData={referenceData}
                        handleSuccess={handleSuccess}
                        isEdit={isEdit}
                        isViewOnly={isViewOnly}
                        actionModalType={actionModalType}
                        closeModal={closeModal}
                        cannedResponse={cannedResponse}
                        existingCannedReponses={existingCannedReponses}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default AddFormCannedRespModal;
