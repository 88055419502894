import { useEffect, useState } from "react";

import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";

import { Empty, Loading } from "components/ui";

import ViewSwitchesListing from "./ViewSwitchesListing/ViewSwitchesListing";
import ViewSwitchesHeader from "./ViewSwitchesHeader/ViewSwitchesHeader";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";

const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const ViewSwitchesContainer = ({ switchType }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();

    const [dependencies, setDependencies] = useState([]);

    const [tableLoading, setTableLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const [filters, updatePageFilters] = useState({
        search: "",
        currentPage: 1,
        pageSize: 5,
    });

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        updatePageFilters({ ...filters, currentPage });
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        updatePageFilters({ ...filters, search });
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <>
                        <ViewSwitchesListing
                            dependencies={[]}
                            getDependencies={() => {
                                setClearSearch(true);
                                updatePageFilters({
                                    search: "",
                                    currentPage: 1,
                                    pageSize: 5,
                                });
                            }}
                            tableLoading={tableLoading}
                            handleSearch={handleSearch}
                            switchType={switchType}
                            clearSearch={clearSearch}
                        />
                        <Empty
                            text={
                                useTableLoader
                                    ? `No ${switchType} switch matches the search.`
                                    : `No ${switchType} switch has been created yet.`
                            }
                        />
                    </>
                );

            case DATAMODE:
                return (
                    <>
                        <ViewSwitchesListing
                            dependencies={dependencies}
                            getDependencies={() => {
                                setClearSearch(true);
                                updatePageFilters({
                                    search: "",
                                    currentPage: 1,
                                    pageSize: 5,
                                });
                            }}
                            tableLoading={tableLoading}
                            handleSearch={handleSearch}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filters}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getDependencies}
                    />
                );

            default:
                return "";
        }
    };

    const getDependencies = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader ? setTableLoading(true) : setStatus(LOADING);

            const url = apiRoute?.getDependency;
            const res = await API.get(url, {
                params: { ...filters, status: switchType },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data, metaData } = res.data;
                data?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
                setTableLoading(false);
                setDependencies(data);
                setTotalRecords(metaData?.totalRecords);
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableLoading(false) : setStatus(ERROR);
            setSearchIsLoading(false);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getDependencies(useTableLoader, httpRequest);
        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchType, filters, useTableLoader]);

    return (
        <>
            <ViewSwitchesHeader switchType={switchType} />

            <div className='padded__layout pt-3'>
                <div className='dependency'>{renderBasedOnStage()}</div>
            </div>
        </>
    );
};

export default ViewSwitchesContainer;
