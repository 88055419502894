import { Tag } from "components/ui";
import { useHistory } from "react-router-dom";

const ScenariosGuides = ({ guides }) => {
    const history = useHistory();

    const routeToViewGuide = (agentGuideId) => {
        return history.push(`/t-sam/guide/view/${agentGuideId}`);
    };

    return (
        <>
            {guides?.length ? (
                <div className='scenarios__guides'>
                    {guides?.map?.(({ agentGuideName, agentGuideId }, key) => (
                        <Tag
                            name={agentGuideName}
                            key={key}
                            popClass={"scenarios__guides__popup"}
                            popContent={
                                <div>
                                    <span
                                        onClick={() =>
                                            routeToViewGuide(agentGuideId)
                                        }>
                                        View Guide
                                    </span>
                                </div>
                            }
                        />
                    ))}
                </div>
            ) : (
                "No guide has been added yet"
            )}
        </>
    );
};

export default ScenariosGuides;
