import React from "react";
import { connect } from "react-redux";
import { defaultPermissions } from "routes/enum";

const SideBarAccessControl = ({ baseResources, children, permissions }) => {
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    let permissionList = permissionCodes?.length > 0 ? permissionCodes : [];

    return (
        <>
            {[...permissionList, ...defaultPermissions]?.some((code) =>
                baseResources?.includes(code)
            ) ? (
                <>{children}</>
            ) : (
                <></>
            )}
        </>
    );
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
    }),
    {}
)(SideBarAccessControl);
