import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import CapsuleClassesGroup from "components/dashboard/KnowledgeBase/CapsuleSettings/common/CapsuleClassesGroup/CapsuleClassesGroup";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const AttachCapsuleClassForm = ({
    selectedCapsule,
    handleCapsulationSuccess,
    closeModal,
}) => {
    const [loading, setLoading] = useState(false);
    const [existingParentsIds, setParentIds] = useState([]);
    const [errorMsg, setErrorMsg] = useState();
    const [childrenCapsuleClasses, updateRelationshipClassList] = useState([]);
    const toastMessage = useContext(ToastContext);

    useEffect(() => {
        const { children, parents } = selectedCapsule;
        let eParentsIds = parents.map((a) => a.capsuleClassId);
        setParentIds(eParentsIds);
        // check if the elements in the previous list exist in the
        const classList = [...children, ...parents];
        updateRelationshipClassList(classList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCapsuleSelect = (capsule) => {
        updateRelationshipClassList((prevList) => [...prevList, capsule]);
    };

    const handleDetach = (capsuleId) => {
        updateRelationshipClassList((prevList) =>
            prevList.filter((x) => x.capsuleClassId !== capsuleId)
        );
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const parentCapsuleClassIds = [];
            const childCapsuleClassIds = [];

            childrenCapsuleClasses.forEach((capsule) => {
                let probableParent = existingParentsIds.includes(
                    capsule.capsuleClassId
                );
                probableParent
                    ? parentCapsuleClassIds.push(capsule.capsuleClassId)
                    : childCapsuleClassIds.push(capsule.capsuleClassId);
            });

            const url = apiRoute?.relateCapsules;
            const res = await API.post(url, {
                capsuleClassId: selectedCapsule.capsuleClassId,
                parentCapsuleClassIds,
                childCapsuleClassIds,
            });

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleCapsulationSuccess(selectedCapsule);
                closeModal();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <p className='form-label text-dark mb-3'>
                Attach one of the unattached classes
            </p>
            <CapsuleClassesGroup
                filterClasses={[
                    ...childrenCapsuleClasses,
                    { ...selectedCapsule },
                ]}
                handleCapsuleSelect={handleCapsuleSelect}
            />

            <div className='capsule_tags mb-0 w-100 d-flex flex-wrap flex-gap  flex-gap--3 align-content-start'>
                {childrenCapsuleClasses.map((item, i) => (
                    <Capsule
                        key={i}
                        capsule={item}
                        showRemove={true}
                        handleRemove={handleDetach}
                    />
                ))}
            </div>
            <Button
                type='submit'
                text='Save Changes'
                classType='primary'
                loading={loading}
                disabled={childrenCapsuleClasses?.length === 0}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

AttachCapsuleClassForm.prototypes = {
    parentCapsuleClassId: PropTypes.string.isRequired,
};

export default AttachCapsuleClassForm;
