import React from "react";
import asset from "assets/images";
import { ReactSVG } from "react-svg";

export const GeneralLayout = ({ children }) => {

	return (
		<div
			className="row vh-100 general-layout">
			<div className="d-none d-lg-flex col-lg-5 h-100 bg-primary layout--left">
				<div className="container py-5 ps-4 pe-2 ps-xl-5 pe-xl-5">
					<ReactSVG
						src={asset?.images?.logoLight}
						fallback="Metacare"
						className="metacare--logo"
					/>
					<h1 className="text-white fw-bold main-title">
						Scalable customer service is now
					</h1>
					<p className="reg-layout--text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus
						nunc, sit purus dui congue quis maecenas mi diam.
					</p>
					<div className="d-flex align-items-center">
						<img src={asset?.images?.peopleImg} alt="people" className="me-1 people" />{" "}
						<p className="text-white mb-0">Metacare is for everyone 🎉🌟</p>
					</div>
				</div>
			</div>
			<div className="col-12 col-lg-7 h-100 overflow-auto scrollba-wide">
				<div className="container p-5">
					<ReactSVG
						src={asset?.images?.logoDark}
						fallback="Metacare"
						className="mb-5 d-block d-lg-none mx-auto"
					/>
					<div className="w-100 mt-lg-5">
						<div className="row">
							<div className="col-md-8 offset-md-2">
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
