import PropTypes from "prop-types";
import React from "react";
import { SideModal } from "components/ui";
import AddBranchForm from "./AddBranchForm/AddBranchForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { END_FORM_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import CreateGuideBranchForm from "components/dashboard/TrainSAM/Guides/form/CreateGuideBranchForm/CreateGuideBranchForm";

const AddBranchModal = ({
    show,
    closeModal,
    referenceData,
    source,
    handleBranchAddSuccess,
    existingBranches,
    isAgentGuideView,
    handleRefresh,
}) => {
    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isAgentGuideView
                                ? "Add a new branch"
                                : source === END_FORM_COLLECTION
                                ? "Create a Concluding Branch"
                                : "Add Branch"
                        }
                    />
                    {isAgentGuideView ? (
                        <CreateGuideBranchForm
                            referenceData={referenceData}
                            handleSuccess={handleBranchAddSuccess}
                            handleRefresh={handleRefresh}
                            isAgentGuideView={isAgentGuideView}
                            closeModal={closeModal}
                        />
                    ) : (
                        <AddBranchForm
                            referenceData={referenceData}
                            source={source}
                            handleBranchAddSuccess={handleBranchAddSuccess}
                            existingBranches={existingBranches}
                        />
                    )}
                </div>
            </div>
        </SideModal>
    );
};

AddBranchModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    referenceData: PropTypes.any,
    show: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
};

export default AddBranchModal;
