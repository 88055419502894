import React, { useContext } from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { branchOptionActions } from "../BranchOption/enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { Button } from "components/ui";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./BranchOptionViewHeading.scss";

const { ERROR } = dataQueryStatus;
const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { CREATE, UPDATE } = baseResourceActions;
const { CREATE_BRANCH_OPTION, CREATE_CONVERSATION_BREAKER_OPTION } =
    branchOptionActions;

const BranchOptionViewHeading = ({
    toggleModal,
    updateModalActionType,
    isConversationBreakerView,
    branchOptionsCount,
    optionsPayload,
    showSaveChangesBtn,
    handleSuccess,
    handleCancel,
    isAgentGuideView,
}) => {
    const toastMessage = useContext(ToastContext);

    const updateOptionsOrder = async () => {
        try {
            const url = apiRoute.updateOptionsOrder;
            const res = await API.put(url, {
                branchOptions: optionsPayload,
            });

            if (res?.status === 200) {
                const { message } = res?.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            const error = getErrorMessage(err);
            toastMessage(error, ERROR);
        }
    };

    return (
        <>
            <div className='branch__option--heading'>
                <div className='row align-items-center'>
                    <div className='col-md-5 option__header__container'>
                        <h6 className='option__header'>
                            {isAgentGuideView ? "Next Steps" : "Options"}
                        </h6>
                        {showSaveChangesBtn && (
                            <div className='update__order'>
                                <ComponentAccessControl
                                    baseResources={[
                                        `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                    ]}>
                                    <Button
                                        type='submit'
                                        classType='primary'
                                        otherClass='update__order--send__button'
                                        onClick={updateOptionsOrder}>
                                        <ReactSVG
                                            src={imageLinks?.icons?.lightCheck}
                                            className='update__order--send__svg'
                                        />
                                    </Button>
                                </ComponentAccessControl>
                                <ReactSVG
                                    src={imageLinks?.icons?.lightCancel}
                                    className='update__order--cancel__svg'
                                    onClick={handleCancel}
                                />
                            </div>
                        )}
                    </div>
                    {(branchOptionsCount < 4 || !branchOptionsCount) && (
                        <div className='col-md-2 ms-auto'>
                            <ComponentAccessControl
                                baseResources={[
                                    `${CREATE}_${KB_SAM_BRANCH}`,
                                    `${CREATE}_${KB_SAM_COLLECTION}`,
                                    `${CREATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                    `${CREATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                                ]}>
                                {isAgentGuideView ? (
                                    <Button
                                        text='Add Next Steps'
                                        classType='plain'
                                        otherClass='next-step-btn'
                                        // otherClass='justify-content-end'
                                        onClick={() => {
                                            updateModalActionType(
                                                isConversationBreakerView
                                                    ? CREATE_CONVERSATION_BREAKER_OPTION
                                                    : CREATE_BRANCH_OPTION
                                            );
                                            toggleModal(true);
                                        }}
                                    />
                                ) : (
                                    <AddButton
                                        btnText={`Create option`}
                                        otherClassName='justify-content-end'
                                        onClick={() => {
                                            updateModalActionType(
                                                isConversationBreakerView
                                                    ? CREATE_CONVERSATION_BREAKER_OPTION
                                                    : CREATE_BRANCH_OPTION
                                            );
                                            toggleModal(true);
                                        }}
                                    />
                                )}
                            </ComponentAccessControl>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BranchOptionViewHeading;
