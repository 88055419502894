import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import PersonasHeader from "./PersonasHeader/PersonasHeader";
import { dataQueryStatus } from "utils";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import PersonasTable from "./PersonasTable/PersonasTable";
import { Loading, Empty } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { setPersonasFilterParams } from "store/filters/actions";
import {
    savePersonas,
    clearPersonas,
} from "store/modules/serviceModule/actions";

const { IDLE, ERROR, LOADING, NULLMODE, DATAMODE } = dataQueryStatus;

const Personas = () => {
    const [errorMssg, setErrorMssg] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const toastMessage = useContext(ToastContext);
    const { filterParams } = useSelector(
        (state) => state?.filters?.personasFilters
    );

    const [personas, setPersonas] = useState([]);
    const {
        personasCache: { pages, totalRecords },
    } = useSelector((state) => state?.serviceModule);

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setPersonasFilterParams({ currentPage: 1, search }));
        dispatch(clearPersonas());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setPersonasFilterParams({ ...filterParams, currentPage }));
    };

    const handleSuccess = () => {
        getPersonas();
        // dispatch(
        //     setPersonasFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //         active: "",
        //     })
        // );
    };

    const updatePersonaStatus = async (personaId) => {
        try {
            setErrorMssg();
            setTableIsLoading(true);
            const url = apiRoute.updatePersonaStatus(personaId);
            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res.data;
                setTableIsLoading(false);
                toastMessage(message);
                setClearSearch(true);

                handleSuccess();
                getPersonas(true);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setTableIsLoading(false);
        }
    };

    const getPersonas = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader && setTableIsLoading(true);

            if (personas?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.personas;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setPersonas(data);
                setStatus(newStatus);
                dispatch(
                    savePersonas(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setPersonas(cachedPage);
        } else {
            getPersonas(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return (
                    <Empty
                        className='h-100'
                        text={
                            useTableLoader
                                ? "No Persona matches the search."
                                : "No Persona created."
                        }
                    />
                );
            case DATAMODE:
                return (
                    <>
                        <PersonasTable
                            personas={personas}
                            tableIsLoading={tableIsLoading}
                            error={errorMssg}
                            updatePersonaStatus={updatePersonaStatus}
                            handleSuccess={() => {
                                setClearSearch(true);
                                dispatch(clearPersonas());
                                handleSuccess();
                            }}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        size='50'
                        handleRetry={getPersonas}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <div className='padded__layout'>
            <PersonasHeader
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                updatePageFilters={(active) => {
                    dispatch(clearPersonas());
                    dispatch(
                        setPersonasFilterParams({ ...filterParams, active })
                    );
                }}
                statusKey={filterParams?.active}
                searchDefaultValue={filterParams?.search}
            />
            {renderCompBasedOnStage()}
        </div>
    );
};

export default Personas;
