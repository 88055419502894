import React from "react";
import ItemsList from "components/ui/ItemsList/ItemsList";
import { truncateString } from "utils/helper";
import FollowUpTaskItem from "./FollowUpTaskItem/FollowUpTaskItem";
import "./FollowUpTasksTableDropdown.scss";

const FollowUpTasksTableDropdown = ({ followUpTask }) => {
    const { taskEmails, taskCannedResponses, branchCollections } = followUpTask;

    return (
        <>
            <div className='follow-up-table-dropdown'>
                <div className='follow-up-table-dropdown__heading'>
                    Communications
                </div>
                <div className='follow-up-table-dropdown__body'>
                    <div className='follow-up-task__item-list'>
                        <ItemsList breakPoint={10}>
                            {taskEmails?.map(
                                (
                                    { cannedEmailSubject, cannedEmailBody },
                                    index
                                ) => (
                                    <FollowUpTaskItem
                                        key={index}
                                        itemName={truncateString(
                                            cannedEmailSubject ||
                                                cannedEmailBody,
                                            20
                                        )}
                                        className='follow-up-task__item__black'
                                    />
                                )
                            )}
                            {taskCannedResponses?.map(
                                ({ cannedResponse }, index) => (
                                    <FollowUpTaskItem
                                        itemName={truncateString(
                                            cannedResponse,
                                            20
                                        )}
                                    />
                                )
                            )}
                            {branchCollections?.map(
                                ({ branchCollectionName }, index) => (
                                    <FollowUpTaskItem
                                        key={index}
                                        itemName={branchCollectionName}
                                        className='follow-up-task__item__black'
                                    />
                                )
                            )}
                        </ItemsList>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FollowUpTasksTableDropdown;
