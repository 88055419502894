import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Textarea } from "components/ui";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const CreateGuideBranchForm = ({
    handleSuccess,
    closeModal,
    referenceData,
    isAgentGuideView,
    handleRefresh,
    isEdit,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedContents, setSelectedContents] = useState(new Map());

    const { guideId } = referenceData || {};

    const [request, setRequest] = useState({
        guideFlowId: null,
        guideFlowName: "",
        capsuleClassId: "",
        capsuleEntryId: "",
        mainInstruction: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const toastMessage = useContext(ToastContext);

    const createGuideBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.post(
                apiRoute?.createGuideBranch(guideId),
                request
            );

            if (res.status === 201) {
                const { message, data } = res.data;
                handleRefresh?.(data?.guideFlowId);
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const updateGuideBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.put(
                apiRoute?.editGuideBranch(referenceData?.guideFlowId),
                request
            );

            if (res.status === 200) {
                const { message, data } = res.data;
                handleRefresh?.(data?.guideFlowId);
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? updateGuideBranch() : createGuideBranch();
        }
        setErrors(formErrors);
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId, capsuleCode } = capsule;
        setErrors({ ...errors, agentGuideCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleCode);

        if (isAgentGuideView) {
            const prevSelectedCapsuleEntryId =
                request?.capsuleEntryId === capsuleEntryId;

            if (prevSelectedCapsuleEntryId) {
                setRequest({
                    ...request,
                    capsuleClassId: null,
                    capsuleEntryId: null,
                });
            } else {
                setRequest({
                    ...request,
                    capsuleClassId,
                    capsuleEntryId,
                });
            }

            setErrors((prevErr) => ({ ...prevErr, capsuleEntryId: "" }));
        } else {
            if (prevSelectedContents) {
                const ccSelectedContentIds =
                    prevSelectedContents?.classCapsuleEntryIds;
                const contentExist =
                    prevSelectedContents?.classCapsuleEntryIds?.includes(
                        capsuleEntryId
                    );

                if (!contentExist) {
                    ccSelectedContentIds.push(capsuleEntryId);
                } else {
                    ccSelectedContentIds.splice(
                        prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                            capsuleEntryId
                        ),
                        1
                    );
                }

                setSelectedContents(
                    (prev) =>
                        new Map([
                            ...prev,
                            [
                                capsuleCode,
                                {
                                    classCapsuleId: capsuleClassId,
                                    classCapsuleEntryIds: ccSelectedContentIds,
                                },
                            ],
                        ])
                );
            } else {
                setSelectedContents(
                    (prev) =>
                        new Map([
                            ...prev,
                            [
                                capsuleCode,
                                {
                                    classCapsuleId: capsuleClassId,
                                    classCapsuleEntryIds: [capsuleEntryId],
                                },
                            ],
                        ])
                );
            }
        }
    };

    const handleClassSelect = (val) => {
        if (val === undefined && isAgentGuideView) {
            setRequest((prevRequest) => ({
                ...prevRequest,
                capsuleClassId: "",
                capsuleEntryId: "",
                allowCapsuleAltConversation: false,
            }));
        }
    };

    useEffect(() => {
        if (isEdit && referenceData) {
            const { guideFlowCapsuleClassId, guideFlowCapsuleEntryId } =
                referenceData;
            setRequest({
                ...referenceData,
                capsuleClassId: guideFlowCapsuleClassId,
                capsuleEntryId: guideFlowCapsuleEntryId,
            });
        }
        // eslint-disable-next-line
    }, [referenceData, isEdit]);

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            <Input
                grpClass='mb-3'
                type='text'
                name='guideFlowName'
                label='Title'
                labelClass='text-dark fw-medium'
                id='title'
                placeholder='Enter name of branch'
                value={request?.guideFlowName}
                onChange={handleChange}
                isErr={errors?.guideFlowName}
                errMssg={errors?.guideFlowName}
                autoFocus={true}
            />

            <Textarea
                grpClass='mb-3'
                type='text'
                name='mainInstruction'
                label='Main instruction'
                labelClass='text-dark fw-medium'
                id='title'
                placeholder='Enter instruction for the branch'
                value={request?.mainInstruction}
                onChange={handleChange}
                isErr={errors?.mainInstruction}
                errMssg={errors?.mainInstruction}
                autoFocus={true}
            />

            <div className='form-group'>
                <ClassCapsuleContentGroup
                    classesListBreakpoint={8}
                    handleContentSelect={handleContentSelect}
                    selectable={true}
                    searchable={true}
                    selectedContentIds={
                        isAgentGuideView
                            ? request?.capsuleEntryId
                                ? [request?.capsuleEntryId]
                                : null
                            : Array.from(selectedContents?.values())?.map(
                                  (x) => x.classCapsuleEntryIds
                              )
                    }
                    // selectedContentIds={flattenArray(
                    //     Array.from(selectedContents?.values())?.map(
                    //         (x) => x.classCapsuleEntryIds
                    //     )
                    // )}
                    pushSelectedClass={handleClassSelect}
                    disableMultipleSelection
                    label='Relate feature or flow'
                    inputKey='agentGuideCapsules'
                    error={errors?.agentGuideCapsules}
                />
            </div>

            <Button
                type='submit'
                text={isEdit ? "Update Branch" : "Create Branch"}
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateGuideBranchForm;
