import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { Link, useLocation } from "react-router-dom";
import asset from "assets/images";
import routes from "routes/routes";
import FadeIn from "../effects/FadeIn";
import { matchPath } from "react-router-dom";
import SidebarNavDropdown from "./SidebarNavDropdown/SidebarNavDropdown";
import SidebarIcon from "./SidebarIcon/SidebarIcon";
import AccessControlNavItem from "./SidebarNavDropdown/AccessControlNavItem/AccessControlNavItem";
import { pickValidRoute } from "./utils/helper";
import "./Sidebar.scss";
import { updateWorkspaceSettings } from "store/settings/actions";
import { defaultThemes } from "hoc/AppTemplateWrapper/enum";

const { DARK_MODE_DEFAULT, WHITE_MODE_DEFAULT } = defaultThemes;

const Sidebar = ({
    user,
    workspace = {},
    collapseComplete,
    handleCollapseSidebar,
    toggleOpenSidebar,
    permissions,
}) => {
    const { email = "" } = user || {};
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );
    const { pathname: currentPath } = useLocation();

    const dispatch = useDispatch();
    const { defaultTheme } = useSelector((state) => state?.settings);

    const activeRoute = (currentPath, path, dropDowns, tabbedRoutes) => {
        const matchFunction = (pathVariable) =>
            matchPath(currentPath, {
                path: pathVariable,
                exact: false,
                strict: false,
            });
        let match = matchFunction(path);
        let dropDownActive = dropDowns
            ? dropDowns.find(
                  (x) => x.path === currentPath || matchFunction(x.path)
              )
            : false;
        let tabbedRouteActive = tabbedRoutes
            ? tabbedRoutes.find(
                  (x) => x.path === currentPath || matchFunction(x.path)
              )
            : false;
        return path === currentPath ||
            dropDownActive ||
            tabbedRouteActive ||
            match
            ? "active"
            : "";
    };

    const isDarkModeTheme = defaultTheme === DARK_MODE_DEFAULT;

    const toggleTheme = () => {
        dispatch(
            updateWorkspaceSettings({
                defaultTheme: isDarkModeTheme
                    ? WHITE_MODE_DEFAULT
                    : DARK_MODE_DEFAULT,
            })
        );
    };

    return (
        <div
            className={`sidebar ${collapseComplete ? "collapsed" : ""}`}>
            <div className='sidebar__heading'>
                {collapseComplete ? (
                    <>
                        <img
                            src={
                                workspace?.workspaceLogoUrl ||
                                asset?.images?.placeholder
                            }
                            alt={workspace?.workspaceName}
                            className='workspace--collapsed-logo'
                        />
                    </>
                ) : (
                    <div className='d-flex justify-content-between logo-plus-toggler'>
                        <FadeIn>
                            <div className='menu-logo--container d-flex align-items-center'>
                                <img
                                    src={
                                        workspace?.workspaceLogoUrl ||
                                        asset?.images?.placeholder
                                    }
                                    alt={workspace?.workspaceName}
                                    className={
                                        collapseComplete
                                            ? "workspace--collapsed-logo"
                                            : "workspace--logo"
                                    }
                                />
                                {!collapseComplete && (
                                    <div className='menu-logo--text'>
                                        <h5 className='fw-medium mb-0'>
                                            {workspace?.workspaceName}
                                        </h5>
                                        <p className='mb-0'>{email}</p>
                                    </div>
                                )}
                            </div>
                        </FadeIn>
                    </div>
                )}
                <div
                    className='collapse-toggler justify-content-center align-items-center cursor-pointer'
                    onClick={handleCollapseSidebar}>
                    <ReactSVG
                        src={asset?.icons?.menuArrow}
                        className='d-inline-flex'
                    />
                </div>
            </div>

            <div className='menu-list'>
                {routes?.map((route, i) =>
                    route.dropDowns &&
                    route.dropDowns.filter(
                        (dropdown) => dropdown.hide === false
                    ).length > 0 ? (
                        <SidebarNavDropdown
                            key={i}
                            id={i}
                            route={route}
                            currentPath={currentPath}
                            collapseComplete={collapseComplete}
                            toggleOpenSidebar={toggleOpenSidebar}
                            isActive={activeRoute(
                                currentPath,
                                route?.path,
                                route?.dropDowns,
                                route?.tabbedRoutes
                            )}
                        />
                    ) : (
                        !route.hide && (
                            <AccessControlNavItem
                                key={i}
                                dropdowns={route?.tabbedRoutes}
                                hasDropdown={route?.tabbedRoutes?.length > 0}
                                baseResources={route?.baseResources}>
                                <div
                                    className='menu-item--container'
                                    onClick={toggleOpenSidebar}>
                                    <Link
                                        to={
                                            route?.tabbedRoutes?.length > 0
                                                ? pickValidRoute(
                                                      route?.tabbedRoutes,
                                                      permissionCodes
                                                  )
                                                : route?.path
                                        }
                                        className={`menu--item ${activeRoute(
                                            currentPath,
                                            route?.path,
                                            route?.dropDowns,
                                            route?.tabbedRoutes
                                        )}`}>
                                        <SidebarIcon
                                            iconName={route.iconName}
                                        />
                                        {collapseComplete ? null : (
                                            <span>{route.title}</span>
                                        )}
                                    </Link>
                                </div>
                            </AccessControlNavItem>
                        )
                    )
                )}
            </div>

            <div
                className={`sidebar-theme-toggle ${
                    collapseComplete ? "collapsed" : ""
                }`}
                onClick={() => toggleTheme()}>
                <ReactSVG
                    src={
                        isDarkModeTheme
                            ? asset?.icons?.darkModeMoon
                            : asset?.icons?.lightModeMoon
                    }
                    className='d-inline-flex sidebar-theme-toggle__moon'
                />
                {!collapseComplete && (
                    <>
                        <span>Dark Mode</span>
                        <ReactSVG
                            src={
                                isDarkModeTheme
                                    ? asset?.icons?.darkModeToggle
                                    : asset?.icons?.lightModeToggle
                            }
                            className='d-inline-flex'
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        user: state?.auth?.user,
        workspace: state?.auth?.user?.workspace,
        permissions: state?.auth?.user?.role?.permissions,
    }),
    null
)(Sidebar);
