import AnalyticsFilters from "components/dashboard/Analytics/AnalyticsTeams/common/AnalyticsFilters/AnalyticsFilters";

const AnalyticsQAReportFilter = () => {
    return (
        <>
            <AnalyticsFilters
                // defaultRequest={currentAnalyticsFilter}
                updateRequest={() => console.log("")}
                // handleSubmit={getAnalyticsData}
                // isLoading={isLoading}
                // setSelectedOptions={setSelectedOptions}
                // currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                // {...{
                //     options,
                //     setOptions: handleSetOptions,
                // }}
            />
        </>
    );
};

export default AnalyticsQAReportFilter;
