import { Table } from "components/ui";
import CapsuleBlock from "./CapsuleBlock/CapsuleBlock";
import CapsuleStatusSwitch from "./CapsuleStatusSwitch/CapsuleStatusSwitch";
import CapsuleTableActions from "./CapsuleTableActions/CapsuleTableActions";
import { CAPSULE_STATUS_LISTING_TABLE_HEADERS } from "components/dashboard/TrainSAM/Dependency/enum";
import CapsuleStatusMap from "./CapsuleStatusMap/CapsuleStatusMap";

const CapsuleStatusListingTable = ({
    capsules,
    selectedCapsuleClass,
    tableLoading,
    getCapsules,
    handleRefresh,
}) => {
    const tableData = () =>
        capsules.map(({ content, enabled, __typename, ...rest }) => ({
            name: <CapsuleBlock capsuleCode={content} />,
            status: (
                <CapsuleStatusSwitch
                    isEnabled={enabled}
                    capsuleClassId={selectedCapsuleClass?.capsuleClassId}
                    capsuleEntryId={rest[selectedCapsuleClass?.collectionKey]}
                    getCapsules={getCapsules}
                    handleRefresh={handleRefresh}
                />
            ),
            actions: <CapsuleTableActions />,
            dropdownElement: (
                <CapsuleStatusMap
                    capsuleEntryId={rest[selectedCapsuleClass?.collectionKey]}
                    collectionName={selectedCapsuleClass?.collectionName}
                />
            ),
        }));

    return (
        <>
            <Table
                headers={CAPSULE_STATUS_LISTING_TABLE_HEADERS}
                dataSource={tableData()}
                isAccordion={true}
                isLoading={tableLoading}
            />
        </>
    );
};

export default CapsuleStatusListingTable;
