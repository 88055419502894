import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TicketInfoDropdown.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { DELETE } = baseResourceActions;

const TicketInfoDropdown = ({
    isActive,
    handleShowRemoveModal,
    firstItem,
    children,
}) => {
    const [dropdownOpen, toggle] = useState(false);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            direction='down'
            id='ticket__info__dropdown'
            onMouseLeave={() => toggle(false)}
            toggle={() => toggle((prevState) => !prevState)}>
            <div onMouseOver={() => toggle(true)}>{children}</div>
            <DropdownMenu
                className={`${
                    "info__bottom__menu"
                    // firstItem ? "info__bottom__menu" : "info__top__menu"
                }`}>
                <div className='ticket__info__dropdown__menu'>
                    <ComponentAccessControl
                        baseResources={[
                            `${DELETE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <DropdownItem onClick={handleShowRemoveModal}>
                            Remove
                        </DropdownItem>
                    </ComponentAccessControl>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default TicketInfoDropdown;
