const StatusMapLegend = ({ hrsDiff }) => {
    return (
        <>
            {hrsDiff > 3 ? (
                <p className='indicator'>{hrsDiff}hrs</p>
            ) : (
                <p className='indicator'>{Number.parseInt(hrsDiff)}</p>
            )}
        </>
    );
};

export default StatusMapLegend;
