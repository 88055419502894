import Filter from "components/dashboard/common/Filter/Filter";
import Heading from "components/ui/Heading/Heading";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import "./AnalyticsTSamHeading.scss";
const AnalyticsTSamHeading = ({
    title,
    placeholder,
    hasFilter,
    noSearch,
    handleSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
}) => {
    return (
        <div className='analytics-tsam-heading'>
            <Heading headingText={title} />
            <div>
                {hasFilter ? (
                    <Filter
                        searchPlaceholder={placeholder}
                        hideClassFilter={true}
                        handleSortByAlpha={handleSortByAlpha}
                        handleSortByDate={handleSortByDate}
                        handleSearch={handleSearch}
                        handleSortByLastModified={handleSortByLastModified}
                    />
                ) : (
                    <>
                        {noSearch ? (
                            ""
                        ) : (
                            <div className='analytics-tsam-heading__search'>
                                <PaginationSearch placeholder={placeholder} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
export default AnalyticsTSamHeading;
