import { dataQueryStatus } from "utils";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import AddUserButton from "components/ui/Button/AddUserButton/AddUserButton";
import Heading from "components/ui/Heading/Heading";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TeamsHeader.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const { LOADING, NULLMODE } = dataQueryStatus;

const TeamsHeader = ({
    status,
    toggleSideModal,
    handleSearch,
    searchIsLoading,
    clearSearch,searchDefaultValue
}) => {
    return (
        <>
            <Heading
                headingText={
                    status === LOADING || status === NULLMODE
                        ? "Create team"
                        : "Team members"
                }
                headingSubText='List of roles and persons settings'
            />
            <div className='teams__header--actions'>
                <PaginationSearch
                    handleSearch={handleSearch}
                    placeholder='Search member here'
                    isLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={searchDefaultValue}
                />
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <AddUserButton
                        btnText='Create New Team'
                        onClick={() => toggleSideModal(true)}
                    />
                </ComponentAccessControl>
            </div>
        </>
    );
};

export default TeamsHeader;
