import "./BranchViewEmbeds.scss";
import { Button } from "components/ui";
import ChangeEmbedModal from "./modal/ChangeEmbedModal/ChangeEmbedModal";
import { useState } from "react";
import BranchEmbedCapsule from "./BranchEmbedCapsule/BranchEmbedCapsule";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const BranchViewEmbeds = ({
    guideFlowCapsuleClassId,
    guideFlowCapsuleEntryId,
    referenceData,
    handleSuccess,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [showDeleteModal, toggleDeleteModal] = useState(false);


    const handleToggleDeleteModal = () => {
        toggleDeleteModal(true);

        console.log("tell lies");
    };

    return (
        <>
            <div className='branch__content branch-embeds'>
                <div className='branch-embeds__heading'>
                    <h6>Embeds</h6>
                    <Button
                        text='Change'
                        classType='plain'
                        onClick={() => toggleModal(true)}
                        otherClassName={"branch__view--heading__button"}
                    />
                </div>
                <div className='d-flex flex-wrap align-items-center flex-gap mt-2'>
                    {guideFlowCapsuleClassId ? (
                        <BranchEmbedCapsule
                            {...{
                                guideFlowCapsuleClassId,
                                guideFlowCapsuleEntryId,
                                handleToggleDeleteModal
                            }}
                        />
                    ) : (
                        <p>No Embed Yet</p>
                    )}
                </div>
            </div>
            {showModal && (
                <ChangeEmbedModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={handleSuccess}
                    currentEmbed={{
                        guideFlowCapsuleClassId,
                        guideFlowCapsuleEntryId,
                    }}
                    referenceData={referenceData}
                />
            )}
            {
                showDeleteModal && (
                    <ConfirmationModal
                        title={`This Action removes the embed from this branch`}
                        show={showDeleteModal}
                        closeModal={() => toggleDeleteModal(false)}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.editGuideBranch(
                            referenceData?.guideFlowId
                        )}
                        method='put'
                        data={{...referenceData, capsuleEntryId: null, capsuleClassId: null}}
                    />
                )
            }
        </>
    );
};

export default BranchViewEmbeds;
