import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ChildrenClasses from "./ChildrenClasses/ChildrenClasses";
import ParentClasses from "./ParentClasses/ParentClasses";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleContentClasses = ({
    capsuleData,
    setAcceptedContentType,
    viewCapsuleOnly,
    hideAddButton,
    hideEditAction,
    hideDeleteAction,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [capsule, setCapsule] = useState();

    const retrieveCaspuleClassInfo = async (httpRequest) => {
        try {
            setStatus(LOADING);
            const {
                capsule: { capsuleClassId },
            } = capsuleData;
            const url = apiRoute?.getExactCapsuleClass(capsuleClassId);
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;

                setCapsule(data);
                setAcceptedContentType?.(data?.acceptedContentType);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        retrieveCaspuleClassInfo(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capsuleData?.capsule, capsuleData?.contentId, capsuleData?.productId]);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <div>
                        <ParentClasses
                            capsuleClassess={capsule?.parents}
                            capsuleData={capsuleData}
                            viewCapsuleOnly={viewCapsuleOnly}
                            hideAddButton={hideAddButton}
                            hideEditAction={hideEditAction}
                            hideDeleteAction={hideDeleteAction}
                        />
                        <ChildrenClasses
                            capsuleClassess={capsule?.children}
                            capsuleData={capsuleData}
                            viewCapsuleOnly={viewCapsuleOnly}
                            hideAddButton={hideAddButton}
                            hideEditAction={hideEditAction}
                            hideDeleteAction={hideDeleteAction}
                        />
                    </div>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMsg}
                        handleRetry={retrieveCaspuleClassInfo}
                    />
                );

            default:
                return "";
        }
    };

    return <>{renderCompBasedOnStage()}</>;
};

export default CapsuleContentClasses;
