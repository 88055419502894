import Filter from "components/dashboard/common/Filter/Filter";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import SolidAddButton from "components/ui/Button/SolidAddButton/SolidAddButton";
import Heading from "components/ui/Heading/Heading";
import { baseResourceActions, baseResources } from "routes/enum";
import { CANNED_RESPONSES_ACTIONS } from "../enums";

const { KB_FORM_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const { CREATE_CANNED_RESPONSE } = CANNED_RESPONSES_ACTIONS;

const CannedResponsesHeader = ({
    handleActionModal,
    handleSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    searchIsLoading,
    clearSearch,
    handleBasefilter,
    filterOption,
    baseFilter,
    searchDefaultValue
}) => {
    return (
        <>
            <div>
                <Heading
                    headingText={"Canned Responses"}
                    headingSubText={"Add or change canned responses"}
                />

                <div className='form-post-form-header'>
                    <div className='row align-items-center'></div>
                    <div>
                        <ComponentAccessControl
                            baseResources={[`${CREATE}_${KB_FORM_SETTING}`]}>
                            <SolidAddButton
                                btnText='Add New Canned Response'
                                onClick={() =>
                                    handleActionModal(CREATE_CANNED_RESPONSE)
                                }
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
                <Filter
                    handleSortByAlpha={handleSortByAlpha}
                    handleSortByDate={handleSortByDate}
                    handleSearch={handleSearch}
                    handleSortByLastModified={handleSortByLastModified}
                    searchPlaceholder='Search canned responses'
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    handleBasefilter={handleBasefilter}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    searchDefaultValue={searchDefaultValue}
                    hideClassFilter
                />
            </div>
        </>
    );
};

export default CannedResponsesHeader;
