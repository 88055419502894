import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import BRANCH_VIEW_ELEMENT_ACTIONS from "../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
} = baseResources;
const { DELETE } = baseResourceActions;

const BranchViewElementDropdown = ({
    handleAction,
    showRemoveBranchOption = true,
}) => {
    const dropdownItems = [
        {
            value: BRANCH_VIEW_ELEMENT_ACTIONS.DELETE_BRANCH,
            label: "Delete",
            baseResources: [
                `${DELETE}_${KB_SAM_BRANCH}`,
                `${DELETE}_${KB_SAM_COLLECTION}`,
                `${DELETE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
            ],
        },
        {
            value: BRANCH_VIEW_ELEMENT_ACTIONS.DETACH_BRANCH,
            label: "Remove",
            baseResources: [
                `${DELETE}_${KB_SAM_BRANCH}`,
                `${DELETE}_${KB_SAM_COLLECTION}`,
                `${DELETE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
            ],
        },
    ];

    const deleteItem = [
        {
            value: BRANCH_VIEW_ELEMENT_ACTIONS.DELETE_BRANCH,
            label: "Delete",
            baseResources: [
                `${DELETE}_${KB_SAM_BRANCH}`,
                `${DELETE}_${KB_SAM_COLLECTION}`,
                `${DELETE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
            ],
        },
    ];

    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleAction(value)}
            options={showRemoveBranchOption ? dropdownItems : deleteItem}
        />
    );
};

export default BranchViewElementDropdown;
