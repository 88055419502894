import React from "react";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";

const CapsuleClassesListing = ({ data = [], handleCapsuleSelect }) => (
    <>
        {data?.map((capsule) => (
            <Capsule
                capsule={capsule}
                isActive={true}
                onClick={() => handleCapsuleSelect(capsule)}
            />
        ))}
    </>
);

export default CapsuleClassesListing;
