import { Modal } from "components/ui";
import ModalPreviewFile from "./ModalPreviewFile/ModalPreviewFile";
import "./ModalPreview.scss";

// const { IMAGE, FILE, VIDEO } = mediaType;

const ModalPreview = ({
    showModal,
    toggleModal,
    preview,
    previewType,
    fileName,
    file
}) => {

    return (
        <Modal
            show={showModal}
            close={toggleModal}
            fullscreen={true}
            toggle={true}>
            <div className='modal__preview--container'>
                <div className='preview__file'>
                    <ModalPreviewFile
                        fileName={fileName}
                        fileType={previewType}
                        preview={preview}
                        file={file}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModalPreview;
