import React from 'react';
import MediaContent from '../MessageContent/MediaContent/MediaContent';
import { appMessageSources } from '../enums';
import { EmailAttachments } from 'components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketInfoDetails/TicketInfoClipboard/TicketInfoAttachments/TicketInfoAttachments';
import "./MessageAttachments.scss";

const { IN_APP } = appMessageSources;

const MessageAttachments = ({ fileAttachments, openPreviewModal, messageSource }) => {
    return (
        <>
            {
                fileAttachments &&
                    messageSource === IN_APP ?
                    <div className="attachment__list">
                        {
                            fileAttachments?.map((attachment, i) => (
                                <MediaContent
                                    attachment={attachment}
                                    key={i}
                                    openPreviewModal={openPreviewModal}
                                />
                            ))}
                    </div> :
                    <div className="my-3" onClick={(e) => e.stopPropagation()}>
                        <EmailAttachments 
                            files={fileAttachments} 
                            handlePreviewFile={openPreviewModal}
                            hideAttachmentOption/>
                    </div>
            }
        </>
    );
};

export default MessageAttachments;