import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Loading } from "components/ui";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { EmptyMessageTagList } from "./EmptyMesageTagList/EmptyMessageTagList";
import CreateMessageTagHeader from "./CreateMessageTagHeader/CreateMessageTagHeader";
import MessageTagList from "./MessageTagList/MessageTagList";
import CurrentMessageTag from "./CurrentMessageTag/CurrentMessageTag";
import {
    setSelectedMssgTagId,
    saveMssgTags,
} from "store/modules/agentInbox/actions";
import { setMssgTagFilterParams } from "store/filters/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const MessageTagging = () => {
    const [errorMssg, setErrorMssg] = useState();
    const {
        messagingTagsCache: {
            selectedMssgTagId,
            messagingTags,
            messageTagDetailsList,
        },
    } = useSelector((state) => state.agentInbox);
    const { filterParams } = useSelector(
        (state) => state?.filters?.mssgTagFilters
    );

    const dispatch = useDispatch();
    const [status, setStatus] = useState(
        messagingTags?.length > 0 ? DATAMODE : IDLE
    );

    const selectedMssgTagDetails = getPageFromCache(
        messageTagDetailsList,
        selectedMssgTagId
    );

    const handleSearch = (searchResult, search) => {
        dispatch(saveMssgTags(searchResult));
        dispatch(setMssgTagFilterParams({ ...filterParams, search }));
        if (searchResult?.length === 0) {
            setStatus(NULLMODE);
        } else {
            setStatus(DATAMODE);
            dispatch(setSelectedMssgTagId(searchResult[0]?.messagingTagId));
        }
    };

    const getMessagingTags = async (httpRequest) => {
        try {
            if (messagingTags?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const res = await API.get(apiRoute.getMessageTags, {
                signal: httpRequest?.signal,
                params: { ...filterParams },
            });

            if (res.status === 200) {
                const { data } = res.data;

                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                dispatch(setSelectedMssgTagId(data[0]?.messagingTagId));
                dispatch(saveMssgTags(data));
                setStatus(newStatus);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        if (
            selectedMssgTagDetails?.id === undefined ||
            messagingTags?.length < 1
        ) {
            getMessagingTags(httpRequest);
        }

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getMessagingTags}
                    />
                );

            case NULLMODE:
                return <EmptyMessageTagList />;

            case DATAMODE:
                return (
                    <>
                        <MessageTagList
                            messagingTagsList={messagingTags}
                            selectedMssgTagId={selectedMssgTagId}
                            setSelectedMssgTagId={setSelectedMssgTagId}
                            handleSuccess={(tagId) => {
                                dispatch(setSelectedMssgTagId(tagId));
                                getMessagingTags();
                            }}
                            selectedMssgTagDetails={selectedMssgTagDetails}
                            setSelectedMssgTagDetails={(tag) => {
                                dispatch(
                                    setSelectedMssgTagId(tag?.messagingTagId)
                                );
                            }}
                        />
                        <CurrentMessageTag
                            selectedMssgTagId={selectedMssgTagId}
                            selectedMssgTagDetails={selectedMssgTagDetails}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return (
        <div className='w-100 h-100'>
            <div className='padded__layout'>
                <div className='row'>
                    <CreateMessageTagHeader
                        fetchMessageTags={getMessagingTags}
                        handleSearch={handleSearch}
                        searchDefaultValue={filterParams?.search}
                    />
                    {renderBasedOnStatus()}
                </div>
            </div>
        </div>
    );
};

export default MessageTagging;
