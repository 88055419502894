import React, { useState, useContext, useCallback } from "react";

import UploadingEmailAttachmentList from "../UploadingEmailAttachmentList/UploadingEmailAttachmentList";
import FileDropZone from "./FileDropZone/FileDropZone";
import { dataQueryStatus } from "utils";
import { splitFileFormat, generateID } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button } from "components/ui";
import "./FileEmailAttachment.scss";

const { ERROR } = dataQueryStatus;

const FileEmailAttachment = ({
    fileAttachments,
    handleToggleEmailAttachment,
    // handleCancel
}) => {
    const toastMessage = useContext(ToastContext);
    const [files, setFiles] = useState([]);
    const [uploadComplete, setUploadComplete] = useState(true);

    const uploadFile = (files) => {
        files?.map((file) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "");

                const uploadObj = {
                    content: base64String,
                    attachmentType: splitFileFormat(file?.name),
                    filename: file?.name,
                    id: generateID(5),
                    progress: 0,
                };

                setFiles((prev) => [
                    ...prev,
                    { ...uploadObj, size: file?.size },
                ]);
            };
            reader.readAsDataURL(file);
            return reader;
        });
    };

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (
                fileRejections?.length > 0 ||
                fileAttachments?.length > 5 ||
                fileAttachments?.length + acceptedFiles?.length > 5
            ) {
                return toastMessage(
                    fileRejections?.[0]?.errors?.[0]?.code ||
                        "Maximum of 5 files",
                    ERROR
                );
            }

            if (acceptedFiles?.length > 0) {
                const selectedFiles = Array.prototype.slice.call(acceptedFiles);
                // maximum of five files
                const firstFiveFiles = selectedFiles?.slice(0, 5);

                const totalFileSize = [...files, ...firstFiveFiles]?.reduce(
                    (prev, curr) => prev + curr.size,
                    0
                );

                if (totalFileSize > 5269601) {
                    const error = "Maximum size is 5MB";

                    return toastMessage(error, ERROR);
                }

                uploadFile(firstFiveFiles);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [files]
    );

    const handleRemoveFile = (fileId) => {
        setFiles((prev) => prev?.filter(({ id }) => id !== fileId));
    };

    const handleAttachment = () => {
        handleToggleEmailAttachment("fileAttachments", [...fileAttachments, ...files])
        setFiles([])
    }


    return (
        <div>
            <div className='close__file__email__attachment__container'>
                <div
                    // src={imageLinks?.icons?.cancelX}
                    // onClick={handleCancel}
                    className={`close__file__email__attachment ${
                        !uploadComplete
                            ? "disable__close__file__email__attachment"
                            : ""
                    }`}
                />
            </div>
            <div className='file__email__attachment--header'>
                <h3 className='file__email__attachment--header__text'>
                    Attach file
                </h3>
                <span className='file__email__attachment--header__sub__text'>
                    Docx, pdf, jpeg formats are accepted.
                </span>
            </div>
            <FileDropZone onDrop={onDrop} />
            {files?.length > 0 && (
                <>
                    <UploadingEmailAttachmentList
                        files={files}
                        setFiles={setFiles}
                        setUploadComplete={setUploadComplete}
                        handleRemoveFile={handleRemoveFile}
                    />
                    <Button
                        text='Attach files'
                        classType='primary'
                        otherClass='w-100 save__email__attachments'
                        onClick={() => handleAttachment()}
                    />
                </>
            )}
        </div>
    );
};

export default FileEmailAttachment;
