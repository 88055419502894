export const ANALYTICS_QA_METRIC_DIRECTIONS = {
    UP_DIRECTION: "UP_DIRECTION",
    DOWN_DIRECTION: "DOWN_DIRECTION",
};

export const ANALYTICS_QA_DURATIONS = [
    {
        label: "This Week",
        value: "THIS_WEEK",
    },
    {
        label: "Last Week",
        value: "LAST_WEEK",
    },
    {
        label: "A Month Ago",
        value: "LAST_MONTH",
    },
];

export const ANALYTICS_QA_TYPES = [
    {
        label: "All QA",
        value: "ALL",
    },
    {
        label: "Awaiting QA",
        value: "AWAITING",
    },
    {
        label: "QA in session",
        value: "IN_PROGRESS",
    },
];

export const ANALYTICS_QA_ACTIONS = {
    VIEW: "VIEW",
};
