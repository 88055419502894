import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { ANALYTICS_QA_VET_TICKET_ACTIONS } from "../../enums";
import { baseResourceActions, baseResources } from "routes/enum";

const { PIN, UNPIN, VIEW } = ANALYTICS_QA_VET_TICKET_ACTIONS;

const { USER_MANAGEMENT_ROLES } = baseResources;
const { UPDATE, READ } = baseResourceActions;

const AnalyticsQAVetTicketTableDropdown = ({
    handleActionModal,
    isPinned,
    ticketId,
}) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, ticketId)}
            options={[
                {
                    value: isPinned ? UNPIN : PIN,
                    label: (
                        <div className='d-flex qa-vet-table-dropdown'>
                            <ReactSVG src={asset.icons.pinDown} />
                            {isPinned ? "Unpin" : "Pin"}
                        </div>
                    ),
                    baseResources: [`${UPDATE}_${USER_MANAGEMENT_ROLES}`],
                },
                {
                    value: VIEW,
                    label: (
                        <div className='d-flex qa-vet-table-dropdown'>
                            <ReactSVG src={asset.icons.viewEye} />
                            View
                        </div>
                    ),
                    baseResources: [`${READ}_${USER_MANAGEMENT_ROLES}`],
                },
            ]}
        />
    );
};

export default AnalyticsQAVetTicketTableDropdown;
