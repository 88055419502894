import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Dropdown } from "components/ui";
import { Checkbox } from "components/ui";
import { appMessageSources } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import "./TicketPhaseSelectorDropdown.scss";

const { IN_APP, EMAIL, TWITTER, FACEBOOK_MESSENGER, INSTAGRAM, ALL } =
    appMessageSources;

const TicketPhaseSelectorDropdown = ({
    handleDropdownAction,
    ticketSource,
}) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    className='phase__selector__anchor'
                    src={imageLinks?.icons?.anchorDown}
                />
            }
            ddValueClass='borderless'
            className='table__dropdown ticket__phase__selector__dropdown'
            ddOptionsClass='ticket__phase__selector'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleDropdownAction(value)}
            options={[
                {
                    value: undefined,
                    label: (
                        <Checkbox
                            label='All'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='in-app'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === ALL}
                        />
                    ),
                },
           
                {
                    value: IN_APP,
                    label: (
                        <Checkbox
                            label='In app'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='in-app'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === IN_APP}
                        />
                    ),
                },
                {
                    value: EMAIL,
                    label: (
                        <Checkbox
                            label='Email'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='email'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === EMAIL}
                        />
                    ),
                },
                {
                    value: TWITTER,
                    label: (
                        <Checkbox
                            label='Twitter'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='twitter'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === TWITTER}
                        />
                    ),
                },
                {
                    value: FACEBOOK_MESSENGER,
                    label: (
                        <Checkbox
                            label='Facebook'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='facebook'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === FACEBOOK_MESSENGER}
                        />
                    ),
                },
                {
                    value: INSTAGRAM,
                    label: (
                        <Checkbox
                            label='Instagram'
                            grpClass='ticket__source__radio__input__group'
                            labelClass='ticket__source__radio__input__label'
                            name='ticketSource'
                            id='instagram'
                            type='radio'
                            inputClass='input-check-grey'
                            checked={ticketSource === INSTAGRAM}
                        />
                    ),
                },
            ]}
            isDefault={false}
            labelClass='phase__selector__label'
        />
    );
};

export default TicketPhaseSelectorDropdown;
