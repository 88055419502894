import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ConfirmAction } from "components/ui";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useDispatch } from "react-redux";
import { deleteRole } from "store/cache/roles/actions";

const { ERROR } = dataQueryStatus;

const DeleteRole = ({ roleCode, closeModal, handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const deleteRoleData = async () => {
        try {
            setLoading(true);

            const url = apiRoute.editRole(roleCode);
            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;
                dispatch(deleteRole({ roleCode }));
                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <>
            <ConfirmAction
                title='Are you sure you want to Delete this role'
                loading={loading}
                close={closeModal}
                handleYesClick={deleteRoleData}
            />
        </>
    );
};

DeleteRole.prototypes = {
    roleCode: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
};

export default DeleteRole;
