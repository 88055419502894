import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { CANNED_RESOURCE_ACTIONS } from "components/dashboard/TrainSAM/CannedResponses/enum";
import { baseResourceActions, baseResources } from "routes/enum";

const { DELETE_CANNED_RESOURCE, VIEW_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { DELETE, READ } = baseResourceActions;

const UpdatesTableDropdown = ({ handleModalAction, hideView }) => {
    const options = hideView
        ? [
              {
                  value: DELETE_CANNED_RESOURCE,
                  label: "Delete",
                  baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
              },
          ]
        : [
              {
                  value: VIEW_CANNED_RESOURCE,
                  label: "View",
                  baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
              },
              {
                  value: DELETE_CANNED_RESOURCE,
                  label: "Delete",
                  baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
              },
          ];
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleModalAction?.(value)}
            options={options}
        />
    );
};

export default UpdatesTableDropdown;
