import Dropdown from "react-bootstrap/Dropdown";
import asset from "assets/images";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import "./BootstrapDropdown.scss";

const BootstrapDropdown = ({
    placeholder,
    options,
    handleChange,
    ddValueClass,
    optClass,
    className,
    ddOptionsClass,
    anchor = true,
    noItemMsg,
    selected,
    menuAlign = "right",
}) => {
    return (
        <>
            <Dropdown
                className={`custom-bootstrap-dropdown ${className || ""}`}>
                <Dropdown.Toggle
                    variant='success'
                    className={`${ddValueClass || ""}`}>
                    <span className='selected__label'>{placeholder}</span>
                    {anchor ? (
                        <img
                            className='anchor'
                            src={asset?.images?.anchorDown}
                            alt='dropdown'
                        />
                    ) : null}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    align={menuAlign}
                    className={`${ddOptionsClass || ""}`}>
                    {options?.length > 0 ? (
                        <>
                            {options?.map((item, key) => (
                                <ComponentAccessControl
                                    baseResources={item?.baseResources}>
                                    <Dropdown.Item
                                        onClick={() => handleChange?.(item)}
                                        className={`${optClass} || ""`}
                                        key={key}>
                                        <p
                                            className={`mb-0 text-dark cursor-pointer opt opt--${
                                                optClass || ""
                                            } ${
                                                item?.value === selected?.value
                                                    ? "opt--selected"
                                                    : ""
                                            }`}>
                                            {item?.label}
                                        </p>
                                    </Dropdown.Item>
                                </ComponentAccessControl>
                            ))}
                        </>
                    ) : (
                        <Dropdown.Item className={`${optClass} || ""`}>
                            <p className='mb-0 text-dark opt'>{noItemMsg}</p>
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default BootstrapDropdown;
