import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { cannedContentDropdownActions } from "../enum";
import "./CannedContentdropdown.scss";

const { EDIT, DELETE } = cannedContentDropdownActions;

const CannedContentdropdown = ({
    cannedContent,
    handleDropdownAction,
    lastItem,
}) => {
    const [dropdownOpen, toggleDropdown] = useState(false);
    const options = [
        {
            value: EDIT,
            label: "Edit",
        },
        {
            value: DELETE,
            label: "Delete",
        },
    ];

    return (
        <Dropdown
            isOpen={dropdownOpen}
            direction='up'
            id='canned__content__dropdown'
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <ReactSVG
                src={imageLinks?.icons?.ellipsis}
                className='canned__content__options'
                onClick={() => toggleDropdown((prevState) => !prevState)}
            />
            <DropdownMenu
                className={`${lastItem ? "top__menu" : "bottom__menu"}`}>
                {options?.map((item, i) => (
                    <DropdownItem
                        key={i}
                        onClick={() =>
                            handleDropdownAction(item.value, cannedContent)
                        }>
                        {item?.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default CannedContentdropdown;
