import React from "react";
import CapsuleClassFormats from "../CapsuleClassFormats/CapsuleClassFormats";
import AttachedCapsuleClassesTable from "./AttachedCapsuleClassesTable/AttachedCapsuleClassesTable";
import CapsuleClassDetailsHeader from "./CapsuleClassDetailsHeader/CapsuleClassDetailsHeader";

const CapsuleClassDetails = ({
    toggleEdited,
    handleEditAction,
    handleCapsulationSuccess,
    selectedCapsuleClass,
}) => {
    return (
        <>
            <CapsuleClassDetailsHeader />
            <AttachedCapsuleClassesTable
                selectedCapsule={selectedCapsuleClass}
                handleCapsulationSuccess={handleCapsulationSuccess}
            />
            <CapsuleClassFormats
                acceptedFormats={selectedCapsuleClass?.acceptedContentType}
                toggleEdited={toggleEdited}
                handleEditAction={handleEditAction}
            />
        </>
    );
};

export default CapsuleClassDetails;
