import CapsuleContentGroup from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleContentGroup/CapsuleContentGroup";
import { capsuleActions } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import React, { useState } from "react";
import ClassesContainer from "../../../ProductDashboard/ProductClassesContainer/ProductClasses/ClassesContainer/ClassesContainer";
import ProductClassesHeader from "../../../ProductDashboard/ProductClassesContainer/ProductClasses/ProductClassesHeader/ProductClassesHeader";
import ParentClassesList from "./ParentClassesList/ParentClassesList";
import "./ParentClasses.scss";

const ParentClasses = ({
    capsuleClassess,
    capsuleData,
    viewCapsuleOnly,
    hideAddButton,
    hideEditAction,
    hideDeleteAction,
}) => {
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState();
    const [renderCount, updateRenderCount] = useState(0);
    const [currentCapsuleAction, setCurrentCapsuleAction] = useState(
        capsuleActions?.ADD_SINGLE_PARENT_CAPSULE
    );

    const handleCapsuleClassSelect = (capsuleClass) => {
        setSelectedCapsuleClass(capsuleClass);
        updateRenderCount((currentCount) => currentCount + 1);
    };
    return (
        <>
            <ProductClassesHeader
                title={"Parent classes"}
                numberOfClasses={capsuleClassess?.length}
            />
            <ParentClassesList
                capsuleClassess={capsuleClassess}
                capsuleData={capsuleData}
                key={renderCount}
                handleCapsuleClassSelect={handleCapsuleClassSelect}
                selectedCapsuleClass={selectedCapsuleClass}
            />
            <div className='parent__classes__container'>
                <ClassesContainer>
                    {selectedCapsuleClass && (
                        <CapsuleContentGroup
                            key={renderCount}
                            childCapsuleClass={capsuleData?.capsule}
                            parentCapsuleClass={selectedCapsuleClass}
                            productId={capsuleData?.productId}
                            capsuleData={capsuleData}
                            childCapsuleId={capsuleData?.contentId}
                            reverseLookUp={true}
                            capsuleAction={currentCapsuleAction}
                            setCurrentCapsuleAction={setCurrentCapsuleAction}
                            updateRenderCount={updateRenderCount}
                            limitListing={true}
                            viewCapsuleOnly={viewCapsuleOnly}
                            hideAddButton={hideAddButton}
                            hideEditAction={hideEditAction}
                            hideDeleteAction={hideDeleteAction}
                            capsuleBottomMenu={true}
                        />
                    )}
                </ClassesContainer>
            </div>
        </>
    );
};

export default ParentClasses;
