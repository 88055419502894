import { useState } from "react";
import FadeIn from "components/ui/effects/FadeIn";
import SignInEmailForm from "./SignInEmailForm/SignInEmailForm";
import SignInPasswordForm from "./SignInPasswordForm/SignInPasswordForm";
import { SignInSelectWorkspace } from "../SignInSelectWorkspace/SignInSelectWorkspace";
import AuthFooter from "pages/PublicPages/AuthFooter/AuthFooter";

export const signInstages = {
    initial: "INITIAL",
    secondary: "SELECT_WORKSPACE",
    final: "INPUT_WORKSPACE_PASSWORD",
};

export const authRedirectPath = "/agents-inbox/tickets";
export const authProfilePath = "/admin/personal-profile";
export const authAdminPath = "/admin/user-management";

export const SignInForm = () => {
    const [signInStage, setSignInStage] = useState(signInstages.initial);
    const [userWorkspaces, setUserWorkspaces] = useState([]);

    const [request, updateRequest] = useState({
        email: "",
        workspaceId: "",
    });

    const handleRequestUpdate = (name, value) => {
        updateRequest({ ...request, [name]: value });
    };

    const renderBasedOnStage = () => {
        const { initial, secondary, final } = signInstages;
        switch (signInStage) {
            case initial:
                return (
                    <SignInEmailForm
                        setSignInStage={setSignInStage}
                        setUserWorkspaces={setUserWorkspaces}
                        handleRequestUpdate={handleRequestUpdate}
                    />
                );
            case secondary:
                return (
                    <SignInSelectWorkspace
                        setSignInStage={setSignInStage}
                        handleRequestUpdate={handleRequestUpdate}
                        workspaces={userWorkspaces}
                        source={"SIGNIN_INSTANCE"}
                    />
                );
            case final:
                return (
                    <SignInPasswordForm
                        setSignInStage={setSignInStage}
                        signInRequest={request}
                        userWorkspaces={userWorkspaces}
                    />
                );
            default:
                return <SignInEmailForm setSignInStage={setSignInStage} />;
        }
    };

    return (
        <div className='signin'>
            {signInStage === signInstages.initial && (
                <h1 className='sub__stage--heading'>
                    Welcome back <span>🎉🎉</span>{" "}
                </h1>
            )}

            <div key={signInStage}>
                <FadeIn pkey={signInStage}>{renderBasedOnStage()}</FadeIn>
            </div>

            <div>
                <AuthFooter
                    url='/sign-up'
                    actionText='Sign up now'
                    questionText='Not a member?'
                />
            </div>
        </div>
    );
};
