export const teamAnalyticsFilters = Object.freeze({
    AGENT: "AGENT",
    TEAM: "TEAM",
    TICKET_PRIORITY: "TICKET_CLASSFICATION",
    // TICKET_PRIORITY: "TICKET_PRIORITY",
    TICKET_CLASSIFICATION: "TICKET_CAPSULES",
    TICKET_PHASE: "TICKET_PHASE",
    TICKET_SOURCE: "TICKET_SOURCE",
    TICKET_SOLUTION_TYPE: "TICKET_SOLUTION_TYPE",
});

export const teamAnalyticsRequestFilters = {
    AGENT: "AGENT",
    TEAM: "TEAM",
    TICKET_CLASSIFICATION: "TICKET_CAPSULES",
    TICKET_PRIORITY: "TICKET_CLASSFICATION",
    TICKET_PHASE: "TICKET_PHASE",
    TICKET_SOURCE: "TICKET_SOURCE",
    TICKET_SOLUTION_TYPE: "TICKET_SOLUTION_TYPE",
};

export const teamAnalyticsBaseFilters = [
    {
        label: "Agent",
        value: "AGENT",
    },
    {
        label: "Team",
        value: "TEAM",
    },
    {
        label: "Ticket Priority",
        value: "TICKET_CLASSFICATION",
    },
    {
        label: "Ticket Classification",
        value: "TICKET_CAPSULES",
    },
    {
        label: "Ticket Phase",
        value: "TICKET_PHASE",
    },
    {
        label: "Ticket Source",
        value: "TICKET_SOURCE",
    },
];

export const EXTERNAL_BACKLOG_FIX = "EXTERNAL_BACKLOG_FIX";
export const INTERNAL_BACKLOG_FIX = "INTERNAL_BACKLOG_FIX";
export const INSTRUCTIONAL_BACKLOG_FIX = "INSTRUCTIONAL_BACKLOG_FIX";
export const EXTERNAL_ANALYTICS_FIX = "EXTERNAL_ANALYTICS_FIX";
export const INTERNAL_ANALYTICS_FIX = "INTERNAL_ANALYTICS_FIX";
export const INSTRUCTIONAL_ANALYTICS_FIX = "INSTRUCTIONAL_ANALYTICS_FIX";

export const teamAnalyticsNoFilters = [
    {
        label: "Internal  Fix",
        value: INTERNAL_ANALYTICS_FIX,
        color: "#be3474",
    },
    {
        label: "External Fix",
        value: EXTERNAL_ANALYTICS_FIX,
        color: "#3b94fc",
    },
    {
        label: "Instructional Fix",
        value: INSTRUCTIONAL_ANALYTICS_FIX,
        color: "#c87717",
    },
];
