import * as types from "./types";

export const savePinnedVetTickets =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_VET_TICKET_PINNED,
            payload: { pageNumber, totalRecords, data },
        });
        
export const clearPinnedVetTickets = () => (dispatch) =>
    dispatch({ type: types.CLEAR_VET_TICKET_PINNED });

export const saveUnpinnedVetTickets =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_VET_TICKET_UNPINNED,
            payload: { pageNumber, totalRecords, data },
        });

export const clearUnpinnedVetTickets = () => (dispatch) =>
    dispatch({ type: types.CLEAR_VET_TICKET_UNPINNED });
