import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const TicketAASentMention = ({ status, mentions, errorMssg }) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <>
                        {mentions?.map((mention, i) => (
                            <div className='ticketaa-mentions__sent' key={i}>
                                <div className='ticketaa-mentions__sent__title'>
                                    <span>@</span>
                                    <h5>You've sent out a mention</h5>
                                </div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: mention?.comment.replace(
                                            /\n\r?/g,
                                            "<br />"
                                        ),
                                    }}
                                />
                            </div>
                        ))}
                    </>
                );
            case ERROR:
                return <ErrorView message={errorMssg} />;
            default:
                return "";
        }
    };

    return <> {renderBasedOnStatus()}</>;
};

export default TicketAASentMention;
