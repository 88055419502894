export const CANNED_RESOURCE_TYPES = {
    CANNED_EMAIL: "CANNED_EMAIL",
    CANNED_UPDATE: "CANNED_UPDATE",
    MENTIONS: "MENTIONS",
    CANNED_RESPONSE: "CANNED_RESPONSE",
    AUTOMATION: "AUTOMATION",
};

export const CANNED_RESOURCE_ACTIONS = {
    CREATE_CANNED_RESOURCE: "CREATE_CANNED_RESOURCE",
    EDIT_CANNED_RESOURCE: "EDIT_CANNED_RESOURCE",
    DELETE_CANNED_RESOURCE: "DELETE_CANNED_RESOURCE",
    VIEW_CANNED_RESOURCE: "VIEW_CANNED_RESOURCE",
};
