import { useEffect, useState } from "react";
import "./ViewGDUpdateDetail.scss";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import UpdateTaskElement from "./UpdateTaskElement/UpdateTaskElement";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatus;

const ViewGDUpdateDetail = ({
    selectedUpdate,
    agentUpdateTasks,
    setAgentUpdateTasks,
    handleModalAction,
}) => {
    const [status, setStatus] = useState();
    const [errorMssg, setErrorMssg] = useState("");

    const fetchAgentUpdateTasks = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute.getUpdateTasks(selectedUpdate?.agentUpdateId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                setAgentUpdateTasks(data?.agentUpdateTasks);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={fetchAgentUpdateTasks}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Task Added to Update Yet"} />;

            case DATAMODE:
                return (
                    <>
                        {agentUpdateTasks?.map((task, key) => (
                            <UpdateTaskElement
                                key={key}
                                task={task}
                                handleModalAction={handleModalAction}
                            />
                        ))}
                    </>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        fetchAgentUpdateTasks();
        // eslint-disable-next-line
    }, [selectedUpdate]);

    return <div className='view-gd-update-detail'>{renderBasedOnStatus()}</div>;
};

export default ViewGDUpdateDetail;
