import React, { useEffect, useState } from "react";
import { getCapsuleCountBasedOnStatus } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import StatusCard from "../../../../common/StatusCard/StatusCard";

const CapsuleStatusCard = ({
    capsuleCardType,
    capsuleClass,
    handleCapsulesTotalCount,
    refresh,
}) => {
    const [capsuleClassCounts, updateCapsuleClassCounts] = useState("-");

    const getCapsuleClassCounts = async () => {
        try {
            let capsuleCountVal = await getCapsuleCountBasedOnStatus(
                capsuleClass,
                capsuleCardType === "ON" ? true : false
            );
            updateCapsuleClassCounts(capsuleCountVal);
            handleCapsulesTotalCount(capsuleCountVal);
        } catch (err) {
            updateCapsuleClassCounts(0);
            handleCapsulesTotalCount(0);
        }
    };

    useEffect(() => {
        getCapsuleClassCounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capsuleClass, refresh]);

    return (
        <>
            <StatusCard
                cardCount={capsuleClassCounts}
                cardType={capsuleCardType}
                cardText={capsuleClass?.capsuleClassName}
                cardUrl={`/dependency/view-capsules/${capsuleClass?.capsuleClassId}/${capsuleCardType}`}
            />
        </>
    );
};

export default CapsuleStatusCard;
