import {
    collection,
    doc,
    limit,
    orderBy,
    query,
    startAfter,
    updateDoc,
    where,
} from "firebase/firestore";

const { firestoreDB } = require("../config/config");

export const qPaginate = (
    workspaceId,
    category,
    lastMessageId,
    operation,
    size
) => {
    return query(
        collection(firestoreDB, `notifications/${workspaceId}/messages`),
        orderBy("createdAt", "desc"),
        where("data.category", operation, category),
        limit(size ? size : 10),
        startAfter(lastMessageId)
    );
};

export const qAll = (workspaceId, category, operation, size) => {
    return query(
        collection(firestoreDB, `notifications/${workspaceId}/messages`),
        orderBy("createdAt", "desc"),
        where("data.category", operation, category),
        limit(size ? size : 10)
    );
};

export const updateDocument = async (workspaceId, messageId, data) => {
    try {
        const messageRef = doc(
            firestoreDB,
            `notifications/${workspaceId}/messages`,
            messageId
        );

        await updateDoc(messageRef, {
            ...data,
        });
    } catch (e) {
        ;
    }
};
