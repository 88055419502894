import React from "react";

const AttachmentFileInput = ({
    id,
    onChange,
    accept,
    disabled,
    file,
    multiple,
    children,
    ...rest
}) => {
    return (
        <label htmlFor={id}>
            {children}
            <input
                type='file'
                id={id}
                name='file'
                onChange={onChange}
                accept={accept}
                disabled={disabled}
                key={file}
                hidden
                multiple={multiple}
                {...rest}
            />
        </label>
    );
};

export default AttachmentFileInput;
