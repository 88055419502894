import { useDispatch, useSelector } from "react-redux";
import "./DropdownWorkspaces.scss";
import { retriveWorkspaceToken, setAccessToken } from "storage/cookieStorage";
import { updateAuthInfo } from "store/auth/actions";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useHistory } from "react-router-dom";

const DropdownWorkspaces = ({
    toggleSignInModal,
    selectWorkspace,
    resetUserStore,
}) => {
    const { workspaces, user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const toastMessage = useContext(ToastContext);
    const history = useHistory();

    const handleSelectWorkspace = async (workspace) => {
        const workspaceToken = retriveWorkspaceToken(workspace?.workspaceName);
        if (workspace?.workspaceId === user?.workspace?.workspaceId) {
            return;
        }

        if (workspaceToken?.accessToken && workspace?.user) {
            await resetUserStore?.();
            await setAccessToken(workspaceToken);
            await dispatch(updateAuthInfo(workspace?.user));
            toastMessage(
                `Successfully switched to ${workspace?.workspaceName} workspace`
            );
            setTimeout(() => {
                history.go();
            }, 500);
        } else {
            selectWorkspace(workspace);
            toggleSignInModal();
        }
    };

    return (
        <div className='dd-menu custom-scroll-bar dropdown-workspaces'>
            {workspaces?.map?.(
                ({ workspaceName, workspaceId, ...rest }, key) => (
                    <span
                        key={key}
                        className={`dd-menu--item ${
                            workspaceId === user?.workspace?.workspaceId
                                ? "active"
                                : ""
                        }`}
                        onClick={() =>
                            handleSelectWorkspace({
                                workspaceId,
                                workspaceName,
                                ...rest,
                            })
                        }>
                        {workspaceName}
                    </span>
                )
            )}
        </div>
    );
};

export default DropdownWorkspaces;
