import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";

const CreateMessageTagButton = ({ openModal }) => {
    return (
        <div>
            <AddButton btnText='Create New Tag' onClick={openModal} />
        </div>
    );
};

CreateMessageTagButton.prototypes = {
    openModal: PropTypes.func.isRequired,
};

export default CreateMessageTagButton;
