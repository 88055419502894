import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading, Table } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useState, useEffect } from "react";
import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { ANALYTICS_CAPSULES_TABLE_HEADERS } from "../../enum";
import AnalyticsTSamCapsulesTDropdown from "./AnalyticsTSamCapsulesTDropdown/AnalyticsTSamCapsulesTDropdown";
import TicketInfoCapsule from "components/dashboard/KnowledgeBase/common/Capsule/TicketInfoCapsule/TicketInfoCapsule";
import { saveCapsuleClassess } from "store/capsule/actions";
import "./AnalyticsTSamCapsulesTable.scss";
import AnalyticsTSamHeading from "../../common/AnalyticsTSamHeading/AnalyticsTSamHeading";
const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;
const AnalyticsTSamCapsulesTable = () => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [retryCount, updateRetryCount] = useState(0);
    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [capsulesAnalytics, setCapsuleAnalytics] = useState([]);
    const [filters, updatePageFilters] = useState({
        search: "",
        currentPage: 1,
        pageSize: 5,
        alphabeticalOrder: null,
        dateCreatedOrder: null,
        lastModified: null,
    });
    const dispatch = useDispatch();
    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        updatePageFilters({ ...filters, currentPage });
    };
    const getConvoAnalytics = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader ? setTableIsLoading(true) : setStatus(LOADING);
            const url = apiRoute?.getFrequentTicketCapsules;
            const res = await API.get(url, {
                params: { ...filters },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                setCapsuleAnalytics(data);
                setTotalRecords(metaData?.totalRecords);
                // setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            // setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };
    const getCapsuleClasses = async (httpRequest) => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            if (retryCount === 0 && capsuleClasses?.length < 1) {
                const url = apiRoute?.getCapsuleClasses;
                const res = await API.get(url, {
                    headers: {
                        workspaceid: workspaceId,
                    },
                    signal: httpRequest?.signal,
                });
                if (res.status === 200) {
                    const { data } = res.data;
                    dispatch(saveCapsuleClassess(data));
                    updateRetryCount(1);
                }
            }
            updateRetryCount(1);
        } catch (err) {
            //
            updateRetryCount(0);
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };
    useEffect(() => {
        let httpRequest = new AbortController();
        if (retryCount > 0) {
            getConvoAnalytics(useTableLoader, httpRequest);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        } else {
            getCapsuleClasses(httpRequest);
        }
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, [capsuleClasses, retryCount, filters, useTableLoader]);

    const tableData = capsulesAnalytics?.map(
        ({ capsuleEntryId, capsuleClassId, percentageFrequency }) => {
            const itemCapsuleClass = capsuleClasses?.find(
                (x) => x.capsuleClassId === capsuleClassId
            );
            return {
                capsuleName: (
                    <TicketInfoCapsule
                        capsule={itemCapsuleClass}
                        capsuleEntryId={capsuleEntryId}
                        noStyles
                    />
                ),
                capsuleType: `${itemCapsuleClass?.capsuleClassName}`,
                percentage: `${percentageFrequency}%`,
                dropdownElement: (
                    <AnalyticsTSamCapsulesTDropdown
                        capsuleEntryId={capsuleEntryId}
                    />
                ),
            };
        }
    );
    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return <Empty text={"Capsules Analytics not available."} />;
            case DATAMODE:
                return (
                    <>
                        <Table
                            headers={ANALYTICS_CAPSULES_TABLE_HEADERS}
                            dataSource={tableData}
                            isAccordion={true}
                            isLoading={tableIsLoading}
                            hasErrors={Boolean(errorMssg)}
                            tableError={errorMssg}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filters}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConvoAnalytics}
                    />
                );
            default:
                return "";
        }
    };
    const handleSortByAlpha = (value) => {
        updatePageFilters({
            ...filters,
            alphabeticalOrder: value,
            dateCreatedOrder: null,
            lastModified: null,
        });
    };

    const handleSortByDate = (value) => {
        updatePageFilters({
            ...filters,
            dateCreatedOrder: value,
            alphabeticalOrder: null,
            lastModified: null,
        });
    };

    const handleSortByLastModified = (value) => {
        setUseTableLoader(false);
        updatePageFilters({
            ...filters,
            dateCreated: null,
            alphabetical: null,
            lastModified: value,
        });
    };

    const handleSearch = (search) => {
        updatePageFilters({
            ...filters,
            search,
        });
    };
    return (
        <div className='analytics-tsam-capsules-table'>
            <AnalyticsTSamHeading
                title='Capsules'
                placeholder={"Search capsules here"}
                hasFilter={true}
                handleSortByDate={handleSortByDate}
                handleSortByAlpha={handleSortByAlpha}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
            />
            {renderBasedOnStatus()}
        </div>
    );
};
export default AnalyticsTSamCapsulesTable;
