import TabbedWrapperHeader from "routes/TabbedWrapperRouter/TabbedWrapperHeader/TabbedWrapperHeader";
import AnalyticsTSamCapsules from "../../AnalyticsTSamCapsules/AnalyticsTSamCapsules";
import AnalyticsTSamConversations from "../../AnalyticsTSamConversations/AnalyticsTSamConversations";

const AnalyticsTSamTabs = () => {
    const tabbledRoutes = [
        {
            title: "Conversations",
            path: "/analytics/t-sam/conversations",
            component: AnalyticsTSamConversations,
        },
        {
            title: "Capsules",
            path: "/analytics/t-sam/capsules",
            component: AnalyticsTSamCapsules,
        },
    ];
    return (
        <>
            <TabbedWrapperHeader tabbedRoutes={tabbledRoutes} />
        </>
    );
};

export default AnalyticsTSamTabs;
