import React, { useState } from "react";
import { Button } from "components/ui";
import RuleConstrainInput from "./RuleConstrainInput/RuleConstrainInput";
const CreateRuleConstraintForm = ({
    elementId,
    constraintKeysData,
    activeRule,
    saveCloseConstraintsForm,
    removeRuleConstraint,
    errors,
    handleRemoveCustomRuleError,
}) => {
    const [constraintValue, setConstraintValue] = useState(
        activeRule?.ruleConstraint
    );
    const [error, setError] = useState(
        errors?.[elementId]?.customRules?.includes(activeRule?.ruleIndex) ||
            false
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        if (constraintValue?.length > 0) {
            handleRemoveCustomRuleError(elementId, activeRule?.ruleIndex);
            return saveCloseConstraintsForm(constraintValue);
        }
        setError(true);
    };

    return (
        <div className='mt-3'>
            <form onSubmit={handleSubmit}>
                <div className='row align-items-center'>
                    <div className='col-md-5'>
                        <RuleConstrainInput
                            removeRuleConstraint={removeRuleConstraint}
                            {...constraintKeysData}
                            handleInputChange={(value) => {
                                setError("");
                                setConstraintValue(value);
                            }}
                            defaultValue={constraintValue}
                            hasError={error}
                        />
                    </div>
                    <div className='col-md-1 px-0'>
                        <Button
                            type={"submit"}
                            text='Save'
                            classType='primary'
                            otherClass='w-100 btn-deletable px-0'
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateRuleConstraintForm;
