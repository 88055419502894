import React from "react";
import Filter from "components/dashboard/common/Filter/Filter";
import Heading from "components/ui/Heading/Heading";

const ConversationsHeader = ({
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter,
    searchDefaultValue,
}) => {
    return (
        <>
            <Heading
                headingText={"Conversations"}
                headingSubText={"Add & change your conversations"}
            />
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
                searchPlaceholder='Search conversations here'
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                hideClassFilter
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchDefaultValue={searchDefaultValue}
            />
        </>
    );
};

export default ConversationsHeader;
