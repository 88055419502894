import { Button, Checkbox, Loading, Empty } from "components/ui";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { capitalizeFirstLetter } from "utils/helper";
import AsyncSearchForm from "components/ui/InputTypes/InputSearch/AsyncSearchForm/AsyncSearchForm";
import "./InviteTeamSearch.scss";

const { NULLMODE, ERROR, DATAMODE, LOADING } = dataQueryStatus;
const InviteTeamSearch = ({
    closeInviteTeamSearch,
    users,
    setUsers,
    selectedUsers,
    setSelectedUsers,
    status,
    userErrorMssg,
    request,
    setRequest,
    handleRetry,
    updateErrors,
}) => {
    const handleSelectedUser = (user) => {
        updateErrors?.("userIds", "");
        if (request?.userIds.includes(user?.userId)) {
            const filteredIds = request?.userIds.filter(
                (userId) => userId !== user?.userId
            );

            const filteredUsers = selectedUsers?.filter(
                (selectedUser) => selectedUser?.userId !== user?.userId
            );

            setSelectedUsers([...filteredUsers]);

            return setRequest({
                ...request,
                userIds: [...filteredIds],
            });
        }

        setSelectedUsers([
            ...selectedUsers,
            {
                name: `${user?.firstName} ${user?.lastName}`,
                userId: user?.userId,
            },
        ]);

        setRequest({
            ...request,
            userIds: [...request?.userIds, user?.userId],
        });
    };

    const renderCompBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return <Empty className='h-100' />;
            case DATAMODE:
                return users?.map((user, i) => (
                    <Checkbox
                        grpClass={`mb-3 ${
                            request?.userIds.includes(user?.userId)
                                ? "mb-3 search--checkbox__container"
                                : ""
                        }`}
                        name={`${user?.firstName} ${user?.lastName}`}
                        label={`${capitalizeFirstLetter(
                            user?.firstName
                        )} ${capitalizeFirstLetter(user?.lastName)}`}
                        checked={request?.userIds.includes(user?.userId)}
                        onChange={() => handleSelectedUser(user)}
                        labelClass='label fw-medium'
                        inputClass='input-check'
                        id={`${user?.firstName} ${user?.lastName}`}
                        key={i}
                    />
                ));
            case ERROR:
                return (
                    <ErrorView
                        message={userErrorMssg}
                        size='50'
                        handleRetry={handleRetry}
                    />
                );
            default:
                return <Loading />;
        }
    };

    return (
        <div className='invite-team-search'>
            <div className='invite-team-search--actions'>
                <AsyncSearchForm
                    setData={setUsers}
                    placeholder='Search member here'
                    endpoint='getUsers'
                />
                <Button
                    type='submit'
                    text='Invite'
                    classType='primary'
                    otherClass='invite-team-search--actions--btn btn-s fw-medium'
                    onClick={closeInviteTeamSearch}
                />
            </div>
            <div className='invite-team-search--checkbox__list'>
                {renderCompBasedOnStage()}
            </div>
        </div>
    );
};

export default InviteTeamSearch;
