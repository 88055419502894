import Heading from "components/ui/Heading/Heading";

import "./CapsuleStatusListingHeader.scss";
import SelectUI from "components/ui/SelectUI/SelectUI";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";

const CapsuleStatusListingHeading = () => {
    return (
        <Heading
            headingText={"Capsules"}
            headingSubText={"Search & change your capsules."}
        />
    );
};

const CapsuleStatusListingSelect = ({
    capsuleClasses,
    handleClassSelect,
    selectedCapsuleClass,
}) => {
    return (
        <SelectUI
            grpClass='mb-3'
            name='selectCapsule'
            label=''
            labelClass='text-dark fw-medium'
            id='selectCapsule'
            placeholder='Select Capsule'
            options={capsuleClasses.map((capsuleClass) => {
                return {
                    label: capsuleClass?.capsuleClassName,
                    value: capsuleClass,
                };
            })}
            value={{
                label: selectedCapsuleClass?.capsuleClassName,
                value: selectedCapsuleClass,
            }}
            handleChange={(name, value) => handleClassSelect(value)}
        />
    );
};

const CapsuleStatusListingSearch = ({
    handleSearch,
    clearSearch,
    searchIsLoading,
}) => {
    return (
        <PaginationSearch
            handleSearch={handleSearch}
            placeholder='Search capsules here'
            isLoading={searchIsLoading}
            clearSearch={clearSearch}
        />
    );
};

const CapsuleStatusListingHeader = ({
    capsuleClasses,
    selectedCapsuleClass,
    handleClassSelect,
    hasCapsuleClassSelect,
    handleSearch,
    clearSearch,
    searchIsLoading,
}) => {
    return (
        <>
            <CapsuleStatusListingHeading />
            <div className='capsule__status__listing__heading'>
                {hasCapsuleClassSelect && (
                    <div className='select-input'>
                        <CapsuleStatusListingSelect
                            {...{
                                capsuleClasses,
                                selectedCapsuleClass,
                                handleClassSelect,
                            }}
                        />
                    </div>
                )}
                <div className='search-input'>
                    <CapsuleStatusListingSearch
                        handleSearch={handleSearch}
                        clearSearch={clearSearch}
                        searchIsLoading={searchIsLoading}
                    />
                </div>
            </div>
        </>
    );
};

export default CapsuleStatusListingHeader;
