import SearchInput from "components/ui/SearchInput/SearchInput";
import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import BuilderElement from "./BuilderElement/BuilderElement";
import { formElementBlocks } from "./data";
import "./BuilderElementsList.scss";

const BuilderElementsList = ({ errors }) => {
    const [builderElementsList, setBuilderElementsList] =
        useState(formElementBlocks);

    const handleSearch = (value) => {
        if (value.length > 2) {
            const formElementBlocksCopy = Object.assign({}, formElementBlocks);
            Object.keys(formElementBlocksCopy).forEach((key) => {
                if (formElementBlocksCopy[key]?.title?.search(value) < 0)
                    delete formElementBlocksCopy[key];
            });
            setBuilderElementsList(formElementBlocksCopy);
        } else {
            setBuilderElementsList(formElementBlocks);
        }
    };

    return (
        <div className='builder__element__list col-4'>
            <div className='builder__col scrollbar scrollbar--white'>
                <h1 className='builder__col--title '>Builder</h1>
                <div className='builder__col--search'>
                    <SearchInput name='search' handleChange={handleSearch} />
                </div>

                <p className="error-msg">{errors?.branch}</p>
                <Droppable droppableId={"builder"} index={1}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {Object.entries(builderElementsList).map(
                                ([elementKey, builderElement], index) => (
                                    <BuilderElement
                                        key={elementKey}
                                        order={index}
                                        item={builderElement}
                                    />
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

export default BuilderElementsList;
