import { CLASSIFICATION_PRIORITY } from "./enums";
import { pipeEnums, capitalizeFirstLetter } from "utils/helper";
import "./ClassificationPriority.scss";

const ClassificationPriority = ({ request, setRequest }) => {
    return (
        <div className='form-group mb-3'>
            <label className='form-label text-dark fw-medium'>
                Select priority
            </label>
            <ul className='classification__priority'>
                {Object.keys(CLASSIFICATION_PRIORITY).map((key, i) => (
                    <li
                        key={i}
                        onClick={() =>
                            setRequest({ ...request, priority: key })
                        }
                        className={`classification__priority--item ${
                            key === request?.priority ? `selected` : ``
                        }`}>
                        {capitalizeFirstLetter(pipeEnums(key))}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClassificationPriority;
