import SelectUI from "components/ui/SelectUI/SelectUI";

const FilterSelectUI = ({
    selectedClassEntry,
    selectedCapsuleClassEntries,
    selectedRelatedClass,
    loading,
    handleSelectCapsuleFilter,
}) => {
    return (
        <>
            <div className='filter__select'>
                <SelectUI
                    name='selectClass'
                    id='selectClass'
                    isLoading={loading}
                    placeholder={`Select ${selectedRelatedClass?.capsuleClassName}`}
                    onChange={({ value }) => handleSelectCapsuleFilter(value)}
                    value={selectedClassEntry}
                    hasOptionIcon={true}
                    options={selectedCapsuleClassEntries.map((capsuleEntry) => {
                        return {
                            label: capsuleEntry?.content,
                            value: capsuleEntry,
                            icon: "radio",
                        };
                    })}
                />
            </div>
        </>
    );
};

export default FilterSelectUI;
