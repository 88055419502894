import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BranchCollectionViewElement from "./BranchCollectionViewElement/BranchCollectionViewElement";
import BranchCollectionViewHeader from "./BranchCollectionViewHeader/BranchCollectionViewHeader";
import ErrorView from "components/ui/ErrorView/ErrorView";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const BranchCollectionView = ({ location }) => {
    const { branchCollectionId } = useParams();
    const [branchCollection, setBranchCollection] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [collectionBranches, updateCollectionBranches] = useState([]);
    const [convoMapBranches, updateConvoMapBranches] = useState([]);

    const [showCollectionError, setShowCollectionError] = useState(false);

    const getBranchCollection = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoute.getBranchCollection(branchCollectionId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;
                setBranchCollection({
                    branchCollectionId: data?.branchCollectionId,
                    branchCollectionName: data?.branchCollectionName,
                    branchCollectionType: data?.branchCollectionType,
                    workspaceId: data?.workspaceId,
                    mainBranch: data?.mainBranch,
                });
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const retrieveAvailableCollection = () => {
        const availableCollection = location?.state?.branchCollection;
        const { mainBranch } = availableCollection || {};
        if (availableCollection && mainBranch) {
            setBranchCollection(availableCollection);
            setStatus(DATAMODE);
        } else {
            getBranchCollection();
        }
    };

    useEffect(() => {
        retrieveAvailableCollection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <>
                        <BranchCollectionViewHeader
                            branchCollection={branchCollection}
                            hasError={showCollectionError}
                        />
                        <BranchCollectionViewElement
                            branchCollectionId={branchCollectionId}
                            branchCollection={branchCollection}
                            updateCollectionBranches={updateCollectionBranches}
                            updateConvoMapBranches={updateConvoMapBranches}
                            convoMapBranches={convoMapBranches}
                            collectionBranches={collectionBranches}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getBranchCollection}
                    />
                );
            default:
                return <Loading />;
        }
    };

    const checkIfCollectionHasInvalidBranches = () => {
        // filter out branches with branch options linked to null and with no action
        const invalidBranches = collectionBranches?.filter(
            ({ branchOptions }) =>
                branchOptions?.length === 0 ||
                branchOptions?.filter(
                    ({ branchOptionActionType, branchOptionValue, branchOptionCapsuleEntryId }) =>
                        ((branchOptionValue === null && branchOptionActionType === null) || (branchOptionActionType === "FORWARD_AGENT" && branchOptionCapsuleEntryId === null))
                )?.length > 0
        );

        if (invalidBranches?.length > 0) {
            setShowCollectionError(true);
        } else {
            setShowCollectionError(false);
        }
    };

    useEffect(() => {
        checkIfCollectionHasInvalidBranches();
        // eslint-disable-next-line
    }, [collectionBranches]);

    return <>{renderBasedOnStatus()}</>;
};

export default BranchCollectionView;
