import TeamClassification from "./TeamClassification/TeamClassification";
import { Button } from "components/ui";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;

const ViewTeamHeaderActions = ({ teamData, openModal }) => {
    return (
        <div className='view__team--actions'>
            <div className='header'>
                <h1 className='header--text fw-bold'>{teamData?.teamName}</h1>
                <TeamClassification
                    classifications={teamData?.classifications}
                />
            </div>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                <Button
                    text='Edit team'
                    classType='outline-secondary'
                    otherClass='view__team--actions--btn btn-xsm'
                    onClick={openModal}
                />
            </ComponentAccessControl>
        </div>
    );
};

export default ViewTeamHeaderActions;
