import { Table } from "components/ui";
import {
    PERSONAS_TABLE_HEADERS,
    PERSONAS_TABLE_ACTIONS,
} from "../PersonasHeader/enum";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import PersonaName from "./PersonaName/PersonaName";
import PersonaStatus from "./PersonaStatus/PersonsStatus";
import PersonaDropdown from "./PersonaDropdown/PersonaDropdown";
import { localeDate } from "utils/helper";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const PersonasTable = ({
    personas,
    tableIsLoading,
    error,
    updatePersonaStatus,
    handleSuccess,
}) => {
    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState({});
    const history = useHistory();

    const handleRedirect = (persona) => {
        history.push({
            pathname: `/service-module/edit/persona`,
            state: {
                personaId: persona?.userId,
                email: persona?.email,
                displayPicture: persona?.displayPicture,
                active: persona?.active.toString(),
                fullName: `${persona?.firstName} ${persona?.lastName}`,
            },
        });
    };

    const handleActionModal = (action, persona) => {
        switch (action) {
            case PERSONAS_TABLE_ACTIONS.EDIT:
                handleRedirect(persona);
                break;
            case PERSONAS_TABLE_ACTIONS.DELETE:
                setSelectedPersona(persona);
                toggleDeleteModal(true);
                break;
            default:
                break;
        }
    };

    const tableData = personas?.map((persona) => ({
        name: <PersonaName persona={persona} />,
        dateCreated: localeDate(persona?.createdDate),
        status: (
            <PersonaStatus
                persona={persona}
                updatePersonaStatus={updatePersonaStatus}
            />
        ),
        actions: (
            <PersonaDropdown
                persona={persona}
                handleActionModal={handleActionModal}
            />
        ),
    }));

    return (
        <>
            <Table
                headers={PERSONAS_TABLE_HEADERS}
                dataSource={tableData}
                hasErrors={Boolean(error)}
                isLoading={tableIsLoading}
                tableError={error}
            />

            {showDeleteModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete ${selectedPersona?.firstName} ${selectedPersona?.lastName}`}
                    show={showDeleteModal}
                    closeModal={() => toggleDeleteModal(false)}
                    handleSuccess={handleSuccess}
                    url={apiRoute.persona(selectedPersona?.userId)}
                    method='delete'
                />
            )}
        </>
    );
};

export default PersonasTable;
