import DeletableInput from "components/dashboard/KnowledgeBase/FormSetup/FormBuilderForm/common/DeletableInput/DeletableInput";
import AddButton from "components/ui/Button/AddButton/AddButton";

const BranchSubSentencesInput = ({
    subSentences,
    handleSubInputChange,
    addSubSentence,
    removeSubSentence,
    subSentenceErrors,
}) => {
    return (
        <div className='mb-3'>
            <div className='row mb-2'>
                <div className='ms-auto col-md-5'>
                    <AddButton
                        btnText={`Add a Sub Sentence`}
                        onClick={addSubSentence}
                        otherClassName='justify-content-end'
                    />
                </div>
            </div>

            {subSentences?.map((subSentence, index) => {
                return (
                    <DeletableInput
                        name={index}
                        key={index}
                        value={subSentence}
                        placeholder='Sub Sentence '
                        hideBadge={true}
                        otherClassName='mb-2'
                        handleInputChange={handleSubInputChange}
                        handleInputDelete={() => removeSubSentence(index)}
                        error={subSentenceErrors?.[index]}
                    />
                );
            })}
        </div>
    );
};

export default BranchSubSentencesInput;
