export const CAPSULE_CONTENT_BLOCK_FORMATS = Object.freeze({
    TEXT: "TEXT",
    PICTURE: "PICTURE",
    VIDEO: "VIDEO",
    RICH_TEXT: "RICH_TEXT",
    DESCRIPTION: "DESCRIPTION",
    LINK: "LINK",
    GIF: "GIF",
    TITLE: "TITLE",
});

export const contentBlocks = Object.freeze({
    TEXT: {
        title: "Text Input",
    },
    RICH_TEXT: {
        title: "Rich Text",
    },
    PICTURE: {
        title: "Picture",
    },
    VIDEO: {
        title: "Video",
    },
    DESCRIPTION: {
        title: "Description",
    },
    LINK: {
        title: "Link",
    },
    GIF: {
        title: "Gif",
    },
});
