import { useEffect, useState } from "react";
import UpdatesHeader from "../../common/UpdatesHeader/UpdatesHeader";
import UpdatesTab from "../../common/UpdatesTab/UpdatesTab";
import EmailFollowupsTable from "./EmailFollowupsTable/EmailFollowupsTable";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import {
    CANNED_RESOURCE_ACTIONS,
    CANNED_RESOURCE_TYPES,
} from "components/dashboard/TrainSAM/CannedResponses/enum";
import CannedTasksModal from "../../common/modal/CannedTasksModal/CannedTasksModal";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const { CREATE_CANNED_RESOURCE, EDIT_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const EmailFollowups = ({ match }) => {
    const { params } = match;
    const agentUpdateId = params?.[0];

    const [status, setStatus] = useState();
    const [errorMssg, setErrorMssg] = useState();
    const [showModal, toggleModal] = useState(false);
    const [cannedTasks, setCannedTasks] = useState([]);
    const [agentUpdateDetails, setAgentUpdateDetails] = useState({});

    const [selectedCannedTask, selectCannedTask] = useState();

    const [modalAction, setModalAction] = useState();

    const agentUpdateType = CANNED_RESOURCE_TYPES?.CANNED_EMAIL;

    const getUpdatesTasks = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute.getUpdateTasks(agentUpdateId);
            const res = await API.get(url);

            if (res.status === 200) {
                setStatus(DATAMODE);
                const { data } = res.data;
                const { agentUpdateTasks, ...rest } = data;
                setAgentUpdateDetails(rest);
                setCannedTasks(agentUpdateTasks);
                setStatus(agentUpdateTasks?.length > 0 ? DATAMODE : NULLMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSuccess = () => {
        getUpdatesTasks();
    };

    const handleActionModal = (actionType, cannedTask) => {
        setModalAction(actionType);
        toggleModal(true);
        selectCannedTask(cannedTask);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getUpdatesTasks}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Canned Update Tasks Available Yet"} />;

            case DATAMODE:
                return (
                    <>
                        <EmailFollowupsTable
                            handleSuccess={handleSuccess}
                            handleActionModal={handleActionModal}
                            modalAction={modalAction}
                            cannedTasks={cannedTasks}
                        />
                        <CustomPagination
                        // totalRecords={totalRecords}
                        // handlePageChange={handlePageChange}
                        // {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        getUpdatesTasks();
        // eslint-disable-next-line
    }, []);

    const isEdit = modalAction === EDIT_CANNED_RESOURCE;

    return (
        <>
            <UpdatesTab agentUpdateId={agentUpdateId} />
            <div className='padded__layout'>
                <UpdatesHeader
                    title={agentUpdateDetails?.agentUpdateName || "-"}
                    subTitle={""}
                    btnText={"New update"}
                    placeholder={"Search updates here"}
                    handleClick={() =>
                        handleActionModal(CREATE_CANNED_RESOURCE)
                    }
                />
                {renderBasedOnStatus()}
            </div>

            {showModal && (
                <CannedTasksModal
                    showModal={showModal}
                    title={isEdit ? "Update Followup" : "New Followup"}
                    handleSuccess={handleSuccess}
                    handleActionModal={handleActionModal}
                    closeModal={() => toggleModal(false)}
                    cannedResourceType={agentUpdateType}
                    modalAction={modalAction}
                    selectedCannedTask={selectedCannedTask}
                    isEdit={isEdit}
                    agentUpdateId={agentUpdateId}
                />
            )}
        </>
    );
};

export default EmailFollowups;
