import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import ReminderDuration from "./ReminderDuration/ReminderDuration";
import "./TaskReminder.scss";
import TaskReminderDropdown from "./TaskReminderDropdown/TaskReminderDropdown";

const TaskReminder = ({ reminderDuration, handleTaskReminderAction }) => {
    return (
        <div className='task__reminder'>
            <span className='task__reminder__text'>Reminder</span>
            <div className='task__reminder__duration__container'>
                <ReminderDuration reminderDuration={reminderDuration} />
                <ReactSVG src={imageLinks.icons.pipe} />
                <TaskReminderDropdown
                    handleTaskReminderAction={handleTaskReminderAction}
                />
            </div>
        </div>
    );
};

export default TaskReminder;
