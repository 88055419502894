import React, { useEffect, useState } from "react";
import CapsuleClassesList from "components/dashboard/KnowledgeBase/CapsuleSettings/CapsuleClasses/CapsuleClassesList/CapsuleClassesList";
import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import ClassesContainer from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesContainer/ProductClasses/ClassesContainer/ClassesContainer";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { getErrorMessage } from "utils/helper";
import { getCapsulesEntries } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import SearchClassCapsuleInput from "./SearchClassCapsuleInput/SearchClassCapsuleInput";
import CapsuleContentList from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleContentList/CapsuleContentList";
import "./ClassCapsuleContent.scss";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const ClassCapsuleContent = ({
    capsuleClasses,
    classesListBreakpoint,
    pushSelectedClass,
    filterIds,
    searchable,
    selectable,
    selectedContentIds,
    defaultCapsuleClassId,
    handleContentSelect,
    saveCount,
    capsuleBottomMenu,
    hideEditAction,
    hideDeleteAction,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [selectedCapsuleClass, selectCapsuleClass] = useState();
    const [capsules, setCapsules] = useState([]);
    const [filteredCapsules, setFilteredCapsules] = useState([]);

    const handleClassSelect = async (selectedClass) => {
        try {
            if (selectedClass !== selectedCapsuleClass) {
                setErrorMssg();
                setStatus(LOADING);
                saveCount(0);
                selectCapsuleClass(selectedClass);
                pushSelectedClass(selectedClass);

                const { capsuleEntries, total } = await getCapsulesEntries(
                    selectedClass
                );

                setCapsules(capsuleEntries);
                setFilteredCapsules(capsuleEntries);
                saveCount(total);
                setStatus(DATAMODE);
            } else {
                selectCapsuleClass();
                pushSelectedClass();
                setCapsules([]);
                setFilteredCapsules([]);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const searchCapsuleContent = (searchKeyword) => {
        const ccCapsule =
            searchKeyword.length > 0
                ? capsules.filter(({ content }) =>
                      content
                          ?.toLocaleLowerCase()
                          .includes(searchKeyword?.toLocaleLowerCase())
                  )
                : capsules;
        setFilteredCapsules(ccCapsule);
    };

    useEffect(() => {
        if (defaultCapsuleClassId) {
            const defaultCapsuleClass = capsuleClasses.find(
                (item) => item.capsuleClassId === defaultCapsuleClassId
            );
            handleClassSelect(defaultCapsuleClass);
        } else {
            handleClassSelect(capsuleClasses[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return (
                    <div className='capsule__loading__container'>
                        <CapsuleSkeletonLoader count={4} />
                    </div>
                );

            case DATAMODE:
                return (
                    <>
                        {searchable && (
                            <SearchClassCapsuleInput
                                handleSearch={searchCapsuleContent}
                            />
                        )}
                        <CapsuleContentList
                            capsules={filteredCapsules}
                            capsuleClass={selectedCapsuleClass}
                            handleContentSelect={handleContentSelect}
                            selectedContentIds={selectedContentIds}
                            selectable={selectable}
                            filterIds={filterIds}
                            hideAddButton
                            capsuleBottomMenu={capsuleBottomMenu}
                            hideDeleteAction={hideDeleteAction}
                            hideEditAction={hideEditAction}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={handleClassSelect}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='capsules__content--picker'>
            <CapsuleClassesList
                data={capsuleClasses}
                classesListBreakpoint={classesListBreakpoint}
                selected={selectedCapsuleClass?.capsuleClassId}
                handleClick={handleClassSelect}
                withEmptyClassNames
            />
            <ClassesContainer extendHeight={searchable}>
                {renderBasedOnStatus()}
            </ClassesContainer>
        </div>
    );
};

ClassCapsuleContent.defaultProps = {
    pushSelectedClass: () => {},
    saveCount: () => {},
};

export default ClassCapsuleContent;
