import React from "react";
import "./BranchesListItem.scss";

const BranchesListItem = ({
    branchName,
    handleSelect,
    isActive,
    isLastToggled,
    showEmptyOptionLabel,
    isAgentGuideView,
    ...rest
}) => {
    const { branchOptions } = rest || {};

    const isBranchInvalid = () => {
        if (branchOptions?.length === 0) {
            return true;
        }

        const invalidOptions = branchOptions?.filter(
            ({
                branchOptionValue,
                branchOptionActionType,
                guideFlowOptionActionType,
                guideFlowOptionValue,
                branchOptionCapsuleEntryId
            }) =>
                isAgentGuideView
                    ? guideFlowOptionValue === null &&
                      guideFlowOptionActionType === null
                      : ((branchOptionValue === null && branchOptionActionType === null) || (branchOptionActionType === "FORWARD_AGENT" && branchOptionCapsuleEntryId === null))
        );

        return invalidOptions?.length > 0 ? true : false;
    };

    return (
        <div
            className={`branch__list--item ${isActive ? "active" : ""} ${
                isLastToggled ? "semi-active" : ""
            }`}
            onClick={handleSelect}>
            {branchName}
            {showEmptyOptionLabel && (
                <>
                    {isBranchInvalid() && (
                        <span
                            className={`${isActive ? "active" : ""} ${
                                isLastToggled ? "semi-active" : ""
                            }`}></span>
                    )}
                </>
            )}
        </div>
    );
};

export default BranchesListItem;
