import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import TeamForm from "./TeamForm/TeamForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import TeamModalInstruction from "./TeamModalInstruction/TeamModalInstruction";
import "./TeamModal.scss";

const TeamsModal = ({ showSideModal, closeModal, editItem, handleSuccess }) => {
    return (
        <SideModal show={showSideModal} close={closeModal}>
            <div className='row me-0 ms-0 team-form'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={`${editItem ? "Edit" : "Create"} Team`}
                        subTitle={`${
                            editItem
                                ? `Edit ${editItem?.teamName}`
                                : "Create a new"
                        } team`}
                    />
                    {!editItem && <TeamModalInstruction />}
                    <TeamForm
                        closeModal={closeModal}
                        editItem={editItem}
                        handleSuccess={handleSuccess}
                    />
                </div>
            </div>
        </SideModal>
    );
};

TeamsModal.prototypes = {
    showSideModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    editItem: PropTypes.object,
};

export default TeamsModal;
