import ViewCapsulesContainer from "./ViewCapsulesContainer/ViewCapsulesContainer";

const DependencyCapsulesView = ({ match }) => {
    const { params: { capsuleClassId, capsuleType } = {} } = match || {};
    return (
        <>
            <ViewCapsulesContainer
                capsuleClassId={capsuleClassId}
                capsuleType={capsuleType}
            />
        </>
    );
};

export default DependencyCapsulesView;
