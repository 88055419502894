import React, { useState, useEffect } from "react";
import { Empty, Loading } from "components/ui";
import Comments from "./Comments/Comments";
import imageLinks from "assets/images";
import CreateComment from "./CreateComment/CreateComment";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { convertCommentMentions } from "utils/helper";
import "./TicketMentions.scss";

const { LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const TicketMentions = ({ selectedTicket, fetchAllTickets }) => {
    const { ticketId } = selectedTicket || {};
    const [comments, setComments] = useState([]);
    const [errorMssg, setErrorMssg] = useState("");
    const [status, setStatus] = useState(LOADING);
    const [currentComment, setCurrentComment] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const handleSetCurrentComment = (commentId, comment, mentioned) => {
        let newComment = convertCommentMentions(comment, false);

        setCurrentComment({ commentId, comment: newComment, mentioned });
        setIsEdit(true);
    };

    const getTicketComments = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoute.getTicketComments(ticketId);

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setComments(data);
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getTicketComments(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return (
                    <Empty
                        icon={imageLinks.icons.comment}
                        text='No comment has been added yet.'
                        className='empty__ticket__activity'
                    />
                );
            case DATAMODE:
                return (
                    <div>
                        <Comments
                            comments={comments}
                            selectedTicket={selectedTicket}
                            handleSuccess={getTicketComments}
                            handleSetCurrentComment={handleSetCurrentComment}
                            fetchAllTickets={fetchAllTickets}
                        />
                    </div>
                );
            case ERROR:
                return <ErrorView message={errorMssg} />;
            default:
                return "";
        }
    };

    return (
        <div className='ticket__mentions'>
            {" "}
            <div className='comment__input__group'>
                <CreateComment
                    ticketId={ticketId}
                    currentComment={currentComment}
                    handleSuccess={getTicketComments}
                    setCurrentComment={setCurrentComment}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                />
            </div>
            {renderBasedOnStatus()}
        </div>
    );
};

export default TicketMentions;
