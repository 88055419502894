import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import { otherCategories } from "components/dashboard/AgentInbox/enum";
import ChatModule from "./ChatModule/ChatModule";
import CustomerTicketsContainer from "./CustomerTicketsContainer/CustomerTicketsContainer";
import TicketDetail from "./TicketDetails/TicketDetail";
import CloseTicket from "./ChatModule/CloseTicket/CloseTicket";
import { setIncomingTickets } from "store/tickets/incomingTickets/actions";
import { motion, AnimatePresence } from "framer-motion";
import TicketsFilterGroup from "../TicketsFilterGroup/TicketsFilterGroup";
import { setActiveTicket } from "store/tickets/actions";
import "./ChatTickets.scss";

const { LOADING, ERROR, IDLE, DATAMODE } = dataQueryStatus;
const { EXTERNAL_EMAILS } = otherCategories;

const ChatTickets = ({
    reconnectUser,
    refreshData,
    setTicketData,
    handleRefreshData,
    showFilters,
    toggleFilters,
    handleToggleEmailPreview,
    selectedTicketHeader,
    setSelectedTicketHeader,
    refetchEmails,
    handleSuccess,
    selectedCannedEmail,
    showEmailPreview,
    emailAction,
    emailPreviewValue,
    toggleEmailPreview,
    ticketData,
    handleRemoveEmailPreview,
    selectAgentGuide,
}) => {
    const { ticketsfilterRequest } = useSelector((state) => state?.ticketsFilters);
    const { ticketInfoValue, messageTagId, agentId } = ticketsfilterRequest;

    const { activeTicket: selectedTicket } = useSelector(
        (state) => state?.tickets
    );

    const [status, setStatus] = useState(IDLE);
    const [fetchMessagesStatus, setFetchMessageStatus] = useState(IDLE);

    const [errorMssg, setErrorMssg] = useState("");
    const [showTicketActionModal, toggleTicketActionModal] = useState(false);
    const [showClipboard, toggleEmailClipboard] = useState(false);


    const [recentCustomerMessage, setRecentCustomerMessage] = useState("");
    const [investigativeCustomerMessage, setInvestigativeCustomerMessage] = useState("");
    const [selectedCustomerMessage, selectCustomerMessage] = useState({});

    const [ticketToClose, setTicketToClose] = useState({});
    const [cannedMessage, setCannedMessage] = useState("");
    const [attachments, setAttachments] = useState({
        fileAttachments: [],
        ticketAttachment: {},
    });

    const dispatch = useDispatch();

    const handleToggleTicketActionModal = (ticket) => {
        toggleTicketActionModal(true);
        setTicketToClose(ticket);
    };

    const handleToggleEmailAttachment = (attachmentType, attachmentData) => {
        // toggleEmailAttachment(true);

        setSelectedTicketHeader(EXTERNAL_EMAILS);
        toggleEmailPreview(true);

        setAttachments((prev) => ({
            ...prev,
            [attachmentType]: attachmentData,
        }));

    };

    const toggleClipboardAndAttachment = (attachmentType) => {
        setSelectedTicketHeader(EXTERNAL_EMAILS);
        toggleEmailClipboard(true)
    }

    const fetchAllTickets = async (search) => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getTickets;
            const res = await API.get(url, {
                params: {
                    ...ticketsfilterRequest
                },
            });

            if (res.status === 200) {
                const tickets = res.data.data;
                handleRemoveEmailPreview();
                dispatch(setIncomingTickets(tickets));
                dispatch(setActiveTicket(null));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };
    

    const clearSelectedCustomerMessage = () => {
        selectCustomerMessage({});
    };

    useEffect(() => {
        if (ticketInfoValue || messageTagId || agentId) {
            fetchAllTickets();
        }
        setCannedMessage("");
        //eslint-disable-next-line
    }, [ticketInfoValue, messageTagId, refreshData, agentId]);




    return (
        <div className='chat__ticket__container'>
            <div className='col-8 pe-0'>
                <TicketsFilterGroup
                    {...{
                        showFilters,
                        toggleFilters,
                        refreshData,
                        handleRefreshData,
                        selectedTicketHeader,
                    }}
                />
                <div className='chat__ticket--section'>
                    <div className='container-fluid h-100'>
                        <div className='row h-100'>
                            <div className='col-4 pe-0'>
                                <CustomerTicketsContainer
                                    status={status}
                                    errorMssg={errorMssg}
                                    handleRetry={fetchAllTickets}
                                    handleRemoveEmailPreview={
                                        handleRemoveEmailPreview
                                    }
                                    selectedTicketHeader={selectedTicketHeader}
                                    clearSelectedCustomerMessage={clearSelectedCustomerMessage}
                                />
                            </div>
                            <div className='col-8 px-0'>
                                <AnimatePresence exitBeforeEnter>
                                    <motion.div
                                        className={`chat__container`}
                                        key={
                                            selectedTicket !== null
                                                ? selectedTicket?.ticketId
                                                : "empty"
                                        }
                                        initial={{ x: 10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.5 }}>
                                        {selectedTicket !== null &&
                                            selectedTicket !== undefined ? (
                                            <ChatModule
                                                ticket={selectedTicket}
                                                setRecentCustomerMessage={
                                                    setRecentCustomerMessage
                                                }
                                                toggleTicketActionModal={(
                                                    ticket
                                                ) =>
                                                    handleToggleTicketActionModal(
                                                        ticket
                                                    )
                                                }
                                                key={selectedTicket?.ticketId}
                                                setTicketData={setTicketData}
                                                reconnectUser={reconnectUser}
                                                handleSuccess={
                                                    handleRefreshData
                                                }
                                                cannedMessage={cannedMessage}
                                                setCannedMessage={
                                                    setCannedMessage
                                                }
                                                showFilters={showFilters}
                                                attachments={attachments}
                                                {...{
                                                    handleSuccess,
                                                    showEmailPreview,
                                                    emailAction,
                                                    emailPreviewValue,
                                                    toggleEmailPreview,
                                                    ticketData,
                                                    setSelectedTicketHeader,
                                                    setInvestigativeCustomerMessage,
                                                    setFetchMessageStatus,
                                                    selectedCustomerMessage,
                                                    selectCustomerMessage,
                                                    toggleClipboardAndAttachment,
                                                    handleToggleEmailAttachment
                                                }}
                                            />
                                        ) : (
                                            <EmptyInfo
                                                otherClassNames={"with-margin"}
                                                message={`No conversation started yet,\n click on a tag above to begin`}
                                            />
                                        )}
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4 ps-0'>
                <TicketDetail
                    recentCustomerMessage={recentCustomerMessage}
                    fetchAllTickets={fetchAllTickets}
                    setCannedMessage={setCannedMessage}
                    handleToggleEmailPreview={handleToggleEmailPreview}
                    handleToggleEmailAttachment={handleToggleEmailAttachment}
                    selectedTicketHeader={selectedTicketHeader}
                    setSelectedTicketHeader={setSelectedTicketHeader}
                    refetchEmails={refetchEmails}
                    selectedCannedEmail={selectedCannedEmail}
                    selectedEmailData={emailPreviewValue}
                    selectAgentGuide={selectAgentGuide}
                    attachments={attachments}
                    fetchMessagesStatus={fetchMessagesStatus}
                    investigativeCustomerMessage={investigativeCustomerMessage}
                    selectedCustomerMessage={selectedCustomerMessage}
                    showClipboard={showClipboard}
                    toggleEmailClipboard={toggleEmailClipboard}
                />
            </div>
            {showTicketActionModal && (
                <CloseTicket
                    show={showTicketActionModal}
                    closeModal={() => toggleTicketActionModal(false)}
                    handleSuccess={fetchAllTickets}
                    ticketToClose={ticketToClose}
                />
            )}
        </div>
    );
};

export default ChatTickets;
