import React from "react";
import { MESSAGE_TAG_ACTIONS } from "../../../enum";
import KeyPhraseModal from "../KeyPhraseHeader/KeyPhraseModal/KeyPhraseModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { EDIT, DELETE } = MESSAGE_TAG_ACTIONS;

const KeyPhraseActionModals = ({
    keyPhrase,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT:
                return (
                    <KeyPhraseModal
                        keyPhrase={keyPhrase}
                        showModal={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        currentAction={currentAction}
                    />
                );

            case DELETE:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${keyPhrase?.keyword}`}
                        show={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute.editKeyPhrase(keyPhrase?.keyPhraseId)}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default KeyPhraseActionModals;
