import React from "react";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TicketsPFCollection.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const TicketsPFCollection = ({
    branchCollectionName,
    sendCollectionToTicket,
}) => {
    return (
        <ComponentAccessControl
            baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
            <div
                className={"pf__branches--branch"}
                onClick={sendCollectionToTicket}>
                <span>{branchCollectionName}</span>
            </div>
        </ComponentAccessControl>
    );
};

export default TicketsPFCollection;
