import React from "react";
import "./EmptyCannedcontent.scss";

const EmptyCannedContent = ({ text, onClick }) => {
    return (
        <span className='empty__canned__content__text' onClick={onClick}>
            {text}
        </span>
    );
};

export default EmptyCannedContent;
