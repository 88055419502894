import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loading } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { flattenArray, getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const AddTicketInfoForm = ({
    ticketId,
    existingTicketInfos,
    handleSuccess,
}) => {
    const [loading, setLoading] = useState();
    const [request, setRequest] = useState({
        ticketId: ticketId,
        ticketInfoCapsules: [],
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [selectedContents, setSelectedContents] = useState(new Map());
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const existingTicketInfoIds = existingTicketInfos
        ?.map((ticketInfo) => ticketInfo?.ticketInfoValue)
        .filter((ticketInfoValue) => ticketInfoValue !== null);

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        //eslint-disable-next-line
    }, []);

    const addTicketInfo = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const capsules = Array.from(selectedContents?.values()).filter(
                (capsule) => capsule?.classCapsuleEntryIds.length > 0
            );

            const requestData = {
                ...request,
                ticketInfoCapsules: capsules
                    .map(({ classCapsuleEntryIds, classCapsuleId }) =>
                        classCapsuleEntryIds?.map((classCapsuleEntryId) => ({
                            ticketInfoCapsuleClassId: classCapsuleId,
                            ticketInfoValue: classCapsuleEntryId,
                            ticketInfoType: "CAPSULE",
                        }))
                    )
                    .flat(),
            };

            const url = apiRoute.addTicketInfo;
            const res = await API.post(url, requestData);

            if (res.status === 201) {
                const { message } = res.data;

                handleSuccess();
                toastMessage(message);
                setRequest()
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId, capsuleCode } = capsule;
        setErrors({ ...errors, classificationCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleCode);
        if (prevSelectedContents) {
            const ccSelectedContentIds =
                prevSelectedContents?.classCapsuleEntryIds;
            const contentExist =
                prevSelectedContents?.classCapsuleEntryIds?.includes(
                    capsuleEntryId
                );

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                        capsuleEntryId
                    ),
                    1
                );
            }

            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: ccSelectedContentIds,
                            },
                        ],
                    ])
            );
        } else {
            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: [capsuleEntryId],
                            },
                        ],
                    ])
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "classificationCapsules",
        ]);
        if (formisValid) {
            addTicketInfo();
        } else {
            setErrors(formErrors);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={capsuleClasses?.map(
                            (capsuleClass) => capsuleClass?.capsuleCode
                        )}
                        classesListBreakpoint={8}
                        handleContentSelect={handleContentSelect}
                        selectable={true}
                        searchable={true}
                        selectedContentIds={flattenArray(
                            Array.from(selectedContents?.values())?.map(
                                (x) => x.classCapsuleEntryIds
                            )
                        )}
                        inputKey='classificationCapsules'
                        error={errors?.classificationCapsules}
                        filterIds={existingTicketInfoIds}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <form className='create-reminder mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <div className='form-group'>{renderBasedOnStatus()}</div>
            <Button
                type='submit'
                classType='primary'
                loading={loading}
                otherClass='w-100'
                text='Save'
            />
        </form>
    );
};

export default AddTicketInfoForm;
