import React from 'react';
import { Link } from 'react-router-dom';

const EmptyCapsuleClass = () => {
    return (
        <div>
            <i>Classes created on this workspace should appear here</i>
            <Link to={'/knowledge-base/capsule-settings'}>Create Capsule</Link>
        </div>
    );
};

export default EmptyCapsuleClass;