import { AGENT_GUIDE_ACTIONS } from "../../enum";
import CreateGuideModal from "../CreateGuideModal/CreateGuideModal";
import DeleteGuideModal from "../DeleteGuideModal/DeleteGuideModal";

const GuideModal = ({
    showModal,
    closeModal,
    handleSuccess,
    selectedGuide,
    modalAction,
}) => {
    const renderBasedOnModalAction = () => {
        switch (modalAction) {
            case AGENT_GUIDE_ACTIONS.CREATE_GUIDE:
            case AGENT_GUIDE_ACTIONS.EDIT_GUIDE:
                return (
                    <CreateGuideModal
                        showModal={showModal}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        {...{ selectedGuide, modalAction }}
                        isEdit={modalAction === AGENT_GUIDE_ACTIONS.EDIT_GUIDE}
                    />
                );

            case AGENT_GUIDE_ACTIONS.DELETE_GUIDE:
                return (
                    <DeleteGuideModal
                        {...{
                            selectedGuide,
                            closeModal,
                            handleSuccess,
                            showModal,
                        }}
                    />
                );

            default:
                return "";
        }
    };
    return <>{renderBasedOnModalAction()}</>;
};

export default GuideModal;
