import { mediaType } from "../../enums";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { splitFileFormat, truncateString } from "utils/helper";
import MessageContent from "../MessageContent";
import "./MediaContent.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const { FILE, IMAGE, VIDEO } = mediaType;
const MediaContent = ({ attachment, openPreviewModal }) => {
    const {
        fileAttachmentUrl,
        fileAttachmentType,
        fileAttachmentName,
        fileAttachmentCaption,
    } = attachment || {};

    const renderBasedOnMediaType = () => {
        switch (fileAttachmentType) {
            case IMAGE:
                return (
                    <ImageView
                        src={fileAttachmentUrl}
                        alt='media'
                        className='content--media img'
                        onClick={() => openPreviewModal(attachment)}
                    />
                );
            case FILE:
                return (
                    <div onClick={() => openPreviewModal(attachment)}>
                        <div className='content--document'>
                            <ReactSVG src={imageLinks?.icons?.document} />
                            <div className='details'>
                                <p>{truncateString(fileAttachmentName)}</p>
                                <span>
                                    {splitFileFormat(fileAttachmentName)}
                                </span>
                            </div>
                        </div>
                        <iframe
                            src={`https://docs.google.com/gview?url=${fileAttachmentUrl}&embedded=true`}
                            width='100%'
                            height='100%'
                            frameborder='1'
                            title='file name'
                            sandbox='allow-modals'></iframe>
                    </div>
                );
            case VIDEO:
                return (
                    <video
                        className='content--media video'
                        controls
                        onClick={() => openPreviewModal(attachment)}>
                        <source src={fileAttachmentUrl} />
                    </video>
                );

            default:
                return "";
        }
    };

    return (
        <>
            {renderBasedOnMediaType()}
            {fileAttachmentCaption &&
                fileAttachmentCaption !== "" &&
                fileAttachmentCaption !== "null" && (
                    <MessageContent
                        isReceivedMessage={false}
                        messageContent={fileAttachmentCaption}
                    />
                )}
        </>
    );
};

export default MediaContent;
