import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { MESSAGE_TAG_ACTIONS } from "../../../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { DELETE, UPDATE } = baseResourceActions;

const KeyPhraseActionDropdown = ({ keyPhrase, handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, keyPhrase)}
            options={[
                {
                    value: MESSAGE_TAG_ACTIONS.EDIT,
                    label: "Edit",
                    baseResources: [`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`],
                },
                {
                    value: MESSAGE_TAG_ACTIONS.DELETE,
                    label: "Delete",
                    baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
                },
            ]}
        />
    );
};

export default KeyPhraseActionDropdown;
