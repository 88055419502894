import React from "react";
import { Link } from "react-router-dom";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./BranchCollectionCapsule.scss";

const { KB_FORM_SETTING } = baseResources;
const { READ } = baseResourceActions;

const BranchCollectionCapsule = ({
    parentForm,
    branchCollectionId,
    branchCollectionName,
    branchCollectionType,
}) => {
    const branchCollection = {
        form: parentForm,
        branchCollectionId,
        branchCollectionName,
        branchCollectionType,
    };
    return (
        <ComponentAccessControl baseResources={[`${READ}_${KB_FORM_SETTING}`]}>
            <Link
                to={{
                    pathname: `/t-sam/branch-collection/${branchCollectionId}/view`,
                    state: { branchCollection },
                }}>
                <div
                    className='branch__collection__capsule'
                    id={branchCollectionId}>
                    {branchCollectionName}
                </div>
            </Link>
        </ComponentAccessControl>
    );
};

export default BranchCollectionCapsule;
