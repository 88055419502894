import { useState, useEffect } from "react";
import FormSetup from "../FormSetup/FormSetup";
import { useParams } from "react-router-dom";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const ViewForm = () => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [formData, setFormData] = useState({});
    const { formId } = useParams();

    const getForm = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getForm(formId);
            const res = await API.get(url);
            const data = res?.data;

            if (res.status === 200) {
                setFormData(data?.data);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getForm();
        // eslint-disable-next-line
    }, [formId]);

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return <FormSetup isViewForm={true} formData={formData} />;
            case ERROR:
                return <ErrorView message={errorMssg} handleRetry={getForm} />;
            default:
                return "";
        }
    };

    return <>{renderBasedOnStage()}</>;
};

export default ViewForm;
