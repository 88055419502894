import React, { useState, useEffect } from "react";
import { Button, Input } from "components/ui";
import ChangePasswordModal from "../modals/ChangePasswordModal/ChangePasswordModal";
import ValidateForm from "utils/validations/Validator";
import PersonalProfileInstruction from "../PersonalProfileInstruction/PersonalProfileInstruction";

const PersonalProfileForm = ({ user, loading, updateProfile }) => {
    const [showModal, setShowModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [request, setRequest] = useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
    });

    const toggleModal = () => setShowModal((prev) => !prev);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setFormErrors((prevError) => ({ ...prevError, [name]: "" }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);
        formisValid ? updateProfile(request) : setFormErrors(errors);
    };

    useEffect(() => {
        const { firstName, lastName } = user;
        setRequest({
            firstName,
            lastName,
        });
    }, [user]);

    const { passwordUpdatedRequired } = user || {};

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Input
                    grpClass='mb-3'
                    name='firstName'
                    label='First Name'
                    labelClass='text-dark fw-medium'
                    placeholder='Enter first name'
                    value={request.firstName}
                    onChange={handleChange}
                    isErr={formErrors?.firstName}
                    errMssg={formErrors?.firstName}
                />

                <Input
                    grpClass='mb-3'
                    name='lastName'
                    label='Last Name'
                    labelClass='text-dark fw-medium'
                    placeholder='Enter last name'
                    value={request.lastName}
                    onChange={handleChange}
                    isErr={formErrors?.lastName}
                    errMssg={formErrors?.lastName}
                />

                <Input
                    grpClass='mb-3'
                    type='password'
                    labelClass='text-dark fw-medium'
                    label='Password'
                    value='Test123$'
                    disabled
                />

                {passwordUpdatedRequired ? (
                    <div onClick={toggleModal}>
                        <PersonalProfileInstruction />
                    </div>
                ) : (
                    <p
                        className='text-primary cursor-pointer mb-4'
                        onClick={toggleModal}>
                        Change Password
                    </p>
                )}

                <Button
                    type='submit'
                    text='Update Profile'
                    classType='primary'
                    loading={loading}
                    otherClass='w-100'
                />
            </form>

            {showModal && <ChangePasswordModal closeModal={toggleModal} />}
        </>
    );
};

export default PersonalProfileForm;
