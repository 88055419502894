import React, { useState, useContext } from "react";
import { Button, Input, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ConvoBreakerCheckbox from "./ConvoBreakerCheckbox/ConvoBreakerCheckbox";
import "./EditConvoBreakerForm.scss";

const EditConvBreakerForm = ({ referenceData, handleSuccess }) => {
    const {
        branchId,
        branchMainSentence,
        branchName,
        branchType,
        displayAverageResponseTime: showAverageResponseTime,
        requestRatings: ratings,
    } = referenceData || {};
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        mainSentence: branchMainSentence,
        actionBranchHeader: branchName,
        displayAverageResponseTime: showAverageResponseTime,
        requestRatings: ratings,
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState("");
    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest({ ...request, [name]: value });

        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const handleCheckboxChange = ({ target: { name, checked } }) => {
        setRequest({ ...request, [name]: checked });

        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const editConvoBreaker = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.conversationBreaker(branchId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res?.data;

                toastMessage(message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);
        if (formisValid) {
            editConvoBreaker();
        } else {
            setErrors(errors);
        }
    };

    const {
        mainSentence,
        actionBranchHeader,
        displayAverageResponseTime,
        requestRatings,
    } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='actionBranchHeader'
                label='Conversation breaker header'
                labelClass='text-dark fw-medium'
                id='actionBranchHeader'
                placeholder='Enter breaker header'
                onChange={handleChange}
                value={actionBranchHeader}
                isErr={errors?.actionBranchHeader}
                errMssg={errors?.actionBranchHeader}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                name='mainSentence'
                label='Main sentence'
                labelClass='text-dark fw-bold'
                id='mainSentence'
                placeholder='Enter main sentence'
                value={mainSentence}
                onChange={handleChange}
                isErr={errors?.mainSentence}
                errMssg={errors?.mainSentence}
            />
            <ConvoBreakerCheckbox
                branchType={branchType}
                displayAverageResponseTime={displayAverageResponseTime}
                requestRatings={requestRatings}
                disableCheckbox={false}
                onChange={handleCheckboxChange}
            />
            <Button
                type='submit'
                text='Save changes'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default EditConvBreakerForm;
