import React, { useEffect, useState } from "react";
import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import { dataQueryStatus } from "utils";
import RelatedClassesList from "./RelatedClassesList/RelatedClassesList";
import ProductClassesHeader from "../ProductClasses/ProductClassesHeader/ProductClassesHeader";
import ClassesContainer from "../ProductClasses/ClassesContainer/ClassesContainer";

const { LOADING, DATAMODE, NULLMODE } = dataQueryStatus;

const RelatedClasses = ({
    status,
    selectedProduct,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    handleSecondaryCapsuleSelect,
    capsuleRenderCount,
    useClassesFilter,
    filterWithSecCapsuleCodes,
}) => {
    const [relatedClasses, setRelatedClasses] = useState([]);

    useEffect(() => {
        if (selectedCapsuleClass?.parents || selectedCapsuleClass?.children) {
            useClassesFilter
                ? setRelatedClasses(
                      [
                          ...selectedCapsuleClass?.parents,
                          ...selectedCapsuleClass?.children,
                      ]?.filter((capsuleClass) =>
                          filterWithSecCapsuleCodes?.includes(
                              capsuleClass?.capsuleCode
                          )
                      )
                  )
                : setRelatedClasses([
                      ...selectedCapsuleClass?.parents,
                      ...selectedCapsuleClass?.children,
                  ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCapsuleClass]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <CapsuleSkeletonLoader count={4} />;

            case DATAMODE:
                return (
                    <RelatedClassesList
                        selectedProduct={selectedProduct}
                        selectedSecCapsuleClass={selectedSecCapsuleClass}
                        handleSecondaryCapsuleSelect={
                            handleSecondaryCapsuleSelect
                        }
                        secondaryCapsuleClass={selectedCapsuleClass}
                        relatedClasses={relatedClasses}
                        capsuleRenderCount={capsuleRenderCount}
                    />
                );

            case NULLMODE:
                return (
                    <div>
                        <i>
                            Classes related to{" "}
                            {selectedCapsuleClass?.capsuleClassName} on will
                            appear here
                        </i>
                    </div>
                );

            default:
                return "";
        }
    };
    return (
        <>
            <ProductClassesHeader
                status={status}
                title={"Related classes"}
                numberOfClasses={
                    status !== DATAMODE ? "-" : relatedClasses?.length
                }
            />
            <ClassesContainer>{renderBasedOnStatus()}</ClassesContainer>
        </>
    );
};

export default RelatedClasses;
