import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConversationHeader from "./ConversationHeader/ConversationHeader";
import ConversationsBranches from "./ConversationsBranches/ConversationsBranches";
import ConvoMapView from "./ConvoMapView/ConvoMapView";
import BCMapViewHeader from "../../BranchCollectionView/BranchCollectionMapView/BCMapViewHeader/BCMapViewHeader";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const ConversationView = () => {
    const { conversationId } = useParams();
    const [refreshConvoMap, setRefreshConvoMap] = useState(0);
    const [mainBranchId, setMainBranchId] = useState();
    const [convoMapBranches, updateConvoMapBranches] = useState([]);
    const [showConvoError, setShowConvoError] = useState(false);
    const [mainConversation, setMainConversation] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [status, setStatus] = useState();
    const [conversationBranches, setConversationBranches] = useState([]);

    const handleRefreshConvoMap = (branchId) => {
        if (!branchId) {
            setMainBranchId();
        }
        setRefreshConvoMap(refreshConvoMap + 1);
    };

    const [lastToggledBranchId, selectLastToggledBranchId] = useState("");

    const checkIfConvoHasInvalidBranches = () => {
        // filter out branches with branch options linked to null and with no action
        const invalidBranches = conversationBranches?.filter(
            ({ branchOptions }) =>
                branchOptions?.length === 0 ||
                branchOptions?.filter(
                    ({ branchOptionActionType, branchOptionValue, branchOptionCapsuleEntryId }) =>
                        ((branchOptionValue === null && branchOptionActionType === null) || (branchOptionActionType === "FORWARD_AGENT" && branchOptionCapsuleEntryId === null))
                )?.length > 0
        );

        if (invalidBranches?.length > 0) {
            setShowConvoError(true);
        } else {
            setShowConvoError(false);
        }
    };

    const getConversation = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoute.getIssuesConversation(conversationId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;
                setMainConversation(data);
                setMainBranchId(data?.mainBranch?.branchId);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <>
                        <ConversationHeader
                            hasError={showConvoError}
                            conversation={mainConversation}
                        />
                        <div className='meta__container pt-3'>
                            <BCMapViewHeader
                                title={mainConversation?.issue?.issueName}
                            />
                            <div className='conversations-container'>
                                <div className='container-xxl p-0'>
                                    <ConvoMapView
                                        mainBranchId={mainBranchId}
                                        refreshConvoMap={refreshConvoMap}
                                        updateConvoMapBranches={
                                            updateConvoMapBranches
                                        }
                                        selectLastToggledBranchId={
                                            selectLastToggledBranchId
                                        }
                                    />
                                    <ConversationsBranches
                                        conversationId={conversationId}
                                        mainBranchId={mainBranchId}
                                        handleRefreshConvoMap={
                                            handleRefreshConvoMap
                                        }
                                        lastToggledBranchId={
                                            lastToggledBranchId
                                        }
                                        conversationBranches={
                                            conversationBranches
                                        }
                                        setConversationBranches={
                                            setConversationBranches
                                        }
                                        convoMapBranches={convoMapBranches}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConversation}
                    />
                );
            default:
                return <Loading />;
        }
    };

    useEffect(() => {
        getConversation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        checkIfConvoHasInvalidBranches();
        // eslint-disable-next-line
    }, [conversationBranches]);

    return <>{renderBasedOnStatus()}</>;
};

export default ConversationView;
