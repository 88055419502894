import { Button } from "components/ui";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "./NoPermission.scss";

const NoPermission = () => {
    const history = useHistory();
    return (
        <>
            <div className='no-permission'>
                <div>
                    <ReactSVG
                        src={
                            "https://res.cloudinary.com/dtdla1h52/image/upload/v1670395677/WebApp/Icon_kyo4bz.svg"
                        }
                        fallback='No Permission'
                        className=''
                    />
                    <div>
                        <h1>No Access</h1>
                        <p>
                            Sorry, you don’t have access to this page. Try
                            contacting your admin.
                        </p>
                        <Button
                            type='button'
                            text='Go back'
                            classType='primary'
                            onClick={() => history.push("/")}
                            otherClass='no-permission__button'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoPermission;
