import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import NewFollowupForm from "./NewFollowupForm/NewFollowupForm";
import { SideModal } from "components/ui";

const NewUpdateTaskModal = ({
    showModal,
    title,
    closeModal,
    subTitle,
    ...rest
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={title || "New Follow up"}
                        subTitle={subTitle}
                    />
                    <NewFollowupForm closeModal={closeModal} {...rest} />
                </div>
            </div>
        </SideModal>
    );
};

export default NewUpdateTaskModal;
