import "./AnalyticsQAVAgentsConversations.scss";

import { useEffect, useState } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";

import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
import { useDispatch } from "react-redux";
import { setAgentsList } from "store/cache/agents/actions";

import AnalyticsQAVAgentsMessages from "./AnalyticsQAVAgentsMessages/AnalyticsQAVAgentsMessages";
import AnalyticsQAVAgentsMentions from "./AnalyticsQAVAgentsMentions/AnalyticsQAVAgentsMentions";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const AnalyticsQAVAgentsConversations = ({ ticketId }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [ticketData, setTicketData] = useState({});

    const dispatch = useDispatch();

    const fetchAgents = async (httpRequest) => {
        try {
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                dispatch(
                    setAgentsList(
                        data?.map(
                            ({ firstName, lastName, userId, ...rest }) => {
                                return {
                                    value: userId,
                                    label: `${firstName} ${lastName}`,
                                    firstName,
                                    lastName,
                                    userId,
                                    ...rest,
                                };
                            }
                        )
                    )
                );
            }
        } catch (err) {
            // setErrorMssg(getErrorMessage(err));
        }
    };

    const getTicketDetails = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg("");
            const url = apiRoute?.getTicketDetails(ticketId);
            const res = await API.get(url, {
                params: {
                    archivedViewing: true,
                },
            });
            if (res.status === 200) {
                const ticketInfo = res.data.data;
                setTicketData(ticketInfo);
                setErrorMssg("");
                setStatus(DATAMODE);
            }
        } catch (err) {
            const errorMsg = getErrorMessage(err);
            setErrorMssg(errorMsg);
            setStatus(ERROR);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        handleRetry={getTicketDetails}
                        errorMssg={errorMssg}
                    />
                );
            case DATAMODE:
                return (
                    <>
                        <AnalyticsQAVAgentsMessages
                            ticketData={ticketData}
                            ticketId={ticketId}
                        />
                        <AnalyticsQAVAgentsMentions ticketData={ticketData} />
                    </>
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        fetchAgents();
        getTicketDetails();
        // eslint-disable-next-line
    }, []);

    return (
        <div className='padded__layout analytics-qa-vet-ticket analytics-qava-convos'>
            {renderBasedOnStatus()}
        </div>
    );
};

export default AnalyticsQAVAgentsConversations;
