import { useState } from "react";
import "./TicketAATasksList.scss";
import TicketAACardHeading from "../TicketAACardHeading/TicketAACardHeading";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { getTimeAgo, timeSince, truncateString } from "utils/helper";
import { TASK_STATUSES, taskStatusNameMap } from "../TicketAARunCard/enum";

const TicketAATaskItem = ({ task, handleToggleEditTicketTaskModal, isUpdate }) => {
    const taskInstruction = task?.isModified ? task?.modMainInstruction : isUpdate ? task?.agentUpdate?.mainInstruction : task?.agentUpdateTask?.mainInstruction;
    return (
        <div className={`ticketaa-response-list__item ${task.taskStatus === TASK_STATUSES.COMPLETED ? 'active' : ''}`}>
            <span
                dangerouslySetInnerHTML={{
                    __html: `${truncateString(
                        taskInstruction,
                        60
                    ) || "Task"}`
                }}>
            </span>
            <div>
                <span>
                    {task?.taskStatus ===
                        TASK_STATUSES.COMPLETED ? timeSince(task.dueDate) : getTimeAgo(task?.dueDate)
                    }
                </span>
                |
                {task?.taskStatus ===
                    TASK_STATUSES.COMPLETED ? (
                    <span className='sent'>
                        Sent
                        <ReactSVG src={asset.icons.mark} />
                    </span>
                ) : (
                    <span
                        className='edit'
                        onClick={() =>
                            handleToggleEditTicketTaskModal(
                                { ...task, isUpdate },
                            )
                        }>
                        Edit
                    </span>
                )}
            </div>
        </div>
    )
}

const TicketAATasksList = ({
    handleToggleEditTicketTaskModal,
    updateTasks,
    overalTaskStaus,
    successMssg,
    children,
    isUpdate
}) => {
    const [show, toggleShow] = useState(false);

    return (
        <>
            <div className='ticketaa-response-list'>
                <section
                    className='ticketaa-response-list__select'
                    onClick={() => toggleShow(!show)}>
                    {successMssg}
                </section>

                {show && (
                    <>
                        {children}

                        {updateTasks.length > 0 && <section className='ticketaa-response-list__dropdown'>
                            {overalTaskStaus && <TicketAACardHeading
                                title={
                                    <div className='d-flex'>
                                        <ReactSVG src={asset.icons.tTimer} className={overalTaskStaus} />{" "}
                                        <span>
                                            {
                                                taskStatusNameMap?.[overalTaskStaus[0]]
                                            }
                                        </span>
                                    </div>
                                }
                                onClick={() => toggleShow(!show)}
                            />
                            }
                            {
                                (updateTasks)?.sort(function (a, b) {
                                    return new Date(b.createdDate) - new Date(a.createdDate);
                                })?.map((task, index) => (
                                    <TicketAATaskItem key={index} {...{ task, handleToggleEditTicketTaskModal, isUpdate }} />
                                ))
                            }
                        </section>}
                    </>

                )}

            </div>
        </>
    );
};

export default TicketAATasksList;
