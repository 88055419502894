const MessageBranchOption = ({
    data: { branchOptionLabel },
    branchOptionId,
    selectedOption,
}) => {
    const isSelected = selectedOption === branchOptionId;
    return (
        <div
            className={`branch__option ${
                selectedOption ? (isSelected ? "active" : "__fade_out") : ""
            }`}
            >
            {branchOptionLabel}
        </div>
    );
};

export default MessageBranchOption;
