import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { classificationActions } from "../../enum";
import "./Classification.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;
const { EDIT_CLASSIFICATION, DELETE_CLASSIFICATION } = classificationActions;

const Classification = ({
    item,
    selectedClassification,
    setSelectedClassification,
    handleActionModal,
}) => {
    const [dropdownOpen, toggle] = useState(false);

    const options = [
        {
            value: EDIT_CLASSIFICATION,
            label: "Edit",
            permission: UPDATE,
        },
        {
            value: DELETE_CLASSIFICATION,
            label: "Delete",
            permission: DELETE,
        },
    ];

    const handleChange = (option) => {
        handleActionModal(option);
        toggle(false);
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            direction='up'
            toggle={() => toggle(false)}>
            <div
                onMouseOver={() =>
                    item?.classificationId ===
                        selectedClassification?.classificationId &&
                    !dropdownOpen &&
                    toggle(true)
                }>
                <div
                    className={`classification ${
                        item?.classificationId ===
                        selectedClassification?.classificationId
                            ? "active__classification"
                            : ""
                    }`}
                    onClick={() => setSelectedClassification(item)}>
                    {item?.name}
                </div>
            </div>
            <DropdownMenu>
                <div className='dropdown__menu__content'>
                    {options?.map((option, i) => (
                        <ComponentAccessControl
                            key={i}
                            baseResources={[
                                `${option.permission}_${AGENTS_INBOX_BASE_SETTING}`,
                            ]}>
                            <DropdownItem
                                onClick={() => handleChange(option.value)}>
                                {option.label}
                            </DropdownItem>
                        </ComponentAccessControl>
                    ))}
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default Classification;
