import React from 'react';

const TabsLayoutHeading = ({ title, subTitle, extraClass }) => {
    return (
        <div className={`layout__heading ${extraClass ? extraClass : ''}`}>
            <h1>{title}</h1>
            <p>{subTitle}</p>
        </div>
    )
}

export default TabsLayoutHeading;