import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import SelectUI from "components/ui/SelectUI/SelectUI";

import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setBranchesList } from "store/cache/branches/actions";

const BranchesSelect = ({
    name = "branchOptionValue",
    selectedValue,
    filterIds,
    handleSelect,
    error,
    darkLabel,
}) => {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [allBranches, setAllBranches] = useState([]);

    const dispatch = useDispatch();

    const { branches } = useSelector((state) => state.branches);

    const filterBranches = () => {
        const allBranches = branches?.map((branch) => ({
            label: branch.branchName,
            value: branch.branchId,
        }));

        const filteredBranchList = allBranches.filter((branch) => {
            return !filterIds?.includes(branch?.value);
        });

        setAllBranches(filteredBranchList);
    };

    const getAllBranches = async () => {
        try {
            setServerError(false);
            setLoading(true);

            const res = await API.get(apiRoute?.getBranches);

            if (res.status === 200) {
                const { data } = res?.data;
                setLoading(false);

                dispatch(setBranchesList(data));
            }
        } catch (err) {
            setLoading(false);
            setServerError(getErrorMessage(err));
            return err;
        }
    };

    useEffect(() => {
        getAllBranches();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (branches?.length > 0) {
            filterBranches();
        }
        // eslint-disable-next-line
    }, [branches]);

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                placeholder='Select Branch'
                isLoading={loading}
                label='Branches'
                options={allBranches}
                error={error ? "Branch is  required" : ""}
                value={allBranches?.find((branch) => {
                    return branch?.value === selectedValue;
                })}
                darkLabel={darkLabel}
                onChange={handleSelect}
                serverError={serverError}
                handleRetry={() => getAllBranches()}
            />
        </div>
    );
};

export default BranchesSelect;
