import React from "react";
import ExternalEmail from "./ExternalEmail/ExternalEmail";
import "./ExternalEmailList.scss";

const ExternalEmailList = ({
    emails,
    followUpLatestActivity,
    handleToggleEmailPreview,
    selectedEmailData,
}) => {
    const handleOpenEmailPreviewModal = (email, action) => {
        handleToggleEmailPreview(email, action);
    };

    const { activityId, type: activityType } = followUpLatestActivity || {};

    return (
        <>
            <div className='external__email__list'>
                {emails?.map((email) => (
                    <ExternalEmail
                        email={email}
                        isNew={
                            email?.externalEmailId === activityId &&
                            activityType === "EMAIL"
                        }
                        handleSelectEmail={(email, action) =>
                            handleOpenEmailPreviewModal(email, action)
                        }
                        isActive={
                            selectedEmailData?.externalEmailId ===
                            email?.externalEmailId
                        }
                    />
                ))}
            </div>
        </>
    );
};

export default ExternalEmailList;
