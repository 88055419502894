import { useEffect, useState } from "react";
import CannedResponseHeader from "../common/CannedResponseHeader/CannedResponseHeader";
import CannedResponsesTab from "../common/CannedResponsesTab/CannedResponsesTab";
import CannedERTable from "./CannedERTable/CannedERTable";
import { CANNED_RESOURCE_ACTIONS, CANNED_RESOURCE_TYPES } from "../enum";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Empty, Loading } from "components/ui";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CannedResourceModal from "../common/modal/CannedResourceModal/CannedResourceModal";
import { useDispatch, useSelector } from "react-redux";
import { setCannedEmailsFilterParams } from "store/filters/actions";
import {
    clearCannedEmails,
    saveCannedEmails,
} from "store/modules/agentAssistance/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const { CREATE_CANNED_RESOURCE, EDIT_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;
const CannedEmailResponses = () => {
    const [errorMssg, setErrorMssg] = useState();
    const [showModal, toggleModal] = useState(false);
    const [cannedEmails, setCannedEmails] = useState([]);

    const [selectedCannedResource, selectCannedResource] = useState();

    const [modalAction, setModalAction] = useState();

    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const {
        cannedEmailsCache: { pages, totalRecords },
    } = useSelector((state) => state.agentAssistance);

    const { filterParams } = useSelector(
        (state) => state?.filters?.cannedEmailsFilters
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const cannedResourceType = CANNED_RESOURCE_TYPES?.CANNED_EMAIL;

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);

        dispatch(setCannedEmailsFilterParams({ currentPage: 1, search }));
        dispatch(clearCannedEmails());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setCannedEmailsFilterParams({ ...filterParams, currentPage }));
    };

    const getCannedEmails = async () => {
        try {
            setErrorMssg();
            useTableLoader && setTableIsLoading(true);
            if (cannedEmails?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }
            const url = apiRoute.getCannedResources;
            const res = await API.get(url, {
                params: {
                    cannedResourceType,
                    ...filterParams,
                },
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setCannedEmails(data);

                dispatch(
                    saveCannedEmails(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleSuccess = () => {
        dispatch(setCannedEmailsFilterParams({ currentPage: 1, search: "" }));
        dispatch(clearCannedEmails());
        setClearSearch(true);
        getCannedEmails();
    };

    const handleActionModal = (actionType, cannedResource) => {
        setModalAction(actionType);
        selectCannedResource(cannedResource);
        toggleModal(true);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCannedEmails}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Canned Resource Available Yet"} />;

            case DATAMODE:
                return (
                    <>
                        <CannedERTable
                            handleSuccess={handleSuccess}
                            handleActionModal={handleActionModal}
                            modalAction={modalAction}
                            cannedEmails={cannedEmails}
                            tableIsLoading={tableIsLoading}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setCannedEmails(cachedPage);
        } else {
            getCannedEmails();
        }
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const isEdit = modalAction === EDIT_CANNED_RESOURCE;
    return (
        <>
            <CannedResponsesTab />
            <div className='padded__layout'>
                <CannedResponseHeader
                    title={"Canned emails"}
                    subTitle={"Add and edit canned emails"}
                    btnText={"Create new canned email"}
                    placeholder='Search emails here'
                    handleClick={() =>
                        handleActionModal(CREATE_CANNED_RESOURCE)
                    }
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={filterParams?.search}
                />
                {renderBasedOnStatus()}
            </div>

            {showModal && (
                <CannedResourceModal
                    title={isEdit ? "Update Canned Email" : "New Canned Email"}
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    cannedResourceType={cannedResourceType}
                    toggleModal={toggleModal}
                    modalAction={modalAction}
                    selectedCannedResource={selectedCannedResource}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

export default CannedEmailResponses;
