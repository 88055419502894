import React, { useState, useEffect, useCallback } from "react";
import { Checkbox } from "components/ui";
import "./EditCapsuleClassFormatForm.scss";

const checkboxList = [
    { label: "Description", value: "DESCRIPTION" },
    { label: "Link", value: "LINK" },
    { label: "Text", value: "TEXT" },
    { label: "Rich Text", value: "RICH_TEXT" },
    { label: "GIF", value: "GIF" },
    { label: "Video", value: "VIDEO" },
    { label: "Picture", value: "PICTURE" },
];

const EditCapsuleClassFormatForm = ({ acceptedFormats, handleEditAction }) => {
    const [acceptedFormatsList, updateFormatCheckList] = useState(new Map());

    const formCheckListData = () => {
        const checkList = new Map();
        checkboxList.forEach((format) => {
            let isChecked = acceptedFormats?.includes(format.value);
            checkList.set(format.value, isChecked);
        });
        updateFormatCheckList(checkList);
    };

    const handleChange = useCallback(
        ({ target: { name, checked } }) => {
            updateFormatCheckList((prevState) => {
                let data = new Map(prevState).set(name, checked);
                handleEditAction("CONTENT_FORMATS", data);
                return data;
            });
        },
        [updateFormatCheckList, handleEditAction]
    );

    useEffect(() => {
        formCheckListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='capsule__class__format'>
            <div className='col-lg-5'>
                <div className='checkbox__list'>
                    {checkboxList?.map(({ label, value }, i) => (
                        <div className='col-4' key={i}>
                            <Checkbox
                                grpClass='mb-3'
                                name={value}
                                label={label}
                                checked={acceptedFormatsList.get(value)}
                                onChange={handleChange}
                                labelClass='text-black fw-medium'
                                inputClass='input-check-grey'
                                id={value}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EditCapsuleClassFormatForm;
