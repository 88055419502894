import PropTypes from "prop-types";
import AddUserButton from "components/ui/Button/AddUserButton/AddUserButton";
import { ROLES_PERMS_ACTIONS } from "../../enums";

const CreateRoleButton = ({ handleActionModal }) => {
    return (
        <AddUserButton
            btnText='Create New Custom Role'
            onClick={() => handleActionModal(ROLES_PERMS_ACTIONS.CREATE_ROLE)}
        />
    );
};

CreateRoleButton.prototypes = {
    handleActionModal: PropTypes.func.isRequired,
};

export default CreateRoleButton;
