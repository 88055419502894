import CapsuleClasses from "components/dashboard/KnowledgeBase/CapsuleSettings/CapsuleClasses/CapsuleClasses";
import Heading from "components/ui/Heading/Heading";

const DowntimeBranchHeader = ({
    capsuleClasses,
    selectedCapsuleClass,
    selectCapsuleClass,
}) => {
    return (
        <>
            <Heading
                headingText={"Downtime Branches"}
                headingSubText='Add & change downtime branches'
            />

            <CapsuleClasses
                data={capsuleClasses}
                selectedClassId={selectedCapsuleClass?.capsuleClassId}
                hideAddClassBtn={true}
                selectCapsuleClass={selectCapsuleClass}
            />
        </>
    );
};

export default DowntimeBranchHeader;
