import { Empty } from "components/ui";
import { Droppable } from "react-beautiful-dnd";
import FormBuilderInput from "./FormBuilderInput/FormBuilderInput";
import asset from "assets/images";
import "./FormBuilderForm.scss";

const FormBuilderForm = ({
    formElements,
    formId,
    errors,
    acceptNewInputRule,
    handleConstraintEdit,
    handleFormInputChange,
    removeInputRule,
    handleSubTextStatusChange,
    handleOptionAdd,
    handleInputDelete,
    handleOptionDelete,
    handleOptionTextChange,
    toggleBranchModal,
    handleFileAttachmentChange,
    handleFileAttachmentDelete,
    handleValidateCustomRule,
    handleRemoveCustomRuleError,
}) => {
    return (
        <div>
            <Droppable droppableId={formId} index={2}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <div>
                            {formElements?.length > 0 ? (
                                <>
                                    {formElements?.map((formElement, index) => (
                                        <FormBuilderInput
                                            key={index}
                                            index={index}
                                            errors={errors}
                                            acceptNewInputRule={
                                                acceptNewInputRule
                                            }
                                            handleConstraintEdit={
                                                handleConstraintEdit
                                            }
                                            removeInputRule={removeInputRule}
                                            handleFormInputChange={
                                                handleFormInputChange
                                            }
                                            handleSubTextStatusChange={
                                                handleSubTextStatusChange
                                            }
                                            handleOptionAdd={handleOptionAdd}
                                            handleOptionTextChange={
                                                handleOptionTextChange
                                            }
                                            handleOptionDelete={
                                                handleOptionDelete
                                            }
                                            handleInputDelete={
                                                handleInputDelete
                                            }
                                            toggleBranchModal={
                                                toggleBranchModal
                                            }
                                            handleFileAttachmentChange={
                                                handleFileAttachmentChange
                                            }
                                            handleFileAttachmentDelete={
                                                handleFileAttachmentDelete
                                            }
                                            handleValidateCustomRule={
                                                handleValidateCustomRule
                                            }
                                            handleRemoveCustomRuleError={
                                                handleRemoveCustomRuleError
                                            }
                                            {...formElement}
                                        />
                                    ))}
                                </>
                            ) : (
                                <div className='drag__section'>
                                    <Empty
                                        className='col-12 no-form-content'
                                        text='Drag a component to start'
                                        icon={asset?.icons.dragIcon}
                                    />
                                </div>
                            )}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default FormBuilderForm;
