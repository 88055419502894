import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./FollowupHeader.scss";
import { useEffect } from "react";
import { useState } from "react";
import { getTimestampDate } from "utils/helper";
import { getSingleCapsuleEntry } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { taskStatusNames } from "../enum";

const FollowupHeader = ({ followupInfo }) => {
    const { name, classCapsuleEntryId, createdDate, capsule } =
        followupInfo || {};

    const [loading, setLoading] = useState(false);

    const [capsuleElement, setCapsuleElement] = useState({});

    const getCapsuleEntryInfo = async () => {
        try {
            setLoading(true);
            const elementObj = await getSingleCapsuleEntry(
                capsule,
                classCapsuleEntryId
            );
            setCapsuleElement(elementObj);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCapsuleEntryInfo();
        //eslint-disable-next-line
    }, [classCapsuleEntryId]);

    return (
        <>
            <div className='follow__up__header'>
                <div className='follow__up__header--sub__container'>
                    <p className='title'>Solution Type</p>
                    <span className='value'>
                        {loading ? "-" : capsuleElement?.content}
                    </span>
                </div>
                <div className='follow__up__header--sub__container'>
                    <p className='title'>{name}</p>
                    <span className='value'>
                        {getTimestampDate(createdDate)}
                    </span>
                </div>
            </div>
            <div className='pending__tasks--header'>
                <h4>{taskStatusNames.PENDING}</h4>
                <ReactSVG src={imageLinks?.icons?.editNote} />
            </div>
        </>
    );
};

export default FollowupHeader;
