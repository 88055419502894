import React from "react";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import "./ProductClassesList.scss";

const ProductClassesList = ({
    capsuleClasses,
    handleMainCapsuleSelect,
    selectedCapsuleClass,
    productId,
    capsuleData,
    capsuleRenderCount,
}) => {
    return (
        <div className='product__classes__list'>
            {capsuleClasses?.map((item, i) => (
                <Capsule
                    key={(i + 1) * capsuleRenderCount}
                    capsule={item}
                    showCount={true}
                    productId={productId}
                    secondaryCapsuleClass={capsuleData?.capsule}
                    onClick={() => handleMainCapsuleSelect(item)}
                    isActive={
                        selectedCapsuleClass?.capsuleClassId ===
                        item?.capsuleClassId
                    }
                    baseEntryId={capsuleData?.contentId}
                />
            ))}
        </div>
    );
};

export default ProductClassesList;
