import EditBlockButton from "./EditBlockButton/EditBlockButton";
import CapsuleContentBlockDropdown from "./CCBlockDropdown/CCBlockDropdown";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleContentBlockHeader.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;

const CapsuleContentBlockHeader = ({
    titleText,
    contentBlock,
    reLoadView,
    hideHeader,
    openDeleteModal,
}) => {
    return (
        <>
            {!hideHeader && (
                <div className='content__block__header__title'>
                    <span className='content__block__header__title--text'>
                        {titleText}
                    </span>
                    <div className='content__block__header__title--actions'>
                        <ComponentAccessControl
                            baseResources={[`${UPDATE}_${KB_PRODUCT_SETTING}`]}>
                            <EditBlockButton
                                contentBlock={contentBlock}
                                reLoadView={reLoadView}
                            />
                        </ComponentAccessControl>
                        <CapsuleContentBlockDropdown
                            openDeleteModal={openDeleteModal}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CapsuleContentBlockHeader;
