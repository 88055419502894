import EditCapsuleClassFormatHeader from "./EditCapsuleClassFormatHeader/EditCapsuleClassFormatHeader";
import EditCapsuleClassFormatForm from "./EditCapsuleClassFormatForm/EditCapsuleClassFormatForm";

const CapsuleClassFormats = ({ acceptedFormats, toggleEdited, handleEditAction }) => {
	return (
		<>
			<EditCapsuleClassFormatHeader />
			<EditCapsuleClassFormatForm
				acceptedFormats={acceptedFormats} 
				toggleEdited={toggleEdited}
				handleEditAction={handleEditAction} />
		</>
	);
};

export default CapsuleClassFormats;
