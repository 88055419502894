import { ReactSVG } from "react-svg";
import asset from "assets/images";


const DeleteBranchModalHeader = ({ description }) => {
    return (
        <>
            <div className='delete-branch-modal__content'>
                <ReactSVG
                    src={asset?.icons?.circledQuest}
                    className='delete-branch-modal__content--icon'
                />
                <h2>Are you sure?</h2>
                <p>{description}</p>
            </div>
        </>
    );
};

export default DeleteBranchModalHeader;
