import {
    findArrayObjectBasedOnAKey,
    findSubArrayBasedOnObjectKey,
} from "utils/helper";
import TicketAAHeading from "../TicketAAHeading/TicketAAHeading";
import TicketAAEmails from "../TicketAAEmails/TicketAAEmails";
import TicketAAMentions from "../TicketAAMentions/TicketAAMentions";
// import TicketAAAutomation from "../TicketAAAutomation/TicketAAAutomation";
import TicketAAScenarios from "../TicketAAScenarios/TicketAAScenarios";
import TicketAAListing from "../TicketAAListing/TicketAAListing";
import TicketAANextSteps from "../TicketAANextSteps/TicketAANextSteps";
import { TICKET_AGENT_ASSISTANT_STAGES } from "../enum";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
import { GUIDE_FLOW_TYPES } from "components/dashboard/TrainSAM/GuideDowntime/enum";
import TicketAAMainInstruction from "../TicketAAMainInstruction/TicketAAMainInstruction";
import TicketAAResponse from "../TicketAAResponse/TicketAAResponse";
import TicketAAGuideCapsule from "../TicketAAGuideCapsule/TicketAAGuideCapsule";

const { GUIDE_LIST, FETCHED_GUIDE } = TICKET_AGENT_ASSISTANT_STAGES;

const { MENTIONS, CANNED_EMAIL, CANNED_RESPONSE } =
    CANNED_RESOURCE_TYPES;

const { DOWNTIME_GUIDE_FLOW, UPTIME_GUIDE_FLOW, DEFAULT} = GUIDE_FLOW_TYPES;

const TicketAgentAssistantModule = ({
    guideDetails,
    currentFlow,
    currentStep,
    selectGuideOptionId,
    handleToggleEditTicketTaskModal,
    isNotDefaultGuide,
    selectAgentGuide,
    selectedTicket,
    isLoading,
    setIsLoading,
    guideList,
    handleSearchGuides,
    options,
    getTicketGuide,
    handleToggleEmailPreview,
    handleToggleScenarioModal,
    stage,
    selectedCustomerMessage,
    ticketConversation,
    handleCannedActionSuccess,
}) => {
    const renderBasedOnStage = () => {
        const cannedEmail = findArrayObjectBasedOnAKey(
            currentFlow?.cannedResources,
            "cannedResourceType",
            CANNED_EMAIL
        );

        const cannedMention = findArrayObjectBasedOnAKey(
            currentFlow?.cannedResources,
            "cannedResourceType",
            MENTIONS
        );

        const cannedResponse = findArrayObjectBasedOnAKey(
            currentFlow?.cannedResources,
            "cannedResourceType",
            CANNED_RESPONSE
        );
        const isUptime = currentFlow?.guideFlowType ===
        UPTIME_GUIDE_FLOW;
        const downtimeOrUptime = currentFlow?.guideFlowType ===
            DOWNTIME_GUIDE_FLOW || currentFlow?.guideFlowType === UPTIME_GUIDE_FLOW;

        const mainInstruction = currentFlow?.guideFlowType === DEFAULT ? currentFlow?.mainInstruction : (currentFlow?.guideFlowType === DOWNTIME_GUIDE_FLOW ? "Send out the canned response to begin managing this downtime" : "We ask if they still have a problem. If they do, they should signify. Choose the right option based on their response");

        switch (stage) {
            case FETCHED_GUIDE:
                return (
                    <>
                        <TicketAAHeading
                            title={guideDetails?.agentGuide?.agentGuideName}
                            hasSteps={Boolean(options?.length)}
                            isDowntime={
                                currentFlow?.guideFlowType ===
                                DOWNTIME_GUIDE_FLOW
                            }
                            isUptime={
                                currentFlow?.guideFlowType === UPTIME_GUIDE_FLOW
                            }
                            guideFlowName={currentFlow?.guideFlowName}
                            // handleGoBack={handleGoBack}
                            getTicketGuide={getTicketGuide}
                            steps={options}
                            selectGuideOptionId={selectGuideOptionId}
                            activeStep={currentStep?.currentStep}
                        />
                        <TicketAAGuideCapsule
                            capsuleClassId={
                                currentFlow?.guideFlowCapsuleClassId
                            }
                            capsuleEntryId={
                                currentFlow?.guideFlowCapsuleEntryId
                            }
                        />
                        {Boolean(currentFlow?.mainInstruction) && (
                            <TicketAAMainInstruction
                                instruction={mainInstruction}
                            />
                        )}
                        {Boolean(cannedResponse) && (
                            <TicketAAResponse
                                key={cannedResponse?.cannedResourceId}
                                selectedTicket={selectedTicket}
                                handleToggleEditTicketTaskModal={
                                    handleToggleEditTicketTaskModal
                                }
                                cannedResponse={cannedResponse}
                                updateTasks={findSubArrayBasedOnObjectKey(
                                    currentStep?.ticketUpdateTasks,
                                    "agentUpdateType",
                                    CANNED_RESPONSE
                                )}
                                ticketConversation={ticketConversation}
                                handleCannedActionSuccess={
                                    handleCannedActionSuccess
                                }
                            />
                        )}

                        {
                            downtimeOrUptime && <TicketAAResponse
                                selectedTicket={selectedTicket}
                                handleToggleEditTicketTaskModal={
                                    handleToggleEditTicketTaskModal
                                }
                                updateTasks={currentStep?.ticketUpdateAgentUpdates}
                                cannedResponse={{
                                    cannedResourceId: currentFlow?.guideFlowId,
                                    cannedResourceType: CANNED_RESOURCE_TYPES.CANNED_UPDATE,
                                    cannedResourceBody: currentFlow?.mainInstruction
                                }}
                                ticketConversation={ticketConversation}
                                handleCannedActionSuccess={
                                    handleCannedActionSuccess
                                }
                                autoSentCR={isUptime}
                                guideFlow={currentFlow}
                                // hideIndicator
                                sendsUpdate
                            />

                        }

                        {Boolean(cannedMention) && (
                            <TicketAAMentions
                                cannedMention={cannedMention}
                                handleToggleEditTicketTaskModal={
                                    handleToggleEditTicketTaskModal
                                }
                                updateTasks={findSubArrayBasedOnObjectKey(
                                    currentStep?.ticketUpdateTasks,
                                    "agentUpdateType",
                                    MENTIONS
                                )}
                                handleCannedActionSuccess={
                                    handleCannedActionSuccess
                                }
                                ticketConversation={ticketConversation}
                            />
                        )}
                        {Boolean(cannedEmail) && (
                            <TicketAAEmails
                                cannedEmail={cannedEmail}
                                updateTasks={findSubArrayBasedOnObjectKey(
                                    currentStep?.ticketUpdateTasks,
                                    "agentUpdateType",
                                    CANNED_EMAIL
                                )}
                                handleToggleEditTicketTaskModal={
                                    handleToggleEditTicketTaskModal
                                }
                                handleToggleEmailPreview={
                                    handleToggleEmailPreview
                                }
                                ticketConversation={ticketConversation}
                                handleCannedActionSuccess={
                                    handleCannedActionSuccess
                                }
                            />
                        )}
                        {/* {isNotDefaultGuide && (
                            <TicketAAAutomation
                                updateTasks={findSubArrayBasedOnObjectKey(
                                    currentStep?.ticketUpdateTasks,
                                    "agentUpdateType",
                                    AUTOMATION
                                )}
                                handleToggleEditTicketTaskModal={
                                    handleToggleEditTicketTaskModal
                                }
                            />
                        )} */}
                        <TicketAAScenarios
                            handleToggleScenarioModal={
                                handleToggleScenarioModal
                            }
                            scenarios={currentFlow?.scenairos}
                            branchCollections={currentFlow?.branchCollections}
                            toggleProposal={() =>
                                selectAgentGuide({ ...guideDetails })
                            }
                            selectedCustomerMessage={selectedCustomerMessage}
                            ticketConversation={ticketConversation}
                            handleCannedActionSuccess={
                                handleCannedActionSuccess
                            }
                        />
                        {
                            currentFlow?.guideFlowOptions?.length > 0 && <TicketAANextSteps
                                steps={options}
                                nextSteps={currentFlow?.guideFlowOptions}
                                currentStep={currentStep}
                                handleSuccess={getTicketGuide}
                                guideDetails={guideDetails}
                                selectedTicket={selectedTicket}
                                selectGuideOptionId={selectGuideOptionId}
                            />
                        }
                    </>
                );
            case GUIDE_LIST:
                return (
                    <TicketAAListing
                        handleRefresh={getTicketGuide}
                        selectedTicketId={selectedTicket?.ticketId}
                        {...{
                            isLoading,
                            setIsLoading,
                            guideList,
                            handleSearchGuides,
                        }}
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnStage()}</>;
};

export default TicketAgentAssistantModule;
