import React from "react";
import { Switch } from "react-router-dom";
import RoutesWithSubroutes from "routes/RoutesWithSubroutes";
import TabbedWrapperHeader from "./TabbedWrapperHeader/TabbedWrapperHeader";


const TabbedWrapperRouter = ({ tabbedRoutes }) => {
    return (
        <>
            <TabbedWrapperHeader tabbedRoutes={tabbedRoutes} />
            <Switch>
                {RoutesWithSubroutes(tabbedRoutes)}
            </Switch>
        </>
    )
}

export default TabbedWrapperRouter;
