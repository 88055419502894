import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading, Table } from "components/ui";
import { useEffect, useState } from "react";
import { getErrorMessage, SmartTime } from "utils/helper";
import { apiRoute } from "services";
import { ticketResolutionStatuses } from "../../../enum";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import API from "services/lib/api";
import "./AnalyticsTSamConvoViewTable.scss";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AnalyticsTSamConvoViewTable = ({ conversationId }) => {
    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [convoActivity, setConvoActivities] = useState([]);
    const [filters, updatePageFilters] = useState({
        search: "",
        currentPage: 1,
        pageSize: 5,
    });

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        updatePageFilters({ ...filters, currentPage });
    };

    const getConvoActivity = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader ? setTableIsLoading(true) : setStatus(LOADING);

            const url = apiRoute?.getConversationTicketActivity(conversationId);
            const res = await API.get(url, {
                params: { ...filters },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                setConvoActivities(data);
                setTotalRecords(metaData?.totalRecords);
                // setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            // setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getConvoActivity(useTableLoader, httpRequest);
        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filters, useTableLoader]);

    const tableData = convoActivity?.map(
        ({ ticketSourceMessage, createdDate, ticketResolutionStatus }) => ({
            query: ticketSourceMessage === null ? "-" : ticketSourceMessage,
            time: SmartTime(createdDate),
            status: (
                <span className={`analytics-tsam-${ticketResolutionStatus}`}>
                    {ticketResolutionStatuses[ticketResolutionStatus]}
                </span>
            ),
        })
    );

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return <Empty text={"No Recent Conversation Activity"} />;

            case DATAMODE:
                return (
                    <>
                        <Table
                            headers={[
                                "Recent user queries",
                                "Time created",
                                "Status",
                            ]}
                            dataSource={tableData}
                            isLoading={tableIsLoading}
                            hasErrors={Boolean(errorMssg)}
                            tableError={errorMssg}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filters}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConvoActivity}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <>
            <div className='analytics-tsam-convo-view-table'>
                {renderBasedOnStatus()}
            </div>
        </>
    );
};

export default AnalyticsTSamConvoViewTable;
