import AnalyticsQATMHeader from "./AnalyticsQATMHeader/AnalyticsQATMHeader";
import AnalyticsQATMessageList from "./AnalyticsQATMessageList/AnalyticsQATMessageList";

const AnalyticsQATMessages = () => {
    return (
        <section>
            <AnalyticsQATMHeader />
            <AnalyticsQATMessageList />
        </section>
    );
};

export default AnalyticsQATMessages;
