import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ConversationsTemplatesCards from "./ConversationsTemplatesCards/ConversationsTemplatesCards";
import ConversationTemplatesHeader from "./ConversationTemplatesHeader/ConversationTemplatesHeader";
import { saveConversationTemplates } from "store/modules/agentInbox/actions";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const ConversationsTemplates = () => {
    const [errorMsg, setErrorMsg] = useState("");
    const [loadingText, setLoadingText] = useState("");
    const { conversationTemplates } = useSelector(state => state.agentInbox)
    const [status, setStatus] = useState(conversationTemplates?.upTimeTemplate !== null || conversationTemplates?.downTimeTemplate !== null ? DATAMODE: IDLE);
   
    const dispatch = useDispatch();
    
    const retrieveConversationTemplates = async (httpRequest) => {
        try {
            setErrorMsg();
            setStatus(LOADING);
            setLoadingText({
                title: "Fetching templates",
            });

            const url = apiRoute?.getMessageTemplates;
            const res = await API.get(url, { signal: httpRequest?.signal });
            if (res.status === 200) {
                let templateResp = res.data.data;

                dispatch(saveConversationTemplates(templateResp.basicTemplate, templateResp.downTimeTemplate,))
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMsg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        if (conversationTemplates?.upTimeTemplate === null || conversationTemplates?.downTimeTemplate === null) {
            retrieveConversationTemplates(httpRequest);
        }
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading {...loadingText} />;

            case DATAMODE:
                return (
                    <ConversationsTemplatesCards
                        templates={conversationTemplates}
                        handleRefresh={retrieveConversationTemplates}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMsg}
                        handleRetry={retrieveConversationTemplates}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <>
            <div className='padded__layout'>
                <div className='conversation__templates'>
                    <ConversationTemplatesHeader />
                    {renderCompBasedOnStage()}
                </div>
            </div>
        </>
    );
};

export default ConversationsTemplates;
