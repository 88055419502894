import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    forms: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_FORMS_LIST:
            return {
                ...state,
                forms: getUniqueListBy([...state.forms, ...payload], "id"),
            };

        case types.SET_FORMS_LIST:
            return { ...state, forms: payload };

        case types.UPDATE_FORM:
            const forms = state.forms?.map(({ id, ...rest }) => {
                return id === payload?.id ? payload : { id, ...rest };
            });
            return { ...state, forms };

        case types.DELETE_FORM:
            const updatedForms = state.forms?.filter(
                (x) => x.id !== payload?.id
            );
            return { ...state, forms: updatedForms };

        default:
            return state;
    }
};

export default AuthReducer;
