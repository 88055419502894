import MessageTag from "components/dashboard/AgentInbox/AgentInboxSettings/MessageTagging/MessageTagList/MessageTag/MessageTag";
import ItemsList from "components/ui/ItemsList/ItemsList";

const TagsDiscoveredList = ({
    messageTagList,
    handleSelectMssgTag,
    selectedMssgTagId,
}) => {
    return (
        <ItemsList breakPoint={10}>
            {messageTagList?.map((tag, i) => (
                <MessageTag
                    key={i}
                    text={tag?.messagingTagName}
                    active={tag?.messagingTagId === selectedMssgTagId}
                    onClick={() => handleSelectMssgTag(tag?.messagingTagId)}
                />
            ))}
        </ItemsList>
    );
};

export default TagsDiscoveredList;
