import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ConfirmAction, Modal } from "components/ui";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

const { ERROR } = dataQueryStatus;

const DeleteGuideModal = ({
    selectedGuide,
    closeModal,
    handleSuccess,
    showModal,
}) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const deleteCannedUpdate = async () => {
        try {
            setLoading(true);

            const url = apiRoute.deleteGuide(selectedGuide?.agentGuideId);
            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess?.();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <Modal show={showModal} close={closeModal}>
            <ConfirmAction
                title='Are you sure you want to Delete this agent guide'
                loading={loading}
                close={closeModal}
                handleYesClick={deleteCannedUpdate}
            />
        </Modal>
    );
};

export default DeleteGuideModal;
