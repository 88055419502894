import React from "react";
import { Button, CustomEmojiPicker } from "components/ui";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import EmailAttachmentDropdown from "../../../EmailAttachment/EmailAttachmentDropdown/EmailAttachmentDropdown";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { appendEmoji } from "utils/helper";
import "./EmailActions.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const EmailActions = ({
    loading,
    sendExternalEmail,
    request,
    setRequest,
    toggleClipboardAndAttachment,
    isDisabled,
}) => {
    const { message, subject, senderEmail, recipientsEmail } = request || {};

    const handleEmojiSelect = (emoji) => {
        const messageInput = document.getElementById("message-input");
        const cursorPosition = messageInput?.selectionStart;

        setRequest((prev) => ({
            ...prev,
            message: appendEmoji(prev?.message, cursorPosition, emoji),
        }));

        messageInput.focus();
    };

    return (
        <>
            <div className='send__email__actions__container'>
                <div
                    className={`send__email__actions ${
                        isDisabled ? "is-disabled" : ""
                    }`}>
                    <CustomEmojiPicker
                        handleEmojiSelect={handleEmojiSelect}
                        positionRight={true}
                        isDisabled={isDisabled}
                    />
                    <ReactSVG src={imageLinks?.icons?.text} />
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <EmailAttachmentDropdown
                            handleOpenEmailAttachmentModal={(type) =>
                                !isDisabled && toggleClipboardAndAttachment(type)
                            }
                        />
                    </ComponentAccessControl>
                    <ReactSVG src={imageLinks?.icons?.scheduleSend} />
                </div>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <Button
                        type='submit'
                        classType='primary'
                        otherClass='send__email__button'
                        disabled={
                            message === "" ||
                            subject === "" ||
                            recipientsEmail?.length === 0 ||
                            !senderEmail ||
                            isDisabled
                        }
                        onClick={sendExternalEmail}
                        loading={loading}>
                        <ReactSVG
                            src={imageLinks?.icons?.sendIconv2}
                            className='email__send__svg'
                        />
                        Send{" "}
                    </Button>
                </ComponentAccessControl>
            </div>
        </>
    );
};

export default EmailActions;
