import imageLinks from 'assets/images';
import React from 'react';
import { ReactSVG } from 'react-svg';

const EmptyInfo = ({ message, otherClassNames, customIcon }) => {
    return (
        <div className={`empty__chat ${otherClassNames ? otherClassNames : ''}`}>
            <ReactSVG src={customIcon ? customIcon : imageLinks.icons.chat_icon} />
            <p>
                {message}
            </p>
        </div>
    );
};

export default EmptyInfo;