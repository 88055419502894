import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { FOLLOW_UP_TABLE_ACTIONS } from "../../enum";
import AddFollowUp from "./AddFollowUp/AddFollowUp";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_COLLECTION } = baseResources;
const { CREATE, DELETE } = baseResourceActions;
const { DELETE_FOLLOW_UP_COLLECTION } = FOLLOW_UP_TABLE_ACTIONS;

const FollowUpTableActions = ({
    handleActionModal,
    hasAttachedFollowUp,
    capsuleEntry,
    solutionCapsuleClass,
}) => {
    return (
        <>
            {hasAttachedFollowUp ? (
                <Dropdown
                    size='sm'
                    placeholder={
                        <ReactSVG
                            src={asset?.icons?.horizontalEllipsis}
                            className='d-inline-flex'
                        />
                    }
                    ddValueClass='borderless'
                    className='table__dropdown'
                    ddOptionsClass='table__dropdown'
                    anchor={false}
                    changeValue={false}
                    onChange={({ value }) =>
                        handleActionModal(value, capsuleEntry)
                    }
                    options={[
                        {
                            value: DELETE_FOLLOW_UP_COLLECTION,
                            label: "Delete",
                            baseResources: [`${DELETE}_${KB_SAM_COLLECTION}`],
                        },
                    ]}
                />
            ) : (
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${KB_SAM_COLLECTION}`]}>
                    <AddFollowUp
                        capsuleEntry={capsuleEntry}
                        solutionCapsuleClass={solutionCapsuleClass}
                        handleActionModal={handleActionModal}
                    />
                </ComponentAccessControl>
            )}
        </>
    );
};

export default FollowUpTableActions;
