import { useState, useContext } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ConfirmAction } from "components/ui";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Modal } from "components/ui";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { unRelateSubCapsuleEntry } from "components/dashboard/KnowledgeBase/common/Capsule/lib";

const { ERROR } = dataQueryStatus;

const DeleteCapsuleConfirmModal = ({
    capsule,
    showModal,
    closeModal,
    deleteRequestObj,
    handleSuccess,
}) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const handleDeleteCapsule = async () => {
        try {
            setLoading(true);
            const url = apiRoute.deleteCapsule(
                capsule?.capsuleClassId,
                deleteRequestObj?.contentId
            );
            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess(capsule);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const unRelateCapsuleEntries = async () => {
        try {
            setLoading(true);
            const {
                parentCapsuleClass,
                childCapsuleClass,
                contentId,
                refCapsuleId,
            } = deleteRequestObj || {};

            const childCapsuleId =
                capsule?.capsuleCode === childCapsuleClass?.capsuleCode
                    ? contentId
                    : refCapsuleId;
            const parentCapsuleId =
                capsule?.capsuleCode === parentCapsuleClass?.capsuleCode
                    ? contentId
                    : refCapsuleId;

            await unRelateSubCapsuleEntry(
                parentCapsuleClass,
                childCapsuleClass,
                {
                    contentId: childCapsuleId,
                    capsuleId: parentCapsuleId,
                }
            );

            handleSuccess(capsule);
            toastMessage(`${deleteRequestObj?.content} removed`);
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleCapsuleRemoval = () => {
        deleteRequestObj?.parentCapsuleClass
            ? unRelateCapsuleEntries()
            : handleDeleteCapsule();
    };

    return (
        <Modal show={showModal} close={closeModal}>
            <ConfirmAction
                title={`Are you sure you want to ${
                    deleteRequestObj?.parentCapsuleClass ? "remove" : "delete"
                } ${deleteRequestObj?.content}`}
                loading={loading}
                close={closeModal}
                handleYesClick={handleCapsuleRemoval}
            />
        </Modal>
    );
};

export default DeleteCapsuleConfirmModal;
