import React from "react";
import { SUB_DOWNTIME_BRANCH } from "components/dashboard/TrainSAM/SAM/DowntimeBranchesView/DowntimeBranchesList/enums";
import { intervalToLevels } from "utils/helper";
import "./BranchGroupItem.scss";

const BranchGroupItem = ({
    isActive,
    branch,
    handleBranchSelect,
    branchType,
}) => {
    return (
        <div
            className={`branch__group--item ${isActive ? "active" : ""}`}
            onClick={() => handleBranchSelect(branch?.branchId)}>
            <span className='branchName'>{branch?.branchName}</span>
            {branchType === SUB_DOWNTIME_BRANCH && (
                <div className='interval'>
                    {" "}
                    <span>{intervalToLevels(branch?.deliveryInterval)}</span>
                </div>
            )}
        </div>
    );
};

export default BranchGroupItem;
