import { useState, useContext } from "react";
import PropTypes from "prop-types";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Button, Input, InputMultiple } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { MESSAGE_TAG_ACTIONS } from "components/dashboard/AgentInbox/AgentInboxSettings/MessageTagging/enum";

const KeyPhraseForm = ({
    keyPhrase,
    closeModal,
    selectedMssgTagId,
    handleSuccess,
    currentAction,
}) => {
    const [request, setRequest] = useState({
        keyword: keyPhrase?.keyword || "",
        metadata: keyPhrase?.metadata || [],
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMultipleInputChange = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const addKeyPhrase = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute?.addKeyPhrase(selectedMssgTagId);

            const res = await API.post(url, request);
            const data = res.data;

            if (res.status === 201) {
                handleSuccess();
                toastMessage(data.message);
                closeModal();
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editKeyPhrase = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute.editKeyPhrase(keyPhrase?.keyPhraseId);

            const res = await API.put(url, request);
            const data = res.data;

            if (res.status === 200) {
                handleSuccess();
                toastMessage(data.message);
                closeModal();
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            if (currentAction === MESSAGE_TAG_ACTIONS.EDIT) {
                return editKeyPhrase();
            }
            return addKeyPhrase();
        } else {
            setErrors(formErrors);
        }
    };

    const { keyword, metadata } = request;

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='keyword'
                label='Enter key word or phrase'
                labelClass='text-dark fw-medium'
                id='keyword'
                placeholder='Enter text here'
                value={keyword}
                onChange={handleChange}
                isErr={errors?.keyword}
                errMssg={errors?.keyword}
                autoFocus={true}
            />
            <InputMultiple
                grpClass='mb-3'
                type='text'
                name='metadata'
                label='Metadata'
                labelClass='text-dark fw-medium'
                id='metadata'
                placeholder='Add metadata'
                setInputValue={handleMultipleInputChange}
                isErr={errors?.metadata}
                errMssg={errors?.metadata}
                inputValue={metadata}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

KeyPhraseForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default KeyPhraseForm;
