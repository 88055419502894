import { getDaysHrsMinsAndSecs } from "utils/helper";
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const BranchOptionSchedule = ({ scheduleDuration }) => {
    const { days, hours, mins } = getDaysHrsMinsAndSecs(scheduleDuration) || {};
    return (
        <>
            <div className='scheduled'>
                <ReactSVG src={asset.icons.clock} />
                {days > 0 && (
                    <span className='duration'>
                        {days}
                        <span> Days</span>
                    </span>
                )}{" "}
                {hours > 0 && (
                    <span className='duration'>
                        {hours}
                        <span> Hrs</span>
                    </span>
                )}{" "}
                {mins > 0 && (
                    <span className='duration'>
                        {mins}
                        <span> Mins</span>
                    </span>
                )}
            </div>
        </>
    );
};

export default BranchOptionSchedule;
