import React from "react";
import BranchCollectionCapsule from "../../common/BranchCollectionCapsule/BranchCollectionCapsule";
import ItemsList from "components/ui/ItemsList/ItemsList";
import "./PostFormCollectionsGroup.scss";
import FormCannedResponse from "../../common/FormCannedResponse/FormCannedResponse";

const PostFormCollectionsGroup = ({
    branchCollections,
    cannedResponses,
    referenceData,
    handleCannedResponseActions
}) => {
    return (
        <div className='collection__capsules post-form-collections'>
            {[...cannedResponses, ...branchCollections]?.length > 0 ? (
                <ItemsList breakPoint={6}>
                    {[...cannedResponses, ...branchCollections].map(
                        ({ cannedResponseId, ...rest }, index) => (
                            <>
                                {cannedResponseId ? (
                                    <FormCannedResponse
                                        cannedResponse={{
                                            cannedResponseId,
                                            ...rest,
                                        }}
                                        handleCannedResponseActions={handleCannedResponseActions}
                                    />
                                ) : (
                                    <BranchCollectionCapsule
                                        key={index}
                                        parentForm={referenceData}
                                        {...rest}
                                    />
                                )}
                            </>
                        )
                    )}
                </ItemsList>
            ) : (
                <span>
                    No Post Form Collections or Canned Responses Created
                </span>
            )}
        </div>
    );
};

export default PostFormCollectionsGroup;
