import React, { useContext, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Textarea, Input } from "components/ui";
import { getErrorMessage } from "utils/helper";
import { withRouter, useHistory } from "react-router-dom";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { apiRoute } from "services";
import API from "services/lib/api";
import BranchNameInput from "../../../branches/BranchNameInput/BranchNameInput";
import {
    ADD_BRANCH_TO_CONVERSATION,
    branchFormActions,
    CONVERSATIONS_PERMS_ACTIONS,
} from "../../../enums";
import BranchSubSentencesInput from "./BranchSubSentencesInput/BranchSubSentencesInput";
import {
    DOWNTIME_BRANCH,
    SUB_DOWNTIME_BRANCH,
} from "components/dashboard/TrainSAM/SAM/DowntimeBranchesView/DowntimeBranchesList/enums";
import ScheduleInput from "components/dashboard/common/ScheduleInput/ScheduleInput";
import AttachedBranchImage from "./AttachedBranchImage/AttachedBranchImage";
import BranchCollectionNameInput from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/AddBranchCollectionForm/BranchCollectionNameInput/BranchCollectionNameInput";
import { SOLUTIONS_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import { default as CreatableBranchName } from "components/dashboard/TrainSAM/SAM/modals/AddBranchModal/AddBranchForm/BranchNameInput/BranchNameInput";
import { CREATE_NEW_BRANCH } from "components/dashboard/TrainSAM/SAM/modals/AddBranchModal/AddBranchForm/enums";

const { CREATE_CONVERSATION } = CONVERSATIONS_PERMS_ACTIONS;

const CreateConversationForm = ({
    isEdit,
    referenceData,
    modalFormAction,
    source,
    handleSuccess,
}) => {
    const [settingReference, handleSettingReference] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isBranchNameAvailable, setBranchNameAvailability] = useState(false);
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const toastMessage = useContext(ToastContext);
    const [request, updateRequest] = useState({
        deliveryInterval: 60,
        mainBranchName: "",
        mainBranchSentence: "",
        mainBranchImage: "",
        mainBranchImageCaption: "",
        branchMainSentenceImgCaption: "",
        mainBranchSubSentences: [],
        issueId: "",
        capsuleId: "",
        classCapsuleEntryId: "",
        branchMainSentenceImgUrlConfig: {},
        isSolutionCollection: false,
        solutionCollectionId: "",
        mainBranchHeading: "",
    });
    const [loadingCollectionsNames, setLoadingCollectionsNames] =
        useState(true);
    const [actionType, setActionType] = useState();
    const [loadingBranchNames, setLoadingBranchNames] = useState(true);

    const history = useHistory();

    const addSubSentence = () => {
        updateRequest({
            ...request,
            mainBranchSubSentences: [...request.mainBranchSubSentences, ""],
        });
    };

    const removeSubSentence = (index) => {
        const allSubSentences = [...request.mainBranchSubSentences];
        allSubSentences.splice(index, 1);
        updateRequest({ ...request, mainBranchSubSentences: allSubSentences });
    };

    const handleSubInputChange = (name, value) => {
        const allSubSentences = [...request.mainBranchSubSentences];
        allSubSentences[name] = value;
        let allSubSentenceChecks = errors?.subSentenceErrors;
        if (allSubSentenceChecks?.[name] !== undefined) {
            allSubSentenceChecks[name] = false;
            setErrors({
                ...errors,
                subSentenceErrors: allSubSentenceChecks,
            });
        }
        updateRequest({ ...request, mainBranchSubSentences: allSubSentences });
    };

    const handleReferenceData = useCallback(() => {
        if (isEdit) {
            updateRequest({
                ...referenceData,
                mainBranchName: referenceData?.branchName,
                mainBranchSubSentences: [
                    ...(referenceData?.branchSubSentences).map(
                        ({ content }) => content
                    ),
                ],
                mainBranchSentence: referenceData?.branchMainSentence,
                deliveryInterval: referenceData?.deliveryInterval,
                mainBranchHeading: referenceData?.branchHeading
                    ? referenceData?.branchHeading
                    : "",
            });
            setBranchNameAvailability(true);
            handleSettingReference(false);
        } else {
            updateRequest((prevRequest) => ({
                ...prevRequest,
                issueId: referenceData?.issueId,
                capsuleId: referenceData?.capsuleId,
                classCapsuleEntryId: referenceData?.classCapsuleEntryId,
            }));
            handleSettingReference(false);
        }
    }, [referenceData, isEdit]);

    useEffect(() => {
        handleReferenceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                ...requestData
            } = request;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            Object.keys(requestData).forEach((key) =>
                formData.append(key, requestData[key])
            );

            const url = apiRoute?.editBranch(referenceData?.branchId);
            const res = await API.put(url, formData);
            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess(referenceData?.branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createConversations = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                isSolutionCollection,
                ...requestData
            } = request;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            formData.append(
                "isSolutionCollection",
                JSON.stringify(isSolutionCollection)
            );

            Object.keys(requestData).forEach((key) =>
                formData.append(key, requestData[key])
            );

            const url = apiRoute?.createConversation;
            const res = await API.post(
                url,
                isSolutionCollection
                    ? {
                          issueId: requestData?.issueId,
                          isSolutionCollection: isSolutionCollection,
                          solutionCollectionId:
                              requestData?.solutionCollectionId,
                      }
                    : formData
            );

            if (res.status === 201) {
                const { data } = res.data;

                let nextPath = isSolutionCollection
                    ? `/t-sam/branch-collection/${data?.branchCollection?.branchCollectionId}/view`
                    : `/t-sam/conversations/${data?.conversationId}/view-conversation`;

                toastMessage(res.data.message);
                history.replace(nextPath);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const addBranchToConversation = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                ...requestData
            } = request;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            Object.keys(requestData).forEach((key) =>
                formData.append(key, requestData[key])
            );

            formData.append("conversationId", referenceData?.conversationId);
            const url = apiRoute?.createBranch;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const { branchId } = res.data.data;
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createDowntimeBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                ...requestData
            } = request;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            Object.keys(requestData).forEach((key) =>
                formData.append(key, requestData[key])
            );

            const url = apiRoute?.downtimeBranch;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const { capsuleId, classCapsuleEntryId } = referenceData;
                toastMessage(res.data.message);
                history.push({
                    pathname: `/t-sam/downtime-collection/${capsuleId}/view/${classCapsuleEntryId}`,
                    state: {
                        downtimeCapsule: referenceData,
                    },
                });
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createGeneralBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                ...requestData
            } = request;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            Object.keys(requestData).forEach((key) => {
                formData.append(key, requestData[key]);
            });

            const { generalDowntime, generalUptime, generalSubdowntime } =
                apiRoute;
            const url =
                source === DOWNTIME_BRANCH
                    ? generalDowntime
                    : source === SUB_DOWNTIME_BRANCH
                    ? generalSubdowntime
                    : generalUptime;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const { branchId } = res.data.data;
                toastMessage(res.data.message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createPrimaryBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            let formRequest = {
                ...request,
                capsuleId: referenceData?.capsuleClassId,
                classCapsuleEntryId: referenceData?.capsuleEntryId,
            };
            const formData = new FormData();

            const {
                branchMainSentenceImgUrlConfig,
                mainBranchSubSentences,
                ...requestData
            } = formRequest;

            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            formData.append(
                "mainBranchSubSentences",
                JSON.stringify(mainBranchSubSentences)
            );

            Object.keys(requestData).forEach((key) => {
                formData.append(key, requestData[key]);
            });

            const url =
                source === SUB_DOWNTIME_BRANCH
                    ? apiRoute?.createSubDowntimeBranch
                    : apiRoute?.createUptimeBranch;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const {
                    branch: { branchId },
                } = res.data.data;
                toastMessage(res.data.message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const setDeliveryInterval = (timeValue) => {
        updateRequest({ ...request, deliveryInterval: timeValue });
    };

    const handleChange = (name, value) => {
        updateRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        handleChange(name, value);
    };

    const handleBranchNameChange = (actionType, value) => {
        setLoadingBranchNames(false);
        setActionType(actionType);

        if (actionType === CREATE_NEW_BRANCH) {
            updateRequest((prev) => ({
                ...prev,
                mainBranchName: value,
                branchId: "",
            }));
        } else {
            updateRequest((prev) => ({
                ...prev,
                branchId: value,
                mainBranchName: "",
            }));
        }
        setBranchNameAvailability(true);
        setErrors((prevErrors) => ({ ...prevErrors, mainBranchValue: "" }));
    };

    const handleBranchCollectionNameChange = (actionType, valueObj) => {
        handleChange("solutionCollectionId", valueObj?.value);
    };

    const callServer = () => {
        switch (modalFormAction) {
            case ADD_BRANCH_TO_CONVERSATION:
                addBranchToConversation();
                break;
            case branchFormActions.CREATE_DOWNTIME_BRANCH:
                createDowntimeBranch();
                break;
            case branchFormActions?.EDIT_BRANCH:
                editBranch();
                break;
            case branchFormActions?.CREATE_PRIMARY_BRANCH:
                createPrimaryBranch();
                break;
            case branchFormActions?.CREATE_GENERAL_BRANCH:
                createGeneralBranch();
                break;
            default:
                createConversations();
                break;
        }
    };

    const validateBranchSubsentences = () => {
        let mainBranchSubSentences = request.mainBranchSubSentences;
        let isValid = true;
        let subSentenceErrors = mainBranchSubSentences.map((sentence) => {
            if (sentence?.length === 0) {
                isValid = false;
            }
            return sentence?.length === 0 ? true : false;
        });

        return { subSentenceErrors, isValid };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request, [
            "mainBranchImage",
            "mainBranchImageCaption",
            "mainBranchSubSentences",
            "issueId",
            "capsuleId",
            "classCapsuleEntryId",
            "mainBranchHeading",
        ]);

        const { isValid, subSentenceErrors } =
            validateBranchSubsentences(request);
        if (formisValid && isValid) {
            callServer();
        } else {
            setErrors({ ...errors, subSentenceErrors });
        }
    };

    const { deliveryInterval, isSolutionCollection } = request;

    if (settingReference) return "";

    const { branchType } = referenceData;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            {!isSolutionCollection && (
                <>
                    {source === CREATE_CONVERSATION ? (
                        <CreatableBranchName
                            handleBranchNameChange={handleBranchNameChange}
                            isLoading={loadingBranchNames}
                            setLoadingBranchNames={setLoadingBranchNames}
                            filterBranches={[]}
                            errorMssg={errors?.mainBranchName}
                            autoFocus={true}
                            name='mainBranchName'
                            label='Branch Name'
                        />
                    ) : (
                        <BranchNameInput
                            handleInputChange={handleInputChange}
                            isBranchNameAvailable={isBranchNameAvailable}
                            setBranchNameAvailability={
                                setBranchNameAvailability
                            }
                            value={request?.mainBranchName}
                            error={errors?.mainBranchName}
                            autoFocus={true}
                            preventInitialResolve={
                                referenceData?.branchName ===
                                request?.mainBranchName
                            }
                        />
                    )}

                    {(actionType === CREATE_NEW_BRANCH ||
                        source !== CREATE_CONVERSATION) && (
                        <>
                            <Input
                                grpClass='mb-3'
                                name='mainBranchHeading'
                                label='Branch Heading'
                                id='mainBranchHeading'
                                labelClass='text-dark fw-medium'
                                placeholder='Enter Branch heading'
                                value={request.mainBranchHeading}
                                onChange={handleInputChange}
                                isErr={errors?.mainBranchHeading}
                                errMssg={errors?.mainBranchHeading}
                            />{" "}
                            <BranchSubSentencesInput
                                subSentences={request?.mainBranchSubSentences}
                                handleSubInputChange={handleSubInputChange}
                                addSubSentence={addSubSentence}
                                removeSubSentence={removeSubSentence}
                                subSentenceErrors={errors?.subSentenceErrors}
                            />
                            <Textarea
                                grpClass='mb-3'
                                name='mainBranchSentence'
                                label='Main sentence'
                                labelClass='text-dark fw-medium'
                                placeholder='Enter Main sentence'
                                value={request?.mainBranchSentence}
                                onChange={handleInputChange}
                                isErr={errors?.mainBranchSentence}
                                errMssg={errors?.mainBranchSentence}
                            />
                        </>
                    )}

                    {(source === SUB_DOWNTIME_BRANCH ||
                        branchType === SUB_DOWNTIME_BRANCH) && (
                        <ScheduleInput
                            timeValue={deliveryInterval}
                            handleTimeChange={setDeliveryInterval}
                            error={errors?.deliveryInterval}
                            label='Schedule branch'
                            showLabel={true}
                            subLabel='This branch would be sent based on the time condition'
                        />
                    )}
                </>
            )}

            {isEdit && (
                <AttachedBranchImage
                    mainBranchImageUrl={referenceData?.branchMainSentenceImgUrl}
                />
            )}

            {isSolutionCollection && (
                <BranchCollectionNameInput
                    source={SOLUTIONS_COLLECTION}
                    handleBranchCollectionNameChange={
                        handleBranchCollectionNameChange
                    }
                    isLoading={loadingCollectionsNames}
                    setLoadingCollectionsNames={setLoadingCollectionsNames}
                    autoFocus={true}
                    createAble={false}
                />
            )}

            {modalFormAction === CREATE_CONVERSATION && (
                <Checkbox
                    // grpClass='mb-3'
                    name='isSolutionCollection'
                    label='Solution Collection'
                    inputClass='input-check-grey'
                    id='isSolutionCollection'
                    onChange={({ target: { checked } }) =>
                        updateRequest({
                            ...request,
                            isSolutionCollection: checked,
                        })
                    }
                    checked={isSolutionCollection}
                />
            )}

            <Button
                type='submit'
                text={isEdit ? "Save Changes" : "Create"}
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
                disabled={isSolutionCollection ? false : !isBranchNameAvailable}
            />
        </form>
    );
};

export default withRouter(CreateConversationForm);
