import * as types from "./types";

const baseState = {
    baseFilter: {
        label: "Filter by:",
        value: "",
        icon: "",
    },
    filterOption: {},
    filterParams: { search: "", currentPage: 1, pageSize: 5 },
};

const initialState = {
    // deep copy
    usersFilters: JSON.parse(JSON.stringify(baseState)),
    rolesAndPermissionFilters: JSON.parse(JSON.stringify(baseState)),
    conversationFilters: JSON.parse(JSON.stringify(baseState)),
    issueFilters: JSON.parse(JSON.stringify(baseState)),
    qaDocumentationFilters: JSON.parse(JSON.stringify(baseState)),
    discoveryFilters: JSON.parse(JSON.stringify(baseState)),
    switchesFilters: JSON.parse(JSON.stringify(baseState)),
    downtimeBranchesFilters: JSON.parse(JSON.stringify(baseState)),
    formFilters: JSON.parse(JSON.stringify(baseState)),
    postFormFilters: JSON.parse(JSON.stringify(baseState)),
    cannedResponsesFilters: JSON.parse(JSON.stringify(baseState)),
    teamsFilters: JSON.parse(JSON.stringify(baseState)),
    mssgTagFilters: JSON.parse(JSON.stringify(baseState)),
    vetTicketFilters: JSON.parse(JSON.stringify(baseState)),
    vetAgentFilters: JSON.parse(JSON.stringify(baseState)),
    personasFilters: JSON.parse(
        JSON.stringify({
            ...baseState,
            filterParams: {
                search: "",
                currentPage: 1,
                pageSize: 5,
                active: "",
            },
        })
    ),
    guidesFilters: JSON.parse(JSON.stringify(baseState)),
    scenariosFilters: JSON.parse(JSON.stringify(baseState)),
    cannedEmailsFilters: JSON.parse(JSON.stringify(baseState)),
    cannedRepliesFilters: JSON.parse(JSON.stringify(baseState)),
    cannedMentionsFilters: JSON.parse(JSON.stringify(baseState)),
    mentionUpdatesFilters: JSON.parse(JSON.stringify(baseState)),
    emailUpdatesFilters: JSON.parse(JSON.stringify(baseState)),
    replyUpdatesFilters: JSON.parse(JSON.stringify(baseState)),
    guideDowntimeFilters: JSON.parse(JSON.stringify(baseState)),
};

const FiltersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_USERS_FILTER_PARAMS:
            return {
                ...state,
                usersFilters: {
                    ...state.usersFilters,
                    filterParams: {
                        ...state.usersFilters.filterParams,
                        ...payload,
                    },
                },
            };
        case types.SET_ROLES_PERMISSION_FILTER_PARAMS:
            return {
                ...state,
                rolesAndPermissionFilters: {
                    ...state.rolesAndPermissionFilters,
                    filterParams: {
                        ...state.rolesAndPermissionFilters.filterParams,
                        ...payload,
                    },
                },
            };
        case types.SET_CONVERSATIONS_BASE_FILTER:
            return {
                ...state,
                conversationFilters: {
                    ...state.conversationFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_CONVERSATIONS_FILTER_OPTION:
            return {
                ...state,
                conversationFilters: {
                    ...state.conversationFilters,
                    filterOption: payload,
                },
            };
        case types.SET_CONVERSATIONS_FILTER_PARAMS:
            return {
                ...state,
                conversationFilters: {
                    ...state.conversationFilters,
                    filterParams: {
                        ...state.conversationFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_ISSUES_BASE_FILTER:
            return {
                ...state,
                issueFilters: {
                    ...state.issueFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_ISSUES_FILTER_OPTION:
            return {
                ...state,
                issueFilters: {
                    ...state.issueFilters,
                    filterOption: payload,
                },
            };
        case types.SET_ISSUES_FILTER_PARAMS:
            return {
                ...state,
                issueFilters: {
                    ...state.issueFilters,
                    filterParams: {
                        ...state.issueFilters.filterParams,
                        ...payload,
                    },
                },
            };
        case types.SET_QA_DOCUMENTATION_BASE_FILTER:
            return {
                ...state,
                qaDocumentationFilters: {
                    ...state.qaDocumentationFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_QA_DOCUMENTATION_FILTER_OPTION:
            return {
                ...state,
                qaDocumentationFilters: {
                    ...state.qaDocumentationFilters,
                    filterOption: payload,
                },
            };
        case types.SET_QA_DOCUMENTATION_FILTER_PARAMS:
            return {
                ...state,
                qaDocumentationFilters: {
                    ...state.qaDocumentationFilters,
                    filterParams: {
                        ...state.qaDocumentationFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_DISCOVERY_BASE_FILTER:
            return {
                ...state,
                discoveryFilters: {
                    ...state.discoveryFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_DISCOVERY_FILTER_OPTION:
            return {
                ...state,
                discoveryFilters: {
                    ...state.discoveryFilters,
                    filterOption: payload,
                },
            };
        case types.SET_DISCOVERY_FILTER_PARAMS:
            return {
                ...state,
                discoveryFilters: {
                    ...state.discoveryFilters,
                    filterParams: {
                        ...state.discoveryFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_SWITCHES_BASE_FILTER:
            return {
                ...state,
                switchesFilters: {
                    ...state.switchesFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_SWITCHES_FILTER_OPTION:
            return {
                ...state,
                switchesFilters: {
                    ...state.switchesFilters,
                    filterOption: payload,
                },
            };
        case types.SET_SWITCHES_FILTER_PARAMS:
            return {
                ...state,
                switchesFilters: {
                    ...state.switchesFilters,
                    filterParams: {
                        ...state.switchesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_DOWNTIME_BRANCHES_BASE_FILTER:
            return {
                ...state,
                downtimeBranchesFilters: {
                    ...state.downtimeBranchesFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_DOWNTIME_BRANCHES_FILTER_OPTION:
            return {
                ...state,
                downtimeBranchesFilters: {
                    ...state.downtimeBranchesFilters,
                    filterOption: payload,
                },
            };
        case types.SET_DOWNTIME_BRANCHES_FILTER_PARAMS:
            return {
                ...state,
                downtimeBranchesFilters: {
                    ...state.downtimeBranchesFilters,
                    filterParams: {
                        ...state.downtimeBranchesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_FORMS_BASE_FILTER:
            return {
                ...state,
                formFilters: {
                    ...state.formFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_FORMS_FILTER_OPTION:
            return {
                ...state,
                formFilters: {
                    ...state.formFilters,
                    filterOption: payload,
                },
            };
        case types.SET_FORMS_FILTER_PARAMS:
            return {
                ...state,
                formFilters: {
                    ...state.formFilters,
                    filterParams: {
                        ...state.formFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_POST_FORM_BASE_FILTER:
            return {
                ...state,
                postFormFilters: {
                    ...state.postFormFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_POST_FORM_FILTER_OPTION:
            return {
                ...state,
                postFormFilters: {
                    ...state.postFormFilters,
                    filterOption: payload,
                },
            };
        case types.SET_POST_FORM_FILTER_PARAMS:
            return {
                ...state,
                postFormFilters: {
                    ...state.postFormFilters,
                    filterParams: {
                        ...state.postFormFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_CANNED_RESPONSES_BASE_FILTER:
            return {
                ...state,
                cannedResponsesFilters: {
                    ...state.cannedResponsesFilters,
                    baseFilter: payload,
                },
            };
        case types.SET_CANNED_RESPONSES_FILTER_OPTION:
            return {
                ...state,
                cannedResponsesFilters: {
                    ...state.cannedResponsesFilters,
                    filterOption: payload,
                },
            };
        case types.SET_CANNED_RESPONSES_FILTER_PARAMS:
            return {
                ...state,
                cannedResponsesFilters: {
                    ...state.cannedResponsesFilters,
                    filterParams: {
                        ...state.cannedResponsesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_TEAMS_FILTER_PARAMS:
            return {
                ...state,
                teamsFilters: {
                    ...state.teamsFilters,
                    filterParams: {
                        ...state.teamsFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_MSSG_TAG_FILTER_PARAMS:
            return {
                ...state,
                mssgTagFilters: {
                    ...state.mssgTagFilters,
                    filterParams: {
                        ...state.mssgTagFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_PERSONAS_FILTER_PARAMS:
            return {
                ...state,
                personasFilters: {
                    ...state.personasFilters,
                    filterParams: {
                        ...state.personasFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_VET_TICKET_FILTER:
            return {
                ...state,
                vetTicketFilters: {
                    ...state.vetTicketFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_VET_TICKET_FILTER_OPTION:
            return {
                ...state,
                vetTicketFilters: {
                    ...state.vetTicketFilters,
                    filterOption: payload,
                },
            };

        case types.SET_VET_TICKET_FILTER_PARAMS:
            return {
                ...state,
                vetTicketFilters: {
                    ...state.vetTicketFilters,
                    filterParams: {
                        ...state.vetTicketFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_VET_AGENT_FILTER:
            return {
                ...state,
                vetAgentFilters: {
                    ...state.vetAgentFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_VET_AGENT_FILTER_OPTION:
            return {
                ...state,
                vetAgentFilters: {
                    ...state.vetAgentFilters,
                    filterOption: payload,
                },
            };

        case types.SET_VET_AGENT_FILTER_PARAMS:
            return {
                ...state,
                vetAgentFilters: {
                    ...state.vetAgentFilters,
                    filterParams: {
                        ...state.vetAgentFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_GUIDES_FILTER:
            return {
                ...state,
                guidesFilters: {
                    ...state.guidesFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_GUIDES_FILTER_OPTION:
            return {
                ...state,
                guidesFilters: {
                    ...state.guidesFilters,
                    filterOption: payload,
                },
            };

        case types.SET_GUIDES_FILTER_PARAMS:
            return {
                ...state,
                guidesFilters: {
                    ...state.guidesFilters,
                    filterParams: {
                        ...state.guidesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_SCENARIOS_FILTER_OPTION:
            return {
                ...state,
                scenariosFilters: {
                    ...state.scenariosFilters,
                    filterOption: payload,
                },
            };

        case types.SET_SCENARIOS_FILTER_PARAMS:
            return {
                ...state,
                scenariosFilters: {
                    ...state.scenariosFilters,
                    filterParams: {
                        ...state.scenariosFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_SCENARIOS_FILTER:
            return {
                ...state,
                scenariosFilters: {
                    ...state.scenariosFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_CANNED_EMAILS_FILTER_OPTION:
            return {
                ...state,
                cannedEmailsFilters: {
                    ...state.cannedEmailsFilters,
                    filterOption: payload,
                },
            };

        case types.SET_CANNED_EMAILS_FILTER_PARAMS:
            return {
                ...state,
                cannedEmailsFilters: {
                    ...state.cannedEmailsFilters,
                    filterParams: {
                        ...state.cannedEmailsFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_CANNED_EMAILS_FILTER:
            return {
                ...state,
                cannedEmailsFilters: {
                    ...state.cannedEmailsFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_CANNED_MENTIONS_FILTER_OPTION:
            return {
                ...state,
                cannedMentionsFilters: {
                    ...state.cannedMentionsFilters,
                    filterOption: payload,
                },
            };

        case types.SET_CANNED_MENTIONS_FILTER_PARAMS:
            return {
                ...state,
                cannedMentionsFilters: {
                    ...state.cannedMentionsFilters,
                    filterParams: {
                        ...state.cannedMentionsFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_CANNED_MENTIONS_FILTER:
            return {
                ...state,
                cannedMentionsFilters: {
                    ...state.cannedMentionsFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_CANNED_REPLIES_FILTER_OPTION:
            return {
                ...state,
                cannedRepliesFilters: {
                    ...state.cannedRepliesFilters,
                    filterOption: payload,
                },
            };

        case types.SET_CANNED_REPLIES_FILTER_PARAMS:
            return {
                ...state,
                cannedRepliesFilters: {
                    ...state.cannedRepliesFilters,
                    filterParams: {
                        ...state.cannedRepliesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_CANNED_REPLIES_FILTER:
            return {
                ...state,
                cannedRepliesFilters: {
                    ...state.cannedRepliesFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_EMAIL_UPDATES_FILTER_OPTION:
            return {
                ...state,
                emailUpdatesFilters: {
                    ...state.emailUpdatesFilters,
                    filterOption: payload,
                },
            };

        case types.SET_EMAIL_UPDATES_FILTER_PARAMS:
            return {
                ...state,
                emailUpdatesFilters: {
                    ...state.emailUpdatesFilters,
                    filterParams: {
                        ...state.emailUpdatesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_EMAIL_UPDATES_FILTER:
            return {
                ...state,
                emailUpdatesFilters: {
                    ...state.emailUpdatesFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_MENTION_UPDATES_FILTER_OPTION:
            return {
                ...state,
                mentionUpdatesFilters: {
                    ...state.mentionUpdatesFilters,
                    filterOption: payload,
                },
            };

        case types.SET_MENTION_UPDATES_FILTER_PARAMS:
            return {
                ...state,
                mentionUpdatesFilters: {
                    ...state.mentionUpdatesFilters,
                    filterParams: {
                        ...state.mentionUpdatesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_MENTION_UPDATES_FILTER:
            return {
                ...state,
                mentionUpdatesFilters: {
                    ...state.mentionUpdatesFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_REPLY_UPDATES_FILTER_OPTION:
            return {
                ...state,
                replyUpdatesFilters: {
                    ...state.replyUpdatesFilters,
                    filterOption: payload,
                },
            };

        case types.SET_REPLY_UPDATES_FILTER_PARAMS:
            return {
                ...state,
                replyUpdatesFilters: {
                    ...state.replyUpdatesFilters,
                    filterParams: {
                        ...state.replyUpdatesFilters.filterParams,
                        ...payload,
                    },
                },
            };

        case types.SET_REPLY_UPDATES_FILTER:
            return {
                ...state,
                replyUpdatesFilters: {
                    ...state.replyUpdatesFilters,
                    baseFilter: payload,
                },
            };

        case types.SET_GUIDE_DOWNTIME_FILTER_PARAMS:
            return {
                ...state,
                guideDowntimeFilters: {
                    ...state.guideDowntimeFilters,
                    filterParams: {
                        ...state.guideDowntimeFilters.filterParams,
                        ...payload,
                    },
                },
            };
        default:
            return state;
    }
};

export default FiltersReducer;
