import StatusMapBar from "./StatusMapBar/StatusMapBar";
import StatusMapLegend from "./StatusMapLegend/StatusMapLegend";
import StatusMapPopup from "./StatusMapPopup/StatusMapPopup";

const StatusMapChartBar = ({
    status,
    hrsDiff,
    toggleShow,
    barId,
    date,
    clickedBar,
    totalHrsOfDowntimes,
    totalHrsOfUptimes,
    selectedOption,
}) => {
    const handlePopup = () => {
        toggleShow(barId);
    };

    return (
        <>
            <div>
                {clickedBar === barId && (
                    <div className='popup-container'>
                        <StatusMapPopup
                            {...{
                                hrsDiff,
                                date,
                                status,
                                totalHrsOfDowntimes,
                                totalHrsOfUptimes,
                            }}
                        />
                    </div>
                )}
            </div>

            <div className='barBody' onClick={handlePopup}>
                <StatusMapBar {...{ hrsDiff, status, selectedOption }} />
                <StatusMapLegend {...{ hrsDiff }} />
            </div>
        </>
    );
};

export default StatusMapChartBar;
