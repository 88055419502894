import React, { useState } from "react";
import Classifications from "../Classifications/Classifications";
import UnassignedCapsules from "../UnassignedCapsules/UnassignedCapsules";
import "./UnassignedTeamClassification.scss";

const UnassignedTeamClassification = () => {
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState();
    const [prevAssigned, setPrevAssigned] = useState([]);

    return (
        <div className='team__classification__container'>
            <UnassignedCapsules
                pushSelectedClass={(capsuleClass) =>
                    setSelectedCapsuleClass(capsuleClass)
                }
                selectedCapsuleClass={selectedCapsuleClass}
                prevAssigned={prevAssigned}
            />
            <Classifications
                prevAssigned={prevAssigned}
                setPrevAssigned={setPrevAssigned}
                selectedUnassignedCapsule={selectedCapsuleClass}
            />
        </div>
    );
};

export default UnassignedTeamClassification;
