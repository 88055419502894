import { useMemo } from "react";
import debounce from "debounce-promise";
import { InputSearch } from "../InputSearch";
import "./PaginationSearch.scss";

const PaginationSearch = ({
    isLoading,
    handleSearch,
    placeholder,
    clearSearch,
    defaultValue,
    ...rest
}) => {
    const debouncedSearch = useMemo(
        () =>
            debounce((search) => handleSearch(search), 1000, { leading: true }),
        // eslint-disable-next-line
        []
    );

    return (
        <div>
            <InputSearch
                placeholder={placeholder}
                onChange={debouncedSearch}
                isLoading={isLoading}
                clearValue={clearSearch}
                inputClass={`pagination-search `}
                defaultValue={defaultValue}
                {...rest}
            />
        </div>
    );
};

export default PaginationSearch;
