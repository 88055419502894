import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { IS_NOT_TYPING, IS_TYPING } from "services/socket/events";
import TypingMessageIndicator from "./Message/TypingMessage/TypingMessage";
import { appMessageUserTypes } from "./Message/enums";
import { AnimatePresence } from "framer-motion";
import MessagesThread from "./MessagesThread/MessagesThread";
import "./Messages.scss";
import { useSocket } from "services/socket/hook";

const Messages = ({
    customer,
    openPreviewModal,
    vetAgentTicket,
    handleResendMessage,
    selectedCustomerMessage,
    selectCustomerMessage,
    defaultMessages
}) => {
    const socket = useSocket() // Access the socket from context

    const [customerIsTyping, sayCustomerIsTyping] = useState(false);
    const { activeTicket, ticketsMessages } = useSelector(
        (state) => state.tickets
    );
    const { ticketId } = activeTicket || {};
    const messages = defaultMessages || ticketsMessages?.filter(
        (item) => item?.ticketId === ticketId
    );

    const handleNewTypingTrigger = (data) => {
        const { userType } = data.user;
        if (userType === appMessageUserTypes?.THIRD_USER) {
            sayCustomerIsTyping(true);
        }
    };

    const handleStoppedTypingTrigger = (data) => {
        const { userType } = data.user;
        if (userType === appMessageUserTypes?.THIRD_USER) {
            sayCustomerIsTyping(false);
        }
    };

    useEffect(() => {
        socket?.on?.(IS_TYPING, handleNewTypingTrigger);
        socket?.on?.(IS_NOT_TYPING, handleStoppedTypingTrigger);
    }, [socket]);

    return (
        <>
            <AnimatePresence>
                <MessagesThread
                    messages={messages}
                    customer={customer}
                    openPreviewModal={openPreviewModal}
                    handleResendMessage={handleResendMessage}
                    {...vetAgentTicket}
                    selectedCustomerMessage={selectedCustomerMessage}
                    selectCustomerMessage={selectCustomerMessage}
                />
            </AnimatePresence>
            {customerIsTyping && <TypingMessageIndicator />}
        </>
    );
};

export default Messages;
