import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import auth from "./auth/reducer";
import alert from "./alert/reducer";
import product from "./product/reducer";
import capsule from "./capsule/reducer";
import messageTags from "./messageTags/reducer";
import tickets from "./tickets/reducer";
import incomingTickets from "./tickets/incomingTickets/reducer";
import ticketsFilters from "./ticketsFilters/reducer";
import analytics from "./analytics/reducer";
import settings from "./settings/reducer";
import filters from "./filters/reducer";
import admin from "./modules/admin/reducer";
import knowledgeBase from "./modules/knowledgeBase/reducer";
import agentInbox from "./modules/agentInbox/reducer";
import sam from "./modules/sam/reducer";
import serviceModule from "./modules/serviceModule/reducer";
import branches from "./cache/branches/reducer";
import collections from "./cache/collections/reducer";
import forms from "./cache/forms/reducer";
import roles from "./cache/roles/reducer";
import agents from "./cache/agents/reducer";
import vetTickets from "./modules/qa/reducer";
import agentAssistance from "./modules/agentAssistance/reducer";

export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const SWITCH_WORKSPACE = "SWITCH_WORKSPACE";

const appReducer = combineReducers({
    auth,
    alert,
    product,
    capsule,
    messageTags,
    tickets,
    incomingTickets,
    ticketsFilters,
    analytics,
    settings,
    filters,
    admin,
    knowledgeBase,
    agentInbox,
    sam,
    serviceModule,
    branches,
    collections,
    forms,
    roles,
    agents,
    vetTickets,
    agentAssistance
});

const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_REQUEST) {
        // for all keys defined in your persistConfig(s)
        localStorage.removeItem("persist:root");
        storage.removeItem("persist:root");
        // storage.removeItem('persist:otherKey')
        state = undefined;
        // return appReducer(undefined, {});
    }

    if (action.type === SWITCH_WORKSPACE) {
        const updatedState = { auth: state.auth };
        return appReducer(updatedState, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
