import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TicketFormDetailsList from "./TicketFormDetailsList/TicketFormDetailsList";
import "./TicketInfoFormDetails.scss";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const TicketInfoFormDetails = ({ ticketId, formId }) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [filledFormDetails, setFilledFormDetails] = useState();

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return <TicketFormDetailsList data={filledFormDetails} />;

            case ERROR:
                return <ErrorView message={errorMssg} />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        message={"No Ticket Info found "}
                        otherClassNames={"with-margin"}
                    />
                );

            default:
                return "";
        }
    };

    const getFilledFormDetails = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg();

            const url = apiRoute?.getTicketFilledForm(ticketId, formId);
            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;

                if (data?.length > 0) {
                    setFilledFormDetails(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                    setFilledFormDetails();
                }
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getFilledFormDetails();
        //eslint-disable-next-line
    }, [ticketId, formId]);

    return <div>{renderBasedOnStatus()}</div>;
};

export default TicketInfoFormDetails;
