import React from "react";

const CTCHeading = ({ data }) => {
    const newTicketsCount = data?.filter((x) => x.isNew === true)?.length;

    return (
        <div className='tside__heading'>
            <h6>Assigned tickets</h6>
            {newTicketsCount > 0 && (
                <div className='countable__badge'>New Tickets Arrived</div>
            )}
        </div>
    );
};

export default CTCHeading;
