import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./AttachedBranchImage.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const AttachedBranchImage = ({ mainBranchImageUrl }) => {
    return (
        <>
            <p className='attached__branch__image__title fw-medium'>
                Attached image
            </p>
            {mainBranchImageUrl ? (
                <div>
                    <ImageView
                        src={mainBranchImageUrl}
                        alt='Uploaded'
                        className='attached__branch__image'
                    />
                </div>
            ) : (
                <div className='empty__attached__branch__image'>
                    <div className='empty__attached__branch__image--text__container'>
                        <ReactSVG src={imageLinks?.icons?.image} />
                        <span className='empty__attached__branch__image--text'>
                            No image attached yet
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default AttachedBranchImage;
