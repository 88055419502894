import React from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Button } from "components/ui";
import "./EmailHolder.scss";

const EmailHolder = ({ email, isSenderEmail, onClick, disabled }) => {
    const history = useHistory();

    const handleRedirect = () => {
        history.push("/agents-inbox/settings/integrations");
    };

    const isEmailAvailable = email?.length > 0;
    return (
        <span
            className={`email__holder ${
                isSenderEmail && isEmailAvailable ? "sender--email" : ""
            } ${email ? "email" : ""}`}>
            {isEmailAvailable ? (
                email
            ) : isSenderEmail ? (
                <Button
                    text='Add external email'
                    type='button'
                    classType='plain'
                    otherClass='fw-bold'
                    onClick={handleRedirect}
                />
            ) : (
                ""
            )}
            {isSenderEmail && (
                <ReactSVG
                    src={imageLinks?.icons?.downside}
                    className={"remove__sender_email"}
                />
            )}

            {!disabled && !isSenderEmail && (
                <ReactSVG
                    src={imageLinks?.icons?.cancelX}
                    className={"remove__email"}
                    onClick={onClick}
                />
            )}
        </span>
    );
};

export default EmailHolder;
