import React, { useState, useContext } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Input, Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import SelectUserRole from "../common/SelectUserRole/SelectUserRole";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./InviteMembersForm.scss";

const { USER_MANAGEMENT_USERS } = baseResources;
const { CREATE } = baseResourceActions;

const InviteMembersForm = ({ handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [errors, setErrors] = useState({});
    const [request, updateRequest] = useState({
        fullName: "",
        email: "",
        roleCode: "",
    });
    const [errorMssg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);

    const handleRoleCode = (name, value) => {
        updateRequest((prevRequest) => ({
            ...prevRequest,
            [name]: value,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleChange = ({ target: { name, value } }) => {
        updateRequest((prevRequest) => ({
            ...prevRequest,
            [name]: value,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const inviteMember = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const [firstName, lastName] = request?.fullName?.split(" ");

            const url = apiRoute.inviteUser;
            const res = await API.post(url, {
                ...request,
                firstName,
                lastName,
            });

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        formisValid ? inviteMember() : setErrors(formErrors);
    };

    return (
        <form className='user__wrapper mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='fullName'
                label='Full Name'
                labelClass='text-dark fw-bold'
                id='fullName'
                placeholder='Full Name'
                value={request?.fullName}
                onChange={(e) => handleChange(e)}
                isErr={errors?.fullName}
                errMssg={errors?.fullName}
                autoFocus={true}
            />

            <Input
                grpClass='mb-3'
                type='email'
                name='email'
                label='Email Address'
                labelClass='text-dark fw-bold'
                id='email'
                placeholder='Enter your email address'
                value={request?.email}
                onChange={(e) => handleChange(e)}
                isErr={errors?.email}
                errMssg={errors?.email}
            />

            <SelectUserRole
                name='roleCode'
                error={errors?.roleCode ? "Role is required" : ""}
                selectedValue={request?.roleCode}
                handleSelect={handleRoleCode}
                setLoadingRoles={setLoadingRoles}
            />

            <div className='mt-5x'>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${USER_MANAGEMENT_USERS}`]}>
                    <Button
                        type='submit'
                        classType='primary'
                        loading={loading}
                        disabled={loadingRoles}
                        otherClass='w-100'
                        text='Add User'
                    />
                </ComponentAccessControl>
            </div>
        </form>
    );
};

export default InviteMembersForm;
