import PropTypes from "prop-types";
import KeyPhraseHeader from "./KeyPhraseHeader/KeyPhraseHeader";
import KeyPhraseList from "./KeyPhraseList/KeyPhraseList";

const KeyPhrase = ({ keyPhrases, handleSuccess, selectedMssgTagId }) => {
    return (
        <>
            <KeyPhraseHeader
                selectedMssgTagId={selectedMssgTagId}
                handleSuccess={handleSuccess}
            />
            <KeyPhraseList
                keyPhrases={keyPhrases}
                handleSuccess={handleSuccess}
            />
        </>
    );
};

KeyPhrase.prototypes = {
    KeyPhrases: PropTypes.array.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default KeyPhrase;
