import React from "react";
import Heading from "components/ui/Heading/Heading";

const SwitchesListingHeading = () => {
    return (
        <>
            <Heading
                headingText={"Switches"}
                headingSubText={"Add & change your dependencies"}
            />
        </>
    );
};

export default SwitchesListingHeading;
