import { inboxAppsActionTypes } from "components/essentials/Integrations/enum";
import AccordionCardItem from "components/ui/AccordionTabs/AccordionCard/AccordionCardItem/AccordionCardItem";
import AppUsersTable from "./AppUsersTable/AppUsersTable";
import InboxAppModal from "./InboxAppModal/InboxAppModal";
import { useState } from "react";
import { availableInboxApps } from "../../enum";

const InboxApps = ({
    data,
    handleNewConnection,
    handleSuccess,
    handleError,
    user,
}) => {
    const [showModal, toggleModal] = useState(false);

    const handleActionModal = () => {
        toggleModal(true);
    };

    const isGmailWebHookConnected =
        data
            .filter(
                (appSources) =>
                    appSources.appSource === availableInboxApps.GMAIL
            )[0]
            ?.appUsers?.filter((appUsers) => appUsers?.isWebHook === true)
            ?.length > 0;

    return (
        <>
            <div className='custom__card'>
                {data?.map((item, key) => (
                    <AccordionCardItem
                        key={key}
                        {...item}
                        handleNewConnection={handleNewConnection}
                        handleSuccess={handleSuccess}
                        user={user}
                        actionType={inboxAppsActionTypes.CONNECT}
                        handleActionModal={handleActionModal}
                        isGmailWebHookConnected={isGmailWebHookConnected}
                        handleError={handleError}>
                        <AppUsersTable
                            data={item?.appUsers}
                            appSource={item?.appSource}
                            actionType={inboxAppsActionTypes.DISCONNECT}
                            handleNewConnection={handleNewConnection}
                            user={user}
                            handleSuccess={handleSuccess}
                            handleError={handleError}
                            handleActionModal={handleActionModal}
                        />
                    </AccordionCardItem>
                ))}
            </div>

            {showModal && (
                <InboxAppModal
                    show={showModal}
                    closeModal={() => toggleModal(false)}
                    handleNewConnection={handleNewConnection}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    workspace={user?.workspace}
                />
            )}
        </>
    );
};

export default InboxApps;
