import { SideModal } from "components/ui/Modal";
import { DEFAULT_GROUP_MESSAGES } from "components/ui/Nav/constant";
import Notifications from "components/ui/Notifications/Notifications";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
    getRealTimeNotifications,
    getTotalNotificationsCount,
} from "services/firebase/firebase";
import { groupNotificationMessages } from "services/firebase/helper/helper";

const NavNotificationModal = ({
    showModal,
    toggleModal,
    totalMessagesCount,
    selectedCategory,
    updateSelectedCategory,
    user,
}) => {
    const { workspace, userId } = user;

    const [messagesList, updateMessagesList] = useState([]);
    const [groupedMessagesList, updateGroupedMessagesList] = useState(
        DEFAULT_GROUP_MESSAGES
    );

    const [currUnreadMsgsCount, updateCurrUnreadMsgsCount] = useState("-");

    const unsubscribe = useRef();
    const lastMessageId = useRef();

    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({
        nextPage: 2,
    });

    const updateFilters = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });
    };

    const { nextPage } = filters;

    const handleClose = () => {
        handleTabChange("ALL");
        toggleModal(false);
    };

    const handleTabChange = (category) => {
        updateSelectedCategory(category);
        updateFilters(lastMessageId, "");
        updateGroupedMessagesList(DEFAULT_GROUP_MESSAGES);
        updateMessagesList([]);
    };

    const updateAllMessages = (newMessages) => {
        let groupedNewMessages = groupNotificationMessages(newMessages, userId);

        let updatedGroupedMessages = groupedMessagesList.map(
            (eachMessageGroup, key) => {
                return {
                    groupName: eachMessageGroup?.groupName,
                    data: [
                        ...eachMessageGroup.data,
                        ...groupedNewMessages[key].data,
                    ],
                };
            }
        );
        updateMessagesList([...messagesList, ...newMessages]);
        updateGroupedMessagesList(updatedGroupedMessages);
    };

    const fetchNextPage = (currentPage) => {
        lastMessageId.current =
            messagesList[
                messagesList.length - 1
            ]?._document.data.value.mapValue.fields?.createdAt?.stringValue;

        if (messagesList?.length < totalMessagesCount) {
            setLoading(true);
            setFilters({ ...filters, nextPage: currentPage + 1 });
        }
    };

    useEffect(() => {
        getRealTimeNotifications(
            workspace?.workspaceId,
            updateAllMessages,
            selectedCategory,
            setLoading,
            lastMessageId.current,
            unsubscribe
        );

        return () => {
            // unsubscribe?.current?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace, selectedCategory, nextPage, lastMessageId, unsubscribe?.current]);

    useEffect(() => {
        getTotalNotificationsCount(
            workspace?.workspaceId,
            selectedCategory,
            null,
            updateCurrUnreadMsgsCount,
            userId
        );
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <SideModal
                show={showModal}
                close={handleClose}
                otherclassNames='custom__modal--padding'
                showCloseIcon={false}>
                <Notifications
                    groupedMessagesList={groupedMessagesList}
                    closeModal={handleClose}
                    totalMessagesCount={totalMessagesCount}
                    handleTabChange={handleTabChange}
                    selectedCategory={selectedCategory}
                    fetchNextPage={fetchNextPage}
                    nextPage={nextPage}
                    loading={loading}
                    fetchedMessagesCount={messagesList?.length}
                    currUnreadMsgsCount={currUnreadMsgsCount}
                />
            </SideModal>
        </>
    );
};

export default NavNotificationModal;
