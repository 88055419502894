import React, { useState, useContext, useEffect } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { analyticsRequestKeys } from "../../AnalyticsTSam/enum";
import AnalyticsFilters from "../common/AnalyticsFilters/AnalyticsFilters";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage, MonthDay, roundUp } from "utils/helper";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
import {
    computerPercentage,
    getCurrentAnalyticsCustomDates,
    getDatesBasedOnDuration,
    weeklyBasedComputation,
} from "../common/helpers";
// import Axios from "axios";
import API from "services/lib/api";
import {
    setAnalyticsChartData,
    updateCurrentAnalyticsFilter,
} from "store/analytics/actions";
import AnalyticsBarChart from "../common/AnalyticsBarChart/AnalyticsBarChart";
import {
    analyticsTeamBacklogDefaultOptions,
    analyticsTeamBacklogExtraTooltipMetrics,
} from "./enums";
import { teamAnalyticsRequestFilters } from "../common/enum";

const { ERROR } = dataQueryStatus;

const AnalyticsTeamBacklog = () => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );

    const [analyticsData, setAnalyticsData] = useState([]);
    const [analyticsResultGroup, setAnalyticsResultGroup] = useState({});

    const [ticketVolumeData, setTicketVolumeData] = useState([]);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);

    const {
        currentAnalyticsFilter,
        analyticsBacklog,
        currentAnalyticsCustomDate,
    } = useSelector((state) => state.analytics);
    const dispatch = useDispatch();

    const toastMessage = useContext(ToastContext);

    const {
        analyticsRequestType,
        analyticsRequestDuration,
        analyticsRequestValue,
    } = currentAnalyticsFilter || {};

    const hasTicketInfo =
        analyticsRequestType ===
        teamAnalyticsRequestFilters.TICKET_CLASSIFICATION;

    const updateRequest = (name, value) => {
        dispatch(
            updateCurrentAnalyticsFilter({
                [name]: value,
            })
        );
    };

    const handleSetOptions = async (options) => {
        // let stringRequestOption = options.map(o => o.value).join(',');
        await setOptions(options);
        // await setSelectedOptions(options);

        if (analyticsBacklog?.[analyticsRequestType]) {
            let persistedAnalyticsData =
                analyticsBacklog?.[analyticsRequestType]?.[
                    analyticsRequestDuration
                ];
            if (persistedAnalyticsData) {
                setAnalyticsData(persistedAnalyticsData);
                setChartLoading(false);
            } else {
                setChartLoading(true);
            }
        } else {
            setChartLoading(true);
        }

        await getAnalyticsData();
    };

    const compileChartData = async (data, keyName, valName) => {
        if (data.length > 0) {
            const dateSeries = getDatesBasedOnDuration(
                analyticsRequestDuration
            );
            const mainArrayData = [];

            const optionValues = options.map(({ value }) => {
                return value;
            });

            const newValsArray =
                analyticsRequestValue?.length > 0
                    ? analyticsRequestValue?.split(",")
                    : optionValues;

            // const strokeOptions = options.filter(({ value }) =>
            //     newValsArray.includes(value)
            // );

            // setSelectedOptions(strokeOptions);

            dateSeries?.forEach(async (dt) => {
                //assignedTeamId
                let valInstances = data.filter((ad) => {
                    return (
                        new Date(ad.dateValue).toDateString() ===
                        new Date(dt).toDateString()
                    );
                });

                let originalObj = Object.assign(
                    {},
                    ...newValsArray.map((y) => {
                        return { [y]: 0 };
                    })
                );

                let result = Object.assign(
                    originalObj,
                    ...valInstances.map((o) => {
                        return {
                            [o[keyName]]: o[valName] ? o[valName] : 0,
                            [`${o[keyName]}_FREQ`]: computerPercentage(
                                o["totalBackLoggedTicketsPerDay"] || 0,
                                o["totalTicketsPerDay"]
                            ),
                            originalData: o,
                        };
                    })
                );
                mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
            });
            return mainArrayData;
        }

        // setAnalyticsData(dateSeries)
    };

    const { startDate, endDate } =
        getCurrentAnalyticsCustomDates(
            currentAnalyticsFilter,
            currentAnalyticsCustomDate
        ) || {};

    const getAnalyticsData = async () => {
        try {
            if (startDate === "" || endDate === "") {
                // if start or end date is empty string, don't make api call and this can only happen when a custom date selection is selected
                return;
            }

            setIsLoading(true);

            setAnalyticsResultGroup({});

            // const commaAnalyticsValue = '\'' + requestVal.split(',').join('\',\'') + '\'';
            const commaAnalyticsValue = "";
            // requestVal === ""
            //     ? ""
            //     : "'" + requestVal?.split(",").join("','") + "'";

            const url = apiRoute?.getTicketsBacklogAnalytics;
            const res = await API.get(url, {
                // const res = await Axios.get(`http://localhost:7073/${url}`, {
                headers: {
                    workspaceid: workspaceId,
                },
                params: {
                    ...currentAnalyticsFilter,
                    analyticsRequestValue: commaAnalyticsValue,
                    startDate,
                    endDate,
                    analyticsRequestType: analyticsRequestType || undefined,
                },
                // signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setIsLoading(false);
                setChartLoading(false);
                dispatch(
                    setAnalyticsChartData({
                        analyticsData: data,
                        analyticsRequestDuration,
                        analyticsRequestType,
                        analyticsChartTitle: "analyticsBacklog",
                    })
                );
                await setAnalyticsData(data);
            }
        } catch (err) {
            setIsLoading(false);
            setChartLoading(false);
            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            let errorMssg = getErrorMessage(err);
            toastMessage(errorMssg, ERROR);
        }
    };

    const compileAnalyticsGroupData = async () => {
        const totalTicketsCount = analyticsData.reduce(
            (total, obj) => obj.totalTicketsPerDay + total,
            0
        );
        const totalBackLogCount = analyticsData.reduce(
            (total, obj) => obj.totalBackLoggedTicketsPerDay + total,
            0
        );

        const avgBacklogPercent = (totalBackLogCount / totalTicketsCount) * 100;
        const weeklyBacklogPercent = weeklyBasedComputation(
            analyticsRequestDuration,
            avgBacklogPercent
        );

        const lookoutKey = analyticsRequestKeys[analyticsRequestType];
        const compileTicketsVolume = await compileChartData(
            analyticsData,
            lookoutKey,
            "totalBackLoggedTicketsPerDay"
        );
        setTicketVolumeData(compileTicketsVolume);
        setAnalyticsResultGroup({
            avgBacklogPercent: `${roundUp(avgBacklogPercent)}%`,
            weeklyBacklogPercent: `${roundUp(weeklyBacklogPercent)}%`,
        });
    };

    useEffect(() => {
        if (analyticsData.length > 0) {
            compileAnalyticsGroupData();
        }
        // eslint-disable-next-line
    }, [analyticsData]);

    useEffect(() => {
        getAnalyticsData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='padded__layout analytics-team-efficiency'>
                <AnalyticsFilters
                    defaultRequest={currentAnalyticsFilter}
                    updateRequest={updateRequest}
                    handleSubmit={getAnalyticsData}
                    isLoading={isLoading}
                    setSelectedOptions={setSelectedOptions}
                    currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                    {...{
                        options,
                        setOptions: handleSetOptions,
                    }}
                />

                <div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='Total Backlogs'
                            chartData={ticketVolumeData || []}
                            isLoading={chartLoading}
                            strokeData={
                                analyticsRequestType
                                    ? selectedOptions
                                    : analyticsTeamBacklogDefaultOptions
                            }
                            allStrokeData={options}
                            xDataKey1={"dateValue"}
                            xDomain2={[0, 100]}
                            yAxisLabel1={"Backlogs"}
                            xAxisLabel1={`Time`}
                            yAxisLabel2={"Freq (%)"}
                            xAxisLabel2={"Range (100%)"}
                            stackOffset='expand'
                            customUnit1={"backlog(s)"}
                            useDefaultOptions={
                                analyticsRequestType?.length === 0
                            }
                            tooltipExtraMetrics={
                                analyticsTeamBacklogExtraTooltipMetrics
                            }
                            hasTicketInfo={hasTicketInfo}
                            roundUpTooltipVal
                            isOverlapped
                            hasFreqKey
                            overviews={[
                                {
                                    title: "Average Backlog",
                                    text: analyticsResultGroup?.avgBacklogPercent,
                                },
                                {
                                    title: "Weekly Backlogs",
                                    text: analyticsResultGroup?.weeklyBacklogPercent,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsTeamBacklog;
