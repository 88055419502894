import { ReactSVG } from "react-svg";
import "./AnalyticsQATicketSummaryItem.scss";
import asset from "assets/images";

const AnalyticsQATicketSummaryItem = ({
    title,
    content,
    initiationDuration,
    ticketResponse,
    ticketVol,
}) => {
    return (
        <div className='analyticsqa-ticket-summary-item'>
            <h6>{title}</h6>
            {content && <p>{content}</p>}
            <div className='d-flex'>
                <div>
                    {initiationDuration && (
                        <span className='duration'>{initiationDuration}</span>
                    )}
                    {ticketResponse && (
                        <span className='upwards'>
                            <ReactSVG src={asset.icons.uptime} />
                            {ticketResponse}
                        </span>
                    )}
                </div>
                {ticketVol && (
                    <b className='d-flex'>
                        Ticket vol
                        <span className='downwards'>
                            <ReactSVG src={asset.icons.downtime} />
                            {ticketVol}
                        </span>
                    </b>
                )}
            </div>
        </div>
    );
};

export default AnalyticsQATicketSummaryItem;
