import * as types from "./types";

const initialState = {
    formsCache: {
        pages: {},
        totalRecords: 0,
    },
    postFormCollectionsCache: {
        pages: {},
        totalRecords: 0,
    },
    cannedResponsesCache: {
        pages: {},
        totalRecords: 0,
    },
};

const KnowledgeBaseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_FORMS:
            return {
                ...state,
                formsCache: {
                    ...state.formsCache,
                    pages: {
                        ...state.formsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_FORMS:
            return {
                ...state,
                formsCache: {
                    ...state.formsCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_POST_FORM_COLLECTIONS:
            return {
                ...state,
                postFormCollectionsCache: {
                    ...state.postFormCollectionsCache,
                    pages: {
                        ...state.postFormCollectionsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_POST_FORM_COLLECTIONS:
            return {
                ...state,
                postFormCollectionsCache: {
                    ...state.postFormCollectionsCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_CANNED_RESPONSES:
            return {
                ...state,
                cannedResponsesCache: {
                    ...state.cannedResponsesCache,
                    pages: {
                        ...state.cannedResponsesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };

        case types.CLEAR_CANNED_RESPONSES:
            return {
                ...state,
                cannedResponsesCache: {
                    ...state.cannedResponsesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        default:
            return state;
    }
};

export default KnowledgeBaseReducer;
