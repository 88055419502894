import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import "./CreateCommentInput.scss";

const CreateCommentInput = ({
    comment,
    handleChange,
    handleTagged,
    isDisabled,
    label,
    labelClass,
    grpClass,
    placeholder,
    autoFocus = true,
}) => {
    const [userData, setUserData] = useState([]);
    const [errorMssg, setErrorMssg] = useState("");

    const fetchUsers = async () => {
        try {
            setErrorMssg();
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
            });

            const teamRes = await API.get(apiRoute?.teams, {
                params: { all: true },
            });

            if (res.status === 200) {
                const { data } = res.data;
                const { data: teamData } = teamRes.data;

                if (data?.length > 0) {
                    const users = data?.map((data) => ({
                        id: [data?.userId],
                        display: `${data?.firstName} ${data?.lastName}`,
                    }));

                    const teams = teamData?.map((data) => ({
                        id: data?.teamMembers.map((member) => member.userId),
                        display: data?.teamName,
                    }));

                    return setUserData([...users, ...teams]);
                }
                return setUserData([]);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className={`create__comment__input__group ${grpClass ? grpClass : ''}`}>
            {label && <label className={labelClass}>{label}</label>}
            <MentionsInput
                value={comment}
                onChange={handleChange}
                allowSpaceInQuery={true}
                className='mentions'
                placeholder={placeholder}
                disabled={isDisabled}
                autoFocus={autoFocus}>
                <Mention
                    trigger='@'
                    data={userData}
                    markup='@@@____id__^^____display__@@@^^^'
                    onAdd={(id) => handleTagged(id)}
                />
            </MentionsInput>
            {errorMssg && (
                <p className='mentions__error'>
                    {errorMssg} <span onClick={fetchUsers}>Retry?</span>
                </p>
            )}
        </div>
    );
};

export default CreateCommentInput;
