import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute/ProtectedRoute";
import PublicRoute from "routes/PublicRoute/PublicRoute";
import SignIn from "pages/PublicPages/SignIn/SignIn";
import { ForgotPassword, SignUp } from "pages/PublicPages";
import VerifyEmail from "pages/PublicPages/SignUp/VerifyEmail/VerifyEmail";
import DashBase from "components/dashboard/Dashbase/Dashbase";
import "assets/scss/_index.scss";
import "react-datepicker/dist/react-datepicker.css";

const NotFound = () => <h1>Not Found, 404</h1>;

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path='/'
                    render={() => <Redirect to='/sign-in' />}
                />

                <PublicRoute path='/sign-in' exact component={SignIn} />

                <PublicRoute
                    path='/forgot-password'
                    exact
                    component={ForgotPassword}
                />

                <PublicRoute path='/sign-up' exact component={SignUp} />

                <PublicRoute
                    path='/verify-email'
                    exact
                    component={VerifyEmail}
                />

                <ProtectedRoute component={DashBase} />
                <Route path='*' component={NotFound} />
            </Switch>
        </Router>
    );
}

export default App;
