import React, { useContext, useState } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ConfirmAction, Modal } from "components/ui";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

const { ERROR } = dataQueryStatus;

const CloseTicket = ({ show, closeModal, handleSuccess, ticketToClose }) => {
    const [isLoading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const { ticketId } = ticketToClose || {};

    const handleDeleteSuccess = (message = "Ticket Closed") => {
        // toastMessage(message);
        handleSuccess();
        closeModal();
    };

    const handleCloseTicket = async () => {
        try {
            setLoading(true);

            const url = apiRoute?.closeTicket(ticketId);
            const res = await API.post(url);

            if (res.status === 201) {
                const { message } = res.data;
                handleDeleteSuccess(message);
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <Modal show={show} closeModal={closeModal}>
            <ConfirmAction
                close={closeModal}
                title={`Continue to close ${ticketToClose?.customer?.email} ticket`}
                loading={isLoading}
                handleYesClick={handleCloseTicket}
            />
        </Modal>
    );
};

export default CloseTicket;
