import SelectUI from "components/ui/SelectUI/SelectUI";

const FilterCapsuleSort = ({
    handleSelectClassType,
    relatedClasses,
    loadingRelatedClasses,
}) => {
    return (
        <>
            <div className='filter__select'>
                <SelectUI
                    name='selectClass'
                    id='selectClass'
                    placeholder='Select Class'
                    onChange={({ value }) => handleSelectClassType(value)}
                    isLoading={loadingRelatedClasses}
                    hasOptionIcon={true}
                    options={relatedClasses?.map((capsuleClass) => {
                        return {
                            label: capsuleClass?.capsuleClassName,
                            value: capsuleClass,
                            icon: "radio",
                        };
                    })}
                />
            </div>
        </>
    );
};

export default FilterCapsuleSort;
