import { Button } from "components/ui";
import "./DeleteBranchModalActions.scss";

const DeleteBranchModalActions = ({
    handleContinue,
    handleCancel,
    loading,
    deleteText,
}) => {
    return (
        <>
            <div className='delete-branch-actions'>
                <Button
                    text={"Concel"}
                    type='button'
                    classType='outline-primary'
                    otherClass='delete-branch-actions__continue'
                    onClick={handleCancel}
                />
                <Button
                    text={deleteText}
                    type='button'
                    classType='outline-primary'
                    otherClass='delete-branch-actions__delete'
                    onClick={handleContinue}
                    loading={loading}
                />
            </div>
        </>
    );
};

export default DeleteBranchModalActions;
