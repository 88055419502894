import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";
import "./KeyPhraseButton.scss";

const KeyPhraseButton = ({ openModal }) => {
    return (
        <div>
            <AddButton
                btnText='Add keyphrase'
                otherClassName='key__phrase__btn'
                onClick={openModal}
            />
        </div>
    );
};

KeyPhraseButton.prototypes = {
    openModal: PropTypes.func.isRequired,
};

export default KeyPhraseButton;
