import React from "react";

const TagsDiscoveredMessage = () => {
    return (
        <p className='tags-container__message'>
            Based on your recent search, these are the most helpful suggestions
            to resolve the issue discovered. Select your most preferred.
        </p>
    );
};

export default TagsDiscoveredMessage