import React from "react";
import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
import ItemsList from "components/ui/ItemsList/ItemsList";
import "./IssueEmbeds.scss";

const IssueEmbeds = ({ elements }) => {
    return (
        <>
            {elements?.length > 0 ? (
                <div className='issues-table-embed'>
                    <ItemsList breakPoint={8}>
                        {elements.map((element, key) => (
                            <CapsuleElement
                                capsule={element?.capsule}
                                capsuleEntryId={element?.capsuleEntryId}
                                key={key}
                                useCapsuleStyles={true}
                                hasBgColor={true}
                                hideDeleteAction={true}
                                hideEditAction={true}
                                capsuleBottomMenu={true}
                            />
                        ))}
                    </ItemsList>
                </div>
            ) : (
                <span>No embeds have been added yet.</span>
            )}
        </>
    );
};

export default IssueEmbeds;
