import { VARIANT_ACTIONS } from "../enum";
import VariantModal from "../VariantModal/VariantModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { CREATE, EDIT, DELETE } = VARIANT_ACTIONS;

const VariantActionModal = ({
    currentAction,
    closeModal,
    variant,
    selectedIssue,
    handleSuccess,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT:
            case CREATE:
                return (
                    <VariantModal
                        showSideModal={true}
                        closeModal={closeModal}
                        editItem={variant}
                        isEdit={currentAction === EDIT ? true : false}
                        selectedIssue={selectedIssue}
                        handleSuccess={handleSuccess}
                    />
                );

            case DELETE:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to remove ${variant?.name}`}
                        show={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.variant(variant?.variantId)}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default VariantActionModal;
