import { useState } from "react";
import { NOTIFICATION_CATEGORIES } from "../../enum";
import "./NotificationsTab.scss";

const NotificationsTab = ({ handleTabChange }) => {
    const tabs = ["All", "Knowledge Base", "Dependency Control"];
    const [activeTab, setActiveTab] = useState("All");

    const handleChange = (tab, tabPosition) => {
        let selectedTabKey = Object.keys(NOTIFICATION_CATEGORIES)[tabPosition];
        handleTabChange(NOTIFICATION_CATEGORIES[selectedTabKey]);
        setActiveTab(tab);
    };

    return (
        <>
            <div className='notification__tabs offset-1'>
                {tabs.map((tab, key) => (
                    <span
                        key={key}
                        className={`notification__tab ${
                            activeTab === tab ? `active` : ""
                        }`}
                        onClick={() => handleChange(tab, key)}>
                        <span>{tab}</span>
                    </span>
                ))}
            </div>
        </>
    );
};

export default NotificationsTab;
