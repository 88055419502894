import React from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./CustomDatePickerInput.scss";

const CustomDatePickerInput = React.forwardRef((props, ref) => {
    const { onClick } = props;

    return (
        <ReactSVG
            className='custom__date__picker__icon'
            src={asset?.icons?.calenderBlank}
            onClick={onClick}
            ref={ref}
        />
    );
});

export default CustomDatePickerInput;
