import React from "react";
import { Button, Input } from "components/ui";
import { useState } from "react";
import { signInstages } from "../SignInForm";
import API from "services/lib/api";
import { apiRoute } from "services";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";

const SignInEmailForm = ({
    setSignInStage,
    suggestedEmail = "",
    handleRequestUpdate,
    setUserWorkspaces,
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});
    const [request, updateRequest] = useState({
        email: suggestedEmail,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validateWorkspaceEmail = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            const { email } = request;
            const url = apiRoute?.getWorkspaces(email);
            const res = await API.get(url);
            if (res.status === 200) {
                setUserWorkspaces(res.data.data);
                handleRequestUpdate("email", email);
                setSignInStage(signInstages.secondary);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, formisValid } = ValidateForm(e, request);
        if (formisValid) {
            validateWorkspaceEmail();
        }
        setErrors(errors);
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <Input
                grpClass='mb-3'
                type='email'
                name='email'
                label='Email address'
                labelClass='text-dark fw-bold'
                id='email'
                placeholder='Enter your email address'
                value={request?.email}
                onChange={handleChange}
                isErr={errors?.email}
                errMssg={errors?.email}
            />
            <Button
                type='submit'
                text='Proceed'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default SignInEmailForm;
