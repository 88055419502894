import * as types from "./types";

const initialState = {
    defaultTheme: "",
};

const WorkspaceSettingsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_WORKSPACE_SETTINGS:
            const { defaultTheme } = payload || {};

            return {
                ...state,
                defaultTheme,
            };

        default:
            return state;
    }
};

export default WorkspaceSettingsReducer;
