import PropTypes from "prop-types";
import React from "react";
import CreateConversationForm from "../../../CreateConversation/form/CreateConversationForm/CreateConversationForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { SideModal } from "components/ui";
import { branchFormActions } from "../../../enums";
import { downtimeTypesData } from "../../../../DowntimeBranchesView/DowntimeBranchesList/data";
import EditConvoBreakerForm from "components/dashboard/ServiceModule/ChatSettings/ConversationBreakers/form/EditConvoBreakerForm/EditConvoBreakerForm";
import CreateGuideBranchForm from "components/dashboard/TrainSAM/Guides/form/CreateGuideBranchForm/CreateGuideBranchForm";

const {
    CREATE_PRIMARY_BRANCH,
    CREATE_GENERAL_BRANCH,
    CREATE_DOWNTIME_BRANCH,
    EDIT_BRANCH,
    EDIT_CONVERSATION_BREAKER,
    CREATE_GUIDE_BRANCH,
} = branchFormActions;

const CreateBranchModal = ({
    show,
    closeModal,
    isEdit,
    referenceData,
    source,
    modalFormAction,
    handleSuccess,
    isAgentGuideView,
    handleRefresh
}) => {
    const renderBasedOnActionType = () => {
        switch (modalFormAction) {
            case CREATE_PRIMARY_BRANCH:
            case CREATE_GENERAL_BRANCH:
            case CREATE_DOWNTIME_BRANCH:
            case EDIT_BRANCH:
                return (
                    <>
                        <ModalHeading
                            title={`${
                                modalFormAction === CREATE_PRIMARY_BRANCH
                                    ? `Create ${downtimeTypesData[source]} `
                                    : modalFormAction === CREATE_GENERAL_BRANCH
                                    ? `Create General ${downtimeTypesData[source]} `
                                    : !isEdit
                                    ? "Create Branch"
                                    : "Edit Branch"
                            }`}
                            subTitle={
                                isAgentGuideView
                                    ? ""
                                    : `A conversation is a collection of multiple branches`
                            }
                        />
                        {isAgentGuideView ? (
                            <CreateGuideBranchForm
                                {...{
                                    handleSuccess,
                                    closeModal,
                                    referenceData,
                                    isAgentGuideView,
                                    handleRefresh
                                }}
                                isEdit={modalFormAction === EDIT_BRANCH}
                            />
                        ) : (
                            <CreateConversationForm
                                isEdit={isEdit}
                                source={source}
                                handleSuccess={handleSuccess}
                                referenceData={referenceData}
                                modalFormAction={modalFormAction}
                            />
                        )}
                    </>
                );

            case CREATE_GUIDE_BRANCH:
                return (
                    <>
                        <ModalHeading title='Add a new branch' />
                        <EditConvoBreakerForm
                            handleSuccess={handleSuccess}
                            referenceData={referenceData}
                        />
                    </>
                );
            case EDIT_CONVERSATION_BREAKER:
                return (
                    <>
                        <ModalHeading title='Edit Conversation breaker' />
                        <EditConvoBreakerForm
                            handleSuccess={handleSuccess}
                            referenceData={referenceData}
                        />
                    </>
                );

            default:
                return "";
        }
    };
    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    {renderBasedOnActionType()}
                </div>
            </div>
        </SideModal>
    );
};

CreateBranchModal.propTypes = {
    closeModal: PropTypes.func,
    handleSuccess: PropTypes.func,
    isEdit: PropTypes.bool,
    modalFormAction: PropTypes.string,
    referenceData: PropTypes.any,
    show: PropTypes.bool,
};

export default CreateBranchModal;
