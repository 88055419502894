import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SideBarAccessControl from "../SideBarAccessControl/SideBarAccessControl";
import SidebarIcon from "../SidebarIcon/SidebarIcon";
import AccessControlNavItem from "./AccessControlNavItem/AccessControlNavItem";
import { connect } from "react-redux";
import { pickValidRoute, checkDropdownForPermission } from "../utils/helper";

const SidebarNavDropdown = ({
    id,
    collapseComplete,
    route,
    isActive,
    toggleOpenSidebar,
    permissions,
}) => {
    const [isOpen, toggleOpen] = useState(false);
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    useEffect(() => {
        if (collapseComplete === true) {
            toggleOpen(false);
        }
    }, [collapseComplete]);

    const handleOpen = () => {
        if (collapseComplete === true) {
            toggleOpenSidebar();
        } else {
            toggleOpen(!isOpen);
        }
    };

    return (
        <AccessControlNavItem
            dropdowns={route?.dropDowns}
            hasDropdown={route?.dropDowns?.length > 0}>
            <div
                className='sidebar__nav__item menu-item--container'
                onClick={handleOpen}>
                <div
                    className={`menu--item cursor-pointer ${isActive}`}
                    // to={route.path}
                    data-toggle='collapse'
                    data-target={`#collapse${id}`}
                    aria-expanded='false'
                    aria-controls={`collapse${id}`}>
                    <SidebarIcon iconName={route.iconName} />
                    {!collapseComplete && <span>{route.title}</span>}
                </div>

                <ul
                    className={`menu-item--children ${
                        isOpen ? "show" : "hide"
                    }`}
                    // className="sidebar__nav__dropdown list-unstyled collapse"
                    id={`collapse${id}`}
                    data-parent='#sidebar__nav'>
                    {route?.dropDowns?.map((dropdown, i) =>
                        dropdown.hide ? null : (
                            <SideBarAccessControl
                                key={i}
                                baseResources={dropdown?.baseResources}>
                                <NavLink
                                    activeClassName='active'
                                    className='d-flex align-items-center menu-item--child'
                                    to={
                                        route?.tabbedRoutes &&
                                        checkDropdownForPermission(
                                            dropdown?.title
                                        )
                                            ? pickValidRoute(
                                                  route?.tabbedRoutes,
                                                  permissionCodes
                                              )
                                            : dropdown.path
                                    }>
                                    {dropdown.title}
                                </NavLink>
                            </SideBarAccessControl>
                        )
                    )}
                </ul>
            </div>
        </AccessControlNavItem>
    );
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
    }),
    {}
)(SidebarNavDropdown);
