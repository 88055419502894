import { useContext, useEffect, useState } from "react";
import { Switch } from "components/ui";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_DEPENDENCY } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const CapsuleStatusSwitch = ({
    isEnabled,
    capsuleEntryId,
    capsuleClassId,
    getCapsules,handleRefresh
}) => {
    const toastMessage = useContext(ToastContext);
    const [capsuleStatus, setCapsuleStatus] = useState(false);

    const updateCapsuleStatus = async (status) => {
        try {
            setCapsuleStatus(status);
            const url = apiRoute?.updateCapsuleStatus(
                capsuleClassId,
                capsuleEntryId
            );
            const res = await API.patch(url);

            if (res.status === 200) {
                const { message } = res?.data;
                getCapsules();
                toastMessage(message);
                handleRefresh?.();
            }
        } catch (err) {
            setCapsuleStatus(!status);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const toggleSwitch = async (status) => {
        await updateCapsuleStatus(status);
    };

    useEffect(() => {
        setCapsuleStatus(isEnabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${KB_DEPENDENCY}`]}>
                <Switch
                    checked={capsuleStatus}
                    label={capsuleStatus ? "ON" : "OFF"}
                    id='capsuleStatus'
                    onChange={(e) => toggleSwitch(e.target.checked)}
                />
            </ComponentAccessControl>
        </>
    );
};

export default CapsuleStatusSwitch;
