import React from "react";
import SelectUI from "components/ui/SelectUI/SelectUI";

// AnalyticsFilterDuration
const durationOptions = [
    {
        label: "This Week",
        value: "THIS_WEEK",
    },
    {
        label: "Last Week",
        value: "LAST_WEEK",
    },
    {
        label: "A Month Ago",
        value: "LAST_MONTH",
    },
    {
        label: "Custom Date",
        value: "CUSTOM_DATE",
    },
];

const AnalyticsFilterDuration = ({ defaultValue, updateRequest }) => {
    return (
        <div className="analytics-filter__select-duration">
            <SelectUI
                // otherClassNames='analytics-filter__select-duration'
                placeholder='Period'
                options={durationOptions}
                value={durationOptions?.find((x) => x.value === defaultValue)}
                onChange={({ value }) =>
                    updateRequest("analyticsRequestDuration", value)
                }
                height='40px !important'
                marginClass='mb-0'
                noBorder={true}
            />
        </div>
    );
};

export default AnalyticsFilterDuration;
