import FacebookIntegration from "components/essentials/Integrations/FacebookIntegration/FacebookIntegration";
import GoogleIntegration from "components/essentials/Integrations/GoogleIntegration/GoogleIntegration";
import TwitterIntegration from "components/essentials/Integrations/TwitterIntegration/TwitterIntegration";

import { availableInboxApps } from "../../../../enum";

const InboxAppsButton = ({
    actionType,
    handleNewConnection,
    user,
    appSource,
    handleSuccess,
    appUserId,
    handleError,
    handleActionModal,
    isGmailWebHookConnected,
    ...rest
}) => {
    const renderAppButtonBasedOnAppName = () => {
        switch (appSource) {
            case availableInboxApps?.GMAIL:
                return (
                    <GoogleIntegration
                        handleNewConnection={handleNewConnection}
                        actionType={actionType}
                        user={user}
                        handleSuccess={handleSuccess}
                        handleError={handleError}
                        appUserId={appUserId}
                        handleActionModal={handleActionModal}
                        hasWebHook={isGmailWebHookConnected}
                        {...rest}
                    />
                );

            case availableInboxApps?.TWITTER:
                return (
                    <TwitterIntegration
                        handleNewConnection={handleNewConnection}
                        actionType={actionType}
                        user={user}
                        handleSuccess={handleSuccess}
                        appUserId={appUserId}
                        handleError={handleError}
                        {...rest}
                    />
                );

            case availableInboxApps?.FACEBOOK:
                return (
                    <FacebookIntegration
                        handleNewConnection={handleNewConnection}
                        actionType={actionType}
                        user={user}
                        handleSuccess={handleSuccess}
                        appUserId={appUserId}
                        handleError={handleError}
                        {...rest}
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderAppButtonBasedOnAppName()}</>;
};

export default InboxAppsButton;
