import asset from "assets/images";
import { ReactSVG } from "react-svg";

const TeamModalInstruction = () => {
    return (
        <div className='team-form__instruction'>
            <ReactSVG src={asset?.icons?.blueInfo} />

            <span>
                You need to create tags and invite members to your workspace
                first before creating a new team.
            </span>
        </div>
    );
};

export default TeamModalInstruction;
