import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import { REMINDERS_TABLE_ACTIONS } from "../enums";
import CreateReminderModal from "./CreateReminderModal/CreateReminderModal";

const { DELETE_REMINDER, EDIT_REMINDER, CREATE_REMINDER } =
    REMINDERS_TABLE_ACTIONS;

const ReminderModals = ({
    showModal,
    closeModal,
    actionType,
    selectedReminder,
    handleRefresh,
}) => {
    const renderBasedOnAction = () => {
        switch (actionType) {
            case DELETE_REMINDER:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${"reminder name"}`}
                        show={showModal}
                        closeModal={closeModal}
                        handleSuccess={handleRefresh}
                        url={apiRoute?.deleteFollowUpTask()}
                        method='delete'
                    />
                );
            case EDIT_REMINDER:
            case CREATE_REMINDER:
                return (
                    <CreateReminderModal
                        {...{
                            showModal,
                            closeModal,
                            selectedReminder,
                            handleRefresh,
                        }}
                        isEdit={actionType === EDIT_REMINDER}
                    />
                );

            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default ReminderModals;
