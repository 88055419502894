import CreateTemplateButton from "./CreateTemplateButton/CreateTemplateButton";
import { templateTypes } from "../enums";
import "./ConversationTemplateHeader.scss";

const ConversationTemplateHeader = ({
    createTemplateType,
    handleRefresh,
    isEdit,
    editTemplateData,
}) => {
    return (
        <div className='conversations__header'>
            <h1 className='conversations__header--text fw-bold'>
                {createTemplateType === templateTypes?.DEFAULT
                    ? "Basic Template"
                    : "Downtime Template"}
            </h1>

            <CreateTemplateButton
                {...{
                    handleRefresh,
                    createTemplateType,
                    isEdit,
                    editTemplateData,
                }}
            />
        </div>
    );
};

export default ConversationTemplateHeader;
