import React from "react";
import { Checkbox } from "components/ui";
import { convoBreakerActionBranchTypes } from "components/dashboard/ServiceModule/ChatSettings/ConversationBreakers/enums";
import "./ConvoBreakerCheckbox.scss";

const { AGENT_FOLLOWUP, FORM_FILLED_COMPLETELY, TICKET_CLOSED_ALERT } =
    convoBreakerActionBranchTypes;

const ConvoBreakerCheckbox = ({
    branchType,
    displayAverageResponseTime,
    requestRatings,
    disableCheckbox,
    onChange,
}) => {
    const renderBasedOnBranchType = () => {
        switch (branchType) {
            case AGENT_FOLLOWUP:
            case FORM_FILLED_COMPLETELY:
                return (
                    <div
                        className={`${
                            disableCheckbox ? "convo__breaker__checkbox" : ""
                        }`}>
                        <Checkbox
                            grpClass='mb-3'
                            name='displayAverageResponseTime'
                            id='displayAverageResponseTime'
                            label='Display accurate time'
                            labelClass='convo__breaker__checkbox__rule'
                            checked={displayAverageResponseTime}
                            inputClass='input-check-grey convo__breaker__checkbox__input'
                            onChange={onChange}
                        />
                    </div>
                );

            case TICKET_CLOSED_ALERT:
                return (
                    <div
                        className={`${
                            disableCheckbox ? "convo__breaker__checkbox" : ""
                        }`}>
                        <Checkbox
                            grpClass='mb-3'
                            name='requestRatings'
                            id='requestRatings'
                            label='Ratings included'
                            labelClass='convo__breaker__checkbox__rule'
                            checked={requestRatings}
                            inputClass='input-check-grey convo__breaker__checkbox__input'
                            onChange={onChange}
                        />
                    </div>
                );

            default:
                return "";
        }
    };

    return <>{renderBasedOnBranchType()}</>;
};

export default ConvoBreakerCheckbox;
