import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { CREATE_NEW_BRANCH, ADD_EXISTING_BRANCH } from "../enums";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setBranchesList } from "store/cache/branches/actions";

const BranchNameInput = ({
    handleBranchNameChange,
    isLoading,
    filterBranches,
    setLoadingBranchNames,
    errorMssg,
    autoFocus,
    name,
    label = "Collection Name",
}) => {
    const [branchValue, setBranchValue] = useState({});
    const [serverError, setServerError] = useState("");
    const [allBranches, setAllBranches] = useState([]);
    const [branchNameChangeValue, setBranchNameChangeValue] = useState("");

    const dispatch = useDispatch();

    const { branches } = useSelector((state) => state.branches);

    const filterBranchesList = () => {
        const allBranches = branches.map(
            ({ branchName, branchId, ...rest }) => ({
                label: branchName,
                value: branchId,
                branchName,
                branchId,
                ...rest,
            })
        );

        if (filterBranches) {
            const filterIds = filterBranches.map((x) => x.branchId);
            const filteredBranchList = allBranches.filter((branch) => {
                return !filterIds.includes(branch?.value);
            });
            return setAllBranches(filteredBranchList);
        }

        return setAllBranches(allBranches);
    };

    const retrieveBranchList = async () => {
        try {
            setServerError("");
            setLoadingBranchNames(true);
            const res = await API.get(apiRoute.getBranches);

            if (res.status === 200) {
                setLoadingBranchNames(false);
                const { data } = res.data; // []

                dispatch(setBranchesList(data));
            }
        } catch (err) {
            setLoadingBranchNames(false);
            setServerError(getErrorMessage(err));
        }
    };

    useEffect(() => {
        retrieveBranchList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (branches?.length > 0) {
            filterBranchesList();
        }
        // eslint-disable-next-line
    }, [branches]);

    return (
        <div>
            <SelectUI
                onChange={(value) => {
                    setBranchValue(value);
                    handleBranchNameChange(ADD_EXISTING_BRANCH, value?.value);
                    setBranchNameChangeValue(ADD_EXISTING_BRANCH);
                }}
                onCreateOption={(value) => {
                    setBranchValue({ value, label: value });
                    handleBranchNameChange(CREATE_NEW_BRANCH, value);
                    setBranchNameChangeValue(CREATE_NEW_BRANCH);
                }}
                label={label}
                name={name}
                options={allBranches}
                isLoading={isLoading}
                createAble={true}
                value={
                    branchNameChangeValue === ADD_EXISTING_BRANCH
                        ? allBranches?.find((branch) => {
                              return branch?.value === branchValue?.value;
                          })
                        : branchValue
                }
                error={errorMssg}
                serverError={serverError}
                handleRetry={() => retrieveBranchList()}
                autoFocus={autoFocus}
                darkLabel={true}
            />
        </div>
    );
};

export default BranchNameInput;
