import { useState } from "react";
import PropTypes from "prop-types";
import KeyPhraseButton from "./KeyPhraseButton/KeyPhraseButton";
import KeyPhraseModal from "./KeyPhraseModal/KeyPhraseModal";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./KeyPhraseHeader.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const KeyPhraseHeader = ({ selectedMssgTagId, handleSuccess }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className='key__Phrase__header'>
                <h1 className='key__Phrase__header--text'>
                    Key words & phrases
                </h1>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <KeyPhraseButton openModal={() => setShowModal(true)} />
                </ComponentAccessControl>{" "}
            </div>
            {showModal && (
                <KeyPhraseModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    selectedMssgTagId={selectedMssgTagId}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

KeyPhraseHeader.prototypes = {
    selectedMssgTagId: PropTypes.string.isRequired,
    handleSuccess: PropTypes.func.isRequired,
};

export default KeyPhraseHeader;
