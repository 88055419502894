import React, { useState, useContext } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import SelectUserRole from "../../../../common/SelectUserRole/SelectUserRole";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import "./UpdateTeamMemberRoleForm.scss";

const UpdateTeamMemberRoleForm = ({ member, handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});
    const [roleCode, updateRoleCode] = useState({
        value: member?.roleCode,
    });
    const [errorMssg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);
    const [loadingRoles, setLoadingRoles] = useState(false);

    const handleRoleCode = (name, value) => {
        updateRoleCode(value);
        setFormError({ [name]: "" });
    };

    const inviteMember = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const { userId } = member;

            const url = apiRoute.updateUserRole(userId, roleCode);
            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, {
            roleCode,
        });
        formisValid ? inviteMember() : setFormError(formErrors);
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <SelectUserRole
                name='roleHey'
                error={formError?.roleCode}
                selectedValue={roleCode}
                defaultInputValue={roleCode}
                handleSelect={handleRoleCode}
                autoFocus={true}
                setLoadingRoles={setLoadingRoles}
            />
            <div className='update__user__role__btn'>
                <Button
                    type='submit'
                    classType='primary'
                    loading={loading}
                    disabled={loadingRoles}
                    otherClass='w-100'
                    text='Save Changes'
                />
            </div>
        </form>
    );
};

export default UpdateTeamMemberRoleForm;
