import { getHrsDiff } from "utils/helper";

export const getFormattedStatusMapData = (statusMapData = []) => {
    let totalHrsOfUptimes = 0;
    let totalHrsOfDowntimes = 0;

    if (statusMapData.length) {
        let startDate = statusMapData[0].date;
        let newStatusMapData = statusMapData.map((data) => {
            let diff = getHrsDiff(startDate, data.date);
            data.status
                ? (totalHrsOfUptimes = +diff.hrsDiff)
                : (totalHrsOfDowntimes = +diff.hrsDiff);
            let result = {
                ...diff,
                status: data.status,
                show: false,
                date: data.date,
            };
            return result;
        });
        return {
            totalHrsOfDowntimes,
            totalHrsOfUptimes,
            data: newStatusMapData,
        };
    } else {
        return { totalHrsOfDowntimes, totalHrsOfUptimes, data: [] };
    }
};

export const calculateBarWidth = (hrsDiff = 0, isWeekly = false) => {
    return isWeekly
        ? Number.parseInt(hrsDiff) === 0
            ? "0.1em"
            : `${hrsDiff / 8}em`
        : Number.parseInt(hrsDiff) === 0
        ? "1em"
        : `${hrsDiff / 2}em`;
};

export const resolveStatus = (status) => {
    return status ? "uptime" : "downtime";
};

export const calculateStatusPercentage = (
    status = false,
    totalHrsOfUptimes = 0,
    totalHrsOfDowntimes = 0,
    hrsDiff = 0
) => {
    let x = (
        (hrsDiff * 100) /
        (status ? totalHrsOfUptimes : totalHrsOfDowntimes)
    ).toFixed(2);

    return status ? `+${x}%` : `-${x}%`;
};
