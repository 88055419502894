import React from "react";
import { localeDate, truncateString } from "utils/helper";
import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";
import "./ExternalEmail.scss";


const { PREVIEW_EMAIL } = EXTERNAL_EMAIL_ACTION;

const ExternalEmail = ({ email, handleSelectEmail, isNew, isActive }) => {
    const {
        message,
        senderEmail,
        subject,
        emailType,
        createdDate,
    } = email || {};

    return (
        <div className={`external__email ${isActive ? "active" : ""}`}>
               <div className='external__email__metadata'>
                    <p>
                        { emailType === "RECEIVED" ? `Received From` : `Sent by` } <span className='sender'>{senderEmail}</span> on{" "}
                        {localeDate(createdDate)}
                    </p>
                </div>
            <div
                className='external__email--content__container'
                onClick={() => handleSelectEmail(email, PREVIEW_EMAIL)}>
                {/* {isNew && (
                    <div className='external__email__new--indicator'>New</div>
                )} */}
                <h4 className='external__email--subject'>
                    {subject}
                </h4>
                <p className='external__email--content'>
                    {truncateString(message, 100)}
                </p>
            </div>
{/* 
            <div className='external__email__actions'>
             

                <EmailActionDropdown 
                    onClick={() =>
                        handleSelectEmail(email, REPLY_EMAIL)
                    }
                />
                
            </div> */}
        </div>
    );
};

export default ExternalEmail;
