export const ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION = [
    {
        label: "This Week",
        value: "THIS_WEEK",
    },
    {
        label: "Last Week",
        value: "LAST_WEEK",
    },
    {
        label: "A Month Ago",
        value: "LAST_MONTH",
    },
];
