import { CANNED_RESOURCE_ACTIONS } from "components/dashboard/TrainSAM/CannedResponses/enum";
import DeleteCannedModal from "../DeleteCannedModal/DeleteCannedModal";
import NewCannedModal from "../NewCannedModal/NewCannedModal";

const { CREATE_CANNED_RESOURCE, EDIT_CANNED_RESOURCE, DELETE_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const CannedResourceModal = ({
    title,
    showModal,
    closeModal,
    cannedResourceType,
    selectedCannedResource,
    isEdit,
    modalAction,
    toggleModal,
    handleSuccess,
    ...rest
}) => {
    const renderBasedOnModalAction = () => {
        switch (modalAction) {
            case CREATE_CANNED_RESOURCE:
            case EDIT_CANNED_RESOURCE:
                return (
                    <NewCannedModal
                        title={title}
                        showModal={showModal}
                        closeModal={() => toggleModal(false)}
                        cannedResourceType={cannedResourceType}
                        selectedCannedResource={selectedCannedResource}
                        isEdit={modalAction === EDIT_CANNED_RESOURCE}
                        handleSuccess={handleSuccess}
                        {...rest}
                    />
                );

            case DELETE_CANNED_RESOURCE:
                return (
                    <DeleteCannedModal
                        {...{ closeModal, showModal, handleSuccess }}
                        cannedResourceId={
                            selectedCannedResource?.cannedResourceId
                        }
                    />
                );

            default:
                return "";
        }
    };

    return <>{renderBasedOnModalAction()}</>;
};

export default CannedResourceModal;
