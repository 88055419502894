import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { truncateString } from "utils/helper";
import "./EmailFileAttachment.scss";

const EmailFileAttachment = ({
    fileAttachment,
    removeFileAttachment,
    disabled,
}) => {
    const { filename } = fileAttachment || {};

    return (
        <div className='ticketinfo-clipboard__file me-1'>
            <div className='attached__file__meta__data'>
                <ReactSVG
                    src={imageLinks?.icons?.document}
                    className='document'
                />{" "}
                <span className='attached__file__name'>
                    {truncateString(filename, 30)}
                </span>
            </div>
            {/* {!disabled && ( */}
            <ReactSVG
                src={imageLinks?.icons?.cancelX}
                className='remove__attached__file ps-1'
                onClick={() => removeFileAttachment()}
            />
            {/* )} */}
        </div>
    );
};

export default EmailFileAttachment;
