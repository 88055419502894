export const CONVERSATION_STATUSES = [
    {
        label: "Draft",
        value: "DRAFT",
    },
    {
        label: "Published",
        value: "PUBLISHED",
    },
];
