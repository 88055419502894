import { Button } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import NewScenarioInput from "./NewScenarioInput/NewScenarioInput";
// import AddButton from "components/ui/Button/AddButton/AddButton";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
// import ValidateForm from "utils/validations/Validator";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ExistingScenarios from "./ExistingScenarios/ExistingScenarios";
import AddedScenarios from "./AddedScenarios/AddedScenarios";

const CreateBranceScenarioForm = ({
    handleSuccess,
    closeModal,
    isEdit,
    selectedScenario,
    guideUpdateLoading,
    hasExternalCall,
    existingScenarios,
    existingCollections,
    hideExistingScenarios,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [addedScenarios, setAddedScenarios] = useState(
        existingScenarios || []
    );
    const [addedCollections, setAddedCollections] = useState(
        existingCollections || []
    );
    const [scenarios, setScenarios] = useState([]);
    const [branchCollections, setCollections] = useState([]);


    const toastMessage = useContext(ToastContext);

    const [request, setRequest] = useState({
        guideScenairoName: "",
        guideScenairoMessage: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleAddScenario = (item) => {
        if (item.isCollection) {
            setAddedCollections((prev) => [...prev, item]);
            setCollections((prev) =>
                prev?.filter(
                    ({ branchCollectionId }) =>
                        branchCollectionId !== item?.branchCollectionId
                )
            );
        } else {
            setAddedScenarios((prev) => [...prev, item]);
            setScenarios((prev) =>
                prev?.filter(
                    ({ guideScenairoId }) =>
                        guideScenairoId !== item?.guideScenairoId
                )
            );
        }
    };

    const handleRemoveScenario = (item) => {
        if (item.isCollection) {
            setAddedCollections((prev) =>
                prev?.filter(
                    ({ branchCollectionId }) =>
                        branchCollectionId !== item?.branchCollectionId
                )
            );
            setCollections((prev) => [...prev, item]);

        } else {
            setAddedScenarios((prev) =>
                prev?.filter(
                    ({ guideScenairoId }) =>
                        guideScenairoId !== item?.guideScenairoId
                )
            );
            setScenarios((prev) => [...prev, item]);
        }

    };

    const createScenario = async () => {
        try {
            if (request?.guideScenairoName !== "" && request?.guideScenairoMessage !== "") {
                setErrorMssg();
                setLoading(true);

                const url = apiRoute.createScenario;
                const res = await API.post(url, request);

                if (res.status === 201) {
                    const { message, data } = res.data;
                    handleSuccess?.(data?.guideScenairoId, addedScenarios, addedCollections);
                    if (!hasExternalCall) {
                        toastMessage(message);
                    }
                }
            } else {
                handleSuccess?.(true, addedScenarios, addedCollections);
            }

        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editScenario = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.updateScenario(
                selectedScenario?.guideScenairoId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        isEdit ? editScenario() : createScenario();
    };

    useEffect(() => {
        if (isEdit && selectedScenario) {
            setRequest(selectedScenario);
        }
        // eslint-disable-next-line
    }, [selectedScenario, isEdit]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            {!isEdit && !hideExistingScenarios && (
                <ExistingScenarios
                    existingScenarios={existingScenarios}
                    handleSelect={handleAddScenario}
                    {
                    ...{
                        addedScenarios,
                        addedCollections,
                        scenarios,
                        setScenarios,
                        branchCollections,
                        setCollections
                    }
                    }
                />
            )}
            <NewScenarioInput
                handleChange={handleChange}
                errors={errors}
                request={request}
            />
            {!isEdit && !hideExistingScenarios && (
                <>
                    {/* <AddButton
                        hasIcon={false}
                        btnText={"Add"}
                        // onClick={() => toggleModal(!showModal)}
                    /> */}
                    <AddedScenarios
                        handleRemove={handleRemoveScenario}
                        addedScenarios={addedScenarios}
                        addedCollections={addedCollections}
                    />
                </>
            )}

            <Button
                type='submit'
                text={isEdit ? "Update scenario" : "Add scenario"}
                classType='primary'
                loading={guideUpdateLoading || loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateBranceScenarioForm;
