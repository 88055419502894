import React, { useState } from "react";
import { Table } from "components/ui";
import { useHistory } from "react-router-dom";
import TeamMembers from "./TeamMembers/TeamMembers";
import TeamDropdown from "./TeamDropdown/TeamDropdown";
import { TEAM_HEADERS, TEAM_ACTIONS } from "./enums";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { VIEW_TEAM, DELETE_TEAM } = TEAM_ACTIONS;

const TeamTable = ({
    teams,
    tableIsLoading,
    hasErrors,
    error,
    handleSuccess,
}) => {
    const [selectedTeam, setSelectedTeam] = useState({});
    const [openDeleteModal, toggleDeleteModal] = useState(false);
    const history = useHistory();

    const handleRedirect = (team) => {
        history.push({
            pathname: `/agents-inbox/settings/teams/${team?.teamId}`,
            state: {
                teamId: team?.teamId,
            },
        });
    };

    const handleDeleteTeam = (team) => {
        setSelectedTeam(team);
        toggleDeleteModal(true);
    };

    const handleActionModal = (action, team) => {
        switch (action) {
            case VIEW_TEAM:
                handleRedirect(team);
                break;
            case DELETE_TEAM:
                handleDeleteTeam(team);
                break;
            default:
                break;
        }
    };

    const tableData = teams?.map((team) => ({
        teamName: team.teamName,
        teamMembers: <TeamMembers teamMembers={team.teamMembers} />,
        action: <TeamDropdown {...{ team, handleActionModal }} />,
    }));

    return (
        <>
            <Table
                headers={TEAM_HEADERS}
                dataSource={tableData}
                hasErrors={hasErrors}
                isLoading={tableIsLoading}
                tableError={error}
            />

            {openDeleteModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete ${selectedTeam?.teamName}`}
                    show={openDeleteModal}
                    closeModal={() => toggleDeleteModal(false)}
                    handleSuccess={handleSuccess}
                    url={apiRoute?.team(selectedTeam?.teamId)}
                    method='delete'
                />
            )}
        </>
    );
};

export default TeamTable;
