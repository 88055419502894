import PropTypes from "prop-types"
import ContentEditable from "components/ui/ContentEditable/ContentEditable";
import { convertTemplateLiteralToHtml } from "utils/helper";

const ConversationTemplateFormIntroField = ({
    introText,
    handleChange,
    error
}) => {

    const convoHtml = convertTemplateLiteralToHtml(introText)

    return (
        <>
            <ContentEditable label="Greeting" html={convoHtml} onChange={handleChange} error={error} />
        </>
    );
};

ConversationTemplateFormIntroField.propTypes = {
  error: PropTypes.any,
  handleChange: PropTypes.any,
  introText: PropTypes.any
}

export default ConversationTemplateFormIntroField;
