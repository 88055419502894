import { useContext, useState } from "react";
import "../BranchViewEmbeds/BranchViewEmbeds.scss";
import { Button, Tag } from "components/ui";

import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { SCENARIO_ACTIONS } from "components/dashboard/TrainSAM/Scenarios/enum";
import BranchScenarioModal from "./modal/BranchScenarioModal/BranchScenarioModal";

const { CREATE_SCENARIO, EDIT_SCENARIO, DELETE_SCENARIO, DETACH_SCENARIO_COLLECTION} = SCENARIO_ACTIONS;

const BranchViewScenarios = ({ scenarios = [], collections = [], guideFlowId, handleSuccess }) => {
    const [showModal, toggleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState();
    const [modalAction, setModalAction] = useState();
    const [selectedScenario, selectScenario] = useState();

    const toastMessage = useContext(ToastContext);

    const handleActionModal = (actionType, scenario) => {
        setModalAction(actionType);
        selectScenario(scenario);
        toggleModal(true);
    };

    // const getExistingScenarioIds =
    //     scenarios?.map?.(({ guideScenairoId }) => guideScenairoId) || [];

    const updateGuideScenarios = async (newScenarioId, addedScenarios, addedCollections) => {
        try {
            setErrorMssg();
            setLoading(true);
            const retrievedAddedScenarioIds =
                addedScenarios?.map?.(
                    ({ guideScenairoId }) => guideScenairoId
                ) || [];
            const retrievedAddedCollectionIds = addedCollections?.map(({ branchCollectionId }) => branchCollectionId) || []
            const url = apiRoute.updateGuideScenario(guideFlowId);
            const res = await API.put(url, {
                scenairosIds: [newScenarioId, ...retrievedAddedScenarioIds],
                branchCollectionIds: retrievedAddedCollectionIds
            });

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                toggleModal(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    return (
        <>
            <div className='branch__content branch-embeds'>
                <div className='branch-embeds__heading'>
                    <h6>Scenarios</h6>
                    <Button
                        text='Add scenario'
                        classType='plain'
                        onClick={() => handleActionModal(CREATE_SCENARIO)}
                        otherClassName={"branch__view--heading__button"}
                    />
                </div>
                <div className='d-flex flex-wrap align-items-center flex-gap mt-2'>
                    {[...scenarios, ...collections]?.length > 0 ? (
                        <>
                            {collections?.map?.(
                                ({ branchCollectionName, branchCollectionId, ...rest }, i) => (
                                    <Tag
                                        name={branchCollectionName}
                                        key={i}
                                        popContent={
                                            <div>
                                                <p
                                                    onClick={() =>
                                                        window.location.href(
                                                            `/t-sam/branch-collection/${branchCollectionId}/view`
                                                        )
                                                    }>
                                                    View
                                                </p>
                                                <p
                                                    onClick={() =>
                                                        handleActionModal(
                                                            DETACH_SCENARIO_COLLECTION,
                                                            {
                                                                guideFlowId,
                                                                branchCollectionName,
                                                                branchCollectionId,
                                                                ...rest,
                                                            }
                                                        )
                                                    }>
                                                    Remove
                                                </p>
                                            </div>
                                        }
                                    />
                                )
                            )}
                            {scenarios?.map?.(
                                ({ guideScenairoName, ...rest }, i) => (
                                    <Tag
                                        name={guideScenairoName}
                                        key={i}
                                        popContent={
                                            <div>
                                                <p
                                                    onClick={() =>
                                                        handleActionModal(
                                                            EDIT_SCENARIO,
                                                            {
                                                                guideScenairoName,
                                                                ...rest,
                                                            }
                                                        )
                                                    }>
                                                    Edit
                                                </p>
                                                <p
                                                    onClick={() =>
                                                        handleActionModal(
                                                            DELETE_SCENARIO,
                                                            {
                                                                guideScenairoName,
                                                                ...rest,
                                                            }
                                                        )
                                                    }>
                                                    Delete
                                                </p>
                                            </div>
                                        }
                                    />
                                )
                            )}
                        </>
                    ) : (
                        <p>No scenarios added yet</p>
                    )}
                </div>
            </div>
            {showModal && (
                <BranchScenarioModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    guideFlowId={guideFlowId}
                    handleSuccess={(newScenarioId, addedScenarios, addedCollections) =>
                        newScenarioId
                            ? updateGuideScenarios(
                                newScenarioId,
                                addedScenarios,
                                addedCollections
                            )
                            : handleSuccess()
                    }
                    hasExternalCall
                    guideUpdateLoading={loading}
                    errorMssg={errorMssg}
                    modalAction={modalAction}
                    selectedScenario={selectedScenario}
                    setErrorMssg={setErrorMssg}
                    existingScenarios={scenarios}
                    existingCollections={collections}
                />
            )}
        </>
    );
};

export default BranchViewScenarios;
