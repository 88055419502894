import React from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import FormBuilderInputOption from "./FormBuilderInputOption/FormBuilderInputOption";
import "./FormBuilderInputOptions.scss";

const FormBuilderInputOptions = ({
    elementId,
    elementOptions,
    handleOptionAdd,
    handleOptionTextChange,
    handleOptionDelete,
    errors,
}) => {
    return (
        <div className='form__builder__options__group'>
            <div className='form__builder__options'>
                {elementOptions.map(({ id, text }, index) => {
                    return (
                        <FormBuilderInputOption
                            key={index}
                            elementId={elementId}
                            optionId={id}
                            badgeText={`Option ${index + 1}`}
                            optionValue={text}
                            handleOptionTextChange={handleOptionTextChange}
                            handleOptionDelete={handleOptionDelete}
                            hasError={errors?.[
                                elementId
                            ]?.elementOptions?.includes(id)}
                        />
                    );
                })}
            </div>
            <AddButton
                btnText={"Add Option"}
                onClick={() => handleOptionAdd(elementId)}
            />
        </div>
    );
};

export default FormBuilderInputOptions;
