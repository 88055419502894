import React from "react";
import UploadingEmailAttachment from "./UploadingEmailAttachment/UploadingEmailAttachment";
import "./UploadingEmailAttachmentList.scss";

const UploadingEmailAttachmentList = ({
    files,
    setFiles,
    setUploadComplete,
    handleRemoveFile,
}) => {
    return (
        <div className='uploading__email__attachment--list custom-scroll-bar'>
            {files &&
                files?.map((file, index) => (
                    <UploadingEmailAttachment
                        key={index}
                        file={file}
                        files={files}
                        setFiles={setFiles}
                        setUploadComplete={setUploadComplete}
                        handleRemoveFile={handleRemoveFile}
                    />
                ))}
        </div>
    );
};

export default UploadingEmailAttachmentList;
