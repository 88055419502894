import { Empty, Button } from "components/ui";
import "./EmptyClassificationsList.scss";

const EmptyClassificationsList = () => {
    return (
        <>
            <div className='empty__classification__list'>
                <Button
                    type='button'
                    text='Classifications would appear here'
                    classType='outline-secondary'
                    otherClass='btn-xs fw-medium text-dark'
                    disabled
                />
            </div>
            <div className='empty__classification__list--box'>
                <Empty className='h-50' />
            </div>
        </>
    );
};

export default EmptyClassificationsList;
