import { useState } from "react";
import PropTypes from "prop-types";
import ClassificationModal from "./ClassificationsModal/ClassificationsModal";
import ClassificationButton from "./ClassificationButton/ClassificationButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ClassificationsHeader.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const ClassificationsHeader = ({ count, handleSuccess, prevAssigned }) => {
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => setShowModal(false);

    return (
        <>
            <div className='classification__header'>
                <h1 className='classification__header--text'>
                    Classifications{" "}
                    <span className='custom-badge custom-badge--light'>
                        {count}
                    </span>
                </h1>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <ClassificationButton setShowModal={setShowModal} />
                </ComponentAccessControl>{" "}
            </div>
            {showModal && (
                <ClassificationModal
                    showModal={showModal}
                    closeModal={closeModal}
                    handleSuccess={handleSuccess}
                    prevAssigned={prevAssigned}
                />
            )}
        </>
    );
};

ClassificationsHeader.prototypes = {
    count: PropTypes.number.isRequired,
    capsuleClasses: PropTypes.array.isRequired,
};

export default ClassificationsHeader;
