import React from "react";
import CapsuleClassesList from "./CapsuleClassesList/CapsuleClassesList";
import CapsuleClassesHeader from "./CapsuleClassesHeader/CapsuleClassesHeader";

const CapsuleClasses = ({
    data,
    selectedClassId,
    selectCapsuleClass,
    handleCapsulationSuccess,
    hideAddClassBtn
}) => {
    return (
        <>
            <div className=''>
                <CapsuleClassesHeader
                    numberOfClasses={data.length}
                    handleCapsulationSuccess={handleCapsulationSuccess}
                    hideAddClassBtn={hideAddClassBtn}
                />
            </div>
            <CapsuleClassesList
                data={data}
                selected={selectedClassId}
                handleClick={selectCapsuleClass}
            />
        </>
    );
};

export default CapsuleClasses;
