import { NOTIFICATION_CATEGORIES } from "components/ui/Notifications/enum";
import config from "config/config";

import { onSnapshot } from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";
import { retrieveDeviceToken } from "storage/localStorage";
import { firebaseMessaging } from "./config/config";
import { filteredUnReadMessages } from "./helper/helper";
import { qAll, qPaginate } from "./queries/queries";

export const getDevicePushToken = () => {
    let retrievedDeviceToken = retrieveDeviceToken();
    if (retrievedDeviceToken) {
        return retrievedDeviceToken;
    }
    return getToken(firebaseMessaging, {
        vapidKey: config.firebase.VAPIDKEY,
    })
        .then((currentToken) => {
            if (currentToken) {
                return currentToken;
            } else {
                return null;
            }
        })
        .catch((err) => {
            return null;
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(firebaseMessaging, (payload) => {
            resolve(payload);
        });
    });

export const getQuery = (workspaceId, category, filters) => {
    const { lastMessageId, size } = filters;
    /* eslint no-eval: 0 */
    let categories = Object.values(NOTIFICATION_CATEGORIES);

    if (category !== NOTIFICATION_CATEGORIES.ALL) {
        if (lastMessageId?.length > 0) {
            return eval(
                qPaginate(workspaceId, category, lastMessageId, "==", size)
            );
        } else {
            return eval(qAll(workspaceId, category, "==", size));
        }
    } else {
        if (lastMessageId?.length > 0) {
            return eval(
                qPaginate(workspaceId, categories, lastMessageId, "in", size)
            );
        } else {
            return eval(qAll(workspaceId, categories, "in", size));
        }
    }
};

export const getTotalNotificationsCount = (
    workspaceId,
    selectedCategory,
    updateTotalCount,
    updateTotalUnreadMessagesCount,
    workspaceUserId
) => {
    if (workspaceId) {
        try {
            onSnapshot(
                getQuery(workspaceId, selectedCategory, {
                    size: 5000,
                }),
                (querySnapshot) => {
                    let unreadMessages = filteredUnReadMessages(
                        querySnapshot.docs,
                        workspaceUserId
                    );
                    updateTotalUnreadMessagesCount?.(unreadMessages?.length);
                    updateTotalCount?.(querySnapshot.size);
                },
                (error) => {}
            );
        } catch (e) {}
    }
};

export const getRealTimeNotifications = (
    workspaceId,
    updateMessagesList,
    selectedCategory,
    setLoading,
    lastMessageId,
    unsubscribe
) => {
    if (workspaceId) {
        unsubscribe.current = onSnapshot(
            getQuery(workspaceId, selectedCategory, {
                lastMessageId,
                size: 5,
            }),
            (querySnapshot) => {
                updateMessagesList(querySnapshot.docs);
                setLoading(false);
            },
            (error) => {
                updateMessagesList([]);
            }
        );
    }
};
