import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import BranchImageCrop from "components/dashboard/Branches/BranchImageCrop/BranchImageCrop";
import { branchImageActions } from "components/dashboard/Branches/BranchImageCrop/enums";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { apiRoute } from "services";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import "./BranchImageAttachment.scss";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { UPDATE, READ } = baseResourceActions;

const { VIEW_IMAGE, ATTACH_IMAGE } = branchImageActions;

const BranchImageAttachment = ({ branch, handleRefresh }) => {
    const { branchMainSentenceImgUrl, branchId } = branch || {};
    const [showModal, toggleModal] = useState(false);
    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [branchImageAction, setBranchImageAction] = useState();

    const handleToggleModal = () => {
        toggleModal(!showModal);
    };

    const handleOpenDeleteModal = () => {
        handleToggleModal();
        toggleDeleteModal(true);
    };

    const handleBranchAction = () => {
        setBranchImageAction(
            branchMainSentenceImgUrl ? VIEW_IMAGE : ATTACH_IMAGE
        );
    };

    return (
        <>
            <div
                className='branch__image__attached'
                onClick={handleToggleModal}>
                <ReactSVG
                    src={asset.icons.indicator}
                    className='branch__image__attached--icon'
                />
                <ComponentAccessControl
                    baseResources={[
                        `${UPDATE}_${KB_SAM_BRANCH}`,
                        `${UPDATE}_${KB_SAM_COLLECTION}`,
                        `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                        `${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                        `${READ}_${KB_SAM_BRANCH}`,
                        `${READ}_${KB_SAM_COLLECTION}`,
                        `${READ}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                        `${READ}_${KB_SAM_ISSUE_CONVERSATION}`,
                    ]}>
                    <span
                        className='branch__image__attached--text'
                        onClick={handleBranchAction}>
                        {branchMainSentenceImgUrl
                            ? `View image`
                            : `Attach an image.`}{" "}
                    </span>
                </ComponentAccessControl>
            </div>

            {showModal && (
                <BranchImageCrop
                    showModal={showModal}
                    toggleModal={handleToggleModal}
                    branchImageAction={branchImageAction}
                    referenceData={branch}
                    handleRefresh={handleRefresh}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                />
            )}

            {showDeleteModal && (
                <ConfirmationModal
                    title='Are you sure you want to Delete this branch image'
                    show={showDeleteModal}
                    closeModal={() => toggleDeleteModal(false)}
                    handleSuccess={() => handleRefresh(branch?.branchId)}
                    url={apiRoute.deleteBranchImage(branchId)}
                    method='delete'
                />
            )}
        </>
    );
};

export default BranchImageAttachment;
