import React from "react";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ClassesPlaygroundTable from "./ClassesPlaygroundTable/ClassesPlaygroundTable";
import { dataQueryStatus } from "utils";
import CapsulesPlayGroundSkeleton from "./CapsulesPlayGroundSkeleton/CapsulesPlayGroundSkeleton";
import Filter from "components/dashboard/common/Filter/Filter";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const ProductClassesPlayground = ({
    playGroundStatus,
    fetchError,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    toggleSubCapsuleModal,
    selectedProduct,
    allCapsulesEntries,
    handleToggleEditModal,
    handleToggleDeleteModal,
    totalRecords,
    filters,
    updatePageFilters,
    toggleParentCapsuleModal,
    handleSearchEntries,
}) => {
    const renderBasedOnStage = () => {
        switch (playGroundStatus) {
            case IDLE:
                return "";

            case LOADING:
                return <CapsulesPlayGroundSkeleton />;

            case ERROR:
                return <ErrorView message={fetchError} />;

            case NULLMODE:
                return <div className='text-center p-5'>No Entries found</div>;

            case DATAMODE:
                return (
                    <>
                        <ClassesPlaygroundTable
                            key={playGroundStatus}
                            data={allCapsulesEntries}
                            selectedCapsuleClass={selectedCapsuleClass}
                            selectedSecCapsuleClass={selectedSecCapsuleClass}
                            selectedProduct={selectedProduct}
                            toggleSubCapsuleModal={toggleSubCapsuleModal}
                            handleToggleEditModal={handleToggleEditModal}
                            handleToggleDeleteModal={handleToggleDeleteModal}
                            totalRecords={totalRecords}
                            filters={filters}
                            updatePageFilters={updatePageFilters}
                            toggleParentCapsuleModal={toggleParentCapsuleModal}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    const handleSortByAlpha = (value) => {
        updatePageFilters({
            ...filters,
            alphabeticalOrder: value,
            dateCreatedOrder: null,
        });
    };

    const handleSortByDate = (value) => {
        updatePageFilters({
            ...filters,
            dateCreatedOrder: value,
            alphabeticalOrder: null,
        });
    };

    return (
        <>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                hideClassFilter={true}
                handleSearch={handleSearchEntries}
                searchPlaceholder={"Search entries here"}
            />
            {renderBasedOnStage()}
        </>
    );
};

export default ProductClassesPlayground;
