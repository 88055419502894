import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import {
    getErrorMessage,
    merge2ObjectArraysUniquely,
    sortFirstAndOthers,
} from "utils/helper";
import BranchesTimeLineHeader from "../../../BranchCollectionView/BranchesTimeLine/BranchesTimeLineHeader/BranchesTimeLineHeader";
import BranchesList from "../../branches/BranchesList/BranchesList";
import { ADD_BRANCH_TO_CONVERSATION } from "../../enums";
import { setExistingBranchOptionConnections } from "components/dashboard/Branches/BranchView/utils/helper";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const ConversationsBranches = ({
    conversationId,
    mainBranchId,
    handleRefreshConvoMap,
    lastToggledBranchId,
    conversationBranches,
    setConversationBranches,
    convoMapBranches,
    branchEmbeds
}) => {
    const [selectedBranch, setSelectedBranch] = useState({});
    const [status, setStatus] = useState("");
    const [branchViewStatus, setBranchViewStatus] = useState(IDLE);

    const [errorMssg, setErrorMssg] = useState("");
    const [bvErrorMssg, setBVErrorMssg] = useState("");
    const [existingOptionConnections, setExistingOptionConnections] = useState(
        {}
    );

    const handleError = (err) => {
        setErrorMssg(getErrorMessage(err));
        setStatus(ERROR);
    };

    const getConversationBranches = async (branchId) => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getConversationBranches(conversationId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    setConversationBranches(data);
                    handleBranchSelect(branchId ? branchId : mainBranchId);
                    mergeConvoBranches(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleBranchSelect = async (branchId) => {
        try {
            setSelectedBranch({ branchId });
            setBranchViewStatus(LOADING);
            const url = apiRoute?.getBranch(branchId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                // --- update the modified particular branch in the list
                // setConversationBranches((prevBranches) =>
                //     prevBranches?.map((branch) => {
                //         return data ? branch?.branchId === branchId : branch;
                //     })
                // );

                setSelectedBranch(data);
                setBranchViewStatus(DATAMODE);
                setExistingBranchOptionConnections(
                    data,
                    setExistingOptionConnections
                );
            }
        } catch (err) {
            setBVErrorMssg(getErrorMessage(err));
            setBranchViewStatus(ERROR);
        }
    };

    const handleRefresh = (branchId) => {
        getConversationBranches(branchId);
    };

    const handleSuccess = (branchId) => {
        if (branchId) {
            handleBranchSelect(branchId);
        } else {
            getConversationBranches();
        }
        handleRefreshConvoMap(branchId);
    };

    useEffect(() => {
        getConversationBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainBranchId]);

    const mergeConvoBranches = (data) => {
        const mergedBranches = merge2ObjectArraysUniquely(
            convoMapBranches,
            data ? data : conversationBranches,
            "branchId"
        );

        const sortedMergedBranches = sortFirstAndOthers(
            "branchId",
            mainBranchId,
            mergedBranches
        );

        setConversationBranches(sortedMergedBranches);
    };

    useEffect(() => {
        mergeConvoBranches();
        // eslint-disable-next-line
    }, [convoMapBranches]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return <Empty text='No Branches' />;

            case DATAMODE:
                return (
                    <div className='mt-3'>
                        <BranchesList
                            branches={conversationBranches}
                            handleBranchSelect={handleBranchSelect}
                            selectedBranch={selectedBranch}
                            conversationId={conversationId}
                            lastToggledBranchId={lastToggledBranchId}
                            showEmptyOptionLabel
                        />
                        <BranchView
                            errorMssg={bvErrorMssg}
                            status={branchViewStatus}
                            branch={selectedBranch}
                            conversationId={conversationId}
                            handleRefresh={(branchId) =>
                                handleSuccess(branchId)
                            }
                            branchEmbeds={branchEmbeds}
                            existingOptionConnections={
                                existingOptionConnections
                            }
                        />
                    </div>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConversationBranches}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='mt-5'>
            <BranchesTimeLineHeader
                source={ADD_BRANCH_TO_CONVERSATION}
                referenceData={{ conversationId }}
                handleRefresh={handleRefresh}
                collectionBranches={conversationBranches}
            />
            {renderBasedOnStatus()}
        </div>
    );
};

export default ConversationsBranches;
