import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateReminderForm from "./CreateReminderForm/CreateReminderForm";

const CreateReminderModal = ({
    showModal,
    closeModal,
    handleRefresh,
    isEdit,
    referenceData,
}) => {
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={isEdit ? "Edit Reminder" : `Create Reminder`}
                        />
                        <CreateReminderForm
                            handleRefresh={handleRefresh}
                            isEdit={isEdit}
                            referenceData={referenceData}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default CreateReminderModal;
