import "./DatePicker.scss";

export const DatePicker = ({
    type,
    onChange,
    onBlur,
    id,
    className,
    defaultValue,
    name,
    isErr,
    errMssg,
    label,
    minDate,
    maxDate,
    value
}) => {
    return (
        <>
            <input
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                id={id}
                defaultValue={defaultValue}
                value={value}
                name={name}
                label={label}
                min={minDate}
                max={maxDate}
                className={`date__picker ${
                    isErr ? "is-invalid" : ""
                } ${className}`}
            />
            <div className='invalid-feedback'>{errMssg}</div>
        </>
    );
};
