import AppUsersTableHeader from "./AppUsersTableHeader/AppUsersTableHeader";
import ConnectedApp from "./ConnectedApp/ConnectedApp";
import NoAppConnections from "./NoAppConnections/NoAppConnections";

const AppUsersTable = ({
    data,
    handleNewConnection,
    appSource,
    actionType,
    handleSuccess,
    user,
    handleError,
    handleActionModal
}) => {
    return (
        <>
            <div className='table_app__users'>
                <AppUsersTableHeader appSource={appSource} />
                <div className='app_user_body'>
                    {data.length > 0 ? (
                        data?.map((connectedApp, key) => (
                            <ConnectedApp
                                {...{
                                    connectedApp,
                                    actionType,
                                    appSource,
                                    handleNewConnection,
                                    user,
                                    handleSuccess,
                                    handleError,
                                    handleActionModal
                                }}
                                key={key}
                            />
                        ))
                    ) : (
                        <NoAppConnections />
                    )}
                </div>
            </div>
        </>
    );
};

export default AppUsersTable;
