import SelectUI from "components/ui/SelectUI/SelectUI";

const FilterAlphaSort = ({ handleSortByAlpha, filterOption }) => {
    return (
        <>
            <div className='filter__select'>
                <SelectUI
                    name='filterByAlpha'
                    id='filterByAlpha'
                    placeholder='A-Z (select order)'
                    hasOptionIcon={true}
                    options={[
                        {
                            label: "A-Z (Ascending)",
                            value: "asc",
                            icon: "radio",
                        },
                        {
                            label: "A-Z (Descending)",
                            value: "desc",
                            icon: "radio",
                        },
                    ]}
                    defaultValue={filterOption}
                    onChange={({ value, ...option }) =>
                        handleSortByAlpha?.(value, option)
                    }
                />
            </div>
        </>
    );
};

export default FilterAlphaSort;
