import CapsuleContentGroup from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleContentGroup/CapsuleContentGroup";
import { capsuleActions } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { useState } from "react";
import ClassesContainer from "../../../ProductDashboard/ProductClassesContainer/ProductClasses/ClassesContainer/ClassesContainer";
import ProductClassesHeader from "../../../ProductDashboard/ProductClassesContainer/ProductClasses/ProductClassesHeader/ProductClassesHeader";
import ChildrenClassesList from "./ChildrenClassesList/ChildrenClassesList";
import "./ChildrenClasses.scss";

const ChildrenClasses = ({
    capsuleClassess,
    capsuleData,
    viewCapsuleOnly,
    hideAddButton,
    hideEditAction,
    hideDeleteAction,
}) => {
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState();
    const [renderCount, updateRenderCount] = useState(0);
    const [currentCapsuleAction, setCurrentCapsuleAction] = useState(
        capsuleActions?.ADD_SINGLE_CHILD_CAPSULE
    );

    const handleCapsuleClassSelect = (capsuleClass) => {
        setSelectedCapsuleClass(capsuleClass);
        updateRenderCount((currentCount) => currentCount + 1);
    };

    return (
        <>
            <ProductClassesHeader
                title={"Children classes"}
                numberOfClasses={capsuleClassess?.length}
            />
            <ChildrenClassesList
                capsuleClassess={capsuleClassess}
                capsuleData={capsuleData}
                key={renderCount}
                handleCapsuleClassSelect={handleCapsuleClassSelect}
                selectedCapsuleClass={selectedCapsuleClass}
            />
            <div className='children__classes__container'>
                <ClassesContainer>
                    {selectedCapsuleClass && (
                        <CapsuleContentGroup
                            key={renderCount}
                            parentCapsuleClass={capsuleData?.capsule}
                            childCapsuleClass={selectedCapsuleClass}
                            productId={capsuleData?.productId}
                            capsuleData={capsuleData}
                            parentCapsuleId={capsuleData?.contentId}
                            reverseLookUp={false}
                            capsuleAction={currentCapsuleAction}
                            setCurrentCapsuleAction={setCurrentCapsuleAction}
                            updateRenderCount={updateRenderCount}
                            limitListing={true}
                            viewCapsuleOnly={viewCapsuleOnly}
                            hideAddButton={hideAddButton}
                            hideEditAction={hideEditAction}
                            hideDeleteAction={hideDeleteAction}
                            capsuleBottomMenu={true}
                        />
                    )}
                </ClassesContainer>
            </div>
        </>
    );
};

export default ChildrenClasses;
