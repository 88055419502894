import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import AddTicketInfoForm from "./AddTicketInfoForm/AddTicketInfoForm";

const AddTicketInfoModal = ({
    showModal,
    closeModal,
    handleSuccess,
    ticketId,
    existingTicketInfos,
}) => {
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title='Add New Ticket Info'
                            subTitle='Create a new capsule'
                        />
                        <AddTicketInfoForm
                            handleSuccess={handleSuccess}
                            ticketId={ticketId}
                            existingTicketInfos={existingTicketInfos}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default AddTicketInfoModal;
