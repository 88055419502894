import Filter from "components/dashboard/common/Filter/Filter";

const AnalyticsQAVetTicketFilters = ({
    handleSearch,
    handleSortByDate,
    filterParams,
    searchIsLoading,
    clearSearch,
    baseFilter,
    filterOption,
    handleBasefilter
}) => {
    return (
        <Filter
            searchPlaceholder={"Search tickets"}
            handleSortByDate={handleSortByDate}
            handleSearch={handleSearch}
            searchIsLoading={searchIsLoading}
            clearSearch={clearSearch}
            hideClassFilter={true}
            hideAlphaFilter={true}
            hideLastModifiedFilter={true}
            searchDefaultValue={filterParams?.search}
            baseFilter={baseFilter}
            filterOption={filterOption}
            handleBasefilter={handleBasefilter}
        />
    );
};

export default AnalyticsQAVetTicketFilters;
