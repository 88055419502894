export const PERSONAS_TABLE_HEADERS = [
    "Name",
    "Date created",
    "Status",
    "Actions",
];

export const PERSONAS_TABLE_ACTIONS = {
    EDIT: "EDIT",
    DELETE: "DELETE",
};

export const PERSONAS_TABLE_STATUSES = [
    { label: "General", value: "" },
    { label: "Active", value: true },
    { label: "Disabled", value: false },
];
