import React, { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import { getSingleCapsuleEntry } from "../lib";
import TicketFilterSelector from "components/dashboard/AgentInbox/AgentInboxTickets/TicketsFilterGroup/TicketFilterSelector/TicketFilterSelector";
import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";

const { IDLE, LOADING, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;
const TicketInfoCapsule = ({
    capsule,
    capsuleEntryId,
    countValue,
    isActive,
    onClick,
    otherClassNames,
    activeStyles,
    useAsTag,
    setBorderColor,
    noStyles,
    hasNew,
}) => {
    const [status, setStatus] = useState(LOADING);
    const [element, setCapsuleElement] = useState();

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
            case NULLMODE:
            case ERROR:
                return "";

            case LOADING:
            case DATAMODE:
                return (
                    <TicketFilterSelector
                        label={
                            status === LOADING ? (
                                <ShinyLoader width={"50px"} height={"14px"} />
                            ) : (
                                element?.content
                            )
                        }
                        countValue={countValue}
                        otherClassNames={otherClassNames}
                        isActive={isActive}
                        onClick={onClick}
                        activeStyles={
                            useAsTag
                                ? { fillColor: capsule?.borderColor }
                                : activeStyles
                        }
                        baseStyles={useAsTag ? capsule?.borderColor : null}
                        useAsTag={useAsTag}
                        setBorderColor={setBorderColor}
                        noStyles={noStyles}
                        hasNew={hasNew}
                    />
                );

            default:
                return "";
        }
    };

    const retrieveCapsule = async () => {
        try {
            setStatus(LOADING);
            const elementObj = await getSingleCapsuleEntry(
                capsule,
                capsuleEntryId
            );

            setCapsuleElement(elementObj);
            setStatus(DATAMODE);
        } catch (err) {
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        retrieveCapsule();
        // eslint-disable-next-line
    }, [capsuleEntryId]);

    return <>{renderCompBasedOnStage()}</>;
};

export default TicketInfoCapsule;
