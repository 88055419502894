import { useEffect, useState, useRef } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { useWindowSize } from "hooks/useWindowSize";

const ImageView = ({
    src,
    setLoading,
    setError,
    alt,
    isModalPreview,
    ...rest
}) => {
    const { width } = useWindowSize();
    const [imageSrc, setImageSrc] = useState("");
    const [naturalWidth, setNaturalWidth] = useState(null);
    const imageRef = useRef();

    const getImageUrl = async () => {
        try {
            setLoading?.(true);
            setError?.();
            const url = apiRoute.getFileLink;

            const res = await API.get(url, {
                params: {
                    fileKey: src,
                },
            });

            const data = res.data;
            setLoading?.(false);
            setError?.();
            setImageSrc(data?.data);
        } catch (err) {
            setLoading?.(false);
            setError?.(err);
        }
    };

    useEffect(() => {
        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
            setNaturalWidth(img.width);
        };

        return () => {
            img.onload = null;
        };
    }, [imageSrc]);

    const resizeImagePreview = () => {
        if (width > 470 && naturalWidth > 279 && naturalWidth < 819) {
            imageRef.current.style.width = "25%";
        } else if (width > 470 && naturalWidth > 819) {
            imageRef.current.style.width = "65%";
        } else {
            imageRef.current.style.width = "90%";
        }
    };

    useEffect(() => {
        if (isModalPreview && naturalWidth !== null) {
            resizeImagePreview();
        }
        // eslint-disable-next-line
    }, [naturalWidth, width]);

    const handleImageSrc = () => {
        if (
            src?.startsWith?.("https") ||
            src?.startsWith?.("data") ||
            src?.startsWith?.("blob")
        ) {
            return setImageSrc(src);
        } else if (src?.name) {
            return setImageSrc(URL.createObjectURL(src));
        } else {
            return getImageUrl();
        }
    };

    useEffect(() => {
        handleImageSrc();
        // eslint-disable-next-line
    }, [src]);

    return <img src={imageSrc} alt={alt} {...rest} ref={imageRef} />;
};

export default ImageView;
