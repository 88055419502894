import asset from "assets/images";
import { ReactSVG } from "react-svg";

const TicketInfoDetailsHeader = ({ toggleShow, show }) => {
    return (
        <div className='ticketinfo-elements__heading'>
            <span>Capsule information</span>
            <ReactSVG
                src={!show ? asset.icons.upside : asset.icons.downside}
                onClick={() => toggleShow(!show)}
            />
        </div>
    );
};

export default TicketInfoDetailsHeader;
