import "./EditCapsuleFormatHeader.scss";

const EditCapsuleFormatHeader = () => {
    return (
        <div className='edit__capsule__format__header'>
            <div>
                <h3 className='edit__capsule__format__header--title'>
                    Edit Class Formatting
                </h3>
                <p className='edit__capsule__format__header--sub__title'>
                    See and change how classes are organized
                </p>
            </div>
        </div>
    );
};

export default EditCapsuleFormatHeader;
