import React, { useState, useEffect } from "react";
import { userStatusList } from "../../enums";
import { pipeEnums } from "utils/helper";

const TeamMemberStatusTag = ({ member }) => {
    const [currentStatus, setCurrentStatus] = useState("");

    const getStatus = () => {
        const { accessStatus, emailVerified } = member || {};

        accessStatus
            ? setCurrentStatus(
                  emailVerified
                      ? userStatusList?.ACTIVE
                      : userStatusList?.VERIFICATION_PENDING
              )
            : setCurrentStatus(userStatusList?.DEACTIVATED);
    };

    useEffect(() => {
        getStatus();
        // eslint-disable-next-line
    }, [member]);

    return (
        <div className={`status_${currentStatus}`}>
            <span>{pipeEnums(currentStatus)}</span>
        </div>
    );
};

export default TeamMemberStatusTag;
