import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { useHistory } from "react-router-dom";

const DeleteBranchItem = ({
    branchType,
    conversationId,
    issue,
    branchCollectionId,
    branchCollectionName,
}) => {
    const history = useHistory();
    const { issueName } = issue || {};

    const handleRedirect = () => {
        branchType === "conversation"
            ? history.push(
                  `/t-sam/conversations/${conversationId}/view-conversation`
              )
            : history.push(
                  `/t-sam/branch-collection/${branchCollectionId}/view`
              );
    };

    return (
        <div
            className='delete-brances-list__item'
            onClick={() => handleRedirect()}>
            <ReactSVG
                src={asset?.icons?.dotCircle}
                className='delete-brances-list__item--icon'
            />
            <span>{issueName ? issueName : branchCollectionName}</span>
        </div>
    );
};

export default DeleteBranchItem;
