import { ANALYTICS_QA_TIMELINE_MESSAGE_TYPES } from "../enum";
import AnalyticsQATMessage from "./AnalyticsQATMessage/AnalyticsQATMessage";
import "./AnalyticsQATMessageList.scss";

const {
    IN_APP,
    RESPONSE,
    MENTION,
    EMAIL,
    SCENARIO,
    DOWNTIME,
    UPTIME,
    EMAIL_UPDATE,
} = ANALYTICS_QA_TIMELINE_MESSAGE_TYPES;

const AnalyticsQATMessageList = () => {
    const messages = [
        {
            sender: "THIRD_USER",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: IN_APP,
            senderName: "User",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: RESPONSE,
            senderName: "Agent",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: MENTION,
            senderName: "Agent",
        },
        {
            sender: "THIRD_USER",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: MENTION,
            senderName: "Manager",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: EMAIL,
            senderName: "Agent",
        },
        {
            sender: "THIRD_USER",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: EMAIL,
            senderName: "GTB Support",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: SCENARIO,
            senderName: "Agent",
        },
        {
            sender: "ASSISTANT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: EMAIL_UPDATE,
            senderName: "Assistant",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: DOWNTIME,
            senderName: "Agentt",
        },
        {
            sender: "AGENT",
            content: "Hi, what’s up, I need help with my phone number",
            time: "1:20pm, Wed",
            type: UPTIME,
            senderName: "Agent",
        },
    ];

    return (
        <div className='analyticsqa-timeline-messages'>
            {messages?.map((content, key) => (
                <AnalyticsQATMessage
                    key={key}
                    {...content}
                    isReceived={content?.sender === "THIRD_USER"}
                />
            ))}
        </div>
    );
};

export default AnalyticsQATMessageList;
