import { useSelector } from "react-redux";
import AnalyticsQAVATitle from "../../AnalyticsQAVAgentsSuggestions/AnalyticsQAVATitle/AnalyticsQAVATitle";
import TicketsHeader from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/TicketsHeader/TicketsHeader";
import MessageBody from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/MessageBody";
import LiveChatInput from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/LiveChatInput/LiveChatInput";

const AnalyticsQAVAgentsMessages = ({ ticketData, ticketId }) => {
    const { agents } = useSelector((state) => state?.agents);

    const getAgentName = (agentId) => {
        if (!agentId) return "No Agent Assigned";
        const { firstName, lastName } =
            agents?.find(({ userId }) => userId === agentId) || {};
        return `${firstName} ${lastName}'s conversations`;
    };

    const { firstRespondedByAgentId, assignedTeamMemberId } = ticketData || {};

    const backUrl = firstRespondedByAgentId
        ? `/analytics/qa/vet-agents/${firstRespondedByAgentId}`
        : `/analytics/qa/vet-tickets/`;

    return (
        <section className='col-8 pe-0'>
            <div className='analytics-qava-convos__header'>
                <AnalyticsQAVATitle
                    url={backUrl}
                    title={`${getAgentName(
                        ticketData?.firstRespondedByAgentId ||
                            assignedTeamMemberId
                    )}`}
                />
            </div>
            <div className='analytics-qava-convos__body'>
                <div className={`chat__interface`}>
                    <TicketsHeader
                        selectedTicket={ticketData}
                        hideActions={true}
                    />
                    <div className={`abs__container`}>
                        <div className={`chat__box scrollable-y`} id='chatBox'>
                            <MessageBody
                                vetAgentTicket={ticketData}
                                messages={ticketData?.messages}
                                customer={ticketData?.customer}
                            />
                        </div>
                        <LiveChatInput
                            {...{
                                ticketId,
                            }}
                            disabledInput={true}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AnalyticsQAVAgentsMessages;
