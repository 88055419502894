import React, { useState } from "react";
import { Input } from "components/ui";
import EmailHolder from "./EmailHolder/EmailHolder";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "./EmailInput.scss";


export const EmailForminput = ({ recipients=[], handleRecipientEmail, handleDelete }) => {

    const emailCC = recipients ? typeof recipients === "string" ? recipients?.split(",") : recipients : []
    
    return (
        <div className="form-group">
            <label className={`form-group dark__label mb-2`}>Recipient</label>
            <ReactMultiEmail
                placeholder="add recipients"
                emails={emailCC}
                onChange={(_emails) => {
                    handleRecipientEmail(_emails);
                }}
                getLabel={(
                    email,
                    index,
                ) => {
                    return (
                        <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => handleDelete(email)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />
        </div>

    );
}



const EmailInput = ({
    emailList,
    handleRemoveEmail,
    handleRecipientEmail,
    disabled,
    useLabel
}) => {
    const [email, setEmail] = useState();

    const handleTyping = ({ target: { value } }) => {
        setEmail(value);
    };

    const handleAddEmail = (e) => {
        e.preventDefault();
        if (!email) {
            return;
        }
        setEmail("");
        handleRecipientEmail(email);
    };

    return (
        <div className='email__input__container'>
            {useLabel && <label>Receipients</label>}
            <div
                className={`list__of__emails ${disabled ? `emails--full__width` : ``
                    } ${emailList?.length > 0 ? "emails__wide" : ""}`}>
                {emailList?.map((email, i) => (
                    <EmailHolder
                        key={i}
                        email={email}
                        onClick={() => handleRemoveEmail(email)}
                        disabled={disabled}
                    />
                ))}
            </div>
            {!disabled && (
                <form
                    onSubmit={handleAddEmail}
                    className={`${emailList?.length > 0 ? "shrink__width" : ""
                        }`}>
                    <Input
                        grpClass='email__input__group'
                        inputClass='email__input'
                        value={email}
                        type='email'
                        onBlur={handleAddEmail}
                        onChange={handleTyping}
                        key={emailList?.length}
                        autoFocus={true}
                        disabled={disabled}
                    />
                </form>
            )}
        </div>
    );
};

export default EmailInput;
