import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import PersonalProfileForm from "./PersonalProfileForm/PersonalProfileForm";
import PersonalProfileAvatar from "./PersonalProfileAvatar/PersonalProfileAvatar";
import { saveUserProfile } from "store/auth/actions";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import "./PersonalProfile.scss";

const { ERROR } = dataQueryStatus;

const PersonalProfile = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.auth);

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState("");
    const toastMessage = useContext(ToastContext);

    const updateProfile = async (request) => {
        try {
            setLoading(true);

            const { firstName, lastName } = request;

            const formData = new FormData();
            image && formData.append("image", image);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);

            const res = await API.put(apiRoute?.userProfile, formData);

            if (res.status === 200) {
                const { message, data } = res.data;

                setLoading(false);
                toastMessage(message);
                dispatch(saveUserProfile(data));
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <div className='padded__layout'>
			<div className='user__wrapper personal-profile'>
				<p>Your Display Picture</p>

                <PersonalProfileAvatar
                    user={{ ...user, image }}
                    setImage={setImage}
                    toastMessage={toastMessage}
                />

                <PersonalProfileForm
                    user={user}
                    loading={loading}
                    updateProfile={updateProfile}
                />
            </div>
        </div>
    );
};

export default PersonalProfile;
