import React from "react";
import { Button } from "components/ui";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ImageCropButtons.scss";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { UPDATE } = baseResourceActions;

const ImageCropButtons = ({
    loading,
    closeModal,
    handleContinue,
    disable,
    isEdit,
}) => {
    return (
        <div className='image__crop__buttons'>
            <ComponentAccessControl
                baseResources={[
                    `${UPDATE}_${KB_SAM_BRANCH}`,
                    `${UPDATE}_${KB_SAM_COLLECTION}`,
                    `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                    `${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                ]}>
                <Button
                    text={`${isEdit ? "Replace" : "Continue"}`}
                    type='submit'
                    classType='primary'
                    otherClass='w-100'
                    loading={loading}
                    onClick={handleContinue}
                    disabled={disable}
                />
            </ComponentAccessControl>
            <Button
                text='Cancel'
                type='submit'
                classType='plain'
                otherClass='w-100 fw-bold'
                onClick={closeModal}
            />
        </div>
    );
};

export default ImageCropButtons;
