import React from "react";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TicketCannedResponse.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const TicketCannedResponse = ({ response, title, setCannedMessage }) => {
    return (
        <ComponentAccessControl
            baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
            <div
                className={"ticket-canned-response"}
                onClick={() => setCannedMessage(response)}>
                <span>{title}</span>
            </div>
        </ComponentAccessControl>
    );
};

export default TicketCannedResponse;
