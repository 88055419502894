import React, { useState } from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import AddBranchModal from "../../../modals/AddBranchModal/AddBranchModal";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./BranchesTimeLineHeader.scss";

const { KB_SAM_COLLECTION, KB_SAM_BRANCH, KB_SAM_ISSUE_CONVERSATION } =
    baseResources;
const { CREATE } = baseResourceActions;

const BranchesTimeLineHeader = ({
    source,
    referenceData,
    handleRefresh,
    collectionBranches,
    isAgentGuideView,
}) => {
    const [showModal, toggleModal] = useState(false);

    return (
        <>
            <div className='branches__header'>
                <div className='row align-items-center'>
                    <div className='col-md-4'>
                        <h6 className='main__subheading main__subheading--md'>
                            Conversation Branches
                        </h6>
                    </div>
                    <div className='col-md-2 ms-auto'>
                        <ComponentAccessControl
                            baseResources={[
                                `${CREATE}_${KB_SAM_COLLECTION}`,
                                `${CREATE}_${KB_SAM_BRANCH}`,
                                `${CREATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                            ]}>
                            <AddButton
                                btnText={"Add a Branch"}
                                otherClassName='justify-content-end'
                                onClick={() => {
                                    toggleModal(true);
                                }}
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
            </div>
            {showModal && (
                <AddBranchModal
                    show={showModal}
                    source={source}
                    closeModal={() => toggleModal(false)}
                    handleBranchAddSuccess={(branchId) => {
                        toggleModal(false);
                        handleRefresh(branchId);
                    }}
                    handleRefresh={handleRefresh}
                    isAgentGuideView={isAgentGuideView}
                    existingBranches={collectionBranches}
                    referenceData={referenceData}
                />
            )}
        </>
    );
};

export default BranchesTimeLineHeader;
