import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import {
    END_FORM_COLLECTION,
    GENERAL_DOWNTIME_COLLECTION,
    POST_FORM_COLLECTION,
    SOLUTIONS_COLLECTION,
} from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import { Button, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import React, { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import { ADD_BRANCH_TO_CONVERSATION } from "../../../Conversations/enums";
import BranchNameInput from "./BranchNameInput/BranchNameInput";
import { CREATE_NEW_BRANCH, ADD_EXISTING_BRANCH } from "./enums";

const AddBranchForm = ({
    source,
    referenceData,
    existingBranches,
    handleBranchAddSuccess,
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const toastMessage = useContext(ToastContext);

    const [request, updateRequest] = useState({
        mainBranchValue: "",
        mainBranchSentence: "",
    });

    const [errors, setErrors] = useState({}); // validation error

    const [actionType, setActionType] = useState();
    const [loadingBranchNames, setLoadingBranchNames] = useState(true);

    const handleBranchNameChange = (actionType, valueObj) => {
        setLoadingBranchNames(false);
        setActionType(actionType);
        updateRequest((prev) => ({ ...prev, mainBranchValue: valueObj }));
        setErrors((prevErrors) => ({ ...prevErrors, mainBranchValue: "" }));
    };

    const handleInputChange = ({ target: { value, name } }) => {
        updateRequest((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const handleError = (err) => {
        setErrorMsg(getErrorMessage(err));
        setLoading(false);
    };

    const addExistingBranchConversation = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            const { conversationId } = referenceData;
            const { mainBranchValue } = request;

            const url = apiRoute?.addBranchToConversation(
                conversationId,
                mainBranchValue
            );

            const res = await API.post(url);

            if (res.status === 201) {
                toastMessage(res.data.message);
                handleBranchAddSuccess(mainBranchValue);
            }
        } catch (err) {
            handleError(err);
        }
    };

    const createConversationBranch = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            const { conversationId } = referenceData;
            const { mainBranchValue, mainBranchSentence } = request;

            const url = apiRoute?.createBranch;
            const res = await API.post(url, {
                mainBranchName: mainBranchValue,
                conversationId,
                mainBranchSentence,
            });

            if (res.status === 201) {
                toastMessage(res.data.message);
                const { branchId } = res.data.data;
                handleBranchAddSuccess(branchId);
            }
        } catch (err) {
            handleError(err);
        }
    };

    // const createConcludingBranch = async (useExisting) => {
    //     try {
    //         setLoading(true);
    //         setErrorMsg();

    //         const { mainBranchValue, mainBranchSentence } = request;
    //         const url = apiRoute?.createBranch;
    //         const res = await API.post(url, {
    //             mainBranchName: mainBranchValue,
    //             mainBranchSentence
    //         });

    //         if (res.status === 201) {
    //             toastMessage(res.data.message);
    //             setLoading(false);
    //             handleBranchAddSuccess(res.data.data);
    //         }
    //     } catch (err) {
    //         handleError(err);
    //     }
    // };

    const createCollectionBranch = async (useExisting) => {
        try {
            setLoading(true);
            setErrorMsg();

            const { mainBranchValue, mainBranchSentence } = request;

            const { attachCollectionBranch, createCollectionBranch } = apiRoute;
            const url = useExisting
                ? attachCollectionBranch
                : createCollectionBranch;
            const isFirstBranch = existingBranches?.length > 0 ? false : true;
            const res = await API.post(url, {
                branchCollectionId: referenceData?.branchCollectionId,
                mainBranchName: mainBranchValue,
                branchId: useExisting ? mainBranchValue : undefined,
                isMainBranch: isFirstBranch,
                mainBranchSentence,
            });
            if (res.status === 201) {
                toastMessage(res.data.message);
                setLoading(false);

                const branchId = useExisting
                    ? mainBranchValue
                    : res?.data?.data?.branchId;

                handleBranchAddSuccess(branchId);
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleRelatingExistingBranch = () => {
        switch (source) {
            case POST_FORM_COLLECTION:
            case SOLUTIONS_COLLECTION:
            case GENERAL_DOWNTIME_COLLECTION:
                createCollectionBranch(true);
                break;
            case END_FORM_COLLECTION:
                handleBranchAddSuccess(request?.mainBranchValue);
                break;
            case ADD_BRANCH_TO_CONVERSATION:
                addExistingBranchConversation();
                break;
            default:
                break;
        }
    };

    const handleCreatingNewBranch = () => {
        switch (source) {
            case POST_FORM_COLLECTION:
            case SOLUTIONS_COLLECTION:
            case GENERAL_DOWNTIME_COLLECTION:
                createCollectionBranch();
                break;
            // case END_FORM_COLLECTION:
            //     createConcludingBranch();
            case ADD_BRANCH_TO_CONVERSATION:
                createConversationBranch();
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);

        if (formisValid) {
            actionType === ADD_EXISTING_BRANCH
                ? handleRelatingExistingBranch()
                : handleCreatingNewBranch();
        } else {
            setErrors(errors);
        }
    };

    const { mainBranchSentence } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <BranchNameInput
                handleBranchNameChange={handleBranchNameChange}
                isLoading={loadingBranchNames}
                setLoadingBranchNames={setLoadingBranchNames}
                filterBranches={existingBranches}
                errorMssg={errors?.mainBranchValue}
                autoFocus={true}
                name='mainBranchValue'
            />
            {actionType === CREATE_NEW_BRANCH && (
                <Textarea
                    grpClass='mb-3'
                    name='mainBranchSentence'
                    label='Main sentence'
                    labelClass='text-dark fw-medium'
                    placeholder='Enter Main sentence'
                    value={mainBranchSentence}
                    onChange={handleInputChange}
                    isErr={errors?.mainBranchSentence}
                    errMssg={errors?.mainBranchSentence}
                />
            )}
            <Button
                classType={`primary`}
                otherClass='w-100'
                type={"submit"}
                text={
                    actionType === CREATE_NEW_BRANCH
                        ? "Create Branch"
                        : "Add Branch"
                }
                disabled={loadingBranchNames}
                loading={loading}
            />
        </form>
    );
};

export default AddBranchForm;
