import React from 'react';
import "./SpringLoader.scss";

const SpringLoader = () => {
    return (
        <div className='spring__container'>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
        </div>
    );
};

export default SpringLoader;