import PreHeading from "components/ui/PreHeading/PreHeading";
import FollowUpHeading from "./FollowUpHeading/FollowUpHeading";
import FollowUpTable from "./FollowUpTable/FollowUpTable";
import FollowUpFilter from "./FollowUpFilter/FollowUpFilter";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveCapsuleClassess } from "store/capsule/actions";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getCapsulesEntries } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { primaryCapsuleCodes } from "components/dashboard/KnowledgeBase/common/Capsule/dictionary/capsuleCodes";
import "./FollowUp.scss";
import FollowUpModals from "./modal/FollowUpModals";

const { LOADING, DATAMODE, NULLMODE, ERROR, IDLE } = dataQueryStatus;

const { SOLUTION } = primaryCapsuleCodes;

const AgentFollowUp = () => {
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState();

    const [solutionCapsuleClass, selectSolutionCapsuleClass] = useState({
        capsuleCode: SOLUTION,
    });

    const [capsuleClassEntries, setCapsuleClassEntries] = useState([]);

    const [followUpCollections, updateFollowUpCollections] = useState([]);
    const [filteredFCollections, updateFilteredFCollections] = useState([]);

    const [capsuleClassEntryIds, setCapsuleClassEntryIds] = useState([]);
    const [selectedCapsuleEntry, setSelectedCapsuleEntry] = useState();
    const [tableLoading, setTableLoading] = useState(false);
    const [refreshGuides, setRefreshGuides] = useState(false);

    const [filters, updatePageFilters] = useState({
        alphabeticalOrder: null,
        dateCreatedOrder: null,
        search: "",
    });

    const { capsuleClasses } = useSelector((state) => state.capsule);

    const dispatch = useDispatch();

    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();

    const handleActionModal = (actionType, capsuleEntry) => {
        setSelectedCapsuleEntry(capsuleEntry);
        setActionType(actionType);
        setShowModal(true);
    };

    const getCapsuleClasses = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            if (capsuleClasses?.length < 1) {
                const url = apiRoute?.getCapsuleClasses;

                const res = await API.get(url);
                if (res.status === 200) {
                    const { data } = res.data;
                    dispatch(saveCapsuleClassess(data));
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const selectClass = async () => {
        setStatus(LOADING);
        const solutionCapsuleClass = capsuleClasses.find(
            (x) => x.capsuleCode === SOLUTION
        );
        if (solutionCapsuleClass) {
            selectSolutionCapsuleClass(solutionCapsuleClass);
        } else {
            selectSolutionCapsuleClass();
            setStatus(NULLMODE);
        }
    };

    const getCapsuleClassEntries = async () => {
        try {
            setTableLoading(true);
            const { collectionKey } = solutionCapsuleClass;
            if (collectionKey) {
                const { capsuleEntries } = await getCapsulesEntries(
                    solutionCapsuleClass,
                    filters
                );

                setCapsuleClassEntries(capsuleEntries);
                updateFilteredFCollections(capsuleEntries);
                updateFollowUpCollections(capsuleEntries);

                setCapsuleClassEntryIds(
                    capsuleEntries?.map((capsuleEntry) => {
                        return capsuleEntry[collectionKey];
                    })
                );

                setStatus(DATAMODE);
                setTableLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setTableLoading(false);
        }
    };

    const updateCapsuleClassEntries = (followUpCollections) => {
        // --- perform a deep copy ----
        let updatedCollections = JSON.parse(
            JSON.stringify(capsuleClassEntries)
        );

        const capsuleEntriesMap = new Map();

        updatedCollections?.forEach((content) => {
            capsuleEntriesMap.set(
                content[solutionCapsuleClass?.collectionKey],
                content
            );
        });

        followUpCollections?.forEach((content) => {
            if (capsuleEntriesMap.has(content?.classCapsuleEntryId)) {
                const mapValue = capsuleEntriesMap.get(
                    content?.classCapsuleEntryId
                );
                mapValue.attachedFollowUpId = content?.agentGuideId;
                mapValue.attachedFollowUpName = content?.agentGuideName;
            }
        });

        updateFollowUpCollections(updatedCollections);
        updateFilteredFCollections(updatedCollections);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Solution Classes"} />;

            case DATAMODE:
                return (
                    <>
                        <FollowUpTable
                            handleActionModal={handleActionModal}
                            followUpCollections={filteredFCollections}
                            capsuleClassEntryIds={capsuleClassEntryIds}
                            updateCapsuleClassEntries={
                                updateCapsuleClassEntries
                            }
                            setErrorMssg={setErrorMssg}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            actionType={actionType}
                            selectedCapsuleEntry={selectedCapsuleEntry}
                            solutionCapsuleClass={solutionCapsuleClass}
                            tableLoading={tableLoading}
                            refreshGuides={refreshGuides}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length > 0) {
            selectClass();
        } else {
            getCapsuleClasses();
        }
        //eslint-disable-next-line
    }, [capsuleClasses, solutionCapsuleClass]);

    useEffect(() => {
        if (solutionCapsuleClass) {
            getCapsuleClassEntries();
            // getAgentGuides()
        }
        //eslint-disable-next-line
    }, [solutionCapsuleClass, filters]);

    const handleSuccess = () => {
        setRefreshGuides(!refreshGuides);
    };

    return (
        <>
            <PreHeading
                text='Solution Discovery'
                subText='Follow up collections'
            />
            <div className='padded__layout pt-3'>
                <div className='agent-follow-up'>
                    <FollowUpHeading />
                    <FollowUpFilter
                        solutionCapsuleClass={solutionCapsuleClass}
                        followUpCollections={followUpCollections}
                        updateFilteredFCollections={updateFilteredFCollections}
                        updateFollowUpCollections={updateFollowUpCollections}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        setTableLoading={setTableLoading}
                        setStatus={setStatus}
                        setErrorMssg={setErrorMssg}
                    />
                    {renderBasedOnStatus()}
                </div>
            </div>

            {showModal && (
                <FollowUpModals
                    showModal={showModal}
                    setShowModal={setShowModal}
                    actionType={actionType}
                    selectedCapsuleEntry={selectedCapsuleEntry}
                    handleSuccess={handleSuccess}
                    solutionCapsule={{
                        classCapsuleEntryId: selectedCapsuleEntry?.solutionid,
                        capsuleId: solutionCapsuleClass?.capsuleClassId,
                    }}
                />
            )}
        </>
    );
};

export default AgentFollowUp;
