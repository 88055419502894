import * as types from "./types";

export const saveForms = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({
        type: types.SAVE_FORMS,
        payload: { pageNumber, totalRecords, data },
    });
export const clearForms = () => (dispatch) =>
    dispatch({ type: types.CLEAR_FORMS });

export const savePostFormCollections =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_POST_FORM_COLLECTIONS,
            payload: { pageNumber, totalRecords, data },
        });
export const clearPostFormCollections = () => (dispatch) =>
    dispatch({ type: types.CLEAR_POST_FORM_COLLECTIONS });

export const saveCannedResponses =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_CANNED_RESPONSES,
            payload: { pageNumber, totalRecords, data },
        });
export const clearCannedResponses = () => (dispatch) =>
    dispatch({ type: types.CLEAR_CANNED_RESPONSES });
