import React from "react";
import ProductClassesList from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesContainer/ProductClasses/ProductClassesList/ProductClassesList";
import "./ParentClassesList.scss";

const ParentClassesList = ({
    capsuleClassess,
    capsuleData,
    handleCapsuleClassSelect,
    selectedCapsuleClass,
}) => {
    return (
        <div className='parent__capsule__classes'>
            <ProductClassesList
                capsuleData={capsuleData}
                capsuleClasses={capsuleClassess}
                productId={capsuleData?.productId}
                handleMainCapsuleSelect={handleCapsuleClassSelect}
                selectedCapsuleClass={selectedCapsuleClass}
            />
        </div>
    );
};

export default ParentClassesList;
