import { Draggable } from "react-beautiful-dnd";
import BuilderElementContent from "./BuilderElementContent/BuilderElementContent";

const BuilderElement = ({ item, order }) => {
    return (
        <Draggable draggableId={item.type} index={order}>
            {(provided, snapshot) => (
                <>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <BuilderElementContent item={item} />
                    </div>
                    {snapshot.isDragging && (
                        <BuilderElementContent item={item} />
                    )}
                </>
            )}
        </Draggable>
    );
};

export default BuilderElement;
