import * as types from "./types";

export const setUsersFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_USERS_FILTER_PARAMS, payload: data });
export const setRolesAndPermissionsFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_ROLES_PERMISSION_FILTER_PARAMS, payload: data });

export const setConversationBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CONVERSATIONS_BASE_FILTER, payload: data });
export const setConversationFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_CONVERSATIONS_FILTER_OPTION, payload: data });
export const setConversationFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_CONVERSATIONS_FILTER_PARAMS, payload: data });

export const setIssueBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_ISSUES_BASE_FILTER, payload: data });
export const setIssueFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_ISSUES_FILTER_OPTION, payload: data });
export const setIssueFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_ISSUES_FILTER_PARAMS, payload: data });

export const setQADocumentationBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_QA_DOCUMENTATION_BASE_FILTER, payload: data });
export const setQADocumentationFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_QA_DOCUMENTATION_FILTER_OPTION, payload: data });
export const setQADocumentationFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_QA_DOCUMENTATION_FILTER_PARAMS, payload: data });

export const setDiscoveryBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_DISCOVERY_BASE_FILTER, payload: data });
export const setDiscoveryFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_DISCOVERY_FILTER_OPTION, payload: data });
export const setDiscoveryFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_DISCOVERY_FILTER_PARAMS, payload: data });

export const setSwitchesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_SWITCHES_BASE_FILTER, payload: data });
export const setSwitchesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_SWITCHES_FILTER_OPTION, payload: data });
export const setSwitchesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_SWITCHES_FILTER_PARAMS, payload: data });

export const setDowntimeBranchesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_DOWNTIME_BRANCHES_BASE_FILTER, payload: data });
export const setDowntimeBranchesFilterOption = (data) => (dispatch) =>
    dispatch({
        type: types.SET_DOWNTIME_BRANCHES_FILTER_OPTION,
        payload: data,
    });
export const setDowntimeBranchesFilterParams = (data) => (dispatch) =>
    dispatch({
        type: types.SET_DOWNTIME_BRANCHES_FILTER_PARAMS,
        payload: data,
    });

export const setFormBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_FORMS_BASE_FILTER, payload: data });
export const setFormFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_FORMS_FILTER_OPTION, payload: data });
export const setFormFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_FORMS_FILTER_PARAMS, payload: data });

export const setPostFormBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_POST_FORM_BASE_FILTER, payload: data });
export const setPostFormFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_POST_FORM_FILTER_OPTION, payload: data });
export const setPostFormFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_POST_FORM_FILTER_PARAMS, payload: data });

export const setCannedResponsesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_RESPONSES_BASE_FILTER, payload: data });
export const setCannedResponsesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_RESPONSES_FILTER_OPTION, payload: data });
export const setCannedResponsesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_RESPONSES_FILTER_PARAMS, payload: data });

export const setTeamsFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_TEAMS_FILTER_PARAMS, payload: data });

export const setMssgTagFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_MSSG_TAG_FILTER_PARAMS, payload: data });

export const setPersonasFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_PERSONAS_FILTER_PARAMS, payload: data });

export const setVetTicketBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_TICKET_FILTER, payload: data });
export const setVetTicketFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_TICKET_FILTER_OPTION, payload: data });
export const setVetTicketFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_TICKET_FILTER_PARAMS, payload: data });

export const setVetAgentBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_AGENT_FILTER, payload: data });
export const setVetAgentFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_AGENT_FILTER_OPTION, payload: data });
export const setVetAgentFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_VET_AGENT_FILTER_PARAMS, payload: data });

export const setGuidesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_GUIDES_FILTER, payload: data });
export const setGuidesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_GUIDES_FILTER_OPTION, payload: data });
export const setGuidesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_GUIDES_FILTER_PARAMS, payload: data });

export const setScenariosBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_SCENARIOS_FILTER, payload: data });
export const setScenariosFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_SCENARIOS_FILTER_OPTION, payload: data });
export const setScenariosFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_SCENARIOS_FILTER_PARAMS, payload: data });

export const setCannedMentionsBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_MENTIONS_FILTER, payload: data });
export const setCannedMentionsFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_MENTIONS_FILTER_OPTION, payload: data });
export const setCannedMentionsFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_MENTIONS_FILTER_PARAMS, payload: data });

export const setCannedEmailsBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_EMAILS_FILTER, payload: data });
export const setCannedEmailsFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_EMAILS_FILTER_OPTION, payload: data });
export const setCannedEmailsFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_EMAILS_FILTER_PARAMS, payload: data });

export const setCannedRepliesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_REPLIES_FILTER, payload: data });
export const setCannedRepliesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_REPLIES_FILTER_OPTION, payload: data });
export const setCannedRepliesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_CANNED_REPLIES_FILTER_PARAMS, payload: data });

export const setEmailUpdatesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_EMAIL_UPDATES_FILTER, payload: data });
export const setEmailUpdatesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_EMAIL_UPDATES_FILTER_OPTION, payload: data });
export const setEmailUpdatesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_EMAIL_UPDATES_FILTER_PARAMS, payload: data });

export const setMentionUpdatesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_MENTION_UPDATES_FILTER, payload: data });
export const setMentionUpdatesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_MENTION_UPDATES_FILTER_OPTION, payload: data });
export const setMentionUpdatesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_MENTION_UPDATES_FILTER_PARAMS, payload: data });

export const setReplyUpdatesBaseFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_REPLY_UPDATES_FILTER, payload: data });
export const setReplyUpdatesFilterOption = (data) => (dispatch) =>
    dispatch({ type: types.SET_REPLY_UPDATES_FILTER_OPTION, payload: data });
export const setReplyUpdatesFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_REPLY_UPDATES_FILTER_PARAMS, payload: data });

export const setGuideDowntimeFilterParams = (data) => (dispatch) =>
    dispatch({ type: types.SET_GUIDE_DOWNTIME_FILTER_PARAMS, payload: data });
