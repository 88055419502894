import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "store/store";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-quill/dist/quill.snow.css";
import AppTemplateWrapper from "hoc/AppTemplateWrapper/AppTemplateWrapper";
// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppTemplateWrapper>
                <App />
            </AppTemplateWrapper>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
