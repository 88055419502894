import React from "react";
import { defaultThemes } from "./enum";
import "./AppTemplateWrapper.scss";
import { useSelector } from "react-redux";

const { DARK_MODE_DEFAULT } = defaultThemes;

const AppTemplateWrapper = ({ children }) => {
    const { defaultTheme } = useSelector((state) => state?.settings);

    const isDarkModeTheme = defaultTheme === DARK_MODE_DEFAULT;

    return (
        <div
            className={`${
                isDarkModeTheme ? "dark-mode-default" : "white-mode-default"
            }`}>
            {children}
        </div>
    );
};

export default AppTemplateWrapper;
