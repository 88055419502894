import * as types from "./types";

const initialState = {
    issuesCache: {
        pages: {},
        totalRecords: 0
    },
    discoveriesCache: {
        pages: {},
        totalRecords: 0
    },
    conversationsCache: {
        pages: {},
        totalRecords: 0
    },
    qaDocumentationCache: {
        pages: {},
        totalRecords: 0
    }
};

const SAMReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case types.SAVE_ISSUES:
            return {
                ...state,
                issuesCache: {
                    ...state.issuesCache,
                    pages: {
                        ...state.issuesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_ISSUES:
            return {
                ...state,
                issuesCache: {
                    ...state.issuesCache,
                    pages: {},
                    totalRecords: 0
                },
            };

        case types.SAVE_DISCOVERIES:
            return {
                ...state,
                discoveriesCache: {
                    ...state.discoveriesCache,
                    pages: {
                        ...state.discoveriesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_DISCOVERIES:
            return {
                ...state,
                discoveriesCache: {
                    ...state.discoveriesCache,
                    pages: {},
                    totalRecords: 0
                },
            };

        case types.SAVE_CONVERSATIONS:
            return {
                ...state,
                conversationsCache: {
                    ...state.conversationsCache,
                    pages: {
                        ...state.conversationsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_CONVERSATIONS:
            return {
                ...state,
                conversationsCache: {
                    ...state.conversationsCache,
                    pages: {},
                    totalRecords: 0
                },
            };


        case types.SAVE_QA_DOCUMENTATIONS:
            return {
                ...state,
                qaDocumentationCache: {
                    ...state.qaDocumentationCache,
                    pages: {
                        ...state.qaDocumentationCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_QA_DOCUMENTATIONS:
            return {
                ...state,
                qaDocumentationCache: {
                    ...state.qaDocumentationCache,
                    pages: {},
                    totalRecords: 0
                },
            };


        default:
            return state;
    }
};

export default SAMReducer;
