import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CCBlockDropdown.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { DELETE } = baseResourceActions;

const CapsuleContentBlockDropdown = ({ openDeleteModal }) => {
    const [dropdownOpen, toggle] = useState(false);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            direction='up'
            id='cc__block__dropdown'
            toggle={() => toggle((prevState) => !prevState)}>
            <ReactSVG
                src={asset?.icons?.carbonMore}
                onClick={() => toggle((prevState) => !prevState)}
            />
            <DropdownMenu className='cc__block__menu'>
                <ComponentAccessControl
                    baseResources={[`${DELETE}_${KB_PRODUCT_SETTING}`]}>
                    <DropdownItem onClick={openDeleteModal}>
                        Delete block
                    </DropdownItem>
                </ComponentAccessControl>
            </DropdownMenu>
        </Dropdown>
    );
};

export default CapsuleContentBlockDropdown;
