import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import AttachCapsuleClassForm from "./AttachCapsuleClassForm/AttachCapsuleClassForm";
import CapsuleClassName from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleClassName/CapsuleClassName";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const AttachCapsuleClassModal = ({
    showModal,
    closeModal,
    selectedCapsule,
    handleCapsulationSuccess,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Attach New Class'
                        subTitle={
                            <>
                                Create{" "}
                                <CapsuleClassName capsule={selectedCapsule} />{" "}
                                relationship with other classes
                            </>
                        }
                    />
                    <AttachCapsuleClassForm
                        selectedCapsule={selectedCapsule}
                        handleCapsulationSuccess={handleCapsulationSuccess}
                        closeModal={closeModal}
                    />
                </div>
            </div>
        </SideModal>
    );
};

AttachCapsuleClassModal.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    capsuleClasses: PropTypes.array.isRequired,
    parentCapsuleClassId: PropTypes.string.isRequired,
    getCapsuleClasses: PropTypes.func.isRequired,
    remove: PropTypes.bool.isRequired,
};

export default AttachCapsuleClassModal;
