import { useState } from "react";
import { Empty, Table } from "components/ui";
import TeamMemberEmail from "./TeamMemberEmail/TeamMemberEmail";
import TeamMemberTableHeader from "./TeamMemberTableHeader/TeamMemberTableHeader";
import TeamMemberDropdown from "./TeamMemberDropdown/TeamMemberDropdown";
import TicketRouting from "./TicketRouting/TicketRouting";
import { TEAM_MEMBER_HEADERS, TEAM_MEMBER_ACTIONS } from "./enums";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import "./TeamMemberTable.scss";

const TeamMemberTable = ({
    members,
    teamId,
    openModal,
    handleSuccess,
    totalRecords,
    handlePageChange,
    filters,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [currentMember, setCurrentMember] = useState({});

    const handleActionModal = (action, member) => {
        setCurrentAction(action);
        setCurrentMember(member);
    };

    const closeActionModal = () => {
        setCurrentAction("");
        setCurrentMember("");
    };

    const tableData = members
        .filter((member) =>
            `${member.firstName.toLocaleLowerCase()} ${member.lastName.toLocaleLowerCase()}`.includes(
                searchValue.toLocaleLowerCase()
            )
        )
        ?.map((member) => ({
            memberName: `${member.firstName} ${member.lastName}`,
            email: <TeamMemberEmail email={member.email} />,
            ticketRouting: (
                <TicketRouting
                    member={member}
                    teamId={teamId}
                    handleSuccess={handleSuccess}
                />
            ),
            action: (
                <TeamMemberDropdown
                    member={member}
                    handleActionModal={handleActionModal}
                />
            ),
        }));

    return (
        <>
            <TeamMemberTableHeader
                searchValue={searchValue}
                setSearchValue={(search) => setSearchValue(search)}
                openModal={openModal}
            />
            <div className='team__table__wrap'>
                {tableData?.length > 0 ? (
                    <Table
                        headers={TEAM_MEMBER_HEADERS}
                        dataSource={tableData}
                    />
                ) : (
                    <Empty text={"No Team member matches the search."} />
                )}
                <CustomPagination
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    {...filters}
                />
            </div>
            {currentAction === TEAM_MEMBER_ACTIONS.REMOVE_MEMBER && (
                <ConfirmationModal
                    title={`Are you sure you want to remove ${currentMember?.firstName} ${currentMember?.lastName}`}
                    show={true}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                    url={apiRoute.removeMember(teamId, currentMember?.userId)}
                    method='delete'
                />
            )}
        </>
    );
};

export default TeamMemberTable;
