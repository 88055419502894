import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Textarea, Loading } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import { flattenArray, getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import ClassificationPriority from "./ClassificationPriority/ClassificationPriority";
import { CLASSIFICATION_PRIORITY } from "./ClassificationPriority/enums";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const ClassificationForm = ({
    closeModal,
    selectedClassification,
    elements,
    isEdit,
    selectedUnassignedCapsule,
    handleSuccess,
    prevAssigned,
}) => {
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        classificationDescription: selectedClassification?.description || "",
        classificationName: selectedClassification?.name || "",
        classificationId: selectedClassification?.classificationId || "",
        classificationCapsules: [],
        priority:
            selectedClassification?.priority || CLASSIFICATION_PRIORITY.HIGH,
    });
    const [selectedContents, setSelectedContents] = useState(new Map());
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();
    const toastMessage = useContext(ToastContext);
    const [selectedClass, setSelectedClass] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);

    const dispatch = useDispatch();

    // const addElementCapsuleCode = capsuleClasses
    //     ?.map((capsuleClass) => capsuleClass?.capsuleCode)
    //     ?.filter(
    //         (capsuleCode) =>
    //             capsuleCode === selectedUnassignedCapsule?.capsuleCode
    //     );

    const codes = elements?.map((element) => element?.capsuleEntryId);
    const prevAssignedElements = prevAssigned?.filter((assigned) =>
        isEdit
            ? assigned?.capsule.capsuleCode === selectedClass?.capsuleCode &&
              !codes?.includes(assigned?.capsuleEntryId)
            : assigned?.capsule.capsuleCode === selectedClass?.capsuleCode
    );
    const assignedIds = prevAssignedElements?.map((el) => el.capsuleEntryId);

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };
    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        //eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const retieveSelectedCapsules = () => {
        let classficationRequest = [];
        let capsuleClassIds = Array.from(selectedContents.keys());

        capsuleClassIds.forEach((item) => {
            classficationRequest.push({
                classCapsuleId: item,
                classCapsuleEntryIds: selectedContents.get(item),
            });
        });
        return classficationRequest;
    };

    const createClassification = async () => {
        try {
            setErrorMsg();
            setLoading(true);
            const url = apiRoute?.classifications;
            const capsulesRequest = retieveSelectedCapsules();

            const res = await API.post(url, {
                ...request,
                classificationCapsules: capsulesRequest?.filter(
                    (capsule) => capsule?.classCapsuleEntryIds?.length > 0
                ),
            });
            const data = res.data;

            if (res.status === 201) {
                toastMessage(data?.message);
                handleSuccess(data?.data);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleContentSelect = ({ capsuleClassId }, capsuleEntryId) => {
        setErrors({ ...errors, classificationCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleClassId);

        if (prevSelectedContents) {
            const ccSelectedContentIds = prevSelectedContents;
            const contentExist = prevSelectedContents?.includes(capsuleEntryId);

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents.indexOf(capsuleEntryId),
                    1
                ); //deleting
            }
            setSelectedContents(
                (prev) =>
                    new Map([...prev, [capsuleClassId, ccSelectedContentIds]])
            );
        } else {
            setSelectedContents(
                (prev) => new Map([...prev, [capsuleClassId, [capsuleEntryId]]])
            );
        }
    };

    const getExistingElements = () => {
        let map = new Map();
        elements?.forEach(function (
            { capsule: { capsuleClassId }, capsuleEntryId },
            obj
        ) {
            let prevArry = map.get(capsuleClassId);

            map = prevArry
                ? new Map([
                      ...map,
                      [capsuleClassId, [...prevArry, capsuleEntryId]],
                  ])
                : new Map([...map, [capsuleClassId, [capsuleEntryId]]]);
        },
        {});

        setSelectedContents(map);
    };

    // const addElement = async () => {
    //     try {
    //         setErrorMsg();
    //         setLoading(true);

    //         const capsulesRequest = retieveSelectedCapsules();
    //         const url = apiRoute.classificationElement;
    //         const res = await API.put(url, {
    //             ...request,
    //             ...capsulesRequest.find(
    //                 (capsule) =>
    //                     capsule?.classCapsuleId ===
    //                     selectedUnassignedCapsule?.capsuleClassId
    //             ),
    //         });
    //         const data = res.data;

    //         if (res.status === 200) {
    //             toastMessage(data?.message);
    //             handleSuccess(data?.data);
    //             closeModal();
    //         }
    //     } catch (err) {
    //         setLoading(false);
    //         setErrorMsg(getErrorMessage(err));
    //     }
    // };

    const editClassification = async () => {
        try {
            setErrorMsg();
            setLoading(true);
            const capsulesRequest = retieveSelectedCapsules();

            const url = apiRoute.classification(
                selectedClassification?.classificationId
            );

            const res = await API.put(url, {
                ...request,
                classificationCapsules: capsulesRequest,
            });
            const data = res.data;

            if (res.status === 200) {
                toastMessage(data?.message);
                handleSuccess(data?.data);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "classificationCapsules",
        ]);

        if (formisValid) {
            if (isEdit) {
                return editClassification();
            }
            return createClassification();
        }
        setErrors(formErrors);
    };

    useEffect(() => {
        getExistingElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={
                            // isEdit
                            //     ? addElementCapsuleCode
                            //     :
                            capsuleClasses?.map(
                                (capsuleClass) => capsuleClass?.capsuleCode
                            )
                        }
                        classesListBreakpoint={8}
                        handleContentSelect={handleContentSelect}
                        selectable={true}
                        searchable={true}
                        selectedContentIds={flattenArray(
                            Array.from(selectedContents?.values())
                        )}
                        label='Select Feature from a tag'
                        inputKey='classificationCapsules'
                        error={errors?.classificationCapsules}
                        filterIds={assignedIds}
                        pushSelectedClass={setSelectedClass}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    const { classificationDescription, classificationName } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />

            <Input
                grpClass='mb-3'
                type='text'
                name='classificationName'
                label='Name of classification'
                labelClass='text-dark fw-medium'
                id='classificationName'
                placeholder='Enter name of classification'
                value={classificationName}
                onChange={handleChange}
                isErr={errors?.classificationName}
                errMssg={errors?.classificationName}
                autoFocus={true}
            />

            <Textarea
                grpClass='mb-3'
                type='text'
                name='classificationDescription'
                label='Describe your class'
                labelClass='text-dark fw-medium'
                id='classificationDescription'
                placeholder='Add a description to this class you are creating'
                value={classificationDescription}
                onChange={handleChange}
                isErr={errors?.classificationDescription}
                errMssg={errors?.classificationDescription}
            />

            <div className='form-group'>{renderBasedOnStatus()}</div>

            <ClassificationPriority request={request} setRequest={setRequest} />

            <Button
                type='submit'
                text={"Save class"}
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

export default ClassificationForm;
