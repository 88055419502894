import { useRef, useState, useEffect } from "react";
import BuilderElementsList from "./BuilderElementsList/BuilderElementsList";
import FormBuilderForm from "./FormBuilderForm/FormBuilderForm";
import FormSetupHeader from "./FormSetupHeader/FormSetupHeader";
import { DragDropContext } from "react-beautiful-dnd";
import { generateID, reOrderList } from "utils/helper";
import BreadCrumbHeader from "components/ui/BreadCrumbHeader/BreadCrumbHeader";
import { END_FORM_COLLECTION } from "../AddBranchCollectionModal/enums";
import AddBranchModal from "components/dashboard/TrainSAM/SAM/modals/AddBranchModal/AddBranchModal";
import "./FormSetup.scss";

const formId = "droppable";

const formElementDefault = {
    elementLabel: "",
    enableSubTitleText: false,
    customRules: [],
    subText: "",
    elementOptions: [],
    media: [],
};

const FormSetup = ({ isViewForm, formData }) => {
    const [showCollectionModal, toggleBranchCollectionModal] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [request, setRequest] = useState({
        formName: "",
        formElements: [],
    });

    const [errors, setErrors] = useState({});
    const dragItem = useRef();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleDragStart = (e, item) => {
        dragItem.current = item;
        setTimeout(() => setDragging(true), 0);
    };

    useEffect(() => {
        if (isViewForm) {
            setRequest({
                formName: formData?.formName,
                formElements: formData?.formElements.map(
                    ({ elementId, elementOptions, media, ...element }) => ({
                        id: elementId,
                        elementId,
                        elementOptions:
                            elementOptions?.length > 0
                                ? elementOptions.map((text) => ({
                                      id: generateID(),
                                      text,
                                  }))
                                : elementOptions,
                        media: media ? media : [],
                        ...element,
                    })
                ),
            });
        }
        // eslint-disable-next-line
    }, []);

    const handleFileAttachmentChange = (elementId, value) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            formElements: [
                ...prevRequest?.formElements?.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              media: [...el?.media, value],
                          }
                        : el;
                }),
            ],
        }));
    };

    const handleFileAttachmentDelete = (elementId, link) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              media: [
                                  ...el?.media.filter(
                                      (attachment) => attachment?.link !== link
                                  ),
                              ],
                          }
                        : el;
                }),
            ],
        });
    };

    const handleFormInputChange = (elementId, name, value) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              [name]: value,
                          }
                        : el;
                }),
            ],
        });
        setErrors({
            ...errors,
            [elementId]: { ...errors[elementId], [name]: "" },
        });
    };

    const handleOptionAdd = (elementId) => {
        const newOption = {
            id: generateID(),
            text: "",
        };

        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              elementOptions: [
                                  ...el?.elementOptions,
                                  newOption,
                              ],
                          }
                        : el;
                }),
            ],
        });
    };

    const handleInputDelete = (elementId) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.filter((el) => {
                    return el.id !== elementId;
                }),
            ],
        });
    };

    const handleOptionDelete = (elementId, optionId) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              elementOptions: [
                                  ...el?.elementOptions.filter(
                                      (opt) => opt.id !== optionId
                                  ),
                              ],
                          }
                        : el;
                }),
            ],
        });
    };

    const handleOptionTextChange = (elementId, optionId, text) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.id === elementId
                        ? {
                              ...el,
                              elementOptions: [
                                  ...el?.elementOptions.map((opt) =>
                                      opt.id === optionId
                                          ? { ...opt, text }
                                          : opt
                                  ),
                              ],
                          }
                        : el;
                }),
            ],
        });

        setErrors({
            ...errors,
            [elementId]: {
                ...errors[elementId],
                elementOptions: errors?.[elementId]?.elementOptions?.filter(
                    (elementOptionId) => elementOptionId !== optionId
                ),
            },
        });
    };

    const handleSubTextStatusChange = (elementId, status) => {
        if (status === false) {
            handleFormInputChange(elementId, "subText", "");
        }
        handleFormInputChange(elementId, "enableSubTitleText", status);
    };

    const acceptNewInputRule = (inputId, ruleCode, constraintData) => {
        const { formElements } = request;
        const newFormElements = formElements.map((input) =>
            input.id === inputId
                ? {
                      ...input,
                      customRules: [
                          ...input?.customRules,
                          {
                              ruleCode,
                              ruleConstraint: "",
                              constraintData,
                          },
                      ],
                  }
                : input
        );
        setRequest({ ...request, formElements: newFormElements });
    };

    const removeInputRule = (inputId, ruleCode) => {
        const { formElements } = request;
        const newFormElements = formElements.map((input) =>
            input.id === inputId
                ? {
                      ...input,
                      customRules: [
                          ...(input?.customRules).filter(
                              (x) => x.ruleCode !== ruleCode
                          ),
                      ],
                  }
                : input
        );
        setRequest({ ...request, formElements: newFormElements });
    };

    const handleValidateCustomRule = (
        elementId,
        customRules,
        setActiveRule
    ) => {
        if (errors?.[elementId]?.customRules?.length > 0) {
            const filteredCustomRules = errors?.[
                elementId
            ]?.customRules?.filter((customRule) => customRule !== "");

            if (filteredCustomRules?.length > 0) {
                setActiveRule(
                    customRules[filteredCustomRules[0]]?.ruleCode,
                    customRules[filteredCustomRules[0]]?.ruleConstraint,
                    filteredCustomRules[0]
                );
            }
        }
    };

    const handleRemoveCustomRuleError = (elementId, ruleIndex) => {
        setErrors({
            ...errors,
            [elementId]: {
                ...errors[elementId],
                customRules: errors?.[elementId]?.customRules?.filter(
                    (customRule) => customRule !== ruleIndex
                ),
            },
        });
    };

    const handleConstraintEdit = (inputId, ruleCode, value) => {
        const { formElements } = request;
        const newFormElements = formElements.map((input) =>
            input.id === inputId
                ? {
                      ...input,
                      customRules: [
                          ...input?.customRules?.map((customRule) =>
                              customRule?.ruleCode === ruleCode
                                  ? {
                                        ...customRule,
                                        ruleConstraint: value,
                                    }
                                  : customRule
                          ),
                      ],
                  }
                : input
        );
        setRequest({ ...request, formElements: newFormElements });
    };

    const handleToggleBranchModal = () => {
        toggleBranchCollectionModal(true);
    };

    const handleBranchCollectionAddition = (branchObj) => {
        setRequest({
            ...request,
            formElements: [
                ...request.formElements.map((el) => {
                    return el.elementType === "END_FORM"
                        ? {
                              ...el,
                              branch: branchObj,
                              branchId: branchObj?.branchId,
                          }
                        : el;
                }),
            ],
        });
        setErrors({ ...errors, branch: undefined });

        toggleBranchCollectionModal(false);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const {
            destination: { droppableId: destinationId },
            source: { droppableId: sourceId },
        } = result || {};

        if (destinationId === sourceId) {
            if (result.destination.index === result.source.index) return;

            const formElements = reOrderList(
                request.formElements,
                result.source.index,
                result.destination.index
            );
            // this is used to ensure End Form element is always at the bottom
            const existingEndForm = formElements.find(
                (x) => x.elementType === "END_FORM"
            );
            const allFormElements = existingEndForm
                ? [
                      ...formElements.filter(
                          (item) => item.elementType !== "END_FORM"
                      ),
                      existingEndForm,
                  ]
                : formElements;
            setRequest({ ...request, formElements: allFormElements });
        } else {
            const { draggableId } = result;
            const buildingFormElementList = [
                ...request?.formElements,
                {
                    ...formElementDefault,
                    elementType: draggableId,
                    id: generateID(),
                },
            ];
            // this is used to ensure End Form element is always at the bottom
            const prevEndFormElement = buildingFormElementList.find(
                (x) => x.elementType === "END_FORM"
            );
            const newFormElements = prevEndFormElement
                ? [
                      ...buildingFormElementList.filter(
                          (item) => item.elementType !== "END_FORM"
                      ),
                      prevEndFormElement,
                  ]
                : buildingFormElementList;
            setRequest({
                ...request,
                formElements: newFormElements,
            });
        }
    };

    const { formElements } = request;

    return (
        <>
            <BreadCrumbHeader
                prevPage='/knowledge-base/forms'
                prevPageTitle='Forms'
                currentPageTitle={
                    isViewForm ? formData?.formName : "Create New"
                }
            />
            <div className='create__forms'>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div>
                        <div className='create__forms--container'>
                            <div className='col-8'>
                                <div className='create__forms--drop__section'>
                                    <FormSetupHeader
                                        requestData={request}
                                        setErrors={setErrors}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        isViewForm={isViewForm}
                                    />
                                    <FormBuilderForm
                                        formElements={formElements}
                                        formId={formId}
                                        errors={errors}
                                        handleFormInputChange={
                                            handleFormInputChange
                                        }
                                        acceptNewInputRule={acceptNewInputRule}
                                        handleConstraintEdit={
                                            handleConstraintEdit
                                        }
                                        removeInputRule={removeInputRule}
                                        handleSubTextStatusChange={
                                            handleSubTextStatusChange
                                        }
                                        handleInputDelete={handleInputDelete}
                                        handleOptionAdd={handleOptionAdd}
                                        handleOptionDelete={handleOptionDelete}
                                        handleOptionTextChange={
                                            handleOptionTextChange
                                        }
                                        toggleBranchModal={
                                            handleToggleBranchModal
                                        }
                                        handleFileAttachmentChange={
                                            handleFileAttachmentChange
                                        }
                                        handleFileAttachmentDelete={
                                            handleFileAttachmentDelete
                                        }
                                        handleValidateCustomRule={
                                            handleValidateCustomRule
                                        }
                                        handleRemoveCustomRuleError={
                                            handleRemoveCustomRuleError
                                        }
                                    />
                                </div>
                            </div>
                            <BuilderElementsList
                                dragging={dragging}
                                handleDragStart={handleDragStart}
                                errors={errors}
                            />
                        </div>
                    </div>
                </DragDropContext>
            </div>

            {showCollectionModal && (
                <AddBranchModal
                    show={showCollectionModal}
                    closeModal={() => toggleBranchCollectionModal(false)}
                    source={END_FORM_COLLECTION}
                    handleBranchAddSuccess={handleBranchCollectionAddition}
                />
            )}
        </>
    );
};

export default FormSetup;
