import NotificationMessage from "./NotificationMessage/NotificationMessage";
import NotificationSectionHeader from "./NotificationSectionHeader/NotificationSectionHeader";

const NotificationBody = ({ groupedMessagesList, selectedCategory }) => {
    return (
        <>
            {groupedMessagesList?.map((eachMessage, key) => {
                return (
                    eachMessage.data.length > 0 && (
                        <div key={key}>
                            <NotificationSectionHeader
                                indicator={eachMessage.groupName}
                            />
                            {eachMessage.data.map((item, pos) => (
                                <NotificationMessage
                                    {...item}
                                    selectedCategory={selectedCategory}
                                    key={pos}
                                />
                            ))}
                        </div>
                    )
                );
            })}
        </>
    );
};

export default NotificationBody;
