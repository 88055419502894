import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { Redirect } from "react-router-dom";
import { FOLLOW_UP_TABLE_ACTIONS } from "../enum";
import { apiRoute } from "services";
import CreateGuideModal from "components/dashboard/TrainSAM/Guides/modals/CreateGuideModal/CreateGuideModal";

const {
    DELETE_FOLLOW_UP_COLLECTION,
    VIEW_FOLLOW_UP_COLLECTION,
    CREATE_FOLLOW_UP_COLLECTION,
} = FOLLOW_UP_TABLE_ACTIONS;

const FollowUpModals = ({
    actionType,
    showModal,
    setShowModal,
    selectedCapsuleEntry,
    handleSuccess,
    solutionCapsule,
}) => {
    const renderBasedOnActionType = () => {
        switch (actionType) {
            case DELETE_FOLLOW_UP_COLLECTION:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${selectedCapsuleEntry?.attachedFollowUpName}`}
                        show={showModal}
                        closeModal={() => setShowModal(false)}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.deleteFollowUpCollection(
                            selectedCapsuleEntry?.attachedFollowUpId
                        )}
                        method='delete'
                    />
                );

            case CREATE_FOLLOW_UP_COLLECTION:
                return (
                    <CreateGuideModal
                        showModal={showModal}
                        closeModal={() => setShowModal(false)}
                        handleSuccess={handleSuccess}
                        solutionCapsule={solutionCapsule}
                        isSolutionGuide
                    />
                );

            case VIEW_FOLLOW_UP_COLLECTION:
                return (
                    <Redirect
                        to={`/t-sam/guide/view/${selectedCapsuleEntry?.attachedFollowUpId}`}
                    />
                );

            default:
                return "";
            // return (
            //     <Redirect
            //         to={`/agents-inbox/follow-up/${selectedCapsuleEntry?.attachedFollowUpId}`}
            //     />
            // );
        }
    };

    return <>{renderBasedOnActionType()}</>;
};

export default FollowUpModals;
