export const ticketTaskStatuses = [
    {
        label: "PENDING",
        value: "PENDING",
    },
    { label: "TO DO", value: "TO_DO" },
    { label: "COMPLETED", value: "COMPLETED" },
];

export const taskStatus = {
    PENDING: "PENDING",
    TODO: "TO_DO",
    COMPLETED: "COMPLETED",
};

export const taskStatusNames = {
    PENDING: "Pending Tasks",
    TO_DO: "To Do Tasks",
    COMPLETED: "Completed Tasks",
};
