import { appMessageSources } from "../enums";
import "./MessageContent.scss";

const { IN_APP } = appMessageSources;

const MessageContent = ({
    isReceivedMessage,
    messageContent,
    messageSource,
}) => {
    const receipientClass = isReceivedMessage ? "received" : "sent";
    const messageSourceClass =
        messageSource === IN_APP && isReceivedMessage ? "" : "other";

    return (
        <>
            {messageContent !== "" && (
                <div
                    className={`message__content ${receipientClass} ${messageSourceClass}`}
                    dangerouslySetInnerHTML={{ __html: messageContent }}>
                </div>
            )}
        </>
    );
};

export default MessageContent;
