import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useDispatch, useSelector } from "react-redux";
import { saveCapsuleClassess } from "store/capsule/actions";
import CapsuleStatusCardGroup from "./CapsuleStatusCardGroup/CapsuleStatusCardGroup";
import CapsuleStatusListing from "./CapsuleStatusListing/CapsuleStatusListing";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const DependencyCapsulesContainer = () => {
    const dispatch = useDispatch();
    const storedCapsuleClasses = useSelector((state) => state.capsule);

    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [capsuleClasses, setCapsuleClasses] = useState([]);
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState();

    const [capsulesTotalCount, updateCapsulesTotalCount] = useState(0);

    const [refresh, triggerRefresh] = useState(false);

    const handleRefresh = () => {
        triggerRefresh(!refresh);
    };

    const handleCapsulesTotalCount = (capsuleCount) => {
        updateCapsulesTotalCount((prevState) => capsuleCount + prevState);
    };

    const handleClassSelect = (selectedClass) => {
        updateCapsulesTotalCount(0);
        setSelectedCapsuleClass(selectedClass);
    };

    const getCapsuleClasses = async (httpRequest) => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url = apiRoute?.getCapsuleClasses;

            const res = await API.get(url, { signal: httpRequest?.signal });
            if (res.status === 200) {
                setCapsuleClasses(res.data.data);
                setSelectedCapsuleClass(res.data.data[0]);
                setStatus(DATAMODE);
                dispatch(saveCapsuleClassess(res.data.data));
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <CapsuleStatusCardGroup
                            selectedCapsuleClass={selectedCapsuleClass}
                            handleCapsulesTotalCount={handleCapsulesTotalCount}
                            refresh={refresh}
                        />
                        <CapsuleStatusListing
                            handleClassSelect={handleClassSelect}
                            selectedCapsuleClass={selectedCapsuleClass}
                            capsuleClasses={capsuleClasses}
                            getCapsuleClasses={getCapsuleClasses}
                            hasCapsuleClassSelect={true}
                            capsulesTotalCount={capsulesTotalCount}
                            handleRefresh={handleRefresh}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        if (storedCapsuleClasses?.length > 0) {
            setCapsuleClasses(storedCapsuleClasses);
        } else {
            getCapsuleClasses(httpRequest);
        }
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='padded__layout'>
                <div className='dependency'>{renderBasedOnStage()}</div>
            </div>
        </>
    );
};

export default DependencyCapsulesContainer;
