import { useState, useEffect } from "react";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { Loading } from "components/ui";
import EmptyContentBlock from "./EmptyContentBlock/EmptyContentBlock";
import CapsuleContentBlocksList from "./CapsuleContentBlocksList/CapsuleContentBlocksList";

const { IDLE, LOADING, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleContentBlocks = ({ capsuleData, reLoadView, hideHeader }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [capsuleContentBlocks, updateCapsuleContentBlock] = useState([]);

    const getCapsuleContents = async (httpRequest) => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const { capsule: { capsuleClassId } = {}, contentId } =
                capsuleData || {};
            const url = apiRoute?.getCapsuleContentBlocks(
                capsuleClassId,
                contentId
            );
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                updateCapsuleContentBlock(data);
            }
        } catch (err) {
            if (!httpRequest?.signal?.aborted) {
                setErrorMssg(getErrorMessage(err));
                setStatus(ERROR);
            }
        }
    };

    //
    useEffect(() => {
        let httpRequest = new AbortController();
        getCapsuleContents(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capsuleData?.contentId]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <CapsuleContentBlocksList
                        contentBlocks={capsuleContentBlocks}
                        reLoadView={reLoadView}
                        hideHeader={hideHeader}
                        handleSuccess={getCapsuleContents}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleContents}
                    />
                );

            case NULLMODE:
                return <EmptyContentBlock />;
            default:
                return "";
        }
    };

    return <div>{renderBasedOnStatus()}</div>;
};

export default CapsuleContentBlocks;
