import { motion } from "framer-motion";
import { useRef } from "react";
import Message from "../Message/Message";
import { useDeepEffect } from "utils/helper";

const transition = {
    type: "spring",
    stiffness: 200,
    mass: 0.2,
    damping: 20,
};

const variants = {
    initial: {
        opacity: 0,
        y: 30,
    },
    enter: {
        opacity: 1,
        y: 0,
        transition,
    },
};

const MessagesThread = ({
    messages,
    customer,
    openPreviewModal,
    handleResendMessage,
    selectedCustomerMessage,
    selectCustomerMessage,
}) => {
    const messageThread = useRef();

    const scrollToBottom = () => {
        messageThread.current.scrollTop = messageThread.current?.scrollHeight;
    };

    useDeepEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <ol className='message-thread custom-scroll-bar' ref={messageThread}>
            {messages.map((message, i) => {
                return (
                    <motion.li
                        key={message?.messageId}
                        initial='initial'
                        animate='enter'
                        variants={variants}
                        transition={{ duration: 0.2, delay: 0 }}
                        layout>
                        <Message
                            data={message}
                            customer={customer}
                            openPreviewModal={openPreviewModal}
                            handleResendMessage={handleResendMessage}
                            selectedCustomerMessage={selectedCustomerMessage}
                            selectCustomerMessage={selectCustomerMessage}
                        />
                    </motion.li>
                );
            })}
        </ol>
    );
};

export default MessagesThread;
