import React from "react";
import EmailInput from "../EmailInput/EmailInput";
import "./CopyRecipient.scss";

const CopyRecipient = ({
    copyType,
    setShowCopy,
    emailList,
    handleRecipientEmail,
    handleRemoveEmail,
    disabled,
}) => {
    return (
        <>
            <span
                className='copy__recipient'
                onClick={() => setShowCopy((prev) => !prev)}>
                {copyType}:{" "}
            </span>
            <EmailInput
                emailList={emailList}
                handleRecipientEmail={handleRecipientEmail}
                handleRemoveEmail={handleRemoveEmail}
                disabled={disabled}
            />
        </>
    );
};

export default CopyRecipient;
