import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import PreHeading from "components/ui/PreHeading/PreHeading";
import { useState, useEffect } from "react";
import { dataQueryStatus } from "utils";
import FollowUpTasksViewHeading from "./FollowUpTasksViewHeading/FollowUpTasksViewHeading";
import FollowUpTasksViewListing from "./FollowUpTasksViewListing/FollowUpTasksViewListing";
import FollowUpTaskViewModals from "./modals/FollowUpTaskViewModals/FollowUpTaskViewModals";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import "./FollowUpTasksView.scss";

const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const FollowUpTasksView = ({ match }) => {
    const { params: { followUpId } = {} } = match || {};

    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState();

    const [status, setStatus] = useState(DATAMODE);
    const [errorMssg, setErrorMssg] = useState();

    const [followUpData, setFollowUpData] = useState({
        classCapsuleEntryId: "",
        followupId: "",
        name: "",
        tasks: [],
    });

    const [selectedFollowUpTask, setSelectedFollowUpTask] = useState({});

    const handleActionModal = (actionType, followUpTask) => {
        setSelectedFollowUpTask(followUpTask);
        setActionType(actionType);
        setShowModal(true);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return (
                    <div>
                        <Empty
                            className='follow-up-task-view__empty'
                            text='No task has been created in this follow up collection yet.'
                        />
                    </div>
                );
            case DATAMODE:
                return (
                    <FollowUpTasksViewListing
                        handleActionModal={handleActionModal}
                        followUpTasks={followUpData?.tasks}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;
            default:
                return "";
        }
    };

    const getFollowUpTasks = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url = apiRoute?.getFollowUpTasks(followUpId);

            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;
                setFollowUpData(data);
                data?.tasks?.length > 0
                    ? setStatus(DATAMODE)
                    : setStatus(NULLMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        getFollowUpTasks();
        //eslint-disable-next-line
    }, [followUpId]);

    return (
        <>
            <PreHeading
                text='Followup Collections'
                subText={followUpData?.name ? followUpData?.name : "Follow Up"}
                url={"/agents-inbox/follow-up"}
            />
            <div className='padded__layout pt-3'>
                <div className='agent-follow-up follow-up-task-view'>
                    <FollowUpTasksViewHeading
                        handleActionModal={handleActionModal}
                        followUpData={followUpData}
                    />
                    {renderBasedOnStatus()}
                </div>
            </div>

            {showModal && (
                <FollowUpTaskViewModals
                    showModal={showModal}
                    setShowModal={setShowModal}
                    actionType={actionType}
                    getFollowUpTasks={getFollowUpTasks}
                    selectedFollowUpTask={selectedFollowUpTask}
                />
            )}
        </>
    );
};

export default FollowUpTasksView;
