import React from "react";
import { InputSearch } from "components/ui";
import AddUserButton from "components/ui/Button/AddUserButton/AddUserButton";
import { baseResources, baseResourceActions } from "routes/enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;

const TeamMemberTableHeader = ({ searchValue, setSearchValue, openModal }) => {
    return (
        <div className='d-flex align-items-center justify-content-between mb-4'>
            <InputSearch
                id='search'
                name='search'
                placeholder='Search member here'
                grpClass='search-input--280'
                onChange={setSearchValue}
                value={searchValue}
            />
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                <AddUserButton btnText='Invite member' onClick={openModal} />
            </ComponentAccessControl>{" "}
        </div>
    );
};

export default TeamMemberTableHeader;
