import CapsuleClasses from "components/dashboard/KnowledgeBase/CapsuleSettings/CapsuleClasses/CapsuleClasses";
import Heading from "components/ui/Heading/Heading";
import "./GuideDowntimeCapsules.scss";


const GuideDowntimeCapsules =  ({
    capsuleClasses,
    selectedCapsuleClass,
    selectCapsuleClass,
}) => {
    return (
        <>
            <Heading
                headingText={"Downtime Guides"}
                headingSubText='Add & change downtime guides'
            />

            <CapsuleClasses
                data={capsuleClasses}
                selectedClassId={selectedCapsuleClass?.capsuleClassId}
                hideAddClassBtn={true}
                selectCapsuleClass={selectCapsuleClass}
            />
        </>
    );
};

export default GuideDowntimeCapsules;
