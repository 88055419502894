import PreHeading from "components/ui/PreHeading/PreHeading";

const ViewSwitchesHeader = ({ switchType }) => {
    return (
        <PreHeading
            url='/dependency/switches/'
            text='Switches'
            subText={`${switchType} Switches`}
        />
    );
};

export default ViewSwitchesHeader;
