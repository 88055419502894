import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    roles: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_ROLES_LIST:
            return {
                ...state,
                roles: getUniqueListBy(
                    [...state.roles, ...payload],
                    "roleCode"
                ),
            };

        case types.SET_ROLES_LIST:
            return { ...state, roles: payload };

        case types.UPDATE_ROLE:
            const roles = state.roles?.map(({ roleCode, ...rest }) => {
                return roleCode === payload?.roleCode
                    ? payload
                    : { roleCode, ...rest };
            });
            return { ...state, roles };

        case types.DELETE_ROLE:
            const updatedRoles = state.roles?.filter(
                (x) => x.roleCode !== payload?.roleCode
            );
            return { ...state, roles: updatedRoles };

        default:
            return state;
    }
};

export default AuthReducer;
