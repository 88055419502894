import { useState } from "react";
import PropTypes from "prop-types";
import CannedResponseButton from "./CannedResponseButton/CanedResponseButton";
import CreateCannedResponseModal from "./CreateCannedResponseModal/CreateCannedResponseModal";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CannedResponseHeader.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const CannedResponseHeader = ({ handleSuccess, selectedMssgTagId }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className='canned__response__header'>
                <h1 className='canned__response__header--text'>
                    Canned responses
                </h1>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <CannedResponseButton
                        openModal={() => setShowModal(true)}
                    />
                </ComponentAccessControl>{" "}
            </div>
            {showModal && (
                <CreateCannedResponseModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    handleSuccess={handleSuccess}
                    selectedMssgTagId={selectedMssgTagId}
                />
            )}
        </>
    );
};

CannedResponseHeader.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default CannedResponseHeader;
