import TDCannedResponsesBody from "./TDCannedResponsesBody/TDCannedResponsesBody";
import TDCannedResponsesHeading from "./TDCannedResponsesHeading/TDCannedResponsesHeading";

const TDCannedResponses = ({ cannedResponses, handleSelectTagResp }) => {
    return (
        <>
            <div className='analyzer-message-tags'>
                <TDCannedResponsesHeading />
                <TDCannedResponsesBody
                    cannedResponses={cannedResponses}
                    handleSelectTagResp={handleSelectTagResp}
                />
            </div>
        </>
    );
};

export default TDCannedResponses;
