import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import BreadCrumbHeader from "components/ui/BreadCrumbHeader/BreadCrumbHeader";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { setExistingBranchOptionConnections } from "components/dashboard/Branches/BranchView/utils/helper";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const ConcludingBranchView = ({ location }) => {
    const [branchViewStatus, setBranchViewStatus] = useState(IDLE);
    const [bvErrorMssg, setBVErrorMssg] = useState("");
    const [selectedBranch, setSelectedBranch] = useState();
    const [existingOptionConnections, setExistingOptionConnections] = useState(
        {}
    );

    const handleBranchSelect = async (branchId) => {
        try {
            setSelectedBranch({ branchId });
            setBranchViewStatus(LOADING);
            const url = apiRoute?.getBranch(branchId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;

                setSelectedBranch(data);
                setBranchViewStatus(DATAMODE);
                setExistingBranchOptionConnections(
                    data,
                    setExistingOptionConnections
                );
            }
        } catch (err) {
            setBVErrorMssg(getErrorMessage(err));
            setBranchViewStatus(ERROR);
        }
    };

    const retrieveAvailableCollection = () => {
        const availableCollection = location?.state?.branchId;
        if (availableCollection) {
            handleBranchSelect(availableCollection);
        }
    };

    useEffect(() => {
        retrieveAvailableCollection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <BreadCrumbHeader
                prevPageTitle={"Form"}
                currentPageTitle={"Concluding Branch"}
                useGoBack
            />
            <div className='meta__container py-3'>
                {selectedBranch && (
                    <BranchView
                        branch={selectedBranch}
                        handleRefresh={(branchId) =>
                            handleBranchSelect(branchId)
                        }
                        status={branchViewStatus}
                        errorMssg={bvErrorMssg}
                        existingOptionConnections={existingOptionConnections}
                    />
                )}
            </div>
        </div>
    );
};

export default ConcludingBranchView;
