import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import AttachmentFileInput from "components/ui/AttachmentFileInput/AttachmentFileInput";
import { useDropzone } from "react-dropzone";
import "./FileDropZone.scss";

const FileDropZone = ({ onDrop }) => {
    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".png", ".jpeg", ".jpg"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
            "text/plain": [".txt"],
        },
        maxFiles: 5,
        noClick: true,
        noKeyboard: true,
    });

    return (
        <div
            className='file__email__attachment--upload__area'
            {...getRootProps()}>
            <div className='drag__and__drop__container'>
                <ReactSVG src={imageLinks?.icons?.cloudUpload} />
                <p className='drag__and__drop__cta'>
                    Drag and drop files or{" "}
                    <span className='browse__file' onClick={open}>
                        Browse
                    </span>
                    <AttachmentFileInput {...getInputProps({})} />
                </p>
                <span className='max__file__size'>
                    {isDragActive ? `Drop files here` : `Maximum size is 5MB`}
                </span>
            </div>
        </div>
    );
};

export default FileDropZone;
