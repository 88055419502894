import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import FileUploader from "../common/FileUploader/FileUploader";

const { ERROR } = dataQueryStatus;

const AddContentVideoForm = ({
    selectedFormat,
    capsuleData,
    handleSuccess,
    editItem,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [request, updateRequest] = useState({
        capsuleClassId: capsuleData?.capsule?.capsuleClassId,
        classCapsuleEntryId: capsuleData?.contentId,
        content: editItem?.content || "",
        contentFile: editItem?.contentFileUrl || "",
        contentType: selectedFormat,
        contentCaption: editItem?.caption || "",
    });
    const [disabled, setDisabled] = useState(
        editItem?.contentFileUrl ? false : true
    );
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        if (name === "contentCaption") {
            updateRequest((prevRequest) => ({
                ...prevRequest,
                content: value,
            }));
        }
        setErrors({ ...errors, [name]: "" });
    };

    const createCapsuleContentVideoBlock = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();
            Object.keys(request).forEach((key) => {
                formData.append(key, request[key]);
            });
            const url = apiRoute.createCapsuleContentBlock;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editCapsuleContentVideoBlock = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const formData = new FormData();
            formData.append("content", request.content);
            formData.append("contentFile", request.contentFile);
            formData.append("contentType", request.contentType);
            formData.append("contentCaption", request.contentCaption);

            const url = apiRoute.capsuleContentBlock(editItem?.contentId);
            const res = await API.put(url, formData);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            if (editItem) {
                return editCapsuleContentVideoBlock();
            }
            return createCapsuleContentVideoBlock();
        } else {
            setErrors(formErrors);
        }
    };

    const uploadVideo = (e) => {
        const { files, name } = e.target;
        if (files?.length) {
            const uploadedVideo = files?.[0];
            if (uploadedVideo?.size > 5242880)
                return toastMessage("File too large. Max of 5MB", ERROR);
            updateRequest({ ...request, [name]: uploadedVideo });
            setErrors({ ...errors, [name]: "" });
            setDisabled(false);
        }
    };

    const clearVideo = () => {
        updateRequest({
            ...request,
            contentFile: "",
            contentCaption: "",
            content: "",
        });
        setErrors({});
        setDisabled(true);
    };

    const { contentFile, contentCaption } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <FileUploader
                fileType='video/*'
                contentFile={contentFile}
                contentCaption={contentCaption}
                handleChange={handleChange}
                errors={errors}
                upload={uploadVideo}
                clear={clearVideo}
                fileSize={10}
            />
            <Button
                type='submit'
                text='Save video file'
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
                disabled={
                    disabled ||
                    Boolean(errors?.contentFile || errors?.contentCaption)
                }
            />
        </form>
    );
};

AddContentVideoForm.prototypes = {
    selectedFormat: PropTypes.string,
    capsuleData: PropTypes.object,
    handleSuccess: PropTypes.func,
    editItem: PropTypes.object,
};

export default AddContentVideoForm;
