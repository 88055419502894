import {
    ticketPhases,
    ticketsClassifications,
    otherCategories,
} from "components/dashboard/AgentInbox/enum";

const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;
const { HIGH, MEDIUM, LOW } = ticketsClassifications;
const { MENTIONS } = otherCategories;

export const ticketsFilterBuilder = Object.freeze({
    [ISSUE_DISCOVERY]: {
        title: "Issue Discovery",
        countKey: "issueDiscoveryCount",
        activeStyles: {
            fillColor: "#0878E9",
            subFillColor: "#4FA5FC",
        },
    },
    [PROBLEM_CONFIRMATION]: {
        title: "Problem Confirmation",
        countKey: "problemConfirmationCount",
        activeStyles: {
            fillColor: "#F49E4C",
            subFillColor: "#FDBE82",
        },
    },
    [SOLUTION_DELIVERY]: {
        title: "Solution Delivery",
        countKey: "solutionDeliveryCount",
        activeStyles: {
            fillColor: "#25BB87",
            subFillColor: "#4EDBAA",
        },
    },
    [MENTIONS]: {
        title: "Mentions",
        countKey: "isMentioned",
        activeStyles: {
            fillColor: "#f25A68",
            subFillColor: "#f5AEb4",
        },
    },
    // [FOLLOW_UP]: {
    //     title: "Follow up",
    //     countKey: "followup",
    //     activeStyles: {
    //         fillColor: "#0A42AE",
    //         subFillColor: "#296CEE",
    //     },
    // },
    [HIGH]: {
        title: "High Priority",
        countKey: "high",
    },
    [MEDIUM]: {
        title: "Mid Priority",
        countKey: "medium",
    },
    [LOW]: {
        title: "Low Priority",
        countKey: "low",
    },
});
