import PropTypes from "prop-types";
import AsyncSearchForm from "components/ui/InputTypes/InputSearch/AsyncSearchForm/AsyncSearchForm";

const SearchMessageTag = ({ handleSearch,searchDefaultValue }) => {
    return (
        <AsyncSearchForm
            setData={handleSearch}
            placeholder='Search tag here'
            endpoint='getMessageTags'
            defaultValue={searchDefaultValue}
        />
    );
};

SearchMessageTag.prototypes = {
    fetchMessageTags: PropTypes.func.isRequired,
};

export default SearchMessageTag;
