import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiRoute } from "services";
import API from "services/lib/api";
import { Input, Button, GeneralLayout } from "components/ui";
import { WorkspaceNameInput } from "./WorkspaceName/WorkspaceName";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import FadeIn from "components/ui/effects/FadeIn";
import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import AuthFooter from "../AuthFooter/AuthFooter";
import "./SignUp.scss";

const initialValues = {
    workspaceUrlSlug: "",
    workspaceName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
};
const { IDLE, LOADING } = dataQueryStatus;

export const SignUp = (props) => {
    const [request, setRequest] = useState(initialValues);
    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});
    const [isAvailable, setAvailable] = useState(null);
    const [status, setStatus] = useState(IDLE);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const history = useHistory();

    const createWorkspace = async () => {
        try {
            setErrorMsg();
            setStatus(LOADING);

            const url = apiRoute.signup;
            const res = await API.post(url, { ...request });
            if (res.status === 201) {
                const { userId } = res.data.data;
                history.replace({
                    pathname: "/verify-email",
                    state: {
                        user: res.data.data,
                        userId,
                    },
                });
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setStatus(IDLE);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // un-comment to mock flow
        // history.replace({
        // 	pathname: "/verify-email",
        // 	state: {
        // 		user: {
        // 			userId: "896896986"
        // 		},
        // 	}
        // })

        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid && isAvailable) {
            createWorkspace();
        }
        setErrors(formErrors);
    };

    const { firstName, lastName, email, password } = request;

    return (
        <>
            <SEO title={"Sign Up"} />
            <GeneralLayout>
                <FadeIn>
                    <div className='signup'>
                        <h1>Sign up your team</h1>
                        <ErrorDialog
                            show={Boolean(errorMsg)}
                            message={errorMsg}
                            hide={() => setErrorMsg()}
                        />

                        <form onSubmit={handleSubmit}>
                            <WorkspaceNameInput
                                {...{
                                    request,
                                    setRequest,
                                    errors,
                                    setErrors,
                                    handleChange,
                                    isAvailable,
                                    setAvailable,
                                }}
                            />
                            <Input
                                grpClass='mb-3'
                                type='text'
                                name='firstName'
                                label='First Name'
                                labelClass='text-dark fw-bold'
                                id='firstName'
                                placeholder='Enter your first name'
                                value={firstName}
                                onChange={handleChange}
                                isErr={errors?.firstName}
                                errMssg={errors?.firstName}
                            />
                            <Input
                                grpClass='mb-3'
                                type='text'
                                name='lastName'
                                label='Last Name'
                                labelClass='text-dark fw-bold'
                                id='lastName'
                                placeholder='Enter your last name'
                                value={lastName}
                                onChange={handleChange}
                                isErr={errors?.lastName}
                                errMssg={errors?.lastName}
                            />
                            <Input
                                grpClass='mb-3'
                                type='email'
                                name='email'
                                label='Email address'
                                labelClass='text-dark fw-bold'
                                id='email'
                                placeholder='Enter your email address'
                                value={email}
                                onChange={handleChange}
                                isErr={errors?.email}
                                errMssg={errors?.email}
                            />
                            <Input
                                grpClass='mb-3'
                                type='password'
                                name='password'
                                label='Password'
                                labelClass='text-dark fw-bold'
                                id='password'
                                placeholder='Enter your password'
                                value={password}
                                onChange={handleChange}
                                isErr={errors?.password}
                                errMssg={errors?.password}
                                fieldType='password'
                                showPwd
                            />
                            <ul>
                                <li className='text-muted'>
                                    8 characters, 1 number, 1 special character
                                </li>
                            </ul>
                            <Button
                                type='submit'
                                text='Sign Up'
                                classType='primary'
                                // disabled={status === LOADING || !isAvailable}
                                loading={status === LOADING}
                                otherClass='my-3 w-100'
                            />

                            <div>
                                <AuthFooter
                                    url='/sign-in'
                                    actionText='Log in here'
                                    questionText='Already a member?'
                                />
                            </div>
                        </form>
                    </div>
                </FadeIn>
            </GeneralLayout>
        </>
    );
};
