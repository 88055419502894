import { apiRoute } from "services";
import API from "services/lib/api";
import { useEffect, useState } from "react";
import ExternalEmailClipboard from "./ExternalEmailClipboard/ExternalEmailClipboard";
import ExternalEmailThreads from "./ExternalEmailThreads/ExternalEmailThreads";
import ExternalNewEmail from "./ExternalNewEmail/ExternalNewEmail";
import "./ExternalEmails.scss";


const ExternalEmails = ({
    selectedTicket,
    handleToggleEmailPreview,
    handleToggleEmailAttachment,
    showClipboard,
    toggleEmailClipboard,
    refetchEmails,
    selectedEmailData,
    attachments
}) => {

    const [emails, setEmails] = useState([]);

    const getEmails = async (httpRequest) => {
        try {
            const { ticketId } = selectedTicket;
            if (!ticketId) {
                return;
            }
            const url = apiRoute?.externalEmail(ticketId);

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setEmails(data);

            }
        } catch (err) {

        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getEmails(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket, refetchEmails]);

    return (
        <div className='external__emails'>

            <ExternalEmailClipboard
                {...{
                    handleToggleEmailAttachment,
                    showClipboard,
                    toggleEmailClipboard,
                    attachments,
                    emails
                }}
            />
            <ExternalEmailThreads
                {...{
                    selectedTicket,
                    handleToggleEmailPreview,
                    refetchEmails,
                    selectedEmailData,
                    emails
                }}
            />
            <ExternalNewEmail handleToggleEmailPreview={handleToggleEmailPreview} />
        </div>
    );
};

export default ExternalEmails;
