import imageLinks from "assets/images";
import MessageTag from "components/dashboard/AgentInbox/AgentInboxSettings/MessageTagging/MessageTagList/MessageTag/MessageTag";
import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ItemsList from "components/ui/ItemsList/ItemsList";
import { dataQueryStatus } from "utils";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;


const SimilarConversations = ({
    status,
    issueList,
    errorMssg,
    getMessageTags
}) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return (
                    <div className='analyzer-tags-container__empty'>
                        <EmptyInfo
                            customIcon={imageLinks.icons.idleIcon}
                            message={
                                "No tag has been discovered yet, \n analyze a conversation to begin. "
                            }
                        />
                    </div>
                );

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <div className='analyzer-tags-container__empty'>
                        <EmptyInfo
                            customIcon={imageLinks.icons.idleIcon}
                            message={
                                "No tags that match the search found yet, \n analyze a conversation again to begin. "
                            }
                        />
                    </div>
                );

            case DATAMODE:
                return (
                    <ItemsList breakPoint={2}>
                        {issueList.map(({issueName}, index) => (
                            <MessageTag text={issueName} key={index}/>
                        ))}
                    </ItemsList>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getMessageTags}
                    />
                );

            default:
                return "";
        }
    };
    return (
        <>
            <div className='analyzer-tags'>
                <div>
                    <h4>Similar Conversations</h4>
                    <div className='analyzer-tags-container'>
                        {renderBasedOnStatus()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimilarConversations;
