import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { ROLES_PERMS_ACTIONS } from "../../enums";
import { baseResources, baseResourceActions } from "routes/enum";

const { USER_MANAGEMENT_ROLES } = baseResources;
const { UPDATE, DELETE, READ } = baseResourceActions;

const RolesAndPermissionsDropdown = ({ role, handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, role)}
            options={[
                {
                    value: ROLES_PERMS_ACTIONS.EDIT_ROLE,
                    label: "Edit Role",
                    baseResources: [`${UPDATE}_${USER_MANAGEMENT_ROLES}`],
                },
                {
                    value: ROLES_PERMS_ACTIONS.ROLE_DESC,
                    label: "Role Description",
                    baseResources: [`${READ}_${USER_MANAGEMENT_ROLES}`],
                },
                {
                    value: ROLES_PERMS_ACTIONS.DELETE_ROLE,
                    label: "Delete Role",
                    baseResources: [`${DELETE}_${USER_MANAGEMENT_ROLES}`],
                },
            ]}
        />
    );
};

export default RolesAndPermissionsDropdown;
