import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import { Link } from "react-router-dom";
import asset from "assets/images";
import { ReactSVG } from "react-svg";
import "./PreHeading.scss";
import { Dropdown } from "components/ui";

const PreHeading = ({
    title,
    text,
    subText,
    url,
    hasError,
    hasSelectAction,
    handleSelect,
    selectOptions,
    selectedValue,
}) => {
    return (
        <>
            {title && <SEO title={title} />}

            <div className='pre-heading'>
                <div className='pre__nav'>
                    <Link to={url}>{text}</Link>
                    <ReactSVG
                        src={asset?.icons?.rightBlack}
                        className='anchor-right'
                    />
                    <p className='primary'>{subText}</p>

                    {hasError && (
                        <div className='pre__error'>
                            <ReactSVG src={asset?.icons?.error} />
                        </div>
                    )}

                    {hasSelectAction && (
                        <div className='pre-heading__select-ui'>
                            <Dropdown
                                isDefault={false}
                                size='md'
                                options={selectOptions}
                                ddValue={selectedValue}
                                onChange={handleSelect}
                                changeValue={false}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PreHeading;
