import EmailFileAttachment from "../../../EmailAttachmentsList/EmailAttachment/EmailFileAttachment";
import "./EmailAttachedFiles.scss";

const EmailAttachedFiles = ({ attachments, handleToggleEmailAttachment }) => {

    const handleRemoveFileAttachment = (fileAttachmentId) => {
        const filteredAttachments = attachments?.filter(
            ({ id }) => id !== fileAttachmentId
        )
        handleToggleEmailAttachment("fileAttachments", filteredAttachments);
    };

    return (

        <div className="email_attached_files">
            {attachments?.map(
                (fileAttachment, index) => (
                    <EmailFileAttachment
                        key={index}
                        fileAttachment={fileAttachment}
                        removeFileAttachment={
                            () => handleRemoveFileAttachment(fileAttachment?.id)
                        }
                    />
                )
            )}
        </div>
    )
};

export default EmailAttachedFiles;