import React from "react";
import StatusCard from "../../../../common/StatusCard/StatusCard";

const SwitchesStatusCard = ({ switchesCardType, switchesCardCount }) => {
    return (
        <StatusCard
            cardCount={switchesCardCount}
            cardType={switchesCardType}
            cardUrl={`/dependency/view-switches/${switchesCardType}`}
        />
    );
};

export default SwitchesStatusCard;
