import { Button, FileInput, Input } from "components/ui";
import "./FileUploader.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const FileUploader = ({
    fileType,
    contentFile,
    contentCaption,
    handleChange,
    errors,
    upload,
    clear,
    fileSize,
}) => {
    return (
        <>
            <p className='file__upload__title'>
                {fileType?.startsWith("image/") ? "Image File" : "Video File"}
            </p>
            <div className='file__group'>
                <div className='file__label__container'>
                    <div className='file__name'>
                        <p>
                            {contentFile?.name
                                ? contentFile?.name
                                : fileType?.startsWith("image/")
                                ? "Click select to upload your image"
                                : "Click select to upload your video"}
                        </p>
                    </div>
                    <FileInput
                        fileType={fileType}
                        id='contentFile'
                        name='contentFile'
                        label={
                            <Button
                                as='span'
                                type='button'
                                text='Select'
                                classType='-file-label'
                                otherClass='file__label__btn'
                            />
                        }
                        labelClass='file__label'
                        labelName={
                            fileType?.startsWith("image/") ? "Image" : "Video"
                        }
                        fileValue={contentFile}
                        fileSize={fileSize}
                        onChange={upload}
                        isErr={errors?.contentFile}
                        errMssg={errors?.contentFile}
                    />
                </div>
                {contentFile && (
                    <Button
                        type='button'
                        text='Clear'
                        classType='-file-label-full'
                        onClick={clear}
                        otherClass='ms-1'
                    />
                )}
            </div>
            {!contentFile && (
                <span className='extra__input__mssg'>
                    {fileType?.startsWith("image/")
                        ? "Upload image with a max of 2MB"
                        : "Upload video with a max of 5MB"}
                </span>
            )}
            {contentFile && (
                <>
                    {fileType?.startsWith("image/") ? (
                        <ImageView
                            src={
                                typeof contentFile === "string"
                                    ? contentFile
                                    : URL.createObjectURL(contentFile)
                            }
                            alt='Uploaded'
                            className='uploaded__file'
                        />
                    ) : (
                        <video
                            className='uploaded__file uploaded__file--video'
                            controls>
                            <source
                                src={
                                    typeof contentFile === "string"
                                        ? contentFile
                                        : URL.createObjectURL(contentFile)
                                }
                            />
                        </video>
                    )}

                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='contentCaption'
                        id='contentCaption'
                        labelClass='d-none'
                        label={
                            fileType?.startsWith("image/")
                                ? "Image caption"
                                : "Video caption"
                        }
                        placeholder={
                            fileType?.startsWith("image/")
                                ? "Image Caption"
                                : "Video caption"
                        }
                        value={contentCaption}
                        onChange={handleChange}
                        isErr={errors?.contentCaption}
                        errMssg={errors?.contentCaption}
                    />
                </>
            )}
        </>
    );
};

export default FileUploader;
