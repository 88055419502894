import React from "react";
import { ROLES_PERMS_ACTIONS } from "../../../enums";
import CreateNewRole from "../CreateNewRole/CreateNewRole";
import DeleteRole from "../DeleteRole/DeleteRole";
import RoleDescription from "../RoleDescription/RoleDescription";

const { CREATE_ROLE, EDIT_ROLE, DELETE_ROLE, ROLE_DESC } = ROLES_PERMS_ACTIONS;

const RolesAndPermissionsActionForms = ({
    role,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const refetchOnSuccess = () => {
        closeModal();
        handleSuccess();
    };

    const renderBasedOnAction = () => {
        switch (currentAction) {
            case CREATE_ROLE:
                return <CreateNewRole handleSuccess={refetchOnSuccess} />;

            case EDIT_ROLE:
                return (
                    <CreateNewRole
                        role={role}
                        handleSuccess={refetchOnSuccess}
                        currentAction={currentAction}
                    />
                );

            case DELETE_ROLE:
                return (
                    <DeleteRole
                        roleCode={role?.roleCode}
                        closeModal={closeModal}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            case ROLE_DESC:
                return <RoleDescription role={role} />;

            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default RolesAndPermissionsActionForms;
