import PropTypes from "prop-types";
import { Empty, Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import "./TagClassesSelect.scss";
import ItemsList from "components/ui/ItemsList/ItemsList";

const { DATAMODE, NULLMODE, ERROR, LOADING } = dataQueryStatus;

const TagClassesSelect = ({
    tagClasses,
    request,
    setRequest,
    hasErr,
    errMssg,
    updateErrors,
    tagClassesErrorMssg,
    handleRetry,
    status,
}) => {
    const handleSelectedTag = (tagId) => {
        updateErrors("classificationIds", "");
        if (request?.classificationIds.includes(tagId)) {
            const filteredIds = request?.classificationIds.filter(
                (tag) => tag !== tagId
            );

            return setRequest({
                ...request,
                classificationIds: [...filteredIds],
            });
        }

        setRequest({
            ...request,
            classificationIds: [...request?.classificationIds, tagId],
        });
    };

    const renderCompBasedOnStage = () => {
        switch (status) {
            case NULLMODE:
                return <Empty className='tag__select__empty' />;

            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ItemsList breakPoint={10}>
                        {tagClasses?.map((tagClass, i) => (
                            <div
                                key={i}
                                className={`tag_classes ${
                                    request?.classificationIds.includes(
                                        tagClass?.classificationId
                                    )
                                        ? "active__tag_classes"
                                        : ""
                                }`}
                                onClick={() =>
                                    handleSelectedTag(
                                        tagClass?.classificationId
                                    )
                                }>
                                {tagClass?.name}
                            </div>
                        ))}
                    </ItemsList>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={tagClassesErrorMssg}
                        size='50'
                        handleRetry={handleRetry}
                    />
                );
            default:
                return <Empty className='tag__select__empty' />;
        }
    };

    return (
        <div className='mb-4'>
            <div
                className={`tag__classes__container ${
                    hasErr ? "tag__select__invalid" : ""
                }`}>
                {renderCompBasedOnStage()}
            </div>
            <div className='tag__select__error'>{errMssg}</div>
        </div>
    );
};

TagClassesSelect.propTypes = {
    tagClasses: PropTypes.array.isRequired,
    request: PropTypes.object.isRequired,
    setRequest: PropTypes.func.isRequired,
};

export default TagClassesSelect;
