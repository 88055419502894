import React, { useState, useContext, useEffect } from "react";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { Input, Button, SearchSelectInput } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setFormsList } from "store/cache/forms/actions";

const PostFormCollectionForm = ({ isEdit, handleSuccess, referenceData }) => {
    const [request, setRequest] = useState({
        branchCollectionName: referenceData?.branchCollectionName || "",
        formIds: referenceData?.forms?.map((form) => form?.formId) || [],
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [forms, setForms] = useState([]);
    const [formsIsLoading, setFormsIsLoading] = useState(false);
    const [formsErrorMssg, setFormsErrorMssg] = useState("");
    const [existingFormLabels, setExistingFormLabels] = useState([]);
    const toastMessage = useContext(ToastContext);

    const { forms: cachedForms } = useSelector((state) => state.forms);

    const dispatch = useDispatch();

    const updateForms = () => {
        const formattedData = cachedForms?.map((form) => ({
            value: form?.formId,
            label: form?.formName,
        }));
        setForms(formattedData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMultipleSelectChange = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    useEffect(() => {
        const { forms } = referenceData || {};
        if (isEdit) {
            const formattedData =
                forms &&
                forms?.map((form) => ({
                    value: form?.formId,
                    label: form?.formName,
                }));
            setExistingFormLabels(formattedData);
        }
        // eslint-disable-next-line
    }, [referenceData]);

    const getForms = async (httpRequest) => {
        try {
            setFormsIsLoading(true);
            setFormsErrorMssg();

            const res = await API.get(apiRoute.getForms, {
                params: { all: true },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res?.data;
                dispatch(setFormsList(data));
                updateForms();
                setFormsIsLoading(false);
            }
        } catch (err) {
            setFormsIsLoading(false);
            setFormsErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getForms(httpRequest);

        return () => {
            httpRequest?.abort();
        };
        // eslint-disable-next-line
    }, []);

    const CreatePostFormCollection = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { branchCollectionName, formIds } = request;

            const requestData = {
                branchCollectionName,
            };

            if (formIds?.length > 0) {
                requestData.formIds = formIds;
            }

            const url = apiRoute?.postFormBranchCollections;
            const res = await API.post(url, requestData);

            if (res.status === 201) {
                const { message } = res.data;

                handleSuccess();
                toastMessage(message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const EditPostFormCollection = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { branchCollectionName, formIds } = request;

            const requestData = {
                branchCollectionName,
            };

            if (formIds?.length > 0) {
                requestData.formIds = formIds;
            }

            const url = apiRoute.updatePostFormBranchCollection(
                referenceData?.branchCollectionId
            );
            const res = await API.put(url, requestData);

            if (res.status === 200) {
                const { message } = res.data;

                handleSuccess();
                toastMessage(message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "formIds",
        ]);

        if (formisValid) {
            isEdit ? EditPostFormCollection() : CreatePostFormCollection();
        } else {
            setErrors(formErrors);
        }
    };

    const { branchCollectionName, formIds } = request;

    useEffect(() => {
        if (cachedForms?.length > 0) {
            updateForms();
        }
        // eslint-disable-next-line
    }, [cachedForms]);

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            <Input
                grpClass='mb-3'
                type='text'
                name='branchCollectionName'
                label='Post Form Name'
                labelClass='text-dark fw-medium'
                id='branchCollectionName'
                placeholder='Enter name of post form collection'
                value={branchCollectionName}
                onChange={handleChange}
                isErr={errors?.branchCollectionName}
                errMssg={errors?.branchCollectionName}
                autoFocus={true}
            />
            <SearchSelectInput
                grpClass='mb-3'
                name='formIds'
                label='Link to'
                labelClass='fw-medium'
                selectedItemsLabel='Connected Forms'
                id='formIds'
                placeholder='Select form here'
                setInputValue={handleMultipleSelectChange}
                errMssg={errors?.formIds}
                isLoading={formsIsLoading}
                serverError={formsErrorMssg}
                handleRetry={() => getForms()}
                data={forms?.filter(({ value }) => !formIds?.includes(value))}
                inputValue={existingFormLabels}
                darkLabel={true}
            />
            <Button
                type='submit'
                text={`${isEdit ? "Save changes" : "Create"}`}
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

export default PostFormCollectionForm;
