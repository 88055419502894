export const branchOptionActions = Object.freeze({
    EDIT_BRANCH_OPTION: "EDIT_BRANCH_OPTION",
    CREATE_BRANCH_OPTION: "CREATE_BRANCH_OPTION",
    CREATE_CONVERSATION_BREAKER_OPTION: "CREATE_CONVERSATION_BREAKER_OPTION",
    EDIT_CONVERSATION_BREAKER_OPTION: "EDIT_CONVERSATION_BREAKER_OPTION",
});

export const branchOptionTypes = Object.freeze({
    BRANCH: "BRANCH",
    FORM: "FORM",
    COLLECTION: "COLLECTION",
    LINK: "LINK",
    GUIDE_FLOW: "GUIDE_FLOW",
});

export const branchOptionTypeList = {
    BRANCH: "Branch",
    FORM: "Form",
    COLLECTION: "Collection",
    LINK: "Link",
    GUIDE_FLOW: "Guide Flow",
};

export const branchOptionTypesArr = [
    { label: "Branch", value: "BRANCH" },
    { label: "Link (URL)", value: "LINK" },
    { label: "Form", value: "FORM" },
    { label: "Collection", value: "COLLECTION" },
    { label: "None", value: null },
];
