import Heading from "components/ui/Heading/Heading";
import React from "react";
import { dataQueryStatus } from "utils";

const { DATAMODE, NULLMODE } = dataQueryStatus;

const RolesHeader = ({ status }) => {
    return (
        <>
            <Heading
                headingText={"Roles & Permissions"}
                headingSubText={
                    status === DATAMODE
                        ? "List of roles and permissions settings"
                        : status === NULLMODE &&
                          "Setup a new role and permissions"
                }
            />
        </>
    );
};

export default RolesHeader;
