import React, { useEffect, useState } from "react";
import OptionSelect from "components/ui/SelectUI/OptionSelect/OptionSelect";
import "./ScheduleInput.scss";

function hmsToSecondsOnly(str) {
    if (str === "") {
        return "";
    } else {
        var p = str.split(":"),
            s = 0,
            m = 1;

        while (p.length > 0) {
            s += m * parseInt(p.pop(), 10);
            m *= 60;
        }

        return s;
    }
}

function padNumber(num, size = 2) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

const defaultComputedTime = {
    HH: "00",
    MM: "00",
    SS: "00",
};

const ScheduleInput = ({
    timeValue,
    handleTimeChange,
    error,
    label,
    showLabel,
    subLabel,
}) => {
    const [usingHour, toggleUsingHour] = useState(false);
    const [computedTime, setComputedTime] = useState(defaultComputedTime);

    const handleInputTimeChange = (e) => {
        let { name, value } = e.target;
        if (value.length > 2) {
            value = value.slice(0, 2);
        }
        setComputedTime({
            ...computedTime,
            [name]: padNumber(value),
        });
    };

    const handleSwitchTimeUnit = () => {
        // setTimeUnit(timeUnit);
        toggleUsingHour(!usingHour);
        setComputedTime(defaultComputedTime);
    };

    const calComputedTime = () => {
        let totalSeconds = timeValue;
        const hourVal = Math.floor(totalSeconds / 3600);
        const HH = padNumber(hourVal);
        totalSeconds %= 3600;
        const MM = padNumber(Math.floor(totalSeconds / 60));
        const SS = padNumber(totalSeconds % 60);
        //
        if (hourVal > 0) {
            toggleUsingHour(true);
        }
        setComputedTime({ HH, MM, SS });
    };

    const handleTimeValueEquation = () => {
        const { HH, MM, SS } = computedTime;
        const timeVal = `${HH}:${MM}:${SS}`;
        const timeVals = hmsToSecondsOnly(timeVal);
        handleTimeChange(timeVals);
    };

    useEffect(() => {
        calComputedTime();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleTimeValueEquation();
        // eslint-disable-next-line
    }, [computedTime]);

    const { HH, MM, SS } = computedTime;

    return (
        <div className='form-group mb-3'>
            {showLabel && (
                <label className='form-label schedule__input__label fw-medium '>
                    {label}
                </label>
            )}
            <p className='sub-label'>{subLabel}</p>
            <div className='row'>
                <div className='col-md-8 pe-0'>
                    <div
                        className={`schedule__input ${
                            error ? "has-error" : ""
                        }`}>
                        {usingHour && (
                            <>
                                <OptionSelect
                                    options={Array.from(Array(25), (e, i) => ({
                                        label: i,
                                        value: i,
                                    }))}
                                    placeholder='HH'
                                    name='HH'
                                    value={{
                                        label: HH,
                                        value: HH,
                                    }}
                                    handleChange={(name, value) =>
                                        handleInputTimeChange({
                                            target: { name, value },
                                        })
                                    }
                                />
                            </>
                        )}
                        <OptionSelect
                            options={Array.from(Array(60), (e, i) => ({
                                label: i,
                                value: i,
                            }))}
                            name='MM'
                            placeholder='MM'
                            value={{
                                label: MM,
                                value: MM,
                            }}
                            handleChange={(name, value) =>
                                handleInputTimeChange({
                                    target: { name, value },
                                })
                            }
                        />
                        {!usingHour && (
                            <OptionSelect
                                options={Array.from(Array(60), (e, i) => ({
                                    label: i,
                                    value: i,
                                }))}
                                name='SS'
                                placeholder='SS'
                                value={{
                                    label: SS,
                                    value: SS,
                                }}
                                handleChange={(name, value) =>
                                    handleInputTimeChange({
                                        target: { name, value },
                                    })
                                }
                            />
                        )}
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='switch6'>
                        <label className='switch6-light'>
                            <input
                                type='checkbox'
                                checked={usingHour}
                                onChange={handleSwitchTimeUnit}
                            />
                            <span>
                                <span>Minute</span>
                                <span>Hour</span>
                            </span>
                            <a href='/' className='btn btn-active-schedule'>
                                .
                            </a>
                        </label>
                    </div>
                </div>
            </div>
            <input
                name='deliveryInterval'
                value={timeValue}
                hidden={true}
                readOnly={true}
            />
        </div>
    );
};

export default ScheduleInput;
