import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { getCapsulesEntries } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

import CapsuleStatusListingHeader from "./CapsuleStatusListingHeader/CapsuleStatusListingHeader";
import CapsuleStatusListingTable from "./CapsuleStatusListingTable/CapsuleStatusListingTable";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const CapsuleStatusListing = ({
    capsuleClasses,
    handleClassSelect,
    selectedCapsuleClass,
    hasCapsuleClassSelect,
    capsulesTotalCount,
    capsuleType,
    hasEnabledCapsules,
    handleRefresh,
}) => {
    const [capsules, setCapsules] = useState([]);

    const [errorMssg, setErrorMssg] = useState();
    const [status, setStatus] = useState();
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const [useTableLoader, setUseTableLoader] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, updatePageFilters] = useState({
        search: "",
        currentPage: 1,
        pageSize: 10,
    });

    const handlePageChange = (currentPage) => {
        updatePageFilters({ ...filters, currentPage });
        setUseTableLoader(true);
    };

    const handleSearch = (searchValue) => {
        setSearchIsLoading(true);
        updatePageFilters({
            ...filters,
            search: searchValue,
        });
        setClearSearch(false);
    };

    const getCapsules = async (useTableLoader) => {
        try {
            setErrorMssg();
            setCapsules([]);

            useTableLoader ? setTableLoading(true) : setStatus(LOADING);

            const { capsuleEntries } = await getCapsulesEntries(
                selectedCapsuleClass,
                filters,
                hasEnabledCapsules,
                capsuleType === "ON" ? true : false
            );

            if (capsuleEntries.length > 0) {
                setCapsules(capsuleEntries);
                setStatus(DATAMODE);
            } else {
                setStatus(NULLMODE);
                setCapsules([]);
            }

            setTableLoading(false);
            setSearchIsLoading(false);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
            useTableLoader ? setTableLoading(false) : setStatus(ERROR);
        }
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <CapsuleStatusListingTable
                            capsules={capsules}
                            selectedCapsuleClass={selectedCapsuleClass}
                            tableLoading={tableLoading}
                            getCapsules={getCapsules}
                            handleRefresh={handleRefresh}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filters}
                        />
                    </>
                );

            case NULLMODE:
                return (
                    <>
                        {capsuleType ? (
                            <Empty
                                text={`No ${capsuleType} ${selectedCapsuleClass?.capsuleClassName} capsules have been created`}
                            />
                        ) : (
                            <Empty
                                text={`No ${selectedCapsuleClass?.capsuleClassName} capsules have been created`}
                            />
                        )}
                    </>
                );

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getCapsules} />
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        getCapsules(useTableLoader);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCapsuleClass, useTableLoader, filters]);

    useEffect(() => {
        setTotalRecords(capsulesTotalCount);
    }, [capsulesTotalCount]);

    return (
        <>
            <CapsuleStatusListingHeader
                handleClassSelect={handleClassSelect}
                selectedCapsuleClass={selectedCapsuleClass}
                capsuleClasses={capsuleClasses}
                hasCapsuleClassSelect={hasCapsuleClassSelect}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                searchIsLoading={searchIsLoading}
            />
            <div>{renderBasedOnStage()}</div>
        </>
    );
};

export default CapsuleStatusListing;
