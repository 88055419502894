import React from 'react';
import { downtimeTypesData } from './data';
import "./DowntimeBranchesList.scss";

const DowntimeBranchesList = ({ activeTab, setActiveTab }) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <ul className="downtime__brancheslist">
                    {Object.entries(downtimeTypesData).map(([elementKey, element], index) => (
                        <li
                            key={elementKey}
                            className={`downtime__brancheslist--item ${activeTab === elementKey ? "selected" : ""}`}
                            onClick={() => setActiveTab(elementKey)}
                        >
                            {element}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
};

export default DowntimeBranchesList;