import React, { useState, useContext, useEffect } from "react";
import { Button } from "components/ui";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import TicketDistribution from "./TicketDistribution/TicketDistribution.js.js";
import TicketSchedule from "./TicketSchedule/TicketSchedule";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl.js";
import { baseResources, baseResourceActions } from "routes/enum/index.js";
import "./CustomRules.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const CustomRules = ({ customRules, teamId, handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        ticketDistributionRule: customRules?.ticketDistributionRule || "",
        weekDayScheduleStartTime: customRules?.weekDayScheduleStartTime || "",
        weekDayScheduleEndTime: customRules?.weekDayScheduleEndTime || "",
        weekendScheduleStartTime: customRules?.weekendScheduleStartTime || "",
        weekendScheduleEndTime: customRules?.weekendScheduleEndTime || "",
    });
    const toastMessage = useContext(ToastContext);
    const [disabledBtn, setDisabledBtn] = useState(true);

    const handleRequestValues = (type, value) => {
        switch (type) {
            case "ticketDistributionRule":
                setRequest({ ...request, ticketDistributionRule: value });
                break;
            case "weekDayScheduleStartTime":
                setRequest({ ...request, weekDayScheduleStartTime: value });
                break;
            case "weekDayScheduleEndTime":
                setRequest({ ...request, weekDayScheduleEndTime: value });
                break;
            case "weekendScheduleStartTime":
                setRequest({ ...request, weekendScheduleStartTime: value });
                break;
            case "weekendScheduleEndTime":
                setRequest({ ...request, weekendScheduleEndTime: value });
                break;
            default:
                break;
        }
    };

    const updateCustomRules = async () => {
        try {
            setLoading(true);
            const res = await API.put(apiRoute?.customRule(teamId), request);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const validateRequest = () => {
        for (let schedule of Object.values(request)) {
            if (schedule?.length === 0) {
                return { isValid: false };
            }
        }
        return { isValid: true };
    };

    useEffect(() => {
        const { isValid } = validateRequest();
        isValid ? setDisabledBtn(false) : setDisabledBtn(true);
        //eslint-disable-next-line
    }, [request]);

    return (
        <>
            <div className='row'>
                <div className='custom-rules col-12'>
                    <div>
                        <div className='custom-rules--header'>
                            <h1 className='custom-rules--header--title fw-bold'>
                                Create custom rules
                            </h1>
                            <p className='custom-rules--header--sub__title'>
                                Nisl viverra facilisi consectetur magna tellus
                                viverra tristique non. Et vulputate diam
                                sollicitudin tellus, amet dolor. Commodo ut
                                morbi elit pellentesque.
                            </p>
                        </div>
                        <ComponentAccessControl
                            baseResources={[
                                `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                            ]}>
                            <TicketDistribution
                                request={request}
                                handleRequestValues={handleRequestValues}
                            />
                        </ComponentAccessControl>
                        <ComponentAccessControl
                            baseResources={[
                                `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                            ]}>
                            <TicketSchedule
                                request={request}
                                handleRequestValues={handleRequestValues}
                            />
                        </ComponentAccessControl>
                    </div>
                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <Button
                            type='submit'
                            text='Save Changes'
                            classType='primary'
                            otherClass='my-3 w-lg-25 w-md-50 w-sm-100'
                            loading={loading}
                            disabled={disabledBtn}
                            onClick={updateCustomRules}
                        />
                    </ComponentAccessControl>
                </div>
            </div>
        </>
    );
};

export default CustomRules;
