import imageLinks from "assets/images";
import { ReactSVG } from "react-svg";

const AnalyzerReset = ({ handleReset }) => {
    return (
        <>
            <div className='analyzer-query__redo' onClick={handleReset}>
                <span>Redo analysis</span>
                <ReactSVG src={imageLinks.icons.reset} />
            </div>
        </>
    );
};

export default AnalyzerReset;
