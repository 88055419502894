import AnalyticsQAVAgentsCard from "./AnalyticsQAVAgentsCard/AnalyticsQAVAgentsCard";
import "./AnalyticsQAVAgentsCards.scss";

const AnalyticsQAVAgentsCards = ({ convoCounts }) => {
    const convoCountsList = [
        {
            title: "Number of suggestions",
            value: convoCounts?.numberOfSuggestions,
        },
        {
            title: "Suggestion success rate",
            value: convoCounts?.numberOfSucessfulSuggestions,
        },
        // {
        //     title: "Time of suggestions",
        //     value: `${convoCounts?.timeBasedNumberOfSuggestions}s`,
        // },
        // {
        //     title: "Time of suggestion success",
        //     value: `${convoCounts?.timeBasedNumberOfSucessfulSuggestions}s`,
        // },
    ];

    return (
        <div className='analytics-qa-agents-cards'>
            {convoCountsList?.map(({ title, value }, key) => (
                <AnalyticsQAVAgentsCard key={key} title={title} text={value} />
            ))}
        </div>
    );
};

export default AnalyticsQAVAgentsCards;
