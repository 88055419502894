import React, { useState, useContext } from "react";
import { Button, Input } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { convoBreakerOptionTypesArr } from "../../enums";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const CreateConvoBreakerOptionForm = ({
    isEdit,
    referenceData,
    handleSuccess,
}) => {
    const {
        branchId,
        branchOptionActionType,
        branchOptionId,
        branchOptionLabel,
    } = referenceData || {};
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        actionBranchOptionTitle: isEdit ? branchOptionLabel : "",
        actionBranchOptionType: isEdit ? branchOptionActionType : "",
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState("");
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest(name, value);
    };

    const updateRequest = (name, value) => {
        setRequest({ ...request, [name]: value });

        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const createConvoBreakerOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.createConversationBreakerOptions(branchId);
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res?.data;
                toastMessage(message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editConvoBreakerOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.conversationBreakerOption(
                branchId,
                branchOptionId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res?.data;
                toastMessage(message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);
        if (formisValid) {
            isEdit ? editConvoBreakerOption() : createConvoBreakerOption();
        } else {
            setErrors(errors);
        }
    };

    const { actionBranchOptionTitle, actionBranchOptionType } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='actionBranchOptionTitle'
                label='Option title'
                labelClass='text-dark fw-medium'
                id='actionBranchOptionTitle'
                placeholder='Enter Option title'
                onChange={handleChange}
                value={actionBranchOptionTitle}
                isErr={errors?.actionBranchOptionTitle}
                errMssg={errors?.actionBranchOptionTitle}
                autoFocus={true}
            />
            <SelectUI
                name='actionBranchOptionType'
                label='Actions'
                id='actionBranchOptionType'
                placeholder='Select action type'
                darkLabel={true}
                options={convoBreakerOptionTypesArr}
                defaultValue={actionBranchOptionType}
                value={convoBreakerOptionTypesArr.filter(
                    ({ value }) => value === actionBranchOptionType
                )}
                handleChange={updateRequest}
                error={
                    errors?.actionBranchOptionType
                        ? "Action type is required"
                        : ""
                }
            />
            <Button
                type='submit'
                text='Save changes'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateConvoBreakerOptionForm;
