import React, { useState } from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import CreateBranchModal from "../../../Conversations/branches/modal/CreateBranchModal/CreateBranchModal";
import { branchFormActions } from "../../../Conversations/enums";
import {
    DOWNTIME_BRANCH,
    UPTIME_BRANCH,
} from "../../../DowntimeBranchesView/DowntimeBranchesList/enums";
import BranchGroupItem from "./BranchGroupItem/BranchGroupItem";
import { GENERAL_DOWNTIME_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";

const BranchesGroupList = ({
    branches,
    selectedBranch,
    branchGroupType,
    parentSource,
    handleBranchSelect,
    parentCapsule,
    handleFullPageRefresh,
}) => {
    const [showModal, toggleModal] = useState(false);
    return (
        <>
            <div className='conversations-branch__downtime-branches'>
                {branches?.map((branch, index) => (
                    <BranchGroupItem
                        key={index}
                        branch={
                            branch?.branchId === selectedBranch?.branchId
                                ? selectedBranch
                                : branch
                        }
                        branchType={branchGroupType}
                        isActive={branch?.branchId === selectedBranch?.branchId}
                        handleBranchSelect={handleBranchSelect}
                    />
                ))}
                {(selectedBranch && branchGroupType === DOWNTIME_BRANCH) ||
                (selectedBranch &&
                    branchGroupType === UPTIME_BRANCH &&
                    branches?.length > 0) ? (
                    ""
                ) : (
                    <AddButton
                        btnText={"Add a Branch"}
                        onClick={() => toggleModal(true)}
                    />
                )}
            </div>
            {showModal && (
                <CreateBranchModal
                    show={showModal}
                    closeModal={() => toggleModal(false)}
                    source={branchGroupType}
                    modalFormAction={
                        parentSource === GENERAL_DOWNTIME_COLLECTION
                            ? branchFormActions?.CREATE_GENERAL_BRANCH
                            : branchFormActions.CREATE_PRIMARY_BRANCH
                    }
                    referenceData={parentCapsule}
                    handleSuccess={(branchId) =>
                        handleFullPageRefresh(branchGroupType, branchId)
                    }
                />
            )}
        </>
    );
};

export default BranchesGroupList;
