import { Loading, Empty } from "components/ui";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import SolutionSetupViewTable from "./SolutionSetupViewTable/SolutionSetupViewTable";
import Filter from "components/dashboard/common/Filter/Filter";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const SolutionSetupPlayGround = ({
    capsulesEntries,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    selectedProduct,
    solutionCollections,
    handleCollectionDelete,
    status,
    error,
    totalRecords,
    filters,
    updatePageFilters,
    searchIsLoading,
    clearSearch,
    handleSearchEntries,
}) => {
    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case ERROR:
                return <ErrorView message={error} />;
            case NULLMODE:
                return <Empty className='h-100 my-5' text='No Entries found' />;
            case DATAMODE:
                return (
                    <SolutionSetupViewTable
                        capsulesEntries={capsulesEntries}
                        selectedCapsuleClass={selectedCapsuleClass}
                        selectedSecCapsuleClass={selectedSecCapsuleClass}
                        selectedProduct={selectedProduct}
                        solutionCollections={solutionCollections}
                        handleCollectionDelete={handleCollectionDelete}
                        totalRecords={totalRecords}
                        filters={filters}
                        updatePageFilters={updatePageFilters}
                    />
                );
            default:
                return "";
        }
    };

    const handleSortByAlpha = (value) => {
        updatePageFilters({
            ...filters,
            alphabeticalOrder: value,
            dateCreatedOrder: null,
        });
    };

    const handleSortByDate = (value) => {
        updatePageFilters({
            ...filters,
            dateCreatedOrder: value,
            alphabeticalOrder: null,
        });
    };

    return (
        <>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                hideClassFilter={true}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                handleSearch={handleSearchEntries}
                searchPlaceholder={"Search entries here"}
            />
            {renderBasedOnStage()}
        </>
    );
};

export default SolutionSetupPlayGround;
