import { BRANCH_CANNED_TYPES } from "../../../enum";
import "./AddCannedMessageOption.scss";

const { CANNED_EMAIL, CANNED_RESPONSE, MENTIONS } = BRANCH_CANNED_TYPES;

const cannedMessageOptions = [
    {
        title: "Email update",
        value: "",
        borderColor: "#fc5a5a",
        type: CANNED_EMAIL,
    },
    {
        title: "Reply update",
        value: "",
        borderColor: "#0066F5",
        type: CANNED_RESPONSE,
    },
    {
        title: "Mention update",
        value: "",
        borderColor: "#25BB87",
        type: MENTIONS,
    },
];

const AddCannedMessageOption = ({
    title,
    cannedModalType,
    setCannedModalType,
}) => {
    return (
        <div className='add-canned-message-option'>
            <h6>{title || "Add an update"}</h6>
            <div className='add-canned-message-option__list'>
                {cannedMessageOptions?.map(
                    ({ title, borderColor, type, value }) => (
                        <span
                            onClick={() => setCannedModalType(type)}
                            style={
                                cannedModalType === type
                                    ? {
                                          borderColor,
                                          color: borderColor,
                                          background: `rgba(${borderColor}, 0.5)`,
                                      }
                                    : {}
                            }>
                            {title}
                        </span>
                    )
                )}
            </div>
        </div>
    );
};

export default AddCannedMessageOption;
