import AnalyticsQATabs from "../common/AnalytcisQATabs/AnalyticsQATabs";
import AnalyticsQAReportFilter from "./AnalyticsQAReportFilter/AnalyticsQAReportFilter";
import AnalyticsQAReportSummary from "./AnalyticsQAReportSummary/AnalyticsQAReportSummary";
import AnalyticsQAReportTable from "./AnalyticsQAReportTable/AnalyticsQAReportTable";

const AnalyticsQAReport = () => {
    return (
        <>
            <AnalyticsQATabs />
            <div className='padded__layout analytics-qa-vet-ticket'>
                <AnalyticsQAReportFilter />
                <AnalyticsQAReportSummary />
                <AnalyticsQAReportTable />
            </div>
        </>
    );
};

export default AnalyticsQAReport;
