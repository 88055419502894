import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
import ItemsList from "components/ui/ItemsList/ItemsList";

const AgentGuidesElements = ({ agentGuideElements }) => {
    return (
        <>
            {agentGuideElements?.length > 0 ? (
                <div className='issues-table-embed'>
                    <ItemsList breakPoint={8}>
                        {agentGuideElements.map(
                            ({
                                agentGuideClassCapsule,
                                agentGuideCapsuleEntryIds,
                            }) => (
                                <>
                                    {agentGuideCapsuleEntryIds
                                        ?.split?.(",")
                                        ?.map(
                                            (agentGuideCapsuleEntryId, pos) => (
                                                <CapsuleElement
                                                    capsule={
                                                        agentGuideClassCapsule
                                                    }
                                                    capsuleEntryId={
                                                        agentGuideCapsuleEntryId
                                                    }
                                                    key={`${agentGuideClassCapsule}${agentGuideCapsuleEntryId}`}
                                                    useCapsuleStyles={true}
                                                    hasBgColor={true}
                                                    hideDeleteAction={true}
                                                    hideEditAction={true}
                                                    capsuleBottomMenu={true}
                                                />
                                            )
                                        )}
                                </>
                            )
                        )}
                    </ItemsList>
                </div>
            ) : (
                <span>No embeds have been added yet.</span>
            )}
        </>
    );
};

export default AgentGuidesElements;
