import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./AnalyticsQACard.scss";

const AnalyticsQACard = ({ title, value, dir, change }) => {
    return (
        <div className='analytics-qa-card'>
            <div className='analytics-qa-cardss'>
                <h5>{title}</h5>
                {dir && <ReactSVG src={asset.icons.uptime} />}
            </div>
            <div>
                <b>{value}</b>
                {change && <span>{change}</span>}
            </div>
        </div>
    );
};

export default AnalyticsQACard;
