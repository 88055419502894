import React, { useState } from "react";
import { Table } from "components/ui";
import TeamMemberSideModal from "./TeamMemberSideModal/TeamMemberSideModal";
import TeamMembersDropdown from "./TeamMembersDropdown/TeamMembersDropdown";
import TeamMemberStatusTag from "./TeamMemberStatusTag/TeamMemberStatusTag";
import { TEAM_MEMBERS_HEADERS } from "../enums";
import AddNewTeamMemberButton from "../buttons/AddNewTeamMemberButton/AddNewTeamMemberButton";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TeamMembers.scss";
import { useSelector } from "react-redux";

const { USER_MANAGEMENT_USERS } = baseResources;
const { CREATE } = baseResourceActions;

const TeamMembers = ({
    members,
    handleSuccess,
    tableIsLoading,
    errorMssg,
    handleSearch,
    searchIsLoading,
    clearSearch,
    defaultValue,
}) => {
    const {
        user: { userId },
    } = useSelector((state) => state.auth);

    const [currentAction, setCurrentAction] = useState("");
    const [currentMember, setCurrentMember] = useState({});

    const handleActionModal = (action, member) => {
        setCurrentAction(action);
        setCurrentMember(member);
    };

    const closeActionModal = () => {
        setCurrentAction("");
        setCurrentMember("");
    };

    const tableData = members?.map((member) => ({
        fullName: `${member.firstName} ${member.lastName}`,
        email: <div className='lower--case'>{member.email}</div>,
        status: <TeamMemberStatusTag member={member} />,
        action: (
            <TeamMembersDropdown
                member={member}
                handleActionModal={handleActionModal}
                currentUserId={userId}
            />
        ),
    }));

    return (
        <>
            <div className='flex__between mb-4'>
                <PaginationSearch
                    handleSearch={handleSearch}
                    placeholder='Search Team Member'
                    isLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={defaultValue}
                />
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${USER_MANAGEMENT_USERS}`]}>
                    <AddNewTeamMemberButton
                        handleActionModal={handleActionModal}
                    />
                </ComponentAccessControl>
            </div>
            <div className='team__members'>
                {(tableData?.length > 0 || tableIsLoading) && (
                    <Table
                        headers={TEAM_MEMBERS_HEADERS}
                        dataSource={tableData}
                        hasErrors={Boolean(errorMssg)}
                        isLoading={tableIsLoading}
                        tableError={errorMssg}
                    />
                )}

                {currentAction && (
                    <TeamMemberSideModal
                        member={currentMember}
                        currentAction={currentAction}
                        closeModal={closeActionModal}
                        handleSuccess={handleSuccess}
                    />
                )}
            </div>
        </>
    );
};

export default TeamMembers;
