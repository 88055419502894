import { useState } from "react";

import { POST_CANNED_RESPONSE_ACTIONS } from "../../FormsPostFormBranchesList/enums";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResourceActions, baseResources } from "routes/enum";

import "./FormCannedResponse.scss";

const { EDIT_CANNED_RESPONSE, VIEW_CANNED_RESPONSE, DELETE_CANNED_RESPONSE } =
    POST_CANNED_RESPONSE_ACTIONS;
const { KB_PRODUCT_SETTING } = baseResources;

const { DELETE, UPDATE, READ } = baseResourceActions;

const dropDownItems = [
    {
        permission: READ,
        actionType: VIEW_CANNED_RESPONSE,
        title: "View",
    },
    {
        permission: UPDATE,
        actionType: EDIT_CANNED_RESPONSE,
        title: "Edit",
    },
    {
        permission: DELETE,
        actionType: DELETE_CANNED_RESPONSE,
        title: "Delete",
    },
];

const FormCannedResponse = ({
    handleCannedResponseActions,
    cannedResponse,
}) => {
    const [show, toggleShow] = useState(false);
    return (
        <Dropdown
            isOpen={show}
            className='form-canned-response'
            direction='up'
            toggle={() => toggleShow((prevState) => !prevState)}>
            <div
                className='form-canned-response__content'
                onClick={() => toggleShow((prevState) => !prevState)}>
                <span>{cannedResponse?.title}</span>
            </div>
            <DropdownMenu className='capsule__bottom__menu'>
                {dropDownItems.map(({ permission, title, actionType }, key) => (
                    <ComponentAccessControl
                        baseResources={[`${permission}_${KB_PRODUCT_SETTING}`]}
                        key={key}>
                        <DropdownItem>
                            <p
                                onClick={() =>
                                    handleCannedResponseActions(
                                        actionType,
                                        cannedResponse
                                    )
                                }>
                                {title}
                            </p>
                        </DropdownItem>
                    </ComponentAccessControl>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default FormCannedResponse;
