import React, { useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { saveCapsuleClassess } from "store/capsule/actions";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import DowntimeBranchHeader from "./DowntimeBranchHeader/DowntimeBranchHeader";
import DowntimeBranchTable from "./DowntimeBranchTable/DowntimeBranchTable";

const { ERROR, DATAMODE, NULLMODE, IDLE, LOADING } = dataQueryStatus;

const Downtime = ({ saveCapsuleClassess, capsuleClasses }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [selectedCapsuleClass, selectCapsuleClass] = useState();

    const dispatch = useDispatch();

    const getCapsuleClasses = async (httpRequest) => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url, { signal: httpRequest?.signal });

            if (res.status === 200) {
                const { data } = res.data;
                await dispatch(saveCapsuleClassess(data));
                data?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            const message = getErrorMessage(err);
            setErrorMssg(message);
        }
    };

    const selectFirstClass = async () => {
        if (capsuleClasses?.length > 0) {
            selectCapsuleClass(capsuleClasses[0]);
            setStatus(DATAMODE);
        } else {
            selectedCapsuleClass();
            setStatus(NULLMODE);
        }
    };

    useEffect(() => {
        selectFirstClass();
        // eslint-disable-next-line
    }, [capsuleClasses]);

    const findFirstClass = async (httpRequest) => {
        if (capsuleClasses?.length > 1) {
            await selectFirstClass();
        } else {
            await getCapsuleClasses(httpRequest);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        findFirstClass(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        className={"empty__container"}
                        text={"No Capsule Class available"}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <DowntimeBranchTable
                            selectedCapsuleClass={selectedCapsuleClass}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <div className='padded__layout'>
                <DowntimeBranchHeader
                    capsuleClasses={capsuleClasses}
                    selectedCapsuleClass={selectedCapsuleClass}
                    selectCapsuleClass={selectCapsuleClass}
                />
                {renderBasedOnStatus()}
            </div>
        </>
    );
};

export default connect(
    (state) => ({
        capsuleClasses: state?.capsule?.capsuleClasses,
    }),
    { saveCapsuleClassess }
)(Downtime);
