import { ReactSVG } from "react-svg";
import "./TicketInfoClipboard.scss";
import asset from "assets/images";
import { useState } from "react";
import TicketInfoClipContent from "./TicketInfoClipContent/TicketInfoClipContent";

const TicketInfoClipboard = () => {
    const [show, toggleShow] = useState(false);
    return (
        <section className='ticketinfo-clipboard'>
            <div className='ticketinfo-clipboard__heading'>
                <span>Clipboard</span>
                <ReactSVG
                    src={!show ? asset.icons.upside : asset.icons.downside}
                    onClick={() => toggleShow(!show)}
                />
            </div>
            {show && <TicketInfoClipContent />}
        </section>
    );
};

export default TicketInfoClipboard;
