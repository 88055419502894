import { useState } from "react";
import Cleave from "cleave.js/react";
import { Tooltip } from "../../index";

export const Input = ({
    name,
    id,
    label,
    labelClass,
    grpClass,
    inputClass,
    type = "text",
    showPwd,
    placeholder,
    isErr,
    isValid,
    errMssg,
    extraMssg,
    isLoading,
    value,
    disabled,
    options,
    tooltip,
    onChange,
    fieldType,
    onBlur,
    onKeyDown,
    onKeyUp,
    hideLabel,
    autoFocus,
    min,
    max,
    minLength,
    maxLength,
    required
}) => {
    const [show, setShow] = useState(false);
    const [onlyReadPassword, changePasswordReadability] = useState(true);

    return (
        <div className={`form-group ${grpClass || ""}`}>
            {label && (
                <label
                    htmlFor={id}
                    className={`form-label ${labelClass || ""} ${
                        disabled ? "text-muted" : ""
                    } ${hideLabel ? "d-none" : ""}`}>
                    {label}
                </label>
            )}
            {type === "password" && showPwd ? (
                <div className='position-relative'>
                    <input
                        type={show ? "text" : "password"}
                        name={name}
                        id={id}
                        className={`form-control ${isErr ? "is-invalid" : ""} ${
                            inputClass || ""
                        }`}
                        placeholder={placeholder}
                        disabled={disabled}
                        data-field={fieldType}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={() => changePasswordReadability(false)}
                        readOnly={onlyReadPassword}
                        value={value}
                        autoComplete='off'
                        autoFocus={autoFocus}
                        required={required}
                    />
                    <div className='invalid-feedback'>{errMssg}</div>
                    <span
                        className={`position-absolute top-${
                            errMssg ? "15" : "25"
                        } end-${
                            errMssg ? "10" : "5"
                        } text-muted fw-bold cursor-pointer`}
                        onClick={() => setShow((prevShow) => !prevShow)}>
                        {show ? "hide" : "show"}
                    </span>
                </div>
            ) : type === "cleave" ? (
                <div className='position-relative'>
                    <Cleave
                        name={name}
                        id={id}
                        className={`form-control ${isErr ? "is-invalid" : ""} ${
                            inputClass || ""
                        }`}
                        placeholder={placeholder}
                        disabled={disabled}
                        options={options}
                        onChange={onChange}
                        onBlur={onBlur}
                        data-field={fieldType}
                        value={value}
                        autoFocus={autoFocus}
                    />
                    <div className='invalid-feedback'>{errMssg}</div>
                    {tooltip ? (
                        <span
                            className={`position-absolute top-${
                                errMssg ? "18" : "25"
                            } end-${errMssg ? "18" : "5"} cursor-pointer`}>
                            <Tooltip text={tooltip} />
                        </span>
                    ) : null}
                </div>
            ) : (
                <>
                    <input
                        type={type}
                        name={name}
                        id={id}
                        data-label={label}
                        className={`form-control ${
                            isLoading
                                ? "is-loading"
                                : isErr
                                ? "is-invalid"
                                : isValid
                                ? "is-valid"
                                : ""
                        } ${inputClass || ""}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        data-field={fieldType}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        autoComplete='off'
                        autoFocus={autoFocus}
                        min={min}
                        max={max}
                        minLength={minLength}
                        maxLength={maxLength}
                        required={required}
                    />
                    {extraMssg ? (
                        <div className='text-muted extra-input-mssg'>
                            {extraMssg}
                        </div>
                    ) : null}
                    <div className='invalid-feedback'>{errMssg}</div>
                </>
            )}
        </div>
    );
};
