import { Loading } from "components/ui";

const ProductLoading = ({loadingText}) => {
	return (
		<div className="w-100 page-layout page-layout-white h-100">
			<div className="container row align-items-center justify-content-center h-100">
				<div className="col-4">
					<Loading
						title="Please hang on"
						paragraph={loadingText}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductLoading;
