import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";
import AddButton from "components/ui/Button/AddButton/AddButton";

const ExternalNewEmail = ({ handleToggleEmailPreview }) => {
    return (
        <section className='external__emails__new'>
            <AddButton
                btnText={"Send new email"}
                onClick={() =>
                    handleToggleEmailPreview(
                        {},
                        EXTERNAL_EMAIL_ACTION.SEND_NEW_EMAIL
                    )
                }
            />
        </section>
    );
};

export default ExternalNewEmail;
