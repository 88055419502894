import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CapsuleContentViewHeader from "./CapsuleContentViewHeader/CapsuleContentViewHeader";
import CapsuleContentBlocks from "./CapsuleContentBlocks/CapsuleContentBlocks";
import CapsuleContentClasses from "./CapsuleContentClasses/CapsuleContentClasses";
import { dataQueryStatus } from "utils";
import "./ProductCapsuleView.scss";

const { IDLE, DATAMODE } = dataQueryStatus;

const ProductCapsuleView = () => {
    const [renderCount, updateRenderCount] = useState(0);
    const [status, setStatus] = useState(IDLE);
    const [capsuleData, upDateCapsuleDate] = useState({});
    const [acceptedContentType, setAcceptedContentType] = useState();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        retrieveProductCapsuleInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const retrieveProductCapsuleInfo = async () => {
        const localState = location?.state;

        if (localState) {
            const { capsule, content, contentId, productId } = localState;
            upDateCapsuleDate({ capsule, content, contentId, productId });
            setStatus(DATAMODE);
        } else {
            history.replace("/knowledge-base/product");
        }
    };

    if (status === IDLE) return "";

    return (
        <>
            {status === DATAMODE && (
                <CapsuleContentViewHeader
                    capsuleData={capsuleData}
                    acceptedContentType={acceptedContentType}
                    reLoadView={() => updateRenderCount((count) => count + 1)}
                />
            )}
            <div className='padded__layout pt-5'>
                <div className='row'>
                    <div className='col-6 capsule__content__container custom-scroll-bar'>
                        <CapsuleContentBlocks
                            capsuleData={capsuleData}
                            key={renderCount}
                            reLoadView={() =>
                                updateRenderCount((count) => count + 1)
                            }
                        />
                    </div>
                    <div className='col-6 capsule__content__container custom-scroll-bar'>
                        <CapsuleContentClasses
                            capsuleData={capsuleData}
                            setAcceptedContentType={setAcceptedContentType}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductCapsuleView;
