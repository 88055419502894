import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { useHistory } from "react-router-dom";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { PERSONAS_TABLE_STATUSES } from "./enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./PersonasHeader.scss";

const { AGENTS_INBOX_SERVICE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const PersonasHeader = ({
    handleSearch,
    searchIsLoading,
    clearSearch,
    updatePageFilters,
    statusKey,searchDefaultValue
}) => {
    const history = useHistory();

    return (
        <div className='personas__header'>
            <h1 className='personas__header--text fw-bold'>
                Discover personas
            </h1>
            <div className='personas__header--actions'>
                <div className='personas__header--actions--search'>
                    <div>
                        <PaginationSearch
                            handleSearch={handleSearch}
                            placeholder='Search personas here'
                            isLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={searchDefaultValue}
                        />
                    </div>

                    <div className='personas__header--actions--select'>
                        <SelectUI
                            grpClass='mb-4'
                            name='personaStatus'
                            label=''
                            labelClass='text-dark fw-medium'
                            id='personaStatus'
                            placeholder='Status'
                            options={PERSONAS_TABLE_STATUSES}
                            value={PERSONAS_TABLE_STATUSES?.find((persona) => {
                                return persona.value === statusKey;
                            })}
                            handleChange={(name, value) =>
                                updatePageFilters( value
                                )
                            }
                        />
                    </div>
                </div>
                <div>
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${AGENTS_INBOX_SERVICE_SETTING}`,
                        ]}>
                        <AddButton
                            btnText='Add new persona'
                            onClick={() =>
                                history.push("/service-module/new/persona")
                            }
                        />
                    </ComponentAccessControl>
                </div>
            </div>
        </div>
    );
};

export default PersonasHeader;
