import React from "react";
import {
    issueDiscovery,
    problemConfirmation,
    solutionDelivery,
    followupMentions,
} from "./data";
import TicketDetailHeading from "./TicketDetailHeading/TicketDetailHeading";
import CustomScroll from "components/ui/CustomScroll/CustomScroll";
import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import "./TicketDetailHeader.scss";
import { FOLLOW_UP_LATEST_ACTIVITY } from "./TicketDetailHeading/enum";
import { useSelector } from "react-redux";

const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;

const TicketDetailHeader = ({
    selectedTicketHeader,
    selectedTicketPhase,
    handleSelectedTicketHeader,
    selectedTicket,
}) => {
    const ticketsDetailHeaderData =
        selectedTicketPhase === ISSUE_DISCOVERY
            ? issueDiscovery
            : selectedTicketPhase === PROBLEM_CONFIRMATION
            ? problemConfirmation
            : selectedTicketPhase === SOLUTION_DELIVERY
            ? solutionDelivery
            : followupMentions;

    const { followUpLatestActivity } = selectedTicket || {};
    const { type: activityType } = followUpLatestActivity || {};

    const {
        lastestActivity
    } = useSelector((state) => state.tickets) || {};
    const { ticketId, ticketActivity } = lastestActivity || {}

    return (
        <div key={selectedTicketPhase}>
            <CustomScroll
                selector='ticket__details__header'
                parentScrollId={"ticket__details__header"}
                activeElement={selectedTicketHeader}
                axis='x'
                className='tickets__detail--header'>
                {ticketsDetailHeaderData?.map(({ value, label }, index) => {
                    return (
                        <TicketDetailHeading
                            key={index}
                            label={label}
                            value={value}
                            isActive={value === selectedTicketHeader}
                            onClick={() => handleSelectedTicketHeader(value)}
                            hasNew={
                                (value ===
                                    FOLLOW_UP_LATEST_ACTIVITY[activityType] &&
                                    value !== FOLLOW_UP_LATEST_ACTIVITY.TASK) ||
                                (value === ticketActivity &&
                                    ticketId === selectedTicket?.ticketId)
                            }
                        />
                    );
                })}
            </CustomScroll>
        </div>
    );
};

export default TicketDetailHeader;
