import PropTypes from "prop-types";
import CapsuleContentElement from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesPlayground/ClassesPlaygroundTable/CapsuleContentElement/CapsuleContentElement";
import React, { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import CapsuleSkeletonLoader from "../../CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import { getSingleCapsuleEntry } from "../lib";

const { IDLE, LOADING, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleElement = ({
    capsule,
    capsuleEntryId,
    useCapsuleStyles,
    hasBgColor,
    handleToggleDeleteModal,
    hideDeleteAction,
    hideEditAction,
    capsuleBottomMenu,
}) => {
    const [status, setStatus] = useState(LOADING);
    const [element, setCapsuleElement] = useState();

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
            case NULLMODE:
            case ERROR:
                return "";

            case LOADING:
                return <CapsuleSkeletonLoader count={1} />;

            case DATAMODE:
                return (
                    <CapsuleContentElement
                        hasContent={true}
                        capsule={capsule}
                        element={element}
                        useCapsuleStyles={useCapsuleStyles}
                        hasBgColor={hasBgColor}
                        handleToggleDeleteModal={handleToggleDeleteModal}
                        hideDeleteAction={hideDeleteAction}
                        hideEditAction={hideEditAction}
                        capsuleBottomMenu={capsuleBottomMenu}
                    />
                );

            default:
                return "";
        }
    };

    const retrieveCapsule = async () => {
        try {
            setStatus(LOADING);
            const elementObj = await getSingleCapsuleEntry(
                capsule,
                capsuleEntryId
            );
            setCapsuleElement(elementObj);
            setStatus(DATAMODE);
        } catch (err) {
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        retrieveCapsule();
        // eslint-disable-next-line
    }, [capsuleEntryId]);

    return <>{renderCompBasedOnStage()}</>;
};

CapsuleElement.propTypes = {
    capsule: PropTypes.any,
    capsuleEntryId: PropTypes.any,
};

export default CapsuleElement;
