import "./ExistingScenarios.scss";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage, removeItemsFromArrayByCommonKey } from "utils/helper";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";

const { LOADING, ERROR, DATAMODE, IDLE } = dataQueryStatus;

const ExistingScenarios = ({
    handleSelect,
    scenarios,
    branchCollections,
    setScenarios,
    setCollections,
    existingScenarios = [],
    existingCollections = []
}) => {
    const [status, setStatus] = useState();
    const [errorMssg, setErrorMssg] = useState("");
    const [search, setSearch] = useState("");

    const getBranchCollections = async () => {
        try {
            const url = apiRoute.getBranchCollections;
            const res = await API.get(url, {
                params: {
                    all: true,
                    search
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setCollections(
                    removeItemsFromArrayByCommonKey(
                        existingCollections,
                        data,
                        "branchCollectionId"
                    )
                );
            }
        } catch (err) {

        }
    };

    const getScenarios = async () => {
        try {
            setErrorMssg();
            search === "" && setStatus(LOADING);
            const url = apiRoute.getScenarios;
            const res = await API.get(url, {
                params: {
                    all: true,
                    search
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                getBranchCollections();
                setStatus(DATAMODE);
                setScenarios(
                    removeItemsFromArrayByCommonKey(
                        existingScenarios,
                        data,
                        "guideScenairoId"
                    )
                );
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getScenarios()
            // Send Axios request here
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return (
                    <div className='capsule__loading__container'>
                        <CapsuleSkeletonLoader
                            count={4}
                            otherClass={"existing-scenarios__loader"}
                        />
                    </div>
                );

            case DATAMODE:
                return (
                    <>
                        <PaginationSearch
                            placeholder='Search possible scenarios'
                            handleSearch={(s) => setSearch(s)}
                        />
                        <section>
                            {
                                branchCollections
                                    .map(
                                        ({
                                            branchCollectionId,
                                            branchCollectionName,
                                        }) => (
                                            <div
                                                onClick={() =>
                                                    handleSelect?.({
                                                        branchCollectionId,
                                                        branchCollectionName,
                                                        isCollection: true
                                                    })
                                                }>
                                                {branchCollectionName}
                                            </div>
                                        )
                                    )
                            }
                            {scenarios?.map(
                                ({
                                    guideScenairoId,
                                    guideScenairoName,
                                }) => (
                                    <div
                                        onClick={() =>
                                            handleSelect?.({
                                                guideScenairoId,
                                                guideScenairoName,
                                            })
                                        }>
                                        {guideScenairoName}
                                    </div>
                                )
                            )}
                        </section>
                    </>
                );

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getScenarios} />
                );

            default:
                return "";
        }
    };


    return (
        <div className='existing-scenarios'>
            <h3>Existing scenarios</h3>
            <div className='existing-scenarios__box'>
                {renderBasedOnStatus()}
            </div>
        </div>
    );
};

export default ExistingScenarios;
