import React, { useState } from "react";
import StartupDetails from "./StartupDetails/StartupDetails";
import StartupProfileModal from "../StartupProfileModal/StartupProfileModal";
import StartupProfileDescription from "./StartupProfileDescription/StartupProfileDescription";

const StartupProfileContent = ({
    workspace,
    handleSuccess,
    toastResponseStatus,
}) => {
    const [currentAction, setCurrentAction] = useState("");

    return (
        <div className='startup-profile'>
            <StartupDetails
                workspace={workspace}
                setCurrentAction={setCurrentAction}
            />

            <StartupProfileDescription description={workspace?.description} />

            {currentAction && (
                <StartupProfileModal
                    currentAction={currentAction}
                    closeModal={() => setCurrentAction()}
                    handleSuccess={handleSuccess}
                    toastResponseStatus={toastResponseStatus}
                />
            )}
        </div>
    );
};

export default StartupProfileContent;
