import SelectUI from "components/ui/SelectUI/SelectUI";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";

const CannedResponseSearchInput = ({
    errors,
    request,
    existingCannedReponses,
    handleRequest,
}) => {
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);
    const [cannedResponseList, setCannedResponseList] = useState([]);
    const [search, setSearch] = useState("");

    const retrieveCannedResponses = async (httpRequest) => {
        try {
            setServerError("");
            setLoading(true);
            const res = await API.get(apiRoute.formCannedResponses, {
                params: { search },
                // signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                setLoading(false);
                const { data } = res.data;
                const allCannnedResponses = data.map(
                    ({ cannedResponseId, title, ...rest }) => ({
                        label: title,
                        value: cannedResponseId,
                        title,
                        ...rest,
                    })
                );

                if (existingCannedReponses) {
                    const filterIds = existingCannedReponses.map(
                        (x) => x.cannedResponseId
                    );
                    const filteredCannedResponses = allCannnedResponses.filter(
                        (cannedResponse) => {
                            return !filterIds.includes(cannedResponse?.value);
                        }
                    );
                    return setCannedResponseList(filteredCannedResponses);
                }

                return setCannedResponseList(allCannnedResponses);
            }
        } catch (err) {
            setLoading(false);
            setServerError(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        retrieveCannedResponses(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, [search]);

    return (
        <>
            <SelectUI
                onChange={(value) => {
                    handleRequest(value);
                }}
                onCreateOption={(value) => {
                    handleRequest({ value, label: value }, true);
                }}
                label='Name of Canned Response'
                name={"title"}
                options={cannedResponseList}
                isLoading={loading}
                createAble={true}
                // callApi={true}
                value={
                    request?.cannedResponseId
                        ? cannedResponseList?.find((branch) => {
                              return (
                                  branch?.value === request?.cannedResponseId
                              );
                          })
                        : request
                }
                error={errors?.title ? "Name is required" : ""}
                serverError={serverError}
                handleRetry={() => retrieveCannedResponses()}
                autoFocus={true}
                darkLabel={true}
                handleApiCall={(value) => setSearch(value)}
            />
        </>
    );
};

export default CannedResponseSearchInput;
