import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Button, Input } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const AddCapsuleClassForm = ({ handleCapsulationSuccess, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [request, updateRequest] = useState({
        capsuleClassName: "",
    });
    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createCapsule = async () => {
        try {
            setErrorMsg();
            setLoading(true);

            const url = apiRoute?.createCapsuleClass;
            const res = await API.post(url, { ...request });

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleCapsulationSuccess(res.data.data);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            createCapsule();
        }
        setErrors(formErrors);
    };

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='capsuleClassName'
                label='Name Class'
                labelClass='text-dark fw-medium'
                id='capsuleClassName'
                placeholder='Please tell us your new name class'
                value={request?.capsuleClassName}
                onChange={handleChange}
                isErr={errors?.capsuleClassName}
                errMssg={errors?.capsuleClassName}
                autoFocus={true}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

AddCapsuleClassForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
};

export default AddCapsuleClassForm;
