import React from "react";
import "./CapsulesPlayGroundSkeleton.scss";

const SkeletonRow = ({ index }) => {
    const indexVal = index > 3 ? 2 : 1 * index;

    return (
        <div className='bordered_table--row'>
            <div className='row align-item-center'>
                <div className='col-3'>
                    <div
                        className='shiny__line capsule'
                        style={{ width: "200px" }}></div>
                </div>

                <div className='col-7'>
                    <div className='d-flex align-items-center'>
                        {Array.from(Array(indexVal)).map((_, i) => (
                            <div
                                className='shiny__line capsule'
                                key={i}
                                style={{ marginRight: "10px" }}></div>
                        ))}
                        <div className='shiny__line capsule'></div>
                    </div>
                </div>
                <div className='col-2'>
                    <div className='shiny__line capsule'></div>
                </div>
            </div>
        </div>
    );
};

const CapsulesPlayGroundSkeleton = () => {
    return (
        <div className='bordered_table'>
            <div className='bordered_table--head'>
                <div className='row align-items-center'>
                    <div className='col-3'>
                        <div className='shiny__line capsule'></div>
                    </div>
                    <div className='col-7'></div>

                    <div className='col-2'>
                        <div className='shiny__line capsule'></div>
                    </div>
                </div>
            </div>

            <div className='bordered_table--body'>
                {Array.from(Array(5)).map((_, i) => (
                    <SkeletonRow key={i} index={i} />
                ))}
            </div>
        </div>
    );
};

export default CapsulesPlayGroundSkeleton;
