import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { dataQueryStatus } from "utils/formatHandlers";
import "./UploadPreviewError.scss";

const { ERROR, DATAMODE } = dataQueryStatus;

const UploadPreviewError = ({
    status = ERROR,
    file,
    handleRemoveFile,
    handleRetry,
    fileIndex,
    uploadStatus,
}) => {
    return (
        <>
            {(status === ERROR || status === DATAMODE) &&
                uploadStatus === ERROR && (
                    <div className='upload__preview--error__group'>
                        <ReactSVG
                            src={imageLinks?.icons?.retry}
                            onClick={() => handleRetry({ ...file, fileIndex })}
                        />{" "}
                        <ReactSVG
                            src={imageLinks?.icons?.abort}
                            onClick={() =>
                                handleRemoveFile(
                                    file?.fileAttachmentName,
                                    fileIndex
                                )
                            }
                        />
                    </div>
                )}
        </>
    );
};

export default UploadPreviewError;
