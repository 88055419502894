import React, { useState, useContext } from "react";
import { Range } from "components/ui";
import ImageCropHeader from "../ImageCropHeader/ImageCropHeader";
import ImageCropUpload from "../ImageCropUpload/ImageCropUpload";
import ImageCropScale from "../ImageCropScale/ImageCropScale";
import ImageCrop from "../ImageCrop/ImageCrop";
import { STANDARD, aspectRatios } from "../ImageCropScale/enums";
import { DESKTOP, MOBILE } from "../ImageCropHeader/enums";
import ImageCropButtons from "../ImageCropButtons/ImageCropButtons";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import "./ImageCropper.scss";

const { ERROR } = dataQueryStatus;
const { standardRatio, wideRatio } = aspectRatios;

const ImageCropper = ({
    close,
    referenceData,
    editImageUrl,
    handleRefresh,
}) => {
    const [request, updateRequest] = useState({
        mainBranchImage: null,
        branchMainSentenceImgUrlConfig: {
            mobileVersion: {
                height: 0,
                width: 0,
                x: 0,
                y: 0,
            },
            desktopVersion: {
                height: 0,
                width: 0,
                x: 0,
                y: 0,
            },
        },
    });
    const [cropMode, setCropMode] = useState(MOBILE);
    const [imgSrc, setImageSrc] = useState(editImageUrl || "");
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(standardRatio);
    const [selectedScale, setSelectedScale] = useState(STANDARD);
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const handleSetZoom = ({ target: { value } }) => {
        setZoom(value);
    };

    const saveBranchImage = async () => {
        try {
            setLoading(true);

            const formData = new FormData();

            const { mainBranchImage, branchMainSentenceImgUrlConfig } = request;

            mainBranchImage &&
                formData.append("mainBranchImage", mainBranchImage);
            formData.append(
                "branchMainSentenceImgUrlConfig",
                JSON.stringify(branchMainSentenceImgUrlConfig)
            );

            const url = apiRoute?.editBranch(referenceData?.branchId);
            const res = await API.put(url, formData);

            if (res.status === 200) {
                const { message } = res?.data;

                toastMessage(message);
                handleRefresh(referenceData?.branchId);
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleContinue = () => {
        setAspect(wideRatio);
        cropMode === DESKTOP ? saveBranchImage() : setCropMode(DESKTOP);
    };

    const handleSwitchScale = (scale) => {
        setSelectedScale(scale);
        setCropMode(scale === STANDARD ? MOBILE : DESKTOP);
        setAspect(scale === STANDARD ? standardRatio : wideRatio);
    };

    return (
        <>
            <ImageCropHeader
                cropMode={cropMode}
                setCropMode={setCropMode}
                setSelectedScale={setSelectedScale}
            />
            {imgSrc ? (
                <>
                    <ImageCrop
                        imgSrc={imgSrc}
                        zoom={zoom}
                        setZoom={setZoom}
                        aspect={aspect}
                        cropMode={cropMode}
                        minZoom={1}
                        maxZoom={10}
                        updateRequest={updateRequest}
                    />
                    <div className='zoom__range__container'>
                        <Range
                            value={zoom}
                            min={1}
                            max={10}
                            name='zoom'
                            onChange={handleSetZoom}
                        />
                    </div>
                </>
            ) : (
                <ImageCropUpload
                    updateRequest={updateRequest}
                    setImageSrc={setImageSrc}
                />
            )}
            <ImageCropScale
                setAspect={setAspect}
                selectedScale={selectedScale}
                setSelectedScale={handleSwitchScale}
            />
            <ImageCropButtons
                loading={loading}
                closeModal={close}
                handleContinue={handleContinue}
                disable={!imgSrc}
            />
        </>
    );
};

export default ImageCropper;
