import React from "react";
import { Table } from "components/ui";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import AddCapsuleEntryButton from "./AddCapsuleEntryButton/AddCapsuleEntryButton";
import CapsuleContentElement from "./CapsuleContentElement/CapsuleContentElement";
import AddChildCapsuleEntryButton from "./AddChildCapsuleEntryButton/AddChildCapsuleEntryButton";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ClassesPlaygroundTable.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;

const ClassesPlaygroundTable = ({
    data,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    selectedProduct,
    toggleSubCapsuleModal,
    handleToggleEditModal,
    handleToggleDeleteModal,
    totalRecords,
    filters,
    updatePageFilters,
    toggleParentCapsuleModal,
}) => {
    const secCapsuleKey = selectedCapsuleClass?.collectionKey;

    const handlePageChange = (currentPage) => {
        updatePageFilters({
            ...filters,
            currentPage,
            offset: (currentPage - 1) * filters.pageSize,
        });
    };

    const tableHeaders = [
        <Capsule
            isActive={true}
            capsule={selectedCapsuleClass}
            showCount={true}
            productId={selectedProduct?.value}
        />,
        <div className='th__sec__capsule__container'>
            {selectedSecCapsuleClass && (
                <Capsule
                    isActive={false}
                    showAdd={true}
                    useDefaultAggregate={true}
                    showCount={true}
                    productId={selectedProduct?.value}
                    capsule={selectedSecCapsuleClass}
                    secondaryCapsuleClass={selectedCapsuleClass}
                />
            )}
        </div>,
        <ComponentAccessControl
            baseResources={[`${CREATE}_${KB_PRODUCT_SETTING}`]}>
            <AddCapsuleEntryButton
                selectedCapsuleClass={selectedCapsuleClass}
                toggleParentCapsuleModal={toggleParentCapsuleModal}
            />
        </ComponentAccessControl>,
    ];

    const tableData = data?.map((capsuleContents) => ({
        capsuleClass: (
            <CapsuleContentElement
                capsule={selectedCapsuleClass}
                element={capsuleContents}
                isMain
                handleToggleEditModal={handleToggleEditModal}
                handleToggleDeleteModal={handleToggleDeleteModal}
            />
        ),
        relatedCapsuleClass: (
            <>
                <div className='collection__capsules'>
                    {capsuleContents?.subCapsules?.map((cap, index) => {
                        let subCap =
                            cap[selectedSecCapsuleClass?.collectionName];
                        return (
                            <CapsuleContentElement
                                key={index}
                                capsule={selectedSecCapsuleClass}
                                element={subCap}
                                hasContent={true}
                                handleToggleEditModal={handleToggleEditModal}
                                handleToggleDeleteModal={(
                                    capsule,
                                    content,
                                    contentId,
                                    hasChildren
                                ) =>
                                    handleToggleDeleteModal(
                                        capsule,
                                        content,
                                        contentId,
                                        hasChildren,
                                        capsuleContents[secCapsuleKey],
                                        selectedSecCapsuleClass,
                                        selectedCapsuleClass
                                    )
                                }
                            />
                        );
                    })}
                    <div>
                        {selectedSecCapsuleClass && (
                            <ComponentAccessControl
                                baseResources={[
                                    `${UPDATE}_${KB_PRODUCT_SETTING}`,
                                    `${CREATE}_${KB_PRODUCT_SETTING}`,
                                ]}>
                                <AddChildCapsuleEntryButton
                                    hasSubCapsules={
                                        capsuleContents?.subCapsules?.length > 0
                                            ? true
                                            : false
                                    }
                                    mainCapsuleClass={selectedSecCapsuleClass}
                                    selectedCapsuleClass={selectedCapsuleClass}
                                    toggleSubCapsuleModal={() =>
                                        toggleSubCapsuleModal(
                                            selectedSecCapsuleClass,
                                            selectedCapsuleClass,
                                            capsuleContents[secCapsuleKey],
                                            capsuleContents?.subCapsules,
                                            capsuleContents?.content,
                                            data
                                                ?.map(
                                                    (capsuleContents) =>
                                                        capsuleContents?.subCapsules
                                                )
                                                ?.flat()
                                        )
                                    }
                                />
                            </ComponentAccessControl>
                        )}
                    </div>
                </div>
            </>
        ),
        actions: "",
    }));

    return (
        <>
            <Table headers={tableHeaders} dataSource={tableData} />
            <CustomPagination
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
                {...filters}
            />
        </>
    );
};

export default ClassesPlaygroundTable;
