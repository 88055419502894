import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { jwtExpired } from "utils/helper";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isJwtExpired = jwtExpired();

    return (
        <Route {...rest}>
            {!isJwtExpired ? (
                <Component />
            ) : (
                <Redirect
                    to={{
                        pathname: "/sign-in",
                        state: { referrer: rest.location.pathname },
                    }}
                />
            )}
        </Route>
    );
};

export default withRouter(ProtectedRoute);
