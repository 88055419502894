import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { mediaType } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import config from "config/config";
import { isLiveApp } from "config/config";
import { retriveAccessToken } from "storage/cookieStorage";
import { retriveTokenExpiry } from "storage/localStorage";
import { isNumber } from "underscore";
import { useEffect, useRef } from "react";

const { FILE, IMAGE, VIDEO } = mediaType;
const { apiGateway } = config;

export const getErrorMessage = (error) => {
    const response = error?.response;
    const defaultMssg = "Something went wrong. Please try again.";
    const has500xError = response?.status?.toString?.()?.includes?.("50");
    const errorMessage = has500xError
        ? defaultMssg
        : response?.data
            ? response?.data?.message
            : defaultMssg;

    return errorMessage;
};

export const pipeEnums = (enumValue) => {
    return enumValue ? enumValue.replace(/_/g, " ").toLowerCase() : "";
};

export const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0)?.toUpperCase() + string?.slice(1) : "";
};

export const truncateString = (str="", len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + "...";
    } else {
        return str;
    }
};

export const getTemplateType = (template) => {
    if (template.includes("Uptime")) {
        return "DEFAULT";
    } else {
        return "DOWNTIME";
    }
};

// export const convertTemplateLiteralToHtml = (introText) => {
//     let newIntroText = introText.replace("${", "<span contentEditable='false'>[");
//     return newIntroText.replace("}", "]</span>").replace('`',"")
// };

//stripeTemplateIntroTextLiterals
export const stripeTemplateIntroTextLiterals = (text, characters) => {
    for (const [i, each] of characters.entries()) {
        console.log("i", i)
        const previousChar = Object.keys(each);
        const newChar = Object.values(each);
        ;

        text = text.replaceAll(previousChar, newChar);
    }
    return text;
};

export const convertHtmlToLiteralString = (htmlString) => {
    // eslint-disable-next-line
    return htmlString.replace(/\[([^\][]+)]/g, "$${$1}");
};

export const convertTemplateLiteralToHtml = (introText) => {
    const characters = [
        {
            "${": "<span contentEditable='false'>[",
        },
        {
            "}": "]</span>",
        },
        {
            "`": "",
        },
    ];
    return stripeTemplateIntroTextLiterals(introText, characters);
};

export const formatTemplateIntroText = (introText) => {
    let newIntroText = introText.replace("`${", "[");
    return newIntroText.replace("}`", "]");
};

export const generateLiteralIntroText = (introText) => {
    let newIntroText = introText;
    newIntroText = newIntroText.replace("{", "`${");
    newIntroText = newIntroText.replace("}", "}`");

    return newIntroText;
};

export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const dateToISO = (date) => {
    return new Date(date).toISOString();
};

export const flattenArray = (arr) => {
    return arr?.reduce(function (flat, toFlatten) {
        return flat?.concat(
            Array?.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
        );
    }, []);
};

const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (prefomattedDate) {
        return `${prefomattedDate} at ${hours}:${minutes}`;
    }
    if (hideYear) {
        return `${day}. ${month} at ${hours}:${minutes}`;
    }
    return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
}

export const getTimeAgo = (dateParam) => {
    if (!dateParam) {
        return null;
    }

    const date =
        typeof dateParam === "object" ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
        return "now";
    } else if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (seconds < 90) {
        return "about a minute ago";
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (isToday) {
        return getFormattedDate(date, "Today"); // Today at 10:20
    } else if (isYesterday) {
        return getFormattedDate(date, "Yesterday"); // Yesterday at 10:20
    } else if (isThisYear) {
        return getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return getFormattedDate(date); // 10. January 2017. at 10:20
};

export const generateUUID = () => uuidv4();

export const getHrsDiff = (startDate, endDate) => {
    if (!(startDate && endDate)) {
        return {
            startDate: null,
            endDate: null,
            hrsDiff: null,
        };
    }

    let diff =
        Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
        3600000;

    return {
        startDate: startDate.substring(0, 10),
        endDate: endDate.substring(0, 10),
        hrsDiff: !isNaN(diff) ? diff.toFixed(2) : 0,
    };
};

export const getFormattedStringDate = (
    inputDate,
    prefomattedDate = false,
    hideYear = false
) => {
    let date = new Date(inputDate);

    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (prefomattedDate) {
        return `${prefomattedDate} at ${hours}:${minutes}`;
    }
    if (hideYear) {
        return `${month} ${day}.at ${hours}:${minutes}`;
    }
    return `${month} ${day} ${year}. at ${hours}:${minutes}`;
};

export const generateID = (length = 10) => {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const reOrderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const isEmptyObject = (obj) => {
    return obj ? Object.keys(obj).length === 0 : false;
};

export const intervalToLevels = (interval) => {
    const levels = {
        scale: [24, 60, 60, 1],
        units: ["days ", "hours ", " mins ", "secs "],
    };

    const cbFun = (d, c) => {
        let bb = d[1] % c[0],
            aa = (d[1] - bb) / c[0];
        aa = aa > 0 ? aa + c[1] : "";

        return [d[0] + aa, bb];
    };

    let rslt = levels.scale
        .map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
        .map((d, i) => [d, levels.units[i]])
        .reduce(cbFun, ["", interval]);
    return rslt[0];
};

export const copyToClipboard = (content) => {
    return navigator.clipboard.writeText(content);
};

export const timeSince = (reqDate) => {
    let date = new Date(reqDate);
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return `${Math.floor(interval)} yr${Math.floor(interval) > 1 ? "s" : ""
            }`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} month${Math.floor(interval) > 1 ? "s" : ""
            }`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} day${Math.floor(interval) > 1 ? "s" : ""
            }`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hr${Math.floor(interval) > 1 ? "s" : ""
            }`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} min${Math.floor(interval) > 1 ? "s" : ""
            }`;
    }
    return Math.floor(seconds) < 1 ? "Now" : `${Math.floor(seconds)} s`;
};

export const SmartTime = (date) => {
    let dayInwords = moment(date).format("MMMM Do YYYY, h:mm a"); // June 12th 2020, 5:27:00 pm
    return dayInwords;

    // let dayInwords = HumanDateFormat(date);
    // let strTime = getDayTime(date);
    // return `${dayInwords} ${strTime}`;
};

export const localeDate = (date) => {
    return moment(date).format("L");
};

export const splitFileFormat = (fileName) => {
    const splitted = fileName?.split(".");
    return splitted[splitted?.length - 1];
};

export const getFileFormat = (fileType) => {
    if (fileType?.startsWith("image/")) {
        return IMAGE;
    } else if (fileType?.startsWith("application/")) {
        return FILE;
    } else if (fileType?.startsWith("video/")) {
        return VIDEO;
    }
};

export const getDaysHrsMinsAndSecs = (noOfSeconds) => {
    let hours = 0;
    let days = 0;
    let mins = 0;
    let secs = 0;

    let totalSeconds = Number(noOfSeconds);
    if (totalSeconds >= 86400) {
        let offSeconds = totalSeconds % 86400;
        days = (totalSeconds - offSeconds) / 86400;
        totalSeconds = offSeconds;
    }

    if (totalSeconds < 86400 && totalSeconds >= 3600) {
        let offSeconds = totalSeconds % 3600;
        hours = (totalSeconds - offSeconds) / 3600;
        totalSeconds = offSeconds;
    }

    if (totalSeconds < 3600 && totalSeconds >= 60) {
        let offSeconds = totalSeconds % 60;
        mins = (totalSeconds - offSeconds) / 60;
        totalSeconds = offSeconds;
    }

    secs = totalSeconds.toFixed(2);

    return { days, hours, mins, secs, total: noOfSeconds };
};

export const formatDuration = (duration) => {
    let { days, hours, mins } = getDaysHrsMinsAndSecs(duration?.toFixed?.(0));
    return duration < 60
        ? `${duration?.toFixed?.(2)}s`
        : `${days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""} ${hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"}` : ""
        } ${mins > 0 ? `${mins} ${mins === 1 ? "min" : "mins"}` : ""}`;
};

export const getSecondsSince = (reqDate) => {
    let date = new Date(reqDate);
    var seconds = Math.floor((date - new Date()) / 1000);
    return seconds;
};

export const getTimestampDate = (reqDate, format = "all") => {
    let fullDate = new Date(reqDate);
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = fullDate.getFullYear(); // 2019
    const date = fullDate.getDate();

    const monthName = months[fullDate.getMonth()];
    // const dayName = days[fullDate.getDay()];
    let hour = fullDate.getHours();

    let minute = fullDate.getMinutes();

    minute = minute < 10 ? `0${minute}` : minute;

    let timePostfix = hour > 12 ? "pm" : "am";

    hour = hour > 12 ? hour - 12 : hour;

    let formattedTime = `${hour}:${minute} ${timePostfix}`;

    let formattedDate = `${date} ${monthName} ${year}`;

    let commaSeparatedDate = `${date} ${monthName}, ${year}`;

    return format === "time"
        ? `${formattedTime}`
        : format === "date"
            ? commaSeparatedDate
            : `${formattedDate}, ${formattedTime}`;
};

export const getDateAndMonth = (reqDate) => {
    let fullDate = new Date(reqDate);
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const date = fullDate.getDate();

    const monthName = months[fullDate.getMonth()];

    return `${monthName} ${date}`;
};

export const getObjectsDifference = (obj1, obj2) => {
    let result = {};
    for (let key in obj1) {
        // ignore properties not present in both objects
        if (!(key in obj2)) continue;
        // ignore not same type, such as a number and string
        if (typeof obj1[key] !== typeof obj2[key]) continue;
        // when both are number, set the property to the difference of the two
        if (typeof obj1[key] === "number") {
            // only use different numbers
            if (obj1[key] < obj2[key]) {
                result[key] = true;
                // in case you really wanted the "diff" property
                // result.diff = obj2[key] - obj1[key]
            } else {
                result[key] = false;
            }
        }
    }

    return result;
};

export const convertCommentMentions = (comment, toHtml = true) => {
    let newComment = comment;

    if (toHtml) {
        newComment = newComment.split("@@@__").join("<a href='#");
        newComment = newComment.split("^^__").join("'>@");
        newComment = newComment.split("@@@^^^").join("</a>");
    } else {
        newComment = newComment.split("<a href='#").join("@@@__");
        newComment = newComment.split("'>@").join("^^__");
        newComment = newComment.split("</a>").join("@@@^^^");
    }

    return newComment;
};

export const getSecsFromNowToFuture = (futureDateTime) => {
    if (!futureDateTime) return null;
    let currentDate = new Date();
    let futureDate = new Date(futureDateTime);
    var diff = (futureDate.getTime() - currentDate.getTime()) / 1000;
    return Number.parseInt(diff);
};

export const formatFileSize = (size) => {
    const suffixes = ["bytes", "Kb", "Mb", "Gb"];
    const i = Math.floor(Math.log(size) / Math.log(1024));

    return (
        (!size && "0 bytes") ||
        (size / Math.pow(1024, i)).toFixed(2) + suffixes[i]
    );
};

const hexValue = (x) => {
    const hex = x.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};

export const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    return `#${hexValue(r)}${hexValue(g)}${hexValue(b)}`;
};

export const MonthDay = (date) => {
    var months = new Array(12);
    months[0] = "Jan";
    months[1] = "Feb";
    months[2] = "Mar";
    months[3] = "Apr";
    months[4] = "May";
    months[5] = "Jun";
    months[6] = "Jul";
    months[7] = "Aug";
    months[8] = "Sep";
    months[9] = "Oct";
    months[10] = "Nov";
    months[11] = "Dec";

    var current_date = new Date(date);
    let month_value = current_date.getMonth();
    let day_value = current_date.getDate();
    return months[month_value] + ", " + day_value;
};

export const sortTickets = (tickets, order) => {
    return tickets?.sort((a, b) =>
        order === "DESC"
            ? new Date(a.createdDate) - new Date(b.createdDate)
            : new Date(b.createdDate) - new Date(a.createdDate)
    );
};

export const validateCapsuleName = (capsuleName) => {
    if (!capsuleName) {
        return false;
    }
    const isValid = new RegExp(/^[ a-zA-Z0-9]+$/);
    return isValid.test(capsuleName) ? true : false;
};

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 */
export function roundUp(num, precision = 2) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
}

export const appendEmoji = (text, cursorPosition, emoji) => {
    return text.slice(0, cursorPosition) + emoji + text.slice(cursorPosition);
};

export const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
};

const rotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) +
            Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) +
            Math.abs(Math.cos(rotRad) * height),
    };
};

export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });

export const getCroppedImage = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            resolve(file);
            // resolve(URL.createObjectURL(file));
        }, "image/jpeg");
    });
};

export const generateConversationLink = (workspace, conversationId) => {
    const { useCustomDomain, workspaceUrlSlug, customDomain } = workspace;
    const chatBaseDomain = isLiveApp
        ? useCustomDomain
            ? customDomain
            : `https://${workspaceUrlSlug}.metacare.app`
        : apiGateway.LIVE_CHAT_URL;
    const convoUrl = `${chatBaseDomain}/conversation?${!isLiveApp ? `workspaceSlug=${workspaceUrlSlug}&` : ""
        }conversationId=${conversationId}`;
    return convoUrl;
};

export const isValidUrl = (string) => {
    try {
        const url = new URL(string);
        return url?.protocol === "http:" || url?.protocol === "https:";
    } catch (err) {
        return false;
    }
};

export const clearStorage = () => {
    localStorage.removeItem("persist:root");
};

export const jwtExpired = () => {
    const userToken = retriveAccessToken();

    if (!userToken) {
        clearStorage();
        return true;
    }

    const { tokenExpiry, loginTime } = retriveTokenExpiry();

    if (!tokenExpiry) {
        clearStorage();
        return true;
    }

    let expiry = tokenExpiry || "24h";
    let login = loginTime || new Date();

    const exp = expiry?.split("h")[0];

    const timestampDiff = new Date().getTime() - new Date(login).getTime();

    const diff = new Date(timestampDiff);

    if (diff.getHours() > exp) {
        clearStorage();
        return true;
    }

    return false;
};

export const getPageFromCache = (state, pageNumber) => state[pageNumber];

export const sumAll = (data) => {
    let sumResult = 0;
    data.forEach((element) => {
        if (isNumber(element)) {
            sumResult += element;
        }
    });

    return sumResult;
};

export const filterDataWithEmptyBars = (
    chartData,
    useFreq = false,
    useAI = false
) => {
    return chartData?.filter((data, key) => {
        const keys = Object.keys(data);
        let sumResult = 0;
        keys.forEach((el) => {
            if (
                el?.includes("FREQ") === useFreq &&
                el?.includes("AI") === useAI &&
                el !== "totalCount" &&
                el !== "dateValue" &&
                typeof data?.[el] !== "object"
                // isNumber(data?.[el])
            ) {
                sumResult += Number(data?.[el]);
            }
        });
        return sumResult !== 0 || key === 0 || key === chartData?.length - 1;
    });
};

export const formatDate = (reqDate) => {
    // return moment(reqDate).format("L");
    if (!reqDate) {
        return "";
    }
    var d = new Date(reqDate),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");

    // if (!reqDate) {
    //     return "";
    // }
    // const date = new Date(reqDate);
    // let year = date.getFullYear();
    // let month = date.getMonth();
    // let day = date.getDay();
    // // 

    // return `${year}-${month > 9 ? month : `0${month}`}-${
    //     day > 9 ? day : `0${day}`
    // }`;
};

export const formdateDatePickerDate = (reqDate) => {
    if (!reqDate) {
        return;
    }
    return moment(reqDate).toDate();
};

export const getMinsDiff = (startDate, endDate) => {
    if (!(startDate && endDate)) {
        return 0;
    }

    let diff = Math.abs(
        new Date(startDate).getTime() - new Date(endDate).getTime()
    );

    return (diff / 60000)?.toFixed?.(2);
};

export const getTimeAgoDiff = (startDate, endDate) => {
    if (!(startDate && endDate)) {
        return 0;
    }

    let noOfSeconds =
        Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
        1000;

    if (noOfSeconds < 60) {
        return `${noOfSeconds}sec(s)`;
    }

    if (noOfSeconds < 3600) {
        return `${(noOfSeconds / 60)?.toFixed(2)}min(s)`;
    }

    if (noOfSeconds < 86400) {
        return `${(noOfSeconds / 3600)?.toFixed(2)}hr(s)`;
    }

    return `${(noOfSeconds / 86400)?.toFixed(2)}day(s)`;
};

export const getNoOfSecondsBtw = (startDate, endDate) => {
    if (!(startDate && endDate)) {
        return 0;
    }

    let noOfSeconds =
        Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
        1000;

    return noOfSeconds;
};

export const merge2ObjectArraysUniquely = (
    firstArray,
    secondArray,
    commonKey
) => {
    const concatenatedArray = firstArray.concat(secondArray);

    // Use reduce to filter unique objects based on the common key. Ref - ChatGPT
    const resultArray = concatenatedArray.reduce((acc, obj) => {
        if (!acc.find((item) => item[commonKey] === obj[commonKey])) {
            acc.push(obj);
        }
        return acc;
    }, []);

    return resultArray;
};

export const sortFirstAndOthers = (
    keyToMoveFirst,
    targetObjectId,
    arrayOfObjects
) => {
    const customSort = (a, b) => {
        const idA = a[keyToMoveFirst];
        const idB = b[keyToMoveFirst];

        // Check if either object matches the target object
        if (idA === targetObjectId) return -1;
        if (idB === targetObjectId) return 1;

        // Sort the rest of the objects
        return idA - idB;
    };

    return arrayOfObjects?.sort?.(customSort);
};

export const removeDuplicateObjectsBasedOn2Keys = (
    arrayOfObjects,
    targetObjectIdA,
    targetObjectIdB
) => {
    const uniqueObjects = {};
    const result = [];

    for (const obj of arrayOfObjects) {
        const keyValue = `${obj[targetObjectIdA]}_${obj[targetObjectIdB]}`;

        if (!uniqueObjects[keyValue]) {
            uniqueObjects[keyValue] = true;
            result.push(obj);
        }
    }

    return result;
};

export const removeItemsFromArrayByCommonKey = (arr1, arr2, commonKey) => {
    const keysSet = new Set(arr1.map((item) => item[commonKey]));
    const newArray = arr2.filter((item) => !keysSet.has(item[commonKey]));
    return newArray;
};

export const removeEmptyKeysFromObj = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export const extractSubarrays = (arrayOfObjects, key) => {
    const result = arrayOfObjects.reduce((accumulator, obj) => {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            return accumulator.concat(obj[key]);
        }
        return accumulator;
    }, []);

    return result;
};

export const findArrayObjectBasedOnAKey = (arr, key, value) => {
    return arr?.find((x) => x[key] === value);
};

export const findSubArrayBasedOnObjectKey = (arr, key, value) => {
    return arr?.filter((x) => x[key] === value);
};

export const extractObjectKeyValueFromArray = (arr, key) => {
    if (arr?.length === 0) {
        return "";
    }

    const validObjects = arr.filter((obj) => obj.hasOwnProperty(key));
    const values = validObjects.map((obj) => obj[key]);
    const result = values.join(" ");

    return result;
};

export const extractActualTextsFromStringWithLinks = (inputString) => {
    const regex = /<a [^>]*>(.*?)<\/a>/g;

    // Replace all occurrences of <a> tags with the captured link text
    const resultString = inputString.replace(
        regex,
        (match, linkText) => linkText
    );

    return resultString;
};

export const extractActualValuesOfHyperlinks = (inputString) => {
    const regex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)["']/g;

    // Array to store extracted hrefs
    const hrefs = [];

    // Iterate over matches and extract hrefs
    inputString.replace(regex, (match, href) => {
        hrefs.push(href);
        return match; // Returning the original match to keep replacing
    });

    return hrefs;
};

export function replaceSubstringsWithHyperlinks(mainString, hyperlinkString) {
    // Extract the hyperlink text and href from the second string
    const regex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)["']>(.*?)<\/a>/;
    const match = hyperlinkString.match(regex);

    if (!match) {
        // No matching hyperlink found
        return mainString;
    }

    const [, href, hyperlinkText] = match;

    // Replace the substrings in the first string with the matching hyperlinks
    const replacedString = mainString.replace(
        /@([^@\s]+)(?=\s|$)/g,
        (match, username) => {
            // Check if the username matches the hyperlink text
            if (username.toLowerCase() === hyperlinkText.toLowerCase()) {
                // Replace with the matched hyperlink
                return `<a href="${href}">${match}</a>`;
            } else {
                // Keep the original substring
                return match;
            }
        }
    );

    return replacedString;
}

export const mergeArrays = (array1, array2, commonKey) => {
    const mergedArray = [];

    const map = new Map();
    array1.forEach((obj) => map.set(obj[commonKey], obj));

    array1.forEach((obj) => {
        mergedArray.push(obj);
    });

    array2.forEach((obj) => {
        if (!map.has(obj[commonKey])) {
            mergedArray.push(obj);
        }
    });

    return mergedArray;
};

export const sortObjectsArrayByKey = (arr, key) => {
    return arr.sort((a, b) => a[key] - b[key]);
};

export const extractUniqueArrayItemsFrom2ArraysBasedOnAKey = (
    array1,
    array2,
    key1,
    key2
) => {
    const commonObjects = [];

    array1.forEach((obj1) => {
        const matchingObj2 = array2.find((obj2) => obj1[key1] === obj2[key2]);

        if (matchingObj2) {
            commonObjects.push({
                ...obj1,
                ...matchingObj2,
            });
        }
    });

    return commonObjects;
};

export function checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    // eslint-disable-next-line 
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

export const arrayBufferToBase64 = buffer => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const generateNameColor = (firstName, lastName) => {

    const hashString = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash & 0xFFFFFF; // Ensure it is a 24-bit number
    };

    const hexValue = (value) => {
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    const hash = hashString(firstName + lastName);

    // Extract RGB values from the hash
    const r = (hash >> 16) & 0xFF;
    const g = (hash >> 8) & 0xFF;
    const b = hash & 0xFF;

    return `#${hexValue(r)}${hexValue(g)}${hexValue(b)}`;
};


export const getFormatedDate = (reqDate, getTimeOnly) => {
    const fullDate = new Date(reqDate);
    const now = new Date();

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const year = fullDate.getFullYear();
    const date = fullDate.getDate();
    const monthName = months[fullDate.getMonth()];

    let hour = fullDate.getHours();
    let minute = fullDate.getMinutes();
    minute = minute < 10 ? `0${minute}` : minute;
    let timePostfix = hour >= 12 ? "pm" : "am";
    hour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

    const formattedTime = `${hour}:${minute} ${timePostfix}`;
    const formattedDate = `${date} ${monthName} ${year}`;

    // Calculate difference in days
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const startOfReqDay = new Date(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDate()).getTime();

    const dayDifference = Math.floor((startOfReqDay - startOfToday) / oneDay);

    if (getTimeOnly) {
        return formattedTime; // Only time
    }

    if (dayDifference === 0) {
        // Same day
        return `${formattedTime}`;
    } else if (dayDifference > 0 && dayDifference <= 7) {
        // Within a week
        return `${dayDifference} day${dayDifference > 1 ? "s" : ""}, ${formattedTime}`;
    } else {
        // More than a week
        return `${formattedDate}, ${formattedTime}`;

    }
};

function areEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export const useDeepEffect = (effect, dependencies) => {
    const previousDependenciesRef = useRef();

    useEffect(() => {
        if (
            !previousDependenciesRef.current ||
            !areEqual(previousDependenciesRef.current, dependencies)
        ) {
            effect();
        }
        previousDependenciesRef.current = dependencies;
    }, [effect, dependencies]);
};

