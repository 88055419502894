import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import PropTypes from "prop-types";
import CreateRoleForm from "../../../CreateRoleForm/CreateRoleForm";

const CreateNewRole = ({ role, handleSuccess, currentAction }) => {
    return (
        <div className='row me-0 ms-0'>
            <div className='col-10 offset-1'>
                <ModalHeading
                    title={role ? "Edit Role" : "Create New Role"}
                    subTitle={
                        role
                            ? "Update your current role"
                            : "Setup a new role and permissions"
                    }
                />

                <CreateRoleForm
                    role={role}
                    handleSuccess={handleSuccess}
                    currentAction={currentAction}
                />
            </div>
        </div>
    );
};

CreateNewRole.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
};

export default CreateNewRole;
