import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import {
    BRANCH_CANNED_MESSAGE_OPTIONS,
    BRANCH_UPDATE_TYPES_NAMES,
} from "../../../enum";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { Button, Input } from "components/ui";
import SelectUI from "components/ui/SelectUI/SelectUI";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import ValidateForm from "utils/validations/Validator";

const { USE_EXISTING, ADD_NEW, NEW_UPDATE } = BRANCH_CANNED_MESSAGE_OPTIONS;

const AddCannedUpdateForm = ({
    handleSuccess,
    isEdit,
    closeModal,
    useExisting,
    isNew,
    handleSelectOption,
    cannedModalType,
    isUpdate,
    guideFlowId,
    selectedCannedResourceId,
    selectedAgentUpdateId,
}) => {
    const [errorMssg, setErrorMssg] = useState();

    const [cannedUpdates, setCannedUpdates] = useState([]);
    const [loadingResources, setLoadingResources] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();

    const [request, setRequest] = useState({
        agentUpdateName: "",
        agentUpdateType: cannedModalType,
        agentUpdateId: "",
    });

    const editCannedUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.cannedUpdate(request?.agentUpdateId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const attachAgentUpdateToGuide = async (agentUpdateId) => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.attachUpdateToGuideFlow(
                guideFlowId,
                selectedCannedResourceId,
                request?.agentUpdateId || agentUpdateId
            );

            const res = await API.post(url, request);
            if (res.status === 201) {
                setLoading(false);
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const createCannedUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.createCannedUpdate;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { data } = res.data;
                attachAgentUpdateToGuide(data?.agentUpdateId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const toastMessage = useContext(ToastContext);

    const handleSelect = (name, value) => {
        if (name === "agentUpdateId") {
            return setRequest(
                cannedUpdates?.find(
                    ({ value: agentUpdateId }) => value === agentUpdateId
                )
            );
        }
        handleSelectOption(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const getCannnedUpdates = async () => {
        try {
            setErrorMssg();
            setLoadingResources(true);
            const url = apiRoute.getCannedUpdates;
            const res = await API.get(url, {
                params: {
                    agentUpdateType: cannedModalType,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setCannedUpdates(
                    data?.map(({ agentUpdateId, agentUpdateName, ...rest }) => {
                        return {
                            label: agentUpdateName,
                            value: agentUpdateId,
                            agentUpdateId,
                            agentUpdateName,
                            ...rest,
                        };
                    })
                );
                setLoadingResources(false);
            }
        } catch (err) {
            setLoadingResources(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isNew
                ? createCannedUpdate()
                : isUpdate
                ? editCannedUpdate()
                : attachAgentUpdateToGuide();
        }

        setErrors(formErrors);
    };

    const modalCannedTypeName = BRANCH_UPDATE_TYPES_NAMES[cannedModalType];

    const selectCannedTypeName =
        BRANCH_UPDATE_TYPES_NAMES[`${cannedModalType}_S`];

    useEffect(() => {
        getCannnedUpdates();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedAgentUpdateId) {
            const findCannedAgentUpdate = cannedUpdates?.find(
                ({ agentUpdateId }) => agentUpdateId === selectedAgentUpdateId
            );
            setRequest(findCannedAgentUpdate);
        }
        // eslint-disable-next-line
    }, [selectedCannedResourceId, cannedUpdates, selectedAgentUpdateId]);

    useEffect(() => {
        setRequest({ ...request, agentUpdateType: cannedModalType });
        // eslint-disable-next-line
    }, [cannedModalType]);
    
    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            {!isNew && !isUpdate ? (
                <SelectUI
                    name={useExisting ? "agentUpdateId" : ""}
                    label={selectCannedTypeName}
                    darkLabel={true}
                    id={"name"}
                    placeholder={
                        useExisting
                            ? `Select ${modalCannedTypeName}`
                            : ` Select option`
                    }
                    isLoading={loadingResources}
                    options={
                        useExisting
                            ? cannedUpdates
                            : [
                                  {
                                      label: "Choose from existing data base",
                                      value: USE_EXISTING,
                                  },
                                  {
                                      label: isEdit
                                          ? `Edit ${modalCannedTypeName}`
                                          : "Add new",
                                      value: isEdit ? NEW_UPDATE : ADD_NEW,
                                  },
                              ]
                    }
                    defaultValue={cannedUpdates?.find(
                        ({ agentUpdateId }) =>
                            agentUpdateId === request?.agentUpdateId
                    )}
                    value={cannedUpdates?.find(
                        ({ agentUpdateId }) =>
                            agentUpdateId === request?.agentUpdateId
                    )}
                    handleChange={handleSelect}
                    error={errors?.agentUpdateId}
                    // autoFocus={autoFocus}
                    // serverError={serverError}
                />
            ) : (
                <>
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='agentUpdateName'
                        label={isNew ? `${selectCannedTypeName} title` : ""}
                        labelClass='text-dark fw-medium'
                        id='agentUpdateName'
                        placeholder={`Name of the ${modalCannedTypeName}`}
                        value={request?.agentUpdateName}
                        onChange={handleChange}
                        isErr={errors?.agentUpdateName}
                        errMssg={errors?.agentUpdateName}
                        autoFocus={true}
                    />
                </>
            )}

            <Button
                type='submit'
                text={
                    isUpdate
                        ? "Save and  edit update"
                        : isEdit
                        ? `Updated ${modalCannedTypeName}`
                        : `Add ${modalCannedTypeName}`
                }
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default AddCannedUpdateForm;
