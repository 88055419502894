import * as types from "./types";

export const setCurrentAnalyticsFilter = (data) => (dispatch) =>
    dispatch({ type: types.SET_CURRENT_ANALYTICS_FILTER, payload: data });

export const updateCurrentAnalyticsFilter = (data) => (dispatch) =>
    dispatch({ type: types.UPDATE_CURRENT_ANALYTICS_FILTER, payload: data });

export const setAnalyticsAgents = (data) => (dispatch) =>
    dispatch({ type: types.SET_ANALYTICS_AGENTS, payload: data });

export const setAnalyticsClassifications = (data) => (dispatch) =>
    dispatch({ type: types.SET_ANALYTICS_CLASSIFICATIONS, payload: data });

export const setAnalyticsTeams = (data) => (dispatch) =>
    dispatch({ type: types.SET_ANALYTICS_TEAMS, payload: data });

export const setAnalyticsCustomerChartData = (data) => (dispatch) =>
    dispatch({ type: types.SET_ANALYTICS_CUSTOMER_CHART_DATA, payload: data });

export const setAnalyticsChartData = (data) => (dispatch) =>
    dispatch({
        type: types.SET_ANALYTICS_CHART_DATA,
        payload: data,
    });

export const updateCurrentAnalyticsCustomDate = (data) => (dispatch) =>
    dispatch({
        type: types.UPDATE_CURRENT_ANALYTICS_CUSTOM_DATE,
        payload: data,
    });
