import "./CannedResponseHeader.scss";
import Heading from "components/ui/Heading/Heading";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import AddButton from "components/ui/Button/AddButton/AddButton";

const CannedResponseHeader = ({
    title,
    subTitle,
    btnText,
    placeholder,
    handleClick,
    handleSearch,
    searchIsLoading,
    clearSearch,
    defaultValue,
}) => {
    return (
        <>
            <Heading headingText={title} headingSubText={subTitle} />
            <div className='canned-response-header'>
                <PaginationSearch
                    placeholder={placeholder}
                    {...{
                        handleSearch,
                        searchIsLoading,
                        clearSearch,
                        defaultValue,
                    }}
                />
                <AddButton btnText={btnText} onClick={() => handleClick?.()} />
            </div>
        </>
    );
};

export default CannedResponseHeader;
