import asset from "assets/images";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import "./CreatePersonaHeader.scss";

const CreatePersonaHeader = ({ personaId }) => {
    return (
        <div className='create__persona__heading'>
            <div className='pre__nav padded__layout'>
                <Link to='/service-module/personas'>Discover personas</Link>
                <ReactSVG
                    src={asset?.icons?.rightBlack}
                    className='anchor-right'
                />
                <p>{personaId ? "Edit persona" : "Add new persona"} </p>
            </div>
        </div>
    );
};

export default CreatePersonaHeader;
