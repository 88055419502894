import LiveChatStatus from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/LiveChatStatus/LiveChatStatus";
import NavBarDropdown from "./NavBarDropdown/NavBarDropdown";
import NavNotification from "./NavNotification/NavNotification";

const NavBarDropdownGroup = ({ otherClassName }) => {
    
    return (
        <>
            <LiveChatStatus />
            <div 
                className={`navbar__dropdown ${otherClassName ? otherClassName : ''}`}
                >
                <NavNotification />
                <div className='middle-line'></div>
                <NavBarDropdown />
            </div>
        </>
    );
};

export default NavBarDropdownGroup;
