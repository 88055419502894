import { Table } from "components/ui";
import { Link } from "react-router-dom";

import AgentGuidesTableActions from "./AgentGuidesTableActions/AgentGuidesTableActions";
import AgentGuidesElements from "./AgentGuidesElements/AgentGuidesElements";

const AgentAGuidesTable = ({ guides, handleActionModal, tableIsLoading }) => {
    const tableData = guides?.map?.(
        ({ agentGuideName, agentGuideId, agentGuideElements, ...rest }) => {
            return {
                agentGuideName: <Link to={`/t-sam/guide/view/${agentGuideId}`} className="td-href">
                    {agentGuideName}
                </Link>,
                embeds: (
                    <AgentGuidesElements
                        agentGuideElements={agentGuideElements}
                    />
                ),
                actions: (
                    <AgentGuidesTableActions
                        agentGuideId={agentGuideId}
                        agentGuide={{
                            agentGuideName,
                            agentGuideId,
                            agentGuideElements,
                            ...rest,
                        }}
                        handleActionModal={handleActionModal}
                    />
                ),
            };
        }
    );
    return (
        <Table
            headers={["Guide name", "Embeds", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
        />
    );
};

export default AgentAGuidesTable;
