import React from "react";
import ReactCrop from "react-easy-crop";
import "./CropImage.scss";

const CropImage = ({
    crop,
    setCrop,
    setCompletedCrop,
    aspect,
    imgSrc,
    onImageLoad,
    zoom,
    setZoom,
    minZoom,
    maxZoom,
}) => {
    return (
        <div className='crop__image__container'>
            <ReactCrop
                image={imgSrc}
                crop={crop}
                onCropChange={setCrop}
                onCropComplete={setCompletedCrop}
                aspect={aspect}
                zoom={zoom}
                showGrid={false}
                onZoomChange={setZoom}
                onMediaLoaded={onImageLoad}
                minZoom={minZoom}
                maxZoom={maxZoom}
                classes={{
                    containerClassName: "cropper__container",
                }}
                restrictPosition={true}
            />
        </div>
    );
};

export default CropImage;
