import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import { baseResourceActions, baseResources } from "routes/enum";
import asset from "assets/images";
import { VIEW_GD_UPDATES_ACTIONS } from "../enum";

const { USER_MANAGEMENT_ROLES } = baseResources;
const { DELETE } = baseResourceActions;

const ViewGDUpdatesActions = ({ handleModalAction }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleModalAction(value)}
            options={[
                {
                    value: VIEW_GD_UPDATES_ACTIONS.DELETE_UPDATE,
                    label: "Delete Update",
                    baseResources: [`${DELETE}_${USER_MANAGEMENT_ROLES}`],
                },
            ]}
        />
    );
};

export default ViewGDUpdatesActions;
