import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes/routes";
import Sidebar from "components/ui/Sidebar/Sidebar";
import Nav from "components/ui/Nav/Nav";

export const AuthLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [collapseComplete, collapseCompletely] = useState(collapsed);

    const handleCollapseSidebar = () => {
        setCollapsed(!collapsed);
        setTimeout(() => collapseCompletely(!collapseComplete), 500);
    };

    const handleOpenSidebar = () => {
        setCollapsed(false);
        setTimeout(() => collapseCompletely(false), 500);
    };

    const { pathname } = useLocation();

    useEffect(() => {
        let allRoutes = routes?.filter((item) => !item?.children);
        const withChildren = routes?.filter((item) => item?.children);
        withChildren?.forEach((item) => {
            allRoutes = [...allRoutes, ...item?.children];
        });
    }, [pathname]);

    const useDcontent = pathname === "/agents-inbox/tickets";

    return (
        <div className='auth-layout d-flex  vw-100'>
            <Sidebar
                collapsed={collapsed}
                collapseComplete={collapseComplete}
                collapseSidebar={() => setCollapsed(true)}
                handleCollapseSidebar={handleCollapseSidebar}
                toggleOpenSidebar={handleOpenSidebar}
            />
            <div
                className={`auth-content auth-content--${
                    collapsed ? "collapsed" : "full"
                }`}>
                <Nav
                    collapsed={collapsed}
                    collapseComplete={collapseComplete}
                    toggleSidebar={handleCollapseSidebar}
                    pathname={pathname}
                />
                {/* ${!showNavbar ? "hide-nav" : ""} */}
                <div className={`auth-page custom-scroll-bar ${useDcontent ? 'd-contents' : ''}`}>
                    {/* {!showNavbar && (
                        <NavBarDropdownGroup otherClassName={"decompose"} />
                    )} */}
                    {children}
                </div>
            </div>
        </div>
    );
};
