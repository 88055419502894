import PropTypes from "prop-types";
import AddUserButton from "components/ui/Button/AddUserButton/AddUserButton";
import { TEAM_MEMBERS_ACTIONS } from "../../enums";

const AddNewTeamMemberButton = ({ handleActionModal }) => {
    return (
        <AddUserButton
            btnText='Add New User'
            onClick={() => handleActionModal(TEAM_MEMBERS_ACTIONS.ADD_USER)}
        />
    );
};

AddNewTeamMemberButton.prototypes = {
    handleActionModal: PropTypes.func.isRequired,
};

export default AddNewTeamMemberButton;
