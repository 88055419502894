import { useEffect, useState } from "react";

import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";

import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";

import ViewCapsulesHeader from "./ViewCapsulesHeader/ViewCapsulesHeader";
import ViewCapsulesListing from "./ViewCapsulesListing/ViewCapsulesListing";
import { getCapsuleCountBasedOnStatus } from "components/dashboard/KnowledgeBase/common/Capsule/lib";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const ViewCapsulesContainer = ({ capsuleClassId, capsuleType }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();

    const [capsuleClass, setCapsuleClass] = useState();
    const [capsuleClassCounts, updateCapsuleClassCounts] = useState(0);

    const getCapsuleClass = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url = apiRoute?.getCapsuleClass(capsuleClassId);

            const res = await API.get(url);
            if (res.status === 200) {
                setCapsuleClass(res.data.data);
                setStatus(SUCCESS);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getCapsuleClassCount = async () => {
        try {
            let capsuleCountVal = await getCapsuleCountBasedOnStatus(
                capsuleClass,
                capsuleType === "ON" ? true : false
            );
            updateCapsuleClassCounts(capsuleCountVal);
        } catch (err) {
            updateCapsuleClassCounts(0);
        }
    };

    useEffect(() => {
        getCapsuleClassCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capsuleClass]);

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case SUCCESS:
                return (
                    <>
                        <ViewCapsulesListing
                            capsuleClass={capsuleClass}
                            getCapsuleClass={getCapsuleClass}
                            capsuleClassCounts={capsuleClassCounts}
                            capsuleType={capsuleType}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClass}
                    />
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        getCapsuleClass();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capsuleType, capsuleClassId]);

    return (
        <>
            <ViewCapsulesHeader
                capsuleClassName={capsuleClass?.capsuleClassName}
                capsuleType={capsuleType}
            />

            <div className='padded__layout pt-3'>
                <div className='dependency'>{renderBasedOnStage()}</div>
            </div>
        </>
    );
};

export default ViewCapsulesContainer;
