import * as types from "./types";

const initialState = {
    agents: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_AGENTS_LIST:
            return {
                ...state,
                agents: payload,
            };

        default:
            return state;
    }
};

export default AuthReducer;
