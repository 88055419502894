import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Loading, Table } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { DOWNTIME_TABLE_HEADERS } from "../enum";
import { dataQueryStatus } from "utils";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import CreateDowntimeBranchModal from "../CreateDowntimeBranchModal/CreateDowntimeBranchModal";
import { getErrorMessage } from "utils/helper";
import {
    getCapsulesEntries,
    getCapsuleEntriesAggregateByWorkspace,
} from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import DowntimeBranch from "./DowntimeBranch/DowntimeBranch";
import { branchFormActions } from "components/dashboard/TrainSAM/SAM/Conversations/enums";
import Filter from "components/dashboard/common/Filter/Filter";
import API from "services/lib/api";
import { apiRoute } from "services";
import {
    setDowntimeBranchesBaseFilter,
    setDowntimeBranchesFilterParams,
    setDowntimeBranchesFilterOption,
} from "store/filters/actions";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;
const { CREATE_DOWNTIME_BRANCH } = branchFormActions;

const DowntimeBranchTable = ({ selectedCapsuleClass }) => {
    const [status, setStatus] = useState();
    const [showModal, toggleModal] = useState(false);
    const [currentReference, setCurrentReference] = useState({});
    const [currentAction, setCurrentAction] = useState();
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [capsulesDowntimeBranch, setCapsulesDowntimeBranch] = useState([]);
    const [selectedClassEntries, setSelectedClassEntries] = useState([]);
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.downtimeBranchesFilters
    );

    const dispatch = useDispatch();

    const getCapsuleClassEntries = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const { collectionKey } = selectedCapsuleClass;
            if (collectionKey) {
                const { capsuleEntries } = await getCapsulesEntries(
                    selectedCapsuleClass,
                    filterParams
                );

                const total = await getCapsuleEntriesAggregateByWorkspace(
                    selectedCapsuleClass
                );

                const newStatus =
                    capsuleEntries.length > 0 ? DATAMODE : NULLMODE;

                setStatus(newStatus);
                setTotalRecords(total);
                setSelectedClassEntries(capsuleEntries);
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getCapsulesDowntimeBranch = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url = apiRoute.getCapsulesDowntimebranch;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                setCapsulesDowntimeBranch(data);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const cleanCapsuleTypeName = (typename) => {
        // remove underscore for capsule __typename that contains it. Eg process_fail should be processfail
        return typename?.replace?.("_", "");
    };

    const handleRowAction = (actionType, data) => {
        toggleModal(true);
        setCurrentAction(actionType);
        setCurrentReference({
            capsuleId: selectedCapsuleClass?.capsuleClassId,
            ...data,
            classCapsuleEntryId:
                data[`${cleanCapsuleTypeName(data?.__typename)}id`],
        });
    };

    const tableData = () =>
        selectedClassEntries?.map((data) => ({
            capsules: <p className='mb-0'>{data?.content}</p>,
            downtimeBranch: (
                <DowntimeBranch
                    downtimeCapsule={{
                        ...data,
                        classCapsuleEntryId: data[`${data?.__typename}id`],
                        capsuleId: selectedCapsuleClass?.capsuleClassId,
                    }}
                    downtimeBranch={capsulesDowntimeBranch?.find(
                        (entry) =>
                            entry?.classCapsuleEntryId ===
                            data[`${data?.__typename}id`]
                    )}
                    addDownTimeBranch={() =>
                        handleRowAction(CREATE_DOWNTIME_BRANCH, data)
                    }
                />
            ),
            action: "",
        }));

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return <Empty className='h-100' text='No capsules created' />;
            case DATAMODE:
                return (
                    <>
                        <Table
                            headers={DOWNTIME_TABLE_HEADERS}
                            dataSource={tableData()}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={() => {
                            getCapsuleClassEntries();
                            getCapsulesDowntimeBranch();
                        }}
                    />
                );
            default:
                return "";
        }
    };

    const handlePageChange = (currentPage) => {
        dispatch(
            setDowntimeBranchesFilterParams({ ...filterParams, currentPage })
        );
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setDowntimeBranchesBaseFilter(baseFilter));

        dispatch(setDowntimeBranchesFilterOption({}));
    };

    const handleSearch = (search) => {
        setSearchIsLoading(true);
        dispatch(setDowntimeBranchesFilterParams({ ...filterParams, search }));
    };

    const handleSortByAlpha = (value, option) => {
        dispatch(
            setDowntimeBranchesFilterParams({
                ...filterParams,
                alphabeticalOrder: value,
                dateCreatedOrder: null,
                lastModified: null,
            })
        );
        dispatch(setDowntimeBranchesFilterOption({ ...option, value }));
    };

    const handleSortByDate = (value, option) => {
        dispatch(
            setDowntimeBranchesFilterParams({
                ...filterParams,
                dateCreatedOrder: value,
                alphabeticalOrder: null,
                lastModified: null,
            })
        );
        dispatch(setDowntimeBranchesFilterOption({ ...option, value }));
    };

    const handleSortByLastModified = (value, option) => {
        dispatch(
            setDowntimeBranchesFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setDowntimeBranchesFilterOption({ ...option, value }));
    };

    useEffect(() => {
        dispatch(
            setDowntimeBranchesFilterParams({ ...filterParams, currentPage: 1 })
        );
        // eslint-disable-next-line
    }, [selectedCapsuleClass]);

    useEffect(() => {
        getCapsuleClassEntries();
        if (capsulesDowntimeBranch?.length === 0) {
            // only call the list of all downtime branches when it has not be called before
            getCapsulesDowntimeBranch();
        }
        // eslint-disable-next-line
    }, [selectedCapsuleClass, filterParams]);

    return (
        <>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
                searchPlaceholder='Search downtime branches here'
                searchIsLoading={searchIsLoading}
                hideClassFilter
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
            />

            {renderBasedOnStatus()}

            <CustomPagination
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
                {...filterParams}
            />

            {showModal && (
                <CreateDowntimeBranchModal
                    show={showModal}
                    closeModal={() => toggleModal(false)}
                    modalFormAction={currentAction}
                    referenceData={currentReference}
                />
            )}
        </>
    );
};

export default DowntimeBranchTable;
