import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Empty } from "components/ui";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Loading } from "components/ui";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import FormsPostFormBranchesHeader from "./FormsPostFormBranchesHeader/FormsPostFormBranchesHeader";
import FormsPFBranchesActionDropdown from "./FormsPFBranchesActionDropdown/FormsPFBranchesActionDropdown";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { POST_FORM_COLLECTION } from "../AddBranchCollectionModal/enums";
import AddBranchCollectionModal from "../AddBranchCollectionModal/AddBranchCollectionModal";
import PostFormCollectionsGroup from "./PostFormCollectionsGroup/PostFormCollectionsGroup";
import { useHistory } from "react-router-dom";
import {
    POST_CANNED_RESPONSE_ACTIONS,
    POST_FORM_BRANCHES_ACTIONS,
    postFormActionTypes,
} from "./enums";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import {
    setFormBaseFilter,
    setFormFilterParams,
    setFormFilterOption,
} from "store/filters/actions";
import { saveForms, clearForms } from "store/modules/knowledgeBase/actions";
import AddFormCannedRespModal from "./modal/AddFormCannedRespModal/AddFormCannedRespModal";
import { updateFormsList } from "store/cache/forms/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const { ADD_POST_FORM_BRANCH, ADD_CANNED_RESPONSE } = postFormActionTypes;

const { EDIT_CANNED_RESPONSE, VIEW_CANNED_RESPONSE, DELETE_CANNED_RESPONSE } =
    POST_CANNED_RESPONSE_ACTIONS;

const FormsPostFormBranchesList = () => {
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [showCollectionModal, toggleCollectionModal] = useState(false);
    const [showCannedRespModal, toggleCannedRespModal] = useState(false);

    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [deleteModalAction, setDeleteModalAction] = useState();

    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [currentForm, setCurrentForm] = useState({});
    const [existingBranchCollections, setExistingBranchCollections] = useState(
        []
    );
    const [existingCannedReponses, setExistingCannedResponses] = useState([]);
    const [actionCRModalType, setActionCRModalType] = useState();
    const [errorMssg, setErrorMssg] = useState();
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.formFilters
    );
    const [forms, setForms] = useState([]);
    const [selectedCannedResponse, selectCannedResponse] = useState();

    const {
        formsCache: { pages, totalRecords },
    } = useSelector((state) => state?.knowledgeBase);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const history = useHistory();

    const handleCannedResponseActions = (actionType, data) => {
        switch (actionType) {
            case EDIT_CANNED_RESPONSE:
            case VIEW_CANNED_RESPONSE:
                toggleCannedRespModal(true);
                setActionCRModalType(actionType);
                selectCannedResponse(data);
                break;
            case DELETE_CANNED_RESPONSE:
                toggleDeleteModal(true);
                setDeleteModalAction(DELETE_CANNED_RESPONSE);
                selectCannedResponse(data);
                break;
            default:
                break;
        }
    };

    const handleRowAction = (actionType, data) => {
        const { formName, formId, branchCollections, cannedResponses } =
            data || {};

        setCurrentForm({ formName, formId });
        switch (actionType) {
            case ADD_POST_FORM_BRANCH:
                setExistingBranchCollections(branchCollections);
                toggleCollectionModal(true);
                break;
            case ADD_CANNED_RESPONSE:
                toggleCannedRespModal(true);
                setActionCRModalType(ADD_CANNED_RESPONSE);
                setExistingCannedResponses(cannedResponses);
                break;
            default:
                break;
        }
    };

    const handleRedirect = ({ formId }) => {
        history.push({
            pathname: `/knowledge-base/form/${formId}`,
            state: {
                formId,
            },
        });
    };

    const handleActionModal = (action, form) => {
        switch (action) {
            case POST_FORM_BRANCHES_ACTIONS.VIEW_FORM:
                handleRedirect(form);
                break;
            case POST_FORM_BRANCHES_ACTIONS.DELETE_FORM:
                setCurrentForm(form);
                setDeleteModalAction(POST_FORM_BRANCHES_ACTIONS.DELETE_FORM);
                toggleDeleteModal(true);
                break;
            default:
                break;
        }
    };

    const tableData = forms?.map(
        ({ formName, formId, branchCollections, cannedResponses }) => ({
            nameOfForm: formName,
            branch: (
                <PostFormCollectionsGroup
                    branchCollections={branchCollections}
                    referenceData={{ formName, formId }}
                    cannedResponses={cannedResponses}
                    handleCannedResponseActions={handleCannedResponseActions}
                />
            ),
            actions: (
                <FormsPFBranchesActionDropdown
                    form={{ formId, formName }}
                    handleActionModal={handleActionModal}
                    handleRowAction={handleRowAction}
                    referenceData={{
                        formName,
                        formId,
                        branchCollections,
                        cannedResponses,
                    }}
                />
            ),
        })
    );

    const getForms = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (forms?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.getForms;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setTableIsLoading(false);
                setStatus(newStatus);
                setForms(data);
                dispatch(updateFormsList(data));
                dispatch(
                    saveForms(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        // ---reset page number to 1 to query search from page 1
        dispatch(setFormFilterParams({ currentPage: 1, search }));
        dispatch(clearForms());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setFormFilterParams({ ...filterParams, currentPage }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setFormBaseFilter(baseFilter));

        dispatch(setFormFilterOption({}));
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setForms(cachedPage);
            dispatch(updateFormsList(cachedPage));
        } else {
            getForms(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return <ErrorView message={errorMssg} handleRetry={getForms} />;

            case NULLMODE:
                return (
                    <Empty
                        text={
                            useTableLoader
                                ? "No Form matches the search"
                                : "No Form has been created yet"
                        }
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <Table
                            headers={[
                                "Name of Form",
                                "Post Form Collections",
                                "Actions",
                            ]}
                            dataSource={tableData}
                            isLoading={tableIsLoading}
                            hasErrors={Boolean(errorMssg)}
                            tableError={errorMssg}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setFormFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setFormFilterOption({ ...option, value }));
        dispatch(clearForms());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setFormFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setFormFilterOption({ ...option, value }));
        dispatch(clearForms());
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setFormFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setFormFilterOption({ ...option, value }));
        dispatch(clearForms());
    };

    const handleSuccess = () => {
        getForms();
        dispatch(clearForms());
        // dispatch(
        //     setFormFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
    };

    return (
        <div className='main__layout'>
            <FormsPostFormBranchesHeader
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSortByLastModified={handleSortByLastModified}
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchDefaultValue={filterParams?.search}
            />
            {renderBasedOnStatus()}
            {showCollectionModal && (
                <AddBranchCollectionModal
                    show={showCollectionModal}
                    closeModal={() => toggleCollectionModal(false)}
                    referenceData={currentForm}
                    source={POST_FORM_COLLECTION}
                    existingBranchCollections={existingBranchCollections}
                    handleSuccess={handleSuccess}
                />
            )}

            {showCannedRespModal && (
                <AddFormCannedRespModal
                    show={showCannedRespModal}
                    closeModal={() => {
                        toggleCannedRespModal(false);
                        selectCannedResponse();
                    }}
                    referenceData={currentForm}
                    existingCannedReponses={existingCannedReponses}
                    cannedResponse={selectedCannedResponse}
                    actionModalType={actionCRModalType}
                    handleSuccess={handleSuccess}
                />
            )}

            {showDeleteModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete ${
                        deleteModalAction === DELETE_CANNED_RESPONSE
                            ? selectedCannedResponse?.title
                            : currentForm?.formName
                    }`}
                    show={showDeleteModal}
                    closeModal={() => {
                        toggleDeleteModal(false);
                        selectCannedResponse();
                    }}
                    handleSuccess={handleSuccess}
                    url={
                        deleteModalAction === DELETE_CANNED_RESPONSE
                            ? apiRoute.editCannedResponse(
                                  selectedCannedResponse?.cannedResponseId
                              )
                            : apiRoute.deleteForm(currentForm?.formId)
                    }
                    method='delete'
                />
            )}
        </div>
    );
};

export default FormsPostFormBranchesList;
