import React, { useRef } from "react";
import asset from "assets/images";
import { useState, useEffect } from "react";
import { deleteAccessToken } from "storage/cookieStorage";
import { Link, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteDeviceToken } from "storage/localStorage";
import store from "store/store";
import { SIGNOUT_REQUEST, SWITCH_WORKSPACE } from "store/rootReducer";
import { clearStorage } from "utils/helper";
import DropdownWorkspaces from "./DropdownWorkspaces/DropdownWorkspaces";
import SignIntoWorkspace from "./SignIntoWorkspace/SignIntoWorkspace";

const NavBarDropdown = () => {
    const [showDropdown, setDropdownVisbility] = useState(false);
    const [showWorkspaces, toggleShowWorkspaces] = useState(false);
    const [showSignInModal, toggleSignInModal] = useState(false);
    const [selectedWorkspace, selectWorkspace] = useState({});

    const {
        user: { imageUrl, firstName },
    } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const navbar = useRef();
    const history = useHistory();
    // const clearDeviceToken = async () => {
    //     try {
    //         const url = apiRoute.signout;
    //         const request = {
    //             workspaceId,
    //             email,
    //             deviceToken: retrieveDeviceToken(),
    //         };
    //         await API.post(url, request);
    //     } catch (err) {
    //
    //     }
    // };

    const logOut = async (e) => {
        e.preventDefault();
        // await clearDeviceToken();
        deleteDeviceToken();
        deleteAccessToken();
        clearStorage();
        await store.dispatch({ type: SIGNOUT_REQUEST });

        history.replace("/sign-in");
    };

    const switchWorkspace = () => {
        toggleShowWorkspaces(!showWorkspaces);
        setDropdownVisbility(true);
    };

    const resetUserStore = async () => {
        clearStorage();
        await dispatch({ type: SWITCH_WORKSPACE });
    };

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownVisbility(!showDropdown);
    };

    useEffect(() => {
        // document.body.addEventListener("click", (e) => {
        //     setDropdownVisbility(false);
        // });

        function handleClickOutside(event) {
            if (navbar.current && !navbar.current.contains(event.target)) {
                setDropdownVisbility(false);
                toggleShowWorkspaces(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () =>
            document.body.removeEventListener("click", () => {
                setDropdownVisbility(false);
                toggleShowWorkspaces(false);
            });
        // eslint-disable-next-line
    }, [navbar]);

    return (
        <>
            <div className='position-relative' ref={navbar}>
                <span className='nav-menu' onClick={toggleDropdown}>
                    <img
                        src={imageUrl || asset?.images?.placeholder}
                        alt={firstName}
                        className='profile'
                    />
                    <ReactSVG
                        src={asset?.icons?.dropdown}
                        className='d-inline-flex'
                    />
                </span>
                {showDropdown ? (
                    <>
                        {showWorkspaces ? (
                            <DropdownWorkspaces
                                toggleSignInModal={() =>
                                    toggleSignInModal(true)
                                }
                                selectWorkspace={selectWorkspace}
                                resetUserStore={resetUserStore}
                            />
                        ) : (
                            <div className='dd-menu'>
                                <Link to={`/admin/personal-profile`}>
                                    <span className='dd-menu--item'>
                                        Profile
                                    </span>
                                </Link>
                                <span
                                    className='dd-menu--item'
                                    onClick={switchWorkspace}>
                                    Switch Workspace
                                </span>
                                <span
                                    className='dd-menu--item'
                                    onClick={logOut}>
                                    Log Out
                                </span>
                            </div>
                        )}
                    </>
                ) : null}
            </div>
            {showSignInModal && (
                <SignIntoWorkspace
                    closeModal={() => toggleSignInModal(false)}
                    selectedWorkspace={selectedWorkspace}
                    resetUserStore={resetUserStore}
                />
            )}
        </>
    );
};

export default NavBarDropdown;
