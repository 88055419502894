import React from "react";
import Teams from "./Teams/Teams";
import UnassignedTeamClassification from "./UnassignedTeamClassification/UnassignedTeamClassification";

const Routing = () => {
    return (
        <div className='padded__layout'>
            <UnassignedTeamClassification />
            <Teams />
        </div>
    );
};

export default Routing;
