import React from "react";
import PropTypes from "prop-types";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import AttachCapsuleClassButton from "./AttachCapsuleClassButton/AttachCapsuleClassButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./AttachedCapsuleClassesList.scss";

const { KB_CAPSULE_SETTINGS } = baseResources;
const { CREATE } = baseResourceActions;

const AttachedCapsuleClassesList = ({
    attachedCapsuleClasses,
    selectedCapsule,
    handleCapsulationSuccess,
}) => {
    return (
        <>
            <div className='attached__capsule__classes__list'>
                {Array.from(attachedCapsuleClasses)?.map((item, i) => (
                    <Capsule capsule={item} key={i} />
                ))}
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${KB_CAPSULE_SETTINGS}`]}>
                    <AttachCapsuleClassButton
                        selectedCapsule={selectedCapsule}
                        handleCapsulationSuccess={handleCapsulationSuccess}
                    />
                </ComponentAccessControl>
            </div>
        </>
    );
};

AttachedCapsuleClassesList.prototypes = {
    attachedCapsuleClasses: PropTypes.array.isRequired,
};

export default AttachedCapsuleClassesList;
