import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";

import { MESSAGE_STATUSES } from "../../../../../enum";

const { SENDING, FAILED, RESENDING } = MESSAGE_STATUSES;

const MessageStatus = ({ status, handleResendMessage }) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case SENDING:
                return (
                    <div className='message__content__sending'>
                        <ReactSVG src={imageLinks?.icons?.loading} />
                    </div>
                );

            case RESENDING:
                return (
                    <div className='message__content__error'>
                        <span>Retrying...</span>
                    </div>
                );

            case FAILED:
                return (
                    <div
                        className='message__content__error'
                        onClick={() => handleResendMessage()}>
                        <span>Rety</span>
                        <ReactSVG src={imageLinks?.icons?.retry} />
                    </div>
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnStatus()}</>;
};

export default MessageStatus;
