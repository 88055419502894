import { Button, Input, Modal } from "components/ui";
import "./InboxAppModal.scss";
import { useContext, useState } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import ValidateForm from "utils/validations/Validator";
import InboxAppModalHeader from "./InboxAppModalHeader/InboxAppModalHeader";

const InboxAppModal = ({
    show,
    closeModal,
    handleNewConnection,
    handleError,
    handleSuccess,
    workspace,
}) => {
    const [loading, setLoading] = useState(false);

    const [request, setRequest] = useState({
        screenName: "",
        webHookUrl: "",
    });

    const [errors, setErrors] = useState({});

    const [errorMssg, setErrorMssg] = useState();

    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const connectWebhook = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.post(apiRoute?.connectWebhook, request);

            if (res.status === 201) {
                toastMessage("Webhook connected successfully");
                closeModal();
                handleSuccess?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            connectWebhook();
        } else setErrors(formErrors);
    };

    return (
        <>
            <Modal show={show} close={closeModal}>
                <InboxAppModalHeader
                    handleNewConnection={handleNewConnection}
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    workspace={workspace}
                    closeModal={closeModal}
                />
                <form onSubmit={handleSubmit}>
                    <ErrorDialog
                        show={Boolean(errorMssg)}
                        message={errorMssg}
                        hide={() => setErrorMssg()}
                    />
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='screenName'
                        label='Display Name'
                        labelClass='text-dark fw-medium'
                        id='screenName'
                        placeholder='Enter the webhook display name'
                        value={request?.screenName}
                        onChange={handleChange}
                        isErr={errors?.screenName}
                        errMssg={errors?.screenName}
                        autoFocus={true}
                    />
                    <Input
                        grpClass='mb-3'
                        type='url'
                        name='webHookUrl'
                        label='Webhook URL'
                        labelClass='text-dark fw-medium'
                        id='webHookUrl'
                        placeholder='Enter the webhook URL'
                        value={request?.webHookUrl}
                        onChange={handleChange}
                        isErr={errors?.webHookUrl}
                        errMssg={errors?.webHookUrl}
                        autoFocus={true}
                    />
                    <Button
                        type='submit'
                        text='Submit'
                        classType='primary'
                        loading={loading}
                        otherClass='my-3 w-100'
                    />
                </form>

                <Button
                    text='Cancel'
                    type='submit'
                    classType='plain'
                    otherClass='w-100 fw-bold'
                    onClick={closeModal}
                />
            </Modal>
        </>
    );
};

export default InboxAppModal;
