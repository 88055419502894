const primaryCapsuleCodes = {
    FEATURE: 'FEATURE',
    FLOW: 'FLOW',
    SOLUTION: 'SOLUTION',
    ROADBLOCK: 'ROADBLOCK',
    FAQ: 'FAQ',
    PROCESS_FAIL: 'PROCESS_FAIL',
    HOW_TO: 'HOW_TO',
    GOAL_OF: 'GOAL_OF',
}

const primaryCapsuleCodesArr = Object.values(primaryCapsuleCodes)

export { primaryCapsuleCodes, primaryCapsuleCodesArr }