import React from "react";
import "./ExternalEmailsHeader.scss";
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const ExternalEmailsHeader = ({ show, toggleShow }) => {
    return (
        <div className='external__emails__header'>
            <h2 className='external__emails__header--title'>
                {/* Email correspondence history */}
                Conversation thread history
                <ReactSVG
                    src={!show ? asset.icons.upside : asset.icons.downside}
                    onClick={() => toggleShow(!show)}
                />
            </h2>
            {/* <p className='external__emails__header--sub__title'>
                Find external emails that have been sent and received in this
                ticket in the email correspondence history here.
            </p> */}
        </div>
    );
};

export default ExternalEmailsHeader;
