import React, { useState } from 'react';
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const SearchInput = ({ placeholder = "Search", handleSearch, handleChange }) => {
    const [inputValue, setInputValue] = useState("")

    return (
        <div className='search__input'>
            <input
                type="text"
                name={"search"}
                className={`form-control`}
                placeholder={placeholder}
                onChange={({ target: { value } }) => handleChange ? handleChange(value) : setInputValue(value)}
                autoComplete="off"
            />
            <div className='search__input--button' onClick={() => handleSearch(inputValue)}>
                <ReactSVG
                    src={asset?.icons?.search}
                />
            </div>

        </div>
    );
};

export default SearchInput;