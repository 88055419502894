export const UNDISCOVERED = "undiscovered";
export const NO_TAGS = "noTags";
export const NO_TICKET_INFO = "noTicketInfo";
export const AUTOMATED = "Automated";
export const AGENT_GUIDE = "AGENT_GUIDE";

export const ticketPhases = {
    ISSUE_DISCOVERY: "ISSUE_DISCOVERY",
    PROBLEM_CONFIRMATION: "PROBLEM_CONFIRMATION",
    SOLUTION_DELIVERY: "SOLUTION_DELIVERY",
};

export const ticketsClassifications = {
    HIGH: "HIGH",
    MEDIUM: "MEDIUM",
    LOW: "LOW",
};

export const ticketInfoTypes = {
    FORM: "FORM",
    CAPSULE: "CAPSULE",
    CONVERSATION: "CONVERSATION",
};

export const otherCategories = {
    FOLLOW_UP: "FOLLOW_UP",
    EXTERNAL_EMAILS: "EXTERNAL_EMAILS",
    MENTIONS: "MENTIONS",
    AGENT_ASSISTANCE: "AGENT_ASSISTANCE",
};

export const EXTERNAL_EMAIL_ACTION = {
    REPLY_EMAIL: "REPLY_EMAIL",
    PREVIEW_EMAIL: "PREVIEW_EMAIL",
    SEND_NEW_EMAIL: "SEND_NEW_EMAIL",
};
