import * as types from "./types";

export const saveGuides = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({
        type: types.SAVE_GUIDES,
        payload: { pageNumber, totalRecords, data },
    });
export const clearGuides = () => (dispatch) =>
    dispatch({ type: types.CLEAR_GUIDES });

export const saveScenarios = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({
        type: types.SAVE_SCENARIOS,
        payload: { pageNumber, totalRecords, data },
    });
export const clearScenarios = () => (dispatch) =>
    dispatch({ type: types.CLEAR_SCENARIOS });

export const saveCannedEmails =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_CANNED_EMAILS,
            payload: { pageNumber, totalRecords, data },
        });
export const clearCannedEmails = () => (dispatch) =>
    dispatch({ type: types.CLEAR_CANNED_EMAILS });

export const saveCannedMentions =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_CANNED_MENTIONS,
            payload: { pageNumber, totalRecords, data },
        });
export const clearCannedMentions = () => (dispatch) =>
    dispatch({ type: types.CLEAR_CANNED_MENTIONS });

export const saveCannedReplies =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_CANNED_REPLIES,
            payload: { pageNumber, totalRecords, data },
        });
export const clearCannedReplies = () => (dispatch) =>
    dispatch({ type: types.CLEAR_CANNED_REPLIES });

export const saveEmailUpdates =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_EMAIL_UPDATES,
            payload: { pageNumber, totalRecords, data },
        });
export const clearEmailUpdates = () => (dispatch) =>
    dispatch({ type: types.CLEAR_EMAIL_UPDATES });

export const saveMentionUpdates =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_MENTION_UPDATES,
            payload: { pageNumber, totalRecords, data },
        });
export const clearMentionUpdates = () => (dispatch) =>
    dispatch({ type: types.CLEAR_MENTION_UPDATES });

export const saveReplyUpdates =
    (pageNumber, totalRecords, data) => (dispatch) =>
        dispatch({
            type: types.SAVE_REPLY_UPDATES,
            payload: { pageNumber, totalRecords, data },
        });
export const clearReplyUpdates = () => (dispatch) =>
    dispatch({ type: types.CLEAR_REPLY_UPDATES });
