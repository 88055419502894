import { useState, useEffect } from "react";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ClassificationElementList from "./ClassificationElementList/ClassificationElementList";
import ClassificationsHeader from "./ClassificationsHeader/ClassificationsHeader";
import ClassificationsList from "./ClassificationsList/ClassificationsList";
import EmptyClassificationsList from "./EmptyClassificationsList/EmptyClassificationsList";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import { saveClassification } from "store/modules/agentInbox/actions";
import { connect } from "react-redux";
import { getErrorMessage } from "utils/helper";
import ClassificationModal from "./ClassificationsHeader/ClassificationsModal/ClassificationsModal";

const { IDLE, NULLMODE, ERROR, DATAMODE, LOADING } = dataQueryStatus;

const Classifications = ({
    saveClassification,
    classifications,
    selectedUnassignedCapsule,
    prevAssigned,
    setPrevAssigned,
}) => {
    const [status, setStatus] = useState(
        classifications?.length > 0 ? DATAMODE : IDLE
    );
    const [elementStatus, setElementsStatus] = useState(IDLE);
    const [selectedClassification, setSelectedClassification] = useState({});
    const [classificationElements, setClassificationElements] = useState([]);
    const [errorMssg, setErrorMssg] = useState();
    const [elementErrorMssg, setELementErrorMssg] = useState();
    const [showClassificationModal, toggleClassificationModal] =
        useState(false);
    const [isEdit, setEdit] = useState(true);

    const handleToggleClassificationModal = () => {
        toggleClassificationModal(!showClassificationModal);
        setEdit(!isEdit);
    };

    const getClassifications = async (classification, httpRequest) => {
        try {
            if (classifications?.length < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.classifications;
            const res = await API.get(url, { signal: httpRequest?.signal });
            if (res.status === 200) {
                const { data } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;

                await saveClassification(data);
                const classificationElements = data?.map(
                    (classification) => classification?.elements
                );

                const elements = classificationElements?.flat();

                setPrevAssigned(elements);
                await handleClassificationSelect(
                    Object.keys(classification).length > 0
                        ? classification
                        : data[0]
                );
                setStatus(newStatus);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleClassificationSelect = async (classification) => {
        try {
            setSelectedClassification(classification);
            setElementsStatus(LOADING);

            const { classificationId } = classification;
            const url = apiRoute.classification(classificationId);
            const res = await API.get(url);
            if (res.status === 200) {
                const { elements } = res.data.data;

                const formattedData = elements?.filter(
                    (element) =>
                        element?.capsuleEntryId !== null ||
                        element?.capsuleEntryId === ""
                );

                setClassificationElements(formattedData);
                setElementsStatus(DATAMODE);
            }
        } catch (err) {
            setElementsStatus(ERROR);
            setELementErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getClassifications({}, httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return <EmptyClassificationsList />;

            case DATAMODE:
                return (
                    <>
                        <ClassificationsList
                            setSelectedClassification={
                                handleClassificationSelect
                            }
                            classifications={classifications}
                            selectedClassification={selectedClassification}
                            handleSuccess={() => getClassifications({})}
                            toggleClassificationModal={
                                toggleClassificationModal
                            }
                            setEdit={setEdit}
                        />
                        <ClassificationElementList
                            selectedUnassignedCapsule={
                                selectedUnassignedCapsule
                            }
                            status={elementStatus}
                            elements={classificationElements}
                            errorMssg={elementErrorMssg}
                            selectedClassification={selectedClassification}
                            handleSuccess={(classification) => {
                                setSelectedClassification(classification);
                                getClassifications(classification);
                            }}
                            prevAssigned={prevAssigned}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        size='50'
                        handleRetry={getClassifications}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='col-lg-12'>
            <ClassificationsHeader
                count={classifications?.length}
                handleSuccess={(classification) => {
                    setSelectedClassification(classification);
                    getClassifications(classification);
                }}
                prevAssigned={prevAssigned}
            />
            {renderCompBasedOnStage()}
            {showClassificationModal && (
                <ClassificationModal
                    showModal={showClassificationModal}
                    closeModal={handleToggleClassificationModal}
                    isEdit={isEdit}
                    selectedClassification={selectedClassification}
                    elements={classificationElements}
                    selectedUnassignedCapsule={selectedUnassignedCapsule}
                    handleSuccess={(classification) => {
                        setSelectedClassification(classification);
                        getClassifications(classification);
                    }}
                    prevAssigned={prevAssigned}
                />
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        classifications: state?.agentInbox?.classifications,
    }),
    { saveClassification }
)(Classifications);
