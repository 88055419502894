import { branchOptionTypes } from "../BranchOptionView/BranchOption/enum";

const { BRANCH, COLLECTION, FORM } = branchOptionTypes;

export const setExistingBranchOptionConnections = (
    data,
    setExistingOptionConnections
) => {
    return setExistingOptionConnections({
        [BRANCH]: data?.branchOptions
            ?.filter(({ branchOptionType }) => branchOptionType === BRANCH)
            ?.map(({ branchOptionValue }) => branchOptionValue),
        [COLLECTION]: data?.branchOptions
            ?.filter(({ branchOptionType }) => branchOptionType === COLLECTION)
            ?.map(({ branchOptionValue }) => branchOptionValue),
        [FORM]: data?.branchOptions
            ?.filter(({ branchOptionType }) => branchOptionType === FORM)
            ?.map(({ branchOptionValue }) => branchOptionValue),
    });
};
