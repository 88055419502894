import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateBranceScenarioForm from "./CreateBranceScenarioForm/CreateBranceScenarioForm";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";

const CreateBranceScenario = ({
    showModal,
    closeModal,
    isEdit,
    handleSuccess,
    selectedScenario,
    guideUpdateLoading,
    hasExternalCall,
    errorMssg,
    setErrorMssg,
    existingScenarios,
    existingCollections,
    hideExistingScenarios
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ErrorDialog
                        show={Boolean(errorMssg)}
                        message={errorMssg}
                        hide={() => setErrorMssg?.()}
                    />
                    <ModalHeading
                        title={isEdit ? "Update a scenario" : "Add a scenario"}
                    />
                    <CreateBranceScenarioForm
                        handleSuccess={handleSuccess}
                        selectedScenario={selectedScenario}
                        isEdit={isEdit}
                        closeModal={closeModal}
                        guideUpdateLoading={guideUpdateLoading}
                        hasExternalCall={hasExternalCall}
                        existingScenarios={existingScenarios}
                        existingCollections={existingCollections}
                        hideExistingScenarios={hideExistingScenarios}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default CreateBranceScenario;
