import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { REMINDERS_TABLE_ACTIONS } from "../../enums";
import { baseResourceActions } from "routes/enum";

const { UPDATE, DELETE } = baseResourceActions;

const RemindersTableActions = ({ handleActionModal }) => {
    return (
        <>
            <Dropdown
                size='sm'
                placeholder={
                    <ReactSVG
                        src={asset?.icons?.horizontalEllipsis}
                        className='d-inline-flex'
                    />
                }
                ddValueClass='borderless'
                className='table__dropdown'
                ddOptionsClass='table__dropdown'
                anchor={false}
                changeValue={false}
                onChange={({ value }) => handleActionModal(value)}
                options={[
                    {
                        value: REMINDERS_TABLE_ACTIONS.EDIT_REMINDER,
                        label: "Edit Reminder",
                        baseResources: [`${UPDATE}_${"the resource"}`],
                    },
                    {
                        value: REMINDERS_TABLE_ACTIONS.DELETE_REMINDER,
                        label: "Delete Reminder",
                        baseResources: [`${DELETE}_${"the resource"}`],
                    },
                ]}
            />
        </>
    );
};

export default RemindersTableActions;
