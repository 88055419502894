import PropTypes from "prop-types";
import CannedResponseHeader from "./CannedResponseHeader/CannedResponseHeader";
import CannedResponseList from "./CannedResponseList/CannedResponseList";

const CannedResponse = ({
    cannedResponses,
    handleSuccess,
    selectedMssgTagId,
}) => {
    return (
        <>
            <CannedResponseHeader
                selectedMssgTagId={selectedMssgTagId}
                handleSuccess={handleSuccess}
            />
            <CannedResponseList
                cannedResponses={cannedResponses}
                handleSuccess={handleSuccess}
            />
        </>
    );
};

CannedResponse.prototypes = {
    cannedResponses: PropTypes.array.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default CannedResponse;
