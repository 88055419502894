import MessageBranchOption from "./MessageBranchOption/MessageBranchOption";
import "./MessageOptions.scss";

const MessageOptions = ({
    options,
    selectedOption,
    branchOptionId
}) => {
    return (
        <div className="message__options">
            {options?.map((option, index) => (
                <MessageBranchOption
                    key={index}
                    data={option}
                    selectedOption={selectedOption}
                    branchOptionId={branchOptionId}
                />
            ))}
        </div>

    );
};

export default MessageOptions;
