// import AddButton from 'components/ui/Button/AddButton/AddButton';
import React from 'react';

const BCMapViewHeader = ({ title }) => {
    return (
        <div className='branches__header'>
            <div className="row align-items-center">
                <div className="col-md-8">
                    <h6 className='main__subheading main__subheading--md'>{title}</h6>
                </div>
                <div className="col-md-2 ms-auto">
                    {/* <AddButton btnText={'Add a Collection'} /> */}
                </div>
            </div>
        </div>
    );
};

export default BCMapViewHeader;