import AddButton from "components/ui/Button/AddButton/AddButton";

import { FOLLOW_UP_TABLE_ACTIONS } from "../../../enum";

const { CREATE_FOLLOW_UP_COLLECTION } = FOLLOW_UP_TABLE_ACTIONS;

const AddFollowUp = ({ capsuleEntry, handleActionModal }) => {
    return (
        <AddButton
            btnText='Add follow up collection'
            otherClassName='agent-follow-up__add-button'
            onClick={() =>
                handleActionModal(CREATE_FOLLOW_UP_COLLECTION, capsuleEntry)
            }
        />
    );
};

export default AddFollowUp;
