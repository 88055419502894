import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const AddContentDescriptionForm = ({
    selectedFormat,
    capsuleData,
    handleSuccess,
    editItem,
}) => {
    const [loading, setLoading] = useState(false);
    const [request, updateRequest] = useState({
        capsuleClassId: capsuleData?.capsule?.capsuleClassId,
        classCapsuleEntryId: capsuleData?.contentId,
        content: editItem?.content || "",
        contentType: selectedFormat,
    });
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createCapsuleContentTextBlock = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute.createCapsuleContentBlock;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editCapsuleContentTextBlock = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const { content } = request;
            const url = apiRoute.capsuleContentBlock(editItem?.contentId);
            const res = await API.put(url, { content });

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            if (editItem) {
                return editCapsuleContentTextBlock();
            }
            return createCapsuleContentTextBlock();
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMssg()}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='content'
                label='Description'
                labelClass='text-dark fw-medium'
                id='content'
                placeholder=''
                value={request?.content}
                onChange={handleChange}
                isErr={errors?.content}
                errMssg={errors?.content}
                autoFocus={true}
            />
            <Button
                type='submit'
                text={editItem ? "Edit description" : "Save description"}
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

AddContentDescriptionForm.prototypes = {
    selectedFormat: PropTypes.string,
    capsuleData: PropTypes.object,
    handleSuccess: PropTypes.func,
    editItem: PropTypes.object,
};

export default AddContentDescriptionForm;
