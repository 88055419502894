import React, { useState } from "react";
import { isValidUrl } from "utils/helper";
import ImageView from "components/essentials/ImageView/ImageView";
import ModalPreview from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/ModalPreview/ModalPreview";
import { useSelector } from "react-redux";
import "./TicketFormDetailsList.scss";


const TicketFormDetailsList = ({ data }) => {

    const [showModal, toggleModal] = useState(false);
    const [preview, setPreview] = useState({
        fileAttachmentUrl: "",
        fileAttachmentType: "",
    });

    const { ticketsMessages, activeTicket } = useSelector(
        (state) => state.tickets
    );

    const { ticketId } = activeTicket || {};

    const messages = ticketsMessages?.filter(
        (item) => item?.ticketId === ticketId
    );

    const fileAttachments = ((messages?.map((x) => x.fileAttachments)).filter(Boolean))?.flat();

    const openPreviewModal = (formElementValue) => {
        const file = fileAttachments?.find((x) => x.fileAttachmentUrl === formElementValue);
        if (file){
            setPreview(file);
            toggleModal(true);
        }
       
    };

    const closePreviewModal = () => {
        setPreview({ fileAttachmentUrl: "", fileAttachmentType: "" });
        toggleModal(false);
    };

    const isFormElementValueAwsUrlAlike = (string) => {
        // this is for form element with url which looks like this "ed1b75b0-478c-402e-b60a-d460d13e4793-undefined"
        let onlyDigitsRegex = /\D/;
        let hasNonDigits = onlyDigitsRegex.test(string);

        try {
            if (
                string?.includes("undefined") &&
                string?.length > 10 &&
                hasNonDigits
            ) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    };

    return (
        <>
            <div className='tickets__form--list'>
                {data?.map(({ formElementLabel, formElementValue }, index) => (
                    <div className='filled__record' key={index}>
                        <h6>{formElementLabel}</h6>
                        <div className='filled__value'>
                            {isValidUrl(formElementValue) ? (
                                <a
                                    href={formElementValue}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='filled__value__url'>
                                    {formElementValue}
                                </a>
                            ) : (
                                <>
                                    {isFormElementValueAwsUrlAlike(
                                        formElementValue
                                    ) ? (
                                        <ImageView
                                            src={formElementValue}
                                            alt='media'
                                            className='filled__value__image cursor-pointer'
                                            onClick={() => openPreviewModal(formElementValue)}
                                        />
                                    ) : (
                                        <span className='filled__value__text'>
                                            {formElementValue}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {showModal && (
                <ModalPreview
                    showModal={showModal}
                    toggleModal={() => closePreviewModal()}
                    file={preview}
                    preview={preview?.fileAttachmentUrl}
                    previewType={preview?.fileAttachmentType}
                />
            )}
        </>
    );
};

export default TicketFormDetailsList;
