export const STANDARD = "STANDARD";
export const WIDE = "WIDE";

export const imageCropScales = {
    [STANDARD]: "standard",
    [WIDE]: "wide",
};

export const aspectRatios = {
    standardRatio: 3 / 2,
    wideRatio: 16 / 9,
};
