import { Table } from "components/ui";
import UpdatesTableAction from "../../../common/UpdatesTableAction/UpdatesTableAction";
import CannedUpdateDuration from "../../../common/CannedUpdateDuration/CannedUpdateDuration";

export const canned_resource_style = {
    MENTIONS: "update__mention-box",
    CANNED_EMAIL: "update__email-box",
    CANNED_RESPONSE: "update__response-box",
};

export const canned_resource_names = {
    MENTIONS: "Mention",
    CANNED_EMAIL: "Email",
    CANNED_RESPONSE: "Response",
};

const EmailFollowupsTable = ({ handleActionModal, cannedTasks }) => {
    const tableData = cannedTasks?.map?.(
        ({
            agentUpdateTaskName,
            initiationSchedule,
            agentUpdateTaskType,
            ...rest
        }) => {
            return {
                name: (
                    <div>
                        {agentUpdateTaskName}{" "}
                        <span
                            className={`${canned_resource_style[agentUpdateTaskType]}`}>
                            {canned_resource_names[agentUpdateTaskType]} update
                        </span>
                    </div>
                ),
                attachedGuides: <CannedUpdateDuration duration={initiationSchedule} />,
                actions: (
                    <UpdatesTableAction
                        editText={"Edit"}
                        hideView
                        handleModalAction={(actionType) =>
                            handleActionModal(actionType, {
                                agentUpdateTaskName,
                                initiationSchedule,
                                agentUpdateTaskType,
                                ...rest,
                            })
                        }
                    />
                ),
            };
        }
    );

    return (
        <Table
            headers={["Name", "Duration", "Actions"]}
            dataSource={tableData}
        />
    );
};

export default EmailFollowupsTable;
