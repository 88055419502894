import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { FOLLOW_UP_TASK_ACTIONS } from "../../enum";
import AddNewTaskModal from "../AddNewTaskModal/AddNewTaskModal";
import { apiRoute } from "services";

const { ADD_NEW_FOLLOW_UP_TASK, EDIT_FOLLOW_UP_TASK, DELETE_FOLLOW_UP_TASK } =
    FOLLOW_UP_TASK_ACTIONS;

const FollowUpTaskViewModals = ({
    showModal,
    setShowModal,
    actionType,
    getFollowUpTasks,
    selectedFollowUpTask,
}) => {
    const renderBasedOnActionType = () => {
        switch (actionType) {
            case ADD_NEW_FOLLOW_UP_TASK:
            case EDIT_FOLLOW_UP_TASK:
                return (
                    <AddNewTaskModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        getFollowUpTasks={getFollowUpTasks}
                        isEdit={actionType === EDIT_FOLLOW_UP_TASK}
                        selectedFollowUpTask={selectedFollowUpTask}
                    />
                );
            case DELETE_FOLLOW_UP_TASK:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${selectedFollowUpTask?.taskTitle}`}
                        show={showModal}
                        closeModal={() => setShowModal(false)}
                        handleSuccess={getFollowUpTasks}
                        url={apiRoute?.deleteFollowUpTask(
                            selectedFollowUpTask?.taskId
                        )}
                        method='delete'
                    />
                );

            default:
                return "";
        }
    };

    return <>{renderBasedOnActionType()}</>;
};

export default FollowUpTaskViewModals;
