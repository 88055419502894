import React from "react";
import { useLocation } from "react-router-dom";
import { branchCollectionData } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/data";
import BreadCrumbHeader from "components/ui/BreadCrumbHeader/BreadCrumbHeader";
import { SOLUTIONS_COLLECTION } from "../../../../KnowledgeBase/AddBranchCollectionModal/enums";
import PreHeading from "components/ui/PreHeading/PreHeading";

const BranchCollectionViewHeader = ({ branchCollection, hasError }) => {
    const { branchCollectionType, branchCollectionName } =
        branchCollection || {};

    const location = useLocation();
    const { conversationPath } = location?.state || {};

    return (
        <>
            {branchCollectionType === SOLUTIONS_COLLECTION ? (
                <PreHeading
                    text={`${
                        conversationPath
                            ? "Conversations"
                            : "Solution Collections"
                    }`}
                    subText={branchCollectionName}
                    url={`${
                        conversationPath
                            ? conversationPath
                            : "/knowledge-base/solution-setup"
                    }`}
                    hasError={hasError}
                />
            ) : (
                <BreadCrumbHeader
                    prevPage={`/knowledge-base/forms`}
                    currentPage={"/knowledge-base/post-form"}
                    prevPageTitle='Form'
                    currentPageTitle={
                        branchCollectionData[branchCollectionType]?.headerTitle
                    }
                    nextPage={branchCollectionName}
                    hasError={hasError}
                />
            )}
        </>
    );
};

export default BranchCollectionViewHeader;
