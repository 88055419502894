import React from 'react';
import AgentInboxApps from './AgentInboxApps/AgentInboxApps';
import AppIntegrationHeading from './AppIntegrationHeading/AppIntegrationHeading';

const AppIntegrations = () => {
    return (
        <div className="padded__layout">
            <AppIntegrationHeading />
            <AgentInboxApps />
        </div>
    );
};

export default AppIntegrations;