import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    incomingTickets: [],
    sortedTickets: [],
};

const IncomingTicketsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.PUSH_INCOMING_TICKET:
            return {
                ...state,
                incomingTickets: [payload]?.concat(
                    state.incomingTickets?.filter(
                        (x) => x.ticketId !== payload.ticketId
                    )
                ),
            };

        case types.SET_INCOMING_TICKETS:
            // let incomingTicketsList = getUniqueListBy(
            //     [...state?.incomingTickets, ...payload],
            //     "ticketId"
            // );
            let incomingTicketsList = getUniqueListBy(
                [...payload],
                "ticketId"
            );
            
            return { ...state, incomingTickets: incomingTicketsList };

        case types.UPDATE_INCOMING_TICKET:
            return {
                ...state,
                incomingTickets: state?.incomingTickets?.map((x) => {
                    return x.ticketId === payload.ticketId
                        ? { ...x, ...payload }
                        : x;
                }),
            };

        case types.DELETE_INCOMING_TICKET:
            return {
                ...state,
                incomingTickets: state.incomingTickets.filter(
                    (x) => x.ticketId !== payload.ticketId
                ),
                sortedTickets: state.sortedTickets.filter(
                    (x) => x.ticketId !== payload.ticketId
                ),
            };

        case types.SET_SORTED_TICKETS:
            return {
                ...state,
                sortedTickets: payload,
            };

        default:
            return state;
    }
};

export default IncomingTicketsReducer;
