import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import AddNewTaskModalForm from "./AddNewTaskModalForm/AddNewTaskModalForm";

const AddNewTaskModal = ({
    showModal,
    setShowModal,
    getFollowUpTasks,
    selectedFollowUpTask,
    isEdit,
}) => {
    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <SideModal show={showModal} close={() => closeModal()}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={`${isEdit ? "Edit" : "Add New"} Task`}
                            subTitle={`${
                                isEdit ? "Update" : "Add"
                            } pending tasks to resolve a ticket.`}
                        />
                        <AddNewTaskModalForm
                            getFollowUpTasks={getFollowUpTasks}
                            closeModal={closeModal}
                            selectedFollowUpTask={selectedFollowUpTask}
                            isEdit={isEdit}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default AddNewTaskModal;
