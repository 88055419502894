import React, { useState } from "react";
import { useSelector } from "react-redux";
import TMTagDetail from "./TMTagDetail/TMTagDetail";
import TMTSuggestionList from "./TMTSuggestionList/TMTSuggestionList";
import {
    UNDISCOVERED,
    NO_TAGS,
    AUTOMATED,
    NO_TICKET_INFO,
} from "components/dashboard/AgentInbox/enum";

const TMTSuggestionElement = ({
    availableMTs,
    selectedTicket,
    lastMessageContent,
    setCannedMessage,
}) => {
    const { ticketsfilterRequest } = useSelector(
        (state) => state?.ticketsFilters
    );

    const { messageTagId: messageTagValue, ticketInfoValue } =
        ticketsfilterRequest || {};

    const messageTagId =
        messageTagValue === UNDISCOVERED || messageTagValue === NO_TAGS
            ? undefined
            : messageTagValue;
    const ticketInfoId =
        ticketInfoValue === AUTOMATED || ticketInfoValue === NO_TICKET_INFO
            ? undefined
            : ticketInfoValue;

    const [selectedMessageTagId, setSelectedMessageTagId] = useState(
        messageTagId || ticketInfoId
    );
    const handleMessageTagSelect = (tagId) => {
        setSelectedMessageTagId(tagId);
    };

    return (
        <div>
            <TMTSuggestionList
                data={availableMTs}
                selectedMessageTagId={selectedMessageTagId}
                handleTagSelect={handleMessageTagSelect}
            />
            <TMTagDetail
                messageTagId={selectedMessageTagId}
                selectedTicket={selectedTicket}
                lastMessageContent={lastMessageContent}
                setCannedMessage={setCannedMessage}
            />
        </div>
    );
};

export default TMTSuggestionElement;
