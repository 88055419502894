import { Button, CustomDatePicker, Switch } from "components/ui";
import React from "react";
import { useState } from "react";
import AnalyticsFilterDuration from "./AnalyticsFilterDuration/AnalyticsFilterDuration";
import AnalyticsFilterType from "./AnalyticsFilterType/AnalyticsFilterType";
import AnalyticsFilterValues from "./AnalyticsFilterValues/AnalyticsFilterValues";
import { useDispatch } from "react-redux";
import { updateCurrentAnalyticsCustomDate } from "store/analytics/actions";
import { formdateDatePickerDate } from "utils/helper";
import "./AnalyticsFilters.scss";


const AnalyticsFilters = ({
    defaultRequest,
    updateRequest,
    handleSubmit,
    options,
    setOptions,
    showOnlyDuration,
    isLoading,
    showAIButton,
    setSelectedOptions,
    selectedOptions,
    currentAnalyticsCustomDate,
    toggleAIAssistance,
}) => {
    const {
        analyticsRequestType,
        analyticsRequestValue,
        analyticsRequestDuration,
    } = defaultRequest || {};

    const [loading, setLoading] = useState(false);

    const handleSpecialRequest = (key, value) => {
        updateRequest(key, value);
        triggerShowDate(value === "CUSTOM_DATE" ? true : false);
        showOnlyDuration && handleSubmit();
    };

    const dispatch = useDispatch();

    const [showDate, triggerShowDate] = useState(false);

    const handleCustomDate = (date) => {
        const startDate = date[0];
        const endDate = date[1];
        if (startDate && endDate) {
            dispatch(
                updateCurrentAnalyticsCustomDate({
                    startDate,
                    endDate,
                })
            );
        }
    };

    const handleBaseFilterChange = (key, value) => {
        setSelectedOptions([]);
        updateRequest(key, value);
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
            <div className='analytics-filter__switch'>
                {showAIButton && (
                    <Switch
                        // checked={fals}
                        label='AI Assisted'
                        id='ai-assisted'
                        useColor={false}
                        onChange={(state) => toggleAIAssistance(state)}
                    />
                )}
            </div>

            <div className='analytics-filter row'>
                <div
                    className={`${
                        showDate ? "col-4" : "col-2"
                    } analytics-filter__duration-select`}>
                    <AnalyticsFilterDuration
                        defaultValue={analyticsRequestDuration}
                        updateRequest={handleSpecialRequest}
                    />

                    {(showDate ||
                        analyticsRequestDuration === "CUSTOM_DATE") && (
                        <CustomDatePicker
                            onChange={handleCustomDate}
                            placeholder={"Date"}
                            startDate={formdateDatePickerDate(
                                currentAnalyticsCustomDate?.startDate
                            )}
                            endDate={formdateDatePickerDate(
                                currentAnalyticsCustomDate?.endDate
                            )}
                            otherClassNames={"analytics-filter__date-range"}
                            selectsRange
                        />
                    )}
                </div>

                {!showOnlyDuration && (
                    <div
                        className={`row ${
                            showDate ? "col-6" : "col-8"
                        } analytics-filter__group`}>
                        <div className='col-3'>
                            <AnalyticsFilterType
                                defaultValue={analyticsRequestType}
                                handleBaseFilterChange={handleBaseFilterChange}
                                // handleBaseFilterChange={updateRequest}
                            />
                        </div>
                        <div className='col-9'>
                            <AnalyticsFilterValues
                                key={analyticsRequestType}
                                analyticsRequestType={analyticsRequestType}
                                defaultValue={analyticsRequestValue}
                                updateRequest={updateRequest}
                                setLoading={setLoading}
                                useCustomDate={showDate}
                                {...{
                                    options,
                                    setOptions,
                                    setSelectedOptions,
                                    selectedOptions,
                                }}
                            />
                        </div>
                    </div>
                )}

                <div className='col-2'>
                    {!showOnlyDuration && (
                        <Button
                            text='Apply all'
                            classType='apply'
                            otherClass='analytics-filter__button'
                            disabled={loading || isLoading}
                        />
                    )}
                </div>
            </div>
        </form>
    );
};

export default AnalyticsFilters;
