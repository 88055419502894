import React, { useEffect, useState } from "react";
import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TicketInfoDetailsElement from "./TicketInfoDetailsElement/TicketInfoDetailsElement";
import { useSelector } from "react-redux";
import TicketInfoClipboard from "./TicketInfoClipboard/TicketInfoClipboard";
import TicketInfoDetailsHeader from "./TicketInfoDetailsHeader/TicketInfoDetailsHeader";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const TicketInfoDetails = ({
    selectedTicket,
    handleToggleTicketInfoModal,
    setExistingTicketInfo,
    refetchTicketInfo,
}) => {
    const { ticketId } = selectedTicket || {};
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [ticketsInfos, setTicketsInfos] = useState();
    const [show, toggleShow] = useState(false);

    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );

    const { agentId } = ticketsfilterRequest || {};

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        otherClassNames={"with-margin"}
                        message={"Ticket info\n Not available yet"}
                    />
                );

            case DATAMODE:
                return (
                    <TicketInfoDetailsElement
                        ticketsInfos={ticketsInfos}
                        selectedTicket={selectedTicket}
                        handleToggleTicketInfoModal={
                            handleToggleTicketInfoModal
                        }
                        handleSuccess={fetchTicketInfo}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    const fetchTicketInfo = async () => {
        try {
            if (ticketId) {
                setStatus(LOADING);
                setErrorMssg();

                const url = apiRoute?.getTicketInfo(ticketId);
                const res = await API.get(url, {
                    params: {
                        agentId,
                    },
                });
                if (res.status === 200) {
                    const { data } = res.data;
                    const newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                    setTicketsInfos(data);
                    setExistingTicketInfo(
                        data?.map(
                            (ticketInfo) =>
                                ticketInfo?.ticketInfoType === "CAPSULE" &&
                                ticketInfo
                        )
                    );
                    setStatus(newStatus);
                }
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        fetchTicketInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket, refetchTicketInfo, agentId]);

    return (
        <div className='h-100'>
            <TicketInfoClipboard />
            <TicketInfoDetailsHeader {...{ show, toggleShow }} />
            {show && renderBasedOnStatus()}
        </div>
    );
};

export default TicketInfoDetails;
