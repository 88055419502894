export const DEFAULT_GROUP_MESSAGES = [
    { groupName: "TODAY", data: [] },
    { groupName: "YESTERDAY", data: [] },
    { groupName: "LAST 4 DAYS", data: [] },
    {
        groupName: "LAST 1 WEEK",
        data: [],
    },
    { groupName: "LESS THAN A MONTH", data: [] },
    {
        groupName: "OVER 1 MONTH AGO",
        data: [],
    },
];
