import * as types from "./types";

const initialState = {
    guidesCache: {
        pages: {},
        totalRecords: 0,
    },
    scenariosCache: {
        pages: {},
        totalRecords: 0,
    },
    emailUpdatesCache: {
        pages: {},
        totalRecords: 0,
    },
    mentionUpdatesCache: {
        pages: {},
        totalRecords: 0,
    },
    replyUpdatesCache: {
        pages: {},
        totalRecords: 0,
    },
    cannedEmailsCache: {
        pages: {},
        totalRecords: 0,
    },
    cannedRepliesCache: {
        pages: {},
        totalRecords: 0,
    },
    cannedMentionsCache: {
        pages: {},
        totalRecords: 0,
    },
};

const AdminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_GUIDES:
            return {
                ...state,
                guidesCache: {
                    ...state.guidesCache,
                    pages: {
                        ...state.guidesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_GUIDES:
            return {
                ...state,
                guidesCache: {
                    ...state.guidesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_SCENARIOS:
            return {
                ...state,
                scenariosCache: {
                    ...state.scenariosCache,
                    pages: {
                        ...state.scenariosCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_SCENARIOS:
            return {
                ...state,
                scenariosCache: {
                    ...state.scenariosCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_CANNED_EMAILS:
            return {
                ...state,
                cannedEmailsCache: {
                    ...state.cannedEmailsCache,
                    pages: {
                        ...state.cannedEmailsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_CANNED_EMAILS:
            return {
                ...state,
                cannedEmailsCache: {
                    ...state.cannedEmailsCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_CANNED_MENTIONS:
            return {
                ...state,
                cannedMentionsCache: {
                    ...state.cannedMentionsCache,
                    pages: {
                        ...state.cannedMentionsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_CANNED_MENTIONS:
            return {
                ...state,
                cannedMentionsCache: {
                    ...state.cannedMentionsCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_CANNED_REPLIES:
            return {
                ...state,
                cannedRepliesCache: {
                    ...state.cannedRepliesCache,
                    pages: {
                        ...state.cannedRepliesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_CANNED_REPLIES:
            return {
                ...state,
                cannedRepliesCache: {
                    ...state.cannedRepliesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_EMAIL_UPDATES:
            return {
                ...state,
                emailUpdatesCache: {
                    ...state.emailUpdatesCache,
                    pages: {
                        ...state.emailUpdatesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_EMAIL_UPDATES:
            return {
                ...state,
                emailUpdatesCache: {
                    ...state.emailUpdatesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_MENTION_UPDATES:
            return {
                ...state,
                mentionUpdatesCache: {
                    ...state.mentionUpdatesCache,
                    pages: {
                        ...state.mentionUpdatesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_MENTION_UPDATES:
            return {
                ...state,
                mentionUpdatesCache: {
                    ...state.mentionUpdatesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_REPLY_UPDATES:
            return {
                ...state,
                replyUpdatesCache: {
                    ...state.replyUpdatesCache,
                    pages: {
                        ...state.replyUpdatesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_REPLY_UPDATES:
            return {
                ...state,
                replyUpdatesCache: {
                    ...state.replyUpdatesCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        default:
            return state;
    }
};

export default AdminReducer;
