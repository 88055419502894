import { STATUS_MAP_COLORS } from "components/dashboard/TrainSAM/Dependency/enum";
import {
    calculateStatusPercentage,
    resolveStatus,
} from "components/dashboard/TrainSAM/Dependency/helpers";

import { getFormattedStringDate } from "utils/helper";

const StatusMapPopup = ({
    hrsDiff,
    date,
    status,
    totalHrsOfDowntimes,
    totalHrsOfUptimes,
}) => {
    return (
        <>
            <div className='popup'>
                <span class='popuptext show'>
                    <h6>{getFormattedStringDate(date)}</h6>

                    <p style={{ color: STATUS_MAP_COLORS[status] }}>
                        <span>
                            {hrsDiff}hrs {resolveStatus(status)}{" "}
                        </span>

                        <span className='text-white'>
                            {calculateStatusPercentage(
                                status,
                                totalHrsOfUptimes,
                                totalHrsOfDowntimes,
                                hrsDiff
                            )}
                        </span>
                    </p>
                </span>
            </div>
        </>
    );
};

export default StatusMapPopup;
