import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { UPDATE } = baseResourceActions;

const EditScenarioButton = ({ onClick }) => {
    return (
        <div>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`]}>
                <AddButton btnText={`Edit`} hasIcon={false} onClick={onClick} />
            </ComponentAccessControl>
        </div>
    );
};

export default EditScenarioButton;
