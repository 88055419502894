import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Checkbox, Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { saveRoleModules } from "store/modules/admin/actions";

const { DATAMODE, LOADING, ERROR } = dataQueryStatus;

const PermissionsCheckBox = ({ existingPermissions = [], updatePerms }) => {
    const [permissions, setPermissions] = useState([]);
    const [errorMsg, setErrorMsg] = useState();

    const { rolesCache: { roleModules } } = useSelector((state) => state.admin);
    const [status, setStatus] = useState(roleModules?.length > 0 ? DATAMODE : LOADING);
    
    const dispatch = useDispatch();
  
    useEffect(() => {
        let httpRequest = new AbortController();
        if (roleModules?.length < 1) {
            getAllPermissions(httpRequest);
        }
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const newPerms = roleModules?.map((perm) => ({
            ...perm,
            checked: !existingPermissions?.includes(perm?.permissionCode),
        }));
        setPermissions(newPerms);
        // eslint-disable-next-line
    }, [roleModules]);

    const handleCheck = (perms, index) => {
        const newPermissions = [...permissions];
        newPermissions[index] = { ...perms, checked: !perms?.checked };
        const checkedPerms = newPermissions?.reduce(
            (acc, arr) => (!arr?.checked ? [...acc, arr?.permissionCode] : acc),
            []
        );
        setPermissions(newPermissions);
        updatePerms(checkedPerms);
    };

    const getAllPermissions = async (httpRequest) => {
        try {
            if (roleModules?.length < 1) {
                setStatus(LOADING);
            }

            const res = await API.get(apiRoute.getPermissions, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data
                setStatus(DATAMODE);
                dispatch(saveRoleModules(data))
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        message={errorMsg}
                        size={40}
                        handleRetry={getAllPermissions}
                    />
                );
            case DATAMODE:
                return (
                    <div>
                        {permissions?.map((perms, index) => (
                            <div
                                className='col-sm-6 mb-3'
                                key={perms?.permissionCode}>
                                <Checkbox
                                    label={perms.displayName}
                                    checked={!perms.checked}
                                    onChange={() => handleCheck(perms, index)}
                                    id={perms.displayName}
                                    inputClass='input-check-grey'
                                />
                            </div>
                        ))}
                    </div>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <p className='fw-bold text-dark'>Role Modules</p>
            {renderBasedOnStatus()}
        </>
    );
};

export default PermissionsCheckBox;
