import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import ChangeEmbedForm from "./ChangeEmbedForm/ChangeEmbedForm";

const ChangeEmbedModal = ({
    showModal,
    closeModal,
    handleSuccess,
    currentEmbed,
    referenceData
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading title={"Change embed"} />
                    <ChangeEmbedForm
                        handleSuccess={handleSuccess}
                        closeModal={closeModal}
                        currentEmbed={currentEmbed}
                        referenceData={referenceData}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default ChangeEmbedModal;
