import imageLinks from "assets/images";
import { Table } from "components/ui";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import AnalyticsQAVetTicketTableDropdown from "./AnalyticsQAVetTicketTableDropdown/AnalyticsQAVetTicketTableDropdown";
import { useSelector } from "react-redux";
import {
    formatDate,
    getDaysHrsMinsAndSecs,
    getErrorMessage,
    getNoOfSecondsBtw,
} from "utils/helper";
import { useContext } from "react";
import { ANALYTICS_QA_VET_TICKET_ACTIONS } from "../enums";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useHistory } from "react-router-dom";

const tableHeaders = [
    "Ticket ID",
    "Agent Assigned",
    "Date Closed",
    "Resolution time",
    "Actions",
];

const { VIEW } = ANALYTICS_QA_VET_TICKET_ACTIONS;

const AnalyticsQAVetTicketTable = ({
    isPinned,
    tickets,
    toggleRefresh,
    useTableLoader,
    filterParams,
}) => {
    const { search } = filterParams || {};
    const { agents } = useSelector((state) => state?.agents);

    const toastMessage = useContext(ToastContext);

    const getAgentName = (agentId) => {
        if (!agentId) return "N/A";
        const { firstName, lastName } =
            agents?.find(({ userId }) => userId === agentId) || {};
        return `${firstName} ${lastName}`;
    };

    const history = useHistory();

    const changeTicketPinStatus = async (ticketId) => {
        try {
            const url = apiRoute?.pinVetableTicket(ticketId, !isPinned);
            const res = await API.put(url);

            if (res.status === 200) {
                toastMessage(
                    `Ticket ${isPinned ? "unpined" : "pin"} successfully`
                );
                toggleRefresh();
            }
        } catch (err) {
            const errorMsg = getErrorMessage(err);
            toastMessage(errorMsg, "ERROR");
        }
    };

    const routeToTicketConversations = (ticketId) => {
        history.push(`/analytics/qa/vet-agents/ticket/${ticketId}`);
    };

    const handleActionModal = (actionType, ticketId) => {
        actionType === VIEW
            ? routeToTicketConversations(ticketId)
            : changeTicketPinStatus(ticketId);
    };

    const computeResolutionTime = (ticketClosedAt, createdDate) => {
        const duration = getNoOfSecondsBtw(ticketClosedAt, createdDate);

        const { days, hours, mins, secs } = getDaysHrsMinsAndSecs(duration);

        return duration === "0"
            ? "0 sec"
            : `${days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""} ${hours > 0 ? `${hours} ${hours === 1 ? "hr" : "hrs"}` : ""
            } ${mins > 0 ? `${mins} ${mins === 1 ? "min" : "mins"}` : ""} ${secs ? `${secs} ${secs <= 1 ? "sec" : "secs"}` : ""
            }`;
    };

    const tableData = tickets
        ?.filter?.(
            ({ ticketId, ticketConversation }) =>
                ticketId?.includes?.(search) ||
                getAgentName(
                    ticketConversation?.[0]?.ticketConversationSenderId
                )
                    ?.toLocaleLowerCase?.()
                    ?.includes?.(search?.toLocaleLowerCase?.())
        )
        ?.map?.(
            ({ createdDate, ticketId, ticketConversation, ticketClosedAt }) => {
                const ticketAgent =
                    ticketConversation?.[0]?.ticketConversationSenderId;

                return {
                    ticketId: (
                        <div>
                            {isPinned && (
                                <ReactSVG src={imageLinks.icons.pinDown} />
                            )}
                            <p>{ticketId}</p>
                        </div>
                    ),
                    agent: (
                        <div>
                            {
                            ticketAgent ? <>
                                    <p>{getAgentName(ticketAgent)}</p>
                                    <Link
                                        to={`/analytics/qa/vet-agents/${ticketAgent}`}>
                                        Vet agent
                                    </Link>
                                </>
                            : ""
                            }
                        </div>
                    ),
                    dateClosed: formatDate(ticketClosedAt),
                    resolutionTime: computeResolutionTime(
                        ticketClosedAt,
                        createdDate
                    ),
                    action: (
                        <AnalyticsQAVetTicketTableDropdown
                            isPinned={isPinned}
                            handleActionModal={handleActionModal}
                            ticketId={ticketId}
                        />
                    ),
                };
            }
        );

    return (
        <Table
            headers={tableHeaders}
            dataSource={tableData}
            isLoading={useTableLoader}
        />
    );
};

export default AnalyticsQAVetTicketTable;
