import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { retriveAccessToken } from "storage/cookieStorage";
import { handleAuthRedirect } from "pages/PublicPages/SignIn/utils/helper";
import { jwtExpired } from "utils/helper";

const PublicRoute = ({
    component: Component,
    permissions,
    passwordUpdatedRequired,
    ...rest
}) => {
    const isAuthenticated = retriveAccessToken();
    const isJwtExpired = jwtExpired();

    return (
        <Route {...rest}>
            {isAuthenticated && !isJwtExpired ? (
                <Redirect
                    to={{
                        pathname: handleAuthRedirect(
                            permissions,
                            passwordUpdatedRequired
                        ),
                        // state: { from: props.location },
                    }}
                />
            ) : (
                <Component {...rest} />
            )}
        </Route>
    );
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
        passwordUpdatedRequired: state?.auth?.user?.passwordUpdatedRequired,
    }),
    {}
)(PublicRoute);
