import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { Input, Button, Checkbox, FileInput, InputColor } from "components/ui";
import asset from "assets/images";
import ValidateForm from "utils/validations/Validator";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { copyToClipboard, getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import SelectUI from "components/ui/SelectUI/SelectUI";
import {
    defaultTemplates,
    defaultThemes,
    WORKMODE,
    WHITE_MODE_DEFAULT,
} from "./enum";
import "./Appearance.scss";
import CustomURLFields from "./CustomURLFields/CustomURLFields";
import FileUploadWrapper from "components/essentials/FileUploadWrapper/FileUploadWrapper";
import ImageView from "components/essentials/ImageView/ImageView";

const { AGENTS_INBOX_SERVICE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const Appearance = () => {
    const {
        workspaceLogo,
        workspaceName,
        workspaceEmailLogo,
        workspaceUrlSlug,
        inAppBackgroundImageUrl,
    } = useSelector((state) => state?.auth?.user?.workspace);

    const [bgSrc, setBg] = useState(asset?.images?.placeholder);
    const [logoSrc, setLogo] = useState(
        workspaceLogo || asset?.images?.placeholder
    );

    const [emailImgUploading, setEmailImgUploading] = useState(false);
    const [workspaceLogoUploading, setWorkspaceLogoUploading] = useState(false);
    const [inAppImgUploading, setInAppImgUploading] = useState(false);

    const [isCopied, setIsCopied] = useState(false);

    const [emailLogoSrc, setEmailLogo] = useState(
        workspaceEmailLogo || asset?.images?.placeholder
    );

    const [inAppImageSrc, setInAppImage] = useState(
        inAppBackgroundImageUrl || asset?.images?.placeholder
    );

    const [request, setRequest] = useState({
        companyLogo: workspaceLogo || "",
        // backgroundImage: "",
        teamName: workspaceName || "",
        subtitle: "",
        numberOfChats: "",
        chatThemeColor: "#6837EF",
        makeChatBoxVisible: true,
        emailLogoUrl: workspaceEmailLogo || "",
        defaultTheme: WHITE_MODE_DEFAULT,
        defaultTemplate: WORKMODE,
        hasCustomDomiain: false,
        customDomain: "",
        inAppBackgroundImageUrl: "",
        inAppLinks: [],
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const toastMessage = useContext(ToastContext);

    const getWorkspaceAppearance = async () => {
        try {
            const url = apiRoute.workspaceAppearance;
            const res = await API.get(url);
            const data = res.data;

            if (res.status === 200) {
                setRequest({ ...data?.data, backgroundImage: bgSrc });
            }
            setLogo(data?.data?.companyLogo);
            setEmailLogo(data?.data?.emailLogoUrl);
            setInAppImage(data?.data?.inAppBackgroundImageUrl);
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    useEffect(() => {
        getWorkspaceAppearance();
        //eslint-disable-next-line
    }, []);

    const createWorkspaceAppearance = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            Object.keys(request).forEach((key) => {
                formData.append(key, request[key]);
            });

            const url = apiRoute.workspaceAppearance;
            const res = await API.put(url, request);
            const data = res.data;

            if (res.status === 200) {
                setLoading(false);
                setRequest({ ...data?.data, backgroundImage: bgSrc });
            }
            setLogo(data?.data?.companyLogo);
            setEmailLogo(data?.data?.emailLogoUrl);
            toastMessage(data?.message);
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "companyLogo",
            "emailLogoUrl",
            // "backgroundImage",
            "makeChatBoxVisible",
        ]);
        setErrors(formErrors);
        if (formisValid) {
            createWorkspaceAppearance();
        }
    };

    const uploadImage = (e) => {
        const { files, name } = e.target;
        if (files?.length) {
            setErrors({ ...errors, [name]: "" });
            switch (name) {
                case "companyLogo":
                    return setLogo(files?.[0]);
                case "emailLogo":
                    return setEmailLogo(files?.[0]);
                case "inAppImage":
                    return setInAppImage(files?.[0]);
                case "backgroundImage":
                    return setBg(files?.[0]);
                default:
                    return "";
            }
        }
    };

    const {
        companyLogo,
        emailLogo,
        teamName,
        chatThemeColor,
        subtitle,
        numberOfChats,
        makeChatBoxVisible,
        defaultTheme,
        defaultTemplate,
        hasCustomDomiain,
        customDomain,
        inAppLinks,
    } = request;

    const handleCopyLink = () => {
        setIsCopied(true);
        toastMessage(`Chat URL has been copied successfully.`);
        copyToClipboard(`https://${workspaceUrlSlug}.metacare.app`);
    };

    const handleSetRequest = (key, value) => {
        setRequest({ ...request, [key]: value });
    };

    return (
        <div className='padded__layout appearance'>
            <div className='appearance__wrapper'>
                <form onSubmit={handleSubmit}>
                    <p>Company Logo</p>
                    <div className='company-logo'>
                        <div>
                            <div className='logo'>
                                <ImageView src={logoSrc} alt='Company' />
                            </div>

                            <FileUploadWrapper
                                file={logoSrc}
                                setLoading={setWorkspaceLogoUploading}
                                setUploadedFileKey={(fileKey) =>
                                    handleSetRequest("companyLogo", fileKey)
                                }
                                loaderClass={'uploading-email-logo'}
                                useLoader={true}>
                                <FileInput
                                    fileType='image/*'
                                    id='companyLogo'
                                    name='companyLogo'
                                    label={
                                        <ReactSVG
                                            src={asset?.icons?.upload}
                                            className='d-inline-flex'
                                        />
                                    }
                                    labelClass='me-3'
                                    labelName='Company logo'
                                    fileValue={companyLogo}
                                    onChange={(e) => uploadImage(e)}
                                />
                            </FileUploadWrapper>

                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setRequest({
                                        ...request,
                                        companyLogo: "",
                                    });
                                    setLogo(asset?.images?.placeholder);
                                }}>
                                <ReactSVG
                                    src={asset?.icons?.bin}
                                    className='d-inline-flex'
                                />
                            </span>
                        </div>
                        <p className='input-err-message text-danger mt-1'>
                            {errors?.companyLogo}
                        </p>
                    </div>

                    <div className='company-email-logo'>
                        <p>Email Logo</p>
                        <div>
                            <div className='logo'>
                                <ImageView src={emailLogoSrc} alt='Email' />
                            </div>

                            <FileUploadWrapper
                                file={emailLogoSrc}
                                setLoading={setEmailImgUploading}
                                setUploadedFileKey={(fileKey) =>
                                    handleSetRequest("emailLogoUrl", fileKey)
                                }
                                loaderClass={'uploading-email-logo'}
                                useLoader={true}>
                                <FileInput
                                    fileType='image/*'
                                    id='emailLogo'
                                    name='emailLogo'
                                    label={
                                        <ReactSVG
                                            src={asset?.icons?.upload}
                                            className='d-inline-flex'
                                        />
                                    }
                                    labelClass='me-3'
                                    labelName='Company logo'
                                    fileValue={emailLogo}
                                    onChange={(e) => uploadImage(e)}
                                />
                            </FileUploadWrapper>

                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setRequest({
                                        ...request,
                                        emailLogoUrl: "",
                                    });
                                    setEmailLogo(asset?.images?.placeholder);
                                }}>
                                <ReactSVG
                                    src={asset?.icons?.bin}
                                    className='d-inline-flex'
                                />
                            </span>
                        </div>
                        <p className='input-err-message text-danger mt-1'>
                            {errors?.emailLogo}
                        </p>
                    </div>

                    <div className='company-email-logo'>
                        <p>In App Image</p>
                        <div>
                            <div className='logo'>
                                <ImageView
                                    src={inAppImageSrc}
                                    alt='In App Background'
                                />
                            </div>

                            <FileUploadWrapper
                                file={inAppImageSrc}
                                setUploadedFileKey={(fileKey) =>
                                    handleSetRequest(
                                        "inAppBackgroundImageUrl",
                                        fileKey
                                    )
                                }
                                loaderClass={'uploading-email-logo'}
                                setLoading={setInAppImgUploading}
                                useLoader={true}>
                                <FileInput
                                    fileType='image/*'
                                    id='inAppImage'
                                    name='inAppImage'
                                    label={
                                        <ReactSVG
                                            src={asset?.icons?.upload}
                                            className='d-inline-flex'
                                        />
                                    }
                                    labelClass='me-3'
                                    labelName='Company logo'
                                    fileValue={inAppBackgroundImageUrl}
                                    onChange={(e) => uploadImage(e)}
                                />
                            </FileUploadWrapper>
                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setRequest({
                                        ...request,
                                        inAppBackgroundImageUrl: "",
                                    });
                                    setInAppImage(asset?.images?.placeholder);
                                }}>
                                <ReactSVG
                                    src={asset?.icons?.bin}
                                    className='d-inline-flex'
                                />
                            </span>
                        </div>
                        <p className='input-err-message text-danger mt-1'>
                            {errors?.inAppBackgroundImageUrl}
                        </p>
                    </div>

                    <Input
                        grpClass='mb-3 mt-4'
                        type='text'
                        name='teamName'
                        label='Team Name'
                        labelClass='text-dark'
                        id='teamName'
                        placeholder='Enter team name'
                        value={teamName}
                        onChange={handleChange}
                        isErr={errors?.teamName}
                        errMssg={errors?.teamName}
                        required
                    />
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='subtitle'
                        label='Subtitle'
                        labelClass='text-dark '
                        id='subtitle'
                        placeholder='Enter subtitle'
                        value={subtitle}
                        onChange={handleChange}
                        isErr={errors?.subtitle}
                        errMssg={errors?.subtitle}
                        required
                    />
                    <Input
                        grpClass='mb-3'
                        type='number'
                        name='numberOfChats'
                        label='Number of Chats'
                        labelClass='text-dark '
                        id='numberOfChats'
                        placeholder='Enter number of chats'
                        value={numberOfChats}
                        onChange={handleChange}
                        isErr={errors?.numberOfChats}
                        errMssg={errors?.numberOfChats}
                        required
                    />
                    <SelectUI
                        name='defaultTemplate'
                        label='Default Template'
                        darkLabel={true}
                        id='defaultTemplate'
                        placeholder='Select default template'
                        options={defaultTemplates}
                        defaultValue={defaultTemplate}
                        value={defaultTemplates.find(
                            ({ value }) => value === defaultTemplate
                        )}
                        handleChange={(name, value) =>
                            handleChange({ target: { name, value } })
                        }
                    />
                    <SelectUI
                        name='defaultTheme'
                        label='Default Theme'
                        darkLabel={true}
                        id='defaultTheme'
                        placeholder='Select default theme'
                        options={defaultThemes}
                        defaultValue={defaultTheme}
                        value={defaultThemes.find(
                            ({ value }) => value === defaultTheme
                        )}
                        handleChange={(name, value) =>
                            handleChange({ target: { name, value } })
                        }
                    />
                    <InputColor
                        grpClass='mb-3'
                        name='chatThemeColor'
                        label='Chat theme color'
                        labelClass='text-dark'
                        id='chatThemeColor'
                        placeholder='Choose chat theme color'
                        value={chatThemeColor}
                        onChange={handleChange}
                        isErr={errors?.chatThemeColor}
                        errMssg={errors?.chatThemeColor}
                    />
                    <div className='company-chat-url'>
                        <Input
                            grpClass='mb-3'
                            label='Chat URL'
                            labelClass='text-dark '
                            value={`https://${workspaceUrlSlug}.metacare.app`}
                            disabled={true}
                        />
                        <p onClick={handleCopyLink}>
                            {isCopied ? "Copied" : "Click to copy url"}
                        </p>
                    </div>

                    <Checkbox
                        grpClass='mb-3'
                        name='hasCustomDomiain'
                        label='Use custom domain'
                        labelClass='text-black role-module'
                        inputClass='input-check-grey'
                        id='hasCustomDomiain'
                        onChange={({ target: { checked } }) =>
                            setRequest({
                                ...request,
                                hasCustomDomiain: checked,
                            })
                        }
                        checked={hasCustomDomiain}
                    />

                    {hasCustomDomiain && (
                        <Input
                            grpClass='mb-3'
                            name='customDomain'
                            label='Custom Domain'
                            labelClass='text-dark '
                            id='customDomain'
                            placeholder='Enter a custom domain'
                            value={customDomain}
                            onChange={handleChange}
                            isErr={errors?.customDomain}
                            errMssg={errors?.customDomain}
                            required
                        />
                    )}
                    <Checkbox
                        grpClass='mb-3'
                        name='makeChatBoxVisible'
                        label='Make chat box visible'
                        labelClass='text-black role-module'
                        inputClass='input-check-grey'
                        id='makeChatBoxVisible'
                        onChange={({ target: { checked } }) =>
                            setRequest({
                                ...request,
                                makeChatBoxVisible: checked,
                            })
                        }
                        checked={makeChatBoxVisible}
                    />

                    <CustomURLFields
                        handleChange={(value) =>
                            setRequest({ ...request, inAppLinks: value })
                        }
                        data={inAppLinks}
                    />

                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${AGENTS_INBOX_SERVICE_SETTING}`,
                        ]}>
                        <Button
                            type='submit'
                            text='Continue'
                            classType='primary'
                            otherClass='my-3 w-100'
                            loading={
                                loading ||
                                emailImgUploading ||
                                inAppImgUploading ||
                                workspaceLogoUploading
                            }
                        />
                    </ComponentAccessControl>
                </form>
            </div>
            {/* {showModal && (
                <EmailLogoModal
                    show={showModal}
                    close={() => toggleModal(false)}
                    uploadEmailLogo={uploadEmailLogo}
                    uploading={uploading}
                    // emailLogoSrc={emailLogoSrc}
                />
            )} */}
        </div>
    );
};

export default Appearance;
