import EmptyConversationBody from "./EmptyConversationBody/EmptyConversationBody";
import NonEmptyConversationBody from "./NonEmptyConversationBody/NonEmptyConversationBody";
import ConversationTemplateHeader from "./ConversationTemplateHeader/ConversationTemplateHeader";
import "./ConversationTemplate.scss";

const ConversationTemplate = ({
    template,
    createTemplateType,
    handleRefresh,
}) => {
    const { templateType, templateVariables } = template || {};

    return (
        <>
            <div className='conversation__template'>
                <div className=''>
                    <ConversationTemplateHeader
                        {...{
                            createTemplateType,
                            handleRefresh,
                            templateType,
                            templateVariables,
                        }}
                        editTemplateData={template}
                        isEdit={template ? true : false}
                    />

                    {template ? (
                        <NonEmptyConversationBody {...template} />
                    ) : (
                        <EmptyConversationBody />
                    )}
                </div>
            </div>
        </>
    );
};

export default ConversationTemplate;
