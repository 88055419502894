import React from "react";
import Controller from "./Controller/Controller";
import "./ConvoMapController.scss";

const ConvoMapController = ({ columns }) => {
    const disableController = columns?.length < 5;
    return (
        <div className='convo__map--controller'>
            <Controller disabled={disableController} />
            <div className='page__list'>
                {columns?.map(({ selectedBranchId }, index) => (
                    <div
                        className={`map__circle ${
                            selectedBranchId !== "" ? "active" : ""
                        }`}
                        key={index}></div>
                ))}
            </div>
            <Controller otherClassName='right' disabled={disableController} />
        </div>
    );
};

export default ConvoMapController;
