import React from "react";
import { useState } from "react";
import BranchCollectionMapView from "../BranchCollectionMapView/BranchCollectionMapView";
import BranchesTimeLine from "../BranchesTimeLine/BranchesTimeLine";

const BranchCollectionViewElement = ({
    branchCollectionId,
    branchCollection,
    updateCollectionBranches,
    updateConvoMapBranches,
    convoMapBranches,
    collectionBranches,
}) => {
    const [refreshConvoMap, setRefreshConvoMap] = useState(0);

    const handleRefreshConvoMap = (branchId) => {
        if (!branchId) {
        }
        setRefreshConvoMap(refreshConvoMap + 1);
    };

    const { mainBranch } = branchCollection || {};

    return (
        <div className='meta__container py-4'>
            <BranchCollectionMapView
                headerTitle={branchCollection?.branchCollectionName}
                mainBranchId={mainBranch?.branch?.branchId}
                refreshConvoMap={refreshConvoMap}
                updateConvoMapBranches={updateConvoMapBranches}
            />
            <BranchesTimeLine
                branchCollectionId={branchCollectionId}
                branchCollection={branchCollection}
                handleRefreshConvoMap={handleRefreshConvoMap}
                updateCollectionBranches={updateCollectionBranches}
                convoMapBranches={convoMapBranches}
                collectionBranches={collectionBranches}
                mainBranchId={mainBranch?.branch?.branchId}
            />
        </div>
    );
};

export default BranchCollectionViewElement;
