import React, { useState } from "react";
import AddProductModal from "../AddProductModal/AddProductModal";
import AddButton from "components/ui/Button/AddButton/AddButton";

const AddProductButton = ({ retrieveProducts, handleProductSelect }) => {
    const [showModal, toggleModal] = useState(false);

    return (
        <>
            <AddButton
                btnText={"Add New Product"}
                otherClassName={`justify-content-end`}
                onClick={() => toggleModal(true)}
            />
            {showModal && (
                <AddProductModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={retrieveProducts}
                    handleProductSelect={handleProductSelect}
                />
            )}
        </>
    );
};

export default AddProductButton;
