import React from "react";
import { Draggable } from "react-beautiful-dnd";
import FormBuilderInputElement from "./FormBuilderInputElement/FormBuilderInputElement";
import "./FormBuilderInput.scss";

const FormBuilderInput = ({
    id,
    elementType,
    branch,
    branchId,
    enableSubTitleText,
    errors,
    handleInputDelete,
    removeInputRule,
    elementOptions,
    customRules,
    index,
    acceptNewInputRule,
    handleConstraintEdit,
    handleFormInputChange,
    handleSubTextStatusChange,
    handleOptionAdd,
    handleOptionTextChange,
    handleOptionDelete,
    toggleBranchModal,
    elementLabel,
    subText,
    handleFileAttachmentChange,
    handleFileAttachmentDelete,
    media,
    handleValidateCustomRule,
    handleRemoveCustomRuleError,
}) => {
    return (
        <Draggable
            draggableId={id}
            index={index}
            key={id}
            isDragDisabled={elementType === "END_FORM"}>
            {(provided, snapshot) => (
                <>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={id}>
                        <FormBuilderInputElement
                            id={id}
                            elementType={elementType}
                            customRules={customRules}
                            enableSubTitleText={enableSubTitleText}
                            elementOptions={elementOptions}
                            errors={errors}
                            branch={branch}
                            branchId={branchId}
                            acceptNewInputRule={acceptNewInputRule}
                            handleConstraintEdit={handleConstraintEdit}
                            handleFormInputChange={handleFormInputChange}
                            handleSubTextStatusChange={
                                handleSubTextStatusChange
                            }
                            handleInputDelete={handleInputDelete}
                            removeInputRule={removeInputRule}
                            handleOptionAdd={handleOptionAdd}
                            handleOptionTextChange={handleOptionTextChange}
                            handleOptionDelete={handleOptionDelete}
                            toggleBranchModal={toggleBranchModal}
                            elementLabel={elementLabel}
                            subText={subText}
                            handleFileAttachmentChange={
                                handleFileAttachmentChange
                            }
                            handleFileAttachmentDelete={
                                handleFileAttachmentDelete
                            }
                            media={media}
                            handleValidateCustomRule={handleValidateCustomRule}
                            handleRemoveCustomRuleError={
                                handleRemoveCustomRuleError
                            }
                        />
                    </div>
                    {/* {snapshot.isDragging && (
                        <FormBuilderInputElement
                            id={id}
                            elementType={elementType}
                            customRules={customRules}
                            acceptNewInputRule={acceptNewInputRule}
                            handleFormInputChange={handleFormInputChange}
                            handleSubTextStatusChange={handleSubTextStatusChange}
                        />
                    )} */}
                </>
            )}
        </Draggable>
    );
};

export default FormBuilderInput;
