import { useState } from "react";
import ConversationTemplateModal from "../../../ConversationTemplateModal/ConversationTemplateModal";
import ConversationsTemplateButton from "../../../ConversationTemplatesTagHeader/ConversationTemplatesButton/ConversationsTemplateButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;

const CreateTemplateButton = ({
    isEdit,
    editTemplateData,
    handleRefresh,
    createTemplateType,
}) => {
    const [showSideModal, toggleModal] = useState(false);

    return (
        <>
            <ComponentAccessControl
                baseResources={[
                    `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                    `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                ]}>
                <ConversationsTemplateButton
                    openModal={() => toggleModal(true)}
                    isEdit={isEdit}
                />
            </ComponentAccessControl>
            {showSideModal && (
                <ConversationTemplateModal
                    closeModal={() => toggleModal(false)}
                    showSideModal={showSideModal}
                    isEdit={isEdit}
                    editTemplateData={editTemplateData}
                    handleRefresh={handleRefresh}
                    createTemplateType={createTemplateType}
                />
            )}
        </>
    );
};

export default CreateTemplateButton;
