import SeeMoreButton from "components/ui/Button/SeeMoreButton/SeeMoreButton";
import StatusCardColor from "./StatusCardColor/StatusCardColor";
import StatusCardCount from "./StatusCardCount/StatusCardCount";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./StatusCard.scss";

const { KB_DEPENDENCY } = baseResources;
const { READ } = baseResourceActions;

const StatusCard = ({
    cardCount,
    cardType,
    cardText = "Switches",
    cardUrl,
}) => {
    return (
        <>
            <div className='status__card'>
                <div className='status__card__heading'>
                    <div>
                        <StatusCardColor cardType={cardType} />
                    </div>

                    <p className='mb-0 text-grey'>
                        {cardType} {cardText}
                    </p>

                    <span className='ms-auto'>
                        <ComponentAccessControl
                            baseResources={[`${READ}_${KB_DEPENDENCY}`]}>
                            <SeeMoreButton url={cardUrl} />
                        </ComponentAccessControl>
                    </span>
                </div>

                <StatusCardCount {...{ cardCount, cardText }} />
            </div>
        </>
    );
};

export default StatusCard;
