import AnalyticsQATabs from "../common/AnalytcisQATabs/AnalyticsQATabs";
import AnalyticsQATDetails from "./AnalyticsQATDetails/AnalyticsQATDetails";
import AnalyticsQATMessages from "./AnalyticsQATMessages/AnalyticsQATMessages";
import "./AnalyticsQATimeline.scss";

const AnalyticsQATimeline = () => {
    return (
        <>
            <AnalyticsQATabs />
            <div className='padded__layout analytics-qa-timeline'>
                <AnalyticsQATMessages />
                <AnalyticsQATDetails />
            </div>
        </>
    );
};

export default AnalyticsQATimeline;
