
export const guideFlowOptionActionType = Object.freeze({
    FORWARD_AGENT: 'FORWARD_AGENT',
    CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
    RESTART_CONVERSATION: 'RESTART_CONVERSATION',
})

export const TICKET_GUIDE_STEP_ACTIONS = {
    [guideFlowOptionActionType.FORWARD_AGENT]: "Send Ticket to Agent",
    [guideFlowOptionActionType.CLOSE_CONVERSATION]: "Close Conversation",
    [guideFlowOptionActionType.RESTART_CONVERSATION]: "Restart Conversation",
};