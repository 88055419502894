import React, { useState } from "react";
import { useSelector } from "react-redux";
import { messageTypes } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
// import TMTagCannedResponses from "../TMTagCannedResponses/TMTagCannedResponses";
// import TMTagConversations from "../TMTagConversations/TMTagConversations";
import ConfirmTagResponseSend from "./ConfirmTagResponseSend/ConfirmTagResponseSend";
import TConversationSuggestions from "../TConversationSuggestions/TConversationSuggestions";
import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import "./TMTagDetailElement.scss";

const { BRANCH } = messageTypes;
const { ISSUE_DISCOVERY } = ticketPhases;

const TMTagDetailElement = ({
    // activeMessageTagData,
    selectedTicket,
    lastMessageContent,
    // setCannedMessage,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [suggestion, setSuggestion] = useState({});
    // const { cannedResponses
    //     // conversations
    //  } = activeMessageTagData || {};
    const { ticketPhase } = useSelector(
        (state) => state?.ticketsFilters?.ticketsfilterRequest
    );

    const handleModalToggle = (item, type) => {
        toggleModal(true);
        setSuggestion({
            item,
            type,
        });
    };

    return (
        <div>
            {/* <TMTagCannedResponses
                cannedResponses={cannedResponses}
                acceptCannedResponse={
                    (cannedResponse) =>
                        setCannedMessage(cannedResponse?.response)
                    // handleModalToggle(cannedResponse, DEFAULT)
                }
            /> */}
            {/* <TMTagConversations
                conversations={conversations}
                acceptConversation={(data) => handleModalToggle(data, BRANCH)}
            /> */}
            {ticketPhase === ISSUE_DISCOVERY && (
                <TConversationSuggestions
                    lastMessageContent={lastMessageContent}
                    acceptConversation={(data) =>
                        handleModalToggle(data, BRANCH)
                    }
                />
            )}

            {showModal && (
                <ConfirmTagResponseSend
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    suggestion={suggestion}
                    selectedTicket={selectedTicket}
                />
            )}
        </div>
    );
};

export default TMTagDetailElement;
