import { Table } from "components/ui";
import EditCRButton from "../../common/EditCRButton/EditCRButton";
import CRTableDropdown from "../../common/CRTableDropdown/CRTableDropdown";
import ScenariosGuides from "components/dashboard/TrainSAM/Scenarios/ScenariosTable/ScenariosGuides/ScenariosGuides";
import { CANNED_RESOURCE_ACTIONS } from "../../enum";

const { EDIT_CANNED_RESOURCE, DELETE_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const CannedERTable = ({ handleActionModal, cannedEmails, tableIsLoading }) => {
    const tableData = cannedEmails?.map(
        ({ cannedResourceName, agentGuides, ...rest }) => {
            return {
                name: cannedResourceName,
                attachedGuides: <ScenariosGuides guides={agentGuides} />,
                actions: (
                    <div className='discovery--actions'>
                        <EditCRButton
                            onClick={() =>
                                handleActionModal(EDIT_CANNED_RESOURCE, {
                                    cannedResourceName,
                                    ...rest,
                                })
                            }
                        />
                        <CRTableDropdown
                            handleActionModal={() =>
                                handleActionModal(DELETE_CANNED_RESOURCE, {
                                    cannedResourceName,
                                    ...rest,
                                })
                            }
                        />
                    </div>
                ),
            };
        }
    );

    return (
        <Table
            headers={["Name", "Attached Guides", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
        />
    );
};

export default CannedERTable;
