import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import StartupProfileContent from "./StartupProfileContent/StartupProfileContent";
import { dataQueryStatus, handleError } from "utils";
import { saveStartupProfile } from "store/modules/admin/actions";
import "./StartupProfile.scss";

const { IDLE, LOADING, ERROR, SUCCESS } = dataQueryStatus;

const StartupProfile = () => {
    const [errorMssg, setErrorMssg] = useState("");
    const { startupProfile } = useSelector((state) => state.admin);
    const [status, setStatus] = useState(
        Object.keys(startupProfile)?.length > 0 ? SUCCESS : IDLE
    );

    const dispatch = useDispatch();

    const getWorkspaceProfile = async (httpRequest) => {
        try {
            if (Object.keys(startupProfile)?.length < 1) {
                setStatus(LOADING);
            }

            const res = await API.get(apiRoute?.workspaceProfile, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveStartupProfile(data));
                setStatus(SUCCESS);
            }
        } catch (err) {
            setErrorMssg(handleError(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getWorkspaceProfile?.(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getWorkspaceProfile}
                    />
                );
            case SUCCESS:
                return (
                    <StartupProfileContent
                        workspace={startupProfile}
                        handleSuccess={getWorkspaceProfile}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <div className='padded__layout'>
            <div className='row'>
                <div className='col-sm-9'>{renderBasedOnStatus()}</div>
            </div>
        </div>
    );
};

export default StartupProfile;
