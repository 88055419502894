import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { CONVERSATIONS_PERMS_ACTIONS } from "../../enums";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { READ, DELETE, UPDATE } = baseResourceActions;
const { COPY_CONVERSATION, DELETE_CONVERSATION, PIN_CONVERSATION } =
    CONVERSATIONS_PERMS_ACTIONS;

const ConversationsDropdown = ({
    conversation,
    handleActionModal,
    hasAConversation,
    pinned,
}) => {
    const conversationsDropdownOptions = [
        {
            value: COPY_CONVERSATION,
            label: "Copy Conversation Link",
            baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
        },
        {
            value: PIN_CONVERSATION,
            label: `${pinned ? "Unpin Conversation" : "Pin Conversation"}`,
            baseResources: [`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`],
        },
        {
            value: DELETE_CONVERSATION,
            label: "Delete Conversation",
            baseResources: [`${DELETE}_${KB_SAM_ISSUE_CONVERSATION}`],
        },
    ];

    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            noItemMsg='No Conversation Added Yet'
            onChange={({ value }) => handleActionModal(value, conversation)}
            options={hasAConversation ? conversationsDropdownOptions : []}
        />
    );
};

export default ConversationsDropdown;
