import { ReactSVG } from "react-svg";
import assets from "assets/images";

const EmptyContentBlock = () => (
    <div className='empty__content--block w-100 h-100 row justify-content-center align-items-center'>
        <div className='col-8'>
            <div className='text-center'>
                <ReactSVG
                    src={assets?.images?.note}
                    fallback='No note'
                    className='no-content-svg'
                />
                <h2 className='no-content text-dark mt-4'>
                    No content blocks
                </h2>
            </div>
        </div>
    </div>
);

export default EmptyContentBlock;
