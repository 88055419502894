import { useState, useContext } from "react";
import { Switch } from "components/ui";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage, getSecsFromNowToFuture } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ScheduleDaySelect from "components/dashboard/common/ScheduleDaySelect/ScheduleDaySelect";
import { useEffect } from "react";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const TicketRouting = ({ member, teamId, handleSuccess }) => {
    const { activationScheduleDate } = member || {};

    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        ticketRouting: member?.ticketRouting,
        activationScheduleDate: "",
    });

    const [error, setError] = useState();
    const [totalScheduleSecs, updateTotalScheduleSecs] = useState(0);

    const toastMessage = useContext(ToastContext);

    const updateTicketRouting = async (request) => {
        try {
            setLoading(true);
            const res = await API.put(
                apiRoute?.ticketRouting(teamId, member?.userId),
                request
            );

            if (res.status === 200) {
                const { message } = res.data;
                setLoading(false);
                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleRequestValues = (type, value) => {
        switch (value) {
            case true:
                updateTicketRouting({ ticketRouting: value });
                break;
            case false:
                setRequest({ ...request, ticketRouting: value });
                break;
            default:
                break;
        }
    };

    const handleSubmit = (totalNoOfSeconds) => {
        if (totalNoOfSeconds === 0) {
            setError(true);
        } else {
            setError(false);
            updateTicketRouting(request);
        }
    };

    const handleChange = (totalNoOfSeconds) => {
        setError(false);
        let scheduleDate = new Date();
        let updatedScheduleDate = new Date(
            scheduleDate.getTime() + totalNoOfSeconds * 1000
        );
        setRequest({ ...request, activationScheduleDate: updatedScheduleDate });
    };

    useEffect(() => {
        updateTotalScheduleSecs(getSecsFromNowToFuture(activationScheduleDate));
    }, [activationScheduleDate]);


    return (
        <div className='d-flex align-items-center gap-3'>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                <Switch
                    checked={request?.ticketRouting === true}
                    label={request.ticketRouting ? "Active" : "Deactivated"}
                    id='ticketRouting'
                    onChange={(e) =>
                        handleRequestValues(e.target.id, e.target.checked)
                    }
                />
            </ComponentAccessControl>
            {!request.ticketRouting && (
                <ScheduleDaySelect
                    handleSubmit={handleSubmit}
                    loading={loading}
                    hasError={error}
                    timeValue={totalScheduleSecs}
                    handleChange={(totalNoOfSeconds) =>
                        handleChange(totalNoOfSeconds)
                    }
                />
            )}
        </div>
    );
};

export default TicketRouting;
