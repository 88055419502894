export const SET_TICKET_MESSAGES = "SET_TICKET_MESSAGES";
export const SET_SELECTED_CUSTOMER_MESSAGE = "SET_SELECTED_CUSTOMER_MESSAGE";
export const UPDATE_TICKET_MESSAGES = "UPDATE_TICKET_MESSAGES";
export const UPDATE_TICKET_MESSAGE = "UPDATE_TICKET_MESSAGE";
export const DELETE_TICKET_MESSAGE = "DELETE_TICKET_MESSAGE";
export const CLEAR_TICKET_MESSAGES = "CLEAR_TICKET_MESSAGES";
export const UPDATE_TICKETS_FILTERS = "UPDATE_TICKETS_FILTERS";
export const SET_ACTIVE_TICKET = "SET_ACTIVE_TICKET";
export const SET_TICKET_TO_LEAVE_PHASE = "SET_TICKET_TO_LEAVE_PHASE";
export const SET_ACTIVE_TICKET_LATEST_ACTIVITY =
    "SET_ACTIVE_TICKET_LATEST_ACTIVITY";
