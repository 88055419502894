import { getDaysHrsMinsAndSecs } from "utils/helper";

const ChatTooltipBlock = ({
    isHorizontalChart,
    timeMetric,
    customUnit1,
    customUnit2,
    metricValue,
    label,
    showAll,
    hideZeroMetrics,
}) => {
    if (!metricValue && hideZeroMetrics) {
        return "";
    }
    let { days, hours, mins, secs } = getDaysHrsMinsAndSecs(
        timeMetric === "Min" ? metricValue * 60 : metricValue
    );

    const renderBasedOnTimeMetric = (time, name) => {
        return (
            <span>
                {time > 0 ? (
                    <>
                        <b>{time + " "}</b>{" "}
                        {time === 1 ? `${name}` : `${name}(s)`}
                    </>
                ) : (
                    ""
                )}
            </span>
        );
    };

    return (
        <>
            {showAll && <h5>{label}</h5>}
            <h4>
                {isHorizontalChart ? (
                    <>
                        {timeMetric ? (
                            <>
                                {metricValue > 0 ? (
                                    <>
                                        {[days, hours, mins, secs]?.map(
                                            (time, key) =>
                                                renderBasedOnTimeMetric(
                                                    time,
                                                    [
                                                        "day",
                                                        "hour",
                                                        "min",
                                                        "sec",
                                                    ][key]
                                                )
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <b>0.00</b>
                                        <span>s</span>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <b>{metricValue}</b>
                                <span>{customUnit1}</span>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <b>{metricValue}</b>
                        <span>
                            {customUnit2 !== undefined ? customUnit2 : "%"}
                        </span>
                    </>
                )}
            </h4>
        </>
    );
};

export default ChatTooltipBlock;
