import React from "react";
import { connect } from "react-redux";
import { defaultPermissions } from "routes/enum";

const AccessControlNavItem = ({
    dropdowns,
    hasDropdown,
    baseResources,
    children,
    permissions,
}) => {
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    const renderBasedOnDropDown = () => {
        let permissionList = permissionCodes?.length > 0 ? permissionCodes : [];

        switch (hasDropdown) {
            case true:
                return (
                    <>
                        {dropdowns?.find((dropdown) => {
                            return dropdown?.hide
                                ? false
                                : [...permissionList, ...defaultPermissions]?.some(
                                      (code) =>
                                          dropdown?.baseResources?.includes(
                                              code
                                          )
                                  );
                        }) ? (
                            <>{children}</>
                        ) : (
                            <></>
                        )}
                    </>
                );

            default:
                return (
                    <>
                        {permissionCodes?.some((code) =>
                            baseResources?.includes(code)
                        ) ? (
                            <>{children}</>
                        ) : (
                            <></>
                        )}
                    </>
                );
        }
    };
    return <>{renderBasedOnDropDown()}</>;
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
    }),
    {}
)(AccessControlNavItem);
