export const VIEW_GD_UPDATES_ACTIONS = {
    EDIT_UPDATE: "EDIT_UPDATE",
    CREATE_UPDATE: "CREATE_UPDATE",
    DELETE_UPDATE: "DELETE_UPDATE",
};

export const GUIDE_TASK_ACTIONS = {
    OPEN_CONVERSATION: "OPEN_CONVERSATION",
    CLOSE_CONVERSATION: "CLOSE_CONVERSATION",
};

export const GUIDE_TASK_SCENARIOS = {
    USER_REPLIED: "USER_REPLIED",
    USER_MUTE: "USER_MUTE",
};

export const GUIDE_TASK_SCENARIOS_SELECT = [
    {
        label: "if user doesn't reply",
        value: "USER_MUTE",
    },
    {
        label: "If user replies",
        value: "USER_REPLIED",
    },
];

export const GUIDE_TASK_ACTIONS_SELECT = [
    {
        label: "Close Conversation",
        value: "CLOSE_CONVERSATION",
    },
    {
        label: "Open Conversation",
        value: "OPEN_CONVERSATION",
    },
];

export const GUIDE_TASK_SCHEDULES = [
    {
        value: "RELATIVE",
        label: "After message has been sent",
    },
    {
        value: "AUTOMATIC",
        label: "Before message is sent",
    },
];

export const UPDATE_TASK_ACTIONS = {
    EDIT_TASK: "EDIT_TASK",
    DELETE_TASK: "DELETE_TASK",
    CREATE_TASK: "CREATE_TASK",
};

export const GUIDE_TASK_ACTION_NAMES = {
    OPEN_CONVERSATION: "Open ticket after",
    CLOSE_CONVERSATION: "Close ticket after",
};
