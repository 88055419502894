import { useState } from "react";
import AnalyticsQADropHeader from "../../../common/AnalyticsQADropHeader/AnalyticsQADropHeader";
import "./AnalyticsQATicketCapsule.scss";
import TicketInfoDetailsElement from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketInfoDetails/TicketInfoDetailsElement/TicketInfoDetailsElement";

const AnalyticsQATicketCapsule = () => {
    const [isOpen, toggleOpen] = useState(false);

    return (
        <section
            className={`analytics-qa-ticket-capsule ${isOpen ? "active" : ""}`}>
            <AnalyticsQADropHeader
                title={"Capsule information"}
                isOpen={isOpen}
                toggleOpen={() => toggleOpen(!isOpen)}
            />
            {isOpen && (
                <TicketInfoDetailsElement
                    selectedTicket={{ ticketId: "" }}
                    ticketsInfos={[]}
                />
            )}
        </section>
    );
};

export default AnalyticsQATicketCapsule;
