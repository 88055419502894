import { Empty } from "components/ui/Empty/Empty";
import asset from "assets/images";
import "./EmptyNotificationBody.scss";

const EmptyNotificationBody = () => {
    return (
        <div className='empty__notification__body'>
            <Empty
                className='empty'
                text={`No notifications yet.`}
                subText={'Find out when there are new changes or comments made here'}
                icon={asset.icons.noConvosIcon}
            />
        </div>
    );
};

export default EmptyNotificationBody;
