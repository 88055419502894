import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "components/ui";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import ConversationsHeader from "./ConversationsHeader/ConversationsHeader";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Empty } from "components/ui";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import ConversationsModal from "../ConversationsModal/ConversationsModal";
import ConversationsTable from "./ConversationsHeader/ConversationsTable/ConversationsTable";
import { CONVERSATIONS_PERMS_ACTIONS } from "../enums";
import {
    setConversationBaseFilter,
    setConversationFilterParams,
    setConversationFilterOption,
} from "store/filters/actions";
import {
    saveConversations,
    clearConversations,
} from "store/modules/sam/actions";
import "./IssuesConversations.scss";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;
const { CREATE_CONVERSATION } = CONVERSATIONS_PERMS_ACTIONS;

const IssuesConversations = () => {
    const [errorMssg, setErrorMssg] = useState("");
    const [showModal, toggleModal] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [issuesConversations, setIssueConversations] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [currentIssueConversation, setCurrentIssueConversation] = useState(
        {}
    );
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.conversationFilters
    );

    const {
        conversationsCache: { pages, totalRecords },
    } = useSelector((state) => state?.sam);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setConversationFilterParams({ currentPage: 1, search }));
        dispatch(clearConversations());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setConversationFilterParams({ ...filterParams, currentPage }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setConversationBaseFilter(baseFilter));

        dispatch(setConversationFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setConversationFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setConversationFilterOption({ ...option, value }));
        dispatch(clearConversations());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setConversationFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setConversationFilterOption({ ...option, value }));
        dispatch(clearConversations());
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setConversationFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setConversationFilterOption({ ...option, value }));
        dispatch(clearConversations());
    };

    const getIssuesConversations = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (issuesConversations?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.issuesConversation;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;

                setStatus(newStatus);
                dispatch(
                    saveConversations(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setIssueConversations(data);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);

        if (cachedPage?.length > 0 && !searchIsLoading) {
            setIssueConversations(cachedPage);
        } else {
            getIssuesConversations(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const handleSuccess = () => {
        setClearSearch(true);

        dispatch(clearConversations());
        // dispatch(
        //     setConversationFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
        getIssuesConversations();
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        text={
                            useTableLoader
                                ? "No Issue matches the search."
                                : "No Issues Created."
                        }
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <ConversationsTable
                            issuesConversations={issuesConversations}
                            isLoading={tableIsLoading}
                            tableError={errorMssg}
                            setCurrentAction={setCurrentAction}
                            setCurrentIssueConversation={
                                setCurrentIssueConversation
                            }
                            toggleModal={toggleModal}
                            handleSuccess={handleSuccess}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getIssuesConversations}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <>
            <div className='padded__layout conversations'>
                <div>
                    <ConversationsHeader
                        handleSearch={handleSearch}
                        searchIsLoading={searchIsLoading}
                        clearSearch={clearSearch}
                        handleSortByAlpha={handleSortByAlpha}
                        handleSortByDate={handleSortByDate}
                        handleSortByLastModified={handleSortByLastModified}
                        baseFilter={baseFilter}
                        filterOption={filterOption}
                        handleBasefilter={handleBasefilter}
                        searchDefaultValue={filterParams?.search}
                    />
                    {renderBasedOnStatus()}
                </div>
            </div>

            {showModal && (
                <ConversationsModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    referenceData={currentIssueConversation}
                    currentAction={currentAction}
                    handleSuccess={handleSuccess}
                    source={CREATE_CONVERSATION}
                />
            )}
        </>
    );
};

export default IssuesConversations;
