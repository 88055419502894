import React, { useState } from "react";
import SwitchesListingTableView from "./SwitchesListingTableView/SwitchesListingTableView";
import SwitchesTableModal from "./SwitchesListingTableView/SwitchesTableModal/SwitchesTableModal";

const SwitchesListingTable = ({
    dependencies,
    getDependencies,
    tableLoading,
}) => {
    const [currentDependency, setCurrentDependency] = useState();
    const [currentAction, setCurrentAction] = useState("");

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        setCurrentAction("");
        setCurrentDependency({});
    };

    const handleActionModal = (actionType, value) => {
        setCurrentAction(actionType);
        setCurrentDependency(value);
        setShowModal(true);
    };

    return (
        <>
            <SwitchesListingTableView
                {...{
                    dependencies,
                    handleActionModal,
                    getDependencies,
                    tableLoading,
                }}
            />

            {showModal && (
                <SwitchesTableModal
                    {...{
                        currentAction,
                        getDependencies,
                        currentDependency,
                        closeModal,
                        showModal,
                    }}
                />
            )}
        </>
    );
};

export default SwitchesListingTable;
