import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import ValidateForm from "utils/validations/Validator";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input, Loading } from "components/ui";
import { flattenArray, getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const DependencyForm = ({
    handleSuccess,
    dependencyName,
    dependencyId,
    dependencyElements,
    isEdit,
}) => {
    const [loading, setLoading] = useState();
    const [request, setRequest] = useState({
        dependencyName: "",
        dependenciesCapsules: [],
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [selectedContents, setSelectedContents] = useState(new Map());
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        // eslint-disable-next-line
    }, []);

    const createNewDependency = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const requestData = {
                ...request,
                dependenciesCapsules: Array.from(
                    selectedContents?.values()
                ).filter((capsule) => capsule?.classCapsuleEntryIds.length > 0),
            };

            const url = apiRoute?.createDependency;
            const res = await API.post(url, requestData);
            if (res.status === 201) {
                handleSuccess();
                await toastMessage(res.data.message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const updateDependency = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const requestData = {
                ...request,
                dependenciesCapsules: Array.from(
                    selectedContents?.values()
                ).filter((capsule) => capsule?.classCapsuleEntryIds.length > 0),
            };

            const url = apiRoute?.dependency(dependencyId);
            const res = await API.put(url, requestData);
            if (res.status === 200) {
                handleSuccess();
                await toastMessage(res.data.message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId: classCapsuleID, capsuleCode } = capsule;
        const prevSelectedContents = selectedContents.get(capsuleCode);

        if (prevSelectedContents) {
            const ccSelectedContentIds =
                prevSelectedContents?.classCapsuleEntryIds;
            const contentExist =
                prevSelectedContents?.classCapsuleEntryIds?.includes(
                    capsuleEntryId
                );

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                        capsuleEntryId
                    ),
                    1
                );
            }

            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: classCapsuleID,
                                classCapsuleEntryIds: ccSelectedContentIds,
                            },
                        ],
                    ])
            );
        } else {
            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: classCapsuleID,
                                classCapsuleEntryIds: [capsuleEntryId],
                            },
                        ],
                    ])
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        setErrors(formErrors);
        if (formisValid) {
            isEdit ? updateDependency() : createNewDependency();
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        setRequest({ ...request, dependencyName });
        setRelatedCapsules(dependencyElements);

        // eslint-disable-next-line
    }, []);

    const setRelatedCapsules = (dependencyElements) => {
        let dependencyElementsMap = new Map();

        dependencyElements?.map(
            ({
                dependencyCapsuleEntryIds,
                dependencyClassCapsule: { capsuleCode, capsuleClassId },
            }) => {
                dependencyElementsMap.set(capsuleCode, {
                    classCapsuleEntryIds: dependencyCapsuleEntryIds,
                    classCapsuleId: capsuleClassId,
                });
                return { [capsuleCode]: dependencyCapsuleEntryIds };
            }
        );

        setSelectedContents(dependencyElementsMap);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={capsuleClasses?.map(
                            (capsuleClass) => capsuleClass?.capsuleCode
                        )}
                        classesListBreakpoint={8}
                        handleContentSelect={handleContentSelect}
                        selectable={true}
                        searchable={true}
                        selectedContentIds={flattenArray(
                            Array.from(selectedContents?.values())?.map(
                                (x) => x.classCapsuleEntryIds
                            )
                        )}
                        label='Relate feature or flow'
                        inputKey='classificationCapsules'
                        error={errors?.classificationCapsules}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMssg)}
                    message={errorMssg}
                    hide={() => setErrorMssg()}
                />

                <Input
                    grpClass='mb-3'
                    type='text'
                    name='dependencyName'
                    label='Name of dependency'
                    labelClass='text-dark fw-medium'
                    id='dependencyName'
                    placeholder='Enter name of dependency'
                    value={request?.dependencyName}
                    onChange={handleChange}
                    isErr={errors?.dependencyName}
                    errMssg={errors?.dependencyName}
                    autoFocus={true}
                />

                <div className='form-group'>{renderBasedOnStatus()}</div>

                <Button
                    type='submit'
                    text='Save'
                    classType='primary'
                    otherClass='my-3 w-100'
                    loading={loading}
                />
            </form>
        </>
    );
};

export default DependencyForm;
