import { useState, useEffect } from "react";
import { ChromePicker } from "react-color";

export const InputColor = ({
	name,
	id,
	label,
	labelClass,
	grpClass,
	inputClass,
	placeholder,
	isErr,
	errMssg,
	disabled,
	value,
	onChange,
}) => {
	const [showPicker, setShow] = useState(false);

	const changeColor = (value) =>
		onChange({ target: { name, value: value?.hex } });

	useEffect(() => {
		document.body.addEventListener("click", () => setShow(false));

		return () =>
			document.body.removeEventListener("click", () => setShow(false));
	}, []);

	return (
		<div
			className={`form-group input-color ${grpClass || ""}`}
			onClick={() => setShow(false)}
		>
			<label
				htmlFor={id}
				className={`form-label ${labelClass || ""} ${
					disabled ? "text-muted" : ""
				}`}
			>
				{label}
			</label>
			<div className="position-relative">
				<input
					type="text"
					name={name}
					id={id}
					className={`form-control ${isErr ? "is-invalid" : ""} ${
						inputClass || ""
					}`}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
				/>
				<div className="invalid-feedback">{errMssg}</div>
				<span
					className={`position-absolute top-${errMssg ? "15" : "30"} end-${
						errMssg ? "10" : "5"
					} text-muted fw-bold cursor-pointer`}
				>
					<div
						className="rounded-circle color-selector cursor-pointer"
						style={{ backgroundColor: value }}
						onClick={(e) => {
							e.stopPropagation();
							setShow(!showPicker);
						}}
					></div>
					{showPicker ? (
						<div
							className="position-absolute top-110"
							onClick={(e) => e.stopPropagation()}
						>
							<ChromePicker color={value} onChange={changeColor} />
						</div>
					) : null}
				</span>
			</div>
		</div>
	);
};
