import * as types from "./types";

const initialState = {
    ticketsfilterRequest: {
        ticketPhase: undefined,
    },
    authTicketInfo: [],
};

const TicketsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_TICKETS_FILTERS:
            const { filterName, filterValue, filterOption } = payload || {};
            return {
                ...state,
                ticketsfilterRequest: {
                    ...state?.ticketsfilterRequest,
                    [filterName]:
                        state?.ticketsfilterRequest?.[filterName] ===
                        filterValue
                            ? undefined
                            : filterValue,
                    otherFilter: filterOption,
                },
            };
        case types.SAVE_AUTH_TICKET__INFO:
            return {
                ...state,
                authTicketInfo: payload,
            };

        default:
            return state;
    }
};

export default TicketsReducer;
