import AnalyticsBarChart from "components/dashboard/Analytics/AnalyticsTeams/common/AnalyticsBarChart/AnalyticsBarChart";
import "./AnalyticsQAProgressChart.scss";

const AnalyticsQAProgressChart = () => {
    const chartData = [];
    return (
        <section className='analytics-qa-progress-chart'>
            <div className='analytics-qa-progress-chart__legends'>
                <div>
                    <span className='accuracy-color'></span>
                    <span>Accuracy</span>
                </div>
                <div>
                    <span className='tickets-color'></span>
                    <span>Number of tickets</span>
                </div>
            </div>
            <div>
                <AnalyticsBarChart
                    chartTitle='Number of Tickets'
                    chartData={chartData || []}
                    isLoading={false}
                    strokeData={[]}
                    allStrokeData={[]}
                    xDataKey1={"dateValue"}
                    xDomain2={[0, 12]}
                    yAxisLabel1={""}
                    xAxisLabel1={`Time`}
                    yAxisLabel2={""}
                    xAxisLabel2={"Days"}
                    customUnit1={"ticket(s)"}
                    tooltipExtraMetrics={false}
                    useDefaultOptions={false}
                    hasTicketInfo={false}
                    roundUpTooltipVal
                    hasFreqKey
                    isOverlapped
                    overviews={[]}
                    hideModeSelection
                />
            </div>
        </section>
    );
};

export default AnalyticsQAProgressChart;
