import { Table } from "components/ui";
import { Link } from "react-router-dom";
import "./AnalyticsQAVAConvoSuggsDropdown.scss";
import { formatDate } from "utils/helper";

const AnalyticsQAVAConvoSuggsDropdown = ({ ticketMessageRecords }) => {
    const tableData = ticketMessageRecords?.map?.(
        ({
            conversationContext,
            createdDate,
            ticketResolutionStatus,
            ticketId,
        }) => {
            const isResolved = ticketResolutionStatus === "RESOLVED";
            return {
                queries: conversationContext,
                timeSent: formatDate(createdDate),
                status: (
                    <span className={`${isResolved ? "success" : "failure"}`}>
                        {isResolved ? "Sucess" : "Failure"}
                    </span>
                ),
                view: (
                    <Link to={`/analytics/qa/vet-agents/ticket/${ticketId}`}>
                        View conversation
                    </Link>
                ),
            };
        }
    );

    return (
        <div className='analytics-qava-convo-dropdown'>
            <Table
                headers={["User Queries", "Time sent", "Status", ""]}
                dataSource={tableData}
            />
        </div>
    );
};

export default AnalyticsQAVAConvoSuggsDropdown;
