import React from "react";
import BranchesListItem from "./BranchesListItem/BranchesListItem";
import ItemsList from "components/ui/ItemsList/ItemsList";
import "./BranchesList.scss";

const BranchesList = ({
    branches,
    selectedBranch,
    handleBranchSelect,
    showBottomBorder = true,
    lastToggledBranchId,
    showEmptyOptionLabel,
    isAgentGuideView
}) => {
    return (
        <div
            className={`branches__list ${
                showBottomBorder ? "bottom__border" : ""
            }`}>
            <ItemsList otherClassNames={"mb-3"} breakPoint={20}>
                {branches
                    ?.filter(
                        (branch, index) =>
                            index ===
                            branches?.findIndex(
                                (item) => branch?.branchId === item?.branchId
                            )
                    )
                    ?.map((item, index) => (
                        <BranchesListItem
                            key={index}
                            {...item}
                            showEmptyOptionLabel={showEmptyOptionLabel}
                            handleSelect={() =>
                                handleBranchSelect(item?.branchId)
                            }
                            isActive={
                                selectedBranch?.branchId === item?.branchId
                            }
                            isLastToggled={
                                lastToggledBranchId === item?.branchId
                            }
                            isAgentGuideView={isAgentGuideView}
                        />
                    ))}
            </ItemsList>
        </div>
    );
};

export default BranchesList;
