import { Checkbox } from "components/ui";
import { CUSTOM_RULES } from "../enums";
import "./TicketDistribution.scss";

const TicketDistribution = ({ request, handleRequestValues }) => {
    return (
        <div className='ticket__distribution'>
            <h3 className='ticket__header fw-medium'>Ticket Distribution</h3>
            <Checkbox
                label='Tickets are distributed evenly amongst team members'
                labelClass='text-grey fw-normal'
                name='ticketDistributionRule'
                id='ticketDistributionRuleFirst'
                inputClass='input-check-grey'
                type='radio'
                checked={
                    CUSTOM_RULES.EVENLY_DISTRIBUTED ===
                    request.ticketDistributionRule
                }
                onChange={(e) =>
                    handleRequestValues(
                        e.target.name,
                        CUSTOM_RULES.EVENLY_DISTRIBUTED
                    )
                }
            />
            <Checkbox
                label='Tickets are distributed based on size of workload'
                labelClass='text-grey fw-normal'
                name='ticketDistributionRule'
                id='ticketDistributionRuleSecond'
                type='radio'
                inputClass='input-check-grey'
                checked={
                    CUSTOM_RULES.WORKLOAD_DISTRIBUTED ===
                    request.ticketDistributionRule
                }
                onChange={(e) =>
                    handleRequestValues(
                        e.target.name,
                        CUSTOM_RULES.WORKLOAD_DISTRIBUTED
                    )
                }
            />
        </div>
    );
};

export default TicketDistribution;
