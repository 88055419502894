import { useState, useEffect } from "react";

import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { extractObjectKeyValueFromArray, getErrorMessage } from "utils/helper";
import { TICKET_AGENT_ASSISTANT_STAGES } from "./enum";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import { useSelector } from "react-redux";
import {
    appMessageUserTypes,
    messageTypes,
} from "../../ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import TicketAgentAssistantModule from "./TicketAgentAssistantModule/TicketAgentAssistantModule";

const { LOADING, ERROR, NULLMODE, IDLE, DATAMODE } = dataQueryStatus;

const { THIRD_USER } = appMessageUserTypes;
const { DEFAULT } = messageTypes;

const { GUIDE_LIST, FETCHED_GUIDE } = TICKET_AGENT_ASSISTANT_STAGES;

const TicketAgentAssistant = ({
    selectedTicket,
    handleToggleEditTicketTaskModal,
    handleToggleScenarioModal,
    refreshAgentAssistant,
    handleToggleEmailPreview,
    selectAgentGuide,
    fetchMessagesStatus,
    selectedCustomerMessage,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [stage, setStage] = useState();
    const [guideList, setGuideList] = useState([]);

    const [guideDetails, setGuideDetails] = useState({});
    const [ticketConversation, setTicketConversation] = useState([]);

    const [currentStep, setCurrentStep] = useState({});
    const [allGuideSteps, setGuideSteps] = useState([]);

    const [selectedGuideOptionId, selectGuideOptionId] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const { ticketsMessages } = useSelector((state) => state.tickets);

    const messages = ticketsMessages?.filter(
        (item) => item?.ticketId === selectedTicket?.ticketId
    );

    const hasTicketMessagesBeenFetched =
        messages?.length > 0 &&
        messages?.[0]?.ticketId === selectedTicket?.ticketId;

    const areMessagesStillFetching = fetchMessagesStatus === LOADING;

    const retriveGuideStep = () => {
        return allGuideSteps?.find(
            ({ ticketAgentGuideOptionId }) =>
                ticketAgentGuideOptionId === selectedGuideOptionId
        );
    };

    const fetchCustomerInvestigateMessages = extractObjectKeyValueFromArray(
        messages?.filter(
            (message) =>
                message?.messageType === DEFAULT &&
                message?.senderType === THIRD_USER
        ),
        "messageContent"
    );

    const investigateTicketGuide = async (searchValue) => {
        try {
            if (searchValue?.length > 0 || clearSearch) {
                setIsLoading(true);
            } else {
                setStatus(LOADING);
            }
            const searchMssg = searchValue || fetchCustomerInvestigateMessages;
            if (searchMssg){
                const url = searchValue ? apiRoute?.getGuides : apiRoute?.investigateTicketGuide;
                const res = await API.get(url, {
                    params: {
                        search: searchValue ? searchValue :  fetchCustomerInvestigateMessages
                    },
                });

                if (res.status === 200) {
                    setGuideList(res.data?.data);
                    setStage(GUIDE_LIST);
                    setStatus(DATAMODE);
                    setIsLoading(false);
                }
            } else {
                setGuideList([]);
                setStage(GUIDE_LIST);
                setStatus(DATAMODE);
                setIsLoading(false);
            }

        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setIsLoading(false);
        }
    };

    const getTicketGuide = async (hideBigLoader, setLoader) => {
        try {
            if (!hideBigLoader) {
                setStatus(LOADING);
            }
            const url = apiRoute?.getTicketGuide(selectedTicket?.ticketId);

            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                if (!Boolean(data)) {
                    investigateTicketGuide();
                } else {
                    const { currentStep } = data;
                    setCurrentStep(currentStep);
                    setGuideSteps([currentStep]);
                    setGuideDetails(data);
                    getGuideSteps(
                        currentStep.guideFlow?.guideFlowId,
                        hideBigLoader
                    );
                    selectGuideOptionId(currentStep.ticketAgentGuideOptionId)
                    setStage(FETCHED_GUIDE);
                    setLoader?.(false);
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const updateSpecificFlowInSteps = () => {
        setGuideSteps((prevSteps) =>
            prevSteps?.map(
                ({ guideFlow, ticketAgentGuideOptionId, ...rest }) => {
                    if (
                        currentStep?.ticketAgentGuideOptionId ===
                            ticketAgentGuideOptionId &&
                        guideFlow?.guideFlowId ===
                            currentStep?.guideFlow?.guideFlowId
                    ) {
                        return {
                            guideFlow,
                            ticketAgentGuideOptionId,
                            ...rest,
                            ...currentStep,
                        };
                    } else {
                        return {
                            guideFlow,
                            ticketAgentGuideOptionId,
                            ...rest,
                        };
                    }
                }
            )
        );
    };

    const getGuideSteps = async (guideFlowId, hideLoader) => {
        try {
            if (!hideLoader) {
                setStatus(LOADING);
            }
            const url = apiRoute?.getGuideFlow(guideFlowId);
            const res = await API.get(url, {});

            if (res.status === 200) {
                const guideFlow = res.data.data;
                setCurrentStep((prev) => ({
                    ...prev,
                    guideFlow,
                }));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getGuideStepInfo = async (hideLoader) => {
        try {
            if (!hideLoader) {
                setStatus(LOADING);
            }
            const url = apiRoute?.getGuideConnectedStepInfo(
                selectedGuideOptionId
            );
            const res = await API.get(url, {});

            if (res.status === 200) {
                const selectedStepInfo = res.data?.data;
                
                setGuideSteps((prev) => [...prev, selectedStepInfo]);
                setCurrentStep(selectedStepInfo);
                getGuideSteps(
                    selectedStepInfo.guideFlow?.guideFlowId,
                    hideLoader
                );
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getTicketDetails = async () => {
        try {
            const url = apiRoute?.getTicketDetails(selectedTicket?.ticketId);
            const res = await API.get(url, {});

            if (res.status === 200) {
                const result = res.data?.data;
                setTicketConversation(result?.ticketConversation);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const handleGuideStepRetrieval = () => {
        const retrievedGuideStep = retriveGuideStep();
        ;
        if (!retrievedGuideStep) {
            getGuideStepInfo();
        } else {
            setCurrentStep(retrievedGuideStep);
        }
    };

    useEffect(() => {
        if (areMessagesStillFetching) {
            setStatus(LOADING);
        } else if (hasTicketMessagesBeenFetched) {
            getTicketGuide();
        }
        // eslint-disable-next-line
    }, [
        selectedTicket?.ticketId,
        refreshAgentAssistant,
        hasTicketMessagesBeenFetched,
        areMessagesStillFetching,
    ]);

    useEffect(() => {
        
        if (selectedGuideOptionId) {
            handleGuideStepRetrieval();
        }
        // eslint-disable-next-line
    }, [selectedGuideOptionId]);

    useEffect(() => {
        if (currentStep) {
            updateSpecificFlowInSteps();
        }
        // eslint-disable-next-line
    }, [currentStep]);

    useEffect(() => {
        if (selectedTicket) {
            getTicketDetails();
        }
        // eslint-disable-next-line
    }, [selectedTicket]);

    const currentFlow = currentStep?.guideFlow;

    const isNotDefaultGuide = currentFlow?.guideFlowType !== "DEFAULT";

    const { options } = guideDetails || {};

    const handleSearchGuides = (searchValue) => {
        setClearSearch(searchValue?.length === 0);
        investigateTicketGuide(searchValue);
    };

    const handleCannedActionSuccess = (newConversation) => {
        setTicketConversation((prev) => [...prev, newConversation]);
        getTicketGuide(true);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        otherClassNames={"with-margin"}
                        message={"Ticket info\n Not available yet"}
                    />
                );

            case DATAMODE:
                return (
                    <TicketAgentAssistantModule
                        {...{
                            guideDetails,
                            currentFlow,
                            currentStep,
                            selectGuideOptionId,
                            handleToggleEditTicketTaskModal,
                            isNotDefaultGuide,
                            selectAgentGuide,
                            selectedTicket,
                            isLoading,
                            setIsLoading,
                            guideList,
                            setStatus,
                            handleSearchGuides,
                            options,
                            getTicketGuide,
                            handleToggleEmailPreview,
                            handleToggleScenarioModal,
                            stage,
                            selectedCustomerMessage,
                            ticketConversation,
                            handleCannedActionSuccess,
                        }}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} handleRetry={getTicketDetails}/>;

            default:
                return "";
        }
    };

    return <div>{renderBasedOnStatus()}</div>;
};

export default TicketAgentAssistant;
