import AddButton from "components/ui/Button/AddButton/AddButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import Filter from "components/dashboard/common/Filter/Filter";

const { KB_DEPENDENCY } = baseResources;
const { CREATE } = baseResourceActions;

const SwitchesHeadingActions = ({
    toggleModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified, baseFilter,
    filterOption,
    handleBasefilter,searchDefaultValue
}) => {
    return (
        <>
            <div className='switches__listing__heading'>
                <div>
                </div>

                <div>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_DEPENDENCY}`]}>
                        <AddButton
                            btnText={"Add dependency"}
                            onClick={toggleModal}
                        />
                    </ComponentAccessControl>
                </div>
            </div>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchPlaceholder='Search dependencies here'
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                searchDefaultValue={searchDefaultValue}
                hideClassFilter
            />
        </>
    );
};

export default SwitchesHeadingActions;
