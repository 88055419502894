import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import ProposeScenarioForm from "./ProposeScenarioForm/ProposeScenarioForm";

const ProposeScenarioModal = ({
    showModal,
    closeModal,
    recentCustomerMessage,
    handleSuccess,
    selectedAgentGuide
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Propose a Scenario'
                        subTitle=''
                    />
                    <ProposeScenarioForm
                        closeModal={closeModal}
                        recentCustomerMessage={recentCustomerMessage}
                        handleSuccess={handleSuccess}
                        selectedAgentGuide={selectedAgentGuide}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default ProposeScenarioModal;
