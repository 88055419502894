import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";
import "./ConversationsTemplateButton.scss";

const ConversationsTemplateButton = ({ openModal, isEdit }) => {
    return (
        <div>
            {isEdit ? (
                <span className='edit__template__btn' onClick={openModal}>
                    Edit template
                </span>
            ) : (
                <AddButton
                    btnText='Create new template'
                    otherClassName='create__template__btn'
                    onClick={openModal}
                />
            )}
        </div>
    );
};

ConversationsTemplateButton.prototypes = {
    openModal: PropTypes.func.isRequired,
};

export default ConversationsTemplateButton;
