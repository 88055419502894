import ScenariosGuides from "components/dashboard/TrainSAM/Scenarios/ScenariosTable/ScenariosGuides/ScenariosGuides";
import { Table } from "components/ui";
import UpdatesTableAction from "../../common/UpdatesTableAction/UpdatesTableAction";

const EmailUpdatesTable = ({
    handleActionModal,
    cannedUpdates,
    tableIsLoading,
}) => {
    const tableData = cannedUpdates?.map?.(
        ({ agentUpdateName, agentGuides, ...rest }) => {
            return {
                name: agentUpdateName,
                attachedGuides: <ScenariosGuides guides={agentGuides} />,
                actions: (
                    <UpdatesTableAction
                        editText={"Edit update"}
                        handleModalAction={(actionType) =>
                            handleActionModal(actionType, {
                                agentUpdateName,
                                agentGuides,
                                ...rest,
                            })
                        }
                    />
                ),
            };
        }
    );

    return (
        <Table
            headers={["Name", "Attached Guides", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
        />
    );
};

export default EmailUpdatesTable;
