import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./AddedSolutionCollection.scss";

const { KB_SAM_COLLECTION } = baseResources;
const { READ, DELETE } = baseResourceActions;

const AddedSolutionCollection = ({ solutionCollection, toggleDeleteModal }) => {
    const { branchCollection } = solutionCollection;
    const [dropdownOpen, toggleDropdown] = React.useState(false);
    const history = useHistory();

    const handleRedirect = () => {
        const { branchCollectionId } = branchCollection;
        history.push({
            pathname: `/t-sam/branch-collection/${branchCollectionId}/view`,
            state: {
                branchCollection: solutionCollection?.branchCollection,
            },
        });
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            className={`added__collection`}
            direction='up'
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <div className='collection--content'>
                <p class='main__content'>Collection Added</p>
            </div>
            <span
                className='carbon__menu--icon'
                onClick={() => toggleDropdown((prevState) => !prevState)}>
                <ReactSVG src={imageLinks?.icons?.carbonMore} />
            </span>
            <DropdownMenu>
                <ComponentAccessControl
                    baseResources={[`${READ}_${KB_SAM_COLLECTION}`]}>
                    <DropdownItem onClick={handleRedirect}>View</DropdownItem>
                </ComponentAccessControl>
                <ComponentAccessControl
                    baseResources={[`${DELETE}_${KB_SAM_COLLECTION}`]}>
                    <DropdownItem onClick={toggleDeleteModal}>
                        Delete
                    </DropdownItem>
                </ComponentAccessControl>
            </DropdownMenu>
        </Dropdown>
    );
};

export default AddedSolutionCollection;
