import { Dropdown } from "components/ui";
import asset from "assets/images";
import { ReactSVG } from "react-svg";
import { GUIDE_TASK_ACTION_NAMES, UPDATE_TASK_ACTIONS } from "../../enum";
import { baseResourceActions, baseResources } from "routes/enum";

const { KB_SAM_COLLECTION } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const UpdateTaskElement = ({ task, handleModalAction }) => {
    const { agentUpdateTaskName, initiationSchedule, taskActionType } = task;
    const minsDur = Number(initiationSchedule / 60)?.toFixed?.(2);
    const hrsDur = Number(initiationSchedule / 3600)?.toFixed?.(2);

    const duration =
        initiationSchedule < 60
            ? `${initiationSchedule} secs`
            : initiationSchedule > 60 && initiationSchedule < 3600
            ? `${minsDur} mins`
            : `${hrsDur} hrs`;

    return (
        <>
            <h5>{agentUpdateTaskName}</h5>
            <div className='task__capsule'>
                <li>
                    {GUIDE_TASK_ACTION_NAMES?.[taskActionType]} {duration}
                </li>
                <Dropdown
                    size='sm'
                    placeholder={
                        <ReactSVG
                            src={asset?.icons?.horizontalEllipsis}
                            className='d-inline-flex'
                        />
                    }
                    ddValueClass='borderless'
                    anchor={false}
                    changeValue={false}
                    onChange={({ value }) =>
                        handleModalAction(value, true, task)
                    }
                    options={[
                        {
                            value: UPDATE_TASK_ACTIONS.EDIT_TASK,
                            label: "Edit Task",
                            baseResources: [`${UPDATE}_${KB_SAM_COLLECTION}`],
                        },
                        {
                            value: UPDATE_TASK_ACTIONS.DELETE_TASK,
                            label: "Delete Task",
                            baseResources: [`${DELETE}_${KB_SAM_COLLECTION}`],
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default UpdateTaskElement;
