import client from "graphql/apolloClient/apolloClient";
import { gql } from "@apollo/client";
import {
    capsuleClassesAggregateQuery,
    capsuleSubClassesEntryAggregateQuery,
    capsuleSubClassesAggregateQuery,
    getCapsuleEntries,
    getCapsuleSubEntries,
    getChildrenEntries,
    getParentEntries,
    getProductCapsuleEntries,
    getSingleCapsuleEntryQuery,
    getCollectionCapsules,
    getCapsuleStatusCount,
    getCapsuleEntriesBasedOnStatus,
    findCapsuleEntriesQuery,
    capsuleEntriesAggregateQuery,
    getCapsuleSubEntry,
    getCapsuleEntriesAggregateQueryByWorkspace,
} from "graphql/queries/queries";
import { v4 as uuidv4 } from "uuid";
import {
    singleAddCapsuleMutation,
    singleAddChildCapsuleMutation,
    singleAddParentCapsuleMutation,
    relateChildCapsuleMutation,
    editCapsuleMutation,
    unRelateChildCapsuleMutation,
} from "graphql/mutations/mutations";
import store from "store/store";
import { isProductionApp } from "config/config";

export const capsuleActions = {
    SINGLE_AGGREGATE: "SINGLE_AGGREGATE",
    PARENT_AGGREGATE: "PARENT_AGGREGATE",
    CHILDREN_AGGREGATE: "CHILDREN_AGGREGATE",
    SINGLE_CAPSULE: "SINGLE_CAPSULE",
    ALL_CAPSULES: "ALL_CAPSULES",
    ALL_PARENT_CHILDREN_CAPSULES: "ALL_PARENT_CHILDREN_CAPSULES",
    ALL_CHILDREN_CAPSULES: "ALL_CHILDREN_CAPSULES",
    ALL_PARENT_CAPSULES: "ALL_PARENT_CAPSULES",
    ADD_SINGLE_CAPSULE: "ADD_SINGLE_CAPSULE",
    ADD_SINGLE_CHILD_CAPSULE: "ADD_SINGLE_CHILD_CAPSULE",
    ADD_SINGLE_PARENT_CAPSULE: "ADD_SINGLE_PARENT_CAPSULE",
    ALL_PRODUCT_CAPSULES: "ALL_PRODUCT_CAPSULES",
    FLOW_CAPSULES: "FLOW_CAPSULES",
    CAPSULE_STATUS_COUNT: "CAPSULE_STATUS_COUNT",
    CAPSULES_BASED_ON_STATUS: "CAPSULES_BASED_ON_STATUS",
    RELATE_CAPSULES: "RELATE_CAPSULES",
    FIND_CAPSULE_ENTRY: "FIND_CAPSULE_ENTRY",
    EDIT_CAPSULE: "EDIT_CAPSULE",
    CAPSULES_ENTRIES_AGGREGATE: "CAPSULES_ENTRIES_AGGREGATE",
    RELATED_CAPSULE: "RELATED_CAPSULE",
    UN_RELATE_CAPSULES: "UN_RELATE_CAPSULES",
    CAPSULES_ENTRIES_AGGREGATE_BY_WORKSPACE:
        " CAPSULES_ENTRIES_AGGREGATE_BY_WORKSPACE",
};

const {
    SINGLE_CAPSULE,
    SINGLE_AGGREGATE,
    CHILDREN_AGGREGATE,
    PARENT_AGGREGATE,
    ADD_SINGLE_CAPSULE,
    ALL_CAPSULES,
    ADD_SINGLE_CHILD_CAPSULE,
    ALL_PARENT_CHILDREN_CAPSULES,
    ALL_CHILDREN_CAPSULES,
    ALL_PARENT_CAPSULES,
    ADD_SINGLE_PARENT_CAPSULE,
    ALL_PRODUCT_CAPSULES,
    FLOW_CAPSULES,
    CAPSULE_STATUS_COUNT,
    CAPSULES_BASED_ON_STATUS,
    RELATE_CAPSULES,
    FIND_CAPSULE_ENTRY,
    EDIT_CAPSULE,
    CAPSULES_ENTRIES_AGGREGATE,
    RELATED_CAPSULE,
    UN_RELATE_CAPSULES,
    CAPSULES_ENTRIES_AGGREGATE_BY_WORKSPACE,
} = capsuleActions;

const combinedCollectionKeySuffix = isProductionApp ? "s" : "s";


const genCapsuleConnectionParams = (
    capsule,
    collectionActionType,
    secCapsule,
    isParental,
    containSingleEntryCapsule
) => {
    switch (collectionActionType) {
        case SINGLE_AGGREGATE:
            const countCollectionName = capsule?.collectionName + "_aggregate";
            return {
                collectionName: countCollectionName,
                collectionQuery:
                    capsuleClassesAggregateQuery(countCollectionName),
            };

        case PARENT_AGGREGATE:
        case CHILDREN_AGGREGATE:
            const pCountCollectionName = `${
                collectionActionType === PARENT_AGGREGATE
                    ? capsule?.collectionName
                    : secCapsule?.collectionName
            }`;
            const pCountCollectionAggName = `${pCountCollectionName}_aggregate`;
            const refCollectionName = `${
                collectionActionType === CHILDREN_AGGREGATE
                    ? capsule?.collectionName
                    : secCapsule?.collectionName
            }`;
            const refCollectionKey = `${
                collectionActionType === CHILDREN_AGGREGATE
                    ? capsule?.collectionKey
                    : secCapsule?.collectionKey
            }`;
            const paParentCollectionName = capsule?.collectionName;
            const paChildCollectionName = secCapsule?.collectionName;
            const paCollectionCombo = `${paParentCollectionName}_${paChildCollectionName}${combinedCollectionKeySuffix}`;

            return {
                collectionName: pCountCollectionAggName,
                collectionQuery: containSingleEntryCapsule
                    ? capsuleSubClassesEntryAggregateQuery(
                          pCountCollectionName,
                          paCollectionCombo,
                          refCollectionName,
                          refCollectionKey
                      )
                    : capsuleSubClassesAggregateQuery(
                          pCountCollectionAggName,
                          paCollectionCombo,
                          paParentCollectionName
                      ),
            };

        case SINGLE_CAPSULE:
            const scCollectionName = capsule?.collectionName + "_by_pk";
            return {
                collectionName: scCollectionName,
                collectionQuery: getSingleCapsuleEntryQuery(
                    scCollectionName,
                    capsule?.collectionKey
                ),
            };

        case ADD_SINGLE_CAPSULE:
            const singleCollectionKey = capsule?.collectionKey;
            const singleCollectionName = `insert_${capsule?.collectionName}`;

            return {
                collectionName: singleCollectionName,
                collectionKey: singleCollectionKey,
                collectionQuery: singleAddCapsuleMutation(
                    singleCollectionName,
                    singleCollectionKey
                ),
            };

        case ALL_CAPSULES:
            const entriesCollectionKey = capsule?.collectionKey;
            const entriesCollectionName =
                capsule?.collectionName + "_aggregate";
            return {
                collectionName: entriesCollectionName,
                collectionKey: entriesCollectionKey,
                collectionQuery: getCapsuleEntries(
                    entriesCollectionName,
                    entriesCollectionKey
                ),
            };

        case CAPSULES_BASED_ON_STATUS:
            const capsulesCollectionKey = capsule?.collectionKey;
            const capsulesCollectionName =
                capsule?.collectionName + "_aggregate";
            const capsulesCollectionStatus = capsule?.isEnabled;
            return {
                collectionName: capsulesCollectionName,
                collectionKey: capsulesCollectionKey,
                collectionQuery: getCapsuleEntriesBasedOnStatus(
                    capsulesCollectionName,
                    capsulesCollectionKey,
                    capsulesCollectionStatus
                ),
            };

        case ALL_PRODUCT_CAPSULES:
            const pEntriesCollectionKey = capsule?.collectionKey;
            const pEntriesCollectionName =
                capsule?.collectionName + "_aggregate";
            return {
                collectionName: pEntriesCollectionName,
                collectionKey: pEntriesCollectionKey,
                collectionQuery: getProductCapsuleEntries(
                    pEntriesCollectionName,
                    pEntriesCollectionKey
                ),
            };

        case ALL_PARENT_CAPSULES:
            const theParentCollectionName = capsule?.collectionName;
            const theParentCollectionKey = capsule?.collectionKey;
            const theChildCollectionName = secCapsule?.collectionName;
            const theChildCollectionKey = secCapsule?.collectionKey;
            const collectionCombo = `${theParentCollectionName}_${theChildCollectionName}${combinedCollectionKeySuffix}_aggregate`;
            const apcCollectionQuery = getParentEntries(
                collectionCombo,
                theParentCollectionName,
                theParentCollectionKey,
                theChildCollectionKey
            );
            return {
                combinedCollectionName: collectionCombo,
                parentCollectionName: theParentCollectionName,
                collectionQuery: apcCollectionQuery,
            };

        case ALL_CHILDREN_CAPSULES:
            const ccParentCollectionName = capsule?.collectionName;
            const ccParentCollectionKey = capsule?.collectionKey;
            const ccChildCollectionName = secCapsule?.collectionName;
            const ccChildCollectionKey = secCapsule?.collectionKey;
            const ccCombinedCollectionName = `${ccParentCollectionName}_${ccChildCollectionName}${combinedCollectionKeySuffix}_aggregate`;
            const ccCollectionQuery = getChildrenEntries(
                ccCombinedCollectionName,
                ccParentCollectionKey,
                ccChildCollectionName,
                ccChildCollectionKey
            );
            return {
                combinedCollectionName: ccCombinedCollectionName,
                childCollectionName: ccChildCollectionName,
                collectionQuery: ccCollectionQuery,
            };

        case ADD_SINGLE_CHILD_CAPSULE:
            const parentCollectionName = capsule?.collectionName;
            const parentCollectionKey = capsule?.collectionKey;
            const childCollectionName = secCapsule?.collectionName;
            const childCollectionKey = secCapsule?.collectionKey;
            const combinedCollectionName = `insert_${parentCollectionName}_${childCollectionName}_one`;
            const subAddQuery = singleAddChildCapsuleMutation(
                combinedCollectionName,
                parentCollectionKey,
                childCollectionName,
                childCollectionKey
            );

            return {
                collectionQuery: subAddQuery,
            };

        case ADD_SINGLE_PARENT_CAPSULE:
            const asParentCollectionName = capsule?.collectionName;
            const asParentCollectionKey = capsule?.collectionKey;
            const asChildCollectionName = secCapsule?.collectionName;
            const asChildCollectionKey = secCapsule?.collectionKey;
            const asCombinedCollectionName = `insert_${asParentCollectionName}_${asChildCollectionName}_one`;

            return {
                collectionQuery: singleAddParentCapsuleMutation(
                    asCombinedCollectionName,
                    asParentCollectionKey,
                    asParentCollectionName,
                    asChildCollectionName,
                    asChildCollectionKey
                ),
            };

        case ALL_PARENT_CHILDREN_CAPSULES:
            const pParentCollectionName = capsule?.collectionName;
            const pParentCollectionKey = capsule?.collectionKey;
            const pChildCollectionName = secCapsule?.collectionName;
            const pChildCollectionKey = secCapsule?.collectionKey;
            const pCombinedCollectionName = isParental
                ? `${pChildCollectionName}_${pParentCollectionName}${combinedCollectionKeySuffix}_aggregate`
                : `${pParentCollectionName}_${pChildCollectionName}${combinedCollectionKeySuffix}_aggregate`;

            return {
                collection: pCombinedCollectionName,
                parentCollectionName: pParentCollectionName,
                parentCollectionKey: pParentCollectionKey,
                childCollectionName: pChildCollectionName,
                collectionQuery: getCapsuleSubEntries(
                    pCombinedCollectionName,
                    pParentCollectionName,
                    pChildCollectionName,
                    pParentCollectionKey,
                    pChildCollectionKey,
                    isParental
                ),
            };
        case FLOW_CAPSULES:
            const collectionName = capsule?.collectionName;
            return {
                collectionQuery: getCollectionCapsules(collectionName),
            };

        case CAPSULE_STATUS_COUNT:
            const statusCountCollectionName = capsule?.collectionName;
            const capsuleStatus = capsule?.isEnabled;
            return {
                collectionName: statusCountCollectionName,
                collectionQuery: getCapsuleStatusCount(
                    statusCountCollectionName,
                    capsuleStatus
                ),
            };

        case RELATE_CAPSULES:
            const mainCollectionName = capsule?.collectionName;
            const mainCollectionKey = capsule?.collectionKey;
            const secCollectionName = secCapsule?.collectionName;
            const secCollectionKey = secCapsule?.collectionKey;
            const relationCollectionName = `${mainCollectionName}_${secCollectionName}`;
            const linkCapsuleQuery = relateChildCapsuleMutation(
                relationCollectionName,
                mainCollectionKey,
                secCollectionName,
                secCollectionKey
            );

            return {
                collectionQuery: linkCapsuleQuery,
            };

        case UN_RELATE_CAPSULES:
            const primaryCollectionName = capsule?.collectionName;
            const primaryCollectionKey = capsule?.collectionKey;
            const secondaryCollectionName = secCapsule?.collectionName;
            const secondaryCollectionKey = secCapsule?.collectionKey;
            const relationshipCollectionName = `${primaryCollectionName}_${secondaryCollectionName}`;
            const unRelateCapsuleQuery = unRelateChildCapsuleMutation(
                relationshipCollectionName,
                primaryCollectionName,
                primaryCollectionKey,
                secondaryCollectionName,
                secondaryCollectionKey
            );

            return {
                collectionQuery: unRelateCapsuleQuery,
            };

        case FIND_CAPSULE_ENTRY:
            const collectionKey = capsule?.collectionKey;

            const findCapsuleQuery = findCapsuleEntriesQuery(
                capsule?.collectionName,
                collectionKey
            );

            return {
                collectionQuery: findCapsuleQuery,
            };

        case EDIT_CAPSULE:
            const editCapsuleQuery = editCapsuleMutation(
                capsule?.collectionName,
                capsule?.collectionKey
            );

            return {
                collectionQuery: editCapsuleQuery,
            };
        case CAPSULES_ENTRIES_AGGREGATE:
            const cEntriesCollectionName =
                capsule?.collectionName + "_aggregate";

            const entriesAggregateQuery = capsuleEntriesAggregateQuery(
                capsule?.collectionName
            );

            return {
                collectionName: cEntriesCollectionName,
                collectionQuery: entriesAggregateQuery,
            };

        case RELATED_CAPSULE:
            const pParentCollName = capsule?.collectionName;
            return {
                parentCollectionName: pParentCollName,
                collectionQuery: getCapsuleSubEntry(pParentCollName),
            };
        case CAPSULES_ENTRIES_AGGREGATE_BY_WORKSPACE:
            const capsuleEntriesCollectionName =
                capsule?.collectionName + "_aggregate";

            const entriesAggregateQueryByWorkspace =
                getCapsuleEntriesAggregateQueryByWorkspace(
                    capsule?.collectionName
                );

            return {
                collectionName: capsuleEntriesCollectionName,
                collectionQuery: entriesAggregateQueryByWorkspace,
            };
        default:
            return "";
    }
};

const getCapsuleOffset = (offset, limit) => {
    return offset === 0 ? offset : offset * limit;
};

const getCapsulePaginationParams = (filters) => {
    const { pageSize, currentPage } = filters ? filters : {};
    if (!pageSize || !currentPage) return {};
    const collectionLimit = pageSize;
    const collectionOffset = getCapsuleOffset(currentPage - 1, pageSize);
    return { collectionOffset, collectionLimit };
};

export const getCapsulesCount = async (
    capsule,
    productId,
    refCapsule,
    currentEntryId
) => {
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;
    const { parentCapsuleClass, childCapsuleClass, capsuleAction } =
        rationalizeCapsuleAction(capsule, refCapsule, true);
    const isSingleEntry = currentEntryId ? true : false;
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        parentCapsuleClass,
        capsuleAction,
        childCapsuleClass,
        false,
        isSingleEntry
    );

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                productId: productId,
                workspaceId: workspaceId,
                capsuleId: currentEntryId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const { data } = res;
            if (data) {
                const count = data[collectionName]?.aggregate?.count;
                return count;
            }
        });
};

export const getCapsuleCountBasedOnStatus = async (capsuleClass, isEnabled) => {
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    const { collectionQuery } = genCapsuleConnectionParams(
        { ...capsuleClass, isEnabled },
        CAPSULE_STATUS_COUNT
    );

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId: workspaceId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const { data } = res;
            if (data) {
                const count =
                    data[`${capsuleClass.collectionName}_aggregate`]?.aggregate
                        ?.count;
                return count;
            }
        });
};

export const getCapsuleChildrenEntries = async (
    parentCapsule,
    childCapsule,
    parentCapsuleId
) => {
    const { collectionQuery, combinedCollectionName, childCollectionName } =
        genCapsuleConnectionParams(
            parentCapsule,
            ALL_CHILDREN_CAPSULES,
            childCapsule
        );
    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                // productId: productId,
                // workspaceId: workspaceId,
                parentCapsuleId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const contentsData = res.data[combinedCollectionName];
            if (contentsData) {
                var result = (contentsData?.nodes).map((content) => ({
                    ...content[childCollectionName],
                }));
                return result;
            }
        });
};

export const getCapsuleParentEntries = async (
    parentCapsule,
    childCapsule,
    childCapsuleId
) => {
    const { collectionQuery, combinedCollectionName, parentCollectionName } =
        genCapsuleConnectionParams(
            parentCapsule,
            ALL_PARENT_CAPSULES,
            childCapsule
        );
    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                // productId: productId,
                // workspaceId: workspaceId,
                childCapsuleId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const contentsData = res.data[combinedCollectionName];
            if (contentsData) {
                var result = (contentsData?.nodes).map((content) => ({
                    ...content[parentCollectionName],
                }));
                return result;
            }
        });
};

export const getCapsulesEntries = async (
    capsule,
    filters,
    hasEnabledCapsules,
    capsuleType
) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        { ...capsule, isEnabled: capsuleType },
        hasEnabledCapsules !== undefined
            ? CAPSULES_BASED_ON_STATUS
            : ALL_CAPSULES
    );
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    const { collectionOffset, collectionLimit } =
        getCapsulePaginationParams(filters);

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId: workspaceId,
                limit: collectionLimit,
                offset: collectionOffset,
                search: filters?.search ? filters?.search : "",
                ...filters,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data[collectionName];
            if (data) {
                return {
                    total: data?.aggregate?.count,
                    capsuleEntries: data?.nodes,
                };
            }
        });
};

export const getSingleCapsuleEntry = async (capsule, capsuleEntryId) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        capsule,
        SINGLE_CAPSULE
    );
    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                capsuleEntryId: capsuleEntryId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data[collectionName];
            return data;
        });
};

export const getProductCapsulesEntries = async (
    capsule,
    productId,
    limit,
    offset,
    alphabeticalOrder,
    dateCreatedOrder,
    search
) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        capsule,
        ALL_PRODUCT_CAPSULES
    );
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                productId: productId,
                workspaceId: workspaceId,
                limit,
                offset,
                alphabeticalOrder,
                dateCreatedOrder,
                search: search ? search : "",
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data[collectionName];
            if (data) {
                return {
                    total: data?.aggregate?.count,
                    capsuleEntries: data?.nodes,
                };
            }
        });
};

export const getCapsulesRelatedEntries = async (
    parentCapsule,
    childCapsule,
    productId,
    isParental,
    limit,
    offset,
    alphabeticalOrder,
    dateCreatedOrder,
    search
) => {
    const {
        parentCollectionName,
        parentCollectionKey,
        collection,
        collectionQuery,
    } = genCapsuleConnectionParams(
        parentCapsule,
        ALL_PARENT_CHILDREN_CAPSULES,
        childCapsule,
        isParental
    );
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                productId: productId,
                workspaceId: workspaceId,
                limit,
                offset,
                alphabeticalOrder,
                dateCreatedOrder,
                search: search ? search : "",
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data[parentCollectionName];
            let newData = [];
            data.forEach((item) => {
                let aggregateList = item[collection]?.nodes;
                newData.push({
                    content: item.content,
                    [parentCollectionKey]: item[parentCollectionKey],
                    contenttype: item.contenttype,
                    subCapsules: aggregateList,
                    productid: item.productid,
                });
            });
            return newData;
        });
};

export const addSingleSubCapsuleEntry = async (
    parentCapsuleClass,
    childCapsuleClass,
    request,
    actionType
) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        parentCapsuleClass,
        actionType,
        childCapsuleClass
    );
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .mutate({
            mutation: gql`
                ${collectionQuery}
            `,
            variables: {
                ...request,
                workspaceId,
                contentId: uuidv4(),
            },
        })
        .then((res) => {
            return res?.data;
        });
};

export const addNewCapsuleEntry = async (capsule, request) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        capsule,
        ADD_SINGLE_CAPSULE
    );
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .mutate({
            mutation: gql`
                ${collectionQuery}
            `,
            variables: {
                ...request,
                workspaceId,
                contentId: uuidv4(),
            },
        })
        .then((res) => res.data[collectionName]);
};

export const rationalizeCapsuleAction = (
    mainCapsuleClass,
    secondaryCapsuleClass,
    countCase
) => {
    try {
        const { capsuleClasses } = store.getState().capsule;
        const partialMainCapsuleClassId = mainCapsuleClass?.capsuleClassId;
        const partialSecCapuleClassId = secondaryCapsuleClass?.capsuleClassId;
        const mMainCapsule = capsuleClasses?.find(
            (x) => x.capsuleClassId === partialMainCapsuleClassId
        );
        const mSecondaryCapsuleClass = capsuleClasses?.find(
            (x) => x.capsuleClassId === partialSecCapuleClassId
        );

        const { children, parents } = mMainCapsule || {};
        const isParental = parents?.some(
            (item) => item.capsuleClassId === partialSecCapuleClassId
        );
        const isChild = children?.some(
            (item) => item.capsuleClassId === partialSecCapuleClassId
        );
        if (isParental) {
            // Top Reference is a Parent ---- ADD CHILD
            return {
                parentCapsuleClass: mSecondaryCapsuleClass,
                childCapsuleClass: mMainCapsule,
                capsuleAction: countCase
                    ? CHILDREN_AGGREGATE
                    : capsuleActions?.ADD_SINGLE_CHILD_CAPSULE,
            };
        } else if (isChild) {
            // Top Reference is a Child ---- ADD PARENT
            return {
                parentCapsuleClass: mMainCapsule,
                childCapsuleClass: mSecondaryCapsuleClass,
                capsuleAction: countCase
                    ? PARENT_AGGREGATE
                    : capsuleActions?.ADD_SINGLE_PARENT_CAPSULE,
                capsuleId: "",
            };
        } else {
            return {
                parentCapsuleClass: mMainCapsule,
                capsuleAction: countCase
                    ? SINGLE_AGGREGATE
                    : capsuleActions?.ADD_SINGLE_CAPSULE,
            };
        }
    } catch (err) {
        return {};
    }
};

export const getCollectionCapsulesEntries = async (capsule) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        capsule,
        FLOW_CAPSULES
    );

    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;
    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId: workspaceId,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data;

            if (data) {
                return {
                    total: data?.flow_aggregate?.aggregate?.count,
                    capsuleEntries: data?.flow_aggregate?.nodes,
                };
            }
        });
};

export const relateSubCapsuleEntry = async (
    parentCapsuleClass,
    childCapsuleClass,
    request,
    actionType
) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        parentCapsuleClass,
        actionType,
        childCapsuleClass
    );

    return await client
        .mutate({
            mutation: gql`
                ${collectionQuery}
            `,
            variables: {
                ...request,
            },
        })
        .then((res) => {
            return res?.data;
        });
};

export const findCapsuleEntries = async (capsule, content, productId) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        capsule,
        FIND_CAPSULE_ENTRY
    );

    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId,
                content,
                productId,
            },
        })
        .then((res) => {
            return res?.data[`${capsule?.collectionName}_aggregate`].nodes;
        });
};

export const editCapsule = async (capsule, contentId, content) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        capsule,
        EDIT_CAPSULE
    );

    return await client
        .mutate({
            mutation: gql`
                ${collectionQuery}
            `,
            variables: {
                contentId,
                content,
            },
        })
        .then((res) => {
            return res?.data;
        });
};

export const getCapsuleEntriesAggregate = async (capsule, productId) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        capsule,
        CAPSULES_ENTRIES_AGGREGATE
    );

    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId,
                productId,
            },
        })
        .then((res) => {
            const { data } = res;
            if (data) {
                const count = data[collectionName]?.aggregate?.count;
                return count;
            }
        });
};

export const getCapsuleInfo = async (parentCapsule, productId, capsuleName) => {
    const { parentCollectionName, collectionQuery } =
        genCapsuleConnectionParams(parentCapsule, RELATED_CAPSULE);
    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                productId: productId,
                workspaceId: workspaceId,
                keyword: capsuleName,
            },
            fetchPolicy: "no-cache",
        })
        .then((res) => {
            const data = res.data[parentCollectionName];
            return data[0];
        });
};

export const unRelateSubCapsuleEntry = async (
    parentCapsuleClass,
    childCapsuleClass,
    request
) => {
    const { collectionQuery } = genCapsuleConnectionParams(
        parentCapsuleClass,
        UN_RELATE_CAPSULES,
        childCapsuleClass
    );

    return await client
        .mutate({
            mutation: gql`
                ${collectionQuery}
            `,
            variables: {
                ...request,
            },
        })
        .then((res) => {
            return res?.data;
        });
};

export const getCapsuleEntriesAggregateByWorkspace = async (capsule) => {
    const { collectionName, collectionQuery } = genCapsuleConnectionParams(
        capsule,
        CAPSULES_ENTRIES_AGGREGATE_BY_WORKSPACE
    );

    const {
        workspace: { workspaceId },
    } = store.getState().auth.user;

    return await client
        .query({
            query: gql`
                ${collectionQuery}
            `,
            variables: {
                workspaceId,
            },
        })
        .then((res) => {
            const { data } = res;
            if (data) {
                const count = data[collectionName]?.aggregate?.count;
                return count;
            }
        });
};
