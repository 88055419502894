import FadeIn from "components/ui/effects/FadeIn";
import React, { useState, useEffect } from "react";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import {
    getCapsulesRelatedEntries,
    getProductCapsulesEntries,
    rationalizeCapsuleAction,
    getCapsuleEntriesAggregate,
} from "../../common/Capsule/lib";
import ProductClassesContainer from "./ProductClassesContainer/ProductClassesContainer";
import AddCapsuleEntryModal from "./ProductClassesPlayground/modals/AddCapsuleEntryModal/AddCapsuleEntryModal";
import ProductClassesPlayground from "./ProductClassesPlayground/ProductClassesPlayground";
import EditCapsuleEntryModal from "./ProductClassesPlayground/modals/EditCapsuleEntryModal/EditCapsuleEntryModal";
import DeleteCapsuleConfirmModal from "./ProductClassesPlayground/modals/DeleteCapsuleConfirmModal/DeleteCapsuleConfirmModal";
import {
    saveActivePrimaryClass,
    saveActiveSecondaryClass,
} from "store/capsule/actions";
import { connect } from "react-redux";
import { primaryCapsuleCodes } from "../../common/Capsule/dictionary/capsuleCodes";
import { PROBLEM_CLASSES } from "../enums";
import "./ProductDashboard.scss";

const { SOLUTION } = primaryCapsuleCodes;

const ProductDashboard = ({
    selectedProduct,
    activeSecondaryClass,
    saveActivePrimaryClass,
    saveActiveSecondaryClass,
}) => {
    const [errorMsg, setErrorMsg] = useState();
    const [capsuleRenderCount, updateCapsuleRenderCount] = useState(1);
    const [additionRequestObj, updateAdditionRequestObj] = useState();
    const [currentCapsuleAction, setCurrentCapsuleAction] = useState("");
    const [currentCapsuleClass, setCurrentCapsuleClass] = useState();
    const [showSubCapsuleModal, toggleSubCapsuleModal] = useState(false);
    const [allCapsulesEntries, updateCapsuleList] = useState();
    const [showPlayground, togglePlayGround] = useState(false);
    const [playGroundStatus, updatePlaygroundStatus] = useState();
    const [selectedCapsuleClass, setSelectedCapsuleClass] = useState({});
    const [selectedSecCapsuleClass, setSelectedSecCapsuleClass] = useState();
    const [showEditCapsuleModal, toggleEditCapsuleModal] = useState(false);
    const [showDeleteCapsuleModal, toggleDeleteCapsuleModal] = useState(false);
    const [deleteRequestObj, updateDeleteRequestObj] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, updatePageFilters] = useState({
        currentPage: 1,
        pageSize: 10,
        offset: 0,
        alphabeticalOrder: null,
        dateCreatedOrder: null,
        search: "",
    });

    const [existingSubCapsules, setExistingSubCapsule] = useState();
    const [showParentCapsuleModal, toggleParentCapsuleModal] = useState(false);
    const [useStoreValue, setUseStoreValue] = useState(true);

    const handleMainCapsuleSelect = async (capsule) => {
        try {
            setSelectedCapsuleClass(capsule);
            saveActivePrimaryClass(capsule);
            setSelectedSecCapsuleClass();
            saveActiveSecondaryClass(null);
            updatePlaygroundStatus(dataQueryStatus?.LOADING);
            setErrorMsg();
            togglePlayGround(true);
            const initialSelectedSecondaryCapsule = [
                ...capsule.parents,
                ...capsule.children,
            ];

            const { capsuleEntries } = await getProductCapsulesEntries(
                capsule,
                selectedProduct?.value,
                filters?.pageSize,
                filters?.offset,
                filters?.alphabeticalOrder,
                filters?.dateCreatedOrder,
                filters?.search
            );

            const total = await getCapsuleEntriesAggregate(
                capsule,
                selectedProduct?.value
            );

            if (initialSelectedSecondaryCapsule?.length > 0) {
                const defaultSecCapsule = useStoreValue
                    ? Object?.keys(activeSecondaryClass)?.length > 0
                        ? activeSecondaryClass
                        : initialSelectedSecondaryCapsule?.[0]
                    : initialSelectedSecondaryCapsule?.[0];
                handleSecondaryCapsuleSelect(defaultSecCapsule, capsule);
            }

            setTotalRecords(total);
            updateCapsuleList(capsuleEntries);
            updatePlaygroundStatus(dataQueryStatus?.DATAMODE);
            setUseStoreValue(false);
        } catch (err) {
            
            updatePlaygroundStatus(dataQueryStatus?.ERROR);
            setErrorMsg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (Object.entries(selectedCapsuleClass).length > 0) {
            handleMainCapsuleSelect(selectedCapsuleClass);
        }
        // eslint-disable-next-line
    }, [filters]);

    const handleSecondaryCapsuleSelect = async (
        capsule,
        primarySelectedCapsule
    ) => {
        try {
            setSelectedSecCapsuleClass(capsule);
            saveActiveSecondaryClass(capsule);
            updatePlaygroundStatus(dataQueryStatus?.LOADING);
            const primarySelected = primarySelectedCapsule
                ? primarySelectedCapsule
                : selectedCapsuleClass;
            const isParental = primarySelected?.parents?.some(
                (item) => item.capsuleClassId === capsule?.capsuleClassId
            );
            setErrorMsg();
            togglePlayGround(true);
            // await scrollToPlayGround()

            const capsuleEntries = await getCapsulesRelatedEntries(
                primarySelected,
                capsule,
                selectedProduct?.value,
                isParental,
                filters?.pageSize,
                filters?.offset,
                filters?.alphabeticalOrder,
                filters?.dateCreatedOrder,
                filters?.search
            );

            updateCapsuleList(capsuleEntries);
            updatePlaygroundStatus(dataQueryStatus?.DATAMODE);
        } catch (err) {
            
            updatePlaygroundStatus(dataQueryStatus?.ERROR);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleToggleParentAddModal = (capsuleAction) => {
        setCurrentCapsuleAction(capsuleAction);
        toggleParentCapsuleModal(true);
    };

    const handleToggleAddModal = async (
        mainCapsuleClass,
        refCapsuleClass,
        refCapsuleId,
        subCapsules,
        content,
        allSubCapsules
    ) => {
        const requestData =
            (await rationalizeCapsuleAction(
                mainCapsuleClass,
                refCapsuleClass
            )) || {};

        setCurrentCapsuleAction(requestData?.capsuleAction);
        updateAdditionRequestObj({
            ...requestData,
            capsuleId: refCapsuleId,
            content,
        });
        setCurrentCapsuleClass(mainCapsuleClass);
        // (selectedSecCapsuleClass?.capsuleCode === SOLUTION &&
        //     Object.keys(PROBLEM_CLASSES).includes(
        //         selectedCapsuleClass?.capsuleCode
        //     )) ||
        const existingCapsules =
            selectedCapsuleClass?.capsuleCode === SOLUTION &&
            Object.keys(PROBLEM_CLASSES).includes(
                selectedSecCapsuleClass?.capsuleCode
            )
                ? allSubCapsules
                : subCapsules;
        setExistingSubCapsule(existingCapsules);

        toggleSubCapsuleModal(true);
    };

    const handleToggleEditModal = (
        mainCapsuleClass,
        contentId,
        content,
        capsuleAction,
        hasChildren
    ) => {
        setCurrentCapsuleAction(capsuleAction);
        updateAdditionRequestObj({ contentId, content, hasChildren });
        setCurrentCapsuleClass(mainCapsuleClass);
        toggleEditCapsuleModal(true);
    };

    const handleToggleDeleteModal = (
        capsule,
        content,
        contentId,
        hasChildren,
        refCapsuleId,
        mainCapsuleClass,
        refCapsuleClass
    ) => {
        const requestData =
            rationalizeCapsuleAction(mainCapsuleClass, refCapsuleClass) || {};

        updateDeleteRequestObj({
            ...requestData,
            content,
            contentId,
            refCapsuleId,
            hasChildren,
        });
        setCurrentCapsuleClass(capsule);
        toggleDeleteCapsuleModal(true);
    };

    const handleSearchEntries = (searchKeyword) => {
        updatePageFilters({ ...filters, search: searchKeyword });
    };

    return (
        <>
            <div className='product__dashboard meta__container'>
                <div>
                    <ProductClassesContainer
                        selectedProduct={selectedProduct}
                        selectedCapsuleClass={selectedCapsuleClass}
                        selectedSecCapsuleClass={selectedSecCapsuleClass}
                        setSelectedCapsuleClass={handleMainCapsuleSelect}
                        setSelectedSecCapsuleClass={
                            handleSecondaryCapsuleSelect
                        }
                        capsuleRenderCount={capsuleRenderCount}
                    />
                    {showPlayground && (
                        <section id='playGround'>
                            <FadeIn>
                                <ProductClassesPlayground
                                    playGroundStatus={playGroundStatus}
                                    selectedProduct={selectedProduct}
                                    fetchError={errorMsg}
                                    selectedCapsuleClass={selectedCapsuleClass}
                                    selectedSecCapsuleClass={
                                        selectedSecCapsuleClass
                                    }
                                    allCapsulesEntries={allCapsulesEntries}
                                    toggleSubCapsuleModal={handleToggleAddModal}
                                    handleToggleEditModal={
                                        handleToggleEditModal
                                    }
                                    handleToggleDeleteModal={
                                        handleToggleDeleteModal
                                    }
                                    totalRecords={totalRecords}
                                    filters={filters}
                                    updatePageFilters={updatePageFilters}
                                    handleSearchEntries={handleSearchEntries}
                                    toggleParentCapsuleModal={
                                        handleToggleParentAddModal
                                    }
                                />
                            </FadeIn>
                        </section>
                    )}
                </div>
            </div>

            {showParentCapsuleModal && (
                <AddCapsuleEntryModal
                    showModal={showParentCapsuleModal}
                    closeModal={() => toggleParentCapsuleModal(false)}
                    selectedProduct={selectedProduct}
                    mainCapsuleClass={selectedCapsuleClass}
                    capsuleAction={currentCapsuleAction}
                    handleCapsulationSuccess={(capsule) => {
                        handleMainCapsuleSelect(capsule);
                        updateCapsuleRenderCount(capsuleRenderCount + 2);
                        toggleParentCapsuleModal(false);
                    }}
                />
            )}

            {showSubCapsuleModal && currentCapsuleClass && (
                <AddCapsuleEntryModal
                    showModal={showSubCapsuleModal}
                    closeModal={() => toggleSubCapsuleModal(false)}
                    selectedProduct={selectedProduct}
                    mainCapsuleClass={currentCapsuleClass}
                    capsuleAction={currentCapsuleAction}
                    additionRequestObj={additionRequestObj}
                    handleCapsulationSuccess={(secCapsule) => {
                        updateCapsuleRenderCount(capsuleRenderCount + 2);
                        handleSecondaryCapsuleSelect(secCapsule);
                        toggleSubCapsuleModal(false);
                    }}
                    setCurrentCapsuleAction={setCurrentCapsuleAction}
                    selectedCapsuleClass={selectedCapsuleClass}
                    selectedSecCapsuleClass={selectedSecCapsuleClass}
                    existingSubCapsules={existingSubCapsules}
                />
            )}

            {showEditCapsuleModal && (
                <EditCapsuleEntryModal
                    showModal={showEditCapsuleModal}
                    closeModal={() => toggleEditCapsuleModal(false)}
                    mainCapsuleClass={currentCapsuleClass}
                    capsuleAction={currentCapsuleAction}
                    additionRequestObj={additionRequestObj}
                    selectedProduct={selectedProduct}
                    handleCapsulationSuccess={(capsule) => {
                        updateCapsuleRenderCount(capsuleRenderCount + 2);
                        additionRequestObj?.hasChildren
                            ? handleSecondaryCapsuleSelect(capsule)
                            : handleMainCapsuleSelect(capsule);
                        toggleEditCapsuleModal(false);
                    }}
                />
            )}

            {showDeleteCapsuleModal && (
                <DeleteCapsuleConfirmModal
                    showModal={showDeleteCapsuleModal}
                    closeModal={() => toggleDeleteCapsuleModal(false)}
                    capsule={currentCapsuleClass}
                    deleteRequestObj={deleteRequestObj}
                    handleSuccess={(capsule) => {
                        updateCapsuleRenderCount(capsuleRenderCount + 2);
                        deleteRequestObj?.hasChildren
                            ? handleSecondaryCapsuleSelect(capsule)
                            : handleMainCapsuleSelect(capsule);
                        toggleDeleteCapsuleModal(false);
                    }}
                />
            )}
        </>
    );
};

export default connect(
    (state) => ({
        activeSecondaryClass: state?.capsule?.activeSecondaryClass,
    }),
    { saveActivePrimaryClass, saveActiveSecondaryClass }
)(ProductDashboard);
