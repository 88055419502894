import React, { useState } from "react";
import { Table } from "components/ui";
import IssuesTableDropdown from "./IssuesTableDropdown/IssuesTableDropdown";
import IssueEmbeds from "./IssueEmbeds/IssueEmbeds";
import IssuesModal from "../IssuesModal/IssuesModal";
import "./IssuesTable.scss";

const IssuesRow = ({ issues, tableIsLoading, errorMssg, handleSuccess }) => {
    const [currentIssue, setCurrentIssue] = useState();
    const [currentAction, setCurrentAction] = useState("");

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        setCurrentAction("");
        setCurrentIssue();
    };

    const handleActionModal = (actionType, value) => {
        setCurrentAction(actionType);
        setCurrentIssue(value);
        setShowModal(true);
    };

    const headers = ["Issues name", "Embeds", "Actions"];
    const dataSource = issues?.map((issue) => ({
        IssuesName: `${issue.issueName}`,
        embeds: <IssueEmbeds elements={issue?.elements} />,
        Actions: (
            <div className='table__action'>
                <IssuesTableDropdown
                    issue={issue}
                    handleActionModal={handleActionModal}
                />
            </div>
        ),
    }));

    return (
        <>
            <Table
                headers={headers}
                dataSource={dataSource}
                isLoading={tableIsLoading}
                hasErrors={Boolean(errorMssg)}
                tableError={errorMssg}
            />

            {showModal && (
                <IssuesModal
                    {...{
                        currentAction,
                        currentIssue,
                        closeModal,
                        showModal,
                        handleSuccess,
                    }}
                />
            )}
        </>
    );
};

export default IssuesRow;
