import { Dropdown } from "components/ui";
import { AGENT_GUIDE_ACTIONS } from "../../enum";
import { baseResourceActions, baseResources } from "routes/enum";
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const { USER_MANAGEMENT_ROLES } = baseResources;
const { UPDATE, DELETE, READ } = baseResourceActions;

const AgentGuidesTableActions = ({
    handleActionModal,
    agentGuideId,
    agentGuide,
}) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, agentGuide)}
            options={[
                {
                    value: AGENT_GUIDE_ACTIONS.VIEW_GUIDE,
                    label: "View Guide",
                    baseResources: [`${READ}_${USER_MANAGEMENT_ROLES}`],
                },
                {
                    value: AGENT_GUIDE_ACTIONS.EDIT_GUIDE,
                    label: "Edit Guide",
                    baseResources: [`${UPDATE}_${USER_MANAGEMENT_ROLES}`],
                },
                {
                    value: AGENT_GUIDE_ACTIONS.DELETE_GUIDE,
                    label: "Delete Guide",
                    baseResources: [`${DELETE}_${USER_MANAGEMENT_ROLES}`],
                },
            ]}
        />
    );
};

export default AgentGuidesTableActions;
