import AddButton from "components/ui/Button/AddButton/AddButton";
import Heading from "components/ui/Heading/Heading";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";

import { SCENARIO_ACTIONS } from "../enum";

const { CREATE_SCENARIO } = SCENARIO_ACTIONS;

const ScenariosHeader = ({
    handleActionModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
    defaultValue,
}) => {
    const handleCreateScenario = () => {
        handleActionModal(CREATE_SCENARIO);
    };

    return (
        <>
            <div className='agentaguides__heading'>
                <Heading
                    headingText={"Scenarios"}
                    headingSubText={"Add & change scenarios"}
                />
                <div className='agentaguides__heading--filter'>
                    <PaginationSearch
                        {...{
                            handleSearch,
                            searchIsLoading,
                            clearSearch,
                            defaultValue,
                        }}
                    />
                    <AddButton
                        btnText={"Create New Scenario"}
                        onClick={handleCreateScenario}
                    />
                </div>
            </div>
        </>
    );
};

export default ScenariosHeader;
