import { Table } from "components/ui";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import CapsuleContentElement from "../../../../ProductSetupContainer/ProductDashboard/ProductClassesPlayground/ClassesPlaygroundTable/CapsuleContentElement/CapsuleContentElement";
import SolutionCollection from "./SolutionCollection/SolutionCollection";
import { useState } from "react";
import SCollectionActionsModal from "./modals/SCollectionActionsModal/SCollectionActionsModal";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import "./SolutionSetupViewTable.scss";

const SolutionSetupViewTable = ({
    capsulesEntries,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    selectedProduct,
    solutionCollections,
    handleCollectionDelete,
    totalRecords,
    filters,
    updatePageFilters,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [currentReference, setCurrentReference] = useState({});
    const [currentAction, setCurrentAction] = useState();

    const handleRowAction = (actionType, data) => {
        toggleModal(true);
        setCurrentAction(actionType);
        setCurrentReference(data);
    };

    const handlePageChange = (currentPage) => {
        updatePageFilters({
            ...filters,
            currentPage,
            offset: (currentPage - 1) * filters.pageSize,
        });
    };

    const tableHeaders = [
        <Capsule
            isActive={true}
            capsule={selectedCapsuleClass}
            showCount={true}
            productId={selectedProduct?.value}
            useDefaultAggregate={true}
        />,
        <Capsule
            isActive={true}
            useDefaultAggregate={true}
            showCount={true}
            productId={selectedProduct?.value}
            capsule={selectedSecCapsuleClass}
            secondaryCapsuleClass={selectedCapsuleClass}
        />,
        <div className='text-right'>Solutions</div>,
    ];

    const tableData = capsulesEntries?.map((capsuleContent) => {
        const solutionCapsule = capsuleContent?.subCapsules[0]?.solution;
        const solutionCapsuleEntryId = solutionCapsule?.solutionid;
        const solutionCollection = solutionCollections?.find(
            (solnCollection) =>
                solnCollection?.classCapsuleEntryId === solutionCapsuleEntryId
        );

        return {
            capsuleClass: (
                <p className='element__content'>{capsuleContent?.content}</p>
            ),
            relatedCapsuleClass: (
                <div className='soln__content soln-related-classes'>
                    {capsuleContent?.subCapsules?.map((subCapsule, i) => (
                        <CapsuleContentElement
                            key={i}
                            hideEditAction={true}
                            hideDeleteAction={true}
                            capsule={selectedSecCapsuleClass}
                            element={
                                subCapsule[
                                    selectedSecCapsuleClass?.collectionName
                                ]
                            }
                            hasContent={true}
                        />
                    ))}
                </div>
            ),
            solution: (
                <>
                    {solutionCapsuleEntryId && (
                        <SolutionCollection
                            solutionCapsuleEntryId={solutionCapsuleEntryId}
                            solutionCollection={solutionCollection}
                            capsuleClass={selectedSecCapsuleClass}
                            handleRowAction={handleRowAction}
                            capsuleContent={solutionCapsule?.content}
                        />
                    )}
                </>
            ),
        };
    });

    return (
        <>
            <Table headers={tableHeaders} dataSource={tableData} />
            <CustomPagination
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
                {...filters}
            />
            {showModal && (
                <SCollectionActionsModal
                    show={showModal}
                    closeModal={() => toggleModal(false)}
                    referenceData={currentReference}
                    handleCollectionDelete={handleCollectionDelete}
                    modalAction={currentAction}
                />
            )}
        </>
    );
};

export default SolutionSetupViewTable;
