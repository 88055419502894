import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GeneralLayout, SuccessScreen } from "components/ui";
import { useHistory } from "react-router-dom";
import OTP from "../OTP/OTP";
import FadeIn from "components/ui/effects/FadeIn";
import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import { setAccessToken } from "storage/cookieStorage";
import { updateAuthInfo } from "store/auth/actions";
import { authAdminPath } from "pages/PublicPages/SignIn/SignInForm/SignInForm";

const VerifyEmail = ({ location }) => {
    const [step, setStep] = useState(0);
    const [user, setUser] = useState({});
    const [showView, toggleView] = useState(false);
    const [auth, setAuth] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        retrieveAvailableUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveAvailableUser = () => {
        const sessionUser = location?.state?.user;
        if (sessionUser) {
            setUser(sessionUser);
            toggleView(true);
        } else {
            history.replace("/sign-up");
        }
    };

    const handleProceed = () => {
        const { user, token } = auth || {};
        setAccessToken(token);
        dispatch(updateAuthInfo(user));
        history.replace(authAdminPath);
    };

    const renderBasedOnStep = () => {
        switch (step) {
            case 0:
                return <OTP setStep={setStep} user={user} setAuth={setAuth} />;

            case 1:
                return (
                    <SuccessScreen
                        title='Email Verified'
                        paragraph='Your email has been verified successfully, please click the button below to access your dashboard'
                        btnText='Proceed'
                        handleProceed={handleProceed}
                    />
                );

            default:
                return <OTP setStep={setStep} user={user} setAuth={setAuth} />;
        }
    };

    if (!showView) return "";

    return (
        <>
            <SEO title={"verify-email"} />
            <GeneralLayout>
                <div key={step}>
                    <FadeIn pkey={step}>{renderBasedOnStep()}</FadeIn>
                </div>
            </GeneralLayout>
        </>
    );
};

export default VerifyEmail;
