import { Table } from "components/ui";
import { Link } from "react-router-dom";
import { SmartTime } from "utils/helper";
import { ticketResolutionStatuses } from "../../../enum";
import "./AnalyticsTSamConvoTDropdown.scss";

const AnalyticsTSamConvoTDropdown = ({
    data: recentConversations,
    conversationId,
    issueName,
}) => {
    let sortedConvos = recentConversations
        ?.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        })
        .filter((item, index) => index < 5);

    const tableData = sortedConvos?.map(
        ({ ticketSourceMessage, createdDate, ticketResolutionStatus }) => ({
            query: ticketSourceMessage === null ? "-" : ticketSourceMessage,
            time: SmartTime(createdDate),
            status: (
                <span className={`analytics-tsam-${ticketResolutionStatus}`}>
                    {ticketResolutionStatuses[ticketResolutionStatus]}
                </span>
            ),
        })
    );

    return (
        <>
            <div className='analytics-tsam-convo-table-dropdown'>
                <Table
                    headers={["Recent user queries", "Time", "Status"]}
                    dataSource={tableData}
                />
                <div className='analytics-tsam-convo-link'>
                    <Link
                        to={{
                            pathname: `/analytics/t-sam/conversation/${conversationId}/activity`,
                            state: {
                                issueName,
                            },
                        }}>
                        See more queries
                    </Link>
                </div>
            </div>
        </>
    );
};

export default AnalyticsTSamConvoTDropdown;
