import { Table } from "components/ui";
import AnalyticsQAVAConvoSuggsDropdown from "./AnalyticsQAVAConvoSuggsDropdown/AnalyticsQAVAConvoSuggsDropdown";
import "./AnalyticsQAVAConvoSuggs.scss";

const AnalyticsQAVAConvoSuggs = ({
    conversations,
    useTableLoader,
    filterParams,
    convoCounts,
}) => {
    const { search } = filterParams || {};

    const calculateSuggestionRate = (count) => {
        const { timeBasedNumberOfSuggestions } = convoCounts;
        return (
            (Number(count) / timeBasedNumberOfSuggestions) *
            100
        )?.toFixed?.(2);
    };

    const tableData = conversations
        ?.filter?.(({ issue }) =>
            issue?.issueName?.toLowerCase()?.includes(search?.toLowerCase())
        )
        ?.map(
            ({ issue, ticketMessageRecords, count, ticketConversationId }) => {
                return {
                    title: issue?.issueName,
                    queries: count,
                    rate: `${calculateSuggestionRate(count)}%`,
                    dropdownElement: (
                        <AnalyticsQAVAConvoSuggsDropdown
                            ticketMessageRecords={ticketMessageRecords}
                            ticketConversationId={ticketConversationId}
                        />
                    ),
                };
            }
        );

    return (
        <div className='analytcis-qava-convo-suggs'>
            <Table
                headers={[
                    "Conversation title suggested",
                    "User Queries",
                    "Suggestion rate",
                ]}
                isAccordion={true}
                dataSource={tableData}
                isLoading={useTableLoader}
            />
        </div>
    );
};

export default AnalyticsQAVAConvoSuggs;
