import { Input } from "components/ui";
import React from "react";

const FormBuilderInputLabel = ({
    elementId,
    errors,
    handleFormInputChange,
    elementLabel,
}) => {
    return (
        <div className='input__label'>
            <Input
                name={`elementLabel`}
                placeholder={`Enter main instruction here`}
                onChange={({ target: { name, value } }) =>
                    handleFormInputChange(elementId, name, value)
                }
                isErr={Boolean(errors?.[elementId]?.elementLabel)}
                errMssg={errors?.[elementId]?.elementLabel}
                value={elementLabel}
            />
        </div>
    );
};

export default FormBuilderInputLabel;
