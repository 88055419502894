import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import EmailContentGroup from "./EmailContentGroup/EmailContentGroup";
// import EmailPreviewModal from "./modal/EmailPreviewModal/EmailPreviewModal";
import EmailHolder from "./EmailContentGroup/SendEmail/EmailInput/EmailHolder/EmailHolder";
import "./EmailPreview.scss";

// const { EXTERNAL_EMAILS } = otherCategories;

const EmailPreview = ({
    toggleEmailPreview,
    ticketId,
    emailPreviewValue,
    ticketData,
    emailAction,
    setSelectedTicketHeader,
    attachments,
    toggleClipboardAndAttachment,
    handleToggleEmailAttachment,
    handleSuccess,
}) => {

    const [senderEmailData, setSenderEmailData] = useState({});
    const { isNewEmail, externalEmail, senderEmail } = senderEmailData;

    
    return (
        <div className='email__preview'>
            <div className='email__preview__nav'>
                <div onClick={() => toggleEmailPreview(false)} className='back'>
                    <ReactSVG
                        src={imageLinks?.icons?.tinyArrowLeft}
                        className='back__arrow'
                    />{" "}
                    <span className='back__text'>Go back</span>
                </div>
                <div>
                    <EmailHolder
                        isSenderEmail={true}
                        email={isNewEmail ? externalEmail : senderEmail}
                        disabled={true}
                    />
                </div>
            </div>

            <div className='email__preview__content'>
                <div className='email__container'>
                    <EmailContentGroup
                        ticketId={ticketId}
                        emailPreviewValue={emailPreviewValue}
                        ticketData={ticketData}
                        emailAction={emailAction}
                        toggleEmailPreview={toggleEmailPreview}
                        attachments={attachments}
                        handleSuccess={handleSuccess}
                        setSenderEmailData={setSenderEmailData}
                        {...{
                            toggleClipboardAndAttachment,
                            handleToggleEmailAttachment
                        }}
                    />

                </div>

            </div>

        </div>
    );
};

export default EmailPreview;
