import React, { useEffect, useState } from "react";
import {
    ticketPhases,
    otherCategories,
    AGENT_GUIDE,
} from "components/dashboard/AgentInbox/enum";
import TicketDetailHeader from "./TicketDetailHeader/TicketDetailHeader";
import TMessageTagSuggestion from "./TMessageTagSuggestion/TMessageTagSuggestion";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import TicketInfoDetails from "./TicketInfoDetails/TicketInfoDetails";
import TicketPostFormCollection from "./TicketPostFormCollection/TicketPostFormCollection";
import { useSelector } from "react-redux";
import TicketMentions from "./TicketMentions/TicketMentions";
import Followup from "./Followup/Followup";
import ExternalEmails from "./ExternalEmails/ExternalEmails";
import AddTicketInfoModal from "./TicketInfoDetails/TicketInfoDetailsElement/TicketInfoList/AddTicketInfoModal/AddTicketInfoModal";
import TicketCannedResponses from "./TicketCannedResponses/TicketCannedResponses";
import TicketAgentAssistant from "./TicketAgentAssistant/TicketAgentAssistant";
import ProposeScenarioModal from "./TicketAgentAssistant/TicketAAScenarios/modal/ProposeScenarioModal/ProposeScenarioModal";
import EditTicketAATaskModal from "./TicketAgentAssistant/EditTicketAATaskModal/EditTicketAATaskModal";
import "./TicketDetail.scss";

const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;

const { FOLLOW_UP, MENTIONS, EXTERNAL_EMAILS, AGENT_ASSISTANCE } =
    otherCategories;

const TicketDetail = ({
    recentCustomerMessage,
    acceptMessageSuggestion,
    fetchAllTickets,
    setCannedMessage,
    handleToggleEmailPreview,
    handleToggleEmailAttachment,
    selectedTicketHeader,
    setSelectedTicketHeader,
    refetchEmails,
    selectedCannedEmail,
    selectedEmailData,
    investigativeCustomerMessage,
    fetchMessagesStatus,
    selectedCustomerMessage,
    showClipboard,
    toggleEmailClipboard,
    attachments
}) => {
    const [showModal, toggleModal] = useState(false);
    const [showScenarioModal, toggleScenarioModal] = useState(false);
    const [selectedTicketTask, selectTicketTask] = useState({});
    const [showEditTicketTaskModal, toggleEditTicketTaskModal] =
        useState(false);
    const {
        ticketPhase: selectedTicketPhase,
        isMentioned,
        followUpActive,
        otherFilter,
    } = useSelector((state) => state?.ticketsFilters?.ticketsfilterRequest);
    // const [selectedTicketHeader, setSelectedTicketHeader] = useState();

    const { activeTicket: selectedTicket } = useSelector(
        (state) => state.tickets
    );

    const [refetchTicketInfo, setRefetchTicketInfo] = useState(false);
    const [existingTicketInfos, setExistingTicketInfo] = useState([]);
    const [refreshAgentAssistant, setRefreshAgentAssistant] = useState(false);
    const [selectedAgentGuide, selectAgentGuide] = useState({});
    
    useEffect(() => {
        setSelectedTicketHeader(
            (otherFilter === AGENT_GUIDE ||
                selectedTicketPhase === SOLUTION_DELIVERY)
                ? AGENT_ASSISTANCE
                : (selectedTicketPhase === ISSUE_DISCOVERY ? ISSUE_DISCOVERY : (isMentioned
                    ? MENTIONS
                    : followUpActive
                        ? FOLLOW_UP
                        : selectedTicketPhase))
        );
        // eslint-disable-next-line
    }, [selectedTicketPhase, isMentioned, followUpActive, otherFilter]);

    const handleSelectedTicketHeader = (ticketHeader) => {
        const newSelectedTicketHeader =
            selectedTicketHeader === ticketHeader ? undefined : ticketHeader;
        setSelectedTicketHeader(newSelectedTicketHeader);
    };

    const ticketDetailHeader = selectedTicketHeader;

    const handleToggleTicketInfoModal = () => {
        setRefetchTicketInfo(false);
        toggleModal(!showModal);
    };

    const handleToggleScenarioModal = () => {
        toggleScenarioModal(!showScenarioModal);
    };

    const handleToggleEditTicketTaskModal = (task) => {
        selectTicketTask(task);
        toggleEditTicketTaskModal(!showEditTicketTaskModal);
    };

    const renderBasedOnPhase = () => {
        switch (ticketDetailHeader) {
            case ISSUE_DISCOVERY:
                return (
                    <TMessageTagSuggestion
                        selectedTicket={selectedTicket}
                        recentCustomerMessage={recentCustomerMessage}
                        acceptMessageSuggestion={acceptMessageSuggestion}
                        setCannedMessage={setCannedMessage}
                    />
                );

            case PROBLEM_CONFIRMATION:
                return (
                    <TicketInfoDetails
                        selectedTicket={selectedTicket}
                        handleToggleTicketInfoModal={
                            handleToggleTicketInfoModal
                        }
                        setExistingTicketInfo={setExistingTicketInfo}
                        refetchTicketInfo={refetchTicketInfo}
                        setRefetchTicketInfo={setRefetchTicketInfo}
                    />
                );

            case SOLUTION_DELIVERY:
                return (
                    <>
                        <TicketPostFormCollection
                            selectedTicket={selectedTicket}
                        />
                        <TicketCannedResponses
                            setCannedMessage={setCannedMessage}
                            selectedTicket={selectedTicket}
                        />
                    </>
                );

            case FOLLOW_UP:
                return (
                    <Followup
                        selectedTicket={selectedTicket}
                        followUpActive={followUpActive}
                        fetchAllTickets={fetchAllTickets}
                        setCannedMessage={setCannedMessage}
                        handleToggleEmailPreview={handleToggleEmailPreview}
                        refetchEmails={refetchEmails}
                    />
                );

            case EXTERNAL_EMAILS:
                return (
                    <ExternalEmails
                        showClipboard={showClipboard}
                        attachments={attachments}
                        toggleEmailClipboard={toggleEmailClipboard}
                        selectedTicket={selectedTicket}
                        handleToggleEmailPreview={handleToggleEmailPreview}
                        handleToggleEmailAttachment={handleToggleEmailAttachment}
                        refetchEmails={refetchEmails}
                        selectedCannedEmail={selectedCannedEmail}
                        selectedEmailData={selectedEmailData}
                    />
                );

            case MENTIONS:
                return (
                    <TicketMentions
                        selectedTicket={selectedTicket}
                        fetchAllTickets={fetchAllTickets}
                    />
                );

            case AGENT_ASSISTANCE:
                return (
                    <TicketAgentAssistant
                        selectedTicket={selectedTicket}
                        handleToggleScenarioModal={handleToggleScenarioModal}
                        handleToggleEditTicketTaskModal={
                            handleToggleEditTicketTaskModal
                        }
                        refreshAgentAssistant={refreshAgentAssistant}
                        handleToggleEmailPreview={handleToggleEmailPreview}
                        selectAgentGuide={selectAgentGuide}
                        investigativeCustomerMessage={
                            investigativeCustomerMessage
                        }
                        fetchMessagesStatus={fetchMessagesStatus}
                        selectedCustomerMessage={selectedCustomerMessage}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='ticket__detail relative__layout h-100'>
            <TicketDetailHeader
                selectedTicketHeader={ticketDetailHeader}
                selectedTicketPhase={selectedTicketPhase}
                handleSelectedTicketHeader={handleSelectedTicketHeader}
                selectedTicket={selectedTicket}
            />
            <div
                className='ticket__detailed custom-scroll-bar'
                id={"ticketsDetails"}>
                <div className='ticket__detailed--container'>
                    {selectedTicket ? (
                        <>{renderBasedOnPhase()}</>
                    ) : (
                        <EmptyInfo
                            otherClassNames={"with-margin"}
                            message={`No conversation started yet,\n click on a tag above to begin`}
                        />
                    )}
                </div>
            </div>

            {showModal && (
                <AddTicketInfoModal
                    showModal={showModal}
                    closeModal={() => toggleModal(!showModal)}
                    ticketId={selectedTicket?.ticketId}
                    existingTicketInfos={existingTicketInfos}
                    handleSuccess={() => {
                        setRefetchTicketInfo(true);
                        toggleModal(!showModal);
                    }}
                />
            )}

            {showEditTicketTaskModal && (
                <EditTicketAATaskModal
                    showModal={showEditTicketTaskModal}
                    closeModal={() => toggleEditTicketTaskModal(false)}
                    selectedTicketTask={selectedTicketTask}
                    handleSuccess={() =>
                        setRefreshAgentAssistant(!refreshAgentAssistant)
                    }
                />
            )}

            {showScenarioModal && (
                <ProposeScenarioModal
                    showModal={showScenarioModal}
                    closeModal={() => toggleScenarioModal(false)}
                    recentCustomerMessage={recentCustomerMessage}
                    handleSuccess={() =>
                        setRefreshAgentAssistant(!refreshAgentAssistant)
                    }
                    selectedAgentGuide={selectedAgentGuide}
                />
            )}
        </div>
    );
};

export default TicketDetail;
