import React from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { capsuleActions } from "components/dashboard/KnowledgeBase/common/Capsule/lib";

const AddCapsuleEntryButton = ({
    selectedCapsuleClass,
    toggleParentCapsuleModal,
}) => {
    return (
        <>
            <AddButton
                btnText={`Add New ${selectedCapsuleClass?.capsuleClassName}`}
                onClick={() =>
                    toggleParentCapsuleModal(capsuleActions?.ADD_SINGLE_CAPSULE)
                }
                otherClassName={`justify-content-end`}
            />
        </>
    );
};

export default AddCapsuleEntryButton;
