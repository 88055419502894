import { GENERAL_DOWNTIME_COLLECTION } from 'components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums';
import React from 'react';
import DowntimeBranchesView from '../../SAM/DowntimeBranchesView/DowntimeBranchesView';

const GeneralDowntimeCollection = () => {
    return (
        <div className='py-5'>
            <DowntimeBranchesView parentSource={GENERAL_DOWNTIME_COLLECTION}/>
        </div>
    );
};

export default GeneralDowntimeCollection;