import SelectUI from "components/ui/SelectUI/SelectUI";
import { SELECT_GUIDE_STAGES } from "../enum";

const { EXISTING_GUIDE, NEW_GUIDE, CHOOSE_STAGE } = SELECT_GUIDE_STAGES;

const CreateGuideFormChoice = ({
    errors,
    handleSelectGuide,
    guideList,
    setSelectGuideStage,
    selectGuideStage,
    fetchingGuides,
}) => {
    return (
        <>
            {selectGuideStage === CHOOSE_STAGE && (
                <SelectUI
                    grpClass='mb-3'
                    name=''
                    label=''
                    labelClass='text-dark fw-medium'
                    placeholder='How do you want to add guide?'
                    options={[
                        {
                            label: "Choose from Existing Guides",
                            value: EXISTING_GUIDE,
                        },
                        {
                            label: "Create New One",
                            value: NEW_GUIDE,
                        },
                    ]}
                    handleChange={(name, value) => setSelectGuideStage(value)}
                />
            )}

            {selectGuideStage === EXISTING_GUIDE && (
                <SelectUI
                    grpClass='mb-3'
                    name='agentGuideId'
                    label=''
                    labelClass='text-dark fw-medium'
                    id='agentGuideId'
                    placeholder='Select Guide'
                    isErr={errors?.agentGuideId}
                    errMssg={errors?.agentGuideId}
                    options={guideList?.map(({ agentGuideName, ...rest }) => {
                        return {
                            label: agentGuideName,
                            value: { agentGuideName, ...rest },
                        };
                    })}
                    isLoading={fetchingGuides}
                    handleChange={(name, value) => handleSelectGuide(value)}
                />
            )}
        </>
    );
};

export default CreateGuideFormChoice;
