import AnalyticsQATabs from "../common/AnalytcisQATabs/AnalyticsQATabs";
import "./AnalyticsQAVetTicket.scss";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import AnalyticsQAVetTicketFilters from "./AnalyticsQAVetTicketFilters/AnalyticsQAVetTicketFilters";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAgentsList } from "store/cache/agents/actions";
import API from "services/lib/api";
import { apiRoute } from "services";
import AnalyticsQAVetTicketsList from "./AnalyticsQAVetTicketsList/AnalyticsQAVetTicketsList";

import {
    setVetTicketBaseFilter,
    setVetTicketFilterOption,
    setVetTicketFilterParams,
} from "store/filters/actions";

import {
    clearPinnedVetTickets,
    clearUnpinnedVetTickets,
} from "store/modules/qa/actions";

const AnalyticsQAVetTicket = () => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(0);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);

    const {
        vetTicketPinnedCache: { pages, totalRecords },
    } = useSelector((state) => state.vetTickets);

    const {
        vetTicketUnPinnedCache: { pages: pages2, totalRecords: totalRecords2 },
    } = useSelector((state) => state.vetTickets);

    const { filterParams, baseFilter, filterOption } = useSelector(
        (state) => state?.filters?.vetTicketFilters
    );

    const toggleRefresh = () => {
        dispatch(clearPinnedVetTickets());
        dispatch(clearUnpinnedVetTickets());
        setRefresh((prev) => prev + 1);
    };

    const fetchAgents = async (httpRequest) => {
        try {
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                dispatch(
                    setAgentsList(
                        data?.map(
                            ({ firstName, lastName, userId, ...rest }) => {
                                return {
                                    value: userId,
                                    label: `${firstName} ${lastName}`,
                                    firstName,
                                    lastName,
                                    userId,
                                    ...rest,
                                };
                            }
                        )
                    )
                );
            }
        } catch (err) {
            // setErrorMssg(getErrorMessage(err));
        }
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setVetTicketBaseFilter(baseFilter));

        dispatch(setVetTicketFilterOption({}));
    };

    const handleSearch = (search) => {
        setSearchIsLoading(true);
        setClearSearch(false);
        setUseTableLoader(true);
        dispatch(setVetTicketFilterParams({ currentPage: 1, search }));
        dispatch(clearPinnedVetTickets());
        dispatch(clearUnpinnedVetTickets());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setVetTicketFilterParams({
                ...filterParams,
                alphabetical: null,
                dateCreated: value,
                lastModified: null,
            })
        );
        dispatch(setVetTicketFilterOption({ ...option, value }));
        dispatch(clearPinnedVetTickets());
        dispatch(clearUnpinnedVetTickets());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setVetTicketFilterParams({ ...filterParams, currentPage }));
    };

    useEffect(() => {
        fetchAgents();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <AnalyticsQATabs />
            <div className='padded__layout analytics-qa-vet-ticket'>
                <AnalyticsQAVetTicketsList
                    isPinned={true}
                    title='Pinned Conversations'
                    toggleRefresh={toggleRefresh}
                    refresh={refresh}
                    filterParams={filterParams}
                    useTableLoader={useTableLoader}
                    setUseTableLoader={setUseTableLoader}
                    setSearchIsLoading={setSearchIsLoading}
                    pages={pages}
                />
                <AnalyticsQAVetTicketFilters
                    handleSearch={handleSearch}
                    filterParams={filterParams}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    handleSortByDate={handleSortByDate}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    handleBasefilter={handleBasefilter}
                />
                <AnalyticsQAVetTicketsList
                    isPinned={false}
                    title={"Other"}
                    titleClass='qa-vet-others'
                    toggleRefresh={toggleRefresh}
                    refresh={refresh}
                    filterParams={filterParams}
                    useTableLoader={useTableLoader}
                    setUseTableLoader={setUseTableLoader}
                    setSearchIsLoading={setSearchIsLoading}
                    pages={pages2}
                />
                <CustomPagination
                    totalRecords={
                        totalRecords > totalRecords2
                            ? totalRecords
                            : totalRecords2
                    }
                    handlePageChange={handlePageChange}
                    {...filterParams}
                />
            </div>
        </>
    );
};

export default AnalyticsQAVetTicket;
