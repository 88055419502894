import { ReactSVG } from "react-svg";
import { Dropdown } from "components/ui";
import asset from "assets/images";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { UPDATE } = baseResourceActions;

const DiscoveryDropdown = ({ handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={() => handleActionModal()}
            options={[
                {
                    value: "EDIT",
                    label: "Edit",
                    baseResources: [`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`],
                },
            ]}
        />
    );
};

export default DiscoveryDropdown;
