import React from "react";
import AccessControlRoute from "./AccessControlRouter/AccessControlRouter";
import { pathTypes } from "./enum";

const { SINGLE, TABBED, DROPDOWN } = pathTypes;

const RoutesWithSubroutes = (routes) =>
    routes.map((route) => {
        const allRouteCases = [];
        const { dropDowns, tabbedRoutes, ...mainRoute } = route;

        if (tabbedRoutes && tabbedRoutes.length > 0) {
            tabbedRoutes.map(
                async (tabbedRoute) =>
                    await allRouteCases.push({
                        ...tabbedRoute,
                        pathType: TABBED,
                        tabs: tabbedRoutes,
                    })
            );
        }

        if (dropDowns && dropDowns.length > 0) {
            dropDowns.map((dropDown) =>
                allRouteCases.push({ ...dropDown, pathType: DROPDOWN })
            );
        }

        allRouteCases.push({ ...mainRoute, pathType: SINGLE });

        return allRouteCases.map(
            (
                { path, pathType, tabs, isExact, baseResources, ...rest },
                index
            ) => (
                <AccessControlRoute
                    key={index}
                    path={path}
                    pathType={pathType}
                    tabs={tabs}
                    exact={isExact ? true : false}
                    baseResources={baseResources}
                    {...rest}
                />
            )
        );
    });

export default RoutesWithSubroutes;
