import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Checkbox, Input, Loading } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { flattenArray, getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const IssuesForm = ({ closeModal, isEdit, currentIssue, handleSuccess }) => {
    const [loading, setLoading] = useState();
    const [request, setRequest] = useState({
        nameOfIssue: "",
        issueCapsules: [],
        allowCapsulesDecideTemplate: true,
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [selectedContents, setSelectedContents] = useState(new Map());
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        // eslint-disable-next-line
    }, []);

    const createIssue = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const requestData = {
                ...request,
                issueCapsules: Array.from(selectedContents?.values()).filter(
                    (capsule) => capsule?.classCapsuleEntryIds.length > 0
                ),
            };

            const url = apiRoute.issue;
            const res = await API.post(url, requestData);
            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess();
                setLoading(false);
                closeModal();

                await toastMessage(message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const updateIssue = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const requestData = {
                ...request,
                issueCapsules: Array.from(selectedContents?.values()).filter(
                    (capsule) => capsule?.classCapsuleEntryIds.length > 0
                ),
            };

            const res = await API.put(
                apiRoute.updateIssue(request?.issueId),
                requestData
            );
            if (res.status === 200) {
                handleSuccess();
                setLoading(false);
                closeModal();

                await toastMessage(res.data.message);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId, capsuleCode } = capsule;
        setErrors({ ...errors, classificationCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleCode);
        if (prevSelectedContents) {
            const ccSelectedContentIds =
                prevSelectedContents?.classCapsuleEntryIds;
            const contentExist =
                prevSelectedContents?.classCapsuleEntryIds?.includes(
                    capsuleEntryId
                );

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                        capsuleEntryId
                    ),
                    1
                );
            }

            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: ccSelectedContentIds,
                            },
                        ],
                    ])
            );
        } else {
            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: [capsuleEntryId],
                            },
                        ],
                    ])
            );
        }
    };

    const getExistingElements = (elements) => {
        let map = new Map();

        elements?.forEach(function (
            { capsule: { capsuleClassId, capsuleCode }, capsuleEntryId },
            obj
        ) {
            let prevArry = map.get(capsuleCode);

            map = prevArry
                ? new Map([
                      ...map,
                      [
                          capsuleCode,
                          {
                              classCapsuleId: capsuleClassId,
                              classCapsuleEntryIds: [
                                  ...prevArry?.classCapsuleEntryIds,
                                  capsuleEntryId,
                              ],
                          },
                      ],
                  ])
                : new Map([
                      ...map,
                      [
                          capsuleCode,
                          {
                              classCapsuleId: capsuleClassId,
                              classCapsuleEntryIds: [capsuleEntryId],
                          },
                      ],
                  ]);
        },
        {});
        setSelectedContents(map);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "classificationCapsules",
        ]);
        if (formisValid) {
            isEdit ? updateIssue() : createIssue();
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setRequest({
                nameOfIssue: currentIssue?.issueName,
                ...currentIssue,
            });

            getExistingElements(currentIssue?.elements);
        }
    }, [currentIssue, isEdit]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={capsuleClasses?.map(
                            (capsuleClass) => capsuleClass?.capsuleCode
                        )}
                        classesListBreakpoint={8}
                        handleContentSelect={handleContentSelect}
                        selectable={true}
                        searchable={true}
                        selectedContentIds={flattenArray(
                            Array.from(selectedContents?.values())?.map(
                                (x) => x.classCapsuleEntryIds
                            )
                        )}
                        label='Relate feature or flow'
                        inputKey='classificationCapsules'
                        error={errors?.classificationCapsules}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    const { allowCapsulesDecideTemplate } = request;

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='nameOfIssue'
                label='Name of issue'
                labelClass='text-dark fw-medium'
                id='name'
                placeholder='Enter name of issue'
                value={request?.nameOfIssue}
                onChange={handleChange}
                isErr={errors?.nameOfIssue}
                errMssg={errors?.nameOfIssue}
                autoFocus={true}
            />
            <div className='form-group'>{renderBasedOnStatus()}</div>
            <Checkbox
                grpClass='mb-3'
                name='allowCapsulesDecideTemplate'
                label='Allow capsules decide template'
                inputClass='input-check-grey'
                id='allowCapsulesDecideTemplate'
                onChange={({ target: { checked } }) =>
                    setRequest({
                        ...request,
                        allowCapsulesDecideTemplate: checked,
                    })
                }
                checked={allowCapsulesDecideTemplate}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

IssuesForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
};

export default IssuesForm;
