import React from "react";
import { GeneralLayout } from "components/ui";
import { SignInForm } from "./SignInForm/SignInForm";
import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import "./SignIn.scss";

const SignIn = () => {
    return (
        <>
            <SEO title={"sign in"} />
            <GeneralLayout>
                <SignInForm />
            </GeneralLayout>
        </>
    );
};

export default SignIn;
