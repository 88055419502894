import { useContext, useState } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import AddButton from "components/ui/Button/AddButton/AddButton";
import TicketAAListingItem from "./TicketAAListingItem/TicketAAListingItem";
import "./TicketAAListing.scss";

const { ERROR } = dataQueryStatus;

const TicketAAListing = ({
    guideList,
    selectedTicketId,
    handleRefresh,
    isLoading,
    handleSearchGuides,
}) => {
    const [isDisabled, setDisable] = useState(false);
    const toastMessage = useContext(ToastContext);
    const [showAll, toggleShowAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedGuideId, selectGuideId] = useState();

    const startAgentGuide = async (agentGuideId) => {
        try {
            setDisable(true);
            // setStatus(LOADING);
            setLoading(true);
            const url = apiRoute?.startTicketGuide(
                agentGuideId,
                selectedTicketId
            );
            const res = await API.put(url, {});

            if (res.status === 200) {
                const { message } = res.data;

                setDisable(false);
                setLoading(false);
                toastMessage(message);
                handleRefresh?.();
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
            setDisable(false);
            setLoading(false);
        }
    };

    const handleStartGuide = (agentGuideId) => {
        if (!isDisabled) {
            selectGuideId(agentGuideId);
            startAgentGuide(agentGuideId);
        }
    };

    const guideListSize = guideList?.length;

    return (
        <div className='ticketaa-listing'>
            <h5>Select an Assisted Guide</h5>
            <PaginationSearch
                placeholder={"Search assisted guides"}
                handleSearch={handleSearchGuides}
                isLoading={isLoading}
            />
            <section>
                {guideList
                    ?.slice?.(0, showAll ? guideListSize : 3)
                    ?.map?.(({ agentGuideName, agentGuideId, hasDowntime}, key) => (
                        <TicketAAListingItem
                            key={key}
                            {...{
                                agentGuideId,
                                handleStartGuide,
                                agentGuideName,
                                selectedGuideId,
                                hasDowntime,
                                loading,
                            }}
                        />
                    ))}
            </section>
            {guideList.length > 0 && <AddButton
                btnText={showAll ? "View Less" : "View More"}
                hasIcon={false}
                onClick={() => toggleShowAll(!showAll)}
            />}
        </div>
    );
};

export default TicketAAListing;
