import { CANNED_RESOURCE_ACTIONS } from "components/dashboard/TrainSAM/CannedResponses/enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;

const { EDIT_CANNED_RESOURCE } = CANNED_RESOURCE_ACTIONS;

const UpdatesTableActionButton = ({ editText, onClick }) => {
    return (
        <div>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                <AddButton
                    btnText={editText}
                    hasIcon={false}
                    onClick={() => onClick?.(EDIT_CANNED_RESOURCE)}
                />
            </ComponentAccessControl>
        </div>
    );
};

export default UpdatesTableActionButton;
