import TabbedWrapperHeader from "routes/TabbedWrapperRouter/TabbedWrapperHeader/TabbedWrapperHeader";
import AnalyticsQAVetTicket from "../../AnalyticsQAVetTicket/AnalyticsQAVetTicket";
import AnalyticsQAVetAgents from "../../AnalyticsQAVetAgents/AnalyticsQAVetAgents";
import AnalyticsQAReport from "../../AnalyticsQAReport/AnalyticsQAReport";
import AnalyticsQAProgress from "../../AnalyticsQAProgress/AnalyticsQAProgress";

const AnalyticsQATabs = () => {
    const tabbledRoutes = [
        {
            title: "QA report",
            path: "/analytics/qa/report",
            component: AnalyticsQAReport,
        },
        {
            title: "Quality progress",
            path: "/analytics/qa/progress",
            component: AnalyticsQAProgress,
        },
        {
            title: "Vet Ticket",
            path: "/analytics/qa/vet-tickets",
            component: AnalyticsQAVetTicket,
        },
        {
            title: "Vet Agent",
            path: "/analytics/qa/vet-agents",
            component: AnalyticsQAVetAgents,
            isDisabled: true,
        },
    ];
    return (
        <>
            <TabbedWrapperHeader tabbedRoutes={tabbledRoutes} />
        </>
    );
};

export default AnalyticsQATabs;
