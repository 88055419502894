import BreadCrumbHeader from 'components/ui/BreadCrumbHeader/BreadCrumbHeader';
import React from 'react';

const DCBranchViewHeading = ({currentCapsule}) => {
    return (
        <>
            <BreadCrumbHeader
                prevPage={`/dependency/downtime-branches`}
                prevPageTitle="Downtime branches"
                currentPageTitle={currentCapsule?.content} />
        </>
    );
};

export default DCBranchViewHeading;