import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";

const ChangeEmbedForm = ({
    handleSuccess,
    closeModal,
    currentEmbed,
    referenceData,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [request, setRequest] = useState({ capsuleEntryId: "" });

    const toastMessage = useContext(ToastContext);

    const { capsuleClassId } = request;
    const { guideFlowCapsuleClassId, guideFlowCapsuleEntryId } =
        currentEmbed || {};

    const updateGuideBranch = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.put(
                apiRoute?.editGuideBranch(referenceData?.guideFlowId),
                request
            );

            if (res.status === 200) {
                const { message, data } = res.data;
                handleSuccess?.(data?.guideFlowId);
                toastMessage(message);
                closeModal?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            updateGuideBranch();
        }
        setErrors(formErrors);
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId } = capsule;
        setErrors({ ...errors, agentGuideCapsules: "" });

        const prevSelectedCapsuleEntryId =
            request?.guideFlowCapsuleEntryId === capsuleEntryId;

        if (prevSelectedCapsuleEntryId) {
            setRequest({
                ...request,
                capsuleClassId: null,
                capsuleEntryId: null,
            });
        } else {
            setRequest({
                ...request,
                capsuleClassId,
                capsuleEntryId,
            });
        }

        setErrors((prevErr) => ({ ...prevErr, guideFlowCapsuleEntryId: "" }));
    };

    useEffect(() => {
        setRequest({
            capsuleClassId: guideFlowCapsuleClassId,
            capsuleEntryId: guideFlowCapsuleEntryId,
        });
        // eslint-disable-next-line
    }, [currentEmbed]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            <div className='form-group'>
                <ClassCapsuleContentGroup
                    capsuleCodes={capsuleClasses?.map(
                        (capsuleClass) => capsuleClass?.capsuleCode
                    )}
                    defaultCapsuleClassId={capsuleClassId}
                    classesListBreakpoint={8}
                    handleContentSelect={handleContentSelect}
                    selectable={true}
                    searchable={true}
                    selectedContentIds={
                        request?.capsuleEntryId
                            ? [request?.capsuleEntryId]
                            : null
                    }
                    label='Relate feature or flow'
                    inputKey='capsuleEntryId'
                    error={errors?.capsuleEntryId}
                />
            </div>

            <Button
                type='submit'
                text='Change embed'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default ChangeEmbedForm;
