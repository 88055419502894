import React from "react";
import "./TicketDetailHeading.scss";

const TicketDetailHeading = ({ label, value, isActive, onClick, hasNew }) => {
    return (
        <>
            <div
                id={`${value}`}
                className={`tickets__detail--heading ${
                    isActive ? "active" : ""
                }`}
                onClick={onClick}>
                {hasNew && <div className='new__circle'></div>}
                {label}
            </div>
        </>
    );
};

export default TicketDetailHeading;
