import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useState } from "react";
import { dataQueryStatus } from "utils";
import RemindersHeader from "./RemindersHeader/RemindersHeader";
import RemindersTable from "./RemindersTable/RemindersTable";

import "./Reminders.scss";

import ReminderModals from "./modals/ReminderModals";

const { LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const Reminders = () => {
    const [status, setStatus] = useState(DATAMODE);

    const [selectedReminder, selectReminder] = useState();
    const [showActionModal, toggleActionModal] = useState(false);
    const [actionType, setActionType] = useState();

    const handleActionModal = (action, value) => {
        setActionType(action);
        selectReminder(value);
        toggleActionModal(true);
    };

    const getReminders = () => {
        setStatus(LOADING);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        className={"reminders__empty"}
                        text='No reminder has been created yet.'
                    />
                );

            case DATAMODE:
                return (
                    <div>
                        <RemindersTable handleActionModal={handleActionModal} />
                    </div>
                );

            case ERROR:
                return <ErrorView message={""} handleRetry={getReminders} />;

            default:
                return "";
        }
    };

    const closeModal = () => {
        toggleActionModal(false);
    };

    const handleRefresh = () => {
        getReminders();
    };
    return (
        <>
            <div className='padded__layout reminders'>
                <RemindersHeader
                    hasData={true}
                    handleActionModal={handleActionModal}
                />
                {renderBasedOnStatus()}
                {showActionModal && (
                    <ReminderModals
                        showModal={showActionModal}
                        closeModal={closeModal}
                        selectedReminder={selectedReminder}
                        actionType={actionType}
                        handleRefresh={handleRefresh}
                    />
                )}
            </div>
        </>
    );
};

export default Reminders;
