import React from "react";
import PendingTaskList from "./PendingTaskList/PendingTaskList";
import "./PendingTasks.scss";

const PendingTasks = ({
    followupTasks,
    getTicketFollowups,
    followupId,
    selectedTicket,
    setCannedMessage,
    handleToggleEmailPreview,
    refetchEmails,
}) => {
    return (
        <div className='pending__tasks'>
            <PendingTaskList
                followupTasks={followupTasks}
                followupId={followupId}
                getTicketFollowups={getTicketFollowups}
                selectedTicket={selectedTicket}
                setCannedMessage={setCannedMessage}
                handleToggleEmailPreview={handleToggleEmailPreview}
                refetchEmails={refetchEmails}
            />
        </div>
    );
};

export default PendingTasks;
