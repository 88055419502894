export const WHITE_MODE_DEFAULT = "WHITE_MODE_DEFAULT";
export const DARK_MODE_DEFAULT = "DARK_MODE_DEFAULT";

export const WORKMODE = "WORKMODE";
export const RELAXED = "RELAXED";

export const defaultThemes = [
    { label: "White Mode", value: WHITE_MODE_DEFAULT },
    { label: "Dark Mode", value: DARK_MODE_DEFAULT },
];

export const defaultTemplates = [
    { label: "Work Mode", value: WORKMODE },
    { label: "Relaxed Mode", value: RELAXED },
];
