import ItemsList from "components/ui/ItemsList/ItemsList";
import Capsule from "../../../common/Capsule/Capsule";

const CapsuleClassesList = ({
    data,
    classesListBreakpoint = 20,
    selected,
    handleClick,
}) => {
    return (
        <>
            <ItemsList
                breakPoint={classesListBreakpoint}
                otherClassNames='mb-3'>
                {data?.map((item, i) => (
                    <Capsule
                        key={i}
                        capsule={item}
                        isActive={item?.capsuleClassId === selected}
                        onClick={() => {
                            handleClick(item);
                        }}
                    />
                ))}
            </ItemsList>
        </>
    );
};

export default CapsuleClassesList;
