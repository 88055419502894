import React from "react";
import TTagElement from "../../common/TTagElement/TTagElement";

const TConversationSuggestionList = ({
    conversationSuggestions,
    acceptConversation,
    searchValue,
}) => {
    return (
        <>
            {conversationSuggestions
                ?.filter((conversation) =>
                    conversation?.issue?.issueName
                        .toLocaleLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                )
                ?.map((conversation, index) => {
                    return (
                        <TTagElement
                            key={index}
                            label={conversation?.issue?.issueName}
                            handleClick={() =>
                                acceptConversation({ conversation })
                            }
                        />
                    );
                })}
        </>
    );
};

export default TConversationSuggestionList;
