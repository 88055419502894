import React, { useEffect, useState } from "react";
import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import { Empty } from "components/ui";
import CreateBranchModal from "../Conversations/branches/modal/CreateBranchModal/CreateBranchModal";
import { branchFormActions } from "../Conversations/enums";
import { downtimeTypesData } from "../DowntimeBranchesView/DowntimeBranchesList/data";
import BranchGroupViewList from "./BranchGroupViewList/BranchGroupViewList";
import { UPTIME_BRANCH } from "../DowntimeBranchesView/DowntimeBranchesList/enums";
import { GENERAL_DOWNTIME_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import "./BranchViewGroup.scss";

const BranchViewGroup = ({
    branches,
    preferredBranchId,
    branchGroupType,
    parentCapsule,
    parentSource,
    selectedBranch,
    branchViewStatus,
    bvErrorMssg,
    handleBranchSelect,
    handleFullPageRefresh,
    existingOptionConnections,
    branchCollectionId,
    setSelectedBranch,
}) => {
    const [showEditModal, toggleEditModal] = useState(false);

    useEffect(() => {
        branches?.length > 0 &&
            handleBranchSelect(
                preferredBranchId ? preferredBranchId : branches?.[0]?.branchId
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchGroupType]);

    const handleRefresh = (branchId) => {
        if (branchId) {
            handleBranchSelect(branchId);
        } else {
            handleFullPageRefresh(branchGroupType);
            setSelectedBranch();
        }
    };

    return (
        <>
            <div>
                <div className='branch__view__group'>
                    <div className='branch__view__group--actions'>
                        <h1 className='branch__view__group--actions__header'>
                            {downtimeTypesData[branchGroupType]}
                        </h1>
                        {/* <div>
                            {selectedBranch && (
                                <EditBranchButton
                                    otherClassName={"ms-auto"}
                                    onClick={() => toggleEditModal(true)}
                                />
                            )}
                        </div> */}
                    </div>
                </div>
                <BranchGroupViewList
                    branchGroupType={branchGroupType}
                    branches={branches}
                    selectedBranch={selectedBranch}
                    handleBranchSelect={handleBranchSelect}
                    parentCapsule={parentCapsule}
                    parentSource={parentSource}
                    handleFullPageRefresh={handleFullPageRefresh}
                />
                {branches?.length > 0 ? (
                    <>
                        {selectedBranch && (
                            <BranchView
                                branch={selectedBranch}
                                handleRefresh={(branchId) => {
                                    handleRefresh(branchId);
                                }}
                                status={branchViewStatus}
                                errorMssg={bvErrorMssg}
                                hideOptionsView={
                                    selectedBranch?.branchType !== UPTIME_BRANCH
                                        ? true
                                        : false
                                }
                                existingOptionConnections={
                                    existingOptionConnections
                                }
                                collectionId={branchCollectionId}
                                showRemoveBranchOption={
                                    parentSource === GENERAL_DOWNTIME_COLLECTION
                                        ? true
                                        : false
                                }
                            />
                        )}
                    </>
                ) : (
                    <Empty
                        text={`No ${downtimeTypesData[branchGroupType]}`}
                        className='my-5'
                    />
                )}
            </div>
            {showEditModal && (
                <CreateBranchModal
                    show={showEditModal}
                    isEdit={true}
                    closeModal={() => toggleEditModal(false)}
                    referenceData={selectedBranch}
                    source={branchGroupType}
                    modalFormAction={branchFormActions?.EDIT_BRANCH}
                    handleSuccess={(branchId) => {
                        toggleEditModal(false);
                        // handleBranchSelect(branchId)
                        handleFullPageRefresh(branchGroupType, branchId);
                    }}
                />
            )}
        </>
    );
};

export default BranchViewGroup;
