import React, { useState, useEffect } from "react";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { checkIfValidUUID, getErrorMessage, getUniqueListBy } from "utils/helper";
import { useSelector } from "react-redux";
import TicketEmailAttachmentUpload from "./TicketEmailAttachmentUpload/TicketEmailAttachmentUpload";
import { ticketInfoTypes } from "components/dashboard/AgentInbox/enum";
import { getSingleCapsuleEntry } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import "./TicketEmailAttachment.scss";

const { CAPSULE, FORM } = ticketInfoTypes;
const { LOADING, ERROR, DATAMODE } = dataQueryStatus;

const TicketEmailAttachment = ({
    handleToggleEmailAttachment
}) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [ticketInfos, setTicketInfos] = useState();
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );
    const { activeTicket } = useSelector(
        (state) => state.tickets
    );
    const { ticketId } = activeTicket || {};

    const { agentId } = ticketsfilterRequest || {};

    const retrieveCapsule = async (capsule, capsuleEntryId) => {
        try {
            const elementObj = await getSingleCapsuleEntry(
                capsule,
                capsuleEntryId
            );
            return { ...elementObj, ticketInfoType: CAPSULE };
        } catch (error) {
            
            // return ;
        }


    };

    const getFilledFormDetails = async (ticketId, formId) => {
        try {
            setStatus(LOADING);
            setErrorMssg();

            const url = apiRoute?.getTicketFilledForm(ticketId, formId);
            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;

                return { data, ticketInfoType: FORM };
            }
        } catch (err) {
            setStatus(ERROR);
        }
    };

    const fetchTicketInfo = async () => {
        try {
            if (ticketId) {
                setStatus(LOADING);
                setErrorMssg();

                const url = apiRoute?.getTicketInfo(ticketId);
                const res = await API.get(url, {
                    params: {
                        agentId,
                    },
                });
                if (res.status === 200) {
                    const { data } = res.data;
                    const uniqueTicketInfoList = getUniqueListBy(
                        data,
                        "ticketInfoValue"
                    );

                    const ticketInfo = await uniqueTicketInfoList?.map(async (ticketInfoEl) => {
                        const {
                            ticketInfoType,
                            ticketInfoCapsuleClassId,
                            ticketInfoValue,
                        } = ticketInfoEl;

                        if (ticketInfoType === CAPSULE && checkIfValidUUID(ticketInfoValue)) {
                            const ticketInfoCapsule = capsuleClasses?.find(
                                (x) =>
                                    x.capsuleClassId ===
                                    ticketInfoCapsuleClassId
                            );

                            const capsule = await retrieveCapsule(
                                ticketInfoCapsule,
                                ticketInfoValue
                            );

                            return capsule
                        }
                        if (ticketInfoType === FORM) {
                            const form = await getFilledFormDetails(
                                ticketId,
                                ticketInfoValue
                            );

                            return form
                        }
                    });
                    const result = await Promise.all(ticketInfo);
                    setTicketInfos(result);
                    setStatus(DATAMODE);
                }
            }
        } catch (err) {

            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        fetchTicketInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <TicketEmailAttachmentUpload

                            ticketInfos={ticketInfos}
                            handleToggleEmailAttachment={handleToggleEmailAttachment}
                        />
                    </>

                );

            case ERROR:
                return <ErrorView message={errorMssg} handleRetry={fetchTicketInfo} />;

            default:
                return "";
        }
    };

    return (
        <>
            {
                renderBasedOnStatus()
            }
        </>
    );
};

export default TicketEmailAttachment;
