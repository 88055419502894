import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateGuideForm from "./CreateGuideForm/CreateGuideForm";
import { SideModal } from "components/ui";

const CreateGuideModal = ({
    showModal,
    closeModal,
    isEdit,
    selectedGuide,
    handleSuccess,
    isSolutionGuide,
    solutionCapsule
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isSolutionGuide
                                ? "Add Guide"
                                : isEdit
                                ? "Edit Guide"
                                : "Create Guide"
                        }
                        subTitle={
                            isSolutionGuide
                                ? "Add Guide To Solution Capsule"
                                : isEdit
                                ? "Update a guide here"
                                : "Create a new guide here"
                        }
                    />
                    <CreateGuideForm
                        handleSuccess={handleSuccess}
                        selectedGuide={selectedGuide}
                        isEdit={isEdit}
                        closeModal={closeModal}
                        isSolutionGuide={isSolutionGuide}
                        solutionCapsule={solutionCapsule}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default CreateGuideModal;
