import React, { useEffect, useState } from "react";
import ErrorView from "components/ui/ErrorView/ErrorView";
import imageLinks from "assets/images";
import { Empty, Loading } from "components/ui";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import BranchesList from "components/dashboard/TrainSAM/SAM/Conversations/branches/BranchesList/BranchesList";
import { convoBreakerBranchNames } from "./enums";
import "./ConversationBreakers.scss";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const ConversationBreakers = () => {
    const [selectedConvBreakerBranch, setSelectedConvBreakerBranch] = useState(
        {}
    );
    const [status, setStatus] = useState("");
    const [conversationBreakers, setConversationBreakers] = useState([]);
    const [branchViewStatus, setBranchViewStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [bvErrorMssg, setBVErrorMssg] = useState("");

    const getConversationBreakers = async (httpRequest) => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getConversationBreakers;
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res?.data;

                const convoBreakerBranches = data?.map(
                    ({ actionBranchId, actionBranchType }) => ({
                        branchId: actionBranchId,
                        branchName: convoBreakerBranchNames[actionBranchType],
                    })
                );

                if (convoBreakerBranches?.length > 0) {
                    setConversationBreakers(convoBreakerBranches);
                    setStatus(DATAMODE);
                    handleConvBreakerSelect(convoBreakerBranches[0]?.branchId);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getConversationBreakers(httpRequest);

        return () => {
            httpRequest?.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConvBreakerSelect = async (convoBreakerBranchId) => {
        try {
            setSelectedConvBreakerBranch({ branchId: convoBreakerBranchId });
            setBranchViewStatus(LOADING);
            const url = apiRoute?.conversationBreaker(convoBreakerBranchId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;
                const {
                    actionBranchHeader,
                    actionBranchId,
                    actionBranchMainSentence,
                    actionBranchOptions,
                    actionBranchType,
                    displayAverageResponseTime,
                    requestRatings,
                } = data || {};

                const convoBreakerBranch = {
                    branchId: actionBranchId,
                    branchMainSentence: actionBranchMainSentence,
                    branchName: actionBranchHeader,
                    branchOptions: actionBranchOptions?.map(
                        ({
                            actionBranchOptionId,
                            actionBranchOptionTitle,
                            actionBranchOptionType,
                        }) => ({
                            branchOptionActionType: actionBranchOptionType,
                            branchOptionId: actionBranchOptionId,
                            branchOptionLabel: actionBranchOptionTitle,
                        })
                    ),
                    branchType: actionBranchType,
                    displayAverageResponseTime,
                    requestRatings,
                };

                setSelectedConvBreakerBranch(convoBreakerBranch);
                setBranchViewStatus(DATAMODE);
            }
        } catch (err) {
            setBVErrorMssg(getErrorMessage(err));
            setBranchViewStatus(ERROR);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        className={"empty__conversation__breaker__container"}
                        text='Add a  new conversation breaker to get started'
                        icon={imageLinks?.images?.branch}
                    />
                );

            case DATAMODE:
                return (
                    <div>
                        <BranchesList
                            branches={conversationBreakers}
                            handleBranchSelect={handleConvBreakerSelect}
                            selectedBranch={selectedConvBreakerBranch}
                            showBottomBorder={false}
                        />
                        <BranchView
                            errorMssg={bvErrorMssg}
                            status={branchViewStatus}
                            branch={selectedConvBreakerBranch}
                            handleRefresh={(convoBreakerBranchId) =>
                                convoBreakerBranchId
                                    ? handleConvBreakerSelect(
                                          convoBreakerBranchId
                                      )
                                    : getConversationBreakers()
                            }
                            hideCapsuleContentGroup={true}
                            hideBranchActions={true}
                            isConversationBreakerView={true}
                        />
                    </div>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConversationBreakers}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='padded__layout conversation__breakers'>
            <h6 className='conversation__breakers--header'>
                Conversation breakers
            </h6>
            {renderBasedOnStatus()}
        </div>
    );
};

export default ConversationBreakers;
