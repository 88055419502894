import { useState, useEffect } from "react";

import BootstrapDropdown from "./BoostrapDropdown/BootstrapDropdown";
import CustomDropdown from "./CustomDropdown/CustomDropdown";

export const Dropdown = ({
    size,
    placeholder,
    options,
    onChange,
    ddValue,
    ddValueClass,
    optClass,
    className,
    ddOptionsClass,
    anchor = true,
    changeValue = true,
    noItemMsg = "No item",
    isDefault = true,
    labelClass,
    menuAlign,
}) => {
    const [selected, setSelected] = useState(
        ddValue || { value: "", label: "" }
    );
    const [show, setShow] = useState(false);

    useEffect(() => {
        document.body.addEventListener("click", () => setShow(false));

        return () =>
            document.body.removeEventListener("click", () => setShow(false));
    }, []);

    const handleChange = (item) => {
        changeValue && setSelected(item);
        onChange?.(item);
        setShow(false);
    };

    return (
        <>
            {isDefault ? (
                <BootstrapDropdown
                    {...{
                        placeholder,
                        options,
                        handleChange,
                        ddValueClass,
                        optClass,
                        className,
                        ddOptionsClass,
                        anchor,
                        noItemMsg,
                        selected: changeValue ? selected : ddValue,
                        menuAlign,
                    }}
                />
            ) : (
                <CustomDropdown
                    {...{
                        show,
                        setShow,
                        selected: changeValue ? selected : ddValue,
                        placeholder,
                        ddOptionsClass,
                        options,
                        noItemMsg,
                        handleChange,
                        className,
                        size,
                        ddValueClass,
                        anchor,
                        optClass,
                        labelClass,
                    }}
                />
            )}
        </>
    );
};
