import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import IssuesForm from "./IssuesForm/IssuesForm";

const CreateIssueModal = ({
    showModal,
    closeModal,
    isEdit,
    currentIssue,
    handleSuccess,
}) => {
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={isEdit ? "Update Issue" : "Create Issue"}
                            subTitle={
                                isEdit
                                    ? "Update an issue here"
                                    : "Create a new issue here"
                            }
                        />
                        <IssuesForm
                            handleSuccess={handleSuccess}
                            currentIssue={currentIssue}
                            isEdit={isEdit}
                            closeModal={closeModal}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default CreateIssueModal;
