import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import DiscoveryForm from "./DiscoveryForm/DiscoveryForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const DiscoveryModal = ({
    showSideModal,
    closeSideModal,
    selectedIssue,
    handleSuccess,
}) => {
    return (
        <SideModal show={showSideModal} close={closeSideModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Create Discovery'
                        subTitle='Create a new discovery here'
                    />
                    <DiscoveryForm
                        selectedIssue={selectedIssue}
                        closeModal={closeSideModal}
                        handleSuccess={handleSuccess}
                    />
                </div>
            </div>
        </SideModal>
    );
};

DiscoveryModal.prototypes = {
    closeSideModal: PropTypes.func.isRequired,
    getDiscoveries: PropTypes.func.isRequired,
    showSideModal: PropTypes.bool.isRequired,
    selectedIssue: PropTypes.string.isRequired,
};

export default DiscoveryModal;
