import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import PostFormCollectionForm from "./PostFormCollectionForm/PostFormCollectionForm";

const PostFormCollectionModal = ({
    showModal,
    closeModal,
    handleSuccess,
    isEdit,
    referenceData,
}) => {
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={`${
                                isEdit
                                    ? "Edit Post Form Collection"
                                    : "Add New Post Form Collection"
                            }`}
                            subTitle={`${
                                isEdit
                                    ? "Update post form"
                                    : "Create a new post form"
                            }`}
                        />
                        <PostFormCollectionForm
                            handleSuccess={handleSuccess}
                            isEdit={isEdit}
                            referenceData={referenceData}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default PostFormCollectionModal;
