import React from "react";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import "./RelatedClassesList.scss";

const RelatedClassesList = ({
    relatedClasses,
    selectedProduct,
    secondaryCapsuleClass,
    selectedSecCapsuleClass,
    handleSecondaryCapsuleSelect,
    capsuleRenderCount,
}) => {
    return (
        <div className='related__classes__list'>
            {relatedClasses?.map((item, i) => (
                <Capsule
                    key={(i + 2) * capsuleRenderCount}
                    capsule={item}
                    onClick={() => handleSecondaryCapsuleSelect(item)}
                    isActive={
                        selectedSecCapsuleClass?.capsuleClassId ===
                        item?.capsuleClassId
                    }
                    showCount={true}
                    secondaryCapsuleClass={secondaryCapsuleClass}
                    productId={selectedProduct?.value}
                />
            ))}
        </div>
    );
};

export default RelatedClassesList;
