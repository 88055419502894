import React from "react";
import SwitchesHeadingActions from "../SwitchesHeadingActions/SwitchesHeadingActions";

const SwitchesListingHeadingActions = ({
    toggleModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleSortByAlpha,
    handleSortByLastModified,
    handleSortByDate, baseFilter,
    filterOption,
    handleBasefilter,searchDefaultValue
}) => {
    return (
        <SwitchesHeadingActions
            toggleModal={toggleModal}
            handleSearch={handleSearch}
            searchIsLoading={searchIsLoading}
            clearSearch={clearSearch}
            handleSortByAlpha={handleSortByAlpha}
            handleSortByDate={handleSortByDate}
            handleSortByLastModified={handleSortByLastModified}
            baseFilter={baseFilter}
            filterOption={filterOption}
            handleBasefilter={handleBasefilter}
            searchDefaultValue={searchDefaultValue}
        />
    );
};

export default SwitchesListingHeadingActions;
