import React from "react";
import { truncateString } from "utils/helper";

const CapsuleBlock = ({ capsuleCode }) => {
    const maxLength = 25;
    return (
        <div className='capsule'>
            <span>
                <span
                    title={capsuleCode?.length > maxLength ? capsuleCode : ""}>
                    {truncateString(capsuleCode, maxLength)}
                </span>
            </span>
        </div>
    );
};

export default CapsuleBlock;
