import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleButton from "../GoogleButton/GoogleButton";
import { inboxAppsActionTypes } from "../../enum";
import API from "services/lib/api";
import { apiRoute } from "services";
import config from "config/config";
import { useContext, useEffect } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { gapi } from "gapi-script";
import { dataQueryStatus } from "utils";

const { SUCCESS } = dataQueryStatus;

const GoogleIntegrationLogin = ({
    handleNewConnection,
    handleError,
    handleSuccess,
    workspace,
    closeModal,
    children,
}) => {
    const clientId = config.google.GOOGLE_CLIENT_ID;

    const scopes = config.google.GOOGLE_CLIENT_SCOPES;

    const toastMessage = useContext(ToastContext);

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.getAuthInstance({
                clientId,
                scope: scopes,
                accessType: "offline",
                prompt: "consent",
                fetch_basic_profile: true,
            });
        };
        gapi.load("client:auth2", initClient);
        // eslint-disable-next-line
    }, []);

    const onLogin = async (tokenData) => {
        try {
            handleNewConnection("Gmail", inboxAppsActionTypes?.CONNECT);
            const url = apiRoute?.authorizeGoogle;
            const res = await API.get(url, {
                params: {
                    code: tokenData?.code,
                },
                headers: {
                    workspaceid: workspace?.workspaceId,
                },
            });
            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message, SUCCESS);
                handleSuccess?.();
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <GoogleOAuthProvider clientId={clientId} responseType='code'>
            <GoogleButton scopes={scopes} onLogin={onLogin}>
                {children}
            </GoogleButton>
        </GoogleOAuthProvider>
    );
};

export default GoogleIntegrationLogin;
