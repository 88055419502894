import { SideModal } from "components/ui";
import StartupProfileForms from "./StartupProfileForms/StartupProfileForms";

const StartupProfileModal = ({ currentAction, closeModal, handleSuccess }) => {
    return (
        <SideModal show={true} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <StartupProfileForms
                        currentAction={currentAction}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default StartupProfileModal;
