import {
    ticketPhases,
    otherCategories,
} from "components/dashboard/AgentInbox/enum";

const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;

const { EXTERNAL_EMAILS, MENTIONS, AGENT_ASSISTANCE } =
    otherCategories;

export const issueDiscovery = [
    {
        value: AGENT_ASSISTANCE,
        label: "Agent's assistant",
    },
    {
        value: ISSUE_DISCOVERY,
        label: "Suggestions",
    },
    {
        value: SOLUTION_DELIVERY,
        label: "Branches",
    },
    // {
    //     value: FOLLOW_UP,
    //     label: "Follow up",
    // },
    {
        value: EXTERNAL_EMAILS,
        label: "Emails",
    },
    {
        value: MENTIONS,
        label: "Mentions",
    },
];

export const problemConfirmation = [
    {
        value: AGENT_ASSISTANCE,
        label: "Agent's assistant",
    },
    {
        value: ISSUE_DISCOVERY,
        label: "Suggestions",
    },
    {
        value: PROBLEM_CONFIRMATION,
        label: "Ticket info",
    },
    {
        value: SOLUTION_DELIVERY,
        label: "Branches",
    },
    // {
    //     value: FOLLOW_UP,
    //     label: "Follow up",
    // },
    {
        value: EXTERNAL_EMAILS,
        label: "Emails",
    },
    {
        value: MENTIONS,
        label: "Mentions",
    },
];

export const solutionDelivery = [
    {
        value: AGENT_ASSISTANCE,
        label: "Agent's assistant",
    },
    {
        value: PROBLEM_CONFIRMATION,
        label: "Ticket info",
    },
    {
        value: SOLUTION_DELIVERY,
        label: "Branches",
    },
    // {
    //     value: FOLLOW_UP,
    //     label: "Follow up",
    // },
    {
        value: EXTERNAL_EMAILS,
        label: "Emails",
    },
    {
        value: MENTIONS,
        label: "Mentions",
    },
];

export const followupMentions = [
    {
        value: AGENT_ASSISTANCE,
        label: "Agent's assistant",
    },
    {
        value: PROBLEM_CONFIRMATION,
        label: "Ticket info",
    },
    {
        value: SOLUTION_DELIVERY,
        label: "Branches",
    },
    // {
    //     value: FOLLOW_UP,
    //     label: "Follow up",
    // },
    {
        value: EXTERNAL_EMAILS,
        label: "Emails",
    },
    {
        value: MENTIONS,
        label: "Mentions",
    },
];
