import GoogleIntegrationLogin from "components/essentials/Integrations/GoogleIntegration/GoogleIntegrationLogin/GoogleIntegrationLogin";
import "./InboxAppModalHeader.scss";

const InboxAppModalHeader = ({
    handleNewConnection,
    handleError,
    handleSuccess,
    workspace,
    closeModal,
}) => {
    return (
        <div className='inbox-app-modal__header'>
            <div>
                {/* <h3 className='inbox-app-modal__header--title'>Choose Medium</h3>
                <span className='inbox-app-modal__header--sub__title'>
                    Select which medium to integrate Google.
                </span> */}
            </div>
            <div className='inbox-app-modal__container'>
                <ul className='inbox-app-modal__modes'>
                    <li
                        className={`inbox-app-modal__mode active__inbox-app-modal__mode
                        `}>
                        Web Hook
                    </li>
                    <GoogleIntegrationLogin
                        {...{
                            handleNewConnection,
                            handleError,
                            handleSuccess,
                            workspace,
                        }}>
                        <li
                            className={`inbox-app-modal__mode`}
                            onClick={() => closeModal()}>
                            Gmail
                        </li>
                    </GoogleIntegrationLogin>
                </ul>
            </div>
        </div>
    );
};

export default InboxAppModalHeader;
