export const UPDATE_RESOURCE_TYPES = {
    UPDATE_EMAIL: "UPDATE_EMAIL",
    MENTIONS: "MENTIONS",
    UPDATE_RESPONSE: "UPDATE_RESPONSE",
};

export const UPDATE_RESOURCE_ACTIONS = {
    CREATE_UPDATE_RESOURCE: "CREATE_UPDATE_RESOURCE",
    EDIT_UPDATE_RESOURCE: "EDIT_UPDATE_RESOURCE",
    DELETE_UPDATE_RESOURCE: "DELETE_UPDATE_RESOURCE",
};

export const UPDATE_TASK_ACTIONS = {
    CREATE_TASK: "CREATE_TASK",
    DELETE_TASK: "DELETE_TASK",
    EDIT_TASK: "EDIT_TASK",
};
