import React, { Component } from 'react';

class ContentEditable extends Component {
    constructor() {
        super();
        this.inputRef = React.createRef();
        this.lastHtml = "";
    }

    // shouldComponentUpdate = (nextProps) => {
    //     return nextProps.html !== this.getDOMNode().innerHTML;
    // }

    getDOMNode = () => {
        return this.inputRef.current
    }

    emitChange = () => {
        var html = this.getDOMNode().innerText;
        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange(html);
        }
        this.lastHtml = html;
    }

    render = () => {
        const { label, error } = this.props
        return (
            <div className='form-group mb-3'>
                { label && <label className='form-label text-dark fw-medium '>{label}</label>}
                <div
                    className={`form-control editableInput ${error ? "is-invalid" : ""}`}
                    onInput={this.emitChange}
                    onBlur={this.emitChange}
                    ref={this.inputRef}
                    contentEditable
                    dangerouslySetInnerHTML={{ __html: this.props.html }}>
                </div>
            </div>
        );
    }
}

export default ContentEditable;