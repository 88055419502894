import Heading from "components/ui/Heading/Heading";
import { useSelector } from "react-redux";
import { dataQueryStatus } from "utils";

const UsersHeader = ({ status }) => {
    const { user } = useSelector((state) => state.auth);

    const renderBasedOnStatus = () => {
        switch (status) {
            case dataQueryStatus.DATAMODE:
                return (
                    <>
                        <Heading
                            headingText={"Team members"}
                            headingSubText={"List of invited team members"}
                        />
                    </>
                );
            case dataQueryStatus.NULLMODE:
                return (
                    <>
                        <Heading
                            headingText={`Welcome, ${user.firstName}`}
                            headingSubText={`${user.firstName} now you can invite your team members`}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnStatus()}</>;
};

export default UsersHeader;
