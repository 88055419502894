import React, { useState } from "react";
import TicketFilterSelector from "components/dashboard/AgentInbox/AgentInboxTickets/TicketsFilterGroup/TicketFilterSelector/TicketFilterSelector";
import TicketInfoCapsule from "components/dashboard/KnowledgeBase/common/Capsule/TicketInfoCapsule/TicketInfoCapsule";
import { useSelector } from "react-redux";
import { ticketInfoTypes } from "components/dashboard/AgentInbox/enum";
import AddButton from "components/ui/Button/AddButton/AddButton";
import ItemsList from "components/ui/ItemsList/ItemsList";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import TicketInfoDropdown from "./TicketInfoDropdown/TicketInfoDropdown";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import { getUniqueListBy } from "utils/helper";
import "./TicketInfoList.scss";

const { CAPSULE, FORM } = ticketInfoTypes;
const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const TicketInfoList = ({
    ticketsInfos,
    selectedTicketInfo,
    handleTicketInfoSelect,
    handleToggleTicketInfoModal,
    ticketId,
    handleSuccess,
}) => {
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [showRemoveModal, toggleRemoveModal] = useState(false);
    const [selectedTicketInfoId, setSelectedTicketInfoId] = useState("");

    const handleShowRemoveModal = (selectedTicketInfoId) => {
        setSelectedTicketInfoId(selectedTicketInfoId);
        toggleRemoveModal(true);
    };

    return (
        <div className='ticket__info__list'>
            <ItemsList breakPoint={10}>
                {getUniqueListBy(ticketsInfos, "ticketInfoValue")?.map(
                    (ticketInfo, index) => {
                        const {
                            ticketInfoType,
                            ticketInfoCapsuleClassId,
                            ticketInfoValue,
                            formName,
                            ticketInfoId,
                        } = ticketInfo;
                        const isActive = selectedTicketInfo === ticketInfoValue;
                        const ticketInfoCapsule =
                            ticketInfoType === CAPSULE &&
                            capsuleClasses?.find(
                                (x) =>
                                    x.capsuleClassId ===
                                    ticketInfoCapsuleClassId
                            );

                        const capsuleEntryIdSplit =
                            ticketInfoType === CAPSULE &&
                            ticketInfoValue?.split(",");

                        return ticketInfoType === CAPSULE ? (
                            <>
                                {capsuleEntryIdSplit?.map(
                                    (capsuleEntryId, capsuleIndex) => (
                                        <TicketInfoDropdown
                                            isActive={
                                                selectedTicketInfo ===
                                                capsuleEntryId
                                            }
                                            handleShowRemoveModal={() =>
                                                handleShowRemoveModal(
                                                    ticketInfoId
                                                )
                                            }
                                            firstItem={index === 0}>
                                            <TicketInfoCapsule
                                                key={capsuleIndex}
                                                capsule={ticketInfoCapsule}
                                                capsuleEntryId={capsuleEntryId}
                                                isActive={
                                                    selectedTicketInfo ===
                                                    capsuleEntryId
                                                }
                                                onClick={() =>
                                                    handleTicketInfoSelect(
                                                        capsuleEntryId,
                                                        ticketInfoType,
                                                        ticketInfoCapsule
                                                    )
                                                }
                                                useAsTag={true}
                                                setBorderColor={true}
                                            />
                                        </TicketInfoDropdown>
                                    )
                                )}
                            </>
                        ) : ticketInfoType === FORM ? (
                            <TicketFilterSelector
                                key={index}
                                isActive={isActive}
                                onClick={() =>
                                    handleTicketInfoSelect(
                                        ticketInfoValue,
                                        ticketInfoType
                                    )
                                }
                                label={formName}
                                useAsTag={true}
                            />
                        ) : (
                            ""
                        );
                    }
                )}
            </ItemsList>
            <ComponentAccessControl
                baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                <AddButton
                    btnText='Add New Ticket Info'
                    otherClassName='new__ticket__info__btn'
                    onClick={handleToggleTicketInfoModal}
                />
            </ComponentAccessControl>

            {showRemoveModal && (
                <ConfirmationModal
                    title='Are you sure you want to remove this ticketInfo'
                    show={showRemoveModal}
                    closeModal={() => {
                        setSelectedTicketInfoId("");
                        toggleRemoveModal(false);
                    }}
                    handleSuccess={handleSuccess}
                    url={apiRoute.removeTicketInfo(
                        ticketId,
                        selectedTicketInfoId
                    )}
                    method='delete'
                />
            )}
        </div>
    );
};

export default TicketInfoList;
