import React from "react";
import { Input } from "components/ui";
import "./SearchClassCapsuleInput.scss";

const SearchClassCapsuleInput = ({ handleSearch }) => {
    const handleChange = ({ target: { value } }) => {
        handleSearch(value);
    };

    return (
        <div className='search__class__capsule__input__group'>
            <Input
                grpClass='mb-3 w-100'
                inputClass={"form-control--xsm"}
                type='text'
                name='contentSearch'
                id='contentSearch'
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchClassCapsuleInput;
