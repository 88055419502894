import Cookies from "js-cookie";
import axios from "axios";
import config from "config/config";
import { apiRoute } from "services";
import {
    deleteAccessToken,
    retriveAccessToken,
    retriveRefreshToken,
} from "storage/cookieStorage";
import store from "store/store";
import { logOut } from "utils";

const { refreshTokenEndpoint } = apiRoute;

export const httpSignal = axios.CancelToken.source();
const CancelToken = axios.CancelToken;
let requestSignal;

const { BASE_URL, CLIENT_KEY } = config.apiGateway;
const API = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
});

API.interceptors.request.use(
    (config) => {
        const accessToken = retriveAccessToken();
        config.headers["x-request-client-key"] = CLIENT_KEY;
        const disableAuthorization = config.disableAuthorization;
        if (!disableAuthorization) {
            accessToken &&
                (config.headers["Authorization"] = "Bearer " + accessToken);
        } else {
            delete config?.headers["Authorization"];
        }

        requestSignal = CancelToken.source();
        config.cancelToken = requestSignal.token;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

API.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        const status = error?.response?.status;
        const originalRequest = error.config;
        const { baseURL, url } = originalRequest;
        const requestPATH = url.replace(baseURL, "");
        if (status === 401 && requestPATH === refreshTokenEndpoint) {
            sessionStorage.clear();
            return Promise.reject(error);
        }

        if (status?.toString?.()?.includes?.("50")) {
            error.response.data.message =
                "Something went wrong. Please try again.";
            return Promise.reject(error);
        }

        if (status === 401 && !originalRequest._retry) {
            const { userId } = store.getState().auth.user;
            originalRequest._retry = true;
            return API.post(refreshTokenEndpoint, {
                userId,
                refreshToken: retriveRefreshToken(),
            })
                .then((res) => {
                    if (res.status === 200) {
                        const { accessToken, refreshToken } = res.data;
                        // setAccessToken(accessToken)
                        Cookies.set("accessToken", accessToken);
                        Cookies.set("refreshToken", refreshToken);
                        return API(originalRequest);
                    }
                })
                .catch((err) => {
                    const refreshTokenErrStatus = err.response.status;
                    if (refreshTokenErrStatus === 401) {
                        deleteAccessToken();
                        logOut();
                        return Promise.reject(error);
                    }
                });
        }

        return Promise.reject(error);
    },
    async (error) => {
        const status = error?.response?.status;
        if (status === 401) {
            deleteAccessToken();
            logOut();
            return Promise.reject(error);
        }

        if (status?.toString?.()?.includes?.("50")) {
            error.response.data.message =
                "Something went wrong. Please try again.";
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

export default API;
