import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import AddCapsuleClassForm from "./AddCapsuleClassForm/AddCapsuleClassForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const AddCapsuleClassModal = ({
    showModal,
    closeModal,
    handleCapsulationSuccess,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Add New Class'
                        subTitle='Create a new class'
                    />
                    <AddCapsuleClassForm
                        handleCapsulationSuccess={handleCapsulationSuccess}
                        closeModal={closeModal}
                    />
                </div>
            </div>
        </SideModal>
    );
};

AddCapsuleClassModal.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
};

export default AddCapsuleClassModal;
