import React, { useState } from "react";
import { truncateString } from "utils/helper";

const StartupProfileDescription = ({ description }) => {
    const [showFullDesc, setShowFullDesc] = useState(false);
    const len = 400;

    return (
        <>
            {description && (
                <div className='startup-profile-description'>
                    <h4>Brief Summary</h4>
                    <p>
                        {showFullDesc
                            ? description
                            : truncateString(description, len)}
                    </p>

                    {description?.length >= len && (
                        <button
                            onClick={() => setShowFullDesc((prev) => !prev)}>
                            {showFullDesc ? "Hide" : "Show"} Full Description
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

export default StartupProfileDescription;
