export const SAVE_CLASSIFICATIONS = "SAVE_CLASSIFICATIONS";

export const SAVE_TEAMS = "SAVE_TEAMS";
export const CLEAR_TEAMS = "CLEAR_TEAMS"

export const SAVE_CONVERSATION_TEMPLATES = "SAVE_CONVERSATION_TEMPLATES"

export const SET_SELECTED_MSSG_TAG = "SET_SELECTED_MSSG_TAG";
export const SAVE_MESSAGING_TAGS = "SAVE_MESSAGING_TAGS";
export const SAVE_MESSAGE_TAG_DETAILS = "SAVE_MESSAGE_TAG_DETAILS";
export const REMOVE_MESSAGE_TAG_DETAILS = "REMOVE_MESSAGE_TAG_DETAILS"