import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Loading, Empty } from "components/ui";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import PostFormCollectionsHeader from "./PostFormCollectionsHeader/PostFormCollectionsHeader";
import PostFormCollectionModals from "./PostFormCollectionModals/PostFormCollectionModals";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import PostFormCollectionTable from "./PostFormCollectionTable/PostFormCollectionTable";
import { POST_FORM_MODAL_ACTIONS } from "./enum";
import {
    setPostFormBaseFilter,
    setPostFormFilterParams,
    setPostFormFilterOption,
} from "store/filters/actions";
import {
    savePostFormCollections,
    clearPostFormCollections,
} from "store/modules/knowledgeBase/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;
const { VIEW_POST_FORM_COLLECTION } = POST_FORM_MODAL_ACTIONS;

const PostFormCollections = () => {
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState();
    const [selectedPostFormCollection, setSelectedPostFormCollection] =
        useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.postFormFilters
    );
    const [postFormCollections, setPostFormCollections] = useState([]);
    const {
        postFormCollectionsCache: { pages, totalRecords },
    } = useSelector((state) => state?.knowledgeBase);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();
    const history = useHistory();

    const handleRedirect = (postFormCollection) => {
        history.push({
            pathname: `/t-sam/branch-collection/${postFormCollection?.branchCollectionId}/view`,
            state: {
                branchCollection: postFormCollection,
            },
        });
    };

    const handleActionModal = (actionType, postFormCollection) => {
        if (actionType === VIEW_POST_FORM_COLLECTION) {
            handleRedirect(postFormCollection);
        } else {
            setSelectedPostFormCollection(postFormCollection);
            setActionType(actionType);
            setShowModal(true);
        }
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setPostFormFilterParams({ ...filterParams, search }));
        dispatch(clearPostFormCollections());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setPostFormFilterParams({ ...filterParams, currentPage }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setPostFormBaseFilter(baseFilter));

        dispatch(setPostFormFilterOption({}));
    };

    const getPostFormCollections = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (postFormCollections?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.postFormBranchCollections;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setTableIsLoading(false);
                setStatus(newStatus);
                setPostFormCollections(data);
                dispatch(
                    savePostFormCollections(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setPostFormCollections(cachedPage);
        } else {
            getPostFormCollections(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getPostFormCollections}
                    />
                );

            case NULLMODE:
                return (
                    <Empty
                        text={
                            useTableLoader
                                ? "No post form matches the search"
                                : "No post form has been\ncreated yet."
                        }
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <PostFormCollectionTable
                            postFormCollections={postFormCollections}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleActionModal={handleActionModal}
                        />

                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setPostFormFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setPostFormFilterOption({ ...option, value }));
        dispatch(clearPostFormCollections());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setPostFormFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setPostFormFilterOption({ ...option, value }));
        dispatch(clearPostFormCollections());
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setPostFormFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setPostFormFilterOption({ ...option, value }));
        dispatch(clearPostFormCollections());
    };

    return (
        <div className='main__layout'>
            <PostFormCollectionsHeader
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                handleActionModal={handleActionModal}
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSortByLastModified={handleSortByLastModified}
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchDefaultValue={filterParams?.search}
            />
            {renderBasedOnStatus()}
            {showModal && (
                <PostFormCollectionModals
                    showModal={showModal}
                    actionType={actionType}
                    closeModal={() => setShowModal(false)}
                    handleSuccess={() => {
                        getPostFormCollections();
                        setShowModal(false);
                        dispatch(clearPostFormCollections());
                        // dispatch(
                        //     setPostFormFilterParams({
                        //         search: "",
                        //         currentPage: 1,
                        //         pageSize: 5,
                        //     })
                        // );
                    }}
                    referenceData={selectedPostFormCollection}
                />
            )}
        </div>
    );
};

export default PostFormCollections;
