import React from "react";
import { Input } from "components/ui";
import "./EmailSubject.scss";

const EmailSubject = ({ emailSubject, handleChange, preview }) => {
    return (
        <div className='email__subject'>
            <h3>Subject:</h3>
            <Input
                value={emailSubject}
                onChange={handleChange}
                name='subject'
                grpClass='email__subject__input__group'
                inputClass='email__subject__input'
                disabled={preview}
            />
        </div>
    );
};

export default EmailSubject;
