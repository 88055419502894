import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Button } from "components/ui";
import { STANDARD, WIDE } from "../ImageCropScale/enums";
import { imageCropModes, MOBILE } from "./enums";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ImageCropHeader.scss";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const ImageCropHeader = ({
    cropMode,
    setCropMode,
    setSelectedScale,
    isEdit,
    handleEdit,
    handleOpenDeleteModal,
}) => {
    const handleCropMode = (mode) => {
        setCropMode?.(mode);
        setSelectedScale?.(mode === MOBILE ? STANDARD : WIDE);
    };

    useEffect(() => {
        setSelectedScale?.(cropMode === MOBILE ? STANDARD : WIDE);
        // eslint-disable-next-line
    }, [cropMode]);

    return (
        <div className='image__crop__header'>
            <div>
                <h3 className='image__crop__header--title'>Attach an image</h3>
                <span className='image__crop__header--sub__title'>
                    Select a photo and upload
                </span>
            </div>
            {isEdit ? (
                <div className='crop__actions'>
                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${KB_SAM_BRANCH}`,
                            `${UPDATE}_${KB_SAM_COLLECTION}`,
                            `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                            `${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                        ]}>
                        <Button
                            text='Edit'
                            classType='plain'
                            otherClass='fw-bold edit__image-btn'
                            onClick={handleEdit}
                        />
                    </ComponentAccessControl>
                    <ReactSVG src={imageLinks?.icons?.pipe} />
                    <ComponentAccessControl
                        baseResources={[
                            `${DELETE}_${KB_SAM_BRANCH}`,
                            `${DELETE}_${KB_SAM_COLLECTION}`,
                            `${DELETE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                            `${DELETE}_${KB_SAM_ISSUE_CONVERSATION}`,
                        ]}>
                        <ReactSVG
                            src={imageLinks?.icons?.redTrash}
                            className='crop__actions--delete'
                            onClick={handleOpenDeleteModal}
                        />
                    </ComponentAccessControl>
                </div>
            ) : (
                <div className='crop__mode__container'>
                    <ul className='crop__modes'>
                        {Object.entries(imageCropModes)?.map(
                            ([key, value], i) => (
                                <li
                                    key={i}
                                    className={`crop__mode ${
                                        key === cropMode
                                            ? "active__crop__mode"
                                            : ""
                                    } `}
                                    onClick={() => handleCropMode(key)}>
                                    {value}
                                </li>
                            )
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ImageCropHeader;
