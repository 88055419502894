import AddButton from "components/ui/Button/AddButton/AddButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { UPDATE } = baseResourceActions;

const AddDiscoveryButton = ({ metadata, onClick }) => {
    return (
        <div>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`]}>
                <AddButton
                    btnText={`${
                        metadata?.length > 0 ? `Add Metadata` : `Add Discovery`
                    }`}
                    onClick={onClick}
                />
            </ComponentAccessControl>
        </div>
    );
};

export default AddDiscoveryButton;
