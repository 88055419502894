import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import "./AnalyticsQAVAFilter.scss";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION } from "../../enums";

const AnalyticsQAVAFilter = ({
    handleSearch,
    handleChange,
    searchIsLoading,
    clearSearch,
    filterParams,
}) => {
    const { search, duration } = filterParams || {};

    return (
        <div className='analytics-qava-filter'>
            <PaginationSearch
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                defaultValue={search}
                placeholder={"Search conversations"}
            />

            <SelectUI
                name='duration'
                placeholder='This Week:'
                options={ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION}
                defaultValue={ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION?.find(
                    ({ value }) =>
                        value === duration ||
                        value ===
                            ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION?.[1]?.value
                )}
                onChange={(option) => handleChange(option)}
            />
        </div>
    );
};

export default AnalyticsQAVAFilter;
