import ProductClassesList from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesContainer/ProductClasses/ProductClassesList/ProductClassesList";
import "./ChildrenClassesList.scss";

const ChildrenClassesList = ({
    capsuleClassess,
    capsuleData,
    handleCapsuleClassSelect,
    selectedCapsuleClass,
}) => {
    return (
        <div className='children__capsule__classes'>
            <ProductClassesList
                capsuleClasses={capsuleClassess}
                productId={capsuleData?.productId}
                handleMainCapsuleSelect={handleCapsuleClassSelect}
                selectedCapsuleClass={selectedCapsuleClass}
                capsuleData={capsuleData}
            />
        </div>
    );
};

export default ChildrenClassesList;
