import React from "react";

const StatusCardCount = ({ cardCount, cardText }) => {
    return (
        <p className='mb-0 mt-auto'>
            <span className='status__card__amount'>
                {cardCount >= 0 ? cardCount : "-"}
            </span>{" "}
            <span className='text-grey'>{cardText}</span>
        </p>
    );
};

export default StatusCardCount;
