import { useEffect, useState } from "react";
import { Button, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { apiRoute } from "services";
import API from "services/lib/api";
import { convertHtmlToLiteralString, getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import ConversationTemplateFormIntroField from "./ConversationTemplateFormFields/ConversationTemplateFormIntroField";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const ConversationTemplateForm = ({
    closeModal,
    createTemplateType,
    handleRefresh,
    editTemplateData,
    isEdit,
}) => {
    const [request, setRequest] = useState({
        introText: "Hello",
        mainSentence: "",
        useVariables: true,
        templateType: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createTemplate = async () => {
        try {
            setErrorMsg();
            setLoading(true);
            const url = apiRoute?.getMessageTemplates;
            const res = await API.post(url, {
                ...request,
                templateType: createTemplateType,
            });
            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                setLoading(false);
                closeModal?.();
                handleRefresh();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editTemplate = async () => {
        try {
            setErrorMsg();
            setLoading(true);
            // editMssgTemplate
            const { templateId } = editTemplateData;
            const url = apiRoute?.editMssgTemplate(templateId);
            const res = await API.put(url, { ...request });

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                setLoading(false);
                closeModal?.();
                handleRefresh();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleIntroTextChange = (htmlString) => {
        const introText = convertHtmlToLiteralString(htmlString);
        const useVariables = /\[([^\][]+)]/g.test(htmlString);
        setRequest({ ...request, introText, useVariables });
    };

    useEffect(() => {
        if (isEdit) {
            setRequest({ ...editTemplateData });
        }
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? editTemplate() : createTemplate();
        } else {
            setErrors(formErrors);
        }
    };

    const { introText, mainSentence } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />

            <ConversationTemplateFormIntroField
                introText={introText}
                handleChange={handleIntroTextChange}
                error={errors?.introText}
            />

            <Textarea
                grpClass='mb-3'
                type='text'
                name='mainSentence'
                label='Enter body text'
                labelClass='text-dark fw-medium'
                id='mainSentence'
                placeholder=''
                value={mainSentence}
                onChange={handleChange}
                isErr={errors?.mainSentence}
                errMssg={errors?.mainSentence}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

export default ConversationTemplateForm;
