import { useContext } from "react";
import AttachmentFileInput from "components/ui/AttachmentFileInput/AttachmentFileInput";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { generateID, getErrorMessage } from "utils/helper";
import { getFileFormat } from "utils/helper";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import API from "services/lib/api";
import { apiRoute } from "services";

const { ERROR } = dataQueryStatus;

const FileAttachment = ({
    id,
    handleUploadUpdate,
    handleFileAttachmentChange,
}) => {
    const toastMessage = useContext(ToastContext);

    const uploadMedia = async ({ target: { files } }) => {
        try {
            const file = files[0];
            if (file) {
                handleUploadUpdate({
                    name: file?.name,
                    hasUploaded: false,
                    id,
                });

                const formData = new FormData();
                formData.append("file", file);

                const url = apiRoute.mediaUpload;
                const res = await API.post(url, formData);

                if (res.status === 201) {
                    const { data } = res.data;

                    handleUploadUpdate({
                        mediaName: file?.name,
                        hasUploaded: true,
                        id,
                        public_id: generateID(),
                        link: data,
                    });

                    handleFileAttachmentChange(id, {
                        type: getFileFormat(file?.type),
                        link: data,
                        mediaName: file?.name,
                    });
                }
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };
    return (
        <AttachmentFileInput
            id={id}
            accept='.pdf,.doc,.docx,video/*,image/png,image/jpeg,image/jpg'
            onChange={uploadMedia}>
            <ReactSVG
                src={imageLinks?.icons?.attachmentIcon}
                className='cursor-pointer'
            />
        </AttachmentFileInput>
    );
};

export default FileAttachment;
