import { useState } from "react";
import ExternalEmailList from "../ExternalEmailList/ExternalEmailList";
import ExternalEmailsHeader from "../ExternalEmailsHeader/ExternalEmailsHeader";

import { Empty } from "components/ui";


const ExternalEmailThreads = ({
    emails,
    handleToggleEmailPreview,
    selectedEmailData,
}) => {

    const [show, toggleShow] = useState(false);
   
    return (
        <>
            <ExternalEmailsHeader {...{ show, toggleShow }} />
            {show && <>
                {emails?.length > 0 ? <ExternalEmailList
                    emails={emails}
                    handleToggleEmailPreview={handleToggleEmailPreview}
                    selectedEmailData={selectedEmailData}
                /> : <div className='empty__external__emails__container'>
                    <Empty
                        text='No external email has been sent in this ticket.'
                        className='empty__external__emails'
                    />
                </div>}
            </>}
        </>
    );
};

export default ExternalEmailThreads;
