import React from "react";
import { Modal, SideModal } from "components/ui";
import RolesAndPermissionsActionForms from "./RolesAndPermissionsActionForms/RolesAndPermissionsActionForms";
import { ROLES_PERMS_ACTIONS } from "../../enums";

const RolesAndPermissionsModals = ({
	role,
	currentAction,
	closeModal,
	handleSuccess,
	toastResponseStatus,
}) => {
	const isCenterModal = currentAction === ROLES_PERMS_ACTIONS.DELETE_ROLE;

	const render = () => {
		return (
			<RolesAndPermissionsActionForms
				role={role}
				currentAction={currentAction}
				closeModal={closeModal}
				handleSuccess={handleSuccess}
				toastResponseStatus={toastResponseStatus}
			/>
		);
	};

	return (
		<>
			{isCenterModal ? (
				<Modal show={true} close={closeModal}>
					{render()}
				</Modal>
			) : (
				<SideModal show={true} close={closeModal}>
					{render()}
				</SideModal>
			)}
		</>
	);
};

export default RolesAndPermissionsModals;
