import CRTableForm from "./CRTableForm/CRTableForm";

const CRTableFormsList = ({ forms }) => {
    return (
        <section className='cr-table-forms'>
            {forms?.length > 0 ? (
                <>
                    {forms?.map(({ formName }, key) => (
                        <CRTableForm formName={formName} key={key} />
                    ))}
                </>
            ) : (
                <p>No forms connected</p>
            )}
        </section>
    );
};

export default CRTableFormsList;
