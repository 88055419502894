import React from "react";
import SolidAddButton from "components/ui/Button/SolidAddButton/SolidAddButton";
import TabsLayoutHeading from "components/dashboard/common/TabsLayoutHeading/TabsLayoutHeading";
import { POST_FORM_MODAL_ACTIONS } from "../enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import Filter from "components/dashboard/common/Filter/Filter";
import "./PostFormCollectionsHeader.scss";

const { KB_FORM_SETTING } = baseResources;
const { CREATE } = baseResourceActions;
const { ADD_POST_FORM_COLLECTION } = POST_FORM_MODAL_ACTIONS;

const PostFormCollectionsHeader = ({
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleActionModal,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter, searchDefaultValue
}) => {
    return (
        <div>
            <TabsLayoutHeading
                title={`Post Forms`}
                subTitle={`Add  & change post forms`}
                extraClass={"large"}
            />
            <div className='post__form__collection__header'>
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${KB_FORM_SETTING}`]}>
                    <SolidAddButton
                        btnText='Add New Post Form'
                        onClick={() =>
                            handleActionModal(ADD_POST_FORM_COLLECTION)
                        }
                    />
                </ComponentAccessControl>
            </div>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
                searchPlaceholder='Search post forms here'
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                hideClassFilter
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchDefaultValue={ searchDefaultValue}
            />
        </div>
    );
};

export default PostFormCollectionsHeader;
