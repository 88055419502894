import React from 'react';
import AddButton from 'components/ui/Button/AddButton/AddButton';

const AddIssueConversationButton = ({ handleClick }) => {

    return (
        <>
            <AddButton btnText={'Create Conversation'} onClick={handleClick} />
        </>
    );
};

export default AddIssueConversationButton;