import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Input, InputMultiple } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const VariantForm = ({
    selectedIssue,
    editItem,
    closeModal,
    handleSuccess,
    isEdit,
}) => {
    const [request, setRequest] = useState({
        name: editItem?.name || "",
        metadata: editItem?.metadata || [],
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMultipleInputChange = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const addVariant = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.createIssueVariant(selectedIssue?.issueId);
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editVariant = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.variant(editItem?.variantId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            if (isEdit) {
                return editVariant();
            }
            return addVariant();
        } else {
            setErrors(formErrors);
        }
    };

    const { name, metadata } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='issueId'
                label='Issue'
                labelClass='text-dark fw-medium'
                id='issueId'
                value={selectedIssue?.issueName}
                onChange={handleChange}
                isErr={errors?.issueId}
                errMssg={errors?.issueId}
                disabled={true}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='name'
                label='Variant'
                labelClass='text-dark fw-medium'
                id='name'
                placeholder='Enter name of variant'
                value={name}
                onChange={handleChange}
                isErr={errors?.name}
                errMssg={errors?.name}
                autoFocus={true}
            />
            <InputMultiple
                grpClass='mb-3'
                type='text'
                name='metadata'
                label='Metadata'
                labelClass='text-dark fw-medium'
                id='metadata'
                placeholder='Add metadata'
                inputValue={metadata}
                setInputValue={handleMultipleInputChange}
                isErr={errors?.metadata}
                errMssg={errors?.metadata}
            />
            <Button
                type='submit'
                text='Save'
                loading={loading}
                classType='primary'
                otherClass='my-3 w-100'
            />
        </form>
    );
};

VariantForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
    issues: PropTypes.array.isRequired,
    selectedIssue: PropTypes.string.isRequired,
    editItem: PropTypes.object.isRequired,
};

export default VariantForm;
