import { appMessageSources } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";

const { IN_APP, EMAIL, TWITTER, FACEBOOK_MESSENGER, INSTAGRAM } =
    appMessageSources;

export const ticketSourcesLabel = {
    [IN_APP]: {
        label: "In app",
    },
    [EMAIL]: {
        label: "Email",
    },
    [TWITTER]: {
        label: "Twitter",
    },
    [FACEBOOK_MESSENGER]: {
        label: "Facebook",
    },
    [INSTAGRAM]: {
        label: "Instagram",
    },
};
