import Heading from "components/ui/Heading/Heading";

const FollowUpHeading = () => {
    return (
        <div className='agent-follow-up__heading'>
            <div>
                <Heading
                    headingText='Follow up collections'
                    headingSubText='Add & edit your follow up collections here.'
                />
            </div>
        </div>
    );
};

export default FollowUpHeading;
