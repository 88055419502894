import { useState, useContext } from "react";
import PropTypes from "prop-types";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Button, Input, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { MESSAGE_TAG_ACTIONS } from "components/dashboard/AgentInbox/AgentInboxSettings/MessageTagging/enum";
import { useDispatch } from "react-redux";
import { clearCannedResponses } from "store/modules/knowledgeBase/actions";

const CreateCannedResponseForm = ({
    cannedResponse,
    closeModal,
    selectedMssgTagId,
    handleSuccess,
    currentAction,
}) => {
    const [request, setRequest] = useState({
        title: cannedResponse?.title || "",
        subject: cannedResponse?.subject || "",
        response: cannedResponse?.response || "",
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createResponse = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute?.createCannedResponse;

            const res = await API.post(url, {
                ...request,
                tagId: selectedMssgTagId,
            });
            const data = res.data;

            if (res?.status === 201) {
                // For everytime a canned response is attached to a form, the cached canned response should be cleared
                // in order to ensure that newly updated canned responses data are fetched when the user leaves this place to view them
                dispatch(clearCannedResponses());
                handleSuccess();
                toastMessage(data.message);
                closeModal();
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editResponse = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute.editCannedResponse(
                cannedResponse?.cannedResponseId
            );

            const res = await API.put(url, request);
            const data = res.data;

            if (res?.status === 200) {
                // For everytime a canned response is attached to a form, the cached canned response should be cleared
                // in order to ensure that newly updated canned responses data are fetched when the user leaves this place to view them
                dispatch(clearCannedResponses());
                handleSuccess();
                toastMessage(data.message);
                closeModal();
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "subject",
        ]);
        if (formisValid) {
            if (currentAction === MESSAGE_TAG_ACTIONS.EDIT) {
                return editResponse();
            }
            return createResponse();
        } else {
            setErrors(formErrors);
        }
    };

    const { title, subject, response } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='title'
                label='Title'
                labelClass='text-dark fw-medium'
                id='title'
                placeholder='Enter here'
                value={title}
                onChange={handleChange}
                isErr={errors?.title}
                errMssg={errors?.title}
                autoFocus={true}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='subject'
                label='Subject'
                labelClass='text-dark fw-medium'
                id='subject'
                placeholder='Enter here'
                value={subject}
                onChange={handleChange}
                isErr={errors?.subject}
                errMssg={errors?.subject}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='response'
                label='Enter response'
                labelClass='text-dark fw-medium'
                id='response'
                placeholder='Enter response text'
                value={response}
                onChange={handleChange}
                isErr={errors?.response}
                errMssg={errors?.response}
            />
            <Button
                type='submit'
                text='Save'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

CreateCannedResponseForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default CreateCannedResponseForm;
