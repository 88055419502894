import React, { useState } from "react";
import PropTypes from "prop-types";
import FadeIn from "components/ui/effects/FadeIn";
import { availableInboxApps } from "components/dashboard/AgentInbox/AgentInboxSettings/AppIntegrations/enum";
import InboxAppsButton from "components/dashboard/AgentInbox/AgentInboxSettings/AppIntegrations/AgentInboxApps/InboxApps/AppUsersTable/InboxAppsButton/InboxAppsButton";
import AccordCardItemDetails from "./AccordCardItemDetails/AccordCardItemDetails";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./AccordionCardItem.scss";

const { AGENTS_INBOX_SERVICE_INTEGRATION } = baseResources;
const { CREATE } = baseResourceActions;

const AccordionCardItem = ({
    logoUrl,
    appName,
    appDescription,
    appSource,
    children,
    handleNewConnection,
    user,
    actionType,
    handleSuccess,
    handleActionModal,
    isGmailWebHookConnected,
    ...rest
}) => {
    const [showAccordion, toggleAccordion] = useState(false);

    const handleClick = (source) => {
        if (
            source === availableInboxApps?.GMAIL ||
            source === availableInboxApps?.TWITTER ||
            availableInboxApps?.FACEBOOK
        ) {
            toggleAccordion((prevState) => !prevState);
        }
    };

    return (
        <div
            className={`accordion__card--item ${
                showAccordion ? "active" : ""
            }`}>
            <div
                className='accordion__header'
                onClick={() => handleClick(appSource)}>
                <div className='row'>
                    <AccordCardItemDetails
                        {...{ logoUrl, appDescription, appName }}
                    />
                    <div className='col-2 d-flex align-items-center justify-content-end'>
                        <ComponentAccessControl
                            baseResources={[
                                `${CREATE}_${AGENTS_INBOX_SERVICE_INTEGRATION}`,
                            ]}>
                            <InboxAppsButton
                                actionType={actionType}
                                appSource={appSource}
                                handleNewConnection={handleNewConnection}
                                user={user}
                                handleSuccess={handleSuccess}
                                handleActionModal={handleActionModal}
                                isGmailWebHookConnected={
                                    isGmailWebHookConnected
                                }
                                {...rest}
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
            </div>
            {showAccordion && (
                <FadeIn>
                    <div className='accordion__body'>{children}</div>{" "}
                </FadeIn>
            )}
        </div>
    );
};

AccordionCardItem.propTypes = {
    appDescription: PropTypes.any,
    appName: PropTypes.any,
    children: PropTypes.any,
    logoUrl: PropTypes.any,
};

export default AccordionCardItem;
