import React, { useEffect, useState, useContext } from "react";
import BranchesColumn from "components/dashboard/Branches/BranchesColumn/BranchesColumn";
import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import ConvoMapController from "./ConvoMapController/ConvoMapController";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { branchOptionTypes } from "components/dashboard/Branches/BranchView/BranchOptionView/BranchOption/enum";
import "./ConvoMapView.scss";
import { uniqueColumnElements } from "./utils/helper";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;
const { BRANCH, COLLECTION, FORM, GUIDE_FLOW } = branchOptionTypes;

const ConvoMapView = ({
    mainBranchId,
    refreshConvoMap,
    updateConvoMapBranches,
    selectLastToggledBranchId,
    isAgentGuideView,
}) => {
    const [conversationColumns, setConversationColumns] = useState([]);
    const [connectionPaths, setConnectionPaths] = useState([]);
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const toastMessage = useContext(ToastContext);

    const updateXarrow = useXarrow();

    const handleError = (err) => {
        setErrorMssg(getErrorMessage(err));
        setStatus(ERROR);
    };

    const getBranch = async (branchId) => {
        try {
            const url = apiRoute?.getBranch(branchId);
            const res = await API.get(url);
            if (res.status === 200) {
                const branchElement = res.data.data;
                return branchElement;
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const getFlow = async (guideFlowId) => {
        try {
            const url = apiRoute?.getGuideFlow(guideFlowId);
            const res = await API.get(url);
            if (res.status === 200) {
                const branchElement = res.data.data;
                const guideFlowOptions = branchElement?.guideFlowOptions?.map(
                    ({
                        guideFlowOptionLabel,
                        guideFlowOptionType,
                        guideFlowOptionValue,
                        guideFlowOptionId,
                        guideFlowOptionActionType,
                        ...rest
                    }) => {
                        return {
                            branchOptionLabel: guideFlowOptionLabel,
                            branchOptionType: guideFlowOptionType,
                            branchOptionId: guideFlowOptionId,
                            branchOptionValue: guideFlowOptionValue,
                            branchOptionActionType: guideFlowOptionActionType,
                            ...rest,
                        };
                    }
                );
                return {
                    branchOptions: guideFlowOptions,
                    branchId: branchElement?.guideFlowId,
                    branchName: branchElement?.guideFlowName,
                    ...branchElement,
                };
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const getCollectionBranch = async (
        branchCollectionId,
        previousColumn,
        allConvoColumns
    ) => {
        try {
            const url = apiRoute?.getCollectionBranches(branchCollectionId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                if (data?.[0]) {
                    const newBranch = await getBranch(data?.[0]?.branchId);

                    const updatedConversationColumn = {
                        ...allConvoColumns[previousColumn],
                        columnElements: allConvoColumns[
                            previousColumn
                        ]?.columnElements
                            ?.filter((branch) => branch !== undefined)
                            ?.map((columnElement) => ({
                                ...columnElement,
                                element: {
                                    ...columnElement?.element,
                                    branchOptions:
                                        columnElement?.element?.branchOptions?.map(
                                            (branchOption) => {
                                                return branchOption?.branchOptionValue ===
                                                    branchCollectionId
                                                    ? {
                                                        ...branchOption,
                                                        branchOptionValue:
                                                            data?.[0]
                                                                ?.branchId,
                                                        //   change branchOptionType from collection to branch because of next iteration
                                                        branchOptionType:
                                                            BRANCH,
                                                    }
                                                    : branchOption;
                                            }
                                        ),
                                },
                            })),
                    };

                    allConvoColumns[previousColumn] = updatedConversationColumn;

                    setConversationColumns(allConvoColumns);

                    return newBranch;
                }

                return null;
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const coupleNextColumn = async (
        newColumn,
        branchOptions,
        allConvoColumns
    ) => {
        try {
            if (branchOptions) {
                const pArray = branchOptions?.map(
                    async ({
                        branchOptionType,
                        branchOptionValue,
                        guideFlowOptionType,
                        guideFlowOptionValue,
                    }) => {
                        if (
                            (guideFlowOptionType === GUIDE_FLOW &&
                                guideFlowOptionValue !== null) || (
                                branchOptionType === GUIDE_FLOW &&
                                branchOptionValue !== null
                            )

                        ) {
                            const newBranch = await getFlow(
                                guideFlowOptionValue || branchOptionValue
                            );

                            

                            if (newBranch) {
                                return {
                                    elementType: GUIDE_FLOW,
                                    element: newBranch,
                                };
                            }

                            return undefined;
                        }

                        if (
                            branchOptionType === BRANCH &&
                            branchOptionValue !== null
                        ) {
                            const newBranch = await getBranch(
                                branchOptionValue
                            );

                            if (newBranch) {
                                return {
                                    elementType: branchOptionType,
                                    element: newBranch,
                                };
                            }

                            return undefined;
                        }

                        if (
                            branchOptionType === COLLECTION &&
                            branchOptionValue !== null
                        ) {
                            const newBranch = await getCollectionBranch(
                                branchOptionValue,
                                newColumn - 1,
                                allConvoColumns
                            );

                            if (newBranch) {
                                return {
                                    elementType: BRANCH,
                                    element: newBranch,
                                };
                            }

                            return undefined;
                        }
                    }
                );

                const columnElements = await Promise.all(pArray);

                const conversationColumnsCopy = [...allConvoColumns];
                conversationColumnsCopy[newColumn] = {
                    selectedBranchId: "",
                    selectedBranchOptionId: "",
                    columnElements: uniqueColumnElements(columnElements),
                };

                setConversationColumns(conversationColumnsCopy);
            } else {
                const conversationColumnsCopy = [...allConvoColumns];

                conversationColumnsCopy[newColumn] = {
                    selectedBranchId: "",
                    selectedBranchOptionId: "",
                    columnElements: [],
                };
                setConversationColumns(conversationColumnsCopy);
            }
        } catch (err) {
            // handleError(err)
        }

        // setConversationColumns([...conversationColumns, [{ isLoading: true}]])
    };

    const getInitialTree = async () => {
        try {
            if (isAgentGuideView && !mainBranchId) {
                setStatus(DATAMODE);
                return "";
            }
            if (!mainBranchId) {
                setStatus(IDLE);
                return "";
            }

            setConnectionPaths([]);

            if (isAgentGuideView) {
                const branchElement = await getFlow(mainBranchId);
                const result = [
                    {
                        selectedBranchId: branchElement?.branchId,
                        selectedBranchOptionId: "",
                        columnElements: [
                            { elementType: BRANCH, element: branchElement },
                        ],
                    },
                    { isLoading: true },
                ];
                setConversationColumns(result);
                coupleNextColumn(
                    1,
                    branchElement?.guideFlowOptions,
                    result
                );
                setStatus(DATAMODE);
            } else {
                const url = apiRoute?.getBranch(mainBranchId);
                const res = await API.get(url);
                if (res.status === 200) {
                    const branchElement = res.data.data;
                    // const result = [
                    //     {
                    //         selectedBranchId: isAgentGuideView
                    //             ? branchElement?.guideFlowId
                    //             : branchElement?.branchId,
                    //         selectedBranchOptionId: "",
                    //         columnElements: [
                    //             {
                    //                 elementType: isAgentGuideView
                    //                     ? GUIDE_FLOW
                    //                     : BRANCH,
                    //                 element: branchElement,
                    //             },
                    //         ],
                    //     },
                    //     { isLoading: true },
                    // ];

                    const result = [
                        {
                            selectedBranchId: branchElement?.branchId,
                            selectedBranchOptionId: "",
                            columnElements: [
                                { elementType: BRANCH, element: branchElement },
                            ],
                        },
                        { isLoading: true },
                    ];
                    setConversationColumns(result);
                    coupleNextColumn(
                        1,
                        branchElement?.branchOptions,
                        result
                    );
                    setStatus(DATAMODE);
                }
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleOptionSelect = (columnNo, branchId, selectedBranchOptionId) => {
        // optionLeadsTo = this.branch(branchId).branchOptions.find((x) => x.branchOptionId === selectedBranchOptionId)
        // optionLeadsToBranch --> Options =  optionLeadsToBranch.options //
        // push to Branch List --> Options.map((option) => getBranch(option.optionValue))
        const conversationColumnsCopy = [...conversationColumns];
        // half the result to pick from index not greater than 3 columns
        //  barely to expland the map
        conversationColumnsCopy.length = columnNo + 2;
        // set State so it doesn't show more than 3 columns from option selected
        setConversationColumns(conversationColumnsCopy);

        // 
        // in the current column, set selected branch Option
        conversationColumnsCopy[columnNo] = {
            ...conversationColumnsCopy[columnNo],
            selectedBranchOptionId,
        };

        // run through the list of options of a current branch, to find 
        // full options object :
        // optionLeadsTo = this.branch(branchId).branchOptions.find((x) => x.branchOptionId === selectedBranchOptionId)

        const originalParentOption = conversationColumnsCopy[
            columnNo
        ]?.columnElements
            ?.find(({ element }) => element?.branchId === branchId)
            ?.element?.branchOptions?.find(
                (x) => x.branchOptionId === selectedBranchOptionId
            );

        const selectedBranchOptions = conversationColumnsCopy[
            columnNo + 1
        ]?.columnElements
            ?.filter((branch) => branch !== undefined)
            ?.find(
                ({ element }) =>
                    element?.branchId ===
                    originalParentOption?.branchOptionValue
            )?.element?.branchOptions;

        conversationColumnsCopy[columnNo + 1] = {
            ...conversationColumnsCopy[columnNo + 1],
            // forms doesn't appear on the map, connection path should not be created
            // branchOptionType will only be equals collection if the collection has no branch, connection path should not be created
            selectedBranchId:
                originalParentOption?.branchOptionType === FORM ||
                    originalParentOption?.branchOptionType === COLLECTION
                    ? null
                    : originalParentOption?.branchOptionValue,
            selectedBranchOptionId: "",
        };
        const newConvoTree = [...conversationColumnsCopy, { isLoading: true }];

        setConversationColumns(newConvoTree);

        const connectionPaths = newConvoTree.slice(1).map((a, i) => {
            return a?.selectedBranchId
                ? [
                    `${i}_${newConvoTree[i]?.selectedBranchId}`,
                    `${i + 1}_${a?.selectedBranchId}`,
                ]
                : [];
        });

        setConnectionPaths(connectionPaths);

        coupleNextColumn(
            newConvoTree.length - 1,
            selectedBranchOptions,
            newConvoTree
        );
    };

    useEffect(() => {
        let convosList = [];
        conversationColumns
            ?.map?.(({ columnElements }) => {
                return columnElements?.map?.(({ elementType, element }) => {
                    if (elementType === "BRANCH") {
                        convosList.push(element);
                        return element;
                    } else return "";
                });
            })
            ?.flat?.();

        updateConvoMapBranches?.(convosList);
        // eslint-disable-next-line
    }, [conversationColumns]);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <div className='convo__map'>
                            <div>
                                <Xwrapper>
                                    <div
                                        className='row meta__container horizontal-scrollable'
                                        onScroll={updateXarrow}>
                                        <div className='row flex-nowrap'>
                                            {conversationColumns?.map(
                                                (entry, index) => {
                                                    const {
                                                        selectedBranchId,
                                                        selectedBranchOptionId,
                                                        columnElements,
                                                        isLoading,
                                                    } = entry;

                                                    return (
                                                        <div
                                                            className='col-3 px-0'
                                                            key={index}>
                                                            <BranchesColumn
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                isDisabled={
                                                                    index >=
                                                                    conversationColumns.length -
                                                                    1
                                                                }
                                                                branches={
                                                                    columnElements
                                                                }
                                                                selectedBranchId={
                                                                    selectedBranchId
                                                                }
                                                                selectedBranchOptionId={
                                                                    selectedBranchOptionId
                                                                }
                                                                columnNo={index}
                                                                handleOptionSelect={
                                                                    handleOptionSelect
                                                                }
                                                                selectLastToggledBranchId={
                                                                    selectLastToggledBranchId
                                                                }
                                                                isAgentGuideView={
                                                                    isAgentGuideView
                                                                }
                                                            />
                                                            <div className='convo__count'>
                                                                {" "}
                                                                <div className='count__span'>{`${index + 1
                                                                    } / ${conversationColumns?.length
                                                                    }`}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        {connectionPaths.map(
                                            (connectionPath, index) => (
                                                <Xarrow
                                                    key={index}
                                                    start={connectionPath[0]}
                                                    end={connectionPath[1]}
                                                    showTail={true}
                                                    color='#25BB87'
                                                    strokeWidth='1'
                                                    headShape='arrow1'
                                                    tailShape='arrow1'
                                                    startAnchor='right'
                                                    endAnchor='left'
                                                    zIndex={0}
                                                />
                                            )
                                        )}
                                    </div>
                                </Xwrapper>
                            </div>
                        </div>
                        <ConvoMapController columns={conversationColumns} />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getInitialTree}
                    />
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        getInitialTree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainBranchId, refreshConvoMap, isAgentGuideView]);

    return <div>{renderCompBasedOnStage()}</div>;
};

export default ConvoMapView;
