import axios from "axios";
import { getToken, logOut } from "utils"
import config from "config/config";

const customAxios = axios.create({
	baseURL: config?.apiGateway?.BASE_URL,
	headers: {
		"Content-Type": "application/json",
		accept: "application/json",
		"x-request-client-key": config?.apiGateway?.CLIENT_KEY
	},
});

const requestHandler = (request) => {
	const token = getToken()
	if (token) request.headers.Authorization = `Bearer ${token}`

	return request;
};

const responseHandler = (response) => {
	if (response.status === 401) {
		logOut();
	}

	return response?.data;
};

const errorHandler = (error) => {
	if (error?.response?.status === 401 || error?.response?.status === 403) {
		logOut();
	}

	return Promise.reject(error);
};

customAxios.interceptors.request.use(
	(request) => requestHandler(request),
	(error) => errorHandler(error)
);

customAxios.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error)
);

export default customAxios;