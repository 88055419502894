import React from "react";
import { POST_FORM_MODAL_ACTIONS } from "../enum";
import PostFormCollectionModal from "./PostFormCollectionModal/PostFormCollectionModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const {
    ADD_POST_FORM_COLLECTION,
    EDIT_POST_FORM_COLLECTION,
    DELETE_POST_FORM_COLLECTION,
} = POST_FORM_MODAL_ACTIONS;

const PostFormCollectionModals = ({
    showModal,
    actionType,
    closeModal,
    handleSuccess,
    referenceData,
}) => {
    const renderBasedOnActionType = () => {
        switch (actionType) {
            case ADD_POST_FORM_COLLECTION:
            case EDIT_POST_FORM_COLLECTION:
                return (
                    <PostFormCollectionModal
                        showModal={showModal}
                        closeModal={closeModal}
                        isEdit={actionType === EDIT_POST_FORM_COLLECTION}
                        handleSuccess={handleSuccess}
                        referenceData={referenceData}
                    />
                );

            case DELETE_POST_FORM_COLLECTION:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${referenceData?.branchCollectionName}`}
                        show={showModal}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.deleteBranchCollection(
                            referenceData?.branchCollectionId
                        )}
                        method='delete'
                    />
                );

            default:
                return "";
        }
    };

    return <>{renderBasedOnActionType()}</>;
};

export default PostFormCollectionModals;
