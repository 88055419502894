export const singleAddCapsuleMutation = (collectionName, collectionKey) => {
    return `mutation MyMutation($productId: String!, $workspaceId: String!, $content: String!, $contentType: String!, $contentId: uuid! )  {
    ${collectionName}(objects: {content: $content, contenttype: $contentType, productid: $productId, workspaceid: $workspaceId, enabled: true, ${collectionKey}: $contentId}) {
      returning { 
        content
        contenttype
        ${collectionKey}
      }
    }
  }`;
};

export const singleAddChildCapsuleMutation = (
    combinedCollectionName,
    parentCollectionKey,
    childCollectionName,
    childCollectionKey
) => {
    return `mutation MyMutation($productId: String!, $workspaceId: String!, $content: String!, $contentType: String!, $capsuleId: uuid!, $contentId: uuid! )  {
    ${combinedCollectionName}(object: {${parentCollectionKey}: $capsuleId, ${childCollectionName}: {data: {content: $content, contenttype: $contentType, productid: $productId,  workspaceid: $workspaceId, enabled: true, ${childCollectionKey}: $contentId}}}) {
      ${parentCollectionKey}
      ${childCollectionName} {
        content
        contenttype
        enabled
        ${childCollectionKey}
      }
    }
  }`;
};

export const relateChildCapsuleMutation = (
    relationCollectionName,
    mainCollectionKey,
    secCollectionName,
    secCollectionKey
) => {
    return `mutation MyMutation ($capsuleId: uuid!, $contentId: uuid! ) {
      insert_${relationCollectionName}(objects: {${mainCollectionKey}: $capsuleId, ${secCollectionKey}: $contentId}) {
      returning {
        ${secCollectionName} {
          content
          contenttype
          enabled
          ${secCollectionKey}
        }
      }
    }
  }
  `;
};

export const unRelateChildCapsuleMutation = (
    relationCollectionName,
    mainCollectionName,
    mainCollectionKey,
    secCollectionName,
    secCollectionKey
) => {
    return `mutation MyMutation ($capsuleId: uuid!, $contentId: uuid! ) {
      delete_${relationCollectionName}(where: {${mainCollectionKey}: {_eq: $capsuleId},  ${secCollectionKey}: {_eq: $contentId}}) {
    returning {
      ${mainCollectionName} {
        content
        contenttype
      }
      ${secCollectionName} {
        content
        contenttype
      }
    }
  }
}`;
};

export const singleAddParentCapsuleMutation = (
    combinedCollectionName,
    parentCollectionKey,
    parentCollectionName,
    childCollectionName,
    childCollectionKey
) => {
    return `mutation MyMutation($productId: String!, $workspaceId: String!, $content: String!, $contentType: String!, $capsuleId: uuid!, $contentId: uuid! )  {
    ${combinedCollectionName}(object: {${childCollectionKey}: $capsuleId, ${parentCollectionName}: {data: {content: $content, contenttype: $contentType, productid: $productId,  workspaceid: $workspaceId, enabled: true, ${parentCollectionKey}: $contentId}}}) {
      ${childCollectionKey}
      ${parentCollectionName} {
        content
        contenttype
        enabled
        ${parentCollectionKey}
      }
    }
  }`;
};

export const generateDeleteClassCapsuleMutation = (collectionName) => {
    return `mutation MyMutation($contentId: uuid! )  {
    delete_${collectionName}_by_pk(${collectionName}id: $contentId) {
      content
    }
  }`;
};

export const editCapsuleMutation = (collectionName, collectionKey) => {
    return `mutation MyMutation($contentId: uuid!, $content: String!) {
  update_${collectionName}_by_pk(pk_columns: {${collectionKey}: $contentId}, _set: {content: $content}) {
    content
    contenttype
    ${collectionKey}
  }
}`;
};
