import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { POST_FORM_BRANCHES_ACTIONS } from "../enums";
import FormsPFBranchesActionDropdownAddButton from "./FormsPFBranchesActionDropdownAddButton/FormsPFBranchesActionDropdownAddButton";
import { Dropdown } from "components/ui";
import { baseResources, baseResourceActions } from "routes/enum";
import "./FormsPFBranchesActionDropdown.scss";

const { KB_FORM_SETTING } = baseResources;
const { READ, DELETE } = baseResourceActions;
const { VIEW_FORM, DELETE_FORM } = POST_FORM_BRANCHES_ACTIONS;

const FormsPFBranchesActionDropdown = ({
    form,
    handleActionModal,
    referenceData,
    handleRowAction,
}) => {
    return (
        <div className='action__block forms__pf-action'>
            <div className='forms__pf__action__button'>
                <FormsPFBranchesActionDropdownAddButton
                    referenceData={referenceData}
                    handleRowAction={handleRowAction}
                />
            </div>
            <Dropdown
                size='sm'
                placeholder={
                    <ReactSVG
                        src={imageLinks?.icons?.carbonMore}
                        className='d-inline-flex'
                    />
                }
                ddValueClass='borderless'
                className='table__dropdown'
                ddOptionsClass='table__dropdown'
                anchor={false}
                changeValue={false}
                onChange={({ value }) => handleActionModal(value, form)}
                options={[
                    {
                        label: "View Form",
                        value: VIEW_FORM,
                        baseResources: [`${READ}_${KB_FORM_SETTING}`],
                    },
                    {
                        label: "Delete Form",
                        value: DELETE_FORM,
                        baseResources: [`${DELETE}_${KB_FORM_SETTING}`],
                    },
                ]}
            />
        </div>
    );
};

export default FormsPFBranchesActionDropdown;
