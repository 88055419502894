import React from "react";
import {
    CREATE_NEW_BRANCH_COLLECTION,
    DELETE_BRANCH_COLLECTION,
} from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/AddBranchCollectionForm/enums";
import AddBranchCollectionModal from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/AddBranchCollectionModal";
import { SOLUTIONS_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const SCollectionActionsModal = ({
    show,
    closeModal,
    modalAction,
    referenceData,
    handleCollectionDelete,
}) => {
    const renderBasedOnActionType = () => {
        switch (modalAction) {
            case CREATE_NEW_BRANCH_COLLECTION:
                return (
                    <AddBranchCollectionModal
                        show={show}
                        closeModal={closeModal}
                        referenceData={referenceData}
                        source={SOLUTIONS_COLLECTION}
                        defaultActionType={modalAction}
                    />
                );
            case DELETE_BRANCH_COLLECTION:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${referenceData?.branchCollection?.branchCollectionName}`}
                        show={show}
                        closeModal={closeModal}
                        handleSuccess={() =>
                            handleCollectionDelete(
                                referenceData?.classCapsuleEntryId
                            )
                        }
                        url={apiRoute?.deleteBranchCollection(
                            referenceData?.branchCollection?.branchCollectionId
                        )}
                        method='delete'
                    />
                );

            default:
                break;
        }
    };

    return <>{renderBasedOnActionType()}</>;
};

export default SCollectionActionsModal;
