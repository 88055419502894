import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import DependencyForm from "./DependencyForm/DependencyForm";

const DependencyModal = ({
    showModal,
    closeModal,
    getDependencies,
    dependencyName,
    dependencyId,
    dependencyElements,
    isEdit
}) => {
    const handleSuccess = () => {
        getDependencies()
        closeModal?.();
    };
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={`${isEdit ? 'Edit dependency' : 'Create dependency'}`}
                            subTitle={`${isEdit ? 'Edit dependency here' : 'Create a new dependency here'}`}
                        />
                        <DependencyForm
                            handleSuccess={handleSuccess}
                            dependencyName={dependencyName}
                            dependencyId={dependencyId}
                            dependencyElements={dependencyElements}
                            isEdit={isEdit}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default DependencyModal;
