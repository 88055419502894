import React, { useEffect, useState } from "react";
import ProductClasses from "./ProductClasses/ProductClasses";
import RelatedClasses from "./RelatedClasses/RelatedClasses";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { saveCapsuleClassess } from "store/capsule/actions";
import { connect, useSelector } from "react-redux";
import "./ProductClassesContainer.scss";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const ProductClassesContainer = ({
    selectedProduct,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    setSelectedCapsuleClass,
    setSelectedSecCapsuleClass,
    saveCapsuleClassess,
    capsuleRenderCount,
    useClassesFilter,
    filterWithMainCapsuleCodes,
    filterWithSecCapsuleCodes,
    activePrimaryClass,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [relatedStatus, updateRelationStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [filteredCapsuleClasses, setFilteredCapsuleClasses] = useState([]);
    const { capsuleClasses } = useSelector((state) => state.capsule);

    const filterCapsuleClasses = (data) => {
        if (useClassesFilter) {
            const filteredData = data?.filter((capsuleClass) =>
                filterWithMainCapsuleCodes?.includes(capsuleClass?.capsuleCode)
            );
            setFilteredCapsuleClasses(filteredData);
            setSelectedCapsuleClass(filteredData?.[0]);
        } else {
            const initialSelectedPrimaryClass = data?.[0];

            const newActiveCapsuleClass =
                activePrimaryClass &&
                Object?.keys(activePrimaryClass)?.length > 0
                    ? activePrimaryClass
                    : initialSelectedPrimaryClass;
            setSelectedCapsuleClass(newActiveCapsuleClass);
        }
        const newStatus = data.length > 0 ? DATAMODE : NULLMODE;
        setStatus(newStatus);
        updateRelationStatus(DATAMODE);
    };

    const getCapsuleClasses = async () => {
        try {
            updateRelationStatus(LOADING);
            setStatus(LOADING);
            const url = apiRoute?.getCapsuleClasses;

            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;
                saveCapsuleClassess(data);
                filterCapsuleClasses(data);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            updateRelationStatus(IDLE);
        }
    };

    const handleMainCapsuleSelect = (capsule) => {
        setSelectedCapsuleClass(capsule);
        updateRelationStatus(DATAMODE);
    };

    const handleSecondaryCapsuleSelect = (capsule) => {
        setSelectedSecCapsuleClass(capsule);
    };

    useEffect(() => {
        if (capsuleClasses?.length > 0) {
            filterCapsuleClasses(capsuleClasses);
        } else {
            getCapsuleClasses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='product__classes__container'>
            <div className='col-6'>
                <ProductClasses
                    selectedProduct={selectedProduct}
                    capsuleClasses={
                        useClassesFilter
                            ? filteredCapsuleClasses
                            : capsuleClasses
                    }
                    handleMainCapsuleSelect={handleMainCapsuleSelect}
                    selectedCapsuleClass={selectedCapsuleClass}
                    status={status}
                    errorMssg={errorMssg}
                    capsuleRenderCount={capsuleRenderCount}
                    handleRetry={getCapsuleClasses}
                />
            </div>
            <div className='col-6'>
                <RelatedClasses
                    key={selectedCapsuleClass?.capsuleClassId}
                    status={relatedStatus}
                    selectedProduct={selectedProduct}
                    selectedCapsuleClass={selectedCapsuleClass}
                    selectedSecCapsuleClass={selectedSecCapsuleClass}
                    handleSecondaryCapsuleSelect={handleSecondaryCapsuleSelect}
                    capsuleRenderCount={capsuleRenderCount}
                    useClassesFilter={useClassesFilter}
                    filterWithSecCapsuleCodes={filterWithSecCapsuleCodes}
                />
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        capsuleClasses: state?.capsule?.capsuleClasses,
        activePrimaryClass: state?.capsule?.activePrimaryClass,
    }),
    { saveCapsuleClassess }
)(ProductClassesContainer);
