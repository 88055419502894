import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";
import PropTypes from "prop-types";
import { dataQueryStatus } from "utils";
import "./ProductClassesHeader.scss";

const { LOADING } = dataQueryStatus;

const ProductClassesHeader = ({ status, title, numberOfClasses }) => {
    return (
        <h3 className='product__classes__header'>
            {status === LOADING ? (
                <ShinyLoader width='120px' height='16px' />
            ) : (
                title
            )}
            <span className='product__classes__header--badge'>
                {numberOfClasses}
            </span>
        </h3>
    );
};

ProductClassesHeader.propTypes = {
    numberOfClasses: PropTypes.number.isRequired,
};

export default ProductClassesHeader;
