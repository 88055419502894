import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "components/ui";
import AddProductButton from "./AddProductButton/AddProductButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ProductDashboardHeader.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const ProductDashboardHeader = ({
    products,
    selectedProduct,
    retrieveProducts,
    handleProductSelect,
}) => {
    return (
        <div className='product__dashboard__header meta__container'>
            <div className='product__dashboard__header--header__items'>
                <div>
                    <Dropdown
                        isDefault={false}
                        size='md'
                        options={products?.map((item) => ({
                            value: item?.productId,
                            label: item?.productName,
                        }))}
                        ddValue={{
                            value: selectedProduct?.productId,
                            label: selectedProduct?.productName,
                        }}
                        onChange={handleProductSelect}
                    />
                </div>

                <div>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_PRODUCT_SETTING}`]}>
                        <AddProductButton
                            retrieveProducts={retrieveProducts}
                            handleProductSelect={handleProductSelect}
                        />
                    </ComponentAccessControl>
                </div>
            </div>
        </div>
    );
};

ProductDashboardHeader.prototypes = {
    products: PropTypes.array.isRequired,
};

export default ProductDashboardHeader;
