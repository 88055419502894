import { apiRoute } from "services";
import API from "services/lib/api";
import ConnectButton from "../common/ConnectButton/ConnectButton";
import { inboxAppsActionTypes } from "../enum";
import { googleLogout } from "@react-oauth/google";

import GoogleIntegrationLogin from "./GoogleIntegrationLogin/GoogleIntegrationLogin";

const GoogleIntegration = ({
    handleNewConnection,
    handleSuccess,
    handleError,
    actionType,
    user,
    appUserId,
    handleActionModal,
    hasWebHook,
}) => {
    const { workspace } = user;

    const onLogout = async () => {
        try {
            handleNewConnection("Gmail", inboxAppsActionTypes?.DISCONNECT);
            const url = apiRoute?.disconnectInboxApp(appUserId);
            const res = await API.delete(url);
            if (res.status === 200) {
                googleLogout();
                handleSuccess?.();
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            {actionType === inboxAppsActionTypes.CONNECT ? (
                hasWebHook ? (
                    <GoogleIntegrationLogin
                        {...{
                            handleNewConnection,
                            handleError,
                            handleSuccess,
                            workspace,
                        }}>
                        <ConnectButton />
                    </GoogleIntegrationLogin>
                ) : (
                    <ConnectButton
                        onClick={handleActionModal}
                        buttonText='Connect'
                    />
                )
            ) : (
                <ConnectButton onClick={onLogout} buttonText='Disconnect' />
            )}
        </>
    );
};

export default GoogleIntegration;
