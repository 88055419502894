import React from "react";
import ImageCropHeader from "../ImageCropHeader/ImageCropHeader";
import ImageCropButtons from "../ImageCropButtons/ImageCropButtons";
import { branchImageActions } from "../../enums";
import "./ImageCropViewer.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const { ATTACH_IMAGE } = branchImageActions;

const ImageCropViewer = ({
    referenceData,
    closeModal,
    setEditImageUrl,
    setCropImageAction,
    handleOpenDeleteModal,
}) => {
    const handleEditImage = () => {
        setEditImageUrl(referenceData?.branchMainSentenceImgUrl);
        setCropImageAction(ATTACH_IMAGE);
    };

    return (
        <>
            <ImageCropHeader
                isEdit={true}
                handleEdit={handleEditImage}
                handleOpenDeleteModal={handleOpenDeleteModal}
            />
            <ImageView
                src={referenceData?.branchMainSentenceImgUrl}
                className='view__image'
                alt='Cropped View'
            />
            <ImageCropButtons
                closeModal={closeModal}
                isEdit={true}
                disable={false}
                handleContinue={() => setCropImageAction(ATTACH_IMAGE)}
            />
        </>
    );
};

export default ImageCropViewer;
