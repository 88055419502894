import { useCallback, useEffect } from "react";

const useObserver = (handleIntersecting, ref, next, loading, clear) => {
    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (target.isIntersecting && next) {
                !loading && handleIntersecting(next);
            }
        },
        [next, loading, handleIntersecting]
    );

    useEffect(() => {
        let loader = ref?.current;
        const option = {
            root: null,
            rootMargin: "0px",
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);

        if (loader && !clear) observer.observe(loader);

        // clean up on willUnMount
        return () => observer.unobserve(loader);
        // eslint-disable-next-line
    }, [ref, handleObserver]);
    // react-hooks/exhaustive-deps
    return;
};

export default useObserver;
