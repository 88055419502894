import { SideModal } from "components/ui";
import {
    BRANCH_CANNED_ACTIONS,
    BRANCH_CANNED_MESSAGE_OPTIONS,
    BRANCH_CANNED_TYPES_NAMES,
} from "../../enum";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

import { useState } from "react";
import AddCannedMessageOption from "./AddCannedMessageOption/AddCannedMessageOption";
import CannedMessageForm from "./CannedMessageForm/CannedMessageForm";
import AddCannedUpdateForm from "./AddCannedUpdateForm/AddCannedUpdateForm";

const { USE_EXISTING, ADD_NEW, NEW_UPDATE } = BRANCH_CANNED_MESSAGE_OPTIONS;

const { EDIT } = BRANCH_CANNED_ACTIONS;

const AddCannedMessageModal = ({
    showModal,
    modalAction,
    cannedModalType,
    handleClose,
    handleSuccess,
    setCannedModalType,
    guideFlowId,
    selectedCannedResourceId,
    isAddCannedUpdate,
    selectedAgentUpdateId,
}) => {
    const isEdit = modalAction === EDIT;

    const [cannedMessageAction, setCannedMessageAction] = useState();

    const modalCannedTypeName = BRANCH_CANNED_TYPES_NAMES[cannedModalType];

    const modalCannedTypeName_S =
        BRANCH_CANNED_TYPES_NAMES[`${cannedModalType}_S`];

    const useExisting = cannedMessageAction === USE_EXISTING;
    const isNew = cannedMessageAction === ADD_NEW;
    const isUpdate = cannedMessageAction === NEW_UPDATE;

    const handleSelectOption = (value) => {
        setCannedMessageAction(value);

        if (useExisting) {
            setCannedMessageAction(ADD_NEW);
        }
    };

    return (
        <SideModal show={showModal} close={handleClose}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isUpdate
                                ? `New ${modalCannedTypeName_S} Update`
                                : isEdit
                                ? `Update ${modalCannedTypeName}`
                                : `${
                                      useExisting
                                          ? "Existing"
                                          : isNew
                                          ? "New"
                                          : "Add"
                                  } ${modalCannedTypeName}`
                        }
                    />

                    {isEdit && !useExisting && !isNew && (
                        <AddCannedMessageOption
                            cannedModalType={cannedModalType}
                            setCannedModalType={setCannedModalType}
                        />
                    )}
                    {isAddCannedUpdate ? (
                        <AddCannedUpdateForm
                            handleSuccess={handleSuccess}
                            isEdit={isEdit}
                            closeModal={handleClose}
                            isNew={isNew}
                            useExisting={useExisting}
                            isUpdate={isUpdate}
                            handleSelectOption={handleSelectOption}
                            cannedModalType={cannedModalType}
                            guideFlowId={guideFlowId}
                            selectedCannedResourceId={selectedCannedResourceId}
                            selectedAgentUpdateId={selectedAgentUpdateId}
                        />
                    ) : (
                        <CannedMessageForm
                            handleSuccess={handleSuccess}
                            isEdit={isEdit}
                            closeModal={handleClose}
                            isNew={isNew}
                            useExisting={useExisting}
                            isUpdate={isUpdate}
                            handleSelectOption={handleSelectOption}
                            cannedModalType={cannedModalType}
                            guideFlowId={guideFlowId}
                            selectedCannedResourceId={selectedCannedResourceId}
                        />
                    )}
                </div>
            </div>
        </SideModal>
    );
};

export default AddCannedMessageModal;
