import React, { useState, useContext, useEffect } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage, convertCommentMentions } from "utils/helper";
import { dataQueryStatus } from "utils";
import CreateCommentButton from "./CreateCommentButton/CreateCommentButton";
import { useSelector } from "react-redux";
import imageLinks from "assets/images";
import CreateCommentInput from "./CreateCommentInput/CreateCommentInput";
import ImageView from "components/essentials/ImageView/ImageView";
import "./CreateComment.scss";

const { ERROR } = dataQueryStatus;

const CreateComment = ({
    ticketId,
    currentComment,
    handleSuccess,
    setCurrentComment,
    isEdit,
    setIsEdit,
}) => {
    const [request, setRequest] = useState({
        comment: "",
        ticketId,
        mentioned: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const {
        user: { imageUrl },
    } = useSelector((state) => state.auth);
    const toastMessage = useContext(ToastContext);

    useEffect(() => {
        setRequest({
            ...request,
            ticketId,
            comment: currentComment?.comment || "",
            mentioned:
                currentComment?.mentioned?.map((mention) => mention?.userId) ||
                [],
        });
        //eslint-disable-next-line
    }, [isEdit, ticketId]);

    const handleChange = ({ target: { value } }) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            comment: value,
        }));

        setCurrentComment((prev) => ({ ...prev, comment: "" }));
    };

    const handleTagged = (id) => {
        setRequest((prev) => ({
            ...prev,
            mentioned: new Set([...prev?.mentioned, id]),
        }));
    };

    const createComment = async () => {
        try {
            setIsLoading(true);
            const url = apiRoute.createTicketComment;
            const { mentioned, comment } = request;

            const newMentioned = Array.from(mentioned);
            const filteredMention = newMentioned?.filter((mention) =>
                comment?.includes(`@@@__${mention}^^__`)
            );

            let newComment = convertCommentMentions(comment, true);

            let newRequest = {};

            if (filteredMention?.length === 0) {
                const { mentioned, ...rest } = request;
                newRequest = rest;
            } else {
                newRequest = request;
                newRequest.mentioned = filteredMention;
            }

            const res = await API.post(url, {
                ...newRequest,
                comment: newComment.trim(),
            });

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                setRequest({ ...request, comment: "", mentioned: [] });
                handleSuccess();
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const editComment = async () => {
        try {
            setIsLoading(true);
            const url = apiRoute.ticketComment(currentComment?.commentId);
            const { mentioned, comment } = request;

            const newMentioned = Array.from(mentioned);
            const filteredMention = newMentioned?.filter((mention) =>
                comment?.includes(`@@@__${mention}^^__`)
            );

            let newComment = convertCommentMentions(comment, true);

            let newRequest = {};

            if (filteredMention?.length === 0) {
                const { mentioned, ...rest } = request;
                newRequest = rest;
            } else {
                newRequest = request;
                newRequest.mentioned = filteredMention;
            }

            const res = await API.put(url, {
                ...newRequest,
                comment: newComment.trim(),
            });

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                setIsEdit(false);
                setRequest({ ...request, comment: "", mentioned: [] });
                setCurrentComment({});
                handleSuccess();
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEdit) {
            return editComment();
        }
        return createComment();
    };

    const { comment } = request;

    return (
        <div className='create__comment__group'>
            <form onSubmit={handleSubmit}>
                <div className='comment__textarea__container'>
                    <div>
                        <ImageView
                            src={imageUrl || imageLinks?.images?.placeholder}
                            alt='user'
                        />
                    </div>
                    <CreateCommentInput
                        comment={comment}
                        handleChange={handleChange}
                        handleTagged={handleTagged}
                    />
                </div>
                <CreateCommentButton
                    handleSubmit={handleSubmit}
                    comment={comment}
                    isEdit={isEdit}
                    disabled={isLoading}
                    setIsEdit={() => {
                        setCurrentComment({});
                        setIsEdit(false);
                    }}
                />
            </form>
        </div>
    );
};

export default CreateComment;
