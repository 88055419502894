import { isNumber } from "underscore";
import "./ChartTooltip.scss";
import ChatTooltipBlock from "./ChatTooltipBlock/ChatTooltipBlock";

const ChartTooltip = ({
    active,
    payload,
    label,
    isHorizontalChart,
    chartTitle,
    timeMetric,
    roundUpTooltipVal,
    customUnit1,
    customUnit2,
    strokeData,
    showAll,
    useAIAssistance,
    useDefaultOptions,
    tooltipExtraMetrics,
    hideZeroMetrics
}) => {
    const { name, value, payload: allPayloads } = payload?.[0] || {};

    const computeMetricVal = (val) => {
        if (!val) {
            return 0;
        }
        return isNumber(val) ? val.toFixed(roundUpTooltipVal ? 0 : 2) : val;
    };

    const extraMetrics = allPayloads?.originalData;

    if (active && payload.length > 0) {
        return (
            <div
                className={`chart-tooltip ${
                    !isHorizontalChart ? "swipe-layout" : ""
                }`}>
                <div>
                    <p>
                        <span>{payload[0]?.payload?.dateValue}</span>
                        {!showAll && <strong>{name}</strong>}
                    </p>

                    {(showAll ? strokeData : [{ value, label: name }])?.map(
                        ({ value, label }, key) => (
                            <ChatTooltipBlock
                                key={key}
                                {...{
                                    isHorizontalChart,
                                    timeMetric,
                                    customUnit1,
                                    customUnit2,
                                    hideZeroMetrics,
                                    metricValue: computeMetricVal(
                                        showAll
                                            ? allPayloads[
                                                  isHorizontalChart
                                                      ? useAIAssistance
                                                          ? `${value}_AI`
                                                          : value
                                                      : `${value}_FREQ`
                                              ]
                                            : value
                                    ),
                                    label,
                                    showAll,
                                }}
                            />
                        )
                    )}

                    {useDefaultOptions && (
                        <>
                            {tooltipExtraMetrics?.map?.(
                                ({ label, value: metricKey }, key) => {
                                    const metricValue =
                                        extraMetrics?.[metricKey];
                                    return (
                                        <ChatTooltipBlock
                                            key={key}
                                            {...{
                                                isHorizontalChart:true,
                                                timeMetric: timeMetric
                                                    ? "Sec"
                                                    : undefined,
                                                customUnit1,
                                                customUnit2: customUnit1,
                                                metricValue:
                                                    computeMetricVal(
                                                        metricValue
                                                    ),
                                                label,
                                                showAll: true,
                                            }}
                                        />
                                    );
                                }
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

export default ChartTooltip;
