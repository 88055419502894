import * as types from "./types";

const initialState = {
    personasCache: {
        pages: {},
        totalRecords: 0
    },
 
};

const  ServiceModuleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_PERSONAS:
            return {
                ...state,
                personasCache: {
                    ...state.personasCache,
                    pages: {
                        ...state.personasCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_PERSONAS:
            return {
                ...state,
                personasCache: {
                    ...state.personasCache,
                    pages: {},
                    totalRecords: 0
                },
            };
     

        default:
            return state;
    }
};

export default ServiceModuleReducer;
