import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { NOTIFICATION_ACTION_TYPE } from "./enum";
import "./ActionIcon.scss";

const ActionIcon = ({ action, read }) => {
    const renderBasedOnActionType = () => {
        switch (action) {
            case NOTIFICATION_ACTION_TYPE.BRANCH:
                return <ReactSVG src={asset?.icons.branch} alt='action-type' />;
            case NOTIFICATION_ACTION_TYPE.CAPSULE:
                return (
                    <ReactSVG src={asset?.icons.capsule} alt='action-type' />
                );

            case NOTIFICATION_ACTION_TYPE.CONVERSATION:
                return <ReactSVG src={asset?.icons.chat} alt='action-type' />;
            case NOTIFICATION_ACTION_TYPE.PRODUCT_DOWNTIME:
                return (
                    <ReactSVG src={asset?.icons.product_downtime} alt='action-type' />
                );
            case NOTIFICATION_ACTION_TYPE.PRODUCT_UPTIME:
                return <ReactSVG src={asset?.icons.product_uptime} alt='action-type' />;
            default:
                return "";
        }
    };

    return (
        <div className=' col-1'>
            <div className='action__type'>
                {renderBasedOnActionType()}
                {!read && (
                    <ReactSVG
                        src={asset?.icons.redDot}
                        className='action__type--read__status'
                        alt='read-status'
                    />
                )}
            </div>
        </div>
    );
};

export default ActionIcon;
