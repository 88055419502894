import { SCENARIO_ACTIONS } from "components/dashboard/TrainSAM/Scenarios/enum";
import CreateBranceScenario from "../../CreateBranceScenario/CreateBranceScenario";
import DeleteBranchScenario from "../../DeleteBranchScenario/DeleteBranchScenario";

const { CREATE_SCENARIO, EDIT_SCENARIO, DELETE_SCENARIO, DETACH_SCENARIO_COLLECTION } = SCENARIO_ACTIONS;

const BranchScenarioModal = ({
    showModal,
    closeModal,
    handleSuccess,
    hasExternalCall,
    guideUpdateLoading,
    modalAction,
    selectedScenario,
    errorMssg,
    setErrorMssg,
    existingScenarios,
    existingCollections,
    hideExistingScenarios
}) => {
    const renderBasedOnModalAction = () => {
        switch (modalAction) {
            case CREATE_SCENARIO:
            case EDIT_SCENARIO:
                return (
                    <CreateBranceScenario
                        showModal={showModal}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        hasExternalCall={hasExternalCall}
                        guideUpdateLoading={guideUpdateLoading}
                        selectedScenario={selectedScenario}
                        isEdit={modalAction === EDIT_SCENARIO}
                        errorMssg={errorMssg}
                        setErrorMssg={setErrorMssg}
                        existingScenarios={existingScenarios}
                        existingCollections={existingCollections}
                        hideExistingScenarios={hideExistingScenarios}
                    />
                );

            case DELETE_SCENARIO:
            case DETACH_SCENARIO_COLLECTION:
                return (
                    <DeleteBranchScenario
                        {...{
                            closeModal,
                            showModal,
                            handleSuccess,
                        }}
                        type={modalAction}
                        reference={selectedScenario}
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnModalAction()}</>;
};

export default BranchScenarioModal;
