import React from 'react';

const ModalHeading = ({ title, subTitle }) => {
    return (
        <div className='modal__heading'>
            <h1 className='fw-bold text-dark'>{title}</h1>
            <p className='text-grey'>{subTitle}</p>
        </div>
    );
};

export default ModalHeading;