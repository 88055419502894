import React, { useState } from "react";
import PinInput from "react-pin-input";
import { apiRoute } from "services";
import { Button } from "components/ui";
import { dataQueryStatus } from "utils";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { updateAuthInfo } from "store/auth/actions";
import { connect } from "react-redux";
import API from "services/lib/api";
import { ResendOTP } from "./ResendOTP/ResendOTP";
import { fpInstance } from "pages/PublicPages";
import { signInstages } from "pages/PublicPages/SignIn/SignInForm/SignInForm";
import "./OTP.scss";

const { IDLE, LOADING } = dataQueryStatus;

const OTP = ({
    user,
    updateAuthInfo,
    setStep,
    setSignInStage,
    source,
    sessionId,
    onConfirmation,
    setAuth,
}) => {
    const [request, updateRequest] = useState({});
    const [errorMsg, setErrorMsg] = useState();
    const [status, setStatus] = useState(IDLE);

    const validateFPOTP = async () => {
        try {
            const url = apiRoute.verifyPwdOTP(sessionId);
            const res = await API.post(url, { verificationCode: request.otp });
            if (res.status === 201) {
                setSignInStage(signInstages.final);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setStatus(IDLE);
        }
    };

    const verifyCustomerOtp = async (e) => {
        try {
            e.preventDefault();
            setStatus(LOADING);

            if (source === fpInstance) return validateFPOTP();
            const { userId } = user || {};
            const res = await API.post(apiRoute.verifyEmail, {
                ...request,
                userId,
            });
            if (res.status === 201) {
                const { user, token } = res.data.data;
                // await setAccessToken(token);
                // await updateAuthInfo(user);
                setAuth({ user, token });
                setStep(1);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setStatus(IDLE);
        }
    };

    return (
        <form onSubmit={verifyCustomerOtp} className='auth--otp'>
            <h1>Enter your OTP</h1>
            <p>We need to verify that this email address is actually yours</p>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <PinInput
                length={6}
                onComplete={(otp) => updateRequest({ ...request, otp })}
                type='numeric'
                inputMode='number'
                style={{
                    padding: "10px",
                    margin: "20px 0",
                    whiteSpace: "nowrap",
                }}
                inputStyle={{
                    border: "1px solid #DEE1E5",
                    borderRadius: "10px",
                    margin: "5px",
                    color: "#11142D",
                    fontWeight: 600,
                }}
                inputFocusStyle={{ border: "1px solid #6837EF" }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {/* <p className="input-err-message mt-n2 mb-2">{error}</p> */}
            <p>
                Enter the 6-digit confirmation code sent to your email address
            </p>
            <Button
                type='submit'
                text='Submit'
                classType='primary'
                disabled={status === LOADING || request?.otp?.length !== 6}
                loading={status === LOADING}
                otherClass='mb-3 w-100'
            />
            <ResendOTP
                userId={user?.userId}
                source={source}
                sessionId={sessionId}
                setErrorMsg={setErrorMsg}
            />
        </form>
    );
};

export default connect(null, {
    updateAuthInfo,
})(OTP);
