// import { analyticsRequestTypes } from "components/dashboard/Analytics/AnalyticsTSam/enum";
import * as types from "./types";

const initialState = {
    currentAnalyticsFilter: {
        analyticsRequestType: "",
        analyticsRequestValue: "",
        analyticsRequestDuration: "LAST_WEEK",
    },

    currentAnalyticsCustomDate: {
        startDate: "",
        endDate: "",
    },

    analyticsAgents: [],

    analyticsTeams: [],

    analyticsClassifications: [],

    analyticsEfficiency: {
        TICKET_SOLUTION_TYPE: {},
        TICKET_SOURCE: {},
        TICKET_PHASE: {},
        AGENT: {},
        TEAM: {},
    },

    analyticsVolume: {
        TICKET_SOLUTION_TYPE: {},
        TICKET_SOURCE: {},
        TICKET_PHASE: {},
        AGENT: {},
        TEAM: {},
    },

    analyticsCustomer: {
        THIS_WEEK: [],
        LAST_WEEK: [],
        LAST_MONTH: [],
    },

    analyticsBacklog: {
        TICKET_SOLUTION_TYPE: {},
        TICKET_SOURCE: {},
        TICKET_PHASE: {},
        AGENT: {},
        TEAM: {},
    },
};

const AnalyticsReducer = (state = initialState, { type, payload }) => {
    const {
        analyticsRequestDuration,
        analyticsData,
        analyticsRequestType,
        analyticsChartTitle,
    } = payload || {};
    switch (type) {
        case types.SET_CURRENT_ANALYTICS_FILTER:
            return {
                ...state,
                currentAnalyticsFilter: {
                    analyticsRequestType: "",
                },
            };

        case types.UPDATE_CURRENT_ANALYTICS_FILTER:
            return {
                ...state,
                currentAnalyticsFilter: {
                    ...state?.currentAnalyticsFilter,
                    ...payload,
                },
            };

        case types.UPDATE_CURRENT_ANALYTICS_CUSTOM_DATE:
            return {
                ...state,
                currentAnalyticsCustomDate: payload,
            };

        case types.SET_ANALYTICS_AGENTS:
            return {
                ...state,
                analyticsAgents: payload,
            };

        case types.SET_ANALYTICS_CLASSIFICATIONS:
            return {
                ...state,
                analyticsClassifications: payload,
            };

        case types.SET_ANALYTICS_TEAMS:
            return {
                ...state,
                analyticsTeams: payload,
            };

        case types.SET_ANALYTICS_CUSTOMER_CHART_DATA:
            return {
                ...state,
                analyticsCustomer: {
                    ...state.analyticsCustomer,
                    [analyticsRequestDuration]: analyticsData,
                },
            };

        case types.SET_ANALYTICS_CHART_DATA:
            return {
                ...state,
                [analyticsChartTitle]: {
                    ...state[analyticsChartTitle],
                    [analyticsRequestType]: {
                        ...state[analyticsChartTitle][analyticsRequestType],
                        [analyticsRequestDuration]: analyticsData,
                    },
                },
            };

        default:
            return state;
    }
};

export default AnalyticsReducer;
