import asset from "assets/images";
import { ReactSVG } from "react-svg";
import { TASK_STATUSES, taskStatusNameMap } from "./enum";
import "./TicketAARunCard.scss";

const TicketAARunCard = ({ updateTasks, overalTaskStaus }) => {

    const visibleNoTasks = overalTaskStaus?.[0] === TASK_STATUSES.TO_DO && overalTaskStaus?.[1] === updateTasks?.length ? 0 : overalTaskStaus?.[1];

    return (
        <div className='ticketaa-run-card'>
            <div className='d-flex'>
                <span
                    // className={`task_timer ${
                    //     isPaused ? "task_timer_paused" : ""
                    // }`}>
                    >
                    <ReactSVG src={asset.icons.timer} />
                </span>
                <span className='task_status'>
                    {overalTaskStaus?.[0] === TASK_STATUSES.TO_DO ? "Running" : taskStatusNameMap?.[overalTaskStaus?.[0]]}
                </span>
            </div>
            <div className='d-flex'>
                <span className='task_counter'>
                    {visibleNoTasks}/{updateTasks?.length}
                </span>
                <span>
                    <ReactSVG
                        src={asset.icons.rightBlack}
                        // onClick={() => updateCurrentTask()}
                    />
                </span>
            </div>
        </div>
    );
};

export default TicketAARunCard;
