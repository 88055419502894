import { useState, useEffect, useContext } from "react";
import CreatePersonaHeader from "./CreatePersonaHeader/CreatePersonaHeader";
import { ReactSVG } from "react-svg";
import { Input, Button, FileInput } from "components/ui";
import asset from "assets/images";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ValidateForm from "utils/validations/Validator";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { PERSONAS_TABLE_STATUSES } from "../PersonasHeader/enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CreatePersona.scss";

const { AGENTS_INBOX_SERVICE_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const CreatePersona = () => {
    const history = useHistory();
    const [request, setRequest] = useState({
        email: "",
        teamName: "",
        displayPicture: "",
        active: true,
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [displayPicUrl, setDisplayPicUrl] = useState(
        asset?.images?.placeholder
    );
    const toastMessage = useContext(ToastContext);
    const location = useLocation();
    const { actionType } = useParams();

    const personaId = location?.state?.personaId;

    const getPersona = () => {
        const persona = location?.state;

        if (persona) {
            setRequest({
                email: persona?.email,
                displayPicture: persona?.displayPicture,
                active: persona?.active.toString(),
                teamName: persona?.fullName,
            });
            setDisplayPicUrl(
                persona?.displayPicture || asset?.images?.placeholder
            );
        }
    };

    useEffect(() => {
        if (actionType === "edit") {
            if (personaId) {
                getPersona();
            } else {
                history.push("/service-module/personas");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const uploadImage = (e) => {
        const { files, name } = e.target;
        if (files?.length) {
            setDisplayPicUrl(URL.createObjectURL(files?.[0]));
            setRequest({ ...request, [name]: files?.[0] });
            setErrors({ ...errors, [name]: "" });
        }
    };

    const createPersona = async () => {
        try {
            setLoading(true);

            const { teamName, ...req } = request;
            const [firstName, lastName, otherName] = teamName?.split(" ");

            const formData = new FormData();

            Object.keys(req).forEach((key) => {
                formData.append(key, req[key]);
            });

            let reformedLastName = lastName;
            if (otherName) {
                reformedLastName = `${reformedLastName} ${otherName}`;
            }

            formData.append("firstName", firstName);
            formData.append("lastName", reformedLastName);

            const url = apiRoute.personas;
            const res = await API.post(url, formData);

            if (res.status === 201) {
                const { message } = res.data;

                setLoading(false);
                toastMessage(message);
                history.push("/service-module/personas");
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const editPersona = async () => {
        try {
            setLoading(true);

            const { teamName, ...req } = request;
            const [firstName, lastName, otherName] = teamName?.split(" ");

            const formData = new FormData();

            Object.keys(req).forEach((key) => {
                formData.append(key, req[key]);
            });

            let reformedLastName = lastName;
            if (otherName) {
                reformedLastName = `${reformedLastName} ${otherName}`;
            }

            formData.append("firstName", firstName);
            formData.append("lastName", reformedLastName);

            const url = apiRoute.persona(personaId);
            const res = await API.patch(url, formData);

            if (res.status === 200) {
                const { message } = res.data;

                setLoading(false);
                toastMessage(message);
                history.push("/service-module/personas");
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "displayPicture",
        ]);
        if (formisValid) {
            if (personaId) {
                return editPersona();
            }
            return createPersona();
        }
        setErrors(formErrors);
    };

    const { email, teamName, displayPicture, active } = request;

    return (
        <>
            <CreatePersonaHeader personaId={personaId} />
            <div className='create__persona col-6 padded__layout pt-5'>
                <form onSubmit={handleSubmit}>
                    <div className='display__picture'>
                        <h2 className='fw-bold'>
                            {personaId ? "Edit persona" : "Add new persona"}
                        </h2>
                        <div className='display__picture--picture__upload'>
                            <div className='logo__icon'>
                                <img src={displayPicUrl} alt='persona' />
                            </div>

                            <FileInput
                                fileType='image/*'
                                id='displayPicture'
                                name='displayPicture'
                                label={
                                    <ReactSVG
                                        src={asset?.icons?.upload}
                                        className='d-inline-flex'
                                    />
                                }
                                labelClass='me-3'
                                labelName='display Picture'
                                fileValue={displayPicture}
                                onChange={(e) => uploadImage(e)}
                            />
                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setRequest({
                                        ...request,
                                        displayPicture: "",
                                    });
                                    setDisplayPicUrl(
                                        asset?.images?.placeholder
                                    );
                                }}>
                                <ReactSVG
                                    src={asset?.icons?.bin}
                                    className='d-inline-flex'
                                />
                            </span>
                        </div>
                        <p className=''>Use picture as display picture</p>
                    </div>
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='teamName'
                        label='Full Name'
                        labelClass='text-dark fw-medium'
                        id='teamName'
                        placeholder='Enter team name'
                        value={teamName}
                        onChange={handleChange}
                        isErr={errors?.teamName}
                        errMssg={errors?.teamName}
                    />
                    <Input
                        grpClass='mb-3'
                        type='email'
                        name='email'
                        label='Email'
                        labelClass='text-dark fw-medium'
                        id='email'
                        placeholder='Enter email'
                        value={email}
                        onChange={handleChange}
                        isErr={errors?.email}
                        errMssg={errors?.email}
                    />
                    <SelectUI
                        grpClass='mb-3'
                        name='active'
                        label='Status'
                        labelClass='text-dark fw-medium'
                        id='active'
                        placeholder='Status'
                        options={PERSONAS_TABLE_STATUSES.slice(1, 3)}
                        value={PERSONAS_TABLE_STATUSES.slice(1, 3)?.find(
                            (persona) => {
                                return persona.value === active;
                            }
                        )}
                        handleChange={(name, value) =>
                            handleChange({ target: { name, value } })
                        }
                    />
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${AGENTS_INBOX_SERVICE_SETTING}`,
                            `${UPDATE}_${AGENTS_INBOX_SERVICE_SETTING}`,
                        ]}>
                        <Button
                            type='submit'
                            text={personaId ? "Save changes" : "Add persona"}
                            classType='primary'
                            otherClass='my-3 w-100'
                            loading={loading}
                        />
                    </ComponentAccessControl>
                </form>
            </div>
        </>
    );
};

export default CreatePersona;
