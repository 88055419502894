import imageLinks from "assets/images";
import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";
import TagsDiscoveredElement from "./TagsDiscoveredElement/TagsDiscoveredElement";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const TagsDiscoveredHeading = () => {
    return <h4>Tags Discovered</h4>;
};

const TagsDiscovered = ({
    status,
    messageTagList,
    getMessageTags,
    errorMssg,
    handleSelectTagResp,
}) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return (
                    <div className='analyzer-tags-container__empty'>
                        <EmptyInfo
                            customIcon={imageLinks.icons.idleIcon}
                            message={
                                "No tag has been discovered yet, \n analyze a conversation to begin. "
                            }
                        />
                    </div>
                );

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <div className='analyzer-tags-container__empty'>
                        <EmptyInfo
                            customIcon={imageLinks.icons.idleIcon}
                            message={
                                "No tags that match the search found yet, \n analyze a conversation again to begin. "
                            }
                        />
                    </div>
                );

            case DATAMODE:
                return (
                    <TagsDiscoveredElement
                        messageTagList={messageTagList}
                        handleSelectTagResp={handleSelectTagResp}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getMessageTags}
                    />
                );

            default:
                return "";
        }
    };
    return (
        <>
            <div className='analyzer-tags'>
                <div>
                    <TagsDiscoveredHeading />
                    <div className='analyzer-tags-container'>
                        {renderBasedOnStatus()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TagsDiscovered;
