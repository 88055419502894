import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Input, Loading, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { flattenArray, getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";
import IssuesSelect from "components/dashboard/TrainSAM/SAM/Issues/IssuesSelect/IssuesSelect";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { QA_DOCUMENTATION_TYPES, qaDocumentationTypeOptions } from "../../../enum";
import UploadPreview from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/LiveChatInput/UploadIcons/UploadPreview/UploadPreview";
import UploadIcons from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/LiveChatInput/UploadIcons/UploadIcons";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const QaDocumentationForm = ({ closeModal, isEdit, currentQaDocumentation, handleSuccess }) => {
    const [loading, setLoading] = useState();
    const [request, setRequest] = useState({
        qaDocumentationTitle: "",
        qaDocumentationAnswer: "",
        qaDocumentationType: QA_DOCUMENTATION_TYPES.TEXT,
        qaDocumentationCapsules: [],
        issueIds: [],
        fileAttachments: [],
    });
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    // const [showMediaModal, toggleMediaModal] = useState(false);
    const [selectedContents, setSelectedContents] = useState(new Map());
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const [uploads, updateUploads] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState({});

    const { capsuleClasses } = useSelector((state) => state.capsule);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        // eslint-disable-next-line
    }, []);

    const createQaDocumentation = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const requestData = {
                ...request,
                qaDocumentationCapsules: Array.from(selectedContents?.values()).filter(
                    (capsule) => capsule?.classCapsuleEntryIds.length > 0
                ),
            };

            const url = apiRoute.createQaDocumentation;
            const res = await API.post(url, requestData);
            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess();
                setLoading(false);
                closeModal();

                await toastMessage(message);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleIssueSelect = (values) => {
        const issueIds = values.map((x) => x.issueId)
        setRequest({ ...request, issueIds });
    }

    const handleDoctypeSelectChange = (value) => {
        setRequest({ ...request, qaDocumentationKey: "", qaDocumentationType: value.value });
    }

    const uploadFile = async (url, originalFile) => {
        try {
            const options = {
                disableAuthorization: true,
            };

            const reader = new FileReader();
            reader.readAsArrayBuffer(originalFile);
            reader.onload = async () => {
                const binaryData = reader.result;
                const res = await API.put(`${url}`, binaryData, options);
                if (res.status === 200) {
                    return true;
                } else {
                    return false;
                }
            };
        } catch (err) {
            return false;
        }
    };

    const handleUpload = async (files) => {
        /*
        Promise.allSettled used here returns responses (both success and failure) for each individual request, 
        thus the need for the removal of try & catch block.
        */

        const uploadedFiles = files?.map(async (media) => {
            const url = apiRoute.generateUploadLink;

            let httpRequest = new AbortController();

            const formData = new FormData();

            formData.append("file", media?.file);

            const res = await API.put(
                url,
                {
                    file: media?.file?.name,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            if (res.status === 200) {
                const { data } = res.data;
                const { fileKey, link } = data || {};
                const { file, ...rest } = media;
                await uploadFile(link, media?.file);
                return {
                    ...rest,
                    fileAttachmentUrl: fileKey,
                };
            }
        });

        const resolvedUploads = await Promise.allSettled(uploadedFiles);

        let totalRequests = uploadedFiles?.length;
        let totalFailedRequests = 0;
        let errorMsg = "";

        let resolvedUpload = resolvedUploads?.map(
            ({ status, reason, value }, fileIndex) => {
                errorMsg = reason;
                if (status === "fulfilled") {
                    value = { ...value, fileIndex, uploadStatus: DATAMODE };

                    setRequest({ ...request, qaDocumentationKey: value.fileAttachmentUrl });

                } else {
                    totalFailedRequests += 1;
                    value = {
                        ...files[fileIndex],
                        fileIndex,
                        uploadStatus: ERROR,
                    };
                }
                return value;
            }
        );

        if (totalFailedRequests === totalRequests) {
            const message = getErrorMessage(errorMsg);
            toastMessage(message, ERROR);
        } else {
            setRequest((prev) => ({
                ...prev,
                fileAttachments: resolvedUpload?.filter(
                    (upload) => upload?.uploadStatus === DATAMODE
                ),
            }));
        }

        updateUploads([...uploads, ...resolvedUpload]);
    };

    const handleRemoveFile = (fileName, fileIndex) => {
        updateUploads((prev) =>
            prev?.filter((upload, uploadIndex) => uploadIndex !== fileIndex)
        );
        setRequest((prev) => ({
            ...prev,
            fileAttachments: prev?.fileAttachments?.filter(
                (upload, uploadIndex) => uploadIndex !== fileIndex
            ),
        }));
    };

    const handleRetryUpload = async (file) => {
        try {
            let prevUploads = [...uploads];
            prevUploads[file?.fileIndex] = {
                ...file,
                uploadStatus: LOADING,
            };

            updateUploads(prevUploads);

            const url = apiRoute.mediaUpload;
            const formData = new FormData();

            let httpRequest = new AbortController();

            formData.append("file", file?.file);

            let resolvedUpload = await API.post(
                url,
                {
                    file: file?.name,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            const { fileKey, link } = resolvedUpload.data || {};
            await uploadFile(link, file);

            let newUpload = {
                ...file,
                fileAttachmentUrl: fileKey,
                uploadStatus: DATAMODE,
            };

            let newUploads = [...uploads];

            newUploads[file?.fileIndex] = {
                ...newUpload,
                uploadStatus: DATAMODE,
            };

            updateUploads(newUploads);

            setRequest((prev) => ({
                ...prev,
                fileAttachments: newUploads?.filter(
                    (upload) => upload?.uploadStatus === DATAMODE
                ),
            }));
        } catch (err) {
            let prevUploads = [...uploads];
            prevUploads[file?.fileIndex] = {
                ...file,
                uploadStatus: ERROR,
            };

            updateUploads(prevUploads);

            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };
    const updateQaDocumentation = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const requestData = {
                ...request,
                qaDocumentationCapsules: Array.from(selectedContents?.values()).filter(
                    (capsule) => capsule?.classCapsuleEntryIds.length > 0
                ),
            };

            const res = await API.put(
                apiRoute.updateQaDocument(request?.qaDocumentationId),
                requestData
            );
            if (res.status === 200) {
                handleSuccess();
                setLoading(false);
                closeModal();

                await toastMessage(res.data.message);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId, capsuleCode } = capsule;
        setErrors({ ...errors, classificationCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleCode);
        if (prevSelectedContents) {
            const ccSelectedContentIds =
                prevSelectedContents?.classCapsuleEntryIds;
            const contentExist =
                prevSelectedContents?.classCapsuleEntryIds?.includes(
                    capsuleEntryId
                );

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                        capsuleEntryId
                    ),
                    1
                );
            }

            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: ccSelectedContentIds,
                            },
                        ],
                    ])
            );
        } else {
            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: [capsuleEntryId],
                            },
                        ],
                    ])
            );
        }
    };

    const getExistingElements = (elements) => {
        let map = new Map();

        elements?.forEach(function (
            { capsule: { capsuleClassId, capsuleCode }, capsuleEntryId },
            obj
        ) {
            let prevArry = map.get(capsuleCode);

            map = prevArry
                ? new Map([
                    ...map,
                    [
                        capsuleCode,
                        {
                            classCapsuleId: capsuleClassId,
                            classCapsuleEntryIds: [
                                ...prevArry?.classCapsuleEntryIds,
                                capsuleEntryId,
                            ],
                        },
                    ],
                ])
                : new Map([
                    ...map,
                    [
                        capsuleCode,
                        {
                            classCapsuleId: capsuleClassId,
                            classCapsuleEntryIds: [capsuleEntryId],
                        },
                    ],
                ]);
        },
            {});
        setSelectedContents(map);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "classificationCapsules",
        ]);
        if (formisValid) {
            isEdit ? updateQaDocumentation() : createQaDocumentation();
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setRequest({
                qaDocumentationTitle: currentQaDocumentation?.qaDocumentationName,
                issueIds: currentQaDocumentation?.issues?.map((x) => x.issueId),
                ...currentQaDocumentation,
            });

            getExistingElements(currentQaDocumentation?.elements);
        }
    }, [currentQaDocumentation, isEdit]);

    const renderBasedOnDocumentType = () => {
        switch (request.qaDocumentationType) {
            case QA_DOCUMENTATION_TYPES.TEXT:
                return <Textarea
                    grpClass='mb-3'
                    type='text'
                    name='qaDocumentationAnswer'
                    label='Answer'
                    labelClass='text-dark fw-medium'
                    placeholder='Answer '
                    value={request?.qaDocumentationAnswer}
                    onChange={handleChange}
                    isErr={errors?.qaDocumentationAnswer}
                    errMssg={errors?.qaDocumentationAnswer}
                    autoFocus={true}
                />
            case QA_DOCUMENTATION_TYPES.WEB:
                return (
                    <Textarea
                        grpClass='mb-3'
                        type='text'
                        name='qaDocumentationKey'
                        label='Answer'
                        labelClass='text-dark fw-medium'
                        placeholder='Provide a valid url https://example.com '
                        value={request?.qaDocumentationKey}
                        onChange={handleChange}
                        isErr={errors?.qaDocumentationKey}
                        errMssg={errors?.qaDocumentationKey}
                        autoFocus={true}
                    />
                );
            case QA_DOCUMENTATION_TYPES.DOCUMENT:
                return (
                    <div>
                        {uploads.length === 0 ?
                            <UploadIcons
                                uploads={uploads}
                                updateUploads={updateUploads}
                                isDisabled={false}
                                handleUpload={handleUpload}
                                selectedMedia={selectedMedia}
                                acceptedFiles=".pdf"
                            /> :
                            <UploadPreview
                                uploads={uploads}
                                handleRemoveFile={handleRemoveFile}
                                handleRetry={(file) => handleRetryUpload(file)}
                                maximize={(
                                    fileAttachmentType,
                                    fileAttachmentName,
                                    fileAttachmentUrl
                                ) => {
                                    setSelectedMedia({
                                        fileAttachmentType,
                                        fileAttachmentName,
                                        fileAttachmentUrl,
                                    });
                                }}
                                disableClick={status !== DATAMODE}
                            />}
                    </div>
                );
            default:
                return "";
        }
    };

    const renderBasedOnCapsuleStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <ClassCapsuleContentGroup
                        capsuleCodes={capsuleClasses?.map(
                            (capsuleClass) => capsuleClass?.capsuleCode
                        )}
                        classesListBreakpoint={8}
                        handleContentSelect={handleContentSelect}
                        selectable={true}
                        searchable={true}
                        selectedContentIds={flattenArray(
                            Array.from(selectedContents?.values())?.map(
                                (x) => x.classCapsuleEntryIds
                            )
                        )}
                        label='Relate feature or flow'
                        inputKey='classificationCapsules'
                        error={errors?.classificationCapsules}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <>

            <form className='mt-4' onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMssg)}
                    message={errorMssg}
                    hide={() => setErrorMssg()}
                />
                <Input
                    grpClass='mb-3'
                    type='text'
                    name='qaDocumentationTitle'
                    label='Question'
                    labelClass='text-dark fw-medium'
                    id='name'
                    placeholder='Enter Question '
                    value={request?.qaDocumentationTitle}
                    onChange={handleChange}
                    isErr={errors?.qaDocumentationTitle}
                    errMssg={errors?.qaDocumentationTitle}
                    autoFocus={true}
                />
                <SelectUI
                    label={'LLM Document Type'}
                    placeholder={`Select Document Type`}
                    options={qaDocumentationTypeOptions}
                    value={qaDocumentationTypeOptions.filter((x) => x.value === (request?.qaDocumentationType))?.[0]}
                    onChange={handleDoctypeSelectChange}
                    hasOptionIcon={true}
                    closeMenuOnSelect={false}
                    isRequired={true}
                    darkLabel
                />

                <div className='form-group'>{renderBasedOnDocumentType()}</div>
                <div className='form-group'>{renderBasedOnCapsuleStatus()}</div>

                <IssuesSelect
                    handleChange={handleIssueSelect}
                    defaultValue={isEdit ? currentQaDocumentation?.issues?.map((x) => ({
                        label: x.issueName,
                        value: x.issueId
                    })) : []}
                />
                <Button
                    type='submit'
                    text='Save'
                    classType='primary'
                    otherClass='my-3 w-100'
                    loading={loading}
                />
            </form>
        </>
    );
};

QaDocumentationForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
};

export default QaDocumentationForm;
