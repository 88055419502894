import React from 'react';
import { ReactSVG } from 'react-svg';
import "./BadgeLabel.scss";

const BadgeLabel = ({ text, icon, ...rest }) => {
    return (
        <div className='badge__label' {...rest}>
            <span>{text}</span>
            {
                icon && <ReactSVG className={`badge__label--icon ${text ? '' : 'm-0'}`} src={icon} />
            }
        </div>
    );
};

export default BadgeLabel;