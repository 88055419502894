import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";
import { getUniqueListBy } from "utils/helper";
import {
    AttachmentIconButton,
    SimpleDeleteIconButton,
} from "components/ui/Button/IconButton/IconButton";
import "./FormAttachment.scss";

const FormAttachment = ({
    uploads,
    updateUploads,
    handleFileAttachmentDelete,
}) => {
    const media = getUniqueListBy(uploads, "mediaName");

    const deleteFile = async (public_id, id, link) => {
        const filteredData = uploads?.filter(
            (upload) => upload?.public_id !== public_id
        );
        updateUploads(filteredData);

        handleFileAttachmentDelete(id, link);
    };

    return (
        <div className='files__container'>
            {media?.map(({ mediaName, public_id, link, id, hasUploaded }, i) =>
                hasUploaded === true ? (
                    <div className='file'>
                        {" "}
                        <span className='file__name' key={i}>
                            <AttachmentIconButton />
                            {mediaName}
                        </span>
                        <SimpleDeleteIconButton
                            onClick={() => deleteFile(public_id, id, link)}
                        />
                    </div>
                ) : (
                    <ShinyLoader height='37px' width='100px' key={i} />
                )
            )}
        </div>
    );
};

export default FormAttachment;
