import Heading from "components/ui/Heading/Heading";
import PreHeading from "components/ui/PreHeading/PreHeading";
import ViewGDSteps from "./ViewGDSteps/ViewGDSteps";
import "./ViewGuideDowntime.scss";
import ViewGDHeading from "./ViewGDHeading/ViewGDHeading";
import ViewGDUpdates from "./ViewGDUpdates/ViewGDUpdates";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import { useEffect, useState } from "react";
import { getErrorMessage } from "utils/helper";
import { GUIDE_FLOW_TYPES } from "../enum";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
import { getSingleCapsuleEntry } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { useSelector } from "react-redux";
import BranchOptionView from "components/dashboard/Branches/BranchView/BranchOptionView/BranchOptionView";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const { DOWNTIME_GUIDE_FLOW } = GUIDE_FLOW_TYPES;
const ViewGuideDowntime = ({ match }) => {
    const { params } = match;
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [guideSteps, setGuideSteps] = useState([]);

    const { capsuleClasses } = useSelector((state) => state.capsule);

    const featureClass = capsuleClasses?.find(
        ({ capsuleCode }) => capsuleCode === "FEATURE"
    );

    const [selectedStepType, selectStepType] = useState(DOWNTIME_GUIDE_FLOW);

    const [selectedUpdate, selectUpdate] = useState();

    const [selectedStep, selectStep] = useState({});
    const [showModal, toggleModal] = useState(false);
    const [capsuleElement, setCapsuleElement] = useState({});

    const capsuleClassId = params?.[0];
    const classCapsuleEntryId = params?.[1];


    const isDowntime = selectedStepType === DOWNTIME_GUIDE_FLOW;

    const retrieveCapsule = async () => {
        try {
            const elementObj = await getSingleCapsuleEntry(
                featureClass,
                classCapsuleEntryId
            );
            setCapsuleElement(elementObj);
        } catch (err) {
            setErrorMssg("An error occurred");
            setStatus(ERROR);
        }
    };

    const getGuideDowntimeSteps = async (agentUpdateId) => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute.getCapsuleGuideSteps(
                capsuleClassId,
                classCapsuleEntryId
            );
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                ;
                const firstStep = data?.[0];
                if (selectedStepType) {
                    const findStep = data?.find(
                        ({ guideFlow }) =>
                            guideFlow?.guideFlowType === selectedStepType
                    );
                    selectStep(findStep);
                    selectUpdate(findStep?.guideFlow?.agentUpdates?.[0]);
                } else if (firstStep) {
                    selectStep(firstStep);
                    const guideFlow = firstStep?.guideFlow;
                    selectStepType(guideFlow?.guideFlowType);
                    selectUpdate(guideFlow?.agentUpdates?.[0]);
                }
                setGuideSteps(data);

                if (agentUpdateId) {
                    selectUpdate(
                        selectedStep?.guideFlow?.agentUpdates?.find(
                            (agentUpdate) =>
                                agentUpdate?.agentUpdateId === agentUpdateId
                        )
                    );
                }
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const handleRefresh = (agentUpdateId) => {
        selectUpdate();
        getGuideDowntimeSteps(agentUpdateId);
    };

    const handleSuccess = () => {
        getGuideDowntimeSteps();
    };

    const handleSelectStep = (stepType) => {
        if (stepType) {
            selectStepType(stepType);
        }
        const step = guideSteps?.find(
            ({ guideFlow }) => guideFlow?.guideFlowType === stepType
        );
        selectStep(step);
        selectUpdate(step?.guideFlow?.agentUpdates?.[0]);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getGuideDowntimeSteps}
                    />
                );

            case DATAMODE:
                return (
                    <div className='view-guidedowntime'>
                        <Heading headingText={"Steps"} />
                        <ViewGDSteps
                            handleSelectStep={handleSelectStep}
                            isDowntime={isDowntime
                            }
                            selectedStep={selectedStep}
                            {...{ showModal, toggleModal }}
                        />
                        <ViewGDHeading
                            handleSuccess={handleSuccess}
                            selectedStep={selectedStep}
                            {...{
                                showModal,
                                toggleModal,
                                classCapsuleEntryId,
                                capsuleClassId,
                                selectedStepType,
                            }}
                        />
                        <ViewGDUpdates
                            {...{
                                selectedStep,
                                selectedStepType,
                                handleSuccess,
                                selectedUpdate,
                                selectUpdate,
                                handleRefresh,
                            }}
                        />

                        {!isDowntime && <BranchOptionView
                            parentBranch={selectedStep?.guideFlow}
                            handleRefresh={handleRefresh}
                            isAgentGuideView
                        />}

                    </div>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        retrieveCapsule();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getGuideDowntimeSteps();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <PreHeading
                text='Downtime guides'
                subText={capsuleElement?.content || "-"}
                url={"/dependency/downtime-guides"}
            />
            {renderBasedOnStatus()}
        </>
    );
};

export default ViewGuideDowntime;
