import React from "react";
import { NavLink } from "react-router-dom";
import TabbedWrapperAccessControl from "../TabbedWrapperAccessControl/TabbedWrapperAccessControl";
import "./TabbedWrapperHeader.scss";

const TabbedWrapperHeader = ({ tabbedRoutes }) => {
    return (
        <div className='tabbed__header' role='tablist'>
            {tabbedRoutes.map((tab, index) => (
                <TabbedWrapperAccessControl
                    key={index}
                    baseResources={tab?.baseResources}>
                    <NavLink
                        to={tab.path}
                        onClick={(e) => tab?.isDisabled && e.preventDefault()}>
                        <span>{tab.title}</span>
                    </NavLink>
                </TabbedWrapperAccessControl>
            ))}
        </div>
    );
};

export default TabbedWrapperHeader;
