import Heading from "components/ui/Heading/Heading";
import { Button } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import Filter from "components/dashboard/common/Filter/Filter";
import "./UpdatesHeader.scss";

const UpdatesHeader = ({
    title,
    subTitle,
    btnText,
    placeholder,
    handleClick,
    handleSearch,
    searchIsLoading,
    clearSearch,
    defaultValue,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter
}) => {
    return (
        <>
            <div className='updates-header'>
                <div className='updates-header__top'>
                    <Heading headingText={title} headingSubText={subTitle} />
                    <Button
                        classType={"primary"}
                        onClick={() => handleClick?.()}>
                        <ReactSVG src={asset?.icons?.addPlusIcon} />
                        {btnText}
                    </Button>
                </div>
                <Filter
                    handleSortByAlpha={handleSortByAlpha}
                    handleSortByDate={handleSortByDate}
                    hideClassFilter={true}
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    searchPlaceholder={placeholder}
                    handleSortByLastModified={handleSortByLastModified}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    handleBasefilter={handleBasefilter}
                    searchDefaultValue={defaultValue}
                />
            </div>
        </>
    );
};

export default UpdatesHeader;
