/* eslint-disable */
import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';

const FadeIn = ({ children, pKey }) => {
    const nodeRef = React.useRef(null);
    return (
        <CSSTransitionGroup
            // nodeRef={nodeRef}
            component={React.Fragment}
            transitionName="home-views"
            transitionAppear={true}
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={700}>
            <div className="h-100" ref={nodeRef} key={pKey}>
                {children}
            </div>
        </CSSTransitionGroup>
    );
};

export default FadeIn