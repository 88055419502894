import React, { useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./CustomEmojiPicker.scss";

export const CustomEmojiPicker = ({
    handleEmojiSelect,
    togglerClass,
    positionRight = false,
    isDisabled,
}) => {
    const [showEmojiPicker, toggleEmojiPicker] = useState(false);
    const handleEmojiValue = (e, emojiValue) => {
        e.stopPropagation();

        handleEmojiSelect(emojiValue?.emoji);
    };

    // useEffect(() => {
    //     document.body.addEventListener("click", () => toggleEmojiPicker(false));

    //     return () => {
    //         document.body.removeEventListener("click", () =>
    //             toggleEmojiPicker(false)
    //         );
    //     };
    // }, []);

    return (
        <div
            className={`custom__emoji__picker ${
                showEmojiPicker ? "" : "hide__emoji__picker"
            } ${positionRight ? "right__display" : ""}`}>
            <EmojiPicker onEmojiClick={handleEmojiValue} />
            <ReactSVG
                src={imageLinks?.icons?.emoji3d}
                onClick={(e) => {
                    e.stopPropagation();
                    !isDisabled && toggleEmojiPicker(!showEmojiPicker);
                }}
                className={`emoji__toggler ${togglerClass ? togglerClass : ""}`}
            />
        </div>
    );
};
