import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import KeyPhraseForm from "./KeyPhraseForm/KeyPhraseForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { MESSAGE_TAG_ACTIONS } from "../../../../enum";

const KeyPhraseModal = ({
    showModal,
    closeModal,
    handleSuccess,
    selectedMssgTagId,
    keyPhrase,
    currentAction,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            currentAction === MESSAGE_TAG_ACTIONS.EDIT
                                ? "Edit Key phrase"
                                : "Create Key phrase"
                        }
                        subTitle={
                            currentAction === MESSAGE_TAG_ACTIONS.EDIT
                                ? "Edit Key phrase"
                                : "Create a new key phrase"
                        }
                    />
                    <KeyPhraseForm
                        closeModal={closeModal}
                        selectedMssgTagId={selectedMssgTagId}
                        handleSuccess={handleSuccess}
                        keyPhrase={keyPhrase}
                        currentAction={currentAction}
                    />
                </div>
            </div>
        </SideModal>
    );
};

KeyPhraseModal.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
};

export default KeyPhraseModal;
