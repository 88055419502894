import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { useDispatch, useSelector } from "react-redux";
import { setRolesList } from "store/cache/roles/actions";

const SelectUserRole = ({
    name,
    selectedValue,
    handleSelect,
    error,
    autoFocus,
    setLoadingRoles,
}) => {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const { roles } = useSelector((state) => state.roles);

    const dispatch = useDispatch();

    const updateRoles = () => {
        const allRoles = roles?.map((role) => ({
            label: role.displayName,
            value: role.roleCode,
        }));

        setAllRoles(allRoles);
    };

    const getRoles = async () => {
        try {
            setServerError("");
            setLoading(true);
            setLoadingRoles?.(true);
            const res = await API.get(apiRoute?.getWorkspaceRoles);

            if (res.status === 200) {
                const { data } = res.data;

                setLoading(false);
                dispatch(setRolesList(data));
                updateRoles();
                setLoadingRoles?.(false);
            }
        } catch (err) {
            setLoading(false);
            setLoadingRoles?.(false);
            setServerError(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getRoles();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateRoles();
        // eslint-disable-next-line
    }, [roles]);

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                label='Select Roles'
                darkLabel={true}
                id={"name"}
                isLoading={loading}
                placeholder='Select user role'
                options={allRoles}
                defaultValue={selectedValue}
                value={allRoles.find(
                    ({ value }) => value === selectedValue?.value
                )}
                handleChange={handleSelect}
                error={error}
                autoFocus={autoFocus}
                serverError={serverError}
            />
        </div>
    );
};

export default SelectUserRole;
