import SwitchesListingHeading from "./SwitchesListingHeading/SwitchesListingHeading";
import SwitchesListingHeadingActions from "./SwitchesListingHeadingActions/SwitchesListingHeadingActions";
import "./SwitchesListingHeader.scss";
import CreateDependencyModal from "components/dashboard/TrainSAM/Dependency/common/DependencyModal/DependencyModal";

const SwitchesListingHeader = ({
    toggleModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter,searchDefaultValue,
    getDependencies,
    showModal,
    closeModal,
}) => {
    return (
        <>
            <SwitchesListingHeading />
            <SwitchesListingHeadingActions
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSortByLastModified={handleSortByLastModified}
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}
                searchDefaultValue={searchDefaultValue}
            />
            {showModal && (
                <CreateDependencyModal
                    {...{ showModal, closeModal, getDependencies }}
                />
            )}
        </>
    );
};

export default SwitchesListingHeader;
