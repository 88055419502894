import { ReactSVG } from "react-svg";
import "./AnalyticsQAMTBlock.scss";
import asset from "assets/images";
import { useState } from "react";

const AnalyticsQAMTBlock = ({ profilePic, title, time, messages }) => {
    const [isOpen, toggleOpen] = useState(false);
    return (
        <div className={`analytics-qa-mt-block`}>
            <div
                className='analytics-qa-mt-block__heading'
                onClick={() => toggleOpen(!isOpen)}>
                <div className='d-flex'>
                    <span>
                        <ReactSVG src={profilePic || asset.images.profile} />
                    </span>
                    <h5>{title}</h5>
                </div>
                <span>{time}</span>
            </div>

            {isOpen && (
                <>
                    <div className='analytics-qa-mt-block__brands'>
                        <div className='d-flex'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ReactSVG src={asset.icons.upside} />
                    </div>
                    <div className='analytics-qa-mt-block__body'>
                        {messages?.map(
                            ({ content, time, profilePic, title }, key) => (
                                <section>
                                    <div className='d-flex'>
                                        <span className='brand__logo'></span>
                                        <span>Sent on {time}</span>
                                    </div>
                                    <h5>{title}</h5>
                                    <p>{content}</p>
                                </section>
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default AnalyticsQAMTBlock;
