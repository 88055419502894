import React, { useContext } from "react";
import { Checkbox } from "components/ui";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import "./ExternalEmailsCheckbox.scss";

const { ERROR } = dataQueryStatus;

const ExternalEmailsCheckbox = ({
    forExternalEmails,
    appUserId,
    handleSuccess,
}) => {
    const toastMessage = useContext(ToastContext);

    const selectExternalEmail = async () => {
        try {
            const url = apiRoute.selectExternalEmail(appUserId);
            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res?.data;
                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <Checkbox
            type='radio'
            id='forExternalEmails'
            inputClass={`external__emails__checkbox ${
                forExternalEmails ? "selected" : ""
            }`}
            checked={forExternalEmails}
            onChange={selectExternalEmail}
        />
    );
};

export default ExternalEmailsCheckbox;
