import React from "react";
import FadeIn from "components/ui/effects/FadeIn";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";
import CapsuleClassDetails from "../CapsuleClassDetails/CapsuleClassDetails";
import CapsuleClasses from "../CapsuleClasses/CapsuleClasses";
import { Loading } from "components/ui";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const CapsuleClassView = ({
    detailsKey,
    status,
    errorMsg,
    selectCapsuleClass,
    selectedCapsuleClass,
    capsuleClasses,
    toggleEdited,
    handleEditAction,
    handleCapsulationSuccess,
}) => {
    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <CapsuleClassDetails
                        key={detailsKey}
                        toggleEdited={toggleEdited}
                        handleEditAction={handleEditAction}
                        selectedCapsuleClass={selectedCapsuleClass}
                        capsuleClasses={capsuleClasses}
                        handleCapsulationSuccess={handleCapsulationSuccess}
                    />
                );
            case ERROR:
                return <ErrorView message={errorMsg} />;
            default:
                return "";
        }
    };

    return (
        <div>
            <CapsuleClasses
                data={capsuleClasses}
                selectedClassId={selectedCapsuleClass?.capsuleClassId}
                selectCapsuleClass={selectCapsuleClass}
                handleCapsulationSuccess={handleCapsulationSuccess}
            />

            <div key={status}>
                <FadeIn pkey={status}>{renderBasedOnStage()}</FadeIn>
            </div>
        </div>
    );
};

export default CapsuleClassView;
