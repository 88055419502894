import ImageView from "components/essentials/ImageView/ImageView";
import asset from "../../../../../../../assets/images/index";
import "./PersonasName.scss";

const PersonasName = ({ persona }) => {
    return (
        <div className='personas__name'>
            <ImageView
                src={persona?.displayPicture || asset?.images?.placeholder}
                alt='profile'
            />
            <span>{`${persona?.firstName} ${persona?.lastName}`}</span>
        </div>
    );
};

export default PersonasName;
