import { Tag } from "components/ui";
import "./DiscoveryMetadata.scss";

const DiscoveryMetadata = ({ metadata }) => {
    return (
        <>
            {metadata?.length ? (
                <div className='discovery-issue-metadata'>
                    {metadata?.map((metadata) => (
                        <Tag name={metadata} />
                    ))}
                </div>
            ) : (
                "No metadata has been added yet"
            )}
        </>
    );
};

export default DiscoveryMetadata;
