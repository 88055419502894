import { Dropdown } from "components/ui";

import { STATUS_MAP_OPTIONS } from "../../../enum";

import StatusMapMetricsGroup from "./StatusMapMetricsGroup/StatusMapMetricsGroup";

import "./StatusMapHeader.scss";

const StatusMapHeaderSelect = ({ handleSelect, selectedOption }) => {
    return (
        <Dropdown
            placeholder='Status Option'
            size='sm'
            ddValueClass='normal-weight'
            isDefault={false}
            options={[
                {
                    value: STATUS_MAP_OPTIONS.DAILY,
                    label: "Daily",
                },
                {
                    value: STATUS_MAP_OPTIONS.WEEKLY,
                    label: "Weekly",
                },
            ]}
            ddValue={{ value: selectedOption, label: selectedOption }}
            onChange={({ value }) => handleSelect(value)}
        />
    );
};

const StatusMapHeader = ({
    handleSelect,
    statusMapMetrics,
    selectedOption,
}) => {
    return (
        <>
            <div className='status-map-header'>
                <div className='row status-map-header__inner'>
                    <div className='col-2'>
                        <StatusMapHeaderSelect
                            handleSelect={handleSelect}
                            selectedOption={selectedOption}
                        />
                    </div>
                    <div className='col-10'>
                        <StatusMapMetricsGroup
                            statusMapMetrics={statusMapMetrics}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default StatusMapHeader;
