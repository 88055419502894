import { getFormattedStringDate } from "utils/helper";
import InboxAppsButton from "../InboxAppsButton/InboxAppsButton";
import ExternalEmailsCheckbox from "./ExternalEmailsCheckbox/ExternalEmailsCheckbox";
import { availableInboxApps } from "../../../../enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./ConnectedApp.scss";

const { AGENTS_INBOX_SERVICE_INTEGRATION } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;
const { GMAIL } = availableInboxApps;

const ConnectedApp = ({
    connectedApp,
    actionType,
    appSource,
    handleNewConnection,
    user,
    handleSuccess,
    handleError,
    handleActionModal,
}) => {
    const { screenName, connectionDate, appUserId, forExternalEmails } =
        connectedApp || {};

    return (
        <>
            <div className='appUser__row'>
                <div className='row'>
                    <div className='col-3'>
                        <p>{screenName}</p>
                    </div>
                    <div className='col-3'>
                        <p>{getFormattedStringDate(connectionDate)}</p>
                    </div>
                    {appSource === GMAIL && (
                        <div className='external__emails__checkbox__container col-3'>
                            <ComponentAccessControl
                                baseResources={[
                                    `${UPDATE}_${AGENTS_INBOX_SERVICE_INTEGRATION}`,
                                ]}>
                                <ExternalEmailsCheckbox
                                    forExternalEmails={forExternalEmails}
                                    appUserId={appUserId}
                                    handleSuccess={handleSuccess}
                                />
                            </ComponentAccessControl>
                        </div>
                    )}
                    <div className='col-1'>
                        <ComponentAccessControl
                            baseResources={[
                                `${DELETE}_${AGENTS_INBOX_SERVICE_INTEGRATION}`,
                            ]}>
                            <InboxAppsButton
                                actionType={actionType}
                                appSource={appSource}
                                handleNewConnection={handleNewConnection}
                                user={user}
                                appUserId={appUserId}
                                handleSuccess={handleSuccess}
                                handleError={handleError}
                                handleActionModal={handleActionModal}
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConnectedApp;
