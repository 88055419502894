import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Checkbox, Loading } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import BranchesSelect from "components/dashboard/Branches/BranchesSelect/BranchesSelect";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { branchOptionActionTypes } from "components/dashboard/TrainSAM/SAM/Conversations/enums";
import FormsSelect from "components/dashboard/KnowledgeBase/form/FormsSelect/FormsSelect";
import BranchCollectionSelect from "components/dashboard/TrainSAM/SAM/BranchCollectionSelect/BranchCollectionSelect";
import ScheduleInput from "components/dashboard/common/ScheduleInput/ScheduleInput";
import {
    branchOptionTypesArr,
    branchOptionTypes,
} from "../../BranchOption/enum";
import { dataQueryStatus } from "utils";
import { saveCapsuleClassess } from "store/capsule/actions";
import ErrorView from "components/ui/ErrorView/ErrorView";
import "./CreateBranchOptionForm.scss";
import GuideFlowSelect from "./GuideFlowSelect/GuideFlowSelect";
import CreateGDUpdateAutomation from "components/dashboard/TrainSAM/GuideDowntime/ViewGuideDowntime/ViewGDUpdates/modal/CreateGDUpdateModal/CreateGDUpdateForm/CreateGDUpdateAutomation/CreateGDUpdateAutomation";
import {
    GUIDE_TASK_ACTIONS,
    GUIDE_TASK_SCENARIOS,
} from "components/dashboard/TrainSAM/GuideDowntime/ViewGuideDowntime/ViewGDUpdates/enum";

const { BRANCH, COLLECTION, FORM, LINK, GUIDE_FLOW } = branchOptionTypes;
const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CreateBranchOptionForm = ({
    referenceData,
    isEdit,
    handleSuccess,
    hideCapsuleContentGroup,
    existingOptionConnections,
    isAgentGuideView,
}) => {
    const { guideId, guideFlowId } = referenceData || {};
    const [loading, setLoading] = useState(false);

    const defaultTask = {
        agentUpdateTaskName: "Uptime Task 1",
        mainInstruction: "",
        cannedResourceId: "",
        agentUpdateTaskType: "AUTOMATION",
        agentUpdateTaskActionType: GUIDE_TASK_ACTIONS.CLOSE_CONVERSATION,
        agentUpdateTaskScenairoType: GUIDE_TASK_SCENARIOS.USER_MUTE,
        initiationScheduleType: "RELATIVE",
        initiationSchedule: 0,
    };

    const [agentUpdateTasks, setAgentUpdateTasks] = useState([defaultTask]);

    const [request, setRequest] = useState({
        branchOptionLabel: "",
        branchId: referenceData?.branchId,
        branchOptionType: isAgentGuideView ? GUIDE_FLOW : null,
        capsuleClassId: null,
        capsuleEntryId: null,
        branchOptionValue: null,
        allowCapsuleAltConversation: false,
        isScheduled: false,
        scheduleDuration: 60,
    });

    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [classesErrorMssg, setClassesErrorMssg] = useState("");
    const [hasAutomation, showAutomation] = useState(false);

    const { capsuleClasses } = useSelector((state) => state.capsule);
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const getCapsuleClasses = async () => {
        try {
            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;

                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setClassesErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0 && !hideCapsuleContentGroup) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest(name, value);
    };
    const handleBranchSelect = ({ value }, { name }) => {
        updateRequest(name, value);
    };

    const updateRequest = (name, value) => {
        setRequest({ ...request, [name]: value });

        if (
            name === "branchOptionType" &&
            request?.branchOptionType !== value
        ) {
            setRequest((prev) => ({ ...prev, branchOptionValue: null }));
            setErrors((prevErr) => ({ ...prevErr, branchOptionValue: "" }));
        }
        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const handleBranchOptionTypeChange = (name, value) => {
        setRequest({
            ...request,
            [name]: value === "NO_ACTION" ? null : value,
        });
        setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const handleToggleScheduleInput = (isScheduled) => {
        setRequest({ ...request, isScheduled });
    };

    const setScheduleTime = (timeValue) => {
        setRequest({ ...request, scheduleDuration: timeValue });
    };

    const handleSendToAgentValidation = () => {
        const { branchOptionValue, branchOptionActionType } = request;

        if (
            branchOptionValue?.length > 0 &&
            branchOptionActionType === "FORWARD_AGENT"
        ) {
            setErrorMssg("Remove Branch link to send ticket to agent");
            return false;
        }

        return true;
    };

    const editBranchOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { isScheduled, scheduleDuration } = request;
            const { branchId, branchOptionId } = referenceData;
            const url = apiRoute?.editBranchOption(branchId, branchOptionId);
            const res = await API.put(url, {
                ...request,
                scheduleDuration: isScheduled ? scheduleDuration : null,
            });
            if (res.status === 200) {
                await toastMessage(res.data.message);
                handleSuccess(branchId);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const createBranchOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { isScheduled, scheduleDuration } = request;

            const url = apiRoute?.createBranchOption;
            const res = await API.post(url, {
                ...request,
                scheduleDuration: isScheduled ? scheduleDuration : null,
            });

            if (res.status === 201) {
                await toastMessage(res.data.message);
                const { branchId } = res.data.data;
                handleSuccess(branchId);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const createGuideFlowOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const {
                branchOptionLabel,
                capsuleClassId,
                capsuleEntryId,
                branchOptionValue,
                branchOptionActionType,
            } = request || {};

            const guideFlowOptionRequest = {
                guideFlowOptionLabel: branchOptionLabel,
                guideFlowOptionValue: branchOptionValue,
                capsuleClassId: capsuleClassId,
                capsuleEntryId: capsuleEntryId,
                guideFlowOptionType: "GUIDE_FLOW",
                guideFlowOptionActionType: branchOptionActionType,
                agentUpdateTasks: hasAutomation ? agentUpdateTasks : [],
            };

            const url = apiRoute?.createGuideFlowOption(guideFlowId);
            const res = await API.post(url, guideFlowOptionRequest);

            if (res.status === 201) {
                await toastMessage(res.data.message);
                handleSuccess(guideFlowId);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editGuideFlowBranchOption = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const {
                branchOptionLabel,
                capsuleClassId,
                capsuleEntryId,
                branchOptionValue,
                branchOptionActionType,
            } = request || {};

            const guideFlowOptionRequest = {
                guideFlowOptionLabel: branchOptionLabel,
                guideFlowOptionValue: branchOptionValue,
                capsuleClassId: capsuleClassId,
                capsuleEntryId: capsuleEntryId,
                guideFlowOptionType: "GUIDE_FLOW",
                guideFlowOptionActionType: branchOptionActionType,
                agentUpdateTasks,
            };

            const { guideFlowOptionId } = referenceData;
            const url = apiRoute?.updateGuideFlowOption(
                guideFlowId,
                guideFlowOptionId
            );
            const res = await API.post(url, guideFlowOptionRequest);
            if (res.status === 201) {
                await toastMessage(res.data.message);
                handleSuccess(guideFlowId);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleContentSelect = ({ capsuleClassId }, capsuleEntryId) => {
        const prevSelectedCapsuleEntryId =
            request?.capsuleEntryId === capsuleEntryId;

        if (prevSelectedCapsuleEntryId) {
            setRequest({
                ...request,
                capsuleClassId: null,
                capsuleEntryId: null,
            });
        } else {
            setRequest({
                ...request,
                capsuleClassId,
                capsuleEntryId,
            });
        }

        setErrors((prevErr) => ({ ...prevErr, capsuleEntryId: "" }));
    };

    const handleCheck = () => {
        setRequest({
            ...request,
            allowCapsuleAltConversation: !request?.allowCapsuleAltConversation,
        });
    };

    const handleClassSelect = (val) => {
        if (val === undefined) {
            setRequest((prevRequest) => ({
                ...prevRequest,
                capsuleClassId: "",
                capsuleEntryId: "",
                allowCapsuleAltConversation: false,
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "branchOptionActionType",
            "branchOptionType",
            "capsuleClassId",
            "capsuleEntryId",
            "allowCapsuleAltConversation",
            isAgentGuideView ? "branchOptionValue" : "",
        ]);
        const isValid = handleSendToAgentValidation();

        if (formisValid && isValid) {
            if (isAgentGuideView) {
                isEdit ? editGuideFlowBranchOption() : createGuideFlowOption();
            } else {
                isEdit ? editBranchOption() : createBranchOption();
            }
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        if (isEdit && !isAgentGuideView) {
            setRequest({
                ...referenceData,
                capsuleClassId: referenceData?.branchOptionCapsuleClassId,
                capsuleEntryId: referenceData?.branchOptionCapsuleEntryId,
            });
        }
        // eslint-disable-next-line
    }, []);

    const correctFlowOptionAutomation = (agentUpdateTasks) => {
        return agentUpdateTasks?.map(
            ({ taskCaseScenairoType, taskActionType, ...rest }) => {
                return {
                    agentUpdateTaskScenairoType: taskCaseScenairoType,
                    agentUpdateTaskActionType: taskActionType,
                    ...rest,
                };
            }
        );
    };

    useEffect(() => {
        if (isEdit && isAgentGuideView) {
            setRequest({
                ...referenceData,
                capsuleClassId: referenceData?.guideFlowOptionCapsuleClassId,
                capsuleEntryId: referenceData?.guideFlowOptionCapsuleEntryId,
                branchOptionLabel: referenceData?.guideFlowOptionLabel,
                branchId: referenceData?.guideFlowOptionId,
                branchOptionType: "GUIDE_FLOW",
                branchOptionValue: referenceData?.guideFlowOptionValue,
                branchOptionActionType:
                    referenceData?.guideFlowOptionActionType,
            });
            if (referenceData?.agentUpdateTasks?.length > 0) {
                showAutomation(true);
                setAgentUpdateTasks(
                    correctFlowOptionAutomation(referenceData?.agentUpdateTasks)
                );
            }
        }
        // eslint-disable-next-line
    }, [referenceData]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case DATAMODE:
                return (
                    <>
                        <ClassCapsuleContentGroup
                            otherClassNames='form-group relate__capsule__group'
                            capsuleCodes={capsuleClasses?.map(
                                (capsuleClass) => capsuleClass?.capsuleCode
                            )}
                            classesListBreakpoint={8}
                            handleContentSelect={handleContentSelect}
                            selectedContentIds={
                                capsuleEntryId ? [capsuleEntryId] : null
                            }
                            defaultCapsuleClassId={capsuleClassId}
                            pushSelectedClass={handleClassSelect}
                            selectable={true}
                            searchable={true}
                            label='Relate to Capsule'
                            inputKey='capsuleEntryId'
                            error={errors?.capsuleEntryId}
                        />

                        {!isAgentGuideView && (
                            <div className='mb-3'>
                                <Checkbox
                                    grpClass='template__checkbox'
                                    name='capsule'
                                    id='capsule'
                                    label='Allow Capsule Change Conversation'
                                    labelClass='text-grey fw-normal'
                                    inputClass='input-check-grey'
                                    checked={allowCapsuleAltConversation}
                                    onChange={() => handleCheck()}
                                />
                            </div>
                        )}
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={classesErrorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );
            default:
                return "";
        }
    };

    const {
        branchOptionType,
        branchOptionValue,
        branchOptionLabel,
        branchOptionActionType,
        capsuleClassId,
        capsuleEntryId,
        allowCapsuleAltConversation,
        isScheduled,
        scheduleDuration,
    } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            <Input
                grpClass='mb-3'
                type='text'
                name='branchOptionLabel'
                label={isAgentGuideView ? "Name" : "Option title"}
                labelClass='text-dark fw-medium'
                id='optionTitle'
                placeholder={isAgentGuideView ? "Name" : "Enter Option title"}
                onChange={handleChange}
                value={branchOptionLabel}
                isErr={errors?.branchOptionLabel}
                errMssg={errors?.branchOptionLabel}
                autoFocus={true}
            />

            {!isAgentGuideView && (
                <Checkbox
                    grpClass='schedule__checkbox'
                    name='schedule-option'
                    id='schedule-option'
                    label='Make option schedulable'
                    labelClass='text-grey fw-normal'
                    inputClass='input-check-grey'
                    checked={isScheduled}
                    onChange={() => handleToggleScheduleInput(!isScheduled)}
                />
            )}

            {isScheduled && (
                <ScheduleInput
                    timeValue={scheduleDuration}
                    handleTimeChange={setScheduleTime}
                    error={errors?.scheduleDuration}
                    subLabel='This option will be made accessible to use after'
                />
            )}

            {!isAgentGuideView && (
                <SelectUI
                    name='branchOptionType'
                    label='Link to'
                    id='branchOptionType'
                    placeholder=''
                    darkLabel={true}
                    options={branchOptionTypesArr}
                    defaultValue={branchOptionType}
                    value={branchOptionTypesArr.filter(
                        ({ value }) => value === branchOptionType
                    )}
                    handleChange={updateRequest}
                    error={errors?.branchOptionType}
                />
            )}

            {branchOptionType === GUIDE_FLOW && (
                <GuideFlowSelect
                    handleSelect={handleBranchSelect}
                    error={errors?.branchOptionValue}
                    guideId={guideId}
                    darkLabel={true}
                    selectedValue={branchOptionValue}
                    guideFlowId={guideFlowId}
                    isEdit={isEdit}
                    callApi
                />
            )}

            {branchOptionType === BRANCH && (
                <BranchesSelect
                    handleSelect={handleBranchSelect}
                    error={errors?.branchOptionValue}
                    darkLabel={true}
                    filterIds={
                        isEdit
                            ? [
                                  //   ...existingOptionConnections?.[
                                  //       BRANCH
                                  //   ]?.filter((id) => id !== branchOptionValue),
                                  referenceData?.branchId,
                              ]
                            : [
                                  //   ...existingOptionConnections?.[BRANCH],
                                  referenceData?.branchId,
                              ]
                    }
                    selectedValue={branchOptionValue}
                />
            )}

            {branchOptionType === FORM && (
                <FormsSelect
                    handleSelect={handleBranchSelect}
                    error={errors?.branchOptionValue}
                    selectedValue={branchOptionValue}
                    filterIds={
                        isEdit
                            ? [
                                  ...existingOptionConnections?.[FORM]?.filter(
                                      (id) => id !== branchOptionValue
                                  ),
                              ]
                            : [...existingOptionConnections?.[FORM]]
                    }
                />
            )}

            {branchOptionType === COLLECTION && (
                <BranchCollectionSelect
                    handleSelect={handleBranchSelect}
                    error={errors?.branchOptionValue}
                    selectedValue={branchOptionValue}
                    darkLabel={true}
                    filterIds={
                        isEdit
                            ? [
                                  ...existingOptionConnections?.[
                                      COLLECTION
                                  ]?.filter((id) => id !== branchOptionValue),
                              ]
                            : [...existingOptionConnections?.[COLLECTION]]
                    }
                />
            )}

            {branchOptionType === LINK && (
                <Input
                    grpClass='mb-3'
                    type='text'
                    name='branchOptionValue'
                    label='Link'
                    labelClass='text-dark fw-medium'
                    id='optionTitle'
                    placeholder={`https://domain.com`}
                    value={branchOptionValue}
                    onChange={handleChange}
                    isErr={errors?.branchOptionValue}
                    errMssg={errors?.branchOptionValue}
                />
            )}

            {!hideCapsuleContentGroup && <>{renderBasedOnStatus()}</>}

            <SelectUI
                name='branchOptionActionType'
                label='Actions'
                id='branchOptionActionType'
                darkLabel={true}
                placeholder=''
                options={[
                    { value: "NO_ACTION", label: "None" },
                    { value: "FORWARD_AGENT", label: "Send Ticket to Agent" },
                    {
                        value: "CLOSE_CONVERSATION",
                        label: "Close Conversation",
                    },
                    {
                        value: "RESTART_CONVERSATION",
                        label: "Restart Conversation",
                    },
                ]}
                value={{
                    label: branchOptionActionTypes[branchOptionActionType]
                        ? branchOptionActionTypes[branchOptionActionType]
                        : "None",
                    value: branchOptionActionType
                        ? branchOptionActionType
                        : "NO_ACTION",
                }}
                handleChange={handleBranchOptionTypeChange}
                error={errors?.branchOptionActionType}
            />

            {isAgentGuideView && (
                <Checkbox
                    label={"Add automation"}
                    onChange={() => showAutomation(!hasAutomation)}
                    id={""}
                    checked={hasAutomation}
                    inputClass='input-check-grey'
                />
            )}

            {hasAutomation && isAgentGuideView && (
                <CreateGDUpdateAutomation
                    {...{
                        agentUpdateTasks,
                        setAgentUpdateTasks,
                        defaultTask,
                        isEdit,
                    }}
                />
            )}

            <Button
                type='submit'
                text={
                    isEdit
                        ? `Edit ${isAgentGuideView ? "Step" : "Option"}`
                        : `Add ${isAgentGuideView ? "Step" : "Option"}`
                }
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateBranchOptionForm;
