import TicketAACardHeading from "../common/TicketAACardHeading/TicketAACardHeading";
import AddButton from "components/ui/Button/AddButton/AddButton";
import TicketAAScenarioDetail from "./TicketAAScenarioDetail/TicketAAScenarioDetail";
import { useEffect, useState } from "react";
import { InputSearch, Loading } from "components/ui";
import "./TicketAAScenarios.scss";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import {
    extractUniqueArrayItemsFrom2ArraysBasedOnAKey,
    getErrorMessage,
} from "utils/helper";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ConfirmPFCollectionSend from "../../TicketPostFormCollection/TicketsPFCollectionsListing/ConfirmPFCollectionSend/ConfirmPFCollectionSend";
import { useSelector } from "react-redux";

// import TicketAASentScenario from "./TicketAASentScenario/TicketAASentScenario";
const { LOADING, ERROR, NULLMODE, IDLE, DATAMODE } = dataQueryStatus;

const TicketAAScenarios = ({
    handleToggleScenarioModal,
    branchCollections,
    scenarios,
    toggleProposal,
    selectedCustomerMessage,
    ticketConversation,
    handleCannedActionSuccess,
}) => {

    const [showModal, toggleModal] = useState(false);
    const [pFCollection, setPFCollection] = useState({});

    const handleModalToggle = (pFCollection) => {
        toggleModal(true);
        setPFCollection(pFCollection);
    };

    const { messageContent } = selectedCustomerMessage || {};

    const isMessageSelected = messageContent?.length > 0;

    const [filteredScenairos, setFilteredScenairos] = useState(scenarios);

    const [open, toggleOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [status, setStatus] = useState(
        isMessageSelected ? LOADING : DATAMODE
    );
    const [errorMssg, setErrorMssg] = useState("");

    const { activeTicket: selectedTicket } = useSelector(
        (state) => state.tickets
    );
    const [investigatedScenarios, setInvestigatedScenarios] = useState([]);
    const sentScenairos = extractUniqueArrayItemsFrom2ArraysBasedOnAKey(
        scenarios,
        ticketConversation,
        "guideScenairoId",
        "ticketConversationId"
    );
    const hasScenarioBeenSent = sentScenairos?.length === scenarios.length;

    const handleToggleScenario = (e) => {
        toggleProposal();
        handleToggleScenarioModal(e);
    };

    const fetchScenarioSuggestions = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.investigateGuideScenario;
            const res = await API.get(url, {
                params: {
                    search: messageContent || "",
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setInvestigatedScenarios(data);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getScenarios = async () => {
        try {
            if (searchValue?.length > 0) {
                setStatus(LOADING);
                const url = apiRoute.getScenarios;
                const res = await API.get(url, {
                    params: {
                        search: searchValue,
                    }
                });

                if (res.status === 200) {
                    const { data } = res.data;
                    let scenairoData = data.length > 0 ? data : scenarios;
                    setFilteredScenairos(scenairoData);
                    setStatus(DATAMODE);
                }
            } else {
                setFilteredScenairos(scenarios);
            }

        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    }

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        otherClassNames={"with-margin"}
                        message={"Ticket info\n Not available yet"}
                    />
                );

            case DATAMODE:
                return (
                    <div className='ticketaa-scenarios__list'>
                        {(isMessageSelected ? investigatedScenarios : filteredScenairos)
                            ?.map((scenario, i) => (
                                <TicketAAScenarioDetail
                                    key={i}
                                    scenario={scenario}
                                    sentScenairos={sentScenairos}
                                    ticketConversation={ticketConversation}
                                    handleCannedActionSuccess={
                                        handleCannedActionSuccess
                                    }
                                />
                            ))}
                        {
                            branchCollections?.length > 0 && <div className='ticketaa-scenarios__content'>
                                {
                                    (branchCollections)?.map(({ branchCollectionName, branchCollectionId }, index) => (
                                        <div
                                            key={index}
                                            className={`ticketaa-scenarios__content__heading`}
                                            onClick={() => handleModalToggle({ branchCollectionName, branchCollectionId })}
                                        >
                                            <h6>{branchCollectionName}</h6>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    useEffect(() => {
        if (isMessageSelected) {
            fetchScenarioSuggestions();
        }
        // eslint-disable-next-line
    }, [selectedCustomerMessage]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getScenarios()
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <>
            <section className='ticketaa-scenarios'>
                <TicketAACardHeading
                    title='Scenarios'
                    onClick={() => toggleOpen(!open)}
                    isOpen={open}
                    // showDot={!hasScenarioBeenSent}
                    showMark={hasScenarioBeenSent}
                    showNone={scenarios.length === 0}
                />
                {open && (
                    <>
                        <div className='ticketaa-scenarios__actions'>
                            <div className="row">
                                <div className="col-lg-8">
                                    <InputSearch
                                        placeholder={"Search"}
                                        onChange={(value) => setSearchValue(value)}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <AddButton
                                        btnText={"Propose scenario"}
                                        onClick={handleToggleScenario}
                                    />
                                </div>
                            </div>


                        </div>
                        {renderBasedOnStatus()}
                        {/* <TicketAASentScenario isActive={true} /> */}
                    </>
                )}
            </section>

            {showModal && (
                <ConfirmPFCollectionSend
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    pFCollection={pFCollection}
                    selectedTicket={selectedTicket}
                />
            )}
        </>
    );
};

export default TicketAAScenarios;
