import { useState } from "react";
import { Button } from "components/ui";
import CapsuleContentFormatModal from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductCapsuleView/CapsuleContentViewHeader/CapsuleContentFormatModal/CapsuleContentFormatModal";
import "./EditBlockButton.scss";

const EditBlockButton = ({ contentBlock, reLoadView }) => {
    const [showModal, toggleModal] = useState(false);

    const handleContentBlockCreationSuccess = () => {
        toggleModal(false);
        reLoadView();
    };

    return (
        <>
            <Button
                text=' Edit block'
                type='button'
                classType='plain'
                otherClass='edit__block__btn fw-bold'
                onClick={() => toggleModal(true)}
            />
            {showModal && (
                <CapsuleContentFormatModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    selectedFormat={contentBlock?.contentType}
                    handleSuccess={handleContentBlockCreationSuccess}
                    editItem={contentBlock}
                />
            )}
        </>
    );
};

export default EditBlockButton;
