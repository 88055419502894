import Heading from "components/ui/Heading/Heading";
import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { CREATE } = baseResourceActions;

const IssuesHeader = ({
    toggleModal,
}) => {
    return (
        <>
            <Heading
                headingText={"Issues"}
                headingSubText={"Add & change your issues"}
            />
            <div className='issues-heading'>
                <div>
                </div>
                <div>
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                        ]}>
                        <AddButton
                            btnText='Create New Issue'
                            onClick={toggleModal}
                        />
                    </ComponentAccessControl>
                </div>
            </div>
        </>
    );
};

IssuesHeader.prototypes = {
    getIssues: PropTypes.func.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    issues: PropTypes.array.isRequired,
    clearSearch: PropTypes.bool,
};

export default IssuesHeader;
