import PropTypes from "prop-types";
import AddContentTextForm from "./AddContentTextForm/AddContentTextForm";
import AddContentRichTextForm from "./AddContentRichTextForm/AddContentRichTextForm";
import AddContentVideoForm from "./AddContentVideoForm/AddContentVideoForm";
import AddContentPictureForm from "./AddContentPictureForm/AddContentPictureForm";
import AddContentDescriptionForm from "./AddContentDescriptionForm/AddContentDescriptionForm";
import AddContentLinkForm from "./AddContentLinkForm/AddContentLinkForm";
import { CAPSULE_CONTENT_BLOCK_FORMATS } from "components/dashboard/KnowledgeBase/CapsuleSettings/enums";
import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const { TEXT, RICH_TEXT, PICTURE, VIDEO, DESCRIPTION, LINK, GIF } =
    CAPSULE_CONTENT_BLOCK_FORMATS;

const CapsuleContentFormatModal = ({
    showModal,
    closeModal,
    selectedFormat,
    handleSuccess,
    capsuleData,
    editItem,
}) => {
    const renderBasedOnAction = () => {
        switch (selectedFormat) {
            case TEXT:
                return (
                    <>
                        <ModalHeading
                            title={editItem ? "Edit Text" : "Add Text"}
                            subTitle={
                                editItem ? "Edit content" : "Create a new text"
                            }
                        />
                        <AddContentTextForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                        />
                    </>
                );
            case RICH_TEXT:
                return (
                    <>
                        <ModalHeading
                            title={
                                editItem ? "Edit Rich Text" : "Add Rich Text"
                            }
                            subTitle={
                                editItem
                                    ? "Edit content"
                                    : "Create a new rich text"
                            }
                        />
                        <AddContentRichTextForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                        />
                    </>
                );
            case PICTURE:
            case GIF:
                return (
                    <>
                        <ModalHeading
                            title={
                                selectedFormat === GIF
                                    ? editItem
                                        ? "Edit Gif Content"
                                        : "Add Gif"
                                    : editItem
                                    ? "Edit Picture Content"
                                    : "Add Picture"
                            }
                            subTitle={
                                selectedFormat === GIF
                                    ? editItem
                                        ? "Edit content"
                                        : "Create a new gif"
                                    : editItem
                                    ? "Edit content"
                                    : "Create a new picture"
                            }
                        />
                        <AddContentPictureForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                            gif={selectedFormat === GIF}
                        />
                    </>
                );
            case VIDEO:
                return (
                    <>
                        <ModalHeading
                            title={
                                editItem ? "Edit Video Content" : "Add Video"
                            }
                            subTitle={
                                editItem ? "Edit content" : "Create a new video"
                            }
                        />
                        <AddContentVideoForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                        />
                    </>
                );
            case DESCRIPTION:
                return (
                    <>
                        <ModalHeading
                            title={
                                editItem
                                    ? "Edit Description"
                                    : "Add Description"
                            }
                            subTitle={
                                editItem
                                    ? "Edit content"
                                    : "Create a new description"
                            }
                        />
                        <AddContentDescriptionForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                        />
                    </>
                );
            case LINK:
                return (
                    <>
                        <ModalHeading
                            title={editItem ? "Edit Link" : "Add Link"}
                            subTitle={
                                editItem ? "Edit content" : "Create a new link"
                            }
                        />
                        <AddContentLinkForm
                            selectedFormat={selectedFormat}
                            handleSuccess={handleSuccess}
                            capsuleData={capsuleData}
                            editItem={editItem}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        {renderBasedOnAction()}
                    </div>
                </div>
            </SideModal>
        </>
    );
};

CapsuleContentFormatModal.prototypes = {
    closeModal: PropTypes.func.isRequired,
    showModal: PropTypes.object.isRequired,
    selectedFormat: PropTypes.string,
    handleSuccess: PropTypes.func,
    capsuleData: PropTypes.object,
    editItem: PropTypes.object,
};

export default CapsuleContentFormatModal;
