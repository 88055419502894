import React from "react";
import { truncateString } from "utils/helper";
import "./CannedEmail.scss";

const CannedEmail = ({ onClick, cannedEmailBody, cannedEmailSubject }) => {
    return (
        <div className='email__content__container' onClick={onClick}>
            <h6 className='email__content__container--subject'>
                Subject: {cannedEmailSubject}
            </h6>
            <p className='email__content__container--content'>
                {truncateString(cannedEmailBody, 60)}
            </p>
        </div>
    );
};

export default CannedEmail;
