import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import BranchViewGroup from "../BranchGroupView/BranchGroupView";
import {
    DOWNTIME_BRANCH,
    SUB_DOWNTIME_BRANCH,
    UPTIME_BRANCH,
} from "./DowntimeBranchesList/enums";
import DowntimeBranchesList from "./DowntimeBranchesList/DowntimeBranchesList";
import { GENERAL_DOWNTIME_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import { setExistingBranchOptionConnections } from "components/dashboard/Branches/BranchView/utils/helper";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const DowntimeBranchesView = ({ parentSource }) => {
    const { capsuleClassId, capsuleEntryId } = useParams();
    const [capsuleBranches, setCapsuleBranches] = useState({});
    const [activeBranchList, setActiveBranchList] = useState([]);
    const [activeTab, setActiveTab] = useState();

    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");
    const [preferredBranchId, setPreferredBranchId] = useState();

    const [selectedBranch, setSelectedBranch] = useState();
    const [branchViewStatus, setBranchViewStatus] = useState(IDLE);
    const [bvErrorMssg, setBVErrorMssg] = useState("");
    const [existingOptionConnections, setExistingOptionConnections] = useState(
        {}
    );
    const [branchCollectionId, setBranchCollectionId] = useState("");

    const handleBranchSelect = async (branchId) => {
        try {
            setSelectedBranch({ branchId });
            setBranchViewStatus(LOADING);
            const url = apiRoute?.getBranch(branchId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;

                setSelectedBranch(data);
                setBranchViewStatus(DATAMODE);
                setExistingBranchOptionConnections(
                    data,
                    setExistingOptionConnections
                );
            }
        } catch (err) {
            setBVErrorMssg(getErrorMessage(err));
            setBranchViewStatus(ERROR);
        }
    };

    const setBranchListBasedOnTab = (tabType, branchList = capsuleBranches) => {
        const { downtimebranch, subtimebranch, uptimebranch } = branchList;
        switch (tabType) {
            case DOWNTIME_BRANCH:
                setActiveBranchList(downtimebranch);
                break;
            case SUB_DOWNTIME_BRANCH:
                setActiveBranchList(subtimebranch);
                break;
            case UPTIME_BRANCH:
                setActiveBranchList(uptimebranch);
                break;
            default:
                break;
        }
    };

    const handleTabSelection = (tabType) => {
        setActiveTab(tabType);
        setPreferredBranchId();
        setSelectedBranch();
        setBranchListBasedOnTab(tabType);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <DowntimeBranchesList
                            activeTab={activeTab}
                            setActiveTab={handleTabSelection}
                        />
                        {activeTab && (
                            <BranchViewGroup
                                key={activeTab}
                                branches={activeBranchList}
                                branchGroupType={activeTab}
                                parentCapsule={{
                                    capsuleClassId,
                                    capsuleEntryId,
                                }}
                                parentSource={parentSource}
                                handleFullPageRefresh={retrieveDowntimeBranches}
                                preferredBranchId={preferredBranchId}
                                {...{
                                    selectedBranch,
                                    branchViewStatus,
                                    bvErrorMssg,
                                    handleBranchSelect,
                                }}
                                existingOptionConnections={
                                    existingOptionConnections
                                }
                                branchCollectionId={branchCollectionId}
                                setSelectedBranch={setSelectedBranch}
                            />
                        )}
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={retrieveDowntimeBranches}
                    />
                );

            default:
                return "";
        }
    };

    const retrieveDowntimeBranches = async (
        branchType = DOWNTIME_BRANCH,
        branchId
    ) => {
        try {
            setStatus(LOADING);
            const url =
                parentSource === GENERAL_DOWNTIME_COLLECTION
                    ? apiRoute?.getGeneralDowntimeBranchCollections
                    : apiRoute?.getAllCapsuleBranches(
                          capsuleClassId,
                          capsuleEntryId
                      );

            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res?.data;
                setActiveTab(branchType);
                setPreferredBranchId(branchId);
                setCapsuleBranches(data);
                setBranchListBasedOnTab(branchType, data);
                if (data?.branchCollectionId) {
                    setBranchCollectionId(data?.branchCollectionId);
                }
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        retrieveDowntimeBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='meta__container py-4 conversations-cont'>
                {renderBasedOnStatus()}
            </div>
        </>
    );
};

export default DowntimeBranchesView;
