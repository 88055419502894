import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Loading } from "components/ui";
import DiscoveryHeader from "./DiscoveryHeader/DiscoveryHeader";
import DiscoveryModal from "./DiscoveryModal/DiscoveryModal";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorView from "components/ui/ErrorView/ErrorView";
import DiscoveryTable from "./DiscoveryTable/DiscoveryTable";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import {
    setDiscoveryBaseFilter,
    setDiscoveryFilterParams,
    setDiscoveryFilterOption,
} from "store/filters/actions";
import { saveDiscoveries, clearDiscoveries } from "store/modules/sam/actions";
import "./Discovery.scss";

const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const Discovery = () => {
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [showSideModal, setShowSideModal] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState({});
    const [issues, setIssues] = useState([]);
    const [errorMssg, setErrorMssg] = useState("");
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.discoveryFilters
    );

    const {
        discoveriesCache: { pages, totalRecords },
    } = useSelector((state) => state?.sam);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const getDiscoveries = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (issues?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const res = await API.get(apiRoute?.getDiscoveries, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                setIssues(data);
                dispatch(
                    saveDiscoveries(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const closeSideModal = () => setShowSideModal(false);

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setIssues(cachedPage);
        } else {
            getDiscoveries(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setDiscoveryFilterParams({ currentPage: 1, search }));
        dispatch(clearDiscoveries());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setDiscoveryFilterParams({ ...filterParams, currentPage }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setDiscoveryBaseFilter(baseFilter));

        dispatch(setDiscoveryFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setDiscoveryFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setDiscoveryFilterOption({ ...option, value }));
        dispatch(clearDiscoveries());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setDiscoveryFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setDiscoveryFilterOption({ ...option, value }));
        dispatch(clearDiscoveries());
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setDiscoveryFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setDiscoveryFilterOption({ ...option, value }));
        dispatch(clearDiscoveries());
    };

    const handleSuccess = () => {
        setClearSearch(true);

        dispatch(clearDiscoveries());
        getDiscoveries();
        // dispatch(
        //     setDiscoveryFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getDiscoveries}
                    />
                );

            case NULLMODE:
                return (
                    <Empty
                        text={
                            useTableLoader
                                ? "No Discovery matches the search."
                                : "No Discovery has been created yet."
                        }
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <DiscoveryTable
                            tableIsLoading={tableIsLoading}
                            issues={issues}
                            handleSuccess={handleSuccess}
                            setSelectedIssue={setSelectedIssue}
                            setShowSideModal={setShowSideModal}
                            errorMssg={errorMssg}
                        />

                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <div className='discovery'>
                <DiscoveryHeader
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    handleSortByAlpha={handleSortByAlpha}
                    handleSortByDate={handleSortByDate}
                    handleSortByLastModified={handleSortByLastModified}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    handleBasefilter={handleBasefilter}
                    searchDefaultValue={filterParams?.search}
                />
                {renderBasedOnStage()}
            </div>

            {showSideModal && (
                <DiscoveryModal
                    showSideModal={showSideModal}
                    closeSideModal={closeSideModal}
                    selectedIssue={selectedIssue}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

export default Discovery;
