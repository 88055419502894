import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./Empty.scss";

export const Empty = ({ className, text, subText, icon, hideIcon}) => {
    return (
        <div className={`empty ${className || ""}`}>
            {!hideIcon && <ReactSVG
                src={icon || asset?.images?.emptyCollection}
                fallback='No component'
                className='empty--svg'
            />}
            <h1 className='empty--text'>{text}</h1>
            { subText && <p className="empty--sub-text">{subText}</p>}
        </div>
    );
};
