export const ROLES_PERMS_HEADERS = ["Full Name", "Modules", "Actions"];

export const ROLES_PERMS_ACTIONS = {
	CREATE_ROLE: "CREATE_ROLE",
	EDIT_ROLE: "EDIT_ROLE",
	ROLE_DESC: "ROLE_DESC",
	DELETE_ROLE: "DELETE_ROLE",
};

export const ROLE_MODULES = ["Edit Knowledge Base", "Access to Agent Inbox"];
