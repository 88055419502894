import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    collections: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_COLLECTIONS_LIST:
            return {
                ...state,
                collections: getUniqueListBy(
                    [...state.collections, ...payload],
                    "id"
                ),
            };

        case types.SET_COLLECTIONS_LIST:
            return { ...state, collections: payload };

        default:
            return state;
    }
};

export default AuthReducer;
