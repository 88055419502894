import { useState } from "react";
import AnalyticsQADropHeader from "../../../common/AnalyticsQADropHeader/AnalyticsQADropHeader";
import AnalyticsQATicketSummaryItem from "./AnalyticsQATicketSummaryItem/AnalyticsQATicketSummaryItem";

const AnalyticsQATicketSummary = () => {
    const [isOpen, toggleOpen] = useState(false);
    const items = [
        {
            title: "User's message",
            content: "I can't fund my account",
        },
        {
            title: "User's message",
            // content: "I can't fund my account",
            initiationDuration: "20mins",
            ticketResponse: "20% faster",
            ticketVol: "20% less",
        },
    ];
    return (
        <section>
            <AnalyticsQADropHeader
                title={"Ticket Summary"}
                isOpen={isOpen}
                toggleOpen={() => toggleOpen(!isOpen)}
            />
            {isOpen && (
                <>
                    {items?.map((item, key) => (
                        <AnalyticsQATicketSummaryItem key={key} {...item} />
                    ))}
                </>
            )}
        </section>
    );
};

export default AnalyticsQATicketSummary;
