import { useState } from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./NavSearch.scss";

export const NavSearch = ({
    grpClass,
    placeholder,
    inputClass,
    id,
    name,
    onSearch,
}) => {
    const [focused, setFocused] = useState("");
    const [searchValue, setSearch] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!searchValue) return;

        onSearch(searchValue);
    };

    return (
        <form
            className={`input-group nav-search-input ${grpClass || ""}`}
            onSubmit={handleSubmit}>
            <input
                type='search'
                id={id}
                name={name}
                className={`form-control ${inputClass || ""}`}
                placeholder={placeholder}
                aria-label='Search'
                aria-describedby='search-addon2'
                value={searchValue}
                onChange={({ target: { value } }) => setSearch(value)}
                onFocus={() => setFocused("focus")}
                onBlur={() => setFocused("")}
            />
            <button
                className={`input-group-btn btn ${focused}`}
                id='search-addon2'>
                <ReactSVG
                    src={asset?.icons?.search}
                    className='d-inline-flex'
                />
            </button>
        </form>
    );
};
