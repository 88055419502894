import AnalyticsQACard from "../../common/AnalyticsQACard/AnalyticsQACard";

const AnalyticsQAProgressSummary = () => {
    const data = [
        {
            title: "Number of tickets",
            value: 30,
            dir: "UPTIME",
            change: "20%",
        },
        {
            title: "Average accuracy",
            value: "70%",
            dir: "DOWNTIME",
            change: "20%",
        },
    ];
    return (
        <section className='mt-2'>
            <div className='row'>
                {data?.map((item, key) => (
                    <div className='col-lg-3' key={key}>
                        <AnalyticsQACard {...item} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default AnalyticsQAProgressSummary;
