export const SET_CURRENT_ANALYTICS_FILTER = "SET_CURRENT_ANALYTICS_FILTER";
export const UPDATE_CURRENT_ANALYTICS_FILTER =
    "UPDATE_CURRENT_ANALYTICS_FILTER";
export const SET_ANALYTICS_TEAMS = "SET_ANALYTICS_TEAMS";
export const SET_ANALYTICS_AGENTS = "SET_ANALYTICS_AGENTS";
export const SET_ANALYTICS_CLASSIFICATIONS = "SET_ANALYTICS_CLASSIFICATIONS";
export const SET_ANALYTICS_CHART_DATA = "SET_ANALYTICS_CHART_DATA";
export const SET_ANALYTICS_CUSTOMER_CHART_DATA =
    "SET_ANALYTICS_CUSTOMER_CHART_DATA";
export const UPDATE_CURRENT_ANALYTICS_CUSTOM_DATE =
    "UPDATE_CURRENT_ANALYTICS_CUSTOM_DATE";
