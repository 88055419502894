import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TicketAACardHeading from "../common/TicketAACardHeading/TicketAACardHeading";
import TicketAARunCard from "../common/TicketAARunCard/TicketAARunCard";
import TicketAASentMention from "./TicketAASentMention/TicketAASentMention";
import TicketAACreateMentions from "./TicketAACreateMentions/TicketAACreateMentions";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import "./TicketAAMentions.scss";
import TicketAATasksList from "../common/TicketAATasksList/TicketAATasksList";
const { LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const TicketAAMentions = ({
    cannedMention,
    selectedTicket,
    updateTasks,
    handleToggleEditTicketTaskModal,
    ticketConversation,
    handleCannedActionSuccess,
}) => {
    const [open, toggleOpen] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");
    const [status, setStatus] = useState(LOADING);
    const [mentions, setMentions] = useState([]);
    const { activeTicket } = useSelector((state) => state.tickets);
    const pausedTasks = updateTasks.filter((ut) => ut.paused === true)
    const runningTasks = updateTasks.filter((ut) => ut.paused === false && ut.taskStatus === "PENDING");
    
    let overalTaskStatuses = updateTasks.reduce(function (r, a) {
        r[a.taskStatus] = (r[a.taskStatus] || 0) + 1;
        return r;
    }, {});

    let overalTaskStaus = runningTasks.length > 0 ? ["PENDING", runningTasks.length] : pausedTasks.length > 0 ? ["PAUSED", pausedTasks.length] : Object.entries(overalTaskStatuses).reduce((max, entry) => entry[1] >= max[1] ? entry : max, [0, -Infinity])

    const getTicketMentions = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoute.getTicketComments(activeTicket?.ticketId);

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setMentions(data);
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getTicketMentions(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTicket]);

    const hasAMention = Boolean(cannedMention);

    const { cannedResourceId } = cannedMention;

    const hasCannedMentionBeenSent = ticketConversation?.find(
        ({ ticketConversationId, ticketConversationType }) =>
            ticketConversationId === cannedResourceId &&
            ticketConversationType === "CANNED_MENTIONS"
    );

    return (
        <section className='ticketaa-mentions'>
            <TicketAACardHeading
                title='Mentions'
                onClick={() => toggleOpen(!open)}
                isOpen={open}
                showDot={!Boolean(hasCannedMentionBeenSent)}
                showMark={Boolean(hasCannedMentionBeenSent)}
            />
            {open && (
                <>
                    {hasAMention && !hasCannedMentionBeenSent ? (
                        <>
                            <TicketAACreateMentions
                                borderColor={"#25BB87"}
                                cannedMention={cannedMention}
                                handleSuccess={getTicketMentions}
                                handleCannedActionSuccess={
                                    handleCannedActionSuccess
                                }
                                isDisabled={Boolean(hasCannedMentionBeenSent)}
                            />
                            {updateTasks.length > 0 && <TicketAARunCard
                                {
                                ...{
                                    updateTasks,
                                    overalTaskStaus
                                }
                                }
                            />
                            }
                        </>
                    ) :

                        <TicketAATasksList
                            handleToggleEditTicketTaskModal={
                                handleToggleEditTicketTaskModal
                            }
                            overalTaskStaus={overalTaskStaus}
                            updateTasks={updateTasks}
                            successMssg={`Mentions Sent Out`}
                        >  <TicketAASentMention
                                status={status}
                                mentions={mentions}
                                errorMssg={errorMssg}
                            />
                        </TicketAATasksList>

                    }
                </>
            )}
        </section>
    );
};

export default TicketAAMentions;
