import React, { useContext, useRef, useState } from "react";
import ScheduleTaskButton from "./ScheduleTaskButton/ScheduleTaskButton";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { SAVE_BUTTON } from "../../../enum";
import TaskReminder from "./TaskReminder/TaskReminder";
import "./ScheduleTaskReminder.scss";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage, getSecondsSince } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { SCHEDULE_TASK_ACTIONS } from "./TaskReminder/enum";

const { ERROR } = dataQueryStatus;

const ScheduleTaskReminder = ({
    reminderDuration,
    ticketId,
    ticketFollowupTaskId,
    toggleDelScheduleTaskModal,
    handleSuccess,
}) => {
    const [loading, setLoading] = useState(false);

    const [hasError, setHasError] = useState(false);
    const [showScheduleInput, setShowScheduleInput] = useState(false);
    const [scheduleDuration, updateScheduleDuration] =
        useState(reminderDuration);
    const [isEdit, setIsEdit] = useState(false);

    const toastMessage = useContext(ToastContext);

    const scheduleTaskReminder = async () => {
        try {
            setLoading(true);

            const url = apiRoute?.scheduleTaskReminder(
                ticketId,
                ticketFollowupTaskId,
                scheduleDuration
            );

            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res.data;

                setLoading(false);
                toastMessage(message);

                setShowScheduleInput(false);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleShowScheduleInput = () => {
        if (scheduleDuration === 0) {
            return setHasError(true);
        }

        if (!showScheduleInput) {
            setShowScheduleInput(true);
        }

        if (showScheduleInput) {
            scheduleTaskReminder();
        }
    };

    const handleTaskReminderAction = (action) => {
        if (action === SCHEDULE_TASK_ACTIONS.DELETE_SCHEDULE) {
            toggleDelScheduleTaskModal(true);
            setIsEdit(false);
        } else {
            setShowScheduleInput(true);
            setIsEdit(true);
        }
    };

    const reminderSeconds = useRef(getSecondsSince(reminderDuration));

    return (
        <div className='schedule__task__reminder'>
            {showScheduleInput && (
                <div>
                    <ScheduleDayInput
                        label='Reminder'
                        showLabel={true}
                        showSubLabel={false}
                        name=''
                        id=''
                        handleDateChange={(totalNoOfSeconds) => {
                            updateScheduleDuration(totalNoOfSeconds);
                            setHasError(false);
                        }}
                        error={hasError}
                        timeValue={reminderSeconds.current}
                        // maxDays={10}
                        showSecondsInput={false}
                        leftColumnClass='col-md-7'
                        rightColumnClass='col-md-5'
                    />
                </div>
            )}

            {reminderDuration && !showScheduleInput ? (
                <TaskReminder
                    reminderDuration={reminderDuration}
                    handleTaskReminderAction={handleTaskReminderAction}
                />
            ) : (
                <ScheduleTaskButton
                    text={
                        showScheduleInput
                            ? isEdit
                                ? "Update Reminder"
                                : "Schedule reminder"
                            : "Schedule a reminder"
                    }
                    type={showScheduleInput ? SAVE_BUTTON : ""}
                    onClick={handleShowScheduleInput}
                    loading={loading}
                />
            )}
        </div>
    );
};

export default ScheduleTaskReminder;
