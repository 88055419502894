import { Table } from "components/ui";
import CRTableDropdown from "./CRTableDropdown/CRTableDropdown";
import CRTableFormsList from "./CRTableFormsList/CRTableFormsList";

const CannedResponsesTable = ({
    handleActionModal,
    cannedResponses,
    tableIsLoading,
    errorMssg,
}) => {
    const tableData = cannedResponses?.map?.(({ title, forms, ...rest }) => {
        return {
            name: title,
            forms: <CRTableFormsList forms={forms} />,
            actions: (
                <CRTableDropdown
                    handleActionModal={(value) =>
                        handleActionModal(value, { title, forms, ...rest })
                    }
                    cannedResponse={{ title, forms, ...rest }}
                />
            ),
        };
    });

    return (
        <Table
            headers={["Name", "Forms Connected", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
            hasErrors={Boolean(errorMssg)}
            tableError={errorMssg}
        />
    );
};

export default CannedResponsesTable;
