import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { formatFileSize, truncateString } from "utils/helper";
import "./UploadingEmailAttachment.scss";

const UploadingEmailAttachment = ({
    file,
    files,
    setFiles,
    setUploadComplete,
    handleRemoveFile,
}) => {
    const { filename, size: fileSize, id, progress } = file || {};

    useEffect(() => {
        setUploadComplete(false);

        if (progress < 100) {
            setTimeout(() => {
                setFiles((prevFiles) => {
                    return prevFiles.map((prevFile) => {
                        if (prevFile?.id === id) {
                            return {
                                ...prevFile,
                                progress:
                                    progress + Math.floor(99428800 / fileSize),
                            };
                        } else {
                            return prevFile;
                        }
                    });
                });
            }, 2000);
        }

        const uploadComplete = files
            ?.map(({ progress }) => progress)
            ?.every((progress) => progress === 100 || progress > 100);

        setUploadComplete(uploadComplete);
        //eslint-disable-next-line
    }, [progress]);

    return (
        <div className='uploading__email__attachment'>
            <div className='uploading__email__attachment--details'>
                <div className='email__attachment__info'>
                    <div>
                        <ReactSVG
                            src={imageLinks?.icons?.document}
                            className='document__icon'
                        />
                        <span className='email__attachment__info--name'>
                            {truncateString(filename, 20)}
                        </span>
                    </div>
                    <ReactSVG
                        src={imageLinks?.icons?.cancelX}
                        onClick={() => handleRemoveFile(id)}
                        className='remove__uploading__email__attachment'
                    />
                </div>
                <div className='email__attachment__upload__progress__container'>
                    <progress
                        className='email__attachment__upload__progress'
                        max={100}
                        value={progress}></progress>
                    <span className='email__attachment__info--size'>
                        {formatFileSize(fileSize)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default UploadingEmailAttachment;
