import React from "react";
import "./MessageTag.scss";

const MessageTag = ({ text, active, onClick }) => {
    return (
        <div
            className={`message__tag ${active ? "active__message__tag" : ""}`}
            onClick={onClick}>
            {text}
        </div>
    );
};

export default MessageTag;
