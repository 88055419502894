import React from "react";
import { SideModal } from "components/ui";
import { ChangePasswordForm } from "./ChangePasswordForm/ChangePasswordForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const ChangePasswordModal = ({ closeModal }) => {
    return (
        <SideModal show={true} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Change Password'
                        subTitle='Update your current password to a new password'
                    />
                    <ChangePasswordForm closeModal={closeModal} />
                </div>
            </div>
        </SideModal>
    );
};

export default ChangePasswordModal;
