import AnalyticsQAMTBlock from "./AnalyticsQAMTBlock/AnalyticsQAMTBlock";
import "./AnalyticsQAMessageTimeline.scss";

const AnalyticsQAMessageTimeline = () => {
    const messageList = [
        {
            profilePic: "",
            title: "Agent sent a message",
            time: "12:12pm",
            messages: [
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
            ],
        },
        {
            profilePic: "",
            title: "Agent sent a message",
            time: "12:12pm",
            messages: [
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
            ],
        },
        {
            profilePic: "",
            title: "Agent sent a message",
            time: "12:12pm",
            messages: [
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
            ],
        },
        {
            profilePic: "",
            title: "Agent sent a message",
            time: "12:12pm",
            messages: [
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
                {
                    content:
                        "This letter is regarding the failed transaction #20234567",
                    time: "12/06",
                    profilePic: "",
                    title: "Payment refund for failed transaction",
                },
            ],
        },
    ];
    return (
        <div className='analytics-qa-message-timeline'>
            {messageList?.map((content, key) => (
                <AnalyticsQAMTBlock key={key} {...content} />
            ))}
        </div>
    );
};

export default AnalyticsQAMessageTimeline;
