import { Checkbox } from "components/ui";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_SERVICE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;

const PersonaStatus = ({ persona, updatePersonaStatus }) => {
    return (
        <ComponentAccessControl
            baseResources={[`${UPDATE}_${AGENTS_INBOX_SERVICE_SETTING}`]}>
            <Checkbox
                name='status'
                label={persona?.active ? "Active" : "Disabled"}
                inputClass='input-check-grey'
                id='status'
                onChange={() => updatePersonaStatus(persona?.userId)}
                checked={persona?.active}
            />
        </ComponentAccessControl>
    );
};

export default PersonaStatus;
