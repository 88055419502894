import React, { useState } from "react";
import { Input, Textarea, Button } from "components/ui";
import { cannedContentTypes } from "../enum";
import "./CannedContentForm.scss";

const { CANNED_EMAIL } = cannedContentTypes;

const CannedContentForm = ({
    type,
    handleSaveCannedContent,
    isEdit,
    selectedCannedContent,
    handleEditCannedContent,
}) => {
    const [request, setRequest] = useState({
        cannedEmailSubject: isEdit
            ? selectedCannedContent?.cannedEmailSubject
            : "",
        cannedEmailBody: isEdit ? selectedCannedContent?.cannedEmailBody : "",
        cannedResponse: isEdit ? selectedCannedContent?.cannedResponse : "",
    });

    const isCannedEmail = type === CANNED_EMAIL;

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { cannedEmailSubject, cannedEmailBody, cannedResponse } = request;

        isEdit
            ? handleEditCannedContent(
                  isCannedEmail
                      ? {
                            cannedEmailSubject,
                            cannedEmailBody,
                            index: selectedCannedContent?.index,
                        }
                      : { cannedResponse, index: selectedCannedContent?.index }
              )
            : handleSaveCannedContent(
                  isCannedEmail
                      ? {
                            cannedEmailSubject,
                            cannedEmailBody,
                        }
                      : { cannedResponse }
              );
    };

    return (
        <form className='canned__content__form'>
            {isCannedEmail && (
                <Input
                    grpClass='mb-3'
                    type='text'
                    name='cannedEmailSubject'
                    label='Subject'
                    labelClass='text-dark fw-bold'
                    id='cannedEmailSubject'
                    placeholder='Enter Subject here'
                    value={request?.cannedEmailSubject}
                    onChange={(e) => handleChange(e)}
                />
            )}

            <Textarea
                grpClass='mb-3'
                name={`${isCannedEmail ? "cannedEmailBody" : "cannedResponse"}`}
                label='Message'
                labelClass='text-dark fw-bold'
                id={`${isCannedEmail ? "cannedEmailBody" : "cannedResponse"}`}
                placeholder='Enter Message here'
                value={
                    isCannedEmail
                        ? request?.cannedEmailBody
                        : request?.cannedResponse
                }
                onChange={(e) => handleChange(e)}
            />
            <Button
                type='submit'
                classType='primary'
                otherClass='w-100'
                text={`${
                    isEdit
                        ? `Edit canned ${isCannedEmail ? "email" : "response"}`
                        : `Add canned ${isCannedEmail ? "email" : "response"}`
                }`}
                onClick={handleSubmit}
            />
        </form>
    );
};

export default CannedContentForm;
