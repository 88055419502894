import React, { createContext, useEffect, useState, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import config from "config/config";
import { retriveAccessToken } from "storage/cookieStorage";

export const SocketContext = createContext(null);

const RECONNECT_INTERVAL = 300000; // 5 minutes in milliseconds
const MAX_RECONNECT_ATTEMPTS = 1; // Max attempts to reconnect


export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const reconnectAttempts = useRef(0);
  const lastAttemptTime = useRef(0);

  const token = retriveAccessToken();

  const connectSocket = useCallback(() => {
    const now = Date.now();
    const timeSinceLastAttempt = now - lastAttemptTime.current;

    if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      return;
    }

    if (timeSinceLastAttempt < RECONNECT_INTERVAL) {
      const delay = RECONNECT_INTERVAL - timeSinceLastAttempt;
      console.log(`Waiting ${delay}ms before next reconnection attempt`);
      setTimeout(connectSocket, delay);
      return;
    }

    lastAttemptTime.current = now;
    reconnectAttempts.current += 1;

    const newSocket = io(config.socketGateway.SOCKET_BASE_URL, {
      extraHeaders: { authorization: token },
      reconnection: false,  // Disable automatic reconnection
    });

    newSocket?.on('connect', () => {
      console.log('Connected to socket');
      reconnectAttempts.current = 0; // Reset on successful connection
    });

    newSocket?.on('disconnect', () => {
      console.log('Socket disconnected, will attempt to reconnect...');
      setTimeout(connectSocket, RECONNECT_INTERVAL);
    });

    newSocket?.on('connect_error', (err) => {
      console.log('Connection Error:', err.message);
      setTimeout(connectSocket, RECONNECT_INTERVAL); // Wait 5 mins before retrying
    });

    setSocket(newSocket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    connectSocket();

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectSocket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
