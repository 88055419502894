import { useContext, useState } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Input, Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

export const ChangePasswordForm = ({ closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [request, setRequest] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
    });
    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const updatePassword = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.put(apiRoute?.updatePassword, request);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            delete request.confirmPassword;
            updatePassword();
        } else setErrors(formErrors);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMssg)}
                    message={errorMssg}
                    hide={() => setErrorMssg()}
                />
                <Input
                    grpClass='mb-3'
                    type='password'
                    name='oldPassword'
                    label='Old Password'
                    labelClass='text-dark fw-medium'
                    id='oldPassword'
                    placeholder='Enter your old password'
                    value={request?.oldPassword}
                    onChange={handleChange}
                    isErr={errors?.oldPassword}
                    errMssg={errors?.oldPassword}
                    showPwd
                    autoFocus={true}
                />
                <Input
                    grpClass='mb-3'
                    type='password'
                    name='password'
                    label='New Password'
                    labelClass='text-dark fw-medium'
                    id='password'
                    placeholder='Enter your new password'
                    value={request?.password}
                    onChange={handleChange}
                    fieldType='password'
                    isErr={errors?.password}
                    errMssg={errors?.password}
                    showPwd
                />
                <Input
                    grpClass='mb-3'
                    type='password'
                    name='confirmPassword'
                    label='Confirm Password'
                    labelClass='text-dark fw-medium'
                    id='confirmPassword'
                    placeholder='Confirm your new password'
                    value={request?.confirmPassword}
                    onChange={handleChange}
                    isErr={errors?.confirmPassword}
                    errMssg={errors?.confirmPassword}
                    showPwd
                />
                <Button
                    type='submit'
                    text='Update Password'
                    classType='primary'
                    loading={loading}
                    otherClass='my-3 w-100'
                />
            </form>
        </>
    );
};
