import CreateGDUAutomationFields from "./CreateGDUAutomationFields/CreateGDUAutomationFields";
import AddButton from "components/ui/Button/AddButton/AddButton";

const CreateGDUpdateAutomation = ({
    selectedUpdate,
    agentUpdateTasks,
    setAgentUpdateTasks,
    defaultTask,
    isEdit,
}) => {
    const handleAddAutomation = () => {
        const generatedNewTaskName = `Uptime Task ${
            agentUpdateTasks?.length + 1
        }`;
        setAgentUpdateTasks((prevTasks) => [
            ...prevTasks,
            { ...defaultTask, agentUpdateTaskName: generatedNewTaskName },
        ]);
    };

    const handleRemoveRequest = (index) => {
        setAgentUpdateTasks((prevTasks) =>
            prevTasks?.filter((s, i) => i !== index)
        );
    };

    const handleUpdateRequest = (key, value, index) => {
        setAgentUpdateTasks((prevTasks) =>
            prevTasks?.map((task, pos) => {
                if (pos === index) {
                    return {
                        ...task,
                        [key]: value,
                    };
                } else {
                    return task;
                }
            })
        );
    };

    return (
        <div>
            {agentUpdateTasks?.map((task, index) => (
                <CreateGDUAutomationFields
                    handleUpdateRequest={(key, value) =>
                        handleUpdateRequest(key, value, index)
                    }
                    handleRemoveRequest={() => handleRemoveRequest(index)}
                    {...task}
                    index={index}
                    key={index}
                    isEdit={isEdit}
                />
            ))}
            <div className='new-automation-button'>
                <AddButton
                    btnText={"New Automation"}
                    onClick={handleAddAutomation}
                />
            </div>
        </div>
    );
};

export default CreateGDUpdateAutomation;
