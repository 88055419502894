import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { VARIANT_ACTIONS } from "../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const VariantDropdown = ({ variant, handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, variant)}
            options={[
                {
                    value: VARIANT_ACTIONS?.EDIT,
                    label: "Edit",
                    baseResources: [`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`],
                },
                {
                    value: VARIANT_ACTIONS?.DELETE,
                    label: "Delete",
                    baseResources: [`${DELETE}_${KB_SAM_ISSUE_CONVERSATION}`],
                },
            ]}
        />
    );
};

export default VariantDropdown;
