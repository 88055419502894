import React from "react";
import { Textarea } from "components/ui";
import { SimpleDeleteIconButton } from "components/ui/Button/IconButton/IconButton";
import BadgeLabel from "../BadgeLabel/BadgeLabel";

const DeletableInput = ({
    name,
    placeholder,
    value,
    error,
    badgeText,
    hideBadge,
    handleInputChange,
    handleInputDelete,
    otherClassName,
}) => {
    return (
        <div
            className={`deletable__input row  ${
                otherClassName ? otherClassName : ""
            }`}>
            {!hideBadge && (
                <div className='col-md-2'>
                    <BadgeLabel text={badgeText} />
                </div>
            )}
            <div className={hideBadge ? "col-md-10" : "col-md-8 ps-0"}>
                <Textarea
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    inputClass={hideBadge ? "full-height" : ""}
                    onChange={({ target: { name, value } }) =>
                        handleInputChange(name, value)
                    }
                    isErr={error}
                />
            </div>
            <div className='col-md-2'>
                <SimpleDeleteIconButton onClick={handleInputDelete} />
            </div>
        </div>
    );
};

export default DeletableInput;
