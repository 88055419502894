import InviteTeamButton from "./InviteTeamButton/InviteTeamButton";
import InviteTeamSearch from "./InviteTeamSearch/InviteTeamSearch";
import "./InviteTeamHeader.scss";

const InviteTeamHeader = ({
    users,
    setUsers,
    selectedUsers,
    setSelectedUsers,
    status,
    userErrorMssg,
    request,
    setRequest,
    handleRetry,
    updateErrors,
    showInviteTeamSearch,
    setShowInviteTeamSearch,
}) => {
    const closeInviteTeamSearch = () => setShowInviteTeamSearch(false);
    return (
        <>
            <div className='users__search__header'>
                <h5 className='users__search__header--text team-form__text'>
                    Invite team members
                </h5>
                <InviteTeamButton
                    {...{ showInviteTeamSearch, setShowInviteTeamSearch }}
                />
            </div>

            {showInviteTeamSearch && (
                <InviteTeamSearch
                    {...{
                        closeInviteTeamSearch,
                        users,
                        setUsers,
                        selectedUsers,
                        setSelectedUsers,
                        status,
                        userErrorMssg,
                        request,
                        setRequest,
                        handleRetry,
                        updateErrors,
                    }}
                />
            )}
        </>
    );
};

export default InviteTeamHeader;
