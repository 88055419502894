import { Checkbox } from "components/ui";
import { useState, useContext } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./NonEmptyConversationBody.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const NonEmptyConversationBody = ({
    useVariables,
    messageTemplateVariables,
    introTextValue,
    mainSentence,
    templateId,
    introText
}) => {
    const [checked, setChecked] = useState(useVariables);
    const toastMessage = useContext(ToastContext);

    const handleCheck = async (checkedStatus) => {
        try {
            setChecked(checkedStatus);

            const url = apiRoute?.editMssgTemplateVariableComp(templateId);
            const res = await API.put(url, {
                useVariables: !checked,
            });

            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
            }
        } catch (err) {
            setChecked(!checkedStatus);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <div className='basic__template'>
            <h1 className='text-dark fw-bold mb-0'>Greeting</h1>
            <div className='basic__template--inner__box'>
                <h1>{introText}</h1>
            </div>
            <div className='basic__template--inner__box'>
                <p>{mainSentence}</p>
            </div>
            {messageTemplateVariables.length > 0 && (
                <div>
                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <Checkbox
                            grpClass='mt-3'
                            name='visible'
                            label={`Replace "${messageTemplateVariables[0]?.templateVariable?.defaultValue}" with ${messageTemplateVariables[0]?.templateVariable?.displayName}`}
                            labelClass='check__box'
                            inputClass='input-check-grey'
                            id='visible'
                            onChange={(e) => handleCheck(e.target.checked)}
                            checked={checked}
                        />
                    </ComponentAccessControl>
                </div>
            )}
        </div>
    );
};

export default NonEmptyConversationBody;
