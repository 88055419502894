export const selectNextTicket = (ticket, sortedTickets, callback) => {
    let currentTicketPos = null;
    sortedTickets?.map(({ ticketId }, index) => {
        if (ticketId === ticket?.ticketId) {
            currentTicketPos = index;
            return currentTicketPos;
        } else {
            return "";
        }
    });

    if (currentTicketPos !== null && sortedTickets?.length > 1) {
        let nextTicket = sortedTickets?.[currentTicketPos + 1];

        if (currentTicketPos === sortedTickets?.length - 1) {
            nextTicket = sortedTickets?.[sortedTickets?.length - 2];
        }

        callback(nextTicket, ticket?.ticketId);
    } else {
        callback(null, ticket?.ticketId);
    }
};
