import { useContext, useState } from "react";
import "./TicketAANextSteps.scss";

import TicketAGuideStep from "./TicketAGuideStep/TicketAGuideStep";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import API from "services/lib/api";
import { setActiveTicket } from "store/tickets/actions";
import { useDispatch } from "react-redux";

const { ERROR } = dataQueryStatus;

const TicketAANextSteps = ({
    steps,
    nextSteps,
    currentStep,
    handleSuccess,
    guideDetails,
    selectedTicket,
    selectGuideOptionId
}) => {
    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();


    const [loading, setLoading] = useState(false);
    const [selectedFlowOptionId, selectFlowOptionId] = useState();

    const selectFlowNextStep = async (guideFlowOptionId, guideFlowOptionActionType) => {
        try {
            setLoading(true);
            const reset = currentStep?.selectedOption
            const url = `${apiRoute?.selectGuideNextStep(
                currentStep?.ticketAgentGuideOptionId,
                selectedTicket?.ticketId,
                guideFlowOptionId
            )}${reset ? `?reset=${true}` : ""}`;

            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess(true, setLoading);
                // 
                if (guideFlowOptionActionType === guideFlowOptionActionType?.CLOSE_CONVERSATION || guideFlowOptionActionType === guideFlowOptionActionType?.RESTART_CONVERSATION) {
                    dispatch(setActiveTicket());
                    
                }
            }
        } catch (err) {
            
            const errorMsg = getErrorMessage(err);
            toastMessage(errorMsg, ERROR);
            setLoading(false);
        }
    };

    const handleNextStep = (guideFlowOptionId, guideFlowOptionActionType, reset) => {
        if (!loading) {
            selectFlowOptionId(guideFlowOptionId);
            selectFlowNextStep(guideFlowOptionId, guideFlowOptionActionType, reset);
        }
    };

    return (
        <section className='ticketaa-next-steps'>
            <h5>Next Steps</h5>
            <div className='ticketaa-next-steps__list'>
                {nextSteps?.map?.((stepData, key) => (
                    <TicketAGuideStep
                        key={key}
                        steps={steps}
                        stepData={stepData}
                        handleNextStep={handleNextStep}
                        currentStep={currentStep}
                        disabledOption={currentStep?.selectedOption}
                        selectedFlowOptionId={selectedFlowOptionId}
                        selectGuideOptionId={selectGuideOptionId}
                        loading={loading}
                    />
                ))}
            </div>
        </section>
    );
};

export default TicketAANextSteps;
