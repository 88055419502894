import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    branches: [],
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_BRANCHES_LIST:
            return {
                ...state,
                branches: getUniqueListBy(
                    [...state.branches, ...payload],
                    "id"
                ),
            };

        case types.SET_BRANCHES_LIST:
            return { ...state, branches: payload };

        default:
            return state;
    }
};

export default AuthReducer;
