import React from "react";
import DeletableInput from "../../../../common/DeletableInput/DeletableInput";

const FormBuilderInputOption = ({
    elementId,
    badgeText,
    optionValue,
    optionId,
    handleOptionTextChange,
    handleOptionDelete,
    hasError,
}) => {
    return (
        <DeletableInput
            name={badgeText}
            badgeText={badgeText}
            value={optionValue}
            handleInputChange={(name, value) =>
                handleOptionTextChange(elementId, optionId, value)
            }
            handleInputDelete={() => handleOptionDelete(elementId, optionId)}
            error={hasError}
        />
    );
};

export default FormBuilderInputOption;
