import { useState } from "react";
import TagsNonSelected from "../TagsNonSelected/TagsNonSelected";
import TagsDiscoveredList from "./TagsDiscoveredList/TagsDiscoveredList";
import TDCurrentMessageTag from "./TDCurrentMessageTag/TDCurrentMessageTag";
import TagsDiscoveredMessage from "./TagsDiscoveredMessage/TagsDiscoveredMessage";

const TagsDiscoveredElement = ({ messageTagList, handleSelectTagResp }) => {
    const [selectedMssgTagId, setSelectedMssgTagId] = useState();

    const handleSelectMssgTag = (mssgTagId) => {
        setSelectedMssgTagId(mssgTagId);
    };

    return (
        <>
            <div>
                <TagsDiscoveredList
                    messageTagList={messageTagList}
                    handleSelectMssgTag={handleSelectMssgTag}
                    selectedMssgTagId={selectedMssgTagId}
                />
                <TagsDiscoveredMessage />
                {selectedMssgTagId !== undefined ? (
                    <TDCurrentMessageTag
                        selectedMssgTagId={selectedMssgTagId}
                        handleSelectTagResp={handleSelectTagResp}
                    />
                ) : (
                    <TagsNonSelected />
                )}
            </div>
        </>
    );
};

export default TagsDiscoveredElement;
