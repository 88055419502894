import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";

const CreateCRModalForm = ({
    isView,
    cannedResponse,
    isEdit,
    closeModal,
    handleSuccess,
}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [request, setRequest] = useState({
        title: "",
        subject: "",
    });

    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const updateCannedResponse = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute?.editCannedResponse(
                cannedResponse?.cannedResponseId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                closeModal();
                setLoading(false);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const createCannedResponse = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const url = apiRoute?.createTaglessCannedResponse;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                closeModal();
                handleSuccess();
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "subject",
        ]);
        if (formisValid) {
            isEdit ? updateCannedResponse() : createCannedResponse();
        } else setErrors(formErrors);
    };

    useEffect(() => {
        const { title, response, subject } = cannedResponse || {};
        setRequest({
            title,
            response,
            subject,
        });
    }, [cannedResponse]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMssg)}
                    message={errorMssg}
                    hide={() => setErrorMssg()}
                />
                <Input
                    label={"Name of Canned Response"}
                    grpClass='mb-3'
                    name='title'
                    labelClass='text-dark fw-medium'
                    id='title'
                    value={request?.title}
                    onChange={handleChange}
                    isErr={errors?.title}
                    errMssg={errors?.title}
                    autoFocus={true}
                    disabled={isView}
                />

                <Input
                    label={"Subject of Canned Response"}
                    grpClass='mb-3'
                    name='subject'
                    labelClass='text-dark fw-medium'
                    id='subject'
                    value={request?.subject}
                    onChange={handleChange}
                    isErr={errors?.subject}
                    errMssg={errors?.subject}
                    autoFocus={true}
                    disabled={isView}
                />

                <Textarea
                    label='Main Sentence'
                    grpClass='mb-3'
                    name='response'
                    labelClass='text-dark fw-medium'
                    id='response'
                    disabled={isView}
                    value={request?.response}
                    onChange={handleChange}
                    isErr={errors?.response}
                    errMssg={errors?.response}
                    autoFocus={true}
                />
                {!isView && (
                    <Button
                        type='submit'
                        text='Save Changes'
                        classType='primary'
                        loading={loading}
                        otherClass='my-3 w-100'
                    />
                )}
            </form>
        </>
    );
};

export default CreateCRModalForm;
