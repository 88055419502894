import { STATUS_CARD_TYPES } from "../../../enum";
import SwitchesStatusCard from "./SwitchesStatusCard/SwitchesStatusCard";
import "./SwitchesStatusCardGroup.scss";

const { ON_TYPE, OFF_TYPE } = STATUS_CARD_TYPES;

const SwitchesStatusCardGroup = ({ onStatusCount, offStatusCount }) => {
    return (
        <>
            <div className='switches__header'>
                <div className='left-card'>
                    <SwitchesStatusCard
                        switchesCardType={ON_TYPE}
                        switchesCardCount={onStatusCount}
                    />
                </div>
                <div>
                    <SwitchesStatusCard
                        switchesCardType={OFF_TYPE}
                        switchesCardCount={offStatusCount}
                    />
                </div>
            </div>
        </>
    );
};

export default SwitchesStatusCardGroup;
