import React from "react";
import ConvoMapView from "../../Conversations/ConversationView/ConvoMapView/ConvoMapView";
import BCMapViewHeader from "./BCMapViewHeader/BCMapViewHeader";

const BranchCollectionMapView = ({
    headerTitle,
    mainBranchId,
    refreshConvoMap,
    updateConvoMapBranches,
}) => {
    return (
        <div className='mb-5'>
            {headerTitle && <BCMapViewHeader title={headerTitle} />}
            <ConvoMapView
                mainBranchId={mainBranchId}
                refreshConvoMap={refreshConvoMap}
                updateConvoMapBranches={updateConvoMapBranches}
            />
        </div>
    );
};

export default BranchCollectionMapView;
