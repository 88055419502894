import { Table } from "components/ui";
import Variant from "../Variant/Variant";
import AddDiscoveryButton from "./AddDiscoveryButton/AddDiscoveryButton";
import DiscoveryMetadata from "./DiscoveryMetadata/DiscoveryMetadata";
import DiscoveryDropdown from "./DiscoveryDropdown/DiscoveryDropdown";
import "./DiscoveryTable.scss";

const DiscoveryTable = ({
    tableIsLoading,
    issues,
    handleSuccess,
    setSelectedIssue,
    setShowSideModal,
    errorMssg,
}) => {
    const headers = ["Issues", "metadata", "Actions"];

    const dataSource = issues?.map((issue) => ({
        Issues: (
            <div>
                <span>{issue.issueName}</span>
            </div>
        ),
        metadata: <DiscoveryMetadata metadata={issue?.metadata} />,
        Actions: (
            <div className='discovery--actions'>
                <AddDiscoveryButton
                    metadata={issue?.metadata}
                    onClick={() => {
                        setSelectedIssue(issue);
                        setShowSideModal(true);
                    }}
                />
                {issue?.metadata?.length > 0 && (
                    <DiscoveryDropdown
                        handleActionModal={() => {
                            setSelectedIssue(issue);
                            setShowSideModal(true);
                        }}
                    />
                )}
            </div>
        ),
        dropdownElement: (
            <Variant selectedIssue={issue} handleSuccess={handleSuccess} />
        ),
    }));

    return (
        <>
            <Table
                headers={headers}
                dataSource={dataSource}
                isAccordion={true}
                isLoading={tableIsLoading}
                hasErrors={Boolean(errorMssg)}
                tableError={errorMssg}
            />
        </>
    );
};

export default DiscoveryTable;
