import React, { forwardRef } from "react";

export const Textarea = forwardRef(
    (
        {
            name,
            id,
            label,
            labelClass,
            hideLabel,
            grpClass,
            inputClass,
            type,
            placeholder,
            isErr,
            isValid,
            errMssg,
            extraMssg,
            isLoading,
            value,
            maxLength,
            onChange,
            disabled,
            rows = 3,
            ...rest
        },
        ref
    ) => {
        return (
            <div className={`form-group ${grpClass || ""}`}>
                {!hideLabel && (
                    <label
                        htmlFor={id}
                        className={`form-label ${labelClass || ""} ${
                            disabled ? "text-muted" : ""
                        }`}>
                        {label}
                    </label>
                )}
                <textarea
                    ref={ref}
                    type={type}
                    name={name}
                    id={id}
                    rows={rows}
                    className={`form-control ${
                        isLoading
                            ? "is-loading"
                            : isErr
                            ? "is-invalid"
                            : isValid
                            ? "is-valid"
                            : ""
                    } ${inputClass || ""}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength}
                    disabled={disabled}
                    {...rest}
                />
                {extraMssg ? (
                    <div className='text-muted extra-input-mssg'>
                        {extraMssg}
                    </div>
                ) : null}
                <div className='invalid-feedback'>{errMssg}</div>
            </div>
        );
    }
);
