import * as types from "./types";

const initialState = {
    membersCache: {
        pages: {},
        totalRecords: 0
    },
    rolesCache: {
        pages: {},
        totalRecords: 0,
        roleModules: []
    },
    startupProfile: {},
};

const AdminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_TEAM_MEMBERS:
            return {
                ...state,
                membersCache: {
                    ...state.membersCache,
                    pages: {
                        ...state.membersCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                },
            };
        case types.CLEAR_TEAM_MEMBERS:
            return {
                ...state,
                membersCache: {
                    ...state.membersCache,
                    pages: {},
                    totalRecords: 0
                },
            };

        case types.SAVE_ROLES_PERMISSIONS:
            return {
                ...state,
                rolesCache: {
                    ...state.rolesCache,
                    pages: {
                        ...state.rolesCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                },
            };
        case types.CLEAR_ROLES_PERMISSIONS:
            return {
                ...state,
                rolesCache: {
                    ...state.rolesCache,
                    pages: {}, totalRecords: 0
                },
            };
        case types.SAVE_ROLE_MODULES:
            return {
                ...state,
                rolesCache: {
                    ...state.rolesCache,
                    roleModules: payload
                },
            };

        case types.SAVE_STARTUP_PROFILE:
            return { ...state, startupProfile: payload };

        default:
            return state;
    }
};

export default AdminReducer;
