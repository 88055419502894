import { Tag } from "components/ui";
import "./VariantMetadata.scss";

const VariantMetadata = ({ metadata }) => {
    return (
        <>
            {metadata?.length ? (
                <div className='variant-metadata'>
                    {metadata?.map((meta) => (
                        <Tag name={meta} />
                    ))}
                </div>
            ) : (
                "No metadata has been added yet"
            )}
        </>
    );
};

export default VariantMetadata;
