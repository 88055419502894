import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import AnalyticsQAVAgentsCards from "./AnalyticsQAVAgentsCards/AnalyticsQAVAgentsCards";
import AnalyticsQAVAConvoSuggs from "./AnalyticsQAVAConvoSuggs/AnalyticsQAVAConvoSuggs";
import AnalyticsQAVAFilter from "./AnalyticsQAVAConvoSuggs/AnalyticsQAVAFilter/AnalyticsQAVAFilter";
import AnalyticsQAVATitle from "./AnalyticsQAVATitle/AnalyticsQAVATitle";
import { apiRoute } from "services";
import API from "services/lib/api";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Empty, Loading } from "components/ui";
import { useDispatch, useSelector } from "react-redux";

import { setVetAgentFilterParams } from "store/filters/actions";
import { setAgentsList } from "store/cache/agents/actions";
import { ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION } from "./enums";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatus;

const AnalyticsQAVAgentsSuggestions = ({ agentId }) => {
    const { agents } = useSelector((state) => state?.agents);

    const getAgentName = (agentId) => {
        if (!agentId) return "N/A";
        if (agents?.length === 0) return "N/A";
        const { firstName, lastName } =
            agents?.find(({ userId }) => userId === agentId) || {};
        return `${firstName} ${lastName}`;
    };

    const [status, setStatus] = useState(IDLE);
    const [conversations, setConversations] = useState([]);
    const [convoCounts, setConvoCounts] = useState({});
    const [selectedDuration, selectDuration] = useState("LAST_WEEK");
    const [useTableLoader, setUseTableLoader] = useState(false);

    const [errorMssg, setErrorMssg] = useState("");
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        currentPage: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const dispatch = useDispatch();

    const { filterParams } = useSelector(
        (state) => state?.filters?.vetAgentFilters
    );

    const handleChange = ({ value }) => {
        selectDuration(value);
        setUseTableLoader(true);
        dispatch(
            setVetAgentFilterParams({
                ...filterParams,
                duration: value,
            })
        );
    };

    const handleSearch = (search) => {
        setSearchIsLoading(true);
        setClearSearch(false);
        setUseTableLoader(true);
        dispatch(setVetAgentFilterParams({ currentPage: 1, search }));
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        setPagination({ ...pagination, currentPage });
    };

    const fetchAgents = async (httpRequest) => {
        try {
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                dispatch(
                    setAgentsList(
                        data?.map(
                            ({ firstName, lastName, userId, ...rest }) => {
                                return {
                                    value: userId,
                                    label: `${firstName} ${lastName}`,
                                    firstName,
                                    lastName,
                                    userId,
                                    ...rest,
                                };
                            }
                        )
                    )
                );
            }
        } catch (err) {
            console.log("ERR", err);
        }
    };

    const getAgentSuggestions = async () => {
        try {
            if (!useTableLoader) {
                setStatus(LOADING);
            }
            setErrorMssg("");
            const url = apiRoute?.getAgentSuggestions(
                agentId,
                selectedDuration
                    ? selectedDuration
                    : ANALYTICS_QAV_AGENTS_SUGGESTION_DURATION?.[1]?.value
            );
            const res = await API.get(url, {
                params: {
                    sortBy: "asc",
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data) {
                    const { conversationRecords, recordsCount } = data;
                    setConversations(conversationRecords);
                    setConvoCounts(recordsCount);
                    setStatus(DATAMODE);
                    setTotalRecords(recordsCount?.totalRecord);
                    setUseTableLoader(false);
                } else {
                    setStatus(NULLMODE);
                }

            }
        } catch (err) {
            console.log("err", err);
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
            setUseTableLoader(false);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        handleRetry={getAgentSuggestions}
                        errorMssg={errorMssg}
                    />
                );
            case DATAMODE:
                return (
                    <>
                        <AnalyticsQAVATitle
                            url='/analytics/qa/vet-tickets/'
                            title={`${getAgentName(agentId)}'s suggestions`}
                        />
                        <AnalyticsQAVAgentsCards convoCounts={convoCounts} />
                        <AnalyticsQAVAFilter
                            handleChange={handleChange}
                            handleSearch={handleSearch}
                            selectedDuration={selectedDuration}
                            filterParams={filterParams}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                        />
                        <AnalyticsQAVAConvoSuggs
                            conversations={conversations}
                            useTableLoader={useTableLoader}
                            filterParams={filterParams}
                            convoCounts={convoCounts}
                        />
                        <CustomPagination
                            {...pagination}
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                        />
                    </>
                );
            case NULLMODE:
                return <Empty />;
            default:
                return "";
        }
    };

    useEffect(() => {
        getAgentSuggestions();
        // eslint-disable-next-line
    }, [selectedDuration, pagination, filterParams]);

    useEffect(() => {
        fetchAgents();
        // eslint-disable-next-line
    }, []);

    return (
        <div className='padded__layout analytics-qa-vet-ticket'>
            {renderBasedOnStatus()}
        </div>
    );
};

export default AnalyticsQAVAgentsSuggestions;
