import React from "react";
import { Modal, SideModal } from "components/ui";
import TeamMemberActionForms from "./TeamMemberActionForms/TeamMemberActionForms";
import { TEAM_MEMBERS_ACTIONS } from "../../enums";

const TeamMemberSideModal = ({
    member,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const isSideModal =
        currentAction === TEAM_MEMBERS_ACTIONS.ADD_USER ||
        currentAction === TEAM_MEMBERS_ACTIONS.CHANGE_USER_ROLE;

    const render = () => {
        return (
            <TeamMemberActionForms
                member={member}
                currentAction={currentAction}
                closeModal={closeModal}
                handleSuccess={handleSuccess}
            />
        );
    };

    return (
        <>
            {isSideModal ? (
                <SideModal show={true} close={closeModal}>
                    {render()}
                </SideModal>
            ) : (
                <Modal show={true} close={closeModal}>
                    {render()}
                </Modal>
            )}
        </>
    );
};

export default TeamMemberSideModal;
