import Cookies from "js-cookie";

export const setAccessToken = async (token) => {
    const { accessToken, refreshToken, expiresIn } = token;
    await Cookies.set("accessToken", accessToken);
    await Cookies.set("refreshToken", refreshToken);
    //
    localStorage.setItem("tokenExpiry", expiresIn);
    localStorage.setItem("loginTime", new Date());
};

export const setWorkspaceToken = async (token, workspaceName) => {
    const { accessToken, refreshToken, expiresIn } = token;
    await Cookies.set(`${workspaceName}_accessToken`, accessToken);
    await Cookies.set(`${workspaceName}_refreshToken`, refreshToken);
    await Cookies.set(`${workspaceName}_expiresIn`, expiresIn);
};

export const retriveWorkspaceToken = (workspaceName) => {
    try {
        const accessToken = Cookies.get(`${workspaceName}_accessToken`);
        const refreshToken = Cookies.get(`${workspaceName}_refreshToken`);
        const expiresIn = Cookies.get(`${workspaceName}_expiresIn`);
        return { accessToken, refreshToken, expiresIn };
    } catch (err) {
        return {};
    }
};

export const deleteAccessToken = async () => {
    await Cookies.remove("accessToken");
    await Cookies.remove("refreshToken");
    //
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("loginTime");
};

export const retriveAccessToken = () => {
    try {
        const accessToken = Cookies.get("accessToken");

        return accessToken;
    } catch (err) {
        return undefined;
    }
};

export const retriveRefreshToken = () => {
    try {
        const refreshToken = Cookies.get("refreshToken");

        return refreshToken;
    } catch (err) {
        return undefined;
    }
};
