const AnalyticsQAVAgentsCard = ({ title, text }) => {
    return (
        <section>
            <h5>{title}</h5>
            <p>{text}</p>
        </section>
    );
};

export default AnalyticsQAVAgentsCard;
