import { Button } from "components/ui";
import "./ConfirmAction.scss";

export const ConfirmAction = ({
    close,
    title,
    handleYesClick,
    loading,
    loadingText,
    hideSubtitle,
    children,
}) => {
    return (
        <div className='confirm-action p-5'>
            <h1 className='text-center fw-medium'>{title}</h1>
            {!hideSubtitle && (
                <p className='text-center text-muted'>
                    You can’t undo this action.
                </p>
            )}
            {children}
            <Button
                type='button'
                text={`${loadingText ? loadingText : "Continue"}`}
                classType='danger'
                otherClass='mt-3 mb-2 w-100 text-white continue-button'
                onClick={handleYesClick}
                disabled={loadingText || loading}
                loading={loading}
            />
            <Button
                type='button'
                text='No, Cancel'
                classType='muted'
                otherClass='my-2 w-100 close-button'
                onClick={close}
                disabled={loading}
            />
        </div>
    );
};
