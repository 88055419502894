import * as types from "./types";

export const updateRolesList = (payload) => (dispatch) =>
    dispatch({ type: types.UPDATE_ROLES_LIST, payload });

export const setRolesList = (payload) => (dispatch) =>
    dispatch({ type: types.SET_ROLES_LIST, payload });

export const updateRole = (payload) => (dispatch) =>
    dispatch({ type: types.UPDATE_ROLE, payload });

export const deleteRole = (payload) => (dispatch) =>
    dispatch({ type: types.DELETE_ROLE, payload });
