import React from "react";
import { Button } from "components/ui";

const EditBranchButton = ({ onClick, otherClassName }) => {
    return (
        <Button
            text='Edit Branch'
            classType='plain'
            onClick={onClick}
            otherClass={otherClassName}
        />
    );
};

export default EditBranchButton;
