import { useState } from "react";
import { SignInSelectWorkspace } from "../SignIn/SignInSelectWorkspace/SignInSelectWorkspace";
import { GeneralLayout } from "components/ui";
import SignInEmailForm from "../SignIn/SignInForm/SignInEmailForm/SignInEmailForm";
import FadeIn from "components/ui/effects/FadeIn";
import { signInstages } from "../SignIn/SignInForm/SignInForm";
import ResetPassword from "../ResetPassword/ResetPassword";
import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import OTP from "../SignUp/OTP/OTP";
import "./ForgotPassword.scss";

export const fpInstance = "FORGOT_PASSWORD";

export const ForgotPassword = ({ location }) => {
    const {
        initial,
        secondary,
        tertiary = "ACCEPT_FP_OTP",
        final,
    } = signInstages;
    const [userWorkspaces, setUserWorkspaces] = useState([]);

    const [signInStage, setSignInStage] = useState(initial);
    const [sessionId, setSession] = useState();
    const [request, updateRequest] = useState({
        email: "",
        workspaceId: "",
    });

    const handleRequestUpdate = (name, value) => {
        updateRequest({ ...request, [name]: value });
    };

    const renderBasedOnStage = () => {
        switch (signInStage) {
            case initial:
                return (
                    <SignInEmailForm
                        suggestedEmail={location?.state?.email}
                        setSignInStage={setSignInStage}
                        setUserWorkspaces={setUserWorkspaces}
                        handleRequestUpdate={handleRequestUpdate}
                    />
                );
            case secondary:
                return (
                    <SignInSelectWorkspace
                        setSignInStage={setSignInStage}
                        handleRequestUpdate={handleRequestUpdate}
                        workspaces={userWorkspaces}
                        source={fpInstance}
                        fpRequest={request}
                        acceptSession={(sessionId) => {
                            setSession(sessionId);
                            setSignInStage(tertiary);
                        }}
                    />
                );

            case tertiary:
                return (
                    <OTP
                        source={fpInstance}
                        sessionId={sessionId}
                        setSignInStage={setSignInStage}
                    />
                );

            case final:
                return <ResetPassword sessionId={sessionId} />;

            default:
                return <SignInEmailForm setSignInStage={setSignInStage} />;
        }
    };

    return (
        <>
            <SEO title={"Forgot Password"} />
            <GeneralLayout>
                {signInStage === initial && (
                    <div className='forgotpassword'>
                        <h1>Forgot Password</h1>
                        <p>
                            Please enter your email address to reset your
                            password
                        </p>
                    </div>
                )}
                <div key={signInStage} className='signin'>
                    <FadeIn pkey={signInStage}>{renderBasedOnStage()}</FadeIn>
                </div>
            </GeneralLayout>
        </>
    );
};
