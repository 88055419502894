import * as types from "./types";

export const saveIssues = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_ISSUES, payload: { pageNumber, totalRecords, data } });
export const clearIssues = () => (dispatch) =>
    dispatch({ type: types.CLEAR_ISSUES });

export const saveDiscoveries = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_DISCOVERIES, payload: { pageNumber, totalRecords, data } });
export const clearDiscoveries = () => (dispatch) =>
    dispatch({ type: types.CLEAR_DISCOVERIES });

export const saveConversations = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_CONVERSATIONS, payload: { pageNumber, totalRecords, data } });
export const clearConversations = () => (dispatch) =>
    dispatch({ type: types.CLEAR_CONVERSATIONS });

export const saveQaDocumentations = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_QA_DOCUMENTATIONS, payload: { pageNumber, totalRecords, data } });
export const clearQaDocumentations = () => (dispatch) =>
    dispatch({ type: types.CLEAR_QA_DOCUMENTATIONS });