import { useState } from "react";
import { Button } from "components/ui";
import { signInstages } from "../SignInForm/SignInForm";
import { fpInstance } from "pages/PublicPages";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";

export const defaultImageUrl =
    "https://documents.dickson-constant.com/medias/images/catalogue/api/m654-grey-680.jpg";

export const SignInSelectWorkspace = ({
    setSignInStage,
    acceptSession,
    fpRequest,
    source,
    handleRequestUpdate,
    workspaces,
}) => {

    const defaultWorkspace = workspaces[0]?.workspaceId;
    const [selectedWorkspace, setWorkspace] = useState(defaultWorkspace);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [error, setError] = useState("");

    const createFPRequest = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            const res = await API.post(apiRoute.forgotPassword, {
                ...fpRequest,
                workspaceId: selectedWorkspace,
            });
            if (res.status === 201) {
                const { sessionId } = res.data.data;
                acceptSession(sessionId);
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedWorkspace) return setError("Select a workspace");

        setError("");
        handleRequestUpdate("workspaceId", selectedWorkspace);
        // this decision is influenced by the current instance, in which this component appear
        source === fpInstance
            ? createFPRequest()
            : setSignInStage(signInstages.final);
    };

  
    return (
        <div>
            <h1 className="sub__stage--heading">
                Choose what workspace you want to{" "}
                {source === fpInstance ? "reset password" : "enter"}
            </h1>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMsg)}
                    message={errorMsg}
                    hide={() => setErrorMsg()}
                />
                {workspaces?.map((workspace) => {
                    const { workspaceLogoUrl } = workspace;
                    return (
                        <div
                            className={`workspace--option ${
                                workspace?.workspaceId === selectedWorkspace
                                    ? "selected"
                                    : ""
                            }`}
                            key={workspace?.workspaceId}
                            onClick={() =>
                                setWorkspace(workspace?.workspaceId)
                            }>
                            <img
                                src={
                                    workspaceLogoUrl
                                        ? workspaceLogoUrl
                                        : defaultImageUrl
                                }
                                alt={workspace?.workspaceName}
                                className='workspace--option-logo'
                            />
                            <p>{workspace?.workspaceName}</p>
                        </div>
                    );
                })}
                <p className='input-err-message mt-1 mb-0'>{error}</p>
                <Button
                    type='submit'
                    loading={isLoading}
                    text='Proceed'
                    classType='primary'
                    otherClass='my-3 w-100'
                />
            </form>
        </div>
    );
};
