import React from "react";
import "./Range.scss";

export const Range = ({ onChange, name, value, min, max }) => {
    return (
        <input
            type='range'
            onChange={onChange}
            name={name}
            value={value}
            min={min}
            max={max}
            valueAsNumber={true}
        />
    );
};
