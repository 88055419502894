const NavToast = ({ title, body }) => {
    return (
        <div>
            <p>{title}</p>
            <p>{body}</p>
        </div>
    );
};

export default NavToast;
