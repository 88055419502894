import { DropdownItem } from "reactstrap";

const CapsuleDeleteButton = ({
    contentId,
    capsule,
    content,
    handleToggleDeleteModal,
    hasChildren,
}) => {
    return (
        <>
            <DropdownItem
                onClick={() =>
                    handleToggleDeleteModal(
                        capsule,
                        content,
                        contentId,
                        hasChildren
                    )
                }>
                Remove
            </DropdownItem>
        </>
    );
};

export default CapsuleDeleteButton;
