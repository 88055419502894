import * as types from "./types";

const initialState = {
    capsuleClasses: [],
    capsulesCount: {},
    activePrimaryClass: {},
    activeSecondaryClass: {},
};

const CapsuleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_CAPSULE_CLASSES:
            return { ...state, capsuleClasses: payload };

        case types.UPDATE_PRODUCT_CAPSULE_COUNT:
            const { countKey, countVal } = payload;
            return {
                ...state,
                capsulesCount: {
                    ...state.capsulesCount,
                    [countKey]: countVal,
                },
            };
        case types.SET_ACTIVE_PRIMARY_CLASS:
            return { ...state, activePrimaryClass: payload };
        case types.SET_ACTIVE_SECONDARY_CLASS:
            return { ...state, activeSecondaryClass: payload };
        default:
            return state;
    }
};

export default CapsuleReducer;
