import { authRedirectPath, authProfilePath } from "../SignInForm/SignInForm";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { READ } = baseResourceActions;

export const handleAuthRedirect = (permissions, passwordUpdatedRequired) => {
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    return passwordUpdatedRequired
        ? authProfilePath
        : permissionCodes?.includes(`${READ}_${AGENTS_INBOX_BASE_SETTING}`)
        ? authRedirectPath
        : authProfilePath;
};
