import React from "react";
import { ReactSVG } from "react-svg";
import { formElementBlocks } from "components/dashboard/KnowledgeBase/FormSetup/BuilderElementsList/data";
import imageLinks from "assets/images";
import "./CustomRuleElement.scss";

const {
    icons: { crossIconGrey },
} = imageLinks;

const CustomRuleElement = ({
    isActive,
    elementType,
    ruleCode,
    ruleConstraint,
    constraintData,
    setActiveRule,
    handleDelete,
    ruleIndex,
    hasError,
}) => {
    const elementRules = formElementBlocks?.[elementType]?.rules;
    return (
        <li
            className={`custom__rule__element ${isActive ? "active" : ""} ${
                hasError ? `error__border` : ``
            } `}
            onClick={() =>
                setActiveRule(
                    ruleCode,
                    ruleConstraint,
                    ruleIndex,
                    constraintData
                )
            }>
            <span>
                {elementRules?.find(({ value }) => value === ruleCode)?.label}
            </span>
            {isActive && (
                <ReactSVG
                    className='ms-1'
                    src={crossIconGrey}
                    onClick={() => handleDelete()}
                />
            )}
        </li>
    );
};

export default CustomRuleElement;
