import React from "react";
import { STATUS_CARD_TYPES } from "../../../enum";

const { ON_TYPE } = STATUS_CARD_TYPES;

const StatusCardColor = ({ cardType }) => {
    return (
        <>
            {cardType === ON_TYPE ? (
                <div className='dot bg-success me-1'></div>
            ) : (
                <div className='dot bg-danger me-1'></div>
            )}
        </>
    );
};

export default StatusCardColor;
