export const messageTypes = Object.freeze({
    DEFAULT: "DEFAULT",
    BRANCH: "BRANCH",
    CONVERSATION: "CONVERSATION",
    CANNED_RESPONSE: "CANNED_RESPONSE",
    CANNED_UPDATE: "CANNED_UPDATE",
    BRANCH_COLLECTION: "BRANCH_COLLECTION",
    FORM_REQUEST: "FORM_REQUEST",
    FORM_RESPONSE: "FORM_RESPONSE",
    BRANCH_SUB_SENTENCE: "BRANCH_SUB_SENTENCE",
    CANNED_SCENAIRO: "CANNED_SCENAIRO",
});

export const appMessageUserTypes = Object.freeze({
    WORKSPACE_AGENT: "WORKSPACE_AGENT",
    THIRD_USER: "THIRD_USER",
});

export const mediaType = Object.freeze({
    IMAGE: "IMAGE",
    FILE: "FILE",
    VIDEO: "VIDEO",
});

export const appMessageSources = Object.freeze({
    IN_APP: "IN_APP",
    EMAIL: "EMAIL",
    TWITTER: "TWITTER",
    FACEBOOK_MESSENGER: "FACEBOOK_MESSENGER",
    INSTAGRAM: "INSTAGRAM",
});

export const messageSources = Object.freeze({
    IN_APP: {
        source: "In-App",
    },
    EMAIL: {
        source: "Email",
    },
    TWITTER: {
        source: "Twitter",
    },
    FACEBOOK_MESSENGER: {
        source: "Facebook",
    },
    INSTAGRAM: {
        source: "Instagram",
    },
});
