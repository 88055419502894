import { ReactSVG } from "react-svg";
import asset from "assets/images";

const InviteTeamButton = ({
    showInviteTeamSearch,
    setShowInviteTeamSearch,
}) => {
    return (
        <span
            className='text-primary fw-bold cursor-pointer'
            onClick={() => setShowInviteTeamSearch(!showInviteTeamSearch)}>
            <ReactSVG src={asset?.icons?.addUser} className='d-inline-flex' />
            &nbsp; Invite Member
        </span>
    );
};

export default InviteTeamButton;
