import PreHeading from "components/ui/PreHeading/PreHeading";

const ViewCapsulesHeader = ({ capsuleClassName, capsuleType }) => {
    return (
        <PreHeading
            title={"some text"}
            url='/dependency/capsules/'
            text='View Capsules'
            subText={`${capsuleType} ${capsuleClassName}`}
        />
    );
};

export default ViewCapsulesHeader;
