import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading, Table } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useState, useEffect } from "react";
import { getErrorMessage } from "utils/helper";

import { ANALYTICS_TSAM_TABLE_HEADERS } from "../../enum";
import AnalyticsTSamConvoTDropdown from "./AnalyticsTSamConvoTDropdown/AnalyticsTSamConvoTDropdown";
import "./AnalyticsTSamConvoTable.scss";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AnalyticsTSamConvoTable = () => {
    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [convoAnalytics, setConvosAnalytics] = useState([]);
    const [filters, updatePageFilters] = useState({
        search: "",
        currentPage: 1,
        pageSize: 5,
    });

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        updatePageFilters({ ...filters, currentPage });
    };

    const getConvoAnalytics = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader ? setTableIsLoading(true) : setStatus(LOADING);

            const url = apiRoute?.getMostFrequentConvos;
            const res = await API.get(url, {
                params: { ...filters },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                setConvosAnalytics(data);
                setTotalRecords(metaData?.totalRecords);
                // setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            // setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getConvoAnalytics(useTableLoader, httpRequest);
        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filters, useTableLoader]);

    const tableData = convoAnalytics?.map(
        ({
            conversationId,
            issue,
            percentageFrequency,
            actualNumberCount,
            percentageSuccessRate,
            recentConversations,
        }) => ({
            convoName: issue?.issueName,
            freq: percentageFrequency,
            actualCount: actualNumberCount,
            successRate: percentageSuccessRate,
            dropdownElement: (
                <AnalyticsTSamConvoTDropdown
                    data={recentConversations}
                    issueName={issue?.issueName}
                    conversationId={conversationId}
                />
            ),
        })
    );

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <Empty
                        text={"No Recent Conversation Analytics available."}
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <Table
                            headers={ANALYTICS_TSAM_TABLE_HEADERS}
                            dataSource={tableData}
                            isAccordion={true}
                            isLoading={tableIsLoading}
                            hasErrors={Boolean(errorMssg)}
                            tableError={errorMssg}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filters}
                        />
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getConvoAnalytics}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='analytics-tsam-convo-table'>
            {renderBasedOnStatus()}
        </div>
    );
};

export default AnalyticsTSamConvoTable;
