import { useEffect, useState } from "react";
import CannedResponsesHeader from "./CannedResponsesHeader/CannedResponsesHeader";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Empty, Loading } from "components/ui";
import CannedResponsesTable from "./CannedResponsesTable/CannedResponsesTable";
import CannedResponsesModals from "./modal/CannedResponsesModals";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import {
    clearCannedResponses,
    saveCannedResponses,
} from "store/modules/knowledgeBase/actions";
import {
    setCannedResponsesBaseFilter,
    setCannedResponsesFilterOption,
    setCannedResponsesFilterParams,
} from "store/filters/actions";

const { IDLE, ERROR, NULLMODE, DATAMODE, LOADING } = dataQueryStatus;

const CannedResponses = () => {
    const [errorMssg, setErrorMssg] = useState();
    const [showModal, toggleModal] = useState(false);
    const [modalAction, setModalAction] = useState();
    const [cannedResponses, setCannedResponses] = useState([]);
    const [selectedCannedResponse, selectCannedResponse] = useState({});
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.cannedResponsesFilters
    );

    const {
        cannedResponsesCache: { pages, totalRecords },
    } = useSelector((state) => state?.knowledgeBase);

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const getCannedResponses = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            const url = apiRoute?.formCannedResponses;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                setTableIsLoading(false);
                if (data.length > 0) {
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }

                dispatch(
                    saveCannedResponses(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );

                setCannedResponses(data);
                setSearchIsLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setSearchIsLoading(false);
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleActionModal = (action, data) => {
        setModalAction(action);
        toggleModal(!showModal);
        selectCannedResponse(data);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCannedResponses}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Canned Responses"} />;

            case DATAMODE:
                return (
                    <>
                        <CannedResponsesTable
                            handleActionModal={handleActionModal}
                            cannedResponses={cannedResponses}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(
            setCannedResponsesFilterParams({ ...filterParams, currentPage })
        );
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setCannedResponsesBaseFilter(baseFilter));

        dispatch(setCannedResponsesFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setCannedResponsesFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setCannedResponsesFilterOption({ ...option, value }));
        dispatch(clearCannedResponses());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setCannedResponsesFilterParams({
                ...filterParams,
                alphabetical: null,
                dateCreated: value,
                lastModified: null,
            })
        );
        dispatch(setCannedResponsesFilterOption({ ...option, value }));
        dispatch(clearCannedResponses());
    };

    const handleSortByLastModified = (value, option) => {
        setUseTableLoader(false);
        dispatch(
            setCannedResponsesFilterParams({
                ...filterParams,
                alphabetical: null,
                dateCreated: null,
                lastModified: value,
            })
        );
        dispatch(setCannedResponsesFilterOption({ ...option, value }));
        dispatch(clearCannedResponses());
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setCannedResponsesFilterParams({ currentPage: 1, search }));
        dispatch(clearCannedResponses());
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setCannedResponses(cachedPage);
        } else {
            getCannedResponses(useTableLoader, httpRequest);
        }

        // eslint-disable-next-line
    }, [filterParams]);

    return (
        <>
            <div className='main__layout'>
                <CannedResponsesHeader
                    handleActionModal={handleActionModal}
                    handleSortByAlpha={handleSortByAlpha}
                    handleSortByDate={handleSortByDate}
                    handleSearch={handleSearch}
                    handleSortByLastModified={handleSortByLastModified}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    handleBasefilter={handleBasefilter}
                    searchDefaultValue={filterParams?.search}
                />
                {renderBasedOnStatus()}
            </div>

            {showModal && (
                <CannedResponsesModals
                    showModal={showModal}
                    modalAction={modalAction}
                    closeModal={() => toggleModal(false)}
                    selectedCannedResponse={selectedCannedResponse}
                    handleSuccess={() => {
                        getCannedResponses();
                    }}
                />
            )}
        </>
    );
};

export default CannedResponses;
