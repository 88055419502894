import { ReactSVG } from "react-svg";
import asset from "assets/images";

import { STATUS_METRICS } from "components/dashboard/TrainSAM/Dependency/enum";

const StatusMapMetric = ({ metricName, metricCount }) => {
    return (
        <div>
            <span className='fs-14 d-flex gap-2'>
                <div>
                    <ReactSVG
                        src={asset?.icons[metricName]}
                        className='anchor-right'
                    />
                </div>
                <span>
                    {metricName} {metricCount}
                </span>
            </span>
        </div>
    );
};

const StatusMapMetricsGroup = ({ statusMapMetrics }) => {
    /**
     *
     * Iteration was performed here because statusMapMetrics
     * come from the backend and we may have multiple data just like the one
     * from the dependency capsules
     *
     * */

    return (
        <>
            <div className='metric__group gap-2'>
                {Object.entries(statusMapMetrics).map((metric, key) => {
                    return (
                        <StatusMapMetric
                            key={key}
                            metricCount={metric[1]}
                            metricName={STATUS_METRICS[metric[0]]}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default StatusMapMetricsGroup;
