import React, { useState, useContext, useEffect, useRef } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { analyticsRequestKeys } from "../../AnalyticsTSam/enum";
import AnalyticsFilters from "../common/AnalyticsFilters/AnalyticsFilters";
import { useSelector, useDispatch } from "react-redux";
import {
    formatDuration,
    getErrorMessage,
    MonthDay,
    roundUp,
} from "utils/helper";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    arrangeSolutionTypeData,
    computeDataFreq,
    computerPercentage,
    getCurrentAnalyticsCustomDates,
    getDatesBasedOnDuration,
} from "../common/helpers";
import {
    setAnalyticsChartData,
    updateCurrentAnalyticsFilter,
} from "store/analytics/actions";
import AnalyticsBarChart from "../common/AnalyticsBarChart/AnalyticsBarChart";
import "./AnalyticsTeamEfficiency.scss";

import {
    teamAnalyticsFResTimeTooltipExtraMetrics,
    teamAnalyticsFirstRespTimeDefaultOptions,
    teamAnalyticsResolveTimeDefaultOptions,
    teamAnalyticsResolveTimeTooltipExtraMetrics,
    teamAnalyticsRespTimeDefaultOptions,
    teamAnalyticsRespTimeTooltipExtraMetrics,
} from "./enums";
import { teamAnalyticsRequestFilters } from "../common/enum";

const { ERROR } = dataQueryStatus;

const AnalyticsTeamEfficiency = () => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );

    const {
        currentAnalyticsFilter,
        currentAnalyticsCustomDate,
        analyticsEfficiency,
    } = useSelector((state) => state.analytics);

    const dispatch = useDispatch();

    const [analyticsData, setAnalyticsData] = useState([]);
    // const [analyticsResultGroup, setAnalyticsResultGroup] = useState({});
    const [analyticsResultsChart, setAnalyticsResultsChart] = useState({});
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    const [useAIAssistance, toggleAIAssistance] = useState(false);

    const [analyticsResultTimes, setAnalyticsResultTimes] = useState({
        avgRespTime: 0,
        totalRespTime: 0,
        avgResTime: 0,
        totalResTime: 0,
        avgFirstRespTime: 0,
        totalFirstRespTime: 0,
        aiAvgRespTime: 0,
        aiAvgFirstRespTime: 0,
        // combinedAverageFirstResponseTime: 0,
    });

    const analyticsResultTimeMetric = useRef({
        averageFirstResponseTime: "Sec",
        averageResolutionTime: "Sec",
        averageResponseTime: "Sec",
        aiAverageFirstResponseTime: "Sec",
        aiAverageResponseTime: "Sec",
    });

    const toastMessage = useContext(ToastContext);

    const {
        analyticsRequestType,
        analyticsRequestDuration,
        analyticsRequestValue,
    } = currentAnalyticsFilter || {};

    const updateRequest = (name, value) => {
        dispatch(
            updateCurrentAnalyticsFilter({
                [name]: value,
            })
        );
    };

    const hasTicketInfo =
        analyticsRequestType ===
        teamAnalyticsRequestFilters.TICKET_CLASSIFICATION;

    const handleSetOptions = async (options) => {
        await setOptions(options);
        // await setSelectedOptions(options);

        if (analyticsEfficiency?.[analyticsRequestType]) {
            let persistedAnalyticsData =
                analyticsEfficiency?.[analyticsRequestType]?.[
                    analyticsRequestDuration
                ];
            if (persistedAnalyticsData) {
                setChartLoading(false);
                setAnalyticsData(persistedAnalyticsData);
            } else {
                setChartLoading(true);
            }
        } else {
            setChartLoading(true);
        }

        await getAnalyticsData("");
    };

    const compileChartData = async (data, keyName, valName, aiValName) => {
        if (data.length > 0) {
            const dateSeries = getDatesBasedOnDuration(
                analyticsRequestDuration,
                data
            );

            const mainArrayData = [];

            // const OptionValuesArray = [
            //     ...new Set(
            //         data.map((o) => o[keyName]).filter((el) => el != null)
            //     ),
            // ];
            // const newValsArray =
            //     analyticsRequestValue === ""
            //         ? analyticsRequestValue.split(",")
            //         : OptionValuesArray;

            // const optionValues = (
            //     analyticsRequestType ? options : defaultOptions
            // ).map(({ value }) => {
            //     return value;
            // });

            const optionValues = options.map(({ value }) => {
                return value;
            });

            const newValsArray =
                analyticsRequestValue?.length > 0
                    ? analyticsRequestValue?.split(",")
                    : optionValues;

            // const strokeOptions = options.filter(({ value }) =>
            //     newValsArray.includes(value)
            // );

            // setSelectedOptions(strokeOptions);

            let { useMinuteAsTime, totalCounts } = computeDataFreq(
                analyticsData,
                keyName,
                valName
            );

            let { useMinuteAsTime: aiUseMinuteAsTime } = computeDataFreq(
                analyticsData,
                keyName,
                aiValName
            );

            analyticsResultTimeMetric.current[valName] = useMinuteAsTime
                ? "Min"
                : "Sec";

            analyticsResultTimeMetric.current[aiValName] = aiUseMinuteAsTime
                ? "Min"
                : "Sec";

            dateSeries?.forEach(async (dt) => {
                //assignedTeamId

                let valInstances = data.filter((ad) => {
                    return (
                        new Date(ad.dateValue).toDateString() ===
                        new Date(dt).toDateString()
                    );
                });

                let originalObj = Object.assign({}, [
                    ...newValsArray.map((y) => {
                        return { [y]: 0 };
                    }),
                ]);

                let result = {};

                if (keyName === "ticketSolutionType") {
                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((valData) => {
                            return {
                                ...arrangeSolutionTypeData(valName, valData),
                                ...arrangeSolutionTypeData(aiValName, valData),
                            };
                        })
                    );
                } else {
                    // the valInstances creates a structure like { a:1, b:2}, so using it with the originaObj on object assign, it will not discard the valInstance properties that  are not found in originObj, thus the data is tampered
                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((o) => {
                            return o[keyName] !== null
                                ? {
                                      [o[keyName]]: o[valName]
                                          ? useMinuteAsTime
                                              ? o[valName] / 60
                                              : o[valName]
                                          : 0,

                                      [`${o[keyName]}_AI`]: o[aiValName]
                                          ? aiUseMinuteAsTime
                                              ? o[aiValName] / 60
                                              : o[aiValName]
                                          : 0,

                                      [`${o[keyName]}_FREQ`]:
                                          computerPercentage(
                                              o["totalCount"] || 0,
                                              totalCounts
                                          ),

                                      [`${o[keyName]}_AI_FREQ`]:
                                          computerPercentage(
                                              o["totalCount"] || 0,
                                              totalCounts
                                          ),

                                      totalCount: o["totalCount"],

                                      originalData: o,
                                  }
                                : {};
                        })
                    );
                }
                mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
            });
            return mainArrayData;
        }

        // setAnalyticsData(dateSeries)
    };

    const { startDate, endDate } =
        getCurrentAnalyticsCustomDates(
            currentAnalyticsFilter,
            currentAnalyticsCustomDate
        ) || {};

    const getAnalyticsData = async (preRequestVal) => {
        try {
            if (startDate === "" || endDate === "") {
                return;
            }
            setIsLoading(true);
            const commaAnalyticsValue = "";

            const url = apiRoute?.getTicketsResponseTimeAnalytics;
            // const res = await Axios.get(`http://localhost:7073/${url}`, {
            const res = await API.get(url, {
                headers: {
                    workspaceid: workspaceId,
                },
                params: {
                    ...currentAnalyticsFilter,
                    analyticsRequestValue: commaAnalyticsValue,
                    startDate,
                    endDate,
                    analyticsRequestType: analyticsRequestType || undefined,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setAnalyticsData(data);
                setIsLoading(false);
                setChartLoading(false);
                dispatch(
                    setAnalyticsChartData({
                        analyticsData: data,
                        analyticsRequestDuration,
                        analyticsRequestType,
                        analyticsChartTitle: "analyticsEfficiency",
                    })
                );
            }
        } catch (err) {
            setIsLoading(false);
            setChartLoading(false);
            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            let errorMssg = getErrorMessage(err);
            toastMessage(errorMssg, ERROR);
        }
    };

    const compileAnalyticsGroupData = async () => {
        const totalDataCount = analyticsData?.length;
        // const totalTicketsCount = analyticsData.reduce(
        //     (total, obj) => obj.totalTicketsPerDay + total,
        //     0
        // );
        // const totalBackLogCount = analyticsData.reduce(
        //     (total, obj) => obj.totalBackLoggedTicketsPerDay + total,
        //     0
        // );

        const totalAvgRespTime = analyticsData.reduce(
            (total, obj) => Number(obj.averageResponseTime) + total,
            0
        );

        const totalAiAvgRespTime = analyticsData.reduce(
            (total, obj) => Number(obj.aiAverageResponseTime) + total,
            0
        );

        const totalAvgResolTime = analyticsData.reduce(
            (total, obj) => Number(obj.averageResolutionTime) + total,
            0
        );

        const totalAvgFirstRespTime = analyticsData.reduce(
            (total, obj) => Number(obj.averageFirstResponseTime) + total,
            0
        );

        const totalAiAvgFirstRespTime = analyticsData.reduce(
            (total, obj) => Number(obj.aiAverageFirstResponseTime) + total,
            0
        );

        // const avgBacklogPercent = (totalBackLogCount / totalTicketsCount) * 100;

        // const weeklyBacklogPercent = weeklyBasedComputation(
        //     analyticsRequestDuration,
        //     avgBacklogPercent
        // );

        const lookoutKey = analyticsRequestKeys[analyticsRequestType];
        const ticketResponseTimes = await compileChartData(
            analyticsData,
            lookoutKey,
            "averageResponseTime",
            "aiAverageResponseTime"
        );
        const ticketResolutionTimes = await compileChartData(
            analyticsData,
            lookoutKey,
            "averageResolutionTime"
        );

        const ticketsFirstResponseTimes = await compileChartData(
            analyticsData,
            lookoutKey,
            "averageFirstResponseTime",
            "aiAverageFirstResponseTime"
        );

        setAnalyticsResultsChart({
            ticketResponseTimes,
            ticketResolutionTimes,
            ticketsFirstResponseTimes,
        });
        // setAnalyticsResultGroup({
        //     avgBacklogPercent: `${roundUp(avgBacklogPercent)}%`,
        //     weeklyBacklogPercent: `${roundUp(weeklyBacklogPercent)}%`,
        // });
        setAnalyticsResultTimes({
            avgRespTime: `${formatDuration(
                roundUp(totalAvgRespTime / totalDataCount)
            )}`,
            aiAvgRespTime: `${formatDuration(
                roundUp(totalAiAvgRespTime / totalDataCount)
            )}`,
            totalRespTime: `${formatDuration(totalAvgRespTime)}`,
            avgResTime: `${formatDuration(
                roundUp(totalAvgResolTime / totalDataCount)
            )}`,
            totalResTime: `${formatDuration(totalAvgResolTime)}`,
            avgFirstRespTime: `${formatDuration(
                roundUp(totalAvgFirstRespTime / totalDataCount)
            )}`,
            aiAvgFirstRespTime: `${formatDuration(
                roundUp(totalAiAvgFirstRespTime / totalDataCount)
            )}`,
            totalFirstRespTime: `${formatDuration(totalAvgFirstRespTime)}`,
        });
    };

    useEffect(() => {
        if (analyticsData.length > 0) {
            compileAnalyticsGroupData();
        }
        // eslint-disable-next-line
    }, [analyticsData]);

    const {
        avgResTime,
        avgRespTime,
        avgFirstRespTime,
        aiAvgFirstRespTime,
        aiAvgRespTime,
        // totalRespTime,
        // totalResTime,
        // totalFirstRespTime,
    } = analyticsResultTimes;

    useEffect(() => {
        getAnalyticsData();
        // eslint-disable-next-line
    }, []);
    
    return (
        <>
            <div className='padded__layout analytics-team-efficiency'>
                <AnalyticsFilters
                    defaultRequest={currentAnalyticsFilter}
                    currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                    updateRequest={updateRequest}
                    handleSubmit={getAnalyticsData}
                    isLoading={isLoading}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    {...{
                        options,
                        setOptions: handleSetOptions,
                    }}
                    showAIButton={true}
                    toggleAIAssistance={(state) =>
                        toggleAIAssistance(!useAIAssistance)
                    }
                />
                <div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='Response Time'
                            chartData={
                                analyticsResultsChart?.ticketResponseTimes || []
                            }
                            isLoading={chartLoading}
                            strokeData={
                                analyticsRequestType
                                    ? selectedOptions
                                    : teamAnalyticsRespTimeDefaultOptions
                            }
                            hasTicketInfo={hasTicketInfo}
                            allStrokeData={options}
                            xDataKey1={"dateValue"}
                            yDataKey2={"totalCount"}
                            yAxisLabel1={"Range"}
                            xAxisLabel1={`Time (${
                                useAIAssistance
                                    ? analyticsResultTimeMetric?.current
                                          ?.aiAverageResponseTime
                                    : analyticsResultTimeMetric?.current
                                          ?.averageResponseTime
                            })`}
                            yAxisLabel2={"Total Record"}
                            xAxisLabel2={"Freq"}
                            xDomain2={[0, 100]}
                            useAIAssistance={useAIAssistance}
                            tooltipExtraMetrics={
                                teamAnalyticsRespTimeTooltipExtraMetrics
                            }
                            useDefaultOptions={
                                analyticsRequestType?.length === 0
                            }
                            isOverlapped
                            hasFreqKey
                            overviews={[
                                {
                                    title: "Average",
                                    text: `${
                                        useAIAssistance
                                            ? aiAvgRespTime
                                            : avgRespTime
                                    }`,
                                },
                            ]}
                            timeMetric={
                                useAIAssistance
                                    ? analyticsResultTimeMetric?.current
                                          ?.aiAverageResponseTime
                                    : analyticsResultTimeMetric?.current
                                          ?.averageResponseTime
                            }
                        />
                    </div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='Resolution Time'
                            chartData={
                                analyticsResultsChart?.ticketResolutionTimes ||
                                []
                            }
                            isLoading={chartLoading}
                            strokeData={
                                analyticsRequestType
                                    ? selectedOptions
                                    : teamAnalyticsResolveTimeDefaultOptions
                            }
                            allStrokeData={options}
                            overviews={[
                                {
                                    title: "Average",
                                    text: `${avgResTime}`,
                                },
                            ]}
                            xDataKey1={"dateValue"}
                            yDataKey2={"totalCount"}
                            yAxisLabel1={"Range"}
                            xAxisLabel1={`Time (${analyticsResultTimeMetric?.current?.averageResolutionTime})`}
                            yAxisLabel2={"Total Record"}
                            xAxisLabel2={"Freq"}
                            xDomain2={[0, 100]}
                            tooltipExtraMetrics={
                                teamAnalyticsResolveTimeTooltipExtraMetrics
                            }
                            // useAIAssistance={useAIAssistance}
                            hasTicketInfo={hasTicketInfo}
                            useDefaultOptions={
                                analyticsRequestType?.length === 0
                            }
                            isOverlapped
                            hasFreqKey
                            timeMetric={
                                analyticsResultTimeMetric?.current
                                    ?.averageResolutionTime
                            }
                            analyticsData={analyticsData}
                        />
                    </div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='First Contact Time'
                            chartData={
                                analyticsResultsChart?.ticketsFirstResponseTimes ||
                                []
                            }
                            xDataKey1={"dateValue"}
                            yDataKey2={"totalCount"}
                            yAxisLabel1={"Range"}
                            xAxisLabel1={`Time (${
                                useAIAssistance
                                    ? analyticsResultTimeMetric?.current
                                          ?.aiAverageFirstResponseTime
                                    : analyticsResultTimeMetric?.current
                                          ?.averageFirstResponseTime
                            })`}
                            yAxisLabel2={"Total Record"}
                            xAxisLabel2={"Freq"}
                            hasFreqKey
                            isLoading={chartLoading}
                            strokeData={
                                analyticsRequestType
                                    ? selectedOptions
                                    : teamAnalyticsFirstRespTimeDefaultOptions
                            }
                            allStrokeData={options}
                            hasTicketInfo={hasTicketInfo}
                            xDomain2={[0, 100]}
                            tooltipExtraMetrics={
                                teamAnalyticsFResTimeTooltipExtraMetrics
                            }
                            useAIAssistance={useAIAssistance}
                            useDefaultOptions={
                                analyticsRequestType?.length === 0
                            }
                            overviews={[
                                {
                                    title: "Average",
                                    text: `${
                                        useAIAssistance
                                            ? aiAvgFirstRespTime
                                            : avgFirstRespTime
                                    }`,
                                },
                            ]}
                            timeMetric={
                                useAIAssistance
                                    ? analyticsResultTimeMetric?.current
                                          ?.aiAverageFirstResponseTime
                                    : analyticsResultTimeMetric?.current
                                          ?.averageFirstResponseTime
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsTeamEfficiency;
