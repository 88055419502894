import TabbedWrapperHeader from "routes/TabbedWrapperRouter/TabbedWrapperHeader/TabbedWrapperHeader";
import AgentAGuides from "../../AgentAGuides";
import Scenarios from "../../../Scenarios/Scenarios";
import EmailUpdates from "components/dashboard/TrainSAM/Updates/EmailUpdates/EmailUpdates";

const AgentAssistanceTabs = () => {
    const tabbledRoutes = [
        {
            title: "Guides",
            path: "/t-sam/agent-assistance",
            component: AgentAGuides,
        },
        {
            title: "Scenarios",
            path: "/t-sam/scenarios",
            component: Scenarios,
        },
        {
            title: "Updates",
            path: "/t-sam/updates/email",
            component: EmailUpdates,
        },
        {
            title: "Responses",
            path: "/t-sam/responses/emails",
            component: AgentAGuides,
        },
    ];

    return <TabbedWrapperHeader tabbedRoutes={tabbledRoutes} />;
};

export default AgentAssistanceTabs;
