import { useGoogleLogin } from "@react-oauth/google";

const GoogleButton = ({ scopes, onLogin, children }) => {
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => onLogin(codeResponse),
        flow: "auth-code",
        scope: scopes,
    });
    return (
        <>
            <div onClick={() => login()}>{children}</div>
        </>
    );
};

export default GoogleButton;
