import React, { useState } from "react";
import { Tag } from "components/ui";
import "./MetadataTags.scss";

const MetadataTags = ({ metadata }) => {
    const [viewMore, toggleViewMore] = useState(false);
    const metaTags = !viewMore ? metadata.slice(0, 7) : metadata;

    return (
        <div className='metadata__tags'>
            {metaTags.map((data, i) => (
                <div className='metadata__tags--tag' key={i}>
                    <span className=''>
                        <Tag name={data} key={i} />
                    </span>
                </div>
            ))}
            {metadata?.length > 7 && (
                <Tag
                    name={viewMore ? "View less" : "View more"}
                    onClick={() => toggleViewMore(!viewMore)}
                    className='view__more'
                />
            )}
        </div>
    );
};

export default MetadataTags;
