import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import DowntimeBranchesView from "../DowntimeBranchesView/DowntimeBranchesView";
import DCBranchViewHeading from "./DCBranchViewHeading/DCBranchViewHeading";

const DowntimeCapsuleBranchView = ({ location }) => {
    const [currentCapsule, setCurrentCapsule] = useState({});
    const history = useHistory();

    useEffect(() => {
        const locationCapsule = location?.state?.downtimeCapsule;

        if (locationCapsule) {
            setCurrentCapsule(locationCapsule);
        } else {
            history.push("/dependency/downtime-branches");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DCBranchViewHeading currentCapsule={currentCapsule} />
            <DowntimeBranchesView />
        </>
    );
};

export default DowntimeCapsuleBranchView;
