import { useState } from "react";
import BranchCannedElement from "./BranchCannedElement/BranchCannedElement";
import "./BranchCannedMessages.scss";
import { BRANCH_CANNED_TYPES } from "./enum";
import AddCannedMessageModal from "./modal/AddCannedMessageModal/AddCannedMessageModal";
import { merge2ObjectArraysUniquely } from "utils/helper";
import DetachCannedMessage from "./modal/DetachCannedMessage/DetachCannedMessage";

const { CANNED_EMAIL, MENTIONS, CANNED_RESPONSE } = BRANCH_CANNED_TYPES;

// const { ADD, EDIT } = BRANCH_CANNED_ACTIONS;

const BranchCannedMessages = ({
    handleSuccess,
    cannedResources,
    guideFlowId,
    agentUpdates,
}) => {
    const [showModal, toggleModal] = useState(false);

    const [modalAction, setModalAction] = useState("");
    const [detachModal, showDetachModal] = useState(false);

    const [cannedModalType, setCannedModalType] = useState();
    const [selectedCannedResourceId, selectCannedResourceId] = useState();
    const [isAddCannedUpdate, setIsAddCannedUpdate] = useState(false);
    const [selectedAgentUpdateId, selectAgentUpdateId] = useState();

    const cannedMessages = [
        {
            title: "Canned email",
            value: "ssss",
            borderColor: "#fc5a5a",
            cannedResourceType: CANNED_EMAIL,
        },
        {
            title: "Canned replies",
            value: "",
            borderColor: "#0066F5",
            cannedResourceType: CANNED_RESPONSE,
        },
        {
            title: "Canned mentions",
            value: "",
            borderColor: "#25BB87",
            cannedResourceType: MENTIONS,
        },
    ];

    const handleActionModal = (
        type,
        action,
        cannedResourceId,
        isUpdate,
        agentUpdate
    ) => {
        toggleModal(true);
        setModalAction(action);
        setCannedModalType(type);
        selectCannedResourceId(cannedResourceId);
        setIsAddCannedUpdate(isUpdate);
        selectAgentUpdateId(agentUpdate?.agentUpdateId);
    };

    const handleClose = () => {
        toggleModal(false);
        setModalAction();
        setCannedModalType();
    };

    const mergedCannedResources = merge2ObjectArraysUniquely(
        cannedResources || [],
        cannedMessages,
        "cannedResourceType"
    );

    const handleDetachModal = (cannedResourceId) => {
        showDetachModal(true);
        selectCannedResourceId(cannedResourceId);
    };

    return (
        <>
            <div className='branch__content branch-canned-messages'>
                <h6>Canned Messages</h6>
                <div className='branch-canned-messages__list'>
                    {mergedCannedResources?.map((data) => (
                        <BranchCannedElement
                            {...data}
                            handleActionModal={handleActionModal}
                            cannedMessages={cannedMessages}
                            agentUpdates={agentUpdates}
                            handleDetachModal={handleDetachModal}
                            handleSuccess={handleSuccess}
                        />
                    ))}
                </div>
            </div>

            {showModal && (
                <AddCannedMessageModal
                    {...{
                        showModal,
                        modalAction,
                        cannedModalType,
                        handleClose,
                        setCannedModalType,
                        handleSuccess,
                        guideFlowId,
                        selectedCannedResourceId,
                        isAddCannedUpdate,
                        selectedAgentUpdateId,
                    }}
                />
            )}

            {detachModal && (
                <DetachCannedMessage
                    {...{
                        handleSuccess,
                        selectedCannedResourceId,
                        guideFlowId
                    }}
                    closeModal={() => showDetachModal(false)}
                    showModal={detachModal}
                />
            )}
        </>
    );
};

export default BranchCannedMessages;
