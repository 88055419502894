import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./Controller.scss";

const Controller = ({ otherClassName, disabled }) => (
    <div
        className={`controller ${otherClassName ? otherClassName : ""} ${
            disabled ? "disabled" : ""
        }`}>
        <ReactSVG src={imageLinks?.icons?.control} />
    </div>
);

export default Controller;
