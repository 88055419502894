import { useEffect, useState } from "react";
import asset from "assets/images";
import BranchColumnItemOption from "./BranchColumnItemOption/BranchColumnItemOption";
import { ReactSVG } from "react-svg";
import { truncateString } from "utils/helper";
import "./BranchColumnItem.scss";

const BranchColumnItem = ({
    branchName,
    branchOptions,
    selectedBranchOptionId,
    isActive,
    columnNo,
    handleOptionSelect,
    branchId,
    isDisabled,
    branchMainSentence,
    selectLastToggledBranchId,
    isByPassed,
    isAgentGuideView
}) => {
    const [showOptions, toggleOptionVisibility] = useState(false);
    const [showMainSentence, toggleMainSentenceVisibility] = useState(false);

    useEffect(() => {
        if (!isActive) {
            toggleOptionVisibility(false);
            toggleMainSentenceVisibility(false);
        }
    }, [isActive, toggleOptionVisibility]);

    const handleToggleOption = () => {
        selectLastToggledBranchId?.(branchId);
        toggleOptionVisibility((prev) => !prev);
    };

    const handleToggleMainSentence = () => {
        selectLastToggledBranchId?.(branchId);
        toggleMainSentenceVisibility((prev) => !prev);
    };

    return (
        <div
            id={`${columnNo}_${branchId}`}
            className={`convo__branch ${
                isActive ? "convo__branch__active" : ""
            } ${isDisabled ? "is-disabled" : ""} ${
                isByPassed ? "is-bypassed" : ""
            }`}>
            <p className='convo__branch__name'>{branchName}</p>
            {branchMainSentence && (
                <div
                    className={`branch__options--toggler ${
                        showMainSentence ? "show" : "hide"
                    }`}
                    onClick={() =>
                        isDisabled ? null : handleToggleMainSentence()
                    }>
                    <ReactSVG src={asset?.icons?.simpleAnchor} />
                    <span>Main sentence</span>
                </div>
            )}
            {showMainSentence && (
                <p className='branch__main__sentence'>
                    {truncateString(branchMainSentence, 100)}
                </p>
            )}
            {branchOptions?.length > 0 && (
                <div
                    className={`branch__options--toggler ${
                        showOptions ? "show" : "hide"
                    }`}
                    onClick={() => (isDisabled ? null : handleToggleOption())}>
                    <ReactSVG src={asset?.icons?.simpleAnchor} />
                    <span>Options</span>
                </div>
            )}
            {showOptions && (
                <div className='branch__options__details'>
                    {branchOptions?.map((branchOption, index) => (
                        <BranchColumnItemOption
                            key={index}
                            isActive={
                                branchOption?.branchOptionId ===
                                selectedBranchOptionId
                            }
                            columnNo={columnNo}
                            handleOptionSelect={handleOptionSelect}
                            branchId={branchId}
                            isAgentGuideView={isAgentGuideView}
                            {...branchOption}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default BranchColumnItem;
