import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { SideModal } from "components/ui";
import EditTicketAATaskForm from "./EditTicketAATaskForm/EditTicketAATaskForm";

const EditTicketAATaskModal = ({
    showModal,
    closeModal,
    handleSuccess,
    selectedTicketTask,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading title='Edit Task' subTitle='' />
                    <EditTicketAATaskForm
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        selectedTicketTask={selectedTicketTask}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default EditTicketAATaskModal;
