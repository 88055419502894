import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./PersonalProfileInstruction.scss";

const PersonalProfileInstruction = () => {
    return (
        <>
            <div className='personal-profile-instruction'>
                <ReactSVG src={asset?.icons?.blueInfo} />

                <span>
                    You need to update your password from the temporary one to a
                    secret one personal and secure to you. Tap to update your password
                </span>
            </div>
        </>
    );
};

export default PersonalProfileInstruction;
