import React, { useState, useEffect } from "react";
import { getCapsulesEntries } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { FILTERS } from "./enum";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import FilterCapsuleSort from "./FilterCapsuleSort/FilterCapsuleSort";
import FilterAlphaSort from "./FilterAlphaSort/FilterAlphaSort";
import FilterDateSort from "./FilterDateSort/FilterDateSort";
import FilterSelectUI from "./FilterSelectUI/FilterSelectUI";
import FilterLastModifiedSort from "./FilterLastModifiedSort/FilterLastModifiedSort";
import "./Filter.scss";

const { ALPHABETICAL, DATE_CREATED, CLASS_TYPE, LAST_MODIFIED } = FILTERS;

const Filter = ({
    capsuleEntries,
    handleSelectRelatedClass,
    handleFilteredEntries,
    handleSortByAlpha,
    handleSortByDate,
    hideClassFilter,
    handleSortByLastModified,
    loadingRelatedClasses,
    relatedClasses,
    handleSearch,
    searchIsLoading,
    clearSearch,
    searchPlaceholder,
    baseFilter,
    filterOption,
    handleBasefilter,
    searchDefaultValue,
    hideAlphaFilter,
    hideLastModifiedFilter,
    hideDateCreatedFilter,
}) => {
    const [filterType, updateFilterType] = useState();
    const [selectedCapsuleClassEntries, setCapsuleClassEntries] = useState([]);
    const [selectedClassEntry, selectClassEntry] = useState();
    const [selectedRelatedClass, selectRelatedClass] = useState();
    const [loading, setLoading] = useState(false);

    const getCapsuleClassEntries = async (selectedCapsuleClass) => {
        try {
            setLoading(true);
            setCapsuleClassEntries([]);
            const { collectionKey } = selectedCapsuleClass;
            if (collectionKey) {
                const { capsuleEntries } = await getCapsulesEntries(
                    selectedCapsuleClass
                );

                setCapsuleClassEntries(capsuleEntries);

                setLoading(false);
            }
        } catch (err) {
            // setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSelectFilterType = (option) => {
        updateFilterType(option?.value);
        handleBasefilter?.(option);
    };

    const handleSelectClassType = (value) => {
        selectRelatedClass(value);
        selectClassEntry(null);
        handleSelectRelatedClass?.(value);
        getCapsuleClassEntries(value);
    };

    const filterCapsuleEntries = (selectedRelatedCapsule) => {
        const relatedCapsuleName = selectedRelatedClass?.collectionName;
        const relatedCapsuleKey = selectedRelatedClass?.collectionKey;

        let filteredCapsuleEntries = capsuleEntries?.filter(
            // eslint-disable-next-line
            ({ subCapsules, ...rest }) => {
                let filteredSubCapsules = subCapsules?.filter((subCapsule) => {
                    return (
                        subCapsule[relatedCapsuleName][relatedCapsuleKey] ===
                        selectedRelatedCapsule[relatedCapsuleKey]
                    );
                });
                if (filteredSubCapsules?.length > 0) {
                    return { subCapsules, ...rest };
                }
            }
        );

        return filteredCapsuleEntries;
    };

    const handleSelectCapsuleFilter = (selectedRelatedCapsule) => {
        selectClassEntry({
            label: selectedRelatedCapsule?.content,
            value: selectedRelatedCapsule,
        });
        handleFilteredEntries?.(filterCapsuleEntries(selectedRelatedCapsule));
    };

    useEffect(() => {
        if (baseFilter) {
            updateFilterType(baseFilter?.value);
        }
        // eslint-disable-next-line
    }, []);

    const renderBasedOnFilterType = () => {
        switch (filterType) {
            case CLASS_TYPE:
                return (
                    <FilterCapsuleSort
                        {...{
                            handleSelectClassType,
                            relatedClasses,
                            loadingRelatedClasses,
                        }}
                    />
                );
            case ALPHABETICAL:
                return (
                    <FilterAlphaSort
                        handleSortByAlpha={handleSortByAlpha}
                        filterOption={filterOption}
                    />
                );
            case LAST_MODIFIED:
                return (
                    <FilterLastModifiedSort
                        handleSortByLastModified={handleSortByLastModified}
                        filterOption={filterOption}
                    />
                );
            case DATE_CREATED:
                return (
                    <FilterDateSort
                        handleSortByDate={handleSortByDate}
                        filterOption={filterOption}
                    />
                );
            default:
                return "";
        }
    };

    const compileFilterList = () => {
        const filterOptions = [];
        if (!hideAlphaFilter) {
            filterOptions.push({
                label: "A-Z",
                value: ALPHABETICAL,
                icon: "radio",
            });
        }

        if (!hideLastModifiedFilter) {
            filterOptions.push({
                label: "Last Modified",
                value: LAST_MODIFIED,
                icon: "radio",
            });
        }

        if (!hideDateCreatedFilter) {
            filterOptions.push({
                label: "Date Created",
                value: DATE_CREATED,
                icon: "radio",
            });
        }

        if (!hideClassFilter) {
            filterOptions.push({
                label: "Class Type",
                value: CLASS_TYPE,
                icon: "radio",
            });
        }

        return filterOptions;
    };

    const filterOptions = compileFilterList();

    return (
        <>
            <div className='filter'>
                <div className='col-8 filter__select-group'>
                    <div className='filter__select'>
                        <SelectUI
                            name='filterByClass'
                            id='filterByClass'
                            placeholder='Filter by:'
                            options={filterOptions}
                            defaultValue={baseFilter}
                            onChange={(option) =>
                                handleSelectFilterType(option)
                            }
                            hasOptionIcon={true}
                        />
                    </div>

                    {renderBasedOnFilterType()}

                    {filterType === CLASS_TYPE && selectedRelatedClass && (
                        <FilterSelectUI
                            {...{
                                selectedClassEntry,
                                selectedCapsuleClassEntries,
                                selectedRelatedClass,
                                loading,
                                handleSelectCapsuleFilter,
                            }}
                        />
                    )}
                </div>
                <div className='col-4'>
                    <div className='filter__search'>
                        <PaginationSearch
                            handleSearch={handleSearch}
                            placeholder={searchPlaceholder}
                            isLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={searchDefaultValue}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;
