const AccordCardItemDetails = ({ logoUrl, appDescription, appName }) => {
    return (
        <>
            <div className='col-1'>
                <div className='logo_container'>
                    <img src={logoUrl} alt='logo' />
                </div>
            </div>
            <div className='col-9'>
                <div className='sub_text'>
                    <h6>{appName}</h6>
                    <p>{appDescription}</p>
                </div>
            </div>
        </>
    );
};

export default AccordCardItemDetails;
