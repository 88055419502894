import { Button } from "components/ui";
import asset from "assets/images";
import { STARTUP_PROFILE_ACTIONS } from "../../enums";
import ImageView from "components/essentials/ImageView/ImageView";

const StartupDetails = ({ workspace, setCurrentAction }) => {
    const { workspaceLogoUrl, workspaceName, address, industry } = workspace;

    return (
        <div className='startup-profile-details'>
            <div>
                <ImageView
                    src={workspaceLogoUrl || asset?.images?.placeholder}
                    alt={workspaceName}
                    className='startup-logo'
                />
                <div className='startup-detail'>
                    <h4>{workspaceName}</h4>
                    <small>{industry}</small>
                    <small>{address}</small>
                </div>
            </div>

            <Button
                text='Edit Startup Profile'
                type='button'
                classType='primary'
                otherClass='startup-profile__button'
                onClick={() =>
                    setCurrentAction(
                        STARTUP_PROFILE_ACTIONS.EDIT_STARTUP_PROFILE
                    )
                }
            />
        </div>
    );
};

export default StartupDetails;
