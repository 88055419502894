import React, { useEffect, useState } from "react";
import { Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import CapsuleContentBlocks from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductCapsuleView/CapsuleContentBlocks/CapsuleContentBlocks";
import CapsuleContentClasses from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductCapsuleView/CapsuleContentClasses/CapsuleContentClasses";
import { getSingleCapsuleEntry } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import "./TicketInfoCapsuleDetail.scss";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const TicketInfoCapsuleDetail = ({
    capsule,
    capsuleEntryId,
    hideRelateCapsuleInfo,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [capsuleElement, setCapsuleElement] = useState({});

    const getCapsuleEntryInfo = async () => {
        try {
            setStatus(LOADING);
            const elementObj = await getSingleCapsuleEntry(
                capsule,
                capsuleEntryId
            );
            setCapsuleElement(elementObj);
            setStatus(DATAMODE);
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getCapsuleEntryInfo();
        //eslint-disable-next-line
    }, [capsuleEntryId]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <div className='mini__capsule--related__classes'>
                        <CapsuleContentClasses
                            capsuleData={{
                                capsule,
                                contentId: capsuleEntryId,
                                productId: capsuleElement?.productid,
                            }}
                            viewCapsuleOnly={true}
                            hideAddButton={true}
                            hideEditAction={true}
                            hideDeleteAction={true}
                        />
                    </div>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleEntryInfo}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='mini__capsule--view'>
            <CapsuleContentBlocks
                capsuleData={{ capsule, contentId: capsuleEntryId }}
                hideHeader={true}
            />
            {!hideRelateCapsuleInfo && renderBasedOnStatus()}
        </div>
    );
};

export default TicketInfoCapsuleDetail;
