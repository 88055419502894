import React from "react";
import { Button } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { SAVE_BUTTON } from "../../../../enum";
import "./ScheduleTaskButton.scss";

const ScheduleTaskButton = ({ text, type, onClick, loading }) => {
    return (
        <>
            {type === SAVE_BUTTON ? (
                <Button
                    text={text}
                    classType='primary'
                    otherClass='save__task__btn'
                    onClick={onClick}
                    loading={loading}
                />
            ) : (
                <AddButton
                    hasIcon={true}
                    btnText={text}
                    otherClassName='add__task__btn'
                    onClick={onClick}
                />
            )}
        </>
    );
};

export default ScheduleTaskButton;
