import * as types from "./types";

export const pushIncomingTicket = (data) => (dispatch) =>
    dispatch({ type: types.PUSH_INCOMING_TICKET, payload: data });
export const setIncomingTickets = (data) => (dispatch) =>
    dispatch({ type: types.SET_INCOMING_TICKETS, payload: data });
export const deleteIncomingTicket = (data) => (dispatch) =>
    dispatch({ type: types.DELETE_INCOMING_TICKET, payload: data });
export const updateIncomingTicket = (data) => (dispatch) =>
    dispatch({ type: types.UPDATE_INCOMING_TICKET, payload: data });
export const setSortedTickets = (data) => (dispatch) =>
    dispatch({ type: types.SET_SORTED_TICKETS, payload: data });
