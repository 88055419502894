import React, { useState } from "react";
import AddCapsuleClassModal from "../AddCapsuleClassModal/AddCapsuleClassModal";
import AddButton from "components/ui/Button/AddButton/AddButton";

const AddCapsuleClassButton = ({ handleCapsulationSuccess }) => {
    const [showModal, toggleModal] = useState(false);

    return (
        <div>
            <AddButton
                btnText='Add New Class'
                onClick={() => toggleModal(true)}
            />
            {showModal && (
                <AddCapsuleClassModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleCapsulationSuccess={handleCapsulationSuccess}
                />
            )}
        </div>
    );
};

export default AddCapsuleClassButton;
