import { Button } from "components/ui";
import { VIEW_GD_UPDATES_ACTIONS } from "../enum";
import ViewGDUpdatesActions from "../ViewGDUpdatesActions/ViewGDUpdatesActions";

const ViewGDUpdateEditActions = ({ handleModalAction, className }) => {
    return (
        <div className={className}>
            <Button
                text={"Edit update"}
                classType={"plain"}
                otherClass={"edit_step_button"}
                onClick={() =>
                    handleModalAction(VIEW_GD_UPDATES_ACTIONS.EDIT_UPDATE)
                }
            />
            <ViewGDUpdatesActions handleModalAction={handleModalAction} />
        </div>
    );
};

export default ViewGDUpdateEditActions;
