import { ReactSVG } from "react-svg";
import asset from "assets/images";

import "./ScheduleDaySelect.scss";
import { useEffect, useState } from "react";
import { getDaysHrsMinsAndSecs } from "utils/helper";
import { Button } from "components/ui";

const ScheduleDaySelect = ({
    hasError,
    maxDays,
    handleChange,
    timeValue,
    handleSubmit,
    loading,
}) => {
    const [dropdown, toggleDropdown] = useState(false);
    const [usingDays, setUsingDays] = useState(false);

    const [noOfDays, updateNoOfDays] = useState();
    const [noOfHours, updateNoOfHours] = useState();
    const [noOfMins, updateNoOfMins] = useState();
    const [noOfSecs, updateNoOfSec] = useState();

    const [totalSeconds, updateTotalSeconds] = useState(0);

    const calTotalSeconds = (timeValue, timeType) => {
        if (timeType === "DD") {
            return timeValue * 60 * 60 * 24;
        } else if (timeType === "HH") {
            return timeValue * 60 * 60;
        } else if (timeType === "MM") {
            return timeValue * 60;
        } else {
            return Number(timeValue);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "DD":
                return updateNoOfDays(value);
            case "HH":
                return updateNoOfHours(value);
            case "MM":
                return updateNoOfMins(value);
            case "SS":
                return updateNoOfSec(value);
            default:
                return "";
        }
    };

    useEffect(() => {
        let totalNoOfSeconds =
            calTotalSeconds(noOfDays ? noOfDays : 0, "DD") +
            calTotalSeconds(noOfHours ? noOfHours : 0, "HH") +
            calTotalSeconds(noOfMins ? noOfMins : 0, "MM") +
            calTotalSeconds(noOfSecs ? noOfSecs : 0, "SS");

        updateTotalSeconds(totalNoOfSeconds);

        handleChange?.(
            totalNoOfSeconds,
            noOfDays,
            noOfHours,
            noOfMins,
            noOfSecs
        );
        // eslint-disable-next-line
    }, [noOfDays, noOfHours, noOfMins, noOfSecs]);

    const handleInitialValues = () => {
        if (timeValue > 0) {
            let { days, hours, mins, secs, total } =
                getDaysHrsMinsAndSecs(timeValue);
            updateNoOfDays(days);
            updateNoOfHours(hours);
            updateNoOfMins(mins);
            updateNoOfSec(secs);
            updateTotalSeconds(total);
        }
    };

    useEffect(() => {
        handleInitialValues();
        // eslint-disable-next-line
    }, [timeValue]);

    return (
        <>
            <div className='schedule-day-select'>
                <div
                    className={`schedule-day-select__label ${
                        !dropdown ? "schedule-day-select__default-label" : ""
                    } ${hasError ? "schedule-day-select__error" : ""}`}
                    onClick={() => toggleDropdown(!dropdown)}>
                    <span>
                        {totalSeconds ? (
                            <>
                                {" "}
                                {noOfDays > 0 &&
                                    `${noOfDays}${
                                        noOfDays > 1 ? "days" : "day"
                                    }`}{" "}
                                {noOfHours > 0 &&
                                    `${noOfHours}${
                                        noOfHours > 1 ? "hrs" : "hr"
                                    }`}{" "}
                                {noOfMins > 0 &&
                                    `${noOfMins}${
                                        noOfMins > 1 ? "mins" : "min"
                                    }`}{" "}
                                {noOfDays === 0 &&
                                    noOfHours === 0 &&
                                    noOfSecs > 0 &&
                                    `${noOfSecs}${
                                        noOfSecs > 1 ? "secs" : "sec"
                                    }`}
                            </>
                        ) : (
                            "Select date range"
                        )}
                    </span>
                    <ReactSVG src={asset?.icons?.calenderBlank} />
                </div>
                {dropdown && (
                    <div className='schedule-day-select__dropdown'>
                        <p>How Long?</p>
                        <div className='schedule-day__select'>
                            <div className='schedule-day__select--value'>
                                {usingDays ? (
                                    <>
                                        <input
                                            type='number'
                                            name='DD'
                                            min={0}
                                            max={maxDays}
                                            key='DD'
                                            placeholder={"DD"}
                                            defaultValue={noOfDays}
                                            onChange={handleInputChange}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type='number'
                                            name='HH'
                                            min={0}
                                            max={24}
                                            key='HH'
                                            maxLength={2}
                                            placeholder={"HH"}
                                            defaultValue={noOfHours}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type='number'
                                            name='MM'
                                            min={0}
                                            max={60}
                                            key='MM'
                                            maxLength={2}
                                            placeholder={"MM"}
                                            defaultValue={noOfMins}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type='number'
                                            name='SS'
                                            min={0}
                                            max={60}
                                            key='SS'
                                            maxLength={2}
                                            placeholder={"SS"}
                                            defaultValue={noOfSecs}
                                            onChange={handleInputChange}
                                        />
                                    </>
                                )}
                            </div>
                            <div
                                className='schedule-day__select--nav'
                                onClick={() => setUsingDays(!usingDays)}>
                                <ReactSVG
                                    src={asset?.icons?.tinyArrowLeft}
                                    className='schedule-day__icon'
                                />
                                <span>{usingDays ? "Days" : "Hrs"}</span>

                                <ReactSVG
                                    src={asset?.icons?.tinyArrowRight}
                                    className='schedule-day__icon'
                                />
                            </div>
                        </div>
                        <Button
                            text='Save'
                            disabled={loading}
                            loading={loading}
                            onClick={() => handleSubmit?.(totalSeconds)}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ScheduleDaySelect;
