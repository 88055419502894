import React, { useState } from "react";
import { ticketInfoTypes } from "components/dashboard/AgentInbox/enum";
import TicketInfoList from "./TicketInfoList/TicketInfoList";
import TicketInfoCapsuleDetail from "./TicketInfoCapsuleDetail/TicketInfoCapsuleDetail";
import TicketInfoFormDetails from "./TicketInfoFormDetails/TicketInfoFormDetails";
import "./TicketInfoDetailsElement.scss";

const { CAPSULE, FORM } = ticketInfoTypes;

const TicketInfoDetailsElement = ({
    ticketsInfos,
    selectedTicket,
    handleToggleTicketInfoModal,
    handleSuccess,
}) => {
    const { ticketId } = selectedTicket;
    const [selectedTicketInfo, setSelectedTicketInfo] = useState();
    const [selectedTicketInfoType, setSelectedTicketInfoType] = useState();
    const [selectedTicketInfoCapsule, setSelectedTicketInfoCapsule] =
        useState();

    const handleTicketInfoSelect = (
        ticketInfoValue,
        ticketInfoType,
        ticketInfoCapsule
    ) => {
        setSelectedTicketInfo(ticketInfoValue);
        setSelectedTicketInfoType(ticketInfoType);
        setSelectedTicketInfoCapsule(ticketInfoCapsule);
    };

    const renderBasedOnTicketType = () => {
        switch (selectedTicketInfoType) {
            case CAPSULE:
                return (
                    <TicketInfoCapsuleDetail
                        capsule={selectedTicketInfoCapsule}
                        capsuleEntryId={selectedTicketInfo}
                    />
                );
            case FORM:
                return (
                    <TicketInfoFormDetails
                        ticketId={ticketId}
                        formId={selectedTicketInfo}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <div className='ticketinfo-elements'>
            <TicketInfoList
                {...{
                    ticketsInfos,
                    selectedTicketInfo,
                    handleTicketInfoSelect,
                    handleToggleTicketInfoModal,
                    ticketId,
                    handleSuccess,
                }}
            />
            <div className='ticket-info__details'>
                {renderBasedOnTicketType()}
            </div>
        </div>
    );
};

export default TicketInfoDetailsElement;
