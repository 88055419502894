import React from "react";
import LiveChat from "./LiveChat/LiveChat";
import TicketsHeader from "./TicketsHeader/TicketsHeader";
import EmailPreview from "../../EmailPreview/EmailPreview";

const ChatModule = ({
    ticket,
    setRecentCustomerMessage,
    toggleTicketActionModal,
    setTicketData,
    reconnectUser,
    handleSuccess,
    cannedMessage,
    setCannedMessage,
    showFilters,
    setSelectedTicketHeader,
    emailAction,
    showEmailPreview,
    emailPreviewValue,
    toggleEmailPreview,
    ticketData,
    setInvestigativeCustomerMessage,
    setFetchMessageStatus,
    selectedCustomerMessage,
    selectCustomerMessage,
    attachments,
    toggleClipboardAndAttachment,
    handleToggleEmailAttachment
}) => {
    return (
        <div className={`chat__interface`}>
            {showEmailPreview ? (
                <>
                    <EmailPreview
                        toggleEmailPreview={toggleEmailPreview}
                        ticketId={ticket?.ticketId}
                        emailAction={emailAction}
                        emailPreviewValue={emailPreviewValue}
                        ticketData={ticketData}
                        setSelectedTicketHeader={setSelectedTicketHeader}
                        handleSuccess={handleSuccess}
                        attachments={attachments}
                        {...{  toggleClipboardAndAttachment,
                            handleToggleEmailAttachment}}
                    />
                </>
            ) : (
                <>
                    <TicketsHeader
                        selectedTicket={ticket}
                        toggleTicketActionModal={toggleTicketActionModal}
                        handleSuccess={handleSuccess}
                    />
                    <LiveChat
                        {...{
                            ticket,
                            setRecentCustomerMessage,
                            setTicketData,
                            reconnectUser,
                            cannedMessage,
                            setCannedMessage,
                            showFilters,
                            setInvestigativeCustomerMessage,
                            setFetchMessageStatus,
                            selectedCustomerMessage,
                            selectCustomerMessage,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ChatModule;
