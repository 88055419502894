import React from "react";
import TDConversationsHeading from "./TDConversationsHeading/TDConversationsHeading";
import TDConversationsBody from "./TDConversationsBody/TDConversationsBody";

const TDConversations = ({ conversations, handleSelectTagResp }) => {
    return (
        <div className='analyzer-message-tags'>
            <TDConversationsHeading />
            <TDConversationsBody
                conversations={conversations}
                handleSelectTagResp={handleSelectTagResp}
            />
        </div>
    );
};

export default TDConversations;
