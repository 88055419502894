import React from "react";
import SelectUI from "components/ui/SelectUI/SelectUI";

const filterOptions = [
    {
        label: "Agent",
        value: "AGENT",
    },
    {
        label: "Team",
        value: "TEAM",
    },
    {
        label: "Ticket Priority",
        value: "TICKET_CLASSFICATION",
    },
    {
        label: "Ticket Classification",
        value: "TICKET_CAPSULES",
    },
    {
        label: "Ticket Phase",
        value: "TICKET_PHASE",
    },
    {
        label: "Ticket Source",
        value: "TICKET_SOURCE",
    },
    {
        label: "Solution Type",
        value: "TICKET_SOLUTION_TYPE",
    },
];

const AnalyticsFilterType = ({ defaultValue, handleBaseFilterChange }) => {
    return (
        <SelectUI
            otherClassNames='analytics-filter__select-sortby'
            placeholder='Sort by'
            options={filterOptions}
            onChange={({ value }) =>
                handleBaseFilterChange("analyticsRequestType", value)
            }
            // defaultValue={defaultValue}
            value={filterOptions?.find((x) => x.value === defaultValue)}
            defaultValue={filterOptions?.find((x) => x.value === defaultValue)}
            height='38px !important'
            marginClass='mb-0'
            noBorder={true}
        />
    );
};

export default AnalyticsFilterType;
