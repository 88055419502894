import { getDaysHrsMinsAndSecs } from "utils/helper";

const FollowUpTaskViewDuration = ({ duration }) => {
    let { days, hours, mins, secs } = getDaysHrsMinsAndSecs(duration);

    return (
        <>
            {duration === "0"
                ? "0 second"
                : `${
                      days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""
                  } ${
                      hours > 0
                          ? `${hours} ${hours === 1 ? "hour" : "hours"}`
                          : ""
                  } ${
                      mins > 0
                          ? `${mins} ${mins === 1 ? "minute" : "minutes"}`
                          : ""
                  } ${
                      secs ? `${secs} ${secs <= 1 ? "second" : "seconds"}` : ""
                  }`}
        </>
    );
};

export default FollowUpTaskViewDuration;
