import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import { Link, withRouter } from "react-router-dom";
import {
    setWorkspaces,
    updateAuthInfo,
    updateParticularWorkspace,
} from "store/auth/actions";
import { setAccessToken, setWorkspaceToken } from "storage/cookieStorage";
import API from "services/lib/api";
import { setDeviceToken } from "storage/localStorage";
import { getDevicePushToken } from "services/firebase/firebase";

const SignInPasswordForm = ({
    signInRequest,
    updateAuthInfo,
    userWorkspaces,
    setWorkspaces,
    updateParticularWorkspace,
}) => {
    const [loading, setLoading] = useState(false);

    const [request, updateRequest] = useState({
        password: "",
    });

    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const getDeviceToken = async () => {
        let deviceToken = await getDevicePushToken();

        setDeviceToken(deviceToken);
        updateRequest({ ...request, deviceToken });
    };

    useEffect(() => {
        getDeviceToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const authorizeLogging = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            const url = apiRoute.signin;
            const newRequest = {
                ...signInRequest,
                ...request,
            };
            const res = await API.post(url, { ...newRequest });
            if (res.status === 201) {
                const { user, token } = res.data.data;
                await setAccessToken(token);
                await setWorkspaceToken(token, user?.workspace?.workspaceName);
                await setWorkspaces(userWorkspaces);
                await updateParticularWorkspace({ user });
                await updateAuthInfo(user);

                // window.location.href = handleAuthRedirect(
                //     user?.role?.permissions
                // );
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, formisValid } = ValidateForm(e, request);
        if (formisValid) {
            authorizeLogging();
        }

        setErrors(errors);
    };

    return (
        <div>
            <h1 className='sub__stage--heading'>Enter your password</h1>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMsg)}
                    message={errorMsg}
                    hide={() => setErrorMsg()}
                />
                <Input
                    grpClass='mb-3'
                    type='password'
                    name='password'
                    label='Password'
                    labelClass='text-dark fw-bold'
                    id='password'
                    placeholder='Enter your password'
                    value={request?.password}
                    showPwd
                    onChange={handleChange}
                    isErr={errors?.password}
                    errMssg={errors?.password}
                />
                <div className='signin--forgot-password-link'>
                    <Link
                        to={{
                            pathname: "/forgot-password",
                            state: {
                                email: signInRequest?.email,
                            },
                        }}
                        className='signin--link'>
                        Forgot Password?
                    </Link>
                </div>
                <Button
                    type='submit'
                    text='Proceed'
                    classType='primary'
                    loading={loading}
                    otherClass='my-3 w-100'
                />
            </form>
        </div>
    );
};

export default connect(null, {
    updateAuthInfo,
    setWorkspaces,
    updateParticularWorkspace,
})(withRouter(SignInPasswordForm));
