export const AVERAGE_RESPONSE_TIME = "AVERAGE_RESPONSE_TIME";
export const AVERAGE_RESOLUTION_TIME = "AVERAGE_RESOLUTION_TIME";
export const AVERAGE_FIRST_RESPONSE_TIME = "AVERAGE_FIRST_RESPONSE_TIME";

export const teamAnalyticsRespTimeDefaultOptions = [
    {
        label: "Average Response Time",
        value: "undefined",
        color: "#be3474",
    },
];

export const teamAnalyticsResolveTimeDefaultOptions = [
    {
        label: "Average Resolution Time",
        value: "undefined",
        color: "#be3474",
    },
];

export const teamAnalyticsFirstRespTimeDefaultOptions = [
    {
        label: "Average First Response Time",
        value: "undefined",
        color: "#be3474",
    },
];

export const teamAnalyticsRespTimeTooltipExtraMetrics = [
    {
        label: "External Fix Average Response Time",
        value: "extFixAvgResTime",
    },
    {
        label: "Instructional Fix Average Response Time",
        value: "insFixAvgResTime",
    },
    {
        label: "Internal Fix Average Response Time",
        value: "intFixAvgResTime",
    },
];

export const teamAnalyticsResolveTimeTooltipExtraMetrics = [
    {
        label: "External Fix Average Resolution Time",
        value: "extFixAvgResolveTime",
    },
    {
        label: "Instructional Fix Average Resolution Time",
        value: "insFixAvgResolveTime",
    },
    {
        label: "Internal Fix Average Resolution Time",
        value: "intFixAvgResolveTime",
    },
];

export const teamAnalyticsFResTimeTooltipExtraMetrics = [
    {
        label: "Instructional Fix Average First Resolution Time",
        value: "insFixAvgFirstResTime",
    },
];
