import { ticketsClassifications } from "components/dashboard/AgentInbox/enum";
import React from "react";
import { useSelector } from "react-redux";
import { ticketsFilterBuilder } from "../constants";
import TicketFilterSelector from "../TicketFilterSelector/TicketFilterSelector";

const TicketsClassificationsFilter = ({
    countsData,
    selectedTicketClassification,
    fetchingCount,
    handleTicketClassificationSelect,
}) => {
    const { userId } = useSelector((state) => state.auth?.user);
    const { incomingTickets } = useSelector((state) => state?.incomingTickets);

    return (
        <>
            <div>
                {/* <h6 className='mb-3'>
                    <span style={{ opacity: 0 }}>Day</span>
                </h6> */}

                <div className='d-flex'>
                    {Object.entries(ticketsClassifications).map(
                        ([_, ticketClass], index) => {
                            const filterBlock =
                                ticketsFilterBuilder[ticketClass];

                            const filterByClassification = (ticket) => {
                                return (
                                    ticket.ticketClassification === ticketClass
                                );
                            };

                            const filterByTicketInfoValue = (ticket) => {
                                let isAssigned =
                                    ticket?.assigned &&
                                    ticket?.assignedTeamMemberId === userId;
                                return isAssigned;
                            };

                            const filterByTicketStatus = (ticket) => {
                                return ticket.ticketStatus && ticket.isNew;
                            };

                            const newTicketsAvailable = incomingTickets
                                ?.filter(filterByClassification)
                                .filter(filterByTicketInfoValue)
                                .filter(filterByTicketStatus);

                            return (
                                <TicketFilterSelector
                                    key={index}
                                    countValue={
                                        countsData?.[filterBlock?.["countKey"]]
                                    }
                                    label={filterBlock?.title}
                                    isActive={
                                        selectedTicketClassification ===
                                        ticketClass
                                    }
                                    onClick={() => {
                                        handleTicketClassificationSelect(
                                            ticketClass
                                        );
                                    }}
                                    hasNew={newTicketsAvailable.length > 0}
                                    fetchingCount={fetchingCount}
                                    otherClassNames='with-classi'
                                />
                            );
                        }
                    )}
                </div>
            </div>
        </>
    );
};

export default TicketsClassificationsFilter;
