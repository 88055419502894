import { React } from "react";
import SolidAddButton from "components/ui/Button/SolidAddButton/SolidAddButton";

const FormsPostFormBranchesHeaderButton = () => {
    return (
        <>
            <SolidAddButton btnText='Add New Form' />
        </>
    );
};

export default FormsPostFormBranchesHeaderButton;
