import React, { useState } from "react";
import { FileInput } from "components/ui";
import asset from "assets/images";
import "./EditStartUpProfileAvatar.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const EditStartUpProfileAvatar = ({ workspaceLogoUrl, setLogo }) => {
    const [formError, setFormError] = useState();
    const [avatar, setAvatar] = useState(
        workspaceLogoUrl || asset?.images?.placeholder
    );

    const handleUpload = ({ target: { files } }) => {
        const file = files[0];
        if (file && ["image/png", "image/jpeg"].includes(file.type)) {
            if (file.size > 1048576) setFormError("File too large. Max of 1MB");
            else {
                setLogo(file);
                setAvatar(URL.createObjectURL(file));
            }
        } else setFormError("Please upload a valid image (png/jpg only)");
    };

    return (
        <div className='edit__profile'>
            <ImageView src={avatar} className='edit__profile--avatar' alt='' />

            <FileInput
                grpClass='mt-n2'
                fileType='image/*'
                id='avatar'
                name='img'
                label='Click to edit image'
                labelClass='text-primary'
                labelName='Company logo'
                onChange={handleUpload}
                isErr={formError}
                errMssg={formError}
            />
        </div>
    );
};

export default EditStartUpProfileAvatar;
