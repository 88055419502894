import { useState } from "react";
import PropTypes from "prop-types";
import { Empty } from "components/ui";
import { Table } from "components/ui/Table/Table";
import { MESSAGE_TAG_HEADERS } from "../../../enum";
import MetadataTags from "./MetadataTags/MetadataTags";
import KeyPhraseActionDropdown from "../KeyPhraseActionDropdown/KeyPhraseActionDropdown";
import KeyPhraseActionModals from "../KeyPhraseActionModal/KayPhraseActonModal";
import "./KeyPhraseList.scss";

const KeyPhraseList = ({ keyPhrases, handleSuccess }) => {
    const [currentAction, setCurrentAction] = useState("");
    const [currentPhrase, setCurrentPhrase] = useState({});

    const handleActionModal = (action, phrase) => {
        setCurrentAction(action);
        setCurrentPhrase(phrase);
    };

    const closeActionModal = () => {
        setCurrentAction("");
        setCurrentPhrase("");
    };

    const tableData = () =>
        keyPhrases?.map((keyPhrase) => ({
            Name: `${keyPhrase.keyword}`,
            Metadata: <MetadataTags metadata={keyPhrase.metadata} />,
            action: (
                <KeyPhraseActionDropdown
                    keyPhrase={keyPhrase}
                    handleActionModal={handleActionModal}
                />
            ),
        }));

    return (
        <>
            <div
                className={`tagging__body ${
                    keyPhrases?.length ? "padding" : ""
                }`}>
                {keyPhrases?.length ? (
                    <Table
                        headers={MESSAGE_TAG_HEADERS}
                        dataSource={tableData()}
                    />
                ) : (
                    <Empty text='No keyword created' />
                )}
            </div>
            {currentAction && (
                <KeyPhraseActionModals
                    keyPhrase={currentPhrase}
                    currentAction={currentAction}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

KeyPhraseList.prototypes = {
    keyPhrases: PropTypes.array.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    toastResponseStatus: PropTypes.func.isRequired,
};

export default KeyPhraseList;
