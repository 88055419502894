import React, { useState, useEffect } from "react";
import PendingTask from "./PendingTask/PendingTask";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
// import ConfirmTagResponseSend from "../../../TMessageTagSuggestion/TMTSuggestionElement/TMTagDetail/TMTagDetailElement/ConfirmTagResponseSend/ConfirmTagResponseSend";
import ConfirmPFCollectionSend from "../../../TicketPostFormCollection/TicketsPFCollectionsListing/ConfirmPFCollectionSend/ConfirmPFCollectionSend";
import { taskTypes } from "../enum";
import "./PendingTaskList.scss";
import { taskStatus } from "../../enum";
import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;
const { TIME_BASED, SCENARIO_BASED } = taskTypes;

const { TODO } = taskStatus;

const { REPLY_EMAIL, PREVIEW_EMAIL } = EXTERNAL_EMAIL_ACTION;
const PendingTaskList = ({
    followupTasks,
    getTicketFollowups,
    followupId,
    selectedTicket,
    setCannedMessage,
    handleToggleEmailPreview,
    refetchEmails,
}) => {
    const { ticketId } = selectedTicket || {};
    const [errorMssg, setErrorMssg] = useState("");
    const [status, setStatus] = useState(LOADING);
    const [mostRecentMail, setMostRecentMail] = useState({});

    // const [showCannedResponseModal, toggleCannedResponseModal] = useState(false);
    // const [cannedResponse, setCannedResponse] = useState({});
    const [showPFCollectionSendModal, togglePFCollectionSendModal] =
        useState(false);
    const [pFCollection, setPFCollection] = useState({});

    const handleOpenEmailPreviewModal = (selectedEmail, isResponse) => {
        const cannedEmail = isResponse
            ? {
                  ...mostRecentMail,
                  reply: true,
                  message: selectedEmail?.message,
              }
            : selectedEmail;

        handleToggleEmailPreview(
            cannedEmail,
            isResponse ? REPLY_EMAIL : PREVIEW_EMAIL
        );
    };

    const handleModalToggle = (item, type) => {
        // toggleCannedResponseModal(true);
        // setCannedResponse({
        //     item,
        //     type,
        // });
        setCannedMessage(item.response);
    };

    const handleTogglePFCollectionSendModal = (pfCollection) => {
        togglePFCollectionSendModal(true);
        setPFCollection(pfCollection);
    };

    const getEmails = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoute?.externalEmail(ticketId);

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                setMostRecentMail(data?.[0] || {});
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getEmails(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchEmails]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <div className='pending__task__list'>
                        <div>
                            {" "}
                            <div className='pending__task__classification'>
                                <span>{TIME_BASED}</span>
                            </div>
                            {followupTasks
                                ?.filter(
                                    ({ task }) =>
                                        task?.initiationSchedule !== "0"
                                )
                                .sort(
                                    (a, b) =>
                                        new Date(a?.dueDate) -
                                        new Date(b?.dueDate)
                                )
                                ?.map((followupTask, key) => (
                                    <PendingTask
                                        key={key}
                                        handleOpenEmailPreviewModal={
                                            handleOpenEmailPreviewModal
                                        }
                                        followupTask={followupTask}
                                        isNew={
                                            followupTask?.taskStatus === TODO
                                        }
                                        followupId={followupId}
                                        getTicketFollowups={getTicketFollowups}
                                        emailsExist={
                                            Object?.keys(mostRecentMail)
                                                ?.length > 0
                                        }
                                        handleModalToggle={handleModalToggle}
                                        handleTogglePFCollectionSendModal={
                                            handleTogglePFCollectionSendModal
                                        }
                                        type={TIME_BASED}
                                    />
                                ))}
                        </div>
                        <div>
                            {" "}
                            <div className='pending__task__classification'>
                                <span>{SCENARIO_BASED}</span>
                            </div>
                            {followupTasks
                                ?.filter(
                                    ({ task }) =>
                                        task?.initiationSchedule === "0"
                                )
                                ?.map((followupTask, key) => (
                                    <PendingTask
                                        key={key}
                                        handleOpenEmailPreviewModal={
                                            handleOpenEmailPreviewModal
                                        }
                                        followupTask={followupTask}
                                        followupId={followupId}
                                        isNew={
                                            followupTask?.taskStatus === TODO
                                        }
                                        getTicketFollowups={getTicketFollowups}
                                        emailsExist={
                                            Object?.keys(mostRecentMail)
                                                ?.length > 0
                                        }
                                        handleModalToggle={handleModalToggle}
                                        handleTogglePFCollectionSendModal={
                                            handleTogglePFCollectionSendModal
                                        }
                                        type={SCENARIO_BASED}
                                    />
                                ))}
                        </div>
                    </div>
                );
            case ERROR:
                return <ErrorView message={errorMssg} />;
            default:
                return "";
        }
    };

    return (
        <>
            {renderBasedOnStatus()}

            {/* {showCannedResponseModal && (
                <ConfirmTagResponseSend
                    showModal={showCannedResponseModal}
                    closeModal={() => toggleCannedResponseModal(false)}
                    suggestion={cannedResponse}
                    selectedTicket={selectedTicket}
                />
            )} */}

            {showPFCollectionSendModal && (
                <ConfirmPFCollectionSend
                    showModal={showPFCollectionSendModal}
                    closeModal={() => togglePFCollectionSendModal(false)}
                    selectedTicket={selectedTicket}
                    pFCollection={pFCollection}
                />
            )}
        </>
    );
};

export default PendingTaskList;
