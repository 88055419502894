import React from "react";
import { ReactSVG } from "react-svg";
import { Link, useHistory } from "react-router-dom";
import asset from "assets/images";
import PropTypes from "prop-types";
import "./BreadCrumbHeader.scss";

const BreadCrumbHeader = ({
    prevPage,
    currentPage,
    prevPageTitle,
    useGoBack,
    currentPageTitle,
    nextPage,
    hasError,
}) => {
    const history = useHistory();

    return (
        <div className='pre__nav'>
            {useGoBack ? (
                <a href='/' onClick={() => history.goBack()}>
                    {prevPageTitle}
                </a>
            ) : (
                <Link to={prevPage}>{prevPageTitle}</Link>
            )}
            <ReactSVG src={asset?.icons?.rightBlack} className='anchor-right' />

            {currentPage ? (
                <Link to={currentPage}>
                    <p className='content'>{currentPageTitle}</p>
                </Link>
            ) : (
                <p className='content'>{currentPageTitle}</p>
            )}
            {nextPage && (
                <>
                    <ReactSVG
                        src={asset?.icons?.rightBlack}
                        className='anchor-right'
                    />
                    <p className='content'>{nextPage}</p>
                </>
            )}
            {hasError && (
                <div className='pre__error'>
                    <ReactSVG src={asset?.icons?.error} />
                </div>
            )}
        </div>
    );
};

BreadCrumbHeader.propTypes = {
    currentPageTitle: PropTypes.string.isRequired,
    prevPage: PropTypes.string.isRequired,
    prevPageTitle: PropTypes.string.isRequired,
};

export default BreadCrumbHeader;
