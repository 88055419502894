import React from "react";
import "./ReminderDuration.scss";

import { getDaysHrsMinsAndSecs, getSecondsSince } from "utils/helper";

const ReminderDuration = ({ reminderDuration }) => {
    const noOfSecondsAhead = getSecondsSince(reminderDuration);
    const time = getDaysHrsMinsAndSecs(noOfSecondsAhead);
    const { days, hours, mins } = time;

    return (
        <div className='reminder__duration'>
            {days > 0 && (
                <span className='duration'>
                    {days}
                    <span className='duration__suffix'>Days</span>
                </span>
            )}{" "}
            {hours > 0 && (
                <span className='duration'>
                    {hours}
                    <span className='duration__suffix'>Hrs</span>
                </span>
            )}{" "}
            {mins > 0 && (
                <span className='duration'>
                    {mins}
                    <span className='duration__suffix'>Mins</span>
                </span>
            )}
        </div>
    );
};

export default ReminderDuration;
