import PropTypes from "prop-types";
import { Dropdown } from "components/ui";
import asset from "assets/images";
import { ReactSVG } from "react-svg";
import { CAPSULE_CONTENT_BLOCK_FORMATS } from "components/dashboard/KnowledgeBase/CapsuleSettings/enums";
import { pipeEnums } from "utils/helper";
import "./CapsuleContentsDropdown.scss";

const CapsuleContentsDropdown = ({
    acceptedContentType,
    toggleBlockContentModal,
}) => {
    return (
        <Dropdown
            size='md'
            placeholder={
                <>
                    <ReactSVG
                        src={asset?.icons?.plusWhite}
                        className='d-inline-flex'
                    />{" "}
                    Add New Content Block
                </>
            }
            ddValueClass='features cc__content__dropdown'
            anchor={false}
            changeValue={false}
            onChange={toggleBlockContentModal}
            options={acceptedContentType
                ?.filter(
                    (contentType) =>
                        contentType !== CAPSULE_CONTENT_BLOCK_FORMATS.TITLE
                )
                ?.map((contentType) => ({
                    value: contentType,
                    label: pipeEnums(contentType),
                }))}
            isDefault={false}
        />
    );
};

CapsuleContentsDropdown.prototypes = {
    toggleBlockContentModal: PropTypes.func.isRequired,
};

export default CapsuleContentsDropdown;
