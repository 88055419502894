import asset from "assets/images";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_FORM_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

export const formInputTypes = {
    TEXT: "TEXT",
    NUMERIC: "NUMERIC",
    LONG_TEXT: "LONG_TEXT",
    VIDEO: "VIDEO",
    IMAGE: "IMAGE",
    MULTISELECT: "MULTISELECT",
    DATE: "DATE",
    END_FORM: "END_FORM",
};

const { TEXT, NUMERIC, LONG_TEXT, IMAGE, MULTISELECT, DATE } = formInputTypes;

export const constraintInputTypes = {
    TEXT_INPUT: "TEXT_INPUT",
    DATE_INPUT: "DATE_INPUT",
    LINK: "LINK",
    NUMBER_INPUT: "NUMBER_INPUT",
};

const { TEXT_INPUT, DATE_INPUT, LINK, NUMBER_INPUT } = constraintInputTypes;

export const formElementBlocks = Object.freeze({
    [TEXT]: {
        title: "Text",
        badge: asset?.icons?.A,
        body: "Single line text inputs or multiple lines",
        type: TEXT,
        rules: [
            {
                label: "Input Must be Email",
                value: "text00",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "acceptedDomains",
                    displayAs: "Accepted Domains",
                    constraintKeyType: TEXT_INPUT,
                },
            },
            {
                label: "Input Must not Exceed",
                value: "text01",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "maxTextLength",
                    displayAs: "Max Length",
                    constraintKeyType: NUMBER_INPUT,
                },
            },
            {
                label: "Input Must be a minimum",
                value: "text02",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "minTextLength",
                    displayAs: "Min Length",
                    constraintKeyType: NUMBER_INPUT,
                },
            },
            {
                label: "Input Must be a Link",
                value: "text03",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "domains",
                    displayAs: "https://domain.com",
                    constraintKeyType: LINK,
                },
            },
        ],
    },
    [NUMERIC]: {
        title: "Numeric",
        badge: asset?.icons?.asterick,
        body: "Single line input or multiple",
        type: NUMERIC,
        rules: [
            {
                label: "Number must not exceed",
                value: "number00",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "maxNumValue",
                    displayAs: "Max Value",
                    constraintKeyType: NUMBER_INPUT,
                },
            },
            {
                label: "Number must not be below",
                value: "number01",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "minNumValue",
                    displayAs: "Min Value",
                    constraintKeyType: NUMBER_INPUT,
                },
            },
            {
                label: "Numeric input with prefix",
                value: "number02",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "numPrefix",
                    displayAs: "Enter prefix here",
                    constraintKeyType: TEXT_INPUT,
                },
            },
        ],
    },
    [LONG_TEXT]: {
        title: "Text Area",
        badge: asset?.icons?.A,
        body: "Multiple lines input",
        type: LONG_TEXT,
    },
    // [VIDEO]: {
    //     title: "Videos",
    //     badge: asset?.icons?.video,
    //     body: "Select a video file",
    //     type: VIDEO,
    //     rules: [
    //         {
    //             label: "Video size must not exceed",
    //             value: "video00",
    //             baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
    //             ruleConstraint: {
    //                 constraintKey: "videoMaxSize",
    //                 displayAs: "Max Video size",
    //                 constraintKeyType: NUMBER_INPUT,
    //             },
    //         },
    //     ],
    // },
    [IMAGE]: {
        title: "Images",
        badge: asset?.icons?.picture,
        body: "Upload an image",
        type: IMAGE,
        rules: [
            {
                label: "Image size must not exceed",
                value: "image00",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "imageMaxSize",
                    displayAs: "Max Image size",
                    constraintKeyType: NUMBER_INPUT,
                },
            },
        ],
    },
    [MULTISELECT]: {
        title: "Multiple Select",
        badge: asset?.icons?.checkGrey,
        body: "Select multiple options",
        type: MULTISELECT,
    },
    [DATE]: {
        title: "Date",
        badge: asset?.icons?.calender,
        body: "Select date from calender",
        type: DATE,
        rules: [
            {
                label: "Date must start from",
                value: "date00",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "startDate",
                    displayAs: "Min Date",
                    constraintKeyType: DATE_INPUT,
                },
            },
            {
                label: "Date must must exceed",
                value: "date01",
                baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                ruleConstraint: {
                    constraintKey: "endDate",
                    displayAs: "Max Date",
                    constraintKeyType: DATE_INPUT,
                },
            },
        ],
    },
    // [END_FORM]: {
    //     title: "End Form",
    //     badge: asset?.icons?.calender,
    //     body: "Create a concluding branch",
    //     type: END_FORM,
    // },
});
