import SelectUI from "components/ui/SelectUI/SelectUI";
import "./CreateGDUAutomationFields.scss";
import {
    GUIDE_TASK_ACTIONS_SELECT,
    GUIDE_TASK_SCENARIOS_SELECT,
    GUIDE_TASK_SCHEDULES,
} from "../../../../../enum";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { ReactSVG } from "react-svg";
import asset from "assets/images";

const CreateGDUAutomationFields = ({
    agentUpdateTaskActionType,
    handleUpdateRequest,
    errors,
    agentUpdateTaskScenairoType,
    initiationSchedule,
    initiationScheduleType,
    index,
    handleRemoveRequest,
    agentUpdateTaskType,
    taskActionType,
    taskCaseScenairoType,
    agentUpdateTaskName,
    isEdit,
}) => {
    return (
        <section className='guide-automation-fields'>
            <h5>Automation {index + 1}</h5>
            {/* <div>
                <Input
                    grpClass='mb-3'
                    type='text'
                    name='agentUpdateTaskName'
                    label='Task Name'
                    labelClass='text-dark fw-medium'
                    id='agentUpdateTaskNamee'
                    placeholder='Enter Task name'
                    value={agentUpdateTaskName}
                    onChange={({ target: { value } }) =>
                        handleUpdateRequest("agentUpdateTaskName", value)
                    }
                    isErr={errors?.agentUpdateTaskName}
                    errMssg={errors?.agentUpdateTaskName}
                    autoFocus={true}
                />
            </div> */}
            <div className='guide-automation-fields__section'>
                <section>
                    <SelectUI
                        name='agentUpdateTaskActionType'
                        label=''
                        id='agentUpdateTaskActionType'
                        placeholder='Action'
                        options={GUIDE_TASK_ACTIONS_SELECT}
                        error={
                            errors?.agentUpdateTaskActionType
                                ? "Action is required"
                                : ""
                        }
                        value={GUIDE_TASK_ACTIONS_SELECT?.find(({ value }) => {
                            return (
                                agentUpdateTaskActionType === value ||
                                taskActionType === value
                            );
                        })}
                        onChange={({ value }) =>
                            handleUpdateRequest(
                               "agentUpdateTaskActionType",
                                value
                            )
                        }
                        isRequired={true}
                    />
                </section>
                <section>
                    <SelectUI
                        name='agentUpdateTaskScenairoType'
                        label=''
                        id='agentUpdateTaskScenairoType'
                        placeholder='Scenario'
                        options={GUIDE_TASK_SCENARIOS_SELECT}
                        error={
                            errors?.agentUpdateTaskScenairoType
                                ? "Scenario is required"
                                : ""
                        }
                        value={GUIDE_TASK_SCENARIOS_SELECT?.find(
                            ({ value }) => {
                                return (
                                    agentUpdateTaskScenairoType === value ||
                                    taskCaseScenairoType === value
                                );
                            }
                        )}
                        onChange={({ value }) =>
                            handleUpdateRequest(
                                "agentUpdateTaskScenairoType",
                                value
                            )
                        }
                        isRequired={true}
                    />
                </section>
            </div>
            <div className=''>
                <ScheduleDayInput
                    label='Add schedule'
                    showLabel={true}
                    showSubLabel={false}
                    name='initiationSchedule'
                    id='initiationSchedule'
                    handleDateChange={(totalNoOfSeconds) =>
                        handleUpdateRequest(
                            "initiationSchedule",
                            totalNoOfSeconds
                        )
                    }
                    error={errors?.initiationSchedule}
                    timeValue={initiationSchedule}
                    maxDays={10}
                />

                <SelectUI
                    name='taskInitiationScheduleType'
                    label=''
                    id='taskInitiationScheduleType'
                    placeholder='When'
                    options={GUIDE_TASK_SCHEDULES}
                    error={
                        errors?.initiationScheduleType
                            ? "Schedule Type is required"
                            : ""
                    }
                    value={GUIDE_TASK_SCHEDULES?.find((scheduleType) => {
                        return initiationScheduleType === scheduleType.value;
                    })}
                    onChange={({ value }) =>
                        handleUpdateRequest("initiationScheduleType", value)
                    }
                    isRequired={true}
                />
            </div>
            <div className='delete_automation'>
                <ReactSVG
                    src={asset.icons.trashIcon}
                    onClick={handleRemoveRequest}
                />
            </div>
        </section>
    );
};

export default CreateGDUAutomationFields;
