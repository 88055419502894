import SwitchesListingTable from "./SwitchesListingTable/SwitchesListingTable";

const SwitchesListing = ({ dependencies, getDependencies, tableLoading }) => {
    return (
        <>
            <SwitchesListingTable
                dependencies={dependencies}
                getDependencies={getDependencies}
                tableLoading={tableLoading}
            />
        </>
    );
};

export default SwitchesListing;
