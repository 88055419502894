import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import NewCannedForm from "./NewCannedForm/NewCannedForm";

const NewCannedModal = ({
    closeModal,
    showModal,
    subTitle,
    title,
    cannedResourceType,
    isEdit,
    selectedCannedResource,
    handleSuccess,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading title={title} subTitle={subTitle} />
                    <NewCannedForm
                        closeModal={closeModal}
                        cannedResourceType={cannedResourceType}
                        isEdit={isEdit}
                        handleSuccess={handleSuccess}
                        selectedCannedResource={selectedCannedResource}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default NewCannedModal;
