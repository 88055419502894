import PropTypes from "prop-types";
import Classification from "./Classification/Classification";
import { useState } from "react";
import ItemsList from "components/ui/ItemsList/ItemsList";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import { classificationActions } from "../enum";

const { EDIT_CLASSIFICATION, DELETE_CLASSIFICATION } = classificationActions;

const ClassificationsList = ({
    classifications,
    selectedClassification,
    setSelectedClassification,
    handleSuccess,
    toggleClassificationModal,
    setEdit,
}) => {
    const [showModal, toggleModal] = useState(false);

    const handleActionModal = (action) => {
        switch (action) {
            case EDIT_CLASSIFICATION:
                toggleClassificationModal(true);
                setEdit(true);
                break;
            case DELETE_CLASSIFICATION:
                toggleModal(true);
                break;
            default:
                break;
        }
    };

    const closeActionModal = () => {
        toggleModal(false);
    };

    return (
        <div>
            <ItemsList otherClassNames={"mb-3"} breakPoint={20}>
                {classifications?.map((item, i) => (
                    <Classification
                        key={i}
                        item={item}
                        selectedClassification={selectedClassification}
                        setSelectedClassification={setSelectedClassification}
                        handleActionModal={handleActionModal}
                    />
                ))}
            </ItemsList>

            {showModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete ${selectedClassification?.name}`}
                    show={showModal}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                    url={apiRoute.classification(
                        selectedClassification?.classificationId
                    )}
                    method='delete'
                />
            )}
        </div>
    );
};
ClassificationsList.prototypes = {
    classifications: PropTypes.array.isRequired,
    setSelectedClassification: PropTypes.func.isRequired,
    selectedClassification: PropTypes.object.isRequired,
};

export default ClassificationsList;
