import "./ViewGDUpdateItem.scss";

const ViewGDUpdateItem = ({
    agentUpdateName,
    initiationSchedule,
    isActive,
    onClick,
}) => {
    const scheduleInMins =
        initiationSchedule > 60
            ? Number(initiationSchedule / 60).toFixed(2)
            : 0;
    return (
        <li
            className={`gd-update-item ${isActive ? "active" : ""}`}
            onClick={onClick}>
            {agentUpdateName}
            <span>
                {scheduleInMins
                    ? `${scheduleInMins} mins`
                    : `${initiationSchedule} secs`}
            </span>
        </li>
    );
};

export default ViewGDUpdateItem;
