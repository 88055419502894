import "./Heading.scss";

const Heading = ({ headingText, headingSubText }) => {
    return (
        <>
            <div className='heading'>
                <h1>{headingText}</h1>
                <p>{headingSubText}</p>
            </div>
        </>
    );
};

export default Heading;
