import React, { useEffect, useState } from "react";
import asset from "assets/images";
import { connect } from "react-redux";
import { getCapsulesCount } from "./lib";
import { updateProductCapsuleCount } from "store/capsule/actions";
import { truncateString } from "utils/helper";
import "./Capsule.scss";

const Capsule = ({
    capsule = {},
    isActive,
    showAdd,
    showCount,
    useDefaultAggregate,
    updateProductCapsuleCount,
    capsulesCount,
    secondaryCapsuleClass,
    baseEntryId,
    productId,
    showRemove,
    handleRemove,
    children,
    ...rest
}) => {
    const [capsuleAggregateCount, updateCapsuleAggregateCount] = useState("-");
    const {
        fillColor = "",
        borderColor,
        capsuleClassName,
        capsuleClassId,
    } = capsule;
    const countKey = `${baseEntryId ? `${baseEntryId}` : ""}caspule_${
        productId +
        "_" +
        capsuleClassId +
        "_" +
        (secondaryCapsuleClass ? secondaryCapsuleClass?.capsuleClassId : "")
    }`;

    const getCapsuleEntriesAggregate = async () => {
        try {
            const countVal = await getCapsulesCount(
                capsule,
                productId,
                secondaryCapsuleClass,
                baseEntryId
            );

            await updateProductCapsuleCount({ countKey, countVal });
            updateCapsuleAggregateCount(countVal);
        } catch (err) {
            updateCapsuleAggregateCount(0);
        }
    };

    useEffect(() => {
        if (showCount) {
            const defaultCount = capsulesCount[countKey];
            if (useDefaultAggregate && defaultCount) {
                updateCapsuleAggregateCount(defaultCount);
            } else {
                getCapsuleEntriesAggregate();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            key={countKey}
            className={`capsule ${showAdd ? "addition-mode" : ""} ${
                isActive ? "active" : ""
            }`}
            id={`capsule${capsuleClassId}`}
            style={
                isActive
                    ? {
                          background: `${fillColor}`,
                          color: borderColor,
                          borderColor,
                      }
                    : {}
            }
            {...rest}>
            <span
                className={`d-flex align-items-center justify-content-center`}>
                {showAdd && (
                    <img
                        src={asset?.icons?.bluePlusIcon}
                        className='cross-add'
                        alt='+'
                    />
                )}
                <span>{truncateString(capsuleClassName, 50)}</span>
                {showCount && (
                    <span
                        className={`capsule_count`}
                        style={
                            isActive
                                ? {
                                      backgroundColor: borderColor,
                                      color: "#fff",
                                  }
                                : {}
                        }>
                        <span>{capsuleAggregateCount}</span>
                    </span>
                )}
                {showRemove && (
                    <img
                        src={asset?.icons?.crossIconGrey}
                        className='cross-del'
                        onClick={() => handleRemove(capsuleClassId)}
                        alt='x'>
                        {children}
                    </img>
                )}
            </span>
        </div>
    );
};

export default connect(
    (state) => ({ capsulesCount: state?.capsule?.capsulesCount }),
    { updateProductCapsuleCount }
)(Capsule);
