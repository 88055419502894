import CapsuleStatusListing from "../../../DependencyCapsulesContainer/CapsuleStatusListing/CapsuleStatusListing";

const ViewCapsulesListing = ({
    capsuleClass,
    getCapsuleClass,
    capsuleClassCounts,
    capsuleType,
}) => {
    return (
        <>
            <CapsuleStatusListing
                selectedCapsuleClass={capsuleClass}
                getCapsuleClasses={getCapsuleClass}
                capsulesTotalCount={capsuleClassCounts}
                capsuleType={capsuleType}
                hasEnabledCapsules={true}
            />
        </>
    );
};

export default ViewCapsulesListing;
