const NoAppConnections = () => {
    return (
        <div className='appUser__row'>
            <div className='row'>
                <div className='col-3'>
                    <p>No Account Connected Yet</p>
                </div>
                <div className='col-3'>
                    <p>______</p>
                </div>
            </div>
        </div>
    );
};

export default NoAppConnections;
