import { ANALYTICS_QA_T_DETAILS_TABS } from "../enum";
import "./AnalyticsQATDHeader.scss";

const { VARIATIONS, COMMENTS, TICKET_INFO, TIMELINE } =
    ANALYTICS_QA_T_DETAILS_TABS;

const tabs = [
    { value: TIMELINE, label: "Timeline" },
    {
        value: VARIATIONS,
        label: "Variations",
    },
    {
        value: COMMENTS,
        label: "Comments",
    },
    {
        value: TICKET_INFO,
        label: "Ticket Info",
    },
];

const AnalyticsQATDHeader = ({ currentTab, setCurrentTab }) => {
    return (
        <div className='analytics-qa-td-header'>
            <ul>
                {tabs?.map(({ label, value }, key) => (
                    <li
                        key={key}
                        onClick={() => setCurrentTab(value)}
                        className={`${value === currentTab ? "active" : ""}`}>
                        {label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AnalyticsQATDHeader;
