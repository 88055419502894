import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    CREATE_NEW_BRANCH_COLLECTION,
    ADD_EXISTING_BRANCH_COLLECTION,
} from "../enums";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { getErrorMessage } from "utils/helper";

const BranchCollectionNameInput = ({
    name,
    source,
    handleBranchCollectionNameChange,
    isLoading,
    setLoadingCollectionsNames,
    autoFocus,
    actionType,
    createAble,
    error,
    selectedBranchCollectionValue,
    existingBranchCollections,
}) => {
    const [branchCollectionValue, setBranchCollectionValue] = useState({});
    const [serverError, setServerError] = useState("");
    const [allCollections, setAllCollections] = useState([]);

    const retrieveBranchCollections = async () => {
        try {
            setServerError("");
            setLoadingCollectionsNames(true);
            const res = await API.get(apiRoute.getBranchCollections, {
                params: {
                    branchCollectionType: source,
                },
            });

            if (res.status === 200) {
                setLoadingCollectionsNames(false);
                const { data } = res.data;

                const allCollections = data.map(
                    ({ branchCollectionId, branchCollectionName }) => ({
                        label: branchCollectionName,
                        value: branchCollectionId,
                    })
                );

                if (existingBranchCollections) {
                    const existingBranchCollectionIds =
                        existingBranchCollections?.map(
                            (existingBranchCollection) =>
                                existingBranchCollection?.branchCollectionId
                        );

                    const filteredCollections = allCollections?.filter(
                        (capsule) => {
                            return !existingBranchCollectionIds.includes(
                                capsule?.value
                            );
                        }
                    );

                    return setAllCollections(filteredCollections);
                }

                return setAllCollections(allCollections);
            }
        } catch (err) {
            setLoadingCollectionsNames(false);
            setServerError(getErrorMessage(err));
        }
    };

    useEffect(() => {
        retrieveBranchCollections();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setBranchCollectionValue({
            value: selectedBranchCollectionValue,
        });
    }, [selectedBranchCollectionValue]);

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                onChange={(value) => {
                    setBranchCollectionValue(value);
                    handleBranchCollectionNameChange?.(
                        ADD_EXISTING_BRANCH_COLLECTION,
                        value
                    );
                }}
                onCreateOption={(value) => {
                    setBranchCollectionValue({ value, label: value });
                    handleBranchCollectionNameChange?.(
                        CREATE_NEW_BRANCH_COLLECTION,
                        value
                    );
                }}
                label='Collection Name'
                options={allCollections}
                isLoading={isLoading}
                createAble={createAble}
                value={
                    createAble
                        ? actionType === ADD_EXISTING_BRANCH_COLLECTION
                            ? allCollections?.find((collection) => {
                                  return (
                                      collection?.value ===
                                      branchCollectionValue?.value
                                  );
                              })
                            : branchCollectionValue
                        : allCollections?.find((collection) => {
                              return (
                                  collection?.value ===
                                  selectedBranchCollectionValue
                              );
                          })
                }
                error={error}
                serverError={serverError}
                handleRetry={() => retrieveBranchCollections()}
                autoFocus={autoFocus}
                darkLabel={true}
            />
        </div>
    );
};

export default BranchCollectionNameInput;
