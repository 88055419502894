export const userStatusList = {
    ACTIVE: "active",
    VERIFICATION_PENDING: "verification__pending",
    DEACTIVATED: "deactivated",
};

export const TEAM_MEMBERS_HEADERS = [
    "Full Name",
    "Email Address",
    "Status",
    "Actions",
];

export const TEAM_MEMBERS_ACTIONS = {
    ADD_USER: "ADD_USER",
    CHANGE_USER_ROLE: "CHANGE_USER_ROLE",
    DEACTIVATE_USER: "DEACTIVATE_USER",
    RESEND_INVITE: "RESEND_INVITE",
    BLOCK_USER: "BLOCK_USER",
    REMOVE_USER: "REMOVE_USER",
};
