import React from "react";
import BranchImageAttachment from "./BranchImageAttachment/BranchImageAttachment";
import ConvoBreakerCheckbox from "components/dashboard/ServiceModule/ChatSettings/ConversationBreakers/form/EditConvoBreakerForm/ConvoBreakerCheckbox/ConvoBreakerCheckbox";
import "./BranchViewContent.scss";

const BranchViewContent = ({
    title = "Main sentence",
    content,
    branch,
    branchType,
    displayAverageResponseTime,
    requestRatings,
    showImageAttachment,
    handleRefresh,
}) => {
    return (
        <div className='branch__content'>
            <h6>{title}</h6>
            <p>{content}</p>
            {showImageAttachment && (
                <BranchImageAttachment
                    branch={branch}
                    handleRefresh={handleRefresh}
                />
            )}

            <ConvoBreakerCheckbox
                branchType={branchType}
                displayAverageResponseTime={displayAverageResponseTime}
                requestRatings={requestRatings}
                disableCheckbox={true}
            />
        </div>
    );
};

export default BranchViewContent;
