import "./CapsuleClassDetailsHeader.scss";

const CapsuleClassDetailsHeader = () => (
    <div className='capsule__class__details__header'>
        <h3 className='capsule__class__details__header--title'>
            Edit Attached Classes
        </h3>
        <p className='capsule__class__details__header--sub__title'>
            See and change how classes are organized
        </p>
    </div>
);

export default CapsuleClassDetailsHeader;
