import { useEffect, useState } from "react";
import UpdatesHeader from "../common/UpdatesHeader/UpdatesHeader";
import UpdatesTab from "../common/UpdatesTab/UpdatesTab";
import ResponseUpdatesTable from "./ResponseUpdatesTable/ResponseUpdatesTable";
import CannedUpdatesModal from "../common/modal/CannedUpdatesModal/CannedUpdatesModal";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import {
    CANNED_RESOURCE_ACTIONS,
    CANNED_RESOURCE_TYPES,
} from "../../CannedResponses/enum";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReplyUpdatesFilterOption, setReplyUpdatesFilterParams } from "store/filters/actions";
import {
    clearReplyUpdates,
    saveReplyUpdates,
} from "store/modules/agentAssistance/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const { CREATE_CANNED_RESOURCE, EDIT_CANNED_RESOURCE, VIEW_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const ResponseUpdates = () => {
    const [errorMssg, setErrorMssg] = useState();
    const [showModal, toggleModal] = useState(false);
    const [cannedUpdates, setCannedUpdates] = useState([]);

    const [selectedCannedUpdate, selectCannedUpdate] = useState();

    const [modalAction, setModalAction] = useState();
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const {
        replyUpdatesCache: { pages, totalRecords },
    } = useSelector((state) => state.agentAssistance);

    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.replyUpdatesFilters
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const agentUpdateType = CANNED_RESOURCE_TYPES?.CANNED_RESPONSE;

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);

        dispatch(setReplyUpdatesFilterParams({ currentPage: 1, search }));
        dispatch(clearReplyUpdates());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setReplyUpdatesFilterParams({ ...filterParams, currentPage }));
    };

    const getCannnedUpdates = async () => {
        try {
            setErrorMssg();
            useTableLoader && setTableIsLoading(true);
            if (cannedUpdates?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }
            const url = apiRoute.getCannedUpdates;
            const res = await API.get(url, {
                params: {
                    agentUpdateType,
                    ...filterParams,
                },
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setCannedUpdates(data);

                dispatch(
                    saveReplyUpdates(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleSuccess = () => {
        dispatch(setReplyUpdatesFilterParams({ currentPage: 1, search: "" }));
        dispatch(clearReplyUpdates());
        setClearSearch(true);
        getCannnedUpdates();
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setReplyUpdatesFilterParams(baseFilter));
        dispatch(setReplyUpdatesFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setReplyUpdatesFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setReplyUpdatesFilterOption({ ...option, value }));
        dispatch(clearReplyUpdates());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setReplyUpdatesFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setReplyUpdatesFilterOption({ ...option, value }));
        dispatch(clearReplyUpdates());
    };

    const handleSortByLastModified = (value, option) => {
        dispatch(
            setReplyUpdatesFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setReplyUpdatesFilterOption({ ...option, value }));
        dispatch(clearReplyUpdates());
    };

    const history = useHistory();

    const routeToViewUpdate = ({ agentUpdateId }) => {
        return history.push(`/t-sam/updates/response/view/${agentUpdateId}`);
    };

    const handleActionModal = (actionType, cannedResource) => {
        if (actionType === VIEW_CANNED_RESOURCE) {
            return routeToViewUpdate(cannedResource);
        }
        setModalAction(actionType);
        toggleModal(true);
        selectCannedUpdate(cannedResource);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCannnedUpdates}
                    />
                );

            case NULLMODE:
                return <Empty text={"No Canned Update Available Yet"} />;

            case DATAMODE:
                return (
                    <>
                        <ResponseUpdatesTable
                            handleSuccess={handleSuccess}
                            handleActionModal={handleActionModal}
                            modalAction={modalAction}
                            cannedUpdates={cannedUpdates}
                            tableIsLoading={tableIsLoading}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setCannedUpdates(cachedPage);
        } else {
            getCannnedUpdates();
        }
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const isEdit = modalAction === EDIT_CANNED_RESOURCE;

    return (
        <>
            <UpdatesTab />
            <div className='padded__layout'>
                <UpdatesHeader
                    title={"Response updates"}
                    subTitle={""}
                    btnText={"New update"}
                    placeholder={"Search updates here"}
                    handleClick={() =>
                        handleActionModal(CREATE_CANNED_RESOURCE)
                    }
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={filterParams?.search}
                    {
                        ...{
                            handleSortByAlpha,
                            handleSortByDate,
                            handleSortByLastModified,
                            baseFilter,
                            filterOption,
                            handleBasefilter
                        }
                    }
                />
                {renderBasedOnStatus()}
            </div>

            {showModal && (
                <CannedUpdatesModal
                    showModal={showModal}
                    title={
                        isEdit
                            ? "Modify Response Update "
                            : "New Response Update"
                    }
                    handleSuccess={handleSuccess}
                    closeModal={() => toggleModal(false)}
                    cannedResourceType={agentUpdateType}
                    modalAction={modalAction}
                    selectedCannedUpdate={selectedCannedUpdate}
                    isEdit={isEdit}
                />
            )}
        </>
    );
};

export default ResponseUpdates;
