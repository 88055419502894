import { Table } from "components/ui";

import { useEffect } from "react";
import { useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { FOLLOW_UP_TABLE_HEADER } from "../enum";
import AttachedFollowUp from "./AttachedFollowUp/AttachedFollowUp";
import FollowUpTableActions from "./FollowUpTableActions/FollowUpTableActions";

const { LOADING, DATAMODE, NULLMODE, ERROR, IDLE } = dataQueryStatus;

const FollowUpTable = ({
    handleActionModal,
    followUpCollections,
    capsuleClassEntryIds,
    updateCapsuleClassEntries,
    setErrorMssg,
    actionType,
    solutionCapsuleClass,
    selectedCapsuleEntry,
    showModal,
    setShowModal,
    tableLoading,
    refreshGuides,
}) => {
    const [status, setStatus] = useState(IDLE);

    const getFollowUpCollections = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url = apiRoute?.getFollowupGuides(
                capsuleClassEntryIds.join(",")
            );
            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;

                updateCapsuleClassEntries(data);

                data?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
            }
            // }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }

        // try {
        //     setErrorMssg();
        //     setStatus(LOADING);

        //     if (capsuleClassEntryIds?.length > 0) {
        //         const url = apiRoute?.getFollowUps(capsuleClassEntryIds);

        //         const res = await API.get(url);
        //         if (res.status === 200) {
        //             const { data } = res.data;

        //             updateCapsuleClassEntries(data);

        //             data?.length > 0
        //                 ? setStatus(DATAMODE)
        //                 : setStatus(NULLMODE);
        //         }
        //     }
        // } catch (err) {
        //     setErrorMssg(getErrorMessage(err));
        //     setStatus(ERROR);
        // }
    };

    const dataSource = followUpCollections?.map((capsuleEntry) => ({
        SolutionType: `${capsuleEntry?.content}`,
        AttachedFollowUp: (
            <AttachedFollowUp
                status={status}
                handleActionModal={handleActionModal}
                capsuleEntry={capsuleEntry}
            />
        ),

        Actions: (
            <FollowUpTableActions
                handleActionModal={handleActionModal}
                hasAttachedFollowUp={capsuleEntry?.attachedFollowUpId}
                capsuleEntry={capsuleEntry}
                solutionCapsuleClass={solutionCapsuleClass}
            />
        ),
    }));

    useEffect(() => {
        getFollowUpCollections();
        //eslint-disable-next-line
    }, [capsuleClassEntryIds, refreshGuides]);

    return (
        <Table
            headers={FOLLOW_UP_TABLE_HEADER}
            dataSource={dataSource}
            isLoading={tableLoading}
        />
    );
};

export default FollowUpTable;
