import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input, Textarea } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { generateID, getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import { SEND_AGENT_REPLY } from "services/socket/events";
import { saveTicketsMessages } from "store/tickets/actions";
import {
    appMessageUserTypes,
    messageTypes,
} from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_STATUSES } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/enum";
import { useSocket } from "services/socket/hook";

const { CANNED_SCENAIRO } = messageTypes;
const { WORKSPACE_AGENT } = appMessageUserTypes;
const { SENDING } = MESSAGE_STATUSES;

const ProposeScenarioForm = ({
    closeModal,
    recentCustomerMessage,
    handleSuccess,
    selectedAgentGuide,
}) => {
    const { activeTicket } = useSelector((state) => state.tickets);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [request, setRequest] = useState({
        guideScenairoName: "",
        guideScenairoMessage: "",
        guideScenairoQuestion: recentCustomerMessage?.messageContent,
        proposedTicketId: activeTicket?.ticketId,
        proposedMessageId: recentCustomerMessage?.messageId || generateID(),
        status: "PROPOSED",
    });
    const socket = useSocket();

    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const sendScenarioToChat = async (messageContentId) => {
        await socket.emit(SEND_AGENT_REPLY, {
            ticketId: activeTicket?.ticketId,
            message: request?.guideScenairoMessage,
            messageType: CANNED_SCENAIRO,
            messageContentId,
        });

        dispatch(
            saveTicketsMessages({
                ticketId: activeTicket?.ticketId,
                senderType: WORKSPACE_AGENT,
                messageContent: request?.guideScenairoMessage,
                messageContentId: messageContentId,
                messageType: CANNED_SCENAIRO,
                messageId: generateID(),
                status: SENDING,

                // messageType: DEFAULT,
            })
        );
    };

    const proposeScenario = async (sendToChat) => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.proposeScenario(
                selectedAgentGuide?.currentStep?.guideFlow?.guideFlowId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message, data } = res.data;
                if (sendToChat) {
                    await sendScenarioToChat(data?.guideScenairoId);
                }
                toastMessage(message);
                closeModal();
                handleSuccess?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            proposeScenario();
        } else setErrors(formErrors);
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='guideScenairoName'
                label='Title'
                labelClass='text-dark fw-medium'
                id='guideScenairoName'
                placeholder='Include a title here'
                value={request?.guideScenairoName}
                onChange={handleChange}
                isErr={errors?.guideScenairoName}
                errMssg={errors?.guideScenairoName}
                autoFocus={true}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='guideScenairoQuestion'
                label='Question'
                labelClass='text-dark fw-medium'
                id='guideScenairoQuestion'
                placeholder='Include a question here'
                value={request?.guideScenairoQuestion}
                onChange={handleChange}
                isErr={errors?.guideScenairoQuestion}
                errMssg={errors?.guideScenairoQuestion}
                disabled={recentCustomerMessage?.messageContent?.length > 0}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='guideScenairoMessage'
                label='Answer'
                labelClass='text-dark fw-medium'
                id='guideScenairoMessage'
                placeholder='Include an answer here'
                value={request?.guideScenairoMessage}
                onChange={handleChange}
                isErr={errors?.guideScenairoMessage}
                errMssg={errors?.guideScenairoMessage}
            />
            <Button
                type='submit'
                text='Propose'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
            <AddButton
                btnText={"Send to Chat"}
                otherClassName='send_to_chat'
                hasIcon={false}
                onClick={() => proposeScenario(true)}
            />
        </form>
    );
};

export default ProposeScenarioForm;
