import React from "react";
import { Table } from "components/ui";
import ConnectedForms from "./ConnectedForms/ConnectedForms";
import PostFormCollectionActionDropdown from "./PostFormCollectionActionDropdown/PostFormCollectionActionDropdown";

const PostFormCollectionTable = ({
    postFormCollections,
    tableIsLoading,
    errorMssg,
    handleActionModal,
}) => {
    const tableData = postFormCollections?.map((postFormCollection) => ({
        name: postFormCollection?.branchCollectionName,
        connectedForms: <ConnectedForms forms={postFormCollection?.forms} />,
        actions: (
            <PostFormCollectionActionDropdown
                postFormCollection={postFormCollection}
                handleActionModal={handleActionModal}
            />
        ),
    }));

    return (
        <Table
            headers={["Name", "Forms connected", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
            hasErrors={Boolean(errorMssg)}
            tableError={errorMssg}
        />
    );
};

export default PostFormCollectionTable;
