import SelectUI from "components/ui/SelectUI/SelectUI";
import { ANALYTICS_QA_DURATIONS, ANALYTICS_QA_TYPES } from "../../../enum";

const AnalyticsQAReportTFilter = () => {
    return (
        <div className='analytics-qa-report-table__filter'>
            <SelectUI
                grpClass='mb-3'
                name=''
                label=''
                labelClass='text-dark fw-medium'
                id=''
                placeholder='Choose duration'
                // isErr={formErrors?.industry}
                // errMssg={formErrors?.industry}
                // value={ANALYTICS?.find((industry) => {
                //     return industry.value === request.industry;
                // })}
                options={ANALYTICS_QA_DURATIONS}
                // handleChange={(name, value) =>
                //     handleChange({ target: { name, value } })
                // }
            />
            <SelectUI
                grpClass='mb-3'
                name='types'
                label=''
                labelClass='text-dark fw-medium'
                id=''
                placeholder='Select type'
                // isErr={formErrors?.industry}
                // errMssg={formErrors?.industry}
                // value={ANALYTICS?.find((industry) => {
                //     return industry.value === request.industry;
                // })}
                options={ANALYTICS_QA_TYPES}
                // handleChange={(name, value) =>
                //     handleChange({ target: { name, value } })
                // }
            />
        </div>
    );
};

export default AnalyticsQAReportTFilter;
