import { ReactSVG } from "react-svg";
import asset from "assets/images";
import TicketInfoClipContent from "../../TicketInfoDetails/TicketInfoClipboard/TicketInfoClipContent/TicketInfoClipContent";
import TicketEmailAttachment from "components/dashboard/AgentInbox/AgentInboxTickets/EmailPreview/EmailAttachment/TicketEmailAttachment/TicketEmailAttachment";
import FileEmailAttachment from "components/dashboard/AgentInbox/AgentInboxTickets/EmailPreview/EmailAttachment/FileEmailAttachment/FileEmailAttachment";
import "./ExternalEmailClipboard.scss";

const ExternalEmailClipboard = ({
    handleToggleEmailAttachment,
    showClipboard,
    toggleEmailClipboard,
    attachments,
    emails
}) => {

    const extEmailAttachments = (emails?.map((email) => (email.fileAttachments)))?.flat();

    return (
        <section className='externalemail-clipboard'>
            <div className='externalemail-clipboard__heading'>
                <span>Clipboard and attachments</span>
                <ReactSVG
                    src={!showClipboard ? asset.icons.upside : asset.icons.downside}
                    onClick={() => toggleEmailClipboard(!showClipboard)}
                />
            </div>

            {showClipboard &&
                <>
                    <TicketEmailAttachment {
                        ...{
                            handleToggleEmailAttachment
                        }
                    } />
                    <TicketInfoClipContent
                        fileAttachments={attachments?.fileAttachments}
                        extEmailAttachments={extEmailAttachments}
                        handleToggleEmailAttachment={handleToggleEmailAttachment}
                    />
                    <FileEmailAttachment
                        fileAttachments={attachments?.fileAttachments}
                        handleToggleEmailAttachment={handleToggleEmailAttachment}
                        handleCancel={() => toggleEmailClipboard(!showClipboard)}
                    />
                </>
            }
        </section>
    );
};

export default ExternalEmailClipboard;
