export const SAVE_GUIDES = "SAVE_GUIDES";
export const CLEAR_GUIDES = "CLEAR_GUIDES";

export const SAVE_SCENARIOS = "SAVE_SCENARIOS";
export const CLEAR_SCENARIOS = "CLEAR_SCENARIOS";

export const SAVE_EMAIL_UPDATES = "SAVE_EMAIL_UPDATES";
export const CLEAR_EMAIL_UPDATES = "CLEAR_EMAIL_UPDATES";

export const SAVE_REPLY_UPDATES = "SAVE_REPLY_UPDATES";
export const CLEAR_REPLY_UPDATES = "CLEAR_REPLY_UPDATES";

export const SAVE_MENTION_UPDATES = "SAVE_MENTION_UPDATES";
export const CLEAR_MENTION_UPDATES = "CLEAR_MENTION_UPDATES";

export const SAVE_CANNED_MENTIONS = "SAVE_CANNED_MENTIONS";
export const CLEAR_CANNED_MENTIONS = "CLEAR_CANNED_MENTIONS";

export const SAVE_CANNED_REPLIES = "SAVE_CANNED_REPLIES";
export const CLEAR_CANNED_REPLIES = "CLEAR_CANNED_REPLIES";

export const SAVE_CANNED_EMAILS = "SAVE_CANNED_EMAILS";
export const CLEAR_CANNED_EMAILS = "CLEAR_CANNED_EMAILS";

