import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import FileUploadWrapperLoader from "./FileUploadWrapperLoader/FileUploadWrapperLoader";

const FileUploadWrapper = ({
    file,
    setLoading,
    setError,
    setUploadedFileKey,
    useLoader,
    loaderClass,
    children,
}) => {
    const { name } = file || {};
    const [isUploading, setIsUploading] = useState(false);

    const generateUploadLink = async () => {
        try {
            setLoading?.(true);
            setError?.();
            setIsUploading(true);
            const url = apiRoute.generateUploadLink;

            const res = await API.put(
                url,
                {
                    file: name,
                },
                {}
            );

            const data = res.data;
            if (res.status === 200) {
                const { fileKey, link } = data?.data || {};
                uploadFile(link, fileKey);
            }
        } catch (err) {
            setLoading?.(false);
            setError?.(err);
            setIsUploading(false);
        }
    };

    const uploadFile = async (url, fileKey) => {
        try {
            setLoading?.(true);
            setIsUploading(true);
            const options = {
                disableAuthorization: true,
            };

            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                const binaryData = reader.result;
                const res = await API.put(`${url}`, binaryData, options);

                if (res.status === 200) {
                    setUploadedFileKey?.(fileKey);
                    setLoading?.(false);
                    setError?.();
                    setIsUploading(false);
                }
            };
        } catch (err) {
            setError?.(err);
            setLoading?.(false);
            setIsUploading(false);
        }
    };

    useEffect(() => {
        if (name) {
            generateUploadLink();
        }
        // eslint-disable-next-line
    }, [name]);

    return (
        <>
            {useLoader ? (
                <FileUploadWrapperLoader
                    {...{ isUploading, loaderClass, children }}
                />
            ) : (
                children
            )}
        </>
    );
};

export default FileUploadWrapper;
