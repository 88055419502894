import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import ProductSetupForm from "../../../ProductSetupView/ProductSetupForm/ProductSetupForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const AddProductModal = ({
    showModal,
    closeModal,
    handleSuccess,
    handleProductSelect,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title='Add Product'
                        subTitle='Create a new product'
                    />
                    <ProductSetupForm
                        handleSuccess={(product) => {
                            handleProductSelect({
                                ...product,
                                value: product?.productId,
                            });
                            handleSuccess();
                            closeModal();
                        }}
                    />
                </div>
            </div>
        </SideModal>
    );
};

AddProductModal.prototypes = {
    getProductsList: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

export default AddProductModal;
