import { Input, Textarea } from "components/ui";

const NewScenarioInput = ({ handleChange, errors, request }) => {
    return (
        <>
            <Input
                grpClass='mb-3'
                type='text'
                name='guideScenairoName'
                label='Scenario Name'
                labelClass='text-dark fw-medium'
                id='guideScenairoName'
                placeholder='Title'
                value={request?.guideScenairoName}
                onChange={handleChange}
                isErr={errors?.guideScenairoName}
                errMssg={errors?.guideScenairoName}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='guideScenairoMessage'
                label=''
                labelClass='text-dark fw-medium'
                id='guideScenairoMessage'
                placeholder='Message'
                value={request?.guideScenairoMessage}
                onChange={handleChange}
                isErr={errors?.guideScenairoMessage}
                errMssg={
                    errors?.guideScenairoMessage
                        ? "Scenario Message is required"
                        : ""
                }
            />
        </>
    );
};
export default NewScenarioInput;
