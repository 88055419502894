import * as types from "./types";

const initialState = {
    classifications: [],
    teamsCache: {
        pages: {},
        totalRecords: 0
    },
    conversationTemplates: {
        upTimeTemplate: null,
        downTimeTemplate: null,
    },
    messagingTagsCache: {
        selectedMssgTagId: "",
        messagingTags: [],
        messageTagDetailsList: {}
    }
};

const AgentInboxReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_CLASSIFICATIONS:
            return { ...state, classifications: payload };
        case types.SAVE_TEAMS:
            return {
                ...state,
                teamsCache: {
                    ...state.teamsCache,
                    pages: {
                        ...state.teamsCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords
                }
            };
        case types.CLEAR_TEAMS:
            return {
                ...state,
                teamsCache: {
                    ...state.teamsCache,
                    pages: {},
                    totalRecords: 0
                },
            };

        case types.SAVE_CONVERSATION_TEMPLATES:
            return {
                ...state,
                conversationTemplates: {
                    ...payload
                },
            };

        case types.SET_SELECTED_MSSG_TAG:
            return {
                ...state,
                messagingTagsCache: { ...state.messagingTagsCache, selectedMssgTagId: payload }
            };
        case types.SAVE_MESSAGING_TAGS:
            return {
                ...state,
                messagingTagsCache: {
                    ...state.messagingTagsCache,
                    messagingTags: payload
                }
            };
        case types.SAVE_MESSAGE_TAG_DETAILS:
            return {
                ...state,
                messagingTagsCache: {
                    ...state.messagingTagsCache,
                    messageTagDetailsList: {
                        ...state.messagingTagsCache.messageTagDetailsList,
                        [payload.mssgTagId]: payload.data,
                    },
                }
            };
     
        case types.REMOVE_MESSAGE_TAG_DETAILS:
            return {
                ...state,
                messagingTagsCache: {
                    ...state.messagingTagsCache,
                    messageTagDetailsList: {
                        ...payload
                    }
                }
            };


        default:
            return state;
    }
};

export default AgentInboxReducer;
