import React, { useRef, useState, useCallback } from "react";
import CropImage from "components/ui/CropImage/CropImage";
import { MOBILE } from "../ImageCropHeader/enums";

const ImageCrop = ({
    imgSrc,
    zoom,
    setZoom,
    aspect,
    cropMode,
    minZoom,
    maxZoom,
    updateRequest,
    setImages,
}) => {
    const cropImageRef = useRef();
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            if (cropMode === MOBILE) {
                updateRequest?.((prev) => ({
                    ...prev,
                    branchMainSentenceImgUrlConfig: {
                        ...prev.branchMainSentenceImgUrlConfig,
                        mobileVersion: {
                            ...croppedAreaPixels,
                        },
                    },
                }));
                setImages?.((prev) => ({
                    ...prev,
                    imgUrlConfig: {
                        ...prev.imgUrlConfig,
                        mobileVersion: {
                            ...croppedAreaPixels,
                        },
                    },
                }));
            } else {
                updateRequest?.((prev) => ({
                    ...prev,
                    branchMainSentenceImgUrlConfig: {
                        ...prev.branchMainSentenceImgUrlConfig,
                        desktopVersion: {
                            ...croppedAreaPixels,
                        },
                    },
                }));

                setImages?.((prev) => ({
                    ...prev,
                    imgUrlConfig: {
                        ...prev.imgUrlConfig,
                        desktopVersion: {
                            ...croppedAreaPixels,
                        },
                    },
                }));
            }
        },
        //eslint-disable-next-line
        [crop, zoom, cropMode]
    );

    return (
        <div>
            <CropImage
                crop={crop}
                setCrop={setCrop}
                setCompletedCrop={onCropComplete}
                imgSrc={imgSrc}
                aspect={aspect}
                zoom={zoom}
                setZoom={setZoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                ref={cropImageRef}
            />
        </div>
    );
};

export default ImageCrop;
