import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { MESSAGE_TAG_ACTIONS } from "../../../enum";
import { Tag } from "components/ui";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const CannedResponseActionDropdown = ({
    cannedResponse,
    handleActionModal,
}) => {
    const [dropdownOpen, toggleDropdown] = useState(false);

    const options = [
        {
            value: MESSAGE_TAG_ACTIONS.EDIT,
            label: "Edit",
            baseResources: [`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
        {
            value: MESSAGE_TAG_ACTIONS.DELETE,
            label: "Delete",
            baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
    ];

    return (
        <>
            <Dropdown
                isOpen={dropdownOpen}
                direction='up'
                toggle={() => toggleDropdown((prevState) => !prevState)}>
                <Tag
                    name={cannedResponse?.title}
                    onClick={() => toggleDropdown((prevState) => !prevState)}
                />
                <DropdownMenu>
                    {options?.map((item, i) => (
                        <ComponentAccessControl
                            baseResources={item?.baseResources}
                            key={i}>
                            <DropdownItem
                                onClick={() =>
                                    handleActionModal(
                                        item.value,
                                        cannedResponse
                                    )
                                }>
                                {item?.label}
                            </DropdownItem>
                        </ComponentAccessControl>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default CannedResponseActionDropdown;
