import { useState } from "react";
import AnalyticsQADropHeader from "../../../common/AnalyticsQADropHeader/AnalyticsQADropHeader";

const AnalyticsQATVariationItem = ({ title, variations }) => {
    const [isOpen, toggleOpen] = useState(false);

    return (
        <div className='analytics-qat-variations__item'>
            <AnalyticsQADropHeader
                title={title}
                toggleOpen={() => toggleOpen(!isOpen)}
                isOpen={isOpen}
            />
            <div className='analytics-qat-variations__variations'>
                {variations?.map((item, key) => (
                    <span
                        key={key}
                        className={`${isOpen && key === 0 ? "active" : ""}`}>
                        {item}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default AnalyticsQATVariationItem;
