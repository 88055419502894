import ProductSetupContainer from "../ProductSetupContainer/ProductSetupContainer"

const SolutionSetup = () => {
	return (
		<>
			<ProductSetupContainer isSolutionView={true} />
		</>
	)
};

export default SolutionSetup