import React from "react";
import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";
import ProductClassesHeader from "./ProductClassesHeader/ProductClassesHeader";
import ProductClassesList from "./ProductClassesList/ProductClassesList";
import EmptyCapsuleClass from "components/dashboard/KnowledgeBase/common/EmptyCapsuleClass/EmptyCapsuleClass";
import ClassesContainer from "./ClassesContainer/ClassesContainer";

const { LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const ProductClasses = ({
    status,
    errorMssg,
    selectedProduct,
    capsuleClasses,
    handleMainCapsuleSelect,
    selectedCapsuleClass,
    capsuleRenderCount,
    handleRetry,
}) => {
    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <CapsuleSkeletonLoader count={7} />;

            case DATAMODE:
                return (
                    <ProductClassesList
                        productId={selectedProduct?.value}
                        capsuleClasses={capsuleClasses}
                        handleMainCapsuleSelect={handleMainCapsuleSelect}
                        selectedCapsuleClass={selectedCapsuleClass}
                        capsuleRenderCount={capsuleRenderCount}
                    />
                );

            case NULLMODE:
                return <EmptyCapsuleClass />;

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={handleRetry} />
                );

            default:
                return "";
        }
    };
    return (
        <>
            <ProductClassesHeader
                status={status}
                title='classes'
                numberOfClasses={
                    status !== DATAMODE ? "-" : capsuleClasses?.length
                }
            />
            <ClassesContainer>{renderBasedOnStatus()}</ClassesContainer>
        </>
    );
};

export default ProductClasses;
