import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { copyToClipboard, generateConversationLink } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import { ANALYZER_ACTIONS, MESSAGE_TAG_RESPONSE_TYPE } from "../enum";
import AnalyzerButton from "./AnalyzerButton/AnalyzerButton";
import AnalyzerReset from "./AnalyzerReset/AnalyzerReset";
import AnalyzerSearchInput from "./AnalyzerSearchInput/AnalyzerSearchInput";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { READ } = baseResourceActions;
const { CANNED_RESPONSE } = MESSAGE_TAG_RESPONSE_TYPE;

const AnalyzerSearch = ({
    getMessageTags,
    loading,
    selectedTagResp,
    showReset,
    search,
    setSearch,
}) => {
    const [request, setRequest] = useState({
        search: "",
    });

    const [analyzerAction, setAnalyzerAction] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [isResponseCopied, setIsResponseCopied] = useState(false);

    const workspace = useSelector((state) => state?.auth?.user?.workspace);

    const isCannedResponse = selectedTagResp?.tagRespType === CANNED_RESPONSE;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (analyzerAction === ANALYZER_ACTIONS.ANALYZE_MESSAGE) {
            const { formisValid, errors } = ValidateForm(e, request);
            formisValid ? getMessageTags() : setFormErrors(errors);
        } else {
            isCannedResponse
                ? copyToClipboard(selectedTagResp?.response)
                : copyToClipboard(
                      generateConversationLink(
                          workspace,
                          selectedTagResp?.conversationId
                      )
                  );

            setIsResponseCopied(true);
        }
    };

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setFormErrors((prevError) => ({ ...prevError, [name]: "" }));
        setSearch(value);
    };

    useEffect(() => {
        if (selectedTagResp) {
            setAnalyzerAction(ANALYZER_ACTIONS.COPY_RESPONSE);
        } else {
            setAnalyzerAction(ANALYZER_ACTIONS.ANALYZE_MESSAGE);
        }

        setIsResponseCopied(false);
    }, [selectedTagResp]);

    useEffect(() => {
        setRequest((prevRequest) => ({ ...prevRequest, search }));
    }, [search]);

    const handleReset = () => {
        getMessageTags();
    };

    const handleEnterKeyPress = (e) => {
        if (e?.keyCode === 13 && e?.shiftKey === false) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <div className='analyzer-query'>
                <form onSubmit={handleSubmit}>
                    <AnalyzerSearchInput
                        handleChange={handleChange}
                        handleEnterKeyPress={handleEnterKeyPress}
                        errors={formErrors}
                        request={request}
                        value={selectedTagResp?.response}
                    />
                    {showReset && <AnalyzerReset handleReset={handleReset} />}
                    <ComponentAccessControl
                        baseResources={[
                            `${READ}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <AnalyzerButton
                            loading={loading}
                            isResponseCopied={isResponseCopied}
                            isACopyAction={
                                analyzerAction ===
                                ANALYZER_ACTIONS.ANALYZE_MESSAGE
                                    ? false
                                    : true
                            }
                            isCannedResponse={isCannedResponse}
                        />
                    </ComponentAccessControl>
                </form>
            </div>
        </>
    );
};

export default AnalyzerSearch;
