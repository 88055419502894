import BuilderElementBadge from "./BuilderElementBadge/BuilderElementBadge";
import "./BuilderElementContent.scss";

const BuilderElementContent = ({ item }) => (
    <div className='each__builder'>
        <div className='col-2'>
            <BuilderElementBadge badge={item?.badge} />
        </div>
        <div className='col-10'>
            <div className='builder__text'>
                <h3 className='builder__text--title'>{item?.title}</h3>
                <p className='builder__text--sub__title'>{item?.body}</p>
            </div>
        </div>
    </div>
);

export default BuilderElementContent;
