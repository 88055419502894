import React from "react";
import "./Loading.scss";

export const Loading = ({ title, paragraph }) => {
	return (
		<div className="w-100 h-100 d-flex justify-content-center align-items-center loading__section">
			<div className="d-flex flex-column align-items-center">
				<div className="dots-container">
					<div className="loading-dots"></div>
				</div>
				<h1 className="text-center text-dark">{title}</h1>
				<p className="text-center text-dark">{paragraph}</p>
			</div>
		</div>
	);
};
