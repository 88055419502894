import React from "react";
import DeletableInput from "../../../common/DeletableInput/DeletableInput";

const FormBuilderInputSubtitle = ({
    elementId,
    errors,
    handleFormInputChange,
    handleSubTextStatusChange,
    subText,
}) => {
    return (
        <DeletableInput
            name='subText'
            badgeText='Subtitle'
            error={errors?.[elementId]?.subText}
            placeholder={`Enter Placeholder text`}
            handleInputChange={(name, value) =>
                handleFormInputChange(elementId, name, value)
            }
            value={subText}
            handleInputDelete={() =>
                handleSubTextStatusChange(elementId, false)
            }
        />
    );
};

export default FormBuilderInputSubtitle;
