import AnalyticsQATabs from "../../common/AnalytcisQATabs/AnalyticsQATabs";
import AnalyticsQAVAgentsConversations from "../AnalyticsQAVAgentsConversations/AnalyticsQAVAgentsConversations";

const AnalyticsQAVetAgentsTicket = ({ match }) => {
    const { params } = match;
    const ticketId = params?.[0];
    return (
        <>
            <AnalyticsQATabs />
            <AnalyticsQAVAgentsConversations ticketId={ticketId} />
        </>
    );
};

export default AnalyticsQAVetAgentsTicket;
