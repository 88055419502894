import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
// import { TASK_REMINDER_ACTIONS } from "../../../../../enum";
import { baseResources, baseResourceActions } from "routes/enum";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import "./TaskReminderDropdown.scss";
import { SCHEDULE_TASK_ACTIONS } from "../enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;
// const { EDIT_REMINDER, DELETE_REMINDER } = TASK_REMINDER_ACTIONS;

const TaskReminderDropdown = ({ handleTaskReminderAction }) => {
    const [dropdownOpen, toggleDropdown] = useState(false);
    return (
        <Dropdown
            isOpen={dropdownOpen}
            className={`added__collection`}
            id='task__reminder__dropdown'
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <ReactSVG
                src={imageLinks?.icons?.carbonMore}
                onClick={() => toggleDropdown((prevState) => !prevState)}
                className='task__reminder__dropdown__icon'
            />
            <DropdownMenu className='task__reminder__dropdown__menu'>
                <ComponentAccessControl
                    baseResources={[`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <DropdownItem
                        onClick={() =>
                            handleTaskReminderAction(
                                SCHEDULE_TASK_ACTIONS.EDIT_SCHEDULE
                            )
                        }>
                        Edit
                    </DropdownItem>
                </ComponentAccessControl>
                <ComponentAccessControl
                    baseResources={[`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <DropdownItem
                        onClick={() =>
                            handleTaskReminderAction(
                                SCHEDULE_TASK_ACTIONS.DELETE_SCHEDULE
                            )
                        }>
                        Delete
                    </DropdownItem>
                </ComponentAccessControl>
            </DropdownMenu>
        </Dropdown>
    );
};

export default TaskReminderDropdown;
