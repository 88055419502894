import { Button } from "components/ui";
import "./BranchCannedElement.scss";
import { BRANCH_CANNED_ACTIONS, CANNED_RESOURCE_ROUTE_NAMES } from "../enum";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import CannedResourceModal from "components/dashboard/TrainSAM/CannedResponses/common/modal/CannedResourceModal/CannedResourceModal";
import { pipeEnums } from "utils/helper";
import { CANNED_RESOURCE_ACTIONS } from "components/dashboard/TrainSAM/CannedResponses/enum";

const { ADD, EDIT } = BRANCH_CANNED_ACTIONS;

const cannedMessageColors = {
    CANNED_EMAIL: "#fc5a5a",
    CANNED_RESPONSE: "#0066F5",
    MENTIONS: "#25BB87",
};

const cannedMessageNames = {
    CANNED_EMAIL: "Canned email",
    CANNED_RESPONSE: "Canned response",
    MENTIONS: "Canned mention",
};

const BranchCannedElement = ({
    cannedResourceSubject,
    cannedResourceBody,
    receipients,
    cannedResourceType,
    handleActionModal,
    cannedResourceName,
    cannedResourceId,
    agentUpdates,
    handleDetachModal,
    handleSuccess
}) => {
    const findCannedAgentUpdate = agentUpdates?.find(
        ({ agentUpdateType }) => agentUpdateType === cannedResourceType
    );

    const history = useHistory();
    const [showModal, toggleModal] = useState(false);


    const routeToUpdatePage = () => {
        const { agentUpdateId, agentUpdateType } = findCannedAgentUpdate || {};
        if (agentUpdateId && agentUpdateType) {
            return history.push(
                `/t-sam/updates/${CANNED_RESOURCE_ROUTE_NAMES?.[agentUpdateType]}/view/${agentUpdateId}`
            );
        }
    };

    return (
        <>
            <div
                className='branch-canned-element'
                style={{ borderColor: cannedMessageColors[cannedResourceType] }}>
                <div className='branch-canned-element__heading'>
                    <h6>{cannedMessageNames[cannedResourceType]}</h6>
                    <Button
                        classType={"plain"}
                        text={cannedResourceName ? "Edit" : "Add"}
                        onClick={() =>
                            handleActionModal(
                                cannedResourceType,
                                cannedResourceName ? EDIT : ADD,
                                cannedResourceId,
                                false
                            )
                        }
                    />
                </div>

                {cannedResourceName ? (
                    <div
                        className='branch-canned-element__list'
                        onClick={() => toggleModal(true)}
                    >
                        <p>{cannedResourceName}</p>
                    </div>
                ) : (
                    <p>Branch has not canned replies</p>
                )}

                <div className="d-flex">
                    <Button
                        otherClass='branch-canned-element__update-btn'
                        onClick={() =>
                            handleActionModal(
                                cannedResourceType,
                                findCannedAgentUpdate ? EDIT : ADD,
                                cannedResourceId,
                                true,
                                findCannedAgentUpdate
                            )
                        }>
                        {findCannedAgentUpdate ? "Edit update" : "Add update"}
                    </Button>
                    {
                        findCannedAgentUpdate && <Button
                            otherClass='ms-2 branch-canned-element__update-btn'
                            onClick={routeToUpdatePage}>
                            View update
                        </Button>
                    }
                </div>
                {cannedResourceId && (
                    <div className='branch-canned-element__thrash'>
                        <ReactSVG
                            src={asset.icons.trashIcon}
                            onClick={() => handleDetachModal(cannedResourceId)}
                        />
                    </div>
                )}
            </div>

            {showModal && (
                <CannedResourceModal
                    title={`Update ${pipeEnums(cannedResourceType)}`}
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    cannedResourceType={cannedResourceType}
                    toggleModal={toggleModal}
                    modalAction={CANNED_RESOURCE_ACTIONS.EDIT_CANNED_RESOURCE}
                    selectedCannedResource={{
                        cannedResourceName,
                        cannedResourceId,
                        cannedResourceType,
                        cannedResourceSubject,
                        cannedResourceBody,
                        receipients
                    }}
                    handleSuccess={() => {
                        toggleModal(false)
                        handleSuccess()
                    }}
                />
            )}
        </>
    );
};

export default BranchCannedElement;
