import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TicketStatus from "../../common/TicketStatus/TicketStatus";
import ChatSettingsToggler from "./ChatSettingsToggler/ChatSettingsToggler";
import HeaderInfo from "./HeaderInfo/HeaderInfo";
import { TICKETS_HEADER_ACTIONS } from "./enum";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import {
    updateIncomingTicket,
    deleteIncomingTicket,
} from "store/tickets/incomingTickets/actions";
import { setActiveTicket } from "store/tickets/actions";
import { selectNextTicket } from "../../CustomerTicketsContainer/CustomerTickets/util/helper";
import "./TicketsHeader.scss";

const { CLOSE_TICKET } = TICKETS_HEADER_ACTIONS;

const TicketsHeader = ({
    selectedTicket,
    toggleTicketActionModal,
    handleSuccess,
    hideActions,
}) => {
    const { customer, ticketId, ticketPhase } = selectedTicket || {};
    const { email, appMessageUserId, userId, firstName } = customer || {};
    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const resolveTicket = async () => {
        try {
            const url = apiRoute.resolveTicket(ticketId);
            const res = await API.put(url);

            if (res.status === 200) {
                const { message } = res?.data;

                dispatch(
                    updateIncomingTicket({ ...selectedTicket, assigned: false })
                );

                selectNextTicket(
                    selectedTicket,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                        ticketId &&
                            dispatch(deleteIncomingTicket({ ticketId }));
                    }
                );
                toastMessage(message);
                handleSuccess?.();
            }
        } catch (err) {
            const message = getErrorMessage(err);
            toastMessage(message);
        }
    };

    const handleActionModal = (action) => {
        if (action === CLOSE_TICKET) {
            toggleTicketActionModal?.(selectedTicket);
        } else {
            resolveTicket();
        }
    };

    return (
        <div id='ticketsHeader' className='tickets__header'>
            <div className='header__rows'>
                <div className='user__names'>
                    {firstName && (
                        <span className='user__name'>{firstName}</span>
                    )}
                    <h6>{email || appMessageUserId || userId}</h6>
                </div>
                <HeaderInfo otherClassName={"ms-auto"}>
                    <TicketStatus {...{ ticketId, ticketPhase }} />
                </HeaderInfo>
                {!hideActions && (
                    <ChatSettingsToggler
                        handleActionModal={handleActionModal}
                        ticketPhase={ticketPhase}
                    />
                )}
            </div>
        </div>
    );
};

export default TicketsHeader;
