import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services/apiRoutes";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CapsuleClassView from "./CapsuleClassView/CapsuleClassView";
import { dataQueryStatus } from "utils";
import { Loading } from "components/ui";
import { getErrorMessage } from "utils/helper";
import CapsuleSettingsHeader from "./CapsuleSettingsHeader/CapsuleSettingsHeader";
import { saveCapsuleClassess } from "store/capsule/actions";
import "./CapsuleSettings.scss";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleSettings = () => {
    const [capsuleViewStatus, setCapsuleViewStatus] = useState(IDLE);
    const [capsuleViewErrorMsg, setCapsuleViewErrorMsg] = useState();
    const [contentTypes, updateContentTypes] = useState([]);
    const [detailsKey, updateDetailsKey] = useState(0);
    const [isEdited, toggleEdited] = useState(false);
    const [selectedCapsuleClass, selectCapsuleClass] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [status, setStatus] = useState(
        capsuleClasses?.length > 0 ? DATAMODE : LOADING
    );

    const dispatch = useDispatch();

    const getCapsuleClasses = async (httpRequest) => {
        try {
            setErrorMssg();

            if (capsuleClasses?.length < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.getCapsuleClasses;

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;
                dispatch(saveCapsuleClassess(data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const handleSelectCapsuleClass = async (capsule, skipInitSelect) => {
        try {
            !skipInitSelect && selectCapsuleClass(capsule);
            setCapsuleViewStatus(LOADING);

            const url = apiRoute.getCapsuleClass(capsule.capsuleClassId);
            const res = await API.get(url);
            if (res.status === 200) {
                const { data } = res.data;
                selectCapsuleClass(data);
                setCapsuleViewStatus(DATAMODE);
            }
        } catch (err) {
            setCapsuleViewErrorMsg(getErrorMessage(err));
            setCapsuleViewStatus(ERROR);
        }
    };

    const handleEditAction = (actionType, requestData) => {
        const acceptedFormatsList = [...requestData.entries()]
            .filter(([_, cond]) => cond)
            .map(([key]) => key);
        toggleEdited(true);
        updateContentTypes(acceptedFormatsList);
    };

    // this function will go ON to handle success most accurately for the entire page
    const handleCapsulationSuccess = async (capsule) => {
        await toggleEdited(false);
        await getCapsuleClasses();
        await handleSelectCapsuleClass(capsule, true);
        updateDetailsKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getCapsuleClasses(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <CapsuleClassView
                        detailsKey={detailsKey}
                        status={capsuleViewStatus}
                        errorMsg={capsuleViewErrorMsg}
                        toggleEdited={toggleEdited}
                        selectedCapsuleClass={selectedCapsuleClass}
                        selectCapsuleClass={handleSelectCapsuleClass}
                        handleEditAction={handleEditAction}
                        handleCapsulationSuccess={handleCapsulationSuccess}
                        capsuleClasses={capsuleClasses}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='meta__container'>
            <div className='capsule__settings'>
                <CapsuleSettingsHeader
                    isEdited={isEdited}
                    status={status}
                    setStatus={setStatus}
                    toggleEdited={toggleEdited}
                    selectedCapsule={selectedCapsuleClass}
                    contentTypes={contentTypes}
                    handleCapsulationSuccess={handleCapsulationSuccess}
                />
                <div className='class__view'>{renderCompBasedOnStage()}</div>
            </div>
        </div>
    );
};

export default CapsuleSettings;
