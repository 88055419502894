import React, { useState } from "react";
import PropTypes from "prop-types";
import MessageTagActionModals from "../MessageTagActionModal/MessageTagActionModal";
import MessageTagActionDropdown from "../MessageTagActionDropdown/MessageTagActionDropdown";
import ItemsList from "components/ui/ItemsList/ItemsList";

const MessageTagList = ({
    setSelectedMssgTagId,
    messagingTagsList,
    selectedMssgTagId,
    handleSuccess,
    selectedMssgTagDetails,
    setSelectedMssgTagDetails,
}) => {
    const [currentAction, setCurrentAction] = useState("");

    const handleActionModal = (action, tag) => {
        setCurrentAction(action);
    };

    const closeActionModal = () => {
        setCurrentAction("");
    };

    return (
        <>
            <ItemsList breakPoint={11} otherClassNames='mb-3'>
                {messagingTagsList?.map((tag, i) => (
                    <MessageTagActionDropdown
                        tag={tag}
                        key={i}
                        handleActionModal={handleActionModal}
                        selectedMssgTagId={selectedMssgTagId}
                        setSelectedMssgTagId={setSelectedMssgTagId}
                        setSelectedMssgTagDetails={setSelectedMssgTagDetails}
                    />
                ))}
            </ItemsList>

            {currentAction && (
                <MessageTagActionModals
                    tag={selectedMssgTagDetails}
                    currentAction={currentAction}
                    closeModal={closeActionModal}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

MessageTagList.prototypes = {
    messagingTagsList: PropTypes.array.isRequired,
    selectedMssgTagId: PropTypes.string.isRequired,
    setSelectedMssgTagId: PropTypes.func.isRequired,
};

export default MessageTagList;
