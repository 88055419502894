import React from "react";
import "./TeamMemberEmail.scss";

const TeamMemberEmail = ({ email }) => {
    return (
        <div className='email'>
            <span className='email--text'>{email}</span>
        </div>
    );
};

export default TeamMemberEmail;
