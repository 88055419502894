import React, { useState } from "react";
import TicketsPFCollection from "./TicketsPFCollection/TicketsPFCollection";
import ConfirmPFCollectionSend from "./ConfirmPFCollectionSend/ConfirmPFCollectionSend";
import "./TicketsPFCollectionsListing.scss";

const TicketsPFCollectionsListing = ({ data, searchValue, selectedTicket }) => {
    const [showModal, toggleModal] = useState(false);
    const [pFCollection, setPFCollection] = useState({});

    const handleModalToggle = (pFCollection) => {
        toggleModal(true);
        setPFCollection(pFCollection);
    };

    return (
        <>
            <div className='pf__branches'>
                {data
                    ?.filter((branchCollection) =>
                        branchCollection?.branchCollectionName
                            ?.toLocaleLowerCase()
                            ?.includes(searchValue.toLocaleLowerCase())
                    )
                    ?.map(
                        (
                            { branchCollectionName, branchCollectionId },
                            index
                        ) => {
                            return (
                                <TicketsPFCollection
                                    key={index}
                                    branchCollectionName={branchCollectionName}
                                    sendCollectionToTicket={() =>
                                        handleModalToggle({
                                            branchCollectionName,
                                            branchCollectionId,
                                        })
                                    }
                                />
                            );
                        }
                    )}
            </div>
            {showModal && (
                <ConfirmPFCollectionSend
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    pFCollection={pFCollection}
                    selectedTicket={selectedTicket}
                />
            )}
        </>
    );
};

export default TicketsPFCollectionsListing;
