import React, { useState } from "react";
import AttachCapsuleClassModal from "../AttachCapsuleClassModal/AttachCapsuleClassModal";
import AddButton from "components/ui/Button/AddButton/AddButton";
import "./AttachCapsuleClassButton.scss";

const AttachCapsuleClassButton = ({
    selectedCapsule,
    handleCapsulationSuccess,
}) => {
    const [showModal, toggleModal] = useState(false);

    return (
        <>
            <AddButton
                btnText='Attach Class'
                otherClassName='attach__capsule__class--btn'
                onClick={() => toggleModal(true)}
            />
            {showModal && (
                <AttachCapsuleClassModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleCapsulationSuccess={handleCapsulationSuccess}
                    selectedCapsule={selectedCapsule}
                />
            )}
        </>
    );
};

export default AttachCapsuleClassButton;
