import React from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { postFormActionTypes } from "../../enums";
import { baseResources, baseResourceActions } from "routes/enum";
import { Dropdown } from "components/ui";

const { KB_FORM_SETTING } = baseResources;
const { CREATE } = baseResourceActions;
const { ADD_POST_FORM_BRANCH, ADD_CANNED_RESPONSE } = postFormActionTypes;

const FormsPFBranchesActionDropdownAddButton = ({
    referenceData,
    handleRowAction,
}) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <AddButton
                    btnText={"Add Item"}
                    otherClassName='justify-content-end me-1'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleRowAction(value, referenceData)}
            options={[
                {
                    label: "Add Collection",
                    value: ADD_POST_FORM_BRANCH,
                    baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                },
                {
                    label: "Add Canned Response",
                    value: ADD_CANNED_RESPONSE,
                    baseResources: [`${CREATE}_${KB_FORM_SETTING}`],
                },
            ]}
        />
    );
};

export default FormsPFBranchesActionDropdownAddButton;
