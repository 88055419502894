import SelectUI from "components/ui/SelectUI/SelectUI";

const FilterLastModifiedSort = ({ handleSortByLastModified, filterOption }) => {
    return (
        <>
            <div className='filter__select'>
                <SelectUI
                    name='filterByLastModified'
                    id='filterByLastModified'
                    placeholder='Last Modified'
                    hasOptionIcon={true}
                    options={[
                        { label: "Most recent", value: "desc", icon: "radio" },
                        {
                            label: "Least recent",
                            value: "asc",
                            icon: "radio",
                        },
                    ]}
                    defaultValue={filterOption}
                    onChange={({ value, ...option }) =>
                        handleSortByLastModified?.(value, option)
                    }
                />
            </div>
        </>
    );
};

export default FilterLastModifiedSort;
