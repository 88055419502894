import { ReactSVG } from "react-svg";
import asset from "assets/images";

const NavMenu = ({ collapsed, toggleSidebar }) => {
    return (
        <>
            {collapsed ? (
                <div className='collapse-toggler d-flex justify-content-center align-items-center'>
                    <span className='cursor-pointer' onClick={toggleSidebar}>
                        <ReactSVG
                            src={asset?.icons?.harmburger}
                            className='d-inline-flex'
                        />
                    </span>
                </div>
            ) : null}
        </>
    );
};

export default NavMenu;
