import PreHeading from "components/ui/PreHeading/PreHeading";

const AgentAViewGuideHeader = () => {
    return (
        <PreHeading
            url='/t-sam/agent-assistance'
            text='Guides'
            subText={"View guide"}
        />
    );
};

export default AgentAViewGuideHeader;
