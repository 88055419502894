import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import SelectUI from "components/ui/SelectUI/SelectUI";

import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setFormsList } from "store/cache/forms/actions";

const FormsSelect = ({
    name = "branchOptionValue",
    selectedValue,
    handleSelect,
    error,
    filterIds,
}) => {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [allForms, setAllForms] = useState([]);
    const { forms } = useSelector((state) => state.forms);

    const dispatch = useDispatch();

    const updateForms = () => {
        const allForms = forms?.map((form) => ({
            label: form.formName,
            value: form.formId,
        }));

        setAllForms(allForms);
    };

    const getForms = async () => {
        try {
            setServerError(false);
            setLoading(true);

            const url = apiRoute?.getForms;
            const res = await API.get(url, {
                params: { all: true },
            });

            if (res.status === 200) {
                const { data } = res?.data;
                setLoading(false);

                // const filteredFormList = allForms.filter((branch) => {
                //     return !filterIds?.includes(branch?.value);
                // });

                dispatch(setFormsList(data));
            }
        } catch (err) {
            setLoading(false);
            setServerError(getErrorMessage(err));
            return err;
        }
    };

    useEffect(() => {
        getForms();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (forms?.length > 0) {
            updateForms();
        }
        //eslint-disable-next-line
    }, [forms]);

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                placeholder='Select a Form'
                isLoading={loading}
                label='Form'
                options={allForms}
                error={error ? "Form is required" : ""}
                value={allForms?.find((form) => {
                    return form?.value === selectedValue;
                })}
                onChange={handleSelect}
                serverError={serverError}
                handleRetry={() => getForms()}
            />
        </div>
    );
};

export default FormsSelect;
