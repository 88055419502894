import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { FOLLOW_UP_TASK_ACTIONS } from "../../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_COLLECTION } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;
const { EDIT_FOLLOW_UP_TASK, DELETE_FOLLOW_UP_TASK } = FOLLOW_UP_TASK_ACTIONS;

const FollowUpTasksViewActions = ({ handleActionModal, followUpTask }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal?.(value, followUpTask)}
            options={[
                {
                    value: EDIT_FOLLOW_UP_TASK,
                    label: "Edit",
                    baseResources: [`${UPDATE}_${KB_SAM_COLLECTION}`],
                },
                {
                    value: DELETE_FOLLOW_UP_TASK,
                    label: "Delete",
                    baseResources: [`${DELETE}_${KB_SAM_COLLECTION}`],
                },
            ]}
        />
    );
};

export default FollowUpTasksViewActions;
