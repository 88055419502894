import imageLinks from "assets/images";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import { CANNED_RESPONSES_ACTIONS } from "../../enums";
import { baseResourceActions, baseResources } from "routes/enum";

const { KB_FORM_SETTING } = baseResources;
const { READ, DELETE } = baseResourceActions;

const { DELETE_CANNED_RESPONSE, VIEW_CANNED_RESPONSE, EDIT_CANNED_RESPONSE } =
    CANNED_RESPONSES_ACTIONS;

const CRTableDropdown = ({ handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={imageLinks?.icons?.carbonMore}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value)}
            options={[
                {
                    label: "View",
                    value: VIEW_CANNED_RESPONSE,
                    baseResources: [`${READ}_${KB_FORM_SETTING}`],
                },
                {
                    label: "Edit",
                    value: EDIT_CANNED_RESPONSE,
                    baseResources: [`${READ}_${KB_FORM_SETTING}`],
                },
                {
                    label: "Delete",
                    value: DELETE_CANNED_RESPONSE,
                    baseResources: [`${DELETE}_${KB_FORM_SETTING}`],
                },
            ]}
        />
    );
};

export default CRTableDropdown;
