import React from "react";
import { MESSAGE_TAG_ACTIONS } from "../enum";
import CreateMessageTagModal from "../CreateMessageTagHeader/CreateMessageTagModal/CreateMessageTagModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { EDIT, DELETE } = MESSAGE_TAG_ACTIONS;

const MessageTagActionModals = ({
    tag,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT:
                return (
                    <CreateMessageTagModal
                        tag={tag}
                        showModal={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        currentAction={currentAction}
                    />
                );

            case DELETE:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${tag?.messagingTagName}`}
                        show={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute.getMessageTag(tag?.messagingTagId)}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default MessageTagActionModals;
