// import { useRef, useEffect, useState } from "react";
// import { ReactSVG } from "react-svg";
// import ScrollArea from "react-scrollbar";
import "./HorizontalScroll.scss";
// import asset from "assets/images";

export const HorizontalScroll = ({ children, className, childrenClassName }) => {
	// const [left, setLeft] = useState(false);
	// const [right, setRight] = useState(false);
	// const scrollContainer = useRef();

	// const scrollRight = () => {
	// 	scrollContainer.current.scrollLeft += 100;
	// 	setLeft(true);
	// 	const width = scrollContainer?.current?.offsetWidth;
	// 	const scrollPosition =
	// 		scrollContainer?.current?.scrollLeft +
	// 		scrollContainer?.current?.lastElementChild?.clientWidth -
	// 		10;
	// 	if (scrollPosition >= width) {
	// 		setRight(false);
	// 	}
	// };

	// const scrollLeft = () => {
	// 	scrollContainer.current.scrollLeft -= 100;
	// 	setRight(true);
	// 	const scrollPosition = scrollContainer?.current?.scrollLeft;
	// 	if (scrollPosition === 0) {
	// 		setLeft(false);
	// 	}
	// };

	// useEffect(() => {
	// 	const rightSide =
	// 		scrollContainer?.current?.lastElementChild?.offsetLeft +
	// 		scrollContainer?.current?.lastElementChild?.clientWidth;
	// 	const width = scrollContainer?.current?.offsetWidth;

	// 	if (rightSide > width) {
	// 		setRight(true);
	// 	} else {
	// 		setRight(false);
	// 	}
	// }, [children]);

	return (
		<div
			className={`d-flex position-relative ${className || ""
				}`}
		>
			{/* {left && (
				<div
					className="horizontal__scroll--control"
					onClick={scrollLeft}>
					<ReactSVG src={asset?.icons?.anchorRight} />
				</div>
			)} */}
			<div
				// speed={0.8}
				className="horizontal__scroll"
			// contentClassName="content"
			// horizontal={true}
			>
				{children}
			</div>
			{/* <div
				className={` ${childrenClassName || ""
					}`}
				ref={scrollContainer}
			>
			</div> */}
			{/* {right && (
				<div
					className="horizontal__scroll--control"
					onClick={scrollRight}
				>
					<ReactSVG src={asset?.icons?.arrowRight}  />
				</div>
			)} */}
		</div>
	);
};
