import React from "react";
import BranchImageCropModal from "./BranchImageCropModal/BranchImageCropModal";

const BranchImageCrop = ({
    showModal,
    toggleModal,
    branchImageAction,
    referenceData,
    handleRefresh,
    handleOpenDeleteModal,
}) => {
    return (
        <>
            <BranchImageCropModal
                show={showModal}
                close={toggleModal}
                branchImageAction={branchImageAction}
                referenceData={referenceData}
                handleRefresh={handleRefresh}
                handleOpenDeleteModal={handleOpenDeleteModal}
            />
        </>
    );
};

export default BranchImageCrop;
