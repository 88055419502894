import PersonalProfile from "components/dashboard/Admin/PersonalProfile/PersonalProfile";
import RolesAndPermissions from "components/dashboard/Admin/RolesAndPermissions/RolesAndPermissions";
import StartupProfile from "components/dashboard/Admin/StartupProfile/StartupProfile";
import UserManagement from "components/dashboard/Admin/UserManagement/UserManagement";
import ConversationsTemplates from "components/dashboard/AgentInbox/AgentInboxSettings/ConversationsTemplates/ConversationsTemplates";
import MessageTagging from "components/dashboard/AgentInbox/AgentInboxSettings/MessageTagging/MessageTagging";
import Routing from "components/dashboard/AgentInbox/AgentInboxSettings/Routing/Routing";
import CapsuleSettings from "components/dashboard/KnowledgeBase/CapsuleSettings/CapsuleSettings";
import ProductCapsuleView from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductCapsuleView/ProductCapsuleView";
import SingleProductView from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/SingleProductView/SingleProductView";
import ProductSetupContainer from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductSetupContainer";
import Appearance from "components/dashboard/ServiceModule/ChatSettings/Appearance/Appearance";
import Discovery from "components/dashboard/TrainSAM/SAM/Discovery/Discovery";
import Issues from "components/dashboard/TrainSAM/SAM/Issues/Issues";
import AppIntegrations from "components/dashboard/AgentInbox/AgentInboxSettings/AppIntegrations/AppIntegrations";
import ViewTeam from "components/dashboard/AgentInbox/AgentInboxSettings/Routing/Teams/ViewTeam/ViewTeam";
import IssuesConversations from "components/dashboard/TrainSAM/SAM/Conversations/IssuesConversations/IssuesConversations";
import ConversationView from "components/dashboard/TrainSAM/SAM/Conversations/ConversationView/ConversationView";
import Downtime from "components/dashboard/TrainSAM/Dependency/Downtime/Downtime";
import SolutionSetupContainer from "components/dashboard/KnowledgeBase/SolutionSetupContainer/SolutionSetupContainer";
import ViewSwitches from "components/dashboard/TrainSAM/Dependency/Switches/ViewSwitches/ViewSwitches";
import Switches from "components/dashboard/TrainSAM/Dependency/Switches/Switches";
import DependencyCapsules from "components/dashboard/TrainSAM/Dependency/DependencyCapsules/DependencyCapsules";
import DependencyCapsulesView from "components/dashboard/TrainSAM/Dependency/DependencyCapsules/DependencyCapsulesView/DependencyCapsulesView";
import FormSetup from "components/dashboard/KnowledgeBase/FormSetup/FormSetup";
import FormsPostFormBranchesList from "components/dashboard/KnowledgeBase/FormsPostFormBranchesList/FormsPostFormBranchesList";
import BranchCollectionView from "components/dashboard/TrainSAM/SAM/BranchCollectionView/BranchCollectionView";
import ViewForm from "components/dashboard/KnowledgeBase/ViewForm/ViewForm";
import DowntimeCapsuleBranchView from "components/dashboard/TrainSAM/SAM/DowntimeCapsuleBranchView/DowntimeCapsuleBranchView";
import GeneralDowntimeCollection from "components/dashboard/TrainSAM/Dependency/GeneralDowntimeCollection/GeneralDowntimeCollection";
import ConcludingBranchView from "components/dashboard/KnowledgeBase/form/ConcludingBranchView/ConcludingBranchView";
import InitialBranch from "components/dashboard/ServiceModule/ChatSettings/InitialBranch/InitialBranch";
import AgentInboxTickets from "components/dashboard/AgentInbox/AgentInboxTickets/AgentInboxTickets";
import Analyzer from "components/dashboard/Analyzer/Anaylzer";
import Personas from "components/dashboard/ServiceModule/ChatSettings/Personas/Personas";
import CreatePersona from "components/dashboard/ServiceModule/ChatSettings/Personas/CreatePersona/CreatePersona";
import AgentFollowUp from "components/dashboard/AgentInbox/AgentFollowUp/AgentFollowUp";
import FollowUpTasksView from "components/dashboard/AgentInbox/AgentFollowUp/FollowUpTasksView/FollowUpTasksView";
import ConversationBreakers from "components/dashboard/ServiceModule/ChatSettings/ConversationBreakers/ConversationBreakers";
import AnalyticsTeamEfficiency from "components/dashboard/Analytics/AnalyticsTeams/AnalyticsTeamEfficiency/AnalyticsTeamEfficiency";
import AnalyticsTeamVolume from "components/dashboard/Analytics/AnalyticsTeams/AnalyticsTeamVolume/AnalyticsTeamVolume";
import AnalyticsTeamCustomer from "components/dashboard/Analytics/AnalyticsTeams/AnalyticsTeamCustomer/AnalyticsTeamCustomer";
import AnalyticsTeamBacklog from "components/dashboard/Analytics/AnalyticsTeams/AnalyticsTeamBacklog/AnalyticsTeamBacklog";
import Reminders from "components/dashboard/ServiceModule/ChatSettings/Reminders/Reminders";
import AnalyticsTSamConversations from "components/dashboard/Analytics/AnalyticsTSam/AnalyticsTSamConversations/AnalyticsTSamConversations";
import AnalyticsTSamCapsules from "components/dashboard/Analytics/AnalyticsTSam/AnalyticsTSamCapsules/AnalyticsTSamCapsules";
import AnalyticsTSamConvoView from "components/dashboard/Analytics/AnalyticsTSam/AnalyticsTSamConversations/AnalyticsTSamConvoView/AnalyticsTSamConvoView";
import PostFormCollections from "components/dashboard/KnowledgeBase/PostFormCollections/PostFormCollections";
import { baseResources, baseResourceActions } from "./enum";
import CannedResponses from "components/dashboard/KnowledgeBase/CannedResponses/CannedResponses";
import AnalyticsQAVetTicket from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQAVetTicket/AnalyticsQAVetTicket";
import AnalyticsQAVetAgents from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQAVetAgents/AnalyticsQAVetAgents";
import AnalyticsQAVetAgentsTicket from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQAVetAgents/AnalyticsQAVetAgentsTicket/AnalyticsQAVetAgentsTicket";
import AgentAGuides from "components/dashboard/TrainSAM/Guides/AgentAGuides";
import AgentAViewGuide from "components/dashboard/TrainSAM/Guides/AgentAViewGuide/AgentAViewGuide";
import Scenarios from "components/dashboard/TrainSAM/Scenarios/Scenarios";
import CannedEmailResponses from "components/dashboard/TrainSAM/CannedResponses/CannedEmailResponses/CannedEmailResponses";
import CannedRepliesResponses from "components/dashboard/TrainSAM/CannedResponses/CannedRepliesResponses/CannedRepliesResponses";
import CannedMentionsResponses from "components/dashboard/TrainSAM/CannedResponses/CannedMentionsResponses/CannedMentionsResponses";
import EmailUpdates from "components/dashboard/TrainSAM/Updates/EmailUpdates/EmailUpdates";
import ResponseUpdates from "components/dashboard/TrainSAM/Updates/ResponseUpdates/ResponseUpdates";
import MentionUpdates from "components/dashboard/TrainSAM/Updates/MentionUpdates/MentionUpdates";
import EmailFollowups from "components/dashboard/TrainSAM/Updates/EmailUpdates/EmailFollowups/EmailFollowups";
import ResponseFollowups from "components/dashboard/TrainSAM/Updates/ResponseUpdates/ResponseFollowups/ResponseFollowups";
import MentionFollowups from "components/dashboard/TrainSAM/Updates/MentionUpdates/MentionFollowups/MentionFollowups";
import GuideDowntime from "components/dashboard/TrainSAM/GuideDowntime/GuideDowntime";
import ViewGuideDowntime from "components/dashboard/TrainSAM/GuideDowntime/ViewGuideDowntime/ViewGuideDowntime";
import AnalyticsQAReport from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQAReport/AnalyticsQAReport";
import AnalyticsQAProgress from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQAProgress/AnalyticsQAProgress";
import AnalyticsQATimeline from "components/dashboard/Analytics/AnalyticsQA/AnalyticsQATimeline/AnalyticsQATimeline";
import QaDocumentation from "components/dashboard/TrainSAM/SAM/QaDocumentation/QaDocumentation";
import ChatSamLLM from "components/dashboard/TrainSAM/SAM/ChatSamLLM/ChatSamLLM";

const {
    USER_MANAGEMENT_ROLES,
    PROFILE,
    USER_MANAGEMENT_USERS,
    KB_CAPSULE_SETTINGS,
    KB_PRODUCT_SETTING,
    KB_FORM_SETTING,
    KB_SAM_COLLECTION,
    KB_SAM_ISSUE_CONVERSATION,
    KB_SAM_BRANCH,
    KB_DEPENDENCY,
    AGENTS_INBOX_BASE_SETTING,
    AGENTS_INBOX_SERVICE_SETTING,
    AGENTS_INBOX_SERVICE_INTEGRATION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
} = baseResources;

const { READ } = baseResourceActions;

const DefaultView = ({ title }) => (
    <div className='container my-5 mx-4'>
        <h4>{title}</h4>
    </div>
);

const dashboardRoutes = [
    {
        iconName: "people",
        title: "Admin",
        path: "/admin/user-management",
        component: DefaultView,
        accessLevel: [],
        groupAccessLevel: [],
        tabbedRoutes: [
            {
                title: "User Management",
                path: "/admin/user-management",
                component: UserManagement,
                baseResources: [`${READ}_${USER_MANAGEMENT_USERS}`],
            },
            {
                title: "Personal Profile",
                path: "/admin/personal-profile",
                component: PersonalProfile,
                baseResources: [`${READ}_${PROFILE}`],
            },
            {
                title: "Roles & Permission",
                path: "/admin/roles-permission",
                component: RolesAndPermissions,
                baseResources: [`${READ}_${USER_MANAGEMENT_ROLES}`],
            },
            {
                title: "Start-up Profile",
                path: "/admin/workspace",
                component: StartupProfile,
                baseResources: [`${READ}_${PROFILE}`],
            },
        ],
    },
    {
        title: "Knowledge Base",
        path: "/knowledge-base/capsule-settings",
        component: CapsuleSettings,
        iconName: "round-check",
        dropDowns: [
            {
                title: "Capsule Settings",
                path: "/knowledge-base/capsule-settings",
                hide: false,
                component: CapsuleSettings,
                baseResources: [`${READ}_${KB_CAPSULE_SETTINGS}`],
            },
            {
                title: "Product Setup",
                path: "/knowledge-base/product",
                hide: false,
                component: ProductSetupContainer,
                baseResources: [`${READ}_${KB_PRODUCT_SETTING}`],
            },
            {
                title: "Product Setup",
                path: "/knowledge-base/product/:productId/capsules",
                component: SingleProductView,
                isExact: false,
                hide: true,
                baseResources: [`${READ}_${KB_PRODUCT_SETTING}`],
            },
            {
                title: "Product Setup",
                path: "/knowledge-base/capsule/:productId/:capsuleId",
                hide: true,
                component: ProductCapsuleView,
                baseResources: [`${READ}_${KB_PRODUCT_SETTING}`],
            },
            {
                title: "Solution Setup",
                path: "/knowledge-base/solution-setup",
                hide: false,
                component: SolutionSetupContainer,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Forms",
                path: "/knowledge-base/forms",
                component: FormsPostFormBranchesList,
                baseResources: [`${READ}_${KB_FORM_SETTING}`],
            },
            {
                title: "Concluding Form Branch",
                path: "/knowledge-base/form/concluding-branch",
                component: ConcludingBranchView,
                hide: true,
                baseResources: [
                    `${READ}_${KB_FORM_SETTING}`,
                    `${READ}_${KB_SAM_BRANCH}`,
                ],
            },
            {
                title: "Create New Form",
                path: "/knowledge-base/form/new",
                component: FormSetup,
                hide: true,
                baseResources: [`${READ}_${KB_FORM_SETTING}`],
            },
            {
                title: "View Form",
                path: "/knowledge-base/form/:formId",
                component: ViewForm,
                hide: true,
                baseResources: [`${READ}_${KB_FORM_SETTING}`],
            },
            {
                title: "Post Forms",
                path: "/knowledge-base/post-form",
                component: PostFormCollections,
                hide: false,
                baseResources: [
                    `${READ}_${KB_FORM_SETTING}`,
                    `${READ}_${KB_SAM_COLLECTION}`,
                ],
            },
            {
                title: "Canned Responses",
                path: "/knowledge-base/canned-responses",
                hide: false,
                component: CannedResponses,
                baseResources: [`${READ}_${KB_CAPSULE_SETTINGS}`],
            },
        ],
    },
    {
        iconName: "agent-inbox",
        title: "Agent Inbox",
        path: "/agents-inbox/settings/tickets-routing",
        component: Routing,
        tabbedRoutes: [
            {
                title: "Routing",
                path: "/agents-inbox/settings/tickets-routing",
                component: Routing,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Conversations",
                path: "/agents-inbox/settings/conversations",
                component: ConversationsTemplates,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Message Tagging",
                path: "/agents-inbox/settings/message-tagging",
                component: MessageTagging,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Integrations",
                path: "/agents-inbox/settings/integrations",
                component: AppIntegrations,
                baseResources: [`${READ}_${AGENTS_INBOX_SERVICE_INTEGRATION}`],
            },
        ],
        dropDowns: [
            {
                title: "Setting",
                path: "/agents-inbox/settings/tickets-routing",
                hide: false,
                component: Routing,
                baseResources: [
                    `${READ}_${AGENTS_INBOX_BASE_SETTING}`,
                    `${READ}_${AGENTS_INBOX_SERVICE_INTEGRATION}`,
                ],
            },
            {
                title: "Inbox",
                path: "/agents-inbox/tickets",
                hide: false,
                component: AgentInboxTickets,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Agent Inbox",
                path: "/agents-inbox/settings/teams/:teamId",
                hide: true,
                component: ViewTeam,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Follow Up",
                path: "/agents-inbox/follow-up/:followUpId",
                hide: true,
                component: FollowUpTasksView,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },

            {
                title: "Follow Up",
                path: "/agents-inbox/follow-up",
                component: AgentFollowUp,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
        ],
    },
    {
        title: "Training SAM",
        path: "/t-sam/issues",
        iconName: "train-sam",
        tabbedRoutes: [
            {
                title: "Issues",
                path: "/t-sam/issues",
                component: Issues,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            },
            {
                title: "Discovery",
                path: "/t-sam/discovery",
                component: Discovery,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            },
            {
                title: "Conversations",
                path: "/t-sam/conversations",
                isExact: true,
                component: IssuesConversations,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            },
        ],
        dropDowns: [
            {
                title: "SAM",
                path: "/t-sam/issues",
                hide: false,
                component: Issues,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            },
            {
                title: "Downtime Capsule Collection View",
                path: "/t-sam/downtime-collection/:capsuleClassId/view/:capsuleEntryId",
                component: DowntimeCapsuleBranchView,
                hide: true,
                baseResources: [
                    `${READ}_${KB_SAM_COLLECTION}`,
                    `${READ}_${KB_SAM_ISSUE_CONVERSATION}`,
                ],
            },
            {
                title: "Branch Collection View",
                path: "/t-sam/branch-collection/:branchCollectionId/view",
                component: BranchCollectionView,
                hide: true,
                baseResources: [
                    `${READ}_${KB_SAM_COLLECTION}`,
                    `${READ}_${KB_SAM_BRANCH}`,
                ],
            },
            {
                title: "View Conversation",
                path: "/t-sam/conversations/:conversationId/view-conversation",
                hide: true,
                component: ConversationView,
                baseResources: [
                    `${READ}_${KB_SAM_ISSUE_CONVERSATION}`,
                    `${READ}_${KB_SAM_BRANCH}`,
                ],
            },

            {
                title: "Agent Assistance",
                path: "/t-sam/agent-assistance",
                component: AgentAGuides,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Agent Assistance",
                path: "/t-sam/guide/view/*",
                component: AgentAViewGuide,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Scenarios",
                path: "/t-sam/scenarios",
                component: Scenarios,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },

            {
                title: "Updates",
                path: "/t-sam/updates/email/view/*",
                component: EmailFollowups,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Updates",
                path: "/t-sam/updates/email",
                component: EmailUpdates,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Updates",
                path: "/t-sam/updates/response/view/*",
                component: ResponseFollowups,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Updates",
                path: "/t-sam/updates/response",
                component: ResponseUpdates,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },

            {
                title: "Updates",
                path: "/t-sam/updates/mention/view/*",
                component: MentionFollowups,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },

            {
                title: "Updates",
                path: "/t-sam/updates/mention",
                component: MentionUpdates,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },

            {
                title: "Responses",
                path: "/t-sam/responses/emails",
                component: CannedEmailResponses,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Responses",
                path: "/t-sam/responses/replies",
                component: CannedRepliesResponses,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "Responses",
                path: "/t-sam/responses/mentions",
                component: CannedMentionsResponses,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "View Guide Downtime",
                path: "/t-sam/guide-downtime/view/*/*",
                component: ViewGuideDowntime,
                hide: true,
                baseResources: [`${READ}_${KB_SAM_COLLECTION}`],
            },
            {
                title: "LLM Documentation",
                path: "/t-sam/llm-documentation",
                component: QaDocumentation,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            },
            {
                title: "LLM Chat",
                path: "/t-sam/llm-chat",
                component: ChatSamLLM,
                baseResources: [`${READ}_${KB_SAM_ISSUE_CONVERSATION}`],
            }
        ],
    },
    {
        title: "Dependency",
        path: "/dependency/switches",
        iconName: "dependency",
        component: Switches,
        tabbedRoutes: [
            {
                title: "Switches",
                path: "/dependency/switches",
                component: Switches,
                baseResources: [`${READ}_${KB_DEPENDENCY}`],
            },

            {
                title: "Downtime branches",
                path: "/dependency/downtime-branches",
                component: Downtime,
                baseResources: [`${READ}_${KB_SAM_BRANCH}`],
            },
            {
                title: "Downtime Guides",
                path: "/dependency/downtime-guides",
                component: GuideDowntime,
                baseResources: [`${READ}_${KB_SAM_BRANCH}`],
            },
            {
                title: "Capsules",
                path: "/dependency/capsules",
                component: DependencyCapsules,
                baseResources: [`${READ}_${KB_DEPENDENCY}`],
            },
            {
                title: "General Downtime Collections",
                path: "/dependency/general-downtime-collection",
                component: GeneralDowntimeCollection,
                baseResources: [`${READ}_${KB_SAM_BRANCH}`],
            },
        ],
        dropDowns: [
            {
                title: "View Switches",
                path: "/dependency/view-switches/:switchType",
                component: ViewSwitches,
                hide: true,
                baseResources: [`${READ}_${KB_DEPENDENCY}`],
            },
            {
                title: "View Capsules",
                path: "/dependency/view-capsules/:capsuleClassId/:capsuleType",
                component: DependencyCapsulesView,
                hide: true,
                baseResources: [`${READ}_${KB_DEPENDENCY}`],
            },
        ],
    },
    // {
    //     title: "Help Center",
    //     path: "/help-center",
    //     iconName: "help-center",
    //     component: Other,
    // },
    {
        title: "Service Module",
        path: "/service-module/appearance",
        iconName: "service-module",
        tabbedRoutes: [
            {
                title: "Appearance",
                path: "/service-module/appearance",
                component: Appearance,
                baseResources: [`${READ}_${AGENTS_INBOX_SERVICE_SETTING}`],
            },
            {
                title: "Initial Branch",
                path: "/service-module/initial-branch",
                component: InitialBranch,
                baseResources: [`${READ}_${KB_SAM_BRANCH}`],
            },
            {
                title: "Personas",
                path: "/service-module/personas",
                component: Personas,
                baseResources: [`${READ}_${AGENTS_INBOX_SERVICE_SETTING}`],
            },
            {
                title: "Conversation Breakers",
                path: "/service-module/conversation-breakers",
                component: ConversationBreakers,
                baseResources: [
                    `${READ}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                ],
            },
            {
                title: "Reminders",
                path: "/service-module/reminders",
                component: Reminders,
                baseResources: [
                    `${READ}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                ],
            },
        ],
        dropDowns: [
            {
                title: "New Persona",
                path: "/service-module/:actionType/persona",
                component: CreatePersona,
                hide: true,
                baseResources: [`${READ}_${AGENTS_INBOX_SERVICE_SETTING}`],
            },
        ],
    },
    {
        title: "Analyzer",
        path: "/analyzer",
        iconName: "help-center",
        component: Analyzer,
        baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
    },
    {
        iconName: "analytics",
        title: "Analytics",
        path: "/analytics/teams/efficiency",
        component: AnalyticsTeamEfficiency,
        tabbedRoutes: [
            {
                title: "Efficiency",
                path: "/analytics/teams/efficiency",
                component: AnalyticsTeamEfficiency,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Volume",
                path: "/analytics/teams/volume",
                component: AnalyticsTeamVolume,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Customer Satisfaction",
                path: "/analytics/teams/customer-satisfaction",
                component: AnalyticsTeamCustomer,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Backlog",
                path: "/analytics/teams/backlog",
                component: AnalyticsTeamBacklog,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
        ],
        dropDowns: [
            {
                title: "Teams",
                path: "/analytics/teams/efficiency",
                hide: false,
                component: AnalyticsTeamEfficiency,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Conversation Analytics",
                path: "/analytics/t-sam/conversation/:conversationId/activity",
                hide: true,
                isExact: false,
                component: AnalyticsTSamConvoView,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Training SAM",
                path: "/analytics/t-sam/conversations",
                hide: false,
                component: AnalyticsTSamConversations,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Capsules",
                path: "/analytics/t-sam/capsules",
                hide: true,
                component: AnalyticsTSamCapsules,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "QA",
                path: "/analytics/qa/vet-tickets",
                hide: false,
                component: AnalyticsQAVetTicket,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Vet Agents",
                path: "/analytics/qa/vet-agents/ticket/*",
                hide: true,
                component: AnalyticsQAVetAgentsTicket,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Vet Agents",
                path: "/analytics/qa/vet-agents/*",
                hide: true,
                component: AnalyticsQAVetAgents,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "QA report",
                path: "/analytics/qa/report",
                hide: true,
                component: AnalyticsQAReport,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Quality timeline",
                path: "/analytics/qa/progress/*",
                component: AnalyticsQATimeline,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },
            {
                title: "Quality progress",
                path: "/analytics/qa/progress",
                component: AnalyticsQAProgress,
                baseResources: [`${READ}_${AGENTS_INBOX_BASE_SETTING}`],
            },

            // {
            //     title: "Knowledge Base",
            //     path: "/analytics/knowledge-base",
            //     component: "",
            //     hide: false,
            // },
            // {
            //     title: "Training SAM",
            //     path: "/analytics/train-sam",
            //     component: "",
            //     hide: false,
            // },
            // {
            //     title: "Help Center",
            //     path: "/analytics/help-center",
            //     component: "",
            //     hide: false,
            // },
        ],
    },
];

export default dashboardRoutes;
