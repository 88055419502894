import React from "react";

const CapsuleSkeletonLoader = ({ count, otherClass }) => {
    return (
        <>
            {Array.from(Array(count)).map((_, i) => (
                <div
                    className={`shiny__line capsule ${otherClass || ""} `}
                    key={i}></div>
            ))}
        </>
    );
};

export default CapsuleSkeletonLoader;
