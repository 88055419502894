import * as types from "./types";

const initialState = {
    vetTicketPinnedCache: {
        pages: {},
        totalRecords: 0,
    },
    vetTicketUnPinnedCache: {
        pages: {},
        totalRecords: 0,
    },
};

const VetTicketReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_VET_TICKET_PINNED:
            return {
                ...state,
                vetTicketPinnedCache: {
                    ...state.vetTicketPinnedCache,
                    pages: {
                        ...state.vetTicketPinnedCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
        case types.CLEAR_VET_TICKET_PINNED:
            return {
                ...state,
                vetTicketPinnedCache: {
                    ...state.vetTicketPinnedCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        case types.SAVE_VET_TICKET_UNPINNED:
            return {
                ...state,
                vetTicketUnPinnedCache: {
                    ...state.vetTicketUnPinnedCache,
                    pages: {
                        ...state.vetTicketUnPinnedCache.pages,
                        [payload.pageNumber]: payload.data,
                    },
                    totalRecords: payload?.totalRecords,
                },
            };
            
        case types.CLEAR_VET_TICKET_UNPINNED:
            return {
                ...state,
                vetTicketUnPinnedCache: {
                    ...state.vetTicketUnPinnedCache,
                    pages: {},
                    totalRecords: 0,
                },
            };

        default:
            return state;
    }
};

export default VetTicketReducer;
