import AddIssueConversationButton from "../AddIssueConversationButton/AddIssueConversationButton";
import { useHistory } from "react-router-dom";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { CONVERSATIONS_PERMS_ACTIONS } from "../../enums";

const { CREATE_CONVERSATION } = CONVERSATIONS_PERMS_ACTIONS;
const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { READ, CREATE } = baseResourceActions;

const ConversationsActions = ({ item, handleActionModal }) => {
    const history = useHistory();

    const handleRedirect = () => {
        history?.push({
            pathname: item.conversation?.isSolutionCollection
                ? `/t-sam/branch-collection/${item.conversation?.branchCollection?.branchCollectionId}/view`
                : `/t-sam/conversations/${item.conversation?.conversationId}/view-conversation`,
            state: {
                conversationPath: `/t-sam/conversations`,
            },
        });
    };

    return (
        <>
            {item.conversation !== null ? (
                <ComponentAccessControl
                    baseResources={[`${READ}_${KB_SAM_ISSUE_CONVERSATION}`]}>
                    <span
                        className='text-primary fw-bold cursor-pointer d-flex align-items-center'
                        onClick={handleRedirect}>
                        View conversation
                    </span>
                </ComponentAccessControl>
            ) : (
                <>
                    <span>No conversation has been added yet.</span>
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                        ]}>
                        <AddIssueConversationButton
                            handleClick={() =>
                                handleActionModal(CREATE_CONVERSATION, item)
                            }
                        />
                    </ComponentAccessControl>
                </>
            )}
        </>
    );
};

export default ConversationsActions;
