import * as types from "./types";

export const saveClassification = (data) => (dispatch) =>
    dispatch({ type: types.SAVE_CLASSIFICATIONS, payload: data });

export const saveTeams = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_TEAMS, payload: { pageNumber, totalRecords, data } });
export const clearTeams = () => (dispatch) =>
    dispatch({ type: types.CLEAR_TEAMS });

export const saveConversationTemplates = (upTimeTemplate,
    downTimeTemplate) => (dispatch) =>
        dispatch({
            type: types.SAVE_CONVERSATION_TEMPLATES, payload: {
                upTimeTemplate,
                downTimeTemplate
            }
        });

export const setSelectedMssgTagId = (data) => (dispatch) =>
    dispatch({ type: types.SET_SELECTED_MSSG_TAG, payload: data });
export const saveMssgTags = (data) => (dispatch) =>
    dispatch({ type: types.SAVE_MESSAGING_TAGS, payload: data });
export const saveMssgTagDetails = (mssgTagId, data) => (dispatch) =>
    dispatch({ type: types.SAVE_MESSAGE_TAG_DETAILS, payload: { mssgTagId, data } });
export const removeMssgTagDetails = (data) => (dispatch) =>
    dispatch({ type: types.REMOVE_MESSAGE_TAG_DETAILS, payload: data });