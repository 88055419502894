import { Input } from "components/ui";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";

const BranchNameInput = ({
    handleInputChange,
    isBranchNameAvailable,
    preventInitialResolve,
    setBranchNameAvailability,
    value,
    error,
    autoFocus,
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !preventInitialResolve
                ? checkBranchAvailablilty()
                : setBranchNameAvailability(true);
            // Send Axios request here
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const checkBranchAvailablilty = async () => {
        try {
            if (value.length > 0) {
                setErrorMsg();
                setLoading(true);
                setBranchNameAvailability(false);

                // setErrors({ ...errors, workspaceName: null });

                const url = apiRoute.checkBranchName;
                const res = await API.get(url, {
                    params: {
                        branchName: value,
                    },
                });

                if (res.status === 200) {
                    const nameUsed = res.data.data;
                    setLoading(false);
                    if (nameUsed) {
                        setErrorMsg(
                            "You already have a branch named like this"
                        );
                        setBranchNameAvailability(false);
                    } else {
                        setBranchNameAvailability(true);
                    }
                }
            }
        } catch (err) {
            setBranchNameAvailability(false);
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    return (
        <>
            <Input
                grpClass='mb-3'
                type='text'
                name='mainBranchName'
                label='Branch Name'
                labelClass='text-dark fw-medium'
                id='mainBranchName'
                placeholder='Enter Branch name'
                isErr={error}
                errMssg={error}
                isLoading={loading}
                onChange={handleInputChange}
                onKeyDown={() => {
                    setBranchNameAvailability(false);
                    setErrorMsg();
                    setLoading(false);
                }}
                isValid={isBranchNameAvailable}
                value={value}
                extraMssg={
                    <>
                        {errorMsg && (
                            <i className={`fw-medium text-danger`}>
                                {errorMsg}
                            </i>
                        )}
                    </>
                }
                autoFocus={autoFocus}
            />
        </>
    );
};

export default BranchNameInput;
