import { END_FORM_COLLECTION, POST_FORM_COLLECTION, SOLUTIONS_COLLECTION } from "../enums";

export const branchCollectionData = Object.freeze({
    [POST_FORM_COLLECTION]: {
        title: "Create Post Form Collection",
        headerTitle: "Post Form Collections"
    },
    [SOLUTIONS_COLLECTION]: {
        title: "Create Solution Collection",
    },
    [END_FORM_COLLECTION]: {
        title: "Add Collection to end form"
    }
})
