import {
    ticketPhases,
    ticketsClassifications,
} from "components/dashboard/AgentInbox/enum";
import { appMessageSources } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import {
    EXTERNAL_ANALYTICS_FIX,
    INSTRUCTIONAL_ANALYTICS_FIX,
    INTERNAL_ANALYTICS_FIX,
} from "../../enum";

const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;
const { HIGH, MEDIUM, LOW } = ticketsClassifications;
const { IN_APP, EMAIL, TWITTER, FACEBOOK_MESSENGER, INSTAGRAM } =
    appMessageSources;

export const ticketPhaseOptions = [
    {
        label: "Issue Discovery",
        value: ISSUE_DISCOVERY,
        color: "#be3474",
    },
    {
        label: "Problem Confirmation",
        value: PROBLEM_CONFIRMATION,
        color: "#3b94fc",
    },
    {
        label: "Solution Delivery",
        value: SOLUTION_DELIVERY,
        color: "#c87717",
    },
];

export const ticketClassificationOptions = [
    {
        label: "High",
        value: HIGH,
        color: "#8b9937",
    },
    {
        label: "Medium",
        value: MEDIUM,
        color: "#1853d5",
    },
    {
        label: "Low",
        value: LOW,
        color: "#0f9cef",
    },
];

export const ticketSourceOptions = [
    {
        label: "In App",
        value: IN_APP,
        color: "#5f21ea",
    },
    {
        label: "Email",
        value: EMAIL,
        color: "#9135b4",
    },
    {
        label: "Twitter",
        value: TWITTER,
        color: "#960f82",
    },
    {
        label: "Facebook",
        value: FACEBOOK_MESSENGER,
        color: "#accd01",
    },
    {
        label: "Instagram",
        value: INSTAGRAM,
        color: "#2a8cf7",
    },
];

export const ticketSolutionTypeOptions = [
    {
        label: "Internal  Fix",
        value: INTERNAL_ANALYTICS_FIX,
        color: "#be3474",
    },
    {
        label: "External Fix",
        value: EXTERNAL_ANALYTICS_FIX,
        color: "#3b94fc",
    },
    {
        label: "Instructional Fix",
        value: INSTRUCTIONAL_ANALYTICS_FIX,
        color: "#c87717",
    },
];
