import { Link } from "react-router-dom";
import "./AuthFooter.scss";

const AuthFooter = ({ url, actionText, questionText }) => {
    return (
        <>
            <div className='auth--footer'>
                <p>{questionText}</p>
                <Link to={url} className='auth--footer-link'>
                    {" "}
                    {actionText}
                </Link>
            </div>
        </>
    );
};

export default AuthFooter;
