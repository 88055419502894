import { Empty } from "components/ui";
import "./EmptyConversationBody.scss";

const EmptyConversationBody = () => {
    return (
        <>
            <div className='empty__template'>
                <Empty className='empty-teams' text='No template created.' />
            </div>
        </>
    );
};

export default EmptyConversationBody;
