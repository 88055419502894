import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import "./NewFollowupForm.scss";
import { Button, Input, Textarea } from "components/ui";
import { useContext, useEffect, useState } from "react";
import AddCannedMessageOption from "components/dashboard/Branches/BranchView/BranchViewElement/BranchCannedMessages/modal/AddCannedMessageModal/AddCannedMessageOption/AddCannedMessageOption";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { FOLLOW_UP_TASK_SCHEDULES } from "components/dashboard/AgentInbox/AgentFollowUp/FollowUpTasksView/enum";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    convertCommentMentions,
    extractActualTextsFromStringWithLinks,
    extractActualValuesOfHyperlinks,
    getErrorMessage,
    removeEmptyKeysFromObj,
} from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import {
    GUIDE_TASK_ACTIONS_SELECT,
    GUIDE_TASK_SCENARIOS_SELECT,
} from "components/dashboard/TrainSAM/GuideDowntime/ViewGuideDowntime/ViewGDUpdates/enum";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
import CreateCommentInput from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketMentions/CreateComment/CreateCommentInput/CreateCommentInput";

const NewFollowupForm = ({
    cannedResourceType,
    isEdit,
    handleSuccess,
    closeModal,
    selectedCannedTask,
    agentUpdateId,
    hideChooseAgentTaskType,
    hasAutomationFields,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const toastMessage = useContext(ToastContext);
    // const [selectedCannedType, selectCannedType] = useState(cannedResourceType);
    const isAutomation = cannedResourceType === CANNED_RESOURCE_TYPES.AUTOMATION;
    const [request, setRequest] = useState({
        agentUpdateId,
        agentUpdateTaskName: "",
        mainInstruction: "",
        // cannedResourceId: "",
        agentUpdateTaskType: cannedResourceType,
        initiationScheduleType: "AUTOMATIC",
        initiationSchedule: "",
        recipientIds: [],
        message: "",
    });

    const isMentionTask =
        request?.agentUpdateTaskType === CANNED_RESOURCE_TYPES.MENTIONS;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMentionChange = ({ target: { value } }) => {
        setRequest({ ...request, message: value });
        setErrors({ ...errors, message: "" });
    };

    const handleTagged = (id) => {
        // setRequest((prev) => ({
        //     ...prev,
        //     recipientIds: Array.from(new Set([...prev?.recipientIds, id[0]])),
        // }));

        setRequest((prev) => ({
            ...prev,
            recipientIds: new Set([...prev?.recipientIds, ...id]),
        }));
    };

    const handleUpdateRequest = (key, value) => {
        // selectCannedType(value);
        handleChange({ target: { name: key, value } });
    };

    const createCannedTask = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            if (isMentionTask) {
                const { recipientIds } = request;
                const newMentioned = Array.from(recipientIds);
                let newComment = convertCommentMentions(request?.message, true);
                request.mainInstruction = newComment.trim();
                request.recipientIds = newMentioned;
            } else {
                request.mainInstruction = request?.message;
            }
            const url = apiRoute.createUpdateTask;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editCannedTask = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            if (isMentionTask) {
                const { recipientIds } = request;
                const newMentioned = Array.from(recipientIds);
                let newComment = convertCommentMentions(request?.message, true);
                request.mainInstruction = newComment.trim();
                request.recipientIds = newMentioned;
            } else {
                request.mainInstruction = request?.message;
            }
            const url = apiRoute.updateTask(
                selectedCannedTask?.agentUpdateTaskId
            );

            const cleanReq = removeEmptyKeysFromObj(request);
            const res = await API.put(url, cleanReq);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? editCannedTask() : createCannedTask();
        }

        setErrors(formErrors);
    };

    useEffect(() => {
        if (isEdit && selectedCannedTask) {
            ;
            const { initiationSchedule, mainInstruction } = selectedCannedTask;
            const recipientIds =
                extractActualValuesOfHyperlinks(mainInstruction);
            // setRequest({ ...request, recipientIds });
            setRequest({
                ...selectedCannedTask,
                message: mainInstruction,
                agentUpdateId,
                agentUpdateTaskActionType: selectedCannedTask?.taskActionType || "",
                agentUpdateTaskScenairoType: selectedCannedTask?.taskCaseScenairoType || "",
                initiationSchedule: Number(initiationSchedule),
                recipientIds: isMentionTask ? recipientIds : [],
                mentionText: isMentionTask ? mainInstruction : "",
                // agentUpdateTaskType: agentUpdateTaskType,
            });
        }
        // eslint-disable-next-line
    }, [selectedCannedTask, isEdit, isMentionTask]);

    return (
        <form className='mt-4 new-followup-form' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            {!hideChooseAgentTaskType && (
                <AddCannedMessageOption
                    title={"Follow-up type"}
                    cannedModalType={request?.agentUpdateTaskType}
                    setCannedModalType={(value) =>
                        handleUpdateRequest("agentUpdateTaskType", value)
                    }
                />
            )}
            {!isAutomation && <Input
                grpClass='mb-3 mt-3'
                type='text'
                name='agentUpdateTaskName'
                label='Task Name'
                labelClass='text-dark fw-medium'
                id='agentUpdateTaskName'
                placeholder='Type task name'
                value={request?.agentUpdateTaskName}
                onChange={handleChange}
                isErr={errors?.agentUpdateTaskName}
                errMssg={errors?.agentUpdateTaskName}
                autoFocus={true}
            />
            }
            {isMentionTask ? (
                <CreateCommentInput
                    comment={extractActualTextsFromStringWithLinks(
                        request?.message
                    )}
                    handleChange={handleMentionChange}
                    handleTagged={handleTagged}
                    autoFocus={false}
                    label='Add instruction'
                    labelClass='text-dark fw-medium'
                    placeholder='Type instruction'
                />
            ) : (
                !isAutomation && <Textarea
                    grpClass='mb-3 mt-3'
                    type='text'
                    name='message'
                    label='Add instruction'
                    labelClass='text-dark fw-medium'
                    id='message'
                    placeholder='Type instruction'
                    value={request?.message}
                    onChange={handleChange}
                    isErr={errors?.message}
                    errMssg={errors?.message}
                />
            )}

            <ScheduleDayInput
                label='Add reminder'
                showLabel={true}
                showSubLabel={false}
                name='initiationSchedule'
                id='initiationSchedule'
                handleDateChange={(totalNoOfSeconds) =>
                    handleUpdateRequest("initiationSchedule", totalNoOfSeconds)
                }
                error={errors?.initiationSchedule}
                timeValue={request?.initiationSchedule}
                maxDays={10}
            />

            <SelectUI
                name='initiationScheduleType'
                label=''
                id='initiationScheduleType'
                placeholder='When'
                options={FOLLOW_UP_TASK_SCHEDULES}
                error={
                    errors?.initiationScheduleType
                        ? "Schedule Type is required"
                        : ""
                }
                value={FOLLOW_UP_TASK_SCHEDULES?.find((scheduleType) => {
                    return (
                        request?.initiationScheduleType === scheduleType.value
                    );
                })}
                onChange={({ value }) =>
                    handleUpdateRequest("initiationScheduleType", value)
                }
                isDisabled
            />

            {hasAutomationFields && (
                <div className='automation_fields'>
                    <section>
                        <SelectUI
                            name='agentUpdateTaskScenairoType'
                            label=''
                            id='agentUpdateTaskScenairoType'
                            placeholder='Scenario'
                            options={GUIDE_TASK_SCENARIOS_SELECT}
                            error={
                                errors?.agentUpdateTaskScenairoType
                                    ? "Scenario type is required"
                                    : ""
                            }
                            value={GUIDE_TASK_SCENARIOS_SELECT?.find(
                                ({ value }) => {
                                    return (
                                        request?.agentUpdateTaskScenairoType === value
                                    );
                                }
                            )}
                            onChange={({ value }) =>{

                                handleUpdateRequest(
                                    "agentUpdateTaskScenairoType",
                                    value
                                )
                                }
                            }
                        />
                    </section>

                    <section>
                        <SelectUI
                            name='agentUpdateTaskActionType'
                            label=''
                            id='agentUpdateTaskActionType'
                            placeholder='Action'
                            options={GUIDE_TASK_ACTIONS_SELECT}
                            error={
                                errors?.agentUpdateTaskActionType
                                    ? "Action is required"
                                    : ""
                            }
                            value={GUIDE_TASK_ACTIONS_SELECT?.find(
                                ({ value }) => {
                                    return request?.taskActionType === value;
                                }
                            )}
                            onChange={({ value }) =>
                                handleUpdateRequest(
                                    "agentUpdateTaskActionType",
                                    value
                                )
                            }
                        />
                    </section>
                </div>
            )}

            <Button
                type='submit'
                text='Save and edit update'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default NewFollowupForm;
