import SwitchesContainer from "./SwitchesContainer/SwitchesContainer";

const Switches = () => {
    return (
        <>
            <SwitchesContainer />
        </>
    );
};

export default Switches;
