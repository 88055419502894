import { useEffect, useState } from "react";
import { apiRoute } from "services";
import { Input, Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { dataQueryStatus } from "utils";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { setAccessToken } from "storage/cookieStorage";
import { updateAuthInfo } from "store/auth/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setDeviceToken } from "storage/localStorage";
import { getDevicePushToken } from "services/firebase/firebase";
import { handleAuthRedirect } from "../SignIn/utils/helper";

const { IDLE, LOADING } = dataQueryStatus;

const ResetPassword = ({ sessionId, updateAuthInfo }) => {
    const [request, setRequest] = useState({ password: "" });
    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState(IDLE);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const getDeviceToken = async () => {
        let deviceToken = await getDevicePushToken();

        setDeviceToken(deviceToken);
        setRequest({ ...request, deviceToken });
    };

    useEffect(() => {
        getDeviceToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const { formisValid, errors: formErrors } = ValidateForm(
                e,
                request
            );
            if (formisValid) {
                setStatus(LOADING);
                const url = apiRoute.resetPassword(sessionId);

                const res = await API.post(url, { ...request });
                if (res.status === 201) {
                    const { user, token } = res.data.data;

                    await setAccessToken(token);
                    await updateAuthInfo(user);

                    window.location.href = handleAuthRedirect(
                        user?.role?.permissions,
                        user?.passwordUpdatedRequired
                    );
                }
            } else {
                setErrors(formErrors);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setStatus(IDLE);
        }
    };

    return (
        <div className='forgotpassword'>
            <h1>Reset Password</h1>
            <p>Please enter your new password</p>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMsg)}
                    message={errorMsg}
                    hide={() => setErrorMsg()}
                />
                <Input
                    grpClass='mb-3'
                    type='password'
                    name='password'
                    label='New Password'
                    labelClass='text-dark fw-bold'
                    id='password'
                    placeholder='Enter your new password'
                    onChange={handleChange}
                    value={request?.password}
                    fieldType='password'
                    isErr={errors?.password}
                    errMssg={errors?.password}
                    showPwd
                />
                <Button
                    type='submit'
                    text='Reset Password'
                    classType='primary'
                    loading={status === LOADING}
                    otherClass='my-3 w-100'
                />
            </form>
        </div>
    );
};

export default connect(null, {
    updateAuthInfo,
})(withRouter(ResetPassword));
