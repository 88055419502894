import { ReactSVG } from "react-svg";
import { Input, Button } from "components/ui";
import asset from "assets/images";
import { useHistory, useLocation } from "react-router-dom";
import { validateCustomForm } from "utils/validations/Validator";
import { useLoading } from "components/dashboard/common/LoadingProvider/context";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./FormSetupHeader.scss";
import { useDispatch } from "react-redux";
import { updateForm, updateFormsList } from "store/cache/forms/actions";

const { KB_FORM_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;

const FormSetupHeader = ({
    requestData,
    handleInputChange,
    errors,
    setErrors,
    isViewForm,
}) => {
    let history = useHistory();
    const location = useLocation();

    const { setLoading } = useLoading();
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    const handleCreateForm = async () => {
        try {
            setLoading(true);
            const url = apiRoute.createForm;

            const reqBody = {
                ...requestData,
                formElements: requestData?.formElements?.map((el, index) => ({
                    ...el,
                    order: index + 1,
                    elementOptions: el?.elementOptions.map(({ text }) => text),
                })),
            };

            const res = await API.post(url, reqBody);
            const data = res.data;

            if (res.status === 201) {
                toastMessage(data?.message);
                setLoading(false);
                dispatch(updateFormsList([res.data.data]));
                history.push("/knowledge-base/forms");
            }
        } catch (err) {
            let errorMssg = getErrorMessage(err);
            await setLoading(false);
            await toastMessage(errorMssg, dataQueryStatus.ERROR);
        }
    };

    const handleUpdateForm = async () => {
        try {
            setLoading(true);
            const url = apiRoute.updateForm(location?.state?.formId);

            const reqBody = {
                ...requestData,
                formElements: requestData?.formElements?.map((el, index) => ({
                    ...el,
                    order: index + 1,
                    elementOptions: el?.elementOptions.map(({ text }) => text),
                })),
            };

            const res = await API.put(url, reqBody);
            const data = res.data;

            if (res.status === 200) {
                toastMessage(data?.message);
                setLoading(false);
                dispatch(updateForm(res.data.data));
                history.replace("/knowledge-base/forms");
            }
        } catch (err) {
            let errorMssg = getErrorMessage(err);
            await setLoading(false);
            toastMessage(errorMssg, dataQueryStatus.ERROR);
        }
    };

    const callServer = () => {
        isViewForm ? handleUpdateForm() : handleCreateForm();
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { errors: formErrors, formisValid } =
            validateCustomForm(requestData);
        if (formisValid) {
            return callServer();
        }
        setErrors(formErrors);
    };

    return (
        <div className='form__setup__header'>
            <form onSubmit={handleFormSubmit}>
                <div className='form__setup__header--actions'>
                    <Input
                        grpClass='me-auto flex-grow-1'
                        type='text'
                        name='formName'
                        onChange={handleInputChange}
                        labelClass='d-none'
                        id='formName'
                        placeholder='Sample Form Name'
                        inputClass='form-control--borderless w-75'
                        label={"Form name"}
                        value={requestData?.formName}
                    />
                    <Button
                        text='Cancel'
                        type='reset'
                        classType='outline-secondary'
                        otherClass={"form__setup__header--cancel me-2"}
                        onClick={history.goBack}
                    />
                    <ComponentAccessControl
                        baseResources={[
                            `${CREATE}_${KB_FORM_SETTING}`,
                            `${UPDATE}_${KB_FORM_SETTING}`,
                        ]}>
                        <Button
                            disabled={requestData?.formElements?.length < 1}
                            text={
                                <>
                                    <ReactSVG
                                        src={asset?.icons?.checkLine}
                                        className='d-inline-flex'
                                    />{" "}
                                    {isViewForm ? "Update form" : "Create form"}
                                </>
                            }
                            otherClass='btn__major--submit'
                        />
                    </ComponentAccessControl>
                </div>
                <div className='form__setup__header--error invalid-feedback'>
                    <span>{errors?.formName}</span>
                </div>
            </form>
        </div>
    );
};

export default FormSetupHeader;
