import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { Table } from "components/ui";
import "./AnalyticsQAReportTable.scss";
import AnalyticsQAReportTFilter from "./AnalyticsQAReportTFilter/AnalyticsQAReportTFilter";
import AnalyticsQAReportTDropdown from "./AnalyticsQAReportTDropdown/AnalyticsQAReportTDropdown";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";

const AnalyticsQAReportTable = () => {
    const tableData = [
        {
            ticketId: "103442",
            status: <span className='awaiting'>Awaiting QA</span>,
            agentName: "Rotimi Ade",
            responseTime: (
                <div className='d-flex'>
                    <span>20mins</span>
                    {"  "}
                    <span className='uptime-trend'>
                        <ReactSVG src={asset.icons.uptime} />
                        20%
                    </span>
                </div>
            ),
            replies: 4,
            deviations: 2,
            actions: <AnalyticsQAReportTDropdown />,
        },
    ];
    return (
        <section className='analytics-qa-report-table'>
            <h3>All QA</h3>
            <AnalyticsQAReportTFilter />
            <Table
                headers={[
                    "Ticket ID",
                    "Status",
                    "Agent Name",
                    "Response time",
                    "New replies",
                    "Deviations",
                    "Actions",
                ]}
                dataSource={tableData}
            />
            <CustomPagination />
        </section>
    );
};

export default AnalyticsQAReportTable;
