import React from "react";
import ItemsList from "components/ui/ItemsList/ItemsList";

const RoleModulesTags = ({ permissions }) => {
    return (
        <div className='d-flex flex-wrap gap-2'>
            <ItemsList breakPoint={10}>
                {permissions.map(({ permissionCode, displayName }) => (
                    <div className='role-module-tag' key={permissionCode}>
                        <span className='text-capitalize fs-14'>
                            {displayName}
                        </span>
                    </div>
                ))}
            </ItemsList>
        </div>
    );
};

export default RoleModulesTags;
