import { useRef } from "react";
import { ReactSVG } from "react-svg";
import NotificationBody from "./NotificationBody/NotificationBody";
import asset from "assets/images";
import NotificationsTab from "./NotificationBody/NotificationsTab/NotificationsTab";
import EmptyNotificationBody from "./EmptyNotificationBody/EmptyNotificationBody";
import { Loading } from "../Loading/Loading";
import useObserver from "hooks/useObserver";
import "./Notifications.scss";

const Notifications = ({
    closeModal,
    groupedMessagesList,
    totalMessagesCount,
    handleTabChange,
    selectedCategory,
    fetchNextPage,
    nextPage,
    loading,
    fetchedMessagesCount,
    currUnreadMsgsCount,
}) => {
    const loaderTarget = useRef(null);

    useObserver(
        fetchNextPage,
        loaderTarget,
        nextPage,
        loading,
        fetchedMessagesCount === totalMessagesCount
    );

    return (
        <div className='notifications-container'>
            <div className='header'>
                <div className=''>
                    <h1 className='super__text'>
                        <span className='main'>Notifications</span>
                        <span className='indicator'>
                            <span>{currUnreadMsgsCount}</span>
                        </span>
                    </h1>
                </div>
                <div className='header--cancel' onClick={closeModal}>
                    <ReactSVG src={asset.icons.darkCancel} />
                </div>
            </div>
            <div>
                <NotificationsTab handleTabChange={handleTabChange} />
                {totalMessagesCount > 0 ? (
                    <>
                        <NotificationBody
                            groupedMessagesList={groupedMessagesList}
                            selectedCategory={selectedCategory}
                        />
                        <div ref={loaderTarget} />
                        <div className='loader__container'>
                            {loading && <Loading />}
                        </div>
                    </>
                ) : (
                    <>
                        <EmptyNotificationBody />
                        <div ref={loaderTarget} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Notifications;
