import React, { useState } from "react";
import CapsuleContentBlock from "./CapsuleContentBlock/CapsuleContentBlock";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import "./CapsuleContentBlocksList.scss";

const CapsuleContentBlocksList = ({
    contentBlocks,
    reLoadView,
    hideHeader,
    handleSuccess,
}) => {
    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState("");

    const openDeleteModal = (selectedContentId) => {
        setSelectedContentId(selectedContentId);
        toggleDeleteModal(true);
    };

    return (
        <>
            <div className='content__block__list'>
                {contentBlocks.map((contentBlock) => (
                    <CapsuleContentBlock
                        contentBlock={contentBlock}
                        reLoadView={reLoadView}
                        hideHeader={hideHeader}
                        openDeleteModal={openDeleteModal}
                    />
                ))}
            </div>

            {showDeleteModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete this content block`}
                    show={showDeleteModal}
                    closeModal={() => toggleDeleteModal(false)}
                    handleSuccess={handleSuccess}
                    url={apiRoute.capsuleContentBlock(selectedContentId)}
                    method='delete'
                />
            )}
        </>
    );
};

export default CapsuleContentBlocksList;
