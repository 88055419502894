import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
import { branchOptionActionTypes } from "components/dashboard/TrainSAM/SAM/Conversations/enums";
import { Button } from "components/ui";
import { DeleteIconButton } from "components/ui/Button/IconButton/IconButton";
import { branchOptionActions, branchOptionTypeList } from "./enum";
import { convoBreakerActionBranchOptionTypeValues } from "components/dashboard/ServiceModule/ChatSettings/ConversationBreakers/enums";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./BranchOption.scss";
import BranchOptionSchedule from "./BranchOptionSchedule/BranchOptionSchedule";
import { useSelector } from "react-redux";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;

const { UPDATE, DELETE } = baseResourceActions;
const { EDIT_BRANCH_OPTION, EDIT_CONVERSATION_BREAKER_OPTION } =
    branchOptionActions;

const BranchOption = ({
    option,
    handleSelectOption,
    toggleEditModal,
    toggleDeleteModal,
    updateModalActionType,
    isConversationBreakerView,
    isAgentGuideView,
}) => {
    const {
        guideFlowOptionActionType,
        guideFlowOptionCapsuleClassId,
        guideFlowOptionCapsuleEntryId,
        guideFlowOptionLabel,
        guideFlowOptionType,
        guideFlowOptionValue,
    } = option || {};

    const {
        branchOptionLabel,
        branchOptionCapsuleClass,
        branchOptionCapsuleEntryId,
        branchOptionActionType,
        branchOptionType,
        isScheduled,
        scheduleDuration,
        branchOptionValue,
    } = option || {};

    // check if this option is linked to another option or has an action
    const isOptionInvalid = isAgentGuideView
        ? guideFlowOptionValue === null && guideFlowOptionActionType === null
        : ((branchOptionValue === null && branchOptionActionType === null) || (branchOptionActionType === "FORWARD_AGENT" && branchOptionCapsuleEntryId === null));

    const { capsuleClasses } = useSelector((state) => state.capsule);

    const guideCapsuleClass = capsuleClasses?.find?.(
        ({ capsuleClassId }) => capsuleClassId === guideFlowOptionCapsuleClassId
    );
    return (
        <>
            <div
                className={`branch__option ${
                    isOptionInvalid ? "invalid" : ""
                }`}>
                <div className='row align-items-center'>
                    <div className='col-md-8'>
                        <h6>{branchOptionLabel || guideFlowOptionLabel}</h6>
                    </div>
                    <div className='col-md-4'>
                        <ComponentAccessControl
                            baseResources={[
                                `${UPDATE}_${KB_SAM_BRANCH}`,
                                `${UPDATE}_${KB_SAM_COLLECTION}`,
                                `${UPDATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                `${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                            ]}>
                            <Button
                                text='Edit Option'
                                classType='plain'
                                otherClass={"branch__option__button"}
                                onClick={() => {
                                    updateModalActionType(
                                        isConversationBreakerView
                                            ? EDIT_CONVERSATION_BREAKER_OPTION
                                            : EDIT_BRANCH_OPTION
                                    );
                                    toggleEditModal(true);
                                    handleSelectOption(option);
                                }}
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
                <div>
                    <p className='connections'>Connections:</p>
                    {branchOptionCapsuleEntryId ||
                    guideFlowOptionCapsuleEntryId ? (
                        <div>
                            <CapsuleElement
                                capsule={
                                    branchOptionCapsuleClass ||
                                    guideCapsuleClass
                                }
                                capsuleEntryId={
                                    branchOptionCapsuleEntryId ||
                                    guideFlowOptionCapsuleEntryId
                                }
                                useCapsuleStyles={true}
                                hideEditAction={true}
                            />
                        </div>
                    ) : (
                        <div className='text-dark'>NONE</div>
                    )}

                    {(branchOptionType || guideFlowOptionType) && (
                        <p className='connections'>
                            Connected to{" "}
                            {" " +
                                branchOptionTypeList[
                                    isAgentGuideView
                                        ? guideFlowOptionType
                                        : branchOptionType
                                ]}
                        </p>
                    )}

                    {isScheduled && (
                        <BranchOptionSchedule
                            scheduleDuration={scheduleDuration}
                        />
                    )}

                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            {(branchOptionActionType ||
                                guideFlowOptionActionType) && (
                                <div className='blue__tag'>
                                    <div className='circle'></div>
                                    <span>
                                        {isConversationBreakerView
                                            ? convoBreakerActionBranchOptionTypeValues[
                                                  branchOptionActionType
                                              ]
                                            : branchOptionActionTypes[
                                                  isAgentGuideView
                                                      ? guideFlowOptionActionType
                                                      : branchOptionActionType
                                              ]}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='col-md-2 d-flex justify-content-end ms-auto'>
                            <ComponentAccessControl
                                baseResources={[
                                    `${DELETE}_${KB_SAM_BRANCH}`,
                                    `${DELETE}_${KB_SAM_COLLECTION}`,
                                    `${DELETE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                    `${DELETE}_${KB_SAM_ISSUE_CONVERSATION}`,
                                ]}>
                                {" "}
                                <DeleteIconButton
                                    onClick={() => {
                                        toggleDeleteModal(true);
                                        handleSelectOption(option);
                                    }}
                                />
                            </ComponentAccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BranchOption;
