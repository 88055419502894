import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { Button } from "components/ui";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CreateCommentButton.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;

const CreateCommentButton = ({
    comment,
    handleSubmit,
    isEdit,
    setIsEdit,
    disabled,
}) => {
    return (
        <div className='create__comment'>
            {isEdit ? (
                <div className='create__comment--edit__actions'>
                    <ReactSVG
                        src={imageLinks?.icons?.lightCancel}
                        className='create__comment--cancel__svg'
                        onClick={setIsEdit}
                    />
                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        <Button
                            type='submit'
                            classType='primary'
                            otherClass='create__comment--send__button'
                            disabled={comment === ""}
                            onClick={handleSubmit}>
                            <ReactSVG
                                src={imageLinks?.icons?.lightCheck}
                                className='create__comment--send__svg'
                            />
                        </Button>
                    </ComponentAccessControl>
                </div>
            ) : (
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <Button
                        type='submit'
                        classType='primary'
                        otherClass='create__comment--send__button'
                        disabled={disabled || comment === ""}
                        onClick={handleSubmit}>
                        <ReactSVG
                            src={imageLinks?.icons?.sendIcon}
                            className='create__comment--send__svg'
                        />
                    </Button>
                </ComponentAccessControl>
            )}
        </div>
    );
};

export default CreateCommentButton;
