import * as types from "./types";

export const saveTeamMembers = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({ type: types.SAVE_TEAM_MEMBERS, payload: { pageNumber, totalRecords, data } });
export const clearTeamMembers = () => (dispatch) =>
    dispatch({ type: types.CLEAR_TEAM_MEMBERS });

export const saveRolesAndPermissions = (pageNumber, totalRecords, data) => (dispatch) =>
    dispatch({
        type: types.SAVE_ROLES_PERMISSIONS,
        payload: { pageNumber, totalRecords, data },
    });
export const clearRolesAndPermissions = () => (dispatch) =>
    dispatch({ type: types.CLEAR_ROLES_PERMISSIONS });
export const saveRoleModules = (data) => (dispatch) =>
    dispatch({
        type: types.SAVE_ROLE_MODULES,
        payload: data,
    });

export const saveStartupProfile = (data) => (dispatch) =>
    dispatch({ type: types.SAVE_STARTUP_PROFILE, payload: data });
