import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";
import "./CannedResponseButton.scss";

const CannedResponseButton = ({ openModal }) => {
    return (
        <div>
            <AddButton
                btnText='New Canned Responses'
                otherClassName='canned__response__btn'
                onClick={openModal}
            />
        </div>
    );
};

CannedResponseButton.prototypes = {
    openModal: PropTypes.func.isRequired,
};

export default CannedResponseButton;
