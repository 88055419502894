import "./SuggestionsHeader.scss";

export const SuggestionsHeader = () => {
    return (
        <>
            <div className='suggestion__header'>
                <h1 className='suggestion__header--text'>
                    Conversation Suggestions
                </h1>
            </div>
        </>
    );
};
