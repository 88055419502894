import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import Comment from "./Comment/Comment";
import { apiRoute } from "services";
import { deleteIncomingTicket } from "store/tickets/incomingTickets/actions";
import { setActiveTicket } from "store/tickets/actions";
import { selectNextTicket } from "../../../CustomerTicketsContainer/CustomerTickets/util/helper";

const Comments = ({
    comments,
    selectedTicket,
    handleSuccess,
    handleSetCurrentComment,
    fetchAllTickets,
}) => {
    const { ticketId } = selectedTicket || {};
    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [showResolveCommentModal, toggleResolveCommentModal] =
        useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState("");
    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const dispatch = useDispatch();

    const handleShowDeleteModal = (selectedCommentId) => {
        setSelectedCommentId(selectedCommentId);
        toggleDeleteModal(true);
    };

    const handleResolveComment = (selectedCommentId) => {
        setSelectedCommentId(selectedCommentId);
        toggleResolveCommentModal(true);
    };

    return (
        <>
            {comments?.map((comment, i) => (
                <Comment
                    key={i}
                    comment={comment}
                    handleShowDeleteModal={(selectedComment) =>
                        handleShowDeleteModal(selectedComment)
                    }
                    handleSetCurrentComment={handleSetCurrentComment}
                    handleResolveComment={handleResolveComment}
                />
            ))}

            {showDeleteModal && (
                <ConfirmationModal
                    title='Are you sure you want to Delete this comment'
                    show={showDeleteModal}
                    closeModal={() => {
                        setSelectedCommentId("");
                        toggleDeleteModal(false);
                    }}
                    handleSuccess={handleSuccess}
                    url={apiRoute.ticketComment(selectedCommentId)}
                    method='delete'
                />
            )}

            {showResolveCommentModal && (
                <ConfirmationModal
                    title='Are you sure you want to resolve this comment'
                    show={showResolveCommentModal}
                    closeModal={() => {
                        setSelectedCommentId("");
                        toggleResolveCommentModal(false);
                    }}
                    handleSuccess={() => {
                        selectNextTicket(
                            selectedTicket,
                            sortedTickets,
                            (ticket, ticketId) => {
                                dispatch(setActiveTicket(ticket));
                                ticketId &&
                                    dispatch(
                                        deleteIncomingTicket({ ticketId })
                                    );
                            }
                        );
                        handleSuccess();
                        fetchAllTickets?.();
                    }}
                    url={apiRoute.resolveTicketComment(ticketId)}
                    method='patch'
                />
            )}
        </>
    );
};

export default Comments;
