import React, { useState, useEffect } from "react";
import { Button, Checkbox, Input } from "components/ui";
import CannedContent from "./CannedContent/CannedContent";
import ValidateForm from "utils/validations/Validator";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { FOLLOW_UP_TASK_SCHEDULES } from "../../../enum";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { useParams } from "react-router-dom";
import { cannedContentTypes } from "./CannedContent/enum";
import { SearchSelectInput } from "components/ui";
import { POST_FORM_COLLECTION } from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/enums";
import "./AddNewTaskModalForm.scss";
import { setCollectionsList } from "store/cache/collections/actions";
import { useDispatch, useSelector } from "react-redux";

const { SUCCESS } = dataQueryStatus;
const { CANNED_EMAIL, CANNED_RESPONSE } = cannedContentTypes;

const AddNewTaskModalForm = ({
    getFollowUpTasks,
    closeModal,
    selectedFollowUpTask,
    isEdit,
}) => {
    const { followUpId } = useParams();
    const [showPFCollections, setShowPFCollections] = useState(
        selectedFollowUpTask?.branchCollections?.length > 0 || false
    );
    const [loading, setLoading] = useState();
    const [request, setRequest] = useState({
        taskTitle: selectedFollowUpTask?.taskTitle || "",
        cannedEmails: selectedFollowUpTask?.taskEmails || [],
        cannedResponses: selectedFollowUpTask?.taskCannedResponses || [],
        relatedCollectionIds: isEdit
            ? selectedFollowUpTask?.branchCollections?.map(
                  (branchCollection) => branchCollection?.branchCollectionId
              ) || []
            : null,
        initiationScheduleType:
            selectedFollowUpTask?.initiationScheduleType || null,
        initiationSchedule: selectedFollowUpTask?.initiationSchedule || 0,
        followupId: followUpId,
    });
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();
    const [postFormCollections, setPostFormCollections] = useState([]);
    const [postFormCollectionsIsLoading, setPostFormCollectionsIsLoading] =
        useState(false);
    const [postFormCollectionsErrorMssg, setPostFormCollectionsErrorMssg] =
        useState("");
    const [
        existingPostFormCollectionLabels,
        setExistingPostFormCollectionLabels,
    ] = useState([]);
    const dispatch = useDispatch();
    const { collections } = useSelector((state) => state.collections);
    const toastMessage = useContext(ToastContext);

    useEffect(() => {
        const { branchCollections } = selectedFollowUpTask || {};

        if (isEdit) {
            const formattedData = branchCollections?.map(
                (branchCollection) => ({
                    value: branchCollection?.branchCollectionId,
                    label: branchCollection?.branchCollectionName,
                })
            );
            setExistingPostFormCollectionLabels(formattedData);
        }
        // eslint-disable-next-line
    }, [selectedFollowUpTask]);

    const handleUpdateRequest = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleUpdateRequest(name, value);
    };

    const handleMultipleInputChange = (name, value) => {
        setRequest({
            ...request,
            [name]: value?.length > 0 ? value : undefined,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const filterCollections = () => {
        const allCollections = collections.map(
            ({ branchCollectionId, branchCollectionName }) => ({
                label: branchCollectionName,
                value: branchCollectionId,
            })
        );

        setPostFormCollections(allCollections);
    };

    const getPostFormCollections = async (httpRequest) => {
        try {
            setPostFormCollectionsErrorMssg("");
            setPostFormCollectionsIsLoading(true);
            const res = await API.get(apiRoute.getBranchCollections, {
                params: {
                    branchCollectionType: POST_FORM_COLLECTION,
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                setPostFormCollectionsIsLoading(false);
                const { data } = res.data;
                dispatch(setCollectionsList(data));
                filterCollections();
            }
        } catch (err) {
            setPostFormCollectionsIsLoading(false);
            setPostFormCollectionsErrorMssg(getErrorMessage(err));
        }
    };

    const createFollowUpTask = async () => {
        try {
            setErrorMsg();
            setLoading(true);

            const {
                cannedEmailBody,
                cannedEmailSubject,
                relatedCollectionIds,
            } = request;

            const url = apiRoute?.createFollowUpTask;
            const res = await API.post(url, {
                ...request,
                cannedEmailBody: cannedEmailBody,
                cannedEmailSubject: cannedEmailSubject,
                relatedCollectionIds: showPFCollections
                    ? relatedCollectionIds
                    : null,
            });
            if (res.status === 201) {
                const { message } = res.data;
                setLoading(false);
                toastMessage(message, SUCCESS);
                getFollowUpTasks();
                closeModal?.();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editFollowUpTask = async () => {
        try {
            setErrorMsg();
            setLoading(true);

            const {
                cannedEmailBody,
                cannedEmailSubject,
                relatedCollectionIds,
            } = request;

            const url = apiRoute?.updateFollowUpTask(
                selectedFollowUpTask?.taskId
            );

            request.initiationSchedule = Number(request.initiationSchedule);
            const res = await API.put(url, {
                ...request,
                cannedEmailBody: cannedEmailBody,
                cannedEmailSubject: cannedEmailSubject,
                relatedCollectionIds: showPFCollections
                    ? relatedCollectionIds
                    : null,
            });

            if (res.status === 200) {
                const { message } = res.data;
                setLoading(false);
                toastMessage(message, SUCCESS);
                getFollowUpTasks();
                closeModal?.();
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "cannedEmailSubject",
            "cannedResponse",
            "initiationScheduleType",
            "relatedCollectionIds",
        ]);

        if (formisValid) {
            isEdit ? editFollowUpTask() : createFollowUpTask();
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getPostFormCollections(httpRequest);

        return () => {
            httpRequest?.abort();
        };
        // eslint-disable-next-line
    }, []);

    const { relatedCollectionIds } = request;

    const handleShowPFCollection = () => {
        setShowPFCollections(!showPFCollections);
        if (showPFCollections) {
            handleUpdateRequest("relatedCollectionIds", undefined);
        }
    };

    useEffect(() => {
        if (collections?.length > 0) {
            filterCollections();
        }
        // eslint-disable-next-line
    }, [collections]);

    return (
        <form className='add-new-form-task mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />

            <Input
                grpClass='mb-3'
                type='text'
                name='taskTitle'
                label='Task Title'
                labelClass='text-dark fw-bold'
                id='taskTitle'
                placeholder='Include a Task Title here'
                value={request?.taskTitle}
                onChange={(e) => handleChange(e)}
                isErr={errors?.taskTitle}
                errMssg={errors?.taskTitle}
                autoFocus={true}
            />

            <CannedContent
                request={request}
                label='Canned Email'
                setRequest={setRequest}
                type={CANNED_EMAIL}
            />

            <CannedContent
                request={request}
                label='Canned Response'
                setRequest={setRequest}
                type={CANNED_RESPONSE}
            />

            <Checkbox
                label={"Link to Post Form Collection of this Solution type"}
                checked={showPFCollections}
                onChange={handleShowPFCollection}
                inputClass='input-check-grey'
                labelClass='add-new-form-task__checkbox-text'
                grpClass='mb-2'
                id='postForm'
            />

            {showPFCollections && (
                <div className='add-new-form-task__select-collection'>
                    <SearchSelectInput
                        grpClass='mb-3'
                        name='relatedCollectionIds'
                        label='Add post form branch'
                        labelClass='text-dark fw-medium'
                        darkLabel={true}
                        id='relatedCollectionIds'
                        placeholder='Search here'
                        setInputValue={handleMultipleInputChange}
                        errMssg={errors?.relatedCollectionIds}
                        isLoading={postFormCollectionsIsLoading}
                        serverError={postFormCollectionsErrorMssg}
                        handleRetry={() => getPostFormCollections()}
                        data={postFormCollections?.filter(
                            ({ value }) =>
                                !relatedCollectionIds?.includes(value)
                        )}
                        inputValue={existingPostFormCollectionLabels}
                    />
                </div>
            )}

            <div>
                <div>
                    <ScheduleDayInput
                        label='Due Date'
                        showLabel={true}
                        showSubLabel={false}
                        name='initiationSchedule'
                        id='initiationSchedule'
                        handleDateChange={(totalNoOfSeconds) =>
                            handleUpdateRequest(
                                "initiationSchedule",
                                totalNoOfSeconds
                            )
                        }
                        error={errors?.initiationSchedule}
                        timeValue={request?.initiationSchedule}
                        maxDays={10}
                    />

                    <SelectUI
                        name='initiationScheduleType'
                        label=''
                        id='initiationScheduleType'
                        placeholder='When'
                        options={FOLLOW_UP_TASK_SCHEDULES}
                        error={errors?.initiationScheduleType}
                        value={FOLLOW_UP_TASK_SCHEDULES?.find(
                            (scheduleType) => {
                                return (
                                    request?.initiationScheduleType ===
                                    scheduleType.value
                                );
                            }
                        )}
                        onChange={({ value }) =>
                            handleUpdateRequest("initiationScheduleType", value)
                        }
                    />
                </div>
            </div>
            <Button
                type='submit'
                classType='primary'
                loading={loading}
                otherClass='w-100'
                text='Save changes'
            />
        </form>
    );
};

export default AddNewTaskModalForm;
