import { components } from "react-select";
import "./SelectUICheckBoxOption.scss";

const SelectUICheckBoxOption = (props) => {
    const optionIcon = props?.data?.icon;
    return (
        <div className='selectui-checkbox-option'>
            <components.Option
                {...props}
                className='selectui-checkbox-option__container'>
                <input
                    type={optionIcon ? optionIcon : "checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default SelectUICheckBoxOption;
