import { ReactSVG } from "react-svg";
import asset from "assets/images";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";

const TicketAAListingItem = ({
    agentGuideId,
    handleStartGuide,
    agentGuideName,
    selectedGuideId,
    hasDowntime,
    loading,
}) => {
    const isGuideSelected = agentGuideId === selectedGuideId && loading;
    return (
        <div
            onClick={() => handleStartGuide(agentGuideId)}
            className='ticketaa-listing__item'>
            <span>{agentGuideName}</span>
            {hasDowntime && <div className="ag__downtime">DT</div>}
            {isGuideSelected ? (
                <SmallLoader />
            ) : (
                <ReactSVG src={asset.icons.rightBlack} />
            )}
        </div>
    );
};

export default TicketAAListingItem;
