import { useState } from "react";
import { apiRoute } from "services";
import { Input } from "components/ui";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";

const { IDLE, LOADING, ERROR, SUCCESS } = dataQueryStatus;
export const WorkspaceNameInput = ({
    request,
    handleChange,
    errors,
    setRequest,
    setErrors,
    isAvailable,
    setAvailable,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [slugUrl, setSlugUrl] = useState("");
    const [respMssg, setRespMssg] = useState("");

    const checkWorkspaceSlugAvailability = async () => {
        try {
            const { workspaceName } = request;
            if (workspaceName.length > 0) {
                setStatus(LOADING);
                setErrors({ ...errors, workspaceName: null });

                const url = apiRoute.generateWorkspaceSlug;
                const res = await API.post(url, { workspaceName });
                if (res.status === 201) {
                    const { isAvailable, fullUrl, slugSubDomain } =
                        res.data.data;
                    setStatus(SUCCESS);
                    setRespMssg(res.data.message);
                    setRequest((prev) => ({
                        ...prev,
                        workspaceUrlSlug: slugSubDomain,
                    }));
                    setAvailable(isAvailable);
                    setSlugUrl(
                        `${fullUrl}${!isAvailable ? " (unavailable)" : ""}`
                    );
                }
            }
        } catch (err) {
            const errMssg = getErrorMessage(err);
            setErrors({ ...errors, workspaceName: errMssg });
            setStatus(ERROR);
        }
    };

    // const handleInput = (e) => {
    // 	const { value } = e.target;
    // 	handleChange(e);
    // 	if (value.length > 4) {
    // 		checkWorkspaceSlugAvailability();
    // 	}

    // }

    // const generateSlug = (e) => {
    // 	const { value } = e.target;
    // 	const slug = request?.workspaceUrlSlug?.split("-")?.join(" ");

    // 	if (value.split("-").join(" ").toLowerCase() !== slug) {
    // 		setStatus(LOADING);
    // 		axios(
    // 			apiRoute?.generateWorkspaceSlug(),
    // 			"post",
    // 			(res, mssg) => {
    // 				setRespMssg(mssg);
    // 				const { isAvailable, fullUrl, slugSubDomain } = res;
    // 				setStatus(SUCCESS);
    // 				setRequest({ ...request, workspaceUrlSlug: slugSubDomain });
    // 				setAvailable(isAvailable);
    // 				setSlugUrl(`${fullUrl}${!isAvailable ? " (unavailable)" : ""}`);
    // 			},
    // 			(err, mssg) => {
    // 				setStatus(ERROR);
    // 				setErrors({ ...errors, workspaceName: mssg });
    // 			},
    // 			{ workspaceName: value },
    // 			false
    // 		);
    // 	}
    // };

    const { workspaceName } = request;
    return (
        <Input
            grpClass='mb-3'
            type='text'
            name='workspaceName'
            label='Team Name'
            labelClass='text-dark fw-bold'
            id='workspaceName'
            placeholder='Enter your team name'
            value={workspaceName}
            onBlur={checkWorkspaceSlugAvailability}
            onKeyDown={() => {
                setAvailable(false);
                setRespMssg();
                setErrors({ ...errors, workspaceName: null });
                setStatus(IDLE);
                setSlugUrl();
            }}
            onChange={handleChange}
            isLoading={status === LOADING}
            isErr={slugUrl ? !isAvailable : errors?.workspaceName}
            isValid={isAvailable}
            extraMssg={
                slugUrl && status !== LOADING ? (
                    <>
                        {respMssg}:{" "}
                        <i
                            className={`fw-medium text-${
                                isAvailable ? "success" : "danger"
                            }`}>
                            {slugUrl}
                        </i>
                    </>
                ) : null
            }
            errMssg={slugUrl ? null : errors?.workspaceName}
        />
    );
};
