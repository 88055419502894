import { Children } from "react";
import { useEffect, useState } from "react";
import "./ItemsList.scss";

const ItemsList = ({
    children,
    breakPoint,
    otherClassNames,
    hideActionBtn,
}) => {
    const [showAll, toggleShowAll] = useState(false);
    const [showActionBtn, setShowActionBtn] = useState();

    const [renderedChildren, updateRenderedChildren] = useState([]);

    const arrayChildren = Children.toArray(children);

    const showAllChildren = () => {
        if (renderedChildren?.length === arrayChildren?.length) {
            updateRenderedChildren(arrayChildren);
        } else {
            updateRenderedChildren(arrayChildren?.slice?.(0, breakPoint));
        }
    };

    useEffect(() => {
        showAllChildren();
        if (breakPoint >= arrayChildren.length || hideActionBtn) {
            setShowActionBtn(false);
        } else {
            setShowActionBtn(true);
        }
        // eslint-disable-next-line
    }, [children, breakPoint]);

    const handleView = () => {
        if (!showAll) {
            updateRenderedChildren(arrayChildren);
        } else {
            updateRenderedChildren(arrayChildren?.slice?.(0, breakPoint));
        }

        toggleShowAll(!showAll);
    };

    return (
        <>
            <div
                className={`items-list ${
                    otherClassNames ? otherClassNames : ""
                }`}>
                <div className='items-list__content'>
                    {Children.map(renderedChildren, (child) => child)}
                </div>

                {showActionBtn && (
                    <div
                        className='items-list__button'
                        onClick={() => handleView()}>
                        {showAll ? "View less" : "View more"}
                    </div>
                )}
            </div>
        </>
    );
};

export default ItemsList;
