import React from "react";
import AddTeamMember from "../AddTeamMember/AddTeamMember";
import RemoveTeamMember from "../RemoveTeamMember/RemoveTeamMember";
import DeactivateTeamMember from "../DeactivateTeamMember/DeactivateTeamMember";
import ResendInvitation from "../ResendInvitation/ResendInvitation";
import UpdateTeamMemberRole from "../UpdateTeamMemberRole/UpdateTeamMemberRole";
import { TEAM_MEMBERS_ACTIONS } from "../../../enums";

const {
    ADD_USER,
    CHANGE_USER_ROLE,
    RESEND_INVITE,
    DEACTIVATE_USER,
    REMOVE_USER,
} = TEAM_MEMBERS_ACTIONS;

const TeamMemberActionForms = ({
    member,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const refetchOnSuccess = () => {
        closeModal();
        handleSuccess();
    };

    const renderBasedOnAction = () => {
        switch (currentAction) {
            case ADD_USER:
                return <AddTeamMember handleSuccess={refetchOnSuccess} />;

            case CHANGE_USER_ROLE:
                return (
                    <UpdateTeamMemberRole
                        member={member}
                        closeModal={closeModal}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            case RESEND_INVITE:
                return (
                    <ResendInvitation
                        userId={member?.userId}
                        closeModal={closeModal}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            case DEACTIVATE_USER:
                return (
                    <DeactivateTeamMember
                        member={member}
                        closeModal={closeModal}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            case REMOVE_USER:
                return (
                    <RemoveTeamMember
                        userId={member?.userId}
                        closeModal={closeModal}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default TeamMemberActionForms;
