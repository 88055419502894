export const SET_USERS_FILTER_PARAMS = "SET_USERS_FILTER_PARAMS";
export const SET_ROLES_PERMISSION_FILTER_PARAMS =
    "SET_ROLES_PERMISSION_FILTER_PARAMS";

export const SET_CONVERSATIONS_BASE_FILTER = "SET_CONVERSATIONS_BASE_FILTER";
export const SET_CONVERSATIONS_FILTER_OPTION =
    "SET_CONVERSATIONS_FILTER_OPTION";
export const SET_CONVERSATIONS_FILTER_PARAMS =
    "SET_CONVERSATIONS_FILTER_PARAMS";


export const SET_QA_DOCUMENTATION_BASE_FILTER = "SET_QA_DOCUMENTATION_BASE_FILTER";
export const SET_QA_DOCUMENTATION_FILTER_OPTION = "SET_QA_DOCUMENTATION_FILTER_OPTION";
export const SET_QA_DOCUMENTATION_FILTER_PARAMS = "SET_QA_DOCUMENTATION_FILTER_PARAMS";

export const SET_ISSUES_BASE_FILTER = "SET_ISSUES_BASE_FILTER";
export const SET_ISSUES_FILTER_OPTION = "SET_ISSUES_FILTER_OPTION";
export const SET_ISSUES_FILTER_PARAMS = "SET_ISSUES_FILTER_PARAMS";


export const SET_DISCOVERY_BASE_FILTER = "SET_DISCOVERY_BASE_FILTER";
export const SET_DISCOVERY_FILTER_OPTION = "SET_DISCOVERY_FILTER_OPTION";
export const SET_DISCOVERY_FILTER_PARAMS = "SET_DISCOVERY_FILTER_PARAMS";

export const SET_SWITCHES_BASE_FILTER = "SET_SWITCHES_BASE_FILTER";
export const SET_SWITCHES_FILTER_OPTION = "SET_SWITCHES_FILTER_OPTION";
export const SET_SWITCHES_FILTER_PARAMS = "SET_SWITCHES_FILTER_PARAMS";

export const SET_DOWNTIME_BRANCHES_BASE_FILTER =
    "SET_DOWNTIME_BRANCHES_BASE_FILTER";
export const SET_DOWNTIME_BRANCHES_FILTER_OPTION =
    "SET_DOWNTIME_BRANCHES_FILTER_OPTION";
export const SET_DOWNTIME_BRANCHES_FILTER_PARAMS =
    "SET_DOWNTIME_BRANCHES_FILTER_PARAMS";

export const SET_FORMS_BASE_FILTER = "SET_FORMS_BASE_FILTER";
export const SET_FORMS_FILTER_OPTION = "SET_FORMS_FILTER_OPTION";
export const SET_FORMS_FILTER_PARAMS = "SET_FORMS_FILTER_PARAMS";

export const SET_POST_FORM_BASE_FILTER = "SET_POST_FORM_BASE_FILTER";
export const SET_POST_FORM_FILTER_OPTION = "SET_POST_FORM_FILTER_OPTION";
export const SET_POST_FORM_FILTER_PARAMS = "SET_POST_FORM_FILTER_PARAMS";

export const SET_TEAMS_FILTER_PARAMS = "SET_TEAMS_FILTER_PARAMS";

export const SET_MSSG_TAG_FILTER_PARAMS = "SET_MSSG_TAG_FILTER_PARAMS";

export const SET_PERSONAS_FILTER_PARAMS = "SET_PERSONAS_FILTER_PARAMS";

export const SET_CANNED_RESPONSES_BASE_FILTER =
    "SET_CANNED_RESPONSES_BASE_FILTER";
export const SET_CANNED_RESPONSES_FILTER_OPTION =
    "SET_CANNED_RESPONSES_FILTER_OPTION";
export const SET_CANNED_RESPONSES_FILTER_PARAMS =
    "SET_CANNED_RESPONSES_FILTER_PARAMS";

export const SET_VET_TICKET_FILTER = "SET_VET_TICKET_FILTER";
export const SET_VET_TICKET_FILTER_OPTION = "SET_VET_TICKET_FILTER_OPTION";
export const SET_VET_TICKET_FILTER_PARAMS = "SET_VET_TICKET_FILTER_PARAMS";

export const SET_VET_AGENT_FILTER = "SET_VET_AGENT_FILTER";
export const SET_VET_AGENT_FILTER_OPTION = "SET_VET_AGENT_FILTER_OPTION";
export const SET_VET_AGENT_FILTER_PARAMS = "SET_VET_AGENT_FILTER_PARAMS";

export const SET_GUIDES_FILTER = "SET_GUIDES_FILTER";
export const SET_GUIDES_FILTER_OPTION = "SET_GUIDES_FILTER_OPTION";
export const SET_GUIDES_FILTER_PARAMS = "SET_GUIDES_FILTER_PARAMS";

export const SET_SCENARIOS_FILTER = "SET_SCENARIOS_FILTER";
export const SET_SCENARIOS_FILTER_OPTION = "SET_SCENARIOS_FILTER_OPTION";
export const SET_SCENARIOS_FILTER_PARAMS = "SET_SCENARIOS_FILTER_PARAMS";

export const SET_CANNED_EMAILS_FILTER = "SET_CANNED_EMAILS_FILTER";
export const SET_CANNED_EMAILS_FILTER_OPTION =
    "SET_CANNED_EMAILS_FILTER_OPTION";
export const SET_CANNED_EMAILS_FILTER_PARAMS =
    "SET_CANNED_EMAILS_FILTER_PARAMS";

export const SET_CANNED_MENTIONS_FILTER = "SET_CANNED_MENTIONS_FILTER";
export const SET_CANNED_MENTIONS_FILTER_OPTION =
    "SET_CANNED_MENTIONS_FILTER_OPTION";
export const SET_CANNED_MENTIONS_FILTER_PARAMS =
    "SET_CANNED_MENTIONS_FILTER_PARAMS";

export const SET_CANNED_REPLIES_FILTER = "SET_CANNED_REPLIES_FILTER";
export const SET_CANNED_REPLIES_FILTER_OPTION =
    "SET_CANNED_REPLIES_FILTER_OPTION";
export const SET_CANNED_REPLIES_FILTER_PARAMS =
    "SET_CANNED_REPLIES_FILTER_PARAMS";

export const SET_EMAIL_UPDATES_FILTER = "SET_EMAIL_UPDATES_FILTER";
export const SET_EMAIL_UPDATES_FILTER_OPTION =
    "SET_EMAIL_UPDATES_FILTER_OPTION";
export const SET_EMAIL_UPDATES_FILTER_PARAMS =
    "SET_EMAIL_UPDATES_FILTER_PARAMS";

export const SET_MENTION_UPDATES_FILTER = "SET_MENTION_UPDATES_FILTER";
export const SET_MENTION_UPDATES_FILTER_OPTION =
    "SET_MENTION_UPDATES_FILTER_OPTION";
export const SET_MENTION_UPDATES_FILTER_PARAMS =
    "SET_MENTION_UPDATES_FILTER_PARAMS";

export const SET_REPLY_UPDATES_FILTER = "SET_REPLY_UPDATES_FILTER";
export const SET_REPLY_UPDATES_FILTER_OPTION =
    "SET_REPLY_UPDATES_FILTER_OPTION";
export const SET_REPLY_UPDATES_FILTER_PARAMS =
    "SET_REPLY_UPDATES_FILTER_PARAMS";

export const SET_GUIDE_DOWNTIME_FILTER_PARAMS =
    "SET_GUIDE_DOWNTIME_FILTER_PARAMS";
