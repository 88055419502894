import React from 'react';
import { ReactSVG } from "react-svg";
import Pagination from 'rc-pagination';
import baseIcon from "./nextIcon.svg";
import "./CustomPagination.scss";

const NextIcon = () => (
    <ReactSVG
        src={baseIcon}
    />
)

const CustomPagination = ({ totalRecords, currentPage, pageSize=0, handlePageChange }) => (
    <Pagination
        current={currentPage}
        total={totalRecords}
        pageSize={pageSize}
        onChange={handlePageChange}
        prevIcon={NextIcon}
        nextIcon={NextIcon}
    />
);

export default CustomPagination;