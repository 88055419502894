import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { IsAnEmptyObject } from "utils";
import asset from "assets/images";

export const Tag = ({
    name,
    classType,
    className,
    popClass,
    amount,
    popContent,
    cancel,
    onCancel,
    value,
    tagCSS = {},
    badgeCSS = {},
    hoverTagCSS = {},
    hoverBadgeCSS = {},
    onClick,
}) => {
    const [showPop, setPop] = useState(false);
    const [hover, setHover] = useState(false);

    const togglePop = () => setPop(!showPop);

    useEffect(() => {
        document.body.addEventListener("click", () => setPop(false));

        return () =>
            document.body.removeEventListener("click", () => setPop(false));
    }, []);

    return (
        <div
            className='position-relative tag-container'
            onClick={(e) => e.stopPropagation()}>
            <div
                className={`cursor-pointer tag ${
                    classType ? "tag--" + classType : ""
                } ${className || ""}`}
                onClick={popContent ? togglePop : onClick}
                style={
                    !IsAnEmptyObject(tagCSS)
                        ? tagCSS
                        : hover
                        ? hoverTagCSS
                        : null
                }
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                {name}{" "}
                {amount ? (
                    <span
                        className='custom-badge tag__badge'
                        style={
                            !IsAnEmptyObject(badgeCSS)
                                ? badgeCSS
                                : hover
                                ? hoverBadgeCSS
                                : null
                        }>
                        {amount}
                    </span>
                ) : null}
                {cancel ? (
                    <span
                        className='cancel'
                        onClick={(e) => {
                            e.stopPropagation();
                            onCancel?.(value);
                        }}>
                        <ReactSVG
                            src={asset?.icons?.cancelX}
                            className='d-inline-flex'
                        />
                    </span>
                ) : null}
            </div>
            {showPop ? (
                <div
                    className={`tag-pop position-absolute end-0 tag-pop--${
                        popClass || ""
                    }`}>
                    {popContent}
                </div>
            ) : null}
        </div>
    );
};
