import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import PropTypes from "prop-types";
import UpdateTeamMemberRoleForm from "./UpdateTeamMemberRoleForm/UpdateTeamMemberRoleForm";

const UpdateTeamMemberRole = ({ member, handleSuccess }) => {
    return (
        <div className='row me-0 ms-0'>
            <div className='col-10 offset-1'>
                <ModalHeading
                    title={"Update user role"}
                    subTitle={`Update the role of ${member?.firstName}`}
                />
                <UpdateTeamMemberRoleForm
                    member={member}
                    handleSuccess={handleSuccess}
                />
            </div>
        </div>
    );
};

UpdateTeamMemberRole.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
};

export default UpdateTeamMemberRole;
