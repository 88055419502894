import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
    SUBSCRIBE_TO_TICKET,
    TICKET_PHASE_CHANGE,
} from "services/socket/events";
import { ticketsPhases } from "./enum";
import { useSocket } from "services/socket/hook";

const TicketStatus = ({ ticketPhase, ticketId }) => {
    const [setPhase, updatePhase] = useState(ticketPhase);

    const currentPhase = ticketsPhases[setPhase];
    const socket = useSocket();

    const handleNewPhase = (data) => {
        updatePhase(data?.ticketPhase);
    };

    useEffect(() => {
        socket?.emit?.(SUBSCRIBE_TO_TICKET, { ticketId });
        socket?.on?.(TICKET_PHASE_CHANGE, handleNewPhase);
        return () => {
            socket?.off?.(TICKET_PHASE_CHANGE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='ticket__status'>
            <div
                className='status__circle'
                style={{ background: currentPhase?.fillColor }}></div>
            <span>{currentPhase?.title} </span>
        </div>
    );
};

export default TicketStatus;
