import React, { useState, useEffect } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import CapsuleClassesListing from "./CapsuleClassesListing/CapsuleClassesListing";
import { getErrorMessage } from "utils/helper";
import "./CapsuleClassesGroup.scss";
import { useDispatch, useSelector } from "react-redux";
import { saveCapsuleClassess } from "store/capsule/actions";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleClassesGroup = ({ filterClasses = [], handleCapsuleSelect }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");

    const { capsuleClasses } = useSelector((state) => state.capsule);

    const dispatch = useDispatch();

    const getCapsuleClasses = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg();

            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url);
            if (res.status === 200) {
                dispatch(saveCapsuleClassess(res.data.data));
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const filterClassesList = (A, B) => {
        const filteredClassList = A?.filter(({ capsuleClassId: id1 }) => {
            return !B?.some(({ capsuleClassId: id2 }) => id2 === id1);
        });
        return filteredClassList;
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <i>retrieving all capsule classes...</i>;

            case DATAMODE:
                return (
                    <CapsuleClassesListing
                        data={filterClassesList(capsuleClasses, filterClasses)}
                        handleCapsuleSelect={handleCapsuleSelect}
                    />
                );
            case ERROR:
                return <i style={{ color: "red" }}>{errorMssg}</i>;

            default:
                break;
        }
    };

    useEffect(() => {
        if (capsuleClasses?.length === 0) {
            getCapsuleClasses();
        } else {
            setStatus(DATAMODE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className='capsule_group'>{renderBasedOnStatus()}</div>;
};

export default CapsuleClassesGroup;
