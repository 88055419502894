import React from "react";
import "./CannedContent.scss";

const CannedContent = ({ content, className, onClick }) => {
    return (
        <div className={className} onClick={onClick}>
            {content}
        </div>
    );
};

export default CannedContent;
