import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TDCannedResponses from "./TDCannedResponses/TDCannedResponses";
import TDConversations from "./TDConversations/TDConversations";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const TDCurrentMessageTag = ({ selectedMssgTagId, handleSelectTagResp }) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrMssg] = useState({});
    const [selectedMssgTagDetails, setSelectedMssgTagDetails] = useState({});

    const getMessageTagDetails = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrMssg();
            const url = apiRoute?.getMessageTag(selectedMssgTagId);

            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });
            const data = res.data;

            if (res.status === 200) {
                setStatus(DATAMODE);
                setSelectedMssgTagDetails(data.data);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrMssg(getErrorMessage(err));
        }
    };

    const { conversations, cannedResponses } = selectedMssgTagDetails;

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <>
                        <TDConversations
                            conversations={conversations}
                            handleSelectTagResp={handleSelectTagResp}
                        />
                        <TDCannedResponses
                            cannedResponses={cannedResponses}
                            selectedMssgTagId={selectedMssgTagId}
                            handleSuccess={getMessageTagDetails}
                            handleSelectTagResp={handleSelectTagResp}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getMessageTagDetails}
                    />
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getMessageTagDetails();
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMssgTagId]);

    return (
        <>
            <div>{renderCompBasedOnStage()}</div>
        </>
    );
};

export default TDCurrentMessageTag;
