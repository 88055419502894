export const postFormActionTypes = {
    ADD_POST_FORM_BRANCH: "ADD_POST_FORM_BRANCH",
    ADD_CANNED_RESPONSE: "ADD_CANNED_RESPONSE",
};

export const POST_FORM_BRANCHES_ACTIONS = {
    VIEW_FORM: "VIEW_FORM",
    ADD_BRANCH: "ADD_BRANCH",
    DELETE_FORM: "DELETE_FORM",
};

export const POST_CANNED_RESPONSE_ACTIONS = {
    VIEW_CANNED_RESPONSE: "VIEW_CANNED_RESPONSE",
    EDIT_CANNED_RESPONSE: "EDIT_CANNED_RESPONSE",
    DELETE_CANNED_RESPONSE: "DELETE_CANNED_RESPONSE",
};
