import React from "react";
import "./TeamMembers.scss";

const TeamMembers = ({ teamMembers }) => {
    return (
        <div className='team__members'>
            {teamMembers?.slice(0, 3)?.map((member, i) => (
                <div className='team__members--member' key={i}>
                    <span className='team__members--text'>
                        {`${member?.firstName} ${member?.lastName}`}
                    </span>
                </div>
            ))}
            {teamMembers?.length > 3 && (
                <div className='team__members--others'>
                    <span className='team__members--text'>
                        {`+${teamMembers?.length - 3} others`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default TeamMembers;
