import { FOLLOW_UP_TASK_SCHEDULES } from "components/dashboard/AgentInbox/AgentFollowUp/FollowUpTasksView/enum";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Checkbox, Input, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import CreateGDUpdateAutomation from "./CreateGDUpdateAutomation/CreateGDUpdateAutomation";
import { GUIDE_TASK_ACTIONS, GUIDE_TASK_SCENARIOS } from "../../../enum";
import { GUIDE_FLOW_TYPES } from "components/dashboard/TrainSAM/GuideDowntime/enum";

const CreateGDUpdateForm = ({
    selectedStepType,
    isEdit,
    closeModal,
    handleSuccess,
    selectedStep,
    selectedUpdate,
    handleRefresh,
}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [hasAutomation, showAutomation] = useState(false);

    const [request, setRequest] = useState({
        agentUpdateName: "",
        agentUpdateType: "AUTOMATION",
        agentUpdateTasks: [],
        initiationScheduleType: "",
    });

    const defaultTask = {
        agentUpdateId: selectedUpdate?.agentUpdateId,
        agentUpdateTaskName: "Uptime Task 1",
        mainInstruction: "",
        cannedResourceId: "",
        agentUpdateTaskType: "AUTOMATION",
        agentUpdateTaskActionType: GUIDE_TASK_ACTIONS.CLOSE_CONVERSATION,
        agentUpdateTaskScenairoType: GUIDE_TASK_SCENARIOS.USER_MUTE,
        initiationScheduleType: "RELATIVE",
        initiationSchedule: 0,
    };

    const [agentUpdateTasks, setAgentUpdateTasks] = useState([defaultTask]);

    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleUpdateRequest = (key, value) => {
        handleChange({ target: { name: key, value } });
    };

    const createAgentUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            if (
                selectedStepType === GUIDE_FLOW_TYPES.UPTIME_GUIDE_FLOW &&
                hasAutomation
            ) {
                request.agentUpdateTasks = agentUpdateTasks;
            }
            const url = apiRoute.createGuideFlowUpdate(
                selectedStep?.guideFlow?.guideFlowId
            );
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;
                handleRefresh?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editAgentUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.cannedUpdate(selectedUpdate?.agentUpdateId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? editAgentUpdate() : createAgentUpdate();
        }

        setErrors(formErrors);
    };

    useEffect(() => {
        if (isEdit && selectedUpdate) {
            setRequest(selectedUpdate);
        }
        // eslint-disable-next-line
    }, [selectedUpdate, isEdit]);

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='agentUpdateName'
                label='Title'
                labelClass='text-dark fw-medium'
                id='agentUpdateName'
                placeholder='Enter title'
                value={request?.agentUpdateName}
                onChange={handleChange}
                isErr={errors?.agentUpdateName}
                errMssg={errors?.agentUpdateName}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='mainInstruction'
                label='Add a response'
                labelClass='text-dark fw-medium'
                id='mainInstruction'
                placeholder='Enter main sentence'
                value={request?.mainInstruction}
                onChange={handleChange}
                isErr={errors?.mainInstruction}
                errMssg={errors?.mainInstruction}
            />

            <ScheduleDayInput
                label='Add schedule'
                showLabel={true}
                showSubLabel={false}
                name='initiationSchedule'
                id='initiationSchedule'
                handleDateChange={(totalNoOfSeconds) =>
                    handleUpdateRequest("initiationSchedule", totalNoOfSeconds)
                }
                error={errors?.initiationSchedule}
                timeValue={request?.initiationSchedule}
                maxDays={10}
            />

            <SelectUI
                name='initiationScheduleType'
                label=''
                id='initiationScheduleType'
                placeholder='When'
                options={FOLLOW_UP_TASK_SCHEDULES}
                error={
                    errors?.initiationScheduleType
                        ? "Schedule Type is required"
                        : ""
                }
                value={FOLLOW_UP_TASK_SCHEDULES?.find((scheduleType) => {
                    return (
                        request?.initiationScheduleType === scheduleType.value
                    );
                })}
                onChange={({ value }) =>
                    handleUpdateRequest("initiationScheduleType", value)
                }
            />

            {selectedStepType === GUIDE_FLOW_TYPES.UPTIME_GUIDE_FLOW &&
                !isEdit && (
                    <Checkbox
                        label={"Add automation"}
                        onChange={() => showAutomation(!hasAutomation)}
                        id={""}
                        inputClass='input-check-grey'
                    />
                )}

            {hasAutomation &&
                selectedStepType === GUIDE_FLOW_TYPES.UPTIME_GUIDE_FLOW && (
                    <CreateGDUpdateAutomation
                        selectedUpdate={selectedUpdate}
                        {...{
                            agentUpdateTasks,
                            setAgentUpdateTasks,
                            defaultTask,
                        }}
                    />
                )}

            <Button
                type='submit'
                text={isEdit ? "Save Update" : "Add update"}
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateGDUpdateForm;
