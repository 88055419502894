import GuideFlowSelect from "components/dashboard/Branches/BranchView/BranchOptionView/form/CreateBranchOptionForm/GuideFlowSelect/GuideFlowSelect";
import { GUIDE_FLOW_TYPES } from "components/dashboard/TrainSAM/GuideDowntime/enum";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";

const EditGDStepForm = ({
    classCapsuleEntryId,
    handleSuccess,
    closeModal,
    selectedStep,
    selectedStepType,
    isEdit,
    capsuleClassId,
}) => {
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [request, setRequest] = useState({
        guideFlowId: "",
        guideFlowName: "",
        mainInstruction: "",
        capsuleId: capsuleClassId,
        capsuleClassId,
        classCapsuleEntryId,
        capsuleEntryId: classCapsuleEntryId,
    });

    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSelect = (value) => {
        setRequest((prev) => ({ ...prev, ...value }));
    }

    const handleCreate = (value) => {
        
        setRequest((prevRequest) => ({ ...prevRequest, guideFlowId: "", guideFlowName: value, mainInstruction: "" }));
    }

    const updateGuideDowntimeStep = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.createDowntimeStep;
            const res = await API.post(url, { ...request, classCapsuleEntryId });

            if (res.status === 201) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess?.();
                closeModal?.();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editGuideStep = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.editGuideBranch(request?.guideFlowId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess?.();
                closeModal?.();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const updateGuideUptimeStep = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.createUptimeStep;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess?.();
                closeModal?.();
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            isEdit
                ? editGuideStep()
                : selectedStepType === GUIDE_FLOW_TYPES?.DOWNTIME_GUIDE_FLOW
                    ? updateGuideDowntimeStep()
                    : updateGuideUptimeStep();
        } else setErrors(formErrors);
    };

    useEffect(() => {
        if (selectedStep && isEdit) {
            const { guideFlow } = selectedStep;
            setRequest((prev) => ({ ...prev, ...guideFlow }));
        }
        // eslint-disable-next-line
    }, [selectedStep]);

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            {
                isEdit ? <Input
                    grpClass='mb-3'
                    type='text'
                    name='guideFlowName'
                    label='Title'
                    labelClass='text-dark fw-medium'
                    id='guideFlowName'
                    placeholder='Enter title'
                    value={request?.guideFlowName}
                    onChange={handleChange}
                    isErr={errors?.guideFlowName}
                    errMssg={errors?.guideFlowName}
                    autoFocus={true}
                /> : <GuideFlowSelect
                    name='guideFlowName'
                    selectedValue={request?.guideFlowId}
                    handleSelect={(value) => handleSelect(value)}
                    handleCreate={(value) => handleCreate(value)}
                    guideFlowType={selectedStepType}
                    defaultValue={{label: request?.guideFlowName, value: request.guideFlowName}}
                    createAble
                />
            }

            <Textarea
                grpClass='mb-3'
                type='text'
                name='mainInstruction'
                label='Main Sentence'
                labelClass='text-dark fw-medium'
                id='mainInstruction'
                placeholder='Enter main sentence'
                value={request?.mainInstruction}
                onChange={handleChange}
                isErr={errors?.mainInstruction}
                errMssg={errors?.mainInstruction}
                readOnly={!isEdit && request.guideFlowId}
            />
            <Button
                type='submit'
                text='Save changes'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default EditGDStepForm;
