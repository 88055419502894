export const FOLLOW_UP_TABLE_HEADER = [
    "Solution Type",
    "Attached follow up collections",
    "Actions",
];

export const FOLLOW_UP_TABLE_ACTIONS = {
    VIEW_FOLLOW_UP_COLLECTION: "VIEW_FOLLOW_UP_COLLECTION",
    DELETE_FOLLOW_UP_COLLECTION: "DELETE_FOLLOW_UP_COLLECTION",
    CREATE_FOLLOW_UP_COLLECTION: "CREATE_FOLLOW_UP_COLLECTION",
    EDIT_FOLLOW_UP_COLLECTION: "EDIT_FOLLOW_UP_COLLECTION",
};

export const FOLLOW_UP_FILTER_TYPES = {
    DATE_CREATED: "DATE_CREATED",
    ALPHABETICAL: "ALPHABETICAL",
    CLASS_TYPE: "CLASS_TYPE",
};

export const FOLLOW_UP_FILTER = [
    {
        label: "A-z",
        value: "A_Z",
    },
    {
        label: "Date Created",
        value: "DATE_CREATED",
    },
    { label: "Class Type", value: "CLASS_TYPE" },
];
