import { useEffect, useState } from "react";

import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";

import StatusMap from "components/dashboard/TrainSAM/Dependency/common/StatusMap/StatusMap";

import { STATUS_MAP_OPTIONS } from "components/dashboard/TrainSAM/Dependency/enum";

const { LOADING, SUCCESS, ERROR } = dataQueryStatus;

const CapsuleStatusMap = ({ collectionName, capsuleEntryId }) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState();

    const [statusMapMetrics, setStatusMapMetrics] = useState({});
    const [statusMapData, setStatusMapData] = useState([]);

    const [selectedStatusMapOption, updateStatusMapOption] = useState(
        STATUS_MAP_OPTIONS.DAILY
    );

    const handleSelectOption = (option) => {
        updateStatusMapOption(option);
    };

    const getCapsuleStatusMap = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute?.getCapsuleStatusMap(
                collectionName,
                capsuleEntryId,
                selectedStatusMapOption
            );

            const res = await API.get(url);
            if (res.status === 200) {
                setStatus(SUCCESS);
                const { data, ...rest } = res.data.data;
                setStatusMapData(data);
                setStatusMapMetrics(rest);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const renderBasedOnStage = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case SUCCESS:
                return (
                    <StatusMap
                        statusMapMetrics={statusMapMetrics}
                        statusMapData={statusMapData}
                        handleSelect={handleSelectOption}
                        selectedOption={selectedStatusMapOption}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleStatusMap}
                    />
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        getCapsuleStatusMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatusMapOption]);

    return (
        <>
            <div>{renderBasedOnStage()}</div>
        </>
    );
};

export default CapsuleStatusMap;
