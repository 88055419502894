export const USER_REPLIED = "USER_REPLIED";
export const MENTION_RECEIVED = "MENTION_RECEIVED";
export const YOU_REPLIED = "YOU_REPLIED";
export const CANNED_RESPONSE_SENT = "CANNED_RESPONSE_SENT";
export const SCENARIO_SENT = "SCENARIO_SENT";
export const FORM_FILLED = "FORM_FILLED";

export const TICKET_INTERACTION_STATUSES = {
    MENTION_RECEIVED: "Mention received",
    USER_REPLIED: "User replied",
    YOU_REPLIED: "You replied",
    CANNED_RESPONSE_SENT: "Canned response sent",
    SCENARIO_SENT: "Scenario sent",
    FORM_FILLED: "User filled form",
};
