import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui";
import FadeIn from "components/ui/effects/FadeIn";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleSettingsHeader.scss";

const { KB_CAPSULE_SETTINGS } = baseResources;
const { UPDATE } = baseResourceActions;
const { LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleSettingsHeader = ({
    isEdited,
    status,
    setStatus,
    selectedCapsule,
    contentTypes,
    handleCapsulationSuccess,
}) => {
    const toastMessage = useContext(ToastContext);

    const submitEditedCapsule = async (e) => {
        try {
            e.preventDefault();
            setStatus(LOADING);

            const url = apiRoute.capsuleClassesContentType;
            const request = {
                capsuleClassId: selectedCapsule?.capsuleClassId,
                contentTypes,
            };

            const res = await API.put(url, { ...request });
            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleCapsulationSuccess(selectedCapsule);
            }
        } catch (err) {
            setStatus(DATAMODE);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <>
            <div className='capsule__setting--header'>
                <div className='capsule__setting--header--contents'>
                    <div>
                        <div>
                            <h1 className='capsule__setting--header--contents--title'>
                                Capsule Settings
                            </h1>
                            <p className='capsule__setting--header--contents--sub__title'>
                                Add & change how your classes interact with each
                                other
                            </p>
                        </div>
                    </div>
                    <div>
                        {isEdited && (
                            <FadeIn>
                                <ComponentAccessControl
                                    baseResources={[
                                        `${UPDATE}_${KB_CAPSULE_SETTINGS}`,
                                    ]}>
                                    <Button
                                        text='save changes'
                                        type='button'
                                        classType='primary'
                                        otherClass='save-change'
                                        disabled={
                                            contentTypes?.length === 0 ||
                                            status === dataQueryStatus.LOADING
                                        }
                                        onClick={submitEditedCapsule}
                                    />
                                </ComponentAccessControl>
                            </FadeIn>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

CapsuleSettingsHeader.prototypes = {
    saveSettings: PropTypes.func.isRequired,
};

export default CapsuleSettingsHeader;
