import React, { useState } from "react";
import AnalyticsBarHeader from "./AnalyticsBarHeader/AnalyticsBarHeader";
import PlottedBarChart from "./PlottedBarChart/PlottedBarChart";
import { chartLayout } from "./PlottedBarChart/enum";
import "./AnalyticsBarChart.scss";

const { HORIZONTAL, VERTICAL } = chartLayout;

const AnalyticsBarChart = ({
    chartData,
    chartTitle,
    strokeData,
    overviews,
    isOverlapped,
    timeMetric,
    xDataKey1,
    yDataKey1,
    xDataKey2,
    yDataKey2,
    yAxisLabel1,
    yAxisLabel2,
    xAxisLabel1,
    xAxisLabel2,
    xDomain1,
    xDomain2,
    yDomain1,
    yDomain2,
    hasFreqKey,
    roundUpTooltipVal,
    allStrokeData,
    customUnit1,
    customUnit2,
    useAIAssistance,
    useDefaultOptions,
    tooltipExtraMetrics,
    hasTicketInfo,
    hideModeSelection,
}) => {
    const [chartMode, setChartMode] = useState(
        hideModeSelection ? VERTICAL : HORIZONTAL
    );
    return (
        <div className='analytics__bar__chart'>
            <h3 className='analytics__title'>{chartTitle}</h3>
            {!hideModeSelection && (
                <AnalyticsBarHeader
                    chartMode={chartMode}
                    setChartMode={setChartMode}
                    overviews={overviews}
                />
            )}
            <PlottedBarChart
                yAxisLabel={
                    chartMode === HORIZONTAL ? yAxisLabel1 : yAxisLabel2
                }
                xAxisLabel={
                    chartMode === HORIZONTAL ? xAxisLabel1 : xAxisLabel2
                }
                {...{
                    xDomain1,
                    xDomain2,
                    yDomain1,
                    yDomain2,
                    xDataKey1,
                    yDataKey1,
                    xDataKey2,
                    yDataKey2,
                    hasFreqKey,
                    roundUpTooltipVal,
                    allStrokeData,
                    strokeData,
                    chartData,
                    chartMode,
                    timeMetric,
                    chartTitle,
                    isOverlapped,
                    customUnit1,
                    customUnit2,
                    useAIAssistance,
                    useDefaultOptions,
                    tooltipExtraMetrics,
                    hasTicketInfo,
                }}
            />
        </div>
    );
};

export default AnalyticsBarChart;
