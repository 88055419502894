import React, { useState } from "react";
import { Table } from "components/ui";
import QaDocumentationTableDropdown from "./QaDocumentationTableDropdown/QaDocumentationTableDropdown";
import QaDocumentationEmbeds from "./QaDocumentationEmbeds/QaDocumentationEmbeds";
import QaDocumentationModal from "../QaDocumentationModal/QaDocumentationModal";

const QaDocumentationTable = ({ qaDocumentations, tableIsLoading, errorMssg, handleSuccess }) => {
    const [currentQaDocumentation, setCurrentQaDocument] = useState();
    const [currentAction, setCurrentAction] = useState("");

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        setCurrentAction("");
        setCurrentQaDocument();
    };

    const handleActionModal = (actionType, value) => {
        setCurrentAction(actionType);
        setCurrentQaDocument(value);
        setShowModal(true);
    };

    const headers = ["Question", "Embeds", ""];
    const dataSource = qaDocumentations?.map((qaDocumentation) => ({
        qaDocumentationTitle: `${qaDocumentation.qaDocumentationTitle}`,
        embeds: <QaDocumentationEmbeds elements={qaDocumentation.elements} issues={qaDocumentation?.issues} />,
        actions: (
            <div className='table__action'>
                <QaDocumentationTableDropdown
                    qaDocumentation={qaDocumentation}
                    handleActionModal={handleActionModal}
                />
            </div>
        ),
    }));

    return (
        <>
            <Table
                headers={headers}
                dataSource={dataSource}
                isLoading={tableIsLoading}
                hasErrors={Boolean(errorMssg)}
                tableError={errorMssg}
            />

            {showModal && (
                <QaDocumentationModal
                    {...{
                        currentAction,
                        currentQaDocumentation,
                        closeModal,
                        showModal,
                        handleSuccess,
                    }}
                />
            )}
        </>
    );
};

export default QaDocumentationTable;
