import React from "react";
import { Link } from "react-router-dom";
import TabsLayoutHeading from "components/dashboard/common/TabsLayoutHeading/TabsLayoutHeading";
// import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import FormsPostFormBranchesHeaderButton from "./FormsPostFormBranchesHeaderButton/FormsPostFormBranchesHeaderButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./FormsPostFormBranchesHeader.scss";
import Filter from "components/dashboard/common/Filter/Filter";

const { KB_FORM_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const FormsPostFormBranchesHeader = ({
    handleSearch,
    searchIsLoading,
    clearSearch,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter,searchDefaultValue
}) => {
    return (
        <div>
            <TabsLayoutHeading
                title={`Forms`}
                subTitle={`Add & change forms`}
                extraClass={"large"}
            />

            <div className='form-post-form-header'>
                <div className='row align-items-center'>
                    {/* <PaginationSearch
                        handleSearch={handleSearch}
                        placeholder='Search forms here'
                        isLoading={searchIsLoading}
                        clearSearch={clearSearch}
                    /> */}
                </div>
                <div>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_FORM_SETTING}`]}>
                        <Link to={`/knowledge-base/form/new`}>
                            <FormsPostFormBranchesHeaderButton />
                        </Link>
                    </ComponentAccessControl>
                </div>
            </div>
            <Filter
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                handleSearch={handleSearch}
                handleSortByLastModified={handleSortByLastModified}
                searchPlaceholder='Search forms here'
                searchIsLoading={searchIsLoading}
                clearSearch={clearSearch}
                hideClassFilter
                baseFilter={baseFilter}
                filterOption={filterOption}
                handleBasefilter={handleBasefilter}searchDefaultValue={searchDefaultValue}
            />
        </div>
    );
};

export default FormsPostFormBranchesHeader;
