import { useState, useEffect } from "react";
import "./_switch.scss";

export const Switch = ({ label, id, checked, onChange, useColor = true }) => {
    const [_checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    return (
        <div className='form-check form-switch custom-control-right'>
            <input
                className={`form-check-input ${
                    useColor
                        ? _checked
                            ? "bg-success border-success is-checked"
                            : "bg-secondary border-secondary"
                        : ""
                }`}
                type='checkbox'
                role='switch'
                id={id}
                checked={_checked}
                onChange={onChange}
            />
            <label className='form-check-label' for={id}>
                {label}
            </label>
        </div>
    );
};
