import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Table } from "components/ui";
import ConversationsActions from "../../ConversationsActions/ConversationsActions";
import ConversationsDropdown from "../../ConversationsDropdown/ConversationsDropdown";
import { copyToClipboard, generateConversationLink } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { CONVERSATIONS_PERMS_ACTIONS } from "../../../enums";
import { getErrorMessage } from "utils/helper";
import { apiRoute } from "services";
import API from "services/lib/api";

const {
    COPY_CONVERSATION,
    DELETE_CONVERSATION,
    PIN_CONVERSATION,
    CREATE_CONVERSATION,
} = CONVERSATIONS_PERMS_ACTIONS;

const conversationTableHeader = {
    name: "Name of Issue",
    conversations: "Attached conversation",
    actions: "",
};

const ConversationsTable = ({
    issuesConversations,
    isLoading,
    tableError,
    setCurrentAction,
    setCurrentIssueConversation,
    toggleModal,
    handleSuccess,
}) => {
    const toastMessage = useContext(ToastContext);

    const workspace = useSelector(
        (state) => state?.auth?.user?.workspace
    );

    const handleCopy = (conversation, issueName) => {
        const { conversationId } = conversation;
      
        copyToClipboard(
            generateConversationLink(
                workspace,
                conversationId
            )
        );
        toastMessage(`${issueName} has been copied successfully.`);
    };

    const pinConversation = async (conversationId, isBookmarked, issueName) => {
        try {
            const url = apiRoute?.pinConversation(conversationId);
            const res = await API.put(url, {
                bookmarked: !isBookmarked,
            });

            if (res.status === 200) {
                toastMessage(`${issueName} has been pinned successfully.`);
                handleSuccess();
            }
        } catch (err) {
            toastMessage(getErrorMessage(err));
        }
    };

    const handleActionModal = (action, issueConvo) => {
        switch (action) {
            case COPY_CONVERSATION:
                handleCopy(issueConvo?.conversation, issueConvo?.issueName);
                break;
            case DELETE_CONVERSATION:
            case CREATE_CONVERSATION:
                setCurrentAction(action);
                setCurrentIssueConversation(issueConvo);
                toggleModal(true);
                break;
            case PIN_CONVERSATION:
                pinConversation(
                    issueConvo?.conversation?.conversationId,
                    issueConvo?.conversation?.bookmarked,
                    issueConvo?.issueName
                );
                break;
            default:
                break;
        }
    };

    const tableData = issuesConversations?.map((item) => ({
        name: item?.issueName,
        issuesConversations: (
            <ConversationsActions
                item={item}
                handleActionModal={handleActionModal}
            />
        ),
        actions: (
            <div>
                <ConversationsDropdown
                    conversation={item}
                    handleActionModal={handleActionModal}
                    hasAConversation={item.conversation === null ? false : true}
                    pinned={item?.conversation?.bookmarked}
                />
            </div>
        ),
    }));

    return (
        <Table
            headers={conversationTableHeader}
            dataSource={tableData}
            hasErrors={Boolean(tableError)}
            isLoading={isLoading}
            tableError={tableError}
        />
    );
};

export default ConversationsTable;
