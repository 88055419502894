import ConversationTemplate from "../ConversationTemplate/ConversationTemplate";
import "./ConversationsTemplatesCards.scss";

const templateTypes = {
    upTimeTemplate: "DEFAULT",
    downTimeTemplate: "DOWNTIME",
};

const ConversationsTemplatesCards = ({ templates, handleRefresh }) => {
    let templateList = ["upTimeTemplate", "downTimeTemplate"];
    return (
        <div className='conversation__template__cards'>
            {templateList.map((eachTemplate, key) => (
                <ConversationTemplate
                    template={templates[eachTemplate]}
                    handleRefresh={handleRefresh}
                    key={key}
                    createTemplateType={templateTypes[eachTemplate]}
                />
            ))}
        </div>
    );
};

export default ConversationsTemplatesCards;
