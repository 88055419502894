import { Textarea } from "components/ui";

const AnalyzerSearchInput = ({ handleChange, errors, request, value, handleEnterKeyPress }) => {
    return (
        <>
            <div>
                <Textarea
                    grpClass='mb-3'
                    name='search'
                    label='Keyword'
                    labelClass='text-dark search-hide-label'
                    inputClass='analyzer-query__search'
                    placeholder='Enter keywords here or simply copy and paste an entire conversation...'
                    value={request?.search || value}
                    onChange={handleChange}
                    isErr={errors?.search}
                    errMssg={errors?.search}
                    rows={12}
                    onKeyDown={handleEnterKeyPress}
                />
            </div>
        </>
    );
};

export default AnalyzerSearchInput;
