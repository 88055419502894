const isLiveApp =
    process.env.REACT_APP_STAGE === "production" ||
    process.env.REACT_APP_STAGE === "staging";

const isProductionApp = process.env.REACT_APP_STAGE === "production";


const enviromentConfig = {
    dev: {
        graphql: {
            URL: process.env.REACT_APP_DEV_GRAPHQL_URL,
            CLIENT_KEY: process.env.REACT_APP_DEV_GRAPHQL_CLIENT_KEY,
        },
        apiGateway: {
            BASE_URL: process.env.REACT_APP_DEV_API_BASE_URL,
            CLIENT_KEY: process.env.REACT_APP_DEV_CLIENT_KEY,
            LIVE_CHAT_URL: process.env.REACT_APP_DEV_LIVE_CHAT_URL,
        },
        socketGateway: {
            SOCKET_BASE_URL: process.env.REACT_APP_DEV_SOCKET_URL,
        },
        firebase: {
            CLIENT_KEY: process.env.REACT_APP_STAGING_FIREBASE_KEY,
            AUTH_DOMAIN: process.env.REACT_APP_STAGING_FIREBASE_AUTHDOMAIN,
            PROJECT_ID: process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
            STORAGE_BUCKET:
                process.env.REACT_APP_STAGING_FIREBASE_STORAGEBUCKET,
            MESSAGE_SENDER_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MESSAGE_SENDER_ID,
            APP_ID: process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
            MEASUREMENT_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MEASUREMENT_ID,
            VAPIDKEY: process.env.REACT_APP_STAGING_FIREBASE_VAPIDKEY,
        },
        google: {
            GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            GOOGLE_CLIENT_SCOPES: process.env.REACT_APP_GOOGLE_SCOPES,
        },
    },
    staging: {
        apiGateway: {
            BASE_URL: process.env.REACT_APP_STAGING_API_BASE_URL,
            CLIENT_KEY: process.env.REACT_APP_STAGING_CLIENT_KEY,
            LIVE_CHAT_URL: process.env.REACT_APP_STAGING_LIVE_CHAT_URL,
        },
        graphql: {
            URL: process.env.REACT_APP_STAGING_GRAPHQL_URL,
            CLIENT_KEY: process.env.REACT_APP_STAGING_GRAPHQL_CLIENT_KEY,
        },
        socketGateway: {
            SOCKET_BASE_URL: process.env.REACT_APP_STAGING_SOCKET_URL,
        },
        firebase: {
            CLIENT_KEY: process.env.REACT_APP_STAGING_FIREBASE_KEY,
            AUTH_DOMAIN: process.env.REACT_APP_STAGING_FIREBASE_AUTHDOMAIN,
            PROJECT_ID: process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
            STORAGE_BUCKET:
                process.env.REACT_APP_STAGING_FIREBASE_STORAGEBUCKET,
            MESSAGE_SENDER_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MESSAGE_SENDER_ID,
            APP_ID: process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
            MEASUREMENT_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MEASUREMENT_ID,
            VAPIDKEY: process.env.REACT_APP_STAGING_FIREBASE_VAPIDKEY,
        },
        google: {
            GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            GOOGLE_CLIENT_SCOPES: process.env.REACT_APP_GOOGLE_SCOPES,
        },
    },
    production: {
        apiGateway: {
            BASE_URL: process.env.REACT_APP_API_BASE_URL,
            CLIENT_KEY: process.env.REACT_APP_CLIENT_KEY,
            LIVE_CHAT_URL: process.env.REACT_APP_LIVE_CHAT_URL,
        },
        graphql: {
            URL: process.env.REACT_APP_GRAPHQL_URL,
            CLIENT_KEY: process.env.REACT_APP_GRAPHQL_CLIENT_KEY,
        },
        socketGateway: {
            SOCKET_BASE_URL: process.env.REACT_APP_SOCKET_URL,
        },
        firebase: {
            CLIENT_KEY: process.env.REACT_APP_STAGING_FIREBASE_KEY,
            AUTH_DOMAIN: process.env.REACT_APP_STAGING_FIREBASE_AUTHDOMAIN,
            PROJECT_ID: process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
            STORAGE_BUCKET:
                process.env.REACT_APP_STAGING_FIREBASE_STORAGEBUCKET,
            MESSAGE_SENDER_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MESSAGE_SENDER_ID,
            APP_ID: process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
            MEASUREMENT_ID:
                process.env.REACT_APP_STAGING_FIREBASE_MEASUREMENT_ID,
            VAPIDKEY: process.env.REACT_APP_STAGING_FIREBASE_VAPIDKEY,
        },
        google: {
            GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            GOOGLE_CLIENT_SCOPES: process.env.REACT_APP_GOOGLE_SCOPES,
        },
    },
};

const config = enviromentConfig[process.env.REACT_APP_STAGE];

// eslint-disable-next-line import/no-anonymous-default-export
export { config as default, isLiveApp, isProductionApp };
