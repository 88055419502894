import { Table } from "components/ui";
import UpdatesTableAction from "../../../common/UpdatesTableAction/UpdatesTableAction";
import {
    canned_resource_names,
    canned_resource_style,
} from "../../../EmailUpdates/EmailFollowups/EmailFollowupsTable/EmailFollowupsTable";
import CannedUpdateDuration from "../../../common/CannedUpdateDuration/CannedUpdateDuration";

const ResponseFollowupsTable = ({ handleActionModal, cannedTasks }) => {
    const tableData = cannedTasks?.map?.(
        ({
            agentUpdateTaskName,
            initiationSchedule,
            agentUpdateTaskType,
            ...rest
        }) => {
            return {
                name: (
                    <div>
                        {agentUpdateTaskName}{" "}
                        <span
                            className={`${canned_resource_style[agentUpdateTaskType]}`}>
                            {canned_resource_names[agentUpdateTaskType]} update
                        </span>
                    </div>
                ),
                attachedGuides: (
                    <CannedUpdateDuration duration={initiationSchedule} />
                ),
                actions: (
                    <UpdatesTableAction
                        editText={"Edit"}
                        hideView
                        handleModalAction={(actionType) =>
                            handleActionModal(actionType, {
                                agentUpdateTaskName,
                                initiationSchedule,
                                agentUpdateTaskType,
                                ...rest,
                            })
                        }
                    />
                ),
            };
        }
    );

    return (
        <Table
            headers={["Name", "Duration", "Actions"]}
            dataSource={tableData}
        />
    );
};

export default ResponseFollowupsTable;
