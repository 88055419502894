import * as types from "./types";

export const saveTicketsMessages = (data) => (dispatch) =>
    dispatch({ type: types.UPDATE_TICKET_MESSAGES, payload: data });

export const deleteTicketsMessages = (data) => (dispatch) =>
    dispatch({ type: types.DELETE_TICKET_MESSAGE, payload: data });

export const clearTicketMessages = (data) => (dispatch) =>
    dispatch({ type: types.CLEAR_TICKET_MESSAGES, payload: data });

export const updateTicketMessage = (data) => (dispatch) =>
    dispatch({ type: types.UPDATE_TICKET_MESSAGE, payload: data });

export const setTicketMessages = (data) => (dispatch) =>
    dispatch({ type: types.SET_TICKET_MESSAGES, payload: data });

export const setActiveTicket = (data) => (dispatch) =>
    dispatch({ type: types.SET_ACTIVE_TICKET, payload: data });

export const setActiveTicketLatestActivity = (data) => (dispatch) =>
    dispatch({ type: types.SET_ACTIVE_TICKET_LATEST_ACTIVITY, payload: data });

export const setTicketToLeavePhase = (data) => (dispatch) =>
    dispatch({ type: types.SET_TICKET_TO_LEAVE_PHASE, payload: data });

export const setSelectedCustomerMessage = (data) => (dispatch) =>
    dispatch({ type: types.SET_SELECTED_CUSTOMER_MESSAGE, payload: data });