import PropTypes from "prop-types";
import { TAG_LIST } from "./enum";
import "./TagList.scss";

const TagList = ({ setSelectedTag, selectedTag }) => {
    return (
        <>
            <h5 className='tag__list__header'>Add features from a tag</h5>
            <div className='mb-3'>
                {Object.entries(TAG_LIST).map(([key, value], i) => (
                    <div
                        key={i}
                        className={`tags ${
                            value === selectedTag ? "active__tags" : ""
                        }`}
                        onClick={() => setSelectedTag(value)}>
                        {value}
                    </div>
                ))}
            </div>
        </>
    );
};

TagList.prototypes = {
    setSelectedTag: PropTypes.func.isRequired,
    selectedTag: PropTypes.object.isRequired,
};

export default TagList;
