export const taskTypes = Object.freeze({
    TIME_BASED: "TIME BASED",
    SCENARIO_BASED: "SCENARIO BASED",
});

export const SAVE_BUTTON = "SAVE_BUTTON";

export const TASK_REMINDER_ACTIONS = Object.freeze({
    EDIT_REMINDER: "EDIT_REMINDER",
    DELETE_REMINDER: "DELETE_REMINDER",
});
