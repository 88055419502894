import { useState, useContext } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Input, Textarea, Button } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const ProductSetupForm = ({ handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [request, updateRequest] = useState({
        productName: "",
        productDesc: "",
    });
    const [errors, setErrors] = useState({});
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createProduct = async () => {
        try {
            setErrorMsg();
            setLoading(true);

            const res = await API.post(apiRoute.addWorkspaceProduct, {
                ...request,
            });
            if (res.status === 201) {
                const { message, data } = res.data;
                toastMessage(message);
                handleSuccess(data);
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);
        if (formisValid) {
            createProduct();
        }
        setErrors(errors);
    };

    const { productName, productDesc } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='productName'
                label='Product Name'
                labelClass='text-dark fw-medium'
                id='productName'
                placeholder='Enter product name'
                value={productName}
                onChange={handleChange}
                isErr={errors?.productName}
                errMssg={errors?.productName}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                name='productDesc'
                label='Describe product'
                labelClass='text-dark fw-medium'
                id='productDesc'
                placeholder='Add a description to this product you are creating'
                maxLength={200}
                value={productDesc}
                onChange={handleChange}
                isErr={errors?.productDesc}
                errMssg={errors?.productDesc}
            />
            <Button
                type='submit'
                text='Save product'
                loading={loading}
                classType='primary'
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default ProductSetupForm;
