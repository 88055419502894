const EmptyVariantData = [
    {
        Variant: (
            <div>
                <span>No variant created</span>
            </div>
        ),
        metadata: "No metadata has been added yet",
        Actions: <></>,
    },
];

export default EmptyVariantData;
