export const analyticsTeamBacklogDefaultOptions = [
    {
        label: "Number of Backlogs",
        value: "undefined",
        name: "Number of Backlogs",
        color: "#3b94fc",
    },
];

export const analyticsTeamBacklogExtraTooltipMetrics = [
    {
        label: "External Backlog Fix Count",
        value: "externalBackLogsFixCount",
    },
    {
        label: "Instructional Backlog Fix Count",
        value: "instructionalBackLogsFixCount",
    },
    {
        label: "Internal Backlog Fix Count",
        value: "internalBackLogsFixCount",
    },
];
