export const BRANCH_CANNED_TYPES = {
    CANNED_EMAIL: "CANNED_EMAIL",
    CANNED_RESPONSE: "CANNED_RESPONSE",
    MENTIONS: "MENTIONS",
};

export const BRANCH_CANNED_ACTIONS = {
    EDIT: "EDIT",
    ADD: "ADD",
};

export const BRANCH_CANNED_TYPES_NAMES = {
    CANNED_EMAIL: "canned email",
    CANNED_RESPONSE: "canned reply",
    MENTIONS: "canned mention",

    CANNED_EMAIL_S: "Canned email",
    CANNED_RESPONSE_S: "Canned reply",
    MENTIONS_S: "Canned mention",
};

export const BRANCH_UPDATE_TYPES_NAMES = {
    CANNED_EMAIL: "email update",
    CANNED_RESPONSE: "response update",
    MENTIONS: "mention update",

    CANNED_EMAIL_S: "Email update",
    CANNED_RESPONSE_S: "Response update",
    MENTIONS_S: "Mention update",
};

export const BRANCH_CANNED_MESSAGE_OPTIONS = {
    ADD_NEW: "ADD_NEW",
    USE_EXISTING: "USE_EXISTING",
    NEW_UPDATE: "NEW_UPDATE",
};

export const CANNED_RESOURCE_ROUTE_NAMES = {
    CANNED_EMAIL: "email",
    CANNED_RESPONSE: "response",
    MENTIONS: "mention",
};
