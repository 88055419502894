import { Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TMTagDetailElement from "./TMTagDetailElement/TMTagDetailElement";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const TMTagDetail = ({
    messageTagId,
    selectedTicket,
    lastMessageContent,
    setCannedMessage,
}) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [activeMessageTagData, setActiveMessageTagData] = useState();

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <TMTagDetailElement
                        selectedTicket={selectedTicket}
                        activeMessageTagData={activeMessageTagData}
                        lastMessageContent={lastMessageContent}
                        setCannedMessage={setCannedMessage}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={retrieveMessageTag}
                    />
                );

            default:
                return "";
        }
    };

    const retrieveMessageTag = async () => {
        try {
            if (messageTagId) {
                setStatus(LOADING);
                setErrorMssg();

                const url = apiRoute?.getMessageTag(messageTagId);
                const res = await API.get(url);
                if (res.status === 200) {
                    const { data } = res.data;
                    setActiveMessageTagData(data);
                    setStatus(DATAMODE);
                }
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (messageTagId) {
            retrieveMessageTag();
        } else {
            setStatus(IDLE);
        }
        //eslint-disable-next-line
    }, [messageTagId]);

    return <div className='h-100'>{renderBasedOnStatus()}</div>;
};

export default TMTagDetail;
