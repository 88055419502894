import { DatePicker } from "components/ui";
import "./TicketSchedule.scss";

const TicketSchedule = ({ request, handleRequestValues }) => {
    return (
        <div className='ticket__schedule'>
            <h3 className='ticket__header fw-medium'>Ticket Schedule</h3>
            <div className='d-flex flex-wrap col-7 gap-3'>
                <div className='ticket__schedule--date'>
                    <div className='ticket__schedule--date__title'>
                        Weekdays
                    </div>
                    <div className='ticket__schedule--date__date__picker__container'>
                        <DatePicker
                            type='time'
                            id='weekDayScheduleStartTime'
                            className='border-0 p-1'
                            defaultValue={request.weekDayScheduleStartTime}
                            onChange={(e) =>
                                handleRequestValues(e.target.id, e.target.value)
                            }
                        />{" "}
                        <span>-</span>
                        <DatePicker
                            type='time'
                            id='weekDayScheduleEndTime'
                            className='border-0 p-1'
                            defaultValue={request.weekDayScheduleEndTime}
                            onChange={(e) =>
                                handleRequestValues(e.target.id, e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className='ticket__schedule--date'>
                    <div className='ticket__schedule--date__title'>
                        Weekends
                    </div>
                    <div className='ticket__schedule--date__date__picker__container'>
                        <DatePicker
                            type='time'
                            id='weekendScheduleStartTime'
                            className='border-0 p-1'
                            defaultValue={request.weekendScheduleStartTime}
                            onChange={(e) =>
                                handleRequestValues(e.target.id, e.target.value)
                            }
                        />
                        <span>-</span>
                        <DatePicker
                            type='time'
                            id='weekendScheduleEndTime'
                            className='border-0 p-1'
                            defaultValue={request.weekendScheduleEndTime}
                            onChange={(e) =>
                                handleRequestValues(e.target.id, e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketSchedule;
