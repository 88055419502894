import AnalyticsQATabs from "../common/AnalytcisQATabs/AnalyticsQATabs";
import "./AnalyticsQAVetAgents.scss";
import AnalyticsQAVAgentsSuggestions from "./AnalyticsQAVAgentsSuggestions/AnalyticsQAVAgentsSuggestions";

const AnalyticsQAVetAgents = ({ match }) => {
    const { params } = match || {};
    const agentId = params[0];
    return (
        <>
            <AnalyticsQATabs />
            <AnalyticsQAVAgentsSuggestions agentId={agentId} />
        </>
    );
};

export default AnalyticsQAVetAgents;
