import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import "./ImageCropUpload.scss";

const ImageCropUpload = ({ setImageSrc, updateRequest,setImage }) => {
    const handleImageUpload = ({ target: { files } }) => {
        const image = files[0];

        if (image) {
            updateRequest?.((prev) => ({ ...prev, mainBranchImage: image }));
            setImageSrc?.(URL.createObjectURL(image));
            setImage?.(image)
        }
    };

    return (
        <div className='image__crop__upload--container'>
            <label htmlFor='image--upload'>
                <div className='image__crop__upload--action'>
                    <ReactSVG src={imageLinks?.icons?.cloud} />
                    <span className='image__crop__upload--call__to__action'>
                        Click to upload Image
                    </span>
                </div>
                <input
                    type='file'
                    accept='image/png,image/jpeg,image/jpg'
                    id='image--upload'
                    onChange={handleImageUpload}
                    hidden={true}
                />
            </label>
        </div>
    );
};

export default ImageCropUpload;
