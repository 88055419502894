import PropTypes from "prop-types";
import { ISSUES_PERMS_ACTIONS } from "../enum";
import CreateIssueModal from "./CreateIssueModal/CreateIssueModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { EDIT_ISSUE, DELETE_ISSUE } = ISSUES_PERMS_ACTIONS;

const IssuesModal = ({
    currentAction,
    currentIssue,
    closeModal,
    showModal,
    handleSuccess,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT_ISSUE:
                return (
                    <CreateIssueModal
                        closeModal={closeModal}
                        currentIssue={currentIssue}
                        handleSuccess={handleSuccess}
                        showModal={showModal}
                        isEdit={true}
                    />
                );
            case DELETE_ISSUE:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${currentIssue?.issueName}`}
                        show={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.deleteIssue(currentIssue?.issueId)}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnAction()}</>;
};

IssuesModal.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    getIssues: PropTypes.func.isRequired,
};

export default IssuesModal;
