import React from "react";

const RoleDescription = ({ role }) => {
    return (
        <div className='row me-0 ms-0'>
            <div className='col-10 offset-1'>
                <h1 className='fw-bold'>{role.displayName}</h1>
                <p className='my-5'>{role.roleDescription}</p>
            </div>
        </div>
    );
};

export default RoleDescription;
