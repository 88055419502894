import { useState } from "react";
import { formElementBlocks } from "../../../BuilderElementsList/data";
import FormBuilderBranchInstruction from "./FormBuilderBranchInstruction/FormBuilderBranchInstruction";
import FormBuilderInputHeading from "./FormBuilderInputHeading/FormBuilderInputHeading";
import FormBuilderInputLabel from "./FormBuilderInputLabel/FormBuilderInputLabel";
import FormBuilderInputOptions from "./FormBuilderInputOptions/FormBuilderInputOptions";
import FormBuilderInputRules from "./FormBuilderInputRules/FormBuilderInputRules";
import FormBuilderInputSubtitle from "./FormBuilderInputSubtitle/FormBuilderInputSubtitle";
import FormAttachment from "./FormAttachment/FormAttachment";

const FormBuilderInputElement = ({
    id,
    elementType,
    branch,
    branchId,
    errors,
    elementOptions,
    enableSubTitleText,
    customRules,
    removeInputRule,
    acceptNewInputRule,
    handleConstraintEdit,
    handleInputDelete,
    handleFormInputChange,
    handleSubTextStatusChange,
    handleOptionAdd,
    handleOptionTextChange,
    handleOptionDelete,
    toggleBranchModal,
    elementLabel,
    subText,
    handleFileAttachmentChange,
    handleFileAttachmentDelete,
    media,
    handleValidateCustomRule,
    handleRemoveCustomRuleError,
    ...rest
}) => {
    const [showRules, toggleRulesVisibility] = useState(false);
    const [activeRule, setActiveRule] = useState({
        ruleCode: "",
        ruleConstraint: "",
        ruleIndex: "",
    });
    const [showConstraintView, toggleConstraintView] = useState(false);
    const [constraintKeysData, setConstraintKeysData] = useState({});
    const existingMedia = media.map((media) => ({
        ...media,
        id,
        hasUploaded: true,
    }));
    const [uploads, updateUploads] = useState(existingMedia);

    const handleUploadUpdate = (fileDetails) => {
        updateUploads([...uploads, fileDetails]);
    };

    const handleCustomRuleSelect = (ruleCode, ruleConstraint, ruleIndex) => {
        const elementRules = formElementBlocks?.[elementType]?.rules;
        const definedConstraint = elementRules?.find(
            ({ value }) => value === ruleCode
        )?.ruleConstraint;
        if (definedConstraint) {
            setActiveRule({ ...activeRule, ruleConstraint, ruleIndex });
            setConstraintKeysData(definedConstraint);
            toggleConstraintView(true);
        }
        setActiveRule((prev) => ({ ...prev, ruleCode, ruleIndex }));
    };

    const removeRuleConstraint = async () => {
        await setActiveRule({
            ruleCode: "",
            ruleConstraint: "",
            ruleIndex: "",
        });
        await toggleConstraintView(false);
    };

    // const toggleRulesConstraints = (elementId, ruleCode, ruleConstraint) => {
    //     acceptNewInputRule(elementId, ruleCode);
    //     setConstraintKeysData({ elementId, ruleCode, ruleConstraint })
    //     toggleRulesVisibility(true)
    //     toggleConstraintView(true)
    // }

    const handleNewInputRule = (elementId, ruleCode, ruleIndex) => {
        const elementRules = formElementBlocks?.[elementType]?.rules;
        const definedConstraint = elementRules?.find(
            ({ value }) => value === ruleCode
        )?.ruleConstraint;
        acceptNewInputRule(elementId, ruleCode);
        setActiveRule({ ruleCode, ruleConstraint: "", ruleIndex });
        toggleRulesVisibility(true);
        if (definedConstraint) {
            toggleConstraintView(true);
            setConstraintKeysData(definedConstraint);
        } else {
            toggleConstraintView(false);
            setConstraintKeysData({});
        }
    };

    const saveCloseConstraintsForm = (value) => {
        handleConstraintEdit(id, activeRule?.ruleCode, value);
        toggleConstraintView(false);
    };

    return (
        <div className='formbuilder__input'>
            <FormBuilderInputHeading
                id={id}
                elementType={elementType}
                acceptNewInputRule={handleNewInputRule}
                customRules={customRules}
                handleSubTextStatusChange={handleSubTextStatusChange}
                enableSubTitleText={enableSubTitleText}
                handleInputDelete={handleInputDelete}
                toggleBranchModal={toggleBranchModal}
                handleFileAttachmentChange={handleFileAttachmentChange}
                handleUploadUpdate={handleUploadUpdate}
                {...rest}
            />
            <div className='formbuilder__input--body'>
                {elementType === "END_FORM" ? (
                    <FormBuilderBranchInstruction
                        elementId={id}
                        errors={errors}
                        branch={branch}
                        branchId={branchId}
                    />
                ) : (
                    <>
                        <FormBuilderInputLabel
                            elementId={id}
                            handleFormInputChange={handleFormInputChange}
                            errors={errors}
                            elementLabel={elementLabel}
                        />
                        {enableSubTitleText && (
                            <FormBuilderInputSubtitle
                                elementId={id}
                                handleSubTextStatusChange={
                                    handleSubTextStatusChange
                                }
                                handleFormInputChange={handleFormInputChange}
                                errors={errors}
                                subText={subText}
                            />
                        )}
                        <FormAttachment
                            uploads={uploads}
                            updateUploads={updateUploads}
                            handleFileAttachmentDelete={
                                handleFileAttachmentDelete
                            }
                        />
                        <FormBuilderInputRules
                            elementId={id}
                            elementType={elementType}
                            customRules={customRules}
                            showConstraintView={showConstraintView}
                            toggleConstraintView={toggleConstraintView}
                            activeRule={activeRule}
                            constraintKeysData={constraintKeysData}
                            saveCloseConstraintsForm={saveCloseConstraintsForm}
                            removeInputRule={removeInputRule}
                            removeRuleConstraint={removeRuleConstraint}
                            handleConstraintEdit={handleConstraintEdit}
                            setActiveRule={handleCustomRuleSelect}
                            showRules={showRules}
                            toggleRulesVisibility={toggleRulesVisibility}
                            errors={errors}
                            handleValidateCustomRule={handleValidateCustomRule}
                            handleRemoveCustomRuleError={
                                handleRemoveCustomRuleError
                            }
                        />
                    </>
                )}

                {elementType === "MULTISELECT" && (
                    <FormBuilderInputOptions
                        elementId={id}
                        handleOptionAdd={handleOptionAdd}
                        handleOptionDelete={handleOptionDelete}
                        elementOptions={elementOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        errors={errors}
                    />
                )}
            </div>
        </div>
    );
};

export default FormBuilderInputElement;
