import BranchView from "components/dashboard/Branches/BranchView/BranchView";
import BranchesTimeLineHeader from "components/dashboard/TrainSAM/SAM/BranchCollectionView/BranchesTimeLine/BranchesTimeLineHeader/BranchesTimeLineHeader";
import BranchesList from "components/dashboard/TrainSAM/SAM/Conversations/branches/BranchesList/BranchesList";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AgentAViewGuideBranches = ({
    guideId,
    steps,
    lastToggledBranchId,
    selectStepId,
    selectedStepId,
    handleRefresh,
}) => {
    const [status, setStatus] = useState();
    const [errorMssg, setErrorMssg] = useState();
    const [selectedStepDetails, setStepDetails] = useState({});

    const conversationBranches = steps?.map?.(
        ({ guideFlow, guideFlowOptions, ...rest }) => {
            return {
                branchId: guideFlow?.guideFlowId,
                branchName: guideFlow?.guideFlowName,
                mainInstruction: guideFlow?.mainInstruction,
                ...rest,
                ...guideFlow,
                branchOptions: guideFlow?.guideFlowOptions,
            };
        }
    );

    const getSelectedStepDetails = async (httpRequest) => {
        try {
            setErrorMssg();
            setStatus(LOADING);
            const url = apiRoute.getGuideFlow(selectedStepId);
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                setStepDetails(data);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSuccess = () => {
        getSelectedStepDetails();
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return <Empty text={"No Branches Added Yet"} />;
            case ERROR:
                return <ErrorView handleRetry={getSelectedStepDetails} />;
            case DATAMODE:
                return (
                    <BranchView
                        errorMssg={errorMssg}
                        status={status}
                        branch={{
                            branchId: selectedStepId,
                            ...selectedStepDetails,
                        }}
                        step={selectedStepDetails}
                        isAgentGuideView
                        handleSuccess={handleSuccess}
                        handleRefresh={(guideFlowId) => {
                            handleRefresh(guideFlowId);
                            handleSuccess();
                        }}
                        guideId={guideId}
                        showCannedMessages={true}
                        showRemoveBranchOption={false}
                        // existingOptionConnections={
                        //     existingOptionConnections
                        // }
                    />
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        if (selectedStepId) {
            getSelectedStepDetails();
        } else {
            setStatus(NULLMODE);
        }
        // eslint-disable-next-line
    }, [selectedStepId]);

    return (
        <div className='mt-5'>
            <BranchesTimeLineHeader
                referenceData={{
                    guideId,
                }}
                handleRefresh={(guideFlowId) => {
                    handleRefresh(guideFlowId);
                    handleSuccess();
                }}
                collectionBranches={conversationBranches}
                isAgentGuideView={true}
                guideId={guideId}
            />
            <div className='mt-3'>
                <BranchesList
                    branches={conversationBranches}
                    handleBranchSelect={(step) => selectStepId(step)}
                    selectedBranch={{ branchId: selectedStepId }}
                    isAgentGuideView
                    lastToggledBranchId={lastToggledBranchId}
                    // showEmptyOptionLabel
                />
                {renderBasedOnStatus()}
            </div>
        </div>
    );
};

export default AgentAViewGuideBranches;
