import React, { useContext } from "react";
import { connect } from "react-redux";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import "./ComponentAccessControl.scss";

const { ERROR } = dataQueryStatus;

const ComponentAccessControl = ({
    baseResources,
    children,
    permissions,
    ignorePermissions,
}) => {
    const toastMessage = useContext(ToastContext);

    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    const handleAccessControl = (e) => {
        e.stopPropagation();
        e.preventDefault();

        toastMessage("Forbidden, contact your administrator for access", ERROR);
    };

    return (
        <>
            {permissionCodes?.some((code) => baseResources?.includes(code)) ||
            ignorePermissions ? (
                <>{children}</>
            ) : (
                <div
                    onClick={(e) => handleAccessControl(e)}
                    className='component__access__control'>
                    <div className='component__access__control--child'>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
    }),
    {}
)(ComponentAccessControl);
