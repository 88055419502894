export const FileInput = ({
	name,
	id,
	label,
	labelClass,
	grpClass,
	isErr,
	errMssg,
	onChange,
	fileType,
	labelName,
	fileSize = 2,
	fileValue,
	extraMssg,
}) => {
	return (
		<div className={`${grpClass || ""}`}>
			<label htmlFor={id} className={`cursor-pointer ${labelClass || ""}`}>
				{label}
			</label>
			<input
				type="file"
				id={id}
				name={name}
				data-label={labelName}
				data-size={fileSize}
				data-value={fileValue}
				value=""
				className={`d-none ${isErr ? "is-invalid" : ""}`}
				accept={fileType}
				onChange={onChange}
			/>
			{extraMssg ? (
				<div className="text-muted extra-input-mssg">{extraMssg}</div>
			) : null}
			<div className="invalid-feedback">{errMssg}</div>
		</div>
	);
};
