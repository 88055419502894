import { ReactSVG } from "react-svg";
import "./TicketAACardHeading.scss";
import asset from "assets/images";
import { Checkbox } from "components/ui";

const TicketAACardHeading = ({ title, onClick, isOpen, showDot, showMark, showNone }) => {
    return (
        <div className='ticket-aa-card-heading' onClick={onClick}>
            <h5>
                {title}
                {!showNone && <>
                    {showDot && (
                        <span className='ticket-aa-card-heading__dot'></span>
                    )}
                    {showMark && <Checkbox checked />}
                </>
                }
            </h5>
            <ReactSVG

                src={isOpen ? asset.icons.upside : asset.icons.downside}
            />
        </div>
    );
};

export default TicketAACardHeading;
