import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import config from "config/config"
import { retriveAccessToken } from "storage/cookieStorage";
// import { retriveAccessToken } from "storage/cookieStorage";

const httpLink = from([
  new HttpLink({ uri: config?.graphql.URL }),
]);

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "Authorization": "Bearer " + retriveAccessToken(),
      "x-request-client-key": config?.graphql.CLIENT_KEY,
      "x-hasura-access-key": config?.graphql.CLIENT_KEY
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default client
