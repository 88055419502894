import PropTypes from "prop-types";
import AddButton from "components/ui/Button/AddButton/AddButton";

const ClassificationButton = ({ setShowModal }) => {
    return (
        <div>
            <AddButton
                btnText='New classifications'
                onClick={() => setShowModal(true)}
            />
        </div>
    );
};

ClassificationButton.prototypes = {
    setShowModal: PropTypes.func.isRequired,
};

export default ClassificationButton;
