import Filter from "components/dashboard/common/Filter/Filter";

import { getCapsulesRelatedEntries } from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { saveProducts, setSelectedProduct } from "store/product/actions";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

const { ERROR, DATAMODE } = dataQueryStatus;

const FollowUpFilter = ({
    followUpCollections,
    updateFilteredFCollections,
    updateFollowUpCollections,
    solutionCapsuleClass,
    filteredFCollection,
    updatePageFilters,
    filters,
    setTableLoading,
    setStatus,
    setErrorMssg,
}) => {
    const [selectedRelatedClass, selectRelatedClass] = useState();
    const [relatedClasses, setRelatedClasses] = useState([]);

    const { products, selectedProduct } = useSelector((state) => state.product);

    const selectedProductId = selectedProduct
        ? selectedProduct?.productId
        : products[0]?.productId;

    const dispatch = useDispatch();

    const retrieveProducts = async (httpRequest) => {
        try {
            const res = await API.get(apiRoute.allWorkspaceProducts, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    const allProducts = data;
                    dispatch(saveProducts(data));
                    if (selectedProductId) {
                        const defaultProduct =
                            selectedProductId &&
                            allProducts.find((product) => {
                                return product.productId === selectedProductId;
                            });
                        if (defaultProduct) {
                            dispatch(
                                setSelectedProduct({
                                    ...data[0],
                                    value: data[0].productId,
                                })
                            );
                            return;
                        }
                        setErrorMssg("Product Not Found");
                        setStatus(ERROR);
                    } else {
                        dispatch(
                            setSelectedProduct({
                                ...data[0],
                                value: data[0]?.productId,
                            })
                        );
                    }
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getSelectedCapsuleEntries = async () => {
        try {
            setTableLoading(true);
            const capsuleEntries = await getCapsulesRelatedEntries(
                solutionCapsuleClass,
                selectedRelatedClass,
                selectedProductId,
                true,
                filters?.limit,
                filters?.offset,
                filters?.alphabeticalOrder,
                filters?.dateCreatedOrder,
                filters?.search
            );

            let updatedFCollections = capsuleEntries?.map(
                ({ solutionid, subCapsules, ...rest }) => {
                    let followupColls = followUpCollections?.find(
                        (followup) => followup?.solutionid === solutionid
                    );
                    return { ...rest, ...followupColls, subCapsules };
                }
            );

            updateFilteredFCollections(updatedFCollections);
            updateFollowUpCollections(updatedFCollections);
            setTableLoading(false);
            setStatus(DATAMODE);
        } catch (err) {
            setTableLoading(false);
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (selectedRelatedClass && selectedProductId) {
            getSelectedCapsuleEntries();
        }
        //eslint-disable-next-line
    }, [selectedRelatedClass, selectedProductId]);

    const handleSortByAlpha = (value) => {
        updatePageFilters({
            ...filters,
            alphabeticalOrder: value,
            dateCreatedOrder: null,
        });
    };

    const handleSortByDate = (value) => {
        updatePageFilters({
            ...filters,
            dateCreatedOrder: value,
            alphabeticalOrder: null,
        });
    };

    const handleSortByLastModified = (value) => {
        updatePageFilters({
            ...filters,
            dateCreated: null,
            alphabetical: null,
            lastModified: value,
        });
    };

    useEffect(() => {
        const { parents, children } = solutionCapsuleClass;
        if (parents && children) {
            setRelatedClasses([...children, ...parents]);
        }
    }, [solutionCapsuleClass]);

    const handleSearchEntries = (search) => {
        updatePageFilters({
            ...filters,
            search,
        });
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        retrieveProducts(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Filter
                capsuleEntries={followUpCollections}
                handleSelectRelatedClass={selectRelatedClass}
                handleFilteredEntries={updateFilteredFCollections}
                filteredCapsuleEntries={filteredFCollection}
                handleSortByAlpha={handleSortByAlpha}
                handleSortByDate={handleSortByDate}
                relatedClasses={relatedClasses}
                handleSearch={handleSearchEntries}
                handleSortByLastModified={handleSortByLastModified}
                searchPlaceholder='Search followup collections here'
            />
        </>
    );
};

export default FollowUpFilter;
