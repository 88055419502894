import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Modal } from "components/ui";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import DeleteBranchModalActions from "./DeleteBranchModalActions/DeleteBranchModalActions";
import DeleteBranchesList from "./DeleteBranchesList/DeleteBranchesList";
import DeleteBranchModalHeader from "./DeleteBranchModalHeader/DeleteBranchModalHeader";
import "./DeleteBranchModal.scss";

const { ERROR } = dataQueryStatus;

const stages = {
    INITIAL: "INITIAL",
    REMOVE_BRANCHES: "REMOVE_BRANCHES",
    REMOVE_CURRENT_BRANCH: "REMOVE_CURRENT_BRANCH",
};

const { INITIAL, REMOVE_BRANCHES, REMOVE_CURRENT_BRANCH } = stages;

const DeleteBranchModal = ({
    show,
    closeModal,
    referenceData,
    handleRefresh,
    conversationId,
    collectionId,
    isAgentGuideView,
    guideFlowId,
}) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);
    const [stage, setStage] = useState(INITIAL);

    const [branchConvoCollections, setBranchConvoCollections] = useState({
        collections: [],
        conversations: [],
    });

    const removeBranch = async () => {
        try {
            setLoading(true);
            const { branchId } = referenceData;

            const url = conversationId
                ? apiRoute?.removeBranchFromConversation(
                      conversationId,
                      branchId
                  )
                : apiRoute?.removeBranchFromCollection(collectionId, branchId);

            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleRefresh();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const destroyGuideFlow = async () => {
        try {
            setLoading(true);
            const url = apiRoute?.deleteGuideFlow(guideFlowId);

            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleRefresh();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const destroyBranch = async () => {
        try {
            setLoading(true);
            const { branchId } = referenceData;
            const url = apiRoute?.destroyBranch(branchId);

            const res = await API.delete(url);

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleRefresh();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const getBranchConvoCollections = async () => {
        try {
            setLoading(true);
            const { branchId } = referenceData;
            const url = apiRoute?.getBranchConvoCollections(branchId);

            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                const { collections, conversations } = data;

                const totalConvoCollections =
                    collections.length + conversations.length;
                if (totalConvoCollections <= 1) {
                    destroyBranch();
                } else {
                    setBranchConvoCollections({
                        collections,
                        conversations,
                    });
                    setStage(REMOVE_BRANCHES);
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const renderBasedOnStage = () => {
        switch (stage) {
            case INITIAL:
                return (
                    <DeleteBranchModalHeader
                        description={"You are about to delete this branch."}
                    />
                );
            case REMOVE_BRANCHES:
                return (
                    <div>
                        <DeleteBranchModalHeader
                            description={
                                "This branch cannot be deleted because it is connected to some conversations or collections"
                            }
                        />
                        <DeleteBranchesList
                            branchConvoCollections={branchConvoCollections}
                        />
                    </div>
                );
            case REMOVE_CURRENT_BRANCH:
                return (
                    <DeleteBranchModalHeader
                        description={`You are about to remove this branch from this ${
                            collectionId ? "collection" : "conversation"
                        }`}
                    />
                );
            default:
                return "";
        }
    };

    const handleContinue = () => {
        if (isAgentGuideView) {
            return destroyGuideFlow();
        }
        if (stage === INITIAL) {
            getBranchConvoCollections();
        }

        if (stage === REMOVE_BRANCHES) {
            setStage(REMOVE_CURRENT_BRANCH);
        }

        if (stage === REMOVE_CURRENT_BRANCH) {
            removeBranch();
        }
    };

    const handleClose = () => {
        closeModal();
    };

    const actionButtonText =
        stage === INITIAL
            ? "Delete"
            : stage === REMOVE_BRANCHES
            ? "Remove branch"
            : "Remove";

    return (
        <>
            <Modal
                contentClassName={"delete-branch-modal"}
                show={show}
                closeModal={closeModal}>
                {renderBasedOnStage()}
                <DeleteBranchModalActions
                    loading={loading}
                    handleCancel={handleClose}
                    handleContinue={handleContinue}
                    deleteText={actionButtonText}
                />
            </Modal>
        </>
    );
};

export default DeleteBranchModal;
