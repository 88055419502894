import TicketMentions from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketMentions/TicketMentions";
import AnalyticsQAVATitle from "../../AnalyticsQAVAgentsSuggestions/AnalyticsQAVATitle/AnalyticsQAVATitle";

const AnalyticsQAVAgentsMentions = ({ ticketData }) => {
    return (
        <section className='col-4 ps-0'>
            <div className='analytics-qava-convos__header'>
                <AnalyticsQAVATitle title={"Mentions"} isActive hideIcon />
            </div>
            <div className='analytics-qava-convos__mentions'>
                <TicketMentions
                    selectedTicket={ticketData}
                    fetchAllTickets={() => console.log("")}
                />
            </div>
        </section>
    );
};

export default AnalyticsQAVAgentsMentions;
