import AddCannedMessageOption from "components/dashboard/Branches/BranchView/BranchViewElement/BranchCannedMessages/modal/AddCannedMessageModal/AddCannedMessageOption/AddCannedMessageOption";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";

const NewUpdateForm = ({
    handleSuccess,
    cannedResourceType,
    selectedCannedUpdate,
    closeModal,
    isEdit,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const toastMessage = useContext(ToastContext);

    const [request, setRequest] = useState({
        agentUpdateName: "",
        agentUpdateType: cannedResourceType,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleUpdateRequest = (key, value) => {
        handleChange({ target: { name: key, value } });
    };

    const createCannedUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.createCannedUpdate;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editCannedUpdate = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.cannedUpdate(
                selectedCannedUpdate?.agentUpdateId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? editCannedUpdate() : createCannedUpdate();
        }

        setErrors(formErrors);
    };

    useEffect(() => {
        if (isEdit && selectedCannedUpdate) {
            setRequest(selectedCannedUpdate);
        }
    }, [selectedCannedUpdate, isEdit]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            {isEdit && (
                <AddCannedMessageOption
                    title={"Update type"}
                    cannedModalType={request?.agentUpdateType}
                    setCannedModalType={(value) =>
                        handleUpdateRequest("agentUpdateType", value)
                    }
                />
            )}
            <Input
                grpClass='mb-3 mt-3'
                type='text'
                name='agentUpdateName'
                label='Title'
                labelClass='text-dark fw-medium'
                id='agentUpdateName'
                placeholder='Enter title'
                value={request?.agentUpdateName}
                onChange={handleChange}
                isErr={errors?.agentUpdateName}
                errMssg={errors?.agentUpdateName}
                autoFocus={true}
            />

            <Button
                type='submit'
                text='Save and edit update'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default NewUpdateForm;
