import CreateConversationModal from "../CreateConversation/modal/CreateConversationModal/CreateConversationModal";
import { CONVERSATIONS_PERMS_ACTIONS } from "../enums";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { CREATE_CONVERSATION, DELETE_CONVERSATION } =
    CONVERSATIONS_PERMS_ACTIONS;

const ConversationsModal = ({
    currentAction,
    referenceData,
    showModal,
    closeModal,
    handleSuccess,
    source,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case CREATE_CONVERSATION:
                return (
                    <CreateConversationModal
                        show={showModal}
                        closeModal={closeModal}
                        referenceData={referenceData}
                        modalFormAction={currentAction}
                        source={source}
                    />
                );
            case DELETE_CONVERSATION:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${referenceData?.issueName}`}
                        show={showModal}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.deleteIssuesConversation(
                            referenceData?.conversation?.conversationId
                        )}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnAction()}</>;
};

export default ConversationsModal;
