import PropTypes from "prop-types";
import SuggestionsList from "./SuggestionsList/SuggestionsList";
import { SuggestionsHeader } from "./SuggestionsHeader/SuggestionsHeader";

const Suggestions = ({ conversations }) => {
	return (
		<>
			<SuggestionsHeader />
			<SuggestionsList conversations={conversations} />
		</>
	);
};

Suggestions.prototypes = {
	conversations: PropTypes.array.isRequired,
};

export default Suggestions;
