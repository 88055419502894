// import { UPDATE_TASK_ACTIONS } from "../../../enum";
import NewUpdateTaskModal from "./NewUpdateTaskModal/NewUpdateTaskModal";
import DeleteUpdateTaskModal from "./DeleteUpdateTaskModal/DeleteUpdateTaskModal";
import { CANNED_RESOURCE_ACTIONS } from "components/dashboard/TrainSAM/CannedResponses/enum";
import { UPDATE_TASK_ACTIONS } from "../../../enum";

// const { CREATE_TASK, EDIT_TASK, DELETE_TASK } = UPDATE_TASK_ACTIONS;
const { CREATE_CANNED_RESOURCE, EDIT_CANNED_RESOURCE, DELETE_CANNED_RESOURCE } =
    CANNED_RESOURCE_ACTIONS;

const { EDIT_TASK, DELETE_TASK, CREATE_TASK } = UPDATE_TASK_ACTIONS;
const CannedTasksModal = ({
    title,
    showModal,
    closeModal,
    cannedResourceType,
    selectedCannedTask,
    agentUpdateId,
    isEdit,
    modalAction,
    toggleModal,
    handleSuccess,
    hideChooseAgentTaskType,
    hasAutomationFields,
    ...rest
}) => {
    const renderBasedOnModalAction = () => {
        switch (modalAction) {
            case CREATE_CANNED_RESOURCE:
            case EDIT_CANNED_RESOURCE:
            case EDIT_TASK:
            case CREATE_TASK:
                return (
                    <NewUpdateTaskModal
                        title={title}
                        showModal={showModal}
                        closeModal={closeModal}
                        cannedResourceType={cannedResourceType}
                        selectedCannedTask={selectedCannedTask}
                        agentUpdateId={agentUpdateId}
                        handleSuccess={handleSuccess}
                        isEdit={
                            modalAction === EDIT_CANNED_RESOURCE ||
                            modalAction === EDIT_TASK
                        }
                        hasAutomationFields={hasAutomationFields}
                        hideChooseAgentTaskType={hideChooseAgentTaskType}
                        {...rest}
                    />
                );

            case DELETE_CANNED_RESOURCE:
            case DELETE_TASK:
                return (
                    <DeleteUpdateTaskModal
                        {...{ closeModal, showModal, handleSuccess }}
                        cannedTaskId={selectedCannedTask?.agentUpdateTaskId}
                    />
                );

            default:
                return "";
        }
    };

    return <>{renderBasedOnModalAction()}</>;
};

export default CannedTasksModal;
