import { Button, Input, Textarea } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import CannedResponseSearchInput from "./CannedResponseSearchInput/CannedResponseSearchInput";
import { getErrorMessage } from "utils/helper";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ValidateForm from "utils/validations/Validator";
import { useDispatch } from "react-redux";
import { clearCannedResponses } from "store/modules/knowledgeBase/actions";

const AddFormCRModalForm = ({
    referenceData,
    isViewOnly,
    isEdit,
    closeModal,
    cannedResponse,
    handleSuccess,
    existingCannedReponses,
}) => {
    const [request, setRequest] = useState({
        cannedResponseId: "",
        response: "",
        subject: "",
        title: "",
    });

    const [errors, setErrors] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [disabledInputs, setDisabledInputs] = useState(true);

    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const attachCannedResponseToForm = async () => {
        try {
            setErrorMsg("");
            setLoading(true);

            const url = apiRoute.addCannedResponseToForm(referenceData?.formId);
            const { title, response, subject, cannedResponseId } = request;
            const res = await API.post(url, {
                title,
                response,
                subject,
                cannedResponseId,
            });

            if (res.status === 201) {
                const { message } = res?.data;
                // For everytime a canned response is attached to a form, the cached canned response should be cleared
                // in order to ensure that newly updated canned responses data are fetched when the user leaves this place to view them
                dispatch(clearCannedResponses());

                setLoading(false);
                toastMessage(message);
                closeModal();
                handleSuccess?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const editCannedResponse = async () => {
        try {
            setErrorMsg("");
            setLoading(true);

            const url = apiRoute.editCannedResponse(
                cannedResponse?.cannedResponseId
            );

            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res?.data;
                setLoading(false);
                // For everytime a canned response is updated, the cached canned response should be cleared
                // in order to ensure that newly updated canned responses data are fetched when the user leaves this place to view them
                dispatch(clearCannedResponses());
                toastMessage(message);
                closeModal();
                handleSuccess?.();
            }
        } catch (err) {
            setLoading(false);
            setErrorMsg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request, [
            "subject",
        ]);
        if (formisValid) {
            isEdit ? editCannedResponse() : attachCannedResponseToForm();
        } else setErrors(formErrors);
    };

    const handleRequest = (data, isNew) => {
        if (isNew) {
            setDisabledInputs(false);
            setRequest({
                ...data,
                title: data?.value,
                cannedResponseId: undefined,
                response: "",
                subject: "",
            });
        } else {
            setRequest(data);
        }
    };

    useEffect(() => {
        if (cannedResponse) {
            setRequest(cannedResponse);
            isViewOnly ? setDisabledInputs(true) : setDisabledInputs(false);
        }
        // eslint-disable-next-line
    }, [cannedResponse]);

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMsg)}
                message={errorMsg}
                hide={() => setErrorMsg()}
            />
            {isEdit || isViewOnly ? (
                <Input
                    name='title'
                    label={"Main sentence"}
                    grpClass='mb-3'
                    labelClass='text-black'
                    value={request?.title}
                    onChange={handleChange}
                    isErr={errors?.title}
                    errMssg={errors?.title}
                    autoFocus={true}
                    disabled={disabledInputs}
                />
            ) : (
                <CannedResponseSearchInput
                    request={request}
                    errors={errors}
                    handleRequest={handleRequest}
                    existingCannedReponses={existingCannedReponses}
                />
            )}

            <Input
                name='subject'
                label={"Subject"}
                grpClass='mb-3'
                labelClass='text-black'
                value={request?.subject}
                onChange={handleChange}
                autoFocus={true}
                disabled={disabledInputs}
            />
            <Textarea
                name='response'
                label={"Main sentence"}
                grpClass='mb-3'
                labelClass='text-black'
                value={request?.response}
                onChange={handleChange}
                isErr={errors?.response}
                errMssg={errors?.response}
                autoFocus={true}
                disabled={disabledInputs}
            />
            {!isViewOnly && (
                <Button
                    classType={`primary`}
                    otherClass='w-100'
                    type={"submit"}
                    text={"Save Changes"}
                    disabled={loading}
                    loading={loading}
                />
            )}
        </form>
    );
};

export default AddFormCRModalForm;
