import React from "react";
import SolidAddButton from "components/ui/Button/SolidAddButton/SolidAddButton";
import Heading from "components/ui/Heading/Heading";
import { FOLLOW_UP_TASK_ACTIONS } from "../enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_COLLECTION } = baseResources;
const { CREATE } = baseResourceActions;
const { ADD_NEW_FOLLOW_UP_TASK } = FOLLOW_UP_TASK_ACTIONS;

const FollowUpTasksViewHeading = ({ handleActionModal, followUpData }) => {
    const { name } = followUpData || {};

    return (
        <>
            <div className='agent-follow-up__heading'>
                <div>
                    <Heading headingText={name} />
                </div>
                <div>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_SAM_COLLECTION}`]}>
                        <SolidAddButton
                            btnText='Add New Task'
                            onClick={() =>
                                handleActionModal(ADD_NEW_FOLLOW_UP_TASK)
                            }
                        />
                    </ComponentAccessControl>
                </div>
            </div>
        </>
    );
};

export default FollowUpTasksViewHeading;
