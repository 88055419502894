import React from "react";
import { Dropdown } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { formElementBlocks } from "components/dashboard/KnowledgeBase/FormSetup/BuilderElementsList/data";
import "./CustomRulesDropDown.scss";

const CustomRulesDropDown = ({
    id,
    elementType,
    acceptNewInputRule,
    selectedRules,
}) => {
    const selectedRuleCodes = selectedRules.map((rule) => rule.ruleCode);

    const handleRuleSelect = (item) => {
        const ruleConstraint = item?.ruleConstraint;
        const ruleIndex = selectedRules?.length > 0 ? selectedRules?.length : 0;
        acceptNewInputRule(id, item.value, ruleIndex, ruleConstraint);
    };

    return (
        <>
            <Dropdown
                size='sm'
                placeholder={
                    <AddButton
                        btnText={`Add Custom Rule`}
                        otherClassName='custom__rules__dropdown__btn'
                    />
                }
                ddValueClass='borderless'
                anchor={false}
                changeValue={false}
                onChange={handleRuleSelect}
                options={formElementBlocks?.[elementType]?.rules?.filter(
                    (el) => !selectedRuleCodes?.includes(el.value)
                )}
            />
        </>
    );
};

export default CustomRulesDropDown;
