import React, { useState } from "react";
import asset from "assets/images";
import CapsuleContentsDropdown from "./CapsuleContentsDropdown/CapsuleContentsDropdown";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { SEO } from "components/dashboard/common/SeoWrapper/SeoWrapper";
import CapsuleContentFormatModal from "./CapsuleContentFormatModal/CapsuleContentFormatModal";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleContentViewHeader.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const CapsuleContentViewHeader = ({
    capsuleData,
    acceptedContentType,
    reLoadView,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState("");

    const toggleBlockContentModal = ({ value }) => {
        toggleModal(true);
        setSelectedFormat(value);
    };

    const handleContentBlockCreationSuccess = () => {
        toggleModal(false);
        reLoadView();
    };

    return (
        <>
            <SEO title={capsuleData?.content} />
            <div className='capsule__content__view__header'>
                <div className='pre__nav padded__layout'>
                    <Link
                        to={`/knowledge-base/product/${capsuleData?.productId}`}>
                        Product Set-up
                    </Link>
                    <ReactSVG
                        src={asset?.icons?.rightBlack}
                        className='anchor-right'
                    />
                    <p>{capsuleData?.content}</p>
                </div>
                <div className='row content__title'>
                    <div className="col-9">
                        <h1 className='content__title--text'>
                            {capsuleData?.content}
                        </h1>
                    </div>
                    <div className="col-3">
                        <ComponentAccessControl
                            baseResources={[`${CREATE}_${KB_PRODUCT_SETTING}`]}>
                            <CapsuleContentsDropdown
                                acceptedContentType={acceptedContentType}
                                toggleBlockContentModal={(formatType) =>
                                    toggleBlockContentModal(formatType)
                                }
                            />
                        </ComponentAccessControl>
                    </div>
                </div>
            </div>
            {showModal && (
                <CapsuleContentFormatModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    selectedFormat={selectedFormat}
                    handleSuccess={handleContentBlockCreationSuccess}
                    capsuleData={capsuleData}
                />
            )}
        </>
    );
};

export default CapsuleContentViewHeader;
