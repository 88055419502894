import React from "react";
import BranchColumnItem from "./BranchColumnItem/BranchColumnItem";
import BranchesColumnSkeleton from "./BranchesColumnSkeleton/BranchesColumnSkeleton";
import { useXarrow } from "react-xarrows";

const BranchesColumn = ({
    branches,
    selectedBranchId,
    selectedBranchOptionId,
    columnNo,
    handleOptionSelect,
    isLoading,
    isDisabled,
    selectLastToggledBranchId,
    isAgentGuideView
}) => {
    const updateXarrow = useXarrow();

    return (
        <>
            <div className='branch__column' onScroll={updateXarrow}>
                {isLoading ? (
                    <BranchesColumnSkeleton />
                ) : (
                    branches
                        ?.filter((branch) => branch !== undefined)
                        .map(({ element }, index) => {
                            const isActive =
                                element?.branchId === selectedBranchId;
                            return (
                                <BranchColumnItem
                                    key={index}
                                    isActive={isActive}
                                    isByPassed={selectedBranchId && !isActive}
                                    isDisabled={isDisabled || !isActive}
                                    selectedBranchOptionId={
                                        selectedBranchOptionId
                                    }
                                    columnNo={columnNo}
                                    handleOptionSelect={handleOptionSelect}
                                    branchMainSentence={
                                        element?.branchMainSentence
                                    }
                                    selectLastToggledBranchId={selectLastToggledBranchId}
                                    isAgentGuideView={isAgentGuideView}
                                    {...element}
                                />
                            );
                        })
                )}
            </div>
        </>
    );
};

export default BranchesColumn;
