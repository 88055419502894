import PropTypes from "prop-types";
import { QA_DOCUMENTATION_PERMS_ACTIONS } from "../enum";
import CreateQaDocumentationModal from "./CreateQaDocumentationModal/CreateQaDocumentationModal";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";

const { EDIT_QA_DOCUMENTATION, DELETE_QA_DOCUMENTATION } = QA_DOCUMENTATION_PERMS_ACTIONS;

const QaDocumentationModal = ({
    currentAction,
    currentQaDocumentation,
    closeModal,
    showModal,
    handleSuccess,
}) => {
    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT_QA_DOCUMENTATION:
                return (
                    <CreateQaDocumentationModal
                        closeModal={closeModal}
                        currentQaDocumentation={currentQaDocumentation}
                        handleSuccess={handleSuccess}
                        showModal={showModal}
                        isEdit={true}
                    />
                );
            case DELETE_QA_DOCUMENTATION:
                return (
                    <ConfirmationModal
                        title={`Are you sure you want to delete ${currentQaDocumentation?.qaDocumentationTitle}`}
                        show={true}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        url={apiRoute?.deleteQaDocument(currentQaDocumentation?.qaDocumentationId)}
                        method='delete'
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnAction()}</>;
};

QaDocumentationModal.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    getQA_DOCUMENTATIONs: PropTypes.func.isRequired,
};

export default QaDocumentationModal;
