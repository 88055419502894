import PropTypes from "prop-types";
import React from "react";
import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { branchCollectionData } from "./data";
import AddBranchCollectionForm from "./AddBranchCollectionForm/AddBranchCollectionForm";
import { SOLUTIONS_COLLECTION } from "./enums";

const AddBranchCollectionModal = ({
    show,
    closeModal,
    referenceData,
    defaultActionType,
    source,
    handleSuccess,
    existingBranchCollections,
}) => {
    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            source === SOLUTIONS_COLLECTION
                                ? `Create Solution Collection for ${referenceData?.capsuleContent}`
                                : branchCollectionData[source]?.title
                        }
                    />
                    <AddBranchCollectionForm
                        referenceData={referenceData}
                        source={source}
                        handleSuccess={handleSuccess}
                        defaultActionType={defaultActionType}
                        existingBranchCollections={existingBranchCollections}
                    />
                </div>
            </div>
        </SideModal>
    );
};

AddBranchCollectionModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    referenceData: PropTypes.any,
    show: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
};

export default AddBranchCollectionModal;
