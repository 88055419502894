import React from "react";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./TMTagItem.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { READ } = baseResourceActions;

const TMTagItem = ({
    messagingTagId,
    messagingTagName,
    handleTagSelect,
    isActive,
}) => {
    return (
        <ComponentAccessControl
            baseResources={[`${READ}_${AGENTS_INBOX_BASE_SETTING}`]}>
            <div
                className={`tm__tag ${isActive ? "active" : ""}`}
                onClick={() => handleTagSelect(messagingTagId)}>
                <span>{messagingTagName}</span>
            </div>
        </ComponentAccessControl>
    );
};

export default TMTagItem;
