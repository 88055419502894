export const setDeviceToken = (token) => {
    localStorage.setItem("deviceToken", token);
};

export const retrieveDeviceToken = () => {
    try {
        const deviceToken = localStorage.getItem("deviceToken");
        return deviceToken;
    } catch (err) {
        return undefined;
    }
};

export const deleteDeviceToken = async () => {
    await localStorage.removeItem("deviceToken");
};

export const retriveTokenExpiry = () => {
    try {
        const tokenExpiry = localStorage.getItem("tokenExpiry");
        const loginTime = localStorage.getItem("loginTime");

        return { tokenExpiry, loginTime };
    } catch (err) {
        return undefined;
    }
};
