import ClassCapsuleContentGroup from "components/dashboard/KnowledgeBase/ui/ClassCapsuleContentGroup/ClassCapsuleContentGroup";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { flattenArray, getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import CreateGuideFormChoice from "./CreateGuideFormChoice/CreateGuideFormChoice";
import { SELECT_GUIDE_STAGES } from "./enum";

const { CHOOSE_STAGE, NEW_GUIDE } = SELECT_GUIDE_STAGES;

const CreateGuideForm = ({
    handleSuccess,
    isEdit,
    closeModal,
    selectedGuide,
    isSolutionGuide,
    solutionCapsule,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedContents, setSelectedContents] = useState(new Map());
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [guideList, setGuideList] = useState([]);
    const [fetchingGuides, setFetchingGuides] = useState(false);

    const extraFields = isSolutionGuide
        ? {
              ...solutionCapsule,
              agentGuideId: "",
          }
        : {};
    const [solSelectedGuide, solSelectGuide] = useState({});

    const [selectGuideStage, setSelectGuideStage] = useState(
        isSolutionGuide ? CHOOSE_STAGE : NEW_GUIDE
    );

    const [request, setRequest] = useState({
        agentGuideCapsules: [],
        agentGuideName: "",
        ...extraFields,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSelectGuide = (guide) => {
        solSelectGuide(guide);
        setErrors({});
    };

    const toastMessage = useContext(ToastContext);

    const createGuide = async () => {
        const capsules = Array.from(selectedContents?.values()).filter(
            (capsule) => capsule?.classCapsuleEntryIds.length > 0
        );

        const newRequest = { ...request, agentGuideCapsules: capsules };

        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.post(apiRoute?.createGuide, newRequest);

            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const updateGuide = async () => {
        const capsules = Array.from(selectedContents?.values()).filter(
            (capsule) => capsule?.classCapsuleEntryIds.length > 0
        );

        const newRequest = { ...request, agentGuideCapsules: capsules };

        try {
            setLoading(true);
            setErrorMssg();

            const res = await API.put(
                apiRoute?.updateGuide(selectedGuide?.agentGuideId),
                newRequest
            );

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const fetchGuides = async () => {
        try {
            setFetchingGuides(true);
            setErrorMssg();

            const res = await API.get(apiRoute?.getGuides);

            if (res.status === 200) {
                const { data } = res.data;
                setGuideList(data);
                setFetchingGuides(false);
            }
        } catch (err) {
            setFetchingGuides(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const attachGuideToSolution = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const capsules = Array.from(selectedContents?.values()).filter(
                (capsule) => capsule?.classCapsuleEntryIds.length > 0
            );

            const newRequest = {
                ...request,
                agentGuideCapsules: capsules,
                ...solutionCapsule,
            };

            delete newRequest.agentGuideElements;

            const res = await API.post(
                apiRoute?.attachFollowupGuide,
                newRequest
            );

            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isSolutionGuide
                ? attachGuideToSolution()
                : isEdit
                ? updateGuide()
                : createGuide();
        }
        setErrors(formErrors);
    };

    const handleContentSelect = (capsule, capsuleEntryId) => {
        const { capsuleClassId, capsuleCode } = capsule;
        setErrors({ ...errors, agentGuideCapsules: "" });
        const prevSelectedContents = selectedContents.get(capsuleCode);
        if (prevSelectedContents) {
            const ccSelectedContentIds =
                prevSelectedContents?.classCapsuleEntryIds;
            const contentExist =
                prevSelectedContents?.classCapsuleEntryIds?.includes(
                    capsuleEntryId
                );

            if (!contentExist) {
                ccSelectedContentIds.push(capsuleEntryId);
            } else {
                ccSelectedContentIds.splice(
                    prevSelectedContents?.classCapsuleEntryIds?.indexOf(
                        capsuleEntryId
                    ),
                    1
                );
            }

            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: ccSelectedContentIds,
                            },
                        ],
                    ])
            );
        } else {
            setSelectedContents(
                (prev) =>
                    new Map([
                        ...prev,
                        [
                            capsuleCode,
                            {
                                classCapsuleId: capsuleClassId,
                                classCapsuleEntryIds: [capsuleEntryId],
                            },
                        ],
                    ])
            );
        }
    };

    useEffect(() => {
        if (
            (isEdit && selectedGuide) ||
            (isSolutionGuide && solSelectedGuide)
        ) {
            setRequest({ ...selectedGuide, ...solSelectedGuide });
            const guide = isSolutionGuide ? solSelectedGuide : selectedGuide;
            const reStructuredElements = guide?.agentGuideElements?.map(
                ({ agentGuideCapsuleEntryIds, agentGuideClassCapsule }) => {
                    return [
                        agentGuideClassCapsule?.capsuleCode,
                        {
                            classCapsuleId:
                                agentGuideClassCapsule?.capsuleClassId,
                            classCapsuleEntryIds: 
                                 agentGuideCapsuleEntryIds?.split?.(",")
                       
                        },
                    ];
                }
            );

            setSelectedContents(new Map(reStructuredElements));
        }
        // eslint-disable-next-line
    }, [selectedGuide, solSelectedGuide]);

    useEffect(() => {
        if (isSolutionGuide) {
            fetchGuides();
        }
    }, [isSolutionGuide]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            {isSolutionGuide && (
                <CreateGuideFormChoice
                    {...{
                        handleSelectGuide,
                        guideList,
                        errors,
                        setSelectGuideStage,
                        selectGuideStage,
                        fetchingGuides,
                    }}
                />
            )}

            {selectGuideStage === NEW_GUIDE && (
                <>
                    <Input
                        grpClass='mb-3'
                        type='text'
                        name='agentGuideName'
                        label='Name of the Guide'
                        labelClass='text-dark fw-medium'
                        id='agentGuideName'
                        placeholder='Enter name of Guide'
                        value={request?.agentGuideName}
                        onChange={handleChange}
                        isErr={errors?.agentGuideName}
                        errMssg={errors?.agentGuideName}
                        autoFocus={true}
                    />

                    <div className='form-group'>
                        <ClassCapsuleContentGroup
                            capsuleCodes={capsuleClasses?.map(
                                (capsuleClass) => capsuleClass?.capsuleCode
                            )}
                            classesListBreakpoint={8}
                            handleContentSelect={handleContentSelect}
                            selectable={true}
                            searchable={true}
                            selectedContentIds={flattenArray(
                                Array.from(selectedContents?.values())?.map(
                                    (x) => x.classCapsuleEntryIds
                                )
                            )}
                            label='Relate feature or flow'
                            inputKey='agentGuideCapsules'
                            error={errors?.agentGuideCapsules}
                        />
                    </div>
                </>
            )}
            <Button
                type='submit'
                text={isEdit ? "Save changes" : "Create Guide"}
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default CreateGuideForm;
