import React from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { chartLayout } from "../PlottedBarChart/enum";
import "./AnalyticsBarHeader.scss";
import { computeChartOverview } from "../../helpers";

const { HORIZONTAL, VERTICAL } = chartLayout;

const AnalyticsBarCard = ({ title, text, isHigh, percent }) => {
    return (
        <div className='analytics__bar__header__card'>
            <div className='bar__header__card__top'>
                <span className='bar__header__card__top__label'>{title}</span>
                <ReactSVG
                    src={
                        isHigh
                            ? imageLinks.icons.greenArrowUP
                            : imageLinks.icons.redArrowDown
                    }
                />
            </div>
            <div className='bar__header__card__bottom'>
                <span className='bar__header__card__bottom__label'>
                    {text || "-"}
                </span>
                <span
                    className={`bar__header__card__bottom__value ${
                        isHigh ? "green" : "red"
                    }`}>
                    {`${percent}%` || "-"}
                </span>
            </div>
        </div>
    );
};

const AnalyticsBarHeader = ({ chartMode, setChartMode, overviews }) => {
    return (
        <div className='analytics__bar__header'>
            <div className='analytics__bar__header__card-container'>
                {computeChartOverview(overviews)?.map?.((data, key) => (
                    <AnalyticsBarCard key={key} {...data} />
                ))}
            </div>
            <div className='analytics__toggle'>
                <div
                    className={`average ${
                        chartMode === HORIZONTAL ? "active" : ""
                    }`}
                    onClick={() => setChartMode(HORIZONTAL)}>
                    AVG
                </div>
                <div
                    className={`percentage ${
                        chartMode === VERTICAL ? "active" : ""
                    }`}
                    onClick={() => setChartMode(VERTICAL)}>
                    %
                </div>
            </div>
        </div>
    );
};

export default AnalyticsBarHeader;
