import { useState } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { saveUserProfile } from "store/auth/actions";
import { Input, Button, Textarea } from "components/ui";
import EditStartUpProfileAvatar from "./EditStartUpProfileAvatar/EditStartUpProfileAvatar";
import ValidateForm from "utils/validations/Validator";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { connect } from "react-redux";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { STARTUP_PROFILE_INDUSTRIES } from "../../enums";

const { ERROR } = dataQueryStatus;

const EditStartupProfile = ({ workspace, handleSuccess, saveUserProfile }) => {
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [request, setRequest] = useState({
        name: workspace?.workspaceName || "",
        address: workspace?.address || "",
        industry: workspace?.industry || "",
        description: workspace?.description || "",
    });
    const toastMessage = useContext(ToastContext);

    const updateWorkspace = async () => {
        try {
            setLoading(true);
            const { industry, address, description } = request;

            const formData = new FormData();
            if (logo) formData.append("image", logo);
            formData.append("industry", industry);
            formData.append("address", address);
            formData.append("description", description);

            const res = await API.put(apiRoute?.workspaceProfile, formData);

            if (res.status === 200) {
                const { message, data } = res.data;

                toastMessage(message);
                saveUserProfile({ workspace: { ...workspace, ...data } });
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors } = ValidateForm(e, request);
        formisValid ? updateWorkspace() : setFormErrors(errors);
    };

    return (
        <form onSubmit={handleSubmit}>
            <ModalHeading
                title={"Edit Startup Profile"}
                subTitle='Edit your startup profile'
            />

            <EditStartUpProfileAvatar
                setLogo={setLogo}
                workspaceLogoUrl={workspace?.workspaceLogoUrl}
            />

            <Input
                grpClass='mb-3'
                name='name'
                label='Company Name'
                labelClass='text-dark fw-medium'
                placeholder='Enter company name'
                value={request.name}
                disabled
            />
            <Input
                grpClass='mb-3'
                name='address'
                label='Company Address'
                labelClass='text-dark fw-medium'
                id='address'
                placeholder='Enter company address'
                value={request.address}
                onChange={handleChange}
                isErr={formErrors?.address}
                errMssg={formErrors?.address}
            />

            <SelectUI
                grpClass='mb-3'
                name='industry'
                label='Industry'
                labelClass='text-dark fw-medium'
                id='industry'
                placeholder='Select industry'
                isErr={formErrors?.industry}
                errMssg={formErrors?.industry}
                value={STARTUP_PROFILE_INDUSTRIES?.find((industry) => {
                    return industry.value === request.industry;
                })}
                options={STARTUP_PROFILE_INDUSTRIES}
                handleChange={(name, value) =>
                    handleChange({ target: { name, value } })
                }
            />

            <Textarea
                grpClass='mb-3'
                name='description'
                label='Company Description'
                labelClass='text-dark fw-medium'
                id='description'
                placeholder='Add company description'
                value={request.description}
                onChange={handleChange}
                isErr={formErrors?.description}
                errMssg={formErrors?.description}
            />
            <Button
                type='submit'
                text='Update Startup profile'
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

export default connect(
    (state) => ({
        workspace: state?.auth?.user?.workspace,
    }),
    { saveUserProfile }
)(EditStartupProfile);
