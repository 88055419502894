export const capsuleClassesAggregateQuery = (collectionName) => {
    return `query MyQuery($productId: String!, $workspaceId: String!) {
    ${collectionName}(where: { productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId} }) {
        aggregate {
          count
        }
      }
  }`;
};

export const capsuleSubClassesAggregateQuery = (
    mainCollectionName,
    combinedCollectionName,
    parentCollectionName
) => {
    return `query MyQuery($productId: String!, $capsuleId: uuid) {
    ${mainCollectionName}(where: {${combinedCollectionName}: {${parentCollectionName}: {productid: {_eq: $productId}} }}) {
      aggregate {
        count
      }
    }
  }
  `;
};

export const capsuleSubClassesEntryAggregateQuery = (
    mainCollectionName,
    combinedCollectionName,
    refCollectionName,
    refCollectionKey
) => {
    return `query MyQuery($productId: String!, $capsuleId: uuid!) {
    ${mainCollectionName}_aggregate(where: {${combinedCollectionName}: {${refCollectionName}: {productid: {_eq: $productId}, ${refCollectionKey}:{_eq: $capsuleId }}}}) {
      aggregate {
        count
      }
    }
  }
  `;
};

export const getProductCapsuleEntries = (collectionName, collectionKey) => {
    return `query MyQuery($productId: String!, $workspaceId: String!, $limit: Int, $offset: Int, $alphabeticalOrder:order_by, $dateCreatedOrder :order_by) {
    ${collectionName}(where: 
      { productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId} }, limit: $limit, offset: $offset, order_by: {content: $alphabeticalOrder, createddate:$dateCreatedOrder}) {
        aggregate {
          count
        }
        nodes {
          content
          contenttype
          ${collectionKey}
          productid
        }
      }
  }`;
};

export const getSingleCapsuleEntryQuery = (collectionName, collectionKey) => {
    return `query MyQuery($capsuleEntryId: uuid!) {
    ${collectionName}(${collectionKey}: $capsuleEntryId) {
        content
        contenttype
        ${collectionKey}
        productid
      }
  }`;
};

export const getCapsuleEntries = (collectionName, collectionKey) => {
    return `query MyQuery($workspaceId: String!, $limit: Int, $offset: Int, $alphabeticalOrder:order_by, $dateCreatedOrder :order_by, $search:String ) {
  ${collectionName}(where: 
    { content: {_iregex: $search }, workspaceid: {_eq: $workspaceId} }, limit : $limit, offset: $offset, order_by:{content: $alphabeticalOrder, createddate :$dateCreatedOrder}) {
      aggregate {
        count
      }
      nodes {
        content
        contenttype
        ${collectionKey}
        productid
        enabled
      }
    }
}`;
};

export const getCapsuleEntriesBasedOnStatus = (
    collectionName,
    collectionKey,
    capsuleStatus
) => {
    return `query MyQuery($workspaceId: String!, $limit: Int, $offset: Int) {
  ${collectionName}(where: 
    { workspaceid: {_eq: $workspaceId}, enabled: {_eq: ${capsuleStatus}} }, 
    limit : $limit, offset: $offset ) {
      aggregate {
        count
      }
      nodes {
        content
        contenttype
        ${collectionKey}
        productid
        enabled
      }
    }
}`;
};

export const getCapsuleStatusCount = (collectionName, capsuleStatus) => {
    return `query MyQuery($workspaceId: String!) {
  ${collectionName}_aggregate(where: 
    { workspaceid: {_eq: $workspaceId}, enabled: {_eq: ${capsuleStatus}} }) {
      aggregate {
        count
      }
    }
}`;
};

export const getCapsuleSubEntries = (
    combinedCollectionName,
    parentCollectionName,
    childCollectionName,
    parentCollectionKey,
    childCollectionKey,
    isParental
) => {
    return `query MyQuery($productId: String!, $workspaceId: String!, $limit : Int, $offset: Int, $alphabeticalOrder: order_by, $dateCreatedOrder :order_by, $search:String ) {
    ${parentCollectionName}(where: 
      { content: {_iregex: $search},  productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId} }, limit: $limit, offset: $offset , order_by: {content : $alphabeticalOrder, createddate: $dateCreatedOrder}) {
        content
        contenttype
        productid
        ${parentCollectionKey}
        ${combinedCollectionName}( where: {${childCollectionName}:{ productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId} }}){
          nodes {
            ${childCollectionName}{
              ${childCollectionKey}
              content
              contenttype
              productid
            }
          }
        }
      }
  }`;
};

export const generateWorkspaceClassCapsuleQuery = (collectionName) => {
    return `query MyQuery($workspaceId: String!) {
    ${collectionName}_aggregate(where: 
      { workspaceid: {_eq: $workspaceId} }) {
        aggregate {
          count
        }
        nodes {
          content
          contenttype
          ${collectionName}id
          productid
        }
      }
  }`;
};

export const getChildrenEntries = (
    combinedCollectionName,
    parentCollectionKey,
    childCollectionName,
    childCollectionKey
) => {
    return `query MyQuery($parentCapsuleId: uuid!) {
      ${combinedCollectionName}(where: {${parentCollectionKey}: {_eq: $parentCapsuleId}}) {
        nodes {
          ${childCollectionName} {
            content
            contenttype
            ${childCollectionKey}
            productid
          }
        }
      }
    }
  `;
};

export const getParentEntries = (
    combinedCollectionName,
    parentCollectionName,
    parentCollectionKey,
    childCollectionKey
) => {
    return `query MyQuery($childCapsuleId: uuid!) {
      ${combinedCollectionName}(where: {${childCollectionKey}: {_eq: $childCapsuleId}}) {
        nodes {
          ${parentCollectionName} {
            content
            contenttype
            ${parentCollectionKey}
            productid
          }
        }
      }
    }
  `;
};

export const getCollectionCapsules = (collectionName) => {
    return `query MyQuery($workspaceId: String!) {
    ${collectionName}_aggregate(where: {workspaceid: {_eq: $workspaceId}}) {
      aggregate {
        count
      }
      nodes {
        content
        ${collectionName}id
        ${collectionName}_feature_aggregate {
          aggregate {
            count
          }
          nodes {
            feature {
              content
              featureid
            }
          }
        }
      }
    }
  }`;
};

export const findCapsuleEntriesQuery = (collectionName, collectionKey) => {
    return `query MyQuery ($productId: String!, $workspaceId: String!, $content: String!,  ){
    ${collectionName}_aggregate(where: {productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId}, content: {_iregex: $content}}) {
      nodes {
        content
        contenttype
        ${collectionKey}
        productid
      }
    }
  }
  `;
};

export const capsuleEntriesAggregateQuery = (collectionName) => {
    return `query MyQuery ($productId: String!, $workspaceId: String!){
${collectionName}_aggregate(where: {productid: {_eq:$productId}, workspaceid: {_eq: $workspaceId}}) {
  aggregate {
    count
  }
}
}`;
};

export const getCapsuleEntriesAggregateQueryByWorkspace = (collectionName) => {
    return `query MyQuery ($workspaceId: String!){
${collectionName}_aggregate(where: {workspaceid: {_eq: $workspaceId}}) {
aggregate {
  count
}
}
}`;
};

export const getCapsuleSubEntry = (parentCollectionName) => {
    return `query MyQuery($productId: String!, $workspaceId: String! $keyword: String) {
  ${parentCollectionName}(where: 
    { content: {_eq:$keyword}, productid: {_eq: $productId}, workspaceid: {_eq: $workspaceId} } ) {
      content
    }
}`;
};
