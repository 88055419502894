import React from "react";
import { Modal as ReactstrapModal, ModalBody, ModalHeader } from "reactstrap";
import "../Modal.scss";
import { defaultThemes } from "hoc/AppTemplateWrapper/enum";
import { useSelector } from "react-redux";

const { DARK_MODE_DEFAULT } = defaultThemes;

export const Modal = ({
    show,
    close,
    size,
    toggle,
    children,
    fullscreen = false,
    centered,
    contentClassName,
}) => {
    const { defaultTheme } = useSelector((state) => state?.settings);

    const isDarkModeTheme = defaultTheme === DARK_MODE_DEFAULT;

    return (
        <ReactstrapModal
            isOpen={show}
            size={size}
            fade={true}
            toggle={toggle}
            zIndex={""}
            onClosed={close}
            fullscreen={fullscreen}
            scrollable={false}
            centered={centered}
            contentClassName={contentClassName}
            className={`${isDarkModeTheme ? "modal-darkmode" : ""}`}>
            {toggle && <ModalHeader toggle={close} />}
            <ModalBody
                className={`${
                    isDarkModeTheme ? "dark-mode-default" : "white-mode-default"
                }`}>
                {children}
            </ModalBody>
        </ReactstrapModal>
    );
};
