import React, { useEffect } from "react";
import { Button } from "components/ui";

export const SuccessScreen = ({ title, paragraph, btnText, handleProceed }) => {
    useEffect(() => {
        setTimeout(() => {
            handleProceed();
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='w-100 d-flex flex-column align-items-center'>
            <h1 className='fw-bold mb-5 text-neutral'>Yay! 🎉 </h1>
            <h3 className='mb-3 text-dark'>{title}</h3>
            <p className='text-dark text-center mb-4'>{paragraph}</p>
            <Button
                type='button'
                text={btnText}
                classType='primary'
                onClick={handleProceed}
                otherClass='my-3 w-100'
            />
        </div>
    );
};
