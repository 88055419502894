import React from "react";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { SideModal } from "components/ui";
import CreateBranchOptionForm from "../../form/CreateBranchOptionForm/CreateBranchOptionForm";
import { branchOptionActions } from "../../BranchOption/enum";
import CreateConvoBreakerOptionForm from "../../../../../ServiceModule/ChatSettings/ConversationBreakers/form/CreateConvoBreakerOptionForm/CreateConvoBreakerOptionForm";

const {
    CREATE_BRANCH_OPTION,
    EDIT_BRANCH_OPTION,
    CREATE_CONVERSATION_BREAKER_OPTION,
    EDIT_CONVERSATION_BREAKER_OPTION,
} = branchOptionActions;

const CreateBranchOptionModal = ({
    show,
    closeModal,
    referenceData,
    handleSuccess,
    isEdit,
    hideCapsuleContentGroup,
    modalActionType,
    existingOptionConnections,
    isAgentGuideView,
}) => {
    const renderBasedOnActionType = () => {
        switch (modalActionType) {
            case CREATE_BRANCH_OPTION:
            case EDIT_BRANCH_OPTION:
                return (
                    <>
                        <ModalHeading
                            title={
                                isEdit
                                    ? `Edit ${
                                          isAgentGuideView
                                              ? "Next Step"
                                              : referenceData?.branchOptionLabel
                                      }`
                                    : `Add ${
                                          isAgentGuideView ? "" : "Option to"
                                      } ${
                                          isAgentGuideView
                                              ? "Next Step"
                                              : referenceData?.branchName
                                      }`
                            }
                            subTitle={
                                isAgentGuideView
                                    ? ""
                                    : `A conversation is a collection of multiple branches`
                            }
                        />
                        <CreateBranchOptionForm
                            isEdit={isEdit}
                            referenceData={referenceData}
                            handleSuccess={handleSuccess}
                            hideCapsuleContentGroup={hideCapsuleContentGroup}
                            existingOptionConnections={
                                existingOptionConnections
                            }
                            isAgentGuideView={isAgentGuideView}
                        />
                    </>
                );
            case CREATE_CONVERSATION_BREAKER_OPTION:
            case EDIT_CONVERSATION_BREAKER_OPTION:
                return (
                    <>
                        <ModalHeading
                            title={isEdit ? `Edit Option` : `Create Option`}
                            subTitle={`A conversation is a collection of multiple branches`}
                        />
                        <CreateConvoBreakerOptionForm
                            isEdit={isEdit}
                            referenceData={referenceData}
                            handleSuccess={handleSuccess}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    {renderBasedOnActionType()}
                </div>
            </div>
        </SideModal>
    );
};

export default CreateBranchOptionModal;
