import React, { useState } from "react";
import CapsuleContentElement from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesPlayground/ClassesPlaygroundTable/CapsuleContentElement/CapsuleContentElement";
import AddButton from "components/ui/Button/AddButton/AddButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleContentList.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const CapsuleContentList = ({
    capsules,
    capsuleClass,
    selectable,
    filterIds,
    toggleSubCapsuleModal,
    hideAddButton,
    handleContentSelect,
    selectedContentIds,
    limitListing,
    viewCapsuleOnly,
    hideEditAction,
    hideDeleteAction,
    capsuleBottomMenu,
}) => {
    const [viewMore, toggleViewMore] = useState(false);
    const viewCapsules =
        limitListing && !viewMore ? capsules.slice(0, 9) : capsules;

    return (
        <div className='capsule__content__list'>
            {viewCapsules?.map((capsule, index) => {
                const contentId = capsule?.[capsuleClass?.collectionKey];
                const filterOut = filterIds?.includes(contentId);
                return filterOut ? (
                    <></>
                ) : (
                    <CapsuleContentElement
                        key={index}
                        selectable={selectable}
                        capsule={capsuleClass}
                        element={capsule}
                        selectedContentIds={selectedContentIds}
                        hasContent={true}
                        handleContentSelect={handleContentSelect}
                        viewCapsuleOnly={viewCapsuleOnly}
                        hideEditAction={hideEditAction}
                        hideDeleteAction={hideDeleteAction}
                        capsuleBottomMenu={capsuleBottomMenu}
                    />
                );
            })}
            {!hideAddButton && (
                <div>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_PRODUCT_SETTING}`]}>
                        <AddButton
                            btnText={`Add ${capsuleClass?.capsuleClassName}`}
                            onClick={() => toggleSubCapsuleModal()}
                        />
                    </ComponentAccessControl>
                </div>
            )}
            {limitListing && capsules?.length > 9 && (
                <div
                    className='view__more__capsules'
                    onClick={() => toggleViewMore(!viewMore)}>
                    {" "}
                    {viewMore ? "Show less" : "Show more"}
                </div>
            )}
        </div>
    );
};

export default CapsuleContentList;
