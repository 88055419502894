import { DOWNTIME_BRANCH, SUB_DOWNTIME_BRANCH, UPTIME_BRANCH } from "../enums";

export const downtimeTypesData = Object.freeze({
    [DOWNTIME_BRANCH]: "Main Downtime Branch",
    [SUB_DOWNTIME_BRANCH]: "Sub Downtime Branch",
    [UPTIME_BRANCH]: "Uptime Branch",
});

export const branchesData = [
    {
        formBranch: [
            {
                branchId: "string",
                branchName: "string",
                branchMainSentence: "string",
                branchSubSentences: ["string"],
                branchMainSentenceImgUrl: "string",
                branchMainSentenceImgCaption: "string",
                branchType: "string",
            },
        ],
        solutionBranch: [
            {
                branchId: "string",
                branchName: "string",
                branchMainSentence: "string",
                branchSubSentences: ["string"],
                branchMainSentenceImgUrl: "string",
                branchMainSentenceImgCaption: "string",
                branchType: "string",
            },
        ],
        uptimeBranch: [
            {
                branchId: "string",
                branchName: "string",
                branchMainSentence: "string",
                branchSubSentences: ["string"],
                branchMainSentenceImgUrl: "string",
                branchMainSentenceImgCaption: "string",
                branchType: "string",
            },
        ],
        subtimeBranch: [
            {
                branchId: "string",
                branchName: "string",
                branchMainSentence: "string",
                branchSubSentences: ["string"],
                branchMainSentenceImgUrl: "string",
                branchMainSentenceImgCaption: "string",
                branchType: "string",
            },
        ],
        downtimeBranch: [
            {
                branchId: "string",
                branchName: "string",
                branchMainSentence: "string",
                branchSubSentences: ["string"],
                branchMainSentenceImgUrl: "string",
                branchMainSentenceImgCaption: "string",
                branchType: "string",
            },
        ],
    },
];
