import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { QA_DOCUMENTATION_PERMS_ACTIONS } from "../../enum";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_ISSUE_CONVERSATION } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const QaDocumentationTableDropdown = ({ qaDocumentation, handleActionModal }) => {
    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleActionModal(value, qaDocumentation)}
            options={[
                {
                    value: QA_DOCUMENTATION_PERMS_ACTIONS.EDIT_QA_DOCUMENTATION,
                    label: "Edit LLM Document",
                    baseResources: [`${UPDATE}_${KB_SAM_ISSUE_CONVERSATION}`],
                },
                {
                    value: QA_DOCUMENTATION_PERMS_ACTIONS.DELETE_QA_DOCUMENTATION,
                    label: "Delete LLM Document",
                    baseResources: [`${DELETE}_${KB_SAM_ISSUE_CONVERSATION}`],
                },
            ]}
        />
    );
};

export default QaDocumentationTableDropdown;
