import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import VariantForm from "./VariantForm/VariantForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const VariantModal = ({
    showSideModal,
    closeModal,
    selectedIssue,
    handleSuccess,
    isEdit,
    editItem
}) => {
    return (
        <SideModal show={showSideModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={`${isEdit ? "Edit" : "Create"} Variant`}
                        subTitle={`${
                            isEdit ? "Edit" : "Create a new"
                        } variant here`}
                    />
                    <VariantForm
                        selectedIssue={selectedIssue}
                        editItem={editItem}
                        isEdit={isEdit}
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                    />
                </div>
            </div>
        </SideModal>
    );
};

VariantModal.prototypes = {
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    showSideModal: PropTypes.bool.isRequired,
    selectedIssue: PropTypes.string.isRequired,
    editItem: PropTypes.object.isRequired,
};

export default VariantModal;
