import AddButton from "components/ui/Button/AddButton/AddButton";
import Heading from "components/ui/Heading/Heading";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";
import { REMINDERS_TABLE_ACTIONS } from "../enums";
import "./RemindersHeader.scss";

const RemindersHeader = ({ hasData, handleActionModal }) => {
    return (
        <div className='reminders-header'>
            <div
                className={`reminders-header__withdata ${
                    !hasData ? "reminders-header__underule" : ""
                }`}>
                <div>
                    <Heading headingText={"Reminders"} />
                </div>
                {!hasData && (
                    <div>
                        <AddButton
                            btnText={"Create reminder"}
                            onClick={() =>
                                handleActionModal(
                                    REMINDERS_TABLE_ACTIONS.CREATE_REMINDER
                                )
                            }
                        />
                    </div>
                )}
            </div>

            {hasData && (
                <div className='reminders-header__empty'>
                    <div>
                        <PaginationSearch
                            placeholder={"Search reminders here"}
                        />
                    </div>
                    <div>
                        <AddButton
                            btnText={"Create reminder"}
                            onClick={() =>
                                handleActionModal(
                                    REMINDERS_TABLE_ACTIONS.CREATE_REMINDER
                                )
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
export default RemindersHeader;
