export const TASK_STATUSES = {
    PENDING: "PENDING",
    TO_DO: "TO_DO",
    COMPLETED: "COMPLETED",
    PAUSED: "PAUSED",
};

export const taskStatusNameMap = Object.freeze({
    [TASK_STATUSES.PENDING]: "Running",
    [TASK_STATUSES.TO_DO]: "To Do",
    [TASK_STATUSES.COMPLETED]: "Completed",
    [TASK_STATUSES.PAUSED]: "Paused",
});
