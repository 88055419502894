import { useState, useEffect } from "react";
import asset from "assets/images";

import {
    getTotalNotificationsCount,
    onMessageListener,
} from "services/firebase/firebase";

import { useSelector } from "react-redux";
import NavNotificationModal from "./NavNotificationModal/NavNotificationModal";
import { NOTIFICATION_CATEGORIES } from "components/ui/Notifications/enum";
import { useContext } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import NavToast from "../../NavToast/NavToast";

const NavNotification = ({ otherClassName }) => {
    const [showModal, toggleModal] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { workspace, userId } = user;

    const [totalMessagesCount, updateTotalMessagesCount] = useState("-");
    const [totalUnreadMessagesCount, updateTotalUnreadMessagesCount] =
        useState("-");

    const [selectedCategory, updateSelectedCategory] = useState(
        NOTIFICATION_CATEGORIES.ALL
    );

    const toastMessage = useContext(ToastContext);

    const toastNotification = ({ title, body }) => {
        toastMessage(<NavToast {...{ title, body }} />);
    };

    onMessageListener().then((payload) => {
        const { notification, data } = payload;
        if (data?.senderId !== userId) {
            toastNotification(notification);
        }
    });

    useEffect(() => {
        getTotalNotificationsCount(
            workspace?.workspaceId,
            selectedCategory,
            updateTotalMessagesCount,
            updateTotalUnreadMessagesCount,
            userId
        );
    }, [workspace, selectedCategory, userId]);

    return (
        <>
            <div
                className={`nav-menu ${otherClassName ? otherClassName : ""}`}
                onClick={() => toggleModal(true)}>
                <img
                    src={asset?.images?.bell}
                    className='notis__bell'
                    alt='notifications'
                />
                <span className='badge bg-danger ms-1'>
                    {totalUnreadMessagesCount}
                </span>
            </div>

            {showModal && (
                <NavNotificationModal
                    showModal={showModal}
                    toggleModal={toggleModal}
                    totalMessagesCount={totalMessagesCount}
                    selectedCategory={selectedCategory}
                    updateSelectedCategory={updateSelectedCategory}
                    user={user}
                />
            )}
        </>
    );
};

export default NavNotification;
