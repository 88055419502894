import Heading from "components/ui/Heading/Heading";

const ConversationTemplatesHeader = () => {
    return (
        <Heading
            headingText='Create conversation templates'
            headingSubText='List of conversation templates'
        />
    );
};

export default ConversationTemplatesHeader;
