import React from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import { DeleteIconButton } from "components/ui/Button/IconButton/IconButton";
import { Checkbox } from "components/ui";
import CustomRulesDropDown from "./CustomRulesDropDown/CustomRulesDropDown";
import { formElementBlocks } from "components/dashboard/KnowledgeBase/FormSetup/BuilderElementsList/data";
import FileAttachment from "./FileAttachment/FileAttachment";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./FormBuilderInputHeading.scss";

const { KB_FORM_SETTING } = baseResources;
const { CREATE, UPDATE, DELETE } = baseResourceActions;

const FormBuilderInputHeading = ({
    id,
    elementType,
    enableSubTitleText,
    acceptNewInputRule,
    customRules,
    handleSubTextStatusChange,
    handleInputDelete,
    toggleBranchModal,
    handleFileAttachmentChange,
    handleUploadUpdate,
}) => {
    return (
        <div className='formbuilder__input--heading'>
            <div className='row align-items-center'>
                <div className='col-1'></div>
                <div className='col-md-2'>
                    <div className='text--holder'>
                        <h3>{formElementBlocks?.[elementType]?.title}</h3>
                    </div>
                </div>
                <div className='col-md-4 pe-0'>
                    {elementType !== "END_FORM" && (
                        <ComponentAccessControl
                            baseResources={[
                                `${CREATE}_${KB_FORM_SETTING}`,
                                `${UPDATE}_${KB_FORM_SETTING}`,
                            ]}>
                            <Checkbox
                                name='addSubtitle'
                                label='Enable subtitile instruction'
                                labelClass='text-grey'
                                inputClass='input-check-grey'
                                id='addSubtitle'
                                checked={enableSubTitleText}
                                onChange={(e) =>
                                    handleSubTextStatusChange(
                                        id,
                                        e.target.checked
                                    )
                                }
                            />
                        </ComponentAccessControl>
                    )}
                </div>
                <div className='col-md-5'>
                    <div className='row formbuilder__heading__actions'>
                        <div className='col-md-7 px-0'>
                            {elementType === "END_FORM" ? (
                                <ComponentAccessControl
                                    baseResources={[
                                        `${CREATE}_${KB_FORM_SETTING}`,
                                        `${UPDATE}_${KB_FORM_SETTING}`,
                                    ]}>
                                    <AddButton
                                        btnText={"Create Collection"}
                                        onClick={() => toggleBranchModal()}
                                    />
                                </ComponentAccessControl>
                            ) : (
                                <ComponentAccessControl
                                    baseResources={[
                                        `${CREATE}_${KB_FORM_SETTING}`,
                                        `${UPDATE}_${KB_FORM_SETTING}`,
                                    ]}>
                                    <CustomRulesDropDown
                                        id={id}
                                        elementType={elementType}
                                        acceptNewInputRule={acceptNewInputRule}
                                        selectedRules={customRules}
                                    />
                                </ComponentAccessControl>
                            )}
                        </div>
                        <div className='col-md-2'>
                            <ComponentAccessControl
                                baseResources={[
                                    `${CREATE}_${KB_FORM_SETTING}`,
                                    `${UPDATE}_${KB_FORM_SETTING}`,
                                ]}>
                                <FileAttachment
                                    id={id}
                                    handleFileAttachmentChange={
                                        handleFileAttachmentChange
                                    }
                                    handleUploadUpdate={handleUploadUpdate}
                                />
                            </ComponentAccessControl>
                        </div>
                        <div className='col-md-3'>
                            <ComponentAccessControl
                                baseResources={[
                                    `${DELETE}_${KB_FORM_SETTING}`,
                                ]}>
                                <DeleteIconButton
                                    onClick={() => handleInputDelete(id)}
                                />
                            </ComponentAccessControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormBuilderInputHeading;
