import AddButton from "components/ui/Button/AddButton/AddButton";
import { Link } from "react-router-dom";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_BRANCH } = baseResources;
const { CREATE, READ } = baseResourceActions;

const DowntimeGuideStep = ({
    downtimeCapsule,
    downtimeBranch,
    addDownTimeBranch,
}) => {
    const { classCapsuleEntryId, capsuleId } = downtimeCapsule;
    return (
        <div
            className='d-flex justify-content-between align-items-center'
            style={{ minHeight: "55px" }}>
            {downtimeBranch ? (
                <ComponentAccessControl
                    baseResources={[`${READ}_${KB_SAM_BRANCH}`]}>
                    <Link
                        to={{
                            pathname: `/t-sam/guide-downtime/view/${capsuleId}/${classCapsuleEntryId}`
                        }}>
                        <div className='text-primary fw-medium'>
                            View downtime guides
                        </div>
                    </Link>
                </ComponentAccessControl>
            ) : (
                <>
                    <span className='no-space me-2'>
                        No downtime guides added
                    </span>
                    <ComponentAccessControl
                        baseResources={[`${CREATE}_${KB_SAM_BRANCH}`]}>
                        <AddButton
                            btnText={"Add downtime guide"}
                            otherClassName='justify-content-end me-2'
                            onClick={addDownTimeBranch}
                        />
                    </ComponentAccessControl>
                </>
            )}
        </div>
    );
};

export default DowntimeGuideStep;
