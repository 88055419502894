import React, { useState, useContext, useEffect } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import AnalyticsFilters from "../common/AnalyticsFilters/AnalyticsFilters";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage, MonthDay, roundUp } from "utils/helper";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
// import API from "services/lib/api";
import {
    computerPercentage,
    getCurrentAnalyticsCustomDates,
    getDatesBasedOnDuration,
    weeklyBasedComputation,
} from "../common/helpers";
// import Axios from "axios";
import API from "services/lib/api";
import {
    setAnalyticsCustomerChartData,
    updateCurrentAnalyticsFilter,
} from "store/analytics/actions";
import AnalyticsBarChart from "../common/AnalyticsBarChart/AnalyticsBarChart";

const { ERROR } = dataQueryStatus;

const AnalyticsTeamCustomer = () => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );

    const [analyticsData, setAnalyticsData] = useState([]);
    const [analyticsResultGroup, setAnalyticsResultGroup] = useState({});

    const [ticketRatingData, setTicketRatingData] = useState([]);
    const [ticketsRatedData, setTicketsRatedData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const {
        currentAnalyticsFilter,
        analyticsCustomer,
        currentAnalyticsCustomDate,
    } = useSelector((state) => state.analytics);

    const dispatch = useDispatch();

    const [options, setOptions] = useState([
        {
            label: "Rating Per Day",
            value: "avgRatingPerDay",
            color: "#F05D23",
        },
        {
            label: "Tickets Rated Per Day",
            value: "totalRatedTicketsPerDay",
            color: "#F05D23",
        },
    ]);

    const [isLoading, setIsLoading] = useState(false);

    const { analyticsRequestDuration, analyticsRequestType } =
        currentAnalyticsFilter || {};

    const toastMessage = useContext(ToastContext);

    const updateRequest = (name, value) => {
        dispatch(
            updateCurrentAnalyticsFilter({
                [name]: value,
            })
        );
    };

    const compileChartData = async (data, keyName, valName) => {
        if (data.length > 0) {
            const dateSeries = getDatesBasedOnDuration(
                analyticsRequestDuration
            );
            const mainArrayData = [];
            // const newValsArray = analyticsRequestValue.split(",");

            dateSeries?.forEach(async (dt) => {
                //assignedTeamId
                let valInstances = data.filter((ad) => {
                    return (
                        new Date(ad.dateValue).toDateString() ===
                        new Date(dt).toDateString()
                    );
                });

                let originalObj = { [keyName]: 0 };

                let result = Object.assign(
                    originalObj,
                    ...valInstances.map((o) => {
                        return {
                            [keyName]: o[valName] ? o[valName] : 0,
                            [`${keyName}_FREQ`]: computerPercentage(
                                o[keyName] || 0,
                                o["totalTicketsCreatedPerDay"]
                            ),
                            originalData: o,
                        };
                    })
                );
                mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
            });
            return mainArrayData;
        }

        // setAnalyticsData(dateSeries)
    };

    const { startDate, endDate } =
        getCurrentAnalyticsCustomDates(
            currentAnalyticsFilter,
            currentAnalyticsCustomDate
        ) || {};

    const getAnalyticsData = async (httpRequest) => {
        try {
            if (startDate === "" || endDate === "") {
                return;
            }
            setIsLoading(true);
            setAnalyticsResultGroup({});

            const commaAnalyticsValue = "";
            // "'" + analyticsRequestValue.split(",").join("','") + "'";
            const url = apiRoute?.getTicketsAnalyticsRatings;
            const res = await API.get(url, {
                headers: {
                    workspaceid: workspaceId,
                },
                params: {
                    ...currentAnalyticsFilter,
                    analyticsRequestValue: commaAnalyticsValue,
                    startDate,
                    endDate,
                    analyticsRequestType: analyticsRequestType || undefined,
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                setAnalyticsData(data);
                setChartLoading(false);
                dispatch(
                    setAnalyticsCustomerChartData({
                        analyticsData: data,
                        analyticsRequestDuration,
                    })
                );
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            setChartLoading(false);
            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            let errorMssg = getErrorMessage(err);
            toastMessage(errorMssg, ERROR);
        }
    };

    const compileAnalyticsGroupData = async () => {
        const compiledTicketsRatingData = await compileChartData(
            analyticsData,
            "avgRatingPerDay",
            "avgRatingPerDay"
        );
        const compiledTicketsRatedData = await compileChartData(
            analyticsData,
            "totalRatedTicketsPerDay",
            "totalRatedTicketsPerDay"
        );

        const totalTicketsCount = analyticsData.reduce(
            (total, obj) => obj.totalTicketsCreatedPerDay + total,
            0
        );
        const totalRatingCount = analyticsData.reduce(
            (total, obj) => obj.avgRatingPerDay + total,
            0
        );
        const totalRatedTickets = analyticsData.reduce(
            (total, obj) => obj.totalRatedTicketsPerDay + total,
            0
        );
        // const totalRatedCount = analyticsData.reduce((total, obj) => obj.totalRatedTicketsPerDay + total,0);

        const dataLength = analyticsData.length;
        const avgRatingsPercent = totalRatingCount / dataLength;
        // const weeklyAvgRatingPercent = weeklyBasedComputation(
        //     analyticsRequestDuration,
        //     avgRatingsPercent
        // );

        const avgRatedTicketsPercent =
            (totalRatedTickets / totalTicketsCount) * 100;
        const weeklyAvgRatedTicketsPercent = weeklyBasedComputation(
            analyticsRequestDuration,
            avgRatedTicketsPercent
        );

        // { weeklyAvgRatingPercent, avgRatedTicketsPercent, weeklyAvgRatedTicketsPercent }
        setTicketRatingData(compiledTicketsRatingData);
        setTicketsRatedData(compiledTicketsRatedData);
        setAnalyticsResultGroup({
            weeklyAvgRatingPercent: `${roundUp(avgRatingsPercent)}`,
            avgRatedTicketsPercent: `${roundUp(avgRatedTicketsPercent)}%`,
            weeklyAvgRatedTicketsPercent: `${roundUp(
                weeklyAvgRatedTicketsPercent
            )}%`,
        });
    };

    useEffect(() => {
        if (analyticsData.length > 0) {
            compileAnalyticsGroupData();
        }
        // eslint-disable-next-line
    }, [analyticsData]);

    useEffect(() => {
        let httpRequest = new AbortController();
        let persistedAnalyticsData =
            analyticsCustomer[analyticsRequestDuration];
        if (persistedAnalyticsData) {
            setAnalyticsData(persistedAnalyticsData);
            setChartLoading(false);
        } else {
            setChartLoading(true);
        }

        getAnalyticsData(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, [analyticsRequestDuration]);

    return (
        <>
            <div className='padded__layout analytics-team-efficiency'>
                <AnalyticsFilters
                    defaultRequest={currentAnalyticsFilter}
                    updateRequest={updateRequest}
                    handleSubmit={getAnalyticsData}
                    currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                    {...{
                        options,
                        isLoading,
                        setOptions,
                    }}
                    showOnlyDuration
                />

                <div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='Review Scores'
                            chartData={ticketRatingData || []}
                            isLoading={chartLoading}
                            strokeData={options}
                            allStrokeData={options}
                            xDataKey1={"dateValue"}
                            xDomain2={[0, 5]}
                            yAxisLabel1={"Scores"}
                            xAxisLabel1={`Time`}
                            yAxisLabel2={"Rating"}
                            xAxisLabel2={"Range(0-5)"}
                            customUnit1={"score(s)"}
                            customUnit2={"score(s)"}
                            roundUpTooltipVal
                            overviews={[
                                {
                                    title: "Average Score",
                                    text: analyticsResultGroup?.weeklyAvgRatingPercent,
                                },
                            ]}
                        />
                    </div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='% of Tickets Rated'
                            chartData={ticketsRatedData || []}
                            isLoading={chartLoading}
                            strokeData={options}
                            allStrokeData={options}
                            xDataKey1={"dateValue"}
                            xDomain2={[0, 100]}
                            yAxisLabel1={"Range"}
                            xAxisLabel1={`Time`}
                            yAxisLabel2={"Rating"}
                            xAxisLabel2={"Range (100%)"}
                            customUnit1={"ticket(s)"}
                            useDefaultOptions
                            // useDefaultOptions={analyticsRequestType?.length>0}
                            hasFreqKey
                            roundUpTooltipVal
                            overviews={[
                                {
                                    title: "Average Monthly Score",
                                    text: analyticsResultGroup?.avgRatedTicketsPercent,
                                },
                                {
                                    title: "Weekly Score",
                                    text: analyticsResultGroup?.weeklyAvgRatedTicketsPercent,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsTeamCustomer;
