import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ConfirmAction, Modal } from "components/ui";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";

const { ERROR } = dataQueryStatus;

const DeleteCRModal = ({
    closeModal,
    showModal,
    selectedCannedResponse,
    handleSuccess
}) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);

    const deleteCannedResponse = async () => {
        try {
            setLoading(true);
            const url = apiRoute?.editCannedResponse(
                selectedCannedResponse?.cannedResponseId
            );

            const res = await API.delete(url, {});

            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
                closeModal()
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <Modal show={showModal} closeModal={closeModal}>
            <ConfirmAction
                close={closeModal}
                title={`Continue to close ${selectedCannedResponse?.title}`}
                loading={loading}
                handleYesClick={deleteCannedResponse}
            />
        </Modal>
    );
};

export default DeleteCRModal;
