import React from "react";
import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";
import TicketPhaseSelectorDropdown from "../TicketsPhasesFilter/TicketPhaseSelector/TicketPhaseSelectorDropdown/TicketPhaseSelectorDropdown";
import "./TicketFilterSelector.scss";

const TicketFilterSelector = ({
    isActive,
    countValue,
    baseStyles,
    activeStyles,
    label,
    fetchingCount,
    onClick,
    otherClassNames,
    useAsTag,
    hasNew,
    handleDropdownAction,
    showFilterSelectorDropdown,
    ticketSource,
    setBorderColor,
    noStyles,
}) => {
    return (
        <div
            onClick={onClick}
            className={
                noStyles
                    ? ""
                    : `${useAsTag ? "tm__tag" : "tickets__filter--selector"} ${
                          isActive ? "active" : ""
                      } ${otherClassNames ? otherClassNames : ""}`
            }
            style={
                isActive
                    ? {
                          background: `${activeStyles?.fillColor}`,
                          borderColor: `${activeStyles?.fillColor}`,
                      }
                    : setBorderColor
                    ? {
                          borderColor: `${baseStyles}`,
                      }
                    : {}
            }>
            {useAsTag ? (
                <span>{label}</span>
            ) : (
                <span className={`selector__value`}>
                    <span>{label}</span>
                    {showFilterSelectorDropdown && (
                        <TicketPhaseSelectorDropdown
                            handleDropdownAction={handleDropdownAction}
                            ticketSource={ticketSource}>
                            <span>{label}</span>{" "}
                        </TicketPhaseSelectorDropdown>
                    )}
                    <span
                        className={`selector_count`}
                        style={
                            isActive
                                ? {
                                      background: `${activeStyles?.subFillColor}`,
                                  }
                                : {}
                        }>
                        {fetchingCount ? (
                            <ShinyLoader
                                width={`20px`}
                                height={`20px`}
                                customClass={`rounded-circle`}
                            />
                        ) : (
                            <>{countValue}</>
                        )}
                        {hasNew && <div className='new__circle'></div>}
                    </span>
                </span>
            )}
        </div>
    );
};

export default TicketFilterSelector;
