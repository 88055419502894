export const TICKET_AGENT_ASSISTANT_STAGES = {
    INVESTIGATE: "INVESTIGATE",
    GUIDE_LIST: "GUIDE_LIST",
    FETCH_GUIDES: "FETCH_GUIDES",
    FETCHED_GUIDE: "FETCHED_GUIDE",
};

export const TICKET_AGENT_TASK_STATUSES = {
    PAUSED: "PAUSED",
    RESUME: "RESUME",
};
