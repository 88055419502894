import React from "react";
import "./EmailSuccess.scss";

const EmailSuccess = ({ toggleEmailPreviewModal }) => {
    return (
        <div className='email__success'>
            <div className='close__email__success'>
                {/* <CloseModal closeModal={toggleEmailPreviewModal} /> */}
            </div>
            <div className='email__success--message__container'>
                <h3 className='email__success__message--title'>
                    Sent successfully.
                </h3>
                <p className='email__success__message--body'>
                    Your email has been sent successfully. Please send a follow
                    up email if you receive no reply after five (5) days.
                </p>
            </div>
        </div>
    );
};

export default EmailSuccess;
