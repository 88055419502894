import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, InputMultiple, Input } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import API from "services/lib/api";
import { apiRoute } from "services";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const DiscoveryForm = ({ selectedIssue, closeModal, handleSuccess }) => {
    const [request, setRequest] = useState({
        metadata: selectedIssue?.metadata,
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMultipleInputChange = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const addDiscovery = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute?.issueDiscovery(selectedIssue?.issueId);
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res?.data;

                toastMessage(message);
                handleSuccess();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            addDiscovery();
        } else {
            setErrors(formErrors);
        }
    };

    const { metadata } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='issueId'
                label='Issue'
                labelClass='text-dark fw-medium'
                id='issueId'
                value={selectedIssue?.issueName}
                onChange={handleChange}
                isErr={errors?.issueId}
                errMssg={errors?.issueId}
                disabled={true}
            />
            <InputMultiple
                grpClass='mb-3'
                type='text'
                name='metadata'
                label='Metadata'
                labelClass='text-dark fw-medium'
                id='metadata'
                placeholder='Add metadata'
                inputValue={metadata}
                setInputValue={handleMultipleInputChange}
                isErr={errors?.metadata}
                errMssg={errors?.metadata}
                autoFocus={true}
            />

            <Button
                type='submit'
                text='Save'
                loading={loading}
                classType='primary'
                otherClass='my-3 w-100'
            />
        </form>
    );
};

DiscoveryForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
    selectedIssue: PropTypes.string.isRequired,
};

export default DiscoveryForm;
