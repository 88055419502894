import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import { useSelector } from "react-redux";
// import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import AnalyticsTSamCapsulesTDElement from "./AnalyticsTSamCapsulesTDElement/AnalyticsTSamCapsulesTDElement";
import "./AnalyticsTSamCapsulesTDropdown.scss";
import API from "services/lib/api";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AnalyticsTSamCapsulesTDropdown = ({ capsuleEntryId }) => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );
    const [capsuleConvoActivity, setCapsuleConvoActivity] = useState();
    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const getCapsuleConvoActivity = async (httpRequest) => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            const url =
                apiRoute?.getCapsuleConversationTicketActivity(capsuleEntryId);
            const res = await API.get(url, {
                headers: {
                    workspaceid: workspaceId,
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setCapsuleConvoActivity(data);
                setStatus(newStatus);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;

            case NULLMODE:
                return <Empty text={"Data not available."} />;

            case DATAMODE:
                return (
                    <>
                        {capsuleConvoActivity?.map(
                            ({ issue, convoFrequencyPercentage }) => (
                                <AnalyticsTSamCapsulesTDElement
                                    issueName={issue?.issueName}
                                    convoFrequencyPercentage={
                                        convoFrequencyPercentage
                                    }
                                />
                            )
                        )}
                    </>
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleConvoActivity}
                    />
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getCapsuleConvoActivity(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='analytics-tsam-capsules-tdropdown'>
                <div className='analytics-tsam-capsules-tdropdown__heading'>
                    <p>Top conversations</p>
                </div>
                <div className='analytics-tsam-capsules-tdropdown__body'>
                    {renderBasedOnStatus()}
                </div>
            </div>
        </>
    );
};

export default AnalyticsTSamCapsulesTDropdown;
