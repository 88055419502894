import SelectUI from "components/ui/SelectUI/SelectUI";
import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";

const GuideFlowSelect = ({
    name = "branchOptionValue",
    selectedValue,
    handleSelect,
    handleCreate,
    error,
    darkLabel,
    guideFlowId,
    isEdit,
    guideFlowType,
    callApi,
    createAble,
    defaultValue
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");
    const [guideFlows, setGuideFlows] = useState([]);

    const getGuideFlows = async (search) => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.getGuideFlows;
            const res = await API.get(url, {
                params:{
                    search,
                    guideFlowType
                }
            });

            if (res.status === 200) {
                const { data } = res.data;
                setGuideFlows(
                    data
                        ?.filter?.(
                            (guideFlow) =>
                                guideFlow?.guideFlowId !== guideFlowId
                        )
                        ?.map((guideFlow) => {
                            return {
                                label: guideFlow?.guideFlowName,
                                value: guideFlow?.guideFlowId,
                                ...guideFlow,
                            };
                        })
                );
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getGuideFlows("")
        // eslint-disable-next-line
    }, [])

    const options = isEdit
        ? [...guideFlows, { label: "None", value: undefined }]
        : guideFlows;

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                placeholder='Select Step'
                isLoading={loading}
                label='Steps'
                options={options}
                error={error ? "step is required" : ""}
                value={defaultValue ? defaultValue : guideFlows?.find(({ value }) => {
                    return value === selectedValue;
                })}
                darkLabel={darkLabel}
                onChange={handleSelect}
                onCreateOption={handleCreate}
                serverError={errorMssg}
                handleApiCall={getGuideFlows}
                callApi={callApi}
                createAble={createAble}
                defaultValue={defaultValue}
            />
        </div>
    );
};

export default GuideFlowSelect;
