import React from 'react';
import "./TypingMessage.scss";

const TypingMessageIndicator = () => {
    return (
        <div
            className={`message__group message-reply`}>
            <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

    );
};

export default TypingMessageIndicator;