import React from "react";
import ReactSelect from "react-select";
import "./OptionSelect.scss";

const OptionSelect = ({
    options,
    placeholder,
    otherClassName,
    handleChange,
    name,
    value,
}) => {
    return (
        <ReactSelect
            options={options}
            placeholder={placeholder}
            className={`option__select ${otherClassName ? otherClassName : ""}`}
            name={name}
            defaultValue={value}
            value={value}
            classNamePrefix='option__select'
            onChange={({ value }, { name }) => handleChange?.(name, value)}
        />
    );
};

export default OptionSelect;
