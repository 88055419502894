export const FOLLOW_UP_TASK_ACTIONS = {
    ADD_NEW_FOLLOW_UP_TASK: "ADD_NEW_FOLLOW_UP_TASK",
    DELETE_FOLLOW_UP_TASK: "DELETE_FOLLOW_UP_TASK",
    EDIT_FOLLOW_UP_TASK: "EDIT_FOLLOW_UP_TASK",
};

export const FOLLOW_UP_TASKS_TABLE_HEADER = [
    "Tasks",
    "Schedule Duration",
    "Actions",
];

export const FOLLOW_UP_TASK_SCHEDULES = [
    {
        value: "RELATIVE",
        label: "After the last task goes",
    },
    {
        value: "AUTOMATIC",
        label: "From original ticket date",
    },
];
