import React, { useState, useEffect } from "react";
import AddCapsuleEntryModal from "components/dashboard/KnowledgeBase/ProductSetupContainer/ProductDashboard/ProductClassesPlayground/modals/AddCapsuleEntryModal/AddCapsuleEntryModal";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { getCapsuleChildrenEntries, getCapsuleParentEntries } from "../lib";
import CapsuleSkeletonLoader from "../../CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import ErrorView from "components/ui/ErrorView/ErrorView";
import CapsuleContentList from "../CapsuleContentList/CapsuleContentList";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const CapsuleContentGroup = ({
    parentCapsuleClass,
    childCapsuleClass,
    productId,
    parentCapsuleId,
    childCapsuleId,
    reverseLookUp,
    capsuleAction,
    setCurrentCapsuleAction,
    updateRenderCount,
    limitListing,
    viewCapsuleOnly,
    hideAddButton,
    hideEditAction,
    hideDeleteAction,
    capsuleBottomMenu,
}) => {
    const [status, setStatus] = useState(IDLE);
    const [showSubCapsuleModal, toggleSubCapsuleModal] = useState(false);
    const [errorMssg, setErrorMssg] = useState();
    const [capsules, setCapsules] = useState([]);

    const getCapsuleEntries = async () => {
        try {
            setErrorMssg();
            setStatus(LOADING);

            if (!reverseLookUp) {
                const capsulesEntriesDefault = await getCapsuleChildrenEntries(
                    parentCapsuleClass,
                    childCapsuleClass,
                    parentCapsuleId
                );
                setCapsules(capsulesEntriesDefault);
            } else {
                const capsuleParentalEntries = await getCapsuleParentEntries(
                    parentCapsuleClass,
                    childCapsuleClass,
                    childCapsuleId
                );
                setCapsules(capsuleParentalEntries);
            }

            setStatus(DATAMODE);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        getCapsuleEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <CapsuleSkeletonLoader count={7} />;

            case DATAMODE:
                return (
                    <CapsuleContentList
                        capsules={capsules}
                        toggleSubCapsuleModal={() =>
                            toggleSubCapsuleModal(true)
                        }
                        capsuleClass={
                            reverseLookUp
                                ? parentCapsuleClass
                                : childCapsuleClass
                        }
                        limitListing={limitListing}
                        viewCapsuleOnly={viewCapsuleOnly}
                        hideAddButton={hideAddButton}
                        hideEditAction={hideEditAction}
                        hideDeleteAction={hideDeleteAction}
                        capsuleBottomMenu={capsuleBottomMenu}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleEntries}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <>
            <>{renderBasedOnStatus()}</>
            <div>
                {showSubCapsuleModal && (
                    <AddCapsuleEntryModal
                        showModal={showSubCapsuleModal}
                        closeModal={() => toggleSubCapsuleModal(false)}
                        selectedProduct={{ value: productId }}
                        capsuleAction={capsuleAction}
                        mainCapsuleClass={
                            reverseLookUp
                                ? parentCapsuleClass
                                : childCapsuleClass
                        }
                        additionRequestObj={{
                            parentCapsuleClass,
                            childCapsuleClass,
                            capsuleId: reverseLookUp
                                ? childCapsuleId
                                : parentCapsuleId,
                        }}
                        selectedCapsuleClass={
                            reverseLookUp
                                ? childCapsuleClass
                                : parentCapsuleClass
                        }
                        selectedSecCapsuleClass={
                            reverseLookUp
                                ? parentCapsuleClass
                                : childCapsuleClass
                        }
                        existingSubCapsules={capsules}
                        setCurrentCapsuleAction={setCurrentCapsuleAction}
                        handleCapsulationSuccess={() => {
                            toggleSubCapsuleModal(false);
                            getCapsuleEntries();
                            updateRenderCount((count) => count + 1);
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default CapsuleContentGroup;
