import React from "react";
import "./TeamClassification.scss";

const TeamClassification = ({ classifications }) => {
    return (
        <div className='classifications'>
            {classifications?.slice(0, 2)?.map((classification, i) => (
                <span className='classifications--item' key={i}>
                    {classification?.name}
                </span>
            ))}
            {classifications?.length > 2 && (
                <span className='classifications--more'>
                    {`+${classifications?.length - 2} others`}
                </span>
            )}
        </div>
    );
};

export default TeamClassification;
