import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import RolesAndPermissionsContent from "./RolesAndPermissionsContent/RolesAndPermissionsContent";
import RolesHeader from "./common/RolesHeader/RolesHeader";
import CreateRoleForm from "./CreateRoleForm/CreateRoleForm";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { dataQueryStatus } from "utils";
import {
    saveRolesAndPermissions,
    clearRolesAndPermissions,
} from "store/modules/admin/actions";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import "./RolesAndPermissions.scss";
import { setRolesAndPermissionsFilterParams } from "store/filters/actions";
import { updateRolesList } from "store/cache/roles/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const RolesAndPermissions = () => {
    const [errorMssg, setErrorMssg] = useState();
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [roles, setRoles] = useState([]);
    const {
        rolesCache: { pages, totalRecords },
    } = useSelector((state) => state.admin);

    const { filterParams } = useSelector(
        (state) => state?.filters?.rolesAndPermissionFilters || {}
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const getRolesAndPermissions = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (roles?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.getRoles;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                dispatch(
                    saveRolesAndPermissions(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setRoles(data);
                dispatch(updateRolesList(data));
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(
            setRolesAndPermissionsFilterParams({ currentPage: 1, search })
        );
        dispatch(clearRolesAndPermissions());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(
            setRolesAndPermissionsFilterParams({ ...filterParams, currentPage })
        );
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);

        if (cachedPage?.length > 0 && !searchIsLoading) {
            setRoles(cachedPage);
            dispatch(updateRolesList(cachedPage));
        } else {
            getRolesAndPermissions(useTableLoader, httpRequest);
        }

        return () => {
            // if (filters.search) {
            //     dispatch(clearRolesAndPermissions());
            // }
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams, useTableLoader]);

    const handleSuccess = () => {
        setClearSearch(true);
        dispatch(clearRolesAndPermissions());
        getRolesAndPermissions()
        // dispatch(
        //     setRolesAndPermissionsFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getRolesAndPermissions}
                    />
                );
            case NULLMODE:
                return useTableLoader ? (
                    <>
                        <RolesHeader status={DATAMODE} />
                        <RolesAndPermissionsContent
                            roles={[]}
                            handleSuccess={handleSuccess}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleSearch={handleSearch}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={filterParams?.search}
                        />
                        <Empty
                            text={"No Role & Permission matches the search."}
                        />
                    </>
                ) : (
                    <>
                        <RolesHeader status={status} />
                        <CreateRoleForm
                            handleSuccess={getRolesAndPermissions}
                        />
                    </>
                );
            case DATAMODE:
                return (
                    <>
                        <RolesHeader status={status} />
                        <RolesAndPermissionsContent
                            roles={roles}
                            handleSuccess={handleSuccess}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleSearch={handleSearch}
                            searchIsLoading={searchIsLoading}
                            clearSearch={clearSearch}
                            defaultValue={filterParams?.search}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    return (
        <div className='padded__layout'>
            <div className='roles__permissions'>{renderBasedOnStatus()}</div>
        </div>
    );
};

export default RolesAndPermissions;
