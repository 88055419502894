import "./NotificationSectionHeader.scss";

const NotificationSectionHeader = ({ indicator }) => {
    return (
        <>
            <div className='notification-section-header'>
                <p className='offset-1'>{indicator}</p>
            </div>
        </>
    );
};

export default NotificationSectionHeader;
