export const convoBreakerActionBranchTypes = Object.freeze({
    FORM_FILLED_COMPLETELY: "FORM_FILLED_COMPLETELY",
    TICKET_CLOSED_ALERT: "TICKET_CLOSED_ALERT",
    ADD_EMAIL_ADDRESS: "ADD_EMAIL_ADDRESS",
    AGENT_UNAVAILABLE: "AGENT_UNAVAILABLE",
    AGENT_FOLLOWUP: "AGENT_FOLLOWUP",
    INPUT_NEEDED: "INPUT_NEEDED",
});

export const convoBreakerActionBranchOptionTypes = Object.freeze({
    OPEN_NEW_TICKET: "OPEN_NEW_TICKET",
    CLOSE_TICKET: "CLOSE_TICKET",
    ADD_EMAIL_ADDRESS: "ADD_EMAIL_ADDRESS",
});

export const convoBreakerActionBranchOptionTypeValues = Object.freeze({
    OPEN_NEW_TICKET: "Open new ticket",
    CLOSE_TICKET: "Resolve by agent",
    ADD_EMAIL_ADDRESS: "Enter email address",
});

export const convoBreakerBranchNames = Object.freeze({
    FORM_FILLED_COMPLETELY: "Form filled completely",
    TICKET_CLOSED_ALERT: "Close ticket",
    ADD_EMAIL_ADDRESS: "Identify Conversation",
    AGENT_UNAVAILABLE: "Agent unavailable",
    AGENT_FOLLOWUP: "Ticket Sent to Agent",
    INPUT_NEEDED: "Reminder message",
});

const { ADD_EMAIL_ADDRESS, CLOSE_TICKET, OPEN_NEW_TICKET } =
    convoBreakerActionBranchOptionTypes;

export const convoBreakerOptionTypesArr = [
    { label: "Add email address", value: ADD_EMAIL_ADDRESS },
    { label: "Close ticket", value: CLOSE_TICKET },
    { label: "Open new ticket", value: OPEN_NEW_TICKET },
];
