import React, { useState } from "react";
import { Empty } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";
// import ClassificationElementButton from "./ClassificationElementButton/ClassificationElementButton";
import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
// import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
// import { baseResources, baseResourceActions } from "routes/enum";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import ItemsList from "components/ui/ItemsList/ItemsList";
import "./ClassificationElementList.scss";

// const { AGENTS_INBOX_BASE_SETTING } = baseResources;
// const { UPDATE } = baseResourceActions;
const { IDLE, NULLMODE, ERROR, DATAMODE, LOADING } = dataQueryStatus;

const ClassificationElementList = ({
    status,
    elements,
    selectedUnassignedCapsule,
    selectedClassification,
    errorMssg,
    handleSuccess,
    prevAssigned,
}) => {
    const [showModal, toggleModal] = useState(false);
    const [selectedClassElement, setClassElement] = useState();

    const handleToggleDeleteModal = (capsule, content, capsuleEntryId) => {
        setClassElement({ capsule, content, capsuleEntryId });
        toggleModal(true);
    };

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <CapsuleSkeletonLoader count={4} />;

            case NULLMODE:
                return <Empty className='h-100' />;

            case DATAMODE:
                return (
                    <>
                        <div className='classification-container-box--classification-capsules'>
                            <ItemsList breakPoint={30}>
                                {elements?.map((element, index) => {
                                    return (
                                        <CapsuleElement
                                            key={index}
                                            capsule={element?.capsule}
                                            capsuleEntryId={
                                                element?.capsuleEntryId
                                            }
                                            useCapsuleStyles={true}
                                            hasBgColor={true}
                                            hideEditAction={true}
                                            handleToggleDeleteModal={(
                                                capsule,
                                                content,
                                                capsuleEntryId
                                            ) =>
                                                handleToggleDeleteModal(
                                                    capsule,
                                                    content,
                                                    capsuleEntryId
                                                )
                                            }
                                            capsuleBottomMenu={true}
                                        />
                                    );
                                })}
                            </ItemsList>
                            {/* <ComponentAccessControl
                                baseResources={[
                                    `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                                ]}>
                                <ClassificationElementButton
                                    selectedUnassignedCapsule={
                                        selectedUnassignedCapsule
                                    }
                                    selectedClassification={
                                        selectedClassification
                                    }
                                    elements={elements}
                                    handleSuccess={handleSuccess}
                                    prevAssigned={prevAssigned}
                                />
                            </ComponentAccessControl> */}
                        </div>
                    </>
                );

            case ERROR:
                return <ErrorView message={errorMssg} size='50' />;

            default:
                return "";
        }
    };

    return (
        <>
            <div className='classification-container-box'>
                {renderCompBasedOnStage()}
            </div>
            {showModal && (
                <ConfirmationModal
                    title={`Are you sure you want to remove ${selectedClassElement?.content}`}
                    show={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={() => handleSuccess(selectedClassification)}
                    url={apiRoute.deleteClassificationElement(
                        selectedClassification?.classificationId,
                        selectedClassElement?.capsuleEntryId
                    )}
                    method='delete'
                />
            )}
        </>
    );
};

export default ClassificationElementList;
