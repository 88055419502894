import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import CreateCommentInput from "../../../TicketMentions/CreateComment/CreateCommentInput/CreateCommentInput";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { getErrorMessage, convertCommentMentions } from "utils/helper";
import { dataQueryStatus } from "utils";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import "./TicketAACreateMentions.scss";

const { ERROR } = dataQueryStatus;

const TicketAACreateMentions = ({
    borderColor,
    cannedMention,
    handleSuccess,
    isDisabled,
    handleCannedActionSuccess,
}) => {
    const { activeTicket } = useSelector((state) => state.tickets);

    const [request, setRequest] = useState({
        comment: cannedMention?.cannedResourceBody,
        ticketId: activeTicket?.ticketId,
        mentioned: cannedMention.receipients || [],
        cannedMentionId: cannedMention?.cannedResourceId,
    });
    const toastMessage = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = ({ target: { value } }) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            comment: value,
        }));
    };

    const handleTagged = (id) => {
        setRequest((prev) => ({
            ...prev,
            mentioned: new Set([...prev?.mentioned, ...id]),
        }));
    };

    const handleSend = async () => {
        try {
            setIsLoading(true);
            const url = apiRoute.createTicketComment;
            const { mentioned, comment } = request;

            const newMentioned = Array.from(mentioned);
            // const filteredMention = newMentioned?.filter((mention) =>
            //     comment?.includes(`@@@__${mention}^^__`)
            // );
            let newComment = convertCommentMentions(comment, true);

            let newRequest = {};

            if (newMentioned?.length === 0) {
                const { mentioned, ...rest } = request;
                newRequest = rest;
            } else {
                newRequest = request;
                newRequest.mentioned = newMentioned;
            }

            const res = await API.post(url, {
                ...newRequest,
                comment: newComment.trim(),
            });

            if (res.status === 201) {
                const { message } = res.data;

                toastMessage(message);
                setRequest({ ...request, comment: "", mentioned: [] });
                handleSuccess();
                handleCannedActionSuccess?.({
                    createdDate: "",
                    updatedDate: "",
                    ticketConversationId: cannedMention?.cannedResourceId,
                    ticketConversationSenderId: "",
                    ticketConversationSenderType: "AGENT",
                    conversationContext: "AGENT",
                    ticketConversationType: "CANNED_MENTIONS",
                });
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <div
            className='ticketaa-create-mentions ticketaa-info-card'
            style={{ borderColor }}>
            <CreateCommentInput
                comment={request?.comment}
                handleChange={handleChange}
                handleTagged={handleTagged}
                isDisabled={isDisabled}
            />
            <span
                className={`ticketaa-info-card-send-btn ${
                    isDisabled ? "disabled" : ""
                }`}
                style={{ background: borderColor }}
                onClick={() => !isDisabled && handleSend()}>
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <ReactSVG
                        src={imageLinks.icons.sendIconv2}
                        className='ticketaa-info-card-send-icon'
                    />
                )}
            </span>
        </div>
    );
};

export default TicketAACreateMentions;
