import UpdatesTableActionButton from "./UpdatesTableActionButton/UpdatesTableActionButton";
import UpdatesTableDropdown from "./UpdatesTableDropdown/UpdatesTableDropdown";

const UpdatesTableAction = ({
    editText,
    handleModalAction,
    cannedResourceType,
    hideView,
    ...rest
}) => {
    return (
        <div className='discovery--actions'>
            <UpdatesTableActionButton
                editText={editText}
                onClick={handleModalAction}
            />
            <UpdatesTableDropdown
                hideView={hideView}
                handleModalAction={handleModalAction}
                cannedResourceType={cannedResourceType}
                {...rest}
            />
        </div>
    );
};
export default UpdatesTableAction;
