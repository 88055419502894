import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import SelectUI from "components/ui/SelectUI/SelectUI";

import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setCollectionsList } from "store/cache/collections/actions";

const BranchCollectionSelect = ({
    name = "branchOptionValue",
    selectedValue,
    handleSelect,
    error,
    filterIds,
    darkLabel,
}) => {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [allBranchCollections, setAllBranchCollections] = useState([]);

    const { collections } = useSelector((state) => state.collections);
    const dispatch = useDispatch();

    const updateCollections = () => {
        const allBranchCollections = collections?.map((branchCollection) => ({
            label: branchCollection.branchCollectionName,
            value: branchCollection.branchCollectionId,
        }));
        setAllBranchCollections(allBranchCollections);
    };

    const getBranchCollections = async () => {
        try {
            setServerError(false);
            setLoading(true);

            const res = await API.get(apiRoute?.getBranchCollections, {
                // params: {
                //     branchCollectionType: "POST_FORM_COLLECTION",
                // },
            });

            if (res.status === 200) {
                const { data } = res?.data;
                setLoading(false);
                dispatch(setCollectionsList(data));
                updateCollections();
                // const filteredCollectionList = allBranchCollections.filter(
                //     (branch) => {
                //         return !filterIds?.includes(branch?.value);
                //     }
                // );
            }
        } catch (err) {
            setLoading(false);
            setServerError(getErrorMessage(err));
            return err;
        }
    };

    useEffect(() => {
        getBranchCollections();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (collections?.length > 0) {
            updateCollections();
        }
        //eslint-disable-next-line
    }, [collections]);

    return (
        <div className='form-group mb-3'>
            <SelectUI
                name={name}
                placeholder='Select collection'
                isLoading={loading}
                label='Collection'
                options={allBranchCollections}
                error={error}
                darkLabel={darkLabel}
                value={allBranchCollections?.find((branchCollection) => {
                    return branchCollection?.value === selectedValue;
                })}
                onChange={handleSelect}
                serverError={serverError}
                handleRetry={() => getBranchCollections()}
            />
        </div>
    );
};

export default BranchCollectionSelect;
