import React, { useEffect } from "react";

const CustomScroll = ({
    children,
    selector,
    axis = "x",
    activeElement,
    className,
    parentScrollId,
}) => {
    const smoothScrollEffect = () => {
        const parentScrollContainer = document.getElementById(parentScrollId);
        const scrollContainerElement = document.getElementById(selector);

        if (!parentScrollContainer || !scrollContainerElement || !activeElement) {
            console.warn("Missing required DOM elements or activeElement.");
            return;
        }

        const activeChild = scrollContainerElement.querySelector(".active");
        if (activeChild) {
            const scrollOptions = {
                behavior: "smooth",
            };

            if (axis === "y") {
                const offsetTop = activeChild.offsetTop - parentScrollContainer.offsetTop;
                scrollOptions.top = offsetTop;
            } else {
                const offsetLeft = activeChild.offsetLeft - parentScrollContainer.offsetLeft;
                scrollOptions.left = offsetLeft;
            }

            parentScrollContainer.scrollTo(scrollOptions);
        }
    };

    useEffect(() => {
        smoothScrollEffect();
        // eslint-disable-next-line
    }, [activeElement, parentScrollId, selector]);

    return (
        <div id={selector} className={className}>
            {children}
        </div>
    );
};

export default CustomScroll;
