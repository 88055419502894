import React from "react";
import { STARTUP_PROFILE_ACTIONS } from "../../enums";
import EditStartupProfile from "../EditStartupProfile/EditStartupProfile";

const { EDIT_STARTUP_PROFILE } = STARTUP_PROFILE_ACTIONS;

const StartupProfileForms = ({
    workspace,
    currentAction,
    closeModal,
    handleSuccess,
}) => {
    const refetchOnSuccess = () => {
        closeModal();
        handleSuccess();
    };

    const renderBasedOnAction = () => {
        switch (currentAction) {
            case EDIT_STARTUP_PROFILE:
                return (
                    <EditStartupProfile
                        workspace={workspace}
                        handleSuccess={refetchOnSuccess}
                    />
                );

            default:
                return "";
        }
    };
    return <>{renderBasedOnAction()}</>;
};

export default StartupProfileForms;
