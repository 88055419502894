import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { getTimeAgo, capitalizeFirstLetter } from "utils/helper";
import { NOTIFICATION_CATEGORIES } from "../../enum";
import ActionIcon from "./ActionIcon/ActionIcon";
import "./NotificationMessage.scss";

const NotificationMessage = ({
    createdAt,
    message,
    title,
    user,
    category,
    data,
    readStatus,
    selectedCategory,
    icon,
}) => {
    return (
        <>
            <div className='notification'>
                <div className='col-10 offset-1'>
                    <div className='row'>
                        <ActionIcon
                            action={icon?.stringValue}
                            read={readStatus}
                        />
                        <div className='col-10'>
                            <h3 className='fw-bold'>{title?.stringValue}</h3>
                            <div className='content'>
                                <p>{message?.stringValue}</p>
                            </div>
                        </div>
                        <div className='col-12 reference'>
                            <div className='col-10 offset-1'>
                                <div className='reference--content'>
                                    <div className='user'>
                                        <img
                                            src={
                                                user?.mapValue?.fields
                                                    ?.userImage?.stringValue
                                            }
                                            alt='user'
                                            className='user--icon'
                                        />
                                        <p className='reference-name'>
                                            {capitalizeFirstLetter(
                                                user?.mapValue?.fields
                                                    ?.firstName?.stringValue
                                            )}
                                        </p>
                                    </div>
                                    <div className='timestamp'>
                                        <ReactSVG src={asset.icons.mutedDot} />
                                        <p>
                                            {getTimeAgo(createdAt.stringValue)}{" "}
                                        </p>
                                        {selectedCategory ===
                                            NOTIFICATION_CATEGORIES.ALL && (
                                            <>
                                                {" "}
                                                <ReactSVG
                                                    src={asset.icons.mutedDot}
                                                />
                                                <p className='category'>
                                                    {data?.mapValue?.fields?.category.stringValue?.toLowerCase()}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationMessage;
