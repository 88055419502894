import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import QaDocumentationForm from "./QaDocumentationForm/QaDocumentationForm";

const CreateQaDocumentationModal = ({
    showModal,
    closeModal,
    isEdit,
    currentQaDocumentation,
    handleSuccess,
}) => {
    return (
        <>
            <SideModal show={showModal} close={closeModal}>
                <div className='row me-0 ms-0'>
                    <div className='col-10 offset-1'>
                        <ModalHeading
                            title={isEdit ? "Update LLM Document" : "Create LLM Document"}
                            subTitle={''}
                        />
                        <QaDocumentationForm
                            handleSuccess={handleSuccess}
                            currentQaDocumentation={currentQaDocumentation}
                            isEdit={isEdit}
                            closeModal={closeModal}
                        />
                    </div>
                </div>
            </SideModal>
        </>
    );
};

export default CreateQaDocumentationModal;
