import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "components/ui";
import FollowupHeader from "./FollowupHeader/FollowupHeader";
import PendingTasks from "./PendingTasks/PendingTasks";
import { useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ErrorView from "components/ui/ErrorView/ErrorView";
import EmptyTasks from "./PendingTasks/EmptyTasks/EmptyTasks";
import { taskStatus } from "./enum";
import { deleteIncomingTicket } from "store/tickets/incomingTickets/actions";
import {
    setActiveTicket,
    setActiveTicketLatestActivity,
} from "store/tickets/actions";
import { selectNextTicket } from "../../CustomerTicketsContainer/CustomerTickets/util/helper";
import "./Followup.scss";
import { FOLLOW_UP_LATEST_ACTIVITY } from "../TicketDetailHeader/TicketDetailHeading/enum";

const { LOADING, DATAMODE, NULLMODE, ERROR, IDLE } = dataQueryStatus;
const { PENDING, TODO } = taskStatus;

const Followup = ({
    selectedTicket,
    followUpActive,
    fetchAllTickets,
    setCannedMessage,
    handleToggleEmailPreview,
    refetchEmails,
}) => {
    const { ticketId } = selectedTicket || {};
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState();
    const [followupTasks, setFollowupTasks] = useState([]);
    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const dispatch = useDispatch();

    const getTicketFollowups = async (httpRequest, taskComplete) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoute.getTicketTasks(ticketId);

            const res = await API.get(url, {
                params: {
                    status: "all",
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                const pendingTasks = data
                    ?.map((task) =>
                        task.ticketFollowupTasks?.filter(
                            (ticketFilter) =>
                                ticketFilter?.taskStatus === PENDING
                        )
                    )
                    .flat();

                const todoTasks = data
                    ?.map((task) =>
                        task.ticketFollowupTasks?.filter(
                            (ticketFilter) => ticketFilter?.taskStatus === TODO
                        )
                    )
                    .flat();

                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;

                if (followUpActive && pendingTasks?.length === 0) {
                    // newStatus = NULLMODE;

                    if (taskComplete) {
                        selectNextTicket(
                            selectedTicket,
                            sortedTickets,
                            (ticket, ticketId) => {
                                dispatch(setActiveTicket(ticket));
                                ticketId &&
                                    dispatch(
                                        deleteIncomingTicket({ ticketId })
                                    );
                            }
                        );
                        return fetchAllTickets();
                    }
                }

                if (todoTasks?.length > 0) {
                    dispatch(
                        setActiveTicketLatestActivity({
                            ticketId,
                            ticketActivity: FOLLOW_UP_LATEST_ACTIVITY.TASK,
                        })
                    );
                } else {
                    dispatch(setActiveTicketLatestActivity(null));
                }

                setFollowupTasks(data);
                setStatus(newStatus);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return <EmptyTasks />;
            case DATAMODE:
                return (
                    <div>
                        {followupTasks?.map((followup, key) => (
                            <div key={key}>
                                <FollowupHeader followupInfo={followup} />
                                <PendingTasks
                                    followupTasks={
                                        followup?.ticketFollowupTasks
                                    }
                                    followupId={followup?.followupId}
                                    getTicketFollowups={getTicketFollowups}
                                    selectedTicket={selectedTicket}
                                    setCannedMessage={setCannedMessage}
                                    handleToggleEmailPreview={
                                        handleToggleEmailPreview
                                    }
                                    refetchEmails={refetchEmails}
                                />
                            </div>
                        ))}
                    </div>
                );
            case ERROR:
                return <ErrorView message={errorMssg} />;
            default:
                return "";
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        getTicketFollowups(httpRequest);

        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket]);

    return <div className='follow__up'>{renderBasedOnStatus()}</div>;
};

export default Followup;
