import PreHeading from "components/ui/PreHeading/PreHeading";
import { CONVERSATION_STATUSES } from "./enums";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ConfirmAction, Modal } from "components/ui";

const { ERROR } = dataQueryStatus;

const ConversationHeader = ({ hasError, conversation }) => {
    const [loading, setLoading] = useState(false);
    const [showModal, toggleModal] = useState(false);

    const [conversationStatus, setConversationStatus] = useState(
        conversation?.conversationStatus
    );

    const toastMessage = useContext(ToastContext);

    const updateConversationStatus = async (status) => {
        try {
            setLoading(true);
            setConversationStatus(status);
            const url = apiRoute.updateConversationStatus(
                conversation?.conversationId
            );

            const res = await API.put(url, {
                status,
            });

            if (res.status === 200) {
                const { message } = res?.data;
                toastMessage(message);
            }
            setLoading(false);
            toggleModal(false);
            setConversationStatus(status);
        } catch (err) {
            setLoading(false);
            setConversationStatus(conversation?.conversationStatus);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleUpdateConvoStatus = ({ value }) => {
        if (!loading) {
            setConversationStatus(value);

            // toggle modal if the conversation has an error but wanted to be set to public
            value === "PUBLISHED" && hasError
                ? toggleModal(true)
                : updateConversationStatus(value);
        }
    };

    const closeModal = () => {
        setConversationStatus(conversation?.conversationStatus);
        toggleModal(false);
    };
    
    return (
        <>
            <PreHeading
                url='/t-sam/conversations'
                text='Conversations'
                subText={conversation?.issue?.issueName}
                conversationStatus={conversation?.conversationStatus}
                hasSelectAction={true}
                selectOptions={CONVERSATION_STATUSES}
                selectedValue={CONVERSATION_STATUSES?.find(
                    ({ value }) => value === conversationStatus
                )}
                handleSelect={handleUpdateConvoStatus}
                hasError={hasError}
            />

            <Modal show={showModal} close={()=>toggleModal(false)}>
                <ConfirmAction
                    title='Are you sure you want to continue, this conversation has in-complete branches'
                    loading={loading}
                    close={closeModal}
                    handleYesClick={() =>
                        updateConversationStatus(conversationStatus)
                    }
                />
            </Modal>
        </>
    );
};

export default ConversationHeader;
