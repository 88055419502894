import { ReactSVG } from "react-svg";
import { messageSources } from "../enums";
import asset from "assets/images";
import "./MessageSource.scss";

const MessageSource = ({ messageSource }) => {
    return (
        <>
            <span className='message__source'>
                {messageSources?.[messageSource]?.source}
            </span>{" "}
            <ReactSVG src={asset.icons.mutedDot} />
        </>
    );
};

export default MessageSource;
