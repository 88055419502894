import React, { useContext, useEffect } from "react";
import { Loading } from "components/ui";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import { useState } from "react";
import API from "services/lib/api";
import { useSelector } from "react-redux";
import InboxApps from "./InboxApps/InboxApps";
import { inboxAppsActionTypes } from "components/essentials/Integrations/enum";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import "./AgentInboxApps.scss";

const { IDLE, LOADING, DATAMODE, ERROR } = dataQueryStatus;

const AgentInboxApps = () => {
    const { user } = useSelector((state) => state.auth);

    const [inboxApps, setInboxApps] = useState([]);
    const [loadingText, setLoadingText] = useState();

    const [status, setStatus] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const toastMessage = useContext(ToastContext);

    const getApps = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setLoadingText({
                title: "Fetching Inbox Apps",
            });

            const url = apiRoute?.getInboxApps;
            const res = await API.get(url, { signal: httpRequest?.signal });
            if (res.status === 200) {
                setInboxApps(res.data.data);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleError = (err) => {
        let errorMsg = getErrorMessage(err);
        toastMessage(errorMsg, ERROR);
        getApps();
    };

    const handleNewConnection = (appName, actionType) => {
        setLoadingText({
            title: `${
                actionType === inboxAppsActionTypes.CONNECT
                    ? "Connecting"
                    : "Disconnecting"
            } ${appName} Inbox`,
        });
        setStatus(LOADING);
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getApps(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading {...loadingText} />;

            case DATAMODE:
                return (
                    <InboxApps
                        data={inboxApps}
                        handleNewConnection={handleNewConnection}
                        handleSuccess={getApps}
                        handleError={handleError}
                        user={user}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} handleRetry={getApps} />;

            default:
                return "";
        }
    };

    return <div>{renderCompBasedOnStage()}</div>;
};

export default AgentInboxApps;
