import "./DeleteBranchesList.scss";
import DeleteBranchItem from "./DeleteBranchItem/DeleteBranchItem";

const DeleteBranchesList = ({ branchConvoCollections }) => {
    const { conversations, collections } = branchConvoCollections;

    return (
        <>
            <div className='delete-brances-list'>
                {collections?.map((collection, key) => (
                    <DeleteBranchItem
                        key={key}
                        branchType='collection'
                        {...collection?.collection}
                    />
                ))}

                {conversations?.map((conversation, key) => (
                    <DeleteBranchItem
                        key={key}
                        branchType='conversation'
                        {...conversation}
                    />
                ))}
            </div>
        </>
    );
};

export default DeleteBranchesList;
