import AgentAssistanceTabs from "./common/AgentAssistanceTabs/AgentAssistanceTabs";
import AgentAGuidesHeading from "./AgentAGuidesHeading/AgentAGuidesHeading";
import AgentAGuidesTable from "./AgentAGuidesTable/AgentAGuidesTable";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "utils";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { AGENT_GUIDE_ACTIONS } from "./enum";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setGuidesFilterOption, setGuidesFilterParams } from "store/filters/actions";
import { clearGuides, saveGuides } from "store/modules/agentAssistance/actions";
import "./AgentAGuides.scss";



const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const { VIEW_GUIDE } = AGENT_GUIDE_ACTIONS;

const AgentAGuides = () => {
    const [guides, setGuides] = useState([]);
    const [errorMssg, setErrorMssg] = useState("");

    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const [showModal, toggleModal] = useState(false);
    const [selectedGuide, selectGuide] = useState();
    const [modalAction, setModalAction] = useState();

    const {
        guidesCache: { pages, totalRecords },
    } = useSelector((state) => state.agentAssistance);

    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.guidesFilters
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);

        dispatch(setGuidesFilterParams({ currentPage: 1, search }));
        dispatch(clearGuides());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setGuidesFilterParams({ ...filterParams, currentPage }));
    };


    const getGuides = async (httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader && setTableIsLoading(true);
            if (guides?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute.getGuides;
            const res = await API.get(url, {
                params: {
                    ...filterParams,
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setGuides(data);
                dispatch(
                    saveGuides(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setGuidesFilterParams(baseFilter));
        dispatch(setGuidesFilterOption({}));
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setGuidesFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setGuidesFilterOption({ ...option, value }));
        dispatch(clearGuides());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setGuidesFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setGuidesFilterOption({ ...option, value }));
        dispatch(clearGuides());
    };

    const handleSortByLastModified = (value, option) => {
        dispatch(
            setGuidesFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setGuidesFilterOption({ ...option, value }));
        dispatch(clearGuides());
    };



    const history = useHistory();

    const handleActionModal = (actionType, guide) => {
        if (actionType === VIEW_GUIDE) {
            return history.push(`/t-sam/guide/view/${guide?.agentGuideId}`);
        }
        selectGuide(guide);
        setModalAction(actionType);
        toggleModal(true);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getGuides} />
                );

            case NULLMODE:
                return <Empty text={"No Guides Available Yet"} />;

            case DATAMODE:
                return (
                    <>
                        <AgentAGuidesTable
                            guides={guides}
                            handleActionModal={handleActionModal}
                            tableIsLoading={tableIsLoading}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    const handleSuccess = () => {
        dispatch(setGuidesFilterParams({ currentPage: 1, search: "" }));
        dispatch(clearGuides());
        setClearSearch(true);
        getGuides();
    };

    useEffect(() => {
        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setGuides(cachedPage);
        } else {
            getGuides();
        }
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    return (
        <>
            <AgentAssistanceTabs />
            <div className='padded__layout agentaguides'>
                <AgentAGuidesHeading
                    defaultValue={filterParams?.search}
                    {...{
                        toggleModal,
                        showModal,
                        modalAction,
                        selectedGuide,
                        handleActionModal,
                        handleSearch,
                        handleSuccess,
                        searchIsLoading,
                        clearSearch,
                        handleSortByAlpha,
                        handleSortByDate,
                        handleSortByLastModified,
                        baseFilter,
                        filterOption,
                        handleBasefilter
                    }}
                />
                {renderBasedOnStatus()}
            </div>
        </>
    );
};

export default AgentAGuides;
