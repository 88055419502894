import { getUniqueListBy } from "utils/helper";
import * as types from "./types";

const initialState = {
    ticketsMessages: [],
    activeTicket: null,
    activeSelectedCustomerMessage: {},
    lastestActivity: null,
    ticketToLeavePhase: null,
};

const TicketsReducer = (state = initialState, { type, payload }) => {
    const { ticketId, messageId, messageContentId, ...otherPayload } = payload || {};
    switch (type) {
        case types.SET_TICKET_MESSAGES:
            let stmProposedVal = getUniqueListBy(payload, "messageId");
            return { ...state, ticketsMessages: stmProposedVal };

        case types.UPDATE_TICKET_MESSAGES:
            let utmProposedVal = getUniqueListBy(
                [...state?.ticketsMessages, payload],
                "messageId"
            );
            return { ...state, ticketsMessages: utmProposedVal };

        case types.UPDATE_TICKET_MESSAGE:
            return {
                ...state,
                ticketsMessages: state?.ticketsMessages?.map((x) => {
                    return x.messageContentId === messageContentId && x.ticketId === ticketId
                        ? { ...x, ...otherPayload }
                        : x;
                }),
            };

        case types.DELETE_TICKET_MESSAGE:
            return {
                ...state,
                ticketsMessages: state?.ticketsMessages?.filter(
                    (x) => x.ticketId !== ticketId && x.messageId !== messageId
                ),
            };

        case types.CLEAR_TICKET_MESSAGES:
            return {
                ...state,
                ticketsMessages: state?.ticketsMessages?.filter(
                    (x) => x.ticketId !== payload
                ),
            };

        case types.SET_ACTIVE_TICKET:
            return {
                ...state,
                activeTicket: payload,
                activeSelectedCustomerMessage: {}
            };

        case types.SET_ACTIVE_TICKET_LATEST_ACTIVITY:
            return {
                ...state,
                lastestActivity: payload,
            };

        case types.SET_TICKET_TO_LEAVE_PHASE:
            return {
                ...state,
                ticketToLeavePhase: payload,
            };

        case types.SET_SELECTED_CUSTOMER_MESSAGE:
            return {
                ...state,
                activeSelectedCustomerMessage: payload,
            };
        default:
            return state;
    }
};

export default TicketsReducer;
