import React from "react";
import SwitchesHeadingActions from "../../../../../SwitchesContainer/SwitchesListing/SwitchesListingHeader/SwitchesHeadingActions/SwitchesHeadingActions";

const ViewSwitchesHeadingActions = ({
    toggleModal,
    handleSearch,
    searchIsLoading,
    clearSearch,
}) => {
    return (
        <SwitchesHeadingActions
            toggleModal={toggleModal}
            handleSearch={handleSearch}
            searchIsLoading={searchIsLoading}
            clearSearch={clearSearch}
        />
    );
};

export default ViewSwitchesHeadingActions;
