import ViewSwitchesContainer from "./ViewSwitchesContainer/ViewSwitchesContainer";

const ViewSwitches = ({ match }) => {
    const { params: { switchType } = {} } = match || {};

    return (
        <>
            <ViewSwitchesContainer switchType={switchType} />
        </>
    );
};

export default ViewSwitches;
