import React from "react";
import CapsuleActionDropdown from "./CapsuleActionDropdown/CapsuleActionDropdown";

const CapsuleContentElement = ({
    capsule,
    element,
    isMain,
    hasContent,
    selectedContentIds,
    selectable,
    handleContentSelect,
    count,
    handleToggleEditModal,
    handleToggleDeleteModal,
    useCapsuleStyles,
    hasBgColor,
    hideEditAction,
    hideDeleteAction,
    viewCapsuleOnly,
    capsuleBottomMenu,
}) => {
    const contentKey = capsule?.collectionKey;
    const contentId = element?.[contentKey];

    const isActive = selectedContentIds?.includes(contentId);

    return (
        <div
            className={`capsule__content ${isActive ? "active" : ""} `}
            onClick={() =>
                selectable ? handleContentSelect(capsule, contentId) : null
            }
            title={capsule?.capsuleCode}>
            {selectable ? (
                <p
                    className={`selectable__capsulecontent d-flex align-items-center ${
                        isActive ? "active" : ""
                    }`}
                    style={
                        isActive
                            ? {
                                  borderColor: capsule?.borderColor?.slice(
                                      0,
                                      7
                                  ),
                                  background: hasBgColor
                                      ? capsule?.borderColor?.slice(0, 7)
                                      : "",
                              }
                            : {}
                    }>
                    {element?.content}
                    {count >= 0 && (
                        <span
                            className='custom-badge'
                            style={
                                isActive
                                    ? {
                                          backgroundColor: capsule?.borderColor,
                                          color: "#fff",
                                      }
                                    : {
                                          backgroundColor: "#e1e1eb",
                                      }
                            }>
                            {count}
                        </span>
                    )}
                </p>
            ) : (
                <CapsuleActionDropdown
                    capsule={capsule}
                    content={element?.content}
                    contentId={contentId}
                    productId={element?.productid}
                    hasChildren={isMain ? false : true}
                    hasContent={hasContent}
                    isMain={isMain}
                    handleToggleEditModal={handleToggleEditModal}
                    handleToggleDeleteModal={handleToggleDeleteModal}
                    hideEditAction={hideEditAction}
                    hideDeleteAction={hideDeleteAction}
                    useCapsuleStyles={useCapsuleStyles}
                    viewCapsuleOnly={viewCapsuleOnly}
                    capsuleBottomMenu={capsuleBottomMenu}>
                    <p
                        className={`content-tag`}
                        style={{
                            color: hasBgColor ? "#696D8C" : "",
                        }}>
                        {element?.content}
                    </p>
                </CapsuleActionDropdown>
            )}
        </div>
    );
};

export default CapsuleContentElement;
