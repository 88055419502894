import { Button } from "components/ui";
import "./ViewGDHeading.scss";
import EditGDStepModal from "../modals/EditGDStepModal/EditGDStepModal";
import { GUIDE_FLOW_TYPES } from "../../enum";
import BranchViewScenarios from "components/dashboard/Branches/BranchView/BranchViewElement/BranchViewScenarios/BranchViewScenarios";

const ViewGDHeading = ({
    handleSuccess,
    classCapsuleEntryId,
    selectedStep,
    showModal,
    toggleModal,
    capsuleClassId,
    selectedStepType,
}) => {
    const { guideFlow } = selectedStep || {};
    const { guideFlowName, mainInstruction } = guideFlow || {};
    return (
        <>
            <section className='view-gd-heading'>
                <div className='view-gd-heading__title'>
                    <h3>
                        {guideFlowName ||
                            `Add a ${selectedStepType ===
                                GUIDE_FLOW_TYPES.DOWNTIME_GUIDE_FLOW
                                ? "downtime"
                                : "uptime"
                            } title`}
                    </h3>
                    {guideFlow && (
                        <Button
                            classType={"plain"}
                            text={"Edit step"}
                            onClick={() => toggleModal(true)}
                        />
                    )}
                </div>
                <h5>Main sentence</h5>
                <p>
                    {mainInstruction ||
                        "You haven't created a main sentence yet"}
                </p>
            </section>

            {
                guideFlow &&
                <div className="mt-2">
                    <BranchViewScenarios
                        guideFlowId={guideFlow?.guideFlowId}
                        scenarios={guideFlow?.scenarios}
                        handleSuccess={handleSuccess}
                    />
                </div>
            }

            {showModal && (
                <EditGDStepModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={handleSuccess}
                    {...{
                        capsuleClassId,
                        classCapsuleEntryId,
                        selectedStepType,
                    }}
                    selectedStep={selectedStep}
                    isEdit={Boolean(selectedStep)}
                />
            )}
        </>
    );
};

export default ViewGDHeading;
