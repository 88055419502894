import React from "react";
import { Dropdown } from "components/ui";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { TEAM_MEMBERS_ACTIONS } from "../../enums";
import { baseResources, baseResourceActions } from "routes/enum";

const { USER_MANAGEMENT_USERS } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const TeamMembersDropdown = ({ member, handleActionModal, currentUserId }) => {
    const { accessStatus, emailVerified, userId } = member || {};

    const handleAction = (value, member) => {
        handleActionModal(value, member);
    };

    const changeUserRole = {
        value: TEAM_MEMBERS_ACTIONS.CHANGE_USER_ROLE,
        label: "Change User Role",
        baseResources: [`${UPDATE}_${USER_MANAGEMENT_USERS}`],
    };

    const deactivateUser = {
        value: TEAM_MEMBERS_ACTIONS.DEACTIVATE_USER,
        label: accessStatus ? "Deactivate User" : "Activate User",
        baseResources: [`${UPDATE}_${USER_MANAGEMENT_USERS}`],
    };

    const resendInvite = {
        value: TEAM_MEMBERS_ACTIONS.RESEND_INVITE,
        label: "Resend Invite",
        baseResources: [`${UPDATE}_${USER_MANAGEMENT_USERS}`],
    };

    const removeUser = {
        value: TEAM_MEMBERS_ACTIONS.REMOVE_USER,
        label: "Remove User",
        baseResources: [`${DELETE}_${USER_MANAGEMENT_USERS}`],
    };

    const dropdownOptions =
        userId === currentUserId
            ? [changeUserRole]
            : emailVerified
            ? [changeUserRole, deactivateUser, removeUser]
            : [changeUserRole, deactivateUser, resendInvite, removeUser];

    return (
        <Dropdown
            size='sm'
            placeholder={
                <ReactSVG
                    src={asset?.icons?.horizontalEllipsis}
                    className='d-inline-flex'
                />
            }
            ddValueClass='borderless'
            className='table__dropdown'
            ddOptionsClass='table__dropdown'
            anchor={false}
            changeValue={false}
            onChange={({ value }) => handleAction(value, member)}
            options={dropdownOptions}
        />
    );
};

export default TeamMembersDropdown;
