import { forwardRef, useRef, useState } from "react";
import Messages from "./Messages/Messages";
import ModalPreview from "./ModalPreview/ModalPreview";
import { useDeepEffect } from "utils/helper";
import "./MessageBody.scss";

// eslint-disable-next-line react/display-name
const DummywithRef = forwardRef(({ id }, ref) => {
    return <div id={id} ref={ref}></div>;
});

const MessageBody = ({
    messages,
    customer,
    handleResendMessage,
    vetAgentTicket,
    selectedCustomerMessage,
    selectCustomerMessage,
    defaultMessages,
}) => {
    const messagesEndRef = useRef(null);

    const [showModal, toggleModal] = useState(false);
    const [preview, setPreview] = useState({
        fileAttachmentUrl: "",
        fileAttachmentType: "",
    });

    const openPreviewModal = (previewObject) => {
        setPreview(previewObject);
        toggleModal(true);
    };

    const closePreviewModal = () => {
        setPreview({ fileAttachmentUrl: "", fileAttachmentType: "" });
        toggleModal(false);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: "smooth",
            bottom: "0",
        });
    };

    useDeepEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <div className='message__body'>
                <Messages
                    {...{
                        customer,
                        openPreviewModal,
                        handleResendMessage,
                        vetAgentTicket,
                        selectedCustomerMessage,
                        selectCustomerMessage,
                        defaultMessages
                    }}
                />
                <DummywithRef id={"dummy"} ref={messagesEndRef} />
            </div>
            {showModal && (
                <ModalPreview
                    showModal={showModal}
                    toggleModal={() => closePreviewModal()}
                    file={preview}
                    preview={preview?.fileAttachmentUrl}
                    previewType={preview?.fileAttachmentType}
                />
            )}
        </>
    );
};

export default MessageBody;
