import AgentAssistanceTabs from "../Guides/common/AgentAssistanceTabs/AgentAssistanceTabs";
import ScenariosHeader from "./ScenariosHeader/ScenariosHeader";
import ScenariosTable from "./ScenariosTable/ScenariosTable";
import "./Scenarios.scss";
import { useEffect, useState } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import BranchScenarioModal from "components/dashboard/Branches/BranchView/BranchViewElement/BranchViewScenarios/modal/BranchScenarioModal/BranchScenarioModal";
import { useDispatch, useSelector } from "react-redux";
import { setScenariosFilterParams } from "store/filters/actions";
import {
    clearScenarios,
    saveScenarios,
} from "store/modules/agentAssistance/actions";

const { IDLE, LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const Scenarios = () => {
    const [scenarios, setScenarios] = useState([]);
    const [errorMssg, setErrorMssg] = useState();
    const [selectedScenario, selectScenario] = useState();
    const [modalAction, setModalAction] = useState();
    const [showModal, toggleModal] = useState(false);

    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);

    const {
        scenariosCache: { pages, totalRecords },
    } = useSelector((state) => state.agentAssistance);

    const { filterParams } = useSelector(
        (state) => state?.filters?.scenariosFilters
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);

        dispatch(setScenariosFilterParams({ currentPage: 1, search }));
        dispatch(clearScenarios());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);

        dispatch(setScenariosFilterParams({ ...filterParams, currentPage }));
    };

    const getScenarios = async (httpRequest) => {
        try {
            setErrorMssg();
            useTableLoader && setTableIsLoading(true);
            if (scenarios?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }
            const url = apiRoute.getScenarios;
            const res = await API.get(url, {
                params: {
                    ...filterParams,
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data.length > 0 ? DATAMODE : NULLMODE;
                setScenarios(data);
                dispatch(
                    saveScenarios(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    const handleSuccess = () => {
        dispatch(setScenariosFilterParams({ currentPage: 1, search: "" }));
        dispatch(clearScenarios());
        setClearSearch(true);
        toggleModal(false);
        getScenarios();
    };

    const handleActionModal = (actionType, scenario) => {
        setModalAction(actionType);
        selectScenario(scenario);
        toggleModal(true);
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getScenarios} />
                );

            case NULLMODE:
                return <Empty text={"No Scenarios Available Yet"} />;

            case DATAMODE:
                return (
                    <>
                        <ScenariosTable
                            handleSuccess={handleSuccess}
                            handleActionModal={handleActionModal}
                            scenarios={scenarios}
                            tableIsLoading={tableIsLoading}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    useEffect(() => {
        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setScenarios(cachedPage);
        } else {
            getScenarios();
        }
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    return (
        <>
            <AgentAssistanceTabs />
            <div className='padded__layout scenarios'>
                <ScenariosHeader
                    handleSuccess={handleSuccess}
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    defaultValue={filterParams?.search}
                    handleActionModal={handleActionModal}
                />
                {renderBasedOnStatus()}
            </div>

            {showModal && (
                <BranchScenarioModal
                    {...{
                        showModal,
                        handleSuccess,
                        modalAction,
                        selectedScenario,
                    }}
                    closeModal={() => toggleModal(false)}
                    errorMssg={errorMssg}
                    setErrorMssg={setErrorMssg}
                    hideExistingScenarios
                />
            )}
        </>
    );
};

export default Scenarios;
