import AnalyticsTSamTabs from "../common/AnalyticsTSamTabs/AnalyticsTSamTabs";
import AnalyticsTSamCapsulesTable from "./AnalyticsTSamCapsulesTable/AnalyticsTSamCapsulesTable";
const AnalyticsTSamCapsules = () => {
    return (
        <>
            <AnalyticsTSamTabs />
            <div className='padded__layout'>
                <AnalyticsTSamCapsulesTable />
            </div>
        </>
    );
};
export default AnalyticsTSamCapsules;
