import React, { useState } from "react";
import { MESSAGE_TAG_ACTIONS } from "../enum";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import MessageTag from "../MessageTagList/MessageTag/MessageTag";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;

const MessageTagActionDropdown = ({
    tag,
    handleActionModal,
    selectedMssgTagId,
    setSelectedMssgTagId,
    setSelectedMssgTagDetails,
}) => {
    const [dropdownOpen, toggle] = useState(false);

    const options = [
        {
            value: MESSAGE_TAG_ACTIONS.DELETE,
            label: "Delete",
            baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
        {
            value: MESSAGE_TAG_ACTIONS.EDIT,
            label: "Edit",
            baseResources: [`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
    ];

    const handleChange = (item) => {
        handleActionModal(item, tag);
        toggle(false);
    };

    const handleSelectMssgTag = (tag) => {
        setSelectedMssgTagId(tag?.messagingTagId);
        setSelectedMssgTagDetails?.(tag);
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            direction='up'
            toggle={() => toggle((prevState) => !prevState)}>
            <div
                onMouseOver={() =>
                    tag?.messagingTagId === selectedMssgTagId && toggle(true)
                }>
                <MessageTag
                    text={tag?.messagingTagName}
                    active={tag?.messagingTagId === selectedMssgTagId}
                    onClick={() => handleSelectMssgTag(tag)}
                />
            </div>
            <DropdownMenu>
                {options?.map((option, i) => (
                    <ComponentAccessControl
                        baseResources={option?.baseResources}
                        key={i}>
                        <DropdownItem
                            onClick={() => handleChange(option.value)}>
                            {option.label}
                        </DropdownItem>
                    </ComponentAccessControl>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default MessageTagActionDropdown;
