import { formatDate } from "utils/helper";

function getDates(start, end) {
    for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
    ) {
        let newDate = new Date(dt).setUTCHours(0, 0, 0, 0);
        arr.push(new Date(newDate).toISOString());
    }
    return arr;
}

export const getDatesBasedOnDuration = (analyticsRequestDuration) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);

    switch (analyticsRequestDuration) {
        case "THIS_WEEK":
            startDate.setDate(startDate.getDate() - 7);
            break;

        case "LAST_WEEK":
            startDate.setDate(startDate.getDate() - 14);
            break;

        case "LAST_MONTH":
        default:
            startDate.setDate(startDate.getDate() - 32);
            break;
    }

    return getDates(startDate, currentDate);
};

export const weeklyBasedComputation = (analyticsRequestDuration, value) => {
    switch (analyticsRequestDuration) {
        case "THIS_WEEK":
            return value / 1;

        case "LAST_WEEK":
            return value / 2;

        case "LAST_MONTH":
        default:
            return value / 4;
    }
};

export const arrangeSolutionTypeData = (solutionReqVal, data) => {
    let {
        extFixAvgFirstResTime,
        extFixAvgResTime,
        extFixAvgResolveTime,
        insFixAvgFirstResTime,
        insFixAvgResTime,
        insFixAvgResolveTime,
        intFixAvgFirstResTime,
        intFixAvgResTime,
        intFixAvgResolveTime,
        externalFixCount,
        instructionalFixCount,
        internalFixCount,
        ...rest
    } = data;

    switch (solutionReqVal) {
        case "averageResponseTime":
            return {
                INSTRUCTIONAL_ANALYTICS_FIX: insFixAvgFirstResTime,
                INTERNAL_ANALYTICS_FIX: intFixAvgFirstResTime,
                EXTERNAL_ANALYTICS_FIX: extFixAvgFirstResTime,
                ...rest,
            };

        case "averageResolutionTime":
            return {
                INSTRUCTIONAL_ANALYTICS_FIX: insFixAvgFirstResTime,
                INTERNAL_ANALYTICS_FIX: intFixAvgFirstResTime,
                EXTERNAL_ANALYTICS_FIX: extFixAvgFirstResTime,
                ...rest,
            };

        case "averageFirstResponseTime":
            return {
                INSTRUCTIONAL_ANALYTICS_FIX: insFixAvgFirstResTime,
                INTERNAL_ANALYTICS_FIX: intFixAvgFirstResTime,
                EXTERNAL_ANALYTICS_FIX: extFixAvgFirstResTime,
                ...rest,
            };

        case "averageVolumeFixCount":
            return {
                INSTRUCTIONAL_ANALYTICS_FIX: instructionalFixCount,
                INTERNAL_ANALYTICS_FIX: internalFixCount,
                EXTERNAL_ANALYTICS_FIX: externalFixCount,
                ...rest,
            };

        default:
            return {
                INSTRUCTIONAL_ANALYTICS_FIX: insFixAvgFirstResTime,
                INTERNAL_ANALYTICS_FIX: intFixAvgFirstResTime,
                EXTERNAL_ANALYTICS_FIX: extFixAvgFirstResTime,
                ...rest,
            };
    }
};

export const computeDataFreq = (data, lookupKey, assignKey) => {
    let results = {};
    let useMinuteAsTime = false;
    let maxTime = 0;
    let totalCounts = 0;
    data?.map(({ [lookupKey]: lk, [assignKey]: ak, totalCount }) => {
        if (ak > maxTime) {
            maxTime = Number(ak);
        }
        if (`${lk}_TOTAL` in results) {
            results[[`${lk}_TOTAL`]] = results[[`${lk}_TOTAL`]] + Number(ak);
        } else {
            results[[`${lk}_TOTAL`]] = Number(ak);
        }

        totalCounts += totalCount;

        return maxTime;
    });

    useMinuteAsTime = true ? maxTime > 3600 : false;
    return { ...results, useMinuteAsTime, totalCounts };
};

export const computerPercentage = (value, totalValue) => {
    return (
        Number.parseFloat(
            ((Number(value) / Number(totalValue)) * 100).toFixed(2)
        ) || 0
    );
};

export const computeChartOverview = (overviews) => {
    let totalValue = 0;
    let higherValue = 0;
    overviews.forEach(({ text }) => {
        const curVal = Number.parseFloat(stripOffDataMetric(text));
        totalValue += curVal;
        if (curVal >= higherValue) {
            higherValue = curVal;
        }
    });

    const updatedData = overviews?.map(({ text, ...rest }) => {
        const currentValue = Number.parseFloat(stripOffDataMetric(text));
        const percent = computerPercentage(currentValue, totalValue);
        return {
            text,
            percent,
            ...rest,
            isHigh: currentValue >= higherValue,
        };
    });

    return updatedData;
};

export const stripOffDataMetric = (text) => {
    const flaggedWords = ["mins", "%"];
    flaggedWords.forEach((element) => {
        if (text?.toString?.()?.includes(element)) {
            return text?.toString?.()?.replace(element, "");
        }
    });

    return text?.toString?.();
};

export const getCurrentAnalyticsCustomDates = (
    currentAnalyticsFilter,
    currentAnalyticsCustomDate
) => {
    const { startDate, endDate } = currentAnalyticsCustomDate;
    const { analyticsRequestDuration } = currentAnalyticsFilter;
    return analyticsRequestDuration === "CUSTOM_DATE"
        ? {
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
          }
        : {};

    // startDate && endDate

    // return analyticsRequestDuration === "CUSTOM_DATE" && startDate && endDate
    //     ? {
    //           startDate: formatDate(startDate),
    //           endDate: formatDate(endDate),
    //       }
    //     : {};
};

export const getReformattedClassificationStrokes = (classifications) => {
    const results = [];
    const tracks = {};

    classifications?.forEach?.(({ data, color, label }) => {
        const { elements } = data || {};
        return elements?.forEach?.(({ capsuleEntryId }) => {
            if (!tracks?.[capsuleEntryId]) {
                return results.push({
                    color,
                    label,
                    value: capsuleEntryId,
                });
            } else {
                return (tracks[capsuleEntryId] = capsuleEntryId);
            }
        });
    });

    return results;
};
