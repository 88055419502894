import React from "react";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateConversationForm from "../../../SAM/Conversations/CreateConversation/form/CreateConversationForm/CreateConversationForm";
import { SideModal } from "components/ui";

const CreateDowntimeBranchModal = ({
    show,
    closeModal,
    modalFormAction,
    referenceData,
    handleSuccess,
}) => {
    return (
        <SideModal show={show} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={`Create Downtime branch for ${referenceData?.content}`}
                    />
                    <CreateConversationForm
                        modalFormAction={modalFormAction}
                        referenceData={referenceData}
                        handleSuccess={handleSuccess}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default CreateDowntimeBranchModal;
