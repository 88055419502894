import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
    loading: false,
    setLoading: null,
});

export function LoadingContextProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>
            {children}
            { loading && <div className="full__screenloader"><SmallLoader /></div>}
        </LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}