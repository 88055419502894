import { apiRoute } from "services";
import API from "services/lib/api";

import ConnectButton from "../common/ConnectButton/ConnectButton";
import { inboxAppsActionTypes } from "../enum";

const TwitterIntegration = ({
    handleNewConnection,
    user,
    actionType,
    appUserId,
    handleSuccess,
    handleError,
}) => {
    const { workspace } = user;

    const connectToTwitter = async () => {
        try {
            handleNewConnection("Twitter", inboxAppsActionTypes?.CONNECT);
            const url = apiRoute?.authorizeTwitter;
            const res = await API.post(
                url,
                {},
                {
                    headers: {
                        workspaceid: workspace?.workspaceId,
                    },
                }
            );
            if (res.status === 201) {
                const { authUrl } = res.data.data;
                window.location.href = authUrl;
            }
        } catch (err) {
            handleError(err);
        }
    };

    const disconnectTwitter = async () => {
        try {
            handleNewConnection("Twitter", inboxAppsActionTypes?.DISCONNECT);
            const url = apiRoute?.disconnectInboxApp(appUserId);
            const res = await API.delete(url);
            if (res.status === 200) {
                handleSuccess();
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            <ConnectButton
                onClick={
                    actionType === inboxAppsActionTypes.CONNECT
                        ? connectToTwitter
                        : disconnectTwitter
                }
                buttonText={
                    actionType === inboxAppsActionTypes.CONNECT
                        ? "Connect"
                        : "Disconnect"
                }
            />
        </>
    );
};

export default TwitterIntegration;
