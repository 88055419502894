export const ANALYTICS_QA_TIMELINE_MESSAGE_TYPES = {
    IN_APP: "IN_APP",
    RESPONSE: "RESPONSE",
    MENTION: "MENTION",
    EMAIL: "EMAIL",
    UPTIME: "UPTIME",
    DOWNTIME: "DOWNTIME",
    SCENARIO: "SCENARIO",
    EMAIL_UPDATE: "EMAIL_UPDATE",
    MENTION_UPDATE: "MENTION_UPDATE",
};

export const ANALYTICS_QA_TIMELINE_MESSAGE_TYPE_NAMES = {
    IN_APP: "In-App",
    RESPONSE: "Response update",
    MENTION: "Mention update",
    EMAIL: "Email",
    EMAIL_UPDATE: "Email update",
    UPTIME: "Uptime message",
    DOWNTIME: "Downtime message",
    SCENARIO: "Scenario",
    MENTION_UPDATE: "Mention update",
};
