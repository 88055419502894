import DependencyCapsulesContainer from "./DependencyCapsulesContainer/DependencyCapsulesContainer";

const DependencyCapsules = () => {
    return (
        <>
            <DependencyCapsulesContainer />
        </>
    );
};

export default DependencyCapsules;
