const AnalyticsTSamCapsulesTDElement = ({
    issueName,
    convoFrequencyPercentage,
}) => {
    return (
        <>
            <div className='analytics-tsam-capsules-element'>
                <span>{issueName}</span>
                <strong>{`${convoFrequencyPercentage}%`}</strong>
            </div>
        </>
    );
};

export default AnalyticsTSamCapsulesTDElement;
