import { getDaysHrsMinsAndSecs } from "utils/helper";

const CannedUpdateDuration = ({ duration }) => {
    const time = getDaysHrsMinsAndSecs(duration);
    const { days, hours, mins, secs } = time;
    return (
        <span>
            {duration === "0"
                ? "0 second"
                : `${
                      days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""
                  } ${
                      hours > 0
                          ? `${hours} ${hours === 1 ? "hour" : "hours"}`
                          : ""
                  } ${
                      mins > 0
                          ? `${mins} ${mins === 1 ? "min" : "mins"}`
                          : ""
                  } ${secs > 0 ? `${secs} ${secs === 1 ? "sec" : "secs"}` : ""}`}
            {" left"}
        </span>
    );
};

export default CannedUpdateDuration;
