import { primaryCapsuleCodes } from "components/dashboard/KnowledgeBase/common/Capsule/dictionary/capsuleCodes";
import { PROBLEM_CLASSES } from "components/dashboard/KnowledgeBase/ProductSetupContainer/enums";
import AddButton from "components/ui/Button/AddButton/AddButton";

const { SOLUTION } = primaryCapsuleCodes;

const AddChildCapsuleEntryButton = ({
    mainCapsuleClass,
    toggleSubCapsuleModal,
    selectedCapsuleClass,
    hasSubCapsules,
}) => {
    const { capsuleClassName, capsuleCode: relatedCapsuleCode } =
        mainCapsuleClass || {};

    const { capsuleCode: parentCapsuleCode } = selectedCapsuleClass || {};

    const showAddBtn =
        ((relatedCapsuleCode === SOLUTION &&
            Object.keys(PROBLEM_CLASSES).includes(parentCapsuleCode)) ||
            (parentCapsuleCode === SOLUTION &&
                Object.keys(PROBLEM_CLASSES).includes(relatedCapsuleCode))) &&
        hasSubCapsules
            ? false
            : true;

    return (
        <>
            {showAddBtn && (
                <AddButton
                    btnText={`Add ${capsuleClassName}`}
                    onClick={() => toggleSubCapsuleModal()}
                />
            )}
        </>
    );
};

export default AddChildCapsuleEntryButton;
