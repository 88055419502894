import React from 'react';
import TMTagItem from './TMTagItem/TMTagItem';
import "./TMTSuggestionList.scss";

const TMTSuggestionList = ({ data, selectedMessageTagId, handleTagSelect }) => {
    return (
        <div className='tmt__suggestion--list'>
            {
                data?.map(({ messagingTagId, messagingTagName }, index) => {
                    return (
                        <TMTagItem
                            key={index}
                            isActive={messagingTagId === selectedMessageTagId}
                            messagingTagId={messagingTagId}
                            messagingTagName={messagingTagName}
                            handleTagSelect={handleTagSelect}
                        />
                    )
                })
            }
        </div>
    );
};

export default TMTSuggestionList;