import React, { useState } from "react";
import { Checkbox } from "components/ui";
import { getDateAndMonth } from "utils/helper";
import { apiRoute } from "services";
import { taskStatus } from "../../../enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import { messageTypes } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import CannedContent from "./CannedContent/CannedContent";
import ItemsList from "components/ui/ItemsList/ItemsList";
import CannedEmail from "./CannedEmail/CannedEmail";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { taskTypes } from "../../enum";
import ScheduleTaskReminder from "./ScheduleTaskReminder/ScheduleTaskReminder";
import "./PendingTask.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;
const { CANNED_RESPONSE } = messageTypes;
const { TIME_BASED, SCENARIO_BASED } = taskTypes;

const PendingTask = ({
    handleOpenEmailPreviewModal,
    followupTask,
    getTicketFollowups,
    followupId,
    emailsExist,
    handleModalToggle,
    handleTogglePFCollectionSendModal,
    isNew,
    type,
}) => {
    const {
        task,
        ticketId,
        ticketFollowupTaskId,
        dueDate,
        taskStatus: ticketTaskStatus,
        reminderDate,
    } = followupTask || {};

    const { taskEmails, taskCannedResponses, taskTitle, branchCollections } =
        task || {};

    const [showTask, toggleTask] = useState(false);
    const [showModal, toggleModal] = useState(false);
    const [delScheduleTaskModal, toggleDelScheduleTaskModal] = useState(false);

    const [isChecked, setIsChecked] = useState(
        ticketTaskStatus === taskStatus.COMPLETED
    );

    const handleToggleModal = () => {
        toggleModal(!showModal);
    };

    return (
        <div className='pending__task'>
            <div
                className='pending__task__actions'
                onClick={() => toggleTask(!showTask)}>
                <div>
                    {" "}
                    <ComponentAccessControl
                        baseResources={[
                            `${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`,
                        ]}>
                        {isNew && (
                            <div className='pending__task__new--indicator'>
                                Due
                            </div>
                        )}
                        <Checkbox
                            label={taskTitle}
                            labelClass={`pending__task__actions--checkbox ${
                                isChecked ? "checked" : ""
                            }`}
                            grpClass='pending__task__actions--checkbox__group'
                            inputClass='input-check-grey'
                            onChange={handleToggleModal}
                            checked={isChecked}
                            disabled={isChecked}
                        />
                    </ComponentAccessControl>
                    {type === TIME_BASED && (
                        <span className='pending__task__actions--timestamp'>
                            {getDateAndMonth(dueDate)}
                        </span>
                    )}
                </div>
                <ReactSVG
                    src={imageLinks?.icons?.tinyArrowRight}
                    className={`toggle__task ${showTask ? "rotate" : ""}`}
                />
            </div>

            {type === SCENARIO_BASED && (
                <ScheduleTaskReminder
                    reminderDuration={reminderDate}
                    ticketId={ticketId}
                    ticketFollowupTaskId={ticketFollowupTaskId}
                    handleSuccess={getTicketFollowups}
                    toggleDelScheduleTaskModal={toggleDelScheduleTaskModal}
                />
            )}

            {showTask && (
                <div className='task__metadata__container'>
                    {taskEmails?.length > 0 && (
                        <div className='email__container'>
                            <div className='email__actions'>
                                <p>Attached canned Email</p>
                            </div>
                            <div className='canned__email__list'>
                                {taskEmails?.map(
                                    (
                                        { cannedEmailBody, cannedEmailSubject },
                                        index
                                    ) => (
                                        <div key={index}>
                                            <ComponentAccessControl
                                                baseResources={[
                                                    `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                                                ]}>
                                                <CannedEmail
                                                    cannedEmailBody={
                                                        cannedEmailBody
                                                    }
                                                    cannedEmailSubject={
                                                        cannedEmailSubject
                                                    }
                                                    onClick={() =>
                                                        handleOpenEmailPreviewModal(
                                                            {
                                                                message:
                                                                    cannedEmailBody,
                                                                subject:
                                                                    cannedEmailSubject,
                                                            },
                                                            emailsExist
                                                                ? true
                                                                : false
                                                        )
                                                    }
                                                />
                                            </ComponentAccessControl>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}

                    {taskCannedResponses?.length > 0 && (
                        <div className='response__container'>
                            <h6 className='response__header'>
                                Canned Response <span>to send to User</span>:
                            </h6>
                            <div className='canned__responses__list'>
                                {taskCannedResponses?.map(
                                    ({ cannedResponse }, index) => (
                                        <div key={index}>
                                            <ComponentAccessControl
                                                baseResources={[
                                                    `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                                                ]}>
                                                <CannedContent
                                                    content={cannedResponse}
                                                    className='canned'
                                                    onClick={() =>
                                                        handleModalToggle(
                                                            {
                                                                response:
                                                                    cannedResponse,
                                                            },
                                                            CANNED_RESPONSE
                                                        )
                                                    }
                                                />
                                            </ComponentAccessControl>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}

                    {branchCollections?.length > 0 && (
                        <div className='response__container'>
                            <h6 className='response__header'>
                                Post form collection{" "}
                                <span>to send to User</span>:
                            </h6>
                            <div className='post__form__collections__list'>
                                <ItemsList breakPoint={10}>
                                    {branchCollections?.map(
                                        (relatedCollection, index) => (
                                            <div key={index}>
                                                <ComponentAccessControl
                                                    baseResources={[
                                                        `${CREATE}_${AGENTS_INBOX_BASE_SETTING}`,
                                                    ]}>
                                                    <CannedContent
                                                        content={
                                                            relatedCollection?.branchCollectionName
                                                        }
                                                        className='postform'
                                                        onClick={() =>
                                                            handleTogglePFCollectionSendModal(
                                                                relatedCollection
                                                            )
                                                        }
                                                    />
                                                </ComponentAccessControl>
                                            </div>
                                        )
                                    )}
                                </ItemsList>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {showModal && (
                <ConfirmationModal
                    title={`Are you sure you want to mark ${taskTitle} as done?`}
                    show={showModal}
                    closeModal={() => toggleModal(!showModal)}
                    handleSuccess={() => {
                        setIsChecked(true);
                        getTicketFollowups(null, true);
                    }}
                    url={apiRoute.updateTicketTask(
                        ticketId,
                        followupId,
                        ticketFollowupTaskId,
                        taskStatus.COMPLETED
                    )}
                    method='put'
                />
            )}

            {delScheduleTaskModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete the task schedule?`}
                    show={delScheduleTaskModal}
                    closeModal={() =>
                        toggleDelScheduleTaskModal(!delScheduleTaskModal)
                    }
                    handleSuccess={() => {
                        getTicketFollowups(null, true);
                    }}
                    url={apiRoute?.scheduleTaskReminder(
                        ticketId,
                        ticketFollowupTaskId,
                        0
                    )}
                    method='put'
                />
            )}
        </div>
    );
};

export default PendingTask;
