import React, { useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import AnalyzerHeading from "./AnalyzerHeading/AnalyzerHeading";
import TagsDiscovered from "./TagsDiscovered/TagsDiscovered";
import AnalyzerSearch from "./AnalyzerSearch/AnalyzerSearch";
import { MESSAGE_TAG_RESPONSE_TYPE } from "./enum";
import "./Analyzer.scss";
import SimilarConversations from "./SimilarConversations/SimilarConversations";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const Analyzer = () => {
    const [messageTagList, setMessageTagList] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState({});
    const [loading, setLoading] = useState(false);

    const [selectedTagResp, setSelectedTagResp] = useState();
    const [showReset, setShowReset] = useState(false);
    const [search, setSearch] = useState("");

    const handleSelectTagResp = (tagResp, tagRespType) => {
        setShowReset(true);
        if (tagRespType === MESSAGE_TAG_RESPONSE_TYPE.CANNED_RESPONSE) {
            const { title, subject, response } = tagResp;
            setSelectedTagResp({
                title,
                subject,
                response,
                tagRespType,
            });
        } else {
            const { issue, conversationId } = tagResp;
            setSelectedTagResp({
                subject: issue?.issueName,
                response: "",
                conversationId,
                tagRespType,
            });
        }
    };



    const getMessageTags = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            setLoading(true);
            setSelectedTagResp();
            setShowReset(false);

            const url = apiRoute?.investigateMessage;
            const res = await API.get(url, {
                params: {
                    search,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    setMessageTagList(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }

                setLoading(false);
                setShowReset(true);
            }
        } catch (err) {
            setStatus(ERROR);
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
            setShowReset(true);
        }
    };

    const getSimilarIssues = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            setLoading(true);
            setSelectedTagResp();
            setShowReset(false);

            const url = apiRoute?.investigateIssueMessage;
            const res = await API.get(url, {
                params: {
                    search,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data.length > 0) {
                    data.length = Math.min(data.length, 5); 
                    setIssueList(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }

                setLoading(false);
                setShowReset(true);
            }
        } catch (err) {
            setStatus(ERROR);
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
            setShowReset(true);
        }
    };

    return (
        <>
            <div className='analyzer'>
                <div className='col-6 analyzer-container'>
                    <AnalyzerHeading
                        selectedTagRespTitle={selectedTagResp?.subject}
                    />
                    <AnalyzerSearch
                        getMessageTags={getSimilarIssues}
                        loading={loading}
                        selectedTagResp={selectedTagResp}
                        showReset={showReset}
                        search={search}
                        setSearch={setSearch}
                    />
                </div>
                <div className='col-6'>
                    <SimilarConversations
                          status={status}
                          issueList={issueList}
                          errorMssg={errorMssg}
                          getMessageTags={getMessageTags}
                      />
                    <TagsDiscovered
                        status={status}
                        messageTagList={messageTagList}
                        errorMssg={errorMssg}
                        getMessageTags={getMessageTags}
                        handleSelectTagResp={handleSelectTagResp}
                    />
                </div>
            </div>
        </>
    );
};

export default Analyzer;
