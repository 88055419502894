import LiveChatInput from 'components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/LiveChatInput/LiveChatInput';
import MessageBody from 'components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/MessageBody';
import { appMessageUserTypes } from 'components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums';
import { Empty } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { apiRoute } from 'services';
import API from 'services/lib/api';
import { generateID } from 'utils/helper';

const ChatSamLLM = () => {

    const defaultContext = "I don't have any relevant information about that, sorry. If you need more help, you can request to talk to an agent";
    const [messages, setMessages] = useState([]);
    const { THIRD_USER, WORKSPACE_AGENT } = appMessageUserTypes;

    const handleCustomerMessage = ({ message }) => {
        console.log("message", message)
        const formattedCustomerMssg = {
            messageId: generateID(),
            messageContent: message,
            senderType: THIRD_USER,
            receiverType: WORKSPACE_AGENT
        }
        setMessages((prevMessages) => ([...prevMessages, formattedCustomerMssg]))
    }

    const fetchAiContextMessaging = useCallback(() => {
        const fetchContextMessaging = async () => {
            try {
                const lastMessage = messages?.[messages?.length - 1];
                if (messages.length > 0 && lastMessage?.senderType === THIRD_USER) {
                    const url = apiRoute.contextAwareMessage;
                    const request = messages.map(({ senderType, messageContent }) => ({
                        role: senderType === THIRD_USER ? "user" : "assistant",
                        content: messageContent
                    }))
                    const res = await API.post(url, {
                        messages: request
                    })
                    if (res.status === 201) {
                        const message = res.data.data
                        const {issues} = res.data
                        const formattedAIMssg = {
                            messageId: generateID(),
                            messageContent: message,
                            senderType: WORKSPACE_AGENT,
                            receiverType: THIRD_USER,
                            branchOptions: message === defaultContext ? [] : issues?.map((issue) => ({
                                branchOptionLabel: issue?.issueName,
                                branchOptionId: issue?.issueId,
                            }))
                        }
                        setMessages((prevMessages) => ([...prevMessages, formattedAIMssg]))
                    }
                }
    
            } catch (err) {
    
            }
        }
        fetchContextMessaging();
    }, [messages, THIRD_USER, WORKSPACE_AGENT])

    useEffect(() => {
        fetchAiContextMessaging()
        // eslint-disable-next-line
    }, [messages])

    return (
        <div className='padded__layout'>
            <div className="row justify-content-center">
                <div className="col-8">
                    <div className='analytics-qava-convos__body'>
                        <div className={`chat__interface`}>

                            <div className={`abs__container`}>
                                <div className={`chat__box scrollable-y`} id='chatBox'>
                                    {
                                        messages.length > 0 ?  <MessageBody
                                        defaultMessages={messages}
                                    /> : <Empty subText="Send a message to start using this feature" hideIcon/>
                                    }
                                  
                                </div>
                                <LiveChatInput
                                    handleNewMessage={handleCustomerMessage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatSamLLM;
