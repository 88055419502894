import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import TeamsModal from "./TeamsModal/TeamsModal";
import TeamTable from "./TeamsTable/TeamTable";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import TeamsHeader from "./TeamsHeader/TeamsHeader";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import { saveTeams, clearTeams } from "store/modules/agentInbox/actions";
import { setTeamsFilterParams } from "store/filters/actions";
import "./Teams.scss";

const { IDLE, NULLMODE, ERROR, DATAMODE, LOADING } = dataQueryStatus;

const Teams = () => {
    const [showSideModal, toggleSideModal] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");
    const [teams, setTeams] = useState([]);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const {
        teamsCache: { pages, totalRecords },
    } = useSelector((state) => state.agentInbox);

    const { filterParams } = useSelector(
        (state) => state?.filters?.teamsFilters || {}
    );

    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const closeModal = () => toggleSideModal(false);

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setTeamsFilterParams({ currentPage: 1, search }));
        dispatch(clearTeams());
    };

    const handlePageChange = (currentPage) => {
        setUseTableLoader(true);
        dispatch(setTeamsFilterParams({ ...filterParams, currentPage }));
    };

    const getTeams = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (teams?.length < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.teams;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setStatus(newStatus);
                setTeams(data);
                dispatch(
                    saveTeams(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams?.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setTeams(cachedPage);
        } else {
            getTeams(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const handleSuccess = () => {
        setClearSearch(true);
        dispatch(clearTeams());
        getTeams();
        // dispatch(
        //     setTeamsFilterParams({
        //         search: "",
        //         currentPage: 1,
        //         pageSize: 5,
        //     })
        // );
    };

    const renderCompBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";
            case LOADING:
                return <Loading />;
            case NULLMODE:
                return (
                    <Empty
                        className='h-100'
                        text={
                            useTableLoader
                                ? "No Team matches the search."
                                : "No Team created."
                        }
                    />
                );
            case DATAMODE:
                return (
                    <>
                        <TeamTable
                            teams={teams}
                            tableIsLoading={tableIsLoading}
                            hasErrors={Boolean(errorMssg)}
                            error={errorMssg}
                            handleSuccess={handleSuccess}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        size='50'
                        handleRetry={getTeams}
                    />
                );
            default:
                return <Loading />;
        }
    };

    return (
        <>
            <div className='row teams'>
                <div className='col-12'>
                    <TeamsHeader
                        status={status}
                        toggleSideModal={toggleSideModal}
                        handleSearch={handleSearch}
                        searchIsLoading={searchIsLoading}
                        clearSearch={clearSearch}
                        searchDefaultValue={filterParams?.search}
                    />
                    {renderCompBasedOnStage()}
                </div>
            </div>
            {showSideModal && (
                <TeamsModal
                    showSideModal={showSideModal}
                    closeModal={closeModal}
                    handleSuccess={handleSuccess}
                />
            )}
        </>
    );
};

export default Teams;
