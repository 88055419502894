import { SideModal } from "components/ui";
import EditGDStepForm from "./EditGDStepForm/EditGDStepForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";

const EditGDStepModal = ({
    showModal,
    closeModal,
    handleSuccess,
    capsuleClassId,
    classCapsuleEntryId,
    selectedStep,
    selectedStepType,
    isEdit,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={isEdit ? "Edit Step" : "Add Step"}
                        subTitle=''
                    />
                    <EditGDStepForm
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        {...{
                            capsuleClassId,
                            classCapsuleEntryId,
                            selectedStep,
                            selectedStepType,
                            isEdit,
                        }}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default EditGDStepModal;
