import { CANNED_RESPONSES_ACTIONS } from "../enums";
import CreateCRModal from "./CreateCRModal/CreateCRModal";
import DeleteCRModal from "./DeleteCRModal/DeleteCRModal";

const {
    CREATE_CANNED_RESPONSE,
    DELETE_CANNED_RESPONSE,
    EDIT_CANNED_RESPONSE,
    VIEW_CANNED_RESPONSE,
} = CANNED_RESPONSES_ACTIONS;

const CannedResponsesModals = ({
    showModal,
    modalAction,
    closeModal,
    selectedCannedResponse,
    handleSuccess,
}) => {
    const isEdit = modalAction === EDIT_CANNED_RESPONSE;
    const isView = modalAction === VIEW_CANNED_RESPONSE;

    const renderBasedOnAction = () => {
        switch (modalAction) {
            case EDIT_CANNED_RESPONSE:
            case CREATE_CANNED_RESPONSE:
            case VIEW_CANNED_RESPONSE:
                return (
                    <CreateCRModal
                        {...{
                            showModal,
                            closeModal,
                            isEdit,
                            isView,
                            cannedResponse: selectedCannedResponse,
                            handleSuccess,
                        }}
                    />
                );

            case DELETE_CANNED_RESPONSE:
                return (
                    <DeleteCRModal
                        {...{
                            closeModal,
                            showModal,
                            selectedCannedResponse,
                            handleSuccess,
                        }}
                    />
                );
            default:
                return "";
        }
    };

    return <>{renderBasedOnAction()}</>;
};

export default CannedResponsesModals;
