import { DropdownItem } from "reactstrap";
import { capsuleActions } from "components/dashboard/KnowledgeBase/common/Capsule/lib";

const CapsuleEditButton = ({
    contentId,
    content,
    capsule,
    handleToggleEditModal,
    hasChildren,
}) => {
    return (
        <>
            <DropdownItem
                onClick={() =>
                    handleToggleEditModal(
                        capsule,
                        contentId,
                        content,
                        capsuleActions?.EDIT_CAPSULE,
                        hasChildren
                    )
                }>
                Edit
            </DropdownItem>
        </>
    );
};

export default CapsuleEditButton;
