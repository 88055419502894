import { useState } from "react";
import AnalyticsQATDHeader from "./AnalyticsQATDHeader/AnalyticsQATDHeader";
import AnalyticsQATicketInfo from "./AnalyticsQATicketInfo/AnalyticsQATicketInfo";
import { ANALYTICS_QA_T_DETAILS_TABS } from "./enum";
import AnalyticsQATVariations from "./AnalyticsQATVariations/AnalyticsQATVariations";
import AnalyticsQAMessageTimeline from "./AnalyticsQAMessageTimeline/AnalyticsQAMessageTimeline";

const { VARIATIONS, COMMENTS, TICKET_INFO, TIMELINE } =
    ANALYTICS_QA_T_DETAILS_TABS;

const AnalyticsQATDetails = () => {
    const [currentTab, setCurrentTab] = useState(VARIATIONS);

    const renderBasedOnTab = () => {
        switch (currentTab) {
            case TIMELINE:
                return <AnalyticsQAMessageTimeline />;
            case VARIATIONS:
                return <AnalyticsQATVariations />;
            case COMMENTS:
                return "";
            case TICKET_INFO:
                return <AnalyticsQATicketInfo />;
            default:
                return "";
        }
    };

    return (
        <section>
            <AnalyticsQATDHeader
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            {renderBasedOnTab()}
        </section>
    );
};

export default AnalyticsQATDetails;
