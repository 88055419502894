import { useState } from "react";
import TicketAACardHeading from "../common/TicketAACardHeading/TicketAACardHeading";
import TicketAAInfoCard from "../common/TicketAAInfoCard/TicketAAInfoCard";
import TicketAARunCard from "../common/TicketAARunCard/TicketAARunCard";
import "./TicketAAEmails.scss";
import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";
import TicketAATasksList from "../common/TicketAATasksList/TicketAATasksList";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
// import TicketAASentEmail from "./TicketAASentEmail/TicketAASentEmail";
const { CANNED_EMAIL } = CANNED_RESOURCE_TYPES;

const TicketAAEmails = ({
    cannedEmail,
    handleToggleEmailPreview,
    updateTasks,
    handleToggleEditTicketTaskModal,
    ticketConversation,
}) => {
    const [open, toggleOpen] = useState(false);
    const {
        cannedResourceBody,
        cannedResourceName,
        cannedResourceId,
        cannedResourceSubject,
        receipients,
    } = cannedEmail || {};

    const [emailData, setEmailData] = useState({
        cannedResourceBody,
        cannedResourceName,
        cannedResourceId,
        receipients,
    });

    const hasEmail = Boolean(cannedEmail);

    const hasCannedEmailBeenSent = ticketConversation?.find(
        ({ ticketConversationId, ticketConversationType }) =>
            ticketConversationId === cannedResourceId &&
            ticketConversationType === CANNED_EMAIL
    );

    const pausedTasks = updateTasks.filter((ut) => ut.paused === true)
    let overalTaskStatuses = updateTasks.reduce(function (r, a) {
        r[a.taskStatus] = (r[a.taskStatus] || 0) + 1;
        return r;
    }, {});

    let overalTaskStaus = pausedTasks.length > 0 ? ["PAUSED", pausedTasks.length] : Object.entries(overalTaskStatuses).reduce((max, entry) => entry[1] >= max[1] ? entry : max, [0, 0])

    return (
        <section className='ticketaa-emails'>
            <TicketAACardHeading
                title='Emails'
                onClick={() => toggleOpen(!open)}
                isOpen={open}
                showDot={!Boolean(hasCannedEmailBeenSent)}
                showMark={Boolean(hasCannedEmailBeenSent)}
            />
            {open && (
                <>
                    {hasEmail && !Boolean(hasCannedEmailBeenSent) ?
                        <>
                            <TicketAAInfoCard
                                borderColor={"#sss"}
                                otherClass={"ticketaa-emails__card"}
                                title={cannedResourceName}
                                text={emailData?.cannedResourceBody}
                                handleChange={(value) =>
                                    setEmailData((prev) => ({
                                        ...prev,
                                        cannedResourceBody: value,
                                    }))
                                }
                                disabled={Boolean(hasCannedEmailBeenSent)}
                                handleSend={() =>
                                    handleToggleEmailPreview(
                                        {
                                            recipientsEmails: receipients,
                                            subject: cannedResourceSubject,
                                            message: emailData?.cannedResourceBody,
                                            messageId: cannedResourceId,
                                        },
                                        EXTERNAL_EMAIL_ACTION.SEND_NEW_EMAIL
                                    )
                                }
                            />
                            {updateTasks?.length > 0 && (
                                <>
                                    <TicketAARunCard
                                        {
                                        ...{
                                            updateTasks,
                                            overalTaskStaus
                                        }
                                        }
                                    />

                                </>
                            )}
                        </> : <TicketAATasksList
                            handleToggleEditTicketTaskModal={
                                handleToggleEditTicketTaskModal
                            }
                            overalTaskStaus={overalTaskStaus}
                            updateTasks={updateTasks}
                            successMssg={`Email Sent to User`}
                        />
                    }
                    {/* <TicketAASentEmail /> */}


                </>
            )}
        </section>
    );
};

export default TicketAAEmails;
