import { ReactSVG } from "react-svg";
import "./AnalyticsQATMessage.scss";
import asset from "assets/images";
import {
    ANALYTICS_QA_TIMELINE_MESSAGE_TYPES,
    ANALYTICS_QA_TIMELINE_MESSAGE_TYPE_NAMES,
} from "../../enum";

const {
    // IN_APP,
    RESPONSE,
    MENTION,
    EMAIL,
    SCENARIO,
    DOWNTIME,
    UPTIME,
    EMAIL_UPDATE,
    MENTION_UPDATE,
} = ANALYTICS_QA_TIMELINE_MESSAGE_TYPES;

const AnalyticsQATMessage = ({ sender, content, time, type, senderName }) => {
    const isReceived = sender === "THIRD_USER";

    const otherClass =
        sender === "THIRD_USER"
            ? type === EMAIL || type === EMAIL_UPDATE
                ? "email_third-user"
                : type === MENTION || type === MENTION_UPDATE
                ? "mention"
                : "received"
            : type === RESPONSE
            ? "response"
            : type === MENTION || type === MENTION_UPDATE
            ? "mention"
            : type === EMAIL
            ? "email"
            : type === EMAIL_UPDATE
            ? "email_update"
            : type === DOWNTIME
            ? "downtime"
            : type === UPTIME
            ? "uptime"
            : type === SCENARIO
            ? "scenario"
            : "";

    const isAgent = sender === "AGENT";
    const isAssistant = sender === "ASSISTANT";
    return (
        <div
            className={`analytics-qa-tmessage ${
                isReceived ? "received" : "sent"
            } ${otherClass} ${isAgent ? "agent" : ""} ${
                isAssistant ? "assistant" : ""
            }`}>
            <div className='analytics-qa-tmessage__sender'>
                <p>
                    <ReactSVG src={asset.images.placeholder} /> Message received
                    from <span>{senderName}</span>
                </p>
                <ReactSVG src={asset.icons.ellipsis} />
            </div>
            <p>{content}</p>
            <div className='analytics-qa-tmessage__bottom'>
                <span className='message__type'>
                    {ANALYTICS_QA_TIMELINE_MESSAGE_TYPE_NAMES[type]}
                </span>
                <span className="dot"></span>
                <span>{time}</span>
            </div>
        </div>
    );
};
export default AnalyticsQATMessage;
