import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { ConfirmAction, Input, Modal } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiRoute } from "services";
import API from "services/lib/api";
import { setAccessToken, setWorkspaceToken } from "storage/cookieStorage";
import { updateAuthInfo, updateParticularWorkspace } from "store/auth/actions";
import { getErrorMessage } from "utils/helper";

const SignIntoWorkspace = ({ closeModal, selectedWorkspace, resetUserStore }) => {
    const {
        user: { email },
    } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);

    const [request, updateRequest] = useState({
        password: "",
    });

    const [errorMsg, setErrorMsg] = useState();
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const toastMessage = useContext(ToastContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const history = useHistory();

    const authorizeLogging = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            setErrors();
            const url = apiRoute.signin;
            const newRequest = {
                email,
                workspaceId: selectedWorkspace?.workspaceId,
                ...request,
            };

            const res = await API.post(url, { ...newRequest });
            if (res.status === 201) {
                const { user, token } = res.data.data;
                const { workspaceName } = selectedWorkspace;
                toastMessage(
                    `Successfully switched to ${workspaceName} workspace`
                );
                await resetUserStore();
                await setAccessToken(token);
                await setWorkspaceToken(token, workspaceName);
                await dispatch(
                    updateParticularWorkspace({
                        user,
                        ...selectedWorkspace,
                    })
                );
                await dispatch(updateAuthInfo(user));
                closeModal();
                setTimeout(() => {
                    history.go();
                }, 500);
            }
        } catch (err) {
            setErrorMsg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (request?.password?.length === 0) {
            return setErrors({ password: true });
        }
        return authorizeLogging();
    };

    return (
        <>
            <Modal show={true} close={closeModal}>
                <form onSubmit={handleSubmit}>
                    <ConfirmAction
                        title={`Please sign into ${selectedWorkspace?.workspaceName} workspace`}
                        loading={loading}
                        close={closeModal}
                        handleYesClick={handleSubmit}
                        hideSubtitle>
                        <ErrorDialog
                            show={Boolean(errorMsg)}
                            message={errorMsg}
                            hide={() => setErrorMsg()}
                        />
                        <Input
                            grpClass='mb-3 mt-3'
                            type='password'
                            name='password'
                            label='Password'
                            labelClass='text-dark fw-bold'
                            id='password'
                            placeholder='Enter your password'
                            value={request?.password}
                            showPwd
                            onChange={handleChange}
                            isErr={errors?.password}
                            errMssg={errors?.password}
                        />
                    </ConfirmAction>
                </form>
            </Modal>
        </>
    );
};

export default SignIntoWorkspace;
