import { ReactSVG } from "react-svg";
import asset from "assets/images";

import "./AnalyticsQATMHeader.scss";

const AnalyticsQATMHeader = () => {
    return (
        <div className='analyticsqa-tm-header'>
            <div className='analyticsqa-tm-header__left'>
                <ReactSVG src={asset.icons.back} />
                <span>10123</span>
            </div>
            <div className='analyticsqa-tm-header__right'>
                <span>Tolulope</span>
                <span>|</span>
                <b>tolu4eva@yahoo.com</b>
                <ReactSVG src={asset.icons.horizontalEllipsis} />
            </div>
        </div>
    );
};

export default AnalyticsQATMHeader;
