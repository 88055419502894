import AnalyticsQACard from "../../common/AnalyticsQACard/AnalyticsQACard";

const AnalyticsQAReportSummary = () => {
    const data = [
        {
            title: "Analyzed tickets",
            value: 32,
            dir: "",
            change: "",
        },
        {
            title: "Passed tickets",
            value: 20,
            dir: "",
            change: "75%",
        },
        {
            title: "Awaiting quality assurance",
            value: 12,
            change: "25%",
        },
    ];

    return (
        <section>
            <h3>Summary</h3>
            <div className='row'>
                {data?.map((item, key) => (
                    <div className='col-lg-3' key={key}>
                        <AnalyticsQACard {...item} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default AnalyticsQAReportSummary;
