import React from "react";
import { Route } from "react-router-dom";
import SeoWrapper from "components/dashboard/common/SeoWrapper/SeoWrapper";
import { connect } from "react-redux";
import TabbedWrapperRouter from "../TabbedWrapperRouter/TabbedWrapperRouter";
import { pathTypes, defaultPermissions } from "routes/enum";
import NoPermission from "pages/PublicPages/ErrorPages/NoPermission/NoPermission";

const { SINGLE, TABBED, DROPDOWN } = pathTypes;

const AccessControlRoute = ({
    baseResources,
    path,
    title,
    isExact,
    pathType,
    permissions,
    tabs,
    component: Component,
}) => {
    const permissionCodes = permissions?.map(
        (permission) => permission?.permissionCode
    );

    let permissionList = permissionCodes?.length > 0 ? permissionCodes : [];

    const renderBasedOnRouteType = () => {
        switch (pathType) {
            case SINGLE:
            case DROPDOWN:
                return (
                    <Route
                        exact={false}
                        path={path}
                        render={(props) => (
                            <SeoWrapper title={title}>
                                <Component title={title} {...props} />
                            </SeoWrapper>
                        )}
                    />
                );
            case TABBED:
                return (
                    <Route
                        exact={isExact ? true : false}
                        path={path}
                        render={() => (
                            <TabbedWrapperRouter tabbedRoutes={tabs} />
                        )}
                    />
                );
            default:
                return (
                    <Route
                        exact={false}
                        path={path}
                        render={(props) => (
                            <SeoWrapper title={title}>
                                <Component title={title} {...props} />
                            </SeoWrapper>
                        )}
                    />
                );
        }
    };
    return (
        <>
            {[...permissionList, ...defaultPermissions]?.some((code) =>
                baseResources?.includes(code)
            ) ? (
                <>{renderBasedOnRouteType()}</>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

export default connect(
    (state) => ({
        permissions: state?.auth?.user?.role?.permissions,
    }),
    {}
)(AccessControlRoute);
