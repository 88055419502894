import PropTypes from "prop-types";
import AttachedCapsuleClassesList from "./AttachedCapsuleClassesList/AttachedCapsuleClassesList";
import Capsule from "components/dashboard/KnowledgeBase/common/Capsule/Capsule";
import "./AttachedCapsuleClassesTable.scss";

const AttachedCapsuleClassesTable = ({
    selectedCapsule,
    handleCapsulationSuccess,
}) => {
    return (
        <div className='attached__classes__table'>
            <div className='col-lg-11'>
                <div className='attached__classes__table--header'>
                    <div className='col-3'>
                        <h4 className='attached__classes__table--header--text'>
                            Class
                        </h4>
                    </div>
                    <div className='col-9'>
                        <h4 className='attached__classes__table--header--text'>
                            Attached Classes
                        </h4>
                    </div>
                </div>
                <div className='attached__classes__table--list'>
                    <div className='col-3' style={{ paddingLeft: 0 }}>
                        <Capsule capsule={selectedCapsule} isActive={true} />
                    </div>
                    <div className='col-9'>
                        <AttachedCapsuleClassesList
                            attachedCapsuleClasses={[
                                ...selectedCapsule?.children,
                                ...selectedCapsule?.parents,
                            ]}
                            selectedCapsule={selectedCapsule}
                            handleCapsulationSuccess={handleCapsulationSuccess}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

AttachedCapsuleClassesTable.prototypes = {
    getCapsuleClasses: PropTypes.func.isRequired,
    selectedCapsule: PropTypes.object.isRequired,
    capsuleClasses: PropTypes.array.isRequired,
};

export default AttachedCapsuleClassesTable;
