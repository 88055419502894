import { defaultPermissions } from "routes/enum";

export const pickValidRoute = (tabbedRoutes, permissionCodes) => {
    let validRoute;
    let permissionList = permissionCodes?.length > 0 ? permissionCodes : [];
    for (let i = 0; i < tabbedRoutes?.length; i++) {
        if (
            [...permissionList, ...defaultPermissions]?.some((code) =>
                tabbedRoutes[i]?.baseResources?.includes(code)
            )
        ) {
            validRoute = tabbedRoutes?.[i]?.path;
            break;
        }
    }

    return validRoute;
};

export const checkDropdownForPermission = (title) => {
    return title === "Setting";
};
