import React, { useState } from "react";
import debounce from "debounce-promise";
import PropTypes from "prop-types";
import { InputSearch } from "components/ui";
import API from "services/lib/api";
import { apiRoute } from "services";
import "./AsyncSearchForm.scss";

const AsyncSearchForm = ({
    setData,
    endpoint,
    placeholder,
    id,
    params,
    setIsLoading,
    defaultValue,
}) => {
    const [loading, setLoading] = useState(false);

    const handleApiCall = async (search) => {
        try {
            setLoading(true);
            setIsLoading?.(true);
            let response;
            if (id) {
                response = await API.get(apiRoute[endpoint](id), {
                    params: { search, ...params },
                });
            } else {
                response = await API.get(apiRoute[endpoint], {
                    params: { search, ...params },
                });
            }

            if (response.status === 200) {
                setLoading(false);
                setIsLoading?.(false);
                setData(response.data.data, search);
            }
        } catch (error) {
            setLoading(false);
            setIsLoading?.(false);
            setData([]);
        }
    };

    const debouncedSearch = React.useMemo(
        () =>
            debounce((value) => handleApiCall(value), 1000, { leading: true }),
        // eslint-disable-next-line
        []
    );

    return (
        <div className=''>
            <InputSearch
                placeholder={placeholder}
                onChange={debouncedSearch}
                isLoading={loading}
                inputClass='async-search'
                defaultValue={defaultValue}
            />
        </div>
    );
};

AsyncSearchForm.prototypes = {
    setData: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
};

export default AsyncSearchForm;
