import { useContext, useEffect, useState } from "react";
import { Switch } from "components/ui";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_DEPENDENCY } = baseResources;
const { UPDATE } = baseResourceActions;
const { ERROR } = dataQueryStatus;

const SwitchesStatusSwitch = ({ dependencyId, status, getDependencies }) => {
    const toastMessage = useContext(ToastContext);

    const [switchesState, setSwitchesState] = useState(false);

    const updateDependencySwitchState = async (state) => {
        try {
            setSwitchesState(state);

            const url = apiRoute?.updateDependencyStatus(dependencyId);

            const res = await API.patch(url, {
                status: Boolean(state),
            });

            if (res.status === 200) {
                const { message } = res?.data;
                getDependencies();

                toastMessage(message);
            }
        } catch (err) {
            setSwitchesState(!state);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    const handleSwitch = async (state) => {
        await updateDependencySwitchState(state);
    };

    useEffect(() => {
        setSwitchesState(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <>
            <ComponentAccessControl
                baseResources={[`${UPDATE}_${KB_DEPENDENCY}`]}>
                <Switch
                    checked={switchesState}
                    label={switchesState ? "ON" : "OFF"}
                    id='dependency'
                    onChange={(e) => handleSwitch(e.target.checked)}
                />
            </ComponentAccessControl>
        </>
    );
};

export default SwitchesStatusSwitch;
