import { NavSearch } from "./NavSearch/NavSearch";
import NavBarDropdownGroup from "./NavBarDropdownGroup/NavBarDropdownGroup";
import NavMenu from "./NavMenu/NavMenu";
import "./Nav.scss";
import TicketsSearch from "components/dashboard/AgentInbox/AgentInboxTickets/TicketsSearch/TicketsSearch";

const Nav = ({ closeDropdown, collapsed, toggleSidebar, pathname }) => {
    const showTicketSearch = pathname === "/agents-inbox/tickets";

    return (
        <nav
            id='nav'
            className={`d-flex align-items-center ${
                collapsed ? "toggler-inclusive" : ""
            }`}
            onClick={closeDropdown}>
            <NavMenu {...{ collapsed, toggleSidebar }} />

            {showTicketSearch ? (
                <TicketsSearch />
            ) : (
                <NavSearch
                    grpClass='me-auto'
                    placeholder='Ask us anything'
                    id='nav-earch'
                    name='nav-search'
                    onSearch={(value) => {}}
                />
            )}

            <NavBarDropdownGroup />
        </nav>
    );
};

export default Nav;
