import React from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import "./AddButton.scss";

const AddButton = ({
    btnText,
    otherClassName = "",
    hasIcon = true,
    loading,
    customIcon,
    fillIcon,
    ...rest
}) => {
    return (
        <button
            className={`add-button add-button--add ${otherClassName}`}
            {...rest}
            type='button'>
            {loading ? (
                <SmallLoader otherClassName={"addLoaderIcon"} />
            ) : (
                <>
                    {" "}
                    {hasIcon && (
                        <ReactSVG
                            src={customIcon || asset?.icons?.addPlusIcon}
                            className={`plusIcon ${fillIcon ? "fill" : ""}`}
                        />
                    )}
                </>
            )}
            <span>{btnText}</span>
        </button>
    );
};

export default AddButton;
