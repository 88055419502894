import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateGDUpdateForm from "./CreateGDUpdateForm/CreateGDUpdateForm";

const CreateGDUpdateModal = ({
    selectedStepType,
    isEdit,
    closeModal,
    showModal,
    selectedStep,
    handleSuccess,
    selectedUpdate,
    handleRefresh,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={isEdit ? "Edit Update" : "New Update"}
                        subTitle=''
                    />
                    <CreateGDUpdateForm
                        {...{
                            selectedStepType,
                            closeModal,
                            handleSuccess,
                            selectedStep,
                            selectedUpdate,
                            isEdit,
                            handleRefresh,
                        }}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default CreateGDUpdateModal;
