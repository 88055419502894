import { SideModal } from "components/ui";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import CreateCRModalForm from "./CreateCRModalForm/CreateCRModalForm";

const CreateCRModal = ({
    showModal,
    closeModal,
    isEdit,
    isView,
    cannedResponse,
    handleSuccess,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            isView
                                ? "View Canned Response"
                                : isEdit
                                ? "Edit Canned Response"
                                : "Add Canned Response"
                        }
                        subTitle=''
                    />
                    <CreateCRModalForm
                        {...{
                            isView,
                            isEdit,
                            cannedResponse,
                            closeModal,
                            handleSuccess,
                        }}
                    />
                </div>
            </div>
        </SideModal>
    );
};

export default CreateCRModal;
