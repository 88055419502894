import { useState } from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import "./InputSearch.scss";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";

export const InputSearch = ({
    grpClass,
    placeholder,
    inputClass,
    onSearch,
    id,
    name,
    reversed,
    onChange,
    isLoading,
    defaultValue,
    ...rest
}) => {
    const [focused, setFocused] = useState("");
    const [searchValue, setSearch] = useState(defaultValue || "");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!searchValue) return;

        onSearch(searchValue);
    };

    const handleClearSearch = () => {
        setSearch("");
        onChange?.("");
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`input-group input-search-group search-input ${
                grpClass || ""
            }`}>
            <span
                className={`input-group-text not-reversed ${focused}`}
                id='search-addon1'>
                <ReactSVG
                    src={asset?.icons?.search}
                    className='d-inline-flex'
                />
            </span>
            <input
                type='text'
                id={id}
                name={name}
                className={`form-control px-0 not-reversed ${
                    isLoading ? "is-loading" : ""
                } ${inputClass || ""} ${
                    searchValue?.length === 0 ? "override-clear-input" : ""
                } override-clear-input`}
                placeholder={placeholder}
                aria-label='Search'
                aria-describedby='search-addon1'
                value={searchValue}
                defaultValue={searchValue}
                onChange={({ target: { value } }) => {
                    setSearch(value);
                    onChange?.(value);
                }}
                onFocus={() => setFocused("focus")}
                onBlur={() => setFocused("")}
                {...rest}
            />
            <span
                className={`input-group-text ${focused} ${
                    searchValue?.length === 0 ? "override-clear-search" : ""
                }`}
                onClick={searchValue && handleClearSearch}>
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <>
                        {searchValue?.length > 0 && (
                            <ReactSVG
                                src={asset?.icons?.cancelX}
                                className='d-inline-flex clear__search__value'
                            />
                        )}
                    </>
                )}
            </span>
        </form>
    );
};
