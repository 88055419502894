import { Button, Loading, Textarea } from "components/ui";
import TicketInfoAttachments from "../TicketInfoAttachments/TicketInfoAttachments";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useSelector } from "react-redux";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { dataQueryStatus } from "utils";

const { LOADING, ERROR, IDLE, DATAMODE } = dataQueryStatus;

const TicketInfoClipContent = ({fileAttachments, extEmailAttachments, handleToggleEmailAttachment}) => {
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [status, setStatus] = useState(IDLE);
    const [errorMssg, setErrorMssg] = useState("");

    const toastMessage = useContext(ToastContext);

    const { activeTicket } = useSelector((state) => state.tickets);

    const getTicketClipboard = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoute?.getTicketDetails(activeTicket?.ticketId);
            const res = await API.get(url);

            if (res.status === 200) {
                const ticket = res.data.data;
                const { ticketNote } = ticket || {};
                setContent(ticketNote);
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const saveTicketClipboard = async () => {
        try {
            setLoading(true);
            setError();
            const res = await API.put(
                apiRoute?.setTicketClipboard(activeTicket?.ticketId),
                {
                    content,
                }
            );
            if (res.status === 200) {
                const { message } = res.data;
                toastMessage(message);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            const errorMessage = getErrorMessage(err);
            toastMessage(errorMessage);
        }
    };

    const handleSubmit = () => {
        if (content?.length === 0) {
            setError("Incomplete input");
        } else {
            saveTicketClipboard();
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <Loading />;
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getTicketClipboard}
                    />
                );
            case DATAMODE:
                return (
                    <>
                        <Textarea
                            placeholder='Copy and Paste info here or type your note'
                            value={content}
                            name='clipboard'
                            isErr={error}
                            onChange={(e) => setContent(e?.target?.value)}
                        />
                        <Button
                            text={"Save Clipboard"}
                            type='submit'
                            classType='primary'
                            loading={loading}
                            otherClass='my-3 w-100'
                            onClick={handleSubmit}
                        />
                    </>
                );
            default:
                return "";
        }
    };

    useEffect(() => {
        getTicketClipboard();
        // eslint-disable-next-line
    }, []);

    return (
        <div className='ticketinfo-clipboard__body'>
            {renderBasedOnStatus()}
            <h5>Attachments</h5>
            <TicketInfoAttachments 
              fileAttachments={fileAttachments} 
              extEmailAttachments={extEmailAttachments}
              handleToggleEmailAttachment={handleToggleEmailAttachment}
            />
        </div>
    );
};

export default TicketInfoClipContent;
