import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import EmptyCapsuleClass from "../../common/EmptyCapsuleClass/EmptyCapsuleClass";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ClassCapsuleContent from "./ClassCapsuleContent/ClassCapsuleContent";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Loading } from "components/ui";
import { primaryCapsuleCodes } from "../../common/Capsule/dictionary/capsuleCodes";
import { saveCapsuleClassess } from "store/capsule/actions";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;
const { FLOW, FEATURE } = primaryCapsuleCodes;

const ClassCapsuleContentGroup = ({
    otherClassNames = "",
    capsuleCodes = [FLOW, FEATURE],
    classesListBreakpoint,
    label,
    inputKey,
    selectedContentIds,
    searchable,
    selectable,
    handleContentSelect,
    error,
    filterIds,
    pushSelectedClass,
    saveCount,
    defaultCapsuleClassId,
    capsuleBottomMenu,
    hideEditAction,
    hideDeleteAction,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const [capsuleClassList, updateCapsuleClassList] = useState(
        capsuleClasses.filter((x) => capsuleCodes.includes(x.capsuleCode))
    );
    const [status, setStatus] = useState(
        capsuleClassList?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const renderBasedOnStage = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case DATAMODE:
                return (
                    <ClassCapsuleContent
                        searchable={searchable}
                        selectable={selectable}
                        defaultCapsuleClassId={defaultCapsuleClassId}
                        capsuleClasses={capsuleClassList}
                        classesListBreakpoint={classesListBreakpoint}
                        selectedContentIds={selectedContentIds}
                        handleContentSelect={handleContentSelect}
                        filterIds={filterIds}
                        pushSelectedClass={pushSelectedClass}
                        saveCount={saveCount}
                        capsuleBottomMenu={capsuleBottomMenu}
                        hideEditAction={hideEditAction}
                        hideDeleteAction={hideDeleteAction}
                    />
                );

            case NULLMODE:
                return <EmptyCapsuleClass />;

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getCapsuleClasses}
                    />
                );

            default:
                return "";
        }
    };

    const getCapsuleClasses = async (httpRequest) => {
        try {
            setErrorMssg();

            if (capsuleClasses?.length < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.getCapsuleClasses;
            const res = await API.get(url, { signal: httpRequest?.signal });

            if (res.status === 200) {
                const capsuleClasses = res.data.data;
                const capsuleClassList = capsuleClasses.filter((x) =>
                    capsuleCodes.includes(x.capsuleCode)
                );
                const newStatus =
                    capsuleClassList?.length > 0 ? DATAMODE : NULLMODE;
                updateCapsuleClassList(capsuleClassList);
                setStatus(newStatus);
                dispatch(saveCapsuleClassess(capsuleClasses));
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getCapsuleClasses(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${otherClassNames}`}>
            {label && (
                <label className='form-label text-dark fw-medium'>
                    {label}
                </label>
            )}
            <div
                className={`capsule__content--grp ${error ? "has-error" : ""}`}>
                {renderBasedOnStage()}
                <input
                    name={inputKey}
                    value={selectedContentIds?.length > 0 ? "data" : ""}
                    hidden={true}
                    readOnly={true}
                />
            </div>
        </div>
    );
};

ClassCapsuleContentGroup.propTypes = {
    capsuleCodes: PropTypes.array,
    handleContentSelect: PropTypes.func,
    searchable: PropTypes.bool,
    selectable: PropTypes.bool,
    selectedContentIds: PropTypes.array,
};

export default ClassCapsuleContentGroup;
