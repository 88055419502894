import React from "react";
import { useSelector } from "react-redux";
import { ISSUE_DISCOVERY } from "../../../ChatTickets/common/TicketStatus/enum";
import { truncateString } from "utils/helper";
import { ticketsFilterBuilder } from "../../constants";
import TicketFilterSelector from "../../TicketFilterSelector/TicketFilterSelector";
import { ticketSourcesLabel } from "../TicketPhaseSelector/enum";

const IssueDiscorveryTicketPhaseSelector = ({
    countsData,
    fetchingCount,
    handleTicketPhaseSelect,
    selectedTicketPhase,
    handleTicketSourceSelect,
    ticketSource,
}) => {
    const { userId } = useSelector((state) => state.auth?.user);
    const { incomingTickets } = useSelector((state) => state?.incomingTickets);

    const ticketPhase = ISSUE_DISCOVERY;
    const filterBlock = ticketsFilterBuilder[ticketPhase];

    const filterByTicketPhase = (ticket) => {
        return ticket.ticketPhase === ticketPhase;
    };

    const filterByMessageTag = (ticket) => {
        return ticket.issueDiscovered === true;
    };

    const filterByTicketInfoValue = (ticket) => {
        let isAssigned =
            ticket?.assigned === true &&
            ticket?.assignedTeamMemberId === userId;
        return isAssigned;
    };

    const filterByTicketStatus = (ticket) => {
        return (
            ticket.ticketStatus === true &&
            ticket.isNew === true
        );
    };

    const isActive = selectedTicketPhase === ticketPhase;

    const onlyNewTickets = incomingTickets
        ?.filter(filterByTicketPhase)
        .filter(filterByTicketStatus);
    const newTicketsAvailable =
        ticketPhase === ISSUE_DISCOVERY
            ? onlyNewTickets.filter(filterByMessageTag)
            : onlyNewTickets?.filter(filterByTicketInfoValue);

    return (
        <>

            <TicketFilterSelector
                label={
                    isActive
                        ? ticketSourcesLabel[ticketSource]
                            ?.label || filterBlock?.title
                        : truncateString(
                            ticketSourcesLabel[
                                ticketSource
                            ]?.label ||
                            filterBlock?.title,
                            9
                        )
                }
                countValue={
                    countsData?.[filterBlock?.["countKey"]]
                }
                isActive={isActive}
                onClick={() => {
                    handleTicketPhaseSelect(ticketPhase);
                }}
                hasNew={newTicketsAvailable.length > 0}
                fetchingCount={fetchingCount}
                activeStyles={filterBlock?.activeStyles}
                handleDropdownAction={
                    handleTicketSourceSelect
                }
                showFilterSelectorDropdown={
                    selectedTicketPhase === ISSUE_DISCOVERY
                }
                ticketSource={ticketSource}
                otherClassNames="me-0"
            />

        </>
    );


};

export default IssueDiscorveryTicketPhaseSelector;
