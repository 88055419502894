import { Table } from "components/ui";
import { REMINDERS_TABLE_HEADERS } from "../enums";
import "./RemindersTable.scss";
import RemindersTableActions from "./RemindersTableActions/RemindersTableActions";
import RemindersTableName from "./RemindersTableName/RemindersTableName";

const RemindersTable = ({ handleActionModal }) => {
    const tableData = [
        {
            name: <RemindersTableName name='First Reminder' />,
            time: "5 minutes",
            actions: (
                <RemindersTableActions handleActionModal={handleActionModal} />
            ),
        },
    ];

    return (
        <div className='reminders-table'>
            <Table headers={REMINDERS_TABLE_HEADERS} dataSource={tableData} />
        </div>
    );
};

export default RemindersTable;
