import CapsuleSkeletonLoader from "components/dashboard/KnowledgeBase/common/CapsuleSkeletonLoader/CapsuleSkeletonLoader";
import { dataQueryStatus } from "utils";
import { FOLLOW_UP_TABLE_ACTIONS } from "../../enum";
import "./AttachedFollowUp.scss";

const { IDLE, LOADING, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const AttachedFollowUp = ({ status, handleActionModal, capsuleEntry }) => {
    const { attachedFollowUpName } = capsuleEntry;
    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
            case NULLMODE:
                return "No follow up collection has been attached.";
            case ERROR:
                return "";

            case LOADING:
                return (
                    <CapsuleSkeletonLoader
                        count={1}
                        otherClass='attached-follow-up__loader'
                    />
                );

            case DATAMODE:
                return (
                    <>
                        {attachedFollowUpName ? (
                            <div
                                className='attached-follow-up__text'
                                onClick={() =>
                                    handleActionModal(
                                        FOLLOW_UP_TABLE_ACTIONS.VIEW_FOLLOW_UP_COLLECTION,
                                        capsuleEntry
                                    )
                                }>
                                View follow up collection
                            </div>
                        ) : (
                            <div>
                                No follow up collection has been attached.
                            </div>
                        )}
                    </>
                );

            default:
                return "";
        }
    };

    return (
        <>
            <div className='attached-follow-up'>{renderBasedOnStatus()}</div>
        </>
    );
};

export default AttachedFollowUp;
