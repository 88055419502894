import { session } from "utils";
import store from "store/store";
import { SIGNOUT_REQUEST } from "store/rootReducer";

export const saveToken = (token) => session.setObject("token", token);

export const getToken = () => session.getObject("token")?.accessToken;

export const saveUserData = (user) => session.setObject("user", user);

export const getUserData = () => session.getObject("user");

export const logOut = async () => {
    window.location.pathname = "/";
    localStorage.removeItem("persist:root")
    session.clear();
    await store.dispatch({ type: SIGNOUT_REQUEST });
};
