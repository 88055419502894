import { useEffect, useState } from "react";
import { Tag } from "components/ui";
import SelectUI from "components/ui/SelectUI/SelectUI";
import "./SearchSelectInput.scss";

export const SearchSelectInput = ({
    data,
    name,
    id,
    grpClass,
    labelClass,
    label,
    selectedItemsLabel,
    darkLabel,
    placeholder,
    errMssg,
    isLoading,
    serverError,
    handleRetry,
    setInputValue,
    inputValue = [],
}) => {
    const [selected, setSelected] = useState(inputValue);
    const [value, setValue] = useState("");

    useEffect(() => {
        setSelected(inputValue);
    }, [inputValue]);

    const handleInputChange = (value) => {
        setSelected([...selected, value]);
        const selectedValues = selected?.map((res) => res.value);
        setInputValue?.(name, [...selectedValues, value?.value]);
        setValue("");
    };

    const removeItem = (index) => {
        const newData = selected?.filter((item, i) => i !== index);

        setSelected(newData);
        const selectedValues = newData.map((res) => res.value);
        setInputValue?.(name, selectedValues);
    };

    return (
        <div
            className={`form-group input-multiple ${grpClass || ""}`}
            data-field='multiple'>
            <SelectUI
                name={name}
                label={label}
                darkLabel={darkLabel}
                labelClass={labelClass}
                id={id}
                placeholder={placeholder}
                error={errMssg}
                value={value}
                options={data}
                onChange={handleInputChange}
                isLoading={isLoading}
                serverError={serverError}
                handleRetry={handleRetry}
            />
            {selected?.length > 0 && selectedItemsLabel && (
                <p className='selected__item__label text-dark'>{selectedItemsLabel}</p>
            )}
            {selected?.length > 0 ? (
                <div className='d-flex align-items-start flex-wrap flex-gap p-3 mt-1 input-multiple__bordered-box'>
                    {selected?.map((item, i) => (
                        <Tag
                            key={i}
                            classType='default-cursor'
                            className='text-dark'
                            name={typeof item === "object" ? item?.label : item}
                            onCancel={() => removeItem(i)}
                            cancel
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
