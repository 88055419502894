import React, { useState } from "react";
import AddButton from "components/ui/Button/AddButton/AddButton";
import CannedContentForm from "./CannedContentForm/CannedContentForm";
import { cannedContentTypes } from "./enum";
import { truncateString } from "utils/helper";
import CannedContentdropdown from "./CannedContentdropdown/CannedContentdropdown";
import { cannedContentDropdownActions } from "./enum";
import EmptyCannedContent from "./EmptyCannedcontent/EmptyCannedcontent";
import "./CannedContent.scss";

const { CANNED_EMAIL } = cannedContentTypes;
const { EDIT } = cannedContentDropdownActions;

const CannedContent = ({ request, label, setRequest, type }) => {
    const [showCannedContentForm, toggleCannedContentForm] = useState(false);
    const [selectedCannedContent, setSelectedCannedContent] = useState({});
    const [currentAction, setCurrentAction] = useState("");
    const { cannedEmails, cannedResponses } = request || {};
    const isCannedEmail = type === CANNED_EMAIL;

    const handleToggleForm = (showCannedContentForm) => {
        toggleCannedContentForm(showCannedContentForm);
        !showCannedContentForm && setCurrentAction("");
    };

    const handleDropdownAction = (action, data) => {
        setCurrentAction(action);
        setSelectedCannedContent(data);

        if (action === EDIT) {
            setSelectedCannedContent(data);
            handleToggleForm(true);
        } else {
            isCannedEmail
                ? setRequest((prev) => ({
                      ...prev,
                      cannedEmails: prev?.cannedEmails?.filter(
                          (cannedEmail, i) => i !== data?.index
                      ),
                  }))
                : setRequest((prev) => ({
                      ...prev,
                      cannedResponses: prev?.cannedResponses?.filter(
                          (cannedResponse, i) => i !== data?.index
                      ),
                  }));
        }
    };

    const handleSaveCannedContent = (data) => {
        isCannedEmail
            ? setRequest((prev) => ({
                  ...prev,
                  cannedEmails: [...prev?.cannedEmails, data],
              }))
            : setRequest((prev) => ({
                  ...prev,
                  cannedResponses: [...prev?.cannedResponses, data],
              }));
        handleToggleForm(false);
    };

    const handleEditCannedContent = (data) => {
        const { cannedEmailSubject, cannedEmailBody, cannedResponse, index } =
            data;
        isCannedEmail
            ? setRequest((prev) => ({
                  ...prev,
                  cannedEmails: prev?.cannedEmails?.map((cannedEmail, i) =>
                      i === index
                          ? {
                                cannedEmailSubject,
                                cannedEmailBody,
                            }
                          : cannedEmail
                  ),
              }))
            : setRequest((prev) => ({
                  ...prev,
                  cannedResponses: prev?.cannedResponses?.map((cannedRes, i) =>
                      i === index
                          ? {
                                cannedResponse,
                            }
                          : cannedRes
                  ),
              }));
        handleToggleForm(false);
    };

    return (
        <div className='attached-canned-content'>
            <div className='canned__content__label'>
                <span className='canned__content__label__text'>{label}</span>
                <div>
                    <AddButton
                        btnText={`Add new ${label}`}
                        onClick={() => handleToggleForm(!showCannedContentForm)}
                    />
                </div>
            </div>
            {showCannedContentForm ? (
                <CannedContentForm
                    type={type}
                    handleSaveCannedContent={handleSaveCannedContent}
                    isEdit={currentAction === EDIT}
                    selectedCannedContent={selectedCannedContent}
                    handleEditCannedContent={handleEditCannedContent}
                />
            ) : (
                <div className='canned__contents__container'>
                    {type === CANNED_EMAIL ? (
                        <>
                            {cannedEmails?.length > 0 ? (
                                cannedEmails?.map((cannedEmail, index) => (
                                    <div className='canned__content__container'>
                                        <div className='canned__content'>
                                            <p className='canned__content__subject'>
                                                Subject:{" "}
                                                {truncateString(
                                                    cannedEmail?.cannedEmailSubject,
                                                    35
                                                )}
                                            </p>
                                            <span className='canned__content__body'>
                                                {truncateString(
                                                    cannedEmail?.cannedEmailBody,
                                                    100
                                                )}
                                            </span>
                                        </div>
                                        <CannedContentdropdown
                                            cannedContent={{
                                                ...cannedEmail,
                                                index,
                                            }}
                                            handleDropdownAction={
                                                handleDropdownAction
                                            }
                                            lastItem={
                                                cannedEmails?.length !== 1 &&
                                                cannedEmails?.length - 1 ===
                                                    index
                                            }
                                        />
                                    </div>
                                ))
                            ) : (
                                <EmptyCannedContent
                                    text='Add a cannned email to send to the user'
                                    onClick={() =>
                                        handleToggleForm(!showCannedContentForm)
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {cannedResponses?.length > 0 ? (
                                cannedResponses?.map(
                                    ({ cannedResponse }, index) => (
                                        <div className='canned__content__container'>
                                            <div className='canned__content'>
                                                <span className='canned__content__body'>
                                                    {truncateString(
                                                        cannedResponse,
                                                        100
                                                    )}
                                                </span>
                                            </div>
                                            <CannedContentdropdown
                                                cannedContent={{
                                                    cannedResponse,
                                                    index,
                                                }}
                                                handleDropdownAction={
                                                    handleDropdownAction
                                                }
                                                lastItem={
                                                    cannedResponses?.length !==
                                                        1 &&
                                                    cannedResponses?.length -
                                                        1 ===
                                                        index
                                                }
                                            />
                                        </div>
                                    )
                                )
                            ) : (
                                <EmptyCannedContent
                                    text='Add a cannned response to send to the user'
                                    onClick={() =>
                                        handleToggleForm(!showCannedContentForm)
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CannedContent;
