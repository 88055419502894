import ScheduleInput from "components/dashboard/common/ScheduleInput/ScheduleInput";
import { Button, Checkbox, Input } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useEffect, useState } from "react";
import ValidateForm from "utils/validations/Validator";
import "./CreateReminderForm.scss";

const CreateReminderForm = ({ isEdit, handleRefresh, referenceData }) => {
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    const [loading, setLoading] = useState();

    const [request, setRequest] = useState({
        name: "",
        duration: "",
        isDefault: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleTimeChange = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleIsDefault = (e) => {
        const { name, checked } = e.target;
        setRequest({ ...request, [name]: checked });
        setErrors({ ...errors, [name]: "" });
    };

    const createReminder = () => {
        handleRefresh?.();
        setLoading(true);
    };

    const editReminder = () => {};

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        setErrors(formErrors);

        if (formisValid) {
            isEdit ? editReminder() : createReminder();
        } else {
            setErrors(formErrors);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setRequest({ ...request, ...referenceData });
        }
        // eslint-disable-next-line
    }, [referenceData]);

    return (
        <>
            <form className='create-reminder mt-4' onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMsg)}
                    message={errorMsg}
                    hide={() => setErrorMsg()}
                />

                <Input
                    grpClass='mb-3'
                    type='text'
                    name='name'
                    label='Name of Reminder'
                    labelClass='text-dark fw-bold'
                    id='name'
                    placeholder='Enter Reminder Name here'
                    value={request?.name}
                    onChange={(e) => handleChange(e)}
                    isErr={errors?.name}
                    errMssg={errors?.name}
                    autoFocus={true}
                />

                <ScheduleInput
                    timeValue={request?.duration}
                    handleTimeChange={(value) =>
                        handleTimeChange("duration", value)
                    }
                    error={request?.duration}
                    label={"Set timer"}
                    showLabel={true}
                />

                <div className='create-reminder__text'>
                    Reminders would be sent via emails
                </div>

                <Checkbox
                    label={"Make default reminder"}
                    checked={request?.isDefault}
                    onChange={handleIsDefault}
                    inputClass='input-check-grey'
                    name={"isDefault"}
                    labelClass='create-reminder__checkbox'
                />

                <Button
                    type='submit'
                    classType='primary'
                    loading={loading}
                    otherClass='w-100'
                    text='Create'
                />
            </form>
        </>
    );
};

export default CreateReminderForm;
