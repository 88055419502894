import React from "react";
import { Link } from "react-router-dom";
import ItemsList from "components/ui/ItemsList/ItemsList";
import "./ConnectedForms.scss";

const ConnectedForms = ({ forms }) => {
    return (
        <>
            {forms?.length > 0 ? (
                <ItemsList breakPoint={6}>
                    {forms?.map(({ formName, formId }) => (
                        <Link to={`/knowledge-base/form/${formId}`}>
                            <div className='connected__form'>{formName}</div>
                        </Link>
                    ))}
                </ItemsList>
            ) : (
                <span>No form connected</span>
            )}
        </>
    );
};

export default ConnectedForms;
