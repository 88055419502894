import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { useSelector } from "react-redux";
import { arrayBufferToBase64, extractSubarrays, generateID, truncateString } from "utils/helper";
import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import ModalPreview from "../../../../ChatModule/LiveChat/MessageBody/ModalPreview/ModalPreview";
import axios from "axios";
import { mimeTypes } from "utils/mime";
import { Buffer } from "buffer";
import { apiRoute } from "services";
import API from "services/lib/api";
// import { mediaType } from "../../../../ChatModule/LiveChat/MessageBody/Messages/Message/enums";

const TicketInfoAttachmentDropdown = ({ handleOpen, attachToEmail, hideAttachmentOption }) => {
    const [dropdownOpen, toggleDropdown] = useState(false);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={() => toggleDropdown((prevState) => !prevState)}>
            <ReactSVG src={asset.icons.ellipsis}
                onClick={() => toggleDropdown((prevState) => !prevState)}
            />
            <DropdownMenu>
                {!hideAttachmentOption && <DropdownItem
                    onClick={attachToEmail}>
                    Attach to mail
                </DropdownItem>}
                <DropdownItem
                    onClick={handleOpen}>
                    Open
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export const EmailAttachments = ({files, handlePreviewFile, attachFileToEmail, hideAttachmentOption }) => {

    return (
        <>
            {files?.map(({ fileAttachmentName, filename, ...rest }, index) => {
                const fileName = filename || fileAttachmentName;
                return (
                    <div
                        key={index}
                        className='ticketinfo-clipboard__file'
                    >
                        <ReactSVG src={asset.icons.document} />
                        <span>{truncateString(fileName, 10)}</span>
                        <TicketInfoAttachmentDropdown
                            handleOpen={() =>
                                handlePreviewFile({
                                    fileName,
                                    filename: fileName,
                                    isEmailLink: true,
                                    ...rest,
                                })
                            }
                            attachToEmail={() => attachFileToEmail({ fileName, ...rest })}
                            hideAttachmentOption={hideAttachmentOption}
                        />{" "}
                    </div>
                )
            })}
        </>
    )
}

const TicketInfoAttachments = ({ fileAttachments, handleToggleEmailAttachment, extEmailAttachments = [] }) => {
    const [showModal, toggleModal] = useState(false);
    const [selectedFile, selectFile] = useState();

    const { ticketsMessages, activeTicket } = useSelector(
        (state) => state.tickets
    );

    const { ticketId } = activeTicket || {};

    const messagesFiles = ticketsMessages?.filter(
        (item) =>
            item?.ticketId === ticketId &&
            item?.fileAttachments !== undefined &&
            item?.senderType === "THIRD_USER"
    );

    const attachmentMessages = messagesFiles?.map((x) => ({ ...x, isKeyFile: true }))

    const messageAttachments = extractSubarrays(
        attachmentMessages,
        "fileAttachments"
    );

    const allAttachments = [...messageAttachments];

    const urlToBase64 = async (url) => {
        return await fetch(url)
            .then((response) => response.text())

    };

    const getRemoteUrlBase64 = async (fileObj) => {

        const { fileAttachmentName, fileAttachmentUrl } = fileObj;
        const url = apiRoute.getFileLink;

        const fileData = await API.get(url, {
            params: {
                fileKey: fileAttachmentUrl,
            },
        });

        const res = await axios.get(fileData?.data?.data, {
            responseType: 'arraybuffer'
        });
        const fileExtension = fileAttachmentName?.split('.').pop()
        const mimeType = mimeTypes[fileExtension]
        const buffer = Buffer.from(res.data, 'base64');
        const bff64 = await arrayBufferToBase64(buffer);
        return `data:${mimeType};base64,${bff64}`;

    };


    const handlePreviewFile = (file) => {
        toggleModal(true);
        selectFile(file);
    };

    const attachFileToEmail = async (fileObj) => {
        ;
        const base64String = fileObj?.fileAttachmentUrl?.startsWith?.("https") || fileObj?.content?.startsWith?.("https") ? urlToBase64(fileObj?.fileAttachmentUrl) : await getRemoteUrlBase64(fileObj).then((f) => f);

        const uploadObj = {
            content: base64String,
            attachmentType: fileObj.fileAttachmentType,
            filename: fileObj.fileName,
            id: generateID(5),
        };
        handleToggleEmailAttachment("fileAttachments",
            [...fileAttachments, uploadObj]
        );
    };

    

    return (
        <>

            <>
                <div className='ticketinfo-clipboard__attachments'>
                    {allAttachments?.map(({ fileAttachmentName, filename, ...rest }, index) => {
                        const fileName = filename || fileAttachmentName;
                        return (
                            <div
                                key={index}
                                className='ticketinfo-clipboard__file'
                            >
                                <ReactSVG src={asset.icons.document} />
                                <span>{truncateString(fileName, 10)}</span>
                                <TicketInfoAttachmentDropdown
                                    handleOpen={() =>
                                        handlePreviewFile({
                                            fileName,
                                            filename: fileName,
                                            ...rest,
                                        })
                                    }
                                    attachToEmail={() => attachFileToEmail({ fileName, ...rest })}
                                />{" "}
                            </div>
                        )
                    })}
                    <EmailAttachments 
                        files={extEmailAttachments}
                        handlePreviewFile={handlePreviewFile}
                        attachFileToEmail={attachFileToEmail}
                    />
                    {/* {extEmailAttachments?.map(({ fileAttachmentName, filename, ...rest }, index) => {
                        const fileName = filename || fileAttachmentName;
                        return (
                            <div
                                key={index}
                                className='ticketinfo-clipboard__file'
                            >
                                <ReactSVG src={asset.icons.document} />
                                <span>{truncateString(fileName, 10)}</span>
                                <TicketInfoAttachmentDropdown
                                    handleOpen={() =>
                                        handlePreviewFile({
                                            fileName,
                                            filename: fileName,
                                            isEmailLink: true,
                                            ...rest,
                                        })
                                    }
                                    attachToEmail={() => attachFileToEmail({ fileName, ...rest })}
                                />{" "}
                            </div>
                        )
                    })} */}

                </div>
            </>

            {showModal && (
                <ModalPreview
                    {...{ showModal }}
                    file={selectedFile}
                    preview={selectedFile?.fileAttachmentUrl || selectedFile?.content}
                    previewType={selectedFile?.fileAttachmentType}
                    toggleModal={() => {
                        toggleModal(false);
                        selectFile();
                    }}
                />
            )}
        </>
    );
};

export default TicketInfoAttachments;
