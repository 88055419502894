import { Table } from "components/ui";
import ScenariosDropdown from "./ScenariosDropdown/ScenariosDropdown";
import EditScenarioButton from "./EditScenarioButton/EditScenarioButton";
import ScenariosGuides from "./ScenariosGuides/ScenariosGuides";
import { SCENARIO_ACTIONS } from "../enum";

const { EDIT_SCENARIO, DELETE_SCENARIO } = SCENARIO_ACTIONS;

const ScenariosTable = ({ scenarios, handleActionModal, tableIsLoading }) => {
    const tableData = scenarios?.map?.(
        ({
            guideScenairoId,
            guideScenairoMessage,
            guideScenairoName,
            agentGuides,
        }) => {
            return {
                name: guideScenairoName,
                attachedGuides: <ScenariosGuides guides={agentGuides} />,
                actions: (
                    <div className='discovery--actions'>
                        <EditScenarioButton
                            onClick={() => {
                                handleActionModal(EDIT_SCENARIO, {
                                    guideScenairoId,
                                    guideScenairoMessage,
                                    guideScenairoName,
                                });
                            }}
                        />
                        <ScenariosDropdown
                            onClick={() => {
                                handleActionModal(DELETE_SCENARIO, {
                                    guideScenairoId,
                                    guideScenairoMessage,
                                    guideScenairoName,
                                });
                            }}
                        />
                    </div>
                ),
            };
        }
    );

    return (
        <Table
            headers={["Name", "Attached Guides", "Actions"]}
            dataSource={tableData}
            isLoading={tableIsLoading}
        />
    );
};

export default ScenariosTable;
