export const QA_DOCUMENTATION_PERMS_ACTIONS = Object.freeze({
    EDIT_QA_DOCUMENTATION: "EDIT_QA_DOCUMENTATION",
    DELETE_QA_DOCUMENTATION: "DELETE_QA_DOCUMENTATION",
    CREATE_QA_DOCUMENTATION: "CREATE_QA_DOCUMENTATION",
});


export const QA_DOCUMENTATION_TYPES = Object.freeze({
    TEXT: "TEXT",
    WEB: "WEB",
    DOCUMENT: "DOCUMENT",
});


export const qaDocumentationTypeOptions = [
    {
        label: "Text",
        value: QA_DOCUMENTATION_TYPES.TEXT
    },
    {
        label: "Link",
        value: QA_DOCUMENTATION_TYPES.WEB
    },
    {
        label: "PDF File",
        value: QA_DOCUMENTATION_TYPES.DOCUMENT
    }
]