import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { apiRoute } from "services";
import API from "services/lib/api";
import { ConfirmAction } from "components/ui";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const { ERROR } = dataQueryStatus;

const DeactivateTeamMember = ({ member, closeModal, handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const toastMessage = useContext(ToastContext);
    const { accessStatus, userId } = member;
    const title = accessStatus ? "Deactivate" : "Activate";

    const deactivateMember = async () => {
        try {
            setLoading(true);

            const url = apiRoute.blockUser(userId);
            const res = await API.put(
                url,
                {},
                {
                    params: { block: accessStatus },
                }
            );

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <>
            <ConfirmAction
                title={`Are you sure you want to ${title} this user`}
                loading={loading}
                close={closeModal}
                handleYesClick={deactivateMember}
            />
        </>
    );
};

DeactivateTeamMember.prototypes = {
    member: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
};

export default DeactivateTeamMember;
