import {
    CREATE_NEW_BRANCH_COLLECTION,
    DELETE_BRANCH_COLLECTION,
} from "components/dashboard/KnowledgeBase/AddBranchCollectionModal/AddBranchCollectionForm/enums";
import AddButton from "components/ui/Button/AddButton/AddButton";
import AddedSolutionCollection from "./AddedSolutionCollection/AddedSolutionCollection";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_SAM_COLLECTION } = baseResources;
const { CREATE } = baseResourceActions;

const SolutionCollection = ({
    handleRowAction,
    solutionCapsuleEntryId,
    solutionCollection,
    capsuleClass,
    capsuleContent,
}) => {
    return (
        <>
            {solutionCollection ? (
                <AddedSolutionCollection
                    solutionCollection={solutionCollection}
                    toggleDeleteModal={() =>
                        handleRowAction(
                            DELETE_BRANCH_COLLECTION,
                            solutionCollection
                        )
                    }
                />
            ) : (
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${KB_SAM_COLLECTION}`]}>
                    <AddButton
                        hasIcon={false}
                        otherClassName='justify-content-end'
                        onClick={() =>
                            handleRowAction(CREATE_NEW_BRANCH_COLLECTION, {
                                capsuleClassId: capsuleClass?.capsuleClassId,
                                capsuleEntryId: solutionCapsuleEntryId,
                                capsuleContent,
                            })
                        }
                        btnText='Add Solution Collection'
                    />
                </ComponentAccessControl>
            )}
        </>
    );
};

export default SolutionCollection;
