export const baseResources = {
    USER_MANAGEMENT_ROLES: "USER_MANAGEMENT_ROLES",
    PROFILE: "PROFILE",
    USER_MANAGEMENT_USERS: "USER_MANAGEMENT_USERS",
    KB_CAPSULE_SETTINGS: "KB_CAPSULE_SETTINGS",
    KB_PRODUCT_SETTING: "KB_PRODUCT_SETTING",
    KB_FORM_SETTING: "KB_FORM_SETTING",
    KB_SAM_COLLECTION: "KB_SAM_COLLECTION",
    KB_SAM_ISSUE_CONVERSATION: "KB_SAM_ISSUE_CONVERSATION",
    KB_SAM_BRANCH: "KB_SAM_BRANCH",
    KB_DEPENDENCY: "KB_DEPENDENCY",
    AGENTS_INBOX_BASE_SETTING: "AGENTS_INBOX_BASE_SETTING",
    AGENTS_INBOX_SERVICE_SETTING: "AGENTS_INBOX_SERVICE_SETTING",
    AGENTS_INBOX_SERVICE_INTEGRATION: "AGENTS_INBOX_SERVICE_INTEGRATION",
    AGENTS_INBOX_SERVICE_BRANCH_SETTING: "AGENTS_INBOX_SERVICE_BRANCH_SETTING",
};

export const defaultPermissions = ["READ_PROFILE"];

export const baseResourceActions = Object.freeze({
    CREATE: "CREATE",
    READ: "READ",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
});

export const pathTypes = Object.freeze({
    SINGLE: "SINGLE",
    TABBED: "TABBED",
    DROPDOWN: "DROPDOWN",
});
