import TabbedWrapperHeader from "routes/TabbedWrapperRouter/TabbedWrapperHeader/TabbedWrapperHeader";
import CannedEmailResponses from "../../CannedEmailResponses/CannedEmailResponses";

const CannedResponsesTab = () => {
    const tabbledRoutes = [
        {
            title: "Canned emails",
            path: "/t-sam/responses/emails",
            component: CannedEmailResponses,
        },
        {
            title: "Canned replies",
            path: "/t-sam/responses/replies",
            component: CannedEmailResponses,
        },
        {
            title: "Canned mentions",
            path: "/t-sam/responses/mentions",
            component: CannedEmailResponses,
        },
    ];

    return <TabbedWrapperHeader tabbedRoutes={tabbledRoutes} />;
};

export default CannedResponsesTab;
