import React, { useState, useEffect, useContext } from "react";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Input, Button, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import { getErrorMessage } from "utils/helper";
import PermissionsCheckBox from "./PermissionsCheckBox/PermissionsCheckBox";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { ROLES_PERMS_ACTIONS } from "../enums";
import { useDispatch } from "react-redux";
import { updateRole, updateRolesList } from "store/cache/roles/actions";

const CreateRoleForm = ({ role, handleSuccess, currentAction }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [request, setRequest] = useState({
        roleName: "",
        roleDescription: "",
    });
    const [errorMssg, setErrorMssg] = useState();
    const toastMessage = useContext(ToastContext);

    const dispatch = useDispatch();

    useEffect(() => {
        const { displayName, roleDescription, permissions: perms } = role || {};
        if (role) {
            const newPermissions = perms?.map(
                ({ permissionCode }) => permissionCode
            );
            setRequest({ roleDescription, roleName: displayName });
            setPermissions(newPermissions);
        }
        // eslint-disable-next-line
    }, [role]);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const createRole = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute.addRole;
            const res = await API.post(url, { ...request, permissions });

            if (res.status === 201) {
                const { message, data } = res.data;
                dispatch(updateRolesList([data]));
                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editRole = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const url = apiRoute.editRole(role?.roleCode);
            const res = await API.put(url, { ...request, permissions });

            if (res.status === 200) {
                const { message, data } = res.data;
                dispatch(updateRole(data));
                toastMessage(message);
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, formisValid } = ValidateForm(e, request);
        if (formisValid) {
            if (currentAction === ROLES_PERMS_ACTIONS.EDIT_ROLE) {
                return editRole();
            }
            return createRole();
        }
        setErrors(errors);
    };

    return (
        <form className='user__wrapper' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                name='roleName'
                label='Role Name'
                labelClass='text-dark fw-bold'
                placeholder='Role Name'
                value={request?.roleName}
                onChange={handleChange}
                isErr={errors?.roleName}
                errMssg={errors?.roleName}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                name='roleDescription'
                label='Role Description'
                labelClass='text-dark fw-bold'
                placeholder='Role Description'
                value={request?.roleDescription}
                onChange={handleChange}
                isErr={errors?.roleDescription}
                errMssg={errors?.roleDescription}
            />
            <PermissionsCheckBox
                existingPermissions={permissions}
                updatePerms={setPermissions}
            />
            <Button
                type='submit'
                classType='primary'
                loading={loading}
                otherClass='w-100 my-3'
                text={role ? "Update Role" : "Save new role"}
                disabled={!permissions?.length}
            />
        </form>
    );
};

export default CreateRoleForm;
