import React from "react";
import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
import ItemsList from "components/ui/ItemsList/ItemsList";
import { Tag } from "components/ui";

const IssueEmbeds = ({ elements, issues }) => {
    return (
        <>
            {elements?.length > 0 || issues?.length > 0 ? (
                <div className='issues-table-embed'>
                    <ItemsList breakPoint={8}>

                        {elements?.map((element, key) => (
                            <CapsuleElement
                                capsule={element?.capsule}
                                capsuleEntryId={element?.capsuleEntryId}
                                key={key}
                                useCapsuleStyles={true}
                                hasBgColor={true}
                                hideDeleteAction={true}
                                hideEditAction={true}
                                capsuleBottomMenu={true}
                            />
                        ))}
                        {
                            issues?.map(({ issueName }, index) => (
                                <Tag
                                    name={issueName}
                                    key={index}
                                />
                            ))
                        }
                    </ItemsList>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default IssueEmbeds;
