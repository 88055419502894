import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import {
    addNewCapsuleEntry,
    addSingleSubCapsuleEntry,
    capsuleActions,
    relateSubCapsuleEntry,
    editCapsule,
    getCapsulesEntries,
    getCapsuleInfo,
} from "components/dashboard/KnowledgeBase/common/Capsule/lib";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { useHistory } from "react-router-dom";
import SelectUI from "components/ui/SelectUI/SelectUI";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { KB_PRODUCT_SETTING } = baseResources;
const { CREATE, UPDATE } = baseResourceActions;

const AddCapsuleEntryForm = ({
    handleSuccess,
    mainCapsuleClass,
    selectedProduct,
    capsuleAction,
    additionRequestObj,
    setCurrentCapsuleAction,
    selectedCapsuleClass,
    selectedSecCapsuleClass,
    existingSubCapsules,
}) => {
    const [loading, setLoading] = useState(false);
    const [request, updateRequest] = useState({
        capsuleContent: additionRequestObj?.content ?? "",
    });
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const toastMessage = useContext(ToastContext);
    const [capsuleIsLoading, seCapsuleIsLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [allCapsuleEntries, setAllCapsuleEntries] = useState([]);

    const getSelectedCapsuleEntries = async () => {
        try {
            seCapsuleIsLoading(true);

            const { capsuleEntries } = await getCapsulesEntries(
                selectedSecCapsuleClass
            );

            const allCapsuleEntries = capsuleEntries?.map(
                ({ content, __typename, ...capsule }) => ({
                    label: content,
                    value: capsule[selectedSecCapsuleClass?.collectionKey],
                })
            );

            seCapsuleIsLoading(false);
            if (existingSubCapsules) {
                const existingSubCapsuleIds = existingSubCapsules?.map(
                    (cap) => {
                        let subCap =
                            cap[selectedSecCapsuleClass?.collectionName];

                        if (subCap) {
                            return subCap?.[
                                selectedSecCapsuleClass?.collectionKey
                            ];
                        }

                        return cap?.[selectedSecCapsuleClass?.collectionKey];
                    }
                );

                const filteredSubCapsuleList = allCapsuleEntries?.filter(
                    (capsule) => {
                        return !existingSubCapsuleIds.includes(capsule?.value);
                    }
                );

                return setAllCapsuleEntries(filteredSubCapsuleList);
            }

            setAllCapsuleEntries(allCapsuleEntries);
        } catch (err) {
            seCapsuleIsLoading(false);
            setServerError(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (selectedSecCapsuleClass) {
            getSelectedCapsuleEntries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSecCapsuleClass]);

    const history = useHistory();

    const handleRedirect = () => {
        history.push({
            pathname: `/knowledge-base/capsule/${selectedProduct?.value}/${
                capsuleAction === capsuleActions.EDIT_CAPSULE
                    ? additionRequestObj?.contentId
                    : additionRequestObj?.capsuleId
            }`,
            state: {
                capsule:
                    capsuleAction === capsuleActions.EDIT_CAPSULE
                        ? mainCapsuleClass
                        : selectedCapsuleClass,
                contentId:
                    capsuleAction === capsuleActions.EDIT_CAPSULE
                        ? additionRequestObj?.contentId
                        : additionRequestObj?.capsuleId,
                content: additionRequestObj?.content,
                productId: selectedProduct?.value,
            },
        });
    };

    const createCapsuleEntry = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            setErrors();

            const { isAvailable } = await isCapsuleNameAvailable(
                request?.capsuleContent
            );

            if (!isAvailable) {
                setErrorMssg(
                    "Capsule already exists on this workspace, try with a different capsule Name"
                );
                setLoading(false);
                return;
            }

            const capsule = await addNewCapsuleEntry(mainCapsuleClass, {
                productId: selectedProduct?.value,
                enabled: true,
                content: request?.capsuleContent,
                contentType: "TEXT",
            });

            handleSuccess(mainCapsuleClass);
            toastMessage(`${mainCapsuleClass?.capsuleClassName} added`);
            history.push({
                pathname: `/knowledge-base/capsule/${selectedProduct?.value}/${
                    capsule.returning[0][mainCapsuleClass?.collectionKey]
                }`,
                state: {
                    capsule: mainCapsuleClass,
                    contentId:
                        capsule.returning[0][mainCapsuleClass?.collectionKey],
                    content: request?.capsuleContent,
                    productId: selectedProduct?.value,
                },
            });
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const isCapsuleNameAvailable = async (capsuleName) => {
        try {
            const capsule = await getCapsuleInfo(
                mainCapsuleClass,
                selectedProduct?.value,
                capsuleName
            );

            return { capsule, isAvailable: capsule ? false : true };
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const createSubCapsuleEntry = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            setErrors();

            const { isAvailable } = await isCapsuleNameAvailable(
                request?.value
            );

            if (!isAvailable) {
                setErrorMssg(
                    "Capsule already exists on this workspace, try with a different capsule Name"
                );
                setLoading(false);
                return;
            }

            const { parentCapsuleClass, childCapsuleClass, capsuleId } =
                additionRequestObj;

            await addSingleSubCapsuleEntry(
                parentCapsuleClass,
                childCapsuleClass,
                {
                    productId: selectedProduct?.value,
                    enabled: true,
                    content: request?.value,
                    contentType: "TEXT",
                    capsuleId: capsuleId,
                },
                capsuleAction
            );
            handleSuccess(mainCapsuleClass);
            toastMessage(`${mainCapsuleClass?.capsuleClassName} added`);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const relateCapsuleEntries = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            setErrors();
            const { parentCapsuleClass, childCapsuleClass, capsuleId } =
                additionRequestObj;

            const childCapsuleId =
                mainCapsuleClass?.capsuleCode === childCapsuleClass?.capsuleCode
                    ? request?.value
                    : capsuleId;
            const parentCapsuleId =
                mainCapsuleClass?.capsuleCode ===
                parentCapsuleClass?.capsuleCode
                    ? request?.value
                    : capsuleId;
                 
            await relateSubCapsuleEntry(
                parentCapsuleClass,
                childCapsuleClass,
                {
                    contentId: childCapsuleId,
                    capsuleId: parentCapsuleId,
                },
                capsuleAction
            );

            handleSuccess(selectedSecCapsuleClass);
            toastMessage(`${selectedSecCapsuleClass?.capsuleClassName} added`);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const editCapsuleEntry = async () => {
        try {
            setErrorMssg();
            setLoading(true);
            setErrors();

            const { isAvailable } = await isCapsuleNameAvailable(
                request?.capsuleContent
            );

            if (!isAvailable) {
                setErrorMssg(
                    "Capsule already exists on this workspace, try with a different capsule Name"
                );
                setLoading(false);
                return;
            }

            const { contentId } = additionRequestObj;

            await editCapsule(
                mainCapsuleClass,
                contentId,
                request?.capsuleContent
            );

            handleSuccess(mainCapsuleClass);
            toastMessage(`${mainCapsuleClass?.capsuleClassName} edited`);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const callServer = () => {
        switch (capsuleAction) {
            case capsuleActions?.ADD_SINGLE_CAPSULE:
                createCapsuleEntry();
                break;
            case capsuleActions?.ADD_SINGLE_CHILD_CAPSULE:
            case capsuleActions?.ADD_SINGLE_PARENT_CAPSULE:
                createSubCapsuleEntry();
                break;
            case capsuleActions.RELATE_CAPSULES:
                relateCapsuleEntries();
                break;
            case capsuleActions.EDIT_CAPSULE:
                editCapsuleEntry();
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            callServer();
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            {capsuleAction === capsuleActions?.ADD_SINGLE_CAPSULE ||
            capsuleAction === capsuleActions?.EDIT_CAPSULE ? (
                <Input
                    grpClass='mb-3'
                    type='text'
                    name='capsuleContent'
                    label='Title'
                    labelClass='text-dark fw-medium'
                    id='capsuleContent'
                    placeholder=''
                    value={request?.capsuleContent}
                    onChange={handleChange}
                    isErr={errors?.capsuleContent}
                    errMssg={errors?.capsuleContent}
                    autoFocus={true}
                />
            ) : (
                <SelectUI
                    onChange={(value) => {
                        updateRequest(value);
                        setCurrentCapsuleAction(
                            capsuleActions?.RELATE_CAPSULES
                        );
                    }}
                    onCreateOption={(value) => {
                        updateRequest({
                            value,
                            label: value,
                            capsuleContent: value,
                        });
                        setCurrentCapsuleAction(
                            mainCapsuleClass?.capsuleCode ===
                                additionRequestObj?.parentCapsuleClass
                                    ?.capsuleCode
                                ? capsuleActions.ADD_SINGLE_PARENT_CAPSULE
                                : capsuleActions?.ADD_SINGLE_CHILD_CAPSULE
                        );
                    }}
                    name='capsuleContent'
                    label='Title'
                    options={allCapsuleEntries}
                    isLoading={capsuleIsLoading}
                    createAble={true}
                    value={
                        capsuleAction === capsuleActions?.RELATE_CAPSULES
                            ? allCapsuleEntries?.find((capsule) => {
                                  return capsule?.value === request?.value;
                              })
                            : request
                    }
                    error={errors?.capsuleContent ? "Title is required" : ""}
                    serverError={serverError}
                    handleRetry={() => getSelectedCapsuleEntries()}
                    autoFocus={true}
                    darkLabel={true}
                />
            )}
            <ComponentAccessControl
                baseResources={[
                    capsuleAction === capsuleActions?.RELATE_CAPSULES ||
                    capsuleAction === capsuleActions?.EDIT_CAPSULE
                        ? `${UPDATE}_${KB_PRODUCT_SETTING}`
                        : `${CREATE}_${KB_PRODUCT_SETTING}`,
                ]}>
                <Button
                    type='submit'
                    text={
                        capsuleAction === capsuleActions?.RELATE_CAPSULES
                            ? "Relate Capsule"
                            : capsuleAction === capsuleActions?.EDIT_CAPSULE
                            ? "Edit Casule"
                            : "Create New"
                    }
                    classType='primary'
                    loading={loading}
                    otherClass='my-3 w-100'
                />
            </ComponentAccessControl>
            {capsuleAction !== capsuleActions.ADD_SINGLE_CAPSULE && (
                <span className='cancel--text' onClick={handleRedirect}>
                    Continue Editing
                </span>
            )}
        </form>
    );
};

AddCapsuleEntryForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
};

export default AddCapsuleEntryForm;
