import React from "react";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { CREATE } = baseResourceActions;

const TTagElement = ({ label, handleClick, otherClassNames }) => {
    return (
        <>
            {label && (
                <ComponentAccessControl
                    baseResources={[`${CREATE}_${AGENTS_INBOX_BASE_SETTING}`]}>
                    <div
                        className={`tt_tag ${
                            otherClassNames ? otherClassNames : ""
                        }`}
                        onClick={handleClick}>
                        {label}
                    </div>
                </ComponentAccessControl>
            )}
        </>
    );
};

export default TTagElement;
