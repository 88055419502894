export const SAVE_ISSUES = "SAVE_ISSUES"
export const CLEAR_ISSUES = "CLEAR_ISSUES"

export const SAVE_DISCOVERIES = "SAVE_DISCOVERIES"
export const CLEAR_DISCOVERIES = "CLEAR_DISCOVERIES"

export const SAVE_CONVERSATIONS = "SAVE_CONVERSATIONS"
export const CLEAR_CONVERSATIONS  = "CLEAR_CONVERSATIONS"

export const SAVE_QA_DOCUMENTATIONS = "SAVE_QA_DOCUMENTATIONS"
export const CLEAR_QA_DOCUMENTATIONS  = "CLEAR_QA_DOCUMENTATIONS"