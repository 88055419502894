import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Button, Input, Textarea } from "components/ui";
import ValidateForm from "utils/validations/Validator";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { getErrorMessage } from "utils/helper";
import TagList from "./TagList/TagList";
import { TAG_LIST } from "./TagList/enum";
import TagClassesSelect from "./TagClassesSelect/TagClassesSelect";
import InviteTeamHeader from "./InviteTeamHeader/InviteTeamHeader";
import TeamMemberSelect from "./TeamMemberSelect/TeamMemberSelect";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const { IDLE, NULLMODE, ERROR, DATAMODE, LOADING } = dataQueryStatus;

const TeamForm = ({ closeModal, editItem, handleSuccess }) => {
    const [status, setStatus] = useState(IDLE);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({
        teamName: editItem?.teamName || "",
        teamDescription: editItem?.teamDescription || "",
        userIds:
            editItem?.teamMembers.map((teamMember) => teamMember.userId) || [],
        classificationIds:
            editItem?.classifications?.map(
                (classification) => classification?.classificationId
            ) || [],
    });
    const [selectedTag, setSelectedTag] = useState(TAG_LIST.CLASSIFICATION_TAG);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [userErrorMssg, setUserErrorMssg] = useState("");
    const [users, setUsers] = useState([]);
    const [showInviteTeamSearch, setShowInviteTeamSearch] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(
        editItem?.teamMembers?.map((teamMember) => ({
            name: `${teamMember.firstName} ${teamMember.lastName}`,
            userId: teamMember.userId,
        })) || []
    );
    const [unassignedClassificationStatus, setUnassignedClassificationStatus] =
        useState(IDLE);
    const [
        unassignedClassificationErrorMssg,
        setUnassignedClassificationErrorMssg,
    ] = useState("");
    const [unassignedClassifications, setUnassignedClassifications] = useState(
        []
    );
    const toastMessage = useContext(ToastContext);
    const location = useLocation();

    const getUsers = async (httpRequest) => {
        try {
            setStatus(LOADING);

            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;

                setUsers(data);
                setStatus(newStatus);
            }
        } catch (err) {
            setUserErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getUnassignedClassifications = async (httpRequest) => {
        try {
            setUnassignedClassificationStatus(LOADING);

            const url = apiRoute.getUnassignedClassifications;
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                let newStatus =
                    data?.length > 0 || editItem?.classifications?.length > 0
                        ? DATAMODE
                        : NULLMODE;

                setUnassignedClassifications(data);
                setUnassignedClassificationStatus(newStatus);
            }
        } catch (err) {
            setUnassignedClassificationErrorMssg(getErrorMessage(err));
            setUnassignedClassificationStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        getUsers(httpRequest);
        getUnassignedClassifications(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const createTeam = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { classificationIds, ...requestData } = request;

            const url = apiRoute?.createTeam;
            const res = await API.post(
                url,
                classificationIds?.length > 0 ? request : requestData
            );

            if (res.status === 201) {
                const { message } = res.data;
                toastMessage(message);
                handleSuccess();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editTeam = async () => {
        try {
            setLoading(true);
            setErrorMssg();
            const { classificationIds, ...requestData } = request;
            const { teamId } = location.state;

            const url = apiRoute?.team(teamId);
            const res = await API.put(
                url,
                classificationIds?.length > 0 ? request : requestData
            );

            if (res.status === 200) {
                const { message } = res.data;

                toastMessage(message);
                handleSuccess();
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const validateNonFormFields = (request) => {
        const { userIds } = request;
        let nonFormFieldErrors = {};
        let nonFormIsValid = true;

        if (userIds?.length === 0) {
            nonFormFieldErrors.userIds =
                "Inviting atleast a team member is required.";
            nonFormIsValid = false;
        }
        return {
            nonFormIsValid,
            nonFormFieldErrors,
        };
    };

    const updateErrors = (errorKey, errorValue) => {
        setErrors({ ...errors, [errorKey]: errorValue });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        const { nonFormIsValid, nonFormFieldErrors } =
            validateNonFormFields(request);

        if (formisValid && nonFormIsValid) {
            if (editItem) {
                return editTeam();
            }
            return createTeam();
        }
        setErrors({ ...formErrors, ...nonFormFieldErrors });
    };

    const { teamName, teamDescription } = request;

    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3'
                type='text'
                name='teamName'
                label='Name of team'
                labelClass='team-form__text'
                id='teamName'
                placeholder='Enter name of team'
                value={teamName}
                onChange={handleChange}
                isErr={errors?.teamName}
                errMssg={errors?.teamName}
                autoFocus={true}
            />
            <Textarea
                grpClass='mb-3'
                type='text'
                name='teamDescription'
                label='Describe your team'
                labelClass='team-form__text'
                id='teamDescription'
                placeholder='Add a description to this team you are creating'
                value={teamDescription}
                onChange={handleChange}
                isErr={errors?.teamDescription}
                errMssg={errors?.teamDescription}
            />
            <TagList {...{ selectedTag, setSelectedTag }} />
            <TagClassesSelect
                tagClasses={
                    editItem?.classifications?.length > 0
                        ? [
                              ...unassignedClassifications,
                              ...editItem?.classifications,
                          ]
                        : unassignedClassifications
                }
                {...{
                    request,
                    setRequest,
                    updateErrors,
                }}
                hasErr={errors?.classificationIds}
                errMssg={errors?.classificationIds}
                status={unassignedClassificationStatus}
                tagClassesErrorMssg={unassignedClassificationErrorMssg}
                handleRetry={getUnassignedClassifications}
            />

            <InviteTeamHeader
                {...{
                    users,
                    setUsers,
                    selectedUsers,
                    setSelectedUsers,
                    status,
                    userErrorMssg,
                    request,
                    setRequest,
                    handleRetry: getUsers,
                    updateErrors,
                    showInviteTeamSearch,
                    setShowInviteTeamSearch,
                }}
            />

            {!showInviteTeamSearch && (
                <TeamMemberSelect
                    {...{
                        selectedUsers,
                        setSelectedUsers,
                        request,
                        setRequest,
                        updateErrors,
                    }}
                    hasErr={errors?.userIds}
                    errMssg={errors?.userIds}
                />
            )}
            <Button
                type='submit'
                text='Save team'
                classType='primary'
                otherClass='my-3 w-100'
                loading={loading}
            />
        </form>
    );
};

TeamForm.prototypes = {
    closeModal: PropTypes.func.isRequired,
    editItem: PropTypes.object,
};

export default TeamForm;
