import {
    STATUS_MAP_COLORS,
    STATUS_MAP_OPTION_STATES,
} from "components/dashboard/TrainSAM/Dependency/enum";

import { calculateBarWidth } from "components/dashboard/TrainSAM/Dependency/helpers";

const StatusMapBar = ({ hrsDiff, selectedOption, status }) => {
    return (
        <div
            className='bar'
            style={{
                width: calculateBarWidth(
                    hrsDiff,
                    STATUS_MAP_OPTION_STATES[selectedOption]
                ),
                background: STATUS_MAP_COLORS[status],
            }}></div>
    );
};

export default StatusMapBar;
