import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import CapsuleEditButton from "./CapsuleEditButton/CapsuleEditButton";
import CapsuleDeleteButton from "./CapsuleDeleteButton/CapsuleDeleteButton";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import "./CapsuleActionDropdown.scss";

const { KB_PRODUCT_SETTING } = baseResources;
const { READ, UPDATE, DELETE } = baseResourceActions;

const CapsuleActionDropdown = ({
    children,
    hasChildren,
    hasContent,
    capsule,
    contentId,
    content,
    productId,
    handleToggleEditModal,
    handleToggleDeleteModal,
    useCapsuleStyles,
    hideEditAction,
    hideDeleteAction,
    viewCapsuleOnly,
    capsuleBottomMenu,
}) => {
    // viewCapsuleOnly
    const [dropdownOpen, toggle] = React.useState(false);
    const history = useHistory();

    const handleRedirect = () => {
        history.push({
            pathname: `/knowledge-base/capsule/${productId}/${contentId}`,
            state: {
                capsule,
                contentId,
                content,
                productId,
            },
        });
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            className={`${
                hasContent ? "capsule__content--tag mb-2" : "mainCapsule"
            }`}
            style={
                useCapsuleStyles
                    ? {
                          color: capsule?.borderColor,
                          borderColor: capsule?.borderColor,
                          background: "#fff",
                      }
                    : {}
            }
            direction='up'
            toggle={() => toggle((prevState) => !prevState)}>
            {hasChildren ? (
                <div onClick={() => toggle(true)}>{children}</div>
            ) : (
                <div className='d-flex align-items-center'>
                    <span
                        className='carbon__menu--icon'
                        onClick={() => toggle((prevState) => !prevState)}>
                        <ReactSVG src={asset?.icons?.carbonMore} />
                    </span>
                    <p class='main__content'>{content}</p>
                </div>
            )}
            <DropdownMenu
                className={`${
                    capsuleBottomMenu ? "capsule__bottom__menu" : ""
                }`}>
                {!hideEditAction && (
                    <ComponentAccessControl
                        baseResources={[`${UPDATE}_${KB_PRODUCT_SETTING}`]}>
                        <CapsuleEditButton
                            contentId={contentId}
                            content={content}
                            capsule={capsule}
                            handleToggleEditModal={handleToggleEditModal}
                            hasChildren={hasChildren}
                        />
                    </ComponentAccessControl>
                )}
                {!hideDeleteAction && (
                    <ComponentAccessControl
                        baseResources={[`${DELETE}_${KB_PRODUCT_SETTING}`]}>
                        <CapsuleDeleteButton
                            contentId={contentId}
                            capsule={capsule}
                            content={content}
                            handleToggleDeleteModal={handleToggleDeleteModal}
                            hasChildren={hasChildren}
                        />
                    </ComponentAccessControl>
                )}
                <ComponentAccessControl
                    baseResources={[`${READ}_${KB_PRODUCT_SETTING}`]}>
                    <DropdownItem onClick={handleRedirect}>View</DropdownItem>
                </ComponentAccessControl>
            </DropdownMenu>
        </Dropdown>
    );
};

export default CapsuleActionDropdown;
