import PropTypes from "prop-types";
import { SideModal } from "components/ui";
import CreateMessageTagForm from "./CreateMessageTagForm/CreateMessageTagForm";
import ModalHeading from "components/ui/Modal/ModalHeading/ModalHeading";
import { MESSAGE_TAG_ACTIONS } from "../../enum";

const CreateMessageTagModal = ({
    tag,
    showModal,
    closeModal,
    handleSuccess,
    currentAction,
}) => {
    return (
        <SideModal show={showModal} close={closeModal}>
            <div className='row me-0 ms-0'>
                <div className='col-10 offset-1'>
                    <ModalHeading
                        title={
                            currentAction === MESSAGE_TAG_ACTIONS.EDIT
                                ? "Edit Tags"
                                : "Create Tags"
                        }
                        subTitle={
                            currentAction === MESSAGE_TAG_ACTIONS.EDIT
                                ? "Edit Tag"
                                : "Create a new tag"
                        }
                    />
                    <CreateMessageTagForm
                        closeModal={closeModal}
                        handleSuccess={handleSuccess}
                        tag={tag}
                        currentAction={currentAction}
                    />
                </div>
            </div>
        </SideModal>
    );
};

CreateMessageTagModal.prototypes = {
    handleSuccess: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default CreateMessageTagModal;
