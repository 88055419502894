import * as types from "./types";

export const updateFormsList = (payload) => (dispatch) =>
    dispatch({ type: types.UPDATE_FORMS_LIST, payload });

export const setFormsList = (payload) => (dispatch) =>
    dispatch({ type: types.SET_FORMS_LIST, payload });

export const updateForm = (payload) => (dispatch) =>
    dispatch({ type: types.UPDATE_FORM, payload });

export const deleteForm = (payload) => (dispatch) =>
    dispatch({ type: types.DELETE_FORM, payload });
