import imageLinks from "assets/images";
import { useEffect, useState } from "react";
import { RECEIVE_MESSAGE, SUBSCRIBE_TO_TICKET } from "services/socket/events";
import {
    appMessageUserTypes,
    messageTypes,
} from "../../../../ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import {
    CANNED_RESPONSE_SENT,
    FORM_FILLED,
    MENTION_RECEIVED,
    SCENARIO_SENT,
    TICKET_INTERACTION_STATUSES,
    USER_REPLIED,
    YOU_REPLIED,
} from "./enum";
import { getRandomColor } from "utils/helper";
import { useSocket } from "services/socket/hook";

const { WORKSPACE_AGENT, THIRD_USER } = appMessageUserTypes;
const { BRANCH, DEFAULT, FORM_RESPONSE, SCENAIRO, CANNED_RESPONSE } =
    messageTypes;

const defaultAvatar = `https://e7.pngegg.com/pngimages/981/645/png-clipart-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-thumbnail.png`;

const TicketInteractionStatus = ({
    ticketId,
    isActive,
    messages,
    customer,
    assignedTeamMemberId,
    mentioned,
    agents,
    authUserId,
}) => {
    const [currentAction, setCurrentAction] = useState();
    const socket = useSocket();

    const isMentionedReceived = Boolean(
        mentioned?.find?.((userId) => userId === authUserId)
    );

    const figureInputAction = (message) => {
        var finalAction = "";
        //
        const { messageType, senderType } = message || {};
        switch (true) {
            case senderType === WORKSPACE_AGENT && messageType === BRANCH:
                finalAction = YOU_REPLIED;
                break;
            case senderType === WORKSPACE_AGENT && messageType === DEFAULT:
                finalAction = YOU_REPLIED;
                break;
            case senderType === WORKSPACE_AGENT && messageType === SCENAIRO:
                finalAction = SCENARIO_SENT;
                break;
            case senderType === WORKSPACE_AGENT &&
                messageType === CANNED_RESPONSE:
                finalAction = CANNED_RESPONSE_SENT;
                break;
            case senderType === THIRD_USER && messageType === DEFAULT:
                finalAction = USER_REPLIED;
                break;
            case senderType === THIRD_USER && messageType === FORM_RESPONSE:
                finalAction = FORM_FILLED;
                break;
            case isMentionedReceived:
                finalAction = MENTION_RECEIVED;
                break;
            default:
                finalAction = USER_REPLIED;
                break;
        }
        setCurrentAction(finalAction);
    };

    const preFigureInputAction = (message) => {
        if (message && message?.ticketId === ticketId) {
            figureInputAction(message);
        }
    };
    useEffect(() => {
        const allMessagesCopy = messages;

        const lastItemIndex = allMessagesCopy?.length - 1;
        const lastMessage = allMessagesCopy?.[lastItemIndex];
        lastMessage && figureInputAction(lastMessage);
        socket.emit(SUBSCRIBE_TO_TICKET, { ticketId });
        socket?.on(RECEIVE_MESSAGE, preFigureInputAction);
        return () => {
            socket.off(RECEIVE_MESSAGE);
        };
        //eslint-disable-next-line
    }, []);

    const customName = customer?.email !== "" ? customer?.email?.substring(0, customer?.email?.lastIndexOf("@")) :  customer?.appMessageUserId?.slice(0, 2);

    const customerProfileImgUrl =
        customer?.profileImageUrl || `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${customName}`;

    const assignedTeamMember = agents?.find?.(
        ({ userId }) => userId === assignedTeamMemberId
    )?.imageUrl;

    const assignedTeamMemberPic =  assignedTeamMember?.imageUrl !== defaultAvatar ? assignedTeamMember?.imageUrl : `https://ui-avatars.com/api/?background=${getRandomColor()}&color=fff&name=${assignedTeamMember?.firstName}+${assignedTeamMember?.lastName}`;
    const cleanedMentioned = [...new Set(mentioned.map((mention) => `${mention.replace(/[^\d]/g, '')}`))];
    const mentionedProfiles = cleanedMentioned?.map?.(
        (mentionedUserid) =>{
            const cleanedMentionedUserId = `${mentionedUserid.replace(/[^\d]/g, '')}`;
            const agent = agents?.find?.(({ userId }) => userId === cleanedMentionedUserId);
            return agent?.imageUrl !== defaultAvatar ? agent?.imageUrl : `https://ui-avatars.com/api/?background=${getRandomColor()}&color=fff&name=${agent?.firstName}+${agent?.lastName}`;
        }
    );

    const images = [customerProfileImgUrl, ...mentionedProfiles];

    const combinedProfilePictures = assignedTeamMemberPic
        ? [...images, assignedTeamMemberPic]
        : images;


    return (
        <div className={`mentioned-users ${isActive ? "active" : ""}`}>
            <div className='mentioned-users-images'>
                {([...new Set(combinedProfilePictures)])?.map((imageUrl, e) => (
                    <img
                        key={e}
                        src={imageUrl || imageLinks?.icons?.avatar}
                        alt={e}
                    />
                ))}
            </div>
            <span>
                {messages?.length > 0
                    ? TICKET_INTERACTION_STATUSES?.[currentAction]
                    : ""}
            </span>
        </div>
    );
};

export default TicketInteractionStatus;
