import React from "react";
import CreateRuleConstraintForm from "../FormBuilderInputHeading/CustomRulesDropDown/CreateRuleConstraintForm/CreateRuleConstraintForm";
import FormBuilderInputRulesListing from "./FormBuilderInputRulesListing/FormBuilderInputRulesListing";
import "./FormBuilderInputRules.scss";

const FormBuilderInputRules = ({
    elementId,
    activeRule,
    handleConstraintEdit,
    setActiveRule,
    removeInputRule,
    constraintKeysData,
    elementType,
    customRules = [],
    showConstraintView,
    saveCloseConstraintsForm,
    showRules,
    removeRuleConstraint,
    toggleRulesVisibility,
    errors,
    handleValidateCustomRule,
    handleRemoveCustomRuleError,
}) => {
    return (
        <div>
            <FormBuilderInputRulesListing
                elementId={elementId}
                activeRule={activeRule}
                setActiveRule={setActiveRule}
                removeInputRule={removeInputRule}
                elementType={elementType}
                customRules={customRules}
                showRules={showRules}
                removeRuleConstraint={removeRuleConstraint}
                toggleRulesVisibility={toggleRulesVisibility}
                errors={errors}
                handleValidateCustomRule={handleValidateCustomRule}
            />
            {showConstraintView && (
                <CreateRuleConstraintForm
                    elementId={elementId}
                    key={activeRule?.ruleCode}
                    constraintKeysData={constraintKeysData}
                    activeRule={activeRule}
                    handleConstraintEdit={handleConstraintEdit}
                    saveCloseConstraintsForm={saveCloseConstraintsForm}
                    removeRuleConstraint={removeRuleConstraint}
                    errors={errors}
                    handleRemoveCustomRuleError={handleRemoveCustomRuleError}
                />
            )}
        </div>
    );
};

export default FormBuilderInputRules;
