import { Button } from "components/ui";

const ConnectButton = ({ onClick, disabled, buttonText = "Connect" }) => {
    return (
        <>
            <Button
                otherClass='btn btn--connect'
                onClick={onClick}
                disabled={disabled}
                text={buttonText}
            />
        </>
    );
};

export default ConnectButton;
