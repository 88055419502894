import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmAction, Modal } from "components/ui";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { SEND_AGENT_COLLECTION } from "services/socket/events";
import { useSocket } from "services/socket/hook";

const ConfirmPFCollectionSend = ({
    showModal,
    closeModal,
    pFCollection,
    selectedTicket,
}) => {
    const { ticketId } = selectedTicket || {};

    const socket = useSocket();
    const toastMessage = useContext(ToastContext);
    const [loading, setLoading] = useState(false);

    const sendSocketEvent = (event, data, callBack) => {
        let socketResponse = null;
        let timeOut = null;
        const socketEvent = socket.emit(event, { ...data }, (resp) => {
            socketResponse = resp;
            clearTimeout(timeOut);
            return callBack(socketResponse, socketEvent);
        });

        timeOut = setTimeout(() => {
            callBack?.(socketResponse, socketEvent);
        }, 8000);
    };

    const socketEventCallBack = (socketResponse, socketEvent) => {
        if (socketResponse?.status === "ok") {
            setLoading(false);
            toastMessage(`Post form collection sent successfully`);
            closeModal();
        } else {
            toastMessage(`Post form collection failed to send`, "ERROR");
            setLoading(false);
        }
    };

    const sendCollectionToTicket = async () => {
        setLoading(true);
        sendSocketEvent(
            SEND_AGENT_COLLECTION,
            {
                ticketId,
                branchCollectionId: pFCollection?.branchCollectionId,
            },
            socketEventCallBack
        );
    };

    return (
        <Modal show={showModal} close={closeModal}>
            <ConfirmAction
                title={`Are you sure you want to send ${pFCollection?.branchCollectionName}`}
                handleYesClick={sendCollectionToTicket}
                loading={loading}
                close={closeModal}
            />
        </Modal>
    );
};

ConfirmPFCollectionSend.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default ConfirmPFCollectionSend;
