import AnalyticsQAReportFilter from "../AnalyticsQAReport/AnalyticsQAReportFilter/AnalyticsQAReportFilter";
import AnalyticsQATabs from "../common/AnalytcisQATabs/AnalyticsQATabs";
import AnalyticsQAProgressChart from "./AnalyticsQAProgressChart/AnalyticsQAProgressChart";
import AnalyticsQAProgressSummary from "./AnalyticsQAProgressSummary/AnalyticsQAProgressSummary";

const AnalyticsQAProgress = () => {
    return (
        <>
            <AnalyticsQATabs />
            <div className='padded__layout analytics-qa-vet-ticket'>
                <AnalyticsQAReportFilter />
                <AnalyticsQAProgressSummary />
                <AnalyticsQAProgressChart />
            </div>
        </>
    );
};

export default AnalyticsQAProgress;
