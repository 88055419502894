import { ReactSVG } from "react-svg";
import asset from "assets/images";
import { sortObjectsArrayByKey, truncateString } from "utils/helper";
import "./TicketAAHeading.scss";
import { apiRoute } from "services";
import { useSelector } from "react-redux";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { useState } from "react";

const TicketAAHeading = ({
    title,
    hasSteps,
    steps,
    getTicketGuide,
    activeStep,
    isDowntime,
    isUptime,
    guideFlowName,
    selectGuideOptionId,
}) => {
    const [showModal, toggleModal] = useState(false); 
    const { activeTicket } = useSelector(
        (state) => state.tickets
    );

    // const handleGoBack = () => {
    //     if (activeStep > 1) {
    //         selectGuideOptionId(
    //             steps?.find?.(
    //                 ({ currentStep }) => currentStep === activeStep - 1
    //             )?.ticketAgentGuideOptionId
    //         );
    //     }
    // };

    return (
        <>
            <section className='ticketaa-heading'>
                <div className='ticketaa-heading__title'>
                    <ReactSVG
                        src={asset.icons.rightBlack}
                        onClick={() => toggleModal(true)}
                    />
                    <h5>{truncateString(title, 30)}</h5>
                </div>
                {hasSteps && activeStep !== null && (
                    <div className='ticketaa-heading__steps'>
                        {sortObjectsArrayByKey(steps, "currentStep")?.slice((activeStep > 3 ? (activeStep - 3) : 0), (activeStep < 3 ? 3 : activeStep))?.map?.(
                            ({ currentStep, ticketAgentGuideOptionId }, index) => (
                                <span
                                    key={index}
                                    className={`${currentStep === activeStep
                                        ? "active"
                                        : ""
                                        }`}
                                    onClick={() =>{
                                        ;
                                        ;
                                        selectGuideOptionId(
                                            ticketAgentGuideOptionId
                                        )
                                    }
                                    }>
                                    {currentStep === activeStep
                                        ? `Step ${currentStep}`
                                        : ""}
                                </span>
                            )
                        )}
                    </div>
                )}
                {(isDowntime || isUptime) && (
                    <div className='ticketaa-heading__steps'>
                        {isDowntime ? (
                            <span className="downtime">
                                <ReactSVG src={asset.icons.downTimeArrow} />
                            </span>
                        ) : (
                            <span></span>
                        )}
                        {isUptime ? (
                            <span className="uptime">
                                <ReactSVG src={asset.icons.uptimeArrow} />
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </div>
                )}
            </section>
            {(isDowntime || isUptime) && <section className='ticketaa-heading_downtime'>
                {isDowntime && (
                    <div className='downtime_heading'>{guideFlowName}</div>
                )}

                {isUptime && (
                    <div className='uptime_heading'>{guideFlowName}</div>
                )}

                {/* {!isUptime && !isDowntime && (
                    <div className='regular_heading'>{guideFlowName}</div>
                )} */}
            </section>
            }

            {
                showModal && <ConfirmationModal 
                        {
                            ...{
                                title: `Change Agent Guide`,
                                show: showModal,
                                closeModal: () => toggleModal(false),
                                method: "get",
                                url: `${apiRoute?.reverseTicketGuideSelection(activeTicket.ticketId, steps && steps[0]?.ticketAgentGuideOptionId)}?exitGuide=${true}`,
                                handleSuccess: getTicketGuide
                            }
                        }
                    />
            }
        </>
    );
};
export default TicketAAHeading;
