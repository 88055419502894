import { ReactSVG } from "react-svg";
import React from "react";
import imageLinks from "assets/images";
import "./TicketsFilterGroupActions.scss";

const TicketsFilterGroupActions = ({
    showFilters,
    toggleFilters,
    handleRefreshData,
}) => {
    return (
        <div className='ticket-filter-group__actions'>
            <ReactSVG
                src={imageLinks?.icons?.tinyArrowRight}
                className={`toggle__ticket__filters ${
                    showFilters ? "rotate" : ""
                }`}
                onClick={() => toggleFilters(!showFilters)}
            />{" "}
            <ReactSVG
                src={imageLinks?.icons?.reset}
                className='ticket-filter-group__refresh'
                onClick={handleRefreshData}
            />
        </div>
    );
};

export default TicketsFilterGroupActions;
