import * as types from "./types";

export const saveCapsuleClassess = (data) => (dispatch) =>
    dispatch({ type: types.SET_CAPSULE_CLASSES, payload: data });
export const updateProductCapsuleCount = (data) => (dispatch) =>
    dispatch({ type: types.UPDATE_PRODUCT_CAPSULE_COUNT, payload: data });
export const saveActivePrimaryClass = (data) => (dispatch) =>
    dispatch({ type: types.SET_ACTIVE_PRIMARY_CLASS, payload: data });
export const saveActiveSecondaryClass = (data) => (dispatch) =>
    dispatch({ type: types.SET_ACTIVE_SECONDARY_CLASS, payload: data });
