import { ReactSVG } from "react-svg";
import "./BuilderElementBadge.scss";

const BuilderElementBadge = ({ badge }) => {
    return (
        <div className='builder__badge'>
            <ReactSVG src={badge} className='builder__badge--svg' />
        </div>
    );
};

export default BuilderElementBadge;
