import AddButton from "components/ui/Button/AddButton/AddButton";
import Heading from "components/ui/Heading/Heading";
import { AGENT_GUIDE_ACTIONS } from "../enum";
import GuideModal from "../modals/GuideModal/GuideModal";
import Filter from "components/dashboard/common/Filter/Filter";

const AgentAGuidesHeading = ({
    handleSuccess,
    handleSearch,
    searchIsLoading,
    clearSearch,
    defaultValue,
    showModal,
    handleActionModal,
    selectedGuide,
    modalAction,
    toggleModal,
    handleSortByAlpha,
    handleSortByDate,
    handleSortByLastModified,
    baseFilter,
    filterOption,
    handleBasefilter
}) => {
    return (
        <>
            <div className='agentaguides__heading'>
                <Heading
                    headingText={"Guides"}
                    headingSubText={"Add & change your guides"}
                />
                
                <div className='issues-heading'>
                    <div></div>
                    <div>
                           <AddButton
                        btnText={"Create New guide"}
                        onClick={() =>
                            handleActionModal(AGENT_GUIDE_ACTIONS.CREATE_GUIDE)
                        }
                        handleSearch={handleSearch}
                        isLoading={searchIsLoading}
                        clearSearch={clearSearch}
                        defaultValue={defaultValue}
                    />
                    </div>
             
                </div>
                <Filter
                        handleSortByAlpha={handleSortByAlpha}
                        handleSortByDate={handleSortByDate}
                        hideClassFilter={true}
                        handleSearch={handleSearch}
                        searchIsLoading={searchIsLoading}
                        clearSearch={clearSearch}
                        searchPlaceholder={"Search Agent Guides here"}
                        handleSortByLastModified={handleSortByLastModified}
                        baseFilter={baseFilter}
                        filterOption={filterOption}
                        handleBasefilter={handleBasefilter}
                        searchDefaultValue={defaultValue}
                    />
            </div>
            {showModal && (
                <GuideModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={handleSuccess}
                    {...{ selectedGuide, modalAction }}
                />
            )}
        </>
    );
};

export default AgentAGuidesHeading;
