import React from "react";
import BranchOptionView from "../BranchOptionView/BranchOptionView";
import BranchScheduleContent from "./BranchScheduleContent/BranchScheduleContent";
import BranchViewContent from "./BranchViewContent/BranchViewContent";
import BranchViewElementHeader from "./BranchViewElementHeader/BranchViewElementHeader";
import { SUB_DOWNTIME_BRANCH } from "components/dashboard/TrainSAM/SAM/DowntimeBranchesView/DowntimeBranchesList/enums";
import BranchViewEmbeds from "./BranchViewEmbeds/BranchViewEmbeds";
import BranchViewScenarios from "./BranchViewScenarios/BranchViewScenarios";
import BranchCannedMessages from "./BranchCannedMessages/BranchCannedMessages";

const BranchViewElement = ({
    branch,
    handleRefresh,
    hideBranchName,
    hideOptionsView,
    hideBranchActions,
    conversationId,
    collectionId,
    hideCapsuleContentGroup,
    isConversationBreakerView,
    existingOptionConnections,
    showRemoveBranchOption,
    branchEmbeds,
    showCannedMessages,
    isAgentGuideView,
    step,
    handleSuccess,
    guideId,
}) => {
    const {
        branchMainSentence,
        branchSubSentences,
        branchType,
        deliveryInterval,
        displayAverageResponseTime,
        requestRatings,
        branchHeading,
    } = branch || {};

    const {
        guideFlowCapsuleClassId,
        guideFlowCapsuleEntryId,
        branchCollections,
        scenairos,
        cannedResources,
        agentUpdates,
        guideFlowId,
        guideFlowOptions,
        mainInstruction,
    } = step || {};

    return (
        <div className='branch__view'>
            {!hideBranchName && (
                <BranchViewElementHeader
                    branch={branch}
                    step={step}
                    handleRefresh={handleRefresh}
                    hideBranchActions={hideBranchActions}
                    conversationId={conversationId}
                    collectionId={collectionId}
                    isConversationBreakerView={isConversationBreakerView}
                    showRemoveBranchOption={showRemoveBranchOption}
                    isAgentGuideView={isAgentGuideView}
                />
            )}
            {branchHeading && (
                <BranchViewContent
                    title='Main heading'
                    content={branchHeading}
                />
            )}
            {branchSubSentences?.map?.(({ content }, index) => (
                <BranchViewContent
                    key={index}
                    title='Sub Sentence'
                    content={content}
                />
            ))}
            <BranchViewContent
                content={branchMainSentence || mainInstruction}
                branch={branch}
                branchType={branchType}
                displayAverageResponseTime={displayAverageResponseTime}
                requestRatings={requestRatings}
                showImageAttachment={isAgentGuideView ? false : true}
                handleRefresh={handleRefresh}
            />
            {isAgentGuideView && (
                <BranchViewEmbeds
                    branchEmbeds={branchEmbeds}
                    handleSuccess={handleRefresh}
                    {...{
                        guideFlowCapsuleClassId,
                        guideFlowCapsuleEntryId,
                    }}
                    referenceData={step}
                />
            )}
            <BranchViewScenarios
                scenarios={scenairos}
                guideFlowId={guideFlowId}
                handleSuccess={handleSuccess}
                collections={branchCollections}
            />
            {showCannedMessages && (
                <BranchCannedMessages
                    cannedResources={cannedResources}
                    handleSuccess={handleSuccess}
                    guideFlowId={guideFlowId}
                    agentUpdates={agentUpdates}
                />
            )}
            {deliveryInterval && branchType === SUB_DOWNTIME_BRANCH && (
                <BranchScheduleContent deliveryInterval={deliveryInterval} />
            )}
            {!hideOptionsView && (
                <BranchOptionView
                    parentBranch={{
                        ...branch,
                        guideFlowOptions,
                        guideFlowId,
                        guideId,
                    }}
                    handleRefresh={handleRefresh}
                    hideCapsuleContentGroup={hideCapsuleContentGroup}
                    isConversationBreakerView={isConversationBreakerView}
                    existingOptionConnections={existingOptionConnections}
                    isAgentGuideView={isAgentGuideView}
                />
            )}
        </div>
    );
};

export default BranchViewElement;
